export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AccessoriesRelatedComponents = Paragraph__Bullets | Paragraph__Text_Button;

export type Agreement_Content = {
  __typename?: 'agreement_content';
  value?: Maybe<Scalars['String']>;
};

export type Agreement_ContentFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Agreement_Explication = {
  __typename?: 'agreement_explication';
  value?: Maybe<Scalars['String']>;
};

export type Agreement_ExplicationFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type BooleanQueryOperatorInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type BranchRelatedComponents =
  | Paragraph__Branches_Hero
  | Paragraph__Branches_What_Is_Ipos
  | Paragraph__Branches_Recommendation
  | Paragraph__Branches_Features
  | Paragraph__Bullets_Container
  | Paragraph__Branches_Recommended_Devices
  | Paragraph__Branches_Recommended_Tariffs
  | Paragraph__Branches_Check_What_S_Going_On
  | Paragraph__Branches_Testimonials
  | Paragraph__Discounts_Container
  | Paragraph__Branches_Saving_Up_Block;

export type ChildImageSharpFixed = Node & {
  __typename?: 'ChildImageSharpFixed';
  base64?: Maybe<Scalars['String']>;
  tracedSVG?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['String']>;
  srcSet?: Maybe<Scalars['String']>;
  srcWebp?: Maybe<Scalars['String']>;
  srcSetWebp?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type ChildImageSharpFixedConnection = {
  __typename?: 'ChildImageSharpFixedConnection';
  totalCount: Scalars['Int'];
  edges: Array<ChildImageSharpFixedEdge>;
  nodes: Array<ChildImageSharpFixed>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<ChildImageSharpFixedGroupConnection>;
};

export type ChildImageSharpFixedConnectionDistinctArgs = {
  field: ChildImageSharpFixedFieldsEnum;
};

export type ChildImageSharpFixedConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: ChildImageSharpFixedFieldsEnum;
};

export type ChildImageSharpFixedEdge = {
  __typename?: 'ChildImageSharpFixedEdge';
  next?: Maybe<ChildImageSharpFixed>;
  node: ChildImageSharpFixed;
  previous?: Maybe<ChildImageSharpFixed>;
};

export enum ChildImageSharpFixedFieldsEnum {
  base64 = 'base64',
  tracedSVG = 'tracedSVG',
  aspectRatio = 'aspectRatio',
  width = 'width',
  height = 'height',
  src = 'src',
  srcSet = 'srcSet',
  srcWebp = 'srcWebp',
  srcSetWebp = 'srcSetWebp',
  originalName = 'originalName',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type ChildImageSharpFixedFilterInput = {
  base64?: Maybe<StringQueryOperatorInput>;
  tracedSVG?: Maybe<StringQueryOperatorInput>;
  aspectRatio?: Maybe<FloatQueryOperatorInput>;
  width?: Maybe<FloatQueryOperatorInput>;
  height?: Maybe<FloatQueryOperatorInput>;
  src?: Maybe<StringQueryOperatorInput>;
  srcSet?: Maybe<StringQueryOperatorInput>;
  srcWebp?: Maybe<StringQueryOperatorInput>;
  srcSetWebp?: Maybe<StringQueryOperatorInput>;
  originalName?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type ChildImageSharpFixedGroupConnection = {
  __typename?: 'ChildImageSharpFixedGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<ChildImageSharpFixedEdge>;
  nodes: Array<ChildImageSharpFixed>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type ChildImageSharpFixedSortInput = {
  fields?: Maybe<Array<Maybe<ChildImageSharpFixedFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type DateQueryOperatorInput = {
  eq?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type Description = {
  __typename?: 'description';
  value?: Maybe<Scalars['String']>;
};

export type DescriptionFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Directory = Node & {
  __typename?: 'Directory';
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  sourceInstanceName?: Maybe<Scalars['String']>;
  absolutePath?: Maybe<Scalars['String']>;
  relativePath?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  prettySize?: Maybe<Scalars['String']>;
  modifiedTime?: Maybe<Scalars['Date']>;
  accessTime?: Maybe<Scalars['Date']>;
  changeTime?: Maybe<Scalars['Date']>;
  birthTime?: Maybe<Scalars['Date']>;
  root?: Maybe<Scalars['String']>;
  dir?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  relativeDirectory?: Maybe<Scalars['String']>;
  dev?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['Int']>;
  nlink?: Maybe<Scalars['Int']>;
  uid?: Maybe<Scalars['Int']>;
  gid?: Maybe<Scalars['Int']>;
  rdev?: Maybe<Scalars['Int']>;
  blksize?: Maybe<Scalars['Int']>;
  ino?: Maybe<Scalars['Int']>;
  blocks?: Maybe<Scalars['Int']>;
  atimeMs?: Maybe<Scalars['Float']>;
  mtimeMs?: Maybe<Scalars['Float']>;
  ctimeMs?: Maybe<Scalars['Float']>;
  birthtimeMs?: Maybe<Scalars['Float']>;
  atime?: Maybe<Scalars['Date']>;
  mtime?: Maybe<Scalars['Date']>;
  ctime?: Maybe<Scalars['Date']>;
  birthtime?: Maybe<Scalars['Date']>;
};

export type DirectoryModifiedTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryAccessTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryChangeTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryBirthTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryAtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryMtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryCtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryBirthtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryConnection = {
  __typename?: 'DirectoryConnection';
  totalCount: Scalars['Int'];
  edges: Array<DirectoryEdge>;
  nodes: Array<Directory>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<DirectoryGroupConnection>;
};

export type DirectoryConnectionDistinctArgs = {
  field: DirectoryFieldsEnum;
};

export type DirectoryConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: DirectoryFieldsEnum;
};

export type DirectoryEdge = {
  __typename?: 'DirectoryEdge';
  next?: Maybe<Directory>;
  node: Directory;
  previous?: Maybe<Directory>;
};

export enum DirectoryFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  sourceInstanceName = 'sourceInstanceName',
  absolutePath = 'absolutePath',
  relativePath = 'relativePath',
  extension = 'extension',
  size = 'size',
  prettySize = 'prettySize',
  modifiedTime = 'modifiedTime',
  accessTime = 'accessTime',
  changeTime = 'changeTime',
  birthTime = 'birthTime',
  root = 'root',
  dir = 'dir',
  base = 'base',
  ext = 'ext',
  name = 'name',
  relativeDirectory = 'relativeDirectory',
  dev = 'dev',
  mode = 'mode',
  nlink = 'nlink',
  uid = 'uid',
  gid = 'gid',
  rdev = 'rdev',
  blksize = 'blksize',
  ino = 'ino',
  blocks = 'blocks',
  atimeMs = 'atimeMs',
  mtimeMs = 'mtimeMs',
  ctimeMs = 'ctimeMs',
  birthtimeMs = 'birthtimeMs',
  atime = 'atime',
  mtime = 'mtime',
  ctime = 'ctime',
  birthtime = 'birthtime',
}

export type DirectoryFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  sourceInstanceName?: Maybe<StringQueryOperatorInput>;
  absolutePath?: Maybe<StringQueryOperatorInput>;
  relativePath?: Maybe<StringQueryOperatorInput>;
  extension?: Maybe<StringQueryOperatorInput>;
  size?: Maybe<IntQueryOperatorInput>;
  prettySize?: Maybe<StringQueryOperatorInput>;
  modifiedTime?: Maybe<DateQueryOperatorInput>;
  accessTime?: Maybe<DateQueryOperatorInput>;
  changeTime?: Maybe<DateQueryOperatorInput>;
  birthTime?: Maybe<DateQueryOperatorInput>;
  root?: Maybe<StringQueryOperatorInput>;
  dir?: Maybe<StringQueryOperatorInput>;
  base?: Maybe<StringQueryOperatorInput>;
  ext?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  relativeDirectory?: Maybe<StringQueryOperatorInput>;
  dev?: Maybe<IntQueryOperatorInput>;
  mode?: Maybe<IntQueryOperatorInput>;
  nlink?: Maybe<IntQueryOperatorInput>;
  uid?: Maybe<IntQueryOperatorInput>;
  gid?: Maybe<IntQueryOperatorInput>;
  rdev?: Maybe<IntQueryOperatorInput>;
  blksize?: Maybe<IntQueryOperatorInput>;
  ino?: Maybe<IntQueryOperatorInput>;
  blocks?: Maybe<IntQueryOperatorInput>;
  atimeMs?: Maybe<FloatQueryOperatorInput>;
  mtimeMs?: Maybe<FloatQueryOperatorInput>;
  ctimeMs?: Maybe<FloatQueryOperatorInput>;
  birthtimeMs?: Maybe<FloatQueryOperatorInput>;
  atime?: Maybe<DateQueryOperatorInput>;
  mtime?: Maybe<DateQueryOperatorInput>;
  ctime?: Maybe<DateQueryOperatorInput>;
  birthtime?: Maybe<DateQueryOperatorInput>;
};

export type DirectoryGroupConnection = {
  __typename?: 'DirectoryGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<DirectoryEdge>;
  nodes: Array<Directory>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type DirectorySortInput = {
  fields?: Maybe<Array<Maybe<DirectoryFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type DuotoneGradient = {
  highlight: Scalars['String'];
  shadow: Scalars['String'];
  opacity?: Maybe<Scalars['Int']>;
};

export type Field_Agreement_1 = {
  __typename?: 'field_agreement_1';
  value?: Maybe<Scalars['String']>;
};

export type Field_Agreement_1FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Agreement_3 = {
  __typename?: 'field_agreement_3';
  value?: Maybe<Scalars['String']>;
};

export type Field_Agreement_3FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Appointment_Text = {
  __typename?: 'field_appointment_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Appointment_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Branches_Image = {
  __typename?: 'field_branches_image';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Branches_Image_Mobile = {
  __typename?: 'field_branches_image_mobile';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Branches_Image_MobileFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Branches_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Branches_Text = {
  __typename?: 'field_branches_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Branches_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Bullets_Icons = {
  __typename?: 'field_bullets_icons';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Bullets_IconsFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Bullets_IconsFilterListInput = {
  elemMatch?: Maybe<Field_Bullets_IconsFilterInput>;
};

export type Field_Date_From_Api = {
  __typename?: 'field_date_from_api';
  iposfee?: Maybe<Scalars['String']>;
};

export type Field_Description = {
  __typename?: 'field_description';
  value?: Maybe<Scalars['String']>;
};

export type Field_Description_Member_Board = {
  __typename?: 'field_description_member_board';
  value?: Maybe<Scalars['String']>;
};

export type Field_Description_Member_BoardFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_DescriptionFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Footer_Left = {
  __typename?: 'field_footer_left';
  value?: Maybe<Scalars['String']>;
};

export type Field_Footer_LeftFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Footer_Right = {
  __typename?: 'field_footer_right';
  value?: Maybe<Scalars['String']>;
};

export type Field_Footer_RightFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Heading_And_Text_Text = {
  __typename?: 'field_heading_and_text_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Heading_And_Text_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_1_Image = {
  __typename?: 'field_hero_1_image';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Hero_1_Image_Mobile = {
  __typename?: 'field_hero_1_image_mobile';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Hero_1_Image_MobileFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_1_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_1_Subtitle = {
  __typename?: 'field_hero_1_subtitle';
  value?: Maybe<Scalars['String']>;
};

export type Field_Hero_1_SubtitleFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_1_Text_1 = {
  __typename?: 'field_hero_1_text_1';
  value?: Maybe<Scalars['String']>;
};

export type Field_Hero_1_Text_1FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_1_Text_2 = {
  __typename?: 'field_hero_1_text_2';
  value?: Maybe<Scalars['String']>;
};

export type Field_Hero_1_Text_2FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_1_Title = {
  __typename?: 'field_hero_1_title';
  value?: Maybe<Scalars['String']>;
};

export type Field_Hero_1_TitleFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_Headline = {
  __typename?: 'field_hero_headline';
  value?: Maybe<Scalars['String']>;
};

export type Field_Hero_HeadlineFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_Image = {
  __typename?: 'field_hero_image';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Hero_Image_Mobile = {
  __typename?: 'field_hero_image_mobile';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Hero_Image_MobileFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_Text = {
  __typename?: 'field_hero_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Hero_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Hero_Video_Thumbnail = {
  __typename?: 'field_hero_video_thumbnail';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Hero_Video_ThumbnailFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Ico = {
  __typename?: 'field_ico';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Ico_1 = {
  __typename?: 'field_ico_1';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Ico_1FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_IcoFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Image = {
  __typename?: 'field_image';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Image_1 = {
  __typename?: 'field_image_1';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Image_1FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Image_2 = {
  __typename?: 'field_image_2';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Image_2FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Image_3 = {
  __typename?: 'field_image_3';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Image_3FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Info_Text = {
  __typename?: 'field_info_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Info_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Logos_Text = {
  __typename?: 'field_logos_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Logos_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Main_Text = {
  __typename?: 'field_main_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Main_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Modal_1 = {
  __typename?: 'field_modal_1';
  value?: Maybe<Scalars['String']>;
};

export type Field_Modal_1FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Modal_2 = {
  __typename?: 'field_modal_2';
  value?: Maybe<Scalars['String']>;
};

export type Field_Modal_2FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Mp_Image = {
  __typename?: 'field_mp_image';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Mp_Image_Footer = {
  __typename?: 'field_mp_image_footer';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Mp_Image_FooterFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Mp_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Mp_Text = {
  __typename?: 'field_mp_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Mp_Text_Footer = {
  __typename?: 'field_mp_text_footer';
  value?: Maybe<Scalars['String']>;
};

export type Field_Mp_Text_FooterFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Mp_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Nq_Quotation = {
  __typename?: 'field_nq_quotation';
  value?: Maybe<Scalars['String']>;
};

export type Field_Nq_QuotationFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Pages_Text = {
  __typename?: 'field_pages_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Pages_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Paragraph_Headline = {
  __typename?: 'field_paragraph_headline';
  value?: Maybe<Scalars['String']>;
};

export type Field_Paragraph_HeadlineFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Photo_Member_Of_The_Board = {
  __typename?: 'field_photo_member_of_the_board';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Photo_Member_Of_The_BoardFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Pl_Description_In_Cms = {
  __typename?: 'field_pl_description_in_cms';
  value?: Maybe<Scalars['String']>;
};

export type Field_Pl_Description_In_CmsFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Pl_Header_1 = {
  __typename?: 'field_pl_header_1';
  value?: Maybe<Scalars['String']>;
};

export type Field_Pl_Header_1FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Pl_Header_2 = {
  __typename?: 'field_pl_header_2';
  value?: Maybe<Scalars['String']>;
};

export type Field_Pl_Header_2FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Pl_Header_3 = {
  __typename?: 'field_pl_header_3';
  value?: Maybe<Scalars['String']>;
};

export type Field_Pl_Header_3FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Pl_Header_4 = {
  __typename?: 'field_pl_header_4';
  value?: Maybe<Scalars['String']>;
};

export type Field_Pl_Header_4FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Popup_Body = {
  __typename?: 'field_popup_body';
  value?: Maybe<Scalars['String']>;
};

export type Field_Popup_BodyFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Profits_Name = {
  __typename?: 'field_profits_name';
  value?: Maybe<Scalars['String']>;
};

export type Field_Profits_NameFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Secondary_Text = {
  __typename?: 'field_secondary_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Secondary_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Settings_Tresc = {
  __typename?: 'field_settings_tresc';
  value?: Maybe<Scalars['String']>;
};

export type Field_Settings_TrescFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Short_Text_1 = {
  __typename?: 'field_short_text_1';
  value?: Maybe<Scalars['String']>;
};

export type Field_Short_Text_1FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Short_Text_2 = {
  __typename?: 'field_short_text_2';
  value?: Maybe<Scalars['String']>;
};

export type Field_Short_Text_2FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Short_Text_3 = {
  __typename?: 'field_short_text_3';
  value?: Maybe<Scalars['String']>;
};

export type Field_Short_Text_3FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Short_Text_4 = {
  __typename?: 'field_short_text_4';
  value?: Maybe<Scalars['String']>;
};

export type Field_Short_Text_4FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Social_Link = {
  __typename?: 'field_social_link';
  uri?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Field_Social_LinkFilterInput = {
  uri?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
};

export type Field_Terminal_Description = {
  __typename?: 'field_terminal_description';
  value?: Maybe<Scalars['String']>;
};

export type Field_Terminal_DescriptionFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Terminal_Image = {
  __typename?: 'field_terminal_image';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Terminal_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Field_Terminals_Description = {
  __typename?: 'field_terminals_description';
  value?: Maybe<Scalars['String']>;
};

export type Field_Terminals_DescriptionFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Terminals_Title = {
  __typename?: 'field_terminals_title';
  value?: Maybe<Scalars['String']>;
};

export type Field_Terminals_TitleFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Text = {
  __typename?: 'field_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Text_1 = {
  __typename?: 'field_text_1';
  value?: Maybe<Scalars['String']>;
};

export type Field_Text_1FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Text_2 = {
  __typename?: 'field_text_2';
  value?: Maybe<Scalars['String']>;
};

export type Field_Text_2FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Text_3 = {
  __typename?: 'field_text_3';
  value?: Maybe<Scalars['String']>;
};

export type Field_Text_3FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Text_4 = {
  __typename?: 'field_text_4';
  value?: Maybe<Scalars['String']>;
};

export type Field_Text_4FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Text_When_Unpublished = {
  __typename?: 'field_text_when_unpublished';
  value?: Maybe<Scalars['String']>;
};

export type Field_Text_When_UnpublishedFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Video_Text = {
  __typename?: 'field_video_text';
  value?: Maybe<Scalars['String']>;
};

export type Field_Video_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Field_Video_Thumbnail = {
  __typename?: 'field_video_thumbnail';
  alt?: Maybe<Scalars['String']>;
};

export type Field_Video_ThumbnailFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type File = Node & {
  __typename?: 'File';
  birthtime?: Maybe<Scalars['Date']>;
  birthtimeMs?: Maybe<Scalars['Float']>;
  childImageSharp?: Maybe<ImageSharp>;
  /** Copy file to static directory and return public url to it */
  publicURL?: Maybe<Scalars['String']>;
  sourceInstanceName?: Maybe<Scalars['String']>;
  absolutePath?: Maybe<Scalars['String']>;
  relativePath?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  prettySize?: Maybe<Scalars['String']>;
  modifiedTime?: Maybe<Scalars['Date']>;
  accessTime?: Maybe<Scalars['Date']>;
  changeTime?: Maybe<Scalars['Date']>;
  birthTime?: Maybe<Scalars['Date']>;
  root?: Maybe<Scalars['String']>;
  dir?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  relativeDirectory?: Maybe<Scalars['String']>;
  dev?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['Int']>;
  nlink?: Maybe<Scalars['Int']>;
  uid?: Maybe<Scalars['Int']>;
  gid?: Maybe<Scalars['Int']>;
  rdev?: Maybe<Scalars['Int']>;
  blksize?: Maybe<Scalars['Int']>;
  ino?: Maybe<Scalars['Int']>;
  blocks?: Maybe<Scalars['Int']>;
  atimeMs?: Maybe<Scalars['Float']>;
  mtimeMs?: Maybe<Scalars['Float']>;
  ctimeMs?: Maybe<Scalars['Float']>;
  atime?: Maybe<Scalars['Date']>;
  mtime?: Maybe<Scalars['Date']>;
  ctime?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  childJsonJson?: Maybe<JsonJson>;
  childrenProductsJson?: Maybe<Array<Maybe<ProductsJson>>>;
};

export type FileModifiedTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type FileAccessTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type FileChangeTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type FileBirthTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type FileAtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type FileMtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type FileCtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type File__File = Node & {
  __typename?: 'file__file';
  localFile?: Maybe<File>;
  uri?: Maybe<Uri>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__fid?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  filemime?: Maybe<Scalars['String']>;
  filesize?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<File__FileRelationships>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type File__FileCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type File__FileChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type File__FileConnection = {
  __typename?: 'file__fileConnection';
  totalCount: Scalars['Int'];
  edges: Array<File__FileEdge>;
  nodes: Array<File__File>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<File__FileGroupConnection>;
};

export type File__FileConnectionDistinctArgs = {
  field: File__FileFieldsEnum;
};

export type File__FileConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: File__FileFieldsEnum;
};

export type File__FileEdge = {
  __typename?: 'file__fileEdge';
  next?: Maybe<File__File>;
  node: File__File;
  previous?: Maybe<File__File>;
};

export enum File__FileFieldsEnum {
  localFile___birthtime = 'localFile___birthtime',
  localFile___birthtimeMs = 'localFile___birthtimeMs',
  localFile___childImageSharp___id = 'localFile___childImageSharp___id',
  localFile___childImageSharp___fixed___base64 = 'localFile___childImageSharp___fixed___base64',
  localFile___childImageSharp___fixed___tracedSVG = 'localFile___childImageSharp___fixed___tracedSVG',
  localFile___childImageSharp___fixed___aspectRatio = 'localFile___childImageSharp___fixed___aspectRatio',
  localFile___childImageSharp___fixed___width = 'localFile___childImageSharp___fixed___width',
  localFile___childImageSharp___fixed___height = 'localFile___childImageSharp___fixed___height',
  localFile___childImageSharp___fixed___src = 'localFile___childImageSharp___fixed___src',
  localFile___childImageSharp___fixed___srcSet = 'localFile___childImageSharp___fixed___srcSet',
  localFile___childImageSharp___fixed___srcWebp = 'localFile___childImageSharp___fixed___srcWebp',
  localFile___childImageSharp___fixed___srcSetWebp = 'localFile___childImageSharp___fixed___srcSetWebp',
  localFile___childImageSharp___fixed___originalName = 'localFile___childImageSharp___fixed___originalName',
  localFile___childImageSharp___resolutions___base64 = 'localFile___childImageSharp___resolutions___base64',
  localFile___childImageSharp___resolutions___tracedSVG = 'localFile___childImageSharp___resolutions___tracedSVG',
  localFile___childImageSharp___resolutions___aspectRatio = 'localFile___childImageSharp___resolutions___aspectRatio',
  localFile___childImageSharp___resolutions___width = 'localFile___childImageSharp___resolutions___width',
  localFile___childImageSharp___resolutions___height = 'localFile___childImageSharp___resolutions___height',
  localFile___childImageSharp___resolutions___src = 'localFile___childImageSharp___resolutions___src',
  localFile___childImageSharp___resolutions___srcSet = 'localFile___childImageSharp___resolutions___srcSet',
  localFile___childImageSharp___resolutions___srcWebp = 'localFile___childImageSharp___resolutions___srcWebp',
  localFile___childImageSharp___resolutions___srcSetWebp = 'localFile___childImageSharp___resolutions___srcSetWebp',
  localFile___childImageSharp___resolutions___originalName = 'localFile___childImageSharp___resolutions___originalName',
  localFile___childImageSharp___fluid___base64 = 'localFile___childImageSharp___fluid___base64',
  localFile___childImageSharp___fluid___tracedSVG = 'localFile___childImageSharp___fluid___tracedSVG',
  localFile___childImageSharp___fluid___aspectRatio = 'localFile___childImageSharp___fluid___aspectRatio',
  localFile___childImageSharp___fluid___src = 'localFile___childImageSharp___fluid___src',
  localFile___childImageSharp___fluid___srcSet = 'localFile___childImageSharp___fluid___srcSet',
  localFile___childImageSharp___fluid___srcWebp = 'localFile___childImageSharp___fluid___srcWebp',
  localFile___childImageSharp___fluid___srcSetWebp = 'localFile___childImageSharp___fluid___srcSetWebp',
  localFile___childImageSharp___fluid___sizes = 'localFile___childImageSharp___fluid___sizes',
  localFile___childImageSharp___fluid___originalImg = 'localFile___childImageSharp___fluid___originalImg',
  localFile___childImageSharp___fluid___originalName = 'localFile___childImageSharp___fluid___originalName',
  localFile___childImageSharp___fluid___presentationWidth = 'localFile___childImageSharp___fluid___presentationWidth',
  localFile___childImageSharp___fluid___presentationHeight = 'localFile___childImageSharp___fluid___presentationHeight',
  localFile___childImageSharp___sizes___base64 = 'localFile___childImageSharp___sizes___base64',
  localFile___childImageSharp___sizes___tracedSVG = 'localFile___childImageSharp___sizes___tracedSVG',
  localFile___childImageSharp___sizes___aspectRatio = 'localFile___childImageSharp___sizes___aspectRatio',
  localFile___childImageSharp___sizes___src = 'localFile___childImageSharp___sizes___src',
  localFile___childImageSharp___sizes___srcSet = 'localFile___childImageSharp___sizes___srcSet',
  localFile___childImageSharp___sizes___srcWebp = 'localFile___childImageSharp___sizes___srcWebp',
  localFile___childImageSharp___sizes___srcSetWebp = 'localFile___childImageSharp___sizes___srcSetWebp',
  localFile___childImageSharp___sizes___sizes = 'localFile___childImageSharp___sizes___sizes',
  localFile___childImageSharp___sizes___originalImg = 'localFile___childImageSharp___sizes___originalImg',
  localFile___childImageSharp___sizes___originalName = 'localFile___childImageSharp___sizes___originalName',
  localFile___childImageSharp___sizes___presentationWidth = 'localFile___childImageSharp___sizes___presentationWidth',
  localFile___childImageSharp___sizes___presentationHeight = 'localFile___childImageSharp___sizes___presentationHeight',
  localFile___childImageSharp___original___width = 'localFile___childImageSharp___original___width',
  localFile___childImageSharp___original___height = 'localFile___childImageSharp___original___height',
  localFile___childImageSharp___original___src = 'localFile___childImageSharp___original___src',
  localFile___childImageSharp___resize___src = 'localFile___childImageSharp___resize___src',
  localFile___childImageSharp___resize___tracedSVG = 'localFile___childImageSharp___resize___tracedSVG',
  localFile___childImageSharp___resize___width = 'localFile___childImageSharp___resize___width',
  localFile___childImageSharp___resize___height = 'localFile___childImageSharp___resize___height',
  localFile___childImageSharp___resize___aspectRatio = 'localFile___childImageSharp___resize___aspectRatio',
  localFile___childImageSharp___resize___originalName = 'localFile___childImageSharp___resize___originalName',
  localFile___childImageSharp___parent___id = 'localFile___childImageSharp___parent___id',
  localFile___childImageSharp___parent___children = 'localFile___childImageSharp___parent___children',
  localFile___childImageSharp___children = 'localFile___childImageSharp___children',
  localFile___childImageSharp___children___id = 'localFile___childImageSharp___children___id',
  localFile___childImageSharp___children___children = 'localFile___childImageSharp___children___children',
  localFile___childImageSharp___internal___content = 'localFile___childImageSharp___internal___content',
  localFile___childImageSharp___internal___contentDigest = 'localFile___childImageSharp___internal___contentDigest',
  localFile___childImageSharp___internal___description = 'localFile___childImageSharp___internal___description',
  localFile___childImageSharp___internal___fieldOwners = 'localFile___childImageSharp___internal___fieldOwners',
  localFile___childImageSharp___internal___ignoreType = 'localFile___childImageSharp___internal___ignoreType',
  localFile___childImageSharp___internal___mediaType = 'localFile___childImageSharp___internal___mediaType',
  localFile___childImageSharp___internal___owner = 'localFile___childImageSharp___internal___owner',
  localFile___childImageSharp___internal___type = 'localFile___childImageSharp___internal___type',
  localFile___publicURL = 'localFile___publicURL',
  localFile___sourceInstanceName = 'localFile___sourceInstanceName',
  localFile___absolutePath = 'localFile___absolutePath',
  localFile___relativePath = 'localFile___relativePath',
  localFile___extension = 'localFile___extension',
  localFile___size = 'localFile___size',
  localFile___prettySize = 'localFile___prettySize',
  localFile___modifiedTime = 'localFile___modifiedTime',
  localFile___accessTime = 'localFile___accessTime',
  localFile___changeTime = 'localFile___changeTime',
  localFile___birthTime = 'localFile___birthTime',
  localFile___root = 'localFile___root',
  localFile___dir = 'localFile___dir',
  localFile___base = 'localFile___base',
  localFile___ext = 'localFile___ext',
  localFile___name = 'localFile___name',
  localFile___relativeDirectory = 'localFile___relativeDirectory',
  localFile___dev = 'localFile___dev',
  localFile___mode = 'localFile___mode',
  localFile___nlink = 'localFile___nlink',
  localFile___uid = 'localFile___uid',
  localFile___gid = 'localFile___gid',
  localFile___rdev = 'localFile___rdev',
  localFile___blksize = 'localFile___blksize',
  localFile___ino = 'localFile___ino',
  localFile___blocks = 'localFile___blocks',
  localFile___atimeMs = 'localFile___atimeMs',
  localFile___mtimeMs = 'localFile___mtimeMs',
  localFile___ctimeMs = 'localFile___ctimeMs',
  localFile___atime = 'localFile___atime',
  localFile___mtime = 'localFile___mtime',
  localFile___ctime = 'localFile___ctime',
  localFile___url = 'localFile___url',
  localFile___id = 'localFile___id',
  localFile___parent___id = 'localFile___parent___id',
  localFile___parent___parent___id = 'localFile___parent___parent___id',
  localFile___parent___parent___children = 'localFile___parent___parent___children',
  localFile___parent___children = 'localFile___parent___children',
  localFile___parent___children___id = 'localFile___parent___children___id',
  localFile___parent___children___children = 'localFile___parent___children___children',
  localFile___parent___internal___content = 'localFile___parent___internal___content',
  localFile___parent___internal___contentDigest = 'localFile___parent___internal___contentDigest',
  localFile___parent___internal___description = 'localFile___parent___internal___description',
  localFile___parent___internal___fieldOwners = 'localFile___parent___internal___fieldOwners',
  localFile___parent___internal___ignoreType = 'localFile___parent___internal___ignoreType',
  localFile___parent___internal___mediaType = 'localFile___parent___internal___mediaType',
  localFile___parent___internal___owner = 'localFile___parent___internal___owner',
  localFile___parent___internal___type = 'localFile___parent___internal___type',
  localFile___children = 'localFile___children',
  localFile___children___id = 'localFile___children___id',
  localFile___children___parent___id = 'localFile___children___parent___id',
  localFile___children___parent___children = 'localFile___children___parent___children',
  localFile___children___children = 'localFile___children___children',
  localFile___children___children___id = 'localFile___children___children___id',
  localFile___children___children___children = 'localFile___children___children___children',
  localFile___children___internal___content = 'localFile___children___internal___content',
  localFile___children___internal___contentDigest = 'localFile___children___internal___contentDigest',
  localFile___children___internal___description = 'localFile___children___internal___description',
  localFile___children___internal___fieldOwners = 'localFile___children___internal___fieldOwners',
  localFile___children___internal___ignoreType = 'localFile___children___internal___ignoreType',
  localFile___children___internal___mediaType = 'localFile___children___internal___mediaType',
  localFile___children___internal___owner = 'localFile___children___internal___owner',
  localFile___children___internal___type = 'localFile___children___internal___type',
  localFile___internal___content = 'localFile___internal___content',
  localFile___internal___contentDigest = 'localFile___internal___contentDigest',
  localFile___internal___description = 'localFile___internal___description',
  localFile___internal___fieldOwners = 'localFile___internal___fieldOwners',
  localFile___internal___ignoreType = 'localFile___internal___ignoreType',
  localFile___internal___mediaType = 'localFile___internal___mediaType',
  localFile___internal___owner = 'localFile___internal___owner',
  localFile___internal___type = 'localFile___internal___type',
  uri___url = 'uri___url',
  drupal_id = 'drupal_id',
  drupal_internal__fid = 'drupal_internal__fid',
  langcode = 'langcode',
  filename = 'filename',
  filemime = 'filemime',
  filesize = 'filesize',
  status = 'status',
  created = 'created',
  changed = 'changed',
  uuid = 'uuid',
  relationships___node__blog_post = 'relationships___node__blog_post',
  relationships___node__blog_post___id = 'relationships___node__blog_post___id',
  relationships___node__blog_post___parent___id = 'relationships___node__blog_post___parent___id',
  relationships___node__blog_post___parent___children = 'relationships___node__blog_post___parent___children',
  relationships___node__blog_post___children = 'relationships___node__blog_post___children',
  relationships___node__blog_post___children___id = 'relationships___node__blog_post___children___id',
  relationships___node__blog_post___children___children = 'relationships___node__blog_post___children___children',
  relationships___node__blog_post___internal___content = 'relationships___node__blog_post___internal___content',
  relationships___node__blog_post___internal___contentDigest = 'relationships___node__blog_post___internal___contentDigest',
  relationships___node__blog_post___internal___description = 'relationships___node__blog_post___internal___description',
  relationships___node__blog_post___internal___fieldOwners = 'relationships___node__blog_post___internal___fieldOwners',
  relationships___node__blog_post___internal___ignoreType = 'relationships___node__blog_post___internal___ignoreType',
  relationships___node__blog_post___internal___mediaType = 'relationships___node__blog_post___internal___mediaType',
  relationships___node__blog_post___internal___owner = 'relationships___node__blog_post___internal___owner',
  relationships___node__blog_post___internal___type = 'relationships___node__blog_post___internal___type',
  relationships___node__blog_post___drupal_id = 'relationships___node__blog_post___drupal_id',
  relationships___node__blog_post___drupal_internal__nid = 'relationships___node__blog_post___drupal_internal__nid',
  relationships___node__blog_post___drupal_internal__vid = 'relationships___node__blog_post___drupal_internal__vid',
  relationships___node__blog_post___title = 'relationships___node__blog_post___title',
  relationships___node__blog_post___default_langcode = 'relationships___node__blog_post___default_langcode',
  relationships___node__blog_post___path___langcode = 'relationships___node__blog_post___path___langcode',
  relationships___node__blog_post___content_translation_source = 'relationships___node__blog_post___content_translation_source',
  relationships___node__blog_post___content_translation_outdated = 'relationships___node__blog_post___content_translation_outdated',
  relationships___node__blog_post___body___value = 'relationships___node__blog_post___body___value',
  relationships___node__blog_post___body___format = 'relationships___node__blog_post___body___format',
  relationships___node__blog_post___body___processed = 'relationships___node__blog_post___body___processed',
  relationships___node__blog_post___body___summary = 'relationships___node__blog_post___body___summary',
  relationships___node__blog_post___langcode = 'relationships___node__blog_post___langcode',
  relationships___node__blog_post___uuid = 'relationships___node__blog_post___uuid',
  relationships___node__blog_post___field_blog_post_image___alt = 'relationships___node__blog_post___field_blog_post_image___alt',
  relationships___node__blog_post___field_blog_post_image___title = 'relationships___node__blog_post___field_blog_post_image___title',
  relationships___node__blog_post___field_blog_post_image___width = 'relationships___node__blog_post___field_blog_post_image___width',
  relationships___node__blog_post___field_blog_post_image___height = 'relationships___node__blog_post___field_blog_post_image___height',
  relationships___node__blog_post___field_blog_post_image___drupal_internal__target_id = 'relationships___node__blog_post___field_blog_post_image___drupal_internal__target_id',
  relationships___node__devices = 'relationships___node__devices',
  relationships___node__devices___langcode = 'relationships___node__devices___langcode',
  relationships___node__devices___drupal_id = 'relationships___node__devices___drupal_id',
  relationships___node__devices___title = 'relationships___node__devices___title',
  relationships___node__devices___field_subtitle = 'relationships___node__devices___field_subtitle',
  relationships___node__devices___field_pl_description_in_cms___value = 'relationships___node__devices___field_pl_description_in_cms___value',
  relationships___node__devices___field_pl_is_online = 'relationships___node__devices___field_pl_is_online',
  relationships___node__devices___field_weight = 'relationships___node__devices___field_weight',
  relationships___node__devices___field_pl_strikethrough_price = 'relationships___node__devices___field_pl_strikethrough_price',
  relationships___node__devices___relationships___field_recommended_for_branches = 'relationships___node__devices___relationships___field_recommended_for_branches',
  relationships___node__devices___relationships___field_accessories = 'relationships___node__devices___relationships___field_accessories',
  relationships___node__devices___relationships___field_pl_cases = 'relationships___node__devices___relationships___field_pl_cases',
  relationships___node__devices___relationships___field_payment_methods = 'relationships___node__devices___relationships___field_payment_methods',
  relationships___node__devices___relationships___node__fiscalization = 'relationships___node__devices___relationships___node__fiscalization',
  relationships___node__devices___field_tooltip_text_for_branches = 'relationships___node__devices___field_tooltip_text_for_branches',
  relationships___node__devices___field_pl_is_virtual = 'relationships___node__devices___field_pl_is_virtual',
  relationships___node__devices___field_is_available = 'relationships___node__devices___field_is_available',
  relationships___node__devices___field_lease_strike_through_price = 'relationships___node__devices___field_lease_strike_through_price',
  relationships___node__devices___field_lease_allowed = 'relationships___node__devices___field_lease_allowed',
  relationships___node__devices___field_pl_price_list_name = 'relationships___node__devices___field_pl_price_list_name',
  relationships___node__devices___field_additional_price_lists = 'relationships___node__devices___field_additional_price_lists',
  relationships___node__devices___field_popup_body___value = 'relationships___node__devices___field_popup_body___value',
  relationships___node__devices___field_popup_button_back = 'relationships___node__devices___field_popup_button_back',
  relationships___node__devices___field_popup_button_ok = 'relationships___node__devices___field_popup_button_ok',
  relationships___node__devices___field_popup_enabled = 'relationships___node__devices___field_popup_enabled',
  relationships___node__devices___field_popup_title = 'relationships___node__devices___field_popup_title',
  relationships___node__devices___drupal_internal__nid = 'relationships___node__devices___drupal_internal__nid',
  relationships___node__devices___status = 'relationships___node__devices___status',
  relationships___node__devices___content_translation_source = 'relationships___node__devices___content_translation_source',
  relationships___node__devices___content_translation_outdated = 'relationships___node__devices___content_translation_outdated',
  relationships___node__devices___uuid = 'relationships___node__devices___uuid',
  relationships___node__devices___field_payment_methods = 'relationships___node__devices___field_payment_methods',
  relationships___node__devices___field_payment_methods___drupal_internal__target_id = 'relationships___node__devices___field_payment_methods___drupal_internal__target_id',
  relationships___node__devices___field_pl_cases = 'relationships___node__devices___field_pl_cases',
  relationships___node__devices___field_pl_cases___drupal_internal__target_id = 'relationships___node__devices___field_pl_cases___drupal_internal__target_id',
  relationships___node__devices___field_pl_image___alt = 'relationships___node__devices___field_pl_image___alt',
  relationships___node__devices___field_pl_image___title = 'relationships___node__devices___field_pl_image___title',
  relationships___node__devices___field_pl_image___width = 'relationships___node__devices___field_pl_image___width',
  relationships___node__devices___field_pl_image___height = 'relationships___node__devices___field_pl_image___height',
  relationships___node__devices___field_pl_image___drupal_internal__target_id = 'relationships___node__devices___field_pl_image___drupal_internal__target_id',
  relationships___node__devices___field_pl_image_for_accessories___alt = 'relationships___node__devices___field_pl_image_for_accessories___alt',
  relationships___node__devices___field_pl_image_for_accessories___title = 'relationships___node__devices___field_pl_image_for_accessories___title',
  relationships___node__devices___field_pl_image_for_accessories___width = 'relationships___node__devices___field_pl_image_for_accessories___width',
  relationships___node__devices___field_pl_image_for_accessories___height = 'relationships___node__devices___field_pl_image_for_accessories___height',
  relationships___node__devices___field_pl_image_for_accessories___drupal_internal__target_id = 'relationships___node__devices___field_pl_image_for_accessories___drupal_internal__target_id',
  relationships___node__devices___field_recommended_for_branches = 'relationships___node__devices___field_recommended_for_branches',
  relationships___node__devices___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__devices___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__devices___id = 'relationships___node__devices___id',
  relationships___node__devices___parent___id = 'relationships___node__devices___parent___id',
  relationships___node__devices___parent___children = 'relationships___node__devices___parent___children',
  relationships___node__devices___children = 'relationships___node__devices___children',
  relationships___node__devices___children___id = 'relationships___node__devices___children___id',
  relationships___node__devices___children___children = 'relationships___node__devices___children___children',
  relationships___node__devices___internal___content = 'relationships___node__devices___internal___content',
  relationships___node__devices___internal___contentDigest = 'relationships___node__devices___internal___contentDigest',
  relationships___node__devices___internal___description = 'relationships___node__devices___internal___description',
  relationships___node__devices___internal___fieldOwners = 'relationships___node__devices___internal___fieldOwners',
  relationships___node__devices___internal___ignoreType = 'relationships___node__devices___internal___ignoreType',
  relationships___node__devices___internal___mediaType = 'relationships___node__devices___internal___mediaType',
  relationships___node__devices___internal___owner = 'relationships___node__devices___internal___owner',
  relationships___node__devices___internal___type = 'relationships___node__devices___internal___type',
  relationships___node__accessories = 'relationships___node__accessories',
  relationships___node__accessories___langcode = 'relationships___node__accessories___langcode',
  relationships___node__accessories___drupal_id = 'relationships___node__accessories___drupal_id',
  relationships___node__accessories___title = 'relationships___node__accessories___title',
  relationships___node__accessories___field_pl_groupid = 'relationships___node__accessories___field_pl_groupid',
  relationships___node__accessories___field_weight = 'relationships___node__accessories___field_weight',
  relationships___node__accessories___relationships___field_recommended_for_branches = 'relationships___node__accessories___relationships___field_recommended_for_branches',
  relationships___node__accessories___drupal_internal__nid = 'relationships___node__accessories___drupal_internal__nid',
  relationships___node__accessories___status = 'relationships___node__accessories___status',
  relationships___node__accessories___content_translation_source = 'relationships___node__accessories___content_translation_source',
  relationships___node__accessories___content_translation_outdated = 'relationships___node__accessories___content_translation_outdated',
  relationships___node__accessories___uuid = 'relationships___node__accessories___uuid',
  relationships___node__accessories___field_date_from_api___drupal_internal__target_id = 'relationships___node__accessories___field_date_from_api___drupal_internal__target_id',
  relationships___node__accessories___field_pl_accessory_type___drupal_internal__target_id = 'relationships___node__accessories___field_pl_accessory_type___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image___alt = 'relationships___node__accessories___field_pl_image___alt',
  relationships___node__accessories___field_pl_image___title = 'relationships___node__accessories___field_pl_image___title',
  relationships___node__accessories___field_pl_image___width = 'relationships___node__accessories___field_pl_image___width',
  relationships___node__accessories___field_pl_image___height = 'relationships___node__accessories___field_pl_image___height',
  relationships___node__accessories___field_pl_image___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image_accessories_outl___alt = 'relationships___node__accessories___field_pl_image_accessories_outl___alt',
  relationships___node__accessories___field_pl_image_accessories_outl___title = 'relationships___node__accessories___field_pl_image_accessories_outl___title',
  relationships___node__accessories___field_pl_image_accessories_outl___width = 'relationships___node__accessories___field_pl_image_accessories_outl___width',
  relationships___node__accessories___field_pl_image_accessories_outl___height = 'relationships___node__accessories___field_pl_image_accessories_outl___height',
  relationships___node__accessories___field_pl_image_accessories_outl___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image_accessories_outl___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image_accessories_table___alt = 'relationships___node__accessories___field_pl_image_accessories_table___alt',
  relationships___node__accessories___field_pl_image_accessories_table___title = 'relationships___node__accessories___field_pl_image_accessories_table___title',
  relationships___node__accessories___field_pl_image_accessories_table___width = 'relationships___node__accessories___field_pl_image_accessories_table___width',
  relationships___node__accessories___field_pl_image_accessories_table___height = 'relationships___node__accessories___field_pl_image_accessories_table___height',
  relationships___node__accessories___field_pl_image_accessories_table___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image_accessories_table___drupal_internal__target_id',
  relationships___node__accessories___field_recommended_for_branches = 'relationships___node__accessories___field_recommended_for_branches',
  relationships___node__accessories___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__accessories___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__accessories___id = 'relationships___node__accessories___id',
  relationships___node__accessories___parent___id = 'relationships___node__accessories___parent___id',
  relationships___node__accessories___parent___children = 'relationships___node__accessories___parent___children',
  relationships___node__accessories___children = 'relationships___node__accessories___children',
  relationships___node__accessories___children___id = 'relationships___node__accessories___children___id',
  relationships___node__accessories___children___children = 'relationships___node__accessories___children___children',
  relationships___node__accessories___internal___content = 'relationships___node__accessories___internal___content',
  relationships___node__accessories___internal___contentDigest = 'relationships___node__accessories___internal___contentDigest',
  relationships___node__accessories___internal___description = 'relationships___node__accessories___internal___description',
  relationships___node__accessories___internal___fieldOwners = 'relationships___node__accessories___internal___fieldOwners',
  relationships___node__accessories___internal___ignoreType = 'relationships___node__accessories___internal___ignoreType',
  relationships___node__accessories___internal___mediaType = 'relationships___node__accessories___internal___mediaType',
  relationships___node__accessories___internal___owner = 'relationships___node__accessories___internal___owner',
  relationships___node__accessories___internal___type = 'relationships___node__accessories___internal___type',
  relationships___paragraph__news_image_title_text = 'relationships___paragraph__news_image_title_text',
  relationships___paragraph__news_image_title_text___field_nitt_text = 'relationships___paragraph__news_image_title_text___field_nitt_text',
  relationships___paragraph__news_image_title_text___field_nitt_title = 'relationships___paragraph__news_image_title_text___field_nitt_title',
  relationships___paragraph__news_image_title_text___id = 'relationships___paragraph__news_image_title_text___id',
  relationships___paragraph__news_image_title_text___internal___content = 'relationships___paragraph__news_image_title_text___internal___content',
  relationships___paragraph__news_image_title_text___internal___contentDigest = 'relationships___paragraph__news_image_title_text___internal___contentDigest',
  relationships___paragraph__news_image_title_text___internal___description = 'relationships___paragraph__news_image_title_text___internal___description',
  relationships___paragraph__news_image_title_text___internal___fieldOwners = 'relationships___paragraph__news_image_title_text___internal___fieldOwners',
  relationships___paragraph__news_image_title_text___internal___ignoreType = 'relationships___paragraph__news_image_title_text___internal___ignoreType',
  relationships___paragraph__news_image_title_text___internal___mediaType = 'relationships___paragraph__news_image_title_text___internal___mediaType',
  relationships___paragraph__news_image_title_text___internal___owner = 'relationships___paragraph__news_image_title_text___internal___owner',
  relationships___paragraph__news_image_title_text___internal___type = 'relationships___paragraph__news_image_title_text___internal___type',
  relationships___paragraph__news_image_title_text___relationships___node__news = 'relationships___paragraph__news_image_title_text___relationships___node__news',
  relationships___paragraph__news_image_title_text___drupal_id = 'relationships___paragraph__news_image_title_text___drupal_id',
  relationships___paragraph__news_image_title_text___drupal_internal__id = 'relationships___paragraph__news_image_title_text___drupal_internal__id',
  relationships___paragraph__news_image_title_text___status = 'relationships___paragraph__news_image_title_text___status',
  relationships___paragraph__news_image_title_text___parent_id = 'relationships___paragraph__news_image_title_text___parent_id',
  relationships___paragraph__news_image_title_text___parent_type = 'relationships___paragraph__news_image_title_text___parent_type',
  relationships___paragraph__news_image_title_text___parent_field_name = 'relationships___paragraph__news_image_title_text___parent_field_name',
  relationships___paragraph__news_image_title_text___content_translation_source = 'relationships___paragraph__news_image_title_text___content_translation_source',
  relationships___paragraph__news_image_title_text___content_translation_outdated = 'relationships___paragraph__news_image_title_text___content_translation_outdated',
  relationships___paragraph__news_image_title_text___content_translation_changed = 'relationships___paragraph__news_image_title_text___content_translation_changed',
  relationships___paragraph__news_image_title_text___langcode = 'relationships___paragraph__news_image_title_text___langcode',
  relationships___paragraph__news_image_title_text___uuid = 'relationships___paragraph__news_image_title_text___uuid',
  relationships___paragraph__news_image_title_text___field_nitt_image___alt = 'relationships___paragraph__news_image_title_text___field_nitt_image___alt',
  relationships___paragraph__news_image_title_text___field_nitt_image___title = 'relationships___paragraph__news_image_title_text___field_nitt_image___title',
  relationships___paragraph__news_image_title_text___field_nitt_image___width = 'relationships___paragraph__news_image_title_text___field_nitt_image___width',
  relationships___paragraph__news_image_title_text___field_nitt_image___height = 'relationships___paragraph__news_image_title_text___field_nitt_image___height',
  relationships___paragraph__news_image_title_text___field_nitt_image___drupal_internal__target_id = 'relationships___paragraph__news_image_title_text___field_nitt_image___drupal_internal__target_id',
  relationships___paragraph__news_image_title_text___parent___id = 'relationships___paragraph__news_image_title_text___parent___id',
  relationships___paragraph__news_image_title_text___parent___children = 'relationships___paragraph__news_image_title_text___parent___children',
  relationships___paragraph__news_image_title_text___children = 'relationships___paragraph__news_image_title_text___children',
  relationships___paragraph__news_image_title_text___children___id = 'relationships___paragraph__news_image_title_text___children___id',
  relationships___paragraph__news_image_title_text___children___children = 'relationships___paragraph__news_image_title_text___children___children',
  relationships___node__cases = 'relationships___node__cases',
  relationships___node__cases___drupal_id = 'relationships___node__cases___drupal_id',
  relationships___node__cases___title = 'relationships___node__cases___title',
  relationships___node__cases___field_pl_color = 'relationships___node__cases___field_pl_color',
  relationships___node__cases___relationships___field_recommended_for_branches = 'relationships___node__cases___relationships___field_recommended_for_branches',
  relationships___node__cases___relationships___node__devices = 'relationships___node__cases___relationships___node__devices',
  relationships___node__cases___drupal_internal__nid = 'relationships___node__cases___drupal_internal__nid',
  relationships___node__cases___status = 'relationships___node__cases___status',
  relationships___node__cases___content_translation_source = 'relationships___node__cases___content_translation_source',
  relationships___node__cases___content_translation_outdated = 'relationships___node__cases___content_translation_outdated',
  relationships___node__cases___langcode = 'relationships___node__cases___langcode',
  relationships___node__cases___uuid = 'relationships___node__cases___uuid',
  relationships___node__cases___field_pl_image___alt = 'relationships___node__cases___field_pl_image___alt',
  relationships___node__cases___field_pl_image___title = 'relationships___node__cases___field_pl_image___title',
  relationships___node__cases___field_pl_image___width = 'relationships___node__cases___field_pl_image___width',
  relationships___node__cases___field_pl_image___height = 'relationships___node__cases___field_pl_image___height',
  relationships___node__cases___field_pl_image___drupal_internal__target_id = 'relationships___node__cases___field_pl_image___drupal_internal__target_id',
  relationships___node__cases___field_recommended_for_branches = 'relationships___node__cases___field_recommended_for_branches',
  relationships___node__cases___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__cases___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__cases___field_date_from_api___drupal_internal__target_id = 'relationships___node__cases___field_date_from_api___drupal_internal__target_id',
  relationships___node__cases___id = 'relationships___node__cases___id',
  relationships___node__cases___parent___id = 'relationships___node__cases___parent___id',
  relationships___node__cases___parent___children = 'relationships___node__cases___parent___children',
  relationships___node__cases___children = 'relationships___node__cases___children',
  relationships___node__cases___children___id = 'relationships___node__cases___children___id',
  relationships___node__cases___children___children = 'relationships___node__cases___children___children',
  relationships___node__cases___internal___content = 'relationships___node__cases___internal___content',
  relationships___node__cases___internal___contentDigest = 'relationships___node__cases___internal___contentDigest',
  relationships___node__cases___internal___description = 'relationships___node__cases___internal___description',
  relationships___node__cases___internal___fieldOwners = 'relationships___node__cases___internal___fieldOwners',
  relationships___node__cases___internal___ignoreType = 'relationships___node__cases___internal___ignoreType',
  relationships___node__cases___internal___mediaType = 'relationships___node__cases___internal___mediaType',
  relationships___node__cases___internal___owner = 'relationships___node__cases___internal___owner',
  relationships___node__cases___internal___type = 'relationships___node__cases___internal___type',
  relationships___paragraph__branches_what_is_ipos = 'relationships___paragraph__branches_what_is_ipos',
  relationships___paragraph__branches_what_is_ipos___internal___content = 'relationships___paragraph__branches_what_is_ipos___internal___content',
  relationships___paragraph__branches_what_is_ipos___internal___contentDigest = 'relationships___paragraph__branches_what_is_ipos___internal___contentDigest',
  relationships___paragraph__branches_what_is_ipos___internal___description = 'relationships___paragraph__branches_what_is_ipos___internal___description',
  relationships___paragraph__branches_what_is_ipos___internal___fieldOwners = 'relationships___paragraph__branches_what_is_ipos___internal___fieldOwners',
  relationships___paragraph__branches_what_is_ipos___internal___ignoreType = 'relationships___paragraph__branches_what_is_ipos___internal___ignoreType',
  relationships___paragraph__branches_what_is_ipos___internal___mediaType = 'relationships___paragraph__branches_what_is_ipos___internal___mediaType',
  relationships___paragraph__branches_what_is_ipos___internal___owner = 'relationships___paragraph__branches_what_is_ipos___internal___owner',
  relationships___paragraph__branches_what_is_ipos___internal___type = 'relationships___paragraph__branches_what_is_ipos___internal___type',
  relationships___paragraph__branches_what_is_ipos___id = 'relationships___paragraph__branches_what_is_ipos___id',
  relationships___paragraph__branches_what_is_ipos___drupal_id = 'relationships___paragraph__branches_what_is_ipos___drupal_id',
  relationships___paragraph__branches_what_is_ipos___field_branches_text___value = 'relationships___paragraph__branches_what_is_ipos___field_branches_text___value',
  relationships___paragraph__branches_what_is_ipos___field_paragraph_headline___value = 'relationships___paragraph__branches_what_is_ipos___field_paragraph_headline___value',
  relationships___paragraph__branches_what_is_ipos___field_short_text_1___value = 'relationships___paragraph__branches_what_is_ipos___field_short_text_1___value',
  relationships___paragraph__branches_what_is_ipos___field_short_text_2___value = 'relationships___paragraph__branches_what_is_ipos___field_short_text_2___value',
  relationships___paragraph__branches_what_is_ipos___field_short_text_3___value = 'relationships___paragraph__branches_what_is_ipos___field_short_text_3___value',
  relationships___paragraph__branches_what_is_ipos___relationships___node__branches = 'relationships___paragraph__branches_what_is_ipos___relationships___node__branches',
  relationships___paragraph__branches_what_is_ipos___relationships___node__pages = 'relationships___paragraph__branches_what_is_ipos___relationships___node__pages',
  relationships___paragraph__branches_what_is_ipos___drupal_internal__id = 'relationships___paragraph__branches_what_is_ipos___drupal_internal__id',
  relationships___paragraph__branches_what_is_ipos___status = 'relationships___paragraph__branches_what_is_ipos___status',
  relationships___paragraph__branches_what_is_ipos___created = 'relationships___paragraph__branches_what_is_ipos___created',
  relationships___paragraph__branches_what_is_ipos___parent_id = 'relationships___paragraph__branches_what_is_ipos___parent_id',
  relationships___paragraph__branches_what_is_ipos___parent_type = 'relationships___paragraph__branches_what_is_ipos___parent_type',
  relationships___paragraph__branches_what_is_ipos___parent_field_name = 'relationships___paragraph__branches_what_is_ipos___parent_field_name',
  relationships___paragraph__branches_what_is_ipos___content_translation_source = 'relationships___paragraph__branches_what_is_ipos___content_translation_source',
  relationships___paragraph__branches_what_is_ipos___content_translation_outdated = 'relationships___paragraph__branches_what_is_ipos___content_translation_outdated',
  relationships___paragraph__branches_what_is_ipos___content_translation_changed = 'relationships___paragraph__branches_what_is_ipos___content_translation_changed',
  relationships___paragraph__branches_what_is_ipos___langcode = 'relationships___paragraph__branches_what_is_ipos___langcode',
  relationships___paragraph__branches_what_is_ipos___uuid = 'relationships___paragraph__branches_what_is_ipos___uuid',
  relationships___paragraph__branches_what_is_ipos___field_branches_image___alt = 'relationships___paragraph__branches_what_is_ipos___field_branches_image___alt',
  relationships___paragraph__branches_what_is_ipos___field_branches_image___title = 'relationships___paragraph__branches_what_is_ipos___field_branches_image___title',
  relationships___paragraph__branches_what_is_ipos___field_branches_image___width = 'relationships___paragraph__branches_what_is_ipos___field_branches_image___width',
  relationships___paragraph__branches_what_is_ipos___field_branches_image___height = 'relationships___paragraph__branches_what_is_ipos___field_branches_image___height',
  relationships___paragraph__branches_what_is_ipos___field_branches_image___drupal_internal__target_id = 'relationships___paragraph__branches_what_is_ipos___field_branches_image___drupal_internal__target_id',
  relationships___paragraph__branches_what_is_ipos___field_ico_1___alt = 'relationships___paragraph__branches_what_is_ipos___field_ico_1___alt',
  relationships___paragraph__branches_what_is_ipos___field_ico_1___title = 'relationships___paragraph__branches_what_is_ipos___field_ico_1___title',
  relationships___paragraph__branches_what_is_ipos___field_ico_1___width = 'relationships___paragraph__branches_what_is_ipos___field_ico_1___width',
  relationships___paragraph__branches_what_is_ipos___field_ico_1___height = 'relationships___paragraph__branches_what_is_ipos___field_ico_1___height',
  relationships___paragraph__branches_what_is_ipos___field_ico_1___drupal_internal__target_id = 'relationships___paragraph__branches_what_is_ipos___field_ico_1___drupal_internal__target_id',
  relationships___paragraph__branches_what_is_ipos___field_ico_2___alt = 'relationships___paragraph__branches_what_is_ipos___field_ico_2___alt',
  relationships___paragraph__branches_what_is_ipos___field_ico_2___title = 'relationships___paragraph__branches_what_is_ipos___field_ico_2___title',
  relationships___paragraph__branches_what_is_ipos___field_ico_2___width = 'relationships___paragraph__branches_what_is_ipos___field_ico_2___width',
  relationships___paragraph__branches_what_is_ipos___field_ico_2___height = 'relationships___paragraph__branches_what_is_ipos___field_ico_2___height',
  relationships___paragraph__branches_what_is_ipos___field_ico_2___drupal_internal__target_id = 'relationships___paragraph__branches_what_is_ipos___field_ico_2___drupal_internal__target_id',
  relationships___paragraph__branches_what_is_ipos___field_ico_3___alt = 'relationships___paragraph__branches_what_is_ipos___field_ico_3___alt',
  relationships___paragraph__branches_what_is_ipos___field_ico_3___title = 'relationships___paragraph__branches_what_is_ipos___field_ico_3___title',
  relationships___paragraph__branches_what_is_ipos___field_ico_3___width = 'relationships___paragraph__branches_what_is_ipos___field_ico_3___width',
  relationships___paragraph__branches_what_is_ipos___field_ico_3___height = 'relationships___paragraph__branches_what_is_ipos___field_ico_3___height',
  relationships___paragraph__branches_what_is_ipos___field_ico_3___drupal_internal__target_id = 'relationships___paragraph__branches_what_is_ipos___field_ico_3___drupal_internal__target_id',
  relationships___paragraph__branches_what_is_ipos___parent___id = 'relationships___paragraph__branches_what_is_ipos___parent___id',
  relationships___paragraph__branches_what_is_ipos___parent___children = 'relationships___paragraph__branches_what_is_ipos___parent___children',
  relationships___paragraph__branches_what_is_ipos___children = 'relationships___paragraph__branches_what_is_ipos___children',
  relationships___paragraph__branches_what_is_ipos___children___id = 'relationships___paragraph__branches_what_is_ipos___children___id',
  relationships___paragraph__branches_what_is_ipos___children___children = 'relationships___paragraph__branches_what_is_ipos___children___children',
  relationships___paragraph__branches_features = 'relationships___paragraph__branches_features',
  relationships___paragraph__branches_features___internal___content = 'relationships___paragraph__branches_features___internal___content',
  relationships___paragraph__branches_features___internal___contentDigest = 'relationships___paragraph__branches_features___internal___contentDigest',
  relationships___paragraph__branches_features___internal___description = 'relationships___paragraph__branches_features___internal___description',
  relationships___paragraph__branches_features___internal___fieldOwners = 'relationships___paragraph__branches_features___internal___fieldOwners',
  relationships___paragraph__branches_features___internal___ignoreType = 'relationships___paragraph__branches_features___internal___ignoreType',
  relationships___paragraph__branches_features___internal___mediaType = 'relationships___paragraph__branches_features___internal___mediaType',
  relationships___paragraph__branches_features___internal___owner = 'relationships___paragraph__branches_features___internal___owner',
  relationships___paragraph__branches_features___internal___type = 'relationships___paragraph__branches_features___internal___type',
  relationships___paragraph__branches_features___drupal_id = 'relationships___paragraph__branches_features___drupal_id',
  relationships___paragraph__branches_features___field_paragraph_headline___value = 'relationships___paragraph__branches_features___field_paragraph_headline___value',
  relationships___paragraph__branches_features___field_branches_text___value = 'relationships___paragraph__branches_features___field_branches_text___value',
  relationships___paragraph__branches_features___field_short_text_1___value = 'relationships___paragraph__branches_features___field_short_text_1___value',
  relationships___paragraph__branches_features___field_short_text_2___value = 'relationships___paragraph__branches_features___field_short_text_2___value',
  relationships___paragraph__branches_features___field_short_text_3___value = 'relationships___paragraph__branches_features___field_short_text_3___value',
  relationships___paragraph__branches_features___field_short_text_4___value = 'relationships___paragraph__branches_features___field_short_text_4___value',
  relationships___paragraph__branches_features___relationships___node__branches = 'relationships___paragraph__branches_features___relationships___node__branches',
  relationships___paragraph__branches_features___relationships___node__pages = 'relationships___paragraph__branches_features___relationships___node__pages',
  relationships___paragraph__branches_features___drupal_internal__id = 'relationships___paragraph__branches_features___drupal_internal__id',
  relationships___paragraph__branches_features___status = 'relationships___paragraph__branches_features___status',
  relationships___paragraph__branches_features___created = 'relationships___paragraph__branches_features___created',
  relationships___paragraph__branches_features___parent_id = 'relationships___paragraph__branches_features___parent_id',
  relationships___paragraph__branches_features___parent_type = 'relationships___paragraph__branches_features___parent_type',
  relationships___paragraph__branches_features___parent_field_name = 'relationships___paragraph__branches_features___parent_field_name',
  relationships___paragraph__branches_features___content_translation_source = 'relationships___paragraph__branches_features___content_translation_source',
  relationships___paragraph__branches_features___content_translation_outdated = 'relationships___paragraph__branches_features___content_translation_outdated',
  relationships___paragraph__branches_features___content_translation_changed = 'relationships___paragraph__branches_features___content_translation_changed',
  relationships___paragraph__branches_features___langcode = 'relationships___paragraph__branches_features___langcode',
  relationships___paragraph__branches_features___uuid = 'relationships___paragraph__branches_features___uuid',
  relationships___paragraph__branches_features___field_branches_image___alt = 'relationships___paragraph__branches_features___field_branches_image___alt',
  relationships___paragraph__branches_features___field_branches_image___title = 'relationships___paragraph__branches_features___field_branches_image___title',
  relationships___paragraph__branches_features___field_branches_image___width = 'relationships___paragraph__branches_features___field_branches_image___width',
  relationships___paragraph__branches_features___field_branches_image___height = 'relationships___paragraph__branches_features___field_branches_image___height',
  relationships___paragraph__branches_features___field_branches_image___drupal_internal__target_id = 'relationships___paragraph__branches_features___field_branches_image___drupal_internal__target_id',
  relationships___paragraph__branches_features___field_ico_1___alt = 'relationships___paragraph__branches_features___field_ico_1___alt',
  relationships___paragraph__branches_features___field_ico_1___title = 'relationships___paragraph__branches_features___field_ico_1___title',
  relationships___paragraph__branches_features___field_ico_1___width = 'relationships___paragraph__branches_features___field_ico_1___width',
  relationships___paragraph__branches_features___field_ico_1___height = 'relationships___paragraph__branches_features___field_ico_1___height',
  relationships___paragraph__branches_features___field_ico_1___drupal_internal__target_id = 'relationships___paragraph__branches_features___field_ico_1___drupal_internal__target_id',
  relationships___paragraph__branches_features___field_ico_2___alt = 'relationships___paragraph__branches_features___field_ico_2___alt',
  relationships___paragraph__branches_features___field_ico_2___title = 'relationships___paragraph__branches_features___field_ico_2___title',
  relationships___paragraph__branches_features___field_ico_2___width = 'relationships___paragraph__branches_features___field_ico_2___width',
  relationships___paragraph__branches_features___field_ico_2___height = 'relationships___paragraph__branches_features___field_ico_2___height',
  relationships___paragraph__branches_features___field_ico_2___drupal_internal__target_id = 'relationships___paragraph__branches_features___field_ico_2___drupal_internal__target_id',
  relationships___paragraph__branches_features___field_ico_3___alt = 'relationships___paragraph__branches_features___field_ico_3___alt',
  relationships___paragraph__branches_features___field_ico_3___title = 'relationships___paragraph__branches_features___field_ico_3___title',
  relationships___paragraph__branches_features___field_ico_3___width = 'relationships___paragraph__branches_features___field_ico_3___width',
  relationships___paragraph__branches_features___field_ico_3___height = 'relationships___paragraph__branches_features___field_ico_3___height',
  relationships___paragraph__branches_features___field_ico_3___drupal_internal__target_id = 'relationships___paragraph__branches_features___field_ico_3___drupal_internal__target_id',
  relationships___paragraph__branches_features___field_ico_4___alt = 'relationships___paragraph__branches_features___field_ico_4___alt',
  relationships___paragraph__branches_features___field_ico_4___title = 'relationships___paragraph__branches_features___field_ico_4___title',
  relationships___paragraph__branches_features___field_ico_4___width = 'relationships___paragraph__branches_features___field_ico_4___width',
  relationships___paragraph__branches_features___field_ico_4___height = 'relationships___paragraph__branches_features___field_ico_4___height',
  relationships___paragraph__branches_features___field_ico_4___drupal_internal__target_id = 'relationships___paragraph__branches_features___field_ico_4___drupal_internal__target_id',
  relationships___paragraph__branches_features___id = 'relationships___paragraph__branches_features___id',
  relationships___paragraph__branches_features___parent___id = 'relationships___paragraph__branches_features___parent___id',
  relationships___paragraph__branches_features___parent___children = 'relationships___paragraph__branches_features___parent___children',
  relationships___paragraph__branches_features___children = 'relationships___paragraph__branches_features___children',
  relationships___paragraph__branches_features___children___id = 'relationships___paragraph__branches_features___children___id',
  relationships___paragraph__branches_features___children___children = 'relationships___paragraph__branches_features___children___children',
  relationships___paragraph__branches_check_what_s_going_on = 'relationships___paragraph__branches_check_what_s_going_on',
  relationships___paragraph__branches_check_what_s_going_on___internal___content = 'relationships___paragraph__branches_check_what_s_going_on___internal___content',
  relationships___paragraph__branches_check_what_s_going_on___internal___contentDigest = 'relationships___paragraph__branches_check_what_s_going_on___internal___contentDigest',
  relationships___paragraph__branches_check_what_s_going_on___internal___description = 'relationships___paragraph__branches_check_what_s_going_on___internal___description',
  relationships___paragraph__branches_check_what_s_going_on___internal___fieldOwners = 'relationships___paragraph__branches_check_what_s_going_on___internal___fieldOwners',
  relationships___paragraph__branches_check_what_s_going_on___internal___ignoreType = 'relationships___paragraph__branches_check_what_s_going_on___internal___ignoreType',
  relationships___paragraph__branches_check_what_s_going_on___internal___mediaType = 'relationships___paragraph__branches_check_what_s_going_on___internal___mediaType',
  relationships___paragraph__branches_check_what_s_going_on___internal___owner = 'relationships___paragraph__branches_check_what_s_going_on___internal___owner',
  relationships___paragraph__branches_check_what_s_going_on___internal___type = 'relationships___paragraph__branches_check_what_s_going_on___internal___type',
  relationships___paragraph__branches_check_what_s_going_on___id = 'relationships___paragraph__branches_check_what_s_going_on___id',
  relationships___paragraph__branches_check_what_s_going_on___drupal_id = 'relationships___paragraph__branches_check_what_s_going_on___drupal_id',
  relationships___paragraph__branches_check_what_s_going_on___field_paragraph_headline___value = 'relationships___paragraph__branches_check_what_s_going_on___field_paragraph_headline___value',
  relationships___paragraph__branches_check_what_s_going_on___field_branches_text___value = 'relationships___paragraph__branches_check_what_s_going_on___field_branches_text___value',
  relationships___paragraph__branches_check_what_s_going_on___field_short_text_1___value = 'relationships___paragraph__branches_check_what_s_going_on___field_short_text_1___value',
  relationships___paragraph__branches_check_what_s_going_on___field_short_text_2___value = 'relationships___paragraph__branches_check_what_s_going_on___field_short_text_2___value',
  relationships___paragraph__branches_check_what_s_going_on___field_short_text_3___value = 'relationships___paragraph__branches_check_what_s_going_on___field_short_text_3___value',
  relationships___paragraph__branches_check_what_s_going_on___field_short_text_4___value = 'relationships___paragraph__branches_check_what_s_going_on___field_short_text_4___value',
  relationships___paragraph__branches_check_what_s_going_on___field_branches_button_text = 'relationships___paragraph__branches_check_what_s_going_on___field_branches_button_text',
  relationships___paragraph__branches_check_what_s_going_on___field_branches_image___alt = 'relationships___paragraph__branches_check_what_s_going_on___field_branches_image___alt',
  relationships___paragraph__branches_check_what_s_going_on___relationships___node__branches = 'relationships___paragraph__branches_check_what_s_going_on___relationships___node__branches',
  relationships___paragraph__branches_check_what_s_going_on___relationships___site_setting_entity__franchise = 'relationships___paragraph__branches_check_what_s_going_on___relationships___site_setting_entity__franchise',
  relationships___paragraph__branches_check_what_s_going_on___drupal_internal__id = 'relationships___paragraph__branches_check_what_s_going_on___drupal_internal__id',
  relationships___paragraph__branches_check_what_s_going_on___status = 'relationships___paragraph__branches_check_what_s_going_on___status',
  relationships___paragraph__branches_check_what_s_going_on___created = 'relationships___paragraph__branches_check_what_s_going_on___created',
  relationships___paragraph__branches_check_what_s_going_on___parent_id = 'relationships___paragraph__branches_check_what_s_going_on___parent_id',
  relationships___paragraph__branches_check_what_s_going_on___parent_type = 'relationships___paragraph__branches_check_what_s_going_on___parent_type',
  relationships___paragraph__branches_check_what_s_going_on___parent_field_name = 'relationships___paragraph__branches_check_what_s_going_on___parent_field_name',
  relationships___paragraph__branches_check_what_s_going_on___content_translation_source = 'relationships___paragraph__branches_check_what_s_going_on___content_translation_source',
  relationships___paragraph__branches_check_what_s_going_on___content_translation_outdated = 'relationships___paragraph__branches_check_what_s_going_on___content_translation_outdated',
  relationships___paragraph__branches_check_what_s_going_on___content_translation_changed = 'relationships___paragraph__branches_check_what_s_going_on___content_translation_changed',
  relationships___paragraph__branches_check_what_s_going_on___langcode = 'relationships___paragraph__branches_check_what_s_going_on___langcode',
  relationships___paragraph__branches_check_what_s_going_on___uuid = 'relationships___paragraph__branches_check_what_s_going_on___uuid',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_1___alt = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_1___alt',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_1___title = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_1___title',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_1___width = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_1___width',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_1___height = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_1___height',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_1___drupal_internal__target_id = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_1___drupal_internal__target_id',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_2___alt = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_2___alt',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_2___title = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_2___title',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_2___width = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_2___width',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_2___height = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_2___height',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_2___drupal_internal__target_id = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_2___drupal_internal__target_id',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_3___alt = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_3___alt',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_3___title = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_3___title',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_3___width = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_3___width',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_3___height = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_3___height',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_3___drupal_internal__target_id = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_3___drupal_internal__target_id',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_4___alt = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_4___alt',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_4___title = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_4___title',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_4___width = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_4___width',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_4___height = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_4___height',
  relationships___paragraph__branches_check_what_s_going_on___field_ico_4___drupal_internal__target_id = 'relationships___paragraph__branches_check_what_s_going_on___field_ico_4___drupal_internal__target_id',
  relationships___paragraph__branches_check_what_s_going_on___parent___id = 'relationships___paragraph__branches_check_what_s_going_on___parent___id',
  relationships___paragraph__branches_check_what_s_going_on___parent___children = 'relationships___paragraph__branches_check_what_s_going_on___parent___children',
  relationships___paragraph__branches_check_what_s_going_on___children = 'relationships___paragraph__branches_check_what_s_going_on___children',
  relationships___paragraph__branches_check_what_s_going_on___children___id = 'relationships___paragraph__branches_check_what_s_going_on___children___id',
  relationships___paragraph__branches_check_what_s_going_on___children___children = 'relationships___paragraph__branches_check_what_s_going_on___children___children',
  relationships___paragraph__branches_testimonials = 'relationships___paragraph__branches_testimonials',
  relationships___paragraph__branches_testimonials___internal___content = 'relationships___paragraph__branches_testimonials___internal___content',
  relationships___paragraph__branches_testimonials___internal___contentDigest = 'relationships___paragraph__branches_testimonials___internal___contentDigest',
  relationships___paragraph__branches_testimonials___internal___description = 'relationships___paragraph__branches_testimonials___internal___description',
  relationships___paragraph__branches_testimonials___internal___fieldOwners = 'relationships___paragraph__branches_testimonials___internal___fieldOwners',
  relationships___paragraph__branches_testimonials___internal___ignoreType = 'relationships___paragraph__branches_testimonials___internal___ignoreType',
  relationships___paragraph__branches_testimonials___internal___mediaType = 'relationships___paragraph__branches_testimonials___internal___mediaType',
  relationships___paragraph__branches_testimonials___internal___owner = 'relationships___paragraph__branches_testimonials___internal___owner',
  relationships___paragraph__branches_testimonials___internal___type = 'relationships___paragraph__branches_testimonials___internal___type',
  relationships___paragraph__branches_testimonials___id = 'relationships___paragraph__branches_testimonials___id',
  relationships___paragraph__branches_testimonials___drupal_id = 'relationships___paragraph__branches_testimonials___drupal_id',
  relationships___paragraph__branches_testimonials___field_nq_quotation___value = 'relationships___paragraph__branches_testimonials___field_nq_quotation___value',
  relationships___paragraph__branches_testimonials___relationships___node__branches = 'relationships___paragraph__branches_testimonials___relationships___node__branches',
  relationships___paragraph__branches_testimonials___drupal_internal__id = 'relationships___paragraph__branches_testimonials___drupal_internal__id',
  relationships___paragraph__branches_testimonials___status = 'relationships___paragraph__branches_testimonials___status',
  relationships___paragraph__branches_testimonials___created = 'relationships___paragraph__branches_testimonials___created',
  relationships___paragraph__branches_testimonials___parent_id = 'relationships___paragraph__branches_testimonials___parent_id',
  relationships___paragraph__branches_testimonials___parent_type = 'relationships___paragraph__branches_testimonials___parent_type',
  relationships___paragraph__branches_testimonials___parent_field_name = 'relationships___paragraph__branches_testimonials___parent_field_name',
  relationships___paragraph__branches_testimonials___content_translation_source = 'relationships___paragraph__branches_testimonials___content_translation_source',
  relationships___paragraph__branches_testimonials___content_translation_outdated = 'relationships___paragraph__branches_testimonials___content_translation_outdated',
  relationships___paragraph__branches_testimonials___content_translation_changed = 'relationships___paragraph__branches_testimonials___content_translation_changed',
  relationships___paragraph__branches_testimonials___field_branches_name = 'relationships___paragraph__branches_testimonials___field_branches_name',
  relationships___paragraph__branches_testimonials___field_paragraph_headline___value = 'relationships___paragraph__branches_testimonials___field_paragraph_headline___value',
  relationships___paragraph__branches_testimonials___field_paragraph_headline___format = 'relationships___paragraph__branches_testimonials___field_paragraph_headline___format',
  relationships___paragraph__branches_testimonials___field_paragraph_headline___processed = 'relationships___paragraph__branches_testimonials___field_paragraph_headline___processed',
  relationships___paragraph__branches_testimonials___langcode = 'relationships___paragraph__branches_testimonials___langcode',
  relationships___paragraph__branches_testimonials___uuid = 'relationships___paragraph__branches_testimonials___uuid',
  relationships___paragraph__branches_testimonials___field_branches_image___alt = 'relationships___paragraph__branches_testimonials___field_branches_image___alt',
  relationships___paragraph__branches_testimonials___field_branches_image___title = 'relationships___paragraph__branches_testimonials___field_branches_image___title',
  relationships___paragraph__branches_testimonials___field_branches_image___width = 'relationships___paragraph__branches_testimonials___field_branches_image___width',
  relationships___paragraph__branches_testimonials___field_branches_image___height = 'relationships___paragraph__branches_testimonials___field_branches_image___height',
  relationships___paragraph__branches_testimonials___field_branches_image___drupal_internal__target_id = 'relationships___paragraph__branches_testimonials___field_branches_image___drupal_internal__target_id',
  relationships___paragraph__branches_testimonials___parent___id = 'relationships___paragraph__branches_testimonials___parent___id',
  relationships___paragraph__branches_testimonials___parent___children = 'relationships___paragraph__branches_testimonials___parent___children',
  relationships___paragraph__branches_testimonials___children = 'relationships___paragraph__branches_testimonials___children',
  relationships___paragraph__branches_testimonials___children___id = 'relationships___paragraph__branches_testimonials___children___id',
  relationships___paragraph__branches_testimonials___children___children = 'relationships___paragraph__branches_testimonials___children___children',
  relationships___paragraph__branches_discounts = 'relationships___paragraph__branches_discounts',
  relationships___paragraph__branches_discounts___field_branches_text___value = 'relationships___paragraph__branches_discounts___field_branches_text___value',
  relationships___paragraph__branches_discounts___id = 'relationships___paragraph__branches_discounts___id',
  relationships___paragraph__branches_discounts___field_branches_button_text = 'relationships___paragraph__branches_discounts___field_branches_button_text',
  relationships___paragraph__branches_discounts___field_branches_image___alt = 'relationships___paragraph__branches_discounts___field_branches_image___alt',
  relationships___paragraph__branches_discounts___relationships___paragraph__discounts_container = 'relationships___paragraph__branches_discounts___relationships___paragraph__discounts_container',
  relationships___paragraph__branches_discounts___drupal_id = 'relationships___paragraph__branches_discounts___drupal_id',
  relationships___paragraph__branches_discounts___drupal_internal__id = 'relationships___paragraph__branches_discounts___drupal_internal__id',
  relationships___paragraph__branches_discounts___status = 'relationships___paragraph__branches_discounts___status',
  relationships___paragraph__branches_discounts___created = 'relationships___paragraph__branches_discounts___created',
  relationships___paragraph__branches_discounts___parent_id = 'relationships___paragraph__branches_discounts___parent_id',
  relationships___paragraph__branches_discounts___parent_type = 'relationships___paragraph__branches_discounts___parent_type',
  relationships___paragraph__branches_discounts___parent_field_name = 'relationships___paragraph__branches_discounts___parent_field_name',
  relationships___paragraph__branches_discounts___content_translation_source = 'relationships___paragraph__branches_discounts___content_translation_source',
  relationships___paragraph__branches_discounts___content_translation_outdated = 'relationships___paragraph__branches_discounts___content_translation_outdated',
  relationships___paragraph__branches_discounts___content_translation_changed = 'relationships___paragraph__branches_discounts___content_translation_changed',
  relationships___paragraph__branches_discounts___langcode = 'relationships___paragraph__branches_discounts___langcode',
  relationships___paragraph__branches_discounts___uuid = 'relationships___paragraph__branches_discounts___uuid',
  relationships___paragraph__branches_discounts___parent___id = 'relationships___paragraph__branches_discounts___parent___id',
  relationships___paragraph__branches_discounts___parent___children = 'relationships___paragraph__branches_discounts___parent___children',
  relationships___paragraph__branches_discounts___children = 'relationships___paragraph__branches_discounts___children',
  relationships___paragraph__branches_discounts___children___id = 'relationships___paragraph__branches_discounts___children___id',
  relationships___paragraph__branches_discounts___children___children = 'relationships___paragraph__branches_discounts___children___children',
  relationships___paragraph__branches_discounts___internal___content = 'relationships___paragraph__branches_discounts___internal___content',
  relationships___paragraph__branches_discounts___internal___contentDigest = 'relationships___paragraph__branches_discounts___internal___contentDigest',
  relationships___paragraph__branches_discounts___internal___description = 'relationships___paragraph__branches_discounts___internal___description',
  relationships___paragraph__branches_discounts___internal___fieldOwners = 'relationships___paragraph__branches_discounts___internal___fieldOwners',
  relationships___paragraph__branches_discounts___internal___ignoreType = 'relationships___paragraph__branches_discounts___internal___ignoreType',
  relationships___paragraph__branches_discounts___internal___mediaType = 'relationships___paragraph__branches_discounts___internal___mediaType',
  relationships___paragraph__branches_discounts___internal___owner = 'relationships___paragraph__branches_discounts___internal___owner',
  relationships___paragraph__branches_discounts___internal___type = 'relationships___paragraph__branches_discounts___internal___type',
  relationships___node__news = 'relationships___node__news',
  relationships___node__news___langcode = 'relationships___node__news___langcode',
  relationships___node__news___id = 'relationships___node__news___id',
  relationships___node__news___drupal_internal__nid = 'relationships___node__news___drupal_internal__nid',
  relationships___node__news___title = 'relationships___node__news___title',
  relationships___node__news___field_news_reading_time = 'relationships___node__news___field_news_reading_time',
  relationships___node__news___field_news_body = 'relationships___node__news___field_news_body',
  relationships___node__news___created = 'relationships___node__news___created',
  relationships___node__news___path___alias = 'relationships___node__news___path___alias',
  relationships___node__news___status = 'relationships___node__news___status',
  relationships___node__news___field_meta_description = 'relationships___node__news___field_meta_description',
  relationships___node__news___field_meta_title = 'relationships___node__news___field_meta_title',
  relationships___node__news___relationships___field_news_tags = 'relationships___node__news___relationships___field_news_tags',
  relationships___node__news___relationships___node__news = 'relationships___node__news___relationships___node__news',
  relationships___node__news___relationships___paragraph__recommended_blog_posts = 'relationships___node__news___relationships___paragraph__recommended_blog_posts',
  relationships___node__news___drupal_id = 'relationships___node__news___drupal_id',
  relationships___node__news___rh_redirect_fallback_action = 'relationships___node__news___rh_redirect_fallback_action',
  relationships___node__news___content_translation_source = 'relationships___node__news___content_translation_source',
  relationships___node__news___content_translation_outdated = 'relationships___node__news___content_translation_outdated',
  relationships___node__news___field_meta_keywords = 'relationships___node__news___field_meta_keywords',
  relationships___node__news___uuid = 'relationships___node__news___uuid',
  relationships___node__news___field_news_categories___drupal_internal__target_id = 'relationships___node__news___field_news_categories___drupal_internal__target_id',
  relationships___node__news___field_news_components = 'relationships___node__news___field_news_components',
  relationships___node__news___field_news_components___target_revision_id = 'relationships___node__news___field_news_components___target_revision_id',
  relationships___node__news___field_news_components___drupal_internal__target_id = 'relationships___node__news___field_news_components___drupal_internal__target_id',
  relationships___node__news___field_news_image___alt = 'relationships___node__news___field_news_image___alt',
  relationships___node__news___field_news_image___title = 'relationships___node__news___field_news_image___title',
  relationships___node__news___field_news_image___width = 'relationships___node__news___field_news_image___width',
  relationships___node__news___field_news_image___height = 'relationships___node__news___field_news_image___height',
  relationships___node__news___field_news_image___drupal_internal__target_id = 'relationships___node__news___field_news_image___drupal_internal__target_id',
  relationships___node__news___field_news_tags = 'relationships___node__news___field_news_tags',
  relationships___node__news___field_news_tags___drupal_internal__target_id = 'relationships___node__news___field_news_tags___drupal_internal__target_id',
  relationships___node__news___field_related_news_1___drupal_internal__target_id = 'relationships___node__news___field_related_news_1___drupal_internal__target_id',
  relationships___node__news___field_related_news_2___drupal_internal__target_id = 'relationships___node__news___field_related_news_2___drupal_internal__target_id',
  relationships___node__news___field_related_news_3___drupal_internal__target_id = 'relationships___node__news___field_related_news_3___drupal_internal__target_id',
  relationships___node__news___parent___id = 'relationships___node__news___parent___id',
  relationships___node__news___parent___children = 'relationships___node__news___parent___children',
  relationships___node__news___children = 'relationships___node__news___children',
  relationships___node__news___children___id = 'relationships___node__news___children___id',
  relationships___node__news___children___children = 'relationships___node__news___children___children',
  relationships___node__news___internal___content = 'relationships___node__news___internal___content',
  relationships___node__news___internal___contentDigest = 'relationships___node__news___internal___contentDigest',
  relationships___node__news___internal___description = 'relationships___node__news___internal___description',
  relationships___node__news___internal___fieldOwners = 'relationships___node__news___internal___fieldOwners',
  relationships___node__news___internal___ignoreType = 'relationships___node__news___internal___ignoreType',
  relationships___node__news___internal___mediaType = 'relationships___node__news___internal___mediaType',
  relationships___node__news___internal___owner = 'relationships___node__news___internal___owner',
  relationships___node__news___internal___type = 'relationships___node__news___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  relationships___paragraph__branches_hero = 'relationships___paragraph__branches_hero',
  relationships___paragraph__branches_hero___id = 'relationships___paragraph__branches_hero___id',
  relationships___paragraph__branches_hero___internal___content = 'relationships___paragraph__branches_hero___internal___content',
  relationships___paragraph__branches_hero___internal___contentDigest = 'relationships___paragraph__branches_hero___internal___contentDigest',
  relationships___paragraph__branches_hero___internal___description = 'relationships___paragraph__branches_hero___internal___description',
  relationships___paragraph__branches_hero___internal___fieldOwners = 'relationships___paragraph__branches_hero___internal___fieldOwners',
  relationships___paragraph__branches_hero___internal___ignoreType = 'relationships___paragraph__branches_hero___internal___ignoreType',
  relationships___paragraph__branches_hero___internal___mediaType = 'relationships___paragraph__branches_hero___internal___mediaType',
  relationships___paragraph__branches_hero___internal___owner = 'relationships___paragraph__branches_hero___internal___owner',
  relationships___paragraph__branches_hero___internal___type = 'relationships___paragraph__branches_hero___internal___type',
  relationships___paragraph__branches_hero___drupal_id = 'relationships___paragraph__branches_hero___drupal_id',
  relationships___paragraph__branches_hero___parent_field_name = 'relationships___paragraph__branches_hero___parent_field_name',
  relationships___paragraph__branches_hero___field_hero_1_video = 'relationships___paragraph__branches_hero___field_hero_1_video',
  relationships___paragraph__branches_hero___field_branches_text___value = 'relationships___paragraph__branches_hero___field_branches_text___value',
  relationships___paragraph__branches_hero___field_paragraph_headline___value = 'relationships___paragraph__branches_hero___field_paragraph_headline___value',
  relationships___paragraph__branches_hero___field_branches_button_text = 'relationships___paragraph__branches_hero___field_branches_button_text',
  relationships___paragraph__branches_hero___field_branches_image___alt = 'relationships___paragraph__branches_hero___field_branches_image___alt',
  relationships___paragraph__branches_hero___field_branches_image_mobile___alt = 'relationships___paragraph__branches_hero___field_branches_image_mobile___alt',
  relationships___paragraph__branches_hero___field_video_thumbnail___alt = 'relationships___paragraph__branches_hero___field_video_thumbnail___alt',
  relationships___paragraph__branches_hero___relationships___node__branches = 'relationships___paragraph__branches_hero___relationships___node__branches',
  relationships___paragraph__branches_hero___relationships___site_setting_entity__price_list = 'relationships___paragraph__branches_hero___relationships___site_setting_entity__price_list',
  relationships___paragraph__branches_hero___relationships___site_setting_entity__financing = 'relationships___paragraph__branches_hero___relationships___site_setting_entity__financing',
  relationships___paragraph__branches_hero___relationships___site_setting_entity__franchise = 'relationships___paragraph__branches_hero___relationships___site_setting_entity__franchise',
  relationships___paragraph__branches_hero___relationships___node__pages = 'relationships___paragraph__branches_hero___relationships___node__pages',
  relationships___paragraph__branches_hero___drupal_internal__id = 'relationships___paragraph__branches_hero___drupal_internal__id',
  relationships___paragraph__branches_hero___status = 'relationships___paragraph__branches_hero___status',
  relationships___paragraph__branches_hero___created = 'relationships___paragraph__branches_hero___created',
  relationships___paragraph__branches_hero___parent_id = 'relationships___paragraph__branches_hero___parent_id',
  relationships___paragraph__branches_hero___parent_type = 'relationships___paragraph__branches_hero___parent_type',
  relationships___paragraph__branches_hero___content_translation_source = 'relationships___paragraph__branches_hero___content_translation_source',
  relationships___paragraph__branches_hero___content_translation_outdated = 'relationships___paragraph__branches_hero___content_translation_outdated',
  relationships___paragraph__branches_hero___content_translation_changed = 'relationships___paragraph__branches_hero___content_translation_changed',
  relationships___paragraph__branches_hero___langcode = 'relationships___paragraph__branches_hero___langcode',
  relationships___paragraph__branches_hero___uuid = 'relationships___paragraph__branches_hero___uuid',
  relationships___paragraph__branches_hero___field_branches_device_image___alt = 'relationships___paragraph__branches_hero___field_branches_device_image___alt',
  relationships___paragraph__branches_hero___field_branches_device_image___title = 'relationships___paragraph__branches_hero___field_branches_device_image___title',
  relationships___paragraph__branches_hero___field_branches_device_image___width = 'relationships___paragraph__branches_hero___field_branches_device_image___width',
  relationships___paragraph__branches_hero___field_branches_device_image___height = 'relationships___paragraph__branches_hero___field_branches_device_image___height',
  relationships___paragraph__branches_hero___field_branches_device_image___drupal_internal__target_id = 'relationships___paragraph__branches_hero___field_branches_device_image___drupal_internal__target_id',
  relationships___paragraph__branches_hero___parent___id = 'relationships___paragraph__branches_hero___parent___id',
  relationships___paragraph__branches_hero___parent___children = 'relationships___paragraph__branches_hero___parent___children',
  relationships___paragraph__branches_hero___children = 'relationships___paragraph__branches_hero___children',
  relationships___paragraph__branches_hero___children___id = 'relationships___paragraph__branches_hero___children___id',
  relationships___paragraph__branches_hero___children___children = 'relationships___paragraph__branches_hero___children___children',
  relationships___paragraph__bullets = 'relationships___paragraph__bullets',
  relationships___paragraph__bullets___internal___content = 'relationships___paragraph__bullets___internal___content',
  relationships___paragraph__bullets___internal___contentDigest = 'relationships___paragraph__bullets___internal___contentDigest',
  relationships___paragraph__bullets___internal___description = 'relationships___paragraph__bullets___internal___description',
  relationships___paragraph__bullets___internal___fieldOwners = 'relationships___paragraph__bullets___internal___fieldOwners',
  relationships___paragraph__bullets___internal___ignoreType = 'relationships___paragraph__bullets___internal___ignoreType',
  relationships___paragraph__bullets___internal___mediaType = 'relationships___paragraph__bullets___internal___mediaType',
  relationships___paragraph__bullets___internal___owner = 'relationships___paragraph__bullets___internal___owner',
  relationships___paragraph__bullets___internal___type = 'relationships___paragraph__bullets___internal___type',
  relationships___paragraph__bullets___field_nc_button_text = 'relationships___paragraph__bullets___field_nc_button_text',
  relationships___paragraph__bullets___field_btn_url = 'relationships___paragraph__bullets___field_btn_url',
  relationships___paragraph__bullets___field_text___value = 'relationships___paragraph__bullets___field_text___value',
  relationships___paragraph__bullets___field_video = 'relationships___paragraph__bullets___field_video',
  relationships___paragraph__bullets___field_image___alt = 'relationships___paragraph__bullets___field_image___alt',
  relationships___paragraph__bullets___field_bullets_icons = 'relationships___paragraph__bullets___field_bullets_icons',
  relationships___paragraph__bullets___field_bullets_icons___alt = 'relationships___paragraph__bullets___field_bullets_icons___alt',
  relationships___paragraph__bullets___relationships___field_bullets_icons = 'relationships___paragraph__bullets___relationships___field_bullets_icons',
  relationships___paragraph__bullets___relationships___paragraph__bullets_container = 'relationships___paragraph__bullets___relationships___paragraph__bullets_container',
  relationships___paragraph__bullets___relationships___site_setting_entity__accessories = 'relationships___paragraph__bullets___relationships___site_setting_entity__accessories',
  relationships___paragraph__bullets___drupal_id = 'relationships___paragraph__bullets___drupal_id',
  relationships___paragraph__bullets___drupal_internal__id = 'relationships___paragraph__bullets___drupal_internal__id',
  relationships___paragraph__bullets___status = 'relationships___paragraph__bullets___status',
  relationships___paragraph__bullets___created = 'relationships___paragraph__bullets___created',
  relationships___paragraph__bullets___parent_id = 'relationships___paragraph__bullets___parent_id',
  relationships___paragraph__bullets___parent_type = 'relationships___paragraph__bullets___parent_type',
  relationships___paragraph__bullets___parent_field_name = 'relationships___paragraph__bullets___parent_field_name',
  relationships___paragraph__bullets___content_translation_source = 'relationships___paragraph__bullets___content_translation_source',
  relationships___paragraph__bullets___content_translation_outdated = 'relationships___paragraph__bullets___content_translation_outdated',
  relationships___paragraph__bullets___content_translation_changed = 'relationships___paragraph__bullets___content_translation_changed',
  relationships___paragraph__bullets___langcode = 'relationships___paragraph__bullets___langcode',
  relationships___paragraph__bullets___uuid = 'relationships___paragraph__bullets___uuid',
  relationships___paragraph__bullets___id = 'relationships___paragraph__bullets___id',
  relationships___paragraph__bullets___parent___id = 'relationships___paragraph__bullets___parent___id',
  relationships___paragraph__bullets___parent___children = 'relationships___paragraph__bullets___parent___children',
  relationships___paragraph__bullets___children = 'relationships___paragraph__bullets___children',
  relationships___paragraph__bullets___children___id = 'relationships___paragraph__bullets___children___id',
  relationships___paragraph__bullets___children___children = 'relationships___paragraph__bullets___children___children',
  relationships___taxonomy_term__pricelist_group = 'relationships___taxonomy_term__pricelist_group',
  relationships___taxonomy_term__pricelist_group___langcode = 'relationships___taxonomy_term__pricelist_group___langcode',
  relationships___taxonomy_term__pricelist_group___drupal_id = 'relationships___taxonomy_term__pricelist_group___drupal_id',
  relationships___taxonomy_term__pricelist_group___name = 'relationships___taxonomy_term__pricelist_group___name',
  relationships___taxonomy_term__pricelist_group___description___value = 'relationships___taxonomy_term__pricelist_group___description___value',
  relationships___taxonomy_term__pricelist_group___field_discount = 'relationships___taxonomy_term__pricelist_group___field_discount',
  relationships___taxonomy_term__pricelist_group___field_name_in_api = 'relationships___taxonomy_term__pricelist_group___field_name_in_api',
  relationships___taxonomy_term__pricelist_group___field_name_in_cms = 'relationships___taxonomy_term__pricelist_group___field_name_in_cms',
  relationships___taxonomy_term__pricelist_group___field_isperproduct = 'relationships___taxonomy_term__pricelist_group___field_isperproduct',
  relationships___taxonomy_term__pricelist_group___field_maxfundedproducts = 'relationships___taxonomy_term__pricelist_group___field_maxfundedproducts',
  relationships___taxonomy_term__pricelist_group___field_publish = 'relationships___taxonomy_term__pricelist_group___field_publish',
  relationships___taxonomy_term__pricelist_group___field_text_when_unpublished___value = 'relationships___taxonomy_term__pricelist_group___field_text_when_unpublished___value',
  relationships___taxonomy_term__pricelist_group___relationships___node__price_list = 'relationships___taxonomy_term__pricelist_group___relationships___node__price_list',
  relationships___taxonomy_term__pricelist_group___relationships___paragraph__profit_reference_to_price_list = 'relationships___taxonomy_term__pricelist_group___relationships___paragraph__profit_reference_to_price_list',
  relationships___taxonomy_term__pricelist_group___field_is_discount = 'relationships___taxonomy_term__pricelist_group___field_is_discount',
  relationships___taxonomy_term__pricelist_group___field_side_panel_info = 'relationships___taxonomy_term__pricelist_group___field_side_panel_info',
  relationships___taxonomy_term__pricelist_group___field_is_default = 'relationships___taxonomy_term__pricelist_group___field_is_default',
  relationships___taxonomy_term__pricelist_group___drupal_internal__tid = 'relationships___taxonomy_term__pricelist_group___drupal_internal__tid',
  relationships___taxonomy_term__pricelist_group___drupal_internal__revision_id = 'relationships___taxonomy_term__pricelist_group___drupal_internal__revision_id',
  relationships___taxonomy_term__pricelist_group___revision_created = 'relationships___taxonomy_term__pricelist_group___revision_created',
  relationships___taxonomy_term__pricelist_group___status = 'relationships___taxonomy_term__pricelist_group___status',
  relationships___taxonomy_term__pricelist_group___weight = 'relationships___taxonomy_term__pricelist_group___weight',
  relationships___taxonomy_term__pricelist_group___changed = 'relationships___taxonomy_term__pricelist_group___changed',
  relationships___taxonomy_term__pricelist_group___default_langcode = 'relationships___taxonomy_term__pricelist_group___default_langcode',
  relationships___taxonomy_term__pricelist_group___revision_translation_affected = 'relationships___taxonomy_term__pricelist_group___revision_translation_affected',
  relationships___taxonomy_term__pricelist_group___content_translation_source = 'relationships___taxonomy_term__pricelist_group___content_translation_source',
  relationships___taxonomy_term__pricelist_group___content_translation_outdated = 'relationships___taxonomy_term__pricelist_group___content_translation_outdated',
  relationships___taxonomy_term__pricelist_group___content_translation_created = 'relationships___taxonomy_term__pricelist_group___content_translation_created',
  relationships___taxonomy_term__pricelist_group___field_coupon_in_api = 'relationships___taxonomy_term__pricelist_group___field_coupon_in_api',
  relationships___taxonomy_term__pricelist_group___uuid = 'relationships___taxonomy_term__pricelist_group___uuid',
  relationships___taxonomy_term__pricelist_group___vid___drupal_internal__target_id = 'relationships___taxonomy_term__pricelist_group___vid___drupal_internal__target_id',
  relationships___taxonomy_term__pricelist_group___field_pg_image___alt = 'relationships___taxonomy_term__pricelist_group___field_pg_image___alt',
  relationships___taxonomy_term__pricelist_group___field_pg_image___title = 'relationships___taxonomy_term__pricelist_group___field_pg_image___title',
  relationships___taxonomy_term__pricelist_group___field_pg_image___width = 'relationships___taxonomy_term__pricelist_group___field_pg_image___width',
  relationships___taxonomy_term__pricelist_group___field_pg_image___height = 'relationships___taxonomy_term__pricelist_group___field_pg_image___height',
  relationships___taxonomy_term__pricelist_group___field_pg_image___drupal_internal__target_id = 'relationships___taxonomy_term__pricelist_group___field_pg_image___drupal_internal__target_id',
  relationships___taxonomy_term__pricelist_group___id = 'relationships___taxonomy_term__pricelist_group___id',
  relationships___taxonomy_term__pricelist_group___parent___id = 'relationships___taxonomy_term__pricelist_group___parent___id',
  relationships___taxonomy_term__pricelist_group___parent___children = 'relationships___taxonomy_term__pricelist_group___parent___children',
  relationships___taxonomy_term__pricelist_group___children = 'relationships___taxonomy_term__pricelist_group___children',
  relationships___taxonomy_term__pricelist_group___children___id = 'relationships___taxonomy_term__pricelist_group___children___id',
  relationships___taxonomy_term__pricelist_group___children___children = 'relationships___taxonomy_term__pricelist_group___children___children',
  relationships___taxonomy_term__pricelist_group___internal___content = 'relationships___taxonomy_term__pricelist_group___internal___content',
  relationships___taxonomy_term__pricelist_group___internal___contentDigest = 'relationships___taxonomy_term__pricelist_group___internal___contentDigest',
  relationships___taxonomy_term__pricelist_group___internal___description = 'relationships___taxonomy_term__pricelist_group___internal___description',
  relationships___taxonomy_term__pricelist_group___internal___fieldOwners = 'relationships___taxonomy_term__pricelist_group___internal___fieldOwners',
  relationships___taxonomy_term__pricelist_group___internal___ignoreType = 'relationships___taxonomy_term__pricelist_group___internal___ignoreType',
  relationships___taxonomy_term__pricelist_group___internal___mediaType = 'relationships___taxonomy_term__pricelist_group___internal___mediaType',
  relationships___taxonomy_term__pricelist_group___internal___owner = 'relationships___taxonomy_term__pricelist_group___internal___owner',
  relationships___taxonomy_term__pricelist_group___internal___type = 'relationships___taxonomy_term__pricelist_group___internal___type',
  relationships___paragraph__branches_device = 'relationships___paragraph__branches_device',
  relationships___paragraph__branches_device___field_branches_name = 'relationships___paragraph__branches_device___field_branches_name',
  relationships___paragraph__branches_device___field_recommended = 'relationships___paragraph__branches_device___field_recommended',
  relationships___paragraph__branches_device___field_button_text_recommended = 'relationships___paragraph__branches_device___field_button_text_recommended',
  relationships___paragraph__branches_device___relationships___field_branches_profits = 'relationships___paragraph__branches_device___relationships___field_branches_profits',
  relationships___paragraph__branches_device___relationships___paragraph__branches_recommended_devices = 'relationships___paragraph__branches_device___relationships___paragraph__branches_recommended_devices',
  relationships___paragraph__branches_device___drupal_id = 'relationships___paragraph__branches_device___drupal_id',
  relationships___paragraph__branches_device___drupal_internal__id = 'relationships___paragraph__branches_device___drupal_internal__id',
  relationships___paragraph__branches_device___status = 'relationships___paragraph__branches_device___status',
  relationships___paragraph__branches_device___created = 'relationships___paragraph__branches_device___created',
  relationships___paragraph__branches_device___parent_id = 'relationships___paragraph__branches_device___parent_id',
  relationships___paragraph__branches_device___parent_type = 'relationships___paragraph__branches_device___parent_type',
  relationships___paragraph__branches_device___parent_field_name = 'relationships___paragraph__branches_device___parent_field_name',
  relationships___paragraph__branches_device___content_translation_source = 'relationships___paragraph__branches_device___content_translation_source',
  relationships___paragraph__branches_device___content_translation_outdated = 'relationships___paragraph__branches_device___content_translation_outdated',
  relationships___paragraph__branches_device___field_nc_button_text = 'relationships___paragraph__branches_device___field_nc_button_text',
  relationships___paragraph__branches_device___langcode = 'relationships___paragraph__branches_device___langcode',
  relationships___paragraph__branches_device___uuid = 'relationships___paragraph__branches_device___uuid',
  relationships___paragraph__branches_device___field_branches_image___alt = 'relationships___paragraph__branches_device___field_branches_image___alt',
  relationships___paragraph__branches_device___field_branches_image___title = 'relationships___paragraph__branches_device___field_branches_image___title',
  relationships___paragraph__branches_device___field_branches_image___width = 'relationships___paragraph__branches_device___field_branches_image___width',
  relationships___paragraph__branches_device___field_branches_image___height = 'relationships___paragraph__branches_device___field_branches_image___height',
  relationships___paragraph__branches_device___field_branches_image___drupal_internal__target_id = 'relationships___paragraph__branches_device___field_branches_image___drupal_internal__target_id',
  relationships___paragraph__branches_device___field_branches_profits = 'relationships___paragraph__branches_device___field_branches_profits',
  relationships___paragraph__branches_device___field_branches_profits___target_revision_id = 'relationships___paragraph__branches_device___field_branches_profits___target_revision_id',
  relationships___paragraph__branches_device___field_branches_profits___drupal_internal__target_id = 'relationships___paragraph__branches_device___field_branches_profits___drupal_internal__target_id',
  relationships___paragraph__branches_device___id = 'relationships___paragraph__branches_device___id',
  relationships___paragraph__branches_device___parent___id = 'relationships___paragraph__branches_device___parent___id',
  relationships___paragraph__branches_device___parent___children = 'relationships___paragraph__branches_device___parent___children',
  relationships___paragraph__branches_device___children = 'relationships___paragraph__branches_device___children',
  relationships___paragraph__branches_device___children___id = 'relationships___paragraph__branches_device___children___id',
  relationships___paragraph__branches_device___children___children = 'relationships___paragraph__branches_device___children___children',
  relationships___paragraph__branches_device___internal___content = 'relationships___paragraph__branches_device___internal___content',
  relationships___paragraph__branches_device___internal___contentDigest = 'relationships___paragraph__branches_device___internal___contentDigest',
  relationships___paragraph__branches_device___internal___description = 'relationships___paragraph__branches_device___internal___description',
  relationships___paragraph__branches_device___internal___fieldOwners = 'relationships___paragraph__branches_device___internal___fieldOwners',
  relationships___paragraph__branches_device___internal___ignoreType = 'relationships___paragraph__branches_device___internal___ignoreType',
  relationships___paragraph__branches_device___internal___mediaType = 'relationships___paragraph__branches_device___internal___mediaType',
  relationships___paragraph__branches_device___internal___owner = 'relationships___paragraph__branches_device___internal___owner',
  relationships___paragraph__branches_device___internal___type = 'relationships___paragraph__branches_device___internal___type',
  relationships___paragraph__branches_recommendation = 'relationships___paragraph__branches_recommendation',
  relationships___paragraph__branches_recommendation___id = 'relationships___paragraph__branches_recommendation___id',
  relationships___paragraph__branches_recommendation___internal___content = 'relationships___paragraph__branches_recommendation___internal___content',
  relationships___paragraph__branches_recommendation___internal___contentDigest = 'relationships___paragraph__branches_recommendation___internal___contentDigest',
  relationships___paragraph__branches_recommendation___internal___description = 'relationships___paragraph__branches_recommendation___internal___description',
  relationships___paragraph__branches_recommendation___internal___fieldOwners = 'relationships___paragraph__branches_recommendation___internal___fieldOwners',
  relationships___paragraph__branches_recommendation___internal___ignoreType = 'relationships___paragraph__branches_recommendation___internal___ignoreType',
  relationships___paragraph__branches_recommendation___internal___mediaType = 'relationships___paragraph__branches_recommendation___internal___mediaType',
  relationships___paragraph__branches_recommendation___internal___owner = 'relationships___paragraph__branches_recommendation___internal___owner',
  relationships___paragraph__branches_recommendation___internal___type = 'relationships___paragraph__branches_recommendation___internal___type',
  relationships___paragraph__branches_recommendation___drupal_id = 'relationships___paragraph__branches_recommendation___drupal_id',
  relationships___paragraph__branches_recommendation___field_branches_image___alt = 'relationships___paragraph__branches_recommendation___field_branches_image___alt',
  relationships___paragraph__branches_recommendation___field_branches_text___value = 'relationships___paragraph__branches_recommendation___field_branches_text___value',
  relationships___paragraph__branches_recommendation___field_is_online = 'relationships___paragraph__branches_recommendation___field_is_online',
  relationships___paragraph__branches_recommendation___field_is_online_text = 'relationships___paragraph__branches_recommendation___field_is_online_text',
  relationships___paragraph__branches_recommendation___field_nc_button_text = 'relationships___paragraph__branches_recommendation___field_nc_button_text',
  relationships___paragraph__branches_recommendation___field_paragraph_headline___value = 'relationships___paragraph__branches_recommendation___field_paragraph_headline___value',
  relationships___paragraph__branches_recommendation___field_url = 'relationships___paragraph__branches_recommendation___field_url',
  relationships___paragraph__branches_recommendation___relationships___node__branches = 'relationships___paragraph__branches_recommendation___relationships___node__branches',
  relationships___paragraph__branches_recommendation___relationships___site_setting_entity__franchise = 'relationships___paragraph__branches_recommendation___relationships___site_setting_entity__franchise',
  relationships___paragraph__branches_recommendation___relationships___site_setting_entity__main_page = 'relationships___paragraph__branches_recommendation___relationships___site_setting_entity__main_page',
  relationships___paragraph__branches_recommendation___drupal_internal__id = 'relationships___paragraph__branches_recommendation___drupal_internal__id',
  relationships___paragraph__branches_recommendation___status = 'relationships___paragraph__branches_recommendation___status',
  relationships___paragraph__branches_recommendation___created = 'relationships___paragraph__branches_recommendation___created',
  relationships___paragraph__branches_recommendation___parent_id = 'relationships___paragraph__branches_recommendation___parent_id',
  relationships___paragraph__branches_recommendation___parent_type = 'relationships___paragraph__branches_recommendation___parent_type',
  relationships___paragraph__branches_recommendation___parent_field_name = 'relationships___paragraph__branches_recommendation___parent_field_name',
  relationships___paragraph__branches_recommendation___content_translation_source = 'relationships___paragraph__branches_recommendation___content_translation_source',
  relationships___paragraph__branches_recommendation___content_translation_outdated = 'relationships___paragraph__branches_recommendation___content_translation_outdated',
  relationships___paragraph__branches_recommendation___content_translation_changed = 'relationships___paragraph__branches_recommendation___content_translation_changed',
  relationships___paragraph__branches_recommendation___langcode = 'relationships___paragraph__branches_recommendation___langcode',
  relationships___paragraph__branches_recommendation___uuid = 'relationships___paragraph__branches_recommendation___uuid',
  relationships___paragraph__branches_recommendation___parent___id = 'relationships___paragraph__branches_recommendation___parent___id',
  relationships___paragraph__branches_recommendation___parent___children = 'relationships___paragraph__branches_recommendation___parent___children',
  relationships___paragraph__branches_recommendation___children = 'relationships___paragraph__branches_recommendation___children',
  relationships___paragraph__branches_recommendation___children___id = 'relationships___paragraph__branches_recommendation___children___id',
  relationships___paragraph__branches_recommendation___children___children = 'relationships___paragraph__branches_recommendation___children___children',
  relationships___site_setting_entity__accessories = 'relationships___site_setting_entity__accessories',
  relationships___site_setting_entity__accessories___langcode = 'relationships___site_setting_entity__accessories___langcode',
  relationships___site_setting_entity__accessories___field_meta_description = 'relationships___site_setting_entity__accessories___field_meta_description',
  relationships___site_setting_entity__accessories___field_meta_title = 'relationships___site_setting_entity__accessories___field_meta_title',
  relationships___site_setting_entity__accessories___drupal_id = 'relationships___site_setting_entity__accessories___drupal_id',
  relationships___site_setting_entity__accessories___field_hero_button_text = 'relationships___site_setting_entity__accessories___field_hero_button_text',
  relationships___site_setting_entity__accessories___field_hero_headline___value = 'relationships___site_setting_entity__accessories___field_hero_headline___value',
  relationships___site_setting_entity__accessories___field_hero_text___value = 'relationships___site_setting_entity__accessories___field_hero_text___value',
  relationships___site_setting_entity__accessories___drupal_internal__id = 'relationships___site_setting_entity__accessories___drupal_internal__id',
  relationships___site_setting_entity__accessories___name = 'relationships___site_setting_entity__accessories___name',
  relationships___site_setting_entity__accessories___fieldset = 'relationships___site_setting_entity__accessories___fieldset',
  relationships___site_setting_entity__accessories___status = 'relationships___site_setting_entity__accessories___status',
  relationships___site_setting_entity__accessories___created = 'relationships___site_setting_entity__accessories___created',
  relationships___site_setting_entity__accessories___changed = 'relationships___site_setting_entity__accessories___changed',
  relationships___site_setting_entity__accessories___default_langcode = 'relationships___site_setting_entity__accessories___default_langcode',
  relationships___site_setting_entity__accessories___content_translation_source = 'relationships___site_setting_entity__accessories___content_translation_source',
  relationships___site_setting_entity__accessories___content_translation_outdated = 'relationships___site_setting_entity__accessories___content_translation_outdated',
  relationships___site_setting_entity__accessories___content_translation_status = 'relationships___site_setting_entity__accessories___content_translation_status',
  relationships___site_setting_entity__accessories___content_translation_created = 'relationships___site_setting_entity__accessories___content_translation_created',
  relationships___site_setting_entity__accessories___content_translation_changed = 'relationships___site_setting_entity__accessories___content_translation_changed',
  relationships___site_setting_entity__accessories___uuid = 'relationships___site_setting_entity__accessories___uuid',
  relationships___site_setting_entity__accessories___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_components = 'relationships___site_setting_entity__accessories___field_components',
  relationships___site_setting_entity__accessories___field_components___target_revision_id = 'relationships___site_setting_entity__accessories___field_components___target_revision_id',
  relationships___site_setting_entity__accessories___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_hero_image___alt = 'relationships___site_setting_entity__accessories___field_hero_image___alt',
  relationships___site_setting_entity__accessories___field_hero_image___title = 'relationships___site_setting_entity__accessories___field_hero_image___title',
  relationships___site_setting_entity__accessories___field_hero_image___width = 'relationships___site_setting_entity__accessories___field_hero_image___width',
  relationships___site_setting_entity__accessories___field_hero_image___height = 'relationships___site_setting_entity__accessories___field_hero_image___height',
  relationships___site_setting_entity__accessories___field_hero_image___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_hero_image___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___alt = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___alt',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___title = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___title',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___width = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___width',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___height = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___height',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___id = 'relationships___site_setting_entity__accessories___id',
  relationships___site_setting_entity__accessories___parent___id = 'relationships___site_setting_entity__accessories___parent___id',
  relationships___site_setting_entity__accessories___parent___children = 'relationships___site_setting_entity__accessories___parent___children',
  relationships___site_setting_entity__accessories___children = 'relationships___site_setting_entity__accessories___children',
  relationships___site_setting_entity__accessories___children___id = 'relationships___site_setting_entity__accessories___children___id',
  relationships___site_setting_entity__accessories___children___children = 'relationships___site_setting_entity__accessories___children___children',
  relationships___site_setting_entity__accessories___internal___content = 'relationships___site_setting_entity__accessories___internal___content',
  relationships___site_setting_entity__accessories___internal___contentDigest = 'relationships___site_setting_entity__accessories___internal___contentDigest',
  relationships___site_setting_entity__accessories___internal___description = 'relationships___site_setting_entity__accessories___internal___description',
  relationships___site_setting_entity__accessories___internal___fieldOwners = 'relationships___site_setting_entity__accessories___internal___fieldOwners',
  relationships___site_setting_entity__accessories___internal___ignoreType = 'relationships___site_setting_entity__accessories___internal___ignoreType',
  relationships___site_setting_entity__accessories___internal___mediaType = 'relationships___site_setting_entity__accessories___internal___mediaType',
  relationships___site_setting_entity__accessories___internal___owner = 'relationships___site_setting_entity__accessories___internal___owner',
  relationships___site_setting_entity__accessories___internal___type = 'relationships___site_setting_entity__accessories___internal___type',
  relationships___site_setting_entity__branches = 'relationships___site_setting_entity__branches',
  relationships___site_setting_entity__branches___langcode = 'relationships___site_setting_entity__branches___langcode',
  relationships___site_setting_entity__branches___field_hero_image___alt = 'relationships___site_setting_entity__branches___field_hero_image___alt',
  relationships___site_setting_entity__branches___field_hero_headline___value = 'relationships___site_setting_entity__branches___field_hero_headline___value',
  relationships___site_setting_entity__branches___field_hero_button_text = 'relationships___site_setting_entity__branches___field_hero_button_text',
  relationships___site_setting_entity__branches___field_button_text_1 = 'relationships___site_setting_entity__branches___field_button_text_1',
  relationships___site_setting_entity__branches___field_button_text_2 = 'relationships___site_setting_entity__branches___field_button_text_2',
  relationships___site_setting_entity__branches___field_hero_text___value = 'relationships___site_setting_entity__branches___field_hero_text___value',
  relationships___site_setting_entity__branches___field_text_1___value = 'relationships___site_setting_entity__branches___field_text_1___value',
  relationships___site_setting_entity__branches___field_text_2___value = 'relationships___site_setting_entity__branches___field_text_2___value',
  relationships___site_setting_entity__branches___field_text_3___value = 'relationships___site_setting_entity__branches___field_text_3___value',
  relationships___site_setting_entity__branches___field_meta_description = 'relationships___site_setting_entity__branches___field_meta_description',
  relationships___site_setting_entity__branches___field_meta_title = 'relationships___site_setting_entity__branches___field_meta_title',
  relationships___site_setting_entity__branches___drupal_id = 'relationships___site_setting_entity__branches___drupal_id',
  relationships___site_setting_entity__branches___drupal_internal__id = 'relationships___site_setting_entity__branches___drupal_internal__id',
  relationships___site_setting_entity__branches___name = 'relationships___site_setting_entity__branches___name',
  relationships___site_setting_entity__branches___fieldset = 'relationships___site_setting_entity__branches___fieldset',
  relationships___site_setting_entity__branches___status = 'relationships___site_setting_entity__branches___status',
  relationships___site_setting_entity__branches___created = 'relationships___site_setting_entity__branches___created',
  relationships___site_setting_entity__branches___changed = 'relationships___site_setting_entity__branches___changed',
  relationships___site_setting_entity__branches___default_langcode = 'relationships___site_setting_entity__branches___default_langcode',
  relationships___site_setting_entity__branches___content_translation_source = 'relationships___site_setting_entity__branches___content_translation_source',
  relationships___site_setting_entity__branches___content_translation_outdated = 'relationships___site_setting_entity__branches___content_translation_outdated',
  relationships___site_setting_entity__branches___content_translation_status = 'relationships___site_setting_entity__branches___content_translation_status',
  relationships___site_setting_entity__branches___content_translation_created = 'relationships___site_setting_entity__branches___content_translation_created',
  relationships___site_setting_entity__branches___content_translation_changed = 'relationships___site_setting_entity__branches___content_translation_changed',
  relationships___site_setting_entity__branches___uuid = 'relationships___site_setting_entity__branches___uuid',
  relationships___site_setting_entity__branches___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__branches___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__branches___field_hero_image_mobile___alt = 'relationships___site_setting_entity__branches___field_hero_image_mobile___alt',
  relationships___site_setting_entity__branches___field_hero_image_mobile___title = 'relationships___site_setting_entity__branches___field_hero_image_mobile___title',
  relationships___site_setting_entity__branches___field_hero_image_mobile___width = 'relationships___site_setting_entity__branches___field_hero_image_mobile___width',
  relationships___site_setting_entity__branches___field_hero_image_mobile___height = 'relationships___site_setting_entity__branches___field_hero_image_mobile___height',
  relationships___site_setting_entity__branches___field_hero_image_mobile___drupal_internal__target_id = 'relationships___site_setting_entity__branches___field_hero_image_mobile___drupal_internal__target_id',
  relationships___site_setting_entity__branches___id = 'relationships___site_setting_entity__branches___id',
  relationships___site_setting_entity__branches___parent___id = 'relationships___site_setting_entity__branches___parent___id',
  relationships___site_setting_entity__branches___parent___children = 'relationships___site_setting_entity__branches___parent___children',
  relationships___site_setting_entity__branches___children = 'relationships___site_setting_entity__branches___children',
  relationships___site_setting_entity__branches___children___id = 'relationships___site_setting_entity__branches___children___id',
  relationships___site_setting_entity__branches___children___children = 'relationships___site_setting_entity__branches___children___children',
  relationships___site_setting_entity__branches___internal___content = 'relationships___site_setting_entity__branches___internal___content',
  relationships___site_setting_entity__branches___internal___contentDigest = 'relationships___site_setting_entity__branches___internal___contentDigest',
  relationships___site_setting_entity__branches___internal___description = 'relationships___site_setting_entity__branches___internal___description',
  relationships___site_setting_entity__branches___internal___fieldOwners = 'relationships___site_setting_entity__branches___internal___fieldOwners',
  relationships___site_setting_entity__branches___internal___ignoreType = 'relationships___site_setting_entity__branches___internal___ignoreType',
  relationships___site_setting_entity__branches___internal___mediaType = 'relationships___site_setting_entity__branches___internal___mediaType',
  relationships___site_setting_entity__branches___internal___owner = 'relationships___site_setting_entity__branches___internal___owner',
  relationships___site_setting_entity__branches___internal___type = 'relationships___site_setting_entity__branches___internal___type',
  relationships___site_setting_entity__about_us = 'relationships___site_setting_entity__about_us',
  relationships___site_setting_entity__about_us___langcode = 'relationships___site_setting_entity__about_us___langcode',
  relationships___site_setting_entity__about_us___field_meta_description = 'relationships___site_setting_entity__about_us___field_meta_description',
  relationships___site_setting_entity__about_us___field_meta_title = 'relationships___site_setting_entity__about_us___field_meta_title',
  relationships___site_setting_entity__about_us___field_btn_au_text_1 = 'relationships___site_setting_entity__about_us___field_btn_au_text_1',
  relationships___site_setting_entity__about_us___field_btn_au_url_1 = 'relationships___site_setting_entity__about_us___field_btn_au_url_1',
  relationships___site_setting_entity__about_us___field_info_text___value = 'relationships___site_setting_entity__about_us___field_info_text___value',
  relationships___site_setting_entity__about_us___field_hero_image___alt = 'relationships___site_setting_entity__about_us___field_hero_image___alt',
  relationships___site_setting_entity__about_us___field_hero_image_mobile___alt = 'relationships___site_setting_entity__about_us___field_hero_image_mobile___alt',
  relationships___site_setting_entity__about_us___field_hero_text___value = 'relationships___site_setting_entity__about_us___field_hero_text___value',
  relationships___site_setting_entity__about_us___field_hero_headline___value = 'relationships___site_setting_entity__about_us___field_hero_headline___value',
  relationships___site_setting_entity__about_us___field_pl_header_1___value = 'relationships___site_setting_entity__about_us___field_pl_header_1___value',
  relationships___site_setting_entity__about_us___relationships___field_components = 'relationships___site_setting_entity__about_us___relationships___field_components',
  relationships___site_setting_entity__about_us___relationships___field_member_of_the_board_paragr = 'relationships___site_setting_entity__about_us___relationships___field_member_of_the_board_paragr',
  relationships___site_setting_entity__about_us___drupal_id = 'relationships___site_setting_entity__about_us___drupal_id',
  relationships___site_setting_entity__about_us___drupal_internal__id = 'relationships___site_setting_entity__about_us___drupal_internal__id',
  relationships___site_setting_entity__about_us___name = 'relationships___site_setting_entity__about_us___name',
  relationships___site_setting_entity__about_us___fieldset = 'relationships___site_setting_entity__about_us___fieldset',
  relationships___site_setting_entity__about_us___status = 'relationships___site_setting_entity__about_us___status',
  relationships___site_setting_entity__about_us___created = 'relationships___site_setting_entity__about_us___created',
  relationships___site_setting_entity__about_us___changed = 'relationships___site_setting_entity__about_us___changed',
  relationships___site_setting_entity__about_us___default_langcode = 'relationships___site_setting_entity__about_us___default_langcode',
  relationships___site_setting_entity__about_us___content_translation_source = 'relationships___site_setting_entity__about_us___content_translation_source',
  relationships___site_setting_entity__about_us___content_translation_outdated = 'relationships___site_setting_entity__about_us___content_translation_outdated',
  relationships___site_setting_entity__about_us___content_translation_status = 'relationships___site_setting_entity__about_us___content_translation_status',
  relationships___site_setting_entity__about_us___content_translation_created = 'relationships___site_setting_entity__about_us___content_translation_created',
  relationships___site_setting_entity__about_us___content_translation_changed = 'relationships___site_setting_entity__about_us___content_translation_changed',
  relationships___site_setting_entity__about_us___uuid = 'relationships___site_setting_entity__about_us___uuid',
  relationships___site_setting_entity__about_us___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___field_components = 'relationships___site_setting_entity__about_us___field_components',
  relationships___site_setting_entity__about_us___field_components___target_revision_id = 'relationships___site_setting_entity__about_us___field_components___target_revision_id',
  relationships___site_setting_entity__about_us___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___target_revision_id = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___target_revision_id',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___id = 'relationships___site_setting_entity__about_us___id',
  relationships___site_setting_entity__about_us___parent___id = 'relationships___site_setting_entity__about_us___parent___id',
  relationships___site_setting_entity__about_us___parent___children = 'relationships___site_setting_entity__about_us___parent___children',
  relationships___site_setting_entity__about_us___children = 'relationships___site_setting_entity__about_us___children',
  relationships___site_setting_entity__about_us___children___id = 'relationships___site_setting_entity__about_us___children___id',
  relationships___site_setting_entity__about_us___children___children = 'relationships___site_setting_entity__about_us___children___children',
  relationships___site_setting_entity__about_us___internal___content = 'relationships___site_setting_entity__about_us___internal___content',
  relationships___site_setting_entity__about_us___internal___contentDigest = 'relationships___site_setting_entity__about_us___internal___contentDigest',
  relationships___site_setting_entity__about_us___internal___description = 'relationships___site_setting_entity__about_us___internal___description',
  relationships___site_setting_entity__about_us___internal___fieldOwners = 'relationships___site_setting_entity__about_us___internal___fieldOwners',
  relationships___site_setting_entity__about_us___internal___ignoreType = 'relationships___site_setting_entity__about_us___internal___ignoreType',
  relationships___site_setting_entity__about_us___internal___mediaType = 'relationships___site_setting_entity__about_us___internal___mediaType',
  relationships___site_setting_entity__about_us___internal___owner = 'relationships___site_setting_entity__about_us___internal___owner',
  relationships___site_setting_entity__about_us___internal___type = 'relationships___site_setting_entity__about_us___internal___type',
  relationships___paragraph__logos = 'relationships___paragraph__logos',
  relationships___paragraph__logos___field_logos_logos = 'relationships___paragraph__logos___field_logos_logos',
  relationships___paragraph__logos___field_logos_logos___alt = 'relationships___paragraph__logos___field_logos_logos___alt',
  relationships___paragraph__logos___field_logos_text___value = 'relationships___paragraph__logos___field_logos_text___value',
  relationships___paragraph__logos___relationships___field_logos_logos = 'relationships___paragraph__logos___relationships___field_logos_logos',
  relationships___paragraph__logos___relationships___site_setting_entity__franchise = 'relationships___paragraph__logos___relationships___site_setting_entity__franchise',
  relationships___paragraph__logos___drupal_id = 'relationships___paragraph__logos___drupal_id',
  relationships___paragraph__logos___drupal_internal__id = 'relationships___paragraph__logos___drupal_internal__id',
  relationships___paragraph__logos___status = 'relationships___paragraph__logos___status',
  relationships___paragraph__logos___parent_id = 'relationships___paragraph__logos___parent_id',
  relationships___paragraph__logos___parent_type = 'relationships___paragraph__logos___parent_type',
  relationships___paragraph__logos___parent_field_name = 'relationships___paragraph__logos___parent_field_name',
  relationships___paragraph__logos___content_translation_source = 'relationships___paragraph__logos___content_translation_source',
  relationships___paragraph__logos___content_translation_outdated = 'relationships___paragraph__logos___content_translation_outdated',
  relationships___paragraph__logos___langcode = 'relationships___paragraph__logos___langcode',
  relationships___paragraph__logos___uuid = 'relationships___paragraph__logos___uuid',
  relationships___paragraph__logos___id = 'relationships___paragraph__logos___id',
  relationships___paragraph__logos___parent___id = 'relationships___paragraph__logos___parent___id',
  relationships___paragraph__logos___parent___children = 'relationships___paragraph__logos___parent___children',
  relationships___paragraph__logos___children = 'relationships___paragraph__logos___children',
  relationships___paragraph__logos___children___id = 'relationships___paragraph__logos___children___id',
  relationships___paragraph__logos___children___children = 'relationships___paragraph__logos___children___children',
  relationships___paragraph__logos___internal___content = 'relationships___paragraph__logos___internal___content',
  relationships___paragraph__logos___internal___contentDigest = 'relationships___paragraph__logos___internal___contentDigest',
  relationships___paragraph__logos___internal___description = 'relationships___paragraph__logos___internal___description',
  relationships___paragraph__logos___internal___fieldOwners = 'relationships___paragraph__logos___internal___fieldOwners',
  relationships___paragraph__logos___internal___ignoreType = 'relationships___paragraph__logos___internal___ignoreType',
  relationships___paragraph__logos___internal___mediaType = 'relationships___paragraph__logos___internal___mediaType',
  relationships___paragraph__logos___internal___owner = 'relationships___paragraph__logos___internal___owner',
  relationships___paragraph__logos___internal___type = 'relationships___paragraph__logos___internal___type',
  relationships___paragraph__how_it_works = 'relationships___paragraph__how_it_works',
  relationships___paragraph__how_it_works___field_description___value = 'relationships___paragraph__how_it_works___field_description___value',
  relationships___paragraph__how_it_works___field_ico_1___alt = 'relationships___paragraph__how_it_works___field_ico_1___alt',
  relationships___paragraph__how_it_works___field_image___alt = 'relationships___paragraph__how_it_works___field_image___alt',
  relationships___paragraph__how_it_works___field_image_1___alt = 'relationships___paragraph__how_it_works___field_image_1___alt',
  relationships___paragraph__how_it_works___field_image_2___alt = 'relationships___paragraph__how_it_works___field_image_2___alt',
  relationships___paragraph__how_it_works___field_image_3___alt = 'relationships___paragraph__how_it_works___field_image_3___alt',
  relationships___paragraph__how_it_works___field_paragraph_headline___value = 'relationships___paragraph__how_it_works___field_paragraph_headline___value',
  relationships___paragraph__how_it_works___field_short_text_1___value = 'relationships___paragraph__how_it_works___field_short_text_1___value',
  relationships___paragraph__how_it_works___field_short_text_2___value = 'relationships___paragraph__how_it_works___field_short_text_2___value',
  relationships___paragraph__how_it_works___field_short_text_3___value = 'relationships___paragraph__how_it_works___field_short_text_3___value',
  relationships___paragraph__how_it_works___field_text___value = 'relationships___paragraph__how_it_works___field_text___value',
  relationships___paragraph__how_it_works___relationships___site_setting_entity__franchise = 'relationships___paragraph__how_it_works___relationships___site_setting_entity__franchise',
  relationships___paragraph__how_it_works___drupal_id = 'relationships___paragraph__how_it_works___drupal_id',
  relationships___paragraph__how_it_works___drupal_internal__id = 'relationships___paragraph__how_it_works___drupal_internal__id',
  relationships___paragraph__how_it_works___drupal_internal__revision_id = 'relationships___paragraph__how_it_works___drupal_internal__revision_id',
  relationships___paragraph__how_it_works___langcode = 'relationships___paragraph__how_it_works___langcode',
  relationships___paragraph__how_it_works___status = 'relationships___paragraph__how_it_works___status',
  relationships___paragraph__how_it_works___created = 'relationships___paragraph__how_it_works___created',
  relationships___paragraph__how_it_works___parent_id = 'relationships___paragraph__how_it_works___parent_id',
  relationships___paragraph__how_it_works___parent_type = 'relationships___paragraph__how_it_works___parent_type',
  relationships___paragraph__how_it_works___parent_field_name = 'relationships___paragraph__how_it_works___parent_field_name',
  relationships___paragraph__how_it_works___default_langcode = 'relationships___paragraph__how_it_works___default_langcode',
  relationships___paragraph__how_it_works___revision_translation_affected = 'relationships___paragraph__how_it_works___revision_translation_affected',
  relationships___paragraph__how_it_works___content_translation_source = 'relationships___paragraph__how_it_works___content_translation_source',
  relationships___paragraph__how_it_works___content_translation_outdated = 'relationships___paragraph__how_it_works___content_translation_outdated',
  relationships___paragraph__how_it_works___uuid = 'relationships___paragraph__how_it_works___uuid',
  relationships___paragraph__how_it_works___id = 'relationships___paragraph__how_it_works___id',
  relationships___paragraph__how_it_works___parent___id = 'relationships___paragraph__how_it_works___parent___id',
  relationships___paragraph__how_it_works___parent___children = 'relationships___paragraph__how_it_works___parent___children',
  relationships___paragraph__how_it_works___children = 'relationships___paragraph__how_it_works___children',
  relationships___paragraph__how_it_works___children___id = 'relationships___paragraph__how_it_works___children___id',
  relationships___paragraph__how_it_works___children___children = 'relationships___paragraph__how_it_works___children___children',
  relationships___paragraph__how_it_works___internal___content = 'relationships___paragraph__how_it_works___internal___content',
  relationships___paragraph__how_it_works___internal___contentDigest = 'relationships___paragraph__how_it_works___internal___contentDigest',
  relationships___paragraph__how_it_works___internal___description = 'relationships___paragraph__how_it_works___internal___description',
  relationships___paragraph__how_it_works___internal___fieldOwners = 'relationships___paragraph__how_it_works___internal___fieldOwners',
  relationships___paragraph__how_it_works___internal___ignoreType = 'relationships___paragraph__how_it_works___internal___ignoreType',
  relationships___paragraph__how_it_works___internal___mediaType = 'relationships___paragraph__how_it_works___internal___mediaType',
  relationships___paragraph__how_it_works___internal___owner = 'relationships___paragraph__how_it_works___internal___owner',
  relationships___paragraph__how_it_works___internal___type = 'relationships___paragraph__how_it_works___internal___type',
  relationships___paragraph__ico_and_text = 'relationships___paragraph__ico_and_text',
  relationships___paragraph__ico_and_text___field_text___value = 'relationships___paragraph__ico_and_text___field_text___value',
  relationships___paragraph__ico_and_text___field_ico___alt = 'relationships___paragraph__ico_and_text___field_ico___alt',
  relationships___paragraph__ico_and_text___relationships___paragraph__title_icons = 'relationships___paragraph__ico_and_text___relationships___paragraph__title_icons',
  relationships___paragraph__ico_and_text___relationships___site_setting_entity__product = 'relationships___paragraph__ico_and_text___relationships___site_setting_entity__product',
  relationships___paragraph__ico_and_text___drupal_id = 'relationships___paragraph__ico_and_text___drupal_id',
  relationships___paragraph__ico_and_text___drupal_internal__id = 'relationships___paragraph__ico_and_text___drupal_internal__id',
  relationships___paragraph__ico_and_text___drupal_internal__revision_id = 'relationships___paragraph__ico_and_text___drupal_internal__revision_id',
  relationships___paragraph__ico_and_text___langcode = 'relationships___paragraph__ico_and_text___langcode',
  relationships___paragraph__ico_and_text___status = 'relationships___paragraph__ico_and_text___status',
  relationships___paragraph__ico_and_text___created = 'relationships___paragraph__ico_and_text___created',
  relationships___paragraph__ico_and_text___parent_id = 'relationships___paragraph__ico_and_text___parent_id',
  relationships___paragraph__ico_and_text___parent_type = 'relationships___paragraph__ico_and_text___parent_type',
  relationships___paragraph__ico_and_text___parent_field_name = 'relationships___paragraph__ico_and_text___parent_field_name',
  relationships___paragraph__ico_and_text___default_langcode = 'relationships___paragraph__ico_and_text___default_langcode',
  relationships___paragraph__ico_and_text___revision_translation_affected = 'relationships___paragraph__ico_and_text___revision_translation_affected',
  relationships___paragraph__ico_and_text___content_translation_source = 'relationships___paragraph__ico_and_text___content_translation_source',
  relationships___paragraph__ico_and_text___content_translation_outdated = 'relationships___paragraph__ico_and_text___content_translation_outdated',
  relationships___paragraph__ico_and_text___uuid = 'relationships___paragraph__ico_and_text___uuid',
  relationships___paragraph__ico_and_text___id = 'relationships___paragraph__ico_and_text___id',
  relationships___paragraph__ico_and_text___parent___id = 'relationships___paragraph__ico_and_text___parent___id',
  relationships___paragraph__ico_and_text___parent___children = 'relationships___paragraph__ico_and_text___parent___children',
  relationships___paragraph__ico_and_text___children = 'relationships___paragraph__ico_and_text___children',
  relationships___paragraph__ico_and_text___children___id = 'relationships___paragraph__ico_and_text___children___id',
  relationships___paragraph__ico_and_text___children___children = 'relationships___paragraph__ico_and_text___children___children',
  relationships___paragraph__ico_and_text___internal___content = 'relationships___paragraph__ico_and_text___internal___content',
  relationships___paragraph__ico_and_text___internal___contentDigest = 'relationships___paragraph__ico_and_text___internal___contentDigest',
  relationships___paragraph__ico_and_text___internal___description = 'relationships___paragraph__ico_and_text___internal___description',
  relationships___paragraph__ico_and_text___internal___fieldOwners = 'relationships___paragraph__ico_and_text___internal___fieldOwners',
  relationships___paragraph__ico_and_text___internal___ignoreType = 'relationships___paragraph__ico_and_text___internal___ignoreType',
  relationships___paragraph__ico_and_text___internal___mediaType = 'relationships___paragraph__ico_and_text___internal___mediaType',
  relationships___paragraph__ico_and_text___internal___owner = 'relationships___paragraph__ico_and_text___internal___owner',
  relationships___paragraph__ico_and_text___internal___type = 'relationships___paragraph__ico_and_text___internal___type',
  relationships___paragraph__hero_1 = 'relationships___paragraph__hero_1',
  relationships___paragraph__hero_1___field_hero_1_button = 'relationships___paragraph__hero_1___field_hero_1_button',
  relationships___paragraph__hero_1___field_hero_1_video = 'relationships___paragraph__hero_1___field_hero_1_video',
  relationships___paragraph__hero_1___field_hero_1_image___alt = 'relationships___paragraph__hero_1___field_hero_1_image___alt',
  relationships___paragraph__hero_1___field_hero_1_image_mobile___alt = 'relationships___paragraph__hero_1___field_hero_1_image_mobile___alt',
  relationships___paragraph__hero_1___field_hero_1_subtitle___value = 'relationships___paragraph__hero_1___field_hero_1_subtitle___value',
  relationships___paragraph__hero_1___field_hero_1_text_1___value = 'relationships___paragraph__hero_1___field_hero_1_text_1___value',
  relationships___paragraph__hero_1___field_hero_1_text_2___value = 'relationships___paragraph__hero_1___field_hero_1_text_2___value',
  relationships___paragraph__hero_1___field_hero_1_title___value = 'relationships___paragraph__hero_1___field_hero_1_title___value',
  relationships___paragraph__hero_1___field_video_thumbnail___alt = 'relationships___paragraph__hero_1___field_video_thumbnail___alt',
  relationships___paragraph__hero_1___relationships___site_setting_entity__main_page = 'relationships___paragraph__hero_1___relationships___site_setting_entity__main_page',
  relationships___paragraph__hero_1___drupal_id = 'relationships___paragraph__hero_1___drupal_id',
  relationships___paragraph__hero_1___drupal_internal__id = 'relationships___paragraph__hero_1___drupal_internal__id',
  relationships___paragraph__hero_1___status = 'relationships___paragraph__hero_1___status',
  relationships___paragraph__hero_1___created = 'relationships___paragraph__hero_1___created',
  relationships___paragraph__hero_1___parent_id = 'relationships___paragraph__hero_1___parent_id',
  relationships___paragraph__hero_1___parent_type = 'relationships___paragraph__hero_1___parent_type',
  relationships___paragraph__hero_1___parent_field_name = 'relationships___paragraph__hero_1___parent_field_name',
  relationships___paragraph__hero_1___content_translation_source = 'relationships___paragraph__hero_1___content_translation_source',
  relationships___paragraph__hero_1___content_translation_outdated = 'relationships___paragraph__hero_1___content_translation_outdated',
  relationships___paragraph__hero_1___langcode = 'relationships___paragraph__hero_1___langcode',
  relationships___paragraph__hero_1___uuid = 'relationships___paragraph__hero_1___uuid',
  relationships___paragraph__hero_1___id = 'relationships___paragraph__hero_1___id',
  relationships___paragraph__hero_1___parent___id = 'relationships___paragraph__hero_1___parent___id',
  relationships___paragraph__hero_1___parent___children = 'relationships___paragraph__hero_1___parent___children',
  relationships___paragraph__hero_1___children = 'relationships___paragraph__hero_1___children',
  relationships___paragraph__hero_1___children___id = 'relationships___paragraph__hero_1___children___id',
  relationships___paragraph__hero_1___children___children = 'relationships___paragraph__hero_1___children___children',
  relationships___paragraph__hero_1___internal___content = 'relationships___paragraph__hero_1___internal___content',
  relationships___paragraph__hero_1___internal___contentDigest = 'relationships___paragraph__hero_1___internal___contentDigest',
  relationships___paragraph__hero_1___internal___description = 'relationships___paragraph__hero_1___internal___description',
  relationships___paragraph__hero_1___internal___fieldOwners = 'relationships___paragraph__hero_1___internal___fieldOwners',
  relationships___paragraph__hero_1___internal___ignoreType = 'relationships___paragraph__hero_1___internal___ignoreType',
  relationships___paragraph__hero_1___internal___mediaType = 'relationships___paragraph__hero_1___internal___mediaType',
  relationships___paragraph__hero_1___internal___owner = 'relationships___paragraph__hero_1___internal___owner',
  relationships___paragraph__hero_1___internal___type = 'relationships___paragraph__hero_1___internal___type',
  relationships___paragraph__comprehensive_solution_item = 'relationships___paragraph__comprehensive_solution_item',
  relationships___paragraph__comprehensive_solution_item___field_is_online = 'relationships___paragraph__comprehensive_solution_item___field_is_online',
  relationships___paragraph__comprehensive_solution_item___field_is_online_text = 'relationships___paragraph__comprehensive_solution_item___field_is_online_text',
  relationships___paragraph__comprehensive_solution_item___field_nc_button_text = 'relationships___paragraph__comprehensive_solution_item___field_nc_button_text',
  relationships___paragraph__comprehensive_solution_item___field_paragraph_headline___value = 'relationships___paragraph__comprehensive_solution_item___field_paragraph_headline___value',
  relationships___paragraph__comprehensive_solution_item___field_image___alt = 'relationships___paragraph__comprehensive_solution_item___field_image___alt',
  relationships___paragraph__comprehensive_solution_item___relationships___paragraph__comprehensive_solution = 'relationships___paragraph__comprehensive_solution_item___relationships___paragraph__comprehensive_solution',
  relationships___paragraph__comprehensive_solution_item___drupal_id = 'relationships___paragraph__comprehensive_solution_item___drupal_id',
  relationships___paragraph__comprehensive_solution_item___drupal_internal__id = 'relationships___paragraph__comprehensive_solution_item___drupal_internal__id',
  relationships___paragraph__comprehensive_solution_item___drupal_internal__revision_id = 'relationships___paragraph__comprehensive_solution_item___drupal_internal__revision_id',
  relationships___paragraph__comprehensive_solution_item___langcode = 'relationships___paragraph__comprehensive_solution_item___langcode',
  relationships___paragraph__comprehensive_solution_item___status = 'relationships___paragraph__comprehensive_solution_item___status',
  relationships___paragraph__comprehensive_solution_item___created = 'relationships___paragraph__comprehensive_solution_item___created',
  relationships___paragraph__comprehensive_solution_item___parent_id = 'relationships___paragraph__comprehensive_solution_item___parent_id',
  relationships___paragraph__comprehensive_solution_item___parent_type = 'relationships___paragraph__comprehensive_solution_item___parent_type',
  relationships___paragraph__comprehensive_solution_item___parent_field_name = 'relationships___paragraph__comprehensive_solution_item___parent_field_name',
  relationships___paragraph__comprehensive_solution_item___default_langcode = 'relationships___paragraph__comprehensive_solution_item___default_langcode',
  relationships___paragraph__comprehensive_solution_item___revision_translation_affected = 'relationships___paragraph__comprehensive_solution_item___revision_translation_affected',
  relationships___paragraph__comprehensive_solution_item___content_translation_source = 'relationships___paragraph__comprehensive_solution_item___content_translation_source',
  relationships___paragraph__comprehensive_solution_item___content_translation_outdated = 'relationships___paragraph__comprehensive_solution_item___content_translation_outdated',
  relationships___paragraph__comprehensive_solution_item___content_translation_changed = 'relationships___paragraph__comprehensive_solution_item___content_translation_changed',
  relationships___paragraph__comprehensive_solution_item___uuid = 'relationships___paragraph__comprehensive_solution_item___uuid',
  relationships___paragraph__comprehensive_solution_item___id = 'relationships___paragraph__comprehensive_solution_item___id',
  relationships___paragraph__comprehensive_solution_item___parent___id = 'relationships___paragraph__comprehensive_solution_item___parent___id',
  relationships___paragraph__comprehensive_solution_item___parent___children = 'relationships___paragraph__comprehensive_solution_item___parent___children',
  relationships___paragraph__comprehensive_solution_item___children = 'relationships___paragraph__comprehensive_solution_item___children',
  relationships___paragraph__comprehensive_solution_item___children___id = 'relationships___paragraph__comprehensive_solution_item___children___id',
  relationships___paragraph__comprehensive_solution_item___children___children = 'relationships___paragraph__comprehensive_solution_item___children___children',
  relationships___paragraph__comprehensive_solution_item___internal___content = 'relationships___paragraph__comprehensive_solution_item___internal___content',
  relationships___paragraph__comprehensive_solution_item___internal___contentDigest = 'relationships___paragraph__comprehensive_solution_item___internal___contentDigest',
  relationships___paragraph__comprehensive_solution_item___internal___description = 'relationships___paragraph__comprehensive_solution_item___internal___description',
  relationships___paragraph__comprehensive_solution_item___internal___fieldOwners = 'relationships___paragraph__comprehensive_solution_item___internal___fieldOwners',
  relationships___paragraph__comprehensive_solution_item___internal___ignoreType = 'relationships___paragraph__comprehensive_solution_item___internal___ignoreType',
  relationships___paragraph__comprehensive_solution_item___internal___mediaType = 'relationships___paragraph__comprehensive_solution_item___internal___mediaType',
  relationships___paragraph__comprehensive_solution_item___internal___owner = 'relationships___paragraph__comprehensive_solution_item___internal___owner',
  relationships___paragraph__comprehensive_solution_item___internal___type = 'relationships___paragraph__comprehensive_solution_item___internal___type',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  relationships___paragraph__zdjecie_opis_link_ico = 'relationships___paragraph__zdjecie_opis_link_ico',
  relationships___paragraph__zdjecie_opis_link_ico___field_image___alt = 'relationships___paragraph__zdjecie_opis_link_ico___field_image___alt',
  relationships___paragraph__zdjecie_opis_link_ico___field_image_1___alt = 'relationships___paragraph__zdjecie_opis_link_ico___field_image_1___alt',
  relationships___paragraph__zdjecie_opis_link_ico___field_nc_button_text = 'relationships___paragraph__zdjecie_opis_link_ico___field_nc_button_text',
  relationships___paragraph__zdjecie_opis_link_ico___field_paragraph_headline___value = 'relationships___paragraph__zdjecie_opis_link_ico___field_paragraph_headline___value',
  relationships___paragraph__zdjecie_opis_link_ico___field_text___value = 'relationships___paragraph__zdjecie_opis_link_ico___field_text___value',
  relationships___paragraph__zdjecie_opis_link_ico___relationships___site_setting_entity__product = 'relationships___paragraph__zdjecie_opis_link_ico___relationships___site_setting_entity__product',
  relationships___paragraph__zdjecie_opis_link_ico___drupal_id = 'relationships___paragraph__zdjecie_opis_link_ico___drupal_id',
  relationships___paragraph__zdjecie_opis_link_ico___drupal_internal__id = 'relationships___paragraph__zdjecie_opis_link_ico___drupal_internal__id',
  relationships___paragraph__zdjecie_opis_link_ico___drupal_internal__revision_id = 'relationships___paragraph__zdjecie_opis_link_ico___drupal_internal__revision_id',
  relationships___paragraph__zdjecie_opis_link_ico___langcode = 'relationships___paragraph__zdjecie_opis_link_ico___langcode',
  relationships___paragraph__zdjecie_opis_link_ico___status = 'relationships___paragraph__zdjecie_opis_link_ico___status',
  relationships___paragraph__zdjecie_opis_link_ico___created = 'relationships___paragraph__zdjecie_opis_link_ico___created',
  relationships___paragraph__zdjecie_opis_link_ico___parent_id = 'relationships___paragraph__zdjecie_opis_link_ico___parent_id',
  relationships___paragraph__zdjecie_opis_link_ico___parent_type = 'relationships___paragraph__zdjecie_opis_link_ico___parent_type',
  relationships___paragraph__zdjecie_opis_link_ico___parent_field_name = 'relationships___paragraph__zdjecie_opis_link_ico___parent_field_name',
  relationships___paragraph__zdjecie_opis_link_ico___default_langcode = 'relationships___paragraph__zdjecie_opis_link_ico___default_langcode',
  relationships___paragraph__zdjecie_opis_link_ico___revision_translation_affected = 'relationships___paragraph__zdjecie_opis_link_ico___revision_translation_affected',
  relationships___paragraph__zdjecie_opis_link_ico___content_translation_source = 'relationships___paragraph__zdjecie_opis_link_ico___content_translation_source',
  relationships___paragraph__zdjecie_opis_link_ico___content_translation_outdated = 'relationships___paragraph__zdjecie_opis_link_ico___content_translation_outdated',
  relationships___paragraph__zdjecie_opis_link_ico___uuid = 'relationships___paragraph__zdjecie_opis_link_ico___uuid',
  relationships___paragraph__zdjecie_opis_link_ico___id = 'relationships___paragraph__zdjecie_opis_link_ico___id',
  relationships___paragraph__zdjecie_opis_link_ico___parent___id = 'relationships___paragraph__zdjecie_opis_link_ico___parent___id',
  relationships___paragraph__zdjecie_opis_link_ico___parent___children = 'relationships___paragraph__zdjecie_opis_link_ico___parent___children',
  relationships___paragraph__zdjecie_opis_link_ico___children = 'relationships___paragraph__zdjecie_opis_link_ico___children',
  relationships___paragraph__zdjecie_opis_link_ico___children___id = 'relationships___paragraph__zdjecie_opis_link_ico___children___id',
  relationships___paragraph__zdjecie_opis_link_ico___children___children = 'relationships___paragraph__zdjecie_opis_link_ico___children___children',
  relationships___paragraph__zdjecie_opis_link_ico___internal___content = 'relationships___paragraph__zdjecie_opis_link_ico___internal___content',
  relationships___paragraph__zdjecie_opis_link_ico___internal___contentDigest = 'relationships___paragraph__zdjecie_opis_link_ico___internal___contentDigest',
  relationships___paragraph__zdjecie_opis_link_ico___internal___description = 'relationships___paragraph__zdjecie_opis_link_ico___internal___description',
  relationships___paragraph__zdjecie_opis_link_ico___internal___fieldOwners = 'relationships___paragraph__zdjecie_opis_link_ico___internal___fieldOwners',
  relationships___paragraph__zdjecie_opis_link_ico___internal___ignoreType = 'relationships___paragraph__zdjecie_opis_link_ico___internal___ignoreType',
  relationships___paragraph__zdjecie_opis_link_ico___internal___mediaType = 'relationships___paragraph__zdjecie_opis_link_ico___internal___mediaType',
  relationships___paragraph__zdjecie_opis_link_ico___internal___owner = 'relationships___paragraph__zdjecie_opis_link_ico___internal___owner',
  relationships___paragraph__zdjecie_opis_link_ico___internal___type = 'relationships___paragraph__zdjecie_opis_link_ico___internal___type',
  relationships___paragraph__porownanie_terminali = 'relationships___paragraph__porownanie_terminali',
  relationships___paragraph__porownanie_terminali___field_description___value = 'relationships___paragraph__porownanie_terminali___field_description___value',
  relationships___paragraph__porownanie_terminali___field_image___alt = 'relationships___paragraph__porownanie_terminali___field_image___alt',
  relationships___paragraph__porownanie_terminali___field_image_1___alt = 'relationships___paragraph__porownanie_terminali___field_image_1___alt',
  relationships___paragraph__porownanie_terminali___field_kup_teraz = 'relationships___paragraph__porownanie_terminali___field_kup_teraz',
  relationships___paragraph__porownanie_terminali___field_label_1 = 'relationships___paragraph__porownanie_terminali___field_label_1',
  relationships___paragraph__porownanie_terminali___field_label_2 = 'relationships___paragraph__porownanie_terminali___field_label_2',
  relationships___paragraph__porownanie_terminali___field_label_3 = 'relationships___paragraph__porownanie_terminali___field_label_3',
  relationships___paragraph__porownanie_terminali___field_label_4 = 'relationships___paragraph__porownanie_terminali___field_label_4',
  relationships___paragraph__porownanie_terminali___field_paragraph_headline___value = 'relationships___paragraph__porownanie_terminali___field_paragraph_headline___value',
  relationships___paragraph__porownanie_terminali___field_text_1___value = 'relationships___paragraph__porownanie_terminali___field_text_1___value',
  relationships___paragraph__porownanie_terminali___field_text_2___value = 'relationships___paragraph__porownanie_terminali___field_text_2___value',
  relationships___paragraph__porownanie_terminali___field_text_3 = 'relationships___paragraph__porownanie_terminali___field_text_3',
  relationships___paragraph__porownanie_terminali___field_text_4___value = 'relationships___paragraph__porownanie_terminali___field_text_4___value',
  relationships___paragraph__porownanie_terminali___field_zobacz_szczegoly = 'relationships___paragraph__porownanie_terminali___field_zobacz_szczegoly',
  relationships___paragraph__porownanie_terminali___relationships___site_setting_entity__product = 'relationships___paragraph__porownanie_terminali___relationships___site_setting_entity__product',
  relationships___paragraph__porownanie_terminali___drupal_id = 'relationships___paragraph__porownanie_terminali___drupal_id',
  relationships___paragraph__porownanie_terminali___drupal_internal__id = 'relationships___paragraph__porownanie_terminali___drupal_internal__id',
  relationships___paragraph__porownanie_terminali___drupal_internal__revision_id = 'relationships___paragraph__porownanie_terminali___drupal_internal__revision_id',
  relationships___paragraph__porownanie_terminali___langcode = 'relationships___paragraph__porownanie_terminali___langcode',
  relationships___paragraph__porownanie_terminali___status = 'relationships___paragraph__porownanie_terminali___status',
  relationships___paragraph__porownanie_terminali___created = 'relationships___paragraph__porownanie_terminali___created',
  relationships___paragraph__porownanie_terminali___parent_id = 'relationships___paragraph__porownanie_terminali___parent_id',
  relationships___paragraph__porownanie_terminali___parent_type = 'relationships___paragraph__porownanie_terminali___parent_type',
  relationships___paragraph__porownanie_terminali___parent_field_name = 'relationships___paragraph__porownanie_terminali___parent_field_name',
  relationships___paragraph__porownanie_terminali___default_langcode = 'relationships___paragraph__porownanie_terminali___default_langcode',
  relationships___paragraph__porownanie_terminali___revision_translation_affected = 'relationships___paragraph__porownanie_terminali___revision_translation_affected',
  relationships___paragraph__porownanie_terminali___content_translation_source = 'relationships___paragraph__porownanie_terminali___content_translation_source',
  relationships___paragraph__porownanie_terminali___content_translation_outdated = 'relationships___paragraph__porownanie_terminali___content_translation_outdated',
  relationships___paragraph__porownanie_terminali___uuid = 'relationships___paragraph__porownanie_terminali___uuid',
  relationships___paragraph__porownanie_terminali___id = 'relationships___paragraph__porownanie_terminali___id',
  relationships___paragraph__porownanie_terminali___parent___id = 'relationships___paragraph__porownanie_terminali___parent___id',
  relationships___paragraph__porownanie_terminali___parent___children = 'relationships___paragraph__porownanie_terminali___parent___children',
  relationships___paragraph__porownanie_terminali___children = 'relationships___paragraph__porownanie_terminali___children',
  relationships___paragraph__porownanie_terminali___children___id = 'relationships___paragraph__porownanie_terminali___children___id',
  relationships___paragraph__porownanie_terminali___children___children = 'relationships___paragraph__porownanie_terminali___children___children',
  relationships___paragraph__porownanie_terminali___internal___content = 'relationships___paragraph__porownanie_terminali___internal___content',
  relationships___paragraph__porownanie_terminali___internal___contentDigest = 'relationships___paragraph__porownanie_terminali___internal___contentDigest',
  relationships___paragraph__porownanie_terminali___internal___description = 'relationships___paragraph__porownanie_terminali___internal___description',
  relationships___paragraph__porownanie_terminali___internal___fieldOwners = 'relationships___paragraph__porownanie_terminali___internal___fieldOwners',
  relationships___paragraph__porownanie_terminali___internal___ignoreType = 'relationships___paragraph__porownanie_terminali___internal___ignoreType',
  relationships___paragraph__porownanie_terminali___internal___mediaType = 'relationships___paragraph__porownanie_terminali___internal___mediaType',
  relationships___paragraph__porownanie_terminali___internal___owner = 'relationships___paragraph__porownanie_terminali___internal___owner',
  relationships___paragraph__porownanie_terminali___internal___type = 'relationships___paragraph__porownanie_terminali___internal___type',
  relationships___paragraph__zdjecie_opis_link = 'relationships___paragraph__zdjecie_opis_link',
  relationships___paragraph__zdjecie_opis_link___field_image___alt = 'relationships___paragraph__zdjecie_opis_link___field_image___alt',
  relationships___paragraph__zdjecie_opis_link___field_nc_button_text = 'relationships___paragraph__zdjecie_opis_link___field_nc_button_text',
  relationships___paragraph__zdjecie_opis_link___field_paragraph_headline___value = 'relationships___paragraph__zdjecie_opis_link___field_paragraph_headline___value',
  relationships___paragraph__zdjecie_opis_link___field_text___value = 'relationships___paragraph__zdjecie_opis_link___field_text___value',
  relationships___paragraph__zdjecie_opis_link___field_hidden_or_disable = 'relationships___paragraph__zdjecie_opis_link___field_hidden_or_disable',
  relationships___paragraph__zdjecie_opis_link___relationships___site_setting_entity__product = 'relationships___paragraph__zdjecie_opis_link___relationships___site_setting_entity__product',
  relationships___paragraph__zdjecie_opis_link___drupal_id = 'relationships___paragraph__zdjecie_opis_link___drupal_id',
  relationships___paragraph__zdjecie_opis_link___drupal_internal__id = 'relationships___paragraph__zdjecie_opis_link___drupal_internal__id',
  relationships___paragraph__zdjecie_opis_link___drupal_internal__revision_id = 'relationships___paragraph__zdjecie_opis_link___drupal_internal__revision_id',
  relationships___paragraph__zdjecie_opis_link___langcode = 'relationships___paragraph__zdjecie_opis_link___langcode',
  relationships___paragraph__zdjecie_opis_link___status = 'relationships___paragraph__zdjecie_opis_link___status',
  relationships___paragraph__zdjecie_opis_link___created = 'relationships___paragraph__zdjecie_opis_link___created',
  relationships___paragraph__zdjecie_opis_link___parent_id = 'relationships___paragraph__zdjecie_opis_link___parent_id',
  relationships___paragraph__zdjecie_opis_link___parent_type = 'relationships___paragraph__zdjecie_opis_link___parent_type',
  relationships___paragraph__zdjecie_opis_link___parent_field_name = 'relationships___paragraph__zdjecie_opis_link___parent_field_name',
  relationships___paragraph__zdjecie_opis_link___default_langcode = 'relationships___paragraph__zdjecie_opis_link___default_langcode',
  relationships___paragraph__zdjecie_opis_link___revision_translation_affected = 'relationships___paragraph__zdjecie_opis_link___revision_translation_affected',
  relationships___paragraph__zdjecie_opis_link___content_translation_source = 'relationships___paragraph__zdjecie_opis_link___content_translation_source',
  relationships___paragraph__zdjecie_opis_link___content_translation_outdated = 'relationships___paragraph__zdjecie_opis_link___content_translation_outdated',
  relationships___paragraph__zdjecie_opis_link___uuid = 'relationships___paragraph__zdjecie_opis_link___uuid',
  relationships___paragraph__zdjecie_opis_link___id = 'relationships___paragraph__zdjecie_opis_link___id',
  relationships___paragraph__zdjecie_opis_link___parent___id = 'relationships___paragraph__zdjecie_opis_link___parent___id',
  relationships___paragraph__zdjecie_opis_link___parent___children = 'relationships___paragraph__zdjecie_opis_link___parent___children',
  relationships___paragraph__zdjecie_opis_link___children = 'relationships___paragraph__zdjecie_opis_link___children',
  relationships___paragraph__zdjecie_opis_link___children___id = 'relationships___paragraph__zdjecie_opis_link___children___id',
  relationships___paragraph__zdjecie_opis_link___children___children = 'relationships___paragraph__zdjecie_opis_link___children___children',
  relationships___paragraph__zdjecie_opis_link___internal___content = 'relationships___paragraph__zdjecie_opis_link___internal___content',
  relationships___paragraph__zdjecie_opis_link___internal___contentDigest = 'relationships___paragraph__zdjecie_opis_link___internal___contentDigest',
  relationships___paragraph__zdjecie_opis_link___internal___description = 'relationships___paragraph__zdjecie_opis_link___internal___description',
  relationships___paragraph__zdjecie_opis_link___internal___fieldOwners = 'relationships___paragraph__zdjecie_opis_link___internal___fieldOwners',
  relationships___paragraph__zdjecie_opis_link___internal___ignoreType = 'relationships___paragraph__zdjecie_opis_link___internal___ignoreType',
  relationships___paragraph__zdjecie_opis_link___internal___mediaType = 'relationships___paragraph__zdjecie_opis_link___internal___mediaType',
  relationships___paragraph__zdjecie_opis_link___internal___owner = 'relationships___paragraph__zdjecie_opis_link___internal___owner',
  relationships___paragraph__zdjecie_opis_link___internal___type = 'relationships___paragraph__zdjecie_opis_link___internal___type',
  relationships___site_setting_entity__main_page = 'relationships___site_setting_entity__main_page',
  relationships___site_setting_entity__main_page___langcode = 'relationships___site_setting_entity__main_page___langcode',
  relationships___site_setting_entity__main_page___field_mp_text_footer___value = 'relationships___site_setting_entity__main_page___field_mp_text_footer___value',
  relationships___site_setting_entity__main_page___field_mp_text___value = 'relationships___site_setting_entity__main_page___field_mp_text___value',
  relationships___site_setting_entity__main_page___field_mp_button_text = 'relationships___site_setting_entity__main_page___field_mp_button_text',
  relationships___site_setting_entity__main_page___field_meta_description = 'relationships___site_setting_entity__main_page___field_meta_description',
  relationships___site_setting_entity__main_page___field_meta_title = 'relationships___site_setting_entity__main_page___field_meta_title',
  relationships___site_setting_entity__main_page___field_btn_url = 'relationships___site_setting_entity__main_page___field_btn_url',
  relationships___site_setting_entity__main_page___field_settings_button_text = 'relationships___site_setting_entity__main_page___field_settings_button_text',
  relationships___site_setting_entity__main_page___field_mp_image_footer___alt = 'relationships___site_setting_entity__main_page___field_mp_image_footer___alt',
  relationships___site_setting_entity__main_page___field_mp_image___alt = 'relationships___site_setting_entity__main_page___field_mp_image___alt',
  relationships___site_setting_entity__main_page___drupal_id = 'relationships___site_setting_entity__main_page___drupal_id',
  relationships___site_setting_entity__main_page___drupal_internal__id = 'relationships___site_setting_entity__main_page___drupal_internal__id',
  relationships___site_setting_entity__main_page___name = 'relationships___site_setting_entity__main_page___name',
  relationships___site_setting_entity__main_page___fieldset = 'relationships___site_setting_entity__main_page___fieldset',
  relationships___site_setting_entity__main_page___status = 'relationships___site_setting_entity__main_page___status',
  relationships___site_setting_entity__main_page___created = 'relationships___site_setting_entity__main_page___created',
  relationships___site_setting_entity__main_page___changed = 'relationships___site_setting_entity__main_page___changed',
  relationships___site_setting_entity__main_page___default_langcode = 'relationships___site_setting_entity__main_page___default_langcode',
  relationships___site_setting_entity__main_page___content_translation_source = 'relationships___site_setting_entity__main_page___content_translation_source',
  relationships___site_setting_entity__main_page___content_translation_outdated = 'relationships___site_setting_entity__main_page___content_translation_outdated',
  relationships___site_setting_entity__main_page___content_translation_status = 'relationships___site_setting_entity__main_page___content_translation_status',
  relationships___site_setting_entity__main_page___content_translation_created = 'relationships___site_setting_entity__main_page___content_translation_created',
  relationships___site_setting_entity__main_page___content_translation_changed = 'relationships___site_setting_entity__main_page___content_translation_changed',
  relationships___site_setting_entity__main_page___uuid = 'relationships___site_setting_entity__main_page___uuid',
  relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___field_components = 'relationships___site_setting_entity__main_page___field_components',
  relationships___site_setting_entity__main_page___field_components___target_revision_id = 'relationships___site_setting_entity__main_page___field_components___target_revision_id',
  relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___id = 'relationships___site_setting_entity__main_page___id',
  relationships___site_setting_entity__main_page___parent___id = 'relationships___site_setting_entity__main_page___parent___id',
  relationships___site_setting_entity__main_page___parent___children = 'relationships___site_setting_entity__main_page___parent___children',
  relationships___site_setting_entity__main_page___children = 'relationships___site_setting_entity__main_page___children',
  relationships___site_setting_entity__main_page___children___id = 'relationships___site_setting_entity__main_page___children___id',
  relationships___site_setting_entity__main_page___children___children = 'relationships___site_setting_entity__main_page___children___children',
  relationships___site_setting_entity__main_page___internal___content = 'relationships___site_setting_entity__main_page___internal___content',
  relationships___site_setting_entity__main_page___internal___contentDigest = 'relationships___site_setting_entity__main_page___internal___contentDigest',
  relationships___site_setting_entity__main_page___internal___description = 'relationships___site_setting_entity__main_page___internal___description',
  relationships___site_setting_entity__main_page___internal___fieldOwners = 'relationships___site_setting_entity__main_page___internal___fieldOwners',
  relationships___site_setting_entity__main_page___internal___ignoreType = 'relationships___site_setting_entity__main_page___internal___ignoreType',
  relationships___site_setting_entity__main_page___internal___mediaType = 'relationships___site_setting_entity__main_page___internal___mediaType',
  relationships___site_setting_entity__main_page___internal___owner = 'relationships___site_setting_entity__main_page___internal___owner',
  relationships___site_setting_entity__main_page___internal___type = 'relationships___site_setting_entity__main_page___internal___type',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___langcode = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___langcode',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_headline___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_headline___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_text___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_text___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_meta_description = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_meta_description',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_meta_title = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_meta_title',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_video = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_video',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_image___alt = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_image___alt',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_image_mobile___alt = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_image_mobile___alt',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_button_text = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_button_text',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_opisy_wykresow = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_opisy_wykresow',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_settings_tresc___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_settings_tresc___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_1___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_1___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_2___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_2___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_3___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_3___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_4___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_4___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___drupal_id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___drupal_id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___drupal_internal__id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___drupal_internal__id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___name = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___name',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___fieldset = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___fieldset',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___status = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___status',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___created = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___created',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___changed = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___changed',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___default_langcode = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___default_langcode',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_source = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_source',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_outdated = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_outdated',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_status = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_status',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_created = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_created',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_changed = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_changed',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___uuid = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___uuid',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components___target_revision_id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components___target_revision_id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___parent___id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___parent___id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___parent___children = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___parent___children',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children___id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children___id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children___children = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children___children',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___content = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___content',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___contentDigest = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___contentDigest',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___description = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___description',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___fieldOwners = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___fieldOwners',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___ignoreType = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___ignoreType',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___mediaType = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___mediaType',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___owner = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___owner',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___type = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___type',
  relationships___paragraph__2_zdjecia_opisy_link = 'relationships___paragraph__2_zdjecia_opisy_link',
  relationships___paragraph__2_zdjecia_opisy_link___field_label_1 = 'relationships___paragraph__2_zdjecia_opisy_link___field_label_1',
  relationships___paragraph__2_zdjecia_opisy_link___field_label_2 = 'relationships___paragraph__2_zdjecia_opisy_link___field_label_2',
  relationships___paragraph__2_zdjecia_opisy_link___field_image___alt = 'relationships___paragraph__2_zdjecia_opisy_link___field_image___alt',
  relationships___paragraph__2_zdjecia_opisy_link___field_image_1___alt = 'relationships___paragraph__2_zdjecia_opisy_link___field_image_1___alt',
  relationships___paragraph__2_zdjecia_opisy_link___field_nc_button_text = 'relationships___paragraph__2_zdjecia_opisy_link___field_nc_button_text',
  relationships___paragraph__2_zdjecia_opisy_link___field_text_1___value = 'relationships___paragraph__2_zdjecia_opisy_link___field_text_1___value',
  relationships___paragraph__2_zdjecia_opisy_link___field_text_2___value = 'relationships___paragraph__2_zdjecia_opisy_link___field_text_2___value',
  relationships___paragraph__2_zdjecia_opisy_link___field_btn_url = 'relationships___paragraph__2_zdjecia_opisy_link___field_btn_url',
  relationships___paragraph__2_zdjecia_opisy_link___field_only_text = 'relationships___paragraph__2_zdjecia_opisy_link___field_only_text',
  relationships___paragraph__2_zdjecia_opisy_link___field_short_text_1___value = 'relationships___paragraph__2_zdjecia_opisy_link___field_short_text_1___value',
  relationships___paragraph__2_zdjecia_opisy_link___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___paragraph__2_zdjecia_opisy_link___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___paragraph__2_zdjecia_opisy_link___drupal_id = 'relationships___paragraph__2_zdjecia_opisy_link___drupal_id',
  relationships___paragraph__2_zdjecia_opisy_link___drupal_internal__id = 'relationships___paragraph__2_zdjecia_opisy_link___drupal_internal__id',
  relationships___paragraph__2_zdjecia_opisy_link___drupal_internal__revision_id = 'relationships___paragraph__2_zdjecia_opisy_link___drupal_internal__revision_id',
  relationships___paragraph__2_zdjecia_opisy_link___langcode = 'relationships___paragraph__2_zdjecia_opisy_link___langcode',
  relationships___paragraph__2_zdjecia_opisy_link___status = 'relationships___paragraph__2_zdjecia_opisy_link___status',
  relationships___paragraph__2_zdjecia_opisy_link___created = 'relationships___paragraph__2_zdjecia_opisy_link___created',
  relationships___paragraph__2_zdjecia_opisy_link___parent_id = 'relationships___paragraph__2_zdjecia_opisy_link___parent_id',
  relationships___paragraph__2_zdjecia_opisy_link___parent_type = 'relationships___paragraph__2_zdjecia_opisy_link___parent_type',
  relationships___paragraph__2_zdjecia_opisy_link___parent_field_name = 'relationships___paragraph__2_zdjecia_opisy_link___parent_field_name',
  relationships___paragraph__2_zdjecia_opisy_link___default_langcode = 'relationships___paragraph__2_zdjecia_opisy_link___default_langcode',
  relationships___paragraph__2_zdjecia_opisy_link___revision_translation_affected = 'relationships___paragraph__2_zdjecia_opisy_link___revision_translation_affected',
  relationships___paragraph__2_zdjecia_opisy_link___content_translation_source = 'relationships___paragraph__2_zdjecia_opisy_link___content_translation_source',
  relationships___paragraph__2_zdjecia_opisy_link___content_translation_outdated = 'relationships___paragraph__2_zdjecia_opisy_link___content_translation_outdated',
  relationships___paragraph__2_zdjecia_opisy_link___content_translation_changed = 'relationships___paragraph__2_zdjecia_opisy_link___content_translation_changed',
  relationships___paragraph__2_zdjecia_opisy_link___uuid = 'relationships___paragraph__2_zdjecia_opisy_link___uuid',
  relationships___paragraph__2_zdjecia_opisy_link___id = 'relationships___paragraph__2_zdjecia_opisy_link___id',
  relationships___paragraph__2_zdjecia_opisy_link___parent___id = 'relationships___paragraph__2_zdjecia_opisy_link___parent___id',
  relationships___paragraph__2_zdjecia_opisy_link___parent___children = 'relationships___paragraph__2_zdjecia_opisy_link___parent___children',
  relationships___paragraph__2_zdjecia_opisy_link___children = 'relationships___paragraph__2_zdjecia_opisy_link___children',
  relationships___paragraph__2_zdjecia_opisy_link___children___id = 'relationships___paragraph__2_zdjecia_opisy_link___children___id',
  relationships___paragraph__2_zdjecia_opisy_link___children___children = 'relationships___paragraph__2_zdjecia_opisy_link___children___children',
  relationships___paragraph__2_zdjecia_opisy_link___internal___content = 'relationships___paragraph__2_zdjecia_opisy_link___internal___content',
  relationships___paragraph__2_zdjecia_opisy_link___internal___contentDigest = 'relationships___paragraph__2_zdjecia_opisy_link___internal___contentDigest',
  relationships___paragraph__2_zdjecia_opisy_link___internal___description = 'relationships___paragraph__2_zdjecia_opisy_link___internal___description',
  relationships___paragraph__2_zdjecia_opisy_link___internal___fieldOwners = 'relationships___paragraph__2_zdjecia_opisy_link___internal___fieldOwners',
  relationships___paragraph__2_zdjecia_opisy_link___internal___ignoreType = 'relationships___paragraph__2_zdjecia_opisy_link___internal___ignoreType',
  relationships___paragraph__2_zdjecia_opisy_link___internal___mediaType = 'relationships___paragraph__2_zdjecia_opisy_link___internal___mediaType',
  relationships___paragraph__2_zdjecia_opisy_link___internal___owner = 'relationships___paragraph__2_zdjecia_opisy_link___internal___owner',
  relationships___paragraph__2_zdjecia_opisy_link___internal___type = 'relationships___paragraph__2_zdjecia_opisy_link___internal___type',
  relationships___site_setting_entity__intercars = 'relationships___site_setting_entity__intercars',
  relationships___site_setting_entity__intercars___langcode = 'relationships___site_setting_entity__intercars___langcode',
  relationships___site_setting_entity__intercars___field_agreement_1___value = 'relationships___site_setting_entity__intercars___field_agreement_1___value',
  relationships___site_setting_entity__intercars___field_agreement_2 = 'relationships___site_setting_entity__intercars___field_agreement_2',
  relationships___site_setting_entity__intercars___field_agreement_3___value = 'relationships___site_setting_entity__intercars___field_agreement_3___value',
  relationships___site_setting_entity__intercars___field_hero_headline___value = 'relationships___site_setting_entity__intercars___field_hero_headline___value',
  relationships___site_setting_entity__intercars___field_image_1___alt = 'relationships___site_setting_entity__intercars___field_image_1___alt',
  relationships___site_setting_entity__intercars___field_info_text___value = 'relationships___site_setting_entity__intercars___field_info_text___value',
  relationships___site_setting_entity__intercars___field_meta_description = 'relationships___site_setting_entity__intercars___field_meta_description',
  relationships___site_setting_entity__intercars___field_meta_title = 'relationships___site_setting_entity__intercars___field_meta_title',
  relationships___site_setting_entity__intercars___field_teksty_formularza = 'relationships___site_setting_entity__intercars___field_teksty_formularza',
  relationships___site_setting_entity__intercars___field_text_1___value = 'relationships___site_setting_entity__intercars___field_text_1___value',
  relationships___site_setting_entity__intercars___drupal_id = 'relationships___site_setting_entity__intercars___drupal_id',
  relationships___site_setting_entity__intercars___drupal_internal__id = 'relationships___site_setting_entity__intercars___drupal_internal__id',
  relationships___site_setting_entity__intercars___name = 'relationships___site_setting_entity__intercars___name',
  relationships___site_setting_entity__intercars___fieldset = 'relationships___site_setting_entity__intercars___fieldset',
  relationships___site_setting_entity__intercars___status = 'relationships___site_setting_entity__intercars___status',
  relationships___site_setting_entity__intercars___created = 'relationships___site_setting_entity__intercars___created',
  relationships___site_setting_entity__intercars___changed = 'relationships___site_setting_entity__intercars___changed',
  relationships___site_setting_entity__intercars___default_langcode = 'relationships___site_setting_entity__intercars___default_langcode',
  relationships___site_setting_entity__intercars___content_translation_source = 'relationships___site_setting_entity__intercars___content_translation_source',
  relationships___site_setting_entity__intercars___content_translation_outdated = 'relationships___site_setting_entity__intercars___content_translation_outdated',
  relationships___site_setting_entity__intercars___content_translation_status = 'relationships___site_setting_entity__intercars___content_translation_status',
  relationships___site_setting_entity__intercars___content_translation_created = 'relationships___site_setting_entity__intercars___content_translation_created',
  relationships___site_setting_entity__intercars___content_translation_changed = 'relationships___site_setting_entity__intercars___content_translation_changed',
  relationships___site_setting_entity__intercars___uuid = 'relationships___site_setting_entity__intercars___uuid',
  relationships___site_setting_entity__intercars___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__intercars___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__intercars___id = 'relationships___site_setting_entity__intercars___id',
  relationships___site_setting_entity__intercars___parent___id = 'relationships___site_setting_entity__intercars___parent___id',
  relationships___site_setting_entity__intercars___parent___children = 'relationships___site_setting_entity__intercars___parent___children',
  relationships___site_setting_entity__intercars___children = 'relationships___site_setting_entity__intercars___children',
  relationships___site_setting_entity__intercars___children___id = 'relationships___site_setting_entity__intercars___children___id',
  relationships___site_setting_entity__intercars___children___children = 'relationships___site_setting_entity__intercars___children___children',
  relationships___site_setting_entity__intercars___internal___content = 'relationships___site_setting_entity__intercars___internal___content',
  relationships___site_setting_entity__intercars___internal___contentDigest = 'relationships___site_setting_entity__intercars___internal___contentDigest',
  relationships___site_setting_entity__intercars___internal___description = 'relationships___site_setting_entity__intercars___internal___description',
  relationships___site_setting_entity__intercars___internal___fieldOwners = 'relationships___site_setting_entity__intercars___internal___fieldOwners',
  relationships___site_setting_entity__intercars___internal___ignoreType = 'relationships___site_setting_entity__intercars___internal___ignoreType',
  relationships___site_setting_entity__intercars___internal___mediaType = 'relationships___site_setting_entity__intercars___internal___mediaType',
  relationships___site_setting_entity__intercars___internal___owner = 'relationships___site_setting_entity__intercars___internal___owner',
  relationships___site_setting_entity__intercars___internal___type = 'relationships___site_setting_entity__intercars___internal___type',
  relationships___paragraph__member_of_the_board_personal_dat = 'relationships___paragraph__member_of_the_board_personal_dat',
  relationships___paragraph__member_of_the_board_personal_dat___field_description_member_board___value = 'relationships___paragraph__member_of_the_board_personal_dat___field_description_member_board___value',
  relationships___paragraph__member_of_the_board_personal_dat___field_photo_member_of_the_board___alt = 'relationships___paragraph__member_of_the_board_personal_dat___field_photo_member_of_the_board___alt',
  relationships___paragraph__member_of_the_board_personal_dat___relationships___site_setting_entity__about_us = 'relationships___paragraph__member_of_the_board_personal_dat___relationships___site_setting_entity__about_us',
  relationships___paragraph__member_of_the_board_personal_dat___drupal_id = 'relationships___paragraph__member_of_the_board_personal_dat___drupal_id',
  relationships___paragraph__member_of_the_board_personal_dat___drupal_internal__id = 'relationships___paragraph__member_of_the_board_personal_dat___drupal_internal__id',
  relationships___paragraph__member_of_the_board_personal_dat___drupal_internal__revision_id = 'relationships___paragraph__member_of_the_board_personal_dat___drupal_internal__revision_id',
  relationships___paragraph__member_of_the_board_personal_dat___langcode = 'relationships___paragraph__member_of_the_board_personal_dat___langcode',
  relationships___paragraph__member_of_the_board_personal_dat___status = 'relationships___paragraph__member_of_the_board_personal_dat___status',
  relationships___paragraph__member_of_the_board_personal_dat___created = 'relationships___paragraph__member_of_the_board_personal_dat___created',
  relationships___paragraph__member_of_the_board_personal_dat___parent_id = 'relationships___paragraph__member_of_the_board_personal_dat___parent_id',
  relationships___paragraph__member_of_the_board_personal_dat___parent_type = 'relationships___paragraph__member_of_the_board_personal_dat___parent_type',
  relationships___paragraph__member_of_the_board_personal_dat___parent_field_name = 'relationships___paragraph__member_of_the_board_personal_dat___parent_field_name',
  relationships___paragraph__member_of_the_board_personal_dat___default_langcode = 'relationships___paragraph__member_of_the_board_personal_dat___default_langcode',
  relationships___paragraph__member_of_the_board_personal_dat___revision_translation_affected = 'relationships___paragraph__member_of_the_board_personal_dat___revision_translation_affected',
  relationships___paragraph__member_of_the_board_personal_dat___content_translation_source = 'relationships___paragraph__member_of_the_board_personal_dat___content_translation_source',
  relationships___paragraph__member_of_the_board_personal_dat___content_translation_outdated = 'relationships___paragraph__member_of_the_board_personal_dat___content_translation_outdated',
  relationships___paragraph__member_of_the_board_personal_dat___content_translation_changed = 'relationships___paragraph__member_of_the_board_personal_dat___content_translation_changed',
  relationships___paragraph__member_of_the_board_personal_dat___uuid = 'relationships___paragraph__member_of_the_board_personal_dat___uuid',
  relationships___paragraph__member_of_the_board_personal_dat___id = 'relationships___paragraph__member_of_the_board_personal_dat___id',
  relationships___paragraph__member_of_the_board_personal_dat___parent___id = 'relationships___paragraph__member_of_the_board_personal_dat___parent___id',
  relationships___paragraph__member_of_the_board_personal_dat___parent___children = 'relationships___paragraph__member_of_the_board_personal_dat___parent___children',
  relationships___paragraph__member_of_the_board_personal_dat___children = 'relationships___paragraph__member_of_the_board_personal_dat___children',
  relationships___paragraph__member_of_the_board_personal_dat___children___id = 'relationships___paragraph__member_of_the_board_personal_dat___children___id',
  relationships___paragraph__member_of_the_board_personal_dat___children___children = 'relationships___paragraph__member_of_the_board_personal_dat___children___children',
  relationships___paragraph__member_of_the_board_personal_dat___internal___content = 'relationships___paragraph__member_of_the_board_personal_dat___internal___content',
  relationships___paragraph__member_of_the_board_personal_dat___internal___contentDigest = 'relationships___paragraph__member_of_the_board_personal_dat___internal___contentDigest',
  relationships___paragraph__member_of_the_board_personal_dat___internal___description = 'relationships___paragraph__member_of_the_board_personal_dat___internal___description',
  relationships___paragraph__member_of_the_board_personal_dat___internal___fieldOwners = 'relationships___paragraph__member_of_the_board_personal_dat___internal___fieldOwners',
  relationships___paragraph__member_of_the_board_personal_dat___internal___ignoreType = 'relationships___paragraph__member_of_the_board_personal_dat___internal___ignoreType',
  relationships___paragraph__member_of_the_board_personal_dat___internal___mediaType = 'relationships___paragraph__member_of_the_board_personal_dat___internal___mediaType',
  relationships___paragraph__member_of_the_board_personal_dat___internal___owner = 'relationships___paragraph__member_of_the_board_personal_dat___internal___owner',
  relationships___paragraph__member_of_the_board_personal_dat___internal___type = 'relationships___paragraph__member_of_the_board_personal_dat___internal___type',
  relationships___node__agreement = 'relationships___node__agreement',
  relationships___node__agreement___langcode = 'relationships___node__agreement___langcode',
  relationships___node__agreement___drupal_id = 'relationships___node__agreement___drupal_id',
  relationships___node__agreement___agreement_content___value = 'relationships___node__agreement___agreement_content___value',
  relationships___node__agreement___agreement_explication___value = 'relationships___node__agreement___agreement_explication___value',
  relationships___node__agreement___agreement_type = 'relationships___node__agreement___agreement_type',
  relationships___node__agreement___agreement_pdf_title = 'relationships___node__agreement___agreement_pdf_title',
  relationships___node__agreement___relationships___agreement_payment_types = 'relationships___node__agreement___relationships___agreement_payment_types',
  relationships___node__agreement___relationships___paragraph__heading_and_agreements = 'relationships___node__agreement___relationships___paragraph__heading_and_agreements',
  relationships___node__agreement___drupal_internal__nid = 'relationships___node__agreement___drupal_internal__nid',
  relationships___node__agreement___drupal_internal__vid = 'relationships___node__agreement___drupal_internal__vid',
  relationships___node__agreement___status = 'relationships___node__agreement___status',
  relationships___node__agreement___title = 'relationships___node__agreement___title',
  relationships___node__agreement___created = 'relationships___node__agreement___created',
  relationships___node__agreement___changed = 'relationships___node__agreement___changed',
  relationships___node__agreement___uuid = 'relationships___node__agreement___uuid',
  relationships___node__agreement___agreement_payment_types = 'relationships___node__agreement___agreement_payment_types',
  relationships___node__agreement___agreement_payment_types___drupal_internal__target_id = 'relationships___node__agreement___agreement_payment_types___drupal_internal__target_id',
  relationships___node__agreement___agreement_pdf___drupal_internal__target_id = 'relationships___node__agreement___agreement_pdf___drupal_internal__target_id',
  relationships___node__agreement___id = 'relationships___node__agreement___id',
  relationships___node__agreement___parent___id = 'relationships___node__agreement___parent___id',
  relationships___node__agreement___parent___children = 'relationships___node__agreement___parent___children',
  relationships___node__agreement___children = 'relationships___node__agreement___children',
  relationships___node__agreement___children___id = 'relationships___node__agreement___children___id',
  relationships___node__agreement___children___children = 'relationships___node__agreement___children___children',
  relationships___node__agreement___internal___content = 'relationships___node__agreement___internal___content',
  relationships___node__agreement___internal___contentDigest = 'relationships___node__agreement___internal___contentDigest',
  relationships___node__agreement___internal___description = 'relationships___node__agreement___internal___description',
  relationships___node__agreement___internal___fieldOwners = 'relationships___node__agreement___internal___fieldOwners',
  relationships___node__agreement___internal___ignoreType = 'relationships___node__agreement___internal___ignoreType',
  relationships___node__agreement___internal___mediaType = 'relationships___node__agreement___internal___mediaType',
  relationships___node__agreement___internal___owner = 'relationships___node__agreement___internal___owner',
  relationships___node__agreement___internal___type = 'relationships___node__agreement___internal___type',
  relationships___node__fiscalization = 'relationships___node__fiscalization',
  relationships___node__fiscalization___langcode = 'relationships___node__fiscalization___langcode',
  relationships___node__fiscalization___drupal_id = 'relationships___node__fiscalization___drupal_id',
  relationships___node__fiscalization___title = 'relationships___node__fiscalization___title',
  relationships___node__fiscalization___description___value = 'relationships___node__fiscalization___description___value',
  relationships___node__fiscalization___appointment_available = 'relationships___node__fiscalization___appointment_available',
  relationships___node__fiscalization___is_free = 'relationships___node__fiscalization___is_free',
  relationships___node__fiscalization___is_popular = 'relationships___node__fiscalization___is_popular',
  relationships___node__fiscalization___price_list_name = 'relationships___node__fiscalization___price_list_name',
  relationships___node__fiscalization___field_weight = 'relationships___node__fiscalization___field_weight',
  relationships___node__fiscalization___calendar_text___value = 'relationships___node__fiscalization___calendar_text___value',
  relationships___node__fiscalization___disabled_fiscalization_messsage = 'relationships___node__fiscalization___disabled_fiscalization_messsage',
  relationships___node__fiscalization___relationships___available_for_devices = 'relationships___node__fiscalization___relationships___available_for_devices',
  relationships___node__fiscalization___drupal_internal__nid = 'relationships___node__fiscalization___drupal_internal__nid',
  relationships___node__fiscalization___drupal_internal__vid = 'relationships___node__fiscalization___drupal_internal__vid',
  relationships___node__fiscalization___status = 'relationships___node__fiscalization___status',
  relationships___node__fiscalization___created = 'relationships___node__fiscalization___created',
  relationships___node__fiscalization___changed = 'relationships___node__fiscalization___changed',
  relationships___node__fiscalization___weight = 'relationships___node__fiscalization___weight',
  relationships___node__fiscalization___uuid = 'relationships___node__fiscalization___uuid',
  relationships___node__fiscalization___available_for_devices = 'relationships___node__fiscalization___available_for_devices',
  relationships___node__fiscalization___available_for_devices___drupal_internal__target_id = 'relationships___node__fiscalization___available_for_devices___drupal_internal__target_id',
  relationships___node__fiscalization___image___alt = 'relationships___node__fiscalization___image___alt',
  relationships___node__fiscalization___image___title = 'relationships___node__fiscalization___image___title',
  relationships___node__fiscalization___image___width = 'relationships___node__fiscalization___image___width',
  relationships___node__fiscalization___image___height = 'relationships___node__fiscalization___image___height',
  relationships___node__fiscalization___image___drupal_internal__target_id = 'relationships___node__fiscalization___image___drupal_internal__target_id',
  relationships___node__fiscalization___id = 'relationships___node__fiscalization___id',
  relationships___node__fiscalization___parent___id = 'relationships___node__fiscalization___parent___id',
  relationships___node__fiscalization___parent___children = 'relationships___node__fiscalization___parent___children',
  relationships___node__fiscalization___children = 'relationships___node__fiscalization___children',
  relationships___node__fiscalization___children___id = 'relationships___node__fiscalization___children___id',
  relationships___node__fiscalization___children___children = 'relationships___node__fiscalization___children___children',
  relationships___node__fiscalization___internal___content = 'relationships___node__fiscalization___internal___content',
  relationships___node__fiscalization___internal___contentDigest = 'relationships___node__fiscalization___internal___contentDigest',
  relationships___node__fiscalization___internal___description = 'relationships___node__fiscalization___internal___description',
  relationships___node__fiscalization___internal___fieldOwners = 'relationships___node__fiscalization___internal___fieldOwners',
  relationships___node__fiscalization___internal___ignoreType = 'relationships___node__fiscalization___internal___ignoreType',
  relationships___node__fiscalization___internal___mediaType = 'relationships___node__fiscalization___internal___mediaType',
  relationships___node__fiscalization___internal___owner = 'relationships___node__fiscalization___internal___owner',
  relationships___node__fiscalization___internal___type = 'relationships___node__fiscalization___internal___type',
  relationships___site_setting_entity__configurator = 'relationships___site_setting_entity__configurator',
  relationships___site_setting_entity__configurator___langcode = 'relationships___site_setting_entity__configurator___langcode',
  relationships___site_setting_entity__configurator___field_calendar_available = 'relationships___site_setting_entity__configurator___field_calendar_available',
  relationships___site_setting_entity__configurator___field_info_text___value = 'relationships___site_setting_entity__configurator___field_info_text___value',
  relationships___site_setting_entity__configurator___field_enable_info_text = 'relationships___site_setting_entity__configurator___field_enable_info_text',
  relationships___site_setting_entity__configurator___field_proste_pola = 'relationships___site_setting_entity__configurator___field_proste_pola',
  relationships___site_setting_entity__configurator___field_meta_description = 'relationships___site_setting_entity__configurator___field_meta_description',
  relationships___site_setting_entity__configurator___field_meta_title = 'relationships___site_setting_entity__configurator___field_meta_title',
  relationships___site_setting_entity__configurator___field_pola_wysywig = 'relationships___site_setting_entity__configurator___field_pola_wysywig',
  relationships___site_setting_entity__configurator___field_pola_wysywig___value = 'relationships___site_setting_entity__configurator___field_pola_wysywig___value',
  relationships___site_setting_entity__configurator___relationships___field_images = 'relationships___site_setting_entity__configurator___relationships___field_images',
  relationships___site_setting_entity__configurator___drupal_id = 'relationships___site_setting_entity__configurator___drupal_id',
  relationships___site_setting_entity__configurator___drupal_internal__id = 'relationships___site_setting_entity__configurator___drupal_internal__id',
  relationships___site_setting_entity__configurator___name = 'relationships___site_setting_entity__configurator___name',
  relationships___site_setting_entity__configurator___fieldset = 'relationships___site_setting_entity__configurator___fieldset',
  relationships___site_setting_entity__configurator___status = 'relationships___site_setting_entity__configurator___status',
  relationships___site_setting_entity__configurator___created = 'relationships___site_setting_entity__configurator___created',
  relationships___site_setting_entity__configurator___changed = 'relationships___site_setting_entity__configurator___changed',
  relationships___site_setting_entity__configurator___default_langcode = 'relationships___site_setting_entity__configurator___default_langcode',
  relationships___site_setting_entity__configurator___content_translation_source = 'relationships___site_setting_entity__configurator___content_translation_source',
  relationships___site_setting_entity__configurator___content_translation_outdated = 'relationships___site_setting_entity__configurator___content_translation_outdated',
  relationships___site_setting_entity__configurator___content_translation_status = 'relationships___site_setting_entity__configurator___content_translation_status',
  relationships___site_setting_entity__configurator___content_translation_created = 'relationships___site_setting_entity__configurator___content_translation_created',
  relationships___site_setting_entity__configurator___content_translation_changed = 'relationships___site_setting_entity__configurator___content_translation_changed',
  relationships___site_setting_entity__configurator___uuid = 'relationships___site_setting_entity__configurator___uuid',
  relationships___site_setting_entity__configurator___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__configurator___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__configurator___field_images = 'relationships___site_setting_entity__configurator___field_images',
  relationships___site_setting_entity__configurator___field_images___alt = 'relationships___site_setting_entity__configurator___field_images___alt',
  relationships___site_setting_entity__configurator___field_images___title = 'relationships___site_setting_entity__configurator___field_images___title',
  relationships___site_setting_entity__configurator___field_images___width = 'relationships___site_setting_entity__configurator___field_images___width',
  relationships___site_setting_entity__configurator___field_images___height = 'relationships___site_setting_entity__configurator___field_images___height',
  relationships___site_setting_entity__configurator___field_images___drupal_internal__target_id = 'relationships___site_setting_entity__configurator___field_images___drupal_internal__target_id',
  relationships___site_setting_entity__configurator___id = 'relationships___site_setting_entity__configurator___id',
  relationships___site_setting_entity__configurator___parent___id = 'relationships___site_setting_entity__configurator___parent___id',
  relationships___site_setting_entity__configurator___parent___children = 'relationships___site_setting_entity__configurator___parent___children',
  relationships___site_setting_entity__configurator___children = 'relationships___site_setting_entity__configurator___children',
  relationships___site_setting_entity__configurator___children___id = 'relationships___site_setting_entity__configurator___children___id',
  relationships___site_setting_entity__configurator___children___children = 'relationships___site_setting_entity__configurator___children___children',
  relationships___site_setting_entity__configurator___internal___content = 'relationships___site_setting_entity__configurator___internal___content',
  relationships___site_setting_entity__configurator___internal___contentDigest = 'relationships___site_setting_entity__configurator___internal___contentDigest',
  relationships___site_setting_entity__configurator___internal___description = 'relationships___site_setting_entity__configurator___internal___description',
  relationships___site_setting_entity__configurator___internal___fieldOwners = 'relationships___site_setting_entity__configurator___internal___fieldOwners',
  relationships___site_setting_entity__configurator___internal___ignoreType = 'relationships___site_setting_entity__configurator___internal___ignoreType',
  relationships___site_setting_entity__configurator___internal___mediaType = 'relationships___site_setting_entity__configurator___internal___mediaType',
  relationships___site_setting_entity__configurator___internal___owner = 'relationships___site_setting_entity__configurator___internal___owner',
  relationships___site_setting_entity__configurator___internal___type = 'relationships___site_setting_entity__configurator___internal___type',
  relationships___paragraph__social_link = 'relationships___paragraph__social_link',
  relationships___paragraph__social_link___field_social_link___uri = 'relationships___paragraph__social_link___field_social_link___uri',
  relationships___paragraph__social_link___field_social_link___title = 'relationships___paragraph__social_link___field_social_link___title',
  relationships___paragraph__social_link___relationships___site_setting_entity__footer = 'relationships___paragraph__social_link___relationships___site_setting_entity__footer',
  relationships___paragraph__social_link___drupal_id = 'relationships___paragraph__social_link___drupal_id',
  relationships___paragraph__social_link___drupal_internal__id = 'relationships___paragraph__social_link___drupal_internal__id',
  relationships___paragraph__social_link___drupal_internal__revision_id = 'relationships___paragraph__social_link___drupal_internal__revision_id',
  relationships___paragraph__social_link___langcode = 'relationships___paragraph__social_link___langcode',
  relationships___paragraph__social_link___status = 'relationships___paragraph__social_link___status',
  relationships___paragraph__social_link___created = 'relationships___paragraph__social_link___created',
  relationships___paragraph__social_link___parent_id = 'relationships___paragraph__social_link___parent_id',
  relationships___paragraph__social_link___parent_type = 'relationships___paragraph__social_link___parent_type',
  relationships___paragraph__social_link___parent_field_name = 'relationships___paragraph__social_link___parent_field_name',
  relationships___paragraph__social_link___default_langcode = 'relationships___paragraph__social_link___default_langcode',
  relationships___paragraph__social_link___revision_translation_affected = 'relationships___paragraph__social_link___revision_translation_affected',
  relationships___paragraph__social_link___content_translation_source = 'relationships___paragraph__social_link___content_translation_source',
  relationships___paragraph__social_link___content_translation_outdated = 'relationships___paragraph__social_link___content_translation_outdated',
  relationships___paragraph__social_link___content_translation_changed = 'relationships___paragraph__social_link___content_translation_changed',
  relationships___paragraph__social_link___uuid = 'relationships___paragraph__social_link___uuid',
  relationships___paragraph__social_link___field_social_icon___alt = 'relationships___paragraph__social_link___field_social_icon___alt',
  relationships___paragraph__social_link___field_social_icon___title = 'relationships___paragraph__social_link___field_social_icon___title',
  relationships___paragraph__social_link___field_social_icon___width = 'relationships___paragraph__social_link___field_social_icon___width',
  relationships___paragraph__social_link___field_social_icon___height = 'relationships___paragraph__social_link___field_social_icon___height',
  relationships___paragraph__social_link___field_social_icon___drupal_internal__target_id = 'relationships___paragraph__social_link___field_social_icon___drupal_internal__target_id',
  relationships___paragraph__social_link___id = 'relationships___paragraph__social_link___id',
  relationships___paragraph__social_link___parent___id = 'relationships___paragraph__social_link___parent___id',
  relationships___paragraph__social_link___parent___children = 'relationships___paragraph__social_link___parent___children',
  relationships___paragraph__social_link___children = 'relationships___paragraph__social_link___children',
  relationships___paragraph__social_link___children___id = 'relationships___paragraph__social_link___children___id',
  relationships___paragraph__social_link___children___children = 'relationships___paragraph__social_link___children___children',
  relationships___paragraph__social_link___internal___content = 'relationships___paragraph__social_link___internal___content',
  relationships___paragraph__social_link___internal___contentDigest = 'relationships___paragraph__social_link___internal___contentDigest',
  relationships___paragraph__social_link___internal___description = 'relationships___paragraph__social_link___internal___description',
  relationships___paragraph__social_link___internal___fieldOwners = 'relationships___paragraph__social_link___internal___fieldOwners',
  relationships___paragraph__social_link___internal___ignoreType = 'relationships___paragraph__social_link___internal___ignoreType',
  relationships___paragraph__social_link___internal___mediaType = 'relationships___paragraph__social_link___internal___mediaType',
  relationships___paragraph__social_link___internal___owner = 'relationships___paragraph__social_link___internal___owner',
  relationships___paragraph__social_link___internal___type = 'relationships___paragraph__social_link___internal___type',
  relationships___paragraph__make_an_appointment = 'relationships___paragraph__make_an_appointment',
  relationships___paragraph__make_an_appointment___id = 'relationships___paragraph__make_an_appointment___id',
  relationships___paragraph__make_an_appointment___internal___content = 'relationships___paragraph__make_an_appointment___internal___content',
  relationships___paragraph__make_an_appointment___internal___contentDigest = 'relationships___paragraph__make_an_appointment___internal___contentDigest',
  relationships___paragraph__make_an_appointment___internal___description = 'relationships___paragraph__make_an_appointment___internal___description',
  relationships___paragraph__make_an_appointment___internal___fieldOwners = 'relationships___paragraph__make_an_appointment___internal___fieldOwners',
  relationships___paragraph__make_an_appointment___internal___ignoreType = 'relationships___paragraph__make_an_appointment___internal___ignoreType',
  relationships___paragraph__make_an_appointment___internal___mediaType = 'relationships___paragraph__make_an_appointment___internal___mediaType',
  relationships___paragraph__make_an_appointment___internal___owner = 'relationships___paragraph__make_an_appointment___internal___owner',
  relationships___paragraph__make_an_appointment___internal___type = 'relationships___paragraph__make_an_appointment___internal___type',
  relationships___paragraph__make_an_appointment___drupal_id = 'relationships___paragraph__make_an_appointment___drupal_id',
  relationships___paragraph__make_an_appointment___field_appointment_header = 'relationships___paragraph__make_an_appointment___field_appointment_header',
  relationships___paragraph__make_an_appointment___field_appointment_text___value = 'relationships___paragraph__make_an_appointment___field_appointment_text___value',
  relationships___paragraph__make_an_appointment___relationships___site_setting_entity__franchise = 'relationships___paragraph__make_an_appointment___relationships___site_setting_entity__franchise',
  relationships___paragraph__make_an_appointment___drupal_internal__id = 'relationships___paragraph__make_an_appointment___drupal_internal__id',
  relationships___paragraph__make_an_appointment___drupal_internal__revision_id = 'relationships___paragraph__make_an_appointment___drupal_internal__revision_id',
  relationships___paragraph__make_an_appointment___langcode = 'relationships___paragraph__make_an_appointment___langcode',
  relationships___paragraph__make_an_appointment___status = 'relationships___paragraph__make_an_appointment___status',
  relationships___paragraph__make_an_appointment___created = 'relationships___paragraph__make_an_appointment___created',
  relationships___paragraph__make_an_appointment___parent_id = 'relationships___paragraph__make_an_appointment___parent_id',
  relationships___paragraph__make_an_appointment___parent_type = 'relationships___paragraph__make_an_appointment___parent_type',
  relationships___paragraph__make_an_appointment___parent_field_name = 'relationships___paragraph__make_an_appointment___parent_field_name',
  relationships___paragraph__make_an_appointment___default_langcode = 'relationships___paragraph__make_an_appointment___default_langcode',
  relationships___paragraph__make_an_appointment___revision_translation_affected = 'relationships___paragraph__make_an_appointment___revision_translation_affected',
  relationships___paragraph__make_an_appointment___content_translation_source = 'relationships___paragraph__make_an_appointment___content_translation_source',
  relationships___paragraph__make_an_appointment___content_translation_outdated = 'relationships___paragraph__make_an_appointment___content_translation_outdated',
  relationships___paragraph__make_an_appointment___content_translation_changed = 'relationships___paragraph__make_an_appointment___content_translation_changed',
  relationships___paragraph__make_an_appointment___uuid = 'relationships___paragraph__make_an_appointment___uuid',
  relationships___paragraph__make_an_appointment___field_appointment_button___target_revision_id = 'relationships___paragraph__make_an_appointment___field_appointment_button___target_revision_id',
  relationships___paragraph__make_an_appointment___field_appointment_button___drupal_internal__target_id = 'relationships___paragraph__make_an_appointment___field_appointment_button___drupal_internal__target_id',
  relationships___paragraph__make_an_appointment___field_appointment_image___alt = 'relationships___paragraph__make_an_appointment___field_appointment_image___alt',
  relationships___paragraph__make_an_appointment___field_appointment_image___title = 'relationships___paragraph__make_an_appointment___field_appointment_image___title',
  relationships___paragraph__make_an_appointment___field_appointment_image___width = 'relationships___paragraph__make_an_appointment___field_appointment_image___width',
  relationships___paragraph__make_an_appointment___field_appointment_image___height = 'relationships___paragraph__make_an_appointment___field_appointment_image___height',
  relationships___paragraph__make_an_appointment___field_appointment_image___drupal_internal__target_id = 'relationships___paragraph__make_an_appointment___field_appointment_image___drupal_internal__target_id',
  relationships___paragraph__make_an_appointment___parent___id = 'relationships___paragraph__make_an_appointment___parent___id',
  relationships___paragraph__make_an_appointment___parent___children = 'relationships___paragraph__make_an_appointment___parent___children',
  relationships___paragraph__make_an_appointment___children = 'relationships___paragraph__make_an_appointment___children',
  relationships___paragraph__make_an_appointment___children___id = 'relationships___paragraph__make_an_appointment___children___id',
  relationships___paragraph__make_an_appointment___children___children = 'relationships___paragraph__make_an_appointment___children___children',
  relationships___paragraph__linked_icons = 'relationships___paragraph__linked_icons',
  relationships___paragraph__linked_icons___field_linked_icon_text = 'relationships___paragraph__linked_icons___field_linked_icon_text',
  relationships___paragraph__linked_icons___field_linked_icon_url = 'relationships___paragraph__linked_icons___field_linked_icon_url',
  relationships___paragraph__linked_icons___relationships___paragraph__icons_belt = 'relationships___paragraph__linked_icons___relationships___paragraph__icons_belt',
  relationships___paragraph__linked_icons___drupal_id = 'relationships___paragraph__linked_icons___drupal_id',
  relationships___paragraph__linked_icons___drupal_internal__id = 'relationships___paragraph__linked_icons___drupal_internal__id',
  relationships___paragraph__linked_icons___drupal_internal__revision_id = 'relationships___paragraph__linked_icons___drupal_internal__revision_id',
  relationships___paragraph__linked_icons___langcode = 'relationships___paragraph__linked_icons___langcode',
  relationships___paragraph__linked_icons___status = 'relationships___paragraph__linked_icons___status',
  relationships___paragraph__linked_icons___created = 'relationships___paragraph__linked_icons___created',
  relationships___paragraph__linked_icons___parent_id = 'relationships___paragraph__linked_icons___parent_id',
  relationships___paragraph__linked_icons___parent_type = 'relationships___paragraph__linked_icons___parent_type',
  relationships___paragraph__linked_icons___parent_field_name = 'relationships___paragraph__linked_icons___parent_field_name',
  relationships___paragraph__linked_icons___default_langcode = 'relationships___paragraph__linked_icons___default_langcode',
  relationships___paragraph__linked_icons___revision_translation_affected = 'relationships___paragraph__linked_icons___revision_translation_affected',
  relationships___paragraph__linked_icons___content_translation_source = 'relationships___paragraph__linked_icons___content_translation_source',
  relationships___paragraph__linked_icons___content_translation_outdated = 'relationships___paragraph__linked_icons___content_translation_outdated',
  relationships___paragraph__linked_icons___content_translation_changed = 'relationships___paragraph__linked_icons___content_translation_changed',
  relationships___paragraph__linked_icons___uuid = 'relationships___paragraph__linked_icons___uuid',
  relationships___paragraph__linked_icons___field_linked_icon_icon___alt = 'relationships___paragraph__linked_icons___field_linked_icon_icon___alt',
  relationships___paragraph__linked_icons___field_linked_icon_icon___title = 'relationships___paragraph__linked_icons___field_linked_icon_icon___title',
  relationships___paragraph__linked_icons___field_linked_icon_icon___width = 'relationships___paragraph__linked_icons___field_linked_icon_icon___width',
  relationships___paragraph__linked_icons___field_linked_icon_icon___height = 'relationships___paragraph__linked_icons___field_linked_icon_icon___height',
  relationships___paragraph__linked_icons___field_linked_icon_icon___drupal_internal__target_id = 'relationships___paragraph__linked_icons___field_linked_icon_icon___drupal_internal__target_id',
  relationships___paragraph__linked_icons___id = 'relationships___paragraph__linked_icons___id',
  relationships___paragraph__linked_icons___parent___id = 'relationships___paragraph__linked_icons___parent___id',
  relationships___paragraph__linked_icons___parent___children = 'relationships___paragraph__linked_icons___parent___children',
  relationships___paragraph__linked_icons___children = 'relationships___paragraph__linked_icons___children',
  relationships___paragraph__linked_icons___children___id = 'relationships___paragraph__linked_icons___children___id',
  relationships___paragraph__linked_icons___children___children = 'relationships___paragraph__linked_icons___children___children',
  relationships___paragraph__linked_icons___internal___content = 'relationships___paragraph__linked_icons___internal___content',
  relationships___paragraph__linked_icons___internal___contentDigest = 'relationships___paragraph__linked_icons___internal___contentDigest',
  relationships___paragraph__linked_icons___internal___description = 'relationships___paragraph__linked_icons___internal___description',
  relationships___paragraph__linked_icons___internal___fieldOwners = 'relationships___paragraph__linked_icons___internal___fieldOwners',
  relationships___paragraph__linked_icons___internal___ignoreType = 'relationships___paragraph__linked_icons___internal___ignoreType',
  relationships___paragraph__linked_icons___internal___mediaType = 'relationships___paragraph__linked_icons___internal___mediaType',
  relationships___paragraph__linked_icons___internal___owner = 'relationships___paragraph__linked_icons___internal___owner',
  relationships___paragraph__linked_icons___internal___type = 'relationships___paragraph__linked_icons___internal___type',
  relationships___site_setting_entity__financing = 'relationships___site_setting_entity__financing',
  relationships___site_setting_entity__financing___langcode = 'relationships___site_setting_entity__financing___langcode',
  relationships___site_setting_entity__financing___field_meta_description = 'relationships___site_setting_entity__financing___field_meta_description',
  relationships___site_setting_entity__financing___field_meta_title = 'relationships___site_setting_entity__financing___field_meta_title',
  relationships___site_setting_entity__financing___drupal_id = 'relationships___site_setting_entity__financing___drupal_id',
  relationships___site_setting_entity__financing___drupal_internal__id = 'relationships___site_setting_entity__financing___drupal_internal__id',
  relationships___site_setting_entity__financing___name = 'relationships___site_setting_entity__financing___name',
  relationships___site_setting_entity__financing___fieldset = 'relationships___site_setting_entity__financing___fieldset',
  relationships___site_setting_entity__financing___status = 'relationships___site_setting_entity__financing___status',
  relationships___site_setting_entity__financing___created = 'relationships___site_setting_entity__financing___created',
  relationships___site_setting_entity__financing___changed = 'relationships___site_setting_entity__financing___changed',
  relationships___site_setting_entity__financing___default_langcode = 'relationships___site_setting_entity__financing___default_langcode',
  relationships___site_setting_entity__financing___content_translation_source = 'relationships___site_setting_entity__financing___content_translation_source',
  relationships___site_setting_entity__financing___content_translation_outdated = 'relationships___site_setting_entity__financing___content_translation_outdated',
  relationships___site_setting_entity__financing___content_translation_status = 'relationships___site_setting_entity__financing___content_translation_status',
  relationships___site_setting_entity__financing___content_translation_created = 'relationships___site_setting_entity__financing___content_translation_created',
  relationships___site_setting_entity__financing___content_translation_changed = 'relationships___site_setting_entity__financing___content_translation_changed',
  relationships___site_setting_entity__financing___uuid = 'relationships___site_setting_entity__financing___uuid',
  relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_components = 'relationships___site_setting_entity__financing___field_components',
  relationships___site_setting_entity__financing___field_components___target_revision_id = 'relationships___site_setting_entity__financing___field_components___target_revision_id',
  relationships___site_setting_entity__financing___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_main_image___alt = 'relationships___site_setting_entity__financing___field_main_image___alt',
  relationships___site_setting_entity__financing___field_main_image___title = 'relationships___site_setting_entity__financing___field_main_image___title',
  relationships___site_setting_entity__financing___field_main_image___width = 'relationships___site_setting_entity__financing___field_main_image___width',
  relationships___site_setting_entity__financing___field_main_image___height = 'relationships___site_setting_entity__financing___field_main_image___height',
  relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__financing___id = 'relationships___site_setting_entity__financing___id',
  relationships___site_setting_entity__financing___parent___id = 'relationships___site_setting_entity__financing___parent___id',
  relationships___site_setting_entity__financing___parent___children = 'relationships___site_setting_entity__financing___parent___children',
  relationships___site_setting_entity__financing___children = 'relationships___site_setting_entity__financing___children',
  relationships___site_setting_entity__financing___children___id = 'relationships___site_setting_entity__financing___children___id',
  relationships___site_setting_entity__financing___children___children = 'relationships___site_setting_entity__financing___children___children',
  relationships___site_setting_entity__financing___internal___content = 'relationships___site_setting_entity__financing___internal___content',
  relationships___site_setting_entity__financing___internal___contentDigest = 'relationships___site_setting_entity__financing___internal___contentDigest',
  relationships___site_setting_entity__financing___internal___description = 'relationships___site_setting_entity__financing___internal___description',
  relationships___site_setting_entity__financing___internal___fieldOwners = 'relationships___site_setting_entity__financing___internal___fieldOwners',
  relationships___site_setting_entity__financing___internal___ignoreType = 'relationships___site_setting_entity__financing___internal___ignoreType',
  relationships___site_setting_entity__financing___internal___mediaType = 'relationships___site_setting_entity__financing___internal___mediaType',
  relationships___site_setting_entity__financing___internal___owner = 'relationships___site_setting_entity__financing___internal___owner',
  relationships___site_setting_entity__financing___internal___type = 'relationships___site_setting_entity__financing___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type File__FileFilterInput = {
  localFile?: Maybe<FileFilterInput>;
  uri?: Maybe<UriFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__fid?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  filename?: Maybe<StringQueryOperatorInput>;
  filemime?: Maybe<StringQueryOperatorInput>;
  filesize?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<File__FileRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type File__FileFilterListInput = {
  elemMatch?: Maybe<File__FileFilterInput>;
};

export type File__FileGroupConnection = {
  __typename?: 'file__fileGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<File__FileEdge>;
  nodes: Array<File__File>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type File__FileRelationships = {
  __typename?: 'file__fileRelationships';
  node__blog_post?: Maybe<Array<Maybe<Node__Blog_Post>>>;
  node__devices?: Maybe<Array<Maybe<Node__Devices>>>;
  node__accessories?: Maybe<Array<Maybe<Node__Accessories>>>;
  paragraph__news_image_title_text?: Maybe<Array<Maybe<Paragraph__News_Image_Title_Text>>>;
  node__cases?: Maybe<Array<Maybe<Node__Cases>>>;
  paragraph__branches_what_is_ipos?: Maybe<Array<Maybe<Paragraph__Branches_What_Is_Ipos>>>;
  paragraph__branches_features?: Maybe<Array<Maybe<Paragraph__Branches_Features>>>;
  paragraph__branches_check_what_s_going_on?: Maybe<
    Array<Maybe<Paragraph__Branches_Check_What_S_Going_On>>
  >;
  paragraph__branches_testimonials?: Maybe<Array<Maybe<Paragraph__Branches_Testimonials>>>;
  paragraph__branches_discounts?: Maybe<Array<Maybe<Paragraph__Branches_Discounts>>>;
  node__news?: Maybe<Array<Maybe<Node__News>>>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
  paragraph__branches_hero?: Maybe<Array<Maybe<Paragraph__Branches_Hero>>>;
  paragraph__bullets?: Maybe<Array<Maybe<Paragraph__Bullets>>>;
  taxonomy_term__pricelist_group?: Maybe<Array<Maybe<Taxonomy_Term__Pricelist_Group>>>;
  paragraph__branches_device?: Maybe<Array<Maybe<Paragraph__Branches_Device>>>;
  paragraph__branches_recommendation?: Maybe<Array<Maybe<Paragraph__Branches_Recommendation>>>;
  site_setting_entity__accessories?: Maybe<Array<Maybe<Site_Setting_Entity__Accessories>>>;
  site_setting_entity__branches?: Maybe<Array<Maybe<Site_Setting_Entity__Branches>>>;
  site_setting_entity__about_us?: Maybe<Array<Maybe<Site_Setting_Entity__About_Us>>>;
  paragraph__logos?: Maybe<Array<Maybe<Paragraph__Logos>>>;
  paragraph__how_it_works?: Maybe<Array<Maybe<Paragraph__How_It_Works>>>;
  paragraph__ico_and_text?: Maybe<Array<Maybe<Paragraph__Ico_And_Text>>>;
  paragraph__hero_1?: Maybe<Array<Maybe<Paragraph__Hero_1>>>;
  paragraph__comprehensive_solution_item?: Maybe<
    Array<Maybe<Paragraph__Comprehensive_Solution_Item>>
  >;
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
  paragraph__zdjecie_opis_link_ico?: Maybe<Array<Maybe<Paragraph__Zdjecie_Opis_Link_Ico>>>;
  paragraph__porownanie_terminali?: Maybe<Array<Maybe<Paragraph__Porownanie_Terminali>>>;
  paragraph__zdjecie_opis_link?: Maybe<Array<Maybe<Paragraph__Zdjecie_Opis_Link>>>;
  site_setting_entity__main_page?: Maybe<Array<Maybe<Site_Setting_Entity__Main_Page>>>;
  site_setting_entity__kasa_fiskalna_vs_kasoterminal?: Maybe<
    Array<Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal>>
  >;
  paragraph__2_zdjecia_opisy_link?: Maybe<Array<Maybe<Paragraph__2_Zdjecia_Opisy_Link>>>;
  site_setting_entity__intercars?: Maybe<Array<Maybe<Site_Setting_Entity__Intercars>>>;
  paragraph__member_of_the_board_personal_dat?: Maybe<
    Array<Maybe<Paragraph__Member_Of_The_Board_Personal_Dat>>
  >;
  node__agreement?: Maybe<Array<Maybe<Node__Agreement>>>;
  node__fiscalization?: Maybe<Array<Maybe<Node__Fiscalization>>>;
  site_setting_entity__configurator?: Maybe<Array<Maybe<Site_Setting_Entity__Configurator>>>;
  paragraph__social_link?: Maybe<Array<Maybe<Paragraph__Social_Link>>>;
  paragraph__make_an_appointment?: Maybe<Array<Maybe<Paragraph__Make_An_Appointment>>>;
  paragraph__linked_icons?: Maybe<Array<Maybe<Paragraph__Linked_Icons>>>;
  site_setting_entity__financing?: Maybe<Array<Maybe<Site_Setting_Entity__Financing>>>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
};

export type File__FileRelationshipsFilterInput = {
  node__blog_post?: Maybe<Node__Blog_PostFilterListInput>;
  node__devices?: Maybe<Node__DevicesFilterListInput>;
  node__accessories?: Maybe<Node__AccessoriesFilterListInput>;
  paragraph__news_image_title_text?: Maybe<Paragraph__News_Image_Title_TextFilterListInput>;
  node__cases?: Maybe<Node__CasesFilterListInput>;
  paragraph__branches_what_is_ipos?: Maybe<Paragraph__Branches_What_Is_IposFilterListInput>;
  paragraph__branches_features?: Maybe<Paragraph__Branches_FeaturesFilterListInput>;
  paragraph__branches_check_what_s_going_on?: Maybe<
    Paragraph__Branches_Check_What_S_Going_OnFilterListInput
  >;
  paragraph__branches_testimonials?: Maybe<Paragraph__Branches_TestimonialsFilterListInput>;
  paragraph__branches_discounts?: Maybe<Paragraph__Branches_DiscountsFilterListInput>;
  node__news?: Maybe<Node__NewsFilterListInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
  paragraph__branches_hero?: Maybe<Paragraph__Branches_HeroFilterListInput>;
  paragraph__bullets?: Maybe<Paragraph__BulletsFilterListInput>;
  taxonomy_term__pricelist_group?: Maybe<Taxonomy_Term__Pricelist_GroupFilterListInput>;
  paragraph__branches_device?: Maybe<Paragraph__Branches_DeviceFilterListInput>;
  paragraph__branches_recommendation?: Maybe<Paragraph__Branches_RecommendationFilterListInput>;
  site_setting_entity__accessories?: Maybe<Site_Setting_Entity__AccessoriesFilterListInput>;
  site_setting_entity__branches?: Maybe<Site_Setting_Entity__BranchesFilterListInput>;
  site_setting_entity__about_us?: Maybe<Site_Setting_Entity__About_UsFilterListInput>;
  paragraph__logos?: Maybe<Paragraph__LogosFilterListInput>;
  paragraph__how_it_works?: Maybe<Paragraph__How_It_WorksFilterListInput>;
  paragraph__ico_and_text?: Maybe<Paragraph__Ico_And_TextFilterListInput>;
  paragraph__hero_1?: Maybe<Paragraph__Hero_1FilterListInput>;
  paragraph__comprehensive_solution_item?: Maybe<
    Paragraph__Comprehensive_Solution_ItemFilterListInput
  >;
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
  paragraph__zdjecie_opis_link_ico?: Maybe<Paragraph__Zdjecie_Opis_Link_IcoFilterListInput>;
  paragraph__porownanie_terminali?: Maybe<Paragraph__Porownanie_TerminaliFilterListInput>;
  paragraph__zdjecie_opis_link?: Maybe<Paragraph__Zdjecie_Opis_LinkFilterListInput>;
  site_setting_entity__main_page?: Maybe<Site_Setting_Entity__Main_PageFilterListInput>;
  site_setting_entity__kasa_fiskalna_vs_kasoterminal?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFilterListInput
  >;
  paragraph__2_zdjecia_opisy_link?: Maybe<Paragraph__2_Zdjecia_Opisy_LinkFilterListInput>;
  site_setting_entity__intercars?: Maybe<Site_Setting_Entity__IntercarsFilterListInput>;
  paragraph__member_of_the_board_personal_dat?: Maybe<
    Paragraph__Member_Of_The_Board_Personal_DatFilterListInput
  >;
  node__agreement?: Maybe<Node__AgreementFilterListInput>;
  node__fiscalization?: Maybe<Node__FiscalizationFilterListInput>;
  site_setting_entity__configurator?: Maybe<Site_Setting_Entity__ConfiguratorFilterListInput>;
  paragraph__social_link?: Maybe<Paragraph__Social_LinkFilterListInput>;
  paragraph__make_an_appointment?: Maybe<Paragraph__Make_An_AppointmentFilterListInput>;
  paragraph__linked_icons?: Maybe<Paragraph__Linked_IconsFilterListInput>;
  site_setting_entity__financing?: Maybe<Site_Setting_Entity__FinancingFilterListInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
};

export type File__FileSortInput = {
  fields?: Maybe<Array<Maybe<File__FileFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type File__FileUri = Node & {
  __typename?: 'file__fileUri';
  value?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type File__FileUriConnection = {
  __typename?: 'file__fileUriConnection';
  totalCount: Scalars['Int'];
  edges: Array<File__FileUriEdge>;
  nodes: Array<File__FileUri>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<File__FileUriGroupConnection>;
};

export type File__FileUriConnectionDistinctArgs = {
  field: File__FileUriFieldsEnum;
};

export type File__FileUriConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: File__FileUriFieldsEnum;
};

export type File__FileUriEdge = {
  __typename?: 'file__fileUriEdge';
  next?: Maybe<File__FileUri>;
  node: File__FileUri;
  previous?: Maybe<File__FileUri>;
};

export enum File__FileUriFieldsEnum {
  value = 'value',
  url = 'url',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type File__FileUriFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
  url?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type File__FileUriGroupConnection = {
  __typename?: 'file__fileUriGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<File__FileUriEdge>;
  nodes: Array<File__FileUri>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type File__FileUriSortInput = {
  fields?: Maybe<Array<Maybe<File__FileUriFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type FileConnection = {
  __typename?: 'FileConnection';
  totalCount: Scalars['Int'];
  edges: Array<FileEdge>;
  nodes: Array<File>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<FileGroupConnection>;
};

export type FileConnectionDistinctArgs = {
  field: FileFieldsEnum;
};

export type FileConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: FileFieldsEnum;
};

export type FileEdge = {
  __typename?: 'FileEdge';
  next?: Maybe<File>;
  node: File;
  previous?: Maybe<File>;
};

export enum FileFieldsEnum {
  birthtime = 'birthtime',
  birthtimeMs = 'birthtimeMs',
  childImageSharp___id = 'childImageSharp___id',
  childImageSharp___fixed___base64 = 'childImageSharp___fixed___base64',
  childImageSharp___fixed___tracedSVG = 'childImageSharp___fixed___tracedSVG',
  childImageSharp___fixed___aspectRatio = 'childImageSharp___fixed___aspectRatio',
  childImageSharp___fixed___width = 'childImageSharp___fixed___width',
  childImageSharp___fixed___height = 'childImageSharp___fixed___height',
  childImageSharp___fixed___src = 'childImageSharp___fixed___src',
  childImageSharp___fixed___srcSet = 'childImageSharp___fixed___srcSet',
  childImageSharp___fixed___srcWebp = 'childImageSharp___fixed___srcWebp',
  childImageSharp___fixed___srcSetWebp = 'childImageSharp___fixed___srcSetWebp',
  childImageSharp___fixed___originalName = 'childImageSharp___fixed___originalName',
  childImageSharp___resolutions___base64 = 'childImageSharp___resolutions___base64',
  childImageSharp___resolutions___tracedSVG = 'childImageSharp___resolutions___tracedSVG',
  childImageSharp___resolutions___aspectRatio = 'childImageSharp___resolutions___aspectRatio',
  childImageSharp___resolutions___width = 'childImageSharp___resolutions___width',
  childImageSharp___resolutions___height = 'childImageSharp___resolutions___height',
  childImageSharp___resolutions___src = 'childImageSharp___resolutions___src',
  childImageSharp___resolutions___srcSet = 'childImageSharp___resolutions___srcSet',
  childImageSharp___resolutions___srcWebp = 'childImageSharp___resolutions___srcWebp',
  childImageSharp___resolutions___srcSetWebp = 'childImageSharp___resolutions___srcSetWebp',
  childImageSharp___resolutions___originalName = 'childImageSharp___resolutions___originalName',
  childImageSharp___fluid___base64 = 'childImageSharp___fluid___base64',
  childImageSharp___fluid___tracedSVG = 'childImageSharp___fluid___tracedSVG',
  childImageSharp___fluid___aspectRatio = 'childImageSharp___fluid___aspectRatio',
  childImageSharp___fluid___src = 'childImageSharp___fluid___src',
  childImageSharp___fluid___srcSet = 'childImageSharp___fluid___srcSet',
  childImageSharp___fluid___srcWebp = 'childImageSharp___fluid___srcWebp',
  childImageSharp___fluid___srcSetWebp = 'childImageSharp___fluid___srcSetWebp',
  childImageSharp___fluid___sizes = 'childImageSharp___fluid___sizes',
  childImageSharp___fluid___originalImg = 'childImageSharp___fluid___originalImg',
  childImageSharp___fluid___originalName = 'childImageSharp___fluid___originalName',
  childImageSharp___fluid___presentationWidth = 'childImageSharp___fluid___presentationWidth',
  childImageSharp___fluid___presentationHeight = 'childImageSharp___fluid___presentationHeight',
  childImageSharp___sizes___base64 = 'childImageSharp___sizes___base64',
  childImageSharp___sizes___tracedSVG = 'childImageSharp___sizes___tracedSVG',
  childImageSharp___sizes___aspectRatio = 'childImageSharp___sizes___aspectRatio',
  childImageSharp___sizes___src = 'childImageSharp___sizes___src',
  childImageSharp___sizes___srcSet = 'childImageSharp___sizes___srcSet',
  childImageSharp___sizes___srcWebp = 'childImageSharp___sizes___srcWebp',
  childImageSharp___sizes___srcSetWebp = 'childImageSharp___sizes___srcSetWebp',
  childImageSharp___sizes___sizes = 'childImageSharp___sizes___sizes',
  childImageSharp___sizes___originalImg = 'childImageSharp___sizes___originalImg',
  childImageSharp___sizes___originalName = 'childImageSharp___sizes___originalName',
  childImageSharp___sizes___presentationWidth = 'childImageSharp___sizes___presentationWidth',
  childImageSharp___sizes___presentationHeight = 'childImageSharp___sizes___presentationHeight',
  childImageSharp___original___width = 'childImageSharp___original___width',
  childImageSharp___original___height = 'childImageSharp___original___height',
  childImageSharp___original___src = 'childImageSharp___original___src',
  childImageSharp___resize___src = 'childImageSharp___resize___src',
  childImageSharp___resize___tracedSVG = 'childImageSharp___resize___tracedSVG',
  childImageSharp___resize___width = 'childImageSharp___resize___width',
  childImageSharp___resize___height = 'childImageSharp___resize___height',
  childImageSharp___resize___aspectRatio = 'childImageSharp___resize___aspectRatio',
  childImageSharp___resize___originalName = 'childImageSharp___resize___originalName',
  childImageSharp___parent___id = 'childImageSharp___parent___id',
  childImageSharp___parent___parent___id = 'childImageSharp___parent___parent___id',
  childImageSharp___parent___parent___children = 'childImageSharp___parent___parent___children',
  childImageSharp___parent___children = 'childImageSharp___parent___children',
  childImageSharp___parent___children___id = 'childImageSharp___parent___children___id',
  childImageSharp___parent___children___children = 'childImageSharp___parent___children___children',
  childImageSharp___parent___internal___content = 'childImageSharp___parent___internal___content',
  childImageSharp___parent___internal___contentDigest = 'childImageSharp___parent___internal___contentDigest',
  childImageSharp___parent___internal___description = 'childImageSharp___parent___internal___description',
  childImageSharp___parent___internal___fieldOwners = 'childImageSharp___parent___internal___fieldOwners',
  childImageSharp___parent___internal___ignoreType = 'childImageSharp___parent___internal___ignoreType',
  childImageSharp___parent___internal___mediaType = 'childImageSharp___parent___internal___mediaType',
  childImageSharp___parent___internal___owner = 'childImageSharp___parent___internal___owner',
  childImageSharp___parent___internal___type = 'childImageSharp___parent___internal___type',
  childImageSharp___children = 'childImageSharp___children',
  childImageSharp___children___id = 'childImageSharp___children___id',
  childImageSharp___children___parent___id = 'childImageSharp___children___parent___id',
  childImageSharp___children___parent___children = 'childImageSharp___children___parent___children',
  childImageSharp___children___children = 'childImageSharp___children___children',
  childImageSharp___children___children___id = 'childImageSharp___children___children___id',
  childImageSharp___children___children___children = 'childImageSharp___children___children___children',
  childImageSharp___children___internal___content = 'childImageSharp___children___internal___content',
  childImageSharp___children___internal___contentDigest = 'childImageSharp___children___internal___contentDigest',
  childImageSharp___children___internal___description = 'childImageSharp___children___internal___description',
  childImageSharp___children___internal___fieldOwners = 'childImageSharp___children___internal___fieldOwners',
  childImageSharp___children___internal___ignoreType = 'childImageSharp___children___internal___ignoreType',
  childImageSharp___children___internal___mediaType = 'childImageSharp___children___internal___mediaType',
  childImageSharp___children___internal___owner = 'childImageSharp___children___internal___owner',
  childImageSharp___children___internal___type = 'childImageSharp___children___internal___type',
  childImageSharp___internal___content = 'childImageSharp___internal___content',
  childImageSharp___internal___contentDigest = 'childImageSharp___internal___contentDigest',
  childImageSharp___internal___description = 'childImageSharp___internal___description',
  childImageSharp___internal___fieldOwners = 'childImageSharp___internal___fieldOwners',
  childImageSharp___internal___ignoreType = 'childImageSharp___internal___ignoreType',
  childImageSharp___internal___mediaType = 'childImageSharp___internal___mediaType',
  childImageSharp___internal___owner = 'childImageSharp___internal___owner',
  childImageSharp___internal___type = 'childImageSharp___internal___type',
  publicURL = 'publicURL',
  sourceInstanceName = 'sourceInstanceName',
  absolutePath = 'absolutePath',
  relativePath = 'relativePath',
  extension = 'extension',
  size = 'size',
  prettySize = 'prettySize',
  modifiedTime = 'modifiedTime',
  accessTime = 'accessTime',
  changeTime = 'changeTime',
  birthTime = 'birthTime',
  root = 'root',
  dir = 'dir',
  base = 'base',
  ext = 'ext',
  name = 'name',
  relativeDirectory = 'relativeDirectory',
  dev = 'dev',
  mode = 'mode',
  nlink = 'nlink',
  uid = 'uid',
  gid = 'gid',
  rdev = 'rdev',
  blksize = 'blksize',
  ino = 'ino',
  blocks = 'blocks',
  atimeMs = 'atimeMs',
  mtimeMs = 'mtimeMs',
  ctimeMs = 'ctimeMs',
  atime = 'atime',
  mtime = 'mtime',
  ctime = 'ctime',
  url = 'url',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type FileFilterInput = {
  birthtime?: Maybe<DateQueryOperatorInput>;
  birthtimeMs?: Maybe<FloatQueryOperatorInput>;
  childImageSharp?: Maybe<ImageSharpFilterInput>;
  publicURL?: Maybe<StringQueryOperatorInput>;
  sourceInstanceName?: Maybe<StringQueryOperatorInput>;
  absolutePath?: Maybe<StringQueryOperatorInput>;
  relativePath?: Maybe<StringQueryOperatorInput>;
  extension?: Maybe<StringQueryOperatorInput>;
  size?: Maybe<IntQueryOperatorInput>;
  prettySize?: Maybe<StringQueryOperatorInput>;
  modifiedTime?: Maybe<DateQueryOperatorInput>;
  accessTime?: Maybe<DateQueryOperatorInput>;
  changeTime?: Maybe<DateQueryOperatorInput>;
  birthTime?: Maybe<DateQueryOperatorInput>;
  root?: Maybe<StringQueryOperatorInput>;
  dir?: Maybe<StringQueryOperatorInput>;
  base?: Maybe<StringQueryOperatorInput>;
  ext?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  relativeDirectory?: Maybe<StringQueryOperatorInput>;
  dev?: Maybe<IntQueryOperatorInput>;
  mode?: Maybe<IntQueryOperatorInput>;
  nlink?: Maybe<IntQueryOperatorInput>;
  uid?: Maybe<IntQueryOperatorInput>;
  gid?: Maybe<IntQueryOperatorInput>;
  rdev?: Maybe<IntQueryOperatorInput>;
  blksize?: Maybe<IntQueryOperatorInput>;
  ino?: Maybe<IntQueryOperatorInput>;
  blocks?: Maybe<IntQueryOperatorInput>;
  atimeMs?: Maybe<FloatQueryOperatorInput>;
  mtimeMs?: Maybe<FloatQueryOperatorInput>;
  ctimeMs?: Maybe<FloatQueryOperatorInput>;
  atime?: Maybe<DateQueryOperatorInput>;
  mtime?: Maybe<DateQueryOperatorInput>;
  ctime?: Maybe<DateQueryOperatorInput>;
  url?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type FileGroupConnection = {
  __typename?: 'FileGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<FileEdge>;
  nodes: Array<File>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type FileSortInput = {
  fields?: Maybe<Array<Maybe<FileFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Fiscalization_Calendar_Text = {
  __typename?: 'fiscalization_calendar_text';
  value?: Maybe<Scalars['String']>;
};

export type Fiscalization_Calendar_TextFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Fiscalization_Description = {
  __typename?: 'fiscalization_description';
  value?: Maybe<Scalars['String']>;
};

export type Fiscalization_DescriptionFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type FloatQueryOperatorInput = {
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FranchiseRelatedComponents =
  | Paragraph__Branches_Check_What_S_Going_On
  | Paragraph__Branches_Hero
  | Paragraph__Branches_Recommendation
  | Paragraph__Bullets_Container
  | Paragraph__News_Text
  | Paragraph__Logos
  | Paragraph__How_It_Works
  | Paragraph__Title_Icons
  | Paragraph__Make_An_Appointment;

export type FundingsRelatedComponents =
  | Paragraph__Branches_Hero
  | Paragraph__Branches_Saving_Up_Block
  | Paragraph__Bullets_Container
  | Paragraph__News_Text;

export type HomepageRelatedComponents =
  | Paragraph__Hero_1
  | Paragraph__Branches_Recommendation
  | Paragraph__Comprehensive_Solution
  | Paragraph__Bullets_Container
  | Paragraph__Recommended_Blog_Posts;

export enum ImageCropFocus {
  CENTER = 'CENTER',
  NORTH = 'NORTH',
  NORTHEAST = 'NORTHEAST',
  EAST = 'EAST',
  SOUTHEAST = 'SOUTHEAST',
  SOUTH = 'SOUTH',
  SOUTHWEST = 'SOUTHWEST',
  WEST = 'WEST',
  NORTHWEST = 'NORTHWEST',
  ENTROPY = 'ENTROPY',
  ATTENTION = 'ATTENTION',
}

export enum ImageFit {
  COVER = 'COVER',
  CONTAIN = 'CONTAIN',
  FILL = 'FILL',
}

export enum ImageFormat {
  NO_CHANGE = 'NO_CHANGE',
  JPG = 'JPG',
  PNG = 'PNG',
  WEBP = 'WEBP',
}

export type ImageSharp = Node & {
  __typename?: 'ImageSharp';
  id: Scalars['ID'];
  fixed?: Maybe<ImageSharpFixed>;
  resolutions?: Maybe<ImageSharpResolutions>;
  fluid?: Maybe<ImageSharpFluid>;
  sizes?: Maybe<ImageSharpSizes>;
  original?: Maybe<ImageSharpOriginal>;
  resize?: Maybe<ImageSharpResize>;
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type ImageSharpFixedArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  base64Width?: Maybe<Scalars['Int']>;
  jpegProgressive?: Maybe<Scalars['Boolean']>;
  pngCompressionSpeed?: Maybe<Scalars['Int']>;
  grayscale?: Maybe<Scalars['Boolean']>;
  duotone?: Maybe<DuotoneGradient>;
  traceSVG?: Maybe<Potrace>;
  quality?: Maybe<Scalars['Int']>;
  toFormat?: Maybe<ImageFormat>;
  toFormatBase64?: Maybe<ImageFormat>;
  cropFocus?: Maybe<ImageCropFocus>;
  fit?: Maybe<ImageFit>;
  background?: Maybe<Scalars['String']>;
  rotate?: Maybe<Scalars['Int']>;
  trim?: Maybe<Scalars['Float']>;
};

export type ImageSharpResolutionsArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  base64Width?: Maybe<Scalars['Int']>;
  jpegProgressive?: Maybe<Scalars['Boolean']>;
  pngCompressionSpeed?: Maybe<Scalars['Int']>;
  grayscale?: Maybe<Scalars['Boolean']>;
  duotone?: Maybe<DuotoneGradient>;
  traceSVG?: Maybe<Potrace>;
  quality?: Maybe<Scalars['Int']>;
  toFormat?: Maybe<ImageFormat>;
  toFormatBase64?: Maybe<ImageFormat>;
  cropFocus?: Maybe<ImageCropFocus>;
  fit?: Maybe<ImageFit>;
  background?: Maybe<Scalars['String']>;
  rotate?: Maybe<Scalars['Int']>;
  trim?: Maybe<Scalars['Float']>;
};

export type ImageSharpFluidArgs = {
  maxWidth?: Maybe<Scalars['Int']>;
  maxHeight?: Maybe<Scalars['Int']>;
  base64Width?: Maybe<Scalars['Int']>;
  grayscale?: Maybe<Scalars['Boolean']>;
  jpegProgressive?: Maybe<Scalars['Boolean']>;
  pngCompressionSpeed?: Maybe<Scalars['Int']>;
  duotone?: Maybe<DuotoneGradient>;
  traceSVG?: Maybe<Potrace>;
  quality?: Maybe<Scalars['Int']>;
  toFormat?: Maybe<ImageFormat>;
  toFormatBase64?: Maybe<ImageFormat>;
  cropFocus?: Maybe<ImageCropFocus>;
  fit?: Maybe<ImageFit>;
  background?: Maybe<Scalars['String']>;
  rotate?: Maybe<Scalars['Int']>;
  trim?: Maybe<Scalars['Float']>;
  sizes?: Maybe<Scalars['String']>;
  srcSetBreakpoints?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ImageSharpSizesArgs = {
  maxWidth?: Maybe<Scalars['Int']>;
  maxHeight?: Maybe<Scalars['Int']>;
  base64Width?: Maybe<Scalars['Int']>;
  grayscale?: Maybe<Scalars['Boolean']>;
  jpegProgressive?: Maybe<Scalars['Boolean']>;
  pngCompressionSpeed?: Maybe<Scalars['Int']>;
  duotone?: Maybe<DuotoneGradient>;
  traceSVG?: Maybe<Potrace>;
  quality?: Maybe<Scalars['Int']>;
  toFormat?: Maybe<ImageFormat>;
  toFormatBase64?: Maybe<ImageFormat>;
  cropFocus?: Maybe<ImageCropFocus>;
  fit?: Maybe<ImageFit>;
  background?: Maybe<Scalars['String']>;
  rotate?: Maybe<Scalars['Int']>;
  trim?: Maybe<Scalars['Float']>;
  sizes?: Maybe<Scalars['String']>;
  srcSetBreakpoints?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ImageSharpResizeArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  quality?: Maybe<Scalars['Int']>;
  jpegProgressive?: Maybe<Scalars['Boolean']>;
  pngCompressionLevel?: Maybe<Scalars['Int']>;
  pngCompressionSpeed?: Maybe<Scalars['Int']>;
  grayscale?: Maybe<Scalars['Boolean']>;
  duotone?: Maybe<DuotoneGradient>;
  base64?: Maybe<Scalars['Boolean']>;
  traceSVG?: Maybe<Potrace>;
  toFormat?: Maybe<ImageFormat>;
  cropFocus?: Maybe<ImageCropFocus>;
  fit?: Maybe<ImageFit>;
  background?: Maybe<Scalars['String']>;
  rotate?: Maybe<Scalars['Int']>;
  trim?: Maybe<Scalars['Float']>;
};

export type ImageSharpConnection = {
  __typename?: 'ImageSharpConnection';
  totalCount: Scalars['Int'];
  edges: Array<ImageSharpEdge>;
  nodes: Array<ImageSharp>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<ImageSharpGroupConnection>;
};

export type ImageSharpConnectionDistinctArgs = {
  field: ImageSharpFieldsEnum;
};

export type ImageSharpConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: ImageSharpFieldsEnum;
};

export type ImageSharpEdge = {
  __typename?: 'ImageSharpEdge';
  next?: Maybe<ImageSharp>;
  node: ImageSharp;
  previous?: Maybe<ImageSharp>;
};

export enum ImageSharpFieldsEnum {
  id = 'id',
  fixed___base64 = 'fixed___base64',
  fixed___tracedSVG = 'fixed___tracedSVG',
  fixed___aspectRatio = 'fixed___aspectRatio',
  fixed___width = 'fixed___width',
  fixed___height = 'fixed___height',
  fixed___src = 'fixed___src',
  fixed___srcSet = 'fixed___srcSet',
  fixed___srcWebp = 'fixed___srcWebp',
  fixed___srcSetWebp = 'fixed___srcSetWebp',
  fixed___originalName = 'fixed___originalName',
  resolutions___base64 = 'resolutions___base64',
  resolutions___tracedSVG = 'resolutions___tracedSVG',
  resolutions___aspectRatio = 'resolutions___aspectRatio',
  resolutions___width = 'resolutions___width',
  resolutions___height = 'resolutions___height',
  resolutions___src = 'resolutions___src',
  resolutions___srcSet = 'resolutions___srcSet',
  resolutions___srcWebp = 'resolutions___srcWebp',
  resolutions___srcSetWebp = 'resolutions___srcSetWebp',
  resolutions___originalName = 'resolutions___originalName',
  fluid___base64 = 'fluid___base64',
  fluid___tracedSVG = 'fluid___tracedSVG',
  fluid___aspectRatio = 'fluid___aspectRatio',
  fluid___src = 'fluid___src',
  fluid___srcSet = 'fluid___srcSet',
  fluid___srcWebp = 'fluid___srcWebp',
  fluid___srcSetWebp = 'fluid___srcSetWebp',
  fluid___sizes = 'fluid___sizes',
  fluid___originalImg = 'fluid___originalImg',
  fluid___originalName = 'fluid___originalName',
  fluid___presentationWidth = 'fluid___presentationWidth',
  fluid___presentationHeight = 'fluid___presentationHeight',
  sizes___base64 = 'sizes___base64',
  sizes___tracedSVG = 'sizes___tracedSVG',
  sizes___aspectRatio = 'sizes___aspectRatio',
  sizes___src = 'sizes___src',
  sizes___srcSet = 'sizes___srcSet',
  sizes___srcWebp = 'sizes___srcWebp',
  sizes___srcSetWebp = 'sizes___srcSetWebp',
  sizes___sizes = 'sizes___sizes',
  sizes___originalImg = 'sizes___originalImg',
  sizes___originalName = 'sizes___originalName',
  sizes___presentationWidth = 'sizes___presentationWidth',
  sizes___presentationHeight = 'sizes___presentationHeight',
  original___width = 'original___width',
  original___height = 'original___height',
  original___src = 'original___src',
  resize___src = 'resize___src',
  resize___tracedSVG = 'resize___tracedSVG',
  resize___width = 'resize___width',
  resize___height = 'resize___height',
  resize___aspectRatio = 'resize___aspectRatio',
  resize___originalName = 'resize___originalName',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type ImageSharpFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  fixed?: Maybe<ImageSharpFixedFilterInput>;
  resolutions?: Maybe<ImageSharpResolutionsFilterInput>;
  fluid?: Maybe<ImageSharpFluidFilterInput>;
  sizes?: Maybe<ImageSharpSizesFilterInput>;
  original?: Maybe<ImageSharpOriginalFilterInput>;
  resize?: Maybe<ImageSharpResizeFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type ImageSharpFixed = {
  __typename?: 'ImageSharpFixed';
  base64?: Maybe<Scalars['String']>;
  tracedSVG?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['String']>;
  srcSet?: Maybe<Scalars['String']>;
  srcWebp?: Maybe<Scalars['String']>;
  srcSetWebp?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
};

export type ImageSharpFixedFilterInput = {
  base64?: Maybe<StringQueryOperatorInput>;
  tracedSVG?: Maybe<StringQueryOperatorInput>;
  aspectRatio?: Maybe<FloatQueryOperatorInput>;
  width?: Maybe<FloatQueryOperatorInput>;
  height?: Maybe<FloatQueryOperatorInput>;
  src?: Maybe<StringQueryOperatorInput>;
  srcSet?: Maybe<StringQueryOperatorInput>;
  srcWebp?: Maybe<StringQueryOperatorInput>;
  srcSetWebp?: Maybe<StringQueryOperatorInput>;
  originalName?: Maybe<StringQueryOperatorInput>;
};

export type ImageSharpFluid = {
  __typename?: 'ImageSharpFluid';
  base64?: Maybe<Scalars['String']>;
  tracedSVG?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['String']>;
  srcSet?: Maybe<Scalars['String']>;
  srcWebp?: Maybe<Scalars['String']>;
  srcSetWebp?: Maybe<Scalars['String']>;
  sizes?: Maybe<Scalars['String']>;
  originalImg?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  presentationWidth?: Maybe<Scalars['Int']>;
  presentationHeight?: Maybe<Scalars['Int']>;
};

export type ImageSharpFluidFilterInput = {
  base64?: Maybe<StringQueryOperatorInput>;
  tracedSVG?: Maybe<StringQueryOperatorInput>;
  aspectRatio?: Maybe<FloatQueryOperatorInput>;
  src?: Maybe<StringQueryOperatorInput>;
  srcSet?: Maybe<StringQueryOperatorInput>;
  srcWebp?: Maybe<StringQueryOperatorInput>;
  srcSetWebp?: Maybe<StringQueryOperatorInput>;
  sizes?: Maybe<StringQueryOperatorInput>;
  originalImg?: Maybe<StringQueryOperatorInput>;
  originalName?: Maybe<StringQueryOperatorInput>;
  presentationWidth?: Maybe<IntQueryOperatorInput>;
  presentationHeight?: Maybe<IntQueryOperatorInput>;
};

export type ImageSharpGroupConnection = {
  __typename?: 'ImageSharpGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<ImageSharpEdge>;
  nodes: Array<ImageSharp>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type ImageSharpOriginal = {
  __typename?: 'ImageSharpOriginal';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['String']>;
};

export type ImageSharpOriginalFilterInput = {
  width?: Maybe<FloatQueryOperatorInput>;
  height?: Maybe<FloatQueryOperatorInput>;
  src?: Maybe<StringQueryOperatorInput>;
};

export type ImageSharpResize = {
  __typename?: 'ImageSharpResize';
  src?: Maybe<Scalars['String']>;
  tracedSVG?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  originalName?: Maybe<Scalars['String']>;
};

export type ImageSharpResizeFilterInput = {
  src?: Maybe<StringQueryOperatorInput>;
  tracedSVG?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  aspectRatio?: Maybe<FloatQueryOperatorInput>;
  originalName?: Maybe<StringQueryOperatorInput>;
};

export type ImageSharpResolutions = {
  __typename?: 'ImageSharpResolutions';
  base64?: Maybe<Scalars['String']>;
  tracedSVG?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['String']>;
  srcSet?: Maybe<Scalars['String']>;
  srcWebp?: Maybe<Scalars['String']>;
  srcSetWebp?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
};

export type ImageSharpResolutionsFilterInput = {
  base64?: Maybe<StringQueryOperatorInput>;
  tracedSVG?: Maybe<StringQueryOperatorInput>;
  aspectRatio?: Maybe<FloatQueryOperatorInput>;
  width?: Maybe<FloatQueryOperatorInput>;
  height?: Maybe<FloatQueryOperatorInput>;
  src?: Maybe<StringQueryOperatorInput>;
  srcSet?: Maybe<StringQueryOperatorInput>;
  srcWebp?: Maybe<StringQueryOperatorInput>;
  srcSetWebp?: Maybe<StringQueryOperatorInput>;
  originalName?: Maybe<StringQueryOperatorInput>;
};

export type ImageSharpSizes = {
  __typename?: 'ImageSharpSizes';
  base64?: Maybe<Scalars['String']>;
  tracedSVG?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['String']>;
  srcSet?: Maybe<Scalars['String']>;
  srcWebp?: Maybe<Scalars['String']>;
  srcSetWebp?: Maybe<Scalars['String']>;
  sizes?: Maybe<Scalars['String']>;
  originalImg?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  presentationWidth?: Maybe<Scalars['Int']>;
  presentationHeight?: Maybe<Scalars['Int']>;
};

export type ImageSharpSizesFilterInput = {
  base64?: Maybe<StringQueryOperatorInput>;
  tracedSVG?: Maybe<StringQueryOperatorInput>;
  aspectRatio?: Maybe<FloatQueryOperatorInput>;
  src?: Maybe<StringQueryOperatorInput>;
  srcSet?: Maybe<StringQueryOperatorInput>;
  srcWebp?: Maybe<StringQueryOperatorInput>;
  srcSetWebp?: Maybe<StringQueryOperatorInput>;
  sizes?: Maybe<StringQueryOperatorInput>;
  originalImg?: Maybe<StringQueryOperatorInput>;
  originalName?: Maybe<StringQueryOperatorInput>;
  presentationWidth?: Maybe<IntQueryOperatorInput>;
  presentationHeight?: Maybe<IntQueryOperatorInput>;
};

export type ImageSharpSortInput = {
  fields?: Maybe<Array<Maybe<ImageSharpFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Internal = {
  __typename?: 'internal';
  content?: Maybe<Scalars['String']>;
  contentDigest: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fieldOwners?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreType?: Maybe<Scalars['Boolean']>;
  mediaType?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  type: Scalars['String'];
};

export type InternalFilterInput = {
  content?: Maybe<StringQueryOperatorInput>;
  contentDigest?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<StringQueryOperatorInput>;
  fieldOwners?: Maybe<StringQueryOperatorInput>;
  ignoreType?: Maybe<BooleanQueryOperatorInput>;
  mediaType?: Maybe<StringQueryOperatorInput>;
  owner?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<StringQueryOperatorInput>;
};

export type IntQueryOperatorInput = {
  eq?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type JsonJson = Node & {
  __typename?: 'JsonJson';
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  default?: Maybe<Scalars['String']>;
  codes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type JsonJsonConnection = {
  __typename?: 'JsonJsonConnection';
  totalCount: Scalars['Int'];
  edges: Array<JsonJsonEdge>;
  nodes: Array<JsonJson>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<JsonJsonGroupConnection>;
};

export type JsonJsonConnectionDistinctArgs = {
  field: JsonJsonFieldsEnum;
};

export type JsonJsonConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: JsonJsonFieldsEnum;
};

export type JsonJsonEdge = {
  __typename?: 'JsonJsonEdge';
  next?: Maybe<JsonJson>;
  node: JsonJson;
  previous?: Maybe<JsonJson>;
};

export enum JsonJsonFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  default = 'default',
  codes = 'codes',
}

export type JsonJsonFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  default?: Maybe<StringQueryOperatorInput>;
  codes?: Maybe<StringQueryOperatorInput>;
};

export type JsonJsonGroupConnection = {
  __typename?: 'JsonJsonGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<JsonJsonEdge>;
  nodes: Array<JsonJson>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type JsonJsonSortInput = {
  fields?: Maybe<Array<Maybe<JsonJsonFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type NewsRelatedComponents =
  | Paragraph__News_Cta
  | Paragraph__News_Image_Title_Text
  | Paragraph__News_Quotation
  | Paragraph__News_Text
  | Paragraph__Video;

/** Node Interface */
export type Node = {
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__Accessories = Node & {
  __typename?: 'node__accessories';
  langcode?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  field_pl_groupid?: Maybe<Scalars['Int']>;
  field_weight?: Maybe<Scalars['Int']>;
  relationships?: Maybe<Node__AccessoriesRelationships>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  field_date_from_api?: Maybe<Node__AccessoriesField_Date_From_Api>;
  field_pl_accessory_type?: Maybe<Node__AccessoriesField_Pl_Accessory_Type>;
  field_pl_image?: Maybe<Node__AccessoriesField_Pl_Image>;
  field_pl_image_accessories_outl?: Maybe<Node__AccessoriesField_Pl_Image_Accessories_Outl>;
  field_pl_image_accessories_table?: Maybe<Node__AccessoriesField_Pl_Image_Accessories_Table>;
  field_recommended_for_branches?: Maybe<
    Array<Maybe<Node__AccessoriesField_Recommended_For_Branches>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__AccessoriesConnection = {
  __typename?: 'node__accessoriesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__AccessoriesEdge>;
  nodes: Array<Node__Accessories>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__AccessoriesGroupConnection>;
};

export type Node__AccessoriesConnectionDistinctArgs = {
  field: Node__AccessoriesFieldsEnum;
};

export type Node__AccessoriesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__AccessoriesFieldsEnum;
};

export type Node__AccessoriesEdge = {
  __typename?: 'node__accessoriesEdge';
  next?: Maybe<Node__Accessories>;
  node: Node__Accessories;
  previous?: Maybe<Node__Accessories>;
};

export type Node__AccessoriesField_Date_From_Api = {
  __typename?: 'node__accessoriesField_date_from_api';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__AccessoriesField_Date_From_ApiFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__AccessoriesField_Pl_Accessory_Type = {
  __typename?: 'node__accessoriesField_pl_accessory_type';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__AccessoriesField_Pl_Accessory_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__AccessoriesField_Pl_Image = {
  __typename?: 'node__accessoriesField_pl_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__AccessoriesField_Pl_Image_Accessories_Outl = {
  __typename?: 'node__accessoriesField_pl_image_accessories_outl';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__AccessoriesField_Pl_Image_Accessories_OutlFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__AccessoriesField_Pl_Image_Accessories_Table = {
  __typename?: 'node__accessoriesField_pl_image_accessories_table';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__AccessoriesField_Pl_Image_Accessories_TableFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__AccessoriesField_Pl_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__AccessoriesField_Recommended_For_Branches = {
  __typename?: 'node__accessoriesField_recommended_for_branches';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__AccessoriesField_Recommended_For_BranchesFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__AccessoriesField_Recommended_For_BranchesFilterListInput = {
  elemMatch?: Maybe<Node__AccessoriesField_Recommended_For_BranchesFilterInput>;
};

export enum Node__AccessoriesFieldsEnum {
  langcode = 'langcode',
  drupal_id = 'drupal_id',
  title = 'title',
  field_pl_groupid = 'field_pl_groupid',
  field_weight = 'field_weight',
  relationships___field_date_from_api___drupal_id = 'relationships___field_date_from_api___drupal_id',
  relationships___field_date_from_api___fixedprice = 'relationships___field_date_from_api___fixedprice',
  relationships___field_date_from_api___iposfee = 'relationships___field_date_from_api___iposfee',
  relationships___field_date_from_api___langcode = 'relationships___field_date_from_api___langcode',
  relationships___field_date_from_api___status = 'relationships___field_date_from_api___status',
  relationships___field_date_from_api___field_pl_locationfee = 'relationships___field_date_from_api___field_pl_locationfee',
  relationships___field_date_from_api___field_pl_name = 'relationships___field_date_from_api___field_pl_name',
  relationships___field_date_from_api___field_pl_optionname = 'relationships___field_date_from_api___field_pl_optionname',
  relationships___field_date_from_api___field_pl_lease = 'relationships___field_date_from_api___field_pl_lease',
  relationships___field_date_from_api___field_pl_lease_name = 'relationships___field_date_from_api___field_pl_lease_name',
  relationships___field_date_from_api___field_pl_payment_type = 'relationships___field_date_from_api___field_pl_payment_type',
  relationships___field_date_from_api___field_pl_activation_price = 'relationships___field_date_from_api___field_pl_activation_price',
  relationships___field_date_from_api___field_pl_contract_duration = 'relationships___field_date_from_api___field_pl_contract_duration',
  relationships___field_date_from_api___field_pl_group_name_in_api = 'relationships___field_date_from_api___field_pl_group_name_in_api',
  relationships___field_date_from_api___field_pl_reduced_fee_duration = 'relationships___field_date_from_api___field_pl_reduced_fee_duration',
  relationships___field_date_from_api___field_pl_reduced_fee = 'relationships___field_date_from_api___field_pl_reduced_fee',
  relationships___field_date_from_api___drupal_internal__nid = 'relationships___field_date_from_api___drupal_internal__nid',
  relationships___field_date_from_api___content_translation_source = 'relationships___field_date_from_api___content_translation_source',
  relationships___field_date_from_api___content_translation_outdated = 'relationships___field_date_from_api___content_translation_outdated',
  relationships___field_date_from_api___field_pl_first_ipos_fee = 'relationships___field_date_from_api___field_pl_first_ipos_fee',
  relationships___field_date_from_api___vatrate = 'relationships___field_date_from_api___vatrate',
  relationships___field_date_from_api___uuid = 'relationships___field_date_from_api___uuid',
  relationships___field_date_from_api___relationships___node__accessories = 'relationships___field_date_from_api___relationships___node__accessories',
  relationships___field_date_from_api___relationships___node__cases = 'relationships___field_date_from_api___relationships___node__cases',
  relationships___field_date_from_api___relationships___paragraph__branches_tariff = 'relationships___field_date_from_api___relationships___paragraph__branches_tariff',
  relationships___field_date_from_api___pricelistgroup___drupal_internal__target_id = 'relationships___field_date_from_api___pricelistgroup___drupal_internal__target_id',
  relationships___field_date_from_api___id = 'relationships___field_date_from_api___id',
  relationships___field_date_from_api___parent___id = 'relationships___field_date_from_api___parent___id',
  relationships___field_date_from_api___parent___children = 'relationships___field_date_from_api___parent___children',
  relationships___field_date_from_api___children = 'relationships___field_date_from_api___children',
  relationships___field_date_from_api___children___id = 'relationships___field_date_from_api___children___id',
  relationships___field_date_from_api___children___children = 'relationships___field_date_from_api___children___children',
  relationships___field_date_from_api___internal___content = 'relationships___field_date_from_api___internal___content',
  relationships___field_date_from_api___internal___contentDigest = 'relationships___field_date_from_api___internal___contentDigest',
  relationships___field_date_from_api___internal___description = 'relationships___field_date_from_api___internal___description',
  relationships___field_date_from_api___internal___fieldOwners = 'relationships___field_date_from_api___internal___fieldOwners',
  relationships___field_date_from_api___internal___ignoreType = 'relationships___field_date_from_api___internal___ignoreType',
  relationships___field_date_from_api___internal___mediaType = 'relationships___field_date_from_api___internal___mediaType',
  relationships___field_date_from_api___internal___owner = 'relationships___field_date_from_api___internal___owner',
  relationships___field_date_from_api___internal___type = 'relationships___field_date_from_api___internal___type',
  relationships___field_pl_accessory_type___name = 'relationships___field_pl_accessory_type___name',
  relationships___field_pl_accessory_type___drupal_id = 'relationships___field_pl_accessory_type___drupal_id',
  relationships___field_pl_accessory_type___drupal_internal__tid = 'relationships___field_pl_accessory_type___drupal_internal__tid',
  relationships___field_pl_accessory_type___drupal_internal__revision_id = 'relationships___field_pl_accessory_type___drupal_internal__revision_id',
  relationships___field_pl_accessory_type___langcode = 'relationships___field_pl_accessory_type___langcode',
  relationships___field_pl_accessory_type___revision_created = 'relationships___field_pl_accessory_type___revision_created',
  relationships___field_pl_accessory_type___status = 'relationships___field_pl_accessory_type___status',
  relationships___field_pl_accessory_type___weight = 'relationships___field_pl_accessory_type___weight',
  relationships___field_pl_accessory_type___changed = 'relationships___field_pl_accessory_type___changed',
  relationships___field_pl_accessory_type___default_langcode = 'relationships___field_pl_accessory_type___default_langcode',
  relationships___field_pl_accessory_type___revision_translation_affected = 'relationships___field_pl_accessory_type___revision_translation_affected',
  relationships___field_pl_accessory_type___rh_action = 'relationships___field_pl_accessory_type___rh_action',
  relationships___field_pl_accessory_type___rh_redirect_response = 'relationships___field_pl_accessory_type___rh_redirect_response',
  relationships___field_pl_accessory_type___content_translation_source = 'relationships___field_pl_accessory_type___content_translation_source',
  relationships___field_pl_accessory_type___content_translation_outdated = 'relationships___field_pl_accessory_type___content_translation_outdated',
  relationships___field_pl_accessory_type___content_translation_created = 'relationships___field_pl_accessory_type___content_translation_created',
  relationships___field_pl_accessory_type___uuid = 'relationships___field_pl_accessory_type___uuid',
  relationships___field_pl_accessory_type___relationships___node__accessories = 'relationships___field_pl_accessory_type___relationships___node__accessories',
  relationships___field_pl_accessory_type___vid___drupal_internal__target_id = 'relationships___field_pl_accessory_type___vid___drupal_internal__target_id',
  relationships___field_pl_accessory_type___id = 'relationships___field_pl_accessory_type___id',
  relationships___field_pl_accessory_type___parent___id = 'relationships___field_pl_accessory_type___parent___id',
  relationships___field_pl_accessory_type___parent___children = 'relationships___field_pl_accessory_type___parent___children',
  relationships___field_pl_accessory_type___children = 'relationships___field_pl_accessory_type___children',
  relationships___field_pl_accessory_type___children___id = 'relationships___field_pl_accessory_type___children___id',
  relationships___field_pl_accessory_type___children___children = 'relationships___field_pl_accessory_type___children___children',
  relationships___field_pl_accessory_type___internal___content = 'relationships___field_pl_accessory_type___internal___content',
  relationships___field_pl_accessory_type___internal___contentDigest = 'relationships___field_pl_accessory_type___internal___contentDigest',
  relationships___field_pl_accessory_type___internal___description = 'relationships___field_pl_accessory_type___internal___description',
  relationships___field_pl_accessory_type___internal___fieldOwners = 'relationships___field_pl_accessory_type___internal___fieldOwners',
  relationships___field_pl_accessory_type___internal___ignoreType = 'relationships___field_pl_accessory_type___internal___ignoreType',
  relationships___field_pl_accessory_type___internal___mediaType = 'relationships___field_pl_accessory_type___internal___mediaType',
  relationships___field_pl_accessory_type___internal___owner = 'relationships___field_pl_accessory_type___internal___owner',
  relationships___field_pl_accessory_type___internal___type = 'relationships___field_pl_accessory_type___internal___type',
  relationships___field_pl_image___localFile___birthtime = 'relationships___field_pl_image___localFile___birthtime',
  relationships___field_pl_image___localFile___birthtimeMs = 'relationships___field_pl_image___localFile___birthtimeMs',
  relationships___field_pl_image___localFile___publicURL = 'relationships___field_pl_image___localFile___publicURL',
  relationships___field_pl_image___localFile___sourceInstanceName = 'relationships___field_pl_image___localFile___sourceInstanceName',
  relationships___field_pl_image___localFile___absolutePath = 'relationships___field_pl_image___localFile___absolutePath',
  relationships___field_pl_image___localFile___relativePath = 'relationships___field_pl_image___localFile___relativePath',
  relationships___field_pl_image___localFile___extension = 'relationships___field_pl_image___localFile___extension',
  relationships___field_pl_image___localFile___size = 'relationships___field_pl_image___localFile___size',
  relationships___field_pl_image___localFile___prettySize = 'relationships___field_pl_image___localFile___prettySize',
  relationships___field_pl_image___localFile___modifiedTime = 'relationships___field_pl_image___localFile___modifiedTime',
  relationships___field_pl_image___localFile___accessTime = 'relationships___field_pl_image___localFile___accessTime',
  relationships___field_pl_image___localFile___changeTime = 'relationships___field_pl_image___localFile___changeTime',
  relationships___field_pl_image___localFile___birthTime = 'relationships___field_pl_image___localFile___birthTime',
  relationships___field_pl_image___localFile___root = 'relationships___field_pl_image___localFile___root',
  relationships___field_pl_image___localFile___dir = 'relationships___field_pl_image___localFile___dir',
  relationships___field_pl_image___localFile___base = 'relationships___field_pl_image___localFile___base',
  relationships___field_pl_image___localFile___ext = 'relationships___field_pl_image___localFile___ext',
  relationships___field_pl_image___localFile___name = 'relationships___field_pl_image___localFile___name',
  relationships___field_pl_image___localFile___relativeDirectory = 'relationships___field_pl_image___localFile___relativeDirectory',
  relationships___field_pl_image___localFile___dev = 'relationships___field_pl_image___localFile___dev',
  relationships___field_pl_image___localFile___mode = 'relationships___field_pl_image___localFile___mode',
  relationships___field_pl_image___localFile___nlink = 'relationships___field_pl_image___localFile___nlink',
  relationships___field_pl_image___localFile___uid = 'relationships___field_pl_image___localFile___uid',
  relationships___field_pl_image___localFile___gid = 'relationships___field_pl_image___localFile___gid',
  relationships___field_pl_image___localFile___rdev = 'relationships___field_pl_image___localFile___rdev',
  relationships___field_pl_image___localFile___blksize = 'relationships___field_pl_image___localFile___blksize',
  relationships___field_pl_image___localFile___ino = 'relationships___field_pl_image___localFile___ino',
  relationships___field_pl_image___localFile___blocks = 'relationships___field_pl_image___localFile___blocks',
  relationships___field_pl_image___localFile___atimeMs = 'relationships___field_pl_image___localFile___atimeMs',
  relationships___field_pl_image___localFile___mtimeMs = 'relationships___field_pl_image___localFile___mtimeMs',
  relationships___field_pl_image___localFile___ctimeMs = 'relationships___field_pl_image___localFile___ctimeMs',
  relationships___field_pl_image___localFile___atime = 'relationships___field_pl_image___localFile___atime',
  relationships___field_pl_image___localFile___mtime = 'relationships___field_pl_image___localFile___mtime',
  relationships___field_pl_image___localFile___ctime = 'relationships___field_pl_image___localFile___ctime',
  relationships___field_pl_image___localFile___url = 'relationships___field_pl_image___localFile___url',
  relationships___field_pl_image___localFile___id = 'relationships___field_pl_image___localFile___id',
  relationships___field_pl_image___localFile___children = 'relationships___field_pl_image___localFile___children',
  relationships___field_pl_image___uri___url = 'relationships___field_pl_image___uri___url',
  relationships___field_pl_image___drupal_id = 'relationships___field_pl_image___drupal_id',
  relationships___field_pl_image___drupal_internal__fid = 'relationships___field_pl_image___drupal_internal__fid',
  relationships___field_pl_image___langcode = 'relationships___field_pl_image___langcode',
  relationships___field_pl_image___filename = 'relationships___field_pl_image___filename',
  relationships___field_pl_image___filemime = 'relationships___field_pl_image___filemime',
  relationships___field_pl_image___filesize = 'relationships___field_pl_image___filesize',
  relationships___field_pl_image___status = 'relationships___field_pl_image___status',
  relationships___field_pl_image___created = 'relationships___field_pl_image___created',
  relationships___field_pl_image___changed = 'relationships___field_pl_image___changed',
  relationships___field_pl_image___uuid = 'relationships___field_pl_image___uuid',
  relationships___field_pl_image___relationships___node__blog_post = 'relationships___field_pl_image___relationships___node__blog_post',
  relationships___field_pl_image___relationships___node__devices = 'relationships___field_pl_image___relationships___node__devices',
  relationships___field_pl_image___relationships___node__accessories = 'relationships___field_pl_image___relationships___node__accessories',
  relationships___field_pl_image___relationships___paragraph__news_image_title_text = 'relationships___field_pl_image___relationships___paragraph__news_image_title_text',
  relationships___field_pl_image___relationships___node__cases = 'relationships___field_pl_image___relationships___node__cases',
  relationships___field_pl_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_pl_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_pl_image___relationships___paragraph__branches_features = 'relationships___field_pl_image___relationships___paragraph__branches_features',
  relationships___field_pl_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_pl_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_pl_image___relationships___paragraph__branches_testimonials = 'relationships___field_pl_image___relationships___paragraph__branches_testimonials',
  relationships___field_pl_image___relationships___paragraph__branches_discounts = 'relationships___field_pl_image___relationships___paragraph__branches_discounts',
  relationships___field_pl_image___relationships___node__news = 'relationships___field_pl_image___relationships___node__news',
  relationships___field_pl_image___relationships___node__branches = 'relationships___field_pl_image___relationships___node__branches',
  relationships___field_pl_image___relationships___paragraph__branches_hero = 'relationships___field_pl_image___relationships___paragraph__branches_hero',
  relationships___field_pl_image___relationships___paragraph__bullets = 'relationships___field_pl_image___relationships___paragraph__bullets',
  relationships___field_pl_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_pl_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_pl_image___relationships___paragraph__branches_device = 'relationships___field_pl_image___relationships___paragraph__branches_device',
  relationships___field_pl_image___relationships___paragraph__branches_recommendation = 'relationships___field_pl_image___relationships___paragraph__branches_recommendation',
  relationships___field_pl_image___relationships___site_setting_entity__accessories = 'relationships___field_pl_image___relationships___site_setting_entity__accessories',
  relationships___field_pl_image___relationships___site_setting_entity__branches = 'relationships___field_pl_image___relationships___site_setting_entity__branches',
  relationships___field_pl_image___relationships___site_setting_entity__about_us = 'relationships___field_pl_image___relationships___site_setting_entity__about_us',
  relationships___field_pl_image___relationships___paragraph__logos = 'relationships___field_pl_image___relationships___paragraph__logos',
  relationships___field_pl_image___relationships___paragraph__how_it_works = 'relationships___field_pl_image___relationships___paragraph__how_it_works',
  relationships___field_pl_image___relationships___paragraph__ico_and_text = 'relationships___field_pl_image___relationships___paragraph__ico_and_text',
  relationships___field_pl_image___relationships___paragraph__hero_1 = 'relationships___field_pl_image___relationships___paragraph__hero_1',
  relationships___field_pl_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_pl_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_pl_image___relationships___site_setting_entity__product = 'relationships___field_pl_image___relationships___site_setting_entity__product',
  relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_pl_image___relationships___paragraph__porownanie_terminali = 'relationships___field_pl_image___relationships___paragraph__porownanie_terminali',
  relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_pl_image___relationships___site_setting_entity__main_page = 'relationships___field_pl_image___relationships___site_setting_entity__main_page',
  relationships___field_pl_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_pl_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_pl_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_pl_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_pl_image___relationships___site_setting_entity__intercars = 'relationships___field_pl_image___relationships___site_setting_entity__intercars',
  relationships___field_pl_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_pl_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_pl_image___relationships___node__agreement = 'relationships___field_pl_image___relationships___node__agreement',
  relationships___field_pl_image___relationships___node__fiscalization = 'relationships___field_pl_image___relationships___node__fiscalization',
  relationships___field_pl_image___relationships___site_setting_entity__configurator = 'relationships___field_pl_image___relationships___site_setting_entity__configurator',
  relationships___field_pl_image___relationships___paragraph__social_link = 'relationships___field_pl_image___relationships___paragraph__social_link',
  relationships___field_pl_image___relationships___paragraph__make_an_appointment = 'relationships___field_pl_image___relationships___paragraph__make_an_appointment',
  relationships___field_pl_image___relationships___paragraph__linked_icons = 'relationships___field_pl_image___relationships___paragraph__linked_icons',
  relationships___field_pl_image___relationships___site_setting_entity__financing = 'relationships___field_pl_image___relationships___site_setting_entity__financing',
  relationships___field_pl_image___relationships___site_setting_entity__franchise = 'relationships___field_pl_image___relationships___site_setting_entity__franchise',
  relationships___field_pl_image___id = 'relationships___field_pl_image___id',
  relationships___field_pl_image___parent___id = 'relationships___field_pl_image___parent___id',
  relationships___field_pl_image___parent___children = 'relationships___field_pl_image___parent___children',
  relationships___field_pl_image___children = 'relationships___field_pl_image___children',
  relationships___field_pl_image___children___id = 'relationships___field_pl_image___children___id',
  relationships___field_pl_image___children___children = 'relationships___field_pl_image___children___children',
  relationships___field_pl_image___internal___content = 'relationships___field_pl_image___internal___content',
  relationships___field_pl_image___internal___contentDigest = 'relationships___field_pl_image___internal___contentDigest',
  relationships___field_pl_image___internal___description = 'relationships___field_pl_image___internal___description',
  relationships___field_pl_image___internal___fieldOwners = 'relationships___field_pl_image___internal___fieldOwners',
  relationships___field_pl_image___internal___ignoreType = 'relationships___field_pl_image___internal___ignoreType',
  relationships___field_pl_image___internal___mediaType = 'relationships___field_pl_image___internal___mediaType',
  relationships___field_pl_image___internal___owner = 'relationships___field_pl_image___internal___owner',
  relationships___field_pl_image___internal___type = 'relationships___field_pl_image___internal___type',
  relationships___field_pl_image_accessories_outl___localFile___birthtime = 'relationships___field_pl_image_accessories_outl___localFile___birthtime',
  relationships___field_pl_image_accessories_outl___localFile___birthtimeMs = 'relationships___field_pl_image_accessories_outl___localFile___birthtimeMs',
  relationships___field_pl_image_accessories_outl___localFile___publicURL = 'relationships___field_pl_image_accessories_outl___localFile___publicURL',
  relationships___field_pl_image_accessories_outl___localFile___sourceInstanceName = 'relationships___field_pl_image_accessories_outl___localFile___sourceInstanceName',
  relationships___field_pl_image_accessories_outl___localFile___absolutePath = 'relationships___field_pl_image_accessories_outl___localFile___absolutePath',
  relationships___field_pl_image_accessories_outl___localFile___relativePath = 'relationships___field_pl_image_accessories_outl___localFile___relativePath',
  relationships___field_pl_image_accessories_outl___localFile___extension = 'relationships___field_pl_image_accessories_outl___localFile___extension',
  relationships___field_pl_image_accessories_outl___localFile___size = 'relationships___field_pl_image_accessories_outl___localFile___size',
  relationships___field_pl_image_accessories_outl___localFile___prettySize = 'relationships___field_pl_image_accessories_outl___localFile___prettySize',
  relationships___field_pl_image_accessories_outl___localFile___modifiedTime = 'relationships___field_pl_image_accessories_outl___localFile___modifiedTime',
  relationships___field_pl_image_accessories_outl___localFile___accessTime = 'relationships___field_pl_image_accessories_outl___localFile___accessTime',
  relationships___field_pl_image_accessories_outl___localFile___changeTime = 'relationships___field_pl_image_accessories_outl___localFile___changeTime',
  relationships___field_pl_image_accessories_outl___localFile___birthTime = 'relationships___field_pl_image_accessories_outl___localFile___birthTime',
  relationships___field_pl_image_accessories_outl___localFile___root = 'relationships___field_pl_image_accessories_outl___localFile___root',
  relationships___field_pl_image_accessories_outl___localFile___dir = 'relationships___field_pl_image_accessories_outl___localFile___dir',
  relationships___field_pl_image_accessories_outl___localFile___base = 'relationships___field_pl_image_accessories_outl___localFile___base',
  relationships___field_pl_image_accessories_outl___localFile___ext = 'relationships___field_pl_image_accessories_outl___localFile___ext',
  relationships___field_pl_image_accessories_outl___localFile___name = 'relationships___field_pl_image_accessories_outl___localFile___name',
  relationships___field_pl_image_accessories_outl___localFile___relativeDirectory = 'relationships___field_pl_image_accessories_outl___localFile___relativeDirectory',
  relationships___field_pl_image_accessories_outl___localFile___dev = 'relationships___field_pl_image_accessories_outl___localFile___dev',
  relationships___field_pl_image_accessories_outl___localFile___mode = 'relationships___field_pl_image_accessories_outl___localFile___mode',
  relationships___field_pl_image_accessories_outl___localFile___nlink = 'relationships___field_pl_image_accessories_outl___localFile___nlink',
  relationships___field_pl_image_accessories_outl___localFile___uid = 'relationships___field_pl_image_accessories_outl___localFile___uid',
  relationships___field_pl_image_accessories_outl___localFile___gid = 'relationships___field_pl_image_accessories_outl___localFile___gid',
  relationships___field_pl_image_accessories_outl___localFile___rdev = 'relationships___field_pl_image_accessories_outl___localFile___rdev',
  relationships___field_pl_image_accessories_outl___localFile___blksize = 'relationships___field_pl_image_accessories_outl___localFile___blksize',
  relationships___field_pl_image_accessories_outl___localFile___ino = 'relationships___field_pl_image_accessories_outl___localFile___ino',
  relationships___field_pl_image_accessories_outl___localFile___blocks = 'relationships___field_pl_image_accessories_outl___localFile___blocks',
  relationships___field_pl_image_accessories_outl___localFile___atimeMs = 'relationships___field_pl_image_accessories_outl___localFile___atimeMs',
  relationships___field_pl_image_accessories_outl___localFile___mtimeMs = 'relationships___field_pl_image_accessories_outl___localFile___mtimeMs',
  relationships___field_pl_image_accessories_outl___localFile___ctimeMs = 'relationships___field_pl_image_accessories_outl___localFile___ctimeMs',
  relationships___field_pl_image_accessories_outl___localFile___atime = 'relationships___field_pl_image_accessories_outl___localFile___atime',
  relationships___field_pl_image_accessories_outl___localFile___mtime = 'relationships___field_pl_image_accessories_outl___localFile___mtime',
  relationships___field_pl_image_accessories_outl___localFile___ctime = 'relationships___field_pl_image_accessories_outl___localFile___ctime',
  relationships___field_pl_image_accessories_outl___localFile___url = 'relationships___field_pl_image_accessories_outl___localFile___url',
  relationships___field_pl_image_accessories_outl___localFile___id = 'relationships___field_pl_image_accessories_outl___localFile___id',
  relationships___field_pl_image_accessories_outl___localFile___children = 'relationships___field_pl_image_accessories_outl___localFile___children',
  relationships___field_pl_image_accessories_outl___uri___url = 'relationships___field_pl_image_accessories_outl___uri___url',
  relationships___field_pl_image_accessories_outl___drupal_id = 'relationships___field_pl_image_accessories_outl___drupal_id',
  relationships___field_pl_image_accessories_outl___drupal_internal__fid = 'relationships___field_pl_image_accessories_outl___drupal_internal__fid',
  relationships___field_pl_image_accessories_outl___langcode = 'relationships___field_pl_image_accessories_outl___langcode',
  relationships___field_pl_image_accessories_outl___filename = 'relationships___field_pl_image_accessories_outl___filename',
  relationships___field_pl_image_accessories_outl___filemime = 'relationships___field_pl_image_accessories_outl___filemime',
  relationships___field_pl_image_accessories_outl___filesize = 'relationships___field_pl_image_accessories_outl___filesize',
  relationships___field_pl_image_accessories_outl___status = 'relationships___field_pl_image_accessories_outl___status',
  relationships___field_pl_image_accessories_outl___created = 'relationships___field_pl_image_accessories_outl___created',
  relationships___field_pl_image_accessories_outl___changed = 'relationships___field_pl_image_accessories_outl___changed',
  relationships___field_pl_image_accessories_outl___uuid = 'relationships___field_pl_image_accessories_outl___uuid',
  relationships___field_pl_image_accessories_outl___relationships___node__blog_post = 'relationships___field_pl_image_accessories_outl___relationships___node__blog_post',
  relationships___field_pl_image_accessories_outl___relationships___node__devices = 'relationships___field_pl_image_accessories_outl___relationships___node__devices',
  relationships___field_pl_image_accessories_outl___relationships___node__accessories = 'relationships___field_pl_image_accessories_outl___relationships___node__accessories',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__news_image_title_text = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__news_image_title_text',
  relationships___field_pl_image_accessories_outl___relationships___node__cases = 'relationships___field_pl_image_accessories_outl___relationships___node__cases',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_what_is_ipos = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_what_is_ipos',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_features = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_features',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_testimonials = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_testimonials',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_discounts = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_discounts',
  relationships___field_pl_image_accessories_outl___relationships___node__news = 'relationships___field_pl_image_accessories_outl___relationships___node__news',
  relationships___field_pl_image_accessories_outl___relationships___node__branches = 'relationships___field_pl_image_accessories_outl___relationships___node__branches',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_hero = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_hero',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__bullets = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__bullets',
  relationships___field_pl_image_accessories_outl___relationships___taxonomy_term__pricelist_group = 'relationships___field_pl_image_accessories_outl___relationships___taxonomy_term__pricelist_group',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_device = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_device',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_recommendation = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__branches_recommendation',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__accessories = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__accessories',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__branches = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__branches',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__about_us = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__about_us',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__logos = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__logos',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__how_it_works = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__how_it_works',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__ico_and_text = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__ico_and_text',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__hero_1 = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__hero_1',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__comprehensive_solution_item = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__comprehensive_solution_item',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__product = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__product',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__porownanie_terminali = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__porownanie_terminali',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__zdjecie_opis_link = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__zdjecie_opis_link',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__main_page = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__main_page',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__intercars = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__intercars',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_pl_image_accessories_outl___relationships___node__agreement = 'relationships___field_pl_image_accessories_outl___relationships___node__agreement',
  relationships___field_pl_image_accessories_outl___relationships___node__fiscalization = 'relationships___field_pl_image_accessories_outl___relationships___node__fiscalization',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__configurator = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__configurator',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__social_link = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__social_link',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__make_an_appointment = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__make_an_appointment',
  relationships___field_pl_image_accessories_outl___relationships___paragraph__linked_icons = 'relationships___field_pl_image_accessories_outl___relationships___paragraph__linked_icons',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__financing = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__financing',
  relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__franchise = 'relationships___field_pl_image_accessories_outl___relationships___site_setting_entity__franchise',
  relationships___field_pl_image_accessories_outl___id = 'relationships___field_pl_image_accessories_outl___id',
  relationships___field_pl_image_accessories_outl___parent___id = 'relationships___field_pl_image_accessories_outl___parent___id',
  relationships___field_pl_image_accessories_outl___parent___children = 'relationships___field_pl_image_accessories_outl___parent___children',
  relationships___field_pl_image_accessories_outl___children = 'relationships___field_pl_image_accessories_outl___children',
  relationships___field_pl_image_accessories_outl___children___id = 'relationships___field_pl_image_accessories_outl___children___id',
  relationships___field_pl_image_accessories_outl___children___children = 'relationships___field_pl_image_accessories_outl___children___children',
  relationships___field_pl_image_accessories_outl___internal___content = 'relationships___field_pl_image_accessories_outl___internal___content',
  relationships___field_pl_image_accessories_outl___internal___contentDigest = 'relationships___field_pl_image_accessories_outl___internal___contentDigest',
  relationships___field_pl_image_accessories_outl___internal___description = 'relationships___field_pl_image_accessories_outl___internal___description',
  relationships___field_pl_image_accessories_outl___internal___fieldOwners = 'relationships___field_pl_image_accessories_outl___internal___fieldOwners',
  relationships___field_pl_image_accessories_outl___internal___ignoreType = 'relationships___field_pl_image_accessories_outl___internal___ignoreType',
  relationships___field_pl_image_accessories_outl___internal___mediaType = 'relationships___field_pl_image_accessories_outl___internal___mediaType',
  relationships___field_pl_image_accessories_outl___internal___owner = 'relationships___field_pl_image_accessories_outl___internal___owner',
  relationships___field_pl_image_accessories_outl___internal___type = 'relationships___field_pl_image_accessories_outl___internal___type',
  relationships___field_pl_image_accessories_table___localFile___birthtime = 'relationships___field_pl_image_accessories_table___localFile___birthtime',
  relationships___field_pl_image_accessories_table___localFile___birthtimeMs = 'relationships___field_pl_image_accessories_table___localFile___birthtimeMs',
  relationships___field_pl_image_accessories_table___localFile___publicURL = 'relationships___field_pl_image_accessories_table___localFile___publicURL',
  relationships___field_pl_image_accessories_table___localFile___sourceInstanceName = 'relationships___field_pl_image_accessories_table___localFile___sourceInstanceName',
  relationships___field_pl_image_accessories_table___localFile___absolutePath = 'relationships___field_pl_image_accessories_table___localFile___absolutePath',
  relationships___field_pl_image_accessories_table___localFile___relativePath = 'relationships___field_pl_image_accessories_table___localFile___relativePath',
  relationships___field_pl_image_accessories_table___localFile___extension = 'relationships___field_pl_image_accessories_table___localFile___extension',
  relationships___field_pl_image_accessories_table___localFile___size = 'relationships___field_pl_image_accessories_table___localFile___size',
  relationships___field_pl_image_accessories_table___localFile___prettySize = 'relationships___field_pl_image_accessories_table___localFile___prettySize',
  relationships___field_pl_image_accessories_table___localFile___modifiedTime = 'relationships___field_pl_image_accessories_table___localFile___modifiedTime',
  relationships___field_pl_image_accessories_table___localFile___accessTime = 'relationships___field_pl_image_accessories_table___localFile___accessTime',
  relationships___field_pl_image_accessories_table___localFile___changeTime = 'relationships___field_pl_image_accessories_table___localFile___changeTime',
  relationships___field_pl_image_accessories_table___localFile___birthTime = 'relationships___field_pl_image_accessories_table___localFile___birthTime',
  relationships___field_pl_image_accessories_table___localFile___root = 'relationships___field_pl_image_accessories_table___localFile___root',
  relationships___field_pl_image_accessories_table___localFile___dir = 'relationships___field_pl_image_accessories_table___localFile___dir',
  relationships___field_pl_image_accessories_table___localFile___base = 'relationships___field_pl_image_accessories_table___localFile___base',
  relationships___field_pl_image_accessories_table___localFile___ext = 'relationships___field_pl_image_accessories_table___localFile___ext',
  relationships___field_pl_image_accessories_table___localFile___name = 'relationships___field_pl_image_accessories_table___localFile___name',
  relationships___field_pl_image_accessories_table___localFile___relativeDirectory = 'relationships___field_pl_image_accessories_table___localFile___relativeDirectory',
  relationships___field_pl_image_accessories_table___localFile___dev = 'relationships___field_pl_image_accessories_table___localFile___dev',
  relationships___field_pl_image_accessories_table___localFile___mode = 'relationships___field_pl_image_accessories_table___localFile___mode',
  relationships___field_pl_image_accessories_table___localFile___nlink = 'relationships___field_pl_image_accessories_table___localFile___nlink',
  relationships___field_pl_image_accessories_table___localFile___uid = 'relationships___field_pl_image_accessories_table___localFile___uid',
  relationships___field_pl_image_accessories_table___localFile___gid = 'relationships___field_pl_image_accessories_table___localFile___gid',
  relationships___field_pl_image_accessories_table___localFile___rdev = 'relationships___field_pl_image_accessories_table___localFile___rdev',
  relationships___field_pl_image_accessories_table___localFile___blksize = 'relationships___field_pl_image_accessories_table___localFile___blksize',
  relationships___field_pl_image_accessories_table___localFile___ino = 'relationships___field_pl_image_accessories_table___localFile___ino',
  relationships___field_pl_image_accessories_table___localFile___blocks = 'relationships___field_pl_image_accessories_table___localFile___blocks',
  relationships___field_pl_image_accessories_table___localFile___atimeMs = 'relationships___field_pl_image_accessories_table___localFile___atimeMs',
  relationships___field_pl_image_accessories_table___localFile___mtimeMs = 'relationships___field_pl_image_accessories_table___localFile___mtimeMs',
  relationships___field_pl_image_accessories_table___localFile___ctimeMs = 'relationships___field_pl_image_accessories_table___localFile___ctimeMs',
  relationships___field_pl_image_accessories_table___localFile___atime = 'relationships___field_pl_image_accessories_table___localFile___atime',
  relationships___field_pl_image_accessories_table___localFile___mtime = 'relationships___field_pl_image_accessories_table___localFile___mtime',
  relationships___field_pl_image_accessories_table___localFile___ctime = 'relationships___field_pl_image_accessories_table___localFile___ctime',
  relationships___field_pl_image_accessories_table___localFile___url = 'relationships___field_pl_image_accessories_table___localFile___url',
  relationships___field_pl_image_accessories_table___localFile___id = 'relationships___field_pl_image_accessories_table___localFile___id',
  relationships___field_pl_image_accessories_table___localFile___children = 'relationships___field_pl_image_accessories_table___localFile___children',
  relationships___field_pl_image_accessories_table___uri___url = 'relationships___field_pl_image_accessories_table___uri___url',
  relationships___field_pl_image_accessories_table___drupal_id = 'relationships___field_pl_image_accessories_table___drupal_id',
  relationships___field_pl_image_accessories_table___drupal_internal__fid = 'relationships___field_pl_image_accessories_table___drupal_internal__fid',
  relationships___field_pl_image_accessories_table___langcode = 'relationships___field_pl_image_accessories_table___langcode',
  relationships___field_pl_image_accessories_table___filename = 'relationships___field_pl_image_accessories_table___filename',
  relationships___field_pl_image_accessories_table___filemime = 'relationships___field_pl_image_accessories_table___filemime',
  relationships___field_pl_image_accessories_table___filesize = 'relationships___field_pl_image_accessories_table___filesize',
  relationships___field_pl_image_accessories_table___status = 'relationships___field_pl_image_accessories_table___status',
  relationships___field_pl_image_accessories_table___created = 'relationships___field_pl_image_accessories_table___created',
  relationships___field_pl_image_accessories_table___changed = 'relationships___field_pl_image_accessories_table___changed',
  relationships___field_pl_image_accessories_table___uuid = 'relationships___field_pl_image_accessories_table___uuid',
  relationships___field_pl_image_accessories_table___relationships___node__blog_post = 'relationships___field_pl_image_accessories_table___relationships___node__blog_post',
  relationships___field_pl_image_accessories_table___relationships___node__devices = 'relationships___field_pl_image_accessories_table___relationships___node__devices',
  relationships___field_pl_image_accessories_table___relationships___node__accessories = 'relationships___field_pl_image_accessories_table___relationships___node__accessories',
  relationships___field_pl_image_accessories_table___relationships___paragraph__news_image_title_text = 'relationships___field_pl_image_accessories_table___relationships___paragraph__news_image_title_text',
  relationships___field_pl_image_accessories_table___relationships___node__cases = 'relationships___field_pl_image_accessories_table___relationships___node__cases',
  relationships___field_pl_image_accessories_table___relationships___paragraph__branches_what_is_ipos = 'relationships___field_pl_image_accessories_table___relationships___paragraph__branches_what_is_ipos',
  relationships___field_pl_image_accessories_table___relationships___paragraph__branches_features = 'relationships___field_pl_image_accessories_table___relationships___paragraph__branches_features',
  relationships___field_pl_image_accessories_table___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_pl_image_accessories_table___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_pl_image_accessories_table___relationships___paragraph__branches_testimonials = 'relationships___field_pl_image_accessories_table___relationships___paragraph__branches_testimonials',
  relationships___field_pl_image_accessories_table___relationships___paragraph__branches_discounts = 'relationships___field_pl_image_accessories_table___relationships___paragraph__branches_discounts',
  relationships___field_pl_image_accessories_table___relationships___node__news = 'relationships___field_pl_image_accessories_table___relationships___node__news',
  relationships___field_pl_image_accessories_table___relationships___node__branches = 'relationships___field_pl_image_accessories_table___relationships___node__branches',
  relationships___field_pl_image_accessories_table___relationships___paragraph__branches_hero = 'relationships___field_pl_image_accessories_table___relationships___paragraph__branches_hero',
  relationships___field_pl_image_accessories_table___relationships___paragraph__bullets = 'relationships___field_pl_image_accessories_table___relationships___paragraph__bullets',
  relationships___field_pl_image_accessories_table___relationships___taxonomy_term__pricelist_group = 'relationships___field_pl_image_accessories_table___relationships___taxonomy_term__pricelist_group',
  relationships___field_pl_image_accessories_table___relationships___paragraph__branches_device = 'relationships___field_pl_image_accessories_table___relationships___paragraph__branches_device',
  relationships___field_pl_image_accessories_table___relationships___paragraph__branches_recommendation = 'relationships___field_pl_image_accessories_table___relationships___paragraph__branches_recommendation',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__accessories = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__accessories',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__branches = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__branches',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__about_us = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__about_us',
  relationships___field_pl_image_accessories_table___relationships___paragraph__logos = 'relationships___field_pl_image_accessories_table___relationships___paragraph__logos',
  relationships___field_pl_image_accessories_table___relationships___paragraph__how_it_works = 'relationships___field_pl_image_accessories_table___relationships___paragraph__how_it_works',
  relationships___field_pl_image_accessories_table___relationships___paragraph__ico_and_text = 'relationships___field_pl_image_accessories_table___relationships___paragraph__ico_and_text',
  relationships___field_pl_image_accessories_table___relationships___paragraph__hero_1 = 'relationships___field_pl_image_accessories_table___relationships___paragraph__hero_1',
  relationships___field_pl_image_accessories_table___relationships___paragraph__comprehensive_solution_item = 'relationships___field_pl_image_accessories_table___relationships___paragraph__comprehensive_solution_item',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__product = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__product',
  relationships___field_pl_image_accessories_table___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_pl_image_accessories_table___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_pl_image_accessories_table___relationships___paragraph__porownanie_terminali = 'relationships___field_pl_image_accessories_table___relationships___paragraph__porownanie_terminali',
  relationships___field_pl_image_accessories_table___relationships___paragraph__zdjecie_opis_link = 'relationships___field_pl_image_accessories_table___relationships___paragraph__zdjecie_opis_link',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__main_page = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__main_page',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_pl_image_accessories_table___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_pl_image_accessories_table___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__intercars = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__intercars',
  relationships___field_pl_image_accessories_table___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_pl_image_accessories_table___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_pl_image_accessories_table___relationships___node__agreement = 'relationships___field_pl_image_accessories_table___relationships___node__agreement',
  relationships___field_pl_image_accessories_table___relationships___node__fiscalization = 'relationships___field_pl_image_accessories_table___relationships___node__fiscalization',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__configurator = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__configurator',
  relationships___field_pl_image_accessories_table___relationships___paragraph__social_link = 'relationships___field_pl_image_accessories_table___relationships___paragraph__social_link',
  relationships___field_pl_image_accessories_table___relationships___paragraph__make_an_appointment = 'relationships___field_pl_image_accessories_table___relationships___paragraph__make_an_appointment',
  relationships___field_pl_image_accessories_table___relationships___paragraph__linked_icons = 'relationships___field_pl_image_accessories_table___relationships___paragraph__linked_icons',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__financing = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__financing',
  relationships___field_pl_image_accessories_table___relationships___site_setting_entity__franchise = 'relationships___field_pl_image_accessories_table___relationships___site_setting_entity__franchise',
  relationships___field_pl_image_accessories_table___id = 'relationships___field_pl_image_accessories_table___id',
  relationships___field_pl_image_accessories_table___parent___id = 'relationships___field_pl_image_accessories_table___parent___id',
  relationships___field_pl_image_accessories_table___parent___children = 'relationships___field_pl_image_accessories_table___parent___children',
  relationships___field_pl_image_accessories_table___children = 'relationships___field_pl_image_accessories_table___children',
  relationships___field_pl_image_accessories_table___children___id = 'relationships___field_pl_image_accessories_table___children___id',
  relationships___field_pl_image_accessories_table___children___children = 'relationships___field_pl_image_accessories_table___children___children',
  relationships___field_pl_image_accessories_table___internal___content = 'relationships___field_pl_image_accessories_table___internal___content',
  relationships___field_pl_image_accessories_table___internal___contentDigest = 'relationships___field_pl_image_accessories_table___internal___contentDigest',
  relationships___field_pl_image_accessories_table___internal___description = 'relationships___field_pl_image_accessories_table___internal___description',
  relationships___field_pl_image_accessories_table___internal___fieldOwners = 'relationships___field_pl_image_accessories_table___internal___fieldOwners',
  relationships___field_pl_image_accessories_table___internal___ignoreType = 'relationships___field_pl_image_accessories_table___internal___ignoreType',
  relationships___field_pl_image_accessories_table___internal___mediaType = 'relationships___field_pl_image_accessories_table___internal___mediaType',
  relationships___field_pl_image_accessories_table___internal___owner = 'relationships___field_pl_image_accessories_table___internal___owner',
  relationships___field_pl_image_accessories_table___internal___type = 'relationships___field_pl_image_accessories_table___internal___type',
  relationships___field_recommended_for_branches = 'relationships___field_recommended_for_branches',
  relationships___field_recommended_for_branches___langcode = 'relationships___field_recommended_for_branches___langcode',
  relationships___field_recommended_for_branches___id = 'relationships___field_recommended_for_branches___id',
  relationships___field_recommended_for_branches___drupal_id = 'relationships___field_recommended_for_branches___drupal_id',
  relationships___field_recommended_for_branches___title = 'relationships___field_recommended_for_branches___title',
  relationships___field_recommended_for_branches___field_pl_is_online = 'relationships___field_recommended_for_branches___field_pl_is_online',
  relationships___field_recommended_for_branches___field_branches_date = 'relationships___field_recommended_for_branches___field_branches_date',
  relationships___field_recommended_for_branches___field_weight = 'relationships___field_recommended_for_branches___field_weight',
  relationships___field_recommended_for_branches___path___alias = 'relationships___field_recommended_for_branches___path___alias',
  relationships___field_recommended_for_branches___field_meta_description = 'relationships___field_recommended_for_branches___field_meta_description',
  relationships___field_recommended_for_branches___field_meta_title = 'relationships___field_recommended_for_branches___field_meta_title',
  relationships___field_recommended_for_branches___relationships___node__accessories = 'relationships___field_recommended_for_branches___relationships___node__accessories',
  relationships___field_recommended_for_branches___relationships___node__cases = 'relationships___field_recommended_for_branches___relationships___node__cases',
  relationships___field_recommended_for_branches___relationships___node__devices = 'relationships___field_recommended_for_branches___relationships___node__devices',
  relationships___field_recommended_for_branches___relationships___node__tariffs = 'relationships___field_recommended_for_branches___relationships___node__tariffs',
  relationships___field_recommended_for_branches___drupal_internal__nid = 'relationships___field_recommended_for_branches___drupal_internal__nid',
  relationships___field_recommended_for_branches___status = 'relationships___field_recommended_for_branches___status',
  relationships___field_recommended_for_branches___content_translation_source = 'relationships___field_recommended_for_branches___content_translation_source',
  relationships___field_recommended_for_branches___content_translation_outdated = 'relationships___field_recommended_for_branches___content_translation_outdated',
  relationships___field_recommended_for_branches___uuid = 'relationships___field_recommended_for_branches___uuid',
  relationships___field_recommended_for_branches___field_branches_icon___alt = 'relationships___field_recommended_for_branches___field_branches_icon___alt',
  relationships___field_recommended_for_branches___field_branches_icon___title = 'relationships___field_recommended_for_branches___field_branches_icon___title',
  relationships___field_recommended_for_branches___field_branches_icon___width = 'relationships___field_recommended_for_branches___field_branches_icon___width',
  relationships___field_recommended_for_branches___field_branches_icon___height = 'relationships___field_recommended_for_branches___field_branches_icon___height',
  relationships___field_recommended_for_branches___field_branches_icon___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_branches_icon___drupal_internal__target_id',
  relationships___field_recommended_for_branches___field_branches_image___alt = 'relationships___field_recommended_for_branches___field_branches_image___alt',
  relationships___field_recommended_for_branches___field_branches_image___title = 'relationships___field_recommended_for_branches___field_branches_image___title',
  relationships___field_recommended_for_branches___field_branches_image___width = 'relationships___field_recommended_for_branches___field_branches_image___width',
  relationships___field_recommended_for_branches___field_branches_image___height = 'relationships___field_recommended_for_branches___field_branches_image___height',
  relationships___field_recommended_for_branches___field_branches_image___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_branches_image___drupal_internal__target_id',
  relationships___field_recommended_for_branches___field_components = 'relationships___field_recommended_for_branches___field_components',
  relationships___field_recommended_for_branches___field_components___target_revision_id = 'relationships___field_recommended_for_branches___field_components___target_revision_id',
  relationships___field_recommended_for_branches___field_components___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_components___drupal_internal__target_id',
  relationships___field_recommended_for_branches___parent___id = 'relationships___field_recommended_for_branches___parent___id',
  relationships___field_recommended_for_branches___parent___children = 'relationships___field_recommended_for_branches___parent___children',
  relationships___field_recommended_for_branches___children = 'relationships___field_recommended_for_branches___children',
  relationships___field_recommended_for_branches___children___id = 'relationships___field_recommended_for_branches___children___id',
  relationships___field_recommended_for_branches___children___children = 'relationships___field_recommended_for_branches___children___children',
  relationships___field_recommended_for_branches___internal___content = 'relationships___field_recommended_for_branches___internal___content',
  relationships___field_recommended_for_branches___internal___contentDigest = 'relationships___field_recommended_for_branches___internal___contentDigest',
  relationships___field_recommended_for_branches___internal___description = 'relationships___field_recommended_for_branches___internal___description',
  relationships___field_recommended_for_branches___internal___fieldOwners = 'relationships___field_recommended_for_branches___internal___fieldOwners',
  relationships___field_recommended_for_branches___internal___ignoreType = 'relationships___field_recommended_for_branches___internal___ignoreType',
  relationships___field_recommended_for_branches___internal___mediaType = 'relationships___field_recommended_for_branches___internal___mediaType',
  relationships___field_recommended_for_branches___internal___owner = 'relationships___field_recommended_for_branches___internal___owner',
  relationships___field_recommended_for_branches___internal___type = 'relationships___field_recommended_for_branches___internal___type',
  drupal_internal__nid = 'drupal_internal__nid',
  status = 'status',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  field_date_from_api___drupal_internal__target_id = 'field_date_from_api___drupal_internal__target_id',
  field_pl_accessory_type___drupal_internal__target_id = 'field_pl_accessory_type___drupal_internal__target_id',
  field_pl_image___alt = 'field_pl_image___alt',
  field_pl_image___title = 'field_pl_image___title',
  field_pl_image___width = 'field_pl_image___width',
  field_pl_image___height = 'field_pl_image___height',
  field_pl_image___drupal_internal__target_id = 'field_pl_image___drupal_internal__target_id',
  field_pl_image_accessories_outl___alt = 'field_pl_image_accessories_outl___alt',
  field_pl_image_accessories_outl___title = 'field_pl_image_accessories_outl___title',
  field_pl_image_accessories_outl___width = 'field_pl_image_accessories_outl___width',
  field_pl_image_accessories_outl___height = 'field_pl_image_accessories_outl___height',
  field_pl_image_accessories_outl___drupal_internal__target_id = 'field_pl_image_accessories_outl___drupal_internal__target_id',
  field_pl_image_accessories_table___alt = 'field_pl_image_accessories_table___alt',
  field_pl_image_accessories_table___title = 'field_pl_image_accessories_table___title',
  field_pl_image_accessories_table___width = 'field_pl_image_accessories_table___width',
  field_pl_image_accessories_table___height = 'field_pl_image_accessories_table___height',
  field_pl_image_accessories_table___drupal_internal__target_id = 'field_pl_image_accessories_table___drupal_internal__target_id',
  field_recommended_for_branches = 'field_recommended_for_branches',
  field_recommended_for_branches___drupal_internal__target_id = 'field_recommended_for_branches___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__AccessoriesFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pl_groupid?: Maybe<IntQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  relationships?: Maybe<Node__AccessoriesRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_date_from_api?: Maybe<Node__AccessoriesField_Date_From_ApiFilterInput>;
  field_pl_accessory_type?: Maybe<Node__AccessoriesField_Pl_Accessory_TypeFilterInput>;
  field_pl_image?: Maybe<Node__AccessoriesField_Pl_ImageFilterInput>;
  field_pl_image_accessories_outl?: Maybe<
    Node__AccessoriesField_Pl_Image_Accessories_OutlFilterInput
  >;
  field_pl_image_accessories_table?: Maybe<
    Node__AccessoriesField_Pl_Image_Accessories_TableFilterInput
  >;
  field_recommended_for_branches?: Maybe<
    Node__AccessoriesField_Recommended_For_BranchesFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__AccessoriesFilterListInput = {
  elemMatch?: Maybe<Node__AccessoriesFilterInput>;
};

export type Node__AccessoriesGroupConnection = {
  __typename?: 'node__accessoriesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__AccessoriesEdge>;
  nodes: Array<Node__Accessories>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__AccessoriesRelationships = {
  __typename?: 'node__accessoriesRelationships';
  field_date_from_api?: Maybe<Node__Price_List>;
  field_pl_accessory_type?: Maybe<Taxonomy_Term__Accesory_Type>;
  field_pl_image?: Maybe<File__File>;
  field_pl_image_accessories_outl?: Maybe<File__File>;
  field_pl_image_accessories_table?: Maybe<File__File>;
  field_recommended_for_branches?: Maybe<Array<Maybe<Node__Branches>>>;
};

export type Node__AccessoriesRelationshipsFilterInput = {
  field_date_from_api?: Maybe<Node__Price_ListFilterInput>;
  field_pl_accessory_type?: Maybe<Taxonomy_Term__Accesory_TypeFilterInput>;
  field_pl_image?: Maybe<File__FileFilterInput>;
  field_pl_image_accessories_outl?: Maybe<File__FileFilterInput>;
  field_pl_image_accessories_table?: Maybe<File__FileFilterInput>;
  field_recommended_for_branches?: Maybe<Node__BranchesFilterListInput>;
};

export type Node__AccessoriesSortInput = {
  fields?: Maybe<Array<Maybe<Node__AccessoriesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Agreement = Node & {
  __typename?: 'node__agreement';
  langcode?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  agreement_content?: Maybe<Agreement_Content>;
  agreement_explication?: Maybe<Agreement_Explication>;
  agreement_type?: Maybe<Scalars['String']>;
  agreement_pdf_title?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__AgreementRelationships>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  drupal_internal__vid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  agreement_payment_types?: Maybe<Array<Maybe<Node__AgreementAgreement_Payment_Types>>>;
  agreement_pdf?: Maybe<Node__AgreementAgreement_Pdf>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__AgreementCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Node__AgreementChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Node__AgreementAgreement_Payment_Types = {
  __typename?: 'node__agreementAgreement_payment_types';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__AgreementAgreement_Payment_TypesFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__AgreementAgreement_Payment_TypesFilterListInput = {
  elemMatch?: Maybe<Node__AgreementAgreement_Payment_TypesFilterInput>;
};

export type Node__AgreementAgreement_Pdf = {
  __typename?: 'node__agreementAgreement_pdf';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__AgreementAgreement_PdfFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__AgreementConnection = {
  __typename?: 'node__agreementConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__AgreementEdge>;
  nodes: Array<Node__Agreement>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__AgreementGroupConnection>;
};

export type Node__AgreementConnectionDistinctArgs = {
  field: Node__AgreementFieldsEnum;
};

export type Node__AgreementConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__AgreementFieldsEnum;
};

export type Node__AgreementEdge = {
  __typename?: 'node__agreementEdge';
  next?: Maybe<Node__Agreement>;
  node: Node__Agreement;
  previous?: Maybe<Node__Agreement>;
};

export enum Node__AgreementFieldsEnum {
  langcode = 'langcode',
  drupal_id = 'drupal_id',
  agreement_content___value = 'agreement_content___value',
  agreement_explication___value = 'agreement_explication___value',
  agreement_type = 'agreement_type',
  agreement_pdf_title = 'agreement_pdf_title',
  relationships___machine_api_name___machine_api_name = 'relationships___machine_api_name___machine_api_name',
  relationships___machine_api_name___name = 'relationships___machine_api_name___name',
  relationships___machine_api_name___drupal_id = 'relationships___machine_api_name___drupal_id',
  relationships___machine_api_name___drupal_internal__tid = 'relationships___machine_api_name___drupal_internal__tid',
  relationships___machine_api_name___langcode = 'relationships___machine_api_name___langcode',
  relationships___machine_api_name___status = 'relationships___machine_api_name___status',
  relationships___machine_api_name___weight = 'relationships___machine_api_name___weight',
  relationships___machine_api_name___changed = 'relationships___machine_api_name___changed',
  relationships___machine_api_name___uuid = 'relationships___machine_api_name___uuid',
  relationships___machine_api_name___relationships___node__agreement = 'relationships___machine_api_name___relationships___node__agreement',
  relationships___machine_api_name___relationships___node__devices = 'relationships___machine_api_name___relationships___node__devices',
  relationships___machine_api_name___vid___drupal_internal__target_id = 'relationships___machine_api_name___vid___drupal_internal__target_id',
  relationships___machine_api_name___id = 'relationships___machine_api_name___id',
  relationships___machine_api_name___parent___id = 'relationships___machine_api_name___parent___id',
  relationships___machine_api_name___parent___children = 'relationships___machine_api_name___parent___children',
  relationships___machine_api_name___children = 'relationships___machine_api_name___children',
  relationships___machine_api_name___children___id = 'relationships___machine_api_name___children___id',
  relationships___machine_api_name___children___children = 'relationships___machine_api_name___children___children',
  relationships___machine_api_name___internal___content = 'relationships___machine_api_name___internal___content',
  relationships___machine_api_name___internal___contentDigest = 'relationships___machine_api_name___internal___contentDigest',
  relationships___machine_api_name___internal___description = 'relationships___machine_api_name___internal___description',
  relationships___machine_api_name___internal___fieldOwners = 'relationships___machine_api_name___internal___fieldOwners',
  relationships___machine_api_name___internal___ignoreType = 'relationships___machine_api_name___internal___ignoreType',
  relationships___machine_api_name___internal___mediaType = 'relationships___machine_api_name___internal___mediaType',
  relationships___machine_api_name___internal___owner = 'relationships___machine_api_name___internal___owner',
  relationships___machine_api_name___internal___type = 'relationships___machine_api_name___internal___type',
  relationships___agreement_pdf___localFile___birthtime = 'relationships___agreement_pdf___localFile___birthtime',
  relationships___agreement_pdf___localFile___birthtimeMs = 'relationships___agreement_pdf___localFile___birthtimeMs',
  relationships___agreement_pdf___localFile___publicURL = 'relationships___agreement_pdf___localFile___publicURL',
  relationships___agreement_pdf___localFile___sourceInstanceName = 'relationships___agreement_pdf___localFile___sourceInstanceName',
  relationships___agreement_pdf___localFile___absolutePath = 'relationships___agreement_pdf___localFile___absolutePath',
  relationships___agreement_pdf___localFile___relativePath = 'relationships___agreement_pdf___localFile___relativePath',
  relationships___agreement_pdf___localFile___extension = 'relationships___agreement_pdf___localFile___extension',
  relationships___agreement_pdf___localFile___size = 'relationships___agreement_pdf___localFile___size',
  relationships___agreement_pdf___localFile___prettySize = 'relationships___agreement_pdf___localFile___prettySize',
  relationships___agreement_pdf___localFile___modifiedTime = 'relationships___agreement_pdf___localFile___modifiedTime',
  relationships___agreement_pdf___localFile___accessTime = 'relationships___agreement_pdf___localFile___accessTime',
  relationships___agreement_pdf___localFile___changeTime = 'relationships___agreement_pdf___localFile___changeTime',
  relationships___agreement_pdf___localFile___birthTime = 'relationships___agreement_pdf___localFile___birthTime',
  relationships___agreement_pdf___localFile___root = 'relationships___agreement_pdf___localFile___root',
  relationships___agreement_pdf___localFile___dir = 'relationships___agreement_pdf___localFile___dir',
  relationships___agreement_pdf___localFile___base = 'relationships___agreement_pdf___localFile___base',
  relationships___agreement_pdf___localFile___ext = 'relationships___agreement_pdf___localFile___ext',
  relationships___agreement_pdf___localFile___name = 'relationships___agreement_pdf___localFile___name',
  relationships___agreement_pdf___localFile___relativeDirectory = 'relationships___agreement_pdf___localFile___relativeDirectory',
  relationships___agreement_pdf___localFile___dev = 'relationships___agreement_pdf___localFile___dev',
  relationships___agreement_pdf___localFile___mode = 'relationships___agreement_pdf___localFile___mode',
  relationships___agreement_pdf___localFile___nlink = 'relationships___agreement_pdf___localFile___nlink',
  relationships___agreement_pdf___localFile___uid = 'relationships___agreement_pdf___localFile___uid',
  relationships___agreement_pdf___localFile___gid = 'relationships___agreement_pdf___localFile___gid',
  relationships___agreement_pdf___localFile___rdev = 'relationships___agreement_pdf___localFile___rdev',
  relationships___agreement_pdf___localFile___blksize = 'relationships___agreement_pdf___localFile___blksize',
  relationships___agreement_pdf___localFile___ino = 'relationships___agreement_pdf___localFile___ino',
  relationships___agreement_pdf___localFile___blocks = 'relationships___agreement_pdf___localFile___blocks',
  relationships___agreement_pdf___localFile___atimeMs = 'relationships___agreement_pdf___localFile___atimeMs',
  relationships___agreement_pdf___localFile___mtimeMs = 'relationships___agreement_pdf___localFile___mtimeMs',
  relationships___agreement_pdf___localFile___ctimeMs = 'relationships___agreement_pdf___localFile___ctimeMs',
  relationships___agreement_pdf___localFile___atime = 'relationships___agreement_pdf___localFile___atime',
  relationships___agreement_pdf___localFile___mtime = 'relationships___agreement_pdf___localFile___mtime',
  relationships___agreement_pdf___localFile___ctime = 'relationships___agreement_pdf___localFile___ctime',
  relationships___agreement_pdf___localFile___url = 'relationships___agreement_pdf___localFile___url',
  relationships___agreement_pdf___localFile___id = 'relationships___agreement_pdf___localFile___id',
  relationships___agreement_pdf___localFile___children = 'relationships___agreement_pdf___localFile___children',
  relationships___agreement_pdf___uri___url = 'relationships___agreement_pdf___uri___url',
  relationships___agreement_pdf___drupal_id = 'relationships___agreement_pdf___drupal_id',
  relationships___agreement_pdf___drupal_internal__fid = 'relationships___agreement_pdf___drupal_internal__fid',
  relationships___agreement_pdf___langcode = 'relationships___agreement_pdf___langcode',
  relationships___agreement_pdf___filename = 'relationships___agreement_pdf___filename',
  relationships___agreement_pdf___filemime = 'relationships___agreement_pdf___filemime',
  relationships___agreement_pdf___filesize = 'relationships___agreement_pdf___filesize',
  relationships___agreement_pdf___status = 'relationships___agreement_pdf___status',
  relationships___agreement_pdf___created = 'relationships___agreement_pdf___created',
  relationships___agreement_pdf___changed = 'relationships___agreement_pdf___changed',
  relationships___agreement_pdf___uuid = 'relationships___agreement_pdf___uuid',
  relationships___agreement_pdf___relationships___node__blog_post = 'relationships___agreement_pdf___relationships___node__blog_post',
  relationships___agreement_pdf___relationships___node__devices = 'relationships___agreement_pdf___relationships___node__devices',
  relationships___agreement_pdf___relationships___node__accessories = 'relationships___agreement_pdf___relationships___node__accessories',
  relationships___agreement_pdf___relationships___paragraph__news_image_title_text = 'relationships___agreement_pdf___relationships___paragraph__news_image_title_text',
  relationships___agreement_pdf___relationships___node__cases = 'relationships___agreement_pdf___relationships___node__cases',
  relationships___agreement_pdf___relationships___paragraph__branches_what_is_ipos = 'relationships___agreement_pdf___relationships___paragraph__branches_what_is_ipos',
  relationships___agreement_pdf___relationships___paragraph__branches_features = 'relationships___agreement_pdf___relationships___paragraph__branches_features',
  relationships___agreement_pdf___relationships___paragraph__branches_check_what_s_going_on = 'relationships___agreement_pdf___relationships___paragraph__branches_check_what_s_going_on',
  relationships___agreement_pdf___relationships___paragraph__branches_testimonials = 'relationships___agreement_pdf___relationships___paragraph__branches_testimonials',
  relationships___agreement_pdf___relationships___paragraph__branches_discounts = 'relationships___agreement_pdf___relationships___paragraph__branches_discounts',
  relationships___agreement_pdf___relationships___node__news = 'relationships___agreement_pdf___relationships___node__news',
  relationships___agreement_pdf___relationships___node__branches = 'relationships___agreement_pdf___relationships___node__branches',
  relationships___agreement_pdf___relationships___paragraph__branches_hero = 'relationships___agreement_pdf___relationships___paragraph__branches_hero',
  relationships___agreement_pdf___relationships___paragraph__bullets = 'relationships___agreement_pdf___relationships___paragraph__bullets',
  relationships___agreement_pdf___relationships___taxonomy_term__pricelist_group = 'relationships___agreement_pdf___relationships___taxonomy_term__pricelist_group',
  relationships___agreement_pdf___relationships___paragraph__branches_device = 'relationships___agreement_pdf___relationships___paragraph__branches_device',
  relationships___agreement_pdf___relationships___paragraph__branches_recommendation = 'relationships___agreement_pdf___relationships___paragraph__branches_recommendation',
  relationships___agreement_pdf___relationships___site_setting_entity__accessories = 'relationships___agreement_pdf___relationships___site_setting_entity__accessories',
  relationships___agreement_pdf___relationships___site_setting_entity__branches = 'relationships___agreement_pdf___relationships___site_setting_entity__branches',
  relationships___agreement_pdf___relationships___site_setting_entity__about_us = 'relationships___agreement_pdf___relationships___site_setting_entity__about_us',
  relationships___agreement_pdf___relationships___paragraph__logos = 'relationships___agreement_pdf___relationships___paragraph__logos',
  relationships___agreement_pdf___relationships___paragraph__how_it_works = 'relationships___agreement_pdf___relationships___paragraph__how_it_works',
  relationships___agreement_pdf___relationships___paragraph__ico_and_text = 'relationships___agreement_pdf___relationships___paragraph__ico_and_text',
  relationships___agreement_pdf___relationships___paragraph__hero_1 = 'relationships___agreement_pdf___relationships___paragraph__hero_1',
  relationships___agreement_pdf___relationships___paragraph__comprehensive_solution_item = 'relationships___agreement_pdf___relationships___paragraph__comprehensive_solution_item',
  relationships___agreement_pdf___relationships___site_setting_entity__product = 'relationships___agreement_pdf___relationships___site_setting_entity__product',
  relationships___agreement_pdf___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___agreement_pdf___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___agreement_pdf___relationships___paragraph__porownanie_terminali = 'relationships___agreement_pdf___relationships___paragraph__porownanie_terminali',
  relationships___agreement_pdf___relationships___paragraph__zdjecie_opis_link = 'relationships___agreement_pdf___relationships___paragraph__zdjecie_opis_link',
  relationships___agreement_pdf___relationships___site_setting_entity__main_page = 'relationships___agreement_pdf___relationships___site_setting_entity__main_page',
  relationships___agreement_pdf___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___agreement_pdf___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___agreement_pdf___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___agreement_pdf___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___agreement_pdf___relationships___site_setting_entity__intercars = 'relationships___agreement_pdf___relationships___site_setting_entity__intercars',
  relationships___agreement_pdf___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___agreement_pdf___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___agreement_pdf___relationships___node__agreement = 'relationships___agreement_pdf___relationships___node__agreement',
  relationships___agreement_pdf___relationships___node__fiscalization = 'relationships___agreement_pdf___relationships___node__fiscalization',
  relationships___agreement_pdf___relationships___site_setting_entity__configurator = 'relationships___agreement_pdf___relationships___site_setting_entity__configurator',
  relationships___agreement_pdf___relationships___paragraph__social_link = 'relationships___agreement_pdf___relationships___paragraph__social_link',
  relationships___agreement_pdf___relationships___paragraph__make_an_appointment = 'relationships___agreement_pdf___relationships___paragraph__make_an_appointment',
  relationships___agreement_pdf___relationships___paragraph__linked_icons = 'relationships___agreement_pdf___relationships___paragraph__linked_icons',
  relationships___agreement_pdf___relationships___site_setting_entity__financing = 'relationships___agreement_pdf___relationships___site_setting_entity__financing',
  relationships___agreement_pdf___relationships___site_setting_entity__franchise = 'relationships___agreement_pdf___relationships___site_setting_entity__franchise',
  relationships___agreement_pdf___id = 'relationships___agreement_pdf___id',
  relationships___agreement_pdf___parent___id = 'relationships___agreement_pdf___parent___id',
  relationships___agreement_pdf___parent___children = 'relationships___agreement_pdf___parent___children',
  relationships___agreement_pdf___children = 'relationships___agreement_pdf___children',
  relationships___agreement_pdf___children___id = 'relationships___agreement_pdf___children___id',
  relationships___agreement_pdf___children___children = 'relationships___agreement_pdf___children___children',
  relationships___agreement_pdf___internal___content = 'relationships___agreement_pdf___internal___content',
  relationships___agreement_pdf___internal___contentDigest = 'relationships___agreement_pdf___internal___contentDigest',
  relationships___agreement_pdf___internal___description = 'relationships___agreement_pdf___internal___description',
  relationships___agreement_pdf___internal___fieldOwners = 'relationships___agreement_pdf___internal___fieldOwners',
  relationships___agreement_pdf___internal___ignoreType = 'relationships___agreement_pdf___internal___ignoreType',
  relationships___agreement_pdf___internal___mediaType = 'relationships___agreement_pdf___internal___mediaType',
  relationships___agreement_pdf___internal___owner = 'relationships___agreement_pdf___internal___owner',
  relationships___agreement_pdf___internal___type = 'relationships___agreement_pdf___internal___type',
  relationships___agreement_payment_types = 'relationships___agreement_payment_types',
  relationships___agreement_payment_types___machine_api_name = 'relationships___agreement_payment_types___machine_api_name',
  relationships___agreement_payment_types___name = 'relationships___agreement_payment_types___name',
  relationships___agreement_payment_types___drupal_id = 'relationships___agreement_payment_types___drupal_id',
  relationships___agreement_payment_types___drupal_internal__tid = 'relationships___agreement_payment_types___drupal_internal__tid',
  relationships___agreement_payment_types___langcode = 'relationships___agreement_payment_types___langcode',
  relationships___agreement_payment_types___status = 'relationships___agreement_payment_types___status',
  relationships___agreement_payment_types___weight = 'relationships___agreement_payment_types___weight',
  relationships___agreement_payment_types___changed = 'relationships___agreement_payment_types___changed',
  relationships___agreement_payment_types___uuid = 'relationships___agreement_payment_types___uuid',
  relationships___agreement_payment_types___relationships___node__agreement = 'relationships___agreement_payment_types___relationships___node__agreement',
  relationships___agreement_payment_types___relationships___node__devices = 'relationships___agreement_payment_types___relationships___node__devices',
  relationships___agreement_payment_types___vid___drupal_internal__target_id = 'relationships___agreement_payment_types___vid___drupal_internal__target_id',
  relationships___agreement_payment_types___id = 'relationships___agreement_payment_types___id',
  relationships___agreement_payment_types___parent___id = 'relationships___agreement_payment_types___parent___id',
  relationships___agreement_payment_types___parent___children = 'relationships___agreement_payment_types___parent___children',
  relationships___agreement_payment_types___children = 'relationships___agreement_payment_types___children',
  relationships___agreement_payment_types___children___id = 'relationships___agreement_payment_types___children___id',
  relationships___agreement_payment_types___children___children = 'relationships___agreement_payment_types___children___children',
  relationships___agreement_payment_types___internal___content = 'relationships___agreement_payment_types___internal___content',
  relationships___agreement_payment_types___internal___contentDigest = 'relationships___agreement_payment_types___internal___contentDigest',
  relationships___agreement_payment_types___internal___description = 'relationships___agreement_payment_types___internal___description',
  relationships___agreement_payment_types___internal___fieldOwners = 'relationships___agreement_payment_types___internal___fieldOwners',
  relationships___agreement_payment_types___internal___ignoreType = 'relationships___agreement_payment_types___internal___ignoreType',
  relationships___agreement_payment_types___internal___mediaType = 'relationships___agreement_payment_types___internal___mediaType',
  relationships___agreement_payment_types___internal___owner = 'relationships___agreement_payment_types___internal___owner',
  relationships___agreement_payment_types___internal___type = 'relationships___agreement_payment_types___internal___type',
  relationships___paragraph__heading_and_agreements = 'relationships___paragraph__heading_and_agreements',
  relationships___paragraph__heading_and_agreements___field_heading_and_agrmnt_heading = 'relationships___paragraph__heading_and_agreements___field_heading_and_agrmnt_heading',
  relationships___paragraph__heading_and_agreements___relationships___field_agreements = 'relationships___paragraph__heading_and_agreements___relationships___field_agreements',
  relationships___paragraph__heading_and_agreements___relationships___paragraph__footer_section = 'relationships___paragraph__heading_and_agreements___relationships___paragraph__footer_section',
  relationships___paragraph__heading_and_agreements___drupal_id = 'relationships___paragraph__heading_and_agreements___drupal_id',
  relationships___paragraph__heading_and_agreements___drupal_internal__id = 'relationships___paragraph__heading_and_agreements___drupal_internal__id',
  relationships___paragraph__heading_and_agreements___drupal_internal__revision_id = 'relationships___paragraph__heading_and_agreements___drupal_internal__revision_id',
  relationships___paragraph__heading_and_agreements___langcode = 'relationships___paragraph__heading_and_agreements___langcode',
  relationships___paragraph__heading_and_agreements___status = 'relationships___paragraph__heading_and_agreements___status',
  relationships___paragraph__heading_and_agreements___created = 'relationships___paragraph__heading_and_agreements___created',
  relationships___paragraph__heading_and_agreements___parent_id = 'relationships___paragraph__heading_and_agreements___parent_id',
  relationships___paragraph__heading_and_agreements___parent_type = 'relationships___paragraph__heading_and_agreements___parent_type',
  relationships___paragraph__heading_and_agreements___parent_field_name = 'relationships___paragraph__heading_and_agreements___parent_field_name',
  relationships___paragraph__heading_and_agreements___default_langcode = 'relationships___paragraph__heading_and_agreements___default_langcode',
  relationships___paragraph__heading_and_agreements___revision_translation_affected = 'relationships___paragraph__heading_and_agreements___revision_translation_affected',
  relationships___paragraph__heading_and_agreements___content_translation_source = 'relationships___paragraph__heading_and_agreements___content_translation_source',
  relationships___paragraph__heading_and_agreements___content_translation_outdated = 'relationships___paragraph__heading_and_agreements___content_translation_outdated',
  relationships___paragraph__heading_and_agreements___content_translation_changed = 'relationships___paragraph__heading_and_agreements___content_translation_changed',
  relationships___paragraph__heading_and_agreements___uuid = 'relationships___paragraph__heading_and_agreements___uuid',
  relationships___paragraph__heading_and_agreements___field_agreements = 'relationships___paragraph__heading_and_agreements___field_agreements',
  relationships___paragraph__heading_and_agreements___field_agreements___drupal_internal__target_id = 'relationships___paragraph__heading_and_agreements___field_agreements___drupal_internal__target_id',
  relationships___paragraph__heading_and_agreements___id = 'relationships___paragraph__heading_and_agreements___id',
  relationships___paragraph__heading_and_agreements___parent___id = 'relationships___paragraph__heading_and_agreements___parent___id',
  relationships___paragraph__heading_and_agreements___parent___children = 'relationships___paragraph__heading_and_agreements___parent___children',
  relationships___paragraph__heading_and_agreements___children = 'relationships___paragraph__heading_and_agreements___children',
  relationships___paragraph__heading_and_agreements___children___id = 'relationships___paragraph__heading_and_agreements___children___id',
  relationships___paragraph__heading_and_agreements___children___children = 'relationships___paragraph__heading_and_agreements___children___children',
  relationships___paragraph__heading_and_agreements___internal___content = 'relationships___paragraph__heading_and_agreements___internal___content',
  relationships___paragraph__heading_and_agreements___internal___contentDigest = 'relationships___paragraph__heading_and_agreements___internal___contentDigest',
  relationships___paragraph__heading_and_agreements___internal___description = 'relationships___paragraph__heading_and_agreements___internal___description',
  relationships___paragraph__heading_and_agreements___internal___fieldOwners = 'relationships___paragraph__heading_and_agreements___internal___fieldOwners',
  relationships___paragraph__heading_and_agreements___internal___ignoreType = 'relationships___paragraph__heading_and_agreements___internal___ignoreType',
  relationships___paragraph__heading_and_agreements___internal___mediaType = 'relationships___paragraph__heading_and_agreements___internal___mediaType',
  relationships___paragraph__heading_and_agreements___internal___owner = 'relationships___paragraph__heading_and_agreements___internal___owner',
  relationships___paragraph__heading_and_agreements___internal___type = 'relationships___paragraph__heading_and_agreements___internal___type',
  drupal_internal__nid = 'drupal_internal__nid',
  drupal_internal__vid = 'drupal_internal__vid',
  status = 'status',
  title = 'title',
  created = 'created',
  changed = 'changed',
  uuid = 'uuid',
  agreement_payment_types = 'agreement_payment_types',
  agreement_payment_types___drupal_internal__target_id = 'agreement_payment_types___drupal_internal__target_id',
  agreement_pdf___drupal_internal__target_id = 'agreement_pdf___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__AgreementFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  agreement_content?: Maybe<Agreement_ContentFilterInput>;
  agreement_explication?: Maybe<Agreement_ExplicationFilterInput>;
  agreement_type?: Maybe<StringQueryOperatorInput>;
  agreement_pdf_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__AgreementRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__vid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  agreement_payment_types?: Maybe<Node__AgreementAgreement_Payment_TypesFilterListInput>;
  agreement_pdf?: Maybe<Node__AgreementAgreement_PdfFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__AgreementFilterListInput = {
  elemMatch?: Maybe<Node__AgreementFilterInput>;
};

export type Node__AgreementGroupConnection = {
  __typename?: 'node__agreementGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__AgreementEdge>;
  nodes: Array<Node__Agreement>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__AgreementRelationships = {
  __typename?: 'node__agreementRelationships';
  machine_api_name?: Maybe<Taxonomy_Term__Payment_Types>;
  agreement_pdf?: Maybe<File__File>;
  agreement_payment_types?: Maybe<Array<Maybe<Taxonomy_Term__Payment_Types>>>;
  paragraph__heading_and_agreements?: Maybe<Array<Maybe<Paragraph__Heading_And_Agreements>>>;
};

export type Node__AgreementRelationshipsFilterInput = {
  machine_api_name?: Maybe<Taxonomy_Term__Payment_TypesFilterInput>;
  agreement_pdf?: Maybe<File__FileFilterInput>;
  agreement_payment_types?: Maybe<Taxonomy_Term__Payment_TypesFilterListInput>;
  paragraph__heading_and_agreements?: Maybe<Paragraph__Heading_And_AgreementsFilterListInput>;
};

export type Node__AgreementSortInput = {
  fields?: Maybe<Array<Maybe<Node__AgreementFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Blog_Post = Node & {
  __typename?: 'node__blog_post';
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  drupal_internal__vid?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Node__Blog_PostPath>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Node__Blog_PostBody>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__Blog_PostRelationships>;
  field_blog_post_image?: Maybe<Node__Blog_PostField_Blog_Post_Image>;
};

export type Node__Blog_PostBody = {
  __typename?: 'node__blog_postBody';
  value?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  processed?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type Node__Blog_PostBodyFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
  format?: Maybe<StringQueryOperatorInput>;
  processed?: Maybe<StringQueryOperatorInput>;
  summary?: Maybe<StringQueryOperatorInput>;
};

export type Node__Blog_PostConnection = {
  __typename?: 'node__blog_postConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__Blog_PostEdge>;
  nodes: Array<Node__Blog_Post>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__Blog_PostGroupConnection>;
};

export type Node__Blog_PostConnectionDistinctArgs = {
  field: Node__Blog_PostFieldsEnum;
};

export type Node__Blog_PostConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__Blog_PostFieldsEnum;
};

export type Node__Blog_PostEdge = {
  __typename?: 'node__blog_postEdge';
  next?: Maybe<Node__Blog_Post>;
  node: Node__Blog_Post;
  previous?: Maybe<Node__Blog_Post>;
};

export type Node__Blog_PostField_Blog_Post_Image = {
  __typename?: 'node__blog_postField_blog_post_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__Blog_PostField_Blog_Post_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Node__Blog_PostFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__nid = 'drupal_internal__nid',
  drupal_internal__vid = 'drupal_internal__vid',
  title = 'title',
  default_langcode = 'default_langcode',
  path___langcode = 'path___langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  body___value = 'body___value',
  body___format = 'body___format',
  body___processed = 'body___processed',
  body___summary = 'body___summary',
  langcode = 'langcode',
  uuid = 'uuid',
  relationships___field_blog_post_image___localFile___birthtime = 'relationships___field_blog_post_image___localFile___birthtime',
  relationships___field_blog_post_image___localFile___birthtimeMs = 'relationships___field_blog_post_image___localFile___birthtimeMs',
  relationships___field_blog_post_image___localFile___publicURL = 'relationships___field_blog_post_image___localFile___publicURL',
  relationships___field_blog_post_image___localFile___sourceInstanceName = 'relationships___field_blog_post_image___localFile___sourceInstanceName',
  relationships___field_blog_post_image___localFile___absolutePath = 'relationships___field_blog_post_image___localFile___absolutePath',
  relationships___field_blog_post_image___localFile___relativePath = 'relationships___field_blog_post_image___localFile___relativePath',
  relationships___field_blog_post_image___localFile___extension = 'relationships___field_blog_post_image___localFile___extension',
  relationships___field_blog_post_image___localFile___size = 'relationships___field_blog_post_image___localFile___size',
  relationships___field_blog_post_image___localFile___prettySize = 'relationships___field_blog_post_image___localFile___prettySize',
  relationships___field_blog_post_image___localFile___modifiedTime = 'relationships___field_blog_post_image___localFile___modifiedTime',
  relationships___field_blog_post_image___localFile___accessTime = 'relationships___field_blog_post_image___localFile___accessTime',
  relationships___field_blog_post_image___localFile___changeTime = 'relationships___field_blog_post_image___localFile___changeTime',
  relationships___field_blog_post_image___localFile___birthTime = 'relationships___field_blog_post_image___localFile___birthTime',
  relationships___field_blog_post_image___localFile___root = 'relationships___field_blog_post_image___localFile___root',
  relationships___field_blog_post_image___localFile___dir = 'relationships___field_blog_post_image___localFile___dir',
  relationships___field_blog_post_image___localFile___base = 'relationships___field_blog_post_image___localFile___base',
  relationships___field_blog_post_image___localFile___ext = 'relationships___field_blog_post_image___localFile___ext',
  relationships___field_blog_post_image___localFile___name = 'relationships___field_blog_post_image___localFile___name',
  relationships___field_blog_post_image___localFile___relativeDirectory = 'relationships___field_blog_post_image___localFile___relativeDirectory',
  relationships___field_blog_post_image___localFile___dev = 'relationships___field_blog_post_image___localFile___dev',
  relationships___field_blog_post_image___localFile___mode = 'relationships___field_blog_post_image___localFile___mode',
  relationships___field_blog_post_image___localFile___nlink = 'relationships___field_blog_post_image___localFile___nlink',
  relationships___field_blog_post_image___localFile___uid = 'relationships___field_blog_post_image___localFile___uid',
  relationships___field_blog_post_image___localFile___gid = 'relationships___field_blog_post_image___localFile___gid',
  relationships___field_blog_post_image___localFile___rdev = 'relationships___field_blog_post_image___localFile___rdev',
  relationships___field_blog_post_image___localFile___blksize = 'relationships___field_blog_post_image___localFile___blksize',
  relationships___field_blog_post_image___localFile___ino = 'relationships___field_blog_post_image___localFile___ino',
  relationships___field_blog_post_image___localFile___blocks = 'relationships___field_blog_post_image___localFile___blocks',
  relationships___field_blog_post_image___localFile___atimeMs = 'relationships___field_blog_post_image___localFile___atimeMs',
  relationships___field_blog_post_image___localFile___mtimeMs = 'relationships___field_blog_post_image___localFile___mtimeMs',
  relationships___field_blog_post_image___localFile___ctimeMs = 'relationships___field_blog_post_image___localFile___ctimeMs',
  relationships___field_blog_post_image___localFile___atime = 'relationships___field_blog_post_image___localFile___atime',
  relationships___field_blog_post_image___localFile___mtime = 'relationships___field_blog_post_image___localFile___mtime',
  relationships___field_blog_post_image___localFile___ctime = 'relationships___field_blog_post_image___localFile___ctime',
  relationships___field_blog_post_image___localFile___url = 'relationships___field_blog_post_image___localFile___url',
  relationships___field_blog_post_image___localFile___id = 'relationships___field_blog_post_image___localFile___id',
  relationships___field_blog_post_image___localFile___children = 'relationships___field_blog_post_image___localFile___children',
  relationships___field_blog_post_image___uri___url = 'relationships___field_blog_post_image___uri___url',
  relationships___field_blog_post_image___drupal_id = 'relationships___field_blog_post_image___drupal_id',
  relationships___field_blog_post_image___drupal_internal__fid = 'relationships___field_blog_post_image___drupal_internal__fid',
  relationships___field_blog_post_image___langcode = 'relationships___field_blog_post_image___langcode',
  relationships___field_blog_post_image___filename = 'relationships___field_blog_post_image___filename',
  relationships___field_blog_post_image___filemime = 'relationships___field_blog_post_image___filemime',
  relationships___field_blog_post_image___filesize = 'relationships___field_blog_post_image___filesize',
  relationships___field_blog_post_image___status = 'relationships___field_blog_post_image___status',
  relationships___field_blog_post_image___created = 'relationships___field_blog_post_image___created',
  relationships___field_blog_post_image___changed = 'relationships___field_blog_post_image___changed',
  relationships___field_blog_post_image___uuid = 'relationships___field_blog_post_image___uuid',
  relationships___field_blog_post_image___relationships___node__blog_post = 'relationships___field_blog_post_image___relationships___node__blog_post',
  relationships___field_blog_post_image___relationships___node__devices = 'relationships___field_blog_post_image___relationships___node__devices',
  relationships___field_blog_post_image___relationships___node__accessories = 'relationships___field_blog_post_image___relationships___node__accessories',
  relationships___field_blog_post_image___relationships___paragraph__news_image_title_text = 'relationships___field_blog_post_image___relationships___paragraph__news_image_title_text',
  relationships___field_blog_post_image___relationships___node__cases = 'relationships___field_blog_post_image___relationships___node__cases',
  relationships___field_blog_post_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_blog_post_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_blog_post_image___relationships___paragraph__branches_features = 'relationships___field_blog_post_image___relationships___paragraph__branches_features',
  relationships___field_blog_post_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_blog_post_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_blog_post_image___relationships___paragraph__branches_testimonials = 'relationships___field_blog_post_image___relationships___paragraph__branches_testimonials',
  relationships___field_blog_post_image___relationships___paragraph__branches_discounts = 'relationships___field_blog_post_image___relationships___paragraph__branches_discounts',
  relationships___field_blog_post_image___relationships___node__news = 'relationships___field_blog_post_image___relationships___node__news',
  relationships___field_blog_post_image___relationships___node__branches = 'relationships___field_blog_post_image___relationships___node__branches',
  relationships___field_blog_post_image___relationships___paragraph__branches_hero = 'relationships___field_blog_post_image___relationships___paragraph__branches_hero',
  relationships___field_blog_post_image___relationships___paragraph__bullets = 'relationships___field_blog_post_image___relationships___paragraph__bullets',
  relationships___field_blog_post_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_blog_post_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_blog_post_image___relationships___paragraph__branches_device = 'relationships___field_blog_post_image___relationships___paragraph__branches_device',
  relationships___field_blog_post_image___relationships___paragraph__branches_recommendation = 'relationships___field_blog_post_image___relationships___paragraph__branches_recommendation',
  relationships___field_blog_post_image___relationships___site_setting_entity__accessories = 'relationships___field_blog_post_image___relationships___site_setting_entity__accessories',
  relationships___field_blog_post_image___relationships___site_setting_entity__branches = 'relationships___field_blog_post_image___relationships___site_setting_entity__branches',
  relationships___field_blog_post_image___relationships___site_setting_entity__about_us = 'relationships___field_blog_post_image___relationships___site_setting_entity__about_us',
  relationships___field_blog_post_image___relationships___paragraph__logos = 'relationships___field_blog_post_image___relationships___paragraph__logos',
  relationships___field_blog_post_image___relationships___paragraph__how_it_works = 'relationships___field_blog_post_image___relationships___paragraph__how_it_works',
  relationships___field_blog_post_image___relationships___paragraph__ico_and_text = 'relationships___field_blog_post_image___relationships___paragraph__ico_and_text',
  relationships___field_blog_post_image___relationships___paragraph__hero_1 = 'relationships___field_blog_post_image___relationships___paragraph__hero_1',
  relationships___field_blog_post_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_blog_post_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_blog_post_image___relationships___site_setting_entity__product = 'relationships___field_blog_post_image___relationships___site_setting_entity__product',
  relationships___field_blog_post_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_blog_post_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_blog_post_image___relationships___paragraph__porownanie_terminali = 'relationships___field_blog_post_image___relationships___paragraph__porownanie_terminali',
  relationships___field_blog_post_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_blog_post_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_blog_post_image___relationships___site_setting_entity__main_page = 'relationships___field_blog_post_image___relationships___site_setting_entity__main_page',
  relationships___field_blog_post_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_blog_post_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_blog_post_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_blog_post_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_blog_post_image___relationships___site_setting_entity__intercars = 'relationships___field_blog_post_image___relationships___site_setting_entity__intercars',
  relationships___field_blog_post_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_blog_post_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_blog_post_image___relationships___node__agreement = 'relationships___field_blog_post_image___relationships___node__agreement',
  relationships___field_blog_post_image___relationships___node__fiscalization = 'relationships___field_blog_post_image___relationships___node__fiscalization',
  relationships___field_blog_post_image___relationships___site_setting_entity__configurator = 'relationships___field_blog_post_image___relationships___site_setting_entity__configurator',
  relationships___field_blog_post_image___relationships___paragraph__social_link = 'relationships___field_blog_post_image___relationships___paragraph__social_link',
  relationships___field_blog_post_image___relationships___paragraph__make_an_appointment = 'relationships___field_blog_post_image___relationships___paragraph__make_an_appointment',
  relationships___field_blog_post_image___relationships___paragraph__linked_icons = 'relationships___field_blog_post_image___relationships___paragraph__linked_icons',
  relationships___field_blog_post_image___relationships___site_setting_entity__financing = 'relationships___field_blog_post_image___relationships___site_setting_entity__financing',
  relationships___field_blog_post_image___relationships___site_setting_entity__franchise = 'relationships___field_blog_post_image___relationships___site_setting_entity__franchise',
  relationships___field_blog_post_image___id = 'relationships___field_blog_post_image___id',
  relationships___field_blog_post_image___parent___id = 'relationships___field_blog_post_image___parent___id',
  relationships___field_blog_post_image___parent___children = 'relationships___field_blog_post_image___parent___children',
  relationships___field_blog_post_image___children = 'relationships___field_blog_post_image___children',
  relationships___field_blog_post_image___children___id = 'relationships___field_blog_post_image___children___id',
  relationships___field_blog_post_image___children___children = 'relationships___field_blog_post_image___children___children',
  relationships___field_blog_post_image___internal___content = 'relationships___field_blog_post_image___internal___content',
  relationships___field_blog_post_image___internal___contentDigest = 'relationships___field_blog_post_image___internal___contentDigest',
  relationships___field_blog_post_image___internal___description = 'relationships___field_blog_post_image___internal___description',
  relationships___field_blog_post_image___internal___fieldOwners = 'relationships___field_blog_post_image___internal___fieldOwners',
  relationships___field_blog_post_image___internal___ignoreType = 'relationships___field_blog_post_image___internal___ignoreType',
  relationships___field_blog_post_image___internal___mediaType = 'relationships___field_blog_post_image___internal___mediaType',
  relationships___field_blog_post_image___internal___owner = 'relationships___field_blog_post_image___internal___owner',
  relationships___field_blog_post_image___internal___type = 'relationships___field_blog_post_image___internal___type',
  field_blog_post_image___alt = 'field_blog_post_image___alt',
  field_blog_post_image___title = 'field_blog_post_image___title',
  field_blog_post_image___width = 'field_blog_post_image___width',
  field_blog_post_image___height = 'field_blog_post_image___height',
  field_blog_post_image___drupal_internal__target_id = 'field_blog_post_image___drupal_internal__target_id',
}

export type Node__Blog_PostFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__vid?: Maybe<IntQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  path?: Maybe<Node__Blog_PostPathFilterInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  body?: Maybe<Node__Blog_PostBodyFilterInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__Blog_PostRelationshipsFilterInput>;
  field_blog_post_image?: Maybe<Node__Blog_PostField_Blog_Post_ImageFilterInput>;
};

export type Node__Blog_PostFilterListInput = {
  elemMatch?: Maybe<Node__Blog_PostFilterInput>;
};

export type Node__Blog_PostGroupConnection = {
  __typename?: 'node__blog_postGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__Blog_PostEdge>;
  nodes: Array<Node__Blog_Post>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__Blog_PostPath = {
  __typename?: 'node__blog_postPath';
  langcode?: Maybe<Scalars['String']>;
};

export type Node__Blog_PostPathFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
};

export type Node__Blog_PostRelationships = {
  __typename?: 'node__blog_postRelationships';
  field_blog_post_image?: Maybe<File__File>;
};

export type Node__Blog_PostRelationshipsFilterInput = {
  field_blog_post_image?: Maybe<File__FileFilterInput>;
};

export type Node__Blog_PostSortInput = {
  fields?: Maybe<Array<Maybe<Node__Blog_PostFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Branches = Node & {
  __typename?: 'node__branches';
  langcode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  field_pl_is_online?: Maybe<Scalars['Boolean']>;
  field_branches_date?: Maybe<Scalars['String']>;
  field_weight?: Maybe<Scalars['Int']>;
  path?: Maybe<Path>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__BranchesRelationships>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_icon?: Maybe<Node__BranchesField_Branches_Icon>;
  field_branches_image?: Maybe<Node__BranchesField_Branches_Image>;
  field_components?: Maybe<Array<Maybe<Node__BranchesField_Components>>>;
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__BranchesConnection = {
  __typename?: 'node__branchesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__BranchesEdge>;
  nodes: Array<Node__Branches>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__BranchesGroupConnection>;
};

export type Node__BranchesConnectionDistinctArgs = {
  field: Node__BranchesFieldsEnum;
};

export type Node__BranchesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__BranchesFieldsEnum;
};

export type Node__BranchesEdge = {
  __typename?: 'node__branchesEdge';
  next?: Maybe<Node__Branches>;
  node: Node__Branches;
  previous?: Maybe<Node__Branches>;
};

export type Node__BranchesField_Branches_Icon = {
  __typename?: 'node__branchesField_branches_icon';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__BranchesField_Branches_IconFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__BranchesField_Branches_Image = {
  __typename?: 'node__branchesField_branches_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__BranchesField_Branches_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__BranchesField_Components = {
  __typename?: 'node__branchesField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__BranchesField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__BranchesField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Node__BranchesField_ComponentsFilterInput>;
};

export enum Node__BranchesFieldsEnum {
  langcode = 'langcode',
  id = 'id',
  drupal_id = 'drupal_id',
  title = 'title',
  field_pl_is_online = 'field_pl_is_online',
  field_branches_date = 'field_branches_date',
  field_weight = 'field_weight',
  path___alias = 'path___alias',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  relationships___field_branches_icon___localFile___birthtime = 'relationships___field_branches_icon___localFile___birthtime',
  relationships___field_branches_icon___localFile___birthtimeMs = 'relationships___field_branches_icon___localFile___birthtimeMs',
  relationships___field_branches_icon___localFile___publicURL = 'relationships___field_branches_icon___localFile___publicURL',
  relationships___field_branches_icon___localFile___sourceInstanceName = 'relationships___field_branches_icon___localFile___sourceInstanceName',
  relationships___field_branches_icon___localFile___absolutePath = 'relationships___field_branches_icon___localFile___absolutePath',
  relationships___field_branches_icon___localFile___relativePath = 'relationships___field_branches_icon___localFile___relativePath',
  relationships___field_branches_icon___localFile___extension = 'relationships___field_branches_icon___localFile___extension',
  relationships___field_branches_icon___localFile___size = 'relationships___field_branches_icon___localFile___size',
  relationships___field_branches_icon___localFile___prettySize = 'relationships___field_branches_icon___localFile___prettySize',
  relationships___field_branches_icon___localFile___modifiedTime = 'relationships___field_branches_icon___localFile___modifiedTime',
  relationships___field_branches_icon___localFile___accessTime = 'relationships___field_branches_icon___localFile___accessTime',
  relationships___field_branches_icon___localFile___changeTime = 'relationships___field_branches_icon___localFile___changeTime',
  relationships___field_branches_icon___localFile___birthTime = 'relationships___field_branches_icon___localFile___birthTime',
  relationships___field_branches_icon___localFile___root = 'relationships___field_branches_icon___localFile___root',
  relationships___field_branches_icon___localFile___dir = 'relationships___field_branches_icon___localFile___dir',
  relationships___field_branches_icon___localFile___base = 'relationships___field_branches_icon___localFile___base',
  relationships___field_branches_icon___localFile___ext = 'relationships___field_branches_icon___localFile___ext',
  relationships___field_branches_icon___localFile___name = 'relationships___field_branches_icon___localFile___name',
  relationships___field_branches_icon___localFile___relativeDirectory = 'relationships___field_branches_icon___localFile___relativeDirectory',
  relationships___field_branches_icon___localFile___dev = 'relationships___field_branches_icon___localFile___dev',
  relationships___field_branches_icon___localFile___mode = 'relationships___field_branches_icon___localFile___mode',
  relationships___field_branches_icon___localFile___nlink = 'relationships___field_branches_icon___localFile___nlink',
  relationships___field_branches_icon___localFile___uid = 'relationships___field_branches_icon___localFile___uid',
  relationships___field_branches_icon___localFile___gid = 'relationships___field_branches_icon___localFile___gid',
  relationships___field_branches_icon___localFile___rdev = 'relationships___field_branches_icon___localFile___rdev',
  relationships___field_branches_icon___localFile___blksize = 'relationships___field_branches_icon___localFile___blksize',
  relationships___field_branches_icon___localFile___ino = 'relationships___field_branches_icon___localFile___ino',
  relationships___field_branches_icon___localFile___blocks = 'relationships___field_branches_icon___localFile___blocks',
  relationships___field_branches_icon___localFile___atimeMs = 'relationships___field_branches_icon___localFile___atimeMs',
  relationships___field_branches_icon___localFile___mtimeMs = 'relationships___field_branches_icon___localFile___mtimeMs',
  relationships___field_branches_icon___localFile___ctimeMs = 'relationships___field_branches_icon___localFile___ctimeMs',
  relationships___field_branches_icon___localFile___atime = 'relationships___field_branches_icon___localFile___atime',
  relationships___field_branches_icon___localFile___mtime = 'relationships___field_branches_icon___localFile___mtime',
  relationships___field_branches_icon___localFile___ctime = 'relationships___field_branches_icon___localFile___ctime',
  relationships___field_branches_icon___localFile___url = 'relationships___field_branches_icon___localFile___url',
  relationships___field_branches_icon___localFile___id = 'relationships___field_branches_icon___localFile___id',
  relationships___field_branches_icon___localFile___children = 'relationships___field_branches_icon___localFile___children',
  relationships___field_branches_icon___uri___url = 'relationships___field_branches_icon___uri___url',
  relationships___field_branches_icon___drupal_id = 'relationships___field_branches_icon___drupal_id',
  relationships___field_branches_icon___drupal_internal__fid = 'relationships___field_branches_icon___drupal_internal__fid',
  relationships___field_branches_icon___langcode = 'relationships___field_branches_icon___langcode',
  relationships___field_branches_icon___filename = 'relationships___field_branches_icon___filename',
  relationships___field_branches_icon___filemime = 'relationships___field_branches_icon___filemime',
  relationships___field_branches_icon___filesize = 'relationships___field_branches_icon___filesize',
  relationships___field_branches_icon___status = 'relationships___field_branches_icon___status',
  relationships___field_branches_icon___created = 'relationships___field_branches_icon___created',
  relationships___field_branches_icon___changed = 'relationships___field_branches_icon___changed',
  relationships___field_branches_icon___uuid = 'relationships___field_branches_icon___uuid',
  relationships___field_branches_icon___relationships___node__blog_post = 'relationships___field_branches_icon___relationships___node__blog_post',
  relationships___field_branches_icon___relationships___node__devices = 'relationships___field_branches_icon___relationships___node__devices',
  relationships___field_branches_icon___relationships___node__accessories = 'relationships___field_branches_icon___relationships___node__accessories',
  relationships___field_branches_icon___relationships___paragraph__news_image_title_text = 'relationships___field_branches_icon___relationships___paragraph__news_image_title_text',
  relationships___field_branches_icon___relationships___node__cases = 'relationships___field_branches_icon___relationships___node__cases',
  relationships___field_branches_icon___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_icon___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_icon___relationships___paragraph__branches_features = 'relationships___field_branches_icon___relationships___paragraph__branches_features',
  relationships___field_branches_icon___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_icon___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_icon___relationships___paragraph__branches_testimonials = 'relationships___field_branches_icon___relationships___paragraph__branches_testimonials',
  relationships___field_branches_icon___relationships___paragraph__branches_discounts = 'relationships___field_branches_icon___relationships___paragraph__branches_discounts',
  relationships___field_branches_icon___relationships___node__news = 'relationships___field_branches_icon___relationships___node__news',
  relationships___field_branches_icon___relationships___node__branches = 'relationships___field_branches_icon___relationships___node__branches',
  relationships___field_branches_icon___relationships___paragraph__branches_hero = 'relationships___field_branches_icon___relationships___paragraph__branches_hero',
  relationships___field_branches_icon___relationships___paragraph__bullets = 'relationships___field_branches_icon___relationships___paragraph__bullets',
  relationships___field_branches_icon___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_icon___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_icon___relationships___paragraph__branches_device = 'relationships___field_branches_icon___relationships___paragraph__branches_device',
  relationships___field_branches_icon___relationships___paragraph__branches_recommendation = 'relationships___field_branches_icon___relationships___paragraph__branches_recommendation',
  relationships___field_branches_icon___relationships___site_setting_entity__accessories = 'relationships___field_branches_icon___relationships___site_setting_entity__accessories',
  relationships___field_branches_icon___relationships___site_setting_entity__branches = 'relationships___field_branches_icon___relationships___site_setting_entity__branches',
  relationships___field_branches_icon___relationships___site_setting_entity__about_us = 'relationships___field_branches_icon___relationships___site_setting_entity__about_us',
  relationships___field_branches_icon___relationships___paragraph__logos = 'relationships___field_branches_icon___relationships___paragraph__logos',
  relationships___field_branches_icon___relationships___paragraph__how_it_works = 'relationships___field_branches_icon___relationships___paragraph__how_it_works',
  relationships___field_branches_icon___relationships___paragraph__ico_and_text = 'relationships___field_branches_icon___relationships___paragraph__ico_and_text',
  relationships___field_branches_icon___relationships___paragraph__hero_1 = 'relationships___field_branches_icon___relationships___paragraph__hero_1',
  relationships___field_branches_icon___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_icon___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_icon___relationships___site_setting_entity__product = 'relationships___field_branches_icon___relationships___site_setting_entity__product',
  relationships___field_branches_icon___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_icon___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_icon___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_icon___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_icon___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_icon___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_icon___relationships___site_setting_entity__main_page = 'relationships___field_branches_icon___relationships___site_setting_entity__main_page',
  relationships___field_branches_icon___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_icon___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_icon___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_icon___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_icon___relationships___site_setting_entity__intercars = 'relationships___field_branches_icon___relationships___site_setting_entity__intercars',
  relationships___field_branches_icon___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_icon___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_icon___relationships___node__agreement = 'relationships___field_branches_icon___relationships___node__agreement',
  relationships___field_branches_icon___relationships___node__fiscalization = 'relationships___field_branches_icon___relationships___node__fiscalization',
  relationships___field_branches_icon___relationships___site_setting_entity__configurator = 'relationships___field_branches_icon___relationships___site_setting_entity__configurator',
  relationships___field_branches_icon___relationships___paragraph__social_link = 'relationships___field_branches_icon___relationships___paragraph__social_link',
  relationships___field_branches_icon___relationships___paragraph__make_an_appointment = 'relationships___field_branches_icon___relationships___paragraph__make_an_appointment',
  relationships___field_branches_icon___relationships___paragraph__linked_icons = 'relationships___field_branches_icon___relationships___paragraph__linked_icons',
  relationships___field_branches_icon___relationships___site_setting_entity__financing = 'relationships___field_branches_icon___relationships___site_setting_entity__financing',
  relationships___field_branches_icon___relationships___site_setting_entity__franchise = 'relationships___field_branches_icon___relationships___site_setting_entity__franchise',
  relationships___field_branches_icon___id = 'relationships___field_branches_icon___id',
  relationships___field_branches_icon___parent___id = 'relationships___field_branches_icon___parent___id',
  relationships___field_branches_icon___parent___children = 'relationships___field_branches_icon___parent___children',
  relationships___field_branches_icon___children = 'relationships___field_branches_icon___children',
  relationships___field_branches_icon___children___id = 'relationships___field_branches_icon___children___id',
  relationships___field_branches_icon___children___children = 'relationships___field_branches_icon___children___children',
  relationships___field_branches_icon___internal___content = 'relationships___field_branches_icon___internal___content',
  relationships___field_branches_icon___internal___contentDigest = 'relationships___field_branches_icon___internal___contentDigest',
  relationships___field_branches_icon___internal___description = 'relationships___field_branches_icon___internal___description',
  relationships___field_branches_icon___internal___fieldOwners = 'relationships___field_branches_icon___internal___fieldOwners',
  relationships___field_branches_icon___internal___ignoreType = 'relationships___field_branches_icon___internal___ignoreType',
  relationships___field_branches_icon___internal___mediaType = 'relationships___field_branches_icon___internal___mediaType',
  relationships___field_branches_icon___internal___owner = 'relationships___field_branches_icon___internal___owner',
  relationships___field_branches_icon___internal___type = 'relationships___field_branches_icon___internal___type',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___image_large___localFile___birthtime = 'relationships___image_large___localFile___birthtime',
  relationships___image_large___localFile___birthtimeMs = 'relationships___image_large___localFile___birthtimeMs',
  relationships___image_large___localFile___publicURL = 'relationships___image_large___localFile___publicURL',
  relationships___image_large___localFile___sourceInstanceName = 'relationships___image_large___localFile___sourceInstanceName',
  relationships___image_large___localFile___absolutePath = 'relationships___image_large___localFile___absolutePath',
  relationships___image_large___localFile___relativePath = 'relationships___image_large___localFile___relativePath',
  relationships___image_large___localFile___extension = 'relationships___image_large___localFile___extension',
  relationships___image_large___localFile___size = 'relationships___image_large___localFile___size',
  relationships___image_large___localFile___prettySize = 'relationships___image_large___localFile___prettySize',
  relationships___image_large___localFile___modifiedTime = 'relationships___image_large___localFile___modifiedTime',
  relationships___image_large___localFile___accessTime = 'relationships___image_large___localFile___accessTime',
  relationships___image_large___localFile___changeTime = 'relationships___image_large___localFile___changeTime',
  relationships___image_large___localFile___birthTime = 'relationships___image_large___localFile___birthTime',
  relationships___image_large___localFile___root = 'relationships___image_large___localFile___root',
  relationships___image_large___localFile___dir = 'relationships___image_large___localFile___dir',
  relationships___image_large___localFile___base = 'relationships___image_large___localFile___base',
  relationships___image_large___localFile___ext = 'relationships___image_large___localFile___ext',
  relationships___image_large___localFile___name = 'relationships___image_large___localFile___name',
  relationships___image_large___localFile___relativeDirectory = 'relationships___image_large___localFile___relativeDirectory',
  relationships___image_large___localFile___dev = 'relationships___image_large___localFile___dev',
  relationships___image_large___localFile___mode = 'relationships___image_large___localFile___mode',
  relationships___image_large___localFile___nlink = 'relationships___image_large___localFile___nlink',
  relationships___image_large___localFile___uid = 'relationships___image_large___localFile___uid',
  relationships___image_large___localFile___gid = 'relationships___image_large___localFile___gid',
  relationships___image_large___localFile___rdev = 'relationships___image_large___localFile___rdev',
  relationships___image_large___localFile___blksize = 'relationships___image_large___localFile___blksize',
  relationships___image_large___localFile___ino = 'relationships___image_large___localFile___ino',
  relationships___image_large___localFile___blocks = 'relationships___image_large___localFile___blocks',
  relationships___image_large___localFile___atimeMs = 'relationships___image_large___localFile___atimeMs',
  relationships___image_large___localFile___mtimeMs = 'relationships___image_large___localFile___mtimeMs',
  relationships___image_large___localFile___ctimeMs = 'relationships___image_large___localFile___ctimeMs',
  relationships___image_large___localFile___atime = 'relationships___image_large___localFile___atime',
  relationships___image_large___localFile___mtime = 'relationships___image_large___localFile___mtime',
  relationships___image_large___localFile___ctime = 'relationships___image_large___localFile___ctime',
  relationships___image_large___localFile___url = 'relationships___image_large___localFile___url',
  relationships___image_large___localFile___id = 'relationships___image_large___localFile___id',
  relationships___image_large___localFile___children = 'relationships___image_large___localFile___children',
  relationships___image_large___uri___url = 'relationships___image_large___uri___url',
  relationships___image_large___drupal_id = 'relationships___image_large___drupal_id',
  relationships___image_large___drupal_internal__fid = 'relationships___image_large___drupal_internal__fid',
  relationships___image_large___langcode = 'relationships___image_large___langcode',
  relationships___image_large___filename = 'relationships___image_large___filename',
  relationships___image_large___filemime = 'relationships___image_large___filemime',
  relationships___image_large___filesize = 'relationships___image_large___filesize',
  relationships___image_large___status = 'relationships___image_large___status',
  relationships___image_large___created = 'relationships___image_large___created',
  relationships___image_large___changed = 'relationships___image_large___changed',
  relationships___image_large___uuid = 'relationships___image_large___uuid',
  relationships___image_large___relationships___node__blog_post = 'relationships___image_large___relationships___node__blog_post',
  relationships___image_large___relationships___node__devices = 'relationships___image_large___relationships___node__devices',
  relationships___image_large___relationships___node__accessories = 'relationships___image_large___relationships___node__accessories',
  relationships___image_large___relationships___paragraph__news_image_title_text = 'relationships___image_large___relationships___paragraph__news_image_title_text',
  relationships___image_large___relationships___node__cases = 'relationships___image_large___relationships___node__cases',
  relationships___image_large___relationships___paragraph__branches_what_is_ipos = 'relationships___image_large___relationships___paragraph__branches_what_is_ipos',
  relationships___image_large___relationships___paragraph__branches_features = 'relationships___image_large___relationships___paragraph__branches_features',
  relationships___image_large___relationships___paragraph__branches_check_what_s_going_on = 'relationships___image_large___relationships___paragraph__branches_check_what_s_going_on',
  relationships___image_large___relationships___paragraph__branches_testimonials = 'relationships___image_large___relationships___paragraph__branches_testimonials',
  relationships___image_large___relationships___paragraph__branches_discounts = 'relationships___image_large___relationships___paragraph__branches_discounts',
  relationships___image_large___relationships___node__news = 'relationships___image_large___relationships___node__news',
  relationships___image_large___relationships___node__branches = 'relationships___image_large___relationships___node__branches',
  relationships___image_large___relationships___paragraph__branches_hero = 'relationships___image_large___relationships___paragraph__branches_hero',
  relationships___image_large___relationships___paragraph__bullets = 'relationships___image_large___relationships___paragraph__bullets',
  relationships___image_large___relationships___taxonomy_term__pricelist_group = 'relationships___image_large___relationships___taxonomy_term__pricelist_group',
  relationships___image_large___relationships___paragraph__branches_device = 'relationships___image_large___relationships___paragraph__branches_device',
  relationships___image_large___relationships___paragraph__branches_recommendation = 'relationships___image_large___relationships___paragraph__branches_recommendation',
  relationships___image_large___relationships___site_setting_entity__accessories = 'relationships___image_large___relationships___site_setting_entity__accessories',
  relationships___image_large___relationships___site_setting_entity__branches = 'relationships___image_large___relationships___site_setting_entity__branches',
  relationships___image_large___relationships___site_setting_entity__about_us = 'relationships___image_large___relationships___site_setting_entity__about_us',
  relationships___image_large___relationships___paragraph__logos = 'relationships___image_large___relationships___paragraph__logos',
  relationships___image_large___relationships___paragraph__how_it_works = 'relationships___image_large___relationships___paragraph__how_it_works',
  relationships___image_large___relationships___paragraph__ico_and_text = 'relationships___image_large___relationships___paragraph__ico_and_text',
  relationships___image_large___relationships___paragraph__hero_1 = 'relationships___image_large___relationships___paragraph__hero_1',
  relationships___image_large___relationships___paragraph__comprehensive_solution_item = 'relationships___image_large___relationships___paragraph__comprehensive_solution_item',
  relationships___image_large___relationships___site_setting_entity__product = 'relationships___image_large___relationships___site_setting_entity__product',
  relationships___image_large___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___image_large___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___image_large___relationships___paragraph__porownanie_terminali = 'relationships___image_large___relationships___paragraph__porownanie_terminali',
  relationships___image_large___relationships___paragraph__zdjecie_opis_link = 'relationships___image_large___relationships___paragraph__zdjecie_opis_link',
  relationships___image_large___relationships___site_setting_entity__main_page = 'relationships___image_large___relationships___site_setting_entity__main_page',
  relationships___image_large___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___image_large___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___image_large___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___image_large___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___image_large___relationships___site_setting_entity__intercars = 'relationships___image_large___relationships___site_setting_entity__intercars',
  relationships___image_large___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___image_large___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___image_large___relationships___node__agreement = 'relationships___image_large___relationships___node__agreement',
  relationships___image_large___relationships___node__fiscalization = 'relationships___image_large___relationships___node__fiscalization',
  relationships___image_large___relationships___site_setting_entity__configurator = 'relationships___image_large___relationships___site_setting_entity__configurator',
  relationships___image_large___relationships___paragraph__social_link = 'relationships___image_large___relationships___paragraph__social_link',
  relationships___image_large___relationships___paragraph__make_an_appointment = 'relationships___image_large___relationships___paragraph__make_an_appointment',
  relationships___image_large___relationships___paragraph__linked_icons = 'relationships___image_large___relationships___paragraph__linked_icons',
  relationships___image_large___relationships___site_setting_entity__financing = 'relationships___image_large___relationships___site_setting_entity__financing',
  relationships___image_large___relationships___site_setting_entity__franchise = 'relationships___image_large___relationships___site_setting_entity__franchise',
  relationships___image_large___id = 'relationships___image_large___id',
  relationships___image_large___parent___id = 'relationships___image_large___parent___id',
  relationships___image_large___parent___children = 'relationships___image_large___parent___children',
  relationships___image_large___children = 'relationships___image_large___children',
  relationships___image_large___children___id = 'relationships___image_large___children___id',
  relationships___image_large___children___children = 'relationships___image_large___children___children',
  relationships___image_large___internal___content = 'relationships___image_large___internal___content',
  relationships___image_large___internal___contentDigest = 'relationships___image_large___internal___contentDigest',
  relationships___image_large___internal___description = 'relationships___image_large___internal___description',
  relationships___image_large___internal___fieldOwners = 'relationships___image_large___internal___fieldOwners',
  relationships___image_large___internal___ignoreType = 'relationships___image_large___internal___ignoreType',
  relationships___image_large___internal___mediaType = 'relationships___image_large___internal___mediaType',
  relationships___image_large___internal___owner = 'relationships___image_large___internal___owner',
  relationships___image_large___internal___type = 'relationships___image_large___internal___type',
  relationships___node__accessories = 'relationships___node__accessories',
  relationships___node__accessories___langcode = 'relationships___node__accessories___langcode',
  relationships___node__accessories___drupal_id = 'relationships___node__accessories___drupal_id',
  relationships___node__accessories___title = 'relationships___node__accessories___title',
  relationships___node__accessories___field_pl_groupid = 'relationships___node__accessories___field_pl_groupid',
  relationships___node__accessories___field_weight = 'relationships___node__accessories___field_weight',
  relationships___node__accessories___relationships___field_recommended_for_branches = 'relationships___node__accessories___relationships___field_recommended_for_branches',
  relationships___node__accessories___drupal_internal__nid = 'relationships___node__accessories___drupal_internal__nid',
  relationships___node__accessories___status = 'relationships___node__accessories___status',
  relationships___node__accessories___content_translation_source = 'relationships___node__accessories___content_translation_source',
  relationships___node__accessories___content_translation_outdated = 'relationships___node__accessories___content_translation_outdated',
  relationships___node__accessories___uuid = 'relationships___node__accessories___uuid',
  relationships___node__accessories___field_date_from_api___drupal_internal__target_id = 'relationships___node__accessories___field_date_from_api___drupal_internal__target_id',
  relationships___node__accessories___field_pl_accessory_type___drupal_internal__target_id = 'relationships___node__accessories___field_pl_accessory_type___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image___alt = 'relationships___node__accessories___field_pl_image___alt',
  relationships___node__accessories___field_pl_image___title = 'relationships___node__accessories___field_pl_image___title',
  relationships___node__accessories___field_pl_image___width = 'relationships___node__accessories___field_pl_image___width',
  relationships___node__accessories___field_pl_image___height = 'relationships___node__accessories___field_pl_image___height',
  relationships___node__accessories___field_pl_image___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image_accessories_outl___alt = 'relationships___node__accessories___field_pl_image_accessories_outl___alt',
  relationships___node__accessories___field_pl_image_accessories_outl___title = 'relationships___node__accessories___field_pl_image_accessories_outl___title',
  relationships___node__accessories___field_pl_image_accessories_outl___width = 'relationships___node__accessories___field_pl_image_accessories_outl___width',
  relationships___node__accessories___field_pl_image_accessories_outl___height = 'relationships___node__accessories___field_pl_image_accessories_outl___height',
  relationships___node__accessories___field_pl_image_accessories_outl___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image_accessories_outl___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image_accessories_table___alt = 'relationships___node__accessories___field_pl_image_accessories_table___alt',
  relationships___node__accessories___field_pl_image_accessories_table___title = 'relationships___node__accessories___field_pl_image_accessories_table___title',
  relationships___node__accessories___field_pl_image_accessories_table___width = 'relationships___node__accessories___field_pl_image_accessories_table___width',
  relationships___node__accessories___field_pl_image_accessories_table___height = 'relationships___node__accessories___field_pl_image_accessories_table___height',
  relationships___node__accessories___field_pl_image_accessories_table___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image_accessories_table___drupal_internal__target_id',
  relationships___node__accessories___field_recommended_for_branches = 'relationships___node__accessories___field_recommended_for_branches',
  relationships___node__accessories___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__accessories___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__accessories___id = 'relationships___node__accessories___id',
  relationships___node__accessories___parent___id = 'relationships___node__accessories___parent___id',
  relationships___node__accessories___parent___children = 'relationships___node__accessories___parent___children',
  relationships___node__accessories___children = 'relationships___node__accessories___children',
  relationships___node__accessories___children___id = 'relationships___node__accessories___children___id',
  relationships___node__accessories___children___children = 'relationships___node__accessories___children___children',
  relationships___node__accessories___internal___content = 'relationships___node__accessories___internal___content',
  relationships___node__accessories___internal___contentDigest = 'relationships___node__accessories___internal___contentDigest',
  relationships___node__accessories___internal___description = 'relationships___node__accessories___internal___description',
  relationships___node__accessories___internal___fieldOwners = 'relationships___node__accessories___internal___fieldOwners',
  relationships___node__accessories___internal___ignoreType = 'relationships___node__accessories___internal___ignoreType',
  relationships___node__accessories___internal___mediaType = 'relationships___node__accessories___internal___mediaType',
  relationships___node__accessories___internal___owner = 'relationships___node__accessories___internal___owner',
  relationships___node__accessories___internal___type = 'relationships___node__accessories___internal___type',
  relationships___node__cases = 'relationships___node__cases',
  relationships___node__cases___drupal_id = 'relationships___node__cases___drupal_id',
  relationships___node__cases___title = 'relationships___node__cases___title',
  relationships___node__cases___field_pl_color = 'relationships___node__cases___field_pl_color',
  relationships___node__cases___relationships___field_recommended_for_branches = 'relationships___node__cases___relationships___field_recommended_for_branches',
  relationships___node__cases___relationships___node__devices = 'relationships___node__cases___relationships___node__devices',
  relationships___node__cases___drupal_internal__nid = 'relationships___node__cases___drupal_internal__nid',
  relationships___node__cases___status = 'relationships___node__cases___status',
  relationships___node__cases___content_translation_source = 'relationships___node__cases___content_translation_source',
  relationships___node__cases___content_translation_outdated = 'relationships___node__cases___content_translation_outdated',
  relationships___node__cases___langcode = 'relationships___node__cases___langcode',
  relationships___node__cases___uuid = 'relationships___node__cases___uuid',
  relationships___node__cases___field_pl_image___alt = 'relationships___node__cases___field_pl_image___alt',
  relationships___node__cases___field_pl_image___title = 'relationships___node__cases___field_pl_image___title',
  relationships___node__cases___field_pl_image___width = 'relationships___node__cases___field_pl_image___width',
  relationships___node__cases___field_pl_image___height = 'relationships___node__cases___field_pl_image___height',
  relationships___node__cases___field_pl_image___drupal_internal__target_id = 'relationships___node__cases___field_pl_image___drupal_internal__target_id',
  relationships___node__cases___field_recommended_for_branches = 'relationships___node__cases___field_recommended_for_branches',
  relationships___node__cases___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__cases___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__cases___field_date_from_api___drupal_internal__target_id = 'relationships___node__cases___field_date_from_api___drupal_internal__target_id',
  relationships___node__cases___id = 'relationships___node__cases___id',
  relationships___node__cases___parent___id = 'relationships___node__cases___parent___id',
  relationships___node__cases___parent___children = 'relationships___node__cases___parent___children',
  relationships___node__cases___children = 'relationships___node__cases___children',
  relationships___node__cases___children___id = 'relationships___node__cases___children___id',
  relationships___node__cases___children___children = 'relationships___node__cases___children___children',
  relationships___node__cases___internal___content = 'relationships___node__cases___internal___content',
  relationships___node__cases___internal___contentDigest = 'relationships___node__cases___internal___contentDigest',
  relationships___node__cases___internal___description = 'relationships___node__cases___internal___description',
  relationships___node__cases___internal___fieldOwners = 'relationships___node__cases___internal___fieldOwners',
  relationships___node__cases___internal___ignoreType = 'relationships___node__cases___internal___ignoreType',
  relationships___node__cases___internal___mediaType = 'relationships___node__cases___internal___mediaType',
  relationships___node__cases___internal___owner = 'relationships___node__cases___internal___owner',
  relationships___node__cases___internal___type = 'relationships___node__cases___internal___type',
  relationships___node__devices = 'relationships___node__devices',
  relationships___node__devices___langcode = 'relationships___node__devices___langcode',
  relationships___node__devices___drupal_id = 'relationships___node__devices___drupal_id',
  relationships___node__devices___title = 'relationships___node__devices___title',
  relationships___node__devices___field_subtitle = 'relationships___node__devices___field_subtitle',
  relationships___node__devices___field_pl_description_in_cms___value = 'relationships___node__devices___field_pl_description_in_cms___value',
  relationships___node__devices___field_pl_is_online = 'relationships___node__devices___field_pl_is_online',
  relationships___node__devices___field_weight = 'relationships___node__devices___field_weight',
  relationships___node__devices___field_pl_strikethrough_price = 'relationships___node__devices___field_pl_strikethrough_price',
  relationships___node__devices___relationships___field_recommended_for_branches = 'relationships___node__devices___relationships___field_recommended_for_branches',
  relationships___node__devices___relationships___field_accessories = 'relationships___node__devices___relationships___field_accessories',
  relationships___node__devices___relationships___field_pl_cases = 'relationships___node__devices___relationships___field_pl_cases',
  relationships___node__devices___relationships___field_payment_methods = 'relationships___node__devices___relationships___field_payment_methods',
  relationships___node__devices___relationships___node__fiscalization = 'relationships___node__devices___relationships___node__fiscalization',
  relationships___node__devices___field_tooltip_text_for_branches = 'relationships___node__devices___field_tooltip_text_for_branches',
  relationships___node__devices___field_pl_is_virtual = 'relationships___node__devices___field_pl_is_virtual',
  relationships___node__devices___field_is_available = 'relationships___node__devices___field_is_available',
  relationships___node__devices___field_lease_strike_through_price = 'relationships___node__devices___field_lease_strike_through_price',
  relationships___node__devices___field_lease_allowed = 'relationships___node__devices___field_lease_allowed',
  relationships___node__devices___field_pl_price_list_name = 'relationships___node__devices___field_pl_price_list_name',
  relationships___node__devices___field_additional_price_lists = 'relationships___node__devices___field_additional_price_lists',
  relationships___node__devices___field_popup_body___value = 'relationships___node__devices___field_popup_body___value',
  relationships___node__devices___field_popup_button_back = 'relationships___node__devices___field_popup_button_back',
  relationships___node__devices___field_popup_button_ok = 'relationships___node__devices___field_popup_button_ok',
  relationships___node__devices___field_popup_enabled = 'relationships___node__devices___field_popup_enabled',
  relationships___node__devices___field_popup_title = 'relationships___node__devices___field_popup_title',
  relationships___node__devices___drupal_internal__nid = 'relationships___node__devices___drupal_internal__nid',
  relationships___node__devices___status = 'relationships___node__devices___status',
  relationships___node__devices___content_translation_source = 'relationships___node__devices___content_translation_source',
  relationships___node__devices___content_translation_outdated = 'relationships___node__devices___content_translation_outdated',
  relationships___node__devices___uuid = 'relationships___node__devices___uuid',
  relationships___node__devices___field_payment_methods = 'relationships___node__devices___field_payment_methods',
  relationships___node__devices___field_payment_methods___drupal_internal__target_id = 'relationships___node__devices___field_payment_methods___drupal_internal__target_id',
  relationships___node__devices___field_pl_cases = 'relationships___node__devices___field_pl_cases',
  relationships___node__devices___field_pl_cases___drupal_internal__target_id = 'relationships___node__devices___field_pl_cases___drupal_internal__target_id',
  relationships___node__devices___field_pl_image___alt = 'relationships___node__devices___field_pl_image___alt',
  relationships___node__devices___field_pl_image___title = 'relationships___node__devices___field_pl_image___title',
  relationships___node__devices___field_pl_image___width = 'relationships___node__devices___field_pl_image___width',
  relationships___node__devices___field_pl_image___height = 'relationships___node__devices___field_pl_image___height',
  relationships___node__devices___field_pl_image___drupal_internal__target_id = 'relationships___node__devices___field_pl_image___drupal_internal__target_id',
  relationships___node__devices___field_pl_image_for_accessories___alt = 'relationships___node__devices___field_pl_image_for_accessories___alt',
  relationships___node__devices___field_pl_image_for_accessories___title = 'relationships___node__devices___field_pl_image_for_accessories___title',
  relationships___node__devices___field_pl_image_for_accessories___width = 'relationships___node__devices___field_pl_image_for_accessories___width',
  relationships___node__devices___field_pl_image_for_accessories___height = 'relationships___node__devices___field_pl_image_for_accessories___height',
  relationships___node__devices___field_pl_image_for_accessories___drupal_internal__target_id = 'relationships___node__devices___field_pl_image_for_accessories___drupal_internal__target_id',
  relationships___node__devices___field_recommended_for_branches = 'relationships___node__devices___field_recommended_for_branches',
  relationships___node__devices___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__devices___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__devices___id = 'relationships___node__devices___id',
  relationships___node__devices___parent___id = 'relationships___node__devices___parent___id',
  relationships___node__devices___parent___children = 'relationships___node__devices___parent___children',
  relationships___node__devices___children = 'relationships___node__devices___children',
  relationships___node__devices___children___id = 'relationships___node__devices___children___id',
  relationships___node__devices___children___children = 'relationships___node__devices___children___children',
  relationships___node__devices___internal___content = 'relationships___node__devices___internal___content',
  relationships___node__devices___internal___contentDigest = 'relationships___node__devices___internal___contentDigest',
  relationships___node__devices___internal___description = 'relationships___node__devices___internal___description',
  relationships___node__devices___internal___fieldOwners = 'relationships___node__devices___internal___fieldOwners',
  relationships___node__devices___internal___ignoreType = 'relationships___node__devices___internal___ignoreType',
  relationships___node__devices___internal___mediaType = 'relationships___node__devices___internal___mediaType',
  relationships___node__devices___internal___owner = 'relationships___node__devices___internal___owner',
  relationships___node__devices___internal___type = 'relationships___node__devices___internal___type',
  relationships___node__tariffs = 'relationships___node__tariffs',
  relationships___node__tariffs___langcode = 'relationships___node__tariffs___langcode',
  relationships___node__tariffs___drupal_id = 'relationships___node__tariffs___drupal_id',
  relationships___node__tariffs___title = 'relationships___node__tariffs___title',
  relationships___node__tariffs___field_pl_description_in_cms___value = 'relationships___node__tariffs___field_pl_description_in_cms___value',
  relationships___node__tariffs___field_pl_is_popular = 'relationships___node__tariffs___field_pl_is_popular',
  relationships___node__tariffs___field_weight = 'relationships___node__tariffs___field_weight',
  relationships___node__tariffs___relationships___field_profits = 'relationships___node__tariffs___relationships___field_profits',
  relationships___node__tariffs___relationships___field_recommended_for_branches = 'relationships___node__tariffs___relationships___field_recommended_for_branches',
  relationships___node__tariffs___price_list_type = 'relationships___node__tariffs___price_list_type',
  relationships___node__tariffs___drupal_internal__nid = 'relationships___node__tariffs___drupal_internal__nid',
  relationships___node__tariffs___status = 'relationships___node__tariffs___status',
  relationships___node__tariffs___content_translation_source = 'relationships___node__tariffs___content_translation_source',
  relationships___node__tariffs___content_translation_outdated = 'relationships___node__tariffs___content_translation_outdated',
  relationships___node__tariffs___uuid = 'relationships___node__tariffs___uuid',
  relationships___node__tariffs___field_profits = 'relationships___node__tariffs___field_profits',
  relationships___node__tariffs___field_profits___target_revision_id = 'relationships___node__tariffs___field_profits___target_revision_id',
  relationships___node__tariffs___field_profits___drupal_internal__target_id = 'relationships___node__tariffs___field_profits___drupal_internal__target_id',
  relationships___node__tariffs___field_recommended_for_branches = 'relationships___node__tariffs___field_recommended_for_branches',
  relationships___node__tariffs___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__tariffs___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__tariffs___id = 'relationships___node__tariffs___id',
  relationships___node__tariffs___parent___id = 'relationships___node__tariffs___parent___id',
  relationships___node__tariffs___parent___children = 'relationships___node__tariffs___parent___children',
  relationships___node__tariffs___children = 'relationships___node__tariffs___children',
  relationships___node__tariffs___children___id = 'relationships___node__tariffs___children___id',
  relationships___node__tariffs___children___children = 'relationships___node__tariffs___children___children',
  relationships___node__tariffs___internal___content = 'relationships___node__tariffs___internal___content',
  relationships___node__tariffs___internal___contentDigest = 'relationships___node__tariffs___internal___contentDigest',
  relationships___node__tariffs___internal___description = 'relationships___node__tariffs___internal___description',
  relationships___node__tariffs___internal___fieldOwners = 'relationships___node__tariffs___internal___fieldOwners',
  relationships___node__tariffs___internal___ignoreType = 'relationships___node__tariffs___internal___ignoreType',
  relationships___node__tariffs___internal___mediaType = 'relationships___node__tariffs___internal___mediaType',
  relationships___node__tariffs___internal___owner = 'relationships___node__tariffs___internal___owner',
  relationships___node__tariffs___internal___type = 'relationships___node__tariffs___internal___type',
  drupal_internal__nid = 'drupal_internal__nid',
  status = 'status',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  field_branches_icon___alt = 'field_branches_icon___alt',
  field_branches_icon___title = 'field_branches_icon___title',
  field_branches_icon___width = 'field_branches_icon___width',
  field_branches_icon___height = 'field_branches_icon___height',
  field_branches_icon___drupal_internal__target_id = 'field_branches_icon___drupal_internal__target_id',
  field_branches_image___alt = 'field_branches_image___alt',
  field_branches_image___title = 'field_branches_image___title',
  field_branches_image___width = 'field_branches_image___width',
  field_branches_image___height = 'field_branches_image___height',
  field_branches_image___drupal_internal__target_id = 'field_branches_image___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__BranchesFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pl_is_online?: Maybe<BooleanQueryOperatorInput>;
  field_branches_date?: Maybe<StringQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  path?: Maybe<PathFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__BranchesRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_icon?: Maybe<Node__BranchesField_Branches_IconFilterInput>;
  field_branches_image?: Maybe<Node__BranchesField_Branches_ImageFilterInput>;
  field_components?: Maybe<Node__BranchesField_ComponentsFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__BranchesFilterListInput = {
  elemMatch?: Maybe<Node__BranchesFilterInput>;
};

export type Node__BranchesGroupConnection = {
  __typename?: 'node__branchesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__BranchesEdge>;
  nodes: Array<Node__Branches>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__BranchesRelationships = {
  __typename?: 'node__branchesRelationships';
  field_branches_icon?: Maybe<File__File>;
  field_branches_image?: Maybe<File__File>;
  image_large?: Maybe<File__File>;
  field_components?: Maybe<Array<Maybe<BranchRelatedComponents>>>;
  node__accessories?: Maybe<Array<Maybe<Node__Accessories>>>;
  node__cases?: Maybe<Array<Maybe<Node__Cases>>>;
  node__devices?: Maybe<Array<Maybe<Node__Devices>>>;
  node__tariffs?: Maybe<Array<Maybe<Node__Tariffs>>>;
};

export type Node__BranchesRelationshipsFilterInput = {
  field_branches_icon?: Maybe<File__FileFilterInput>;
  field_branches_image?: Maybe<File__FileFilterInput>;
  image_large?: Maybe<File__FileFilterInput>;
  node__accessories?: Maybe<Node__AccessoriesFilterListInput>;
  node__cases?: Maybe<Node__CasesFilterListInput>;
  node__devices?: Maybe<Node__DevicesFilterListInput>;
  node__tariffs?: Maybe<Node__TariffsFilterListInput>;
};

export type Node__BranchesSortInput = {
  fields?: Maybe<Array<Maybe<Node__BranchesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Cases = Node & {
  __typename?: 'node__cases';
  drupal_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  field_pl_color?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__CasesRelationships>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_pl_image?: Maybe<Node__CasesField_Pl_Image>;
  field_recommended_for_branches?: Maybe<Array<Maybe<Node__CasesField_Recommended_For_Branches>>>;
  field_date_from_api?: Maybe<Node__CasesField_Date_From_Api>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__CasesConnection = {
  __typename?: 'node__casesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__CasesEdge>;
  nodes: Array<Node__Cases>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__CasesGroupConnection>;
};

export type Node__CasesConnectionDistinctArgs = {
  field: Node__CasesFieldsEnum;
};

export type Node__CasesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__CasesFieldsEnum;
};

export type Node__CasesEdge = {
  __typename?: 'node__casesEdge';
  next?: Maybe<Node__Cases>;
  node: Node__Cases;
  previous?: Maybe<Node__Cases>;
};

export type Node__CasesField_Date_From_Api = {
  __typename?: 'node__casesField_date_from_api';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__CasesField_Date_From_ApiFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__CasesField_Pl_Image = {
  __typename?: 'node__casesField_pl_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__CasesField_Pl_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__CasesField_Recommended_For_Branches = {
  __typename?: 'node__casesField_recommended_for_branches';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__CasesField_Recommended_For_BranchesFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__CasesField_Recommended_For_BranchesFilterListInput = {
  elemMatch?: Maybe<Node__CasesField_Recommended_For_BranchesFilterInput>;
};

export enum Node__CasesFieldsEnum {
  drupal_id = 'drupal_id',
  title = 'title',
  field_pl_color = 'field_pl_color',
  relationships___field_pl_image___localFile___birthtime = 'relationships___field_pl_image___localFile___birthtime',
  relationships___field_pl_image___localFile___birthtimeMs = 'relationships___field_pl_image___localFile___birthtimeMs',
  relationships___field_pl_image___localFile___publicURL = 'relationships___field_pl_image___localFile___publicURL',
  relationships___field_pl_image___localFile___sourceInstanceName = 'relationships___field_pl_image___localFile___sourceInstanceName',
  relationships___field_pl_image___localFile___absolutePath = 'relationships___field_pl_image___localFile___absolutePath',
  relationships___field_pl_image___localFile___relativePath = 'relationships___field_pl_image___localFile___relativePath',
  relationships___field_pl_image___localFile___extension = 'relationships___field_pl_image___localFile___extension',
  relationships___field_pl_image___localFile___size = 'relationships___field_pl_image___localFile___size',
  relationships___field_pl_image___localFile___prettySize = 'relationships___field_pl_image___localFile___prettySize',
  relationships___field_pl_image___localFile___modifiedTime = 'relationships___field_pl_image___localFile___modifiedTime',
  relationships___field_pl_image___localFile___accessTime = 'relationships___field_pl_image___localFile___accessTime',
  relationships___field_pl_image___localFile___changeTime = 'relationships___field_pl_image___localFile___changeTime',
  relationships___field_pl_image___localFile___birthTime = 'relationships___field_pl_image___localFile___birthTime',
  relationships___field_pl_image___localFile___root = 'relationships___field_pl_image___localFile___root',
  relationships___field_pl_image___localFile___dir = 'relationships___field_pl_image___localFile___dir',
  relationships___field_pl_image___localFile___base = 'relationships___field_pl_image___localFile___base',
  relationships___field_pl_image___localFile___ext = 'relationships___field_pl_image___localFile___ext',
  relationships___field_pl_image___localFile___name = 'relationships___field_pl_image___localFile___name',
  relationships___field_pl_image___localFile___relativeDirectory = 'relationships___field_pl_image___localFile___relativeDirectory',
  relationships___field_pl_image___localFile___dev = 'relationships___field_pl_image___localFile___dev',
  relationships___field_pl_image___localFile___mode = 'relationships___field_pl_image___localFile___mode',
  relationships___field_pl_image___localFile___nlink = 'relationships___field_pl_image___localFile___nlink',
  relationships___field_pl_image___localFile___uid = 'relationships___field_pl_image___localFile___uid',
  relationships___field_pl_image___localFile___gid = 'relationships___field_pl_image___localFile___gid',
  relationships___field_pl_image___localFile___rdev = 'relationships___field_pl_image___localFile___rdev',
  relationships___field_pl_image___localFile___blksize = 'relationships___field_pl_image___localFile___blksize',
  relationships___field_pl_image___localFile___ino = 'relationships___field_pl_image___localFile___ino',
  relationships___field_pl_image___localFile___blocks = 'relationships___field_pl_image___localFile___blocks',
  relationships___field_pl_image___localFile___atimeMs = 'relationships___field_pl_image___localFile___atimeMs',
  relationships___field_pl_image___localFile___mtimeMs = 'relationships___field_pl_image___localFile___mtimeMs',
  relationships___field_pl_image___localFile___ctimeMs = 'relationships___field_pl_image___localFile___ctimeMs',
  relationships___field_pl_image___localFile___atime = 'relationships___field_pl_image___localFile___atime',
  relationships___field_pl_image___localFile___mtime = 'relationships___field_pl_image___localFile___mtime',
  relationships___field_pl_image___localFile___ctime = 'relationships___field_pl_image___localFile___ctime',
  relationships___field_pl_image___localFile___url = 'relationships___field_pl_image___localFile___url',
  relationships___field_pl_image___localFile___id = 'relationships___field_pl_image___localFile___id',
  relationships___field_pl_image___localFile___children = 'relationships___field_pl_image___localFile___children',
  relationships___field_pl_image___uri___url = 'relationships___field_pl_image___uri___url',
  relationships___field_pl_image___drupal_id = 'relationships___field_pl_image___drupal_id',
  relationships___field_pl_image___drupal_internal__fid = 'relationships___field_pl_image___drupal_internal__fid',
  relationships___field_pl_image___langcode = 'relationships___field_pl_image___langcode',
  relationships___field_pl_image___filename = 'relationships___field_pl_image___filename',
  relationships___field_pl_image___filemime = 'relationships___field_pl_image___filemime',
  relationships___field_pl_image___filesize = 'relationships___field_pl_image___filesize',
  relationships___field_pl_image___status = 'relationships___field_pl_image___status',
  relationships___field_pl_image___created = 'relationships___field_pl_image___created',
  relationships___field_pl_image___changed = 'relationships___field_pl_image___changed',
  relationships___field_pl_image___uuid = 'relationships___field_pl_image___uuid',
  relationships___field_pl_image___relationships___node__blog_post = 'relationships___field_pl_image___relationships___node__blog_post',
  relationships___field_pl_image___relationships___node__devices = 'relationships___field_pl_image___relationships___node__devices',
  relationships___field_pl_image___relationships___node__accessories = 'relationships___field_pl_image___relationships___node__accessories',
  relationships___field_pl_image___relationships___paragraph__news_image_title_text = 'relationships___field_pl_image___relationships___paragraph__news_image_title_text',
  relationships___field_pl_image___relationships___node__cases = 'relationships___field_pl_image___relationships___node__cases',
  relationships___field_pl_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_pl_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_pl_image___relationships___paragraph__branches_features = 'relationships___field_pl_image___relationships___paragraph__branches_features',
  relationships___field_pl_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_pl_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_pl_image___relationships___paragraph__branches_testimonials = 'relationships___field_pl_image___relationships___paragraph__branches_testimonials',
  relationships___field_pl_image___relationships___paragraph__branches_discounts = 'relationships___field_pl_image___relationships___paragraph__branches_discounts',
  relationships___field_pl_image___relationships___node__news = 'relationships___field_pl_image___relationships___node__news',
  relationships___field_pl_image___relationships___node__branches = 'relationships___field_pl_image___relationships___node__branches',
  relationships___field_pl_image___relationships___paragraph__branches_hero = 'relationships___field_pl_image___relationships___paragraph__branches_hero',
  relationships___field_pl_image___relationships___paragraph__bullets = 'relationships___field_pl_image___relationships___paragraph__bullets',
  relationships___field_pl_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_pl_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_pl_image___relationships___paragraph__branches_device = 'relationships___field_pl_image___relationships___paragraph__branches_device',
  relationships___field_pl_image___relationships___paragraph__branches_recommendation = 'relationships___field_pl_image___relationships___paragraph__branches_recommendation',
  relationships___field_pl_image___relationships___site_setting_entity__accessories = 'relationships___field_pl_image___relationships___site_setting_entity__accessories',
  relationships___field_pl_image___relationships___site_setting_entity__branches = 'relationships___field_pl_image___relationships___site_setting_entity__branches',
  relationships___field_pl_image___relationships___site_setting_entity__about_us = 'relationships___field_pl_image___relationships___site_setting_entity__about_us',
  relationships___field_pl_image___relationships___paragraph__logos = 'relationships___field_pl_image___relationships___paragraph__logos',
  relationships___field_pl_image___relationships___paragraph__how_it_works = 'relationships___field_pl_image___relationships___paragraph__how_it_works',
  relationships___field_pl_image___relationships___paragraph__ico_and_text = 'relationships___field_pl_image___relationships___paragraph__ico_and_text',
  relationships___field_pl_image___relationships___paragraph__hero_1 = 'relationships___field_pl_image___relationships___paragraph__hero_1',
  relationships___field_pl_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_pl_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_pl_image___relationships___site_setting_entity__product = 'relationships___field_pl_image___relationships___site_setting_entity__product',
  relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_pl_image___relationships___paragraph__porownanie_terminali = 'relationships___field_pl_image___relationships___paragraph__porownanie_terminali',
  relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_pl_image___relationships___site_setting_entity__main_page = 'relationships___field_pl_image___relationships___site_setting_entity__main_page',
  relationships___field_pl_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_pl_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_pl_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_pl_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_pl_image___relationships___site_setting_entity__intercars = 'relationships___field_pl_image___relationships___site_setting_entity__intercars',
  relationships___field_pl_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_pl_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_pl_image___relationships___node__agreement = 'relationships___field_pl_image___relationships___node__agreement',
  relationships___field_pl_image___relationships___node__fiscalization = 'relationships___field_pl_image___relationships___node__fiscalization',
  relationships___field_pl_image___relationships___site_setting_entity__configurator = 'relationships___field_pl_image___relationships___site_setting_entity__configurator',
  relationships___field_pl_image___relationships___paragraph__social_link = 'relationships___field_pl_image___relationships___paragraph__social_link',
  relationships___field_pl_image___relationships___paragraph__make_an_appointment = 'relationships___field_pl_image___relationships___paragraph__make_an_appointment',
  relationships___field_pl_image___relationships___paragraph__linked_icons = 'relationships___field_pl_image___relationships___paragraph__linked_icons',
  relationships___field_pl_image___relationships___site_setting_entity__financing = 'relationships___field_pl_image___relationships___site_setting_entity__financing',
  relationships___field_pl_image___relationships___site_setting_entity__franchise = 'relationships___field_pl_image___relationships___site_setting_entity__franchise',
  relationships___field_pl_image___id = 'relationships___field_pl_image___id',
  relationships___field_pl_image___parent___id = 'relationships___field_pl_image___parent___id',
  relationships___field_pl_image___parent___children = 'relationships___field_pl_image___parent___children',
  relationships___field_pl_image___children = 'relationships___field_pl_image___children',
  relationships___field_pl_image___children___id = 'relationships___field_pl_image___children___id',
  relationships___field_pl_image___children___children = 'relationships___field_pl_image___children___children',
  relationships___field_pl_image___internal___content = 'relationships___field_pl_image___internal___content',
  relationships___field_pl_image___internal___contentDigest = 'relationships___field_pl_image___internal___contentDigest',
  relationships___field_pl_image___internal___description = 'relationships___field_pl_image___internal___description',
  relationships___field_pl_image___internal___fieldOwners = 'relationships___field_pl_image___internal___fieldOwners',
  relationships___field_pl_image___internal___ignoreType = 'relationships___field_pl_image___internal___ignoreType',
  relationships___field_pl_image___internal___mediaType = 'relationships___field_pl_image___internal___mediaType',
  relationships___field_pl_image___internal___owner = 'relationships___field_pl_image___internal___owner',
  relationships___field_pl_image___internal___type = 'relationships___field_pl_image___internal___type',
  relationships___field_date_from_api___drupal_id = 'relationships___field_date_from_api___drupal_id',
  relationships___field_date_from_api___fixedprice = 'relationships___field_date_from_api___fixedprice',
  relationships___field_date_from_api___iposfee = 'relationships___field_date_from_api___iposfee',
  relationships___field_date_from_api___langcode = 'relationships___field_date_from_api___langcode',
  relationships___field_date_from_api___status = 'relationships___field_date_from_api___status',
  relationships___field_date_from_api___field_pl_locationfee = 'relationships___field_date_from_api___field_pl_locationfee',
  relationships___field_date_from_api___field_pl_name = 'relationships___field_date_from_api___field_pl_name',
  relationships___field_date_from_api___field_pl_optionname = 'relationships___field_date_from_api___field_pl_optionname',
  relationships___field_date_from_api___field_pl_lease = 'relationships___field_date_from_api___field_pl_lease',
  relationships___field_date_from_api___field_pl_lease_name = 'relationships___field_date_from_api___field_pl_lease_name',
  relationships___field_date_from_api___field_pl_payment_type = 'relationships___field_date_from_api___field_pl_payment_type',
  relationships___field_date_from_api___field_pl_activation_price = 'relationships___field_date_from_api___field_pl_activation_price',
  relationships___field_date_from_api___field_pl_contract_duration = 'relationships___field_date_from_api___field_pl_contract_duration',
  relationships___field_date_from_api___field_pl_group_name_in_api = 'relationships___field_date_from_api___field_pl_group_name_in_api',
  relationships___field_date_from_api___field_pl_reduced_fee_duration = 'relationships___field_date_from_api___field_pl_reduced_fee_duration',
  relationships___field_date_from_api___field_pl_reduced_fee = 'relationships___field_date_from_api___field_pl_reduced_fee',
  relationships___field_date_from_api___drupal_internal__nid = 'relationships___field_date_from_api___drupal_internal__nid',
  relationships___field_date_from_api___content_translation_source = 'relationships___field_date_from_api___content_translation_source',
  relationships___field_date_from_api___content_translation_outdated = 'relationships___field_date_from_api___content_translation_outdated',
  relationships___field_date_from_api___field_pl_first_ipos_fee = 'relationships___field_date_from_api___field_pl_first_ipos_fee',
  relationships___field_date_from_api___vatrate = 'relationships___field_date_from_api___vatrate',
  relationships___field_date_from_api___uuid = 'relationships___field_date_from_api___uuid',
  relationships___field_date_from_api___relationships___node__accessories = 'relationships___field_date_from_api___relationships___node__accessories',
  relationships___field_date_from_api___relationships___node__cases = 'relationships___field_date_from_api___relationships___node__cases',
  relationships___field_date_from_api___relationships___paragraph__branches_tariff = 'relationships___field_date_from_api___relationships___paragraph__branches_tariff',
  relationships___field_date_from_api___pricelistgroup___drupal_internal__target_id = 'relationships___field_date_from_api___pricelistgroup___drupal_internal__target_id',
  relationships___field_date_from_api___id = 'relationships___field_date_from_api___id',
  relationships___field_date_from_api___parent___id = 'relationships___field_date_from_api___parent___id',
  relationships___field_date_from_api___parent___children = 'relationships___field_date_from_api___parent___children',
  relationships___field_date_from_api___children = 'relationships___field_date_from_api___children',
  relationships___field_date_from_api___children___id = 'relationships___field_date_from_api___children___id',
  relationships___field_date_from_api___children___children = 'relationships___field_date_from_api___children___children',
  relationships___field_date_from_api___internal___content = 'relationships___field_date_from_api___internal___content',
  relationships___field_date_from_api___internal___contentDigest = 'relationships___field_date_from_api___internal___contentDigest',
  relationships___field_date_from_api___internal___description = 'relationships___field_date_from_api___internal___description',
  relationships___field_date_from_api___internal___fieldOwners = 'relationships___field_date_from_api___internal___fieldOwners',
  relationships___field_date_from_api___internal___ignoreType = 'relationships___field_date_from_api___internal___ignoreType',
  relationships___field_date_from_api___internal___mediaType = 'relationships___field_date_from_api___internal___mediaType',
  relationships___field_date_from_api___internal___owner = 'relationships___field_date_from_api___internal___owner',
  relationships___field_date_from_api___internal___type = 'relationships___field_date_from_api___internal___type',
  relationships___field_recommended_for_branches = 'relationships___field_recommended_for_branches',
  relationships___field_recommended_for_branches___langcode = 'relationships___field_recommended_for_branches___langcode',
  relationships___field_recommended_for_branches___id = 'relationships___field_recommended_for_branches___id',
  relationships___field_recommended_for_branches___drupal_id = 'relationships___field_recommended_for_branches___drupal_id',
  relationships___field_recommended_for_branches___title = 'relationships___field_recommended_for_branches___title',
  relationships___field_recommended_for_branches___field_pl_is_online = 'relationships___field_recommended_for_branches___field_pl_is_online',
  relationships___field_recommended_for_branches___field_branches_date = 'relationships___field_recommended_for_branches___field_branches_date',
  relationships___field_recommended_for_branches___field_weight = 'relationships___field_recommended_for_branches___field_weight',
  relationships___field_recommended_for_branches___path___alias = 'relationships___field_recommended_for_branches___path___alias',
  relationships___field_recommended_for_branches___field_meta_description = 'relationships___field_recommended_for_branches___field_meta_description',
  relationships___field_recommended_for_branches___field_meta_title = 'relationships___field_recommended_for_branches___field_meta_title',
  relationships___field_recommended_for_branches___relationships___node__accessories = 'relationships___field_recommended_for_branches___relationships___node__accessories',
  relationships___field_recommended_for_branches___relationships___node__cases = 'relationships___field_recommended_for_branches___relationships___node__cases',
  relationships___field_recommended_for_branches___relationships___node__devices = 'relationships___field_recommended_for_branches___relationships___node__devices',
  relationships___field_recommended_for_branches___relationships___node__tariffs = 'relationships___field_recommended_for_branches___relationships___node__tariffs',
  relationships___field_recommended_for_branches___drupal_internal__nid = 'relationships___field_recommended_for_branches___drupal_internal__nid',
  relationships___field_recommended_for_branches___status = 'relationships___field_recommended_for_branches___status',
  relationships___field_recommended_for_branches___content_translation_source = 'relationships___field_recommended_for_branches___content_translation_source',
  relationships___field_recommended_for_branches___content_translation_outdated = 'relationships___field_recommended_for_branches___content_translation_outdated',
  relationships___field_recommended_for_branches___uuid = 'relationships___field_recommended_for_branches___uuid',
  relationships___field_recommended_for_branches___field_branches_icon___alt = 'relationships___field_recommended_for_branches___field_branches_icon___alt',
  relationships___field_recommended_for_branches___field_branches_icon___title = 'relationships___field_recommended_for_branches___field_branches_icon___title',
  relationships___field_recommended_for_branches___field_branches_icon___width = 'relationships___field_recommended_for_branches___field_branches_icon___width',
  relationships___field_recommended_for_branches___field_branches_icon___height = 'relationships___field_recommended_for_branches___field_branches_icon___height',
  relationships___field_recommended_for_branches___field_branches_icon___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_branches_icon___drupal_internal__target_id',
  relationships___field_recommended_for_branches___field_branches_image___alt = 'relationships___field_recommended_for_branches___field_branches_image___alt',
  relationships___field_recommended_for_branches___field_branches_image___title = 'relationships___field_recommended_for_branches___field_branches_image___title',
  relationships___field_recommended_for_branches___field_branches_image___width = 'relationships___field_recommended_for_branches___field_branches_image___width',
  relationships___field_recommended_for_branches___field_branches_image___height = 'relationships___field_recommended_for_branches___field_branches_image___height',
  relationships___field_recommended_for_branches___field_branches_image___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_branches_image___drupal_internal__target_id',
  relationships___field_recommended_for_branches___field_components = 'relationships___field_recommended_for_branches___field_components',
  relationships___field_recommended_for_branches___field_components___target_revision_id = 'relationships___field_recommended_for_branches___field_components___target_revision_id',
  relationships___field_recommended_for_branches___field_components___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_components___drupal_internal__target_id',
  relationships___field_recommended_for_branches___parent___id = 'relationships___field_recommended_for_branches___parent___id',
  relationships___field_recommended_for_branches___parent___children = 'relationships___field_recommended_for_branches___parent___children',
  relationships___field_recommended_for_branches___children = 'relationships___field_recommended_for_branches___children',
  relationships___field_recommended_for_branches___children___id = 'relationships___field_recommended_for_branches___children___id',
  relationships___field_recommended_for_branches___children___children = 'relationships___field_recommended_for_branches___children___children',
  relationships___field_recommended_for_branches___internal___content = 'relationships___field_recommended_for_branches___internal___content',
  relationships___field_recommended_for_branches___internal___contentDigest = 'relationships___field_recommended_for_branches___internal___contentDigest',
  relationships___field_recommended_for_branches___internal___description = 'relationships___field_recommended_for_branches___internal___description',
  relationships___field_recommended_for_branches___internal___fieldOwners = 'relationships___field_recommended_for_branches___internal___fieldOwners',
  relationships___field_recommended_for_branches___internal___ignoreType = 'relationships___field_recommended_for_branches___internal___ignoreType',
  relationships___field_recommended_for_branches___internal___mediaType = 'relationships___field_recommended_for_branches___internal___mediaType',
  relationships___field_recommended_for_branches___internal___owner = 'relationships___field_recommended_for_branches___internal___owner',
  relationships___field_recommended_for_branches___internal___type = 'relationships___field_recommended_for_branches___internal___type',
  relationships___node__devices = 'relationships___node__devices',
  relationships___node__devices___langcode = 'relationships___node__devices___langcode',
  relationships___node__devices___drupal_id = 'relationships___node__devices___drupal_id',
  relationships___node__devices___title = 'relationships___node__devices___title',
  relationships___node__devices___field_subtitle = 'relationships___node__devices___field_subtitle',
  relationships___node__devices___field_pl_description_in_cms___value = 'relationships___node__devices___field_pl_description_in_cms___value',
  relationships___node__devices___field_pl_is_online = 'relationships___node__devices___field_pl_is_online',
  relationships___node__devices___field_weight = 'relationships___node__devices___field_weight',
  relationships___node__devices___field_pl_strikethrough_price = 'relationships___node__devices___field_pl_strikethrough_price',
  relationships___node__devices___relationships___field_recommended_for_branches = 'relationships___node__devices___relationships___field_recommended_for_branches',
  relationships___node__devices___relationships___field_accessories = 'relationships___node__devices___relationships___field_accessories',
  relationships___node__devices___relationships___field_pl_cases = 'relationships___node__devices___relationships___field_pl_cases',
  relationships___node__devices___relationships___field_payment_methods = 'relationships___node__devices___relationships___field_payment_methods',
  relationships___node__devices___relationships___node__fiscalization = 'relationships___node__devices___relationships___node__fiscalization',
  relationships___node__devices___field_tooltip_text_for_branches = 'relationships___node__devices___field_tooltip_text_for_branches',
  relationships___node__devices___field_pl_is_virtual = 'relationships___node__devices___field_pl_is_virtual',
  relationships___node__devices___field_is_available = 'relationships___node__devices___field_is_available',
  relationships___node__devices___field_lease_strike_through_price = 'relationships___node__devices___field_lease_strike_through_price',
  relationships___node__devices___field_lease_allowed = 'relationships___node__devices___field_lease_allowed',
  relationships___node__devices___field_pl_price_list_name = 'relationships___node__devices___field_pl_price_list_name',
  relationships___node__devices___field_additional_price_lists = 'relationships___node__devices___field_additional_price_lists',
  relationships___node__devices___field_popup_body___value = 'relationships___node__devices___field_popup_body___value',
  relationships___node__devices___field_popup_button_back = 'relationships___node__devices___field_popup_button_back',
  relationships___node__devices___field_popup_button_ok = 'relationships___node__devices___field_popup_button_ok',
  relationships___node__devices___field_popup_enabled = 'relationships___node__devices___field_popup_enabled',
  relationships___node__devices___field_popup_title = 'relationships___node__devices___field_popup_title',
  relationships___node__devices___drupal_internal__nid = 'relationships___node__devices___drupal_internal__nid',
  relationships___node__devices___status = 'relationships___node__devices___status',
  relationships___node__devices___content_translation_source = 'relationships___node__devices___content_translation_source',
  relationships___node__devices___content_translation_outdated = 'relationships___node__devices___content_translation_outdated',
  relationships___node__devices___uuid = 'relationships___node__devices___uuid',
  relationships___node__devices___field_payment_methods = 'relationships___node__devices___field_payment_methods',
  relationships___node__devices___field_payment_methods___drupal_internal__target_id = 'relationships___node__devices___field_payment_methods___drupal_internal__target_id',
  relationships___node__devices___field_pl_cases = 'relationships___node__devices___field_pl_cases',
  relationships___node__devices___field_pl_cases___drupal_internal__target_id = 'relationships___node__devices___field_pl_cases___drupal_internal__target_id',
  relationships___node__devices___field_pl_image___alt = 'relationships___node__devices___field_pl_image___alt',
  relationships___node__devices___field_pl_image___title = 'relationships___node__devices___field_pl_image___title',
  relationships___node__devices___field_pl_image___width = 'relationships___node__devices___field_pl_image___width',
  relationships___node__devices___field_pl_image___height = 'relationships___node__devices___field_pl_image___height',
  relationships___node__devices___field_pl_image___drupal_internal__target_id = 'relationships___node__devices___field_pl_image___drupal_internal__target_id',
  relationships___node__devices___field_pl_image_for_accessories___alt = 'relationships___node__devices___field_pl_image_for_accessories___alt',
  relationships___node__devices___field_pl_image_for_accessories___title = 'relationships___node__devices___field_pl_image_for_accessories___title',
  relationships___node__devices___field_pl_image_for_accessories___width = 'relationships___node__devices___field_pl_image_for_accessories___width',
  relationships___node__devices___field_pl_image_for_accessories___height = 'relationships___node__devices___field_pl_image_for_accessories___height',
  relationships___node__devices___field_pl_image_for_accessories___drupal_internal__target_id = 'relationships___node__devices___field_pl_image_for_accessories___drupal_internal__target_id',
  relationships___node__devices___field_recommended_for_branches = 'relationships___node__devices___field_recommended_for_branches',
  relationships___node__devices___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__devices___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__devices___id = 'relationships___node__devices___id',
  relationships___node__devices___parent___id = 'relationships___node__devices___parent___id',
  relationships___node__devices___parent___children = 'relationships___node__devices___parent___children',
  relationships___node__devices___children = 'relationships___node__devices___children',
  relationships___node__devices___children___id = 'relationships___node__devices___children___id',
  relationships___node__devices___children___children = 'relationships___node__devices___children___children',
  relationships___node__devices___internal___content = 'relationships___node__devices___internal___content',
  relationships___node__devices___internal___contentDigest = 'relationships___node__devices___internal___contentDigest',
  relationships___node__devices___internal___description = 'relationships___node__devices___internal___description',
  relationships___node__devices___internal___fieldOwners = 'relationships___node__devices___internal___fieldOwners',
  relationships___node__devices___internal___ignoreType = 'relationships___node__devices___internal___ignoreType',
  relationships___node__devices___internal___mediaType = 'relationships___node__devices___internal___mediaType',
  relationships___node__devices___internal___owner = 'relationships___node__devices___internal___owner',
  relationships___node__devices___internal___type = 'relationships___node__devices___internal___type',
  drupal_internal__nid = 'drupal_internal__nid',
  status = 'status',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  langcode = 'langcode',
  uuid = 'uuid',
  field_pl_image___alt = 'field_pl_image___alt',
  field_pl_image___title = 'field_pl_image___title',
  field_pl_image___width = 'field_pl_image___width',
  field_pl_image___height = 'field_pl_image___height',
  field_pl_image___drupal_internal__target_id = 'field_pl_image___drupal_internal__target_id',
  field_recommended_for_branches = 'field_recommended_for_branches',
  field_recommended_for_branches___drupal_internal__target_id = 'field_recommended_for_branches___drupal_internal__target_id',
  field_date_from_api___drupal_internal__target_id = 'field_date_from_api___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__CasesFilterInput = {
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pl_color?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__CasesRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_pl_image?: Maybe<Node__CasesField_Pl_ImageFilterInput>;
  field_recommended_for_branches?: Maybe<Node__CasesField_Recommended_For_BranchesFilterListInput>;
  field_date_from_api?: Maybe<Node__CasesField_Date_From_ApiFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__CasesFilterListInput = {
  elemMatch?: Maybe<Node__CasesFilterInput>;
};

export type Node__CasesGroupConnection = {
  __typename?: 'node__casesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__CasesEdge>;
  nodes: Array<Node__Cases>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__CasesRelationships = {
  __typename?: 'node__casesRelationships';
  field_pl_image?: Maybe<File__File>;
  field_date_from_api?: Maybe<Node__Price_List>;
  field_recommended_for_branches?: Maybe<Array<Maybe<Node__Branches>>>;
  node__devices?: Maybe<Array<Maybe<Node__Devices>>>;
};

export type Node__CasesRelationshipsFilterInput = {
  field_pl_image?: Maybe<File__FileFilterInput>;
  field_date_from_api?: Maybe<Node__Price_ListFilterInput>;
  field_recommended_for_branches?: Maybe<Node__BranchesFilterListInput>;
  node__devices?: Maybe<Node__DevicesFilterListInput>;
};

export type Node__CasesSortInput = {
  fields?: Maybe<Array<Maybe<Node__CasesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Devices = Node & {
  __typename?: 'node__devices';
  langcode?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  field_subtitle?: Maybe<Scalars['String']>;
  field_pl_description_in_cms?: Maybe<Field_Pl_Description_In_Cms>;
  field_pl_is_online?: Maybe<Scalars['Boolean']>;
  field_weight?: Maybe<Scalars['Int']>;
  field_pl_strikethrough_price?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__DevicesRelationships>;
  field_tooltip_text_for_branches?: Maybe<Scalars['String']>;
  field_pl_is_virtual?: Maybe<Scalars['Boolean']>;
  field_is_available?: Maybe<Scalars['Boolean']>;
  field_lease_strike_through_price?: Maybe<Scalars['String']>;
  field_lease_allowed?: Maybe<Scalars['Boolean']>;
  field_pl_price_list_name?: Maybe<Scalars['String']>;
  field_additional_price_lists?: Maybe<Array<Maybe<Scalars['String']>>>;
  field_popup_body?: Maybe<Field_Popup_Body>;
  field_popup_button_back?: Maybe<Scalars['String']>;
  field_popup_button_ok?: Maybe<Scalars['String']>;
  field_popup_enabled?: Maybe<Scalars['Boolean']>;
  field_popup_title?: Maybe<Scalars['String']>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  field_payment_methods?: Maybe<Array<Maybe<Node__DevicesField_Payment_Methods>>>;
  field_pl_cases?: Maybe<Array<Maybe<Node__DevicesField_Pl_Cases>>>;
  field_pl_image?: Maybe<Node__DevicesField_Pl_Image>;
  field_pl_image_for_accessories?: Maybe<Node__DevicesField_Pl_Image_For_Accessories>;
  field_recommended_for_branches?: Maybe<Array<Maybe<Node__DevicesField_Recommended_For_Branches>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  field_category?: Maybe<Node__DevicesField_Category>;
};

export type Node__DevicesConnection = {
  __typename?: 'node__devicesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__DevicesEdge>;
  nodes: Array<Node__Devices>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__DevicesGroupConnection>;
};

export type Node__DevicesConnectionDistinctArgs = {
  field: Node__DevicesFieldsEnum;
};

export type Node__DevicesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__DevicesFieldsEnum;
};

export type Node__DevicesEdge = {
  __typename?: 'node__devicesEdge';
  next?: Maybe<Node__Devices>;
  node: Node__Devices;
  previous?: Maybe<Node__Devices>;
};

export type Node__DevicesField_Payment_Methods = {
  __typename?: 'node__devicesField_payment_methods';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__DevicesField_Category = {
  __typename?: 'node__devicesField_category';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__DevicesField_Payment_MethodsFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__DevicesField_Payment_MethodsFilterListInput = {
  elemMatch?: Maybe<Node__DevicesField_Payment_MethodsFilterInput>;
};

export type Node__DevicesField_Pl_Cases = {
  __typename?: 'node__devicesField_pl_cases';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__DevicesField_Pl_CasesFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__DevicesField_Pl_CasesFilterListInput = {
  elemMatch?: Maybe<Node__DevicesField_Pl_CasesFilterInput>;
};

export type Node__DevicesField_Pl_Image = {
  __typename?: 'node__devicesField_pl_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__DevicesField_Pl_Image_For_Accessories = {
  __typename?: 'node__devicesField_pl_image_for_accessories';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__DevicesField_Pl_Image_For_AccessoriesFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__DevicesField_Pl_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__DevicesField_Recommended_For_Branches = {
  __typename?: 'node__devicesField_recommended_for_branches';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__DevicesField_Recommended_For_BranchesFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__DevicesField_Recommended_For_BranchesFilterListInput = {
  elemMatch?: Maybe<Node__DevicesField_Recommended_For_BranchesFilterInput>;
};

export enum Node__DevicesFieldsEnum {
  langcode = 'langcode',
  drupal_id = 'drupal_id',
  title = 'title',
  field_subtitle = 'field_subtitle',
  field_pl_description_in_cms___value = 'field_pl_description_in_cms___value',
  field_pl_is_online = 'field_pl_is_online',
  field_weight = 'field_weight',
  field_pl_strikethrough_price = 'field_pl_strikethrough_price',
  relationships___field_date_from_api___drupal_id = 'relationships___field_date_from_api___drupal_id',
  relationships___field_date_from_api___fixedprice = 'relationships___field_date_from_api___fixedprice',
  relationships___field_date_from_api___iposfee = 'relationships___field_date_from_api___iposfee',
  relationships___field_date_from_api___langcode = 'relationships___field_date_from_api___langcode',
  relationships___field_date_from_api___status = 'relationships___field_date_from_api___status',
  relationships___field_date_from_api___field_pl_locationfee = 'relationships___field_date_from_api___field_pl_locationfee',
  relationships___field_date_from_api___field_pl_name = 'relationships___field_date_from_api___field_pl_name',
  relationships___field_date_from_api___field_pl_optionname = 'relationships___field_date_from_api___field_pl_optionname',
  relationships___field_date_from_api___field_pl_lease = 'relationships___field_date_from_api___field_pl_lease',
  relationships___field_date_from_api___field_pl_lease_name = 'relationships___field_date_from_api___field_pl_lease_name',
  relationships___field_date_from_api___field_pl_payment_type = 'relationships___field_date_from_api___field_pl_payment_type',
  relationships___field_date_from_api___field_pl_activation_price = 'relationships___field_date_from_api___field_pl_activation_price',
  relationships___field_date_from_api___field_pl_contract_duration = 'relationships___field_date_from_api___field_pl_contract_duration',
  relationships___field_date_from_api___field_pl_group_name_in_api = 'relationships___field_date_from_api___field_pl_group_name_in_api',
  relationships___field_date_from_api___field_pl_reduced_fee_duration = 'relationships___field_date_from_api___field_pl_reduced_fee_duration',
  relationships___field_date_from_api___field_pl_reduced_fee = 'relationships___field_date_from_api___field_pl_reduced_fee',
  relationships___field_date_from_api___drupal_internal__nid = 'relationships___field_date_from_api___drupal_internal__nid',
  relationships___field_date_from_api___content_translation_source = 'relationships___field_date_from_api___content_translation_source',
  relationships___field_date_from_api___content_translation_outdated = 'relationships___field_date_from_api___content_translation_outdated',
  relationships___field_date_from_api___field_pl_first_ipos_fee = 'relationships___field_date_from_api___field_pl_first_ipos_fee',
  relationships___field_date_from_api___vatrate = 'relationships___field_date_from_api___vatrate',
  relationships___field_date_from_api___uuid = 'relationships___field_date_from_api___uuid',
  relationships___field_date_from_api___relationships___node__accessories = 'relationships___field_date_from_api___relationships___node__accessories',
  relationships___field_date_from_api___relationships___node__cases = 'relationships___field_date_from_api___relationships___node__cases',
  relationships___field_date_from_api___relationships___paragraph__branches_tariff = 'relationships___field_date_from_api___relationships___paragraph__branches_tariff',
  relationships___field_date_from_api___pricelistgroup___drupal_internal__target_id = 'relationships___field_date_from_api___pricelistgroup___drupal_internal__target_id',
  relationships___field_date_from_api___id = 'relationships___field_date_from_api___id',
  relationships___field_date_from_api___parent___id = 'relationships___field_date_from_api___parent___id',
  relationships___field_date_from_api___parent___children = 'relationships___field_date_from_api___parent___children',
  relationships___field_date_from_api___children = 'relationships___field_date_from_api___children',
  relationships___field_date_from_api___children___id = 'relationships___field_date_from_api___children___id',
  relationships___field_date_from_api___children___children = 'relationships___field_date_from_api___children___children',
  relationships___field_date_from_api___internal___content = 'relationships___field_date_from_api___internal___content',
  relationships___field_date_from_api___internal___contentDigest = 'relationships___field_date_from_api___internal___contentDigest',
  relationships___field_date_from_api___internal___description = 'relationships___field_date_from_api___internal___description',
  relationships___field_date_from_api___internal___fieldOwners = 'relationships___field_date_from_api___internal___fieldOwners',
  relationships___field_date_from_api___internal___ignoreType = 'relationships___field_date_from_api___internal___ignoreType',
  relationships___field_date_from_api___internal___mediaType = 'relationships___field_date_from_api___internal___mediaType',
  relationships___field_date_from_api___internal___owner = 'relationships___field_date_from_api___internal___owner',
  relationships___field_date_from_api___internal___type = 'relationships___field_date_from_api___internal___type',
  relationships___field_recommended_for_branches = 'relationships___field_recommended_for_branches',
  relationships___field_recommended_for_branches___langcode = 'relationships___field_recommended_for_branches___langcode',
  relationships___field_recommended_for_branches___id = 'relationships___field_recommended_for_branches___id',
  relationships___field_recommended_for_branches___drupal_id = 'relationships___field_recommended_for_branches___drupal_id',
  relationships___field_recommended_for_branches___title = 'relationships___field_recommended_for_branches___title',
  relationships___field_recommended_for_branches___field_pl_is_online = 'relationships___field_recommended_for_branches___field_pl_is_online',
  relationships___field_recommended_for_branches___field_branches_date = 'relationships___field_recommended_for_branches___field_branches_date',
  relationships___field_recommended_for_branches___field_weight = 'relationships___field_recommended_for_branches___field_weight',
  relationships___field_recommended_for_branches___path___alias = 'relationships___field_recommended_for_branches___path___alias',
  relationships___field_recommended_for_branches___field_meta_description = 'relationships___field_recommended_for_branches___field_meta_description',
  relationships___field_recommended_for_branches___field_meta_title = 'relationships___field_recommended_for_branches___field_meta_title',
  relationships___field_recommended_for_branches___relationships___node__accessories = 'relationships___field_recommended_for_branches___relationships___node__accessories',
  relationships___field_recommended_for_branches___relationships___node__cases = 'relationships___field_recommended_for_branches___relationships___node__cases',
  relationships___field_recommended_for_branches___relationships___node__devices = 'relationships___field_recommended_for_branches___relationships___node__devices',
  relationships___field_recommended_for_branches___relationships___node__tariffs = 'relationships___field_recommended_for_branches___relationships___node__tariffs',
  relationships___field_recommended_for_branches___drupal_internal__nid = 'relationships___field_recommended_for_branches___drupal_internal__nid',
  relationships___field_recommended_for_branches___status = 'relationships___field_recommended_for_branches___status',
  relationships___field_recommended_for_branches___content_translation_source = 'relationships___field_recommended_for_branches___content_translation_source',
  relationships___field_recommended_for_branches___content_translation_outdated = 'relationships___field_recommended_for_branches___content_translation_outdated',
  relationships___field_recommended_for_branches___uuid = 'relationships___field_recommended_for_branches___uuid',
  relationships___field_recommended_for_branches___field_branches_icon___alt = 'relationships___field_recommended_for_branches___field_branches_icon___alt',
  relationships___field_recommended_for_branches___field_branches_icon___title = 'relationships___field_recommended_for_branches___field_branches_icon___title',
  relationships___field_recommended_for_branches___field_branches_icon___width = 'relationships___field_recommended_for_branches___field_branches_icon___width',
  relationships___field_recommended_for_branches___field_branches_icon___height = 'relationships___field_recommended_for_branches___field_branches_icon___height',
  relationships___field_recommended_for_branches___field_branches_icon___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_branches_icon___drupal_internal__target_id',
  relationships___field_recommended_for_branches___field_branches_image___alt = 'relationships___field_recommended_for_branches___field_branches_image___alt',
  relationships___field_recommended_for_branches___field_branches_image___title = 'relationships___field_recommended_for_branches___field_branches_image___title',
  relationships___field_recommended_for_branches___field_branches_image___width = 'relationships___field_recommended_for_branches___field_branches_image___width',
  relationships___field_recommended_for_branches___field_branches_image___height = 'relationships___field_recommended_for_branches___field_branches_image___height',
  relationships___field_recommended_for_branches___field_branches_image___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_branches_image___drupal_internal__target_id',
  relationships___field_recommended_for_branches___field_components = 'relationships___field_recommended_for_branches___field_components',
  relationships___field_recommended_for_branches___field_components___target_revision_id = 'relationships___field_recommended_for_branches___field_components___target_revision_id',
  relationships___field_recommended_for_branches___field_components___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_components___drupal_internal__target_id',
  relationships___field_recommended_for_branches___parent___id = 'relationships___field_recommended_for_branches___parent___id',
  relationships___field_recommended_for_branches___parent___children = 'relationships___field_recommended_for_branches___parent___children',
  relationships___field_recommended_for_branches___children = 'relationships___field_recommended_for_branches___children',
  relationships___field_recommended_for_branches___children___id = 'relationships___field_recommended_for_branches___children___id',
  relationships___field_recommended_for_branches___children___children = 'relationships___field_recommended_for_branches___children___children',
  relationships___field_recommended_for_branches___internal___content = 'relationships___field_recommended_for_branches___internal___content',
  relationships___field_recommended_for_branches___internal___contentDigest = 'relationships___field_recommended_for_branches___internal___contentDigest',
  relationships___field_recommended_for_branches___internal___description = 'relationships___field_recommended_for_branches___internal___description',
  relationships___field_recommended_for_branches___internal___fieldOwners = 'relationships___field_recommended_for_branches___internal___fieldOwners',
  relationships___field_recommended_for_branches___internal___ignoreType = 'relationships___field_recommended_for_branches___internal___ignoreType',
  relationships___field_recommended_for_branches___internal___mediaType = 'relationships___field_recommended_for_branches___internal___mediaType',
  relationships___field_recommended_for_branches___internal___owner = 'relationships___field_recommended_for_branches___internal___owner',
  relationships___field_recommended_for_branches___internal___type = 'relationships___field_recommended_for_branches___internal___type',
  relationships___field_accessories = 'relationships___field_accessories',
  relationships___field_accessories___langcode = 'relationships___field_accessories___langcode',
  relationships___field_accessories___drupal_id = 'relationships___field_accessories___drupal_id',
  relationships___field_accessories___title = 'relationships___field_accessories___title',
  relationships___field_accessories___field_pl_groupid = 'relationships___field_accessories___field_pl_groupid',
  relationships___field_accessories___field_weight = 'relationships___field_accessories___field_weight',
  relationships___field_accessories___relationships___field_recommended_for_branches = 'relationships___field_accessories___relationships___field_recommended_for_branches',
  relationships___field_accessories___drupal_internal__nid = 'relationships___field_accessories___drupal_internal__nid',
  relationships___field_accessories___status = 'relationships___field_accessories___status',
  relationships___field_accessories___content_translation_source = 'relationships___field_accessories___content_translation_source',
  relationships___field_accessories___content_translation_outdated = 'relationships___field_accessories___content_translation_outdated',
  relationships___field_accessories___uuid = 'relationships___field_accessories___uuid',
  relationships___field_accessories___field_date_from_api___drupal_internal__target_id = 'relationships___field_accessories___field_date_from_api___drupal_internal__target_id',
  relationships___field_accessories___field_pl_accessory_type___drupal_internal__target_id = 'relationships___field_accessories___field_pl_accessory_type___drupal_internal__target_id',
  relationships___field_accessories___field_pl_image___alt = 'relationships___field_accessories___field_pl_image___alt',
  relationships___field_accessories___field_pl_image___title = 'relationships___field_accessories___field_pl_image___title',
  relationships___field_accessories___field_pl_image___width = 'relationships___field_accessories___field_pl_image___width',
  relationships___field_accessories___field_pl_image___height = 'relationships___field_accessories___field_pl_image___height',
  relationships___field_accessories___field_pl_image___drupal_internal__target_id = 'relationships___field_accessories___field_pl_image___drupal_internal__target_id',
  relationships___field_accessories___field_pl_image_accessories_outl___alt = 'relationships___field_accessories___field_pl_image_accessories_outl___alt',
  relationships___field_accessories___field_pl_image_accessories_outl___title = 'relationships___field_accessories___field_pl_image_accessories_outl___title',
  relationships___field_accessories___field_pl_image_accessories_outl___width = 'relationships___field_accessories___field_pl_image_accessories_outl___width',
  relationships___field_accessories___field_pl_image_accessories_outl___height = 'relationships___field_accessories___field_pl_image_accessories_outl___height',
  relationships___field_accessories___field_pl_image_accessories_outl___drupal_internal__target_id = 'relationships___field_accessories___field_pl_image_accessories_outl___drupal_internal__target_id',
  relationships___field_accessories___field_pl_image_accessories_table___alt = 'relationships___field_accessories___field_pl_image_accessories_table___alt',
  relationships___field_accessories___field_pl_image_accessories_table___title = 'relationships___field_accessories___field_pl_image_accessories_table___title',
  relationships___field_accessories___field_pl_image_accessories_table___width = 'relationships___field_accessories___field_pl_image_accessories_table___width',
  relationships___field_accessories___field_pl_image_accessories_table___height = 'relationships___field_accessories___field_pl_image_accessories_table___height',
  relationships___field_accessories___field_pl_image_accessories_table___drupal_internal__target_id = 'relationships___field_accessories___field_pl_image_accessories_table___drupal_internal__target_id',
  relationships___field_accessories___field_recommended_for_branches = 'relationships___field_accessories___field_recommended_for_branches',
  relationships___field_accessories___field_recommended_for_branches___drupal_internal__target_id = 'relationships___field_accessories___field_recommended_for_branches___drupal_internal__target_id',
  relationships___field_accessories___id = 'relationships___field_accessories___id',
  relationships___field_accessories___parent___id = 'relationships___field_accessories___parent___id',
  relationships___field_accessories___parent___children = 'relationships___field_accessories___parent___children',
  relationships___field_accessories___children = 'relationships___field_accessories___children',
  relationships___field_accessories___children___id = 'relationships___field_accessories___children___id',
  relationships___field_accessories___children___children = 'relationships___field_accessories___children___children',
  relationships___field_accessories___internal___content = 'relationships___field_accessories___internal___content',
  relationships___field_accessories___internal___contentDigest = 'relationships___field_accessories___internal___contentDigest',
  relationships___field_accessories___internal___description = 'relationships___field_accessories___internal___description',
  relationships___field_accessories___internal___fieldOwners = 'relationships___field_accessories___internal___fieldOwners',
  relationships___field_accessories___internal___ignoreType = 'relationships___field_accessories___internal___ignoreType',
  relationships___field_accessories___internal___mediaType = 'relationships___field_accessories___internal___mediaType',
  relationships___field_accessories___internal___owner = 'relationships___field_accessories___internal___owner',
  relationships___field_accessories___internal___type = 'relationships___field_accessories___internal___type',
  relationships___field_pl_image_for_accessories___localFile___birthtime = 'relationships___field_pl_image_for_accessories___localFile___birthtime',
  relationships___field_pl_image_for_accessories___localFile___birthtimeMs = 'relationships___field_pl_image_for_accessories___localFile___birthtimeMs',
  relationships___field_pl_image_for_accessories___localFile___publicURL = 'relationships___field_pl_image_for_accessories___localFile___publicURL',
  relationships___field_pl_image_for_accessories___localFile___sourceInstanceName = 'relationships___field_pl_image_for_accessories___localFile___sourceInstanceName',
  relationships___field_pl_image_for_accessories___localFile___absolutePath = 'relationships___field_pl_image_for_accessories___localFile___absolutePath',
  relationships___field_pl_image_for_accessories___localFile___relativePath = 'relationships___field_pl_image_for_accessories___localFile___relativePath',
  relationships___field_pl_image_for_accessories___localFile___extension = 'relationships___field_pl_image_for_accessories___localFile___extension',
  relationships___field_pl_image_for_accessories___localFile___size = 'relationships___field_pl_image_for_accessories___localFile___size',
  relationships___field_pl_image_for_accessories___localFile___prettySize = 'relationships___field_pl_image_for_accessories___localFile___prettySize',
  relationships___field_pl_image_for_accessories___localFile___modifiedTime = 'relationships___field_pl_image_for_accessories___localFile___modifiedTime',
  relationships___field_pl_image_for_accessories___localFile___accessTime = 'relationships___field_pl_image_for_accessories___localFile___accessTime',
  relationships___field_pl_image_for_accessories___localFile___changeTime = 'relationships___field_pl_image_for_accessories___localFile___changeTime',
  relationships___field_pl_image_for_accessories___localFile___birthTime = 'relationships___field_pl_image_for_accessories___localFile___birthTime',
  relationships___field_pl_image_for_accessories___localFile___root = 'relationships___field_pl_image_for_accessories___localFile___root',
  relationships___field_pl_image_for_accessories___localFile___dir = 'relationships___field_pl_image_for_accessories___localFile___dir',
  relationships___field_pl_image_for_accessories___localFile___base = 'relationships___field_pl_image_for_accessories___localFile___base',
  relationships___field_pl_image_for_accessories___localFile___ext = 'relationships___field_pl_image_for_accessories___localFile___ext',
  relationships___field_pl_image_for_accessories___localFile___name = 'relationships___field_pl_image_for_accessories___localFile___name',
  relationships___field_pl_image_for_accessories___localFile___relativeDirectory = 'relationships___field_pl_image_for_accessories___localFile___relativeDirectory',
  relationships___field_pl_image_for_accessories___localFile___dev = 'relationships___field_pl_image_for_accessories___localFile___dev',
  relationships___field_pl_image_for_accessories___localFile___mode = 'relationships___field_pl_image_for_accessories___localFile___mode',
  relationships___field_pl_image_for_accessories___localFile___nlink = 'relationships___field_pl_image_for_accessories___localFile___nlink',
  relationships___field_pl_image_for_accessories___localFile___uid = 'relationships___field_pl_image_for_accessories___localFile___uid',
  relationships___field_pl_image_for_accessories___localFile___gid = 'relationships___field_pl_image_for_accessories___localFile___gid',
  relationships___field_pl_image_for_accessories___localFile___rdev = 'relationships___field_pl_image_for_accessories___localFile___rdev',
  relationships___field_pl_image_for_accessories___localFile___blksize = 'relationships___field_pl_image_for_accessories___localFile___blksize',
  relationships___field_pl_image_for_accessories___localFile___ino = 'relationships___field_pl_image_for_accessories___localFile___ino',
  relationships___field_pl_image_for_accessories___localFile___blocks = 'relationships___field_pl_image_for_accessories___localFile___blocks',
  relationships___field_pl_image_for_accessories___localFile___atimeMs = 'relationships___field_pl_image_for_accessories___localFile___atimeMs',
  relationships___field_pl_image_for_accessories___localFile___mtimeMs = 'relationships___field_pl_image_for_accessories___localFile___mtimeMs',
  relationships___field_pl_image_for_accessories___localFile___ctimeMs = 'relationships___field_pl_image_for_accessories___localFile___ctimeMs',
  relationships___field_pl_image_for_accessories___localFile___atime = 'relationships___field_pl_image_for_accessories___localFile___atime',
  relationships___field_pl_image_for_accessories___localFile___mtime = 'relationships___field_pl_image_for_accessories___localFile___mtime',
  relationships___field_pl_image_for_accessories___localFile___ctime = 'relationships___field_pl_image_for_accessories___localFile___ctime',
  relationships___field_pl_image_for_accessories___localFile___url = 'relationships___field_pl_image_for_accessories___localFile___url',
  relationships___field_pl_image_for_accessories___localFile___id = 'relationships___field_pl_image_for_accessories___localFile___id',
  relationships___field_pl_image_for_accessories___localFile___children = 'relationships___field_pl_image_for_accessories___localFile___children',
  relationships___field_pl_image_for_accessories___uri___url = 'relationships___field_pl_image_for_accessories___uri___url',
  relationships___field_pl_image_for_accessories___drupal_id = 'relationships___field_pl_image_for_accessories___drupal_id',
  relationships___field_pl_image_for_accessories___drupal_internal__fid = 'relationships___field_pl_image_for_accessories___drupal_internal__fid',
  relationships___field_pl_image_for_accessories___langcode = 'relationships___field_pl_image_for_accessories___langcode',
  relationships___field_pl_image_for_accessories___filename = 'relationships___field_pl_image_for_accessories___filename',
  relationships___field_pl_image_for_accessories___filemime = 'relationships___field_pl_image_for_accessories___filemime',
  relationships___field_pl_image_for_accessories___filesize = 'relationships___field_pl_image_for_accessories___filesize',
  relationships___field_pl_image_for_accessories___status = 'relationships___field_pl_image_for_accessories___status',
  relationships___field_pl_image_for_accessories___created = 'relationships___field_pl_image_for_accessories___created',
  relationships___field_pl_image_for_accessories___changed = 'relationships___field_pl_image_for_accessories___changed',
  relationships___field_pl_image_for_accessories___uuid = 'relationships___field_pl_image_for_accessories___uuid',
  relationships___field_pl_image_for_accessories___relationships___node__blog_post = 'relationships___field_pl_image_for_accessories___relationships___node__blog_post',
  relationships___field_pl_image_for_accessories___relationships___node__devices = 'relationships___field_pl_image_for_accessories___relationships___node__devices',
  relationships___field_pl_image_for_accessories___relationships___node__accessories = 'relationships___field_pl_image_for_accessories___relationships___node__accessories',
  relationships___field_pl_image_for_accessories___relationships___paragraph__news_image_title_text = 'relationships___field_pl_image_for_accessories___relationships___paragraph__news_image_title_text',
  relationships___field_pl_image_for_accessories___relationships___node__cases = 'relationships___field_pl_image_for_accessories___relationships___node__cases',
  relationships___field_pl_image_for_accessories___relationships___paragraph__branches_what_is_ipos = 'relationships___field_pl_image_for_accessories___relationships___paragraph__branches_what_is_ipos',
  relationships___field_pl_image_for_accessories___relationships___paragraph__branches_features = 'relationships___field_pl_image_for_accessories___relationships___paragraph__branches_features',
  relationships___field_pl_image_for_accessories___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_pl_image_for_accessories___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_pl_image_for_accessories___relationships___paragraph__branches_testimonials = 'relationships___field_pl_image_for_accessories___relationships___paragraph__branches_testimonials',
  relationships___field_pl_image_for_accessories___relationships___paragraph__branches_discounts = 'relationships___field_pl_image_for_accessories___relationships___paragraph__branches_discounts',
  relationships___field_pl_image_for_accessories___relationships___node__news = 'relationships___field_pl_image_for_accessories___relationships___node__news',
  relationships___field_pl_image_for_accessories___relationships___node__branches = 'relationships___field_pl_image_for_accessories___relationships___node__branches',
  relationships___field_pl_image_for_accessories___relationships___paragraph__branches_hero = 'relationships___field_pl_image_for_accessories___relationships___paragraph__branches_hero',
  relationships___field_pl_image_for_accessories___relationships___paragraph__bullets = 'relationships___field_pl_image_for_accessories___relationships___paragraph__bullets',
  relationships___field_pl_image_for_accessories___relationships___taxonomy_term__pricelist_group = 'relationships___field_pl_image_for_accessories___relationships___taxonomy_term__pricelist_group',
  relationships___field_pl_image_for_accessories___relationships___paragraph__branches_device = 'relationships___field_pl_image_for_accessories___relationships___paragraph__branches_device',
  relationships___field_pl_image_for_accessories___relationships___paragraph__branches_recommendation = 'relationships___field_pl_image_for_accessories___relationships___paragraph__branches_recommendation',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__accessories = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__accessories',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__branches = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__branches',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__about_us = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__about_us',
  relationships___field_pl_image_for_accessories___relationships___paragraph__logos = 'relationships___field_pl_image_for_accessories___relationships___paragraph__logos',
  relationships___field_pl_image_for_accessories___relationships___paragraph__how_it_works = 'relationships___field_pl_image_for_accessories___relationships___paragraph__how_it_works',
  relationships___field_pl_image_for_accessories___relationships___paragraph__ico_and_text = 'relationships___field_pl_image_for_accessories___relationships___paragraph__ico_and_text',
  relationships___field_pl_image_for_accessories___relationships___paragraph__hero_1 = 'relationships___field_pl_image_for_accessories___relationships___paragraph__hero_1',
  relationships___field_pl_image_for_accessories___relationships___paragraph__comprehensive_solution_item = 'relationships___field_pl_image_for_accessories___relationships___paragraph__comprehensive_solution_item',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__product = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__product',
  relationships___field_pl_image_for_accessories___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_pl_image_for_accessories___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_pl_image_for_accessories___relationships___paragraph__porownanie_terminali = 'relationships___field_pl_image_for_accessories___relationships___paragraph__porownanie_terminali',
  relationships___field_pl_image_for_accessories___relationships___paragraph__zdjecie_opis_link = 'relationships___field_pl_image_for_accessories___relationships___paragraph__zdjecie_opis_link',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__main_page = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__main_page',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_pl_image_for_accessories___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_pl_image_for_accessories___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__intercars = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__intercars',
  relationships___field_pl_image_for_accessories___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_pl_image_for_accessories___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_pl_image_for_accessories___relationships___node__agreement = 'relationships___field_pl_image_for_accessories___relationships___node__agreement',
  relationships___field_pl_image_for_accessories___relationships___node__fiscalization = 'relationships___field_pl_image_for_accessories___relationships___node__fiscalization',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__configurator = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__configurator',
  relationships___field_pl_image_for_accessories___relationships___paragraph__social_link = 'relationships___field_pl_image_for_accessories___relationships___paragraph__social_link',
  relationships___field_pl_image_for_accessories___relationships___paragraph__make_an_appointment = 'relationships___field_pl_image_for_accessories___relationships___paragraph__make_an_appointment',
  relationships___field_pl_image_for_accessories___relationships___paragraph__linked_icons = 'relationships___field_pl_image_for_accessories___relationships___paragraph__linked_icons',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__financing = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__financing',
  relationships___field_pl_image_for_accessories___relationships___site_setting_entity__franchise = 'relationships___field_pl_image_for_accessories___relationships___site_setting_entity__franchise',
  relationships___field_pl_image_for_accessories___id = 'relationships___field_pl_image_for_accessories___id',
  relationships___field_pl_image_for_accessories___parent___id = 'relationships___field_pl_image_for_accessories___parent___id',
  relationships___field_pl_image_for_accessories___parent___children = 'relationships___field_pl_image_for_accessories___parent___children',
  relationships___field_pl_image_for_accessories___children = 'relationships___field_pl_image_for_accessories___children',
  relationships___field_pl_image_for_accessories___children___id = 'relationships___field_pl_image_for_accessories___children___id',
  relationships___field_pl_image_for_accessories___children___children = 'relationships___field_pl_image_for_accessories___children___children',
  relationships___field_pl_image_for_accessories___internal___content = 'relationships___field_pl_image_for_accessories___internal___content',
  relationships___field_pl_image_for_accessories___internal___contentDigest = 'relationships___field_pl_image_for_accessories___internal___contentDigest',
  relationships___field_pl_image_for_accessories___internal___description = 'relationships___field_pl_image_for_accessories___internal___description',
  relationships___field_pl_image_for_accessories___internal___fieldOwners = 'relationships___field_pl_image_for_accessories___internal___fieldOwners',
  relationships___field_pl_image_for_accessories___internal___ignoreType = 'relationships___field_pl_image_for_accessories___internal___ignoreType',
  relationships___field_pl_image_for_accessories___internal___mediaType = 'relationships___field_pl_image_for_accessories___internal___mediaType',
  relationships___field_pl_image_for_accessories___internal___owner = 'relationships___field_pl_image_for_accessories___internal___owner',
  relationships___field_pl_image_for_accessories___internal___type = 'relationships___field_pl_image_for_accessories___internal___type',
  relationships___field_pl_image___localFile___birthtime = 'relationships___field_pl_image___localFile___birthtime',
  relationships___field_pl_image___localFile___birthtimeMs = 'relationships___field_pl_image___localFile___birthtimeMs',
  relationships___field_pl_image___localFile___publicURL = 'relationships___field_pl_image___localFile___publicURL',
  relationships___field_pl_image___localFile___sourceInstanceName = 'relationships___field_pl_image___localFile___sourceInstanceName',
  relationships___field_pl_image___localFile___absolutePath = 'relationships___field_pl_image___localFile___absolutePath',
  relationships___field_pl_image___localFile___relativePath = 'relationships___field_pl_image___localFile___relativePath',
  relationships___field_pl_image___localFile___extension = 'relationships___field_pl_image___localFile___extension',
  relationships___field_pl_image___localFile___size = 'relationships___field_pl_image___localFile___size',
  relationships___field_pl_image___localFile___prettySize = 'relationships___field_pl_image___localFile___prettySize',
  relationships___field_pl_image___localFile___modifiedTime = 'relationships___field_pl_image___localFile___modifiedTime',
  relationships___field_pl_image___localFile___accessTime = 'relationships___field_pl_image___localFile___accessTime',
  relationships___field_pl_image___localFile___changeTime = 'relationships___field_pl_image___localFile___changeTime',
  relationships___field_pl_image___localFile___birthTime = 'relationships___field_pl_image___localFile___birthTime',
  relationships___field_pl_image___localFile___root = 'relationships___field_pl_image___localFile___root',
  relationships___field_pl_image___localFile___dir = 'relationships___field_pl_image___localFile___dir',
  relationships___field_pl_image___localFile___base = 'relationships___field_pl_image___localFile___base',
  relationships___field_pl_image___localFile___ext = 'relationships___field_pl_image___localFile___ext',
  relationships___field_pl_image___localFile___name = 'relationships___field_pl_image___localFile___name',
  relationships___field_pl_image___localFile___relativeDirectory = 'relationships___field_pl_image___localFile___relativeDirectory',
  relationships___field_pl_image___localFile___dev = 'relationships___field_pl_image___localFile___dev',
  relationships___field_pl_image___localFile___mode = 'relationships___field_pl_image___localFile___mode',
  relationships___field_pl_image___localFile___nlink = 'relationships___field_pl_image___localFile___nlink',
  relationships___field_pl_image___localFile___uid = 'relationships___field_pl_image___localFile___uid',
  relationships___field_pl_image___localFile___gid = 'relationships___field_pl_image___localFile___gid',
  relationships___field_pl_image___localFile___rdev = 'relationships___field_pl_image___localFile___rdev',
  relationships___field_pl_image___localFile___blksize = 'relationships___field_pl_image___localFile___blksize',
  relationships___field_pl_image___localFile___ino = 'relationships___field_pl_image___localFile___ino',
  relationships___field_pl_image___localFile___blocks = 'relationships___field_pl_image___localFile___blocks',
  relationships___field_pl_image___localFile___atimeMs = 'relationships___field_pl_image___localFile___atimeMs',
  relationships___field_pl_image___localFile___mtimeMs = 'relationships___field_pl_image___localFile___mtimeMs',
  relationships___field_pl_image___localFile___ctimeMs = 'relationships___field_pl_image___localFile___ctimeMs',
  relationships___field_pl_image___localFile___atime = 'relationships___field_pl_image___localFile___atime',
  relationships___field_pl_image___localFile___mtime = 'relationships___field_pl_image___localFile___mtime',
  relationships___field_pl_image___localFile___ctime = 'relationships___field_pl_image___localFile___ctime',
  relationships___field_pl_image___localFile___url = 'relationships___field_pl_image___localFile___url',
  relationships___field_pl_image___localFile___id = 'relationships___field_pl_image___localFile___id',
  relationships___field_pl_image___localFile___children = 'relationships___field_pl_image___localFile___children',
  relationships___field_pl_image___uri___url = 'relationships___field_pl_image___uri___url',
  relationships___field_pl_image___drupal_id = 'relationships___field_pl_image___drupal_id',
  relationships___field_pl_image___drupal_internal__fid = 'relationships___field_pl_image___drupal_internal__fid',
  relationships___field_pl_image___langcode = 'relationships___field_pl_image___langcode',
  relationships___field_pl_image___filename = 'relationships___field_pl_image___filename',
  relationships___field_pl_image___filemime = 'relationships___field_pl_image___filemime',
  relationships___field_pl_image___filesize = 'relationships___field_pl_image___filesize',
  relationships___field_pl_image___status = 'relationships___field_pl_image___status',
  relationships___field_pl_image___created = 'relationships___field_pl_image___created',
  relationships___field_pl_image___changed = 'relationships___field_pl_image___changed',
  relationships___field_pl_image___uuid = 'relationships___field_pl_image___uuid',
  relationships___field_pl_image___relationships___node__blog_post = 'relationships___field_pl_image___relationships___node__blog_post',
  relationships___field_pl_image___relationships___node__devices = 'relationships___field_pl_image___relationships___node__devices',
  relationships___field_pl_image___relationships___node__accessories = 'relationships___field_pl_image___relationships___node__accessories',
  relationships___field_pl_image___relationships___paragraph__news_image_title_text = 'relationships___field_pl_image___relationships___paragraph__news_image_title_text',
  relationships___field_pl_image___relationships___node__cases = 'relationships___field_pl_image___relationships___node__cases',
  relationships___field_pl_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_pl_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_pl_image___relationships___paragraph__branches_features = 'relationships___field_pl_image___relationships___paragraph__branches_features',
  relationships___field_pl_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_pl_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_pl_image___relationships___paragraph__branches_testimonials = 'relationships___field_pl_image___relationships___paragraph__branches_testimonials',
  relationships___field_pl_image___relationships___paragraph__branches_discounts = 'relationships___field_pl_image___relationships___paragraph__branches_discounts',
  relationships___field_pl_image___relationships___node__news = 'relationships___field_pl_image___relationships___node__news',
  relationships___field_pl_image___relationships___node__branches = 'relationships___field_pl_image___relationships___node__branches',
  relationships___field_pl_image___relationships___paragraph__branches_hero = 'relationships___field_pl_image___relationships___paragraph__branches_hero',
  relationships___field_pl_image___relationships___paragraph__bullets = 'relationships___field_pl_image___relationships___paragraph__bullets',
  relationships___field_pl_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_pl_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_pl_image___relationships___paragraph__branches_device = 'relationships___field_pl_image___relationships___paragraph__branches_device',
  relationships___field_pl_image___relationships___paragraph__branches_recommendation = 'relationships___field_pl_image___relationships___paragraph__branches_recommendation',
  relationships___field_pl_image___relationships___site_setting_entity__accessories = 'relationships___field_pl_image___relationships___site_setting_entity__accessories',
  relationships___field_pl_image___relationships___site_setting_entity__branches = 'relationships___field_pl_image___relationships___site_setting_entity__branches',
  relationships___field_pl_image___relationships___site_setting_entity__about_us = 'relationships___field_pl_image___relationships___site_setting_entity__about_us',
  relationships___field_pl_image___relationships___paragraph__logos = 'relationships___field_pl_image___relationships___paragraph__logos',
  relationships___field_pl_image___relationships___paragraph__how_it_works = 'relationships___field_pl_image___relationships___paragraph__how_it_works',
  relationships___field_pl_image___relationships___paragraph__ico_and_text = 'relationships___field_pl_image___relationships___paragraph__ico_and_text',
  relationships___field_pl_image___relationships___paragraph__hero_1 = 'relationships___field_pl_image___relationships___paragraph__hero_1',
  relationships___field_pl_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_pl_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_pl_image___relationships___site_setting_entity__product = 'relationships___field_pl_image___relationships___site_setting_entity__product',
  relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_pl_image___relationships___paragraph__porownanie_terminali = 'relationships___field_pl_image___relationships___paragraph__porownanie_terminali',
  relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_pl_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_pl_image___relationships___site_setting_entity__main_page = 'relationships___field_pl_image___relationships___site_setting_entity__main_page',
  relationships___field_pl_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_pl_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_pl_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_pl_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_pl_image___relationships___site_setting_entity__intercars = 'relationships___field_pl_image___relationships___site_setting_entity__intercars',
  relationships___field_pl_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_pl_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_pl_image___relationships___node__agreement = 'relationships___field_pl_image___relationships___node__agreement',
  relationships___field_pl_image___relationships___node__fiscalization = 'relationships___field_pl_image___relationships___node__fiscalization',
  relationships___field_pl_image___relationships___site_setting_entity__configurator = 'relationships___field_pl_image___relationships___site_setting_entity__configurator',
  relationships___field_pl_image___relationships___paragraph__social_link = 'relationships___field_pl_image___relationships___paragraph__social_link',
  relationships___field_pl_image___relationships___paragraph__make_an_appointment = 'relationships___field_pl_image___relationships___paragraph__make_an_appointment',
  relationships___field_pl_image___relationships___paragraph__linked_icons = 'relationships___field_pl_image___relationships___paragraph__linked_icons',
  relationships___field_pl_image___relationships___site_setting_entity__financing = 'relationships___field_pl_image___relationships___site_setting_entity__financing',
  relationships___field_pl_image___relationships___site_setting_entity__franchise = 'relationships___field_pl_image___relationships___site_setting_entity__franchise',
  relationships___field_pl_image___id = 'relationships___field_pl_image___id',
  relationships___field_pl_image___parent___id = 'relationships___field_pl_image___parent___id',
  relationships___field_pl_image___parent___children = 'relationships___field_pl_image___parent___children',
  relationships___field_pl_image___children = 'relationships___field_pl_image___children',
  relationships___field_pl_image___children___id = 'relationships___field_pl_image___children___id',
  relationships___field_pl_image___children___children = 'relationships___field_pl_image___children___children',
  relationships___field_pl_image___internal___content = 'relationships___field_pl_image___internal___content',
  relationships___field_pl_image___internal___contentDigest = 'relationships___field_pl_image___internal___contentDigest',
  relationships___field_pl_image___internal___description = 'relationships___field_pl_image___internal___description',
  relationships___field_pl_image___internal___fieldOwners = 'relationships___field_pl_image___internal___fieldOwners',
  relationships___field_pl_image___internal___ignoreType = 'relationships___field_pl_image___internal___ignoreType',
  relationships___field_pl_image___internal___mediaType = 'relationships___field_pl_image___internal___mediaType',
  relationships___field_pl_image___internal___owner = 'relationships___field_pl_image___internal___owner',
  relationships___field_pl_image___internal___type = 'relationships___field_pl_image___internal___type',
  relationships___field_pl_cases = 'relationships___field_pl_cases',
  relationships___field_pl_cases___drupal_id = 'relationships___field_pl_cases___drupal_id',
  relationships___field_pl_cases___title = 'relationships___field_pl_cases___title',
  relationships___field_pl_cases___field_pl_color = 'relationships___field_pl_cases___field_pl_color',
  relationships___field_pl_cases___relationships___field_recommended_for_branches = 'relationships___field_pl_cases___relationships___field_recommended_for_branches',
  relationships___field_pl_cases___relationships___node__devices = 'relationships___field_pl_cases___relationships___node__devices',
  relationships___field_pl_cases___drupal_internal__nid = 'relationships___field_pl_cases___drupal_internal__nid',
  relationships___field_pl_cases___status = 'relationships___field_pl_cases___status',
  relationships___field_pl_cases___content_translation_source = 'relationships___field_pl_cases___content_translation_source',
  relationships___field_pl_cases___content_translation_outdated = 'relationships___field_pl_cases___content_translation_outdated',
  relationships___field_pl_cases___langcode = 'relationships___field_pl_cases___langcode',
  relationships___field_pl_cases___uuid = 'relationships___field_pl_cases___uuid',
  relationships___field_pl_cases___field_pl_image___alt = 'relationships___field_pl_cases___field_pl_image___alt',
  relationships___field_pl_cases___field_pl_image___title = 'relationships___field_pl_cases___field_pl_image___title',
  relationships___field_pl_cases___field_pl_image___width = 'relationships___field_pl_cases___field_pl_image___width',
  relationships___field_pl_cases___field_pl_image___height = 'relationships___field_pl_cases___field_pl_image___height',
  relationships___field_pl_cases___field_pl_image___drupal_internal__target_id = 'relationships___field_pl_cases___field_pl_image___drupal_internal__target_id',
  relationships___field_pl_cases___field_recommended_for_branches = 'relationships___field_pl_cases___field_recommended_for_branches',
  relationships___field_pl_cases___field_recommended_for_branches___drupal_internal__target_id = 'relationships___field_pl_cases___field_recommended_for_branches___drupal_internal__target_id',
  relationships___field_pl_cases___field_date_from_api___drupal_internal__target_id = 'relationships___field_pl_cases___field_date_from_api___drupal_internal__target_id',
  relationships___field_pl_cases___id = 'relationships___field_pl_cases___id',
  relationships___field_pl_cases___parent___id = 'relationships___field_pl_cases___parent___id',
  relationships___field_pl_cases___parent___children = 'relationships___field_pl_cases___parent___children',
  relationships___field_pl_cases___children = 'relationships___field_pl_cases___children',
  relationships___field_pl_cases___children___id = 'relationships___field_pl_cases___children___id',
  relationships___field_pl_cases___children___children = 'relationships___field_pl_cases___children___children',
  relationships___field_pl_cases___internal___content = 'relationships___field_pl_cases___internal___content',
  relationships___field_pl_cases___internal___contentDigest = 'relationships___field_pl_cases___internal___contentDigest',
  relationships___field_pl_cases___internal___description = 'relationships___field_pl_cases___internal___description',
  relationships___field_pl_cases___internal___fieldOwners = 'relationships___field_pl_cases___internal___fieldOwners',
  relationships___field_pl_cases___internal___ignoreType = 'relationships___field_pl_cases___internal___ignoreType',
  relationships___field_pl_cases___internal___mediaType = 'relationships___field_pl_cases___internal___mediaType',
  relationships___field_pl_cases___internal___owner = 'relationships___field_pl_cases___internal___owner',
  relationships___field_pl_cases___internal___type = 'relationships___field_pl_cases___internal___type',
  relationships___field_payment_methods = 'relationships___field_payment_methods',
  relationships___field_payment_methods___machine_api_name = 'relationships___field_payment_methods___machine_api_name',
  relationships___field_payment_methods___name = 'relationships___field_payment_methods___name',
  relationships___field_payment_methods___drupal_id = 'relationships___field_payment_methods___drupal_id',
  relationships___field_payment_methods___drupal_internal__tid = 'relationships___field_payment_methods___drupal_internal__tid',
  relationships___field_payment_methods___langcode = 'relationships___field_payment_methods___langcode',
  relationships___field_payment_methods___status = 'relationships___field_payment_methods___status',
  relationships___field_payment_methods___weight = 'relationships___field_payment_methods___weight',
  relationships___field_payment_methods___changed = 'relationships___field_payment_methods___changed',
  relationships___field_payment_methods___uuid = 'relationships___field_payment_methods___uuid',
  relationships___field_payment_methods___relationships___node__agreement = 'relationships___field_payment_methods___relationships___node__agreement',
  relationships___field_payment_methods___relationships___node__devices = 'relationships___field_payment_methods___relationships___node__devices',
  relationships___field_payment_methods___vid___drupal_internal__target_id = 'relationships___field_payment_methods___vid___drupal_internal__target_id',
  relationships___field_payment_methods___id = 'relationships___field_payment_methods___id',
  relationships___field_payment_methods___parent___id = 'relationships___field_payment_methods___parent___id',
  relationships___field_payment_methods___parent___children = 'relationships___field_payment_methods___parent___children',
  relationships___field_payment_methods___children = 'relationships___field_payment_methods___children',
  relationships___field_payment_methods___children___id = 'relationships___field_payment_methods___children___id',
  relationships___field_payment_methods___children___children = 'relationships___field_payment_methods___children___children',
  relationships___field_payment_methods___internal___content = 'relationships___field_payment_methods___internal___content',
  relationships___field_payment_methods___internal___contentDigest = 'relationships___field_payment_methods___internal___contentDigest',
  relationships___field_payment_methods___internal___description = 'relationships___field_payment_methods___internal___description',
  relationships___field_payment_methods___internal___fieldOwners = 'relationships___field_payment_methods___internal___fieldOwners',
  relationships___field_payment_methods___internal___ignoreType = 'relationships___field_payment_methods___internal___ignoreType',
  relationships___field_payment_methods___internal___mediaType = 'relationships___field_payment_methods___internal___mediaType',
  relationships___field_payment_methods___internal___owner = 'relationships___field_payment_methods___internal___owner',
  relationships___field_payment_methods___internal___type = 'relationships___field_payment_methods___internal___type',
  relationships___node__fiscalization = 'relationships___node__fiscalization',
  relationships___node__fiscalization___langcode = 'relationships___node__fiscalization___langcode',
  relationships___node__fiscalization___drupal_id = 'relationships___node__fiscalization___drupal_id',
  relationships___node__fiscalization___title = 'relationships___node__fiscalization___title',
  relationships___node__fiscalization___description___value = 'relationships___node__fiscalization___description___value',
  relationships___node__fiscalization___appointment_available = 'relationships___node__fiscalization___appointment_available',
  relationships___node__fiscalization___is_free = 'relationships___node__fiscalization___is_free',
  relationships___node__fiscalization___is_popular = 'relationships___node__fiscalization___is_popular',
  relationships___node__fiscalization___price_list_name = 'relationships___node__fiscalization___price_list_name',
  relationships___node__fiscalization___field_weight = 'relationships___node__fiscalization___field_weight',
  relationships___node__fiscalization___calendar_text___value = 'relationships___node__fiscalization___calendar_text___value',
  relationships___node__fiscalization___disabled_fiscalization_messsage = 'relationships___node__fiscalization___disabled_fiscalization_messsage',
  relationships___node__fiscalization___relationships___available_for_devices = 'relationships___node__fiscalization___relationships___available_for_devices',
  relationships___node__fiscalization___drupal_internal__nid = 'relationships___node__fiscalization___drupal_internal__nid',
  relationships___node__fiscalization___drupal_internal__vid = 'relationships___node__fiscalization___drupal_internal__vid',
  relationships___node__fiscalization___status = 'relationships___node__fiscalization___status',
  relationships___node__fiscalization___created = 'relationships___node__fiscalization___created',
  relationships___node__fiscalization___changed = 'relationships___node__fiscalization___changed',
  relationships___node__fiscalization___weight = 'relationships___node__fiscalization___weight',
  relationships___node__fiscalization___uuid = 'relationships___node__fiscalization___uuid',
  relationships___node__fiscalization___available_for_devices = 'relationships___node__fiscalization___available_for_devices',
  relationships___node__fiscalization___available_for_devices___drupal_internal__target_id = 'relationships___node__fiscalization___available_for_devices___drupal_internal__target_id',
  relationships___node__fiscalization___image___alt = 'relationships___node__fiscalization___image___alt',
  relationships___node__fiscalization___image___title = 'relationships___node__fiscalization___image___title',
  relationships___node__fiscalization___image___width = 'relationships___node__fiscalization___image___width',
  relationships___node__fiscalization___image___height = 'relationships___node__fiscalization___image___height',
  relationships___node__fiscalization___image___drupal_internal__target_id = 'relationships___node__fiscalization___image___drupal_internal__target_id',
  relationships___node__fiscalization___id = 'relationships___node__fiscalization___id',
  relationships___node__fiscalization___parent___id = 'relationships___node__fiscalization___parent___id',
  relationships___node__fiscalization___parent___children = 'relationships___node__fiscalization___parent___children',
  relationships___node__fiscalization___children = 'relationships___node__fiscalization___children',
  relationships___node__fiscalization___children___id = 'relationships___node__fiscalization___children___id',
  relationships___node__fiscalization___children___children = 'relationships___node__fiscalization___children___children',
  relationships___node__fiscalization___internal___content = 'relationships___node__fiscalization___internal___content',
  relationships___node__fiscalization___internal___contentDigest = 'relationships___node__fiscalization___internal___contentDigest',
  relationships___node__fiscalization___internal___description = 'relationships___node__fiscalization___internal___description',
  relationships___node__fiscalization___internal___fieldOwners = 'relationships___node__fiscalization___internal___fieldOwners',
  relationships___node__fiscalization___internal___ignoreType = 'relationships___node__fiscalization___internal___ignoreType',
  relationships___node__fiscalization___internal___mediaType = 'relationships___node__fiscalization___internal___mediaType',
  relationships___node__fiscalization___internal___owner = 'relationships___node__fiscalization___internal___owner',
  relationships___node__fiscalization___internal___type = 'relationships___node__fiscalization___internal___type',
  field_tooltip_text_for_branches = 'field_tooltip_text_for_branches',
  field_pl_is_virtual = 'field_pl_is_virtual',
  field_is_available = 'field_is_available',
  field_lease_strike_through_price = 'field_lease_strike_through_price',
  field_lease_allowed = 'field_lease_allowed',
  field_pl_price_list_name = 'field_pl_price_list_name',
  field_additional_price_lists = 'field_additional_price_lists',
  field_popup_body___value = 'field_popup_body___value',
  field_popup_button_back = 'field_popup_button_back',
  field_popup_button_ok = 'field_popup_button_ok',
  field_popup_enabled = 'field_popup_enabled',
  field_popup_title = 'field_popup_title',
  drupal_internal__nid = 'drupal_internal__nid',
  status = 'status',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  field_payment_methods = 'field_payment_methods',
  field_payment_methods___drupal_internal__target_id = 'field_payment_methods___drupal_internal__target_id',
  field_pl_cases = 'field_pl_cases',
  field_pl_cases___drupal_internal__target_id = 'field_pl_cases___drupal_internal__target_id',
  field_pl_image___alt = 'field_pl_image___alt',
  field_pl_image___title = 'field_pl_image___title',
  field_pl_image___width = 'field_pl_image___width',
  field_pl_image___height = 'field_pl_image___height',
  field_pl_image___drupal_internal__target_id = 'field_pl_image___drupal_internal__target_id',
  field_pl_image_for_accessories___alt = 'field_pl_image_for_accessories___alt',
  field_pl_image_for_accessories___title = 'field_pl_image_for_accessories___title',
  field_pl_image_for_accessories___width = 'field_pl_image_for_accessories___width',
  field_pl_image_for_accessories___height = 'field_pl_image_for_accessories___height',
  field_pl_image_for_accessories___drupal_internal__target_id = 'field_pl_image_for_accessories___drupal_internal__target_id',
  field_recommended_for_branches = 'field_recommended_for_branches',
  field_recommended_for_branches___drupal_internal__target_id = 'field_recommended_for_branches___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__DevicesFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_subtitle?: Maybe<StringQueryOperatorInput>;
  field_pl_description_in_cms?: Maybe<Field_Pl_Description_In_CmsFilterInput>;
  field_pl_is_online?: Maybe<BooleanQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  field_pl_strikethrough_price?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__DevicesRelationshipsFilterInput>;
  field_tooltip_text_for_branches?: Maybe<StringQueryOperatorInput>;
  field_pl_is_virtual?: Maybe<BooleanQueryOperatorInput>;
  field_is_available?: Maybe<BooleanQueryOperatorInput>;
  field_lease_strike_through_price?: Maybe<StringQueryOperatorInput>;
  field_lease_allowed?: Maybe<BooleanQueryOperatorInput>;
  field_pl_price_list_name?: Maybe<StringQueryOperatorInput>;
  field_additional_price_lists?: Maybe<StringQueryOperatorInput>;
  field_popup_body?: Maybe<Field_Popup_BodyFilterInput>;
  field_popup_button_back?: Maybe<StringQueryOperatorInput>;
  field_popup_button_ok?: Maybe<StringQueryOperatorInput>;
  field_popup_enabled?: Maybe<BooleanQueryOperatorInput>;
  field_popup_title?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_payment_methods?: Maybe<Node__DevicesField_Payment_MethodsFilterListInput>;
  field_pl_cases?: Maybe<Node__DevicesField_Pl_CasesFilterListInput>;
  field_pl_image?: Maybe<Node__DevicesField_Pl_ImageFilterInput>;
  field_pl_image_for_accessories?: Maybe<Node__DevicesField_Pl_Image_For_AccessoriesFilterInput>;
  field_recommended_for_branches?: Maybe<
    Node__DevicesField_Recommended_For_BranchesFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__DevicesFilterListInput = {
  elemMatch?: Maybe<Node__DevicesFilterInput>;
};

export type Node__DevicesGroupConnection = {
  __typename?: 'node__devicesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__DevicesEdge>;
  nodes: Array<Node__Devices>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__DevicesRelationships = {
  __typename?: 'node__devicesRelationships';
  field_date_from_api?: Maybe<Node__Price_List>;
  field_recommended_for_branches?: Maybe<Array<Maybe<Node__Branches>>>;
  field_accessories?: Maybe<Array<Maybe<Node__Accessories>>>;
  field_pl_image_for_accessories?: Maybe<File__File>;
  field_pl_image?: Maybe<File__File>;
  field_pl_cases?: Maybe<Array<Maybe<Node__Cases>>>;
  field_payment_methods?: Maybe<Array<Maybe<Taxonomy_Term__Payment_Types>>>;
  node__fiscalization?: Maybe<Array<Maybe<Node__Fiscalization>>>;
  field_category?: Maybe<Taxonomy_Term__Product_Category>;
};

export type Node__DevicesRelationshipsFilterInput = {
  field_date_from_api?: Maybe<Node__Price_ListFilterInput>;
  field_recommended_for_branches?: Maybe<Node__BranchesFilterListInput>;
  field_accessories?: Maybe<Node__AccessoriesFilterListInput>;
  field_pl_image_for_accessories?: Maybe<File__FileFilterInput>;
  field_pl_image?: Maybe<File__FileFilterInput>;
  field_pl_cases?: Maybe<Node__CasesFilterListInput>;
  field_payment_methods?: Maybe<Taxonomy_Term__Payment_TypesFilterListInput>;
  node__fiscalization?: Maybe<Node__FiscalizationFilterListInput>;
};

export type Node__DevicesSortInput = {
  fields?: Maybe<Array<Maybe<Node__DevicesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Fiscalization = Node & {
  __typename?: 'node__fiscalization';
  langcode?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Fiscalization_Description>;
  appointment_available?: Maybe<Scalars['Boolean']>;
  is_free?: Maybe<Scalars['Boolean']>;
  is_popular?: Maybe<Scalars['Boolean']>;
  price_list_name?: Maybe<Scalars['String']>;
  field_weight?: Maybe<Scalars['Int']>;
  calendar_text?: Maybe<Fiscalization_Calendar_Text>;
  disabled_fiscalization_messsage?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__FiscalizationRelationships>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  drupal_internal__vid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  weight?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
  available_for_devices?: Maybe<Array<Maybe<Node__FiscalizationAvailable_For_Devices>>>;
  image?: Maybe<Node__FiscalizationImage>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__FiscalizationCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Node__FiscalizationChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Node__FiscalizationAvailable_For_Devices = {
  __typename?: 'node__fiscalizationAvailable_for_devices';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__FiscalizationAvailable_For_DevicesFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__FiscalizationAvailable_For_DevicesFilterListInput = {
  elemMatch?: Maybe<Node__FiscalizationAvailable_For_DevicesFilterInput>;
};

export type Node__FiscalizationConnection = {
  __typename?: 'node__fiscalizationConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__FiscalizationEdge>;
  nodes: Array<Node__Fiscalization>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__FiscalizationGroupConnection>;
};

export type Node__FiscalizationConnectionDistinctArgs = {
  field: Node__FiscalizationFieldsEnum;
};

export type Node__FiscalizationConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__FiscalizationFieldsEnum;
};

export type Node__FiscalizationEdge = {
  __typename?: 'node__fiscalizationEdge';
  next?: Maybe<Node__Fiscalization>;
  node: Node__Fiscalization;
  previous?: Maybe<Node__Fiscalization>;
};

export enum Node__FiscalizationFieldsEnum {
  langcode = 'langcode',
  drupal_id = 'drupal_id',
  title = 'title',
  description___value = 'description___value',
  appointment_available = 'appointment_available',
  is_free = 'is_free',
  is_popular = 'is_popular',
  price_list_name = 'price_list_name',
  field_weight = 'field_weight',
  calendar_text___value = 'calendar_text___value',
  disabled_fiscalization_messsage = 'disabled_fiscalization_messsage',
  relationships___image___localFile___birthtime = 'relationships___image___localFile___birthtime',
  relationships___image___localFile___birthtimeMs = 'relationships___image___localFile___birthtimeMs',
  relationships___image___localFile___publicURL = 'relationships___image___localFile___publicURL',
  relationships___image___localFile___sourceInstanceName = 'relationships___image___localFile___sourceInstanceName',
  relationships___image___localFile___absolutePath = 'relationships___image___localFile___absolutePath',
  relationships___image___localFile___relativePath = 'relationships___image___localFile___relativePath',
  relationships___image___localFile___extension = 'relationships___image___localFile___extension',
  relationships___image___localFile___size = 'relationships___image___localFile___size',
  relationships___image___localFile___prettySize = 'relationships___image___localFile___prettySize',
  relationships___image___localFile___modifiedTime = 'relationships___image___localFile___modifiedTime',
  relationships___image___localFile___accessTime = 'relationships___image___localFile___accessTime',
  relationships___image___localFile___changeTime = 'relationships___image___localFile___changeTime',
  relationships___image___localFile___birthTime = 'relationships___image___localFile___birthTime',
  relationships___image___localFile___root = 'relationships___image___localFile___root',
  relationships___image___localFile___dir = 'relationships___image___localFile___dir',
  relationships___image___localFile___base = 'relationships___image___localFile___base',
  relationships___image___localFile___ext = 'relationships___image___localFile___ext',
  relationships___image___localFile___name = 'relationships___image___localFile___name',
  relationships___image___localFile___relativeDirectory = 'relationships___image___localFile___relativeDirectory',
  relationships___image___localFile___dev = 'relationships___image___localFile___dev',
  relationships___image___localFile___mode = 'relationships___image___localFile___mode',
  relationships___image___localFile___nlink = 'relationships___image___localFile___nlink',
  relationships___image___localFile___uid = 'relationships___image___localFile___uid',
  relationships___image___localFile___gid = 'relationships___image___localFile___gid',
  relationships___image___localFile___rdev = 'relationships___image___localFile___rdev',
  relationships___image___localFile___blksize = 'relationships___image___localFile___blksize',
  relationships___image___localFile___ino = 'relationships___image___localFile___ino',
  relationships___image___localFile___blocks = 'relationships___image___localFile___blocks',
  relationships___image___localFile___atimeMs = 'relationships___image___localFile___atimeMs',
  relationships___image___localFile___mtimeMs = 'relationships___image___localFile___mtimeMs',
  relationships___image___localFile___ctimeMs = 'relationships___image___localFile___ctimeMs',
  relationships___image___localFile___atime = 'relationships___image___localFile___atime',
  relationships___image___localFile___mtime = 'relationships___image___localFile___mtime',
  relationships___image___localFile___ctime = 'relationships___image___localFile___ctime',
  relationships___image___localFile___url = 'relationships___image___localFile___url',
  relationships___image___localFile___id = 'relationships___image___localFile___id',
  relationships___image___localFile___children = 'relationships___image___localFile___children',
  relationships___image___uri___url = 'relationships___image___uri___url',
  relationships___image___drupal_id = 'relationships___image___drupal_id',
  relationships___image___drupal_internal__fid = 'relationships___image___drupal_internal__fid',
  relationships___image___langcode = 'relationships___image___langcode',
  relationships___image___filename = 'relationships___image___filename',
  relationships___image___filemime = 'relationships___image___filemime',
  relationships___image___filesize = 'relationships___image___filesize',
  relationships___image___status = 'relationships___image___status',
  relationships___image___created = 'relationships___image___created',
  relationships___image___changed = 'relationships___image___changed',
  relationships___image___uuid = 'relationships___image___uuid',
  relationships___image___relationships___node__blog_post = 'relationships___image___relationships___node__blog_post',
  relationships___image___relationships___node__devices = 'relationships___image___relationships___node__devices',
  relationships___image___relationships___node__accessories = 'relationships___image___relationships___node__accessories',
  relationships___image___relationships___paragraph__news_image_title_text = 'relationships___image___relationships___paragraph__news_image_title_text',
  relationships___image___relationships___node__cases = 'relationships___image___relationships___node__cases',
  relationships___image___relationships___paragraph__branches_what_is_ipos = 'relationships___image___relationships___paragraph__branches_what_is_ipos',
  relationships___image___relationships___paragraph__branches_features = 'relationships___image___relationships___paragraph__branches_features',
  relationships___image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___image___relationships___paragraph__branches_testimonials = 'relationships___image___relationships___paragraph__branches_testimonials',
  relationships___image___relationships___paragraph__branches_discounts = 'relationships___image___relationships___paragraph__branches_discounts',
  relationships___image___relationships___node__news = 'relationships___image___relationships___node__news',
  relationships___image___relationships___node__branches = 'relationships___image___relationships___node__branches',
  relationships___image___relationships___paragraph__branches_hero = 'relationships___image___relationships___paragraph__branches_hero',
  relationships___image___relationships___paragraph__bullets = 'relationships___image___relationships___paragraph__bullets',
  relationships___image___relationships___taxonomy_term__pricelist_group = 'relationships___image___relationships___taxonomy_term__pricelist_group',
  relationships___image___relationships___paragraph__branches_device = 'relationships___image___relationships___paragraph__branches_device',
  relationships___image___relationships___paragraph__branches_recommendation = 'relationships___image___relationships___paragraph__branches_recommendation',
  relationships___image___relationships___site_setting_entity__accessories = 'relationships___image___relationships___site_setting_entity__accessories',
  relationships___image___relationships___site_setting_entity__branches = 'relationships___image___relationships___site_setting_entity__branches',
  relationships___image___relationships___site_setting_entity__about_us = 'relationships___image___relationships___site_setting_entity__about_us',
  relationships___image___relationships___paragraph__logos = 'relationships___image___relationships___paragraph__logos',
  relationships___image___relationships___paragraph__how_it_works = 'relationships___image___relationships___paragraph__how_it_works',
  relationships___image___relationships___paragraph__ico_and_text = 'relationships___image___relationships___paragraph__ico_and_text',
  relationships___image___relationships___paragraph__hero_1 = 'relationships___image___relationships___paragraph__hero_1',
  relationships___image___relationships___paragraph__comprehensive_solution_item = 'relationships___image___relationships___paragraph__comprehensive_solution_item',
  relationships___image___relationships___site_setting_entity__product = 'relationships___image___relationships___site_setting_entity__product',
  relationships___image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___image___relationships___paragraph__porownanie_terminali = 'relationships___image___relationships___paragraph__porownanie_terminali',
  relationships___image___relationships___paragraph__zdjecie_opis_link = 'relationships___image___relationships___paragraph__zdjecie_opis_link',
  relationships___image___relationships___site_setting_entity__main_page = 'relationships___image___relationships___site_setting_entity__main_page',
  relationships___image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___image___relationships___site_setting_entity__intercars = 'relationships___image___relationships___site_setting_entity__intercars',
  relationships___image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___image___relationships___node__agreement = 'relationships___image___relationships___node__agreement',
  relationships___image___relationships___node__fiscalization = 'relationships___image___relationships___node__fiscalization',
  relationships___image___relationships___site_setting_entity__configurator = 'relationships___image___relationships___site_setting_entity__configurator',
  relationships___image___relationships___paragraph__social_link = 'relationships___image___relationships___paragraph__social_link',
  relationships___image___relationships___paragraph__make_an_appointment = 'relationships___image___relationships___paragraph__make_an_appointment',
  relationships___image___relationships___paragraph__linked_icons = 'relationships___image___relationships___paragraph__linked_icons',
  relationships___image___relationships___site_setting_entity__financing = 'relationships___image___relationships___site_setting_entity__financing',
  relationships___image___relationships___site_setting_entity__franchise = 'relationships___image___relationships___site_setting_entity__franchise',
  relationships___image___id = 'relationships___image___id',
  relationships___image___parent___id = 'relationships___image___parent___id',
  relationships___image___parent___children = 'relationships___image___parent___children',
  relationships___image___children = 'relationships___image___children',
  relationships___image___children___id = 'relationships___image___children___id',
  relationships___image___children___children = 'relationships___image___children___children',
  relationships___image___internal___content = 'relationships___image___internal___content',
  relationships___image___internal___contentDigest = 'relationships___image___internal___contentDigest',
  relationships___image___internal___description = 'relationships___image___internal___description',
  relationships___image___internal___fieldOwners = 'relationships___image___internal___fieldOwners',
  relationships___image___internal___ignoreType = 'relationships___image___internal___ignoreType',
  relationships___image___internal___mediaType = 'relationships___image___internal___mediaType',
  relationships___image___internal___owner = 'relationships___image___internal___owner',
  relationships___image___internal___type = 'relationships___image___internal___type',
  relationships___available_for_devices = 'relationships___available_for_devices',
  relationships___available_for_devices___langcode = 'relationships___available_for_devices___langcode',
  relationships___available_for_devices___drupal_id = 'relationships___available_for_devices___drupal_id',
  relationships___available_for_devices___title = 'relationships___available_for_devices___title',
  relationships___available_for_devices___field_subtitle = 'relationships___available_for_devices___field_subtitle',
  relationships___available_for_devices___field_pl_description_in_cms___value = 'relationships___available_for_devices___field_pl_description_in_cms___value',
  relationships___available_for_devices___field_pl_is_online = 'relationships___available_for_devices___field_pl_is_online',
  relationships___available_for_devices___field_weight = 'relationships___available_for_devices___field_weight',
  relationships___available_for_devices___field_pl_strikethrough_price = 'relationships___available_for_devices___field_pl_strikethrough_price',
  relationships___available_for_devices___relationships___field_recommended_for_branches = 'relationships___available_for_devices___relationships___field_recommended_for_branches',
  relationships___available_for_devices___relationships___field_accessories = 'relationships___available_for_devices___relationships___field_accessories',
  relationships___available_for_devices___relationships___field_pl_cases = 'relationships___available_for_devices___relationships___field_pl_cases',
  relationships___available_for_devices___relationships___field_payment_methods = 'relationships___available_for_devices___relationships___field_payment_methods',
  relationships___available_for_devices___relationships___node__fiscalization = 'relationships___available_for_devices___relationships___node__fiscalization',
  relationships___available_for_devices___field_tooltip_text_for_branches = 'relationships___available_for_devices___field_tooltip_text_for_branches',
  relationships___available_for_devices___field_pl_is_virtual = 'relationships___available_for_devices___field_pl_is_virtual',
  relationships___available_for_devices___field_is_available = 'relationships___available_for_devices___field_is_available',
  relationships___available_for_devices___field_lease_strike_through_price = 'relationships___available_for_devices___field_lease_strike_through_price',
  relationships___available_for_devices___field_lease_allowed = 'relationships___available_for_devices___field_lease_allowed',
  relationships___available_for_devices___field_pl_price_list_name = 'relationships___available_for_devices___field_pl_price_list_name',
  relationships___available_for_devices___field_additional_price_lists = 'relationships___available_for_devices___field_additional_price_lists',
  relationships___available_for_devices___field_popup_body___value = 'relationships___available_for_devices___field_popup_body___value',
  relationships___available_for_devices___field_popup_button_back = 'relationships___available_for_devices___field_popup_button_back',
  relationships___available_for_devices___field_popup_button_ok = 'relationships___available_for_devices___field_popup_button_ok',
  relationships___available_for_devices___field_popup_enabled = 'relationships___available_for_devices___field_popup_enabled',
  relationships___available_for_devices___field_popup_title = 'relationships___available_for_devices___field_popup_title',
  relationships___available_for_devices___drupal_internal__nid = 'relationships___available_for_devices___drupal_internal__nid',
  relationships___available_for_devices___status = 'relationships___available_for_devices___status',
  relationships___available_for_devices___content_translation_source = 'relationships___available_for_devices___content_translation_source',
  relationships___available_for_devices___content_translation_outdated = 'relationships___available_for_devices___content_translation_outdated',
  relationships___available_for_devices___uuid = 'relationships___available_for_devices___uuid',
  relationships___available_for_devices___field_payment_methods = 'relationships___available_for_devices___field_payment_methods',
  relationships___available_for_devices___field_payment_methods___drupal_internal__target_id = 'relationships___available_for_devices___field_payment_methods___drupal_internal__target_id',
  relationships___available_for_devices___field_pl_cases = 'relationships___available_for_devices___field_pl_cases',
  relationships___available_for_devices___field_pl_cases___drupal_internal__target_id = 'relationships___available_for_devices___field_pl_cases___drupal_internal__target_id',
  relationships___available_for_devices___field_pl_image___alt = 'relationships___available_for_devices___field_pl_image___alt',
  relationships___available_for_devices___field_pl_image___title = 'relationships___available_for_devices___field_pl_image___title',
  relationships___available_for_devices___field_pl_image___width = 'relationships___available_for_devices___field_pl_image___width',
  relationships___available_for_devices___field_pl_image___height = 'relationships___available_for_devices___field_pl_image___height',
  relationships___available_for_devices___field_pl_image___drupal_internal__target_id = 'relationships___available_for_devices___field_pl_image___drupal_internal__target_id',
  relationships___available_for_devices___field_pl_image_for_accessories___alt = 'relationships___available_for_devices___field_pl_image_for_accessories___alt',
  relationships___available_for_devices___field_pl_image_for_accessories___title = 'relationships___available_for_devices___field_pl_image_for_accessories___title',
  relationships___available_for_devices___field_pl_image_for_accessories___width = 'relationships___available_for_devices___field_pl_image_for_accessories___width',
  relationships___available_for_devices___field_pl_image_for_accessories___height = 'relationships___available_for_devices___field_pl_image_for_accessories___height',
  relationships___available_for_devices___field_pl_image_for_accessories___drupal_internal__target_id = 'relationships___available_for_devices___field_pl_image_for_accessories___drupal_internal__target_id',
  relationships___available_for_devices___field_recommended_for_branches = 'relationships___available_for_devices___field_recommended_for_branches',
  relationships___available_for_devices___field_recommended_for_branches___drupal_internal__target_id = 'relationships___available_for_devices___field_recommended_for_branches___drupal_internal__target_id',
  relationships___available_for_devices___id = 'relationships___available_for_devices___id',
  relationships___available_for_devices___parent___id = 'relationships___available_for_devices___parent___id',
  relationships___available_for_devices___parent___children = 'relationships___available_for_devices___parent___children',
  relationships___available_for_devices___children = 'relationships___available_for_devices___children',
  relationships___available_for_devices___children___id = 'relationships___available_for_devices___children___id',
  relationships___available_for_devices___children___children = 'relationships___available_for_devices___children___children',
  relationships___available_for_devices___internal___content = 'relationships___available_for_devices___internal___content',
  relationships___available_for_devices___internal___contentDigest = 'relationships___available_for_devices___internal___contentDigest',
  relationships___available_for_devices___internal___description = 'relationships___available_for_devices___internal___description',
  relationships___available_for_devices___internal___fieldOwners = 'relationships___available_for_devices___internal___fieldOwners',
  relationships___available_for_devices___internal___ignoreType = 'relationships___available_for_devices___internal___ignoreType',
  relationships___available_for_devices___internal___mediaType = 'relationships___available_for_devices___internal___mediaType',
  relationships___available_for_devices___internal___owner = 'relationships___available_for_devices___internal___owner',
  relationships___available_for_devices___internal___type = 'relationships___available_for_devices___internal___type',
  drupal_internal__nid = 'drupal_internal__nid',
  drupal_internal__vid = 'drupal_internal__vid',
  status = 'status',
  created = 'created',
  changed = 'changed',
  weight = 'weight',
  uuid = 'uuid',
  available_for_devices = 'available_for_devices',
  available_for_devices___drupal_internal__target_id = 'available_for_devices___drupal_internal__target_id',
  image___alt = 'image___alt',
  image___title = 'image___title',
  image___width = 'image___width',
  image___height = 'image___height',
  image___drupal_internal__target_id = 'image___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__FiscalizationFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<Fiscalization_DescriptionFilterInput>;
  appointment_available?: Maybe<BooleanQueryOperatorInput>;
  is_free?: Maybe<BooleanQueryOperatorInput>;
  is_popular?: Maybe<BooleanQueryOperatorInput>;
  price_list_name?: Maybe<StringQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  calendar_text?: Maybe<Fiscalization_Calendar_TextFilterInput>;
  disabled_fiscalization_messsage?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__FiscalizationRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__vid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  available_for_devices?: Maybe<Node__FiscalizationAvailable_For_DevicesFilterListInput>;
  image?: Maybe<Node__FiscalizationImageFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__FiscalizationFilterListInput = {
  elemMatch?: Maybe<Node__FiscalizationFilterInput>;
};

export type Node__FiscalizationGroupConnection = {
  __typename?: 'node__fiscalizationGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__FiscalizationEdge>;
  nodes: Array<Node__Fiscalization>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__FiscalizationImage = {
  __typename?: 'node__fiscalizationImage';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__FiscalizationImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__FiscalizationRelationships = {
  __typename?: 'node__fiscalizationRelationships';
  image?: Maybe<File__File>;
  available_for_devices?: Maybe<Array<Maybe<Node__Devices>>>;
};

export type Node__FiscalizationRelationshipsFilterInput = {
  image?: Maybe<File__FileFilterInput>;
  available_for_devices?: Maybe<Node__DevicesFilterListInput>;
};

export type Node__FiscalizationSortInput = {
  fields?: Maybe<Array<Maybe<Node__FiscalizationFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__News = Node & {
  __typename?: 'node__news';
  langcode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  field_news_reading_time?: Maybe<Scalars['Int']>;
  field_news_body?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  path?: Maybe<Path>;
  status?: Maybe<Scalars['Boolean']>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__NewsRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  rh_redirect_fallback_action?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  field_meta_keywords?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_news_categories?: Maybe<Node__NewsField_News_Categories>;
  field_news_components?: Maybe<Array<Maybe<Node__NewsField_News_Components>>>;
  field_news_image?: Maybe<Node__NewsField_News_Image>;
  field_news_tags?: Maybe<Array<Maybe<Node__NewsField_News_Tags>>>;
  field_related_news_1?: Maybe<Node__NewsField_Related_News_1>;
  field_related_news_2?: Maybe<Node__NewsField_Related_News_2>;
  field_related_news_3?: Maybe<Node__NewsField_Related_News_3>;
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__NewsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Node__NewsConnection = {
  __typename?: 'node__newsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__NewsEdge>;
  nodes: Array<Node__News>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__NewsGroupConnection>;
};

export type Node__NewsConnectionDistinctArgs = {
  field: Node__NewsFieldsEnum;
};

export type Node__NewsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__NewsFieldsEnum;
};

export type Node__NewsEdge = {
  __typename?: 'node__newsEdge';
  next?: Maybe<Node__News>;
  node: Node__News;
  previous?: Maybe<Node__News>;
};

export type Node__NewsField_News_Categories = {
  __typename?: 'node__newsField_news_categories';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__NewsField_News_CategoriesFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__NewsField_News_Components = {
  __typename?: 'node__newsField_news_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__NewsField_News_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__NewsField_News_ComponentsFilterListInput = {
  elemMatch?: Maybe<Node__NewsField_News_ComponentsFilterInput>;
};

export type Node__NewsField_News_Image = {
  __typename?: 'node__newsField_news_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__NewsField_News_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__NewsField_News_Tags = {
  __typename?: 'node__newsField_news_tags';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__NewsField_News_TagsFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__NewsField_News_TagsFilterListInput = {
  elemMatch?: Maybe<Node__NewsField_News_TagsFilterInput>;
};

export type Node__NewsField_Related_News_1 = {
  __typename?: 'node__newsField_related_news_1';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__NewsField_Related_News_1FilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__NewsField_Related_News_2 = {
  __typename?: 'node__newsField_related_news_2';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__NewsField_Related_News_2FilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__NewsField_Related_News_3 = {
  __typename?: 'node__newsField_related_news_3';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__NewsField_Related_News_3FilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Node__NewsFieldsEnum {
  langcode = 'langcode',
  id = 'id',
  drupal_internal__nid = 'drupal_internal__nid',
  title = 'title',
  field_news_reading_time = 'field_news_reading_time',
  field_news_body = 'field_news_body',
  created = 'created',
  path___alias = 'path___alias',
  status = 'status',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  relationships___field_news_categories___name = 'relationships___field_news_categories___name',
  relationships___field_news_categories___drupal_id = 'relationships___field_news_categories___drupal_id',
  relationships___field_news_categories___drupal_internal__tid = 'relationships___field_news_categories___drupal_internal__tid',
  relationships___field_news_categories___drupal_internal__revision_id = 'relationships___field_news_categories___drupal_internal__revision_id',
  relationships___field_news_categories___langcode = 'relationships___field_news_categories___langcode',
  relationships___field_news_categories___revision_created = 'relationships___field_news_categories___revision_created',
  relationships___field_news_categories___status = 'relationships___field_news_categories___status',
  relationships___field_news_categories___weight = 'relationships___field_news_categories___weight',
  relationships___field_news_categories___changed = 'relationships___field_news_categories___changed',
  relationships___field_news_categories___default_langcode = 'relationships___field_news_categories___default_langcode',
  relationships___field_news_categories___revision_translation_affected = 'relationships___field_news_categories___revision_translation_affected',
  relationships___field_news_categories___rh_action = 'relationships___field_news_categories___rh_action',
  relationships___field_news_categories___rh_redirect_response = 'relationships___field_news_categories___rh_redirect_response',
  relationships___field_news_categories___content_translation_source = 'relationships___field_news_categories___content_translation_source',
  relationships___field_news_categories___content_translation_outdated = 'relationships___field_news_categories___content_translation_outdated',
  relationships___field_news_categories___uuid = 'relationships___field_news_categories___uuid',
  relationships___field_news_categories___relationships___node__news = 'relationships___field_news_categories___relationships___node__news',
  relationships___field_news_categories___vid___drupal_internal__target_id = 'relationships___field_news_categories___vid___drupal_internal__target_id',
  relationships___field_news_categories___id = 'relationships___field_news_categories___id',
  relationships___field_news_categories___parent___id = 'relationships___field_news_categories___parent___id',
  relationships___field_news_categories___parent___children = 'relationships___field_news_categories___parent___children',
  relationships___field_news_categories___children = 'relationships___field_news_categories___children',
  relationships___field_news_categories___children___id = 'relationships___field_news_categories___children___id',
  relationships___field_news_categories___children___children = 'relationships___field_news_categories___children___children',
  relationships___field_news_categories___internal___content = 'relationships___field_news_categories___internal___content',
  relationships___field_news_categories___internal___contentDigest = 'relationships___field_news_categories___internal___contentDigest',
  relationships___field_news_categories___internal___description = 'relationships___field_news_categories___internal___description',
  relationships___field_news_categories___internal___fieldOwners = 'relationships___field_news_categories___internal___fieldOwners',
  relationships___field_news_categories___internal___ignoreType = 'relationships___field_news_categories___internal___ignoreType',
  relationships___field_news_categories___internal___mediaType = 'relationships___field_news_categories___internal___mediaType',
  relationships___field_news_categories___internal___owner = 'relationships___field_news_categories___internal___owner',
  relationships___field_news_categories___internal___type = 'relationships___field_news_categories___internal___type',
  relationships___field_news_tags = 'relationships___field_news_tags',
  relationships___field_news_tags___name = 'relationships___field_news_tags___name',
  relationships___field_news_tags___drupal_id = 'relationships___field_news_tags___drupal_id',
  relationships___field_news_tags___drupal_internal__tid = 'relationships___field_news_tags___drupal_internal__tid',
  relationships___field_news_tags___drupal_internal__revision_id = 'relationships___field_news_tags___drupal_internal__revision_id',
  relationships___field_news_tags___langcode = 'relationships___field_news_tags___langcode',
  relationships___field_news_tags___revision_created = 'relationships___field_news_tags___revision_created',
  relationships___field_news_tags___status = 'relationships___field_news_tags___status',
  relationships___field_news_tags___weight = 'relationships___field_news_tags___weight',
  relationships___field_news_tags___changed = 'relationships___field_news_tags___changed',
  relationships___field_news_tags___default_langcode = 'relationships___field_news_tags___default_langcode',
  relationships___field_news_tags___revision_translation_affected = 'relationships___field_news_tags___revision_translation_affected',
  relationships___field_news_tags___rh_action = 'relationships___field_news_tags___rh_action',
  relationships___field_news_tags___rh_redirect_response = 'relationships___field_news_tags___rh_redirect_response',
  relationships___field_news_tags___rh_redirect_fallback_action = 'relationships___field_news_tags___rh_redirect_fallback_action',
  relationships___field_news_tags___content_translation_source = 'relationships___field_news_tags___content_translation_source',
  relationships___field_news_tags___content_translation_outdated = 'relationships___field_news_tags___content_translation_outdated',
  relationships___field_news_tags___content_translation_created = 'relationships___field_news_tags___content_translation_created',
  relationships___field_news_tags___uuid = 'relationships___field_news_tags___uuid',
  relationships___field_news_tags___relationships___node__news = 'relationships___field_news_tags___relationships___node__news',
  relationships___field_news_tags___relationships___parent = 'relationships___field_news_tags___relationships___parent',
  relationships___field_news_tags___relationships___taxonomy_term__news_tags = 'relationships___field_news_tags___relationships___taxonomy_term__news_tags',
  relationships___field_news_tags___vid___drupal_internal__target_id = 'relationships___field_news_tags___vid___drupal_internal__target_id',
  relationships___field_news_tags___id = 'relationships___field_news_tags___id',
  relationships___field_news_tags___parent___id = 'relationships___field_news_tags___parent___id',
  relationships___field_news_tags___parent___children = 'relationships___field_news_tags___parent___children',
  relationships___field_news_tags___children = 'relationships___field_news_tags___children',
  relationships___field_news_tags___children___id = 'relationships___field_news_tags___children___id',
  relationships___field_news_tags___children___children = 'relationships___field_news_tags___children___children',
  relationships___field_news_tags___internal___content = 'relationships___field_news_tags___internal___content',
  relationships___field_news_tags___internal___contentDigest = 'relationships___field_news_tags___internal___contentDigest',
  relationships___field_news_tags___internal___description = 'relationships___field_news_tags___internal___description',
  relationships___field_news_tags___internal___fieldOwners = 'relationships___field_news_tags___internal___fieldOwners',
  relationships___field_news_tags___internal___ignoreType = 'relationships___field_news_tags___internal___ignoreType',
  relationships___field_news_tags___internal___mediaType = 'relationships___field_news_tags___internal___mediaType',
  relationships___field_news_tags___internal___owner = 'relationships___field_news_tags___internal___owner',
  relationships___field_news_tags___internal___type = 'relationships___field_news_tags___internal___type',
  relationships___field_news_image___localFile___birthtime = 'relationships___field_news_image___localFile___birthtime',
  relationships___field_news_image___localFile___birthtimeMs = 'relationships___field_news_image___localFile___birthtimeMs',
  relationships___field_news_image___localFile___publicURL = 'relationships___field_news_image___localFile___publicURL',
  relationships___field_news_image___localFile___sourceInstanceName = 'relationships___field_news_image___localFile___sourceInstanceName',
  relationships___field_news_image___localFile___absolutePath = 'relationships___field_news_image___localFile___absolutePath',
  relationships___field_news_image___localFile___relativePath = 'relationships___field_news_image___localFile___relativePath',
  relationships___field_news_image___localFile___extension = 'relationships___field_news_image___localFile___extension',
  relationships___field_news_image___localFile___size = 'relationships___field_news_image___localFile___size',
  relationships___field_news_image___localFile___prettySize = 'relationships___field_news_image___localFile___prettySize',
  relationships___field_news_image___localFile___modifiedTime = 'relationships___field_news_image___localFile___modifiedTime',
  relationships___field_news_image___localFile___accessTime = 'relationships___field_news_image___localFile___accessTime',
  relationships___field_news_image___localFile___changeTime = 'relationships___field_news_image___localFile___changeTime',
  relationships___field_news_image___localFile___birthTime = 'relationships___field_news_image___localFile___birthTime',
  relationships___field_news_image___localFile___root = 'relationships___field_news_image___localFile___root',
  relationships___field_news_image___localFile___dir = 'relationships___field_news_image___localFile___dir',
  relationships___field_news_image___localFile___base = 'relationships___field_news_image___localFile___base',
  relationships___field_news_image___localFile___ext = 'relationships___field_news_image___localFile___ext',
  relationships___field_news_image___localFile___name = 'relationships___field_news_image___localFile___name',
  relationships___field_news_image___localFile___relativeDirectory = 'relationships___field_news_image___localFile___relativeDirectory',
  relationships___field_news_image___localFile___dev = 'relationships___field_news_image___localFile___dev',
  relationships___field_news_image___localFile___mode = 'relationships___field_news_image___localFile___mode',
  relationships___field_news_image___localFile___nlink = 'relationships___field_news_image___localFile___nlink',
  relationships___field_news_image___localFile___uid = 'relationships___field_news_image___localFile___uid',
  relationships___field_news_image___localFile___gid = 'relationships___field_news_image___localFile___gid',
  relationships___field_news_image___localFile___rdev = 'relationships___field_news_image___localFile___rdev',
  relationships___field_news_image___localFile___blksize = 'relationships___field_news_image___localFile___blksize',
  relationships___field_news_image___localFile___ino = 'relationships___field_news_image___localFile___ino',
  relationships___field_news_image___localFile___blocks = 'relationships___field_news_image___localFile___blocks',
  relationships___field_news_image___localFile___atimeMs = 'relationships___field_news_image___localFile___atimeMs',
  relationships___field_news_image___localFile___mtimeMs = 'relationships___field_news_image___localFile___mtimeMs',
  relationships___field_news_image___localFile___ctimeMs = 'relationships___field_news_image___localFile___ctimeMs',
  relationships___field_news_image___localFile___atime = 'relationships___field_news_image___localFile___atime',
  relationships___field_news_image___localFile___mtime = 'relationships___field_news_image___localFile___mtime',
  relationships___field_news_image___localFile___ctime = 'relationships___field_news_image___localFile___ctime',
  relationships___field_news_image___localFile___url = 'relationships___field_news_image___localFile___url',
  relationships___field_news_image___localFile___id = 'relationships___field_news_image___localFile___id',
  relationships___field_news_image___localFile___children = 'relationships___field_news_image___localFile___children',
  relationships___field_news_image___uri___url = 'relationships___field_news_image___uri___url',
  relationships___field_news_image___drupal_id = 'relationships___field_news_image___drupal_id',
  relationships___field_news_image___drupal_internal__fid = 'relationships___field_news_image___drupal_internal__fid',
  relationships___field_news_image___langcode = 'relationships___field_news_image___langcode',
  relationships___field_news_image___filename = 'relationships___field_news_image___filename',
  relationships___field_news_image___filemime = 'relationships___field_news_image___filemime',
  relationships___field_news_image___filesize = 'relationships___field_news_image___filesize',
  relationships___field_news_image___status = 'relationships___field_news_image___status',
  relationships___field_news_image___created = 'relationships___field_news_image___created',
  relationships___field_news_image___changed = 'relationships___field_news_image___changed',
  relationships___field_news_image___uuid = 'relationships___field_news_image___uuid',
  relationships___field_news_image___relationships___node__blog_post = 'relationships___field_news_image___relationships___node__blog_post',
  relationships___field_news_image___relationships___node__devices = 'relationships___field_news_image___relationships___node__devices',
  relationships___field_news_image___relationships___node__accessories = 'relationships___field_news_image___relationships___node__accessories',
  relationships___field_news_image___relationships___paragraph__news_image_title_text = 'relationships___field_news_image___relationships___paragraph__news_image_title_text',
  relationships___field_news_image___relationships___node__cases = 'relationships___field_news_image___relationships___node__cases',
  relationships___field_news_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_news_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_news_image___relationships___paragraph__branches_features = 'relationships___field_news_image___relationships___paragraph__branches_features',
  relationships___field_news_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_news_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_news_image___relationships___paragraph__branches_testimonials = 'relationships___field_news_image___relationships___paragraph__branches_testimonials',
  relationships___field_news_image___relationships___paragraph__branches_discounts = 'relationships___field_news_image___relationships___paragraph__branches_discounts',
  relationships___field_news_image___relationships___node__news = 'relationships___field_news_image___relationships___node__news',
  relationships___field_news_image___relationships___node__branches = 'relationships___field_news_image___relationships___node__branches',
  relationships___field_news_image___relationships___paragraph__branches_hero = 'relationships___field_news_image___relationships___paragraph__branches_hero',
  relationships___field_news_image___relationships___paragraph__bullets = 'relationships___field_news_image___relationships___paragraph__bullets',
  relationships___field_news_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_news_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_news_image___relationships___paragraph__branches_device = 'relationships___field_news_image___relationships___paragraph__branches_device',
  relationships___field_news_image___relationships___paragraph__branches_recommendation = 'relationships___field_news_image___relationships___paragraph__branches_recommendation',
  relationships___field_news_image___relationships___site_setting_entity__accessories = 'relationships___field_news_image___relationships___site_setting_entity__accessories',
  relationships___field_news_image___relationships___site_setting_entity__branches = 'relationships___field_news_image___relationships___site_setting_entity__branches',
  relationships___field_news_image___relationships___site_setting_entity__about_us = 'relationships___field_news_image___relationships___site_setting_entity__about_us',
  relationships___field_news_image___relationships___paragraph__logos = 'relationships___field_news_image___relationships___paragraph__logos',
  relationships___field_news_image___relationships___paragraph__how_it_works = 'relationships___field_news_image___relationships___paragraph__how_it_works',
  relationships___field_news_image___relationships___paragraph__ico_and_text = 'relationships___field_news_image___relationships___paragraph__ico_and_text',
  relationships___field_news_image___relationships___paragraph__hero_1 = 'relationships___field_news_image___relationships___paragraph__hero_1',
  relationships___field_news_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_news_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_news_image___relationships___site_setting_entity__product = 'relationships___field_news_image___relationships___site_setting_entity__product',
  relationships___field_news_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_news_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_news_image___relationships___paragraph__porownanie_terminali = 'relationships___field_news_image___relationships___paragraph__porownanie_terminali',
  relationships___field_news_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_news_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_news_image___relationships___site_setting_entity__main_page = 'relationships___field_news_image___relationships___site_setting_entity__main_page',
  relationships___field_news_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_news_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_news_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_news_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_news_image___relationships___site_setting_entity__intercars = 'relationships___field_news_image___relationships___site_setting_entity__intercars',
  relationships___field_news_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_news_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_news_image___relationships___node__agreement = 'relationships___field_news_image___relationships___node__agreement',
  relationships___field_news_image___relationships___node__fiscalization = 'relationships___field_news_image___relationships___node__fiscalization',
  relationships___field_news_image___relationships___site_setting_entity__configurator = 'relationships___field_news_image___relationships___site_setting_entity__configurator',
  relationships___field_news_image___relationships___paragraph__social_link = 'relationships___field_news_image___relationships___paragraph__social_link',
  relationships___field_news_image___relationships___paragraph__make_an_appointment = 'relationships___field_news_image___relationships___paragraph__make_an_appointment',
  relationships___field_news_image___relationships___paragraph__linked_icons = 'relationships___field_news_image___relationships___paragraph__linked_icons',
  relationships___field_news_image___relationships___site_setting_entity__financing = 'relationships___field_news_image___relationships___site_setting_entity__financing',
  relationships___field_news_image___relationships___site_setting_entity__franchise = 'relationships___field_news_image___relationships___site_setting_entity__franchise',
  relationships___field_news_image___id = 'relationships___field_news_image___id',
  relationships___field_news_image___parent___id = 'relationships___field_news_image___parent___id',
  relationships___field_news_image___parent___children = 'relationships___field_news_image___parent___children',
  relationships___field_news_image___children = 'relationships___field_news_image___children',
  relationships___field_news_image___children___id = 'relationships___field_news_image___children___id',
  relationships___field_news_image___children___children = 'relationships___field_news_image___children___children',
  relationships___field_news_image___internal___content = 'relationships___field_news_image___internal___content',
  relationships___field_news_image___internal___contentDigest = 'relationships___field_news_image___internal___contentDigest',
  relationships___field_news_image___internal___description = 'relationships___field_news_image___internal___description',
  relationships___field_news_image___internal___fieldOwners = 'relationships___field_news_image___internal___fieldOwners',
  relationships___field_news_image___internal___ignoreType = 'relationships___field_news_image___internal___ignoreType',
  relationships___field_news_image___internal___mediaType = 'relationships___field_news_image___internal___mediaType',
  relationships___field_news_image___internal___owner = 'relationships___field_news_image___internal___owner',
  relationships___field_news_image___internal___type = 'relationships___field_news_image___internal___type',
  relationships___field_related_news_1___langcode = 'relationships___field_related_news_1___langcode',
  relationships___field_related_news_1___id = 'relationships___field_related_news_1___id',
  relationships___field_related_news_1___drupal_internal__nid = 'relationships___field_related_news_1___drupal_internal__nid',
  relationships___field_related_news_1___title = 'relationships___field_related_news_1___title',
  relationships___field_related_news_1___field_news_reading_time = 'relationships___field_related_news_1___field_news_reading_time',
  relationships___field_related_news_1___field_news_body = 'relationships___field_related_news_1___field_news_body',
  relationships___field_related_news_1___created = 'relationships___field_related_news_1___created',
  relationships___field_related_news_1___path___alias = 'relationships___field_related_news_1___path___alias',
  relationships___field_related_news_1___status = 'relationships___field_related_news_1___status',
  relationships___field_related_news_1___field_meta_description = 'relationships___field_related_news_1___field_meta_description',
  relationships___field_related_news_1___field_meta_title = 'relationships___field_related_news_1___field_meta_title',
  relationships___field_related_news_1___relationships___field_news_tags = 'relationships___field_related_news_1___relationships___field_news_tags',
  relationships___field_related_news_1___relationships___node__news = 'relationships___field_related_news_1___relationships___node__news',
  relationships___field_related_news_1___relationships___paragraph__recommended_blog_posts = 'relationships___field_related_news_1___relationships___paragraph__recommended_blog_posts',
  relationships___field_related_news_1___drupal_id = 'relationships___field_related_news_1___drupal_id',
  relationships___field_related_news_1___rh_redirect_fallback_action = 'relationships___field_related_news_1___rh_redirect_fallback_action',
  relationships___field_related_news_1___content_translation_source = 'relationships___field_related_news_1___content_translation_source',
  relationships___field_related_news_1___content_translation_outdated = 'relationships___field_related_news_1___content_translation_outdated',
  relationships___field_related_news_1___field_meta_keywords = 'relationships___field_related_news_1___field_meta_keywords',
  relationships___field_related_news_1___uuid = 'relationships___field_related_news_1___uuid',
  relationships___field_related_news_1___field_news_categories___drupal_internal__target_id = 'relationships___field_related_news_1___field_news_categories___drupal_internal__target_id',
  relationships___field_related_news_1___field_news_components = 'relationships___field_related_news_1___field_news_components',
  relationships___field_related_news_1___field_news_components___target_revision_id = 'relationships___field_related_news_1___field_news_components___target_revision_id',
  relationships___field_related_news_1___field_news_components___drupal_internal__target_id = 'relationships___field_related_news_1___field_news_components___drupal_internal__target_id',
  relationships___field_related_news_1___field_news_image___alt = 'relationships___field_related_news_1___field_news_image___alt',
  relationships___field_related_news_1___field_news_image___title = 'relationships___field_related_news_1___field_news_image___title',
  relationships___field_related_news_1___field_news_image___width = 'relationships___field_related_news_1___field_news_image___width',
  relationships___field_related_news_1___field_news_image___height = 'relationships___field_related_news_1___field_news_image___height',
  relationships___field_related_news_1___field_news_image___drupal_internal__target_id = 'relationships___field_related_news_1___field_news_image___drupal_internal__target_id',
  relationships___field_related_news_1___field_news_tags = 'relationships___field_related_news_1___field_news_tags',
  relationships___field_related_news_1___field_news_tags___drupal_internal__target_id = 'relationships___field_related_news_1___field_news_tags___drupal_internal__target_id',
  relationships___field_related_news_1___field_related_news_1___drupal_internal__target_id = 'relationships___field_related_news_1___field_related_news_1___drupal_internal__target_id',
  relationships___field_related_news_1___field_related_news_2___drupal_internal__target_id = 'relationships___field_related_news_1___field_related_news_2___drupal_internal__target_id',
  relationships___field_related_news_1___field_related_news_3___drupal_internal__target_id = 'relationships___field_related_news_1___field_related_news_3___drupal_internal__target_id',
  relationships___field_related_news_1___parent___id = 'relationships___field_related_news_1___parent___id',
  relationships___field_related_news_1___parent___children = 'relationships___field_related_news_1___parent___children',
  relationships___field_related_news_1___children = 'relationships___field_related_news_1___children',
  relationships___field_related_news_1___children___id = 'relationships___field_related_news_1___children___id',
  relationships___field_related_news_1___children___children = 'relationships___field_related_news_1___children___children',
  relationships___field_related_news_1___internal___content = 'relationships___field_related_news_1___internal___content',
  relationships___field_related_news_1___internal___contentDigest = 'relationships___field_related_news_1___internal___contentDigest',
  relationships___field_related_news_1___internal___description = 'relationships___field_related_news_1___internal___description',
  relationships___field_related_news_1___internal___fieldOwners = 'relationships___field_related_news_1___internal___fieldOwners',
  relationships___field_related_news_1___internal___ignoreType = 'relationships___field_related_news_1___internal___ignoreType',
  relationships___field_related_news_1___internal___mediaType = 'relationships___field_related_news_1___internal___mediaType',
  relationships___field_related_news_1___internal___owner = 'relationships___field_related_news_1___internal___owner',
  relationships___field_related_news_1___internal___type = 'relationships___field_related_news_1___internal___type',
  relationships___field_related_news_2___langcode = 'relationships___field_related_news_2___langcode',
  relationships___field_related_news_2___id = 'relationships___field_related_news_2___id',
  relationships___field_related_news_2___drupal_internal__nid = 'relationships___field_related_news_2___drupal_internal__nid',
  relationships___field_related_news_2___title = 'relationships___field_related_news_2___title',
  relationships___field_related_news_2___field_news_reading_time = 'relationships___field_related_news_2___field_news_reading_time',
  relationships___field_related_news_2___field_news_body = 'relationships___field_related_news_2___field_news_body',
  relationships___field_related_news_2___created = 'relationships___field_related_news_2___created',
  relationships___field_related_news_2___path___alias = 'relationships___field_related_news_2___path___alias',
  relationships___field_related_news_2___status = 'relationships___field_related_news_2___status',
  relationships___field_related_news_2___field_meta_description = 'relationships___field_related_news_2___field_meta_description',
  relationships___field_related_news_2___field_meta_title = 'relationships___field_related_news_2___field_meta_title',
  relationships___field_related_news_2___relationships___field_news_tags = 'relationships___field_related_news_2___relationships___field_news_tags',
  relationships___field_related_news_2___relationships___node__news = 'relationships___field_related_news_2___relationships___node__news',
  relationships___field_related_news_2___relationships___paragraph__recommended_blog_posts = 'relationships___field_related_news_2___relationships___paragraph__recommended_blog_posts',
  relationships___field_related_news_2___drupal_id = 'relationships___field_related_news_2___drupal_id',
  relationships___field_related_news_2___rh_redirect_fallback_action = 'relationships___field_related_news_2___rh_redirect_fallback_action',
  relationships___field_related_news_2___content_translation_source = 'relationships___field_related_news_2___content_translation_source',
  relationships___field_related_news_2___content_translation_outdated = 'relationships___field_related_news_2___content_translation_outdated',
  relationships___field_related_news_2___field_meta_keywords = 'relationships___field_related_news_2___field_meta_keywords',
  relationships___field_related_news_2___uuid = 'relationships___field_related_news_2___uuid',
  relationships___field_related_news_2___field_news_categories___drupal_internal__target_id = 'relationships___field_related_news_2___field_news_categories___drupal_internal__target_id',
  relationships___field_related_news_2___field_news_components = 'relationships___field_related_news_2___field_news_components',
  relationships___field_related_news_2___field_news_components___target_revision_id = 'relationships___field_related_news_2___field_news_components___target_revision_id',
  relationships___field_related_news_2___field_news_components___drupal_internal__target_id = 'relationships___field_related_news_2___field_news_components___drupal_internal__target_id',
  relationships___field_related_news_2___field_news_image___alt = 'relationships___field_related_news_2___field_news_image___alt',
  relationships___field_related_news_2___field_news_image___title = 'relationships___field_related_news_2___field_news_image___title',
  relationships___field_related_news_2___field_news_image___width = 'relationships___field_related_news_2___field_news_image___width',
  relationships___field_related_news_2___field_news_image___height = 'relationships___field_related_news_2___field_news_image___height',
  relationships___field_related_news_2___field_news_image___drupal_internal__target_id = 'relationships___field_related_news_2___field_news_image___drupal_internal__target_id',
  relationships___field_related_news_2___field_news_tags = 'relationships___field_related_news_2___field_news_tags',
  relationships___field_related_news_2___field_news_tags___drupal_internal__target_id = 'relationships___field_related_news_2___field_news_tags___drupal_internal__target_id',
  relationships___field_related_news_2___field_related_news_1___drupal_internal__target_id = 'relationships___field_related_news_2___field_related_news_1___drupal_internal__target_id',
  relationships___field_related_news_2___field_related_news_2___drupal_internal__target_id = 'relationships___field_related_news_2___field_related_news_2___drupal_internal__target_id',
  relationships___field_related_news_2___field_related_news_3___drupal_internal__target_id = 'relationships___field_related_news_2___field_related_news_3___drupal_internal__target_id',
  relationships___field_related_news_2___parent___id = 'relationships___field_related_news_2___parent___id',
  relationships___field_related_news_2___parent___children = 'relationships___field_related_news_2___parent___children',
  relationships___field_related_news_2___children = 'relationships___field_related_news_2___children',
  relationships___field_related_news_2___children___id = 'relationships___field_related_news_2___children___id',
  relationships___field_related_news_2___children___children = 'relationships___field_related_news_2___children___children',
  relationships___field_related_news_2___internal___content = 'relationships___field_related_news_2___internal___content',
  relationships___field_related_news_2___internal___contentDigest = 'relationships___field_related_news_2___internal___contentDigest',
  relationships___field_related_news_2___internal___description = 'relationships___field_related_news_2___internal___description',
  relationships___field_related_news_2___internal___fieldOwners = 'relationships___field_related_news_2___internal___fieldOwners',
  relationships___field_related_news_2___internal___ignoreType = 'relationships___field_related_news_2___internal___ignoreType',
  relationships___field_related_news_2___internal___mediaType = 'relationships___field_related_news_2___internal___mediaType',
  relationships___field_related_news_2___internal___owner = 'relationships___field_related_news_2___internal___owner',
  relationships___field_related_news_2___internal___type = 'relationships___field_related_news_2___internal___type',
  relationships___field_related_news_3___langcode = 'relationships___field_related_news_3___langcode',
  relationships___field_related_news_3___id = 'relationships___field_related_news_3___id',
  relationships___field_related_news_3___drupal_internal__nid = 'relationships___field_related_news_3___drupal_internal__nid',
  relationships___field_related_news_3___title = 'relationships___field_related_news_3___title',
  relationships___field_related_news_3___field_news_reading_time = 'relationships___field_related_news_3___field_news_reading_time',
  relationships___field_related_news_3___field_news_body = 'relationships___field_related_news_3___field_news_body',
  relationships___field_related_news_3___created = 'relationships___field_related_news_3___created',
  relationships___field_related_news_3___path___alias = 'relationships___field_related_news_3___path___alias',
  relationships___field_related_news_3___status = 'relationships___field_related_news_3___status',
  relationships___field_related_news_3___field_meta_description = 'relationships___field_related_news_3___field_meta_description',
  relationships___field_related_news_3___field_meta_title = 'relationships___field_related_news_3___field_meta_title',
  relationships___field_related_news_3___relationships___field_news_tags = 'relationships___field_related_news_3___relationships___field_news_tags',
  relationships___field_related_news_3___relationships___node__news = 'relationships___field_related_news_3___relationships___node__news',
  relationships___field_related_news_3___relationships___paragraph__recommended_blog_posts = 'relationships___field_related_news_3___relationships___paragraph__recommended_blog_posts',
  relationships___field_related_news_3___drupal_id = 'relationships___field_related_news_3___drupal_id',
  relationships___field_related_news_3___rh_redirect_fallback_action = 'relationships___field_related_news_3___rh_redirect_fallback_action',
  relationships___field_related_news_3___content_translation_source = 'relationships___field_related_news_3___content_translation_source',
  relationships___field_related_news_3___content_translation_outdated = 'relationships___field_related_news_3___content_translation_outdated',
  relationships___field_related_news_3___field_meta_keywords = 'relationships___field_related_news_3___field_meta_keywords',
  relationships___field_related_news_3___uuid = 'relationships___field_related_news_3___uuid',
  relationships___field_related_news_3___field_news_categories___drupal_internal__target_id = 'relationships___field_related_news_3___field_news_categories___drupal_internal__target_id',
  relationships___field_related_news_3___field_news_components = 'relationships___field_related_news_3___field_news_components',
  relationships___field_related_news_3___field_news_components___target_revision_id = 'relationships___field_related_news_3___field_news_components___target_revision_id',
  relationships___field_related_news_3___field_news_components___drupal_internal__target_id = 'relationships___field_related_news_3___field_news_components___drupal_internal__target_id',
  relationships___field_related_news_3___field_news_image___alt = 'relationships___field_related_news_3___field_news_image___alt',
  relationships___field_related_news_3___field_news_image___title = 'relationships___field_related_news_3___field_news_image___title',
  relationships___field_related_news_3___field_news_image___width = 'relationships___field_related_news_3___field_news_image___width',
  relationships___field_related_news_3___field_news_image___height = 'relationships___field_related_news_3___field_news_image___height',
  relationships___field_related_news_3___field_news_image___drupal_internal__target_id = 'relationships___field_related_news_3___field_news_image___drupal_internal__target_id',
  relationships___field_related_news_3___field_news_tags = 'relationships___field_related_news_3___field_news_tags',
  relationships___field_related_news_3___field_news_tags___drupal_internal__target_id = 'relationships___field_related_news_3___field_news_tags___drupal_internal__target_id',
  relationships___field_related_news_3___field_related_news_1___drupal_internal__target_id = 'relationships___field_related_news_3___field_related_news_1___drupal_internal__target_id',
  relationships___field_related_news_3___field_related_news_2___drupal_internal__target_id = 'relationships___field_related_news_3___field_related_news_2___drupal_internal__target_id',
  relationships___field_related_news_3___field_related_news_3___drupal_internal__target_id = 'relationships___field_related_news_3___field_related_news_3___drupal_internal__target_id',
  relationships___field_related_news_3___parent___id = 'relationships___field_related_news_3___parent___id',
  relationships___field_related_news_3___parent___children = 'relationships___field_related_news_3___parent___children',
  relationships___field_related_news_3___children = 'relationships___field_related_news_3___children',
  relationships___field_related_news_3___children___id = 'relationships___field_related_news_3___children___id',
  relationships___field_related_news_3___children___children = 'relationships___field_related_news_3___children___children',
  relationships___field_related_news_3___internal___content = 'relationships___field_related_news_3___internal___content',
  relationships___field_related_news_3___internal___contentDigest = 'relationships___field_related_news_3___internal___contentDigest',
  relationships___field_related_news_3___internal___description = 'relationships___field_related_news_3___internal___description',
  relationships___field_related_news_3___internal___fieldOwners = 'relationships___field_related_news_3___internal___fieldOwners',
  relationships___field_related_news_3___internal___ignoreType = 'relationships___field_related_news_3___internal___ignoreType',
  relationships___field_related_news_3___internal___mediaType = 'relationships___field_related_news_3___internal___mediaType',
  relationships___field_related_news_3___internal___owner = 'relationships___field_related_news_3___internal___owner',
  relationships___field_related_news_3___internal___type = 'relationships___field_related_news_3___internal___type',
  relationships___node__news = 'relationships___node__news',
  relationships___node__news___langcode = 'relationships___node__news___langcode',
  relationships___node__news___id = 'relationships___node__news___id',
  relationships___node__news___drupal_internal__nid = 'relationships___node__news___drupal_internal__nid',
  relationships___node__news___title = 'relationships___node__news___title',
  relationships___node__news___field_news_reading_time = 'relationships___node__news___field_news_reading_time',
  relationships___node__news___field_news_body = 'relationships___node__news___field_news_body',
  relationships___node__news___created = 'relationships___node__news___created',
  relationships___node__news___path___alias = 'relationships___node__news___path___alias',
  relationships___node__news___status = 'relationships___node__news___status',
  relationships___node__news___field_meta_description = 'relationships___node__news___field_meta_description',
  relationships___node__news___field_meta_title = 'relationships___node__news___field_meta_title',
  relationships___node__news___relationships___field_news_tags = 'relationships___node__news___relationships___field_news_tags',
  relationships___node__news___relationships___node__news = 'relationships___node__news___relationships___node__news',
  relationships___node__news___relationships___paragraph__recommended_blog_posts = 'relationships___node__news___relationships___paragraph__recommended_blog_posts',
  relationships___node__news___drupal_id = 'relationships___node__news___drupal_id',
  relationships___node__news___rh_redirect_fallback_action = 'relationships___node__news___rh_redirect_fallback_action',
  relationships___node__news___content_translation_source = 'relationships___node__news___content_translation_source',
  relationships___node__news___content_translation_outdated = 'relationships___node__news___content_translation_outdated',
  relationships___node__news___field_meta_keywords = 'relationships___node__news___field_meta_keywords',
  relationships___node__news___uuid = 'relationships___node__news___uuid',
  relationships___node__news___field_news_categories___drupal_internal__target_id = 'relationships___node__news___field_news_categories___drupal_internal__target_id',
  relationships___node__news___field_news_components = 'relationships___node__news___field_news_components',
  relationships___node__news___field_news_components___target_revision_id = 'relationships___node__news___field_news_components___target_revision_id',
  relationships___node__news___field_news_components___drupal_internal__target_id = 'relationships___node__news___field_news_components___drupal_internal__target_id',
  relationships___node__news___field_news_image___alt = 'relationships___node__news___field_news_image___alt',
  relationships___node__news___field_news_image___title = 'relationships___node__news___field_news_image___title',
  relationships___node__news___field_news_image___width = 'relationships___node__news___field_news_image___width',
  relationships___node__news___field_news_image___height = 'relationships___node__news___field_news_image___height',
  relationships___node__news___field_news_image___drupal_internal__target_id = 'relationships___node__news___field_news_image___drupal_internal__target_id',
  relationships___node__news___field_news_tags = 'relationships___node__news___field_news_tags',
  relationships___node__news___field_news_tags___drupal_internal__target_id = 'relationships___node__news___field_news_tags___drupal_internal__target_id',
  relationships___node__news___field_related_news_1___drupal_internal__target_id = 'relationships___node__news___field_related_news_1___drupal_internal__target_id',
  relationships___node__news___field_related_news_2___drupal_internal__target_id = 'relationships___node__news___field_related_news_2___drupal_internal__target_id',
  relationships___node__news___field_related_news_3___drupal_internal__target_id = 'relationships___node__news___field_related_news_3___drupal_internal__target_id',
  relationships___node__news___parent___id = 'relationships___node__news___parent___id',
  relationships___node__news___parent___children = 'relationships___node__news___parent___children',
  relationships___node__news___children = 'relationships___node__news___children',
  relationships___node__news___children___id = 'relationships___node__news___children___id',
  relationships___node__news___children___children = 'relationships___node__news___children___children',
  relationships___node__news___internal___content = 'relationships___node__news___internal___content',
  relationships___node__news___internal___contentDigest = 'relationships___node__news___internal___contentDigest',
  relationships___node__news___internal___description = 'relationships___node__news___internal___description',
  relationships___node__news___internal___fieldOwners = 'relationships___node__news___internal___fieldOwners',
  relationships___node__news___internal___ignoreType = 'relationships___node__news___internal___ignoreType',
  relationships___node__news___internal___mediaType = 'relationships___node__news___internal___mediaType',
  relationships___node__news___internal___owner = 'relationships___node__news___internal___owner',
  relationships___node__news___internal___type = 'relationships___node__news___internal___type',
  relationships___paragraph__recommended_blog_posts = 'relationships___paragraph__recommended_blog_posts',
  relationships___paragraph__recommended_blog_posts___id = 'relationships___paragraph__recommended_blog_posts___id',
  relationships___paragraph__recommended_blog_posts___internal___content = 'relationships___paragraph__recommended_blog_posts___internal___content',
  relationships___paragraph__recommended_blog_posts___internal___contentDigest = 'relationships___paragraph__recommended_blog_posts___internal___contentDigest',
  relationships___paragraph__recommended_blog_posts___internal___description = 'relationships___paragraph__recommended_blog_posts___internal___description',
  relationships___paragraph__recommended_blog_posts___internal___fieldOwners = 'relationships___paragraph__recommended_blog_posts___internal___fieldOwners',
  relationships___paragraph__recommended_blog_posts___internal___ignoreType = 'relationships___paragraph__recommended_blog_posts___internal___ignoreType',
  relationships___paragraph__recommended_blog_posts___internal___mediaType = 'relationships___paragraph__recommended_blog_posts___internal___mediaType',
  relationships___paragraph__recommended_blog_posts___internal___owner = 'relationships___paragraph__recommended_blog_posts___internal___owner',
  relationships___paragraph__recommended_blog_posts___internal___type = 'relationships___paragraph__recommended_blog_posts___internal___type',
  relationships___paragraph__recommended_blog_posts___drupal_id = 'relationships___paragraph__recommended_blog_posts___drupal_id',
  relationships___paragraph__recommended_blog_posts___field_headline = 'relationships___paragraph__recommended_blog_posts___field_headline',
  relationships___paragraph__recommended_blog_posts___field_read_more_button_text = 'relationships___paragraph__recommended_blog_posts___field_read_more_button_text',
  relationships___paragraph__recommended_blog_posts___relationships___field_blog_posts = 'relationships___paragraph__recommended_blog_posts___relationships___field_blog_posts',
  relationships___paragraph__recommended_blog_posts___relationships___site_setting_entity__main_page = 'relationships___paragraph__recommended_blog_posts___relationships___site_setting_entity__main_page',
  relationships___paragraph__recommended_blog_posts___drupal_internal__id = 'relationships___paragraph__recommended_blog_posts___drupal_internal__id',
  relationships___paragraph__recommended_blog_posts___status = 'relationships___paragraph__recommended_blog_posts___status',
  relationships___paragraph__recommended_blog_posts___created = 'relationships___paragraph__recommended_blog_posts___created',
  relationships___paragraph__recommended_blog_posts___parent_id = 'relationships___paragraph__recommended_blog_posts___parent_id',
  relationships___paragraph__recommended_blog_posts___parent_type = 'relationships___paragraph__recommended_blog_posts___parent_type',
  relationships___paragraph__recommended_blog_posts___parent_field_name = 'relationships___paragraph__recommended_blog_posts___parent_field_name',
  relationships___paragraph__recommended_blog_posts___content_translation_source = 'relationships___paragraph__recommended_blog_posts___content_translation_source',
  relationships___paragraph__recommended_blog_posts___content_translation_outdated = 'relationships___paragraph__recommended_blog_posts___content_translation_outdated',
  relationships___paragraph__recommended_blog_posts___content_translation_changed = 'relationships___paragraph__recommended_blog_posts___content_translation_changed',
  relationships___paragraph__recommended_blog_posts___langcode = 'relationships___paragraph__recommended_blog_posts___langcode',
  relationships___paragraph__recommended_blog_posts___uuid = 'relationships___paragraph__recommended_blog_posts___uuid',
  relationships___paragraph__recommended_blog_posts___field_blog_posts = 'relationships___paragraph__recommended_blog_posts___field_blog_posts',
  relationships___paragraph__recommended_blog_posts___field_blog_posts___drupal_internal__target_id = 'relationships___paragraph__recommended_blog_posts___field_blog_posts___drupal_internal__target_id',
  relationships___paragraph__recommended_blog_posts___parent___id = 'relationships___paragraph__recommended_blog_posts___parent___id',
  relationships___paragraph__recommended_blog_posts___parent___children = 'relationships___paragraph__recommended_blog_posts___parent___children',
  relationships___paragraph__recommended_blog_posts___children = 'relationships___paragraph__recommended_blog_posts___children',
  relationships___paragraph__recommended_blog_posts___children___id = 'relationships___paragraph__recommended_blog_posts___children___id',
  relationships___paragraph__recommended_blog_posts___children___children = 'relationships___paragraph__recommended_blog_posts___children___children',
  drupal_id = 'drupal_id',
  rh_redirect_fallback_action = 'rh_redirect_fallback_action',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  field_meta_keywords = 'field_meta_keywords',
  uuid = 'uuid',
  field_news_categories___drupal_internal__target_id = 'field_news_categories___drupal_internal__target_id',
  field_news_components = 'field_news_components',
  field_news_components___target_revision_id = 'field_news_components___target_revision_id',
  field_news_components___drupal_internal__target_id = 'field_news_components___drupal_internal__target_id',
  field_news_image___alt = 'field_news_image___alt',
  field_news_image___title = 'field_news_image___title',
  field_news_image___width = 'field_news_image___width',
  field_news_image___height = 'field_news_image___height',
  field_news_image___drupal_internal__target_id = 'field_news_image___drupal_internal__target_id',
  field_news_tags = 'field_news_tags',
  field_news_tags___drupal_internal__target_id = 'field_news_tags___drupal_internal__target_id',
  field_related_news_1___drupal_internal__target_id = 'field_related_news_1___drupal_internal__target_id',
  field_related_news_2___drupal_internal__target_id = 'field_related_news_2___drupal_internal__target_id',
  field_related_news_3___drupal_internal__target_id = 'field_related_news_3___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__NewsFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_news_reading_time?: Maybe<IntQueryOperatorInput>;
  field_news_body?: Maybe<StringQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  path?: Maybe<PathFilterInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__NewsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  rh_redirect_fallback_action?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  field_meta_keywords?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_news_categories?: Maybe<Node__NewsField_News_CategoriesFilterInput>;
  field_news_components?: Maybe<Node__NewsField_News_ComponentsFilterListInput>;
  field_news_image?: Maybe<Node__NewsField_News_ImageFilterInput>;
  field_news_tags?: Maybe<Node__NewsField_News_TagsFilterListInput>;
  field_related_news_1?: Maybe<Node__NewsField_Related_News_1FilterInput>;
  field_related_news_2?: Maybe<Node__NewsField_Related_News_2FilterInput>;
  field_related_news_3?: Maybe<Node__NewsField_Related_News_3FilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__NewsFilterListInput = {
  elemMatch?: Maybe<Node__NewsFilterInput>;
};

export type Node__NewsGroupConnection = {
  __typename?: 'node__newsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__NewsEdge>;
  nodes: Array<Node__News>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__NewsRelationships = {
  __typename?: 'node__newsRelationships';
  field_news_categories?: Maybe<Taxonomy_Term__News_Categories>;
  field_news_tags?: Maybe<Array<Maybe<Taxonomy_Term__News_Tags>>>;
  field_news_image?: Maybe<File__File>;
  field_news_components?: Maybe<Array<Maybe<NewsRelatedComponents>>>;
  field_related_news_1?: Maybe<Node__News>;
  field_related_news_2?: Maybe<Node__News>;
  field_related_news_3?: Maybe<Node__News>;
  node__news?: Maybe<Array<Maybe<Node__News>>>;
  paragraph__recommended_blog_posts?: Maybe<Array<Maybe<Paragraph__Recommended_Blog_Posts>>>;
};

export type Node__NewsRelationshipsFilterInput = {
  field_news_categories?: Maybe<Taxonomy_Term__News_CategoriesFilterInput>;
  field_news_tags?: Maybe<Taxonomy_Term__News_TagsFilterListInput>;
  field_news_image?: Maybe<File__FileFilterInput>;
  field_related_news_1?: Maybe<Node__NewsFilterInput>;
  field_related_news_2?: Maybe<Node__NewsFilterInput>;
  field_related_news_3?: Maybe<Node__NewsFilterInput>;
  node__news?: Maybe<Node__NewsFilterListInput>;
  paragraph__recommended_blog_posts?: Maybe<Paragraph__Recommended_Blog_PostsFilterListInput>;
};

export type Node__NewsSortInput = {
  fields?: Maybe<Array<Maybe<Node__NewsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Pages = Node & {
  __typename?: 'node__pages';
  langcode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  field_pages_text?: Maybe<Field_Pages_Text>;
  path?: Maybe<Path>;
  field_meta_title?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__PagesRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  drupal_internal__vid?: Maybe<Scalars['Int']>;
  rh_action?: Maybe<Scalars['String']>;
  rh_redirect_response?: Maybe<Scalars['Int']>;
  rh_redirect_fallback_action?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_keywords?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_components?: Maybe<Array<Maybe<Node__PagesField_Components>>>;
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__PagesConnection = {
  __typename?: 'node__pagesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__PagesEdge>;
  nodes: Array<Node__Pages>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__PagesGroupConnection>;
};

export type Node__PagesConnectionDistinctArgs = {
  field: Node__PagesFieldsEnum;
};

export type Node__PagesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__PagesFieldsEnum;
};

export type Node__PagesEdge = {
  __typename?: 'node__pagesEdge';
  next?: Maybe<Node__Pages>;
  node: Node__Pages;
  previous?: Maybe<Node__Pages>;
};

export type Node__PagesField_Components = {
  __typename?: 'node__pagesField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__PagesField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__PagesField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Node__PagesField_ComponentsFilterInput>;
};

export enum Node__PagesFieldsEnum {
  langcode = 'langcode',
  id = 'id',
  title = 'title',
  field_pages_text___value = 'field_pages_text___value',
  path___alias = 'path___alias',
  field_meta_title = 'field_meta_title',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__nid = 'drupal_internal__nid',
  drupal_internal__vid = 'drupal_internal__vid',
  rh_action = 'rh_action',
  rh_redirect_response = 'rh_redirect_response',
  rh_redirect_fallback_action = 'rh_redirect_fallback_action',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  field_meta_description = 'field_meta_description',
  field_meta_keywords = 'field_meta_keywords',
  uuid = 'uuid',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__PagesFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pages_text?: Maybe<Field_Pages_TextFilterInput>;
  path?: Maybe<PathFilterInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__PagesRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__vid?: Maybe<IntQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  rh_redirect_fallback_action?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_keywords?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_components?: Maybe<Node__PagesField_ComponentsFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__PagesFilterListInput = {
  elemMatch?: Maybe<Node__PagesFilterInput>;
};

export type Node__PagesGroupConnection = {
  __typename?: 'node__pagesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__PagesEdge>;
  nodes: Array<Node__Pages>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__PagesRelationships = {
  __typename?: 'node__pagesRelationships';
  field_main_image?: Maybe<File__File>;
  field_components?: Maybe<Array<Maybe<SimplePageRelatedComponents>>>;
};

export type Node__PagesRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
};

export type Node__PagesSortInput = {
  fields?: Maybe<Array<Maybe<Node__PagesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Price_List = Node & {
  __typename?: 'node__price_list';
  drupal_id?: Maybe<Scalars['String']>;
  fixedprice?: Maybe<Scalars['String']>;
  iposfee?: Maybe<Scalars['String']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  field_pl_locationfee?: Maybe<Scalars['String']>;
  field_pl_name?: Maybe<Scalars['String']>;
  field_pl_optionname?: Maybe<Scalars['String']>;
  field_pl_lease?: Maybe<Scalars['Boolean']>;
  field_pl_payment_type?: Maybe<Scalars['String']>;
  field_pl_lease_name?: Maybe<Scalars['String']>;
  field_pl_activation_price?: Maybe<Scalars['String']>;
  field_pl_contract_duration?: Maybe<Scalars['Int']>;
  field_pl_group_name_in_api?: Maybe<Scalars['String']>;
  field_pl_reduced_fee_duration?: Maybe<Scalars['Int']>;
  field_pl_reduced_fee?: Maybe<Scalars['String']>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  field_pl_first_ipos_fee?: Maybe<Scalars['String']>;
  vatrate?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Node__Price_ListRelationships>;
  pricelistgroup?: Maybe<Node__Price_ListPricelistgroup>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__Price_ListConnection = {
  __typename?: 'node__price_listConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__Price_ListEdge>;
  nodes: Array<Node__Price_List>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__Price_ListGroupConnection>;
};

export type Node__Price_ListConnectionDistinctArgs = {
  field: Node__Price_ListFieldsEnum;
};

export type Node__Price_ListConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__Price_ListFieldsEnum;
};

export type Node__Price_ListEdge = {
  __typename?: 'node__price_listEdge';
  next?: Maybe<Node__Price_List>;
  node: Node__Price_List;
  previous?: Maybe<Node__Price_List>;
};

export enum Node__Price_ListFieldsEnum {
  drupal_id = 'drupal_id',
  fixedprice = 'fixedprice',
  iposfee = 'iposfee',
  langcode = 'langcode',
  status = 'status',
  field_pl_locationfee = 'field_pl_locationfee',
  field_pl_name = 'field_pl_name',
  field_pl_optionname = 'field_pl_optionname',
  field_pl_lease = 'field_pl_lease',
  field_pl_lease_name = 'field_pl_lease_name',
  field_pl_payment_type = 'field_pl_payment_type',
  field_pl_activation_price = 'field_pl_activation_price',
  field_pl_contract_duration = 'field_pl_contract_duration',
  field_pl_group_name_in_api = 'field_pl_group_name_in_api',
  field_pl_reduced_fee_duration = 'field_pl_reduced_fee_duration',
  field_pl_reduced_fee = 'field_pl_reduced_fee',
  drupal_internal__nid = 'drupal_internal__nid',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  field_pl_first_ipos_fee = 'field_pl_first_ipos_fee',
  vatrate = 'vatrate',
  uuid = 'uuid',
  relationships___pricelistgroup___langcode = 'relationships___pricelistgroup___langcode',
  relationships___pricelistgroup___drupal_id = 'relationships___pricelistgroup___drupal_id',
  relationships___pricelistgroup___name = 'relationships___pricelistgroup___name',
  relationships___pricelistgroup___description___value = 'relationships___pricelistgroup___description___value',
  relationships___pricelistgroup___field_discount = 'relationships___pricelistgroup___field_discount',
  relationships___pricelistgroup___field_name_in_api = 'relationships___pricelistgroup___field_name_in_api',
  relationships___pricelistgroup___field_name_in_cms = 'relationships___pricelistgroup___field_name_in_cms',
  relationships___pricelistgroup___field_isperproduct = 'relationships___pricelistgroup___field_isperproduct',
  relationships___pricelistgroup___field_maxfundedproducts = 'relationships___pricelistgroup___field_maxfundedproducts',
  relationships___pricelistgroup___field_publish = 'relationships___pricelistgroup___field_publish',
  relationships___pricelistgroup___field_text_when_unpublished___value = 'relationships___pricelistgroup___field_text_when_unpublished___value',
  relationships___pricelistgroup___relationships___node__price_list = 'relationships___pricelistgroup___relationships___node__price_list',
  relationships___pricelistgroup___relationships___paragraph__profit_reference_to_price_list = 'relationships___pricelistgroup___relationships___paragraph__profit_reference_to_price_list',
  relationships___pricelistgroup___field_is_discount = 'relationships___pricelistgroup___field_is_discount',
  relationships___pricelistgroup___field_side_panel_info = 'relationships___pricelistgroup___field_side_panel_info',
  relationships___pricelistgroup___field_is_default = 'relationships___pricelistgroup___field_is_default',
  relationships___pricelistgroup___drupal_internal__tid = 'relationships___pricelistgroup___drupal_internal__tid',
  relationships___pricelistgroup___drupal_internal__revision_id = 'relationships___pricelistgroup___drupal_internal__revision_id',
  relationships___pricelistgroup___revision_created = 'relationships___pricelistgroup___revision_created',
  relationships___pricelistgroup___status = 'relationships___pricelistgroup___status',
  relationships___pricelistgroup___weight = 'relationships___pricelistgroup___weight',
  relationships___pricelistgroup___changed = 'relationships___pricelistgroup___changed',
  relationships___pricelistgroup___default_langcode = 'relationships___pricelistgroup___default_langcode',
  relationships___pricelistgroup___revision_translation_affected = 'relationships___pricelistgroup___revision_translation_affected',
  relationships___pricelistgroup___content_translation_source = 'relationships___pricelistgroup___content_translation_source',
  relationships___pricelistgroup___content_translation_outdated = 'relationships___pricelistgroup___content_translation_outdated',
  relationships___pricelistgroup___content_translation_created = 'relationships___pricelistgroup___content_translation_created',
  relationships___pricelistgroup___field_coupon_in_api = 'relationships___pricelistgroup___field_coupon_in_api',
  relationships___pricelistgroup___uuid = 'relationships___pricelistgroup___uuid',
  relationships___pricelistgroup___vid___drupal_internal__target_id = 'relationships___pricelistgroup___vid___drupal_internal__target_id',
  relationships___pricelistgroup___field_pg_image___alt = 'relationships___pricelistgroup___field_pg_image___alt',
  relationships___pricelistgroup___field_pg_image___title = 'relationships___pricelistgroup___field_pg_image___title',
  relationships___pricelistgroup___field_pg_image___width = 'relationships___pricelistgroup___field_pg_image___width',
  relationships___pricelistgroup___field_pg_image___height = 'relationships___pricelistgroup___field_pg_image___height',
  relationships___pricelistgroup___field_pg_image___drupal_internal__target_id = 'relationships___pricelistgroup___field_pg_image___drupal_internal__target_id',
  relationships___pricelistgroup___id = 'relationships___pricelistgroup___id',
  relationships___pricelistgroup___parent___id = 'relationships___pricelistgroup___parent___id',
  relationships___pricelistgroup___parent___children = 'relationships___pricelistgroup___parent___children',
  relationships___pricelistgroup___children = 'relationships___pricelistgroup___children',
  relationships___pricelistgroup___children___id = 'relationships___pricelistgroup___children___id',
  relationships___pricelistgroup___children___children = 'relationships___pricelistgroup___children___children',
  relationships___pricelistgroup___internal___content = 'relationships___pricelistgroup___internal___content',
  relationships___pricelistgroup___internal___contentDigest = 'relationships___pricelistgroup___internal___contentDigest',
  relationships___pricelistgroup___internal___description = 'relationships___pricelistgroup___internal___description',
  relationships___pricelistgroup___internal___fieldOwners = 'relationships___pricelistgroup___internal___fieldOwners',
  relationships___pricelistgroup___internal___ignoreType = 'relationships___pricelistgroup___internal___ignoreType',
  relationships___pricelistgroup___internal___mediaType = 'relationships___pricelistgroup___internal___mediaType',
  relationships___pricelistgroup___internal___owner = 'relationships___pricelistgroup___internal___owner',
  relationships___pricelistgroup___internal___type = 'relationships___pricelistgroup___internal___type',
  relationships___node__accessories = 'relationships___node__accessories',
  relationships___node__accessories___langcode = 'relationships___node__accessories___langcode',
  relationships___node__accessories___drupal_id = 'relationships___node__accessories___drupal_id',
  relationships___node__accessories___title = 'relationships___node__accessories___title',
  relationships___node__accessories___field_pl_groupid = 'relationships___node__accessories___field_pl_groupid',
  relationships___node__accessories___field_weight = 'relationships___node__accessories___field_weight',
  relationships___node__accessories___relationships___field_recommended_for_branches = 'relationships___node__accessories___relationships___field_recommended_for_branches',
  relationships___node__accessories___drupal_internal__nid = 'relationships___node__accessories___drupal_internal__nid',
  relationships___node__accessories___status = 'relationships___node__accessories___status',
  relationships___node__accessories___content_translation_source = 'relationships___node__accessories___content_translation_source',
  relationships___node__accessories___content_translation_outdated = 'relationships___node__accessories___content_translation_outdated',
  relationships___node__accessories___uuid = 'relationships___node__accessories___uuid',
  relationships___node__accessories___field_date_from_api___drupal_internal__target_id = 'relationships___node__accessories___field_date_from_api___drupal_internal__target_id',
  relationships___node__accessories___field_pl_accessory_type___drupal_internal__target_id = 'relationships___node__accessories___field_pl_accessory_type___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image___alt = 'relationships___node__accessories___field_pl_image___alt',
  relationships___node__accessories___field_pl_image___title = 'relationships___node__accessories___field_pl_image___title',
  relationships___node__accessories___field_pl_image___width = 'relationships___node__accessories___field_pl_image___width',
  relationships___node__accessories___field_pl_image___height = 'relationships___node__accessories___field_pl_image___height',
  relationships___node__accessories___field_pl_image___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image_accessories_outl___alt = 'relationships___node__accessories___field_pl_image_accessories_outl___alt',
  relationships___node__accessories___field_pl_image_accessories_outl___title = 'relationships___node__accessories___field_pl_image_accessories_outl___title',
  relationships___node__accessories___field_pl_image_accessories_outl___width = 'relationships___node__accessories___field_pl_image_accessories_outl___width',
  relationships___node__accessories___field_pl_image_accessories_outl___height = 'relationships___node__accessories___field_pl_image_accessories_outl___height',
  relationships___node__accessories___field_pl_image_accessories_outl___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image_accessories_outl___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image_accessories_table___alt = 'relationships___node__accessories___field_pl_image_accessories_table___alt',
  relationships___node__accessories___field_pl_image_accessories_table___title = 'relationships___node__accessories___field_pl_image_accessories_table___title',
  relationships___node__accessories___field_pl_image_accessories_table___width = 'relationships___node__accessories___field_pl_image_accessories_table___width',
  relationships___node__accessories___field_pl_image_accessories_table___height = 'relationships___node__accessories___field_pl_image_accessories_table___height',
  relationships___node__accessories___field_pl_image_accessories_table___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image_accessories_table___drupal_internal__target_id',
  relationships___node__accessories___field_recommended_for_branches = 'relationships___node__accessories___field_recommended_for_branches',
  relationships___node__accessories___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__accessories___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__accessories___id = 'relationships___node__accessories___id',
  relationships___node__accessories___parent___id = 'relationships___node__accessories___parent___id',
  relationships___node__accessories___parent___children = 'relationships___node__accessories___parent___children',
  relationships___node__accessories___children = 'relationships___node__accessories___children',
  relationships___node__accessories___children___id = 'relationships___node__accessories___children___id',
  relationships___node__accessories___children___children = 'relationships___node__accessories___children___children',
  relationships___node__accessories___internal___content = 'relationships___node__accessories___internal___content',
  relationships___node__accessories___internal___contentDigest = 'relationships___node__accessories___internal___contentDigest',
  relationships___node__accessories___internal___description = 'relationships___node__accessories___internal___description',
  relationships___node__accessories___internal___fieldOwners = 'relationships___node__accessories___internal___fieldOwners',
  relationships___node__accessories___internal___ignoreType = 'relationships___node__accessories___internal___ignoreType',
  relationships___node__accessories___internal___mediaType = 'relationships___node__accessories___internal___mediaType',
  relationships___node__accessories___internal___owner = 'relationships___node__accessories___internal___owner',
  relationships___node__accessories___internal___type = 'relationships___node__accessories___internal___type',
  relationships___node__cases = 'relationships___node__cases',
  relationships___node__cases___drupal_id = 'relationships___node__cases___drupal_id',
  relationships___node__cases___title = 'relationships___node__cases___title',
  relationships___node__cases___field_pl_color = 'relationships___node__cases___field_pl_color',
  relationships___node__cases___relationships___field_recommended_for_branches = 'relationships___node__cases___relationships___field_recommended_for_branches',
  relationships___node__cases___relationships___node__devices = 'relationships___node__cases___relationships___node__devices',
  relationships___node__cases___drupal_internal__nid = 'relationships___node__cases___drupal_internal__nid',
  relationships___node__cases___status = 'relationships___node__cases___status',
  relationships___node__cases___content_translation_source = 'relationships___node__cases___content_translation_source',
  relationships___node__cases___content_translation_outdated = 'relationships___node__cases___content_translation_outdated',
  relationships___node__cases___langcode = 'relationships___node__cases___langcode',
  relationships___node__cases___uuid = 'relationships___node__cases___uuid',
  relationships___node__cases___field_pl_image___alt = 'relationships___node__cases___field_pl_image___alt',
  relationships___node__cases___field_pl_image___title = 'relationships___node__cases___field_pl_image___title',
  relationships___node__cases___field_pl_image___width = 'relationships___node__cases___field_pl_image___width',
  relationships___node__cases___field_pl_image___height = 'relationships___node__cases___field_pl_image___height',
  relationships___node__cases___field_pl_image___drupal_internal__target_id = 'relationships___node__cases___field_pl_image___drupal_internal__target_id',
  relationships___node__cases___field_recommended_for_branches = 'relationships___node__cases___field_recommended_for_branches',
  relationships___node__cases___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__cases___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__cases___field_date_from_api___drupal_internal__target_id = 'relationships___node__cases___field_date_from_api___drupal_internal__target_id',
  relationships___node__cases___id = 'relationships___node__cases___id',
  relationships___node__cases___parent___id = 'relationships___node__cases___parent___id',
  relationships___node__cases___parent___children = 'relationships___node__cases___parent___children',
  relationships___node__cases___children = 'relationships___node__cases___children',
  relationships___node__cases___children___id = 'relationships___node__cases___children___id',
  relationships___node__cases___children___children = 'relationships___node__cases___children___children',
  relationships___node__cases___internal___content = 'relationships___node__cases___internal___content',
  relationships___node__cases___internal___contentDigest = 'relationships___node__cases___internal___contentDigest',
  relationships___node__cases___internal___description = 'relationships___node__cases___internal___description',
  relationships___node__cases___internal___fieldOwners = 'relationships___node__cases___internal___fieldOwners',
  relationships___node__cases___internal___ignoreType = 'relationships___node__cases___internal___ignoreType',
  relationships___node__cases___internal___mediaType = 'relationships___node__cases___internal___mediaType',
  relationships___node__cases___internal___owner = 'relationships___node__cases___internal___owner',
  relationships___node__cases___internal___type = 'relationships___node__cases___internal___type',
  relationships___paragraph__branches_tariff = 'relationships___paragraph__branches_tariff',
  relationships___paragraph__branches_tariff___field_branches_name = 'relationships___paragraph__branches_tariff___field_branches_name',
  relationships___paragraph__branches_tariff___field_recommended = 'relationships___paragraph__branches_tariff___field_recommended',
  relationships___paragraph__branches_tariff___relationships___field_branches_profits = 'relationships___paragraph__branches_tariff___relationships___field_branches_profits',
  relationships___paragraph__branches_tariff___relationships___paragraph__branches_recommended_tariffs = 'relationships___paragraph__branches_tariff___relationships___paragraph__branches_recommended_tariffs',
  relationships___paragraph__branches_tariff___relationships___site_setting_entity__product = 'relationships___paragraph__branches_tariff___relationships___site_setting_entity__product',
  relationships___paragraph__branches_tariff___drupal_id = 'relationships___paragraph__branches_tariff___drupal_id',
  relationships___paragraph__branches_tariff___drupal_internal__id = 'relationships___paragraph__branches_tariff___drupal_internal__id',
  relationships___paragraph__branches_tariff___status = 'relationships___paragraph__branches_tariff___status',
  relationships___paragraph__branches_tariff___created = 'relationships___paragraph__branches_tariff___created',
  relationships___paragraph__branches_tariff___parent_id = 'relationships___paragraph__branches_tariff___parent_id',
  relationships___paragraph__branches_tariff___parent_type = 'relationships___paragraph__branches_tariff___parent_type',
  relationships___paragraph__branches_tariff___parent_field_name = 'relationships___paragraph__branches_tariff___parent_field_name',
  relationships___paragraph__branches_tariff___content_translation_source = 'relationships___paragraph__branches_tariff___content_translation_source',
  relationships___paragraph__branches_tariff___content_translation_outdated = 'relationships___paragraph__branches_tariff___content_translation_outdated',
  relationships___paragraph__branches_tariff___content_translation_changed = 'relationships___paragraph__branches_tariff___content_translation_changed',
  relationships___paragraph__branches_tariff___langcode = 'relationships___paragraph__branches_tariff___langcode',
  relationships___paragraph__branches_tariff___uuid = 'relationships___paragraph__branches_tariff___uuid',
  relationships___paragraph__branches_tariff___field_branches_profits = 'relationships___paragraph__branches_tariff___field_branches_profits',
  relationships___paragraph__branches_tariff___field_branches_profits___target_revision_id = 'relationships___paragraph__branches_tariff___field_branches_profits___target_revision_id',
  relationships___paragraph__branches_tariff___field_branches_profits___drupal_internal__target_id = 'relationships___paragraph__branches_tariff___field_branches_profits___drupal_internal__target_id',
  relationships___paragraph__branches_tariff___field_date_from_api___drupal_internal__target_id = 'relationships___paragraph__branches_tariff___field_date_from_api___drupal_internal__target_id',
  relationships___paragraph__branches_tariff___id = 'relationships___paragraph__branches_tariff___id',
  relationships___paragraph__branches_tariff___parent___id = 'relationships___paragraph__branches_tariff___parent___id',
  relationships___paragraph__branches_tariff___parent___children = 'relationships___paragraph__branches_tariff___parent___children',
  relationships___paragraph__branches_tariff___children = 'relationships___paragraph__branches_tariff___children',
  relationships___paragraph__branches_tariff___children___id = 'relationships___paragraph__branches_tariff___children___id',
  relationships___paragraph__branches_tariff___children___children = 'relationships___paragraph__branches_tariff___children___children',
  relationships___paragraph__branches_tariff___internal___content = 'relationships___paragraph__branches_tariff___internal___content',
  relationships___paragraph__branches_tariff___internal___contentDigest = 'relationships___paragraph__branches_tariff___internal___contentDigest',
  relationships___paragraph__branches_tariff___internal___description = 'relationships___paragraph__branches_tariff___internal___description',
  relationships___paragraph__branches_tariff___internal___fieldOwners = 'relationships___paragraph__branches_tariff___internal___fieldOwners',
  relationships___paragraph__branches_tariff___internal___ignoreType = 'relationships___paragraph__branches_tariff___internal___ignoreType',
  relationships___paragraph__branches_tariff___internal___mediaType = 'relationships___paragraph__branches_tariff___internal___mediaType',
  relationships___paragraph__branches_tariff___internal___owner = 'relationships___paragraph__branches_tariff___internal___owner',
  relationships___paragraph__branches_tariff___internal___type = 'relationships___paragraph__branches_tariff___internal___type',
  pricelistgroup___drupal_internal__target_id = 'pricelistgroup___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__Price_ListFilterInput = {
  drupal_id?: Maybe<StringQueryOperatorInput>;
  fixedprice?: Maybe<StringQueryOperatorInput>;
  iposfee?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  field_pl_locationfee?: Maybe<StringQueryOperatorInput>;
  field_pl_name?: Maybe<StringQueryOperatorInput>;
  field_pl_optionname?: Maybe<StringQueryOperatorInput>;
  field_pl_lease?: Maybe<BooleanQueryOperatorInput>;
  field_pl_lease_name?: Maybe<StringQueryOperatorInput>;
  field_pl_payment_type?: Maybe<StringQueryOperatorInput>;
  field_pl_activation_price?: Maybe<StringQueryOperatorInput>;
  field_pl_contract_duration?: Maybe<IntQueryOperatorInput>;
  field_pl_group_name_in_api?: Maybe<StringQueryOperatorInput>;
  field_pl_reduced_fee_duration?: Maybe<IntQueryOperatorInput>;
  field_pl_reduced_fee?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  field_pl_first_ipos_fee?: Maybe<StringQueryOperatorInput>;
  vatrate?: Maybe<IntQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__Price_ListRelationshipsFilterInput>;
  pricelistgroup?: Maybe<Node__Price_ListPricelistgroupFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__Price_ListFilterListInput = {
  elemMatch?: Maybe<Node__Price_ListFilterInput>;
};

export type Node__Price_ListGroupConnection = {
  __typename?: 'node__price_listGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__Price_ListEdge>;
  nodes: Array<Node__Price_List>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__Price_ListPricelistgroup = {
  __typename?: 'node__price_listPricelistgroup';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__Price_ListPricelistgroupFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__Price_ListRelationships = {
  __typename?: 'node__price_listRelationships';
  pricelistgroup?: Maybe<Taxonomy_Term__Pricelist_Group>;
  node__accessories?: Maybe<Array<Maybe<Node__Accessories>>>;
  node__cases?: Maybe<Array<Maybe<Node__Cases>>>;
  paragraph__branches_tariff?: Maybe<Array<Maybe<Paragraph__Branches_Tariff>>>;
};

export type Node__Price_ListRelationshipsFilterInput = {
  pricelistgroup?: Maybe<Taxonomy_Term__Pricelist_GroupFilterInput>;
  node__accessories?: Maybe<Node__AccessoriesFilterListInput>;
  node__cases?: Maybe<Node__CasesFilterListInput>;
  paragraph__branches_tariff?: Maybe<Paragraph__Branches_TariffFilterListInput>;
};

export type Node__Price_ListSortInput = {
  fields?: Maybe<Array<Maybe<Node__Price_ListFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Node__Tariffs = Node & {
  __typename?: 'node__tariffs';
  langcode?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  field_pl_description_in_cms?: Maybe<Field_Pl_Description_In_Cms>;
  field_pl_is_popular?: Maybe<Scalars['Boolean']>;
  field_weight?: Maybe<Scalars['Int']>;
  relationships?: Maybe<Node__TariffsRelationships>;
  price_list_type?: Maybe<Scalars['String']>;
  drupal_internal__nid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  field_profits?: Maybe<Array<Maybe<Node__TariffsField_Profits>>>;
  field_recommended_for_branches?: Maybe<Array<Maybe<Node__TariffsField_Recommended_For_Branches>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Node__TariffsConnection = {
  __typename?: 'node__tariffsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__TariffsEdge>;
  nodes: Array<Node__Tariffs>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Node__TariffsGroupConnection>;
};

export type Node__TariffsConnectionDistinctArgs = {
  field: Node__TariffsFieldsEnum;
};

export type Node__TariffsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Node__TariffsFieldsEnum;
};

export type Node__TariffsEdge = {
  __typename?: 'node__tariffsEdge';
  next?: Maybe<Node__Tariffs>;
  node: Node__Tariffs;
  previous?: Maybe<Node__Tariffs>;
};

export type Node__TariffsField_Profits = {
  __typename?: 'node__tariffsField_profits';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__TariffsField_ProfitsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__TariffsField_ProfitsFilterListInput = {
  elemMatch?: Maybe<Node__TariffsField_ProfitsFilterInput>;
};

export type Node__TariffsField_Recommended_For_Branches = {
  __typename?: 'node__tariffsField_recommended_for_branches';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Node__TariffsField_Recommended_For_BranchesFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Node__TariffsField_Recommended_For_BranchesFilterListInput = {
  elemMatch?: Maybe<Node__TariffsField_Recommended_For_BranchesFilterInput>;
};

export enum Node__TariffsFieldsEnum {
  langcode = 'langcode',
  drupal_id = 'drupal_id',
  title = 'title',
  field_pl_description_in_cms___value = 'field_pl_description_in_cms___value',
  field_pl_is_popular = 'field_pl_is_popular',
  field_weight = 'field_weight',
  relationships___field_profits = 'relationships___field_profits',
  relationships___field_profits___field_profits_active = 'relationships___field_profits___field_profits_active',
  relationships___field_profits___field_profits_name___value = 'relationships___field_profits___field_profits_name___value',
  relationships___field_profits___field_ref_desc = 'relationships___field_profits___field_ref_desc',
  relationships___field_profits___field_ref_desc___target_revision_id = 'relationships___field_profits___field_ref_desc___target_revision_id',
  relationships___field_profits___field_ref_desc___drupal_internal__target_id = 'relationships___field_profits___field_ref_desc___drupal_internal__target_id',
  relationships___field_profits___drupal_id = 'relationships___field_profits___drupal_id',
  relationships___field_profits___drupal_internal__id = 'relationships___field_profits___drupal_internal__id',
  relationships___field_profits___status = 'relationships___field_profits___status',
  relationships___field_profits___created = 'relationships___field_profits___created',
  relationships___field_profits___parent_id = 'relationships___field_profits___parent_id',
  relationships___field_profits___parent_type = 'relationships___field_profits___parent_type',
  relationships___field_profits___parent_field_name = 'relationships___field_profits___parent_field_name',
  relationships___field_profits___content_translation_source = 'relationships___field_profits___content_translation_source',
  relationships___field_profits___content_translation_outdated = 'relationships___field_profits___content_translation_outdated',
  relationships___field_profits___content_translation_changed = 'relationships___field_profits___content_translation_changed',
  relationships___field_profits___langcode = 'relationships___field_profits___langcode',
  relationships___field_profits___uuid = 'relationships___field_profits___uuid',
  relationships___field_profits___relationships___node__tariffs = 'relationships___field_profits___relationships___node__tariffs',
  relationships___field_profits___relationships___field_ref_desc = 'relationships___field_profits___relationships___field_ref_desc',
  relationships___field_profits___relationships___paragraph__branches_device = 'relationships___field_profits___relationships___paragraph__branches_device',
  relationships___field_profits___relationships___paragraph__branches_tariff = 'relationships___field_profits___relationships___paragraph__branches_tariff',
  relationships___field_profits___id = 'relationships___field_profits___id',
  relationships___field_profits___parent___id = 'relationships___field_profits___parent___id',
  relationships___field_profits___parent___children = 'relationships___field_profits___parent___children',
  relationships___field_profits___children = 'relationships___field_profits___children',
  relationships___field_profits___children___id = 'relationships___field_profits___children___id',
  relationships___field_profits___children___children = 'relationships___field_profits___children___children',
  relationships___field_profits___internal___content = 'relationships___field_profits___internal___content',
  relationships___field_profits___internal___contentDigest = 'relationships___field_profits___internal___contentDigest',
  relationships___field_profits___internal___description = 'relationships___field_profits___internal___description',
  relationships___field_profits___internal___fieldOwners = 'relationships___field_profits___internal___fieldOwners',
  relationships___field_profits___internal___ignoreType = 'relationships___field_profits___internal___ignoreType',
  relationships___field_profits___internal___mediaType = 'relationships___field_profits___internal___mediaType',
  relationships___field_profits___internal___owner = 'relationships___field_profits___internal___owner',
  relationships___field_profits___internal___type = 'relationships___field_profits___internal___type',
  relationships___field_recommended_for_branches = 'relationships___field_recommended_for_branches',
  relationships___field_recommended_for_branches___langcode = 'relationships___field_recommended_for_branches___langcode',
  relationships___field_recommended_for_branches___id = 'relationships___field_recommended_for_branches___id',
  relationships___field_recommended_for_branches___drupal_id = 'relationships___field_recommended_for_branches___drupal_id',
  relationships___field_recommended_for_branches___title = 'relationships___field_recommended_for_branches___title',
  relationships___field_recommended_for_branches___field_pl_is_online = 'relationships___field_recommended_for_branches___field_pl_is_online',
  relationships___field_recommended_for_branches___field_branches_date = 'relationships___field_recommended_for_branches___field_branches_date',
  relationships___field_recommended_for_branches___field_weight = 'relationships___field_recommended_for_branches___field_weight',
  relationships___field_recommended_for_branches___path___alias = 'relationships___field_recommended_for_branches___path___alias',
  relationships___field_recommended_for_branches___field_meta_description = 'relationships___field_recommended_for_branches___field_meta_description',
  relationships___field_recommended_for_branches___field_meta_title = 'relationships___field_recommended_for_branches___field_meta_title',
  relationships___field_recommended_for_branches___relationships___node__accessories = 'relationships___field_recommended_for_branches___relationships___node__accessories',
  relationships___field_recommended_for_branches___relationships___node__cases = 'relationships___field_recommended_for_branches___relationships___node__cases',
  relationships___field_recommended_for_branches___relationships___node__devices = 'relationships___field_recommended_for_branches___relationships___node__devices',
  relationships___field_recommended_for_branches___relationships___node__tariffs = 'relationships___field_recommended_for_branches___relationships___node__tariffs',
  relationships___field_recommended_for_branches___drupal_internal__nid = 'relationships___field_recommended_for_branches___drupal_internal__nid',
  relationships___field_recommended_for_branches___status = 'relationships___field_recommended_for_branches___status',
  relationships___field_recommended_for_branches___content_translation_source = 'relationships___field_recommended_for_branches___content_translation_source',
  relationships___field_recommended_for_branches___content_translation_outdated = 'relationships___field_recommended_for_branches___content_translation_outdated',
  relationships___field_recommended_for_branches___uuid = 'relationships___field_recommended_for_branches___uuid',
  relationships___field_recommended_for_branches___field_branches_icon___alt = 'relationships___field_recommended_for_branches___field_branches_icon___alt',
  relationships___field_recommended_for_branches___field_branches_icon___title = 'relationships___field_recommended_for_branches___field_branches_icon___title',
  relationships___field_recommended_for_branches___field_branches_icon___width = 'relationships___field_recommended_for_branches___field_branches_icon___width',
  relationships___field_recommended_for_branches___field_branches_icon___height = 'relationships___field_recommended_for_branches___field_branches_icon___height',
  relationships___field_recommended_for_branches___field_branches_icon___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_branches_icon___drupal_internal__target_id',
  relationships___field_recommended_for_branches___field_branches_image___alt = 'relationships___field_recommended_for_branches___field_branches_image___alt',
  relationships___field_recommended_for_branches___field_branches_image___title = 'relationships___field_recommended_for_branches___field_branches_image___title',
  relationships___field_recommended_for_branches___field_branches_image___width = 'relationships___field_recommended_for_branches___field_branches_image___width',
  relationships___field_recommended_for_branches___field_branches_image___height = 'relationships___field_recommended_for_branches___field_branches_image___height',
  relationships___field_recommended_for_branches___field_branches_image___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_branches_image___drupal_internal__target_id',
  relationships___field_recommended_for_branches___field_components = 'relationships___field_recommended_for_branches___field_components',
  relationships___field_recommended_for_branches___field_components___target_revision_id = 'relationships___field_recommended_for_branches___field_components___target_revision_id',
  relationships___field_recommended_for_branches___field_components___drupal_internal__target_id = 'relationships___field_recommended_for_branches___field_components___drupal_internal__target_id',
  relationships___field_recommended_for_branches___parent___id = 'relationships___field_recommended_for_branches___parent___id',
  relationships___field_recommended_for_branches___parent___children = 'relationships___field_recommended_for_branches___parent___children',
  relationships___field_recommended_for_branches___children = 'relationships___field_recommended_for_branches___children',
  relationships___field_recommended_for_branches___children___id = 'relationships___field_recommended_for_branches___children___id',
  relationships___field_recommended_for_branches___children___children = 'relationships___field_recommended_for_branches___children___children',
  relationships___field_recommended_for_branches___internal___content = 'relationships___field_recommended_for_branches___internal___content',
  relationships___field_recommended_for_branches___internal___contentDigest = 'relationships___field_recommended_for_branches___internal___contentDigest',
  relationships___field_recommended_for_branches___internal___description = 'relationships___field_recommended_for_branches___internal___description',
  relationships___field_recommended_for_branches___internal___fieldOwners = 'relationships___field_recommended_for_branches___internal___fieldOwners',
  relationships___field_recommended_for_branches___internal___ignoreType = 'relationships___field_recommended_for_branches___internal___ignoreType',
  relationships___field_recommended_for_branches___internal___mediaType = 'relationships___field_recommended_for_branches___internal___mediaType',
  relationships___field_recommended_for_branches___internal___owner = 'relationships___field_recommended_for_branches___internal___owner',
  relationships___field_recommended_for_branches___internal___type = 'relationships___field_recommended_for_branches___internal___type',
  price_list_type = 'price_list_type',
  drupal_internal__nid = 'drupal_internal__nid',
  status = 'status',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  field_profits = 'field_profits',
  field_profits___target_revision_id = 'field_profits___target_revision_id',
  field_profits___drupal_internal__target_id = 'field_profits___drupal_internal__target_id',
  field_recommended_for_branches = 'field_recommended_for_branches',
  field_recommended_for_branches___drupal_internal__target_id = 'field_recommended_for_branches___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Node__TariffsFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pl_description_in_cms?: Maybe<Field_Pl_Description_In_CmsFilterInput>;
  field_pl_is_popular?: Maybe<BooleanQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  relationships?: Maybe<Node__TariffsRelationshipsFilterInput>;
  price_list_type?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_profits?: Maybe<Node__TariffsField_ProfitsFilterListInput>;
  field_recommended_for_branches?: Maybe<
    Node__TariffsField_Recommended_For_BranchesFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Node__TariffsFilterListInput = {
  elemMatch?: Maybe<Node__TariffsFilterInput>;
};

export type Node__TariffsGroupConnection = {
  __typename?: 'node__tariffsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Node__TariffsEdge>;
  nodes: Array<Node__Tariffs>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Node__TariffsRelationships = {
  __typename?: 'node__tariffsRelationships';
  field_profits?: Maybe<Array<Maybe<Paragraph__Profits>>>;
  field_recommended_for_branches?: Maybe<Array<Maybe<Node__Branches>>>;
};

export type Node__TariffsRelationshipsFilterInput = {
  field_profits?: Maybe<Paragraph__ProfitsFilterListInput>;
  field_recommended_for_branches?: Maybe<Node__BranchesFilterListInput>;
};

export type Node__TariffsSortInput = {
  fields?: Maybe<Array<Maybe<Node__TariffsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type NodeFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type NodeFilterListInput = {
  elemMatch?: Maybe<NodeFilterInput>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int'];
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  perPage?: Maybe<Scalars['Int']>;
};

export type Paragraph__2_Zdjecia_Opisy_Link = Node & {
  __typename?: 'paragraph__2_zdjecia_opisy_link';
  field_label_1?: Maybe<Scalars['String']>;
  field_label_2?: Maybe<Scalars['String']>;
  field_image?: Maybe<Field_Image>;
  field_image_1?: Maybe<Field_Image_1>;
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_text_1?: Maybe<Field_Text_1>;
  field_text_2?: Maybe<Field_Text_2>;
  field_btn_url?: Maybe<Scalars['String']>;
  field_only_text?: Maybe<Scalars['Boolean']>;
  field_short_text_1?: Maybe<Field_Short_Text_1>;
  relationships?: Maybe<Paragraph__2_Zdjecia_Opisy_LinkRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__2_Zdjecia_Opisy_LinkCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__2_Zdjecia_Opisy_LinkContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__2_Zdjecia_Opisy_LinkConnection = {
  __typename?: 'paragraph__2_zdjecia_opisy_linkConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__2_Zdjecia_Opisy_LinkEdge>;
  nodes: Array<Paragraph__2_Zdjecia_Opisy_Link>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__2_Zdjecia_Opisy_LinkGroupConnection>;
};

export type Paragraph__2_Zdjecia_Opisy_LinkConnectionDistinctArgs = {
  field: Paragraph__2_Zdjecia_Opisy_LinkFieldsEnum;
};

export type Paragraph__2_Zdjecia_Opisy_LinkConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__2_Zdjecia_Opisy_LinkFieldsEnum;
};

export type Paragraph__2_Zdjecia_Opisy_LinkEdge = {
  __typename?: 'paragraph__2_zdjecia_opisy_linkEdge';
  next?: Maybe<Paragraph__2_Zdjecia_Opisy_Link>;
  node: Paragraph__2_Zdjecia_Opisy_Link;
  previous?: Maybe<Paragraph__2_Zdjecia_Opisy_Link>;
};

export enum Paragraph__2_Zdjecia_Opisy_LinkFieldsEnum {
  field_label_1 = 'field_label_1',
  field_label_2 = 'field_label_2',
  field_image___alt = 'field_image___alt',
  field_image_1___alt = 'field_image_1___alt',
  field_nc_button_text = 'field_nc_button_text',
  field_text_1___value = 'field_text_1___value',
  field_text_2___value = 'field_text_2___value',
  field_btn_url = 'field_btn_url',
  field_only_text = 'field_only_text',
  field_short_text_1___value = 'field_short_text_1___value',
  relationships___field_image___localFile___birthtime = 'relationships___field_image___localFile___birthtime',
  relationships___field_image___localFile___birthtimeMs = 'relationships___field_image___localFile___birthtimeMs',
  relationships___field_image___localFile___publicURL = 'relationships___field_image___localFile___publicURL',
  relationships___field_image___localFile___sourceInstanceName = 'relationships___field_image___localFile___sourceInstanceName',
  relationships___field_image___localFile___absolutePath = 'relationships___field_image___localFile___absolutePath',
  relationships___field_image___localFile___relativePath = 'relationships___field_image___localFile___relativePath',
  relationships___field_image___localFile___extension = 'relationships___field_image___localFile___extension',
  relationships___field_image___localFile___size = 'relationships___field_image___localFile___size',
  relationships___field_image___localFile___prettySize = 'relationships___field_image___localFile___prettySize',
  relationships___field_image___localFile___modifiedTime = 'relationships___field_image___localFile___modifiedTime',
  relationships___field_image___localFile___accessTime = 'relationships___field_image___localFile___accessTime',
  relationships___field_image___localFile___changeTime = 'relationships___field_image___localFile___changeTime',
  relationships___field_image___localFile___birthTime = 'relationships___field_image___localFile___birthTime',
  relationships___field_image___localFile___root = 'relationships___field_image___localFile___root',
  relationships___field_image___localFile___dir = 'relationships___field_image___localFile___dir',
  relationships___field_image___localFile___base = 'relationships___field_image___localFile___base',
  relationships___field_image___localFile___ext = 'relationships___field_image___localFile___ext',
  relationships___field_image___localFile___name = 'relationships___field_image___localFile___name',
  relationships___field_image___localFile___relativeDirectory = 'relationships___field_image___localFile___relativeDirectory',
  relationships___field_image___localFile___dev = 'relationships___field_image___localFile___dev',
  relationships___field_image___localFile___mode = 'relationships___field_image___localFile___mode',
  relationships___field_image___localFile___nlink = 'relationships___field_image___localFile___nlink',
  relationships___field_image___localFile___uid = 'relationships___field_image___localFile___uid',
  relationships___field_image___localFile___gid = 'relationships___field_image___localFile___gid',
  relationships___field_image___localFile___rdev = 'relationships___field_image___localFile___rdev',
  relationships___field_image___localFile___blksize = 'relationships___field_image___localFile___blksize',
  relationships___field_image___localFile___ino = 'relationships___field_image___localFile___ino',
  relationships___field_image___localFile___blocks = 'relationships___field_image___localFile___blocks',
  relationships___field_image___localFile___atimeMs = 'relationships___field_image___localFile___atimeMs',
  relationships___field_image___localFile___mtimeMs = 'relationships___field_image___localFile___mtimeMs',
  relationships___field_image___localFile___ctimeMs = 'relationships___field_image___localFile___ctimeMs',
  relationships___field_image___localFile___atime = 'relationships___field_image___localFile___atime',
  relationships___field_image___localFile___mtime = 'relationships___field_image___localFile___mtime',
  relationships___field_image___localFile___ctime = 'relationships___field_image___localFile___ctime',
  relationships___field_image___localFile___url = 'relationships___field_image___localFile___url',
  relationships___field_image___localFile___id = 'relationships___field_image___localFile___id',
  relationships___field_image___localFile___children = 'relationships___field_image___localFile___children',
  relationships___field_image___uri___url = 'relationships___field_image___uri___url',
  relationships___field_image___drupal_id = 'relationships___field_image___drupal_id',
  relationships___field_image___drupal_internal__fid = 'relationships___field_image___drupal_internal__fid',
  relationships___field_image___langcode = 'relationships___field_image___langcode',
  relationships___field_image___filename = 'relationships___field_image___filename',
  relationships___field_image___filemime = 'relationships___field_image___filemime',
  relationships___field_image___filesize = 'relationships___field_image___filesize',
  relationships___field_image___status = 'relationships___field_image___status',
  relationships___field_image___created = 'relationships___field_image___created',
  relationships___field_image___changed = 'relationships___field_image___changed',
  relationships___field_image___uuid = 'relationships___field_image___uuid',
  relationships___field_image___relationships___node__blog_post = 'relationships___field_image___relationships___node__blog_post',
  relationships___field_image___relationships___node__devices = 'relationships___field_image___relationships___node__devices',
  relationships___field_image___relationships___node__accessories = 'relationships___field_image___relationships___node__accessories',
  relationships___field_image___relationships___paragraph__news_image_title_text = 'relationships___field_image___relationships___paragraph__news_image_title_text',
  relationships___field_image___relationships___node__cases = 'relationships___field_image___relationships___node__cases',
  relationships___field_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image___relationships___paragraph__branches_features = 'relationships___field_image___relationships___paragraph__branches_features',
  relationships___field_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image___relationships___paragraph__branches_testimonials = 'relationships___field_image___relationships___paragraph__branches_testimonials',
  relationships___field_image___relationships___paragraph__branches_discounts = 'relationships___field_image___relationships___paragraph__branches_discounts',
  relationships___field_image___relationships___node__news = 'relationships___field_image___relationships___node__news',
  relationships___field_image___relationships___node__branches = 'relationships___field_image___relationships___node__branches',
  relationships___field_image___relationships___paragraph__branches_hero = 'relationships___field_image___relationships___paragraph__branches_hero',
  relationships___field_image___relationships___paragraph__bullets = 'relationships___field_image___relationships___paragraph__bullets',
  relationships___field_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_image___relationships___paragraph__branches_device = 'relationships___field_image___relationships___paragraph__branches_device',
  relationships___field_image___relationships___paragraph__branches_recommendation = 'relationships___field_image___relationships___paragraph__branches_recommendation',
  relationships___field_image___relationships___site_setting_entity__accessories = 'relationships___field_image___relationships___site_setting_entity__accessories',
  relationships___field_image___relationships___site_setting_entity__branches = 'relationships___field_image___relationships___site_setting_entity__branches',
  relationships___field_image___relationships___site_setting_entity__about_us = 'relationships___field_image___relationships___site_setting_entity__about_us',
  relationships___field_image___relationships___paragraph__logos = 'relationships___field_image___relationships___paragraph__logos',
  relationships___field_image___relationships___paragraph__how_it_works = 'relationships___field_image___relationships___paragraph__how_it_works',
  relationships___field_image___relationships___paragraph__ico_and_text = 'relationships___field_image___relationships___paragraph__ico_and_text',
  relationships___field_image___relationships___paragraph__hero_1 = 'relationships___field_image___relationships___paragraph__hero_1',
  relationships___field_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image___relationships___site_setting_entity__product = 'relationships___field_image___relationships___site_setting_entity__product',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image___relationships___paragraph__porownanie_terminali = 'relationships___field_image___relationships___paragraph__porownanie_terminali',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image___relationships___site_setting_entity__main_page = 'relationships___field_image___relationships___site_setting_entity__main_page',
  relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image___relationships___site_setting_entity__intercars = 'relationships___field_image___relationships___site_setting_entity__intercars',
  relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image___relationships___node__agreement = 'relationships___field_image___relationships___node__agreement',
  relationships___field_image___relationships___node__fiscalization = 'relationships___field_image___relationships___node__fiscalization',
  relationships___field_image___relationships___site_setting_entity__configurator = 'relationships___field_image___relationships___site_setting_entity__configurator',
  relationships___field_image___relationships___paragraph__social_link = 'relationships___field_image___relationships___paragraph__social_link',
  relationships___field_image___relationships___paragraph__make_an_appointment = 'relationships___field_image___relationships___paragraph__make_an_appointment',
  relationships___field_image___relationships___paragraph__linked_icons = 'relationships___field_image___relationships___paragraph__linked_icons',
  relationships___field_image___relationships___site_setting_entity__financing = 'relationships___field_image___relationships___site_setting_entity__financing',
  relationships___field_image___relationships___site_setting_entity__franchise = 'relationships___field_image___relationships___site_setting_entity__franchise',
  relationships___field_image___id = 'relationships___field_image___id',
  relationships___field_image___parent___id = 'relationships___field_image___parent___id',
  relationships___field_image___parent___children = 'relationships___field_image___parent___children',
  relationships___field_image___children = 'relationships___field_image___children',
  relationships___field_image___children___id = 'relationships___field_image___children___id',
  relationships___field_image___children___children = 'relationships___field_image___children___children',
  relationships___field_image___internal___content = 'relationships___field_image___internal___content',
  relationships___field_image___internal___contentDigest = 'relationships___field_image___internal___contentDigest',
  relationships___field_image___internal___description = 'relationships___field_image___internal___description',
  relationships___field_image___internal___fieldOwners = 'relationships___field_image___internal___fieldOwners',
  relationships___field_image___internal___ignoreType = 'relationships___field_image___internal___ignoreType',
  relationships___field_image___internal___mediaType = 'relationships___field_image___internal___mediaType',
  relationships___field_image___internal___owner = 'relationships___field_image___internal___owner',
  relationships___field_image___internal___type = 'relationships___field_image___internal___type',
  relationships___field_image_1___localFile___birthtime = 'relationships___field_image_1___localFile___birthtime',
  relationships___field_image_1___localFile___birthtimeMs = 'relationships___field_image_1___localFile___birthtimeMs',
  relationships___field_image_1___localFile___publicURL = 'relationships___field_image_1___localFile___publicURL',
  relationships___field_image_1___localFile___sourceInstanceName = 'relationships___field_image_1___localFile___sourceInstanceName',
  relationships___field_image_1___localFile___absolutePath = 'relationships___field_image_1___localFile___absolutePath',
  relationships___field_image_1___localFile___relativePath = 'relationships___field_image_1___localFile___relativePath',
  relationships___field_image_1___localFile___extension = 'relationships___field_image_1___localFile___extension',
  relationships___field_image_1___localFile___size = 'relationships___field_image_1___localFile___size',
  relationships___field_image_1___localFile___prettySize = 'relationships___field_image_1___localFile___prettySize',
  relationships___field_image_1___localFile___modifiedTime = 'relationships___field_image_1___localFile___modifiedTime',
  relationships___field_image_1___localFile___accessTime = 'relationships___field_image_1___localFile___accessTime',
  relationships___field_image_1___localFile___changeTime = 'relationships___field_image_1___localFile___changeTime',
  relationships___field_image_1___localFile___birthTime = 'relationships___field_image_1___localFile___birthTime',
  relationships___field_image_1___localFile___root = 'relationships___field_image_1___localFile___root',
  relationships___field_image_1___localFile___dir = 'relationships___field_image_1___localFile___dir',
  relationships___field_image_1___localFile___base = 'relationships___field_image_1___localFile___base',
  relationships___field_image_1___localFile___ext = 'relationships___field_image_1___localFile___ext',
  relationships___field_image_1___localFile___name = 'relationships___field_image_1___localFile___name',
  relationships___field_image_1___localFile___relativeDirectory = 'relationships___field_image_1___localFile___relativeDirectory',
  relationships___field_image_1___localFile___dev = 'relationships___field_image_1___localFile___dev',
  relationships___field_image_1___localFile___mode = 'relationships___field_image_1___localFile___mode',
  relationships___field_image_1___localFile___nlink = 'relationships___field_image_1___localFile___nlink',
  relationships___field_image_1___localFile___uid = 'relationships___field_image_1___localFile___uid',
  relationships___field_image_1___localFile___gid = 'relationships___field_image_1___localFile___gid',
  relationships___field_image_1___localFile___rdev = 'relationships___field_image_1___localFile___rdev',
  relationships___field_image_1___localFile___blksize = 'relationships___field_image_1___localFile___blksize',
  relationships___field_image_1___localFile___ino = 'relationships___field_image_1___localFile___ino',
  relationships___field_image_1___localFile___blocks = 'relationships___field_image_1___localFile___blocks',
  relationships___field_image_1___localFile___atimeMs = 'relationships___field_image_1___localFile___atimeMs',
  relationships___field_image_1___localFile___mtimeMs = 'relationships___field_image_1___localFile___mtimeMs',
  relationships___field_image_1___localFile___ctimeMs = 'relationships___field_image_1___localFile___ctimeMs',
  relationships___field_image_1___localFile___atime = 'relationships___field_image_1___localFile___atime',
  relationships___field_image_1___localFile___mtime = 'relationships___field_image_1___localFile___mtime',
  relationships___field_image_1___localFile___ctime = 'relationships___field_image_1___localFile___ctime',
  relationships___field_image_1___localFile___url = 'relationships___field_image_1___localFile___url',
  relationships___field_image_1___localFile___id = 'relationships___field_image_1___localFile___id',
  relationships___field_image_1___localFile___children = 'relationships___field_image_1___localFile___children',
  relationships___field_image_1___uri___url = 'relationships___field_image_1___uri___url',
  relationships___field_image_1___drupal_id = 'relationships___field_image_1___drupal_id',
  relationships___field_image_1___drupal_internal__fid = 'relationships___field_image_1___drupal_internal__fid',
  relationships___field_image_1___langcode = 'relationships___field_image_1___langcode',
  relationships___field_image_1___filename = 'relationships___field_image_1___filename',
  relationships___field_image_1___filemime = 'relationships___field_image_1___filemime',
  relationships___field_image_1___filesize = 'relationships___field_image_1___filesize',
  relationships___field_image_1___status = 'relationships___field_image_1___status',
  relationships___field_image_1___created = 'relationships___field_image_1___created',
  relationships___field_image_1___changed = 'relationships___field_image_1___changed',
  relationships___field_image_1___uuid = 'relationships___field_image_1___uuid',
  relationships___field_image_1___relationships___node__blog_post = 'relationships___field_image_1___relationships___node__blog_post',
  relationships___field_image_1___relationships___node__devices = 'relationships___field_image_1___relationships___node__devices',
  relationships___field_image_1___relationships___node__accessories = 'relationships___field_image_1___relationships___node__accessories',
  relationships___field_image_1___relationships___paragraph__news_image_title_text = 'relationships___field_image_1___relationships___paragraph__news_image_title_text',
  relationships___field_image_1___relationships___node__cases = 'relationships___field_image_1___relationships___node__cases',
  relationships___field_image_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_1___relationships___paragraph__branches_features = 'relationships___field_image_1___relationships___paragraph__branches_features',
  relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_1___relationships___paragraph__branches_testimonials = 'relationships___field_image_1___relationships___paragraph__branches_testimonials',
  relationships___field_image_1___relationships___paragraph__branches_discounts = 'relationships___field_image_1___relationships___paragraph__branches_discounts',
  relationships___field_image_1___relationships___node__news = 'relationships___field_image_1___relationships___node__news',
  relationships___field_image_1___relationships___node__branches = 'relationships___field_image_1___relationships___node__branches',
  relationships___field_image_1___relationships___paragraph__branches_hero = 'relationships___field_image_1___relationships___paragraph__branches_hero',
  relationships___field_image_1___relationships___paragraph__bullets = 'relationships___field_image_1___relationships___paragraph__bullets',
  relationships___field_image_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_1___relationships___paragraph__branches_device = 'relationships___field_image_1___relationships___paragraph__branches_device',
  relationships___field_image_1___relationships___paragraph__branches_recommendation = 'relationships___field_image_1___relationships___paragraph__branches_recommendation',
  relationships___field_image_1___relationships___site_setting_entity__accessories = 'relationships___field_image_1___relationships___site_setting_entity__accessories',
  relationships___field_image_1___relationships___site_setting_entity__branches = 'relationships___field_image_1___relationships___site_setting_entity__branches',
  relationships___field_image_1___relationships___site_setting_entity__about_us = 'relationships___field_image_1___relationships___site_setting_entity__about_us',
  relationships___field_image_1___relationships___paragraph__logos = 'relationships___field_image_1___relationships___paragraph__logos',
  relationships___field_image_1___relationships___paragraph__how_it_works = 'relationships___field_image_1___relationships___paragraph__how_it_works',
  relationships___field_image_1___relationships___paragraph__ico_and_text = 'relationships___field_image_1___relationships___paragraph__ico_and_text',
  relationships___field_image_1___relationships___paragraph__hero_1 = 'relationships___field_image_1___relationships___paragraph__hero_1',
  relationships___field_image_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_1___relationships___site_setting_entity__product = 'relationships___field_image_1___relationships___site_setting_entity__product',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_1___relationships___paragraph__porownanie_terminali = 'relationships___field_image_1___relationships___paragraph__porownanie_terminali',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_1___relationships___site_setting_entity__main_page = 'relationships___field_image_1___relationships___site_setting_entity__main_page',
  relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_1___relationships___site_setting_entity__intercars = 'relationships___field_image_1___relationships___site_setting_entity__intercars',
  relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_1___relationships___node__agreement = 'relationships___field_image_1___relationships___node__agreement',
  relationships___field_image_1___relationships___node__fiscalization = 'relationships___field_image_1___relationships___node__fiscalization',
  relationships___field_image_1___relationships___site_setting_entity__configurator = 'relationships___field_image_1___relationships___site_setting_entity__configurator',
  relationships___field_image_1___relationships___paragraph__social_link = 'relationships___field_image_1___relationships___paragraph__social_link',
  relationships___field_image_1___relationships___paragraph__make_an_appointment = 'relationships___field_image_1___relationships___paragraph__make_an_appointment',
  relationships___field_image_1___relationships___paragraph__linked_icons = 'relationships___field_image_1___relationships___paragraph__linked_icons',
  relationships___field_image_1___relationships___site_setting_entity__financing = 'relationships___field_image_1___relationships___site_setting_entity__financing',
  relationships___field_image_1___relationships___site_setting_entity__franchise = 'relationships___field_image_1___relationships___site_setting_entity__franchise',
  relationships___field_image_1___id = 'relationships___field_image_1___id',
  relationships___field_image_1___parent___id = 'relationships___field_image_1___parent___id',
  relationships___field_image_1___parent___children = 'relationships___field_image_1___parent___children',
  relationships___field_image_1___children = 'relationships___field_image_1___children',
  relationships___field_image_1___children___id = 'relationships___field_image_1___children___id',
  relationships___field_image_1___children___children = 'relationships___field_image_1___children___children',
  relationships___field_image_1___internal___content = 'relationships___field_image_1___internal___content',
  relationships___field_image_1___internal___contentDigest = 'relationships___field_image_1___internal___contentDigest',
  relationships___field_image_1___internal___description = 'relationships___field_image_1___internal___description',
  relationships___field_image_1___internal___fieldOwners = 'relationships___field_image_1___internal___fieldOwners',
  relationships___field_image_1___internal___ignoreType = 'relationships___field_image_1___internal___ignoreType',
  relationships___field_image_1___internal___mediaType = 'relationships___field_image_1___internal___mediaType',
  relationships___field_image_1___internal___owner = 'relationships___field_image_1___internal___owner',
  relationships___field_image_1___internal___type = 'relationships___field_image_1___internal___type',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___langcode = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___langcode',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_headline___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_headline___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_text___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_text___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_meta_description = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_meta_description',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_meta_title = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_meta_title',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_video = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_video',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_image___alt = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_image___alt',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_image_mobile___alt = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_image_mobile___alt',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_button_text = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_hero_button_text',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_opisy_wykresow = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_opisy_wykresow',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_settings_tresc___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_settings_tresc___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_1___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_1___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_2___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_2___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_3___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_3___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_4___value = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_text_4___value',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___drupal_id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___drupal_id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___drupal_internal__id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___drupal_internal__id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___name = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___name',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___fieldset = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___fieldset',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___status = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___status',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___created = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___created',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___changed = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___changed',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___default_langcode = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___default_langcode',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_source = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_source',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_outdated = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_outdated',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_status = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_status',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_created = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_created',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_changed = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___content_translation_changed',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___uuid = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___uuid',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components___target_revision_id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components___target_revision_id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___parent___id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___parent___id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___parent___children = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___parent___children',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children___id = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children___id',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children___children = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___children___children',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___content = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___content',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___contentDigest = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___contentDigest',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___description = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___description',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___fieldOwners = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___fieldOwners',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___ignoreType = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___ignoreType',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___mediaType = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___mediaType',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___owner = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___owner',
  relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___type = 'relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__2_Zdjecia_Opisy_LinkFilterInput = {
  field_label_1?: Maybe<StringQueryOperatorInput>;
  field_label_2?: Maybe<StringQueryOperatorInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_btn_url?: Maybe<StringQueryOperatorInput>;
  field_only_text?: Maybe<BooleanQueryOperatorInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  relationships?: Maybe<Paragraph__2_Zdjecia_Opisy_LinkRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__2_Zdjecia_Opisy_LinkFilterListInput = {
  elemMatch?: Maybe<Paragraph__2_Zdjecia_Opisy_LinkFilterInput>;
};

export type Paragraph__2_Zdjecia_Opisy_LinkGroupConnection = {
  __typename?: 'paragraph__2_zdjecia_opisy_linkGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__2_Zdjecia_Opisy_LinkEdge>;
  nodes: Array<Paragraph__2_Zdjecia_Opisy_Link>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__2_Zdjecia_Opisy_LinkRelationships = {
  __typename?: 'paragraph__2_zdjecia_opisy_linkRelationships';
  field_image?: Maybe<File__File>;
  field_image_1?: Maybe<File__File>;
  site_setting_entity__kasa_fiskalna_vs_kasoterminal?: Maybe<
    Array<Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal>>
  >;
};

export type Paragraph__2_Zdjecia_Opisy_LinkRelationshipsFilterInput = {
  field_image?: Maybe<File__FileFilterInput>;
  field_image_1?: Maybe<File__FileFilterInput>;
  site_setting_entity__kasa_fiskalna_vs_kasoterminal?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFilterListInput
  >;
};

export type Paragraph__2_Zdjecia_Opisy_LinkSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__2_Zdjecia_Opisy_LinkFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Check_What_S_Going_On = Node & {
  __typename?: 'paragraph__branches_check_what_s_going_on';
  internal: Internal;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_branches_text?: Maybe<Field_Branches_Text>;
  field_short_text_1?: Maybe<Field_Short_Text_1>;
  field_short_text_2?: Maybe<Field_Short_Text_2>;
  field_short_text_3?: Maybe<Field_Short_Text_3>;
  field_short_text_4?: Maybe<Field_Short_Text_4>;
  field_branches_button_text?: Maybe<Scalars['String']>;
  field_branches_image?: Maybe<Field_Branches_Image>;
  relationships?: Maybe<Paragraph__Branches_Check_What_S_Going_OnRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_ico_1?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_1>;
  field_ico_2?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_2>;
  field_ico_3?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_3>;
  field_ico_4?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_4>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_Check_What_S_Going_OnCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Check_What_S_Going_OnContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Check_What_S_Going_OnConnection = {
  __typename?: 'paragraph__branches_check_what_s_going_onConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_Check_What_S_Going_OnEdge>;
  nodes: Array<Paragraph__Branches_Check_What_S_Going_On>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_Check_What_S_Going_OnGroupConnection>;
};

export type Paragraph__Branches_Check_What_S_Going_OnConnectionDistinctArgs = {
  field: Paragraph__Branches_Check_What_S_Going_OnFieldsEnum;
};

export type Paragraph__Branches_Check_What_S_Going_OnConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_Check_What_S_Going_OnFieldsEnum;
};

export type Paragraph__Branches_Check_What_S_Going_OnEdge = {
  __typename?: 'paragraph__branches_check_what_s_going_onEdge';
  next?: Maybe<Paragraph__Branches_Check_What_S_Going_On>;
  node: Paragraph__Branches_Check_What_S_Going_On;
  previous?: Maybe<Paragraph__Branches_Check_What_S_Going_On>;
};

export type Paragraph__Branches_Check_What_S_Going_OnField_Ico_1 = {
  __typename?: 'paragraph__branches_check_what_s_going_onField_ico_1';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_Check_What_S_Going_OnField_Ico_1FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_Check_What_S_Going_OnField_Ico_2 = {
  __typename?: 'paragraph__branches_check_what_s_going_onField_ico_2';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_Check_What_S_Going_OnField_Ico_2FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_Check_What_S_Going_OnField_Ico_3 = {
  __typename?: 'paragraph__branches_check_what_s_going_onField_ico_3';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_Check_What_S_Going_OnField_Ico_3FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_Check_What_S_Going_OnField_Ico_4 = {
  __typename?: 'paragraph__branches_check_what_s_going_onField_ico_4';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_Check_What_S_Going_OnField_Ico_4FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Paragraph__Branches_Check_What_S_Going_OnFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  drupal_id = 'drupal_id',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_branches_text___value = 'field_branches_text___value',
  field_short_text_1___value = 'field_short_text_1___value',
  field_short_text_2___value = 'field_short_text_2___value',
  field_short_text_3___value = 'field_short_text_3___value',
  field_short_text_4___value = 'field_short_text_4___value',
  field_branches_button_text = 'field_branches_button_text',
  field_branches_image___alt = 'field_branches_image___alt',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___field_ico_1___localFile___birthtime = 'relationships___field_ico_1___localFile___birthtime',
  relationships___field_ico_1___localFile___birthtimeMs = 'relationships___field_ico_1___localFile___birthtimeMs',
  relationships___field_ico_1___localFile___publicURL = 'relationships___field_ico_1___localFile___publicURL',
  relationships___field_ico_1___localFile___sourceInstanceName = 'relationships___field_ico_1___localFile___sourceInstanceName',
  relationships___field_ico_1___localFile___absolutePath = 'relationships___field_ico_1___localFile___absolutePath',
  relationships___field_ico_1___localFile___relativePath = 'relationships___field_ico_1___localFile___relativePath',
  relationships___field_ico_1___localFile___extension = 'relationships___field_ico_1___localFile___extension',
  relationships___field_ico_1___localFile___size = 'relationships___field_ico_1___localFile___size',
  relationships___field_ico_1___localFile___prettySize = 'relationships___field_ico_1___localFile___prettySize',
  relationships___field_ico_1___localFile___modifiedTime = 'relationships___field_ico_1___localFile___modifiedTime',
  relationships___field_ico_1___localFile___accessTime = 'relationships___field_ico_1___localFile___accessTime',
  relationships___field_ico_1___localFile___changeTime = 'relationships___field_ico_1___localFile___changeTime',
  relationships___field_ico_1___localFile___birthTime = 'relationships___field_ico_1___localFile___birthTime',
  relationships___field_ico_1___localFile___root = 'relationships___field_ico_1___localFile___root',
  relationships___field_ico_1___localFile___dir = 'relationships___field_ico_1___localFile___dir',
  relationships___field_ico_1___localFile___base = 'relationships___field_ico_1___localFile___base',
  relationships___field_ico_1___localFile___ext = 'relationships___field_ico_1___localFile___ext',
  relationships___field_ico_1___localFile___name = 'relationships___field_ico_1___localFile___name',
  relationships___field_ico_1___localFile___relativeDirectory = 'relationships___field_ico_1___localFile___relativeDirectory',
  relationships___field_ico_1___localFile___dev = 'relationships___field_ico_1___localFile___dev',
  relationships___field_ico_1___localFile___mode = 'relationships___field_ico_1___localFile___mode',
  relationships___field_ico_1___localFile___nlink = 'relationships___field_ico_1___localFile___nlink',
  relationships___field_ico_1___localFile___uid = 'relationships___field_ico_1___localFile___uid',
  relationships___field_ico_1___localFile___gid = 'relationships___field_ico_1___localFile___gid',
  relationships___field_ico_1___localFile___rdev = 'relationships___field_ico_1___localFile___rdev',
  relationships___field_ico_1___localFile___blksize = 'relationships___field_ico_1___localFile___blksize',
  relationships___field_ico_1___localFile___ino = 'relationships___field_ico_1___localFile___ino',
  relationships___field_ico_1___localFile___blocks = 'relationships___field_ico_1___localFile___blocks',
  relationships___field_ico_1___localFile___atimeMs = 'relationships___field_ico_1___localFile___atimeMs',
  relationships___field_ico_1___localFile___mtimeMs = 'relationships___field_ico_1___localFile___mtimeMs',
  relationships___field_ico_1___localFile___ctimeMs = 'relationships___field_ico_1___localFile___ctimeMs',
  relationships___field_ico_1___localFile___atime = 'relationships___field_ico_1___localFile___atime',
  relationships___field_ico_1___localFile___mtime = 'relationships___field_ico_1___localFile___mtime',
  relationships___field_ico_1___localFile___ctime = 'relationships___field_ico_1___localFile___ctime',
  relationships___field_ico_1___localFile___url = 'relationships___field_ico_1___localFile___url',
  relationships___field_ico_1___localFile___id = 'relationships___field_ico_1___localFile___id',
  relationships___field_ico_1___localFile___children = 'relationships___field_ico_1___localFile___children',
  relationships___field_ico_1___uri___url = 'relationships___field_ico_1___uri___url',
  relationships___field_ico_1___drupal_id = 'relationships___field_ico_1___drupal_id',
  relationships___field_ico_1___drupal_internal__fid = 'relationships___field_ico_1___drupal_internal__fid',
  relationships___field_ico_1___langcode = 'relationships___field_ico_1___langcode',
  relationships___field_ico_1___filename = 'relationships___field_ico_1___filename',
  relationships___field_ico_1___filemime = 'relationships___field_ico_1___filemime',
  relationships___field_ico_1___filesize = 'relationships___field_ico_1___filesize',
  relationships___field_ico_1___status = 'relationships___field_ico_1___status',
  relationships___field_ico_1___created = 'relationships___field_ico_1___created',
  relationships___field_ico_1___changed = 'relationships___field_ico_1___changed',
  relationships___field_ico_1___uuid = 'relationships___field_ico_1___uuid',
  relationships___field_ico_1___relationships___node__blog_post = 'relationships___field_ico_1___relationships___node__blog_post',
  relationships___field_ico_1___relationships___node__devices = 'relationships___field_ico_1___relationships___node__devices',
  relationships___field_ico_1___relationships___node__accessories = 'relationships___field_ico_1___relationships___node__accessories',
  relationships___field_ico_1___relationships___paragraph__news_image_title_text = 'relationships___field_ico_1___relationships___paragraph__news_image_title_text',
  relationships___field_ico_1___relationships___node__cases = 'relationships___field_ico_1___relationships___node__cases',
  relationships___field_ico_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_1___relationships___paragraph__branches_features = 'relationships___field_ico_1___relationships___paragraph__branches_features',
  relationships___field_ico_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_1___relationships___paragraph__branches_testimonials = 'relationships___field_ico_1___relationships___paragraph__branches_testimonials',
  relationships___field_ico_1___relationships___paragraph__branches_discounts = 'relationships___field_ico_1___relationships___paragraph__branches_discounts',
  relationships___field_ico_1___relationships___node__news = 'relationships___field_ico_1___relationships___node__news',
  relationships___field_ico_1___relationships___node__branches = 'relationships___field_ico_1___relationships___node__branches',
  relationships___field_ico_1___relationships___paragraph__branches_hero = 'relationships___field_ico_1___relationships___paragraph__branches_hero',
  relationships___field_ico_1___relationships___paragraph__bullets = 'relationships___field_ico_1___relationships___paragraph__bullets',
  relationships___field_ico_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_1___relationships___paragraph__branches_device = 'relationships___field_ico_1___relationships___paragraph__branches_device',
  relationships___field_ico_1___relationships___paragraph__branches_recommendation = 'relationships___field_ico_1___relationships___paragraph__branches_recommendation',
  relationships___field_ico_1___relationships___site_setting_entity__accessories = 'relationships___field_ico_1___relationships___site_setting_entity__accessories',
  relationships___field_ico_1___relationships___site_setting_entity__branches = 'relationships___field_ico_1___relationships___site_setting_entity__branches',
  relationships___field_ico_1___relationships___site_setting_entity__about_us = 'relationships___field_ico_1___relationships___site_setting_entity__about_us',
  relationships___field_ico_1___relationships___paragraph__logos = 'relationships___field_ico_1___relationships___paragraph__logos',
  relationships___field_ico_1___relationships___paragraph__how_it_works = 'relationships___field_ico_1___relationships___paragraph__how_it_works',
  relationships___field_ico_1___relationships___paragraph__ico_and_text = 'relationships___field_ico_1___relationships___paragraph__ico_and_text',
  relationships___field_ico_1___relationships___paragraph__hero_1 = 'relationships___field_ico_1___relationships___paragraph__hero_1',
  relationships___field_ico_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_1___relationships___site_setting_entity__product = 'relationships___field_ico_1___relationships___site_setting_entity__product',
  relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_1___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_1___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_1___relationships___site_setting_entity__main_page = 'relationships___field_ico_1___relationships___site_setting_entity__main_page',
  relationships___field_ico_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_1___relationships___site_setting_entity__intercars = 'relationships___field_ico_1___relationships___site_setting_entity__intercars',
  relationships___field_ico_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_1___relationships___node__agreement = 'relationships___field_ico_1___relationships___node__agreement',
  relationships___field_ico_1___relationships___node__fiscalization = 'relationships___field_ico_1___relationships___node__fiscalization',
  relationships___field_ico_1___relationships___site_setting_entity__configurator = 'relationships___field_ico_1___relationships___site_setting_entity__configurator',
  relationships___field_ico_1___relationships___paragraph__social_link = 'relationships___field_ico_1___relationships___paragraph__social_link',
  relationships___field_ico_1___relationships___paragraph__make_an_appointment = 'relationships___field_ico_1___relationships___paragraph__make_an_appointment',
  relationships___field_ico_1___relationships___paragraph__linked_icons = 'relationships___field_ico_1___relationships___paragraph__linked_icons',
  relationships___field_ico_1___relationships___site_setting_entity__financing = 'relationships___field_ico_1___relationships___site_setting_entity__financing',
  relationships___field_ico_1___relationships___site_setting_entity__franchise = 'relationships___field_ico_1___relationships___site_setting_entity__franchise',
  relationships___field_ico_1___id = 'relationships___field_ico_1___id',
  relationships___field_ico_1___parent___id = 'relationships___field_ico_1___parent___id',
  relationships___field_ico_1___parent___children = 'relationships___field_ico_1___parent___children',
  relationships___field_ico_1___children = 'relationships___field_ico_1___children',
  relationships___field_ico_1___children___id = 'relationships___field_ico_1___children___id',
  relationships___field_ico_1___children___children = 'relationships___field_ico_1___children___children',
  relationships___field_ico_1___internal___content = 'relationships___field_ico_1___internal___content',
  relationships___field_ico_1___internal___contentDigest = 'relationships___field_ico_1___internal___contentDigest',
  relationships___field_ico_1___internal___description = 'relationships___field_ico_1___internal___description',
  relationships___field_ico_1___internal___fieldOwners = 'relationships___field_ico_1___internal___fieldOwners',
  relationships___field_ico_1___internal___ignoreType = 'relationships___field_ico_1___internal___ignoreType',
  relationships___field_ico_1___internal___mediaType = 'relationships___field_ico_1___internal___mediaType',
  relationships___field_ico_1___internal___owner = 'relationships___field_ico_1___internal___owner',
  relationships___field_ico_1___internal___type = 'relationships___field_ico_1___internal___type',
  relationships___field_ico_2___localFile___birthtime = 'relationships___field_ico_2___localFile___birthtime',
  relationships___field_ico_2___localFile___birthtimeMs = 'relationships___field_ico_2___localFile___birthtimeMs',
  relationships___field_ico_2___localFile___publicURL = 'relationships___field_ico_2___localFile___publicURL',
  relationships___field_ico_2___localFile___sourceInstanceName = 'relationships___field_ico_2___localFile___sourceInstanceName',
  relationships___field_ico_2___localFile___absolutePath = 'relationships___field_ico_2___localFile___absolutePath',
  relationships___field_ico_2___localFile___relativePath = 'relationships___field_ico_2___localFile___relativePath',
  relationships___field_ico_2___localFile___extension = 'relationships___field_ico_2___localFile___extension',
  relationships___field_ico_2___localFile___size = 'relationships___field_ico_2___localFile___size',
  relationships___field_ico_2___localFile___prettySize = 'relationships___field_ico_2___localFile___prettySize',
  relationships___field_ico_2___localFile___modifiedTime = 'relationships___field_ico_2___localFile___modifiedTime',
  relationships___field_ico_2___localFile___accessTime = 'relationships___field_ico_2___localFile___accessTime',
  relationships___field_ico_2___localFile___changeTime = 'relationships___field_ico_2___localFile___changeTime',
  relationships___field_ico_2___localFile___birthTime = 'relationships___field_ico_2___localFile___birthTime',
  relationships___field_ico_2___localFile___root = 'relationships___field_ico_2___localFile___root',
  relationships___field_ico_2___localFile___dir = 'relationships___field_ico_2___localFile___dir',
  relationships___field_ico_2___localFile___base = 'relationships___field_ico_2___localFile___base',
  relationships___field_ico_2___localFile___ext = 'relationships___field_ico_2___localFile___ext',
  relationships___field_ico_2___localFile___name = 'relationships___field_ico_2___localFile___name',
  relationships___field_ico_2___localFile___relativeDirectory = 'relationships___field_ico_2___localFile___relativeDirectory',
  relationships___field_ico_2___localFile___dev = 'relationships___field_ico_2___localFile___dev',
  relationships___field_ico_2___localFile___mode = 'relationships___field_ico_2___localFile___mode',
  relationships___field_ico_2___localFile___nlink = 'relationships___field_ico_2___localFile___nlink',
  relationships___field_ico_2___localFile___uid = 'relationships___field_ico_2___localFile___uid',
  relationships___field_ico_2___localFile___gid = 'relationships___field_ico_2___localFile___gid',
  relationships___field_ico_2___localFile___rdev = 'relationships___field_ico_2___localFile___rdev',
  relationships___field_ico_2___localFile___blksize = 'relationships___field_ico_2___localFile___blksize',
  relationships___field_ico_2___localFile___ino = 'relationships___field_ico_2___localFile___ino',
  relationships___field_ico_2___localFile___blocks = 'relationships___field_ico_2___localFile___blocks',
  relationships___field_ico_2___localFile___atimeMs = 'relationships___field_ico_2___localFile___atimeMs',
  relationships___field_ico_2___localFile___mtimeMs = 'relationships___field_ico_2___localFile___mtimeMs',
  relationships___field_ico_2___localFile___ctimeMs = 'relationships___field_ico_2___localFile___ctimeMs',
  relationships___field_ico_2___localFile___atime = 'relationships___field_ico_2___localFile___atime',
  relationships___field_ico_2___localFile___mtime = 'relationships___field_ico_2___localFile___mtime',
  relationships___field_ico_2___localFile___ctime = 'relationships___field_ico_2___localFile___ctime',
  relationships___field_ico_2___localFile___url = 'relationships___field_ico_2___localFile___url',
  relationships___field_ico_2___localFile___id = 'relationships___field_ico_2___localFile___id',
  relationships___field_ico_2___localFile___children = 'relationships___field_ico_2___localFile___children',
  relationships___field_ico_2___uri___url = 'relationships___field_ico_2___uri___url',
  relationships___field_ico_2___drupal_id = 'relationships___field_ico_2___drupal_id',
  relationships___field_ico_2___drupal_internal__fid = 'relationships___field_ico_2___drupal_internal__fid',
  relationships___field_ico_2___langcode = 'relationships___field_ico_2___langcode',
  relationships___field_ico_2___filename = 'relationships___field_ico_2___filename',
  relationships___field_ico_2___filemime = 'relationships___field_ico_2___filemime',
  relationships___field_ico_2___filesize = 'relationships___field_ico_2___filesize',
  relationships___field_ico_2___status = 'relationships___field_ico_2___status',
  relationships___field_ico_2___created = 'relationships___field_ico_2___created',
  relationships___field_ico_2___changed = 'relationships___field_ico_2___changed',
  relationships___field_ico_2___uuid = 'relationships___field_ico_2___uuid',
  relationships___field_ico_2___relationships___node__blog_post = 'relationships___field_ico_2___relationships___node__blog_post',
  relationships___field_ico_2___relationships___node__devices = 'relationships___field_ico_2___relationships___node__devices',
  relationships___field_ico_2___relationships___node__accessories = 'relationships___field_ico_2___relationships___node__accessories',
  relationships___field_ico_2___relationships___paragraph__news_image_title_text = 'relationships___field_ico_2___relationships___paragraph__news_image_title_text',
  relationships___field_ico_2___relationships___node__cases = 'relationships___field_ico_2___relationships___node__cases',
  relationships___field_ico_2___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_2___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_2___relationships___paragraph__branches_features = 'relationships___field_ico_2___relationships___paragraph__branches_features',
  relationships___field_ico_2___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_2___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_2___relationships___paragraph__branches_testimonials = 'relationships___field_ico_2___relationships___paragraph__branches_testimonials',
  relationships___field_ico_2___relationships___paragraph__branches_discounts = 'relationships___field_ico_2___relationships___paragraph__branches_discounts',
  relationships___field_ico_2___relationships___node__news = 'relationships___field_ico_2___relationships___node__news',
  relationships___field_ico_2___relationships___node__branches = 'relationships___field_ico_2___relationships___node__branches',
  relationships___field_ico_2___relationships___paragraph__branches_hero = 'relationships___field_ico_2___relationships___paragraph__branches_hero',
  relationships___field_ico_2___relationships___paragraph__bullets = 'relationships___field_ico_2___relationships___paragraph__bullets',
  relationships___field_ico_2___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_2___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_2___relationships___paragraph__branches_device = 'relationships___field_ico_2___relationships___paragraph__branches_device',
  relationships___field_ico_2___relationships___paragraph__branches_recommendation = 'relationships___field_ico_2___relationships___paragraph__branches_recommendation',
  relationships___field_ico_2___relationships___site_setting_entity__accessories = 'relationships___field_ico_2___relationships___site_setting_entity__accessories',
  relationships___field_ico_2___relationships___site_setting_entity__branches = 'relationships___field_ico_2___relationships___site_setting_entity__branches',
  relationships___field_ico_2___relationships___site_setting_entity__about_us = 'relationships___field_ico_2___relationships___site_setting_entity__about_us',
  relationships___field_ico_2___relationships___paragraph__logos = 'relationships___field_ico_2___relationships___paragraph__logos',
  relationships___field_ico_2___relationships___paragraph__how_it_works = 'relationships___field_ico_2___relationships___paragraph__how_it_works',
  relationships___field_ico_2___relationships___paragraph__ico_and_text = 'relationships___field_ico_2___relationships___paragraph__ico_and_text',
  relationships___field_ico_2___relationships___paragraph__hero_1 = 'relationships___field_ico_2___relationships___paragraph__hero_1',
  relationships___field_ico_2___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_2___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_2___relationships___site_setting_entity__product = 'relationships___field_ico_2___relationships___site_setting_entity__product',
  relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_2___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_2___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_2___relationships___site_setting_entity__main_page = 'relationships___field_ico_2___relationships___site_setting_entity__main_page',
  relationships___field_ico_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_2___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_2___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_2___relationships___site_setting_entity__intercars = 'relationships___field_ico_2___relationships___site_setting_entity__intercars',
  relationships___field_ico_2___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_2___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_2___relationships___node__agreement = 'relationships___field_ico_2___relationships___node__agreement',
  relationships___field_ico_2___relationships___node__fiscalization = 'relationships___field_ico_2___relationships___node__fiscalization',
  relationships___field_ico_2___relationships___site_setting_entity__configurator = 'relationships___field_ico_2___relationships___site_setting_entity__configurator',
  relationships___field_ico_2___relationships___paragraph__social_link = 'relationships___field_ico_2___relationships___paragraph__social_link',
  relationships___field_ico_2___relationships___paragraph__make_an_appointment = 'relationships___field_ico_2___relationships___paragraph__make_an_appointment',
  relationships___field_ico_2___relationships___paragraph__linked_icons = 'relationships___field_ico_2___relationships___paragraph__linked_icons',
  relationships___field_ico_2___relationships___site_setting_entity__financing = 'relationships___field_ico_2___relationships___site_setting_entity__financing',
  relationships___field_ico_2___relationships___site_setting_entity__franchise = 'relationships___field_ico_2___relationships___site_setting_entity__franchise',
  relationships___field_ico_2___id = 'relationships___field_ico_2___id',
  relationships___field_ico_2___parent___id = 'relationships___field_ico_2___parent___id',
  relationships___field_ico_2___parent___children = 'relationships___field_ico_2___parent___children',
  relationships___field_ico_2___children = 'relationships___field_ico_2___children',
  relationships___field_ico_2___children___id = 'relationships___field_ico_2___children___id',
  relationships___field_ico_2___children___children = 'relationships___field_ico_2___children___children',
  relationships___field_ico_2___internal___content = 'relationships___field_ico_2___internal___content',
  relationships___field_ico_2___internal___contentDigest = 'relationships___field_ico_2___internal___contentDigest',
  relationships___field_ico_2___internal___description = 'relationships___field_ico_2___internal___description',
  relationships___field_ico_2___internal___fieldOwners = 'relationships___field_ico_2___internal___fieldOwners',
  relationships___field_ico_2___internal___ignoreType = 'relationships___field_ico_2___internal___ignoreType',
  relationships___field_ico_2___internal___mediaType = 'relationships___field_ico_2___internal___mediaType',
  relationships___field_ico_2___internal___owner = 'relationships___field_ico_2___internal___owner',
  relationships___field_ico_2___internal___type = 'relationships___field_ico_2___internal___type',
  relationships___field_ico_3___localFile___birthtime = 'relationships___field_ico_3___localFile___birthtime',
  relationships___field_ico_3___localFile___birthtimeMs = 'relationships___field_ico_3___localFile___birthtimeMs',
  relationships___field_ico_3___localFile___publicURL = 'relationships___field_ico_3___localFile___publicURL',
  relationships___field_ico_3___localFile___sourceInstanceName = 'relationships___field_ico_3___localFile___sourceInstanceName',
  relationships___field_ico_3___localFile___absolutePath = 'relationships___field_ico_3___localFile___absolutePath',
  relationships___field_ico_3___localFile___relativePath = 'relationships___field_ico_3___localFile___relativePath',
  relationships___field_ico_3___localFile___extension = 'relationships___field_ico_3___localFile___extension',
  relationships___field_ico_3___localFile___size = 'relationships___field_ico_3___localFile___size',
  relationships___field_ico_3___localFile___prettySize = 'relationships___field_ico_3___localFile___prettySize',
  relationships___field_ico_3___localFile___modifiedTime = 'relationships___field_ico_3___localFile___modifiedTime',
  relationships___field_ico_3___localFile___accessTime = 'relationships___field_ico_3___localFile___accessTime',
  relationships___field_ico_3___localFile___changeTime = 'relationships___field_ico_3___localFile___changeTime',
  relationships___field_ico_3___localFile___birthTime = 'relationships___field_ico_3___localFile___birthTime',
  relationships___field_ico_3___localFile___root = 'relationships___field_ico_3___localFile___root',
  relationships___field_ico_3___localFile___dir = 'relationships___field_ico_3___localFile___dir',
  relationships___field_ico_3___localFile___base = 'relationships___field_ico_3___localFile___base',
  relationships___field_ico_3___localFile___ext = 'relationships___field_ico_3___localFile___ext',
  relationships___field_ico_3___localFile___name = 'relationships___field_ico_3___localFile___name',
  relationships___field_ico_3___localFile___relativeDirectory = 'relationships___field_ico_3___localFile___relativeDirectory',
  relationships___field_ico_3___localFile___dev = 'relationships___field_ico_3___localFile___dev',
  relationships___field_ico_3___localFile___mode = 'relationships___field_ico_3___localFile___mode',
  relationships___field_ico_3___localFile___nlink = 'relationships___field_ico_3___localFile___nlink',
  relationships___field_ico_3___localFile___uid = 'relationships___field_ico_3___localFile___uid',
  relationships___field_ico_3___localFile___gid = 'relationships___field_ico_3___localFile___gid',
  relationships___field_ico_3___localFile___rdev = 'relationships___field_ico_3___localFile___rdev',
  relationships___field_ico_3___localFile___blksize = 'relationships___field_ico_3___localFile___blksize',
  relationships___field_ico_3___localFile___ino = 'relationships___field_ico_3___localFile___ino',
  relationships___field_ico_3___localFile___blocks = 'relationships___field_ico_3___localFile___blocks',
  relationships___field_ico_3___localFile___atimeMs = 'relationships___field_ico_3___localFile___atimeMs',
  relationships___field_ico_3___localFile___mtimeMs = 'relationships___field_ico_3___localFile___mtimeMs',
  relationships___field_ico_3___localFile___ctimeMs = 'relationships___field_ico_3___localFile___ctimeMs',
  relationships___field_ico_3___localFile___atime = 'relationships___field_ico_3___localFile___atime',
  relationships___field_ico_3___localFile___mtime = 'relationships___field_ico_3___localFile___mtime',
  relationships___field_ico_3___localFile___ctime = 'relationships___field_ico_3___localFile___ctime',
  relationships___field_ico_3___localFile___url = 'relationships___field_ico_3___localFile___url',
  relationships___field_ico_3___localFile___id = 'relationships___field_ico_3___localFile___id',
  relationships___field_ico_3___localFile___children = 'relationships___field_ico_3___localFile___children',
  relationships___field_ico_3___uri___url = 'relationships___field_ico_3___uri___url',
  relationships___field_ico_3___drupal_id = 'relationships___field_ico_3___drupal_id',
  relationships___field_ico_3___drupal_internal__fid = 'relationships___field_ico_3___drupal_internal__fid',
  relationships___field_ico_3___langcode = 'relationships___field_ico_3___langcode',
  relationships___field_ico_3___filename = 'relationships___field_ico_3___filename',
  relationships___field_ico_3___filemime = 'relationships___field_ico_3___filemime',
  relationships___field_ico_3___filesize = 'relationships___field_ico_3___filesize',
  relationships___field_ico_3___status = 'relationships___field_ico_3___status',
  relationships___field_ico_3___created = 'relationships___field_ico_3___created',
  relationships___field_ico_3___changed = 'relationships___field_ico_3___changed',
  relationships___field_ico_3___uuid = 'relationships___field_ico_3___uuid',
  relationships___field_ico_3___relationships___node__blog_post = 'relationships___field_ico_3___relationships___node__blog_post',
  relationships___field_ico_3___relationships___node__devices = 'relationships___field_ico_3___relationships___node__devices',
  relationships___field_ico_3___relationships___node__accessories = 'relationships___field_ico_3___relationships___node__accessories',
  relationships___field_ico_3___relationships___paragraph__news_image_title_text = 'relationships___field_ico_3___relationships___paragraph__news_image_title_text',
  relationships___field_ico_3___relationships___node__cases = 'relationships___field_ico_3___relationships___node__cases',
  relationships___field_ico_3___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_3___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_3___relationships___paragraph__branches_features = 'relationships___field_ico_3___relationships___paragraph__branches_features',
  relationships___field_ico_3___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_3___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_3___relationships___paragraph__branches_testimonials = 'relationships___field_ico_3___relationships___paragraph__branches_testimonials',
  relationships___field_ico_3___relationships___paragraph__branches_discounts = 'relationships___field_ico_3___relationships___paragraph__branches_discounts',
  relationships___field_ico_3___relationships___node__news = 'relationships___field_ico_3___relationships___node__news',
  relationships___field_ico_3___relationships___node__branches = 'relationships___field_ico_3___relationships___node__branches',
  relationships___field_ico_3___relationships___paragraph__branches_hero = 'relationships___field_ico_3___relationships___paragraph__branches_hero',
  relationships___field_ico_3___relationships___paragraph__bullets = 'relationships___field_ico_3___relationships___paragraph__bullets',
  relationships___field_ico_3___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_3___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_3___relationships___paragraph__branches_device = 'relationships___field_ico_3___relationships___paragraph__branches_device',
  relationships___field_ico_3___relationships___paragraph__branches_recommendation = 'relationships___field_ico_3___relationships___paragraph__branches_recommendation',
  relationships___field_ico_3___relationships___site_setting_entity__accessories = 'relationships___field_ico_3___relationships___site_setting_entity__accessories',
  relationships___field_ico_3___relationships___site_setting_entity__branches = 'relationships___field_ico_3___relationships___site_setting_entity__branches',
  relationships___field_ico_3___relationships___site_setting_entity__about_us = 'relationships___field_ico_3___relationships___site_setting_entity__about_us',
  relationships___field_ico_3___relationships___paragraph__logos = 'relationships___field_ico_3___relationships___paragraph__logos',
  relationships___field_ico_3___relationships___paragraph__how_it_works = 'relationships___field_ico_3___relationships___paragraph__how_it_works',
  relationships___field_ico_3___relationships___paragraph__ico_and_text = 'relationships___field_ico_3___relationships___paragraph__ico_and_text',
  relationships___field_ico_3___relationships___paragraph__hero_1 = 'relationships___field_ico_3___relationships___paragraph__hero_1',
  relationships___field_ico_3___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_3___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_3___relationships___site_setting_entity__product = 'relationships___field_ico_3___relationships___site_setting_entity__product',
  relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_3___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_3___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_3___relationships___site_setting_entity__main_page = 'relationships___field_ico_3___relationships___site_setting_entity__main_page',
  relationships___field_ico_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_3___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_3___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_3___relationships___site_setting_entity__intercars = 'relationships___field_ico_3___relationships___site_setting_entity__intercars',
  relationships___field_ico_3___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_3___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_3___relationships___node__agreement = 'relationships___field_ico_3___relationships___node__agreement',
  relationships___field_ico_3___relationships___node__fiscalization = 'relationships___field_ico_3___relationships___node__fiscalization',
  relationships___field_ico_3___relationships___site_setting_entity__configurator = 'relationships___field_ico_3___relationships___site_setting_entity__configurator',
  relationships___field_ico_3___relationships___paragraph__social_link = 'relationships___field_ico_3___relationships___paragraph__social_link',
  relationships___field_ico_3___relationships___paragraph__make_an_appointment = 'relationships___field_ico_3___relationships___paragraph__make_an_appointment',
  relationships___field_ico_3___relationships___paragraph__linked_icons = 'relationships___field_ico_3___relationships___paragraph__linked_icons',
  relationships___field_ico_3___relationships___site_setting_entity__financing = 'relationships___field_ico_3___relationships___site_setting_entity__financing',
  relationships___field_ico_3___relationships___site_setting_entity__franchise = 'relationships___field_ico_3___relationships___site_setting_entity__franchise',
  relationships___field_ico_3___id = 'relationships___field_ico_3___id',
  relationships___field_ico_3___parent___id = 'relationships___field_ico_3___parent___id',
  relationships___field_ico_3___parent___children = 'relationships___field_ico_3___parent___children',
  relationships___field_ico_3___children = 'relationships___field_ico_3___children',
  relationships___field_ico_3___children___id = 'relationships___field_ico_3___children___id',
  relationships___field_ico_3___children___children = 'relationships___field_ico_3___children___children',
  relationships___field_ico_3___internal___content = 'relationships___field_ico_3___internal___content',
  relationships___field_ico_3___internal___contentDigest = 'relationships___field_ico_3___internal___contentDigest',
  relationships___field_ico_3___internal___description = 'relationships___field_ico_3___internal___description',
  relationships___field_ico_3___internal___fieldOwners = 'relationships___field_ico_3___internal___fieldOwners',
  relationships___field_ico_3___internal___ignoreType = 'relationships___field_ico_3___internal___ignoreType',
  relationships___field_ico_3___internal___mediaType = 'relationships___field_ico_3___internal___mediaType',
  relationships___field_ico_3___internal___owner = 'relationships___field_ico_3___internal___owner',
  relationships___field_ico_3___internal___type = 'relationships___field_ico_3___internal___type',
  relationships___field_ico_4___localFile___birthtime = 'relationships___field_ico_4___localFile___birthtime',
  relationships___field_ico_4___localFile___birthtimeMs = 'relationships___field_ico_4___localFile___birthtimeMs',
  relationships___field_ico_4___localFile___publicURL = 'relationships___field_ico_4___localFile___publicURL',
  relationships___field_ico_4___localFile___sourceInstanceName = 'relationships___field_ico_4___localFile___sourceInstanceName',
  relationships___field_ico_4___localFile___absolutePath = 'relationships___field_ico_4___localFile___absolutePath',
  relationships___field_ico_4___localFile___relativePath = 'relationships___field_ico_4___localFile___relativePath',
  relationships___field_ico_4___localFile___extension = 'relationships___field_ico_4___localFile___extension',
  relationships___field_ico_4___localFile___size = 'relationships___field_ico_4___localFile___size',
  relationships___field_ico_4___localFile___prettySize = 'relationships___field_ico_4___localFile___prettySize',
  relationships___field_ico_4___localFile___modifiedTime = 'relationships___field_ico_4___localFile___modifiedTime',
  relationships___field_ico_4___localFile___accessTime = 'relationships___field_ico_4___localFile___accessTime',
  relationships___field_ico_4___localFile___changeTime = 'relationships___field_ico_4___localFile___changeTime',
  relationships___field_ico_4___localFile___birthTime = 'relationships___field_ico_4___localFile___birthTime',
  relationships___field_ico_4___localFile___root = 'relationships___field_ico_4___localFile___root',
  relationships___field_ico_4___localFile___dir = 'relationships___field_ico_4___localFile___dir',
  relationships___field_ico_4___localFile___base = 'relationships___field_ico_4___localFile___base',
  relationships___field_ico_4___localFile___ext = 'relationships___field_ico_4___localFile___ext',
  relationships___field_ico_4___localFile___name = 'relationships___field_ico_4___localFile___name',
  relationships___field_ico_4___localFile___relativeDirectory = 'relationships___field_ico_4___localFile___relativeDirectory',
  relationships___field_ico_4___localFile___dev = 'relationships___field_ico_4___localFile___dev',
  relationships___field_ico_4___localFile___mode = 'relationships___field_ico_4___localFile___mode',
  relationships___field_ico_4___localFile___nlink = 'relationships___field_ico_4___localFile___nlink',
  relationships___field_ico_4___localFile___uid = 'relationships___field_ico_4___localFile___uid',
  relationships___field_ico_4___localFile___gid = 'relationships___field_ico_4___localFile___gid',
  relationships___field_ico_4___localFile___rdev = 'relationships___field_ico_4___localFile___rdev',
  relationships___field_ico_4___localFile___blksize = 'relationships___field_ico_4___localFile___blksize',
  relationships___field_ico_4___localFile___ino = 'relationships___field_ico_4___localFile___ino',
  relationships___field_ico_4___localFile___blocks = 'relationships___field_ico_4___localFile___blocks',
  relationships___field_ico_4___localFile___atimeMs = 'relationships___field_ico_4___localFile___atimeMs',
  relationships___field_ico_4___localFile___mtimeMs = 'relationships___field_ico_4___localFile___mtimeMs',
  relationships___field_ico_4___localFile___ctimeMs = 'relationships___field_ico_4___localFile___ctimeMs',
  relationships___field_ico_4___localFile___atime = 'relationships___field_ico_4___localFile___atime',
  relationships___field_ico_4___localFile___mtime = 'relationships___field_ico_4___localFile___mtime',
  relationships___field_ico_4___localFile___ctime = 'relationships___field_ico_4___localFile___ctime',
  relationships___field_ico_4___localFile___url = 'relationships___field_ico_4___localFile___url',
  relationships___field_ico_4___localFile___id = 'relationships___field_ico_4___localFile___id',
  relationships___field_ico_4___localFile___children = 'relationships___field_ico_4___localFile___children',
  relationships___field_ico_4___uri___url = 'relationships___field_ico_4___uri___url',
  relationships___field_ico_4___drupal_id = 'relationships___field_ico_4___drupal_id',
  relationships___field_ico_4___drupal_internal__fid = 'relationships___field_ico_4___drupal_internal__fid',
  relationships___field_ico_4___langcode = 'relationships___field_ico_4___langcode',
  relationships___field_ico_4___filename = 'relationships___field_ico_4___filename',
  relationships___field_ico_4___filemime = 'relationships___field_ico_4___filemime',
  relationships___field_ico_4___filesize = 'relationships___field_ico_4___filesize',
  relationships___field_ico_4___status = 'relationships___field_ico_4___status',
  relationships___field_ico_4___created = 'relationships___field_ico_4___created',
  relationships___field_ico_4___changed = 'relationships___field_ico_4___changed',
  relationships___field_ico_4___uuid = 'relationships___field_ico_4___uuid',
  relationships___field_ico_4___relationships___node__blog_post = 'relationships___field_ico_4___relationships___node__blog_post',
  relationships___field_ico_4___relationships___node__devices = 'relationships___field_ico_4___relationships___node__devices',
  relationships___field_ico_4___relationships___node__accessories = 'relationships___field_ico_4___relationships___node__accessories',
  relationships___field_ico_4___relationships___paragraph__news_image_title_text = 'relationships___field_ico_4___relationships___paragraph__news_image_title_text',
  relationships___field_ico_4___relationships___node__cases = 'relationships___field_ico_4___relationships___node__cases',
  relationships___field_ico_4___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_4___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_4___relationships___paragraph__branches_features = 'relationships___field_ico_4___relationships___paragraph__branches_features',
  relationships___field_ico_4___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_4___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_4___relationships___paragraph__branches_testimonials = 'relationships___field_ico_4___relationships___paragraph__branches_testimonials',
  relationships___field_ico_4___relationships___paragraph__branches_discounts = 'relationships___field_ico_4___relationships___paragraph__branches_discounts',
  relationships___field_ico_4___relationships___node__news = 'relationships___field_ico_4___relationships___node__news',
  relationships___field_ico_4___relationships___node__branches = 'relationships___field_ico_4___relationships___node__branches',
  relationships___field_ico_4___relationships___paragraph__branches_hero = 'relationships___field_ico_4___relationships___paragraph__branches_hero',
  relationships___field_ico_4___relationships___paragraph__bullets = 'relationships___field_ico_4___relationships___paragraph__bullets',
  relationships___field_ico_4___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_4___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_4___relationships___paragraph__branches_device = 'relationships___field_ico_4___relationships___paragraph__branches_device',
  relationships___field_ico_4___relationships___paragraph__branches_recommendation = 'relationships___field_ico_4___relationships___paragraph__branches_recommendation',
  relationships___field_ico_4___relationships___site_setting_entity__accessories = 'relationships___field_ico_4___relationships___site_setting_entity__accessories',
  relationships___field_ico_4___relationships___site_setting_entity__branches = 'relationships___field_ico_4___relationships___site_setting_entity__branches',
  relationships___field_ico_4___relationships___site_setting_entity__about_us = 'relationships___field_ico_4___relationships___site_setting_entity__about_us',
  relationships___field_ico_4___relationships___paragraph__logos = 'relationships___field_ico_4___relationships___paragraph__logos',
  relationships___field_ico_4___relationships___paragraph__how_it_works = 'relationships___field_ico_4___relationships___paragraph__how_it_works',
  relationships___field_ico_4___relationships___paragraph__ico_and_text = 'relationships___field_ico_4___relationships___paragraph__ico_and_text',
  relationships___field_ico_4___relationships___paragraph__hero_1 = 'relationships___field_ico_4___relationships___paragraph__hero_1',
  relationships___field_ico_4___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_4___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_4___relationships___site_setting_entity__product = 'relationships___field_ico_4___relationships___site_setting_entity__product',
  relationships___field_ico_4___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_4___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_4___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_4___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_4___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_4___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_4___relationships___site_setting_entity__main_page = 'relationships___field_ico_4___relationships___site_setting_entity__main_page',
  relationships___field_ico_4___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_4___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_4___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_4___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_4___relationships___site_setting_entity__intercars = 'relationships___field_ico_4___relationships___site_setting_entity__intercars',
  relationships___field_ico_4___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_4___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_4___relationships___node__agreement = 'relationships___field_ico_4___relationships___node__agreement',
  relationships___field_ico_4___relationships___node__fiscalization = 'relationships___field_ico_4___relationships___node__fiscalization',
  relationships___field_ico_4___relationships___site_setting_entity__configurator = 'relationships___field_ico_4___relationships___site_setting_entity__configurator',
  relationships___field_ico_4___relationships___paragraph__social_link = 'relationships___field_ico_4___relationships___paragraph__social_link',
  relationships___field_ico_4___relationships___paragraph__make_an_appointment = 'relationships___field_ico_4___relationships___paragraph__make_an_appointment',
  relationships___field_ico_4___relationships___paragraph__linked_icons = 'relationships___field_ico_4___relationships___paragraph__linked_icons',
  relationships___field_ico_4___relationships___site_setting_entity__financing = 'relationships___field_ico_4___relationships___site_setting_entity__financing',
  relationships___field_ico_4___relationships___site_setting_entity__franchise = 'relationships___field_ico_4___relationships___site_setting_entity__franchise',
  relationships___field_ico_4___id = 'relationships___field_ico_4___id',
  relationships___field_ico_4___parent___id = 'relationships___field_ico_4___parent___id',
  relationships___field_ico_4___parent___children = 'relationships___field_ico_4___parent___children',
  relationships___field_ico_4___children = 'relationships___field_ico_4___children',
  relationships___field_ico_4___children___id = 'relationships___field_ico_4___children___id',
  relationships___field_ico_4___children___children = 'relationships___field_ico_4___children___children',
  relationships___field_ico_4___internal___content = 'relationships___field_ico_4___internal___content',
  relationships___field_ico_4___internal___contentDigest = 'relationships___field_ico_4___internal___contentDigest',
  relationships___field_ico_4___internal___description = 'relationships___field_ico_4___internal___description',
  relationships___field_ico_4___internal___fieldOwners = 'relationships___field_ico_4___internal___fieldOwners',
  relationships___field_ico_4___internal___ignoreType = 'relationships___field_ico_4___internal___ignoreType',
  relationships___field_ico_4___internal___mediaType = 'relationships___field_ico_4___internal___mediaType',
  relationships___field_ico_4___internal___owner = 'relationships___field_ico_4___internal___owner',
  relationships___field_ico_4___internal___type = 'relationships___field_ico_4___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_ico_1___alt = 'field_ico_1___alt',
  field_ico_1___title = 'field_ico_1___title',
  field_ico_1___width = 'field_ico_1___width',
  field_ico_1___height = 'field_ico_1___height',
  field_ico_1___drupal_internal__target_id = 'field_ico_1___drupal_internal__target_id',
  field_ico_2___alt = 'field_ico_2___alt',
  field_ico_2___title = 'field_ico_2___title',
  field_ico_2___width = 'field_ico_2___width',
  field_ico_2___height = 'field_ico_2___height',
  field_ico_2___drupal_internal__target_id = 'field_ico_2___drupal_internal__target_id',
  field_ico_3___alt = 'field_ico_3___alt',
  field_ico_3___title = 'field_ico_3___title',
  field_ico_3___width = 'field_ico_3___width',
  field_ico_3___height = 'field_ico_3___height',
  field_ico_3___drupal_internal__target_id = 'field_ico_3___drupal_internal__target_id',
  field_ico_4___alt = 'field_ico_4___alt',
  field_ico_4___title = 'field_ico_4___title',
  field_ico_4___width = 'field_ico_4___width',
  field_ico_4___height = 'field_ico_4___height',
  field_ico_4___drupal_internal__target_id = 'field_ico_4___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_Check_What_S_Going_OnFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  field_short_text_2?: Maybe<Field_Short_Text_2FilterInput>;
  field_short_text_3?: Maybe<Field_Short_Text_3FilterInput>;
  field_short_text_4?: Maybe<Field_Short_Text_4FilterInput>;
  field_branches_button_text?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Field_Branches_ImageFilterInput>;
  relationships?: Maybe<Paragraph__Branches_Check_What_S_Going_OnRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_ico_1?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_1FilterInput>;
  field_ico_2?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_2FilterInput>;
  field_ico_3?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_3FilterInput>;
  field_ico_4?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_4FilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_Check_What_S_Going_OnFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_Check_What_S_Going_OnFilterInput>;
};

export type Paragraph__Branches_Check_What_S_Going_OnGroupConnection = {
  __typename?: 'paragraph__branches_check_what_s_going_onGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_Check_What_S_Going_OnEdge>;
  nodes: Array<Paragraph__Branches_Check_What_S_Going_On>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Check_What_S_Going_OnRelationships = {
  __typename?: 'paragraph__branches_check_what_s_going_onRelationships';
  field_branches_image?: Maybe<File__File>;
  field_ico_1?: Maybe<File__File>;
  field_ico_2?: Maybe<File__File>;
  field_ico_3?: Maybe<File__File>;
  field_ico_4?: Maybe<File__File>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
};

export type Paragraph__Branches_Check_What_S_Going_OnRelationshipsFilterInput = {
  field_branches_image?: Maybe<File__FileFilterInput>;
  field_ico_1?: Maybe<File__FileFilterInput>;
  field_ico_2?: Maybe<File__FileFilterInput>;
  field_ico_3?: Maybe<File__FileFilterInput>;
  field_ico_4?: Maybe<File__FileFilterInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
};

export type Paragraph__Branches_Check_What_S_Going_OnSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_Check_What_S_Going_OnFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Device = Node & {
  __typename?: 'paragraph__branches_device';
  field_branches_name?: Maybe<Scalars['String']>;
  field_recommended?: Maybe<Scalars['Boolean']>;
  field_button_text_recommended?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Branches_DeviceRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  field_nc_button_text?: Maybe<Scalars['String']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_image?: Maybe<Paragraph__Branches_DeviceField_Branches_Image>;
  field_branches_profits?: Maybe<Array<Maybe<Paragraph__Branches_DeviceField_Branches_Profits>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Branches_DeviceCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_DeviceConnection = {
  __typename?: 'paragraph__branches_deviceConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_DeviceEdge>;
  nodes: Array<Paragraph__Branches_Device>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_DeviceGroupConnection>;
};

export type Paragraph__Branches_DeviceConnectionDistinctArgs = {
  field: Paragraph__Branches_DeviceFieldsEnum;
};

export type Paragraph__Branches_DeviceConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_DeviceFieldsEnum;
};

export type Paragraph__Branches_DeviceEdge = {
  __typename?: 'paragraph__branches_deviceEdge';
  next?: Maybe<Paragraph__Branches_Device>;
  node: Paragraph__Branches_Device;
  previous?: Maybe<Paragraph__Branches_Device>;
};

export type Paragraph__Branches_DeviceField_Branches_Image = {
  __typename?: 'paragraph__branches_deviceField_branches_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_DeviceField_Branches_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_DeviceField_Branches_Profits = {
  __typename?: 'paragraph__branches_deviceField_branches_profits';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_DeviceField_Branches_ProfitsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_DeviceField_Branches_ProfitsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_DeviceField_Branches_ProfitsFilterInput>;
};

export enum Paragraph__Branches_DeviceFieldsEnum {
  field_branches_name = 'field_branches_name',
  field_recommended = 'field_recommended',
  field_button_text_recommended = 'field_button_text_recommended',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___field_branches_profits = 'relationships___field_branches_profits',
  relationships___field_branches_profits___field_profits_active = 'relationships___field_branches_profits___field_profits_active',
  relationships___field_branches_profits___field_profits_name___value = 'relationships___field_branches_profits___field_profits_name___value',
  relationships___field_branches_profits___field_ref_desc = 'relationships___field_branches_profits___field_ref_desc',
  relationships___field_branches_profits___field_ref_desc___target_revision_id = 'relationships___field_branches_profits___field_ref_desc___target_revision_id',
  relationships___field_branches_profits___field_ref_desc___drupal_internal__target_id = 'relationships___field_branches_profits___field_ref_desc___drupal_internal__target_id',
  relationships___field_branches_profits___drupal_id = 'relationships___field_branches_profits___drupal_id',
  relationships___field_branches_profits___drupal_internal__id = 'relationships___field_branches_profits___drupal_internal__id',
  relationships___field_branches_profits___status = 'relationships___field_branches_profits___status',
  relationships___field_branches_profits___created = 'relationships___field_branches_profits___created',
  relationships___field_branches_profits___parent_id = 'relationships___field_branches_profits___parent_id',
  relationships___field_branches_profits___parent_type = 'relationships___field_branches_profits___parent_type',
  relationships___field_branches_profits___parent_field_name = 'relationships___field_branches_profits___parent_field_name',
  relationships___field_branches_profits___content_translation_source = 'relationships___field_branches_profits___content_translation_source',
  relationships___field_branches_profits___content_translation_outdated = 'relationships___field_branches_profits___content_translation_outdated',
  relationships___field_branches_profits___content_translation_changed = 'relationships___field_branches_profits___content_translation_changed',
  relationships___field_branches_profits___langcode = 'relationships___field_branches_profits___langcode',
  relationships___field_branches_profits___uuid = 'relationships___field_branches_profits___uuid',
  relationships___field_branches_profits___relationships___node__tariffs = 'relationships___field_branches_profits___relationships___node__tariffs',
  relationships___field_branches_profits___relationships___field_ref_desc = 'relationships___field_branches_profits___relationships___field_ref_desc',
  relationships___field_branches_profits___relationships___paragraph__branches_device = 'relationships___field_branches_profits___relationships___paragraph__branches_device',
  relationships___field_branches_profits___relationships___paragraph__branches_tariff = 'relationships___field_branches_profits___relationships___paragraph__branches_tariff',
  relationships___field_branches_profits___id = 'relationships___field_branches_profits___id',
  relationships___field_branches_profits___parent___id = 'relationships___field_branches_profits___parent___id',
  relationships___field_branches_profits___parent___children = 'relationships___field_branches_profits___parent___children',
  relationships___field_branches_profits___children = 'relationships___field_branches_profits___children',
  relationships___field_branches_profits___children___id = 'relationships___field_branches_profits___children___id',
  relationships___field_branches_profits___children___children = 'relationships___field_branches_profits___children___children',
  relationships___field_branches_profits___internal___content = 'relationships___field_branches_profits___internal___content',
  relationships___field_branches_profits___internal___contentDigest = 'relationships___field_branches_profits___internal___contentDigest',
  relationships___field_branches_profits___internal___description = 'relationships___field_branches_profits___internal___description',
  relationships___field_branches_profits___internal___fieldOwners = 'relationships___field_branches_profits___internal___fieldOwners',
  relationships___field_branches_profits___internal___ignoreType = 'relationships___field_branches_profits___internal___ignoreType',
  relationships___field_branches_profits___internal___mediaType = 'relationships___field_branches_profits___internal___mediaType',
  relationships___field_branches_profits___internal___owner = 'relationships___field_branches_profits___internal___owner',
  relationships___field_branches_profits___internal___type = 'relationships___field_branches_profits___internal___type',
  relationships___paragraph__branches_recommended_devices = 'relationships___paragraph__branches_recommended_devices',
  relationships___paragraph__branches_recommended_devices___internal___content = 'relationships___paragraph__branches_recommended_devices___internal___content',
  relationships___paragraph__branches_recommended_devices___internal___contentDigest = 'relationships___paragraph__branches_recommended_devices___internal___contentDigest',
  relationships___paragraph__branches_recommended_devices___internal___description = 'relationships___paragraph__branches_recommended_devices___internal___description',
  relationships___paragraph__branches_recommended_devices___internal___fieldOwners = 'relationships___paragraph__branches_recommended_devices___internal___fieldOwners',
  relationships___paragraph__branches_recommended_devices___internal___ignoreType = 'relationships___paragraph__branches_recommended_devices___internal___ignoreType',
  relationships___paragraph__branches_recommended_devices___internal___mediaType = 'relationships___paragraph__branches_recommended_devices___internal___mediaType',
  relationships___paragraph__branches_recommended_devices___internal___owner = 'relationships___paragraph__branches_recommended_devices___internal___owner',
  relationships___paragraph__branches_recommended_devices___internal___type = 'relationships___paragraph__branches_recommended_devices___internal___type',
  relationships___paragraph__branches_recommended_devices___id = 'relationships___paragraph__branches_recommended_devices___id',
  relationships___paragraph__branches_recommended_devices___drupal_id = 'relationships___paragraph__branches_recommended_devices___drupal_id',
  relationships___paragraph__branches_recommended_devices___field_paragraph_headline___value = 'relationships___paragraph__branches_recommended_devices___field_paragraph_headline___value',
  relationships___paragraph__branches_recommended_devices___relationships___field_branches_devices = 'relationships___paragraph__branches_recommended_devices___relationships___field_branches_devices',
  relationships___paragraph__branches_recommended_devices___relationships___node__branches = 'relationships___paragraph__branches_recommended_devices___relationships___node__branches',
  relationships___paragraph__branches_recommended_devices___drupal_internal__id = 'relationships___paragraph__branches_recommended_devices___drupal_internal__id',
  relationships___paragraph__branches_recommended_devices___status = 'relationships___paragraph__branches_recommended_devices___status',
  relationships___paragraph__branches_recommended_devices___created = 'relationships___paragraph__branches_recommended_devices___created',
  relationships___paragraph__branches_recommended_devices___parent_id = 'relationships___paragraph__branches_recommended_devices___parent_id',
  relationships___paragraph__branches_recommended_devices___parent_type = 'relationships___paragraph__branches_recommended_devices___parent_type',
  relationships___paragraph__branches_recommended_devices___parent_field_name = 'relationships___paragraph__branches_recommended_devices___parent_field_name',
  relationships___paragraph__branches_recommended_devices___content_translation_source = 'relationships___paragraph__branches_recommended_devices___content_translation_source',
  relationships___paragraph__branches_recommended_devices___content_translation_outdated = 'relationships___paragraph__branches_recommended_devices___content_translation_outdated',
  relationships___paragraph__branches_recommended_devices___langcode = 'relationships___paragraph__branches_recommended_devices___langcode',
  relationships___paragraph__branches_recommended_devices___uuid = 'relationships___paragraph__branches_recommended_devices___uuid',
  relationships___paragraph__branches_recommended_devices___field_branches_devices = 'relationships___paragraph__branches_recommended_devices___field_branches_devices',
  relationships___paragraph__branches_recommended_devices___field_branches_devices___target_revision_id = 'relationships___paragraph__branches_recommended_devices___field_branches_devices___target_revision_id',
  relationships___paragraph__branches_recommended_devices___field_branches_devices___drupal_internal__target_id = 'relationships___paragraph__branches_recommended_devices___field_branches_devices___drupal_internal__target_id',
  relationships___paragraph__branches_recommended_devices___parent___id = 'relationships___paragraph__branches_recommended_devices___parent___id',
  relationships___paragraph__branches_recommended_devices___parent___children = 'relationships___paragraph__branches_recommended_devices___parent___children',
  relationships___paragraph__branches_recommended_devices___children = 'relationships___paragraph__branches_recommended_devices___children',
  relationships___paragraph__branches_recommended_devices___children___id = 'relationships___paragraph__branches_recommended_devices___children___id',
  relationships___paragraph__branches_recommended_devices___children___children = 'relationships___paragraph__branches_recommended_devices___children___children',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  field_nc_button_text = 'field_nc_button_text',
  langcode = 'langcode',
  uuid = 'uuid',
  field_branches_image___alt = 'field_branches_image___alt',
  field_branches_image___title = 'field_branches_image___title',
  field_branches_image___width = 'field_branches_image___width',
  field_branches_image___height = 'field_branches_image___height',
  field_branches_image___drupal_internal__target_id = 'field_branches_image___drupal_internal__target_id',
  field_branches_profits = 'field_branches_profits',
  field_branches_profits___target_revision_id = 'field_branches_profits___target_revision_id',
  field_branches_profits___drupal_internal__target_id = 'field_branches_profits___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Branches_DeviceFilterInput = {
  field_branches_name?: Maybe<StringQueryOperatorInput>;
  field_recommended?: Maybe<BooleanQueryOperatorInput>;
  field_button_text_recommended?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_DeviceRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Paragraph__Branches_DeviceField_Branches_ImageFilterInput>;
  field_branches_profits?: Maybe<Paragraph__Branches_DeviceField_Branches_ProfitsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Branches_DeviceFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_DeviceFilterInput>;
};

export type Paragraph__Branches_DeviceGroupConnection = {
  __typename?: 'paragraph__branches_deviceGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_DeviceEdge>;
  nodes: Array<Paragraph__Branches_Device>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_DeviceRelationships = {
  __typename?: 'paragraph__branches_deviceRelationships';
  field_branches_image?: Maybe<File__File>;
  field_branches_profits?: Maybe<Array<Maybe<Paragraph__Profits>>>;
  paragraph__branches_recommended_devices?: Maybe<
    Array<Maybe<Paragraph__Branches_Recommended_Devices>>
  >;
};

export type Paragraph__Branches_DeviceRelationshipsFilterInput = {
  field_branches_image?: Maybe<File__FileFilterInput>;
  field_branches_profits?: Maybe<Paragraph__ProfitsFilterListInput>;
  paragraph__branches_recommended_devices?: Maybe<
    Paragraph__Branches_Recommended_DevicesFilterListInput
  >;
};

export type Paragraph__Branches_DeviceSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_DeviceFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Discounts = Node & {
  __typename?: 'paragraph__branches_discounts';
  field_branches_text?: Maybe<Field_Branches_Text>;
  id: Scalars['ID'];
  field_branches_button_text?: Maybe<Scalars['String']>;
  field_branches_image?: Maybe<Field_Branches_Image>;
  relationships?: Maybe<Paragraph__Branches_DiscountsRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Branches_DiscountsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_DiscountsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_DiscountsConnection = {
  __typename?: 'paragraph__branches_discountsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_DiscountsEdge>;
  nodes: Array<Paragraph__Branches_Discounts>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_DiscountsGroupConnection>;
};

export type Paragraph__Branches_DiscountsConnectionDistinctArgs = {
  field: Paragraph__Branches_DiscountsFieldsEnum;
};

export type Paragraph__Branches_DiscountsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_DiscountsFieldsEnum;
};

export type Paragraph__Branches_DiscountsEdge = {
  __typename?: 'paragraph__branches_discountsEdge';
  next?: Maybe<Paragraph__Branches_Discounts>;
  node: Paragraph__Branches_Discounts;
  previous?: Maybe<Paragraph__Branches_Discounts>;
};

export enum Paragraph__Branches_DiscountsFieldsEnum {
  field_branches_text___value = 'field_branches_text___value',
  id = 'id',
  field_branches_button_text = 'field_branches_button_text',
  field_branches_image___alt = 'field_branches_image___alt',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___paragraph__discounts_container = 'relationships___paragraph__discounts_container',
  relationships___paragraph__discounts_container___internal___content = 'relationships___paragraph__discounts_container___internal___content',
  relationships___paragraph__discounts_container___internal___contentDigest = 'relationships___paragraph__discounts_container___internal___contentDigest',
  relationships___paragraph__discounts_container___internal___description = 'relationships___paragraph__discounts_container___internal___description',
  relationships___paragraph__discounts_container___internal___fieldOwners = 'relationships___paragraph__discounts_container___internal___fieldOwners',
  relationships___paragraph__discounts_container___internal___ignoreType = 'relationships___paragraph__discounts_container___internal___ignoreType',
  relationships___paragraph__discounts_container___internal___mediaType = 'relationships___paragraph__discounts_container___internal___mediaType',
  relationships___paragraph__discounts_container___internal___owner = 'relationships___paragraph__discounts_container___internal___owner',
  relationships___paragraph__discounts_container___internal___type = 'relationships___paragraph__discounts_container___internal___type',
  relationships___paragraph__discounts_container___id = 'relationships___paragraph__discounts_container___id',
  relationships___paragraph__discounts_container___drupal_id = 'relationships___paragraph__discounts_container___drupal_id',
  relationships___paragraph__discounts_container___field_text___value = 'relationships___paragraph__discounts_container___field_text___value',
  relationships___paragraph__discounts_container___field_paragraph_headline___value = 'relationships___paragraph__discounts_container___field_paragraph_headline___value',
  relationships___paragraph__discounts_container___relationships___field_discounts_item = 'relationships___paragraph__discounts_container___relationships___field_discounts_item',
  relationships___paragraph__discounts_container___relationships___node__branches = 'relationships___paragraph__discounts_container___relationships___node__branches',
  relationships___paragraph__discounts_container___drupal_internal__id = 'relationships___paragraph__discounts_container___drupal_internal__id',
  relationships___paragraph__discounts_container___status = 'relationships___paragraph__discounts_container___status',
  relationships___paragraph__discounts_container___created = 'relationships___paragraph__discounts_container___created',
  relationships___paragraph__discounts_container___parent_id = 'relationships___paragraph__discounts_container___parent_id',
  relationships___paragraph__discounts_container___parent_type = 'relationships___paragraph__discounts_container___parent_type',
  relationships___paragraph__discounts_container___parent_field_name = 'relationships___paragraph__discounts_container___parent_field_name',
  relationships___paragraph__discounts_container___content_translation_source = 'relationships___paragraph__discounts_container___content_translation_source',
  relationships___paragraph__discounts_container___content_translation_outdated = 'relationships___paragraph__discounts_container___content_translation_outdated',
  relationships___paragraph__discounts_container___langcode = 'relationships___paragraph__discounts_container___langcode',
  relationships___paragraph__discounts_container___uuid = 'relationships___paragraph__discounts_container___uuid',
  relationships___paragraph__discounts_container___field_discounts_item = 'relationships___paragraph__discounts_container___field_discounts_item',
  relationships___paragraph__discounts_container___field_discounts_item___target_revision_id = 'relationships___paragraph__discounts_container___field_discounts_item___target_revision_id',
  relationships___paragraph__discounts_container___field_discounts_item___drupal_internal__target_id = 'relationships___paragraph__discounts_container___field_discounts_item___drupal_internal__target_id',
  relationships___paragraph__discounts_container___parent___id = 'relationships___paragraph__discounts_container___parent___id',
  relationships___paragraph__discounts_container___parent___children = 'relationships___paragraph__discounts_container___parent___children',
  relationships___paragraph__discounts_container___children = 'relationships___paragraph__discounts_container___children',
  relationships___paragraph__discounts_container___children___id = 'relationships___paragraph__discounts_container___children___id',
  relationships___paragraph__discounts_container___children___children = 'relationships___paragraph__discounts_container___children___children',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Branches_DiscountsFilterInput = {
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  field_branches_button_text?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Field_Branches_ImageFilterInput>;
  relationships?: Maybe<Paragraph__Branches_DiscountsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Branches_DiscountsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_DiscountsFilterInput>;
};

export type Paragraph__Branches_DiscountsGroupConnection = {
  __typename?: 'paragraph__branches_discountsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_DiscountsEdge>;
  nodes: Array<Paragraph__Branches_Discounts>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_DiscountsRelationships = {
  __typename?: 'paragraph__branches_discountsRelationships';
  field_branches_image?: Maybe<File__File>;
  paragraph__discounts_container?: Maybe<Array<Maybe<Paragraph__Discounts_Container>>>;
};

export type Paragraph__Branches_DiscountsRelationshipsFilterInput = {
  field_branches_image?: Maybe<File__FileFilterInput>;
  paragraph__discounts_container?: Maybe<Paragraph__Discounts_ContainerFilterListInput>;
};

export type Paragraph__Branches_DiscountsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_DiscountsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Features = Node & {
  __typename?: 'paragraph__branches_features';
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_branches_text?: Maybe<Field_Branches_Text>;
  field_short_text_1?: Maybe<Field_Short_Text_1>;
  field_short_text_2?: Maybe<Field_Short_Text_2>;
  field_short_text_3?: Maybe<Field_Short_Text_3>;
  field_short_text_4?: Maybe<Field_Short_Text_4>;
  relationships?: Maybe<Paragraph__Branches_FeaturesRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_image?: Maybe<Paragraph__Branches_FeaturesField_Branches_Image>;
  field_ico_1?: Maybe<Paragraph__Branches_FeaturesField_Ico_1>;
  field_ico_2?: Maybe<Paragraph__Branches_FeaturesField_Ico_2>;
  field_ico_3?: Maybe<Paragraph__Branches_FeaturesField_Ico_3>;
  field_ico_4?: Maybe<Paragraph__Branches_FeaturesField_Ico_4>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_FeaturesCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_FeaturesContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_FeaturesConnection = {
  __typename?: 'paragraph__branches_featuresConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_FeaturesEdge>;
  nodes: Array<Paragraph__Branches_Features>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_FeaturesGroupConnection>;
};

export type Paragraph__Branches_FeaturesConnectionDistinctArgs = {
  field: Paragraph__Branches_FeaturesFieldsEnum;
};

export type Paragraph__Branches_FeaturesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_FeaturesFieldsEnum;
};

export type Paragraph__Branches_FeaturesEdge = {
  __typename?: 'paragraph__branches_featuresEdge';
  next?: Maybe<Paragraph__Branches_Features>;
  node: Paragraph__Branches_Features;
  previous?: Maybe<Paragraph__Branches_Features>;
};

export type Paragraph__Branches_FeaturesField_Branches_Image = {
  __typename?: 'paragraph__branches_featuresField_branches_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_FeaturesField_Branches_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_FeaturesField_Ico_1 = {
  __typename?: 'paragraph__branches_featuresField_ico_1';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_FeaturesField_Ico_1FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_FeaturesField_Ico_2 = {
  __typename?: 'paragraph__branches_featuresField_ico_2';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_FeaturesField_Ico_2FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_FeaturesField_Ico_3 = {
  __typename?: 'paragraph__branches_featuresField_ico_3';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_FeaturesField_Ico_3FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_FeaturesField_Ico_4 = {
  __typename?: 'paragraph__branches_featuresField_ico_4';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_FeaturesField_Ico_4FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Paragraph__Branches_FeaturesFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_branches_text___value = 'field_branches_text___value',
  field_short_text_1___value = 'field_short_text_1___value',
  field_short_text_2___value = 'field_short_text_2___value',
  field_short_text_3___value = 'field_short_text_3___value',
  field_short_text_4___value = 'field_short_text_4___value',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___field_ico_1___localFile___birthtime = 'relationships___field_ico_1___localFile___birthtime',
  relationships___field_ico_1___localFile___birthtimeMs = 'relationships___field_ico_1___localFile___birthtimeMs',
  relationships___field_ico_1___localFile___publicURL = 'relationships___field_ico_1___localFile___publicURL',
  relationships___field_ico_1___localFile___sourceInstanceName = 'relationships___field_ico_1___localFile___sourceInstanceName',
  relationships___field_ico_1___localFile___absolutePath = 'relationships___field_ico_1___localFile___absolutePath',
  relationships___field_ico_1___localFile___relativePath = 'relationships___field_ico_1___localFile___relativePath',
  relationships___field_ico_1___localFile___extension = 'relationships___field_ico_1___localFile___extension',
  relationships___field_ico_1___localFile___size = 'relationships___field_ico_1___localFile___size',
  relationships___field_ico_1___localFile___prettySize = 'relationships___field_ico_1___localFile___prettySize',
  relationships___field_ico_1___localFile___modifiedTime = 'relationships___field_ico_1___localFile___modifiedTime',
  relationships___field_ico_1___localFile___accessTime = 'relationships___field_ico_1___localFile___accessTime',
  relationships___field_ico_1___localFile___changeTime = 'relationships___field_ico_1___localFile___changeTime',
  relationships___field_ico_1___localFile___birthTime = 'relationships___field_ico_1___localFile___birthTime',
  relationships___field_ico_1___localFile___root = 'relationships___field_ico_1___localFile___root',
  relationships___field_ico_1___localFile___dir = 'relationships___field_ico_1___localFile___dir',
  relationships___field_ico_1___localFile___base = 'relationships___field_ico_1___localFile___base',
  relationships___field_ico_1___localFile___ext = 'relationships___field_ico_1___localFile___ext',
  relationships___field_ico_1___localFile___name = 'relationships___field_ico_1___localFile___name',
  relationships___field_ico_1___localFile___relativeDirectory = 'relationships___field_ico_1___localFile___relativeDirectory',
  relationships___field_ico_1___localFile___dev = 'relationships___field_ico_1___localFile___dev',
  relationships___field_ico_1___localFile___mode = 'relationships___field_ico_1___localFile___mode',
  relationships___field_ico_1___localFile___nlink = 'relationships___field_ico_1___localFile___nlink',
  relationships___field_ico_1___localFile___uid = 'relationships___field_ico_1___localFile___uid',
  relationships___field_ico_1___localFile___gid = 'relationships___field_ico_1___localFile___gid',
  relationships___field_ico_1___localFile___rdev = 'relationships___field_ico_1___localFile___rdev',
  relationships___field_ico_1___localFile___blksize = 'relationships___field_ico_1___localFile___blksize',
  relationships___field_ico_1___localFile___ino = 'relationships___field_ico_1___localFile___ino',
  relationships___field_ico_1___localFile___blocks = 'relationships___field_ico_1___localFile___blocks',
  relationships___field_ico_1___localFile___atimeMs = 'relationships___field_ico_1___localFile___atimeMs',
  relationships___field_ico_1___localFile___mtimeMs = 'relationships___field_ico_1___localFile___mtimeMs',
  relationships___field_ico_1___localFile___ctimeMs = 'relationships___field_ico_1___localFile___ctimeMs',
  relationships___field_ico_1___localFile___atime = 'relationships___field_ico_1___localFile___atime',
  relationships___field_ico_1___localFile___mtime = 'relationships___field_ico_1___localFile___mtime',
  relationships___field_ico_1___localFile___ctime = 'relationships___field_ico_1___localFile___ctime',
  relationships___field_ico_1___localFile___url = 'relationships___field_ico_1___localFile___url',
  relationships___field_ico_1___localFile___id = 'relationships___field_ico_1___localFile___id',
  relationships___field_ico_1___localFile___children = 'relationships___field_ico_1___localFile___children',
  relationships___field_ico_1___uri___url = 'relationships___field_ico_1___uri___url',
  relationships___field_ico_1___drupal_id = 'relationships___field_ico_1___drupal_id',
  relationships___field_ico_1___drupal_internal__fid = 'relationships___field_ico_1___drupal_internal__fid',
  relationships___field_ico_1___langcode = 'relationships___field_ico_1___langcode',
  relationships___field_ico_1___filename = 'relationships___field_ico_1___filename',
  relationships___field_ico_1___filemime = 'relationships___field_ico_1___filemime',
  relationships___field_ico_1___filesize = 'relationships___field_ico_1___filesize',
  relationships___field_ico_1___status = 'relationships___field_ico_1___status',
  relationships___field_ico_1___created = 'relationships___field_ico_1___created',
  relationships___field_ico_1___changed = 'relationships___field_ico_1___changed',
  relationships___field_ico_1___uuid = 'relationships___field_ico_1___uuid',
  relationships___field_ico_1___relationships___node__blog_post = 'relationships___field_ico_1___relationships___node__blog_post',
  relationships___field_ico_1___relationships___node__devices = 'relationships___field_ico_1___relationships___node__devices',
  relationships___field_ico_1___relationships___node__accessories = 'relationships___field_ico_1___relationships___node__accessories',
  relationships___field_ico_1___relationships___paragraph__news_image_title_text = 'relationships___field_ico_1___relationships___paragraph__news_image_title_text',
  relationships___field_ico_1___relationships___node__cases = 'relationships___field_ico_1___relationships___node__cases',
  relationships___field_ico_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_1___relationships___paragraph__branches_features = 'relationships___field_ico_1___relationships___paragraph__branches_features',
  relationships___field_ico_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_1___relationships___paragraph__branches_testimonials = 'relationships___field_ico_1___relationships___paragraph__branches_testimonials',
  relationships___field_ico_1___relationships___paragraph__branches_discounts = 'relationships___field_ico_1___relationships___paragraph__branches_discounts',
  relationships___field_ico_1___relationships___node__news = 'relationships___field_ico_1___relationships___node__news',
  relationships___field_ico_1___relationships___node__branches = 'relationships___field_ico_1___relationships___node__branches',
  relationships___field_ico_1___relationships___paragraph__branches_hero = 'relationships___field_ico_1___relationships___paragraph__branches_hero',
  relationships___field_ico_1___relationships___paragraph__bullets = 'relationships___field_ico_1___relationships___paragraph__bullets',
  relationships___field_ico_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_1___relationships___paragraph__branches_device = 'relationships___field_ico_1___relationships___paragraph__branches_device',
  relationships___field_ico_1___relationships___paragraph__branches_recommendation = 'relationships___field_ico_1___relationships___paragraph__branches_recommendation',
  relationships___field_ico_1___relationships___site_setting_entity__accessories = 'relationships___field_ico_1___relationships___site_setting_entity__accessories',
  relationships___field_ico_1___relationships___site_setting_entity__branches = 'relationships___field_ico_1___relationships___site_setting_entity__branches',
  relationships___field_ico_1___relationships___site_setting_entity__about_us = 'relationships___field_ico_1___relationships___site_setting_entity__about_us',
  relationships___field_ico_1___relationships___paragraph__logos = 'relationships___field_ico_1___relationships___paragraph__logos',
  relationships___field_ico_1___relationships___paragraph__how_it_works = 'relationships___field_ico_1___relationships___paragraph__how_it_works',
  relationships___field_ico_1___relationships___paragraph__ico_and_text = 'relationships___field_ico_1___relationships___paragraph__ico_and_text',
  relationships___field_ico_1___relationships___paragraph__hero_1 = 'relationships___field_ico_1___relationships___paragraph__hero_1',
  relationships___field_ico_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_1___relationships___site_setting_entity__product = 'relationships___field_ico_1___relationships___site_setting_entity__product',
  relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_1___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_1___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_1___relationships___site_setting_entity__main_page = 'relationships___field_ico_1___relationships___site_setting_entity__main_page',
  relationships___field_ico_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_1___relationships___site_setting_entity__intercars = 'relationships___field_ico_1___relationships___site_setting_entity__intercars',
  relationships___field_ico_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_1___relationships___node__agreement = 'relationships___field_ico_1___relationships___node__agreement',
  relationships___field_ico_1___relationships___node__fiscalization = 'relationships___field_ico_1___relationships___node__fiscalization',
  relationships___field_ico_1___relationships___site_setting_entity__configurator = 'relationships___field_ico_1___relationships___site_setting_entity__configurator',
  relationships___field_ico_1___relationships___paragraph__social_link = 'relationships___field_ico_1___relationships___paragraph__social_link',
  relationships___field_ico_1___relationships___paragraph__make_an_appointment = 'relationships___field_ico_1___relationships___paragraph__make_an_appointment',
  relationships___field_ico_1___relationships___paragraph__linked_icons = 'relationships___field_ico_1___relationships___paragraph__linked_icons',
  relationships___field_ico_1___relationships___site_setting_entity__financing = 'relationships___field_ico_1___relationships___site_setting_entity__financing',
  relationships___field_ico_1___relationships___site_setting_entity__franchise = 'relationships___field_ico_1___relationships___site_setting_entity__franchise',
  relationships___field_ico_1___id = 'relationships___field_ico_1___id',
  relationships___field_ico_1___parent___id = 'relationships___field_ico_1___parent___id',
  relationships___field_ico_1___parent___children = 'relationships___field_ico_1___parent___children',
  relationships___field_ico_1___children = 'relationships___field_ico_1___children',
  relationships___field_ico_1___children___id = 'relationships___field_ico_1___children___id',
  relationships___field_ico_1___children___children = 'relationships___field_ico_1___children___children',
  relationships___field_ico_1___internal___content = 'relationships___field_ico_1___internal___content',
  relationships___field_ico_1___internal___contentDigest = 'relationships___field_ico_1___internal___contentDigest',
  relationships___field_ico_1___internal___description = 'relationships___field_ico_1___internal___description',
  relationships___field_ico_1___internal___fieldOwners = 'relationships___field_ico_1___internal___fieldOwners',
  relationships___field_ico_1___internal___ignoreType = 'relationships___field_ico_1___internal___ignoreType',
  relationships___field_ico_1___internal___mediaType = 'relationships___field_ico_1___internal___mediaType',
  relationships___field_ico_1___internal___owner = 'relationships___field_ico_1___internal___owner',
  relationships___field_ico_1___internal___type = 'relationships___field_ico_1___internal___type',
  relationships___field_ico_2___localFile___birthtime = 'relationships___field_ico_2___localFile___birthtime',
  relationships___field_ico_2___localFile___birthtimeMs = 'relationships___field_ico_2___localFile___birthtimeMs',
  relationships___field_ico_2___localFile___publicURL = 'relationships___field_ico_2___localFile___publicURL',
  relationships___field_ico_2___localFile___sourceInstanceName = 'relationships___field_ico_2___localFile___sourceInstanceName',
  relationships___field_ico_2___localFile___absolutePath = 'relationships___field_ico_2___localFile___absolutePath',
  relationships___field_ico_2___localFile___relativePath = 'relationships___field_ico_2___localFile___relativePath',
  relationships___field_ico_2___localFile___extension = 'relationships___field_ico_2___localFile___extension',
  relationships___field_ico_2___localFile___size = 'relationships___field_ico_2___localFile___size',
  relationships___field_ico_2___localFile___prettySize = 'relationships___field_ico_2___localFile___prettySize',
  relationships___field_ico_2___localFile___modifiedTime = 'relationships___field_ico_2___localFile___modifiedTime',
  relationships___field_ico_2___localFile___accessTime = 'relationships___field_ico_2___localFile___accessTime',
  relationships___field_ico_2___localFile___changeTime = 'relationships___field_ico_2___localFile___changeTime',
  relationships___field_ico_2___localFile___birthTime = 'relationships___field_ico_2___localFile___birthTime',
  relationships___field_ico_2___localFile___root = 'relationships___field_ico_2___localFile___root',
  relationships___field_ico_2___localFile___dir = 'relationships___field_ico_2___localFile___dir',
  relationships___field_ico_2___localFile___base = 'relationships___field_ico_2___localFile___base',
  relationships___field_ico_2___localFile___ext = 'relationships___field_ico_2___localFile___ext',
  relationships___field_ico_2___localFile___name = 'relationships___field_ico_2___localFile___name',
  relationships___field_ico_2___localFile___relativeDirectory = 'relationships___field_ico_2___localFile___relativeDirectory',
  relationships___field_ico_2___localFile___dev = 'relationships___field_ico_2___localFile___dev',
  relationships___field_ico_2___localFile___mode = 'relationships___field_ico_2___localFile___mode',
  relationships___field_ico_2___localFile___nlink = 'relationships___field_ico_2___localFile___nlink',
  relationships___field_ico_2___localFile___uid = 'relationships___field_ico_2___localFile___uid',
  relationships___field_ico_2___localFile___gid = 'relationships___field_ico_2___localFile___gid',
  relationships___field_ico_2___localFile___rdev = 'relationships___field_ico_2___localFile___rdev',
  relationships___field_ico_2___localFile___blksize = 'relationships___field_ico_2___localFile___blksize',
  relationships___field_ico_2___localFile___ino = 'relationships___field_ico_2___localFile___ino',
  relationships___field_ico_2___localFile___blocks = 'relationships___field_ico_2___localFile___blocks',
  relationships___field_ico_2___localFile___atimeMs = 'relationships___field_ico_2___localFile___atimeMs',
  relationships___field_ico_2___localFile___mtimeMs = 'relationships___field_ico_2___localFile___mtimeMs',
  relationships___field_ico_2___localFile___ctimeMs = 'relationships___field_ico_2___localFile___ctimeMs',
  relationships___field_ico_2___localFile___atime = 'relationships___field_ico_2___localFile___atime',
  relationships___field_ico_2___localFile___mtime = 'relationships___field_ico_2___localFile___mtime',
  relationships___field_ico_2___localFile___ctime = 'relationships___field_ico_2___localFile___ctime',
  relationships___field_ico_2___localFile___url = 'relationships___field_ico_2___localFile___url',
  relationships___field_ico_2___localFile___id = 'relationships___field_ico_2___localFile___id',
  relationships___field_ico_2___localFile___children = 'relationships___field_ico_2___localFile___children',
  relationships___field_ico_2___uri___url = 'relationships___field_ico_2___uri___url',
  relationships___field_ico_2___drupal_id = 'relationships___field_ico_2___drupal_id',
  relationships___field_ico_2___drupal_internal__fid = 'relationships___field_ico_2___drupal_internal__fid',
  relationships___field_ico_2___langcode = 'relationships___field_ico_2___langcode',
  relationships___field_ico_2___filename = 'relationships___field_ico_2___filename',
  relationships___field_ico_2___filemime = 'relationships___field_ico_2___filemime',
  relationships___field_ico_2___filesize = 'relationships___field_ico_2___filesize',
  relationships___field_ico_2___status = 'relationships___field_ico_2___status',
  relationships___field_ico_2___created = 'relationships___field_ico_2___created',
  relationships___field_ico_2___changed = 'relationships___field_ico_2___changed',
  relationships___field_ico_2___uuid = 'relationships___field_ico_2___uuid',
  relationships___field_ico_2___relationships___node__blog_post = 'relationships___field_ico_2___relationships___node__blog_post',
  relationships___field_ico_2___relationships___node__devices = 'relationships___field_ico_2___relationships___node__devices',
  relationships___field_ico_2___relationships___node__accessories = 'relationships___field_ico_2___relationships___node__accessories',
  relationships___field_ico_2___relationships___paragraph__news_image_title_text = 'relationships___field_ico_2___relationships___paragraph__news_image_title_text',
  relationships___field_ico_2___relationships___node__cases = 'relationships___field_ico_2___relationships___node__cases',
  relationships___field_ico_2___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_2___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_2___relationships___paragraph__branches_features = 'relationships___field_ico_2___relationships___paragraph__branches_features',
  relationships___field_ico_2___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_2___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_2___relationships___paragraph__branches_testimonials = 'relationships___field_ico_2___relationships___paragraph__branches_testimonials',
  relationships___field_ico_2___relationships___paragraph__branches_discounts = 'relationships___field_ico_2___relationships___paragraph__branches_discounts',
  relationships___field_ico_2___relationships___node__news = 'relationships___field_ico_2___relationships___node__news',
  relationships___field_ico_2___relationships___node__branches = 'relationships___field_ico_2___relationships___node__branches',
  relationships___field_ico_2___relationships___paragraph__branches_hero = 'relationships___field_ico_2___relationships___paragraph__branches_hero',
  relationships___field_ico_2___relationships___paragraph__bullets = 'relationships___field_ico_2___relationships___paragraph__bullets',
  relationships___field_ico_2___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_2___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_2___relationships___paragraph__branches_device = 'relationships___field_ico_2___relationships___paragraph__branches_device',
  relationships___field_ico_2___relationships___paragraph__branches_recommendation = 'relationships___field_ico_2___relationships___paragraph__branches_recommendation',
  relationships___field_ico_2___relationships___site_setting_entity__accessories = 'relationships___field_ico_2___relationships___site_setting_entity__accessories',
  relationships___field_ico_2___relationships___site_setting_entity__branches = 'relationships___field_ico_2___relationships___site_setting_entity__branches',
  relationships___field_ico_2___relationships___site_setting_entity__about_us = 'relationships___field_ico_2___relationships___site_setting_entity__about_us',
  relationships___field_ico_2___relationships___paragraph__logos = 'relationships___field_ico_2___relationships___paragraph__logos',
  relationships___field_ico_2___relationships___paragraph__how_it_works = 'relationships___field_ico_2___relationships___paragraph__how_it_works',
  relationships___field_ico_2___relationships___paragraph__ico_and_text = 'relationships___field_ico_2___relationships___paragraph__ico_and_text',
  relationships___field_ico_2___relationships___paragraph__hero_1 = 'relationships___field_ico_2___relationships___paragraph__hero_1',
  relationships___field_ico_2___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_2___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_2___relationships___site_setting_entity__product = 'relationships___field_ico_2___relationships___site_setting_entity__product',
  relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_2___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_2___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_2___relationships___site_setting_entity__main_page = 'relationships___field_ico_2___relationships___site_setting_entity__main_page',
  relationships___field_ico_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_2___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_2___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_2___relationships___site_setting_entity__intercars = 'relationships___field_ico_2___relationships___site_setting_entity__intercars',
  relationships___field_ico_2___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_2___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_2___relationships___node__agreement = 'relationships___field_ico_2___relationships___node__agreement',
  relationships___field_ico_2___relationships___node__fiscalization = 'relationships___field_ico_2___relationships___node__fiscalization',
  relationships___field_ico_2___relationships___site_setting_entity__configurator = 'relationships___field_ico_2___relationships___site_setting_entity__configurator',
  relationships___field_ico_2___relationships___paragraph__social_link = 'relationships___field_ico_2___relationships___paragraph__social_link',
  relationships___field_ico_2___relationships___paragraph__make_an_appointment = 'relationships___field_ico_2___relationships___paragraph__make_an_appointment',
  relationships___field_ico_2___relationships___paragraph__linked_icons = 'relationships___field_ico_2___relationships___paragraph__linked_icons',
  relationships___field_ico_2___relationships___site_setting_entity__financing = 'relationships___field_ico_2___relationships___site_setting_entity__financing',
  relationships___field_ico_2___relationships___site_setting_entity__franchise = 'relationships___field_ico_2___relationships___site_setting_entity__franchise',
  relationships___field_ico_2___id = 'relationships___field_ico_2___id',
  relationships___field_ico_2___parent___id = 'relationships___field_ico_2___parent___id',
  relationships___field_ico_2___parent___children = 'relationships___field_ico_2___parent___children',
  relationships___field_ico_2___children = 'relationships___field_ico_2___children',
  relationships___field_ico_2___children___id = 'relationships___field_ico_2___children___id',
  relationships___field_ico_2___children___children = 'relationships___field_ico_2___children___children',
  relationships___field_ico_2___internal___content = 'relationships___field_ico_2___internal___content',
  relationships___field_ico_2___internal___contentDigest = 'relationships___field_ico_2___internal___contentDigest',
  relationships___field_ico_2___internal___description = 'relationships___field_ico_2___internal___description',
  relationships___field_ico_2___internal___fieldOwners = 'relationships___field_ico_2___internal___fieldOwners',
  relationships___field_ico_2___internal___ignoreType = 'relationships___field_ico_2___internal___ignoreType',
  relationships___field_ico_2___internal___mediaType = 'relationships___field_ico_2___internal___mediaType',
  relationships___field_ico_2___internal___owner = 'relationships___field_ico_2___internal___owner',
  relationships___field_ico_2___internal___type = 'relationships___field_ico_2___internal___type',
  relationships___field_ico_3___localFile___birthtime = 'relationships___field_ico_3___localFile___birthtime',
  relationships___field_ico_3___localFile___birthtimeMs = 'relationships___field_ico_3___localFile___birthtimeMs',
  relationships___field_ico_3___localFile___publicURL = 'relationships___field_ico_3___localFile___publicURL',
  relationships___field_ico_3___localFile___sourceInstanceName = 'relationships___field_ico_3___localFile___sourceInstanceName',
  relationships___field_ico_3___localFile___absolutePath = 'relationships___field_ico_3___localFile___absolutePath',
  relationships___field_ico_3___localFile___relativePath = 'relationships___field_ico_3___localFile___relativePath',
  relationships___field_ico_3___localFile___extension = 'relationships___field_ico_3___localFile___extension',
  relationships___field_ico_3___localFile___size = 'relationships___field_ico_3___localFile___size',
  relationships___field_ico_3___localFile___prettySize = 'relationships___field_ico_3___localFile___prettySize',
  relationships___field_ico_3___localFile___modifiedTime = 'relationships___field_ico_3___localFile___modifiedTime',
  relationships___field_ico_3___localFile___accessTime = 'relationships___field_ico_3___localFile___accessTime',
  relationships___field_ico_3___localFile___changeTime = 'relationships___field_ico_3___localFile___changeTime',
  relationships___field_ico_3___localFile___birthTime = 'relationships___field_ico_3___localFile___birthTime',
  relationships___field_ico_3___localFile___root = 'relationships___field_ico_3___localFile___root',
  relationships___field_ico_3___localFile___dir = 'relationships___field_ico_3___localFile___dir',
  relationships___field_ico_3___localFile___base = 'relationships___field_ico_3___localFile___base',
  relationships___field_ico_3___localFile___ext = 'relationships___field_ico_3___localFile___ext',
  relationships___field_ico_3___localFile___name = 'relationships___field_ico_3___localFile___name',
  relationships___field_ico_3___localFile___relativeDirectory = 'relationships___field_ico_3___localFile___relativeDirectory',
  relationships___field_ico_3___localFile___dev = 'relationships___field_ico_3___localFile___dev',
  relationships___field_ico_3___localFile___mode = 'relationships___field_ico_3___localFile___mode',
  relationships___field_ico_3___localFile___nlink = 'relationships___field_ico_3___localFile___nlink',
  relationships___field_ico_3___localFile___uid = 'relationships___field_ico_3___localFile___uid',
  relationships___field_ico_3___localFile___gid = 'relationships___field_ico_3___localFile___gid',
  relationships___field_ico_3___localFile___rdev = 'relationships___field_ico_3___localFile___rdev',
  relationships___field_ico_3___localFile___blksize = 'relationships___field_ico_3___localFile___blksize',
  relationships___field_ico_3___localFile___ino = 'relationships___field_ico_3___localFile___ino',
  relationships___field_ico_3___localFile___blocks = 'relationships___field_ico_3___localFile___blocks',
  relationships___field_ico_3___localFile___atimeMs = 'relationships___field_ico_3___localFile___atimeMs',
  relationships___field_ico_3___localFile___mtimeMs = 'relationships___field_ico_3___localFile___mtimeMs',
  relationships___field_ico_3___localFile___ctimeMs = 'relationships___field_ico_3___localFile___ctimeMs',
  relationships___field_ico_3___localFile___atime = 'relationships___field_ico_3___localFile___atime',
  relationships___field_ico_3___localFile___mtime = 'relationships___field_ico_3___localFile___mtime',
  relationships___field_ico_3___localFile___ctime = 'relationships___field_ico_3___localFile___ctime',
  relationships___field_ico_3___localFile___url = 'relationships___field_ico_3___localFile___url',
  relationships___field_ico_3___localFile___id = 'relationships___field_ico_3___localFile___id',
  relationships___field_ico_3___localFile___children = 'relationships___field_ico_3___localFile___children',
  relationships___field_ico_3___uri___url = 'relationships___field_ico_3___uri___url',
  relationships___field_ico_3___drupal_id = 'relationships___field_ico_3___drupal_id',
  relationships___field_ico_3___drupal_internal__fid = 'relationships___field_ico_3___drupal_internal__fid',
  relationships___field_ico_3___langcode = 'relationships___field_ico_3___langcode',
  relationships___field_ico_3___filename = 'relationships___field_ico_3___filename',
  relationships___field_ico_3___filemime = 'relationships___field_ico_3___filemime',
  relationships___field_ico_3___filesize = 'relationships___field_ico_3___filesize',
  relationships___field_ico_3___status = 'relationships___field_ico_3___status',
  relationships___field_ico_3___created = 'relationships___field_ico_3___created',
  relationships___field_ico_3___changed = 'relationships___field_ico_3___changed',
  relationships___field_ico_3___uuid = 'relationships___field_ico_3___uuid',
  relationships___field_ico_3___relationships___node__blog_post = 'relationships___field_ico_3___relationships___node__blog_post',
  relationships___field_ico_3___relationships___node__devices = 'relationships___field_ico_3___relationships___node__devices',
  relationships___field_ico_3___relationships___node__accessories = 'relationships___field_ico_3___relationships___node__accessories',
  relationships___field_ico_3___relationships___paragraph__news_image_title_text = 'relationships___field_ico_3___relationships___paragraph__news_image_title_text',
  relationships___field_ico_3___relationships___node__cases = 'relationships___field_ico_3___relationships___node__cases',
  relationships___field_ico_3___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_3___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_3___relationships___paragraph__branches_features = 'relationships___field_ico_3___relationships___paragraph__branches_features',
  relationships___field_ico_3___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_3___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_3___relationships___paragraph__branches_testimonials = 'relationships___field_ico_3___relationships___paragraph__branches_testimonials',
  relationships___field_ico_3___relationships___paragraph__branches_discounts = 'relationships___field_ico_3___relationships___paragraph__branches_discounts',
  relationships___field_ico_3___relationships___node__news = 'relationships___field_ico_3___relationships___node__news',
  relationships___field_ico_3___relationships___node__branches = 'relationships___field_ico_3___relationships___node__branches',
  relationships___field_ico_3___relationships___paragraph__branches_hero = 'relationships___field_ico_3___relationships___paragraph__branches_hero',
  relationships___field_ico_3___relationships___paragraph__bullets = 'relationships___field_ico_3___relationships___paragraph__bullets',
  relationships___field_ico_3___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_3___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_3___relationships___paragraph__branches_device = 'relationships___field_ico_3___relationships___paragraph__branches_device',
  relationships___field_ico_3___relationships___paragraph__branches_recommendation = 'relationships___field_ico_3___relationships___paragraph__branches_recommendation',
  relationships___field_ico_3___relationships___site_setting_entity__accessories = 'relationships___field_ico_3___relationships___site_setting_entity__accessories',
  relationships___field_ico_3___relationships___site_setting_entity__branches = 'relationships___field_ico_3___relationships___site_setting_entity__branches',
  relationships___field_ico_3___relationships___site_setting_entity__about_us = 'relationships___field_ico_3___relationships___site_setting_entity__about_us',
  relationships___field_ico_3___relationships___paragraph__logos = 'relationships___field_ico_3___relationships___paragraph__logos',
  relationships___field_ico_3___relationships___paragraph__how_it_works = 'relationships___field_ico_3___relationships___paragraph__how_it_works',
  relationships___field_ico_3___relationships___paragraph__ico_and_text = 'relationships___field_ico_3___relationships___paragraph__ico_and_text',
  relationships___field_ico_3___relationships___paragraph__hero_1 = 'relationships___field_ico_3___relationships___paragraph__hero_1',
  relationships___field_ico_3___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_3___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_3___relationships___site_setting_entity__product = 'relationships___field_ico_3___relationships___site_setting_entity__product',
  relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_3___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_3___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_3___relationships___site_setting_entity__main_page = 'relationships___field_ico_3___relationships___site_setting_entity__main_page',
  relationships___field_ico_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_3___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_3___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_3___relationships___site_setting_entity__intercars = 'relationships___field_ico_3___relationships___site_setting_entity__intercars',
  relationships___field_ico_3___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_3___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_3___relationships___node__agreement = 'relationships___field_ico_3___relationships___node__agreement',
  relationships___field_ico_3___relationships___node__fiscalization = 'relationships___field_ico_3___relationships___node__fiscalization',
  relationships___field_ico_3___relationships___site_setting_entity__configurator = 'relationships___field_ico_3___relationships___site_setting_entity__configurator',
  relationships___field_ico_3___relationships___paragraph__social_link = 'relationships___field_ico_3___relationships___paragraph__social_link',
  relationships___field_ico_3___relationships___paragraph__make_an_appointment = 'relationships___field_ico_3___relationships___paragraph__make_an_appointment',
  relationships___field_ico_3___relationships___paragraph__linked_icons = 'relationships___field_ico_3___relationships___paragraph__linked_icons',
  relationships___field_ico_3___relationships___site_setting_entity__financing = 'relationships___field_ico_3___relationships___site_setting_entity__financing',
  relationships___field_ico_3___relationships___site_setting_entity__franchise = 'relationships___field_ico_3___relationships___site_setting_entity__franchise',
  relationships___field_ico_3___id = 'relationships___field_ico_3___id',
  relationships___field_ico_3___parent___id = 'relationships___field_ico_3___parent___id',
  relationships___field_ico_3___parent___children = 'relationships___field_ico_3___parent___children',
  relationships___field_ico_3___children = 'relationships___field_ico_3___children',
  relationships___field_ico_3___children___id = 'relationships___field_ico_3___children___id',
  relationships___field_ico_3___children___children = 'relationships___field_ico_3___children___children',
  relationships___field_ico_3___internal___content = 'relationships___field_ico_3___internal___content',
  relationships___field_ico_3___internal___contentDigest = 'relationships___field_ico_3___internal___contentDigest',
  relationships___field_ico_3___internal___description = 'relationships___field_ico_3___internal___description',
  relationships___field_ico_3___internal___fieldOwners = 'relationships___field_ico_3___internal___fieldOwners',
  relationships___field_ico_3___internal___ignoreType = 'relationships___field_ico_3___internal___ignoreType',
  relationships___field_ico_3___internal___mediaType = 'relationships___field_ico_3___internal___mediaType',
  relationships___field_ico_3___internal___owner = 'relationships___field_ico_3___internal___owner',
  relationships___field_ico_3___internal___type = 'relationships___field_ico_3___internal___type',
  relationships___field_ico_4___localFile___birthtime = 'relationships___field_ico_4___localFile___birthtime',
  relationships___field_ico_4___localFile___birthtimeMs = 'relationships___field_ico_4___localFile___birthtimeMs',
  relationships___field_ico_4___localFile___publicURL = 'relationships___field_ico_4___localFile___publicURL',
  relationships___field_ico_4___localFile___sourceInstanceName = 'relationships___field_ico_4___localFile___sourceInstanceName',
  relationships___field_ico_4___localFile___absolutePath = 'relationships___field_ico_4___localFile___absolutePath',
  relationships___field_ico_4___localFile___relativePath = 'relationships___field_ico_4___localFile___relativePath',
  relationships___field_ico_4___localFile___extension = 'relationships___field_ico_4___localFile___extension',
  relationships___field_ico_4___localFile___size = 'relationships___field_ico_4___localFile___size',
  relationships___field_ico_4___localFile___prettySize = 'relationships___field_ico_4___localFile___prettySize',
  relationships___field_ico_4___localFile___modifiedTime = 'relationships___field_ico_4___localFile___modifiedTime',
  relationships___field_ico_4___localFile___accessTime = 'relationships___field_ico_4___localFile___accessTime',
  relationships___field_ico_4___localFile___changeTime = 'relationships___field_ico_4___localFile___changeTime',
  relationships___field_ico_4___localFile___birthTime = 'relationships___field_ico_4___localFile___birthTime',
  relationships___field_ico_4___localFile___root = 'relationships___field_ico_4___localFile___root',
  relationships___field_ico_4___localFile___dir = 'relationships___field_ico_4___localFile___dir',
  relationships___field_ico_4___localFile___base = 'relationships___field_ico_4___localFile___base',
  relationships___field_ico_4___localFile___ext = 'relationships___field_ico_4___localFile___ext',
  relationships___field_ico_4___localFile___name = 'relationships___field_ico_4___localFile___name',
  relationships___field_ico_4___localFile___relativeDirectory = 'relationships___field_ico_4___localFile___relativeDirectory',
  relationships___field_ico_4___localFile___dev = 'relationships___field_ico_4___localFile___dev',
  relationships___field_ico_4___localFile___mode = 'relationships___field_ico_4___localFile___mode',
  relationships___field_ico_4___localFile___nlink = 'relationships___field_ico_4___localFile___nlink',
  relationships___field_ico_4___localFile___uid = 'relationships___field_ico_4___localFile___uid',
  relationships___field_ico_4___localFile___gid = 'relationships___field_ico_4___localFile___gid',
  relationships___field_ico_4___localFile___rdev = 'relationships___field_ico_4___localFile___rdev',
  relationships___field_ico_4___localFile___blksize = 'relationships___field_ico_4___localFile___blksize',
  relationships___field_ico_4___localFile___ino = 'relationships___field_ico_4___localFile___ino',
  relationships___field_ico_4___localFile___blocks = 'relationships___field_ico_4___localFile___blocks',
  relationships___field_ico_4___localFile___atimeMs = 'relationships___field_ico_4___localFile___atimeMs',
  relationships___field_ico_4___localFile___mtimeMs = 'relationships___field_ico_4___localFile___mtimeMs',
  relationships___field_ico_4___localFile___ctimeMs = 'relationships___field_ico_4___localFile___ctimeMs',
  relationships___field_ico_4___localFile___atime = 'relationships___field_ico_4___localFile___atime',
  relationships___field_ico_4___localFile___mtime = 'relationships___field_ico_4___localFile___mtime',
  relationships___field_ico_4___localFile___ctime = 'relationships___field_ico_4___localFile___ctime',
  relationships___field_ico_4___localFile___url = 'relationships___field_ico_4___localFile___url',
  relationships___field_ico_4___localFile___id = 'relationships___field_ico_4___localFile___id',
  relationships___field_ico_4___localFile___children = 'relationships___field_ico_4___localFile___children',
  relationships___field_ico_4___uri___url = 'relationships___field_ico_4___uri___url',
  relationships___field_ico_4___drupal_id = 'relationships___field_ico_4___drupal_id',
  relationships___field_ico_4___drupal_internal__fid = 'relationships___field_ico_4___drupal_internal__fid',
  relationships___field_ico_4___langcode = 'relationships___field_ico_4___langcode',
  relationships___field_ico_4___filename = 'relationships___field_ico_4___filename',
  relationships___field_ico_4___filemime = 'relationships___field_ico_4___filemime',
  relationships___field_ico_4___filesize = 'relationships___field_ico_4___filesize',
  relationships___field_ico_4___status = 'relationships___field_ico_4___status',
  relationships___field_ico_4___created = 'relationships___field_ico_4___created',
  relationships___field_ico_4___changed = 'relationships___field_ico_4___changed',
  relationships___field_ico_4___uuid = 'relationships___field_ico_4___uuid',
  relationships___field_ico_4___relationships___node__blog_post = 'relationships___field_ico_4___relationships___node__blog_post',
  relationships___field_ico_4___relationships___node__devices = 'relationships___field_ico_4___relationships___node__devices',
  relationships___field_ico_4___relationships___node__accessories = 'relationships___field_ico_4___relationships___node__accessories',
  relationships___field_ico_4___relationships___paragraph__news_image_title_text = 'relationships___field_ico_4___relationships___paragraph__news_image_title_text',
  relationships___field_ico_4___relationships___node__cases = 'relationships___field_ico_4___relationships___node__cases',
  relationships___field_ico_4___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_4___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_4___relationships___paragraph__branches_features = 'relationships___field_ico_4___relationships___paragraph__branches_features',
  relationships___field_ico_4___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_4___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_4___relationships___paragraph__branches_testimonials = 'relationships___field_ico_4___relationships___paragraph__branches_testimonials',
  relationships___field_ico_4___relationships___paragraph__branches_discounts = 'relationships___field_ico_4___relationships___paragraph__branches_discounts',
  relationships___field_ico_4___relationships___node__news = 'relationships___field_ico_4___relationships___node__news',
  relationships___field_ico_4___relationships___node__branches = 'relationships___field_ico_4___relationships___node__branches',
  relationships___field_ico_4___relationships___paragraph__branches_hero = 'relationships___field_ico_4___relationships___paragraph__branches_hero',
  relationships___field_ico_4___relationships___paragraph__bullets = 'relationships___field_ico_4___relationships___paragraph__bullets',
  relationships___field_ico_4___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_4___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_4___relationships___paragraph__branches_device = 'relationships___field_ico_4___relationships___paragraph__branches_device',
  relationships___field_ico_4___relationships___paragraph__branches_recommendation = 'relationships___field_ico_4___relationships___paragraph__branches_recommendation',
  relationships___field_ico_4___relationships___site_setting_entity__accessories = 'relationships___field_ico_4___relationships___site_setting_entity__accessories',
  relationships___field_ico_4___relationships___site_setting_entity__branches = 'relationships___field_ico_4___relationships___site_setting_entity__branches',
  relationships___field_ico_4___relationships___site_setting_entity__about_us = 'relationships___field_ico_4___relationships___site_setting_entity__about_us',
  relationships___field_ico_4___relationships___paragraph__logos = 'relationships___field_ico_4___relationships___paragraph__logos',
  relationships___field_ico_4___relationships___paragraph__how_it_works = 'relationships___field_ico_4___relationships___paragraph__how_it_works',
  relationships___field_ico_4___relationships___paragraph__ico_and_text = 'relationships___field_ico_4___relationships___paragraph__ico_and_text',
  relationships___field_ico_4___relationships___paragraph__hero_1 = 'relationships___field_ico_4___relationships___paragraph__hero_1',
  relationships___field_ico_4___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_4___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_4___relationships___site_setting_entity__product = 'relationships___field_ico_4___relationships___site_setting_entity__product',
  relationships___field_ico_4___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_4___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_4___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_4___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_4___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_4___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_4___relationships___site_setting_entity__main_page = 'relationships___field_ico_4___relationships___site_setting_entity__main_page',
  relationships___field_ico_4___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_4___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_4___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_4___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_4___relationships___site_setting_entity__intercars = 'relationships___field_ico_4___relationships___site_setting_entity__intercars',
  relationships___field_ico_4___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_4___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_4___relationships___node__agreement = 'relationships___field_ico_4___relationships___node__agreement',
  relationships___field_ico_4___relationships___node__fiscalization = 'relationships___field_ico_4___relationships___node__fiscalization',
  relationships___field_ico_4___relationships___site_setting_entity__configurator = 'relationships___field_ico_4___relationships___site_setting_entity__configurator',
  relationships___field_ico_4___relationships___paragraph__social_link = 'relationships___field_ico_4___relationships___paragraph__social_link',
  relationships___field_ico_4___relationships___paragraph__make_an_appointment = 'relationships___field_ico_4___relationships___paragraph__make_an_appointment',
  relationships___field_ico_4___relationships___paragraph__linked_icons = 'relationships___field_ico_4___relationships___paragraph__linked_icons',
  relationships___field_ico_4___relationships___site_setting_entity__financing = 'relationships___field_ico_4___relationships___site_setting_entity__financing',
  relationships___field_ico_4___relationships___site_setting_entity__franchise = 'relationships___field_ico_4___relationships___site_setting_entity__franchise',
  relationships___field_ico_4___id = 'relationships___field_ico_4___id',
  relationships___field_ico_4___parent___id = 'relationships___field_ico_4___parent___id',
  relationships___field_ico_4___parent___children = 'relationships___field_ico_4___parent___children',
  relationships___field_ico_4___children = 'relationships___field_ico_4___children',
  relationships___field_ico_4___children___id = 'relationships___field_ico_4___children___id',
  relationships___field_ico_4___children___children = 'relationships___field_ico_4___children___children',
  relationships___field_ico_4___internal___content = 'relationships___field_ico_4___internal___content',
  relationships___field_ico_4___internal___contentDigest = 'relationships___field_ico_4___internal___contentDigest',
  relationships___field_ico_4___internal___description = 'relationships___field_ico_4___internal___description',
  relationships___field_ico_4___internal___fieldOwners = 'relationships___field_ico_4___internal___fieldOwners',
  relationships___field_ico_4___internal___ignoreType = 'relationships___field_ico_4___internal___ignoreType',
  relationships___field_ico_4___internal___mediaType = 'relationships___field_ico_4___internal___mediaType',
  relationships___field_ico_4___internal___owner = 'relationships___field_ico_4___internal___owner',
  relationships___field_ico_4___internal___type = 'relationships___field_ico_4___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  relationships___node__pages = 'relationships___node__pages',
  relationships___node__pages___langcode = 'relationships___node__pages___langcode',
  relationships___node__pages___id = 'relationships___node__pages___id',
  relationships___node__pages___title = 'relationships___node__pages___title',
  relationships___node__pages___field_pages_text___value = 'relationships___node__pages___field_pages_text___value',
  relationships___node__pages___path___alias = 'relationships___node__pages___path___alias',
  relationships___node__pages___field_meta_title = 'relationships___node__pages___field_meta_title',
  relationships___node__pages___drupal_id = 'relationships___node__pages___drupal_id',
  relationships___node__pages___drupal_internal__nid = 'relationships___node__pages___drupal_internal__nid',
  relationships___node__pages___drupal_internal__vid = 'relationships___node__pages___drupal_internal__vid',
  relationships___node__pages___rh_action = 'relationships___node__pages___rh_action',
  relationships___node__pages___rh_redirect_response = 'relationships___node__pages___rh_redirect_response',
  relationships___node__pages___rh_redirect_fallback_action = 'relationships___node__pages___rh_redirect_fallback_action',
  relationships___node__pages___content_translation_source = 'relationships___node__pages___content_translation_source',
  relationships___node__pages___content_translation_outdated = 'relationships___node__pages___content_translation_outdated',
  relationships___node__pages___field_meta_description = 'relationships___node__pages___field_meta_description',
  relationships___node__pages___field_meta_keywords = 'relationships___node__pages___field_meta_keywords',
  relationships___node__pages___uuid = 'relationships___node__pages___uuid',
  relationships___node__pages___field_components = 'relationships___node__pages___field_components',
  relationships___node__pages___field_components___target_revision_id = 'relationships___node__pages___field_components___target_revision_id',
  relationships___node__pages___field_components___drupal_internal__target_id = 'relationships___node__pages___field_components___drupal_internal__target_id',
  relationships___node__pages___parent___id = 'relationships___node__pages___parent___id',
  relationships___node__pages___parent___children = 'relationships___node__pages___parent___children',
  relationships___node__pages___children = 'relationships___node__pages___children',
  relationships___node__pages___children___id = 'relationships___node__pages___children___id',
  relationships___node__pages___children___children = 'relationships___node__pages___children___children',
  relationships___node__pages___internal___content = 'relationships___node__pages___internal___content',
  relationships___node__pages___internal___contentDigest = 'relationships___node__pages___internal___contentDigest',
  relationships___node__pages___internal___description = 'relationships___node__pages___internal___description',
  relationships___node__pages___internal___fieldOwners = 'relationships___node__pages___internal___fieldOwners',
  relationships___node__pages___internal___ignoreType = 'relationships___node__pages___internal___ignoreType',
  relationships___node__pages___internal___mediaType = 'relationships___node__pages___internal___mediaType',
  relationships___node__pages___internal___owner = 'relationships___node__pages___internal___owner',
  relationships___node__pages___internal___type = 'relationships___node__pages___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_branches_image___alt = 'field_branches_image___alt',
  field_branches_image___title = 'field_branches_image___title',
  field_branches_image___width = 'field_branches_image___width',
  field_branches_image___height = 'field_branches_image___height',
  field_branches_image___drupal_internal__target_id = 'field_branches_image___drupal_internal__target_id',
  field_ico_1___alt = 'field_ico_1___alt',
  field_ico_1___title = 'field_ico_1___title',
  field_ico_1___width = 'field_ico_1___width',
  field_ico_1___height = 'field_ico_1___height',
  field_ico_1___drupal_internal__target_id = 'field_ico_1___drupal_internal__target_id',
  field_ico_2___alt = 'field_ico_2___alt',
  field_ico_2___title = 'field_ico_2___title',
  field_ico_2___width = 'field_ico_2___width',
  field_ico_2___height = 'field_ico_2___height',
  field_ico_2___drupal_internal__target_id = 'field_ico_2___drupal_internal__target_id',
  field_ico_3___alt = 'field_ico_3___alt',
  field_ico_3___title = 'field_ico_3___title',
  field_ico_3___width = 'field_ico_3___width',
  field_ico_3___height = 'field_ico_3___height',
  field_ico_3___drupal_internal__target_id = 'field_ico_3___drupal_internal__target_id',
  field_ico_4___alt = 'field_ico_4___alt',
  field_ico_4___title = 'field_ico_4___title',
  field_ico_4___width = 'field_ico_4___width',
  field_ico_4___height = 'field_ico_4___height',
  field_ico_4___drupal_internal__target_id = 'field_ico_4___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_FeaturesFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  field_short_text_2?: Maybe<Field_Short_Text_2FilterInput>;
  field_short_text_3?: Maybe<Field_Short_Text_3FilterInput>;
  field_short_text_4?: Maybe<Field_Short_Text_4FilterInput>;
  relationships?: Maybe<Paragraph__Branches_FeaturesRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Paragraph__Branches_FeaturesField_Branches_ImageFilterInput>;
  field_ico_1?: Maybe<Paragraph__Branches_FeaturesField_Ico_1FilterInput>;
  field_ico_2?: Maybe<Paragraph__Branches_FeaturesField_Ico_2FilterInput>;
  field_ico_3?: Maybe<Paragraph__Branches_FeaturesField_Ico_3FilterInput>;
  field_ico_4?: Maybe<Paragraph__Branches_FeaturesField_Ico_4FilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_FeaturesFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_FeaturesFilterInput>;
};

export type Paragraph__Branches_FeaturesGroupConnection = {
  __typename?: 'paragraph__branches_featuresGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_FeaturesEdge>;
  nodes: Array<Paragraph__Branches_Features>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_FeaturesRelationships = {
  __typename?: 'paragraph__branches_featuresRelationships';
  field_branches_image?: Maybe<File__File>;
  field_ico_1?: Maybe<File__File>;
  field_ico_2?: Maybe<File__File>;
  field_ico_3?: Maybe<File__File>;
  field_ico_4?: Maybe<File__File>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
  node__pages?: Maybe<Array<Maybe<Node__Pages>>>;
};

export type Paragraph__Branches_FeaturesRelationshipsFilterInput = {
  field_branches_image?: Maybe<File__FileFilterInput>;
  field_ico_1?: Maybe<File__FileFilterInput>;
  field_ico_2?: Maybe<File__FileFilterInput>;
  field_ico_3?: Maybe<File__FileFilterInput>;
  field_ico_4?: Maybe<File__FileFilterInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
  node__pages?: Maybe<Node__PagesFilterListInput>;
};

export type Paragraph__Branches_FeaturesSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_FeaturesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Hero = Node & {
  __typename?: 'paragraph__branches_hero';
  id: Scalars['ID'];
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  field_hero_1_video?: Maybe<Scalars['String']>;
  field_branches_text?: Maybe<Field_Branches_Text>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_branches_button_text?: Maybe<Scalars['String']>;
  field_branches_image?: Maybe<Field_Branches_Image>;
  field_branches_image_mobile?: Maybe<Field_Branches_Image_Mobile>;
  field_video_thumbnail?: Maybe<Field_Video_Thumbnail>;
  relationships?: Maybe<Paragraph__Branches_HeroRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_device_image?: Maybe<Paragraph__Branches_HeroField_Branches_Device_Image>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_HeroCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_HeroContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_HeroConnection = {
  __typename?: 'paragraph__branches_heroConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_HeroEdge>;
  nodes: Array<Paragraph__Branches_Hero>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_HeroGroupConnection>;
};

export type Paragraph__Branches_HeroConnectionDistinctArgs = {
  field: Paragraph__Branches_HeroFieldsEnum;
};

export type Paragraph__Branches_HeroConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_HeroFieldsEnum;
};

export type Paragraph__Branches_HeroEdge = {
  __typename?: 'paragraph__branches_heroEdge';
  next?: Maybe<Paragraph__Branches_Hero>;
  node: Paragraph__Branches_Hero;
  previous?: Maybe<Paragraph__Branches_Hero>;
};

export type Paragraph__Branches_HeroField_Branches_Device_Image = {
  __typename?: 'paragraph__branches_heroField_branches_device_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_HeroField_Branches_Device_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Paragraph__Branches_HeroFieldsEnum {
  id = 'id',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  parent_field_name = 'parent_field_name',
  field_hero_1_video = 'field_hero_1_video',
  field_branches_text___value = 'field_branches_text___value',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_branches_button_text = 'field_branches_button_text',
  field_branches_image___alt = 'field_branches_image___alt',
  field_branches_image_mobile___alt = 'field_branches_image_mobile___alt',
  field_video_thumbnail___alt = 'field_video_thumbnail___alt',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___field_branches_device_image___localFile___birthtime = 'relationships___field_branches_device_image___localFile___birthtime',
  relationships___field_branches_device_image___localFile___birthtimeMs = 'relationships___field_branches_device_image___localFile___birthtimeMs',
  relationships___field_branches_device_image___localFile___publicURL = 'relationships___field_branches_device_image___localFile___publicURL',
  relationships___field_branches_device_image___localFile___sourceInstanceName = 'relationships___field_branches_device_image___localFile___sourceInstanceName',
  relationships___field_branches_device_image___localFile___absolutePath = 'relationships___field_branches_device_image___localFile___absolutePath',
  relationships___field_branches_device_image___localFile___relativePath = 'relationships___field_branches_device_image___localFile___relativePath',
  relationships___field_branches_device_image___localFile___extension = 'relationships___field_branches_device_image___localFile___extension',
  relationships___field_branches_device_image___localFile___size = 'relationships___field_branches_device_image___localFile___size',
  relationships___field_branches_device_image___localFile___prettySize = 'relationships___field_branches_device_image___localFile___prettySize',
  relationships___field_branches_device_image___localFile___modifiedTime = 'relationships___field_branches_device_image___localFile___modifiedTime',
  relationships___field_branches_device_image___localFile___accessTime = 'relationships___field_branches_device_image___localFile___accessTime',
  relationships___field_branches_device_image___localFile___changeTime = 'relationships___field_branches_device_image___localFile___changeTime',
  relationships___field_branches_device_image___localFile___birthTime = 'relationships___field_branches_device_image___localFile___birthTime',
  relationships___field_branches_device_image___localFile___root = 'relationships___field_branches_device_image___localFile___root',
  relationships___field_branches_device_image___localFile___dir = 'relationships___field_branches_device_image___localFile___dir',
  relationships___field_branches_device_image___localFile___base = 'relationships___field_branches_device_image___localFile___base',
  relationships___field_branches_device_image___localFile___ext = 'relationships___field_branches_device_image___localFile___ext',
  relationships___field_branches_device_image___localFile___name = 'relationships___field_branches_device_image___localFile___name',
  relationships___field_branches_device_image___localFile___relativeDirectory = 'relationships___field_branches_device_image___localFile___relativeDirectory',
  relationships___field_branches_device_image___localFile___dev = 'relationships___field_branches_device_image___localFile___dev',
  relationships___field_branches_device_image___localFile___mode = 'relationships___field_branches_device_image___localFile___mode',
  relationships___field_branches_device_image___localFile___nlink = 'relationships___field_branches_device_image___localFile___nlink',
  relationships___field_branches_device_image___localFile___uid = 'relationships___field_branches_device_image___localFile___uid',
  relationships___field_branches_device_image___localFile___gid = 'relationships___field_branches_device_image___localFile___gid',
  relationships___field_branches_device_image___localFile___rdev = 'relationships___field_branches_device_image___localFile___rdev',
  relationships___field_branches_device_image___localFile___blksize = 'relationships___field_branches_device_image___localFile___blksize',
  relationships___field_branches_device_image___localFile___ino = 'relationships___field_branches_device_image___localFile___ino',
  relationships___field_branches_device_image___localFile___blocks = 'relationships___field_branches_device_image___localFile___blocks',
  relationships___field_branches_device_image___localFile___atimeMs = 'relationships___field_branches_device_image___localFile___atimeMs',
  relationships___field_branches_device_image___localFile___mtimeMs = 'relationships___field_branches_device_image___localFile___mtimeMs',
  relationships___field_branches_device_image___localFile___ctimeMs = 'relationships___field_branches_device_image___localFile___ctimeMs',
  relationships___field_branches_device_image___localFile___atime = 'relationships___field_branches_device_image___localFile___atime',
  relationships___field_branches_device_image___localFile___mtime = 'relationships___field_branches_device_image___localFile___mtime',
  relationships___field_branches_device_image___localFile___ctime = 'relationships___field_branches_device_image___localFile___ctime',
  relationships___field_branches_device_image___localFile___url = 'relationships___field_branches_device_image___localFile___url',
  relationships___field_branches_device_image___localFile___id = 'relationships___field_branches_device_image___localFile___id',
  relationships___field_branches_device_image___localFile___children = 'relationships___field_branches_device_image___localFile___children',
  relationships___field_branches_device_image___uri___url = 'relationships___field_branches_device_image___uri___url',
  relationships___field_branches_device_image___drupal_id = 'relationships___field_branches_device_image___drupal_id',
  relationships___field_branches_device_image___drupal_internal__fid = 'relationships___field_branches_device_image___drupal_internal__fid',
  relationships___field_branches_device_image___langcode = 'relationships___field_branches_device_image___langcode',
  relationships___field_branches_device_image___filename = 'relationships___field_branches_device_image___filename',
  relationships___field_branches_device_image___filemime = 'relationships___field_branches_device_image___filemime',
  relationships___field_branches_device_image___filesize = 'relationships___field_branches_device_image___filesize',
  relationships___field_branches_device_image___status = 'relationships___field_branches_device_image___status',
  relationships___field_branches_device_image___created = 'relationships___field_branches_device_image___created',
  relationships___field_branches_device_image___changed = 'relationships___field_branches_device_image___changed',
  relationships___field_branches_device_image___uuid = 'relationships___field_branches_device_image___uuid',
  relationships___field_branches_device_image___relationships___node__blog_post = 'relationships___field_branches_device_image___relationships___node__blog_post',
  relationships___field_branches_device_image___relationships___node__devices = 'relationships___field_branches_device_image___relationships___node__devices',
  relationships___field_branches_device_image___relationships___node__accessories = 'relationships___field_branches_device_image___relationships___node__accessories',
  relationships___field_branches_device_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_device_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_device_image___relationships___node__cases = 'relationships___field_branches_device_image___relationships___node__cases',
  relationships___field_branches_device_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_device_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_device_image___relationships___paragraph__branches_features = 'relationships___field_branches_device_image___relationships___paragraph__branches_features',
  relationships___field_branches_device_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_device_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_device_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_device_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_device_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_device_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_device_image___relationships___node__news = 'relationships___field_branches_device_image___relationships___node__news',
  relationships___field_branches_device_image___relationships___node__branches = 'relationships___field_branches_device_image___relationships___node__branches',
  relationships___field_branches_device_image___relationships___paragraph__branches_hero = 'relationships___field_branches_device_image___relationships___paragraph__branches_hero',
  relationships___field_branches_device_image___relationships___paragraph__bullets = 'relationships___field_branches_device_image___relationships___paragraph__bullets',
  relationships___field_branches_device_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_device_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_device_image___relationships___paragraph__branches_device = 'relationships___field_branches_device_image___relationships___paragraph__branches_device',
  relationships___field_branches_device_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_device_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_device_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_device_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_device_image___relationships___site_setting_entity__branches = 'relationships___field_branches_device_image___relationships___site_setting_entity__branches',
  relationships___field_branches_device_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_device_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_device_image___relationships___paragraph__logos = 'relationships___field_branches_device_image___relationships___paragraph__logos',
  relationships___field_branches_device_image___relationships___paragraph__how_it_works = 'relationships___field_branches_device_image___relationships___paragraph__how_it_works',
  relationships___field_branches_device_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_device_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_device_image___relationships___paragraph__hero_1 = 'relationships___field_branches_device_image___relationships___paragraph__hero_1',
  relationships___field_branches_device_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_device_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_device_image___relationships___site_setting_entity__product = 'relationships___field_branches_device_image___relationships___site_setting_entity__product',
  relationships___field_branches_device_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_device_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_device_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_device_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_device_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_device_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_device_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_device_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_device_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_device_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_device_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_device_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_device_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_device_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_device_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_device_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_device_image___relationships___node__agreement = 'relationships___field_branches_device_image___relationships___node__agreement',
  relationships___field_branches_device_image___relationships___node__fiscalization = 'relationships___field_branches_device_image___relationships___node__fiscalization',
  relationships___field_branches_device_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_device_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_device_image___relationships___paragraph__social_link = 'relationships___field_branches_device_image___relationships___paragraph__social_link',
  relationships___field_branches_device_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_device_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_device_image___relationships___paragraph__linked_icons = 'relationships___field_branches_device_image___relationships___paragraph__linked_icons',
  relationships___field_branches_device_image___relationships___site_setting_entity__financing = 'relationships___field_branches_device_image___relationships___site_setting_entity__financing',
  relationships___field_branches_device_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_device_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_device_image___id = 'relationships___field_branches_device_image___id',
  relationships___field_branches_device_image___parent___id = 'relationships___field_branches_device_image___parent___id',
  relationships___field_branches_device_image___parent___children = 'relationships___field_branches_device_image___parent___children',
  relationships___field_branches_device_image___children = 'relationships___field_branches_device_image___children',
  relationships___field_branches_device_image___children___id = 'relationships___field_branches_device_image___children___id',
  relationships___field_branches_device_image___children___children = 'relationships___field_branches_device_image___children___children',
  relationships___field_branches_device_image___internal___content = 'relationships___field_branches_device_image___internal___content',
  relationships___field_branches_device_image___internal___contentDigest = 'relationships___field_branches_device_image___internal___contentDigest',
  relationships___field_branches_device_image___internal___description = 'relationships___field_branches_device_image___internal___description',
  relationships___field_branches_device_image___internal___fieldOwners = 'relationships___field_branches_device_image___internal___fieldOwners',
  relationships___field_branches_device_image___internal___ignoreType = 'relationships___field_branches_device_image___internal___ignoreType',
  relationships___field_branches_device_image___internal___mediaType = 'relationships___field_branches_device_image___internal___mediaType',
  relationships___field_branches_device_image___internal___owner = 'relationships___field_branches_device_image___internal___owner',
  relationships___field_branches_device_image___internal___type = 'relationships___field_branches_device_image___internal___type',
  relationships___field_branches_image_mobile___localFile___birthtime = 'relationships___field_branches_image_mobile___localFile___birthtime',
  relationships___field_branches_image_mobile___localFile___birthtimeMs = 'relationships___field_branches_image_mobile___localFile___birthtimeMs',
  relationships___field_branches_image_mobile___localFile___publicURL = 'relationships___field_branches_image_mobile___localFile___publicURL',
  relationships___field_branches_image_mobile___localFile___sourceInstanceName = 'relationships___field_branches_image_mobile___localFile___sourceInstanceName',
  relationships___field_branches_image_mobile___localFile___absolutePath = 'relationships___field_branches_image_mobile___localFile___absolutePath',
  relationships___field_branches_image_mobile___localFile___relativePath = 'relationships___field_branches_image_mobile___localFile___relativePath',
  relationships___field_branches_image_mobile___localFile___extension = 'relationships___field_branches_image_mobile___localFile___extension',
  relationships___field_branches_image_mobile___localFile___size = 'relationships___field_branches_image_mobile___localFile___size',
  relationships___field_branches_image_mobile___localFile___prettySize = 'relationships___field_branches_image_mobile___localFile___prettySize',
  relationships___field_branches_image_mobile___localFile___modifiedTime = 'relationships___field_branches_image_mobile___localFile___modifiedTime',
  relationships___field_branches_image_mobile___localFile___accessTime = 'relationships___field_branches_image_mobile___localFile___accessTime',
  relationships___field_branches_image_mobile___localFile___changeTime = 'relationships___field_branches_image_mobile___localFile___changeTime',
  relationships___field_branches_image_mobile___localFile___birthTime = 'relationships___field_branches_image_mobile___localFile___birthTime',
  relationships___field_branches_image_mobile___localFile___root = 'relationships___field_branches_image_mobile___localFile___root',
  relationships___field_branches_image_mobile___localFile___dir = 'relationships___field_branches_image_mobile___localFile___dir',
  relationships___field_branches_image_mobile___localFile___base = 'relationships___field_branches_image_mobile___localFile___base',
  relationships___field_branches_image_mobile___localFile___ext = 'relationships___field_branches_image_mobile___localFile___ext',
  relationships___field_branches_image_mobile___localFile___name = 'relationships___field_branches_image_mobile___localFile___name',
  relationships___field_branches_image_mobile___localFile___relativeDirectory = 'relationships___field_branches_image_mobile___localFile___relativeDirectory',
  relationships___field_branches_image_mobile___localFile___dev = 'relationships___field_branches_image_mobile___localFile___dev',
  relationships___field_branches_image_mobile___localFile___mode = 'relationships___field_branches_image_mobile___localFile___mode',
  relationships___field_branches_image_mobile___localFile___nlink = 'relationships___field_branches_image_mobile___localFile___nlink',
  relationships___field_branches_image_mobile___localFile___uid = 'relationships___field_branches_image_mobile___localFile___uid',
  relationships___field_branches_image_mobile___localFile___gid = 'relationships___field_branches_image_mobile___localFile___gid',
  relationships___field_branches_image_mobile___localFile___rdev = 'relationships___field_branches_image_mobile___localFile___rdev',
  relationships___field_branches_image_mobile___localFile___blksize = 'relationships___field_branches_image_mobile___localFile___blksize',
  relationships___field_branches_image_mobile___localFile___ino = 'relationships___field_branches_image_mobile___localFile___ino',
  relationships___field_branches_image_mobile___localFile___blocks = 'relationships___field_branches_image_mobile___localFile___blocks',
  relationships___field_branches_image_mobile___localFile___atimeMs = 'relationships___field_branches_image_mobile___localFile___atimeMs',
  relationships___field_branches_image_mobile___localFile___mtimeMs = 'relationships___field_branches_image_mobile___localFile___mtimeMs',
  relationships___field_branches_image_mobile___localFile___ctimeMs = 'relationships___field_branches_image_mobile___localFile___ctimeMs',
  relationships___field_branches_image_mobile___localFile___atime = 'relationships___field_branches_image_mobile___localFile___atime',
  relationships___field_branches_image_mobile___localFile___mtime = 'relationships___field_branches_image_mobile___localFile___mtime',
  relationships___field_branches_image_mobile___localFile___ctime = 'relationships___field_branches_image_mobile___localFile___ctime',
  relationships___field_branches_image_mobile___localFile___url = 'relationships___field_branches_image_mobile___localFile___url',
  relationships___field_branches_image_mobile___localFile___id = 'relationships___field_branches_image_mobile___localFile___id',
  relationships___field_branches_image_mobile___localFile___children = 'relationships___field_branches_image_mobile___localFile___children',
  relationships___field_branches_image_mobile___uri___url = 'relationships___field_branches_image_mobile___uri___url',
  relationships___field_branches_image_mobile___drupal_id = 'relationships___field_branches_image_mobile___drupal_id',
  relationships___field_branches_image_mobile___drupal_internal__fid = 'relationships___field_branches_image_mobile___drupal_internal__fid',
  relationships___field_branches_image_mobile___langcode = 'relationships___field_branches_image_mobile___langcode',
  relationships___field_branches_image_mobile___filename = 'relationships___field_branches_image_mobile___filename',
  relationships___field_branches_image_mobile___filemime = 'relationships___field_branches_image_mobile___filemime',
  relationships___field_branches_image_mobile___filesize = 'relationships___field_branches_image_mobile___filesize',
  relationships___field_branches_image_mobile___status = 'relationships___field_branches_image_mobile___status',
  relationships___field_branches_image_mobile___created = 'relationships___field_branches_image_mobile___created',
  relationships___field_branches_image_mobile___changed = 'relationships___field_branches_image_mobile___changed',
  relationships___field_branches_image_mobile___uuid = 'relationships___field_branches_image_mobile___uuid',
  relationships___field_branches_image_mobile___relationships___node__blog_post = 'relationships___field_branches_image_mobile___relationships___node__blog_post',
  relationships___field_branches_image_mobile___relationships___node__devices = 'relationships___field_branches_image_mobile___relationships___node__devices',
  relationships___field_branches_image_mobile___relationships___node__accessories = 'relationships___field_branches_image_mobile___relationships___node__accessories',
  relationships___field_branches_image_mobile___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image_mobile___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image_mobile___relationships___node__cases = 'relationships___field_branches_image_mobile___relationships___node__cases',
  relationships___field_branches_image_mobile___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image_mobile___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image_mobile___relationships___paragraph__branches_features = 'relationships___field_branches_image_mobile___relationships___paragraph__branches_features',
  relationships___field_branches_image_mobile___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image_mobile___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image_mobile___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image_mobile___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image_mobile___relationships___paragraph__branches_discounts = 'relationships___field_branches_image_mobile___relationships___paragraph__branches_discounts',
  relationships___field_branches_image_mobile___relationships___node__news = 'relationships___field_branches_image_mobile___relationships___node__news',
  relationships___field_branches_image_mobile___relationships___node__branches = 'relationships___field_branches_image_mobile___relationships___node__branches',
  relationships___field_branches_image_mobile___relationships___paragraph__branches_hero = 'relationships___field_branches_image_mobile___relationships___paragraph__branches_hero',
  relationships___field_branches_image_mobile___relationships___paragraph__bullets = 'relationships___field_branches_image_mobile___relationships___paragraph__bullets',
  relationships___field_branches_image_mobile___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image_mobile___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image_mobile___relationships___paragraph__branches_device = 'relationships___field_branches_image_mobile___relationships___paragraph__branches_device',
  relationships___field_branches_image_mobile___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image_mobile___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__accessories = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__accessories',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__branches = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__branches',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__about_us = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__about_us',
  relationships___field_branches_image_mobile___relationships___paragraph__logos = 'relationships___field_branches_image_mobile___relationships___paragraph__logos',
  relationships___field_branches_image_mobile___relationships___paragraph__how_it_works = 'relationships___field_branches_image_mobile___relationships___paragraph__how_it_works',
  relationships___field_branches_image_mobile___relationships___paragraph__ico_and_text = 'relationships___field_branches_image_mobile___relationships___paragraph__ico_and_text',
  relationships___field_branches_image_mobile___relationships___paragraph__hero_1 = 'relationships___field_branches_image_mobile___relationships___paragraph__hero_1',
  relationships___field_branches_image_mobile___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image_mobile___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__product = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__product',
  relationships___field_branches_image_mobile___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image_mobile___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image_mobile___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image_mobile___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image_mobile___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image_mobile___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__main_page = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__main_page',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image_mobile___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image_mobile___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__intercars = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__intercars',
  relationships___field_branches_image_mobile___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image_mobile___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image_mobile___relationships___node__agreement = 'relationships___field_branches_image_mobile___relationships___node__agreement',
  relationships___field_branches_image_mobile___relationships___node__fiscalization = 'relationships___field_branches_image_mobile___relationships___node__fiscalization',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__configurator = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__configurator',
  relationships___field_branches_image_mobile___relationships___paragraph__social_link = 'relationships___field_branches_image_mobile___relationships___paragraph__social_link',
  relationships___field_branches_image_mobile___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image_mobile___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image_mobile___relationships___paragraph__linked_icons = 'relationships___field_branches_image_mobile___relationships___paragraph__linked_icons',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__financing = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__financing',
  relationships___field_branches_image_mobile___relationships___site_setting_entity__franchise = 'relationships___field_branches_image_mobile___relationships___site_setting_entity__franchise',
  relationships___field_branches_image_mobile___id = 'relationships___field_branches_image_mobile___id',
  relationships___field_branches_image_mobile___parent___id = 'relationships___field_branches_image_mobile___parent___id',
  relationships___field_branches_image_mobile___parent___children = 'relationships___field_branches_image_mobile___parent___children',
  relationships___field_branches_image_mobile___children = 'relationships___field_branches_image_mobile___children',
  relationships___field_branches_image_mobile___children___id = 'relationships___field_branches_image_mobile___children___id',
  relationships___field_branches_image_mobile___children___children = 'relationships___field_branches_image_mobile___children___children',
  relationships___field_branches_image_mobile___internal___content = 'relationships___field_branches_image_mobile___internal___content',
  relationships___field_branches_image_mobile___internal___contentDigest = 'relationships___field_branches_image_mobile___internal___contentDigest',
  relationships___field_branches_image_mobile___internal___description = 'relationships___field_branches_image_mobile___internal___description',
  relationships___field_branches_image_mobile___internal___fieldOwners = 'relationships___field_branches_image_mobile___internal___fieldOwners',
  relationships___field_branches_image_mobile___internal___ignoreType = 'relationships___field_branches_image_mobile___internal___ignoreType',
  relationships___field_branches_image_mobile___internal___mediaType = 'relationships___field_branches_image_mobile___internal___mediaType',
  relationships___field_branches_image_mobile___internal___owner = 'relationships___field_branches_image_mobile___internal___owner',
  relationships___field_branches_image_mobile___internal___type = 'relationships___field_branches_image_mobile___internal___type',
  relationships___field_video_thumbnail___localFile___birthtime = 'relationships___field_video_thumbnail___localFile___birthtime',
  relationships___field_video_thumbnail___localFile___birthtimeMs = 'relationships___field_video_thumbnail___localFile___birthtimeMs',
  relationships___field_video_thumbnail___localFile___publicURL = 'relationships___field_video_thumbnail___localFile___publicURL',
  relationships___field_video_thumbnail___localFile___sourceInstanceName = 'relationships___field_video_thumbnail___localFile___sourceInstanceName',
  relationships___field_video_thumbnail___localFile___absolutePath = 'relationships___field_video_thumbnail___localFile___absolutePath',
  relationships___field_video_thumbnail___localFile___relativePath = 'relationships___field_video_thumbnail___localFile___relativePath',
  relationships___field_video_thumbnail___localFile___extension = 'relationships___field_video_thumbnail___localFile___extension',
  relationships___field_video_thumbnail___localFile___size = 'relationships___field_video_thumbnail___localFile___size',
  relationships___field_video_thumbnail___localFile___prettySize = 'relationships___field_video_thumbnail___localFile___prettySize',
  relationships___field_video_thumbnail___localFile___modifiedTime = 'relationships___field_video_thumbnail___localFile___modifiedTime',
  relationships___field_video_thumbnail___localFile___accessTime = 'relationships___field_video_thumbnail___localFile___accessTime',
  relationships___field_video_thumbnail___localFile___changeTime = 'relationships___field_video_thumbnail___localFile___changeTime',
  relationships___field_video_thumbnail___localFile___birthTime = 'relationships___field_video_thumbnail___localFile___birthTime',
  relationships___field_video_thumbnail___localFile___root = 'relationships___field_video_thumbnail___localFile___root',
  relationships___field_video_thumbnail___localFile___dir = 'relationships___field_video_thumbnail___localFile___dir',
  relationships___field_video_thumbnail___localFile___base = 'relationships___field_video_thumbnail___localFile___base',
  relationships___field_video_thumbnail___localFile___ext = 'relationships___field_video_thumbnail___localFile___ext',
  relationships___field_video_thumbnail___localFile___name = 'relationships___field_video_thumbnail___localFile___name',
  relationships___field_video_thumbnail___localFile___relativeDirectory = 'relationships___field_video_thumbnail___localFile___relativeDirectory',
  relationships___field_video_thumbnail___localFile___dev = 'relationships___field_video_thumbnail___localFile___dev',
  relationships___field_video_thumbnail___localFile___mode = 'relationships___field_video_thumbnail___localFile___mode',
  relationships___field_video_thumbnail___localFile___nlink = 'relationships___field_video_thumbnail___localFile___nlink',
  relationships___field_video_thumbnail___localFile___uid = 'relationships___field_video_thumbnail___localFile___uid',
  relationships___field_video_thumbnail___localFile___gid = 'relationships___field_video_thumbnail___localFile___gid',
  relationships___field_video_thumbnail___localFile___rdev = 'relationships___field_video_thumbnail___localFile___rdev',
  relationships___field_video_thumbnail___localFile___blksize = 'relationships___field_video_thumbnail___localFile___blksize',
  relationships___field_video_thumbnail___localFile___ino = 'relationships___field_video_thumbnail___localFile___ino',
  relationships___field_video_thumbnail___localFile___blocks = 'relationships___field_video_thumbnail___localFile___blocks',
  relationships___field_video_thumbnail___localFile___atimeMs = 'relationships___field_video_thumbnail___localFile___atimeMs',
  relationships___field_video_thumbnail___localFile___mtimeMs = 'relationships___field_video_thumbnail___localFile___mtimeMs',
  relationships___field_video_thumbnail___localFile___ctimeMs = 'relationships___field_video_thumbnail___localFile___ctimeMs',
  relationships___field_video_thumbnail___localFile___atime = 'relationships___field_video_thumbnail___localFile___atime',
  relationships___field_video_thumbnail___localFile___mtime = 'relationships___field_video_thumbnail___localFile___mtime',
  relationships___field_video_thumbnail___localFile___ctime = 'relationships___field_video_thumbnail___localFile___ctime',
  relationships___field_video_thumbnail___localFile___url = 'relationships___field_video_thumbnail___localFile___url',
  relationships___field_video_thumbnail___localFile___id = 'relationships___field_video_thumbnail___localFile___id',
  relationships___field_video_thumbnail___localFile___children = 'relationships___field_video_thumbnail___localFile___children',
  relationships___field_video_thumbnail___uri___url = 'relationships___field_video_thumbnail___uri___url',
  relationships___field_video_thumbnail___drupal_id = 'relationships___field_video_thumbnail___drupal_id',
  relationships___field_video_thumbnail___drupal_internal__fid = 'relationships___field_video_thumbnail___drupal_internal__fid',
  relationships___field_video_thumbnail___langcode = 'relationships___field_video_thumbnail___langcode',
  relationships___field_video_thumbnail___filename = 'relationships___field_video_thumbnail___filename',
  relationships___field_video_thumbnail___filemime = 'relationships___field_video_thumbnail___filemime',
  relationships___field_video_thumbnail___filesize = 'relationships___field_video_thumbnail___filesize',
  relationships___field_video_thumbnail___status = 'relationships___field_video_thumbnail___status',
  relationships___field_video_thumbnail___created = 'relationships___field_video_thumbnail___created',
  relationships___field_video_thumbnail___changed = 'relationships___field_video_thumbnail___changed',
  relationships___field_video_thumbnail___uuid = 'relationships___field_video_thumbnail___uuid',
  relationships___field_video_thumbnail___relationships___node__blog_post = 'relationships___field_video_thumbnail___relationships___node__blog_post',
  relationships___field_video_thumbnail___relationships___node__devices = 'relationships___field_video_thumbnail___relationships___node__devices',
  relationships___field_video_thumbnail___relationships___node__accessories = 'relationships___field_video_thumbnail___relationships___node__accessories',
  relationships___field_video_thumbnail___relationships___paragraph__news_image_title_text = 'relationships___field_video_thumbnail___relationships___paragraph__news_image_title_text',
  relationships___field_video_thumbnail___relationships___node__cases = 'relationships___field_video_thumbnail___relationships___node__cases',
  relationships___field_video_thumbnail___relationships___paragraph__branches_what_is_ipos = 'relationships___field_video_thumbnail___relationships___paragraph__branches_what_is_ipos',
  relationships___field_video_thumbnail___relationships___paragraph__branches_features = 'relationships___field_video_thumbnail___relationships___paragraph__branches_features',
  relationships___field_video_thumbnail___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_video_thumbnail___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_video_thumbnail___relationships___paragraph__branches_testimonials = 'relationships___field_video_thumbnail___relationships___paragraph__branches_testimonials',
  relationships___field_video_thumbnail___relationships___paragraph__branches_discounts = 'relationships___field_video_thumbnail___relationships___paragraph__branches_discounts',
  relationships___field_video_thumbnail___relationships___node__news = 'relationships___field_video_thumbnail___relationships___node__news',
  relationships___field_video_thumbnail___relationships___node__branches = 'relationships___field_video_thumbnail___relationships___node__branches',
  relationships___field_video_thumbnail___relationships___paragraph__branches_hero = 'relationships___field_video_thumbnail___relationships___paragraph__branches_hero',
  relationships___field_video_thumbnail___relationships___paragraph__bullets = 'relationships___field_video_thumbnail___relationships___paragraph__bullets',
  relationships___field_video_thumbnail___relationships___taxonomy_term__pricelist_group = 'relationships___field_video_thumbnail___relationships___taxonomy_term__pricelist_group',
  relationships___field_video_thumbnail___relationships___paragraph__branches_device = 'relationships___field_video_thumbnail___relationships___paragraph__branches_device',
  relationships___field_video_thumbnail___relationships___paragraph__branches_recommendation = 'relationships___field_video_thumbnail___relationships___paragraph__branches_recommendation',
  relationships___field_video_thumbnail___relationships___site_setting_entity__accessories = 'relationships___field_video_thumbnail___relationships___site_setting_entity__accessories',
  relationships___field_video_thumbnail___relationships___site_setting_entity__branches = 'relationships___field_video_thumbnail___relationships___site_setting_entity__branches',
  relationships___field_video_thumbnail___relationships___site_setting_entity__about_us = 'relationships___field_video_thumbnail___relationships___site_setting_entity__about_us',
  relationships___field_video_thumbnail___relationships___paragraph__logos = 'relationships___field_video_thumbnail___relationships___paragraph__logos',
  relationships___field_video_thumbnail___relationships___paragraph__how_it_works = 'relationships___field_video_thumbnail___relationships___paragraph__how_it_works',
  relationships___field_video_thumbnail___relationships___paragraph__ico_and_text = 'relationships___field_video_thumbnail___relationships___paragraph__ico_and_text',
  relationships___field_video_thumbnail___relationships___paragraph__hero_1 = 'relationships___field_video_thumbnail___relationships___paragraph__hero_1',
  relationships___field_video_thumbnail___relationships___paragraph__comprehensive_solution_item = 'relationships___field_video_thumbnail___relationships___paragraph__comprehensive_solution_item',
  relationships___field_video_thumbnail___relationships___site_setting_entity__product = 'relationships___field_video_thumbnail___relationships___site_setting_entity__product',
  relationships___field_video_thumbnail___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_video_thumbnail___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_video_thumbnail___relationships___paragraph__porownanie_terminali = 'relationships___field_video_thumbnail___relationships___paragraph__porownanie_terminali',
  relationships___field_video_thumbnail___relationships___paragraph__zdjecie_opis_link = 'relationships___field_video_thumbnail___relationships___paragraph__zdjecie_opis_link',
  relationships___field_video_thumbnail___relationships___site_setting_entity__main_page = 'relationships___field_video_thumbnail___relationships___site_setting_entity__main_page',
  relationships___field_video_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_video_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_video_thumbnail___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_video_thumbnail___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_video_thumbnail___relationships___site_setting_entity__intercars = 'relationships___field_video_thumbnail___relationships___site_setting_entity__intercars',
  relationships___field_video_thumbnail___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_video_thumbnail___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_video_thumbnail___relationships___node__agreement = 'relationships___field_video_thumbnail___relationships___node__agreement',
  relationships___field_video_thumbnail___relationships___node__fiscalization = 'relationships___field_video_thumbnail___relationships___node__fiscalization',
  relationships___field_video_thumbnail___relationships___site_setting_entity__configurator = 'relationships___field_video_thumbnail___relationships___site_setting_entity__configurator',
  relationships___field_video_thumbnail___relationships___paragraph__social_link = 'relationships___field_video_thumbnail___relationships___paragraph__social_link',
  relationships___field_video_thumbnail___relationships___paragraph__make_an_appointment = 'relationships___field_video_thumbnail___relationships___paragraph__make_an_appointment',
  relationships___field_video_thumbnail___relationships___paragraph__linked_icons = 'relationships___field_video_thumbnail___relationships___paragraph__linked_icons',
  relationships___field_video_thumbnail___relationships___site_setting_entity__financing = 'relationships___field_video_thumbnail___relationships___site_setting_entity__financing',
  relationships___field_video_thumbnail___relationships___site_setting_entity__franchise = 'relationships___field_video_thumbnail___relationships___site_setting_entity__franchise',
  relationships___field_video_thumbnail___id = 'relationships___field_video_thumbnail___id',
  relationships___field_video_thumbnail___parent___id = 'relationships___field_video_thumbnail___parent___id',
  relationships___field_video_thumbnail___parent___children = 'relationships___field_video_thumbnail___parent___children',
  relationships___field_video_thumbnail___children = 'relationships___field_video_thumbnail___children',
  relationships___field_video_thumbnail___children___id = 'relationships___field_video_thumbnail___children___id',
  relationships___field_video_thumbnail___children___children = 'relationships___field_video_thumbnail___children___children',
  relationships___field_video_thumbnail___internal___content = 'relationships___field_video_thumbnail___internal___content',
  relationships___field_video_thumbnail___internal___contentDigest = 'relationships___field_video_thumbnail___internal___contentDigest',
  relationships___field_video_thumbnail___internal___description = 'relationships___field_video_thumbnail___internal___description',
  relationships___field_video_thumbnail___internal___fieldOwners = 'relationships___field_video_thumbnail___internal___fieldOwners',
  relationships___field_video_thumbnail___internal___ignoreType = 'relationships___field_video_thumbnail___internal___ignoreType',
  relationships___field_video_thumbnail___internal___mediaType = 'relationships___field_video_thumbnail___internal___mediaType',
  relationships___field_video_thumbnail___internal___owner = 'relationships___field_video_thumbnail___internal___owner',
  relationships___field_video_thumbnail___internal___type = 'relationships___field_video_thumbnail___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  relationships___site_setting_entity__price_list = 'relationships___site_setting_entity__price_list',
  relationships___site_setting_entity__price_list___langcode = 'relationships___site_setting_entity__price_list___langcode',
  relationships___site_setting_entity__price_list___field_pl_header_1___value = 'relationships___site_setting_entity__price_list___field_pl_header_1___value',
  relationships___site_setting_entity__price_list___field_pl_header_2___value = 'relationships___site_setting_entity__price_list___field_pl_header_2___value',
  relationships___site_setting_entity__price_list___field_pl_header_3___value = 'relationships___site_setting_entity__price_list___field_pl_header_3___value',
  relationships___site_setting_entity__price_list___field_pl_header_4___value = 'relationships___site_setting_entity__price_list___field_pl_header_4___value',
  relationships___site_setting_entity__price_list___field_footer_left___value = 'relationships___site_setting_entity__price_list___field_footer_left___value',
  relationships___site_setting_entity__price_list___field_footer_right___value = 'relationships___site_setting_entity__price_list___field_footer_right___value',
  relationships___site_setting_entity__price_list___field_meta_description = 'relationships___site_setting_entity__price_list___field_meta_description',
  relationships___site_setting_entity__price_list___field_meta_title = 'relationships___site_setting_entity__price_list___field_meta_title',
  relationships___site_setting_entity__price_list___field_texts = 'relationships___site_setting_entity__price_list___field_texts',
  relationships___site_setting_entity__price_list___relationships___field_components = 'relationships___site_setting_entity__price_list___relationships___field_components',
  relationships___site_setting_entity__price_list___drupal_id = 'relationships___site_setting_entity__price_list___drupal_id',
  relationships___site_setting_entity__price_list___drupal_internal__id = 'relationships___site_setting_entity__price_list___drupal_internal__id',
  relationships___site_setting_entity__price_list___name = 'relationships___site_setting_entity__price_list___name',
  relationships___site_setting_entity__price_list___fieldset = 'relationships___site_setting_entity__price_list___fieldset',
  relationships___site_setting_entity__price_list___status = 'relationships___site_setting_entity__price_list___status',
  relationships___site_setting_entity__price_list___created = 'relationships___site_setting_entity__price_list___created',
  relationships___site_setting_entity__price_list___changed = 'relationships___site_setting_entity__price_list___changed',
  relationships___site_setting_entity__price_list___default_langcode = 'relationships___site_setting_entity__price_list___default_langcode',
  relationships___site_setting_entity__price_list___content_translation_source = 'relationships___site_setting_entity__price_list___content_translation_source',
  relationships___site_setting_entity__price_list___content_translation_outdated = 'relationships___site_setting_entity__price_list___content_translation_outdated',
  relationships___site_setting_entity__price_list___content_translation_status = 'relationships___site_setting_entity__price_list___content_translation_status',
  relationships___site_setting_entity__price_list___content_translation_created = 'relationships___site_setting_entity__price_list___content_translation_created',
  relationships___site_setting_entity__price_list___content_translation_changed = 'relationships___site_setting_entity__price_list___content_translation_changed',
  relationships___site_setting_entity__price_list___uuid = 'relationships___site_setting_entity__price_list___uuid',
  relationships___site_setting_entity__price_list___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__price_list___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__price_list___field_components = 'relationships___site_setting_entity__price_list___field_components',
  relationships___site_setting_entity__price_list___field_components___target_revision_id = 'relationships___site_setting_entity__price_list___field_components___target_revision_id',
  relationships___site_setting_entity__price_list___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__price_list___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__price_list___id = 'relationships___site_setting_entity__price_list___id',
  relationships___site_setting_entity__price_list___parent___id = 'relationships___site_setting_entity__price_list___parent___id',
  relationships___site_setting_entity__price_list___parent___children = 'relationships___site_setting_entity__price_list___parent___children',
  relationships___site_setting_entity__price_list___children = 'relationships___site_setting_entity__price_list___children',
  relationships___site_setting_entity__price_list___children___id = 'relationships___site_setting_entity__price_list___children___id',
  relationships___site_setting_entity__price_list___children___children = 'relationships___site_setting_entity__price_list___children___children',
  relationships___site_setting_entity__price_list___internal___content = 'relationships___site_setting_entity__price_list___internal___content',
  relationships___site_setting_entity__price_list___internal___contentDigest = 'relationships___site_setting_entity__price_list___internal___contentDigest',
  relationships___site_setting_entity__price_list___internal___description = 'relationships___site_setting_entity__price_list___internal___description',
  relationships___site_setting_entity__price_list___internal___fieldOwners = 'relationships___site_setting_entity__price_list___internal___fieldOwners',
  relationships___site_setting_entity__price_list___internal___ignoreType = 'relationships___site_setting_entity__price_list___internal___ignoreType',
  relationships___site_setting_entity__price_list___internal___mediaType = 'relationships___site_setting_entity__price_list___internal___mediaType',
  relationships___site_setting_entity__price_list___internal___owner = 'relationships___site_setting_entity__price_list___internal___owner',
  relationships___site_setting_entity__price_list___internal___type = 'relationships___site_setting_entity__price_list___internal___type',
  relationships___site_setting_entity__financing = 'relationships___site_setting_entity__financing',
  relationships___site_setting_entity__financing___langcode = 'relationships___site_setting_entity__financing___langcode',
  relationships___site_setting_entity__financing___field_meta_description = 'relationships___site_setting_entity__financing___field_meta_description',
  relationships___site_setting_entity__financing___field_meta_title = 'relationships___site_setting_entity__financing___field_meta_title',
  relationships___site_setting_entity__financing___drupal_id = 'relationships___site_setting_entity__financing___drupal_id',
  relationships___site_setting_entity__financing___drupal_internal__id = 'relationships___site_setting_entity__financing___drupal_internal__id',
  relationships___site_setting_entity__financing___name = 'relationships___site_setting_entity__financing___name',
  relationships___site_setting_entity__financing___fieldset = 'relationships___site_setting_entity__financing___fieldset',
  relationships___site_setting_entity__financing___status = 'relationships___site_setting_entity__financing___status',
  relationships___site_setting_entity__financing___created = 'relationships___site_setting_entity__financing___created',
  relationships___site_setting_entity__financing___changed = 'relationships___site_setting_entity__financing___changed',
  relationships___site_setting_entity__financing___default_langcode = 'relationships___site_setting_entity__financing___default_langcode',
  relationships___site_setting_entity__financing___content_translation_source = 'relationships___site_setting_entity__financing___content_translation_source',
  relationships___site_setting_entity__financing___content_translation_outdated = 'relationships___site_setting_entity__financing___content_translation_outdated',
  relationships___site_setting_entity__financing___content_translation_status = 'relationships___site_setting_entity__financing___content_translation_status',
  relationships___site_setting_entity__financing___content_translation_created = 'relationships___site_setting_entity__financing___content_translation_created',
  relationships___site_setting_entity__financing___content_translation_changed = 'relationships___site_setting_entity__financing___content_translation_changed',
  relationships___site_setting_entity__financing___uuid = 'relationships___site_setting_entity__financing___uuid',
  relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_components = 'relationships___site_setting_entity__financing___field_components',
  relationships___site_setting_entity__financing___field_components___target_revision_id = 'relationships___site_setting_entity__financing___field_components___target_revision_id',
  relationships___site_setting_entity__financing___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_main_image___alt = 'relationships___site_setting_entity__financing___field_main_image___alt',
  relationships___site_setting_entity__financing___field_main_image___title = 'relationships___site_setting_entity__financing___field_main_image___title',
  relationships___site_setting_entity__financing___field_main_image___width = 'relationships___site_setting_entity__financing___field_main_image___width',
  relationships___site_setting_entity__financing___field_main_image___height = 'relationships___site_setting_entity__financing___field_main_image___height',
  relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__financing___id = 'relationships___site_setting_entity__financing___id',
  relationships___site_setting_entity__financing___parent___id = 'relationships___site_setting_entity__financing___parent___id',
  relationships___site_setting_entity__financing___parent___children = 'relationships___site_setting_entity__financing___parent___children',
  relationships___site_setting_entity__financing___children = 'relationships___site_setting_entity__financing___children',
  relationships___site_setting_entity__financing___children___id = 'relationships___site_setting_entity__financing___children___id',
  relationships___site_setting_entity__financing___children___children = 'relationships___site_setting_entity__financing___children___children',
  relationships___site_setting_entity__financing___internal___content = 'relationships___site_setting_entity__financing___internal___content',
  relationships___site_setting_entity__financing___internal___contentDigest = 'relationships___site_setting_entity__financing___internal___contentDigest',
  relationships___site_setting_entity__financing___internal___description = 'relationships___site_setting_entity__financing___internal___description',
  relationships___site_setting_entity__financing___internal___fieldOwners = 'relationships___site_setting_entity__financing___internal___fieldOwners',
  relationships___site_setting_entity__financing___internal___ignoreType = 'relationships___site_setting_entity__financing___internal___ignoreType',
  relationships___site_setting_entity__financing___internal___mediaType = 'relationships___site_setting_entity__financing___internal___mediaType',
  relationships___site_setting_entity__financing___internal___owner = 'relationships___site_setting_entity__financing___internal___owner',
  relationships___site_setting_entity__financing___internal___type = 'relationships___site_setting_entity__financing___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  relationships___node__pages = 'relationships___node__pages',
  relationships___node__pages___langcode = 'relationships___node__pages___langcode',
  relationships___node__pages___id = 'relationships___node__pages___id',
  relationships___node__pages___title = 'relationships___node__pages___title',
  relationships___node__pages___field_pages_text___value = 'relationships___node__pages___field_pages_text___value',
  relationships___node__pages___path___alias = 'relationships___node__pages___path___alias',
  relationships___node__pages___field_meta_title = 'relationships___node__pages___field_meta_title',
  relationships___node__pages___drupal_id = 'relationships___node__pages___drupal_id',
  relationships___node__pages___drupal_internal__nid = 'relationships___node__pages___drupal_internal__nid',
  relationships___node__pages___drupal_internal__vid = 'relationships___node__pages___drupal_internal__vid',
  relationships___node__pages___rh_action = 'relationships___node__pages___rh_action',
  relationships___node__pages___rh_redirect_response = 'relationships___node__pages___rh_redirect_response',
  relationships___node__pages___rh_redirect_fallback_action = 'relationships___node__pages___rh_redirect_fallback_action',
  relationships___node__pages___content_translation_source = 'relationships___node__pages___content_translation_source',
  relationships___node__pages___content_translation_outdated = 'relationships___node__pages___content_translation_outdated',
  relationships___node__pages___field_meta_description = 'relationships___node__pages___field_meta_description',
  relationships___node__pages___field_meta_keywords = 'relationships___node__pages___field_meta_keywords',
  relationships___node__pages___uuid = 'relationships___node__pages___uuid',
  relationships___node__pages___field_components = 'relationships___node__pages___field_components',
  relationships___node__pages___field_components___target_revision_id = 'relationships___node__pages___field_components___target_revision_id',
  relationships___node__pages___field_components___drupal_internal__target_id = 'relationships___node__pages___field_components___drupal_internal__target_id',
  relationships___node__pages___parent___id = 'relationships___node__pages___parent___id',
  relationships___node__pages___parent___children = 'relationships___node__pages___parent___children',
  relationships___node__pages___children = 'relationships___node__pages___children',
  relationships___node__pages___children___id = 'relationships___node__pages___children___id',
  relationships___node__pages___children___children = 'relationships___node__pages___children___children',
  relationships___node__pages___internal___content = 'relationships___node__pages___internal___content',
  relationships___node__pages___internal___contentDigest = 'relationships___node__pages___internal___contentDigest',
  relationships___node__pages___internal___description = 'relationships___node__pages___internal___description',
  relationships___node__pages___internal___fieldOwners = 'relationships___node__pages___internal___fieldOwners',
  relationships___node__pages___internal___ignoreType = 'relationships___node__pages___internal___ignoreType',
  relationships___node__pages___internal___mediaType = 'relationships___node__pages___internal___mediaType',
  relationships___node__pages___internal___owner = 'relationships___node__pages___internal___owner',
  relationships___node__pages___internal___type = 'relationships___node__pages___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_branches_device_image___alt = 'field_branches_device_image___alt',
  field_branches_device_image___title = 'field_branches_device_image___title',
  field_branches_device_image___width = 'field_branches_device_image___width',
  field_branches_device_image___height = 'field_branches_device_image___height',
  field_branches_device_image___drupal_internal__target_id = 'field_branches_device_image___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_HeroFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  field_hero_1_video?: Maybe<StringQueryOperatorInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_branches_button_text?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Field_Branches_ImageFilterInput>;
  field_branches_image_mobile?: Maybe<Field_Branches_Image_MobileFilterInput>;
  field_video_thumbnail?: Maybe<Field_Video_ThumbnailFilterInput>;
  relationships?: Maybe<Paragraph__Branches_HeroRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_device_image?: Maybe<
    Paragraph__Branches_HeroField_Branches_Device_ImageFilterInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_HeroFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_HeroFilterInput>;
};

export type Paragraph__Branches_HeroGroupConnection = {
  __typename?: 'paragraph__branches_heroGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_HeroEdge>;
  nodes: Array<Paragraph__Branches_Hero>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_HeroRelationships = {
  __typename?: 'paragraph__branches_heroRelationships';
  field_branches_image?: Maybe<File__File>;
  field_branches_device_image?: Maybe<File__File>;
  field_branches_image_mobile?: Maybe<File__File>;
  field_video_thumbnail?: Maybe<File__File>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
  site_setting_entity__price_list?: Maybe<Array<Maybe<Site_Setting_Entity__Price_List>>>;
  site_setting_entity__financing?: Maybe<Array<Maybe<Site_Setting_Entity__Financing>>>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
  node__pages?: Maybe<Array<Maybe<Node__Pages>>>;
};

export type Paragraph__Branches_HeroRelationshipsFilterInput = {
  field_branches_image?: Maybe<File__FileFilterInput>;
  field_branches_device_image?: Maybe<File__FileFilterInput>;
  field_branches_image_mobile?: Maybe<File__FileFilterInput>;
  field_video_thumbnail?: Maybe<File__FileFilterInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
  site_setting_entity__price_list?: Maybe<Site_Setting_Entity__Price_ListFilterListInput>;
  site_setting_entity__financing?: Maybe<Site_Setting_Entity__FinancingFilterListInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
  node__pages?: Maybe<Node__PagesFilterListInput>;
};

export type Paragraph__Branches_HeroSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_HeroFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Recommendation = Node & {
  __typename?: 'paragraph__branches_recommendation';
  id: Scalars['ID'];
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  field_branches_image?: Maybe<Field_Branches_Image>;
  field_branches_text?: Maybe<Field_Branches_Text>;
  field_is_online?: Maybe<Scalars['Boolean']>;
  field_is_online_text?: Maybe<Scalars['String']>;
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_url?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Branches_RecommendationRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_RecommendationCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_RecommendationContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_RecommendationConnection = {
  __typename?: 'paragraph__branches_recommendationConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_RecommendationEdge>;
  nodes: Array<Paragraph__Branches_Recommendation>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_RecommendationGroupConnection>;
};

export type Paragraph__Branches_RecommendationConnectionDistinctArgs = {
  field: Paragraph__Branches_RecommendationFieldsEnum;
};

export type Paragraph__Branches_RecommendationConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_RecommendationFieldsEnum;
};

export type Paragraph__Branches_RecommendationEdge = {
  __typename?: 'paragraph__branches_recommendationEdge';
  next?: Maybe<Paragraph__Branches_Recommendation>;
  node: Paragraph__Branches_Recommendation;
  previous?: Maybe<Paragraph__Branches_Recommendation>;
};

export enum Paragraph__Branches_RecommendationFieldsEnum {
  id = 'id',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  field_branches_image___alt = 'field_branches_image___alt',
  field_branches_text___value = 'field_branches_text___value',
  field_is_online = 'field_is_online',
  field_is_online_text = 'field_is_online_text',
  field_nc_button_text = 'field_nc_button_text',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_url = 'field_url',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  relationships___site_setting_entity__main_page = 'relationships___site_setting_entity__main_page',
  relationships___site_setting_entity__main_page___langcode = 'relationships___site_setting_entity__main_page___langcode',
  relationships___site_setting_entity__main_page___field_mp_text_footer___value = 'relationships___site_setting_entity__main_page___field_mp_text_footer___value',
  relationships___site_setting_entity__main_page___field_mp_text___value = 'relationships___site_setting_entity__main_page___field_mp_text___value',
  relationships___site_setting_entity__main_page___field_mp_button_text = 'relationships___site_setting_entity__main_page___field_mp_button_text',
  relationships___site_setting_entity__main_page___field_meta_description = 'relationships___site_setting_entity__main_page___field_meta_description',
  relationships___site_setting_entity__main_page___field_meta_title = 'relationships___site_setting_entity__main_page___field_meta_title',
  relationships___site_setting_entity__main_page___field_btn_url = 'relationships___site_setting_entity__main_page___field_btn_url',
  relationships___site_setting_entity__main_page___field_settings_button_text = 'relationships___site_setting_entity__main_page___field_settings_button_text',
  relationships___site_setting_entity__main_page___field_mp_image_footer___alt = 'relationships___site_setting_entity__main_page___field_mp_image_footer___alt',
  relationships___site_setting_entity__main_page___field_mp_image___alt = 'relationships___site_setting_entity__main_page___field_mp_image___alt',
  relationships___site_setting_entity__main_page___drupal_id = 'relationships___site_setting_entity__main_page___drupal_id',
  relationships___site_setting_entity__main_page___drupal_internal__id = 'relationships___site_setting_entity__main_page___drupal_internal__id',
  relationships___site_setting_entity__main_page___name = 'relationships___site_setting_entity__main_page___name',
  relationships___site_setting_entity__main_page___fieldset = 'relationships___site_setting_entity__main_page___fieldset',
  relationships___site_setting_entity__main_page___status = 'relationships___site_setting_entity__main_page___status',
  relationships___site_setting_entity__main_page___created = 'relationships___site_setting_entity__main_page___created',
  relationships___site_setting_entity__main_page___changed = 'relationships___site_setting_entity__main_page___changed',
  relationships___site_setting_entity__main_page___default_langcode = 'relationships___site_setting_entity__main_page___default_langcode',
  relationships___site_setting_entity__main_page___content_translation_source = 'relationships___site_setting_entity__main_page___content_translation_source',
  relationships___site_setting_entity__main_page___content_translation_outdated = 'relationships___site_setting_entity__main_page___content_translation_outdated',
  relationships___site_setting_entity__main_page___content_translation_status = 'relationships___site_setting_entity__main_page___content_translation_status',
  relationships___site_setting_entity__main_page___content_translation_created = 'relationships___site_setting_entity__main_page___content_translation_created',
  relationships___site_setting_entity__main_page___content_translation_changed = 'relationships___site_setting_entity__main_page___content_translation_changed',
  relationships___site_setting_entity__main_page___uuid = 'relationships___site_setting_entity__main_page___uuid',
  relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___field_components = 'relationships___site_setting_entity__main_page___field_components',
  relationships___site_setting_entity__main_page___field_components___target_revision_id = 'relationships___site_setting_entity__main_page___field_components___target_revision_id',
  relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___id = 'relationships___site_setting_entity__main_page___id',
  relationships___site_setting_entity__main_page___parent___id = 'relationships___site_setting_entity__main_page___parent___id',
  relationships___site_setting_entity__main_page___parent___children = 'relationships___site_setting_entity__main_page___parent___children',
  relationships___site_setting_entity__main_page___children = 'relationships___site_setting_entity__main_page___children',
  relationships___site_setting_entity__main_page___children___id = 'relationships___site_setting_entity__main_page___children___id',
  relationships___site_setting_entity__main_page___children___children = 'relationships___site_setting_entity__main_page___children___children',
  relationships___site_setting_entity__main_page___internal___content = 'relationships___site_setting_entity__main_page___internal___content',
  relationships___site_setting_entity__main_page___internal___contentDigest = 'relationships___site_setting_entity__main_page___internal___contentDigest',
  relationships___site_setting_entity__main_page___internal___description = 'relationships___site_setting_entity__main_page___internal___description',
  relationships___site_setting_entity__main_page___internal___fieldOwners = 'relationships___site_setting_entity__main_page___internal___fieldOwners',
  relationships___site_setting_entity__main_page___internal___ignoreType = 'relationships___site_setting_entity__main_page___internal___ignoreType',
  relationships___site_setting_entity__main_page___internal___mediaType = 'relationships___site_setting_entity__main_page___internal___mediaType',
  relationships___site_setting_entity__main_page___internal___owner = 'relationships___site_setting_entity__main_page___internal___owner',
  relationships___site_setting_entity__main_page___internal___type = 'relationships___site_setting_entity__main_page___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_RecommendationFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Field_Branches_ImageFilterInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_is_online?: Maybe<BooleanQueryOperatorInput>;
  field_is_online_text?: Maybe<StringQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_url?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_RecommendationRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_RecommendationFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_RecommendationFilterInput>;
};

export type Paragraph__Branches_RecommendationGroupConnection = {
  __typename?: 'paragraph__branches_recommendationGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_RecommendationEdge>;
  nodes: Array<Paragraph__Branches_Recommendation>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_RecommendationRelationships = {
  __typename?: 'paragraph__branches_recommendationRelationships';
  field_branches_image?: Maybe<File__File>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
  site_setting_entity__main_page?: Maybe<Array<Maybe<Site_Setting_Entity__Main_Page>>>;
};

export type Paragraph__Branches_RecommendationRelationshipsFilterInput = {
  field_branches_image?: Maybe<File__FileFilterInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
  site_setting_entity__main_page?: Maybe<Site_Setting_Entity__Main_PageFilterListInput>;
};

export type Paragraph__Branches_RecommendationSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_RecommendationFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Recommended_Devices = Node & {
  __typename?: 'paragraph__branches_recommended_devices';
  internal: Internal;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  relationships?: Maybe<Paragraph__Branches_Recommended_DevicesRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_devices?: Maybe<
    Array<Maybe<Paragraph__Branches_Recommended_DevicesField_Branches_Devices>>
  >;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_Recommended_DevicesCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Recommended_DevicesConnection = {
  __typename?: 'paragraph__branches_recommended_devicesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_Recommended_DevicesEdge>;
  nodes: Array<Paragraph__Branches_Recommended_Devices>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_Recommended_DevicesGroupConnection>;
};

export type Paragraph__Branches_Recommended_DevicesConnectionDistinctArgs = {
  field: Paragraph__Branches_Recommended_DevicesFieldsEnum;
};

export type Paragraph__Branches_Recommended_DevicesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_Recommended_DevicesFieldsEnum;
};

export type Paragraph__Branches_Recommended_DevicesEdge = {
  __typename?: 'paragraph__branches_recommended_devicesEdge';
  next?: Maybe<Paragraph__Branches_Recommended_Devices>;
  node: Paragraph__Branches_Recommended_Devices;
  previous?: Maybe<Paragraph__Branches_Recommended_Devices>;
};

export type Paragraph__Branches_Recommended_DevicesField_Branches_Devices = {
  __typename?: 'paragraph__branches_recommended_devicesField_branches_devices';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_Recommended_DevicesField_Branches_DevicesFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_Recommended_DevicesField_Branches_DevicesFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_Recommended_DevicesField_Branches_DevicesFilterInput>;
};

export enum Paragraph__Branches_Recommended_DevicesFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  drupal_id = 'drupal_id',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  relationships___field_branches_devices = 'relationships___field_branches_devices',
  relationships___field_branches_devices___field_branches_name = 'relationships___field_branches_devices___field_branches_name',
  relationships___field_branches_devices___field_recommended = 'relationships___field_branches_devices___field_recommended',
  relationships___field_branches_devices___field_button_text_recommended = 'relationships___field_branches_devices___field_button_text_recommended',
  relationships___field_branches_devices___relationships___field_branches_profits = 'relationships___field_branches_devices___relationships___field_branches_profits',
  relationships___field_branches_devices___relationships___paragraph__branches_recommended_devices = 'relationships___field_branches_devices___relationships___paragraph__branches_recommended_devices',
  relationships___field_branches_devices___drupal_id = 'relationships___field_branches_devices___drupal_id',
  relationships___field_branches_devices___drupal_internal__id = 'relationships___field_branches_devices___drupal_internal__id',
  relationships___field_branches_devices___status = 'relationships___field_branches_devices___status',
  relationships___field_branches_devices___created = 'relationships___field_branches_devices___created',
  relationships___field_branches_devices___parent_id = 'relationships___field_branches_devices___parent_id',
  relationships___field_branches_devices___parent_type = 'relationships___field_branches_devices___parent_type',
  relationships___field_branches_devices___parent_field_name = 'relationships___field_branches_devices___parent_field_name',
  relationships___field_branches_devices___content_translation_source = 'relationships___field_branches_devices___content_translation_source',
  relationships___field_branches_devices___content_translation_outdated = 'relationships___field_branches_devices___content_translation_outdated',
  relationships___field_branches_devices___field_nc_button_text = 'relationships___field_branches_devices___field_nc_button_text',
  relationships___field_branches_devices___langcode = 'relationships___field_branches_devices___langcode',
  relationships___field_branches_devices___uuid = 'relationships___field_branches_devices___uuid',
  relationships___field_branches_devices___field_branches_image___alt = 'relationships___field_branches_devices___field_branches_image___alt',
  relationships___field_branches_devices___field_branches_image___title = 'relationships___field_branches_devices___field_branches_image___title',
  relationships___field_branches_devices___field_branches_image___width = 'relationships___field_branches_devices___field_branches_image___width',
  relationships___field_branches_devices___field_branches_image___height = 'relationships___field_branches_devices___field_branches_image___height',
  relationships___field_branches_devices___field_branches_image___drupal_internal__target_id = 'relationships___field_branches_devices___field_branches_image___drupal_internal__target_id',
  relationships___field_branches_devices___field_branches_profits = 'relationships___field_branches_devices___field_branches_profits',
  relationships___field_branches_devices___field_branches_profits___target_revision_id = 'relationships___field_branches_devices___field_branches_profits___target_revision_id',
  relationships___field_branches_devices___field_branches_profits___drupal_internal__target_id = 'relationships___field_branches_devices___field_branches_profits___drupal_internal__target_id',
  relationships___field_branches_devices___id = 'relationships___field_branches_devices___id',
  relationships___field_branches_devices___parent___id = 'relationships___field_branches_devices___parent___id',
  relationships___field_branches_devices___parent___children = 'relationships___field_branches_devices___parent___children',
  relationships___field_branches_devices___children = 'relationships___field_branches_devices___children',
  relationships___field_branches_devices___children___id = 'relationships___field_branches_devices___children___id',
  relationships___field_branches_devices___children___children = 'relationships___field_branches_devices___children___children',
  relationships___field_branches_devices___internal___content = 'relationships___field_branches_devices___internal___content',
  relationships___field_branches_devices___internal___contentDigest = 'relationships___field_branches_devices___internal___contentDigest',
  relationships___field_branches_devices___internal___description = 'relationships___field_branches_devices___internal___description',
  relationships___field_branches_devices___internal___fieldOwners = 'relationships___field_branches_devices___internal___fieldOwners',
  relationships___field_branches_devices___internal___ignoreType = 'relationships___field_branches_devices___internal___ignoreType',
  relationships___field_branches_devices___internal___mediaType = 'relationships___field_branches_devices___internal___mediaType',
  relationships___field_branches_devices___internal___owner = 'relationships___field_branches_devices___internal___owner',
  relationships___field_branches_devices___internal___type = 'relationships___field_branches_devices___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  langcode = 'langcode',
  uuid = 'uuid',
  field_branches_devices = 'field_branches_devices',
  field_branches_devices___target_revision_id = 'field_branches_devices___target_revision_id',
  field_branches_devices___drupal_internal__target_id = 'field_branches_devices___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_Recommended_DevicesFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  relationships?: Maybe<Paragraph__Branches_Recommended_DevicesRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_devices?: Maybe<
    Paragraph__Branches_Recommended_DevicesField_Branches_DevicesFilterListInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_Recommended_DevicesFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_Recommended_DevicesFilterInput>;
};

export type Paragraph__Branches_Recommended_DevicesGroupConnection = {
  __typename?: 'paragraph__branches_recommended_devicesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_Recommended_DevicesEdge>;
  nodes: Array<Paragraph__Branches_Recommended_Devices>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Recommended_DevicesRelationships = {
  __typename?: 'paragraph__branches_recommended_devicesRelationships';
  field_branches_devices?: Maybe<Array<Maybe<Paragraph__Branches_Device>>>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
};

export type Paragraph__Branches_Recommended_DevicesRelationshipsFilterInput = {
  field_branches_devices?: Maybe<Paragraph__Branches_DeviceFilterListInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
};

export type Paragraph__Branches_Recommended_DevicesSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_Recommended_DevicesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Recommended_Tariffs = Node & {
  __typename?: 'paragraph__branches_recommended_tariffs';
  internal: Internal;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_branches_text?: Maybe<Field_Branches_Text>;
  field_nc_button_text?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Branches_Recommended_TariffsRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_tarrifs?: Maybe<
    Array<Maybe<Paragraph__Branches_Recommended_TariffsField_Branches_Tarrifs>>
  >;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_Recommended_TariffsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Recommended_TariffsConnection = {
  __typename?: 'paragraph__branches_recommended_tariffsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_Recommended_TariffsEdge>;
  nodes: Array<Paragraph__Branches_Recommended_Tariffs>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_Recommended_TariffsGroupConnection>;
};

export type Paragraph__Branches_Recommended_TariffsConnectionDistinctArgs = {
  field: Paragraph__Branches_Recommended_TariffsFieldsEnum;
};

export type Paragraph__Branches_Recommended_TariffsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_Recommended_TariffsFieldsEnum;
};

export type Paragraph__Branches_Recommended_TariffsEdge = {
  __typename?: 'paragraph__branches_recommended_tariffsEdge';
  next?: Maybe<Paragraph__Branches_Recommended_Tariffs>;
  node: Paragraph__Branches_Recommended_Tariffs;
  previous?: Maybe<Paragraph__Branches_Recommended_Tariffs>;
};

export type Paragraph__Branches_Recommended_TariffsField_Branches_Tarrifs = {
  __typename?: 'paragraph__branches_recommended_tariffsField_branches_tarrifs';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_Recommended_TariffsField_Branches_TarrifsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_Recommended_TariffsField_Branches_TarrifsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_Recommended_TariffsField_Branches_TarrifsFilterInput>;
};

export enum Paragraph__Branches_Recommended_TariffsFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  drupal_id = 'drupal_id',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_branches_text___value = 'field_branches_text___value',
  field_nc_button_text = 'field_nc_button_text',
  relationships___field_branches_tarrifs = 'relationships___field_branches_tarrifs',
  relationships___field_branches_tarrifs___field_branches_name = 'relationships___field_branches_tarrifs___field_branches_name',
  relationships___field_branches_tarrifs___field_recommended = 'relationships___field_branches_tarrifs___field_recommended',
  relationships___field_branches_tarrifs___relationships___field_branches_profits = 'relationships___field_branches_tarrifs___relationships___field_branches_profits',
  relationships___field_branches_tarrifs___relationships___paragraph__branches_recommended_tariffs = 'relationships___field_branches_tarrifs___relationships___paragraph__branches_recommended_tariffs',
  relationships___field_branches_tarrifs___relationships___site_setting_entity__product = 'relationships___field_branches_tarrifs___relationships___site_setting_entity__product',
  relationships___field_branches_tarrifs___drupal_id = 'relationships___field_branches_tarrifs___drupal_id',
  relationships___field_branches_tarrifs___drupal_internal__id = 'relationships___field_branches_tarrifs___drupal_internal__id',
  relationships___field_branches_tarrifs___status = 'relationships___field_branches_tarrifs___status',
  relationships___field_branches_tarrifs___created = 'relationships___field_branches_tarrifs___created',
  relationships___field_branches_tarrifs___parent_id = 'relationships___field_branches_tarrifs___parent_id',
  relationships___field_branches_tarrifs___parent_type = 'relationships___field_branches_tarrifs___parent_type',
  relationships___field_branches_tarrifs___parent_field_name = 'relationships___field_branches_tarrifs___parent_field_name',
  relationships___field_branches_tarrifs___content_translation_source = 'relationships___field_branches_tarrifs___content_translation_source',
  relationships___field_branches_tarrifs___content_translation_outdated = 'relationships___field_branches_tarrifs___content_translation_outdated',
  relationships___field_branches_tarrifs___content_translation_changed = 'relationships___field_branches_tarrifs___content_translation_changed',
  relationships___field_branches_tarrifs___langcode = 'relationships___field_branches_tarrifs___langcode',
  relationships___field_branches_tarrifs___uuid = 'relationships___field_branches_tarrifs___uuid',
  relationships___field_branches_tarrifs___field_branches_profits = 'relationships___field_branches_tarrifs___field_branches_profits',
  relationships___field_branches_tarrifs___field_branches_profits___target_revision_id = 'relationships___field_branches_tarrifs___field_branches_profits___target_revision_id',
  relationships___field_branches_tarrifs___field_branches_profits___drupal_internal__target_id = 'relationships___field_branches_tarrifs___field_branches_profits___drupal_internal__target_id',
  relationships___field_branches_tarrifs___field_date_from_api___drupal_internal__target_id = 'relationships___field_branches_tarrifs___field_date_from_api___drupal_internal__target_id',
  relationships___field_branches_tarrifs___id = 'relationships___field_branches_tarrifs___id',
  relationships___field_branches_tarrifs___parent___id = 'relationships___field_branches_tarrifs___parent___id',
  relationships___field_branches_tarrifs___parent___children = 'relationships___field_branches_tarrifs___parent___children',
  relationships___field_branches_tarrifs___children = 'relationships___field_branches_tarrifs___children',
  relationships___field_branches_tarrifs___children___id = 'relationships___field_branches_tarrifs___children___id',
  relationships___field_branches_tarrifs___children___children = 'relationships___field_branches_tarrifs___children___children',
  relationships___field_branches_tarrifs___internal___content = 'relationships___field_branches_tarrifs___internal___content',
  relationships___field_branches_tarrifs___internal___contentDigest = 'relationships___field_branches_tarrifs___internal___contentDigest',
  relationships___field_branches_tarrifs___internal___description = 'relationships___field_branches_tarrifs___internal___description',
  relationships___field_branches_tarrifs___internal___fieldOwners = 'relationships___field_branches_tarrifs___internal___fieldOwners',
  relationships___field_branches_tarrifs___internal___ignoreType = 'relationships___field_branches_tarrifs___internal___ignoreType',
  relationships___field_branches_tarrifs___internal___mediaType = 'relationships___field_branches_tarrifs___internal___mediaType',
  relationships___field_branches_tarrifs___internal___owner = 'relationships___field_branches_tarrifs___internal___owner',
  relationships___field_branches_tarrifs___internal___type = 'relationships___field_branches_tarrifs___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  langcode = 'langcode',
  uuid = 'uuid',
  field_branches_tarrifs = 'field_branches_tarrifs',
  field_branches_tarrifs___target_revision_id = 'field_branches_tarrifs___target_revision_id',
  field_branches_tarrifs___drupal_internal__target_id = 'field_branches_tarrifs___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_Recommended_TariffsFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_Recommended_TariffsRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_tarrifs?: Maybe<
    Paragraph__Branches_Recommended_TariffsField_Branches_TarrifsFilterListInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_Recommended_TariffsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_Recommended_TariffsFilterInput>;
};

export type Paragraph__Branches_Recommended_TariffsGroupConnection = {
  __typename?: 'paragraph__branches_recommended_tariffsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_Recommended_TariffsEdge>;
  nodes: Array<Paragraph__Branches_Recommended_Tariffs>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Recommended_TariffsRelationships = {
  __typename?: 'paragraph__branches_recommended_tariffsRelationships';
  field_branches_tarrifs?: Maybe<Array<Maybe<Paragraph__Branches_Tariff>>>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
};

export type Paragraph__Branches_Recommended_TariffsRelationshipsFilterInput = {
  field_branches_tarrifs?: Maybe<Paragraph__Branches_TariffFilterListInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
};

export type Paragraph__Branches_Recommended_TariffsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_Recommended_TariffsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Saving_Up_Block = Node & {
  __typename?: 'paragraph__branches_saving_up_block';
  id: Scalars['ID'];
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  field_branches_button_text?: Maybe<Scalars['String']>;
  field_branches_text?: Maybe<Field_Branches_Text>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Branches_Saving_Up_BlockRelationships>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_Saving_Up_BlockCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Saving_Up_BlockContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Saving_Up_BlockConnection = {
  __typename?: 'paragraph__branches_saving_up_blockConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_Saving_Up_BlockEdge>;
  nodes: Array<Paragraph__Branches_Saving_Up_Block>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_Saving_Up_BlockGroupConnection>;
};

export type Paragraph__Branches_Saving_Up_BlockConnectionDistinctArgs = {
  field: Paragraph__Branches_Saving_Up_BlockFieldsEnum;
};

export type Paragraph__Branches_Saving_Up_BlockConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_Saving_Up_BlockFieldsEnum;
};

export type Paragraph__Branches_Saving_Up_BlockEdge = {
  __typename?: 'paragraph__branches_saving_up_blockEdge';
  next?: Maybe<Paragraph__Branches_Saving_Up_Block>;
  node: Paragraph__Branches_Saving_Up_Block;
  previous?: Maybe<Paragraph__Branches_Saving_Up_Block>;
};

export enum Paragraph__Branches_Saving_Up_BlockFieldsEnum {
  id = 'id',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  field_branches_button_text = 'field_branches_button_text',
  field_branches_text___value = 'field_branches_text___value',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  relationships___site_setting_entity__financing = 'relationships___site_setting_entity__financing',
  relationships___site_setting_entity__financing___langcode = 'relationships___site_setting_entity__financing___langcode',
  relationships___site_setting_entity__financing___field_meta_description = 'relationships___site_setting_entity__financing___field_meta_description',
  relationships___site_setting_entity__financing___field_meta_title = 'relationships___site_setting_entity__financing___field_meta_title',
  relationships___site_setting_entity__financing___drupal_id = 'relationships___site_setting_entity__financing___drupal_id',
  relationships___site_setting_entity__financing___drupal_internal__id = 'relationships___site_setting_entity__financing___drupal_internal__id',
  relationships___site_setting_entity__financing___name = 'relationships___site_setting_entity__financing___name',
  relationships___site_setting_entity__financing___fieldset = 'relationships___site_setting_entity__financing___fieldset',
  relationships___site_setting_entity__financing___status = 'relationships___site_setting_entity__financing___status',
  relationships___site_setting_entity__financing___created = 'relationships___site_setting_entity__financing___created',
  relationships___site_setting_entity__financing___changed = 'relationships___site_setting_entity__financing___changed',
  relationships___site_setting_entity__financing___default_langcode = 'relationships___site_setting_entity__financing___default_langcode',
  relationships___site_setting_entity__financing___content_translation_source = 'relationships___site_setting_entity__financing___content_translation_source',
  relationships___site_setting_entity__financing___content_translation_outdated = 'relationships___site_setting_entity__financing___content_translation_outdated',
  relationships___site_setting_entity__financing___content_translation_status = 'relationships___site_setting_entity__financing___content_translation_status',
  relationships___site_setting_entity__financing___content_translation_created = 'relationships___site_setting_entity__financing___content_translation_created',
  relationships___site_setting_entity__financing___content_translation_changed = 'relationships___site_setting_entity__financing___content_translation_changed',
  relationships___site_setting_entity__financing___uuid = 'relationships___site_setting_entity__financing___uuid',
  relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_components = 'relationships___site_setting_entity__financing___field_components',
  relationships___site_setting_entity__financing___field_components___target_revision_id = 'relationships___site_setting_entity__financing___field_components___target_revision_id',
  relationships___site_setting_entity__financing___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_main_image___alt = 'relationships___site_setting_entity__financing___field_main_image___alt',
  relationships___site_setting_entity__financing___field_main_image___title = 'relationships___site_setting_entity__financing___field_main_image___title',
  relationships___site_setting_entity__financing___field_main_image___width = 'relationships___site_setting_entity__financing___field_main_image___width',
  relationships___site_setting_entity__financing___field_main_image___height = 'relationships___site_setting_entity__financing___field_main_image___height',
  relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__financing___id = 'relationships___site_setting_entity__financing___id',
  relationships___site_setting_entity__financing___parent___id = 'relationships___site_setting_entity__financing___parent___id',
  relationships___site_setting_entity__financing___parent___children = 'relationships___site_setting_entity__financing___parent___children',
  relationships___site_setting_entity__financing___children = 'relationships___site_setting_entity__financing___children',
  relationships___site_setting_entity__financing___children___id = 'relationships___site_setting_entity__financing___children___id',
  relationships___site_setting_entity__financing___children___children = 'relationships___site_setting_entity__financing___children___children',
  relationships___site_setting_entity__financing___internal___content = 'relationships___site_setting_entity__financing___internal___content',
  relationships___site_setting_entity__financing___internal___contentDigest = 'relationships___site_setting_entity__financing___internal___contentDigest',
  relationships___site_setting_entity__financing___internal___description = 'relationships___site_setting_entity__financing___internal___description',
  relationships___site_setting_entity__financing___internal___fieldOwners = 'relationships___site_setting_entity__financing___internal___fieldOwners',
  relationships___site_setting_entity__financing___internal___ignoreType = 'relationships___site_setting_entity__financing___internal___ignoreType',
  relationships___site_setting_entity__financing___internal___mediaType = 'relationships___site_setting_entity__financing___internal___mediaType',
  relationships___site_setting_entity__financing___internal___owner = 'relationships___site_setting_entity__financing___internal___owner',
  relationships___site_setting_entity__financing___internal___type = 'relationships___site_setting_entity__financing___internal___type',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_Saving_Up_BlockFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_branches_button_text?: Maybe<StringQueryOperatorInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_Saving_Up_BlockRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_Saving_Up_BlockGroupConnection = {
  __typename?: 'paragraph__branches_saving_up_blockGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_Saving_Up_BlockEdge>;
  nodes: Array<Paragraph__Branches_Saving_Up_Block>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_Saving_Up_BlockRelationships = {
  __typename?: 'paragraph__branches_saving_up_blockRelationships';
  site_setting_entity__financing?: Maybe<Array<Maybe<Site_Setting_Entity__Financing>>>;
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
};

export type Paragraph__Branches_Saving_Up_BlockRelationshipsFilterInput = {
  site_setting_entity__financing?: Maybe<Site_Setting_Entity__FinancingFilterListInput>;
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
};

export type Paragraph__Branches_Saving_Up_BlockSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_Saving_Up_BlockFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Tariff = Node & {
  __typename?: 'paragraph__branches_tariff';
  field_branches_name?: Maybe<Scalars['String']>;
  field_recommended?: Maybe<Scalars['Boolean']>;
  relationships?: Maybe<Paragraph__Branches_TariffRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_profits?: Maybe<Array<Maybe<Paragraph__Branches_TariffField_Branches_Profits>>>;
  field_date_from_api?: Maybe<Paragraph__Branches_TariffField_Date_From_Api>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Branches_TariffCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_TariffContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_TariffConnection = {
  __typename?: 'paragraph__branches_tariffConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_TariffEdge>;
  nodes: Array<Paragraph__Branches_Tariff>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_TariffGroupConnection>;
};

export type Paragraph__Branches_TariffConnectionDistinctArgs = {
  field: Paragraph__Branches_TariffFieldsEnum;
};

export type Paragraph__Branches_TariffConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_TariffFieldsEnum;
};

export type Paragraph__Branches_TariffEdge = {
  __typename?: 'paragraph__branches_tariffEdge';
  next?: Maybe<Paragraph__Branches_Tariff>;
  node: Paragraph__Branches_Tariff;
  previous?: Maybe<Paragraph__Branches_Tariff>;
};

export type Paragraph__Branches_TariffField_Branches_Profits = {
  __typename?: 'paragraph__branches_tariffField_branches_profits';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_TariffField_Branches_ProfitsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_TariffField_Branches_ProfitsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_TariffField_Branches_ProfitsFilterInput>;
};

export type Paragraph__Branches_TariffField_Date_From_Api = {
  __typename?: 'paragraph__branches_tariffField_date_from_api';
  links?: Maybe<Paragraph__Branches_TariffField_Date_From_ApiLinks>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_TariffField_Date_From_ApiFilterInput = {
  links?: Maybe<Paragraph__Branches_TariffField_Date_From_ApiLinksFilterInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_TariffField_Date_From_ApiLinks = {
  __typename?: 'paragraph__branches_tariffField_date_from_apiLinks';
  help?: Maybe<Paragraph__Branches_TariffField_Date_From_ApiLinksHelp>;
};

export type Paragraph__Branches_TariffField_Date_From_ApiLinksFilterInput = {
  help?: Maybe<Paragraph__Branches_TariffField_Date_From_ApiLinksHelpFilterInput>;
};

export type Paragraph__Branches_TariffField_Date_From_ApiLinksHelp = {
  __typename?: 'paragraph__branches_tariffField_date_from_apiLinksHelp';
  href?: Maybe<Scalars['String']>;
  meta?: Maybe<Paragraph__Branches_TariffField_Date_From_ApiLinksHelpMeta>;
};

export type Paragraph__Branches_TariffField_Date_From_ApiLinksHelpFilterInput = {
  href?: Maybe<StringQueryOperatorInput>;
  meta?: Maybe<Paragraph__Branches_TariffField_Date_From_ApiLinksHelpMetaFilterInput>;
};

export type Paragraph__Branches_TariffField_Date_From_ApiLinksHelpMeta = {
  __typename?: 'paragraph__branches_tariffField_date_from_apiLinksHelpMeta';
  about?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_TariffField_Date_From_ApiLinksHelpMetaFilterInput = {
  about?: Maybe<StringQueryOperatorInput>;
};

export enum Paragraph__Branches_TariffFieldsEnum {
  field_branches_name = 'field_branches_name',
  field_recommended = 'field_recommended',
  relationships___field_branches_profits = 'relationships___field_branches_profits',
  relationships___field_branches_profits___field_profits_active = 'relationships___field_branches_profits___field_profits_active',
  relationships___field_branches_profits___field_profits_name___value = 'relationships___field_branches_profits___field_profits_name___value',
  relationships___field_branches_profits___field_ref_desc = 'relationships___field_branches_profits___field_ref_desc',
  relationships___field_branches_profits___field_ref_desc___target_revision_id = 'relationships___field_branches_profits___field_ref_desc___target_revision_id',
  relationships___field_branches_profits___field_ref_desc___drupal_internal__target_id = 'relationships___field_branches_profits___field_ref_desc___drupal_internal__target_id',
  relationships___field_branches_profits___drupal_id = 'relationships___field_branches_profits___drupal_id',
  relationships___field_branches_profits___drupal_internal__id = 'relationships___field_branches_profits___drupal_internal__id',
  relationships___field_branches_profits___status = 'relationships___field_branches_profits___status',
  relationships___field_branches_profits___created = 'relationships___field_branches_profits___created',
  relationships___field_branches_profits___parent_id = 'relationships___field_branches_profits___parent_id',
  relationships___field_branches_profits___parent_type = 'relationships___field_branches_profits___parent_type',
  relationships___field_branches_profits___parent_field_name = 'relationships___field_branches_profits___parent_field_name',
  relationships___field_branches_profits___content_translation_source = 'relationships___field_branches_profits___content_translation_source',
  relationships___field_branches_profits___content_translation_outdated = 'relationships___field_branches_profits___content_translation_outdated',
  relationships___field_branches_profits___content_translation_changed = 'relationships___field_branches_profits___content_translation_changed',
  relationships___field_branches_profits___langcode = 'relationships___field_branches_profits___langcode',
  relationships___field_branches_profits___uuid = 'relationships___field_branches_profits___uuid',
  relationships___field_branches_profits___relationships___node__tariffs = 'relationships___field_branches_profits___relationships___node__tariffs',
  relationships___field_branches_profits___relationships___field_ref_desc = 'relationships___field_branches_profits___relationships___field_ref_desc',
  relationships___field_branches_profits___relationships___paragraph__branches_device = 'relationships___field_branches_profits___relationships___paragraph__branches_device',
  relationships___field_branches_profits___relationships___paragraph__branches_tariff = 'relationships___field_branches_profits___relationships___paragraph__branches_tariff',
  relationships___field_branches_profits___id = 'relationships___field_branches_profits___id',
  relationships___field_branches_profits___parent___id = 'relationships___field_branches_profits___parent___id',
  relationships___field_branches_profits___parent___children = 'relationships___field_branches_profits___parent___children',
  relationships___field_branches_profits___children = 'relationships___field_branches_profits___children',
  relationships___field_branches_profits___children___id = 'relationships___field_branches_profits___children___id',
  relationships___field_branches_profits___children___children = 'relationships___field_branches_profits___children___children',
  relationships___field_branches_profits___internal___content = 'relationships___field_branches_profits___internal___content',
  relationships___field_branches_profits___internal___contentDigest = 'relationships___field_branches_profits___internal___contentDigest',
  relationships___field_branches_profits___internal___description = 'relationships___field_branches_profits___internal___description',
  relationships___field_branches_profits___internal___fieldOwners = 'relationships___field_branches_profits___internal___fieldOwners',
  relationships___field_branches_profits___internal___ignoreType = 'relationships___field_branches_profits___internal___ignoreType',
  relationships___field_branches_profits___internal___mediaType = 'relationships___field_branches_profits___internal___mediaType',
  relationships___field_branches_profits___internal___owner = 'relationships___field_branches_profits___internal___owner',
  relationships___field_branches_profits___internal___type = 'relationships___field_branches_profits___internal___type',
  relationships___field_date_from_api___drupal_id = 'relationships___field_date_from_api___drupal_id',
  relationships___field_date_from_api___fixedprice = 'relationships___field_date_from_api___fixedprice',
  relationships___field_date_from_api___iposfee = 'relationships___field_date_from_api___iposfee',
  relationships___field_date_from_api___langcode = 'relationships___field_date_from_api___langcode',
  relationships___field_date_from_api___status = 'relationships___field_date_from_api___status',
  relationships___field_date_from_api___field_pl_locationfee = 'relationships___field_date_from_api___field_pl_locationfee',
  relationships___field_date_from_api___field_pl_name = 'relationships___field_date_from_api___field_pl_name',
  relationships___field_date_from_api___field_pl_optionname = 'relationships___field_date_from_api___field_pl_optionname',
  relationships___field_date_from_api___field_pl_lease = 'relationships___field_date_from_api___field_pl_lease',
  relationships___field_date_from_api___field_pl_lease_name = 'relationships___field_date_from_api___field_pl_lease_name',
  relationships___field_date_from_api___field_pl_payment_type = 'relationships___field_date_from_api___field_pl_payment_type',
  relationships___field_date_from_api___field_pl_activation_price = 'relationships___field_date_from_api___field_pl_activation_price',
  relationships___field_date_from_api___field_pl_contract_duration = 'relationships___field_date_from_api___field_pl_contract_duration',
  relationships___field_date_from_api___field_pl_group_name_in_api = 'relationships___field_date_from_api___field_pl_group_name_in_api',
  relationships___field_date_from_api___field_pl_reduced_fee_duration = 'relationships___field_date_from_api___field_pl_reduced_fee_duration',
  relationships___field_date_from_api___field_pl_reduced_fee = 'relationships___field_date_from_api___field_pl_reduced_fee',
  relationships___field_date_from_api___drupal_internal__nid = 'relationships___field_date_from_api___drupal_internal__nid',
  relationships___field_date_from_api___content_translation_source = 'relationships___field_date_from_api___content_translation_source',
  relationships___field_date_from_api___content_translation_outdated = 'relationships___field_date_from_api___content_translation_outdated',
  relationships___field_date_from_api___field_pl_first_ipos_fee = 'relationships___field_date_from_api___field_pl_first_ipos_fee',
  relationships___field_date_from_api___vatrate = 'relationships___field_date_from_api___vatrate',
  relationships___field_date_from_api___uuid = 'relationships___field_date_from_api___uuid',
  relationships___field_date_from_api___relationships___node__accessories = 'relationships___field_date_from_api___relationships___node__accessories',
  relationships___field_date_from_api___relationships___node__cases = 'relationships___field_date_from_api___relationships___node__cases',
  relationships___field_date_from_api___relationships___paragraph__branches_tariff = 'relationships___field_date_from_api___relationships___paragraph__branches_tariff',
  relationships___field_date_from_api___pricelistgroup___drupal_internal__target_id = 'relationships___field_date_from_api___pricelistgroup___drupal_internal__target_id',
  relationships___field_date_from_api___id = 'relationships___field_date_from_api___id',
  relationships___field_date_from_api___parent___id = 'relationships___field_date_from_api___parent___id',
  relationships___field_date_from_api___parent___children = 'relationships___field_date_from_api___parent___children',
  relationships___field_date_from_api___children = 'relationships___field_date_from_api___children',
  relationships___field_date_from_api___children___id = 'relationships___field_date_from_api___children___id',
  relationships___field_date_from_api___children___children = 'relationships___field_date_from_api___children___children',
  relationships___field_date_from_api___internal___content = 'relationships___field_date_from_api___internal___content',
  relationships___field_date_from_api___internal___contentDigest = 'relationships___field_date_from_api___internal___contentDigest',
  relationships___field_date_from_api___internal___description = 'relationships___field_date_from_api___internal___description',
  relationships___field_date_from_api___internal___fieldOwners = 'relationships___field_date_from_api___internal___fieldOwners',
  relationships___field_date_from_api___internal___ignoreType = 'relationships___field_date_from_api___internal___ignoreType',
  relationships___field_date_from_api___internal___mediaType = 'relationships___field_date_from_api___internal___mediaType',
  relationships___field_date_from_api___internal___owner = 'relationships___field_date_from_api___internal___owner',
  relationships___field_date_from_api___internal___type = 'relationships___field_date_from_api___internal___type',
  relationships___paragraph__branches_recommended_tariffs = 'relationships___paragraph__branches_recommended_tariffs',
  relationships___paragraph__branches_recommended_tariffs___internal___content = 'relationships___paragraph__branches_recommended_tariffs___internal___content',
  relationships___paragraph__branches_recommended_tariffs___internal___contentDigest = 'relationships___paragraph__branches_recommended_tariffs___internal___contentDigest',
  relationships___paragraph__branches_recommended_tariffs___internal___description = 'relationships___paragraph__branches_recommended_tariffs___internal___description',
  relationships___paragraph__branches_recommended_tariffs___internal___fieldOwners = 'relationships___paragraph__branches_recommended_tariffs___internal___fieldOwners',
  relationships___paragraph__branches_recommended_tariffs___internal___ignoreType = 'relationships___paragraph__branches_recommended_tariffs___internal___ignoreType',
  relationships___paragraph__branches_recommended_tariffs___internal___mediaType = 'relationships___paragraph__branches_recommended_tariffs___internal___mediaType',
  relationships___paragraph__branches_recommended_tariffs___internal___owner = 'relationships___paragraph__branches_recommended_tariffs___internal___owner',
  relationships___paragraph__branches_recommended_tariffs___internal___type = 'relationships___paragraph__branches_recommended_tariffs___internal___type',
  relationships___paragraph__branches_recommended_tariffs___id = 'relationships___paragraph__branches_recommended_tariffs___id',
  relationships___paragraph__branches_recommended_tariffs___drupal_id = 'relationships___paragraph__branches_recommended_tariffs___drupal_id',
  relationships___paragraph__branches_recommended_tariffs___field_paragraph_headline___value = 'relationships___paragraph__branches_recommended_tariffs___field_paragraph_headline___value',
  relationships___paragraph__branches_recommended_tariffs___field_branches_text___value = 'relationships___paragraph__branches_recommended_tariffs___field_branches_text___value',
  relationships___paragraph__branches_recommended_tariffs___field_nc_button_text = 'relationships___paragraph__branches_recommended_tariffs___field_nc_button_text',
  relationships___paragraph__branches_recommended_tariffs___relationships___field_branches_tarrifs = 'relationships___paragraph__branches_recommended_tariffs___relationships___field_branches_tarrifs',
  relationships___paragraph__branches_recommended_tariffs___relationships___node__branches = 'relationships___paragraph__branches_recommended_tariffs___relationships___node__branches',
  relationships___paragraph__branches_recommended_tariffs___drupal_internal__id = 'relationships___paragraph__branches_recommended_tariffs___drupal_internal__id',
  relationships___paragraph__branches_recommended_tariffs___status = 'relationships___paragraph__branches_recommended_tariffs___status',
  relationships___paragraph__branches_recommended_tariffs___created = 'relationships___paragraph__branches_recommended_tariffs___created',
  relationships___paragraph__branches_recommended_tariffs___parent_id = 'relationships___paragraph__branches_recommended_tariffs___parent_id',
  relationships___paragraph__branches_recommended_tariffs___parent_type = 'relationships___paragraph__branches_recommended_tariffs___parent_type',
  relationships___paragraph__branches_recommended_tariffs___parent_field_name = 'relationships___paragraph__branches_recommended_tariffs___parent_field_name',
  relationships___paragraph__branches_recommended_tariffs___content_translation_source = 'relationships___paragraph__branches_recommended_tariffs___content_translation_source',
  relationships___paragraph__branches_recommended_tariffs___content_translation_outdated = 'relationships___paragraph__branches_recommended_tariffs___content_translation_outdated',
  relationships___paragraph__branches_recommended_tariffs___langcode = 'relationships___paragraph__branches_recommended_tariffs___langcode',
  relationships___paragraph__branches_recommended_tariffs___uuid = 'relationships___paragraph__branches_recommended_tariffs___uuid',
  relationships___paragraph__branches_recommended_tariffs___field_branches_tarrifs = 'relationships___paragraph__branches_recommended_tariffs___field_branches_tarrifs',
  relationships___paragraph__branches_recommended_tariffs___field_branches_tarrifs___target_revision_id = 'relationships___paragraph__branches_recommended_tariffs___field_branches_tarrifs___target_revision_id',
  relationships___paragraph__branches_recommended_tariffs___field_branches_tarrifs___drupal_internal__target_id = 'relationships___paragraph__branches_recommended_tariffs___field_branches_tarrifs___drupal_internal__target_id',
  relationships___paragraph__branches_recommended_tariffs___parent___id = 'relationships___paragraph__branches_recommended_tariffs___parent___id',
  relationships___paragraph__branches_recommended_tariffs___parent___children = 'relationships___paragraph__branches_recommended_tariffs___parent___children',
  relationships___paragraph__branches_recommended_tariffs___children = 'relationships___paragraph__branches_recommended_tariffs___children',
  relationships___paragraph__branches_recommended_tariffs___children___id = 'relationships___paragraph__branches_recommended_tariffs___children___id',
  relationships___paragraph__branches_recommended_tariffs___children___children = 'relationships___paragraph__branches_recommended_tariffs___children___children',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_branches_profits = 'field_branches_profits',
  field_branches_profits___target_revision_id = 'field_branches_profits___target_revision_id',
  field_branches_profits___drupal_internal__target_id = 'field_branches_profits___drupal_internal__target_id',
  field_date_from_api___links___help___href = 'field_date_from_api___links___help___href',
  field_date_from_api___drupal_internal__target_id = 'field_date_from_api___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Branches_TariffFilterInput = {
  field_branches_name?: Maybe<StringQueryOperatorInput>;
  field_recommended?: Maybe<BooleanQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_TariffRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_profits?: Maybe<Paragraph__Branches_TariffField_Branches_ProfitsFilterListInput>;
  field_date_from_api?: Maybe<Paragraph__Branches_TariffField_Date_From_ApiFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Branches_TariffFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_TariffFilterInput>;
};

export type Paragraph__Branches_TariffGroupConnection = {
  __typename?: 'paragraph__branches_tariffGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_TariffEdge>;
  nodes: Array<Paragraph__Branches_Tariff>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_TariffRelationships = {
  __typename?: 'paragraph__branches_tariffRelationships';
  field_branches_profits?: Maybe<Array<Maybe<Paragraph__Profits>>>;
  field_date_from_api?: Maybe<Node__Price_List>;
  paragraph__branches_recommended_tariffs?: Maybe<
    Array<Maybe<Paragraph__Branches_Recommended_Tariffs>>
  >;
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
};

export type Paragraph__Branches_TariffRelationshipsFilterInput = {
  field_branches_profits?: Maybe<Paragraph__ProfitsFilterListInput>;
  field_date_from_api?: Maybe<Node__Price_ListFilterInput>;
  paragraph__branches_recommended_tariffs?: Maybe<
    Paragraph__Branches_Recommended_TariffsFilterListInput
  >;
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
};

export type Paragraph__Branches_TariffSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_TariffFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_Testimonials = Node & {
  __typename?: 'paragraph__branches_testimonials';
  internal: Internal;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  field_nq_quotation?: Maybe<Field_Nq_Quotation>;
  relationships?: Maybe<Paragraph__Branches_TestimonialsRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  field_branches_name?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Paragraph__Branches_TestimonialsField_Paragraph_Headline>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_image?: Maybe<Paragraph__Branches_TestimonialsField_Branches_Image>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_TestimonialsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_TestimonialsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_TestimonialsConnection = {
  __typename?: 'paragraph__branches_testimonialsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_TestimonialsEdge>;
  nodes: Array<Paragraph__Branches_Testimonials>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_TestimonialsGroupConnection>;
};

export type Paragraph__Branches_TestimonialsConnectionDistinctArgs = {
  field: Paragraph__Branches_TestimonialsFieldsEnum;
};

export type Paragraph__Branches_TestimonialsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_TestimonialsFieldsEnum;
};

export type Paragraph__Branches_TestimonialsEdge = {
  __typename?: 'paragraph__branches_testimonialsEdge';
  next?: Maybe<Paragraph__Branches_Testimonials>;
  node: Paragraph__Branches_Testimonials;
  previous?: Maybe<Paragraph__Branches_Testimonials>;
};

export type Paragraph__Branches_TestimonialsField_Branches_Image = {
  __typename?: 'paragraph__branches_testimonialsField_branches_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_TestimonialsField_Branches_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_TestimonialsField_Paragraph_Headline = {
  __typename?: 'paragraph__branches_testimonialsField_paragraph_headline';
  value?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  processed?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_TestimonialsField_Paragraph_HeadlineFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
  format?: Maybe<StringQueryOperatorInput>;
  processed?: Maybe<StringQueryOperatorInput>;
};

export enum Paragraph__Branches_TestimonialsFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  drupal_id = 'drupal_id',
  field_nq_quotation___value = 'field_nq_quotation___value',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  field_branches_name = 'field_branches_name',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_paragraph_headline___format = 'field_paragraph_headline___format',
  field_paragraph_headline___processed = 'field_paragraph_headline___processed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_branches_image___alt = 'field_branches_image___alt',
  field_branches_image___title = 'field_branches_image___title',
  field_branches_image___width = 'field_branches_image___width',
  field_branches_image___height = 'field_branches_image___height',
  field_branches_image___drupal_internal__target_id = 'field_branches_image___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_TestimonialsFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_nq_quotation?: Maybe<Field_Nq_QuotationFilterInput>;
  relationships?: Maybe<Paragraph__Branches_TestimonialsRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  field_branches_name?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<
    Paragraph__Branches_TestimonialsField_Paragraph_HeadlineFilterInput
  >;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Paragraph__Branches_TestimonialsField_Branches_ImageFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_TestimonialsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_TestimonialsFilterInput>;
};

export type Paragraph__Branches_TestimonialsGroupConnection = {
  __typename?: 'paragraph__branches_testimonialsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_TestimonialsEdge>;
  nodes: Array<Paragraph__Branches_Testimonials>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_TestimonialsRelationships = {
  __typename?: 'paragraph__branches_testimonialsRelationships';
  field_branches_image?: Maybe<File__File>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
};

export type Paragraph__Branches_TestimonialsRelationshipsFilterInput = {
  field_branches_image?: Maybe<File__FileFilterInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
};

export type Paragraph__Branches_TestimonialsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_TestimonialsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Branches_What_Is_Ipos = Node & {
  __typename?: 'paragraph__branches_what_is_ipos';
  internal: Internal;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  field_branches_text?: Maybe<Field_Branches_Text>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_short_text_1?: Maybe<Field_Short_Text_1>;
  field_short_text_2?: Maybe<Field_Short_Text_2>;
  field_short_text_3?: Maybe<Field_Short_Text_3>;
  relationships?: Maybe<Paragraph__Branches_What_Is_IposRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_branches_image?: Maybe<Paragraph__Branches_What_Is_IposField_Branches_Image>;
  field_ico_1?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_1>;
  field_ico_2?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_2>;
  field_ico_3?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_3>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Branches_What_Is_IposCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_What_Is_IposContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_What_Is_IposConnection = {
  __typename?: 'paragraph__branches_what_is_iposConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_What_Is_IposEdge>;
  nodes: Array<Paragraph__Branches_What_Is_Ipos>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Branches_What_Is_IposGroupConnection>;
};

export type Paragraph__Branches_What_Is_IposConnectionDistinctArgs = {
  field: Paragraph__Branches_What_Is_IposFieldsEnum;
};

export type Paragraph__Branches_What_Is_IposConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Branches_What_Is_IposFieldsEnum;
};

export type Paragraph__Branches_What_Is_IposEdge = {
  __typename?: 'paragraph__branches_what_is_iposEdge';
  next?: Maybe<Paragraph__Branches_What_Is_Ipos>;
  node: Paragraph__Branches_What_Is_Ipos;
  previous?: Maybe<Paragraph__Branches_What_Is_Ipos>;
};

export type Paragraph__Branches_What_Is_IposField_Branches_Image = {
  __typename?: 'paragraph__branches_what_is_iposField_branches_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_What_Is_IposField_Branches_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_What_Is_IposField_Ico_1 = {
  __typename?: 'paragraph__branches_what_is_iposField_ico_1';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_What_Is_IposField_Ico_1FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_What_Is_IposField_Ico_2 = {
  __typename?: 'paragraph__branches_what_is_iposField_ico_2';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_What_Is_IposField_Ico_2FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Branches_What_Is_IposField_Ico_3 = {
  __typename?: 'paragraph__branches_what_is_iposField_ico_3';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Branches_What_Is_IposField_Ico_3FilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Paragraph__Branches_What_Is_IposFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  drupal_id = 'drupal_id',
  field_branches_text___value = 'field_branches_text___value',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_short_text_1___value = 'field_short_text_1___value',
  field_short_text_2___value = 'field_short_text_2___value',
  field_short_text_3___value = 'field_short_text_3___value',
  relationships___field_branches_image___localFile___birthtime = 'relationships___field_branches_image___localFile___birthtime',
  relationships___field_branches_image___localFile___birthtimeMs = 'relationships___field_branches_image___localFile___birthtimeMs',
  relationships___field_branches_image___localFile___publicURL = 'relationships___field_branches_image___localFile___publicURL',
  relationships___field_branches_image___localFile___sourceInstanceName = 'relationships___field_branches_image___localFile___sourceInstanceName',
  relationships___field_branches_image___localFile___absolutePath = 'relationships___field_branches_image___localFile___absolutePath',
  relationships___field_branches_image___localFile___relativePath = 'relationships___field_branches_image___localFile___relativePath',
  relationships___field_branches_image___localFile___extension = 'relationships___field_branches_image___localFile___extension',
  relationships___field_branches_image___localFile___size = 'relationships___field_branches_image___localFile___size',
  relationships___field_branches_image___localFile___prettySize = 'relationships___field_branches_image___localFile___prettySize',
  relationships___field_branches_image___localFile___modifiedTime = 'relationships___field_branches_image___localFile___modifiedTime',
  relationships___field_branches_image___localFile___accessTime = 'relationships___field_branches_image___localFile___accessTime',
  relationships___field_branches_image___localFile___changeTime = 'relationships___field_branches_image___localFile___changeTime',
  relationships___field_branches_image___localFile___birthTime = 'relationships___field_branches_image___localFile___birthTime',
  relationships___field_branches_image___localFile___root = 'relationships___field_branches_image___localFile___root',
  relationships___field_branches_image___localFile___dir = 'relationships___field_branches_image___localFile___dir',
  relationships___field_branches_image___localFile___base = 'relationships___field_branches_image___localFile___base',
  relationships___field_branches_image___localFile___ext = 'relationships___field_branches_image___localFile___ext',
  relationships___field_branches_image___localFile___name = 'relationships___field_branches_image___localFile___name',
  relationships___field_branches_image___localFile___relativeDirectory = 'relationships___field_branches_image___localFile___relativeDirectory',
  relationships___field_branches_image___localFile___dev = 'relationships___field_branches_image___localFile___dev',
  relationships___field_branches_image___localFile___mode = 'relationships___field_branches_image___localFile___mode',
  relationships___field_branches_image___localFile___nlink = 'relationships___field_branches_image___localFile___nlink',
  relationships___field_branches_image___localFile___uid = 'relationships___field_branches_image___localFile___uid',
  relationships___field_branches_image___localFile___gid = 'relationships___field_branches_image___localFile___gid',
  relationships___field_branches_image___localFile___rdev = 'relationships___field_branches_image___localFile___rdev',
  relationships___field_branches_image___localFile___blksize = 'relationships___field_branches_image___localFile___blksize',
  relationships___field_branches_image___localFile___ino = 'relationships___field_branches_image___localFile___ino',
  relationships___field_branches_image___localFile___blocks = 'relationships___field_branches_image___localFile___blocks',
  relationships___field_branches_image___localFile___atimeMs = 'relationships___field_branches_image___localFile___atimeMs',
  relationships___field_branches_image___localFile___mtimeMs = 'relationships___field_branches_image___localFile___mtimeMs',
  relationships___field_branches_image___localFile___ctimeMs = 'relationships___field_branches_image___localFile___ctimeMs',
  relationships___field_branches_image___localFile___atime = 'relationships___field_branches_image___localFile___atime',
  relationships___field_branches_image___localFile___mtime = 'relationships___field_branches_image___localFile___mtime',
  relationships___field_branches_image___localFile___ctime = 'relationships___field_branches_image___localFile___ctime',
  relationships___field_branches_image___localFile___url = 'relationships___field_branches_image___localFile___url',
  relationships___field_branches_image___localFile___id = 'relationships___field_branches_image___localFile___id',
  relationships___field_branches_image___localFile___children = 'relationships___field_branches_image___localFile___children',
  relationships___field_branches_image___uri___url = 'relationships___field_branches_image___uri___url',
  relationships___field_branches_image___drupal_id = 'relationships___field_branches_image___drupal_id',
  relationships___field_branches_image___drupal_internal__fid = 'relationships___field_branches_image___drupal_internal__fid',
  relationships___field_branches_image___langcode = 'relationships___field_branches_image___langcode',
  relationships___field_branches_image___filename = 'relationships___field_branches_image___filename',
  relationships___field_branches_image___filemime = 'relationships___field_branches_image___filemime',
  relationships___field_branches_image___filesize = 'relationships___field_branches_image___filesize',
  relationships___field_branches_image___status = 'relationships___field_branches_image___status',
  relationships___field_branches_image___created = 'relationships___field_branches_image___created',
  relationships___field_branches_image___changed = 'relationships___field_branches_image___changed',
  relationships___field_branches_image___uuid = 'relationships___field_branches_image___uuid',
  relationships___field_branches_image___relationships___node__blog_post = 'relationships___field_branches_image___relationships___node__blog_post',
  relationships___field_branches_image___relationships___node__devices = 'relationships___field_branches_image___relationships___node__devices',
  relationships___field_branches_image___relationships___node__accessories = 'relationships___field_branches_image___relationships___node__accessories',
  relationships___field_branches_image___relationships___paragraph__news_image_title_text = 'relationships___field_branches_image___relationships___paragraph__news_image_title_text',
  relationships___field_branches_image___relationships___node__cases = 'relationships___field_branches_image___relationships___node__cases',
  relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_branches_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_branches_image___relationships___paragraph__branches_features = 'relationships___field_branches_image___relationships___paragraph__branches_features',
  relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_branches_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_branches_image___relationships___paragraph__branches_testimonials = 'relationships___field_branches_image___relationships___paragraph__branches_testimonials',
  relationships___field_branches_image___relationships___paragraph__branches_discounts = 'relationships___field_branches_image___relationships___paragraph__branches_discounts',
  relationships___field_branches_image___relationships___node__news = 'relationships___field_branches_image___relationships___node__news',
  relationships___field_branches_image___relationships___node__branches = 'relationships___field_branches_image___relationships___node__branches',
  relationships___field_branches_image___relationships___paragraph__branches_hero = 'relationships___field_branches_image___relationships___paragraph__branches_hero',
  relationships___field_branches_image___relationships___paragraph__bullets = 'relationships___field_branches_image___relationships___paragraph__bullets',
  relationships___field_branches_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_branches_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_branches_image___relationships___paragraph__branches_device = 'relationships___field_branches_image___relationships___paragraph__branches_device',
  relationships___field_branches_image___relationships___paragraph__branches_recommendation = 'relationships___field_branches_image___relationships___paragraph__branches_recommendation',
  relationships___field_branches_image___relationships___site_setting_entity__accessories = 'relationships___field_branches_image___relationships___site_setting_entity__accessories',
  relationships___field_branches_image___relationships___site_setting_entity__branches = 'relationships___field_branches_image___relationships___site_setting_entity__branches',
  relationships___field_branches_image___relationships___site_setting_entity__about_us = 'relationships___field_branches_image___relationships___site_setting_entity__about_us',
  relationships___field_branches_image___relationships___paragraph__logos = 'relationships___field_branches_image___relationships___paragraph__logos',
  relationships___field_branches_image___relationships___paragraph__how_it_works = 'relationships___field_branches_image___relationships___paragraph__how_it_works',
  relationships___field_branches_image___relationships___paragraph__ico_and_text = 'relationships___field_branches_image___relationships___paragraph__ico_and_text',
  relationships___field_branches_image___relationships___paragraph__hero_1 = 'relationships___field_branches_image___relationships___paragraph__hero_1',
  relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_branches_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_branches_image___relationships___site_setting_entity__product = 'relationships___field_branches_image___relationships___site_setting_entity__product',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_branches_image___relationships___paragraph__porownanie_terminali = 'relationships___field_branches_image___relationships___paragraph__porownanie_terminali',
  relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_branches_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_branches_image___relationships___site_setting_entity__main_page = 'relationships___field_branches_image___relationships___site_setting_entity__main_page',
  relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_branches_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_branches_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_branches_image___relationships___site_setting_entity__intercars = 'relationships___field_branches_image___relationships___site_setting_entity__intercars',
  relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_branches_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_branches_image___relationships___node__agreement = 'relationships___field_branches_image___relationships___node__agreement',
  relationships___field_branches_image___relationships___node__fiscalization = 'relationships___field_branches_image___relationships___node__fiscalization',
  relationships___field_branches_image___relationships___site_setting_entity__configurator = 'relationships___field_branches_image___relationships___site_setting_entity__configurator',
  relationships___field_branches_image___relationships___paragraph__social_link = 'relationships___field_branches_image___relationships___paragraph__social_link',
  relationships___field_branches_image___relationships___paragraph__make_an_appointment = 'relationships___field_branches_image___relationships___paragraph__make_an_appointment',
  relationships___field_branches_image___relationships___paragraph__linked_icons = 'relationships___field_branches_image___relationships___paragraph__linked_icons',
  relationships___field_branches_image___relationships___site_setting_entity__financing = 'relationships___field_branches_image___relationships___site_setting_entity__financing',
  relationships___field_branches_image___relationships___site_setting_entity__franchise = 'relationships___field_branches_image___relationships___site_setting_entity__franchise',
  relationships___field_branches_image___id = 'relationships___field_branches_image___id',
  relationships___field_branches_image___parent___id = 'relationships___field_branches_image___parent___id',
  relationships___field_branches_image___parent___children = 'relationships___field_branches_image___parent___children',
  relationships___field_branches_image___children = 'relationships___field_branches_image___children',
  relationships___field_branches_image___children___id = 'relationships___field_branches_image___children___id',
  relationships___field_branches_image___children___children = 'relationships___field_branches_image___children___children',
  relationships___field_branches_image___internal___content = 'relationships___field_branches_image___internal___content',
  relationships___field_branches_image___internal___contentDigest = 'relationships___field_branches_image___internal___contentDigest',
  relationships___field_branches_image___internal___description = 'relationships___field_branches_image___internal___description',
  relationships___field_branches_image___internal___fieldOwners = 'relationships___field_branches_image___internal___fieldOwners',
  relationships___field_branches_image___internal___ignoreType = 'relationships___field_branches_image___internal___ignoreType',
  relationships___field_branches_image___internal___mediaType = 'relationships___field_branches_image___internal___mediaType',
  relationships___field_branches_image___internal___owner = 'relationships___field_branches_image___internal___owner',
  relationships___field_branches_image___internal___type = 'relationships___field_branches_image___internal___type',
  relationships___field_ico_1___localFile___birthtime = 'relationships___field_ico_1___localFile___birthtime',
  relationships___field_ico_1___localFile___birthtimeMs = 'relationships___field_ico_1___localFile___birthtimeMs',
  relationships___field_ico_1___localFile___publicURL = 'relationships___field_ico_1___localFile___publicURL',
  relationships___field_ico_1___localFile___sourceInstanceName = 'relationships___field_ico_1___localFile___sourceInstanceName',
  relationships___field_ico_1___localFile___absolutePath = 'relationships___field_ico_1___localFile___absolutePath',
  relationships___field_ico_1___localFile___relativePath = 'relationships___field_ico_1___localFile___relativePath',
  relationships___field_ico_1___localFile___extension = 'relationships___field_ico_1___localFile___extension',
  relationships___field_ico_1___localFile___size = 'relationships___field_ico_1___localFile___size',
  relationships___field_ico_1___localFile___prettySize = 'relationships___field_ico_1___localFile___prettySize',
  relationships___field_ico_1___localFile___modifiedTime = 'relationships___field_ico_1___localFile___modifiedTime',
  relationships___field_ico_1___localFile___accessTime = 'relationships___field_ico_1___localFile___accessTime',
  relationships___field_ico_1___localFile___changeTime = 'relationships___field_ico_1___localFile___changeTime',
  relationships___field_ico_1___localFile___birthTime = 'relationships___field_ico_1___localFile___birthTime',
  relationships___field_ico_1___localFile___root = 'relationships___field_ico_1___localFile___root',
  relationships___field_ico_1___localFile___dir = 'relationships___field_ico_1___localFile___dir',
  relationships___field_ico_1___localFile___base = 'relationships___field_ico_1___localFile___base',
  relationships___field_ico_1___localFile___ext = 'relationships___field_ico_1___localFile___ext',
  relationships___field_ico_1___localFile___name = 'relationships___field_ico_1___localFile___name',
  relationships___field_ico_1___localFile___relativeDirectory = 'relationships___field_ico_1___localFile___relativeDirectory',
  relationships___field_ico_1___localFile___dev = 'relationships___field_ico_1___localFile___dev',
  relationships___field_ico_1___localFile___mode = 'relationships___field_ico_1___localFile___mode',
  relationships___field_ico_1___localFile___nlink = 'relationships___field_ico_1___localFile___nlink',
  relationships___field_ico_1___localFile___uid = 'relationships___field_ico_1___localFile___uid',
  relationships___field_ico_1___localFile___gid = 'relationships___field_ico_1___localFile___gid',
  relationships___field_ico_1___localFile___rdev = 'relationships___field_ico_1___localFile___rdev',
  relationships___field_ico_1___localFile___blksize = 'relationships___field_ico_1___localFile___blksize',
  relationships___field_ico_1___localFile___ino = 'relationships___field_ico_1___localFile___ino',
  relationships___field_ico_1___localFile___blocks = 'relationships___field_ico_1___localFile___blocks',
  relationships___field_ico_1___localFile___atimeMs = 'relationships___field_ico_1___localFile___atimeMs',
  relationships___field_ico_1___localFile___mtimeMs = 'relationships___field_ico_1___localFile___mtimeMs',
  relationships___field_ico_1___localFile___ctimeMs = 'relationships___field_ico_1___localFile___ctimeMs',
  relationships___field_ico_1___localFile___atime = 'relationships___field_ico_1___localFile___atime',
  relationships___field_ico_1___localFile___mtime = 'relationships___field_ico_1___localFile___mtime',
  relationships___field_ico_1___localFile___ctime = 'relationships___field_ico_1___localFile___ctime',
  relationships___field_ico_1___localFile___url = 'relationships___field_ico_1___localFile___url',
  relationships___field_ico_1___localFile___id = 'relationships___field_ico_1___localFile___id',
  relationships___field_ico_1___localFile___children = 'relationships___field_ico_1___localFile___children',
  relationships___field_ico_1___uri___url = 'relationships___field_ico_1___uri___url',
  relationships___field_ico_1___drupal_id = 'relationships___field_ico_1___drupal_id',
  relationships___field_ico_1___drupal_internal__fid = 'relationships___field_ico_1___drupal_internal__fid',
  relationships___field_ico_1___langcode = 'relationships___field_ico_1___langcode',
  relationships___field_ico_1___filename = 'relationships___field_ico_1___filename',
  relationships___field_ico_1___filemime = 'relationships___field_ico_1___filemime',
  relationships___field_ico_1___filesize = 'relationships___field_ico_1___filesize',
  relationships___field_ico_1___status = 'relationships___field_ico_1___status',
  relationships___field_ico_1___created = 'relationships___field_ico_1___created',
  relationships___field_ico_1___changed = 'relationships___field_ico_1___changed',
  relationships___field_ico_1___uuid = 'relationships___field_ico_1___uuid',
  relationships___field_ico_1___relationships___node__blog_post = 'relationships___field_ico_1___relationships___node__blog_post',
  relationships___field_ico_1___relationships___node__devices = 'relationships___field_ico_1___relationships___node__devices',
  relationships___field_ico_1___relationships___node__accessories = 'relationships___field_ico_1___relationships___node__accessories',
  relationships___field_ico_1___relationships___paragraph__news_image_title_text = 'relationships___field_ico_1___relationships___paragraph__news_image_title_text',
  relationships___field_ico_1___relationships___node__cases = 'relationships___field_ico_1___relationships___node__cases',
  relationships___field_ico_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_1___relationships___paragraph__branches_features = 'relationships___field_ico_1___relationships___paragraph__branches_features',
  relationships___field_ico_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_1___relationships___paragraph__branches_testimonials = 'relationships___field_ico_1___relationships___paragraph__branches_testimonials',
  relationships___field_ico_1___relationships___paragraph__branches_discounts = 'relationships___field_ico_1___relationships___paragraph__branches_discounts',
  relationships___field_ico_1___relationships___node__news = 'relationships___field_ico_1___relationships___node__news',
  relationships___field_ico_1___relationships___node__branches = 'relationships___field_ico_1___relationships___node__branches',
  relationships___field_ico_1___relationships___paragraph__branches_hero = 'relationships___field_ico_1___relationships___paragraph__branches_hero',
  relationships___field_ico_1___relationships___paragraph__bullets = 'relationships___field_ico_1___relationships___paragraph__bullets',
  relationships___field_ico_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_1___relationships___paragraph__branches_device = 'relationships___field_ico_1___relationships___paragraph__branches_device',
  relationships___field_ico_1___relationships___paragraph__branches_recommendation = 'relationships___field_ico_1___relationships___paragraph__branches_recommendation',
  relationships___field_ico_1___relationships___site_setting_entity__accessories = 'relationships___field_ico_1___relationships___site_setting_entity__accessories',
  relationships___field_ico_1___relationships___site_setting_entity__branches = 'relationships___field_ico_1___relationships___site_setting_entity__branches',
  relationships___field_ico_1___relationships___site_setting_entity__about_us = 'relationships___field_ico_1___relationships___site_setting_entity__about_us',
  relationships___field_ico_1___relationships___paragraph__logos = 'relationships___field_ico_1___relationships___paragraph__logos',
  relationships___field_ico_1___relationships___paragraph__how_it_works = 'relationships___field_ico_1___relationships___paragraph__how_it_works',
  relationships___field_ico_1___relationships___paragraph__ico_and_text = 'relationships___field_ico_1___relationships___paragraph__ico_and_text',
  relationships___field_ico_1___relationships___paragraph__hero_1 = 'relationships___field_ico_1___relationships___paragraph__hero_1',
  relationships___field_ico_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_1___relationships___site_setting_entity__product = 'relationships___field_ico_1___relationships___site_setting_entity__product',
  relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_1___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_1___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_1___relationships___site_setting_entity__main_page = 'relationships___field_ico_1___relationships___site_setting_entity__main_page',
  relationships___field_ico_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_1___relationships___site_setting_entity__intercars = 'relationships___field_ico_1___relationships___site_setting_entity__intercars',
  relationships___field_ico_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_1___relationships___node__agreement = 'relationships___field_ico_1___relationships___node__agreement',
  relationships___field_ico_1___relationships___node__fiscalization = 'relationships___field_ico_1___relationships___node__fiscalization',
  relationships___field_ico_1___relationships___site_setting_entity__configurator = 'relationships___field_ico_1___relationships___site_setting_entity__configurator',
  relationships___field_ico_1___relationships___paragraph__social_link = 'relationships___field_ico_1___relationships___paragraph__social_link',
  relationships___field_ico_1___relationships___paragraph__make_an_appointment = 'relationships___field_ico_1___relationships___paragraph__make_an_appointment',
  relationships___field_ico_1___relationships___paragraph__linked_icons = 'relationships___field_ico_1___relationships___paragraph__linked_icons',
  relationships___field_ico_1___relationships___site_setting_entity__financing = 'relationships___field_ico_1___relationships___site_setting_entity__financing',
  relationships___field_ico_1___relationships___site_setting_entity__franchise = 'relationships___field_ico_1___relationships___site_setting_entity__franchise',
  relationships___field_ico_1___id = 'relationships___field_ico_1___id',
  relationships___field_ico_1___parent___id = 'relationships___field_ico_1___parent___id',
  relationships___field_ico_1___parent___children = 'relationships___field_ico_1___parent___children',
  relationships___field_ico_1___children = 'relationships___field_ico_1___children',
  relationships___field_ico_1___children___id = 'relationships___field_ico_1___children___id',
  relationships___field_ico_1___children___children = 'relationships___field_ico_1___children___children',
  relationships___field_ico_1___internal___content = 'relationships___field_ico_1___internal___content',
  relationships___field_ico_1___internal___contentDigest = 'relationships___field_ico_1___internal___contentDigest',
  relationships___field_ico_1___internal___description = 'relationships___field_ico_1___internal___description',
  relationships___field_ico_1___internal___fieldOwners = 'relationships___field_ico_1___internal___fieldOwners',
  relationships___field_ico_1___internal___ignoreType = 'relationships___field_ico_1___internal___ignoreType',
  relationships___field_ico_1___internal___mediaType = 'relationships___field_ico_1___internal___mediaType',
  relationships___field_ico_1___internal___owner = 'relationships___field_ico_1___internal___owner',
  relationships___field_ico_1___internal___type = 'relationships___field_ico_1___internal___type',
  relationships___field_ico_2___localFile___birthtime = 'relationships___field_ico_2___localFile___birthtime',
  relationships___field_ico_2___localFile___birthtimeMs = 'relationships___field_ico_2___localFile___birthtimeMs',
  relationships___field_ico_2___localFile___publicURL = 'relationships___field_ico_2___localFile___publicURL',
  relationships___field_ico_2___localFile___sourceInstanceName = 'relationships___field_ico_2___localFile___sourceInstanceName',
  relationships___field_ico_2___localFile___absolutePath = 'relationships___field_ico_2___localFile___absolutePath',
  relationships___field_ico_2___localFile___relativePath = 'relationships___field_ico_2___localFile___relativePath',
  relationships___field_ico_2___localFile___extension = 'relationships___field_ico_2___localFile___extension',
  relationships___field_ico_2___localFile___size = 'relationships___field_ico_2___localFile___size',
  relationships___field_ico_2___localFile___prettySize = 'relationships___field_ico_2___localFile___prettySize',
  relationships___field_ico_2___localFile___modifiedTime = 'relationships___field_ico_2___localFile___modifiedTime',
  relationships___field_ico_2___localFile___accessTime = 'relationships___field_ico_2___localFile___accessTime',
  relationships___field_ico_2___localFile___changeTime = 'relationships___field_ico_2___localFile___changeTime',
  relationships___field_ico_2___localFile___birthTime = 'relationships___field_ico_2___localFile___birthTime',
  relationships___field_ico_2___localFile___root = 'relationships___field_ico_2___localFile___root',
  relationships___field_ico_2___localFile___dir = 'relationships___field_ico_2___localFile___dir',
  relationships___field_ico_2___localFile___base = 'relationships___field_ico_2___localFile___base',
  relationships___field_ico_2___localFile___ext = 'relationships___field_ico_2___localFile___ext',
  relationships___field_ico_2___localFile___name = 'relationships___field_ico_2___localFile___name',
  relationships___field_ico_2___localFile___relativeDirectory = 'relationships___field_ico_2___localFile___relativeDirectory',
  relationships___field_ico_2___localFile___dev = 'relationships___field_ico_2___localFile___dev',
  relationships___field_ico_2___localFile___mode = 'relationships___field_ico_2___localFile___mode',
  relationships___field_ico_2___localFile___nlink = 'relationships___field_ico_2___localFile___nlink',
  relationships___field_ico_2___localFile___uid = 'relationships___field_ico_2___localFile___uid',
  relationships___field_ico_2___localFile___gid = 'relationships___field_ico_2___localFile___gid',
  relationships___field_ico_2___localFile___rdev = 'relationships___field_ico_2___localFile___rdev',
  relationships___field_ico_2___localFile___blksize = 'relationships___field_ico_2___localFile___blksize',
  relationships___field_ico_2___localFile___ino = 'relationships___field_ico_2___localFile___ino',
  relationships___field_ico_2___localFile___blocks = 'relationships___field_ico_2___localFile___blocks',
  relationships___field_ico_2___localFile___atimeMs = 'relationships___field_ico_2___localFile___atimeMs',
  relationships___field_ico_2___localFile___mtimeMs = 'relationships___field_ico_2___localFile___mtimeMs',
  relationships___field_ico_2___localFile___ctimeMs = 'relationships___field_ico_2___localFile___ctimeMs',
  relationships___field_ico_2___localFile___atime = 'relationships___field_ico_2___localFile___atime',
  relationships___field_ico_2___localFile___mtime = 'relationships___field_ico_2___localFile___mtime',
  relationships___field_ico_2___localFile___ctime = 'relationships___field_ico_2___localFile___ctime',
  relationships___field_ico_2___localFile___url = 'relationships___field_ico_2___localFile___url',
  relationships___field_ico_2___localFile___id = 'relationships___field_ico_2___localFile___id',
  relationships___field_ico_2___localFile___children = 'relationships___field_ico_2___localFile___children',
  relationships___field_ico_2___uri___url = 'relationships___field_ico_2___uri___url',
  relationships___field_ico_2___drupal_id = 'relationships___field_ico_2___drupal_id',
  relationships___field_ico_2___drupal_internal__fid = 'relationships___field_ico_2___drupal_internal__fid',
  relationships___field_ico_2___langcode = 'relationships___field_ico_2___langcode',
  relationships___field_ico_2___filename = 'relationships___field_ico_2___filename',
  relationships___field_ico_2___filemime = 'relationships___field_ico_2___filemime',
  relationships___field_ico_2___filesize = 'relationships___field_ico_2___filesize',
  relationships___field_ico_2___status = 'relationships___field_ico_2___status',
  relationships___field_ico_2___created = 'relationships___field_ico_2___created',
  relationships___field_ico_2___changed = 'relationships___field_ico_2___changed',
  relationships___field_ico_2___uuid = 'relationships___field_ico_2___uuid',
  relationships___field_ico_2___relationships___node__blog_post = 'relationships___field_ico_2___relationships___node__blog_post',
  relationships___field_ico_2___relationships___node__devices = 'relationships___field_ico_2___relationships___node__devices',
  relationships___field_ico_2___relationships___node__accessories = 'relationships___field_ico_2___relationships___node__accessories',
  relationships___field_ico_2___relationships___paragraph__news_image_title_text = 'relationships___field_ico_2___relationships___paragraph__news_image_title_text',
  relationships___field_ico_2___relationships___node__cases = 'relationships___field_ico_2___relationships___node__cases',
  relationships___field_ico_2___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_2___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_2___relationships___paragraph__branches_features = 'relationships___field_ico_2___relationships___paragraph__branches_features',
  relationships___field_ico_2___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_2___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_2___relationships___paragraph__branches_testimonials = 'relationships___field_ico_2___relationships___paragraph__branches_testimonials',
  relationships___field_ico_2___relationships___paragraph__branches_discounts = 'relationships___field_ico_2___relationships___paragraph__branches_discounts',
  relationships___field_ico_2___relationships___node__news = 'relationships___field_ico_2___relationships___node__news',
  relationships___field_ico_2___relationships___node__branches = 'relationships___field_ico_2___relationships___node__branches',
  relationships___field_ico_2___relationships___paragraph__branches_hero = 'relationships___field_ico_2___relationships___paragraph__branches_hero',
  relationships___field_ico_2___relationships___paragraph__bullets = 'relationships___field_ico_2___relationships___paragraph__bullets',
  relationships___field_ico_2___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_2___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_2___relationships___paragraph__branches_device = 'relationships___field_ico_2___relationships___paragraph__branches_device',
  relationships___field_ico_2___relationships___paragraph__branches_recommendation = 'relationships___field_ico_2___relationships___paragraph__branches_recommendation',
  relationships___field_ico_2___relationships___site_setting_entity__accessories = 'relationships___field_ico_2___relationships___site_setting_entity__accessories',
  relationships___field_ico_2___relationships___site_setting_entity__branches = 'relationships___field_ico_2___relationships___site_setting_entity__branches',
  relationships___field_ico_2___relationships___site_setting_entity__about_us = 'relationships___field_ico_2___relationships___site_setting_entity__about_us',
  relationships___field_ico_2___relationships___paragraph__logos = 'relationships___field_ico_2___relationships___paragraph__logos',
  relationships___field_ico_2___relationships___paragraph__how_it_works = 'relationships___field_ico_2___relationships___paragraph__how_it_works',
  relationships___field_ico_2___relationships___paragraph__ico_and_text = 'relationships___field_ico_2___relationships___paragraph__ico_and_text',
  relationships___field_ico_2___relationships___paragraph__hero_1 = 'relationships___field_ico_2___relationships___paragraph__hero_1',
  relationships___field_ico_2___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_2___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_2___relationships___site_setting_entity__product = 'relationships___field_ico_2___relationships___site_setting_entity__product',
  relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_2___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_2___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_2___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_2___relationships___site_setting_entity__main_page = 'relationships___field_ico_2___relationships___site_setting_entity__main_page',
  relationships___field_ico_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_2___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_2___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_2___relationships___site_setting_entity__intercars = 'relationships___field_ico_2___relationships___site_setting_entity__intercars',
  relationships___field_ico_2___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_2___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_2___relationships___node__agreement = 'relationships___field_ico_2___relationships___node__agreement',
  relationships___field_ico_2___relationships___node__fiscalization = 'relationships___field_ico_2___relationships___node__fiscalization',
  relationships___field_ico_2___relationships___site_setting_entity__configurator = 'relationships___field_ico_2___relationships___site_setting_entity__configurator',
  relationships___field_ico_2___relationships___paragraph__social_link = 'relationships___field_ico_2___relationships___paragraph__social_link',
  relationships___field_ico_2___relationships___paragraph__make_an_appointment = 'relationships___field_ico_2___relationships___paragraph__make_an_appointment',
  relationships___field_ico_2___relationships___paragraph__linked_icons = 'relationships___field_ico_2___relationships___paragraph__linked_icons',
  relationships___field_ico_2___relationships___site_setting_entity__financing = 'relationships___field_ico_2___relationships___site_setting_entity__financing',
  relationships___field_ico_2___relationships___site_setting_entity__franchise = 'relationships___field_ico_2___relationships___site_setting_entity__franchise',
  relationships___field_ico_2___id = 'relationships___field_ico_2___id',
  relationships___field_ico_2___parent___id = 'relationships___field_ico_2___parent___id',
  relationships___field_ico_2___parent___children = 'relationships___field_ico_2___parent___children',
  relationships___field_ico_2___children = 'relationships___field_ico_2___children',
  relationships___field_ico_2___children___id = 'relationships___field_ico_2___children___id',
  relationships___field_ico_2___children___children = 'relationships___field_ico_2___children___children',
  relationships___field_ico_2___internal___content = 'relationships___field_ico_2___internal___content',
  relationships___field_ico_2___internal___contentDigest = 'relationships___field_ico_2___internal___contentDigest',
  relationships___field_ico_2___internal___description = 'relationships___field_ico_2___internal___description',
  relationships___field_ico_2___internal___fieldOwners = 'relationships___field_ico_2___internal___fieldOwners',
  relationships___field_ico_2___internal___ignoreType = 'relationships___field_ico_2___internal___ignoreType',
  relationships___field_ico_2___internal___mediaType = 'relationships___field_ico_2___internal___mediaType',
  relationships___field_ico_2___internal___owner = 'relationships___field_ico_2___internal___owner',
  relationships___field_ico_2___internal___type = 'relationships___field_ico_2___internal___type',
  relationships___field_ico_3___localFile___birthtime = 'relationships___field_ico_3___localFile___birthtime',
  relationships___field_ico_3___localFile___birthtimeMs = 'relationships___field_ico_3___localFile___birthtimeMs',
  relationships___field_ico_3___localFile___publicURL = 'relationships___field_ico_3___localFile___publicURL',
  relationships___field_ico_3___localFile___sourceInstanceName = 'relationships___field_ico_3___localFile___sourceInstanceName',
  relationships___field_ico_3___localFile___absolutePath = 'relationships___field_ico_3___localFile___absolutePath',
  relationships___field_ico_3___localFile___relativePath = 'relationships___field_ico_3___localFile___relativePath',
  relationships___field_ico_3___localFile___extension = 'relationships___field_ico_3___localFile___extension',
  relationships___field_ico_3___localFile___size = 'relationships___field_ico_3___localFile___size',
  relationships___field_ico_3___localFile___prettySize = 'relationships___field_ico_3___localFile___prettySize',
  relationships___field_ico_3___localFile___modifiedTime = 'relationships___field_ico_3___localFile___modifiedTime',
  relationships___field_ico_3___localFile___accessTime = 'relationships___field_ico_3___localFile___accessTime',
  relationships___field_ico_3___localFile___changeTime = 'relationships___field_ico_3___localFile___changeTime',
  relationships___field_ico_3___localFile___birthTime = 'relationships___field_ico_3___localFile___birthTime',
  relationships___field_ico_3___localFile___root = 'relationships___field_ico_3___localFile___root',
  relationships___field_ico_3___localFile___dir = 'relationships___field_ico_3___localFile___dir',
  relationships___field_ico_3___localFile___base = 'relationships___field_ico_3___localFile___base',
  relationships___field_ico_3___localFile___ext = 'relationships___field_ico_3___localFile___ext',
  relationships___field_ico_3___localFile___name = 'relationships___field_ico_3___localFile___name',
  relationships___field_ico_3___localFile___relativeDirectory = 'relationships___field_ico_3___localFile___relativeDirectory',
  relationships___field_ico_3___localFile___dev = 'relationships___field_ico_3___localFile___dev',
  relationships___field_ico_3___localFile___mode = 'relationships___field_ico_3___localFile___mode',
  relationships___field_ico_3___localFile___nlink = 'relationships___field_ico_3___localFile___nlink',
  relationships___field_ico_3___localFile___uid = 'relationships___field_ico_3___localFile___uid',
  relationships___field_ico_3___localFile___gid = 'relationships___field_ico_3___localFile___gid',
  relationships___field_ico_3___localFile___rdev = 'relationships___field_ico_3___localFile___rdev',
  relationships___field_ico_3___localFile___blksize = 'relationships___field_ico_3___localFile___blksize',
  relationships___field_ico_3___localFile___ino = 'relationships___field_ico_3___localFile___ino',
  relationships___field_ico_3___localFile___blocks = 'relationships___field_ico_3___localFile___blocks',
  relationships___field_ico_3___localFile___atimeMs = 'relationships___field_ico_3___localFile___atimeMs',
  relationships___field_ico_3___localFile___mtimeMs = 'relationships___field_ico_3___localFile___mtimeMs',
  relationships___field_ico_3___localFile___ctimeMs = 'relationships___field_ico_3___localFile___ctimeMs',
  relationships___field_ico_3___localFile___atime = 'relationships___field_ico_3___localFile___atime',
  relationships___field_ico_3___localFile___mtime = 'relationships___field_ico_3___localFile___mtime',
  relationships___field_ico_3___localFile___ctime = 'relationships___field_ico_3___localFile___ctime',
  relationships___field_ico_3___localFile___url = 'relationships___field_ico_3___localFile___url',
  relationships___field_ico_3___localFile___id = 'relationships___field_ico_3___localFile___id',
  relationships___field_ico_3___localFile___children = 'relationships___field_ico_3___localFile___children',
  relationships___field_ico_3___uri___url = 'relationships___field_ico_3___uri___url',
  relationships___field_ico_3___drupal_id = 'relationships___field_ico_3___drupal_id',
  relationships___field_ico_3___drupal_internal__fid = 'relationships___field_ico_3___drupal_internal__fid',
  relationships___field_ico_3___langcode = 'relationships___field_ico_3___langcode',
  relationships___field_ico_3___filename = 'relationships___field_ico_3___filename',
  relationships___field_ico_3___filemime = 'relationships___field_ico_3___filemime',
  relationships___field_ico_3___filesize = 'relationships___field_ico_3___filesize',
  relationships___field_ico_3___status = 'relationships___field_ico_3___status',
  relationships___field_ico_3___created = 'relationships___field_ico_3___created',
  relationships___field_ico_3___changed = 'relationships___field_ico_3___changed',
  relationships___field_ico_3___uuid = 'relationships___field_ico_3___uuid',
  relationships___field_ico_3___relationships___node__blog_post = 'relationships___field_ico_3___relationships___node__blog_post',
  relationships___field_ico_3___relationships___node__devices = 'relationships___field_ico_3___relationships___node__devices',
  relationships___field_ico_3___relationships___node__accessories = 'relationships___field_ico_3___relationships___node__accessories',
  relationships___field_ico_3___relationships___paragraph__news_image_title_text = 'relationships___field_ico_3___relationships___paragraph__news_image_title_text',
  relationships___field_ico_3___relationships___node__cases = 'relationships___field_ico_3___relationships___node__cases',
  relationships___field_ico_3___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_3___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_3___relationships___paragraph__branches_features = 'relationships___field_ico_3___relationships___paragraph__branches_features',
  relationships___field_ico_3___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_3___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_3___relationships___paragraph__branches_testimonials = 'relationships___field_ico_3___relationships___paragraph__branches_testimonials',
  relationships___field_ico_3___relationships___paragraph__branches_discounts = 'relationships___field_ico_3___relationships___paragraph__branches_discounts',
  relationships___field_ico_3___relationships___node__news = 'relationships___field_ico_3___relationships___node__news',
  relationships___field_ico_3___relationships___node__branches = 'relationships___field_ico_3___relationships___node__branches',
  relationships___field_ico_3___relationships___paragraph__branches_hero = 'relationships___field_ico_3___relationships___paragraph__branches_hero',
  relationships___field_ico_3___relationships___paragraph__bullets = 'relationships___field_ico_3___relationships___paragraph__bullets',
  relationships___field_ico_3___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_3___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_3___relationships___paragraph__branches_device = 'relationships___field_ico_3___relationships___paragraph__branches_device',
  relationships___field_ico_3___relationships___paragraph__branches_recommendation = 'relationships___field_ico_3___relationships___paragraph__branches_recommendation',
  relationships___field_ico_3___relationships___site_setting_entity__accessories = 'relationships___field_ico_3___relationships___site_setting_entity__accessories',
  relationships___field_ico_3___relationships___site_setting_entity__branches = 'relationships___field_ico_3___relationships___site_setting_entity__branches',
  relationships___field_ico_3___relationships___site_setting_entity__about_us = 'relationships___field_ico_3___relationships___site_setting_entity__about_us',
  relationships___field_ico_3___relationships___paragraph__logos = 'relationships___field_ico_3___relationships___paragraph__logos',
  relationships___field_ico_3___relationships___paragraph__how_it_works = 'relationships___field_ico_3___relationships___paragraph__how_it_works',
  relationships___field_ico_3___relationships___paragraph__ico_and_text = 'relationships___field_ico_3___relationships___paragraph__ico_and_text',
  relationships___field_ico_3___relationships___paragraph__hero_1 = 'relationships___field_ico_3___relationships___paragraph__hero_1',
  relationships___field_ico_3___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_3___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_3___relationships___site_setting_entity__product = 'relationships___field_ico_3___relationships___site_setting_entity__product',
  relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_3___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_3___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_3___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_3___relationships___site_setting_entity__main_page = 'relationships___field_ico_3___relationships___site_setting_entity__main_page',
  relationships___field_ico_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_3___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_3___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_3___relationships___site_setting_entity__intercars = 'relationships___field_ico_3___relationships___site_setting_entity__intercars',
  relationships___field_ico_3___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_3___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_3___relationships___node__agreement = 'relationships___field_ico_3___relationships___node__agreement',
  relationships___field_ico_3___relationships___node__fiscalization = 'relationships___field_ico_3___relationships___node__fiscalization',
  relationships___field_ico_3___relationships___site_setting_entity__configurator = 'relationships___field_ico_3___relationships___site_setting_entity__configurator',
  relationships___field_ico_3___relationships___paragraph__social_link = 'relationships___field_ico_3___relationships___paragraph__social_link',
  relationships___field_ico_3___relationships___paragraph__make_an_appointment = 'relationships___field_ico_3___relationships___paragraph__make_an_appointment',
  relationships___field_ico_3___relationships___paragraph__linked_icons = 'relationships___field_ico_3___relationships___paragraph__linked_icons',
  relationships___field_ico_3___relationships___site_setting_entity__financing = 'relationships___field_ico_3___relationships___site_setting_entity__financing',
  relationships___field_ico_3___relationships___site_setting_entity__franchise = 'relationships___field_ico_3___relationships___site_setting_entity__franchise',
  relationships___field_ico_3___id = 'relationships___field_ico_3___id',
  relationships___field_ico_3___parent___id = 'relationships___field_ico_3___parent___id',
  relationships___field_ico_3___parent___children = 'relationships___field_ico_3___parent___children',
  relationships___field_ico_3___children = 'relationships___field_ico_3___children',
  relationships___field_ico_3___children___id = 'relationships___field_ico_3___children___id',
  relationships___field_ico_3___children___children = 'relationships___field_ico_3___children___children',
  relationships___field_ico_3___internal___content = 'relationships___field_ico_3___internal___content',
  relationships___field_ico_3___internal___contentDigest = 'relationships___field_ico_3___internal___contentDigest',
  relationships___field_ico_3___internal___description = 'relationships___field_ico_3___internal___description',
  relationships___field_ico_3___internal___fieldOwners = 'relationships___field_ico_3___internal___fieldOwners',
  relationships___field_ico_3___internal___ignoreType = 'relationships___field_ico_3___internal___ignoreType',
  relationships___field_ico_3___internal___mediaType = 'relationships___field_ico_3___internal___mediaType',
  relationships___field_ico_3___internal___owner = 'relationships___field_ico_3___internal___owner',
  relationships___field_ico_3___internal___type = 'relationships___field_ico_3___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  relationships___node__pages = 'relationships___node__pages',
  relationships___node__pages___langcode = 'relationships___node__pages___langcode',
  relationships___node__pages___id = 'relationships___node__pages___id',
  relationships___node__pages___title = 'relationships___node__pages___title',
  relationships___node__pages___field_pages_text___value = 'relationships___node__pages___field_pages_text___value',
  relationships___node__pages___path___alias = 'relationships___node__pages___path___alias',
  relationships___node__pages___field_meta_title = 'relationships___node__pages___field_meta_title',
  relationships___node__pages___drupal_id = 'relationships___node__pages___drupal_id',
  relationships___node__pages___drupal_internal__nid = 'relationships___node__pages___drupal_internal__nid',
  relationships___node__pages___drupal_internal__vid = 'relationships___node__pages___drupal_internal__vid',
  relationships___node__pages___rh_action = 'relationships___node__pages___rh_action',
  relationships___node__pages___rh_redirect_response = 'relationships___node__pages___rh_redirect_response',
  relationships___node__pages___rh_redirect_fallback_action = 'relationships___node__pages___rh_redirect_fallback_action',
  relationships___node__pages___content_translation_source = 'relationships___node__pages___content_translation_source',
  relationships___node__pages___content_translation_outdated = 'relationships___node__pages___content_translation_outdated',
  relationships___node__pages___field_meta_description = 'relationships___node__pages___field_meta_description',
  relationships___node__pages___field_meta_keywords = 'relationships___node__pages___field_meta_keywords',
  relationships___node__pages___uuid = 'relationships___node__pages___uuid',
  relationships___node__pages___field_components = 'relationships___node__pages___field_components',
  relationships___node__pages___field_components___target_revision_id = 'relationships___node__pages___field_components___target_revision_id',
  relationships___node__pages___field_components___drupal_internal__target_id = 'relationships___node__pages___field_components___drupal_internal__target_id',
  relationships___node__pages___parent___id = 'relationships___node__pages___parent___id',
  relationships___node__pages___parent___children = 'relationships___node__pages___parent___children',
  relationships___node__pages___children = 'relationships___node__pages___children',
  relationships___node__pages___children___id = 'relationships___node__pages___children___id',
  relationships___node__pages___children___children = 'relationships___node__pages___children___children',
  relationships___node__pages___internal___content = 'relationships___node__pages___internal___content',
  relationships___node__pages___internal___contentDigest = 'relationships___node__pages___internal___contentDigest',
  relationships___node__pages___internal___description = 'relationships___node__pages___internal___description',
  relationships___node__pages___internal___fieldOwners = 'relationships___node__pages___internal___fieldOwners',
  relationships___node__pages___internal___ignoreType = 'relationships___node__pages___internal___ignoreType',
  relationships___node__pages___internal___mediaType = 'relationships___node__pages___internal___mediaType',
  relationships___node__pages___internal___owner = 'relationships___node__pages___internal___owner',
  relationships___node__pages___internal___type = 'relationships___node__pages___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_branches_image___alt = 'field_branches_image___alt',
  field_branches_image___title = 'field_branches_image___title',
  field_branches_image___width = 'field_branches_image___width',
  field_branches_image___height = 'field_branches_image___height',
  field_branches_image___drupal_internal__target_id = 'field_branches_image___drupal_internal__target_id',
  field_ico_1___alt = 'field_ico_1___alt',
  field_ico_1___title = 'field_ico_1___title',
  field_ico_1___width = 'field_ico_1___width',
  field_ico_1___height = 'field_ico_1___height',
  field_ico_1___drupal_internal__target_id = 'field_ico_1___drupal_internal__target_id',
  field_ico_2___alt = 'field_ico_2___alt',
  field_ico_2___title = 'field_ico_2___title',
  field_ico_2___width = 'field_ico_2___width',
  field_ico_2___height = 'field_ico_2___height',
  field_ico_2___drupal_internal__target_id = 'field_ico_2___drupal_internal__target_id',
  field_ico_3___alt = 'field_ico_3___alt',
  field_ico_3___title = 'field_ico_3___title',
  field_ico_3___width = 'field_ico_3___width',
  field_ico_3___height = 'field_ico_3___height',
  field_ico_3___drupal_internal__target_id = 'field_ico_3___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Branches_What_Is_IposFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  field_short_text_2?: Maybe<Field_Short_Text_2FilterInput>;
  field_short_text_3?: Maybe<Field_Short_Text_3FilterInput>;
  relationships?: Maybe<Paragraph__Branches_What_Is_IposRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Paragraph__Branches_What_Is_IposField_Branches_ImageFilterInput>;
  field_ico_1?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_1FilterInput>;
  field_ico_2?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_2FilterInput>;
  field_ico_3?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_3FilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Branches_What_Is_IposFilterListInput = {
  elemMatch?: Maybe<Paragraph__Branches_What_Is_IposFilterInput>;
};

export type Paragraph__Branches_What_Is_IposGroupConnection = {
  __typename?: 'paragraph__branches_what_is_iposGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Branches_What_Is_IposEdge>;
  nodes: Array<Paragraph__Branches_What_Is_Ipos>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Branches_What_Is_IposRelationships = {
  __typename?: 'paragraph__branches_what_is_iposRelationships';
  field_branches_image?: Maybe<File__File>;
  field_ico_1?: Maybe<File__File>;
  field_ico_2?: Maybe<File__File>;
  field_ico_3?: Maybe<File__File>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
  node__pages?: Maybe<Array<Maybe<Node__Pages>>>;
};

export type Paragraph__Branches_What_Is_IposRelationshipsFilterInput = {
  field_branches_image?: Maybe<File__FileFilterInput>;
  field_ico_1?: Maybe<File__FileFilterInput>;
  field_ico_2?: Maybe<File__FileFilterInput>;
  field_ico_3?: Maybe<File__FileFilterInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
  node__pages?: Maybe<Node__PagesFilterListInput>;
};

export type Paragraph__Branches_What_Is_IposSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Branches_What_Is_IposFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Bullets = Node & {
  __typename?: 'paragraph__bullets';
  internal: Internal;
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_btn_url?: Maybe<Scalars['String']>;
  field_text?: Maybe<Field_Text>;
  field_video?: Maybe<Scalars['String']>;
  field_image?: Maybe<Field_Image>;
  field_bullets_icons?: Maybe<Array<Maybe<Field_Bullets_Icons>>>;
  relationships?: Maybe<Paragraph__BulletsRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__BulletsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__BulletsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Bullets_Container = Node & {
  __typename?: 'paragraph__bullets_container';
  internal: Internal;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Bullets_ContainerRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_bullets_item?: Maybe<Array<Maybe<Paragraph__Bullets_ContainerField_Bullets_Item>>>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Bullets_ContainerCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Bullets_ContainerContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Bullets_ContainerConnection = {
  __typename?: 'paragraph__bullets_containerConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Bullets_ContainerEdge>;
  nodes: Array<Paragraph__Bullets_Container>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Bullets_ContainerGroupConnection>;
};

export type Paragraph__Bullets_ContainerConnectionDistinctArgs = {
  field: Paragraph__Bullets_ContainerFieldsEnum;
};

export type Paragraph__Bullets_ContainerConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Bullets_ContainerFieldsEnum;
};

export type Paragraph__Bullets_ContainerEdge = {
  __typename?: 'paragraph__bullets_containerEdge';
  next?: Maybe<Paragraph__Bullets_Container>;
  node: Paragraph__Bullets_Container;
  previous?: Maybe<Paragraph__Bullets_Container>;
};

export type Paragraph__Bullets_ContainerField_Bullets_Item = {
  __typename?: 'paragraph__bullets_containerField_bullets_item';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Bullets_ContainerField_Bullets_ItemFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Bullets_ContainerField_Bullets_ItemFilterListInput = {
  elemMatch?: Maybe<Paragraph__Bullets_ContainerField_Bullets_ItemFilterInput>;
};

export enum Paragraph__Bullets_ContainerFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  drupal_id = 'drupal_id',
  relationships___field_bullets_item = 'relationships___field_bullets_item',
  relationships___field_bullets_item___internal___content = 'relationships___field_bullets_item___internal___content',
  relationships___field_bullets_item___internal___contentDigest = 'relationships___field_bullets_item___internal___contentDigest',
  relationships___field_bullets_item___internal___description = 'relationships___field_bullets_item___internal___description',
  relationships___field_bullets_item___internal___fieldOwners = 'relationships___field_bullets_item___internal___fieldOwners',
  relationships___field_bullets_item___internal___ignoreType = 'relationships___field_bullets_item___internal___ignoreType',
  relationships___field_bullets_item___internal___mediaType = 'relationships___field_bullets_item___internal___mediaType',
  relationships___field_bullets_item___internal___owner = 'relationships___field_bullets_item___internal___owner',
  relationships___field_bullets_item___internal___type = 'relationships___field_bullets_item___internal___type',
  relationships___field_bullets_item___field_nc_button_text = 'relationships___field_bullets_item___field_nc_button_text',
  relationships___field_bullets_item___field_btn_url = 'relationships___field_bullets_item___field_btn_url',
  relationships___field_bullets_item___field_text___value = 'relationships___field_bullets_item___field_text___value',
  relationships___field_bullets_item___field_video = 'relationships___field_bullets_item___field_video',
  relationships___field_bullets_item___field_image___alt = 'relationships___field_bullets_item___field_image___alt',
  relationships___field_bullets_item___field_bullets_icons = 'relationships___field_bullets_item___field_bullets_icons',
  relationships___field_bullets_item___field_bullets_icons___alt = 'relationships___field_bullets_item___field_bullets_icons___alt',
  relationships___field_bullets_item___relationships___field_bullets_icons = 'relationships___field_bullets_item___relationships___field_bullets_icons',
  relationships___field_bullets_item___relationships___paragraph__bullets_container = 'relationships___field_bullets_item___relationships___paragraph__bullets_container',
  relationships___field_bullets_item___relationships___site_setting_entity__accessories = 'relationships___field_bullets_item___relationships___site_setting_entity__accessories',
  relationships___field_bullets_item___drupal_id = 'relationships___field_bullets_item___drupal_id',
  relationships___field_bullets_item___drupal_internal__id = 'relationships___field_bullets_item___drupal_internal__id',
  relationships___field_bullets_item___status = 'relationships___field_bullets_item___status',
  relationships___field_bullets_item___created = 'relationships___field_bullets_item___created',
  relationships___field_bullets_item___parent_id = 'relationships___field_bullets_item___parent_id',
  relationships___field_bullets_item___parent_type = 'relationships___field_bullets_item___parent_type',
  relationships___field_bullets_item___parent_field_name = 'relationships___field_bullets_item___parent_field_name',
  relationships___field_bullets_item___content_translation_source = 'relationships___field_bullets_item___content_translation_source',
  relationships___field_bullets_item___content_translation_outdated = 'relationships___field_bullets_item___content_translation_outdated',
  relationships___field_bullets_item___content_translation_changed = 'relationships___field_bullets_item___content_translation_changed',
  relationships___field_bullets_item___langcode = 'relationships___field_bullets_item___langcode',
  relationships___field_bullets_item___uuid = 'relationships___field_bullets_item___uuid',
  relationships___field_bullets_item___id = 'relationships___field_bullets_item___id',
  relationships___field_bullets_item___parent___id = 'relationships___field_bullets_item___parent___id',
  relationships___field_bullets_item___parent___children = 'relationships___field_bullets_item___parent___children',
  relationships___field_bullets_item___children = 'relationships___field_bullets_item___children',
  relationships___field_bullets_item___children___id = 'relationships___field_bullets_item___children___id',
  relationships___field_bullets_item___children___children = 'relationships___field_bullets_item___children___children',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  relationships___site_setting_entity__about_us = 'relationships___site_setting_entity__about_us',
  relationships___site_setting_entity__about_us___langcode = 'relationships___site_setting_entity__about_us___langcode',
  relationships___site_setting_entity__about_us___field_meta_description = 'relationships___site_setting_entity__about_us___field_meta_description',
  relationships___site_setting_entity__about_us___field_meta_title = 'relationships___site_setting_entity__about_us___field_meta_title',
  relationships___site_setting_entity__about_us___field_btn_au_text_1 = 'relationships___site_setting_entity__about_us___field_btn_au_text_1',
  relationships___site_setting_entity__about_us___field_btn_au_url_1 = 'relationships___site_setting_entity__about_us___field_btn_au_url_1',
  relationships___site_setting_entity__about_us___field_info_text___value = 'relationships___site_setting_entity__about_us___field_info_text___value',
  relationships___site_setting_entity__about_us___field_hero_image___alt = 'relationships___site_setting_entity__about_us___field_hero_image___alt',
  relationships___site_setting_entity__about_us___field_hero_image_mobile___alt = 'relationships___site_setting_entity__about_us___field_hero_image_mobile___alt',
  relationships___site_setting_entity__about_us___field_hero_text___value = 'relationships___site_setting_entity__about_us___field_hero_text___value',
  relationships___site_setting_entity__about_us___field_hero_headline___value = 'relationships___site_setting_entity__about_us___field_hero_headline___value',
  relationships___site_setting_entity__about_us___field_pl_header_1___value = 'relationships___site_setting_entity__about_us___field_pl_header_1___value',
  relationships___site_setting_entity__about_us___relationships___field_components = 'relationships___site_setting_entity__about_us___relationships___field_components',
  relationships___site_setting_entity__about_us___relationships___field_member_of_the_board_paragr = 'relationships___site_setting_entity__about_us___relationships___field_member_of_the_board_paragr',
  relationships___site_setting_entity__about_us___drupal_id = 'relationships___site_setting_entity__about_us___drupal_id',
  relationships___site_setting_entity__about_us___drupal_internal__id = 'relationships___site_setting_entity__about_us___drupal_internal__id',
  relationships___site_setting_entity__about_us___name = 'relationships___site_setting_entity__about_us___name',
  relationships___site_setting_entity__about_us___fieldset = 'relationships___site_setting_entity__about_us___fieldset',
  relationships___site_setting_entity__about_us___status = 'relationships___site_setting_entity__about_us___status',
  relationships___site_setting_entity__about_us___created = 'relationships___site_setting_entity__about_us___created',
  relationships___site_setting_entity__about_us___changed = 'relationships___site_setting_entity__about_us___changed',
  relationships___site_setting_entity__about_us___default_langcode = 'relationships___site_setting_entity__about_us___default_langcode',
  relationships___site_setting_entity__about_us___content_translation_source = 'relationships___site_setting_entity__about_us___content_translation_source',
  relationships___site_setting_entity__about_us___content_translation_outdated = 'relationships___site_setting_entity__about_us___content_translation_outdated',
  relationships___site_setting_entity__about_us___content_translation_status = 'relationships___site_setting_entity__about_us___content_translation_status',
  relationships___site_setting_entity__about_us___content_translation_created = 'relationships___site_setting_entity__about_us___content_translation_created',
  relationships___site_setting_entity__about_us___content_translation_changed = 'relationships___site_setting_entity__about_us___content_translation_changed',
  relationships___site_setting_entity__about_us___uuid = 'relationships___site_setting_entity__about_us___uuid',
  relationships___site_setting_entity__about_us___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___field_components = 'relationships___site_setting_entity__about_us___field_components',
  relationships___site_setting_entity__about_us___field_components___target_revision_id = 'relationships___site_setting_entity__about_us___field_components___target_revision_id',
  relationships___site_setting_entity__about_us___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___target_revision_id = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___target_revision_id',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___id = 'relationships___site_setting_entity__about_us___id',
  relationships___site_setting_entity__about_us___parent___id = 'relationships___site_setting_entity__about_us___parent___id',
  relationships___site_setting_entity__about_us___parent___children = 'relationships___site_setting_entity__about_us___parent___children',
  relationships___site_setting_entity__about_us___children = 'relationships___site_setting_entity__about_us___children',
  relationships___site_setting_entity__about_us___children___id = 'relationships___site_setting_entity__about_us___children___id',
  relationships___site_setting_entity__about_us___children___children = 'relationships___site_setting_entity__about_us___children___children',
  relationships___site_setting_entity__about_us___internal___content = 'relationships___site_setting_entity__about_us___internal___content',
  relationships___site_setting_entity__about_us___internal___contentDigest = 'relationships___site_setting_entity__about_us___internal___contentDigest',
  relationships___site_setting_entity__about_us___internal___description = 'relationships___site_setting_entity__about_us___internal___description',
  relationships___site_setting_entity__about_us___internal___fieldOwners = 'relationships___site_setting_entity__about_us___internal___fieldOwners',
  relationships___site_setting_entity__about_us___internal___ignoreType = 'relationships___site_setting_entity__about_us___internal___ignoreType',
  relationships___site_setting_entity__about_us___internal___mediaType = 'relationships___site_setting_entity__about_us___internal___mediaType',
  relationships___site_setting_entity__about_us___internal___owner = 'relationships___site_setting_entity__about_us___internal___owner',
  relationships___site_setting_entity__about_us___internal___type = 'relationships___site_setting_entity__about_us___internal___type',
  relationships___site_setting_entity__financing = 'relationships___site_setting_entity__financing',
  relationships___site_setting_entity__financing___langcode = 'relationships___site_setting_entity__financing___langcode',
  relationships___site_setting_entity__financing___field_meta_description = 'relationships___site_setting_entity__financing___field_meta_description',
  relationships___site_setting_entity__financing___field_meta_title = 'relationships___site_setting_entity__financing___field_meta_title',
  relationships___site_setting_entity__financing___drupal_id = 'relationships___site_setting_entity__financing___drupal_id',
  relationships___site_setting_entity__financing___drupal_internal__id = 'relationships___site_setting_entity__financing___drupal_internal__id',
  relationships___site_setting_entity__financing___name = 'relationships___site_setting_entity__financing___name',
  relationships___site_setting_entity__financing___fieldset = 'relationships___site_setting_entity__financing___fieldset',
  relationships___site_setting_entity__financing___status = 'relationships___site_setting_entity__financing___status',
  relationships___site_setting_entity__financing___created = 'relationships___site_setting_entity__financing___created',
  relationships___site_setting_entity__financing___changed = 'relationships___site_setting_entity__financing___changed',
  relationships___site_setting_entity__financing___default_langcode = 'relationships___site_setting_entity__financing___default_langcode',
  relationships___site_setting_entity__financing___content_translation_source = 'relationships___site_setting_entity__financing___content_translation_source',
  relationships___site_setting_entity__financing___content_translation_outdated = 'relationships___site_setting_entity__financing___content_translation_outdated',
  relationships___site_setting_entity__financing___content_translation_status = 'relationships___site_setting_entity__financing___content_translation_status',
  relationships___site_setting_entity__financing___content_translation_created = 'relationships___site_setting_entity__financing___content_translation_created',
  relationships___site_setting_entity__financing___content_translation_changed = 'relationships___site_setting_entity__financing___content_translation_changed',
  relationships___site_setting_entity__financing___uuid = 'relationships___site_setting_entity__financing___uuid',
  relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_components = 'relationships___site_setting_entity__financing___field_components',
  relationships___site_setting_entity__financing___field_components___target_revision_id = 'relationships___site_setting_entity__financing___field_components___target_revision_id',
  relationships___site_setting_entity__financing___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_main_image___alt = 'relationships___site_setting_entity__financing___field_main_image___alt',
  relationships___site_setting_entity__financing___field_main_image___title = 'relationships___site_setting_entity__financing___field_main_image___title',
  relationships___site_setting_entity__financing___field_main_image___width = 'relationships___site_setting_entity__financing___field_main_image___width',
  relationships___site_setting_entity__financing___field_main_image___height = 'relationships___site_setting_entity__financing___field_main_image___height',
  relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__financing___id = 'relationships___site_setting_entity__financing___id',
  relationships___site_setting_entity__financing___parent___id = 'relationships___site_setting_entity__financing___parent___id',
  relationships___site_setting_entity__financing___parent___children = 'relationships___site_setting_entity__financing___parent___children',
  relationships___site_setting_entity__financing___children = 'relationships___site_setting_entity__financing___children',
  relationships___site_setting_entity__financing___children___id = 'relationships___site_setting_entity__financing___children___id',
  relationships___site_setting_entity__financing___children___children = 'relationships___site_setting_entity__financing___children___children',
  relationships___site_setting_entity__financing___internal___content = 'relationships___site_setting_entity__financing___internal___content',
  relationships___site_setting_entity__financing___internal___contentDigest = 'relationships___site_setting_entity__financing___internal___contentDigest',
  relationships___site_setting_entity__financing___internal___description = 'relationships___site_setting_entity__financing___internal___description',
  relationships___site_setting_entity__financing___internal___fieldOwners = 'relationships___site_setting_entity__financing___internal___fieldOwners',
  relationships___site_setting_entity__financing___internal___ignoreType = 'relationships___site_setting_entity__financing___internal___ignoreType',
  relationships___site_setting_entity__financing___internal___mediaType = 'relationships___site_setting_entity__financing___internal___mediaType',
  relationships___site_setting_entity__financing___internal___owner = 'relationships___site_setting_entity__financing___internal___owner',
  relationships___site_setting_entity__financing___internal___type = 'relationships___site_setting_entity__financing___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  relationships___site_setting_entity__main_page = 'relationships___site_setting_entity__main_page',
  relationships___site_setting_entity__main_page___langcode = 'relationships___site_setting_entity__main_page___langcode',
  relationships___site_setting_entity__main_page___field_mp_text_footer___value = 'relationships___site_setting_entity__main_page___field_mp_text_footer___value',
  relationships___site_setting_entity__main_page___field_mp_text___value = 'relationships___site_setting_entity__main_page___field_mp_text___value',
  relationships___site_setting_entity__main_page___field_mp_button_text = 'relationships___site_setting_entity__main_page___field_mp_button_text',
  relationships___site_setting_entity__main_page___field_meta_description = 'relationships___site_setting_entity__main_page___field_meta_description',
  relationships___site_setting_entity__main_page___field_meta_title = 'relationships___site_setting_entity__main_page___field_meta_title',
  relationships___site_setting_entity__main_page___field_btn_url = 'relationships___site_setting_entity__main_page___field_btn_url',
  relationships___site_setting_entity__main_page___field_settings_button_text = 'relationships___site_setting_entity__main_page___field_settings_button_text',
  relationships___site_setting_entity__main_page___field_mp_image_footer___alt = 'relationships___site_setting_entity__main_page___field_mp_image_footer___alt',
  relationships___site_setting_entity__main_page___field_mp_image___alt = 'relationships___site_setting_entity__main_page___field_mp_image___alt',
  relationships___site_setting_entity__main_page___drupal_id = 'relationships___site_setting_entity__main_page___drupal_id',
  relationships___site_setting_entity__main_page___drupal_internal__id = 'relationships___site_setting_entity__main_page___drupal_internal__id',
  relationships___site_setting_entity__main_page___name = 'relationships___site_setting_entity__main_page___name',
  relationships___site_setting_entity__main_page___fieldset = 'relationships___site_setting_entity__main_page___fieldset',
  relationships___site_setting_entity__main_page___status = 'relationships___site_setting_entity__main_page___status',
  relationships___site_setting_entity__main_page___created = 'relationships___site_setting_entity__main_page___created',
  relationships___site_setting_entity__main_page___changed = 'relationships___site_setting_entity__main_page___changed',
  relationships___site_setting_entity__main_page___default_langcode = 'relationships___site_setting_entity__main_page___default_langcode',
  relationships___site_setting_entity__main_page___content_translation_source = 'relationships___site_setting_entity__main_page___content_translation_source',
  relationships___site_setting_entity__main_page___content_translation_outdated = 'relationships___site_setting_entity__main_page___content_translation_outdated',
  relationships___site_setting_entity__main_page___content_translation_status = 'relationships___site_setting_entity__main_page___content_translation_status',
  relationships___site_setting_entity__main_page___content_translation_created = 'relationships___site_setting_entity__main_page___content_translation_created',
  relationships___site_setting_entity__main_page___content_translation_changed = 'relationships___site_setting_entity__main_page___content_translation_changed',
  relationships___site_setting_entity__main_page___uuid = 'relationships___site_setting_entity__main_page___uuid',
  relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___field_components = 'relationships___site_setting_entity__main_page___field_components',
  relationships___site_setting_entity__main_page___field_components___target_revision_id = 'relationships___site_setting_entity__main_page___field_components___target_revision_id',
  relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___id = 'relationships___site_setting_entity__main_page___id',
  relationships___site_setting_entity__main_page___parent___id = 'relationships___site_setting_entity__main_page___parent___id',
  relationships___site_setting_entity__main_page___parent___children = 'relationships___site_setting_entity__main_page___parent___children',
  relationships___site_setting_entity__main_page___children = 'relationships___site_setting_entity__main_page___children',
  relationships___site_setting_entity__main_page___children___id = 'relationships___site_setting_entity__main_page___children___id',
  relationships___site_setting_entity__main_page___children___children = 'relationships___site_setting_entity__main_page___children___children',
  relationships___site_setting_entity__main_page___internal___content = 'relationships___site_setting_entity__main_page___internal___content',
  relationships___site_setting_entity__main_page___internal___contentDigest = 'relationships___site_setting_entity__main_page___internal___contentDigest',
  relationships___site_setting_entity__main_page___internal___description = 'relationships___site_setting_entity__main_page___internal___description',
  relationships___site_setting_entity__main_page___internal___fieldOwners = 'relationships___site_setting_entity__main_page___internal___fieldOwners',
  relationships___site_setting_entity__main_page___internal___ignoreType = 'relationships___site_setting_entity__main_page___internal___ignoreType',
  relationships___site_setting_entity__main_page___internal___mediaType = 'relationships___site_setting_entity__main_page___internal___mediaType',
  relationships___site_setting_entity__main_page___internal___owner = 'relationships___site_setting_entity__main_page___internal___owner',
  relationships___site_setting_entity__main_page___internal___type = 'relationships___site_setting_entity__main_page___internal___type',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  relationships___node__pages = 'relationships___node__pages',
  relationships___node__pages___langcode = 'relationships___node__pages___langcode',
  relationships___node__pages___id = 'relationships___node__pages___id',
  relationships___node__pages___title = 'relationships___node__pages___title',
  relationships___node__pages___field_pages_text___value = 'relationships___node__pages___field_pages_text___value',
  relationships___node__pages___path___alias = 'relationships___node__pages___path___alias',
  relationships___node__pages___field_meta_title = 'relationships___node__pages___field_meta_title',
  relationships___node__pages___drupal_id = 'relationships___node__pages___drupal_id',
  relationships___node__pages___drupal_internal__nid = 'relationships___node__pages___drupal_internal__nid',
  relationships___node__pages___drupal_internal__vid = 'relationships___node__pages___drupal_internal__vid',
  relationships___node__pages___rh_action = 'relationships___node__pages___rh_action',
  relationships___node__pages___rh_redirect_response = 'relationships___node__pages___rh_redirect_response',
  relationships___node__pages___rh_redirect_fallback_action = 'relationships___node__pages___rh_redirect_fallback_action',
  relationships___node__pages___content_translation_source = 'relationships___node__pages___content_translation_source',
  relationships___node__pages___content_translation_outdated = 'relationships___node__pages___content_translation_outdated',
  relationships___node__pages___field_meta_description = 'relationships___node__pages___field_meta_description',
  relationships___node__pages___field_meta_keywords = 'relationships___node__pages___field_meta_keywords',
  relationships___node__pages___uuid = 'relationships___node__pages___uuid',
  relationships___node__pages___field_components = 'relationships___node__pages___field_components',
  relationships___node__pages___field_components___target_revision_id = 'relationships___node__pages___field_components___target_revision_id',
  relationships___node__pages___field_components___drupal_internal__target_id = 'relationships___node__pages___field_components___drupal_internal__target_id',
  relationships___node__pages___parent___id = 'relationships___node__pages___parent___id',
  relationships___node__pages___parent___children = 'relationships___node__pages___parent___children',
  relationships___node__pages___children = 'relationships___node__pages___children',
  relationships___node__pages___children___id = 'relationships___node__pages___children___id',
  relationships___node__pages___children___children = 'relationships___node__pages___children___children',
  relationships___node__pages___internal___content = 'relationships___node__pages___internal___content',
  relationships___node__pages___internal___contentDigest = 'relationships___node__pages___internal___contentDigest',
  relationships___node__pages___internal___description = 'relationships___node__pages___internal___description',
  relationships___node__pages___internal___fieldOwners = 'relationships___node__pages___internal___fieldOwners',
  relationships___node__pages___internal___ignoreType = 'relationships___node__pages___internal___ignoreType',
  relationships___node__pages___internal___mediaType = 'relationships___node__pages___internal___mediaType',
  relationships___node__pages___internal___owner = 'relationships___node__pages___internal___owner',
  relationships___node__pages___internal___type = 'relationships___node__pages___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_bullets_item = 'field_bullets_item',
  field_bullets_item___target_revision_id = 'field_bullets_item___target_revision_id',
  field_bullets_item___drupal_internal__target_id = 'field_bullets_item___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Bullets_ContainerFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Bullets_ContainerRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_bullets_item?: Maybe<Paragraph__Bullets_ContainerField_Bullets_ItemFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Bullets_ContainerFilterListInput = {
  elemMatch?: Maybe<Paragraph__Bullets_ContainerFilterInput>;
};

export type Paragraph__Bullets_ContainerGroupConnection = {
  __typename?: 'paragraph__bullets_containerGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Bullets_ContainerEdge>;
  nodes: Array<Paragraph__Bullets_Container>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Bullets_ContainerRelationships = {
  __typename?: 'paragraph__bullets_containerRelationships';
  field_bullets_item?: Maybe<Array<Maybe<Paragraph__Bullets>>>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
  site_setting_entity__about_us?: Maybe<Array<Maybe<Site_Setting_Entity__About_Us>>>;
  site_setting_entity__financing?: Maybe<Array<Maybe<Site_Setting_Entity__Financing>>>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
  site_setting_entity__main_page?: Maybe<Array<Maybe<Site_Setting_Entity__Main_Page>>>;
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
  node__pages?: Maybe<Array<Maybe<Node__Pages>>>;
};

export type Paragraph__Bullets_ContainerRelationshipsFilterInput = {
  field_bullets_item?: Maybe<Paragraph__BulletsFilterListInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
  site_setting_entity__about_us?: Maybe<Site_Setting_Entity__About_UsFilterListInput>;
  site_setting_entity__financing?: Maybe<Site_Setting_Entity__FinancingFilterListInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
  site_setting_entity__main_page?: Maybe<Site_Setting_Entity__Main_PageFilterListInput>;
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
  node__pages?: Maybe<Node__PagesFilterListInput>;
};

export type Paragraph__Bullets_ContainerSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Bullets_ContainerFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__BulletsConnection = {
  __typename?: 'paragraph__bulletsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__BulletsEdge>;
  nodes: Array<Paragraph__Bullets>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__BulletsGroupConnection>;
};

export type Paragraph__BulletsConnectionDistinctArgs = {
  field: Paragraph__BulletsFieldsEnum;
};

export type Paragraph__BulletsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__BulletsFieldsEnum;
};

export type Paragraph__BulletsEdge = {
  __typename?: 'paragraph__bulletsEdge';
  next?: Maybe<Paragraph__Bullets>;
  node: Paragraph__Bullets;
  previous?: Maybe<Paragraph__Bullets>;
};

export enum Paragraph__BulletsFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  field_nc_button_text = 'field_nc_button_text',
  field_btn_url = 'field_btn_url',
  field_text___value = 'field_text___value',
  field_video = 'field_video',
  field_image___alt = 'field_image___alt',
  field_bullets_icons = 'field_bullets_icons',
  field_bullets_icons___alt = 'field_bullets_icons___alt',
  relationships___field_image___localFile___birthtime = 'relationships___field_image___localFile___birthtime',
  relationships___field_image___localFile___birthtimeMs = 'relationships___field_image___localFile___birthtimeMs',
  relationships___field_image___localFile___publicURL = 'relationships___field_image___localFile___publicURL',
  relationships___field_image___localFile___sourceInstanceName = 'relationships___field_image___localFile___sourceInstanceName',
  relationships___field_image___localFile___absolutePath = 'relationships___field_image___localFile___absolutePath',
  relationships___field_image___localFile___relativePath = 'relationships___field_image___localFile___relativePath',
  relationships___field_image___localFile___extension = 'relationships___field_image___localFile___extension',
  relationships___field_image___localFile___size = 'relationships___field_image___localFile___size',
  relationships___field_image___localFile___prettySize = 'relationships___field_image___localFile___prettySize',
  relationships___field_image___localFile___modifiedTime = 'relationships___field_image___localFile___modifiedTime',
  relationships___field_image___localFile___accessTime = 'relationships___field_image___localFile___accessTime',
  relationships___field_image___localFile___changeTime = 'relationships___field_image___localFile___changeTime',
  relationships___field_image___localFile___birthTime = 'relationships___field_image___localFile___birthTime',
  relationships___field_image___localFile___root = 'relationships___field_image___localFile___root',
  relationships___field_image___localFile___dir = 'relationships___field_image___localFile___dir',
  relationships___field_image___localFile___base = 'relationships___field_image___localFile___base',
  relationships___field_image___localFile___ext = 'relationships___field_image___localFile___ext',
  relationships___field_image___localFile___name = 'relationships___field_image___localFile___name',
  relationships___field_image___localFile___relativeDirectory = 'relationships___field_image___localFile___relativeDirectory',
  relationships___field_image___localFile___dev = 'relationships___field_image___localFile___dev',
  relationships___field_image___localFile___mode = 'relationships___field_image___localFile___mode',
  relationships___field_image___localFile___nlink = 'relationships___field_image___localFile___nlink',
  relationships___field_image___localFile___uid = 'relationships___field_image___localFile___uid',
  relationships___field_image___localFile___gid = 'relationships___field_image___localFile___gid',
  relationships___field_image___localFile___rdev = 'relationships___field_image___localFile___rdev',
  relationships___field_image___localFile___blksize = 'relationships___field_image___localFile___blksize',
  relationships___field_image___localFile___ino = 'relationships___field_image___localFile___ino',
  relationships___field_image___localFile___blocks = 'relationships___field_image___localFile___blocks',
  relationships___field_image___localFile___atimeMs = 'relationships___field_image___localFile___atimeMs',
  relationships___field_image___localFile___mtimeMs = 'relationships___field_image___localFile___mtimeMs',
  relationships___field_image___localFile___ctimeMs = 'relationships___field_image___localFile___ctimeMs',
  relationships___field_image___localFile___atime = 'relationships___field_image___localFile___atime',
  relationships___field_image___localFile___mtime = 'relationships___field_image___localFile___mtime',
  relationships___field_image___localFile___ctime = 'relationships___field_image___localFile___ctime',
  relationships___field_image___localFile___url = 'relationships___field_image___localFile___url',
  relationships___field_image___localFile___id = 'relationships___field_image___localFile___id',
  relationships___field_image___localFile___children = 'relationships___field_image___localFile___children',
  relationships___field_image___uri___url = 'relationships___field_image___uri___url',
  relationships___field_image___drupal_id = 'relationships___field_image___drupal_id',
  relationships___field_image___drupal_internal__fid = 'relationships___field_image___drupal_internal__fid',
  relationships___field_image___langcode = 'relationships___field_image___langcode',
  relationships___field_image___filename = 'relationships___field_image___filename',
  relationships___field_image___filemime = 'relationships___field_image___filemime',
  relationships___field_image___filesize = 'relationships___field_image___filesize',
  relationships___field_image___status = 'relationships___field_image___status',
  relationships___field_image___created = 'relationships___field_image___created',
  relationships___field_image___changed = 'relationships___field_image___changed',
  relationships___field_image___uuid = 'relationships___field_image___uuid',
  relationships___field_image___relationships___node__blog_post = 'relationships___field_image___relationships___node__blog_post',
  relationships___field_image___relationships___node__devices = 'relationships___field_image___relationships___node__devices',
  relationships___field_image___relationships___node__accessories = 'relationships___field_image___relationships___node__accessories',
  relationships___field_image___relationships___paragraph__news_image_title_text = 'relationships___field_image___relationships___paragraph__news_image_title_text',
  relationships___field_image___relationships___node__cases = 'relationships___field_image___relationships___node__cases',
  relationships___field_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image___relationships___paragraph__branches_features = 'relationships___field_image___relationships___paragraph__branches_features',
  relationships___field_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image___relationships___paragraph__branches_testimonials = 'relationships___field_image___relationships___paragraph__branches_testimonials',
  relationships___field_image___relationships___paragraph__branches_discounts = 'relationships___field_image___relationships___paragraph__branches_discounts',
  relationships___field_image___relationships___node__news = 'relationships___field_image___relationships___node__news',
  relationships___field_image___relationships___node__branches = 'relationships___field_image___relationships___node__branches',
  relationships___field_image___relationships___paragraph__branches_hero = 'relationships___field_image___relationships___paragraph__branches_hero',
  relationships___field_image___relationships___paragraph__bullets = 'relationships___field_image___relationships___paragraph__bullets',
  relationships___field_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_image___relationships___paragraph__branches_device = 'relationships___field_image___relationships___paragraph__branches_device',
  relationships___field_image___relationships___paragraph__branches_recommendation = 'relationships___field_image___relationships___paragraph__branches_recommendation',
  relationships___field_image___relationships___site_setting_entity__accessories = 'relationships___field_image___relationships___site_setting_entity__accessories',
  relationships___field_image___relationships___site_setting_entity__branches = 'relationships___field_image___relationships___site_setting_entity__branches',
  relationships___field_image___relationships___site_setting_entity__about_us = 'relationships___field_image___relationships___site_setting_entity__about_us',
  relationships___field_image___relationships___paragraph__logos = 'relationships___field_image___relationships___paragraph__logos',
  relationships___field_image___relationships___paragraph__how_it_works = 'relationships___field_image___relationships___paragraph__how_it_works',
  relationships___field_image___relationships___paragraph__ico_and_text = 'relationships___field_image___relationships___paragraph__ico_and_text',
  relationships___field_image___relationships___paragraph__hero_1 = 'relationships___field_image___relationships___paragraph__hero_1',
  relationships___field_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image___relationships___site_setting_entity__product = 'relationships___field_image___relationships___site_setting_entity__product',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image___relationships___paragraph__porownanie_terminali = 'relationships___field_image___relationships___paragraph__porownanie_terminali',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image___relationships___site_setting_entity__main_page = 'relationships___field_image___relationships___site_setting_entity__main_page',
  relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image___relationships___site_setting_entity__intercars = 'relationships___field_image___relationships___site_setting_entity__intercars',
  relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image___relationships___node__agreement = 'relationships___field_image___relationships___node__agreement',
  relationships___field_image___relationships___node__fiscalization = 'relationships___field_image___relationships___node__fiscalization',
  relationships___field_image___relationships___site_setting_entity__configurator = 'relationships___field_image___relationships___site_setting_entity__configurator',
  relationships___field_image___relationships___paragraph__social_link = 'relationships___field_image___relationships___paragraph__social_link',
  relationships___field_image___relationships___paragraph__make_an_appointment = 'relationships___field_image___relationships___paragraph__make_an_appointment',
  relationships___field_image___relationships___paragraph__linked_icons = 'relationships___field_image___relationships___paragraph__linked_icons',
  relationships___field_image___relationships___site_setting_entity__financing = 'relationships___field_image___relationships___site_setting_entity__financing',
  relationships___field_image___relationships___site_setting_entity__franchise = 'relationships___field_image___relationships___site_setting_entity__franchise',
  relationships___field_image___id = 'relationships___field_image___id',
  relationships___field_image___parent___id = 'relationships___field_image___parent___id',
  relationships___field_image___parent___children = 'relationships___field_image___parent___children',
  relationships___field_image___children = 'relationships___field_image___children',
  relationships___field_image___children___id = 'relationships___field_image___children___id',
  relationships___field_image___children___children = 'relationships___field_image___children___children',
  relationships___field_image___internal___content = 'relationships___field_image___internal___content',
  relationships___field_image___internal___contentDigest = 'relationships___field_image___internal___contentDigest',
  relationships___field_image___internal___description = 'relationships___field_image___internal___description',
  relationships___field_image___internal___fieldOwners = 'relationships___field_image___internal___fieldOwners',
  relationships___field_image___internal___ignoreType = 'relationships___field_image___internal___ignoreType',
  relationships___field_image___internal___mediaType = 'relationships___field_image___internal___mediaType',
  relationships___field_image___internal___owner = 'relationships___field_image___internal___owner',
  relationships___field_image___internal___type = 'relationships___field_image___internal___type',
  relationships___field_bullets_icons = 'relationships___field_bullets_icons',
  relationships___field_bullets_icons___localFile___birthtime = 'relationships___field_bullets_icons___localFile___birthtime',
  relationships___field_bullets_icons___localFile___birthtimeMs = 'relationships___field_bullets_icons___localFile___birthtimeMs',
  relationships___field_bullets_icons___localFile___publicURL = 'relationships___field_bullets_icons___localFile___publicURL',
  relationships___field_bullets_icons___localFile___sourceInstanceName = 'relationships___field_bullets_icons___localFile___sourceInstanceName',
  relationships___field_bullets_icons___localFile___absolutePath = 'relationships___field_bullets_icons___localFile___absolutePath',
  relationships___field_bullets_icons___localFile___relativePath = 'relationships___field_bullets_icons___localFile___relativePath',
  relationships___field_bullets_icons___localFile___extension = 'relationships___field_bullets_icons___localFile___extension',
  relationships___field_bullets_icons___localFile___size = 'relationships___field_bullets_icons___localFile___size',
  relationships___field_bullets_icons___localFile___prettySize = 'relationships___field_bullets_icons___localFile___prettySize',
  relationships___field_bullets_icons___localFile___modifiedTime = 'relationships___field_bullets_icons___localFile___modifiedTime',
  relationships___field_bullets_icons___localFile___accessTime = 'relationships___field_bullets_icons___localFile___accessTime',
  relationships___field_bullets_icons___localFile___changeTime = 'relationships___field_bullets_icons___localFile___changeTime',
  relationships___field_bullets_icons___localFile___birthTime = 'relationships___field_bullets_icons___localFile___birthTime',
  relationships___field_bullets_icons___localFile___root = 'relationships___field_bullets_icons___localFile___root',
  relationships___field_bullets_icons___localFile___dir = 'relationships___field_bullets_icons___localFile___dir',
  relationships___field_bullets_icons___localFile___base = 'relationships___field_bullets_icons___localFile___base',
  relationships___field_bullets_icons___localFile___ext = 'relationships___field_bullets_icons___localFile___ext',
  relationships___field_bullets_icons___localFile___name = 'relationships___field_bullets_icons___localFile___name',
  relationships___field_bullets_icons___localFile___relativeDirectory = 'relationships___field_bullets_icons___localFile___relativeDirectory',
  relationships___field_bullets_icons___localFile___dev = 'relationships___field_bullets_icons___localFile___dev',
  relationships___field_bullets_icons___localFile___mode = 'relationships___field_bullets_icons___localFile___mode',
  relationships___field_bullets_icons___localFile___nlink = 'relationships___field_bullets_icons___localFile___nlink',
  relationships___field_bullets_icons___localFile___uid = 'relationships___field_bullets_icons___localFile___uid',
  relationships___field_bullets_icons___localFile___gid = 'relationships___field_bullets_icons___localFile___gid',
  relationships___field_bullets_icons___localFile___rdev = 'relationships___field_bullets_icons___localFile___rdev',
  relationships___field_bullets_icons___localFile___blksize = 'relationships___field_bullets_icons___localFile___blksize',
  relationships___field_bullets_icons___localFile___ino = 'relationships___field_bullets_icons___localFile___ino',
  relationships___field_bullets_icons___localFile___blocks = 'relationships___field_bullets_icons___localFile___blocks',
  relationships___field_bullets_icons___localFile___atimeMs = 'relationships___field_bullets_icons___localFile___atimeMs',
  relationships___field_bullets_icons___localFile___mtimeMs = 'relationships___field_bullets_icons___localFile___mtimeMs',
  relationships___field_bullets_icons___localFile___ctimeMs = 'relationships___field_bullets_icons___localFile___ctimeMs',
  relationships___field_bullets_icons___localFile___atime = 'relationships___field_bullets_icons___localFile___atime',
  relationships___field_bullets_icons___localFile___mtime = 'relationships___field_bullets_icons___localFile___mtime',
  relationships___field_bullets_icons___localFile___ctime = 'relationships___field_bullets_icons___localFile___ctime',
  relationships___field_bullets_icons___localFile___url = 'relationships___field_bullets_icons___localFile___url',
  relationships___field_bullets_icons___localFile___id = 'relationships___field_bullets_icons___localFile___id',
  relationships___field_bullets_icons___localFile___children = 'relationships___field_bullets_icons___localFile___children',
  relationships___field_bullets_icons___uri___url = 'relationships___field_bullets_icons___uri___url',
  relationships___field_bullets_icons___drupal_id = 'relationships___field_bullets_icons___drupal_id',
  relationships___field_bullets_icons___drupal_internal__fid = 'relationships___field_bullets_icons___drupal_internal__fid',
  relationships___field_bullets_icons___langcode = 'relationships___field_bullets_icons___langcode',
  relationships___field_bullets_icons___filename = 'relationships___field_bullets_icons___filename',
  relationships___field_bullets_icons___filemime = 'relationships___field_bullets_icons___filemime',
  relationships___field_bullets_icons___filesize = 'relationships___field_bullets_icons___filesize',
  relationships___field_bullets_icons___status = 'relationships___field_bullets_icons___status',
  relationships___field_bullets_icons___created = 'relationships___field_bullets_icons___created',
  relationships___field_bullets_icons___changed = 'relationships___field_bullets_icons___changed',
  relationships___field_bullets_icons___uuid = 'relationships___field_bullets_icons___uuid',
  relationships___field_bullets_icons___relationships___node__blog_post = 'relationships___field_bullets_icons___relationships___node__blog_post',
  relationships___field_bullets_icons___relationships___node__devices = 'relationships___field_bullets_icons___relationships___node__devices',
  relationships___field_bullets_icons___relationships___node__accessories = 'relationships___field_bullets_icons___relationships___node__accessories',
  relationships___field_bullets_icons___relationships___paragraph__news_image_title_text = 'relationships___field_bullets_icons___relationships___paragraph__news_image_title_text',
  relationships___field_bullets_icons___relationships___node__cases = 'relationships___field_bullets_icons___relationships___node__cases',
  relationships___field_bullets_icons___relationships___paragraph__branches_what_is_ipos = 'relationships___field_bullets_icons___relationships___paragraph__branches_what_is_ipos',
  relationships___field_bullets_icons___relationships___paragraph__branches_features = 'relationships___field_bullets_icons___relationships___paragraph__branches_features',
  relationships___field_bullets_icons___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_bullets_icons___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_bullets_icons___relationships___paragraph__branches_testimonials = 'relationships___field_bullets_icons___relationships___paragraph__branches_testimonials',
  relationships___field_bullets_icons___relationships___paragraph__branches_discounts = 'relationships___field_bullets_icons___relationships___paragraph__branches_discounts',
  relationships___field_bullets_icons___relationships___node__news = 'relationships___field_bullets_icons___relationships___node__news',
  relationships___field_bullets_icons___relationships___node__branches = 'relationships___field_bullets_icons___relationships___node__branches',
  relationships___field_bullets_icons___relationships___paragraph__branches_hero = 'relationships___field_bullets_icons___relationships___paragraph__branches_hero',
  relationships___field_bullets_icons___relationships___paragraph__bullets = 'relationships___field_bullets_icons___relationships___paragraph__bullets',
  relationships___field_bullets_icons___relationships___taxonomy_term__pricelist_group = 'relationships___field_bullets_icons___relationships___taxonomy_term__pricelist_group',
  relationships___field_bullets_icons___relationships___paragraph__branches_device = 'relationships___field_bullets_icons___relationships___paragraph__branches_device',
  relationships___field_bullets_icons___relationships___paragraph__branches_recommendation = 'relationships___field_bullets_icons___relationships___paragraph__branches_recommendation',
  relationships___field_bullets_icons___relationships___site_setting_entity__accessories = 'relationships___field_bullets_icons___relationships___site_setting_entity__accessories',
  relationships___field_bullets_icons___relationships___site_setting_entity__branches = 'relationships___field_bullets_icons___relationships___site_setting_entity__branches',
  relationships___field_bullets_icons___relationships___site_setting_entity__about_us = 'relationships___field_bullets_icons___relationships___site_setting_entity__about_us',
  relationships___field_bullets_icons___relationships___paragraph__logos = 'relationships___field_bullets_icons___relationships___paragraph__logos',
  relationships___field_bullets_icons___relationships___paragraph__how_it_works = 'relationships___field_bullets_icons___relationships___paragraph__how_it_works',
  relationships___field_bullets_icons___relationships___paragraph__ico_and_text = 'relationships___field_bullets_icons___relationships___paragraph__ico_and_text',
  relationships___field_bullets_icons___relationships___paragraph__hero_1 = 'relationships___field_bullets_icons___relationships___paragraph__hero_1',
  relationships___field_bullets_icons___relationships___paragraph__comprehensive_solution_item = 'relationships___field_bullets_icons___relationships___paragraph__comprehensive_solution_item',
  relationships___field_bullets_icons___relationships___site_setting_entity__product = 'relationships___field_bullets_icons___relationships___site_setting_entity__product',
  relationships___field_bullets_icons___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_bullets_icons___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_bullets_icons___relationships___paragraph__porownanie_terminali = 'relationships___field_bullets_icons___relationships___paragraph__porownanie_terminali',
  relationships___field_bullets_icons___relationships___paragraph__zdjecie_opis_link = 'relationships___field_bullets_icons___relationships___paragraph__zdjecie_opis_link',
  relationships___field_bullets_icons___relationships___site_setting_entity__main_page = 'relationships___field_bullets_icons___relationships___site_setting_entity__main_page',
  relationships___field_bullets_icons___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_bullets_icons___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_bullets_icons___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_bullets_icons___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_bullets_icons___relationships___site_setting_entity__intercars = 'relationships___field_bullets_icons___relationships___site_setting_entity__intercars',
  relationships___field_bullets_icons___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_bullets_icons___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_bullets_icons___relationships___node__agreement = 'relationships___field_bullets_icons___relationships___node__agreement',
  relationships___field_bullets_icons___relationships___node__fiscalization = 'relationships___field_bullets_icons___relationships___node__fiscalization',
  relationships___field_bullets_icons___relationships___site_setting_entity__configurator = 'relationships___field_bullets_icons___relationships___site_setting_entity__configurator',
  relationships___field_bullets_icons___relationships___paragraph__social_link = 'relationships___field_bullets_icons___relationships___paragraph__social_link',
  relationships___field_bullets_icons___relationships___paragraph__make_an_appointment = 'relationships___field_bullets_icons___relationships___paragraph__make_an_appointment',
  relationships___field_bullets_icons___relationships___paragraph__linked_icons = 'relationships___field_bullets_icons___relationships___paragraph__linked_icons',
  relationships___field_bullets_icons___relationships___site_setting_entity__financing = 'relationships___field_bullets_icons___relationships___site_setting_entity__financing',
  relationships___field_bullets_icons___relationships___site_setting_entity__franchise = 'relationships___field_bullets_icons___relationships___site_setting_entity__franchise',
  relationships___field_bullets_icons___id = 'relationships___field_bullets_icons___id',
  relationships___field_bullets_icons___parent___id = 'relationships___field_bullets_icons___parent___id',
  relationships___field_bullets_icons___parent___children = 'relationships___field_bullets_icons___parent___children',
  relationships___field_bullets_icons___children = 'relationships___field_bullets_icons___children',
  relationships___field_bullets_icons___children___id = 'relationships___field_bullets_icons___children___id',
  relationships___field_bullets_icons___children___children = 'relationships___field_bullets_icons___children___children',
  relationships___field_bullets_icons___internal___content = 'relationships___field_bullets_icons___internal___content',
  relationships___field_bullets_icons___internal___contentDigest = 'relationships___field_bullets_icons___internal___contentDigest',
  relationships___field_bullets_icons___internal___description = 'relationships___field_bullets_icons___internal___description',
  relationships___field_bullets_icons___internal___fieldOwners = 'relationships___field_bullets_icons___internal___fieldOwners',
  relationships___field_bullets_icons___internal___ignoreType = 'relationships___field_bullets_icons___internal___ignoreType',
  relationships___field_bullets_icons___internal___mediaType = 'relationships___field_bullets_icons___internal___mediaType',
  relationships___field_bullets_icons___internal___owner = 'relationships___field_bullets_icons___internal___owner',
  relationships___field_bullets_icons___internal___type = 'relationships___field_bullets_icons___internal___type',
  relationships___paragraph__bullets_container = 'relationships___paragraph__bullets_container',
  relationships___paragraph__bullets_container___internal___content = 'relationships___paragraph__bullets_container___internal___content',
  relationships___paragraph__bullets_container___internal___contentDigest = 'relationships___paragraph__bullets_container___internal___contentDigest',
  relationships___paragraph__bullets_container___internal___description = 'relationships___paragraph__bullets_container___internal___description',
  relationships___paragraph__bullets_container___internal___fieldOwners = 'relationships___paragraph__bullets_container___internal___fieldOwners',
  relationships___paragraph__bullets_container___internal___ignoreType = 'relationships___paragraph__bullets_container___internal___ignoreType',
  relationships___paragraph__bullets_container___internal___mediaType = 'relationships___paragraph__bullets_container___internal___mediaType',
  relationships___paragraph__bullets_container___internal___owner = 'relationships___paragraph__bullets_container___internal___owner',
  relationships___paragraph__bullets_container___internal___type = 'relationships___paragraph__bullets_container___internal___type',
  relationships___paragraph__bullets_container___id = 'relationships___paragraph__bullets_container___id',
  relationships___paragraph__bullets_container___drupal_id = 'relationships___paragraph__bullets_container___drupal_id',
  relationships___paragraph__bullets_container___relationships___field_bullets_item = 'relationships___paragraph__bullets_container___relationships___field_bullets_item',
  relationships___paragraph__bullets_container___relationships___node__branches = 'relationships___paragraph__bullets_container___relationships___node__branches',
  relationships___paragraph__bullets_container___relationships___site_setting_entity__about_us = 'relationships___paragraph__bullets_container___relationships___site_setting_entity__about_us',
  relationships___paragraph__bullets_container___relationships___site_setting_entity__financing = 'relationships___paragraph__bullets_container___relationships___site_setting_entity__financing',
  relationships___paragraph__bullets_container___relationships___site_setting_entity__franchise = 'relationships___paragraph__bullets_container___relationships___site_setting_entity__franchise',
  relationships___paragraph__bullets_container___relationships___site_setting_entity__main_page = 'relationships___paragraph__bullets_container___relationships___site_setting_entity__main_page',
  relationships___paragraph__bullets_container___relationships___site_setting_entity__product = 'relationships___paragraph__bullets_container___relationships___site_setting_entity__product',
  relationships___paragraph__bullets_container___relationships___node__pages = 'relationships___paragraph__bullets_container___relationships___node__pages',
  relationships___paragraph__bullets_container___drupal_internal__id = 'relationships___paragraph__bullets_container___drupal_internal__id',
  relationships___paragraph__bullets_container___status = 'relationships___paragraph__bullets_container___status',
  relationships___paragraph__bullets_container___created = 'relationships___paragraph__bullets_container___created',
  relationships___paragraph__bullets_container___parent_id = 'relationships___paragraph__bullets_container___parent_id',
  relationships___paragraph__bullets_container___parent_type = 'relationships___paragraph__bullets_container___parent_type',
  relationships___paragraph__bullets_container___parent_field_name = 'relationships___paragraph__bullets_container___parent_field_name',
  relationships___paragraph__bullets_container___content_translation_source = 'relationships___paragraph__bullets_container___content_translation_source',
  relationships___paragraph__bullets_container___content_translation_outdated = 'relationships___paragraph__bullets_container___content_translation_outdated',
  relationships___paragraph__bullets_container___content_translation_changed = 'relationships___paragraph__bullets_container___content_translation_changed',
  relationships___paragraph__bullets_container___langcode = 'relationships___paragraph__bullets_container___langcode',
  relationships___paragraph__bullets_container___uuid = 'relationships___paragraph__bullets_container___uuid',
  relationships___paragraph__bullets_container___field_bullets_item = 'relationships___paragraph__bullets_container___field_bullets_item',
  relationships___paragraph__bullets_container___field_bullets_item___target_revision_id = 'relationships___paragraph__bullets_container___field_bullets_item___target_revision_id',
  relationships___paragraph__bullets_container___field_bullets_item___drupal_internal__target_id = 'relationships___paragraph__bullets_container___field_bullets_item___drupal_internal__target_id',
  relationships___paragraph__bullets_container___parent___id = 'relationships___paragraph__bullets_container___parent___id',
  relationships___paragraph__bullets_container___parent___children = 'relationships___paragraph__bullets_container___parent___children',
  relationships___paragraph__bullets_container___children = 'relationships___paragraph__bullets_container___children',
  relationships___paragraph__bullets_container___children___id = 'relationships___paragraph__bullets_container___children___id',
  relationships___paragraph__bullets_container___children___children = 'relationships___paragraph__bullets_container___children___children',
  relationships___site_setting_entity__accessories = 'relationships___site_setting_entity__accessories',
  relationships___site_setting_entity__accessories___langcode = 'relationships___site_setting_entity__accessories___langcode',
  relationships___site_setting_entity__accessories___field_meta_description = 'relationships___site_setting_entity__accessories___field_meta_description',
  relationships___site_setting_entity__accessories___field_meta_title = 'relationships___site_setting_entity__accessories___field_meta_title',
  relationships___site_setting_entity__accessories___drupal_id = 'relationships___site_setting_entity__accessories___drupal_id',
  relationships___site_setting_entity__accessories___field_hero_button_text = 'relationships___site_setting_entity__accessories___field_hero_button_text',
  relationships___site_setting_entity__accessories___field_hero_headline___value = 'relationships___site_setting_entity__accessories___field_hero_headline___value',
  relationships___site_setting_entity__accessories___field_hero_text___value = 'relationships___site_setting_entity__accessories___field_hero_text___value',
  relationships___site_setting_entity__accessories___drupal_internal__id = 'relationships___site_setting_entity__accessories___drupal_internal__id',
  relationships___site_setting_entity__accessories___name = 'relationships___site_setting_entity__accessories___name',
  relationships___site_setting_entity__accessories___fieldset = 'relationships___site_setting_entity__accessories___fieldset',
  relationships___site_setting_entity__accessories___status = 'relationships___site_setting_entity__accessories___status',
  relationships___site_setting_entity__accessories___created = 'relationships___site_setting_entity__accessories___created',
  relationships___site_setting_entity__accessories___changed = 'relationships___site_setting_entity__accessories___changed',
  relationships___site_setting_entity__accessories___default_langcode = 'relationships___site_setting_entity__accessories___default_langcode',
  relationships___site_setting_entity__accessories___content_translation_source = 'relationships___site_setting_entity__accessories___content_translation_source',
  relationships___site_setting_entity__accessories___content_translation_outdated = 'relationships___site_setting_entity__accessories___content_translation_outdated',
  relationships___site_setting_entity__accessories___content_translation_status = 'relationships___site_setting_entity__accessories___content_translation_status',
  relationships___site_setting_entity__accessories___content_translation_created = 'relationships___site_setting_entity__accessories___content_translation_created',
  relationships___site_setting_entity__accessories___content_translation_changed = 'relationships___site_setting_entity__accessories___content_translation_changed',
  relationships___site_setting_entity__accessories___uuid = 'relationships___site_setting_entity__accessories___uuid',
  relationships___site_setting_entity__accessories___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_components = 'relationships___site_setting_entity__accessories___field_components',
  relationships___site_setting_entity__accessories___field_components___target_revision_id = 'relationships___site_setting_entity__accessories___field_components___target_revision_id',
  relationships___site_setting_entity__accessories___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_hero_image___alt = 'relationships___site_setting_entity__accessories___field_hero_image___alt',
  relationships___site_setting_entity__accessories___field_hero_image___title = 'relationships___site_setting_entity__accessories___field_hero_image___title',
  relationships___site_setting_entity__accessories___field_hero_image___width = 'relationships___site_setting_entity__accessories___field_hero_image___width',
  relationships___site_setting_entity__accessories___field_hero_image___height = 'relationships___site_setting_entity__accessories___field_hero_image___height',
  relationships___site_setting_entity__accessories___field_hero_image___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_hero_image___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___alt = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___alt',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___title = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___title',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___width = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___width',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___height = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___height',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___id = 'relationships___site_setting_entity__accessories___id',
  relationships___site_setting_entity__accessories___parent___id = 'relationships___site_setting_entity__accessories___parent___id',
  relationships___site_setting_entity__accessories___parent___children = 'relationships___site_setting_entity__accessories___parent___children',
  relationships___site_setting_entity__accessories___children = 'relationships___site_setting_entity__accessories___children',
  relationships___site_setting_entity__accessories___children___id = 'relationships___site_setting_entity__accessories___children___id',
  relationships___site_setting_entity__accessories___children___children = 'relationships___site_setting_entity__accessories___children___children',
  relationships___site_setting_entity__accessories___internal___content = 'relationships___site_setting_entity__accessories___internal___content',
  relationships___site_setting_entity__accessories___internal___contentDigest = 'relationships___site_setting_entity__accessories___internal___contentDigest',
  relationships___site_setting_entity__accessories___internal___description = 'relationships___site_setting_entity__accessories___internal___description',
  relationships___site_setting_entity__accessories___internal___fieldOwners = 'relationships___site_setting_entity__accessories___internal___fieldOwners',
  relationships___site_setting_entity__accessories___internal___ignoreType = 'relationships___site_setting_entity__accessories___internal___ignoreType',
  relationships___site_setting_entity__accessories___internal___mediaType = 'relationships___site_setting_entity__accessories___internal___mediaType',
  relationships___site_setting_entity__accessories___internal___owner = 'relationships___site_setting_entity__accessories___internal___owner',
  relationships___site_setting_entity__accessories___internal___type = 'relationships___site_setting_entity__accessories___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__BulletsFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_btn_url?: Maybe<StringQueryOperatorInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  field_video?: Maybe<StringQueryOperatorInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  field_bullets_icons?: Maybe<Field_Bullets_IconsFilterListInput>;
  relationships?: Maybe<Paragraph__BulletsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__BulletsFilterListInput = {
  elemMatch?: Maybe<Paragraph__BulletsFilterInput>;
};

export type Paragraph__BulletsGroupConnection = {
  __typename?: 'paragraph__bulletsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__BulletsEdge>;
  nodes: Array<Paragraph__Bullets>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__BulletsRelationships = {
  __typename?: 'paragraph__bulletsRelationships';
  field_image?: Maybe<File__File>;
  field_bullets_icons?: Maybe<Array<Maybe<File__File>>>;
  paragraph__bullets_container?: Maybe<Array<Maybe<Paragraph__Bullets_Container>>>;
  site_setting_entity__accessories?: Maybe<Array<Maybe<Site_Setting_Entity__Accessories>>>;
};

export type Paragraph__BulletsRelationshipsFilterInput = {
  field_image?: Maybe<File__FileFilterInput>;
  field_bullets_icons?: Maybe<File__FileFilterListInput>;
  paragraph__bullets_container?: Maybe<Paragraph__Bullets_ContainerFilterListInput>;
  site_setting_entity__accessories?: Maybe<Site_Setting_Entity__AccessoriesFilterListInput>;
};

export type Paragraph__BulletsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__BulletsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Comprehensive_Solution = Node & {
  __typename?: 'paragraph__comprehensive_solution';
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_text?: Maybe<Field_Text>;
  relationships?: Maybe<Paragraph__Comprehensive_SolutionRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  field_comprehensive_solution_ite?: Maybe<
    Array<Maybe<Paragraph__Comprehensive_SolutionField_Comprehensive_Solution_Ite>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Comprehensive_SolutionCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Comprehensive_Solution_Item = Node & {
  __typename?: 'paragraph__comprehensive_solution_item';
  field_is_online?: Maybe<Scalars['Boolean']>;
  field_is_online_text?: Maybe<Scalars['String']>;
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_image?: Maybe<Field_Image>;
  relationships?: Maybe<Paragraph__Comprehensive_Solution_ItemRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Comprehensive_Solution_ItemCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Comprehensive_Solution_ItemContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Comprehensive_Solution_ItemConnection = {
  __typename?: 'paragraph__comprehensive_solution_itemConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Comprehensive_Solution_ItemEdge>;
  nodes: Array<Paragraph__Comprehensive_Solution_Item>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Comprehensive_Solution_ItemGroupConnection>;
};

export type Paragraph__Comprehensive_Solution_ItemConnectionDistinctArgs = {
  field: Paragraph__Comprehensive_Solution_ItemFieldsEnum;
};

export type Paragraph__Comprehensive_Solution_ItemConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Comprehensive_Solution_ItemFieldsEnum;
};

export type Paragraph__Comprehensive_Solution_ItemEdge = {
  __typename?: 'paragraph__comprehensive_solution_itemEdge';
  next?: Maybe<Paragraph__Comprehensive_Solution_Item>;
  node: Paragraph__Comprehensive_Solution_Item;
  previous?: Maybe<Paragraph__Comprehensive_Solution_Item>;
};

export enum Paragraph__Comprehensive_Solution_ItemFieldsEnum {
  field_is_online = 'field_is_online',
  field_is_online_text = 'field_is_online_text',
  field_nc_button_text = 'field_nc_button_text',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_image___alt = 'field_image___alt',
  relationships___field_image___localFile___birthtime = 'relationships___field_image___localFile___birthtime',
  relationships___field_image___localFile___birthtimeMs = 'relationships___field_image___localFile___birthtimeMs',
  relationships___field_image___localFile___publicURL = 'relationships___field_image___localFile___publicURL',
  relationships___field_image___localFile___sourceInstanceName = 'relationships___field_image___localFile___sourceInstanceName',
  relationships___field_image___localFile___absolutePath = 'relationships___field_image___localFile___absolutePath',
  relationships___field_image___localFile___relativePath = 'relationships___field_image___localFile___relativePath',
  relationships___field_image___localFile___extension = 'relationships___field_image___localFile___extension',
  relationships___field_image___localFile___size = 'relationships___field_image___localFile___size',
  relationships___field_image___localFile___prettySize = 'relationships___field_image___localFile___prettySize',
  relationships___field_image___localFile___modifiedTime = 'relationships___field_image___localFile___modifiedTime',
  relationships___field_image___localFile___accessTime = 'relationships___field_image___localFile___accessTime',
  relationships___field_image___localFile___changeTime = 'relationships___field_image___localFile___changeTime',
  relationships___field_image___localFile___birthTime = 'relationships___field_image___localFile___birthTime',
  relationships___field_image___localFile___root = 'relationships___field_image___localFile___root',
  relationships___field_image___localFile___dir = 'relationships___field_image___localFile___dir',
  relationships___field_image___localFile___base = 'relationships___field_image___localFile___base',
  relationships___field_image___localFile___ext = 'relationships___field_image___localFile___ext',
  relationships___field_image___localFile___name = 'relationships___field_image___localFile___name',
  relationships___field_image___localFile___relativeDirectory = 'relationships___field_image___localFile___relativeDirectory',
  relationships___field_image___localFile___dev = 'relationships___field_image___localFile___dev',
  relationships___field_image___localFile___mode = 'relationships___field_image___localFile___mode',
  relationships___field_image___localFile___nlink = 'relationships___field_image___localFile___nlink',
  relationships___field_image___localFile___uid = 'relationships___field_image___localFile___uid',
  relationships___field_image___localFile___gid = 'relationships___field_image___localFile___gid',
  relationships___field_image___localFile___rdev = 'relationships___field_image___localFile___rdev',
  relationships___field_image___localFile___blksize = 'relationships___field_image___localFile___blksize',
  relationships___field_image___localFile___ino = 'relationships___field_image___localFile___ino',
  relationships___field_image___localFile___blocks = 'relationships___field_image___localFile___blocks',
  relationships___field_image___localFile___atimeMs = 'relationships___field_image___localFile___atimeMs',
  relationships___field_image___localFile___mtimeMs = 'relationships___field_image___localFile___mtimeMs',
  relationships___field_image___localFile___ctimeMs = 'relationships___field_image___localFile___ctimeMs',
  relationships___field_image___localFile___atime = 'relationships___field_image___localFile___atime',
  relationships___field_image___localFile___mtime = 'relationships___field_image___localFile___mtime',
  relationships___field_image___localFile___ctime = 'relationships___field_image___localFile___ctime',
  relationships___field_image___localFile___url = 'relationships___field_image___localFile___url',
  relationships___field_image___localFile___id = 'relationships___field_image___localFile___id',
  relationships___field_image___localFile___children = 'relationships___field_image___localFile___children',
  relationships___field_image___uri___url = 'relationships___field_image___uri___url',
  relationships___field_image___drupal_id = 'relationships___field_image___drupal_id',
  relationships___field_image___drupal_internal__fid = 'relationships___field_image___drupal_internal__fid',
  relationships___field_image___langcode = 'relationships___field_image___langcode',
  relationships___field_image___filename = 'relationships___field_image___filename',
  relationships___field_image___filemime = 'relationships___field_image___filemime',
  relationships___field_image___filesize = 'relationships___field_image___filesize',
  relationships___field_image___status = 'relationships___field_image___status',
  relationships___field_image___created = 'relationships___field_image___created',
  relationships___field_image___changed = 'relationships___field_image___changed',
  relationships___field_image___uuid = 'relationships___field_image___uuid',
  relationships___field_image___relationships___node__blog_post = 'relationships___field_image___relationships___node__blog_post',
  relationships___field_image___relationships___node__devices = 'relationships___field_image___relationships___node__devices',
  relationships___field_image___relationships___node__accessories = 'relationships___field_image___relationships___node__accessories',
  relationships___field_image___relationships___paragraph__news_image_title_text = 'relationships___field_image___relationships___paragraph__news_image_title_text',
  relationships___field_image___relationships___node__cases = 'relationships___field_image___relationships___node__cases',
  relationships___field_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image___relationships___paragraph__branches_features = 'relationships___field_image___relationships___paragraph__branches_features',
  relationships___field_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image___relationships___paragraph__branches_testimonials = 'relationships___field_image___relationships___paragraph__branches_testimonials',
  relationships___field_image___relationships___paragraph__branches_discounts = 'relationships___field_image___relationships___paragraph__branches_discounts',
  relationships___field_image___relationships___node__news = 'relationships___field_image___relationships___node__news',
  relationships___field_image___relationships___node__branches = 'relationships___field_image___relationships___node__branches',
  relationships___field_image___relationships___paragraph__branches_hero = 'relationships___field_image___relationships___paragraph__branches_hero',
  relationships___field_image___relationships___paragraph__bullets = 'relationships___field_image___relationships___paragraph__bullets',
  relationships___field_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_image___relationships___paragraph__branches_device = 'relationships___field_image___relationships___paragraph__branches_device',
  relationships___field_image___relationships___paragraph__branches_recommendation = 'relationships___field_image___relationships___paragraph__branches_recommendation',
  relationships___field_image___relationships___site_setting_entity__accessories = 'relationships___field_image___relationships___site_setting_entity__accessories',
  relationships___field_image___relationships___site_setting_entity__branches = 'relationships___field_image___relationships___site_setting_entity__branches',
  relationships___field_image___relationships___site_setting_entity__about_us = 'relationships___field_image___relationships___site_setting_entity__about_us',
  relationships___field_image___relationships___paragraph__logos = 'relationships___field_image___relationships___paragraph__logos',
  relationships___field_image___relationships___paragraph__how_it_works = 'relationships___field_image___relationships___paragraph__how_it_works',
  relationships___field_image___relationships___paragraph__ico_and_text = 'relationships___field_image___relationships___paragraph__ico_and_text',
  relationships___field_image___relationships___paragraph__hero_1 = 'relationships___field_image___relationships___paragraph__hero_1',
  relationships___field_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image___relationships___site_setting_entity__product = 'relationships___field_image___relationships___site_setting_entity__product',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image___relationships___paragraph__porownanie_terminali = 'relationships___field_image___relationships___paragraph__porownanie_terminali',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image___relationships___site_setting_entity__main_page = 'relationships___field_image___relationships___site_setting_entity__main_page',
  relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image___relationships___site_setting_entity__intercars = 'relationships___field_image___relationships___site_setting_entity__intercars',
  relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image___relationships___node__agreement = 'relationships___field_image___relationships___node__agreement',
  relationships___field_image___relationships___node__fiscalization = 'relationships___field_image___relationships___node__fiscalization',
  relationships___field_image___relationships___site_setting_entity__configurator = 'relationships___field_image___relationships___site_setting_entity__configurator',
  relationships___field_image___relationships___paragraph__social_link = 'relationships___field_image___relationships___paragraph__social_link',
  relationships___field_image___relationships___paragraph__make_an_appointment = 'relationships___field_image___relationships___paragraph__make_an_appointment',
  relationships___field_image___relationships___paragraph__linked_icons = 'relationships___field_image___relationships___paragraph__linked_icons',
  relationships___field_image___relationships___site_setting_entity__financing = 'relationships___field_image___relationships___site_setting_entity__financing',
  relationships___field_image___relationships___site_setting_entity__franchise = 'relationships___field_image___relationships___site_setting_entity__franchise',
  relationships___field_image___id = 'relationships___field_image___id',
  relationships___field_image___parent___id = 'relationships___field_image___parent___id',
  relationships___field_image___parent___children = 'relationships___field_image___parent___children',
  relationships___field_image___children = 'relationships___field_image___children',
  relationships___field_image___children___id = 'relationships___field_image___children___id',
  relationships___field_image___children___children = 'relationships___field_image___children___children',
  relationships___field_image___internal___content = 'relationships___field_image___internal___content',
  relationships___field_image___internal___contentDigest = 'relationships___field_image___internal___contentDigest',
  relationships___field_image___internal___description = 'relationships___field_image___internal___description',
  relationships___field_image___internal___fieldOwners = 'relationships___field_image___internal___fieldOwners',
  relationships___field_image___internal___ignoreType = 'relationships___field_image___internal___ignoreType',
  relationships___field_image___internal___mediaType = 'relationships___field_image___internal___mediaType',
  relationships___field_image___internal___owner = 'relationships___field_image___internal___owner',
  relationships___field_image___internal___type = 'relationships___field_image___internal___type',
  relationships___paragraph__comprehensive_solution = 'relationships___paragraph__comprehensive_solution',
  relationships___paragraph__comprehensive_solution___field_paragraph_headline___value = 'relationships___paragraph__comprehensive_solution___field_paragraph_headline___value',
  relationships___paragraph__comprehensive_solution___field_text___value = 'relationships___paragraph__comprehensive_solution___field_text___value',
  relationships___paragraph__comprehensive_solution___relationships___field_comprehensive_solution_ite = 'relationships___paragraph__comprehensive_solution___relationships___field_comprehensive_solution_ite',
  relationships___paragraph__comprehensive_solution___relationships___site_setting_entity__main_page = 'relationships___paragraph__comprehensive_solution___relationships___site_setting_entity__main_page',
  relationships___paragraph__comprehensive_solution___drupal_id = 'relationships___paragraph__comprehensive_solution___drupal_id',
  relationships___paragraph__comprehensive_solution___drupal_internal__id = 'relationships___paragraph__comprehensive_solution___drupal_internal__id',
  relationships___paragraph__comprehensive_solution___drupal_internal__revision_id = 'relationships___paragraph__comprehensive_solution___drupal_internal__revision_id',
  relationships___paragraph__comprehensive_solution___langcode = 'relationships___paragraph__comprehensive_solution___langcode',
  relationships___paragraph__comprehensive_solution___status = 'relationships___paragraph__comprehensive_solution___status',
  relationships___paragraph__comprehensive_solution___created = 'relationships___paragraph__comprehensive_solution___created',
  relationships___paragraph__comprehensive_solution___parent_id = 'relationships___paragraph__comprehensive_solution___parent_id',
  relationships___paragraph__comprehensive_solution___parent_type = 'relationships___paragraph__comprehensive_solution___parent_type',
  relationships___paragraph__comprehensive_solution___parent_field_name = 'relationships___paragraph__comprehensive_solution___parent_field_name',
  relationships___paragraph__comprehensive_solution___default_langcode = 'relationships___paragraph__comprehensive_solution___default_langcode',
  relationships___paragraph__comprehensive_solution___revision_translation_affected = 'relationships___paragraph__comprehensive_solution___revision_translation_affected',
  relationships___paragraph__comprehensive_solution___content_translation_source = 'relationships___paragraph__comprehensive_solution___content_translation_source',
  relationships___paragraph__comprehensive_solution___content_translation_outdated = 'relationships___paragraph__comprehensive_solution___content_translation_outdated',
  relationships___paragraph__comprehensive_solution___uuid = 'relationships___paragraph__comprehensive_solution___uuid',
  relationships___paragraph__comprehensive_solution___field_comprehensive_solution_ite = 'relationships___paragraph__comprehensive_solution___field_comprehensive_solution_ite',
  relationships___paragraph__comprehensive_solution___field_comprehensive_solution_ite___target_revision_id = 'relationships___paragraph__comprehensive_solution___field_comprehensive_solution_ite___target_revision_id',
  relationships___paragraph__comprehensive_solution___field_comprehensive_solution_ite___drupal_internal__target_id = 'relationships___paragraph__comprehensive_solution___field_comprehensive_solution_ite___drupal_internal__target_id',
  relationships___paragraph__comprehensive_solution___id = 'relationships___paragraph__comprehensive_solution___id',
  relationships___paragraph__comprehensive_solution___parent___id = 'relationships___paragraph__comprehensive_solution___parent___id',
  relationships___paragraph__comprehensive_solution___parent___children = 'relationships___paragraph__comprehensive_solution___parent___children',
  relationships___paragraph__comprehensive_solution___children = 'relationships___paragraph__comprehensive_solution___children',
  relationships___paragraph__comprehensive_solution___children___id = 'relationships___paragraph__comprehensive_solution___children___id',
  relationships___paragraph__comprehensive_solution___children___children = 'relationships___paragraph__comprehensive_solution___children___children',
  relationships___paragraph__comprehensive_solution___internal___content = 'relationships___paragraph__comprehensive_solution___internal___content',
  relationships___paragraph__comprehensive_solution___internal___contentDigest = 'relationships___paragraph__comprehensive_solution___internal___contentDigest',
  relationships___paragraph__comprehensive_solution___internal___description = 'relationships___paragraph__comprehensive_solution___internal___description',
  relationships___paragraph__comprehensive_solution___internal___fieldOwners = 'relationships___paragraph__comprehensive_solution___internal___fieldOwners',
  relationships___paragraph__comprehensive_solution___internal___ignoreType = 'relationships___paragraph__comprehensive_solution___internal___ignoreType',
  relationships___paragraph__comprehensive_solution___internal___mediaType = 'relationships___paragraph__comprehensive_solution___internal___mediaType',
  relationships___paragraph__comprehensive_solution___internal___owner = 'relationships___paragraph__comprehensive_solution___internal___owner',
  relationships___paragraph__comprehensive_solution___internal___type = 'relationships___paragraph__comprehensive_solution___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Comprehensive_Solution_ItemFilterInput = {
  field_is_online?: Maybe<BooleanQueryOperatorInput>;
  field_is_online_text?: Maybe<StringQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  relationships?: Maybe<Paragraph__Comprehensive_Solution_ItemRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Comprehensive_Solution_ItemFilterListInput = {
  elemMatch?: Maybe<Paragraph__Comprehensive_Solution_ItemFilterInput>;
};

export type Paragraph__Comprehensive_Solution_ItemGroupConnection = {
  __typename?: 'paragraph__comprehensive_solution_itemGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Comprehensive_Solution_ItemEdge>;
  nodes: Array<Paragraph__Comprehensive_Solution_Item>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Comprehensive_Solution_ItemRelationships = {
  __typename?: 'paragraph__comprehensive_solution_itemRelationships';
  field_image?: Maybe<File__File>;
  paragraph__comprehensive_solution?: Maybe<Array<Maybe<Paragraph__Comprehensive_Solution>>>;
};

export type Paragraph__Comprehensive_Solution_ItemRelationshipsFilterInput = {
  field_image?: Maybe<File__FileFilterInput>;
  paragraph__comprehensive_solution?: Maybe<Paragraph__Comprehensive_SolutionFilterListInput>;
};

export type Paragraph__Comprehensive_Solution_ItemSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Comprehensive_Solution_ItemFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Comprehensive_SolutionConnection = {
  __typename?: 'paragraph__comprehensive_solutionConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Comprehensive_SolutionEdge>;
  nodes: Array<Paragraph__Comprehensive_Solution>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Comprehensive_SolutionGroupConnection>;
};

export type Paragraph__Comprehensive_SolutionConnectionDistinctArgs = {
  field: Paragraph__Comprehensive_SolutionFieldsEnum;
};

export type Paragraph__Comprehensive_SolutionConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Comprehensive_SolutionFieldsEnum;
};

export type Paragraph__Comprehensive_SolutionEdge = {
  __typename?: 'paragraph__comprehensive_solutionEdge';
  next?: Maybe<Paragraph__Comprehensive_Solution>;
  node: Paragraph__Comprehensive_Solution;
  previous?: Maybe<Paragraph__Comprehensive_Solution>;
};

export type Paragraph__Comprehensive_SolutionField_Comprehensive_Solution_Ite = {
  __typename?: 'paragraph__comprehensive_solutionField_comprehensive_solution_ite';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Comprehensive_SolutionField_Comprehensive_Solution_IteFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Comprehensive_SolutionField_Comprehensive_Solution_IteFilterListInput = {
  elemMatch?: Maybe<Paragraph__Comprehensive_SolutionField_Comprehensive_Solution_IteFilterInput>;
};

export enum Paragraph__Comprehensive_SolutionFieldsEnum {
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_text___value = 'field_text___value',
  relationships___field_comprehensive_solution_ite = 'relationships___field_comprehensive_solution_ite',
  relationships___field_comprehensive_solution_ite___field_is_online = 'relationships___field_comprehensive_solution_ite___field_is_online',
  relationships___field_comprehensive_solution_ite___field_is_online_text = 'relationships___field_comprehensive_solution_ite___field_is_online_text',
  relationships___field_comprehensive_solution_ite___field_nc_button_text = 'relationships___field_comprehensive_solution_ite___field_nc_button_text',
  relationships___field_comprehensive_solution_ite___field_paragraph_headline___value = 'relationships___field_comprehensive_solution_ite___field_paragraph_headline___value',
  relationships___field_comprehensive_solution_ite___field_image___alt = 'relationships___field_comprehensive_solution_ite___field_image___alt',
  relationships___field_comprehensive_solution_ite___relationships___paragraph__comprehensive_solution = 'relationships___field_comprehensive_solution_ite___relationships___paragraph__comprehensive_solution',
  relationships___field_comprehensive_solution_ite___drupal_id = 'relationships___field_comprehensive_solution_ite___drupal_id',
  relationships___field_comprehensive_solution_ite___drupal_internal__id = 'relationships___field_comprehensive_solution_ite___drupal_internal__id',
  relationships___field_comprehensive_solution_ite___drupal_internal__revision_id = 'relationships___field_comprehensive_solution_ite___drupal_internal__revision_id',
  relationships___field_comprehensive_solution_ite___langcode = 'relationships___field_comprehensive_solution_ite___langcode',
  relationships___field_comprehensive_solution_ite___status = 'relationships___field_comprehensive_solution_ite___status',
  relationships___field_comprehensive_solution_ite___created = 'relationships___field_comprehensive_solution_ite___created',
  relationships___field_comprehensive_solution_ite___parent_id = 'relationships___field_comprehensive_solution_ite___parent_id',
  relationships___field_comprehensive_solution_ite___parent_type = 'relationships___field_comprehensive_solution_ite___parent_type',
  relationships___field_comprehensive_solution_ite___parent_field_name = 'relationships___field_comprehensive_solution_ite___parent_field_name',
  relationships___field_comprehensive_solution_ite___default_langcode = 'relationships___field_comprehensive_solution_ite___default_langcode',
  relationships___field_comprehensive_solution_ite___revision_translation_affected = 'relationships___field_comprehensive_solution_ite___revision_translation_affected',
  relationships___field_comprehensive_solution_ite___content_translation_source = 'relationships___field_comprehensive_solution_ite___content_translation_source',
  relationships___field_comprehensive_solution_ite___content_translation_outdated = 'relationships___field_comprehensive_solution_ite___content_translation_outdated',
  relationships___field_comprehensive_solution_ite___content_translation_changed = 'relationships___field_comprehensive_solution_ite___content_translation_changed',
  relationships___field_comprehensive_solution_ite___uuid = 'relationships___field_comprehensive_solution_ite___uuid',
  relationships___field_comprehensive_solution_ite___id = 'relationships___field_comprehensive_solution_ite___id',
  relationships___field_comprehensive_solution_ite___parent___id = 'relationships___field_comprehensive_solution_ite___parent___id',
  relationships___field_comprehensive_solution_ite___parent___children = 'relationships___field_comprehensive_solution_ite___parent___children',
  relationships___field_comprehensive_solution_ite___children = 'relationships___field_comprehensive_solution_ite___children',
  relationships___field_comprehensive_solution_ite___children___id = 'relationships___field_comprehensive_solution_ite___children___id',
  relationships___field_comprehensive_solution_ite___children___children = 'relationships___field_comprehensive_solution_ite___children___children',
  relationships___field_comprehensive_solution_ite___internal___content = 'relationships___field_comprehensive_solution_ite___internal___content',
  relationships___field_comprehensive_solution_ite___internal___contentDigest = 'relationships___field_comprehensive_solution_ite___internal___contentDigest',
  relationships___field_comprehensive_solution_ite___internal___description = 'relationships___field_comprehensive_solution_ite___internal___description',
  relationships___field_comprehensive_solution_ite___internal___fieldOwners = 'relationships___field_comprehensive_solution_ite___internal___fieldOwners',
  relationships___field_comprehensive_solution_ite___internal___ignoreType = 'relationships___field_comprehensive_solution_ite___internal___ignoreType',
  relationships___field_comprehensive_solution_ite___internal___mediaType = 'relationships___field_comprehensive_solution_ite___internal___mediaType',
  relationships___field_comprehensive_solution_ite___internal___owner = 'relationships___field_comprehensive_solution_ite___internal___owner',
  relationships___field_comprehensive_solution_ite___internal___type = 'relationships___field_comprehensive_solution_ite___internal___type',
  relationships___site_setting_entity__main_page = 'relationships___site_setting_entity__main_page',
  relationships___site_setting_entity__main_page___langcode = 'relationships___site_setting_entity__main_page___langcode',
  relationships___site_setting_entity__main_page___field_mp_text_footer___value = 'relationships___site_setting_entity__main_page___field_mp_text_footer___value',
  relationships___site_setting_entity__main_page___field_mp_text___value = 'relationships___site_setting_entity__main_page___field_mp_text___value',
  relationships___site_setting_entity__main_page___field_mp_button_text = 'relationships___site_setting_entity__main_page___field_mp_button_text',
  relationships___site_setting_entity__main_page___field_meta_description = 'relationships___site_setting_entity__main_page___field_meta_description',
  relationships___site_setting_entity__main_page___field_meta_title = 'relationships___site_setting_entity__main_page___field_meta_title',
  relationships___site_setting_entity__main_page___field_btn_url = 'relationships___site_setting_entity__main_page___field_btn_url',
  relationships___site_setting_entity__main_page___field_settings_button_text = 'relationships___site_setting_entity__main_page___field_settings_button_text',
  relationships___site_setting_entity__main_page___field_mp_image_footer___alt = 'relationships___site_setting_entity__main_page___field_mp_image_footer___alt',
  relationships___site_setting_entity__main_page___field_mp_image___alt = 'relationships___site_setting_entity__main_page___field_mp_image___alt',
  relationships___site_setting_entity__main_page___drupal_id = 'relationships___site_setting_entity__main_page___drupal_id',
  relationships___site_setting_entity__main_page___drupal_internal__id = 'relationships___site_setting_entity__main_page___drupal_internal__id',
  relationships___site_setting_entity__main_page___name = 'relationships___site_setting_entity__main_page___name',
  relationships___site_setting_entity__main_page___fieldset = 'relationships___site_setting_entity__main_page___fieldset',
  relationships___site_setting_entity__main_page___status = 'relationships___site_setting_entity__main_page___status',
  relationships___site_setting_entity__main_page___created = 'relationships___site_setting_entity__main_page___created',
  relationships___site_setting_entity__main_page___changed = 'relationships___site_setting_entity__main_page___changed',
  relationships___site_setting_entity__main_page___default_langcode = 'relationships___site_setting_entity__main_page___default_langcode',
  relationships___site_setting_entity__main_page___content_translation_source = 'relationships___site_setting_entity__main_page___content_translation_source',
  relationships___site_setting_entity__main_page___content_translation_outdated = 'relationships___site_setting_entity__main_page___content_translation_outdated',
  relationships___site_setting_entity__main_page___content_translation_status = 'relationships___site_setting_entity__main_page___content_translation_status',
  relationships___site_setting_entity__main_page___content_translation_created = 'relationships___site_setting_entity__main_page___content_translation_created',
  relationships___site_setting_entity__main_page___content_translation_changed = 'relationships___site_setting_entity__main_page___content_translation_changed',
  relationships___site_setting_entity__main_page___uuid = 'relationships___site_setting_entity__main_page___uuid',
  relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___field_components = 'relationships___site_setting_entity__main_page___field_components',
  relationships___site_setting_entity__main_page___field_components___target_revision_id = 'relationships___site_setting_entity__main_page___field_components___target_revision_id',
  relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___id = 'relationships___site_setting_entity__main_page___id',
  relationships___site_setting_entity__main_page___parent___id = 'relationships___site_setting_entity__main_page___parent___id',
  relationships___site_setting_entity__main_page___parent___children = 'relationships___site_setting_entity__main_page___parent___children',
  relationships___site_setting_entity__main_page___children = 'relationships___site_setting_entity__main_page___children',
  relationships___site_setting_entity__main_page___children___id = 'relationships___site_setting_entity__main_page___children___id',
  relationships___site_setting_entity__main_page___children___children = 'relationships___site_setting_entity__main_page___children___children',
  relationships___site_setting_entity__main_page___internal___content = 'relationships___site_setting_entity__main_page___internal___content',
  relationships___site_setting_entity__main_page___internal___contentDigest = 'relationships___site_setting_entity__main_page___internal___contentDigest',
  relationships___site_setting_entity__main_page___internal___description = 'relationships___site_setting_entity__main_page___internal___description',
  relationships___site_setting_entity__main_page___internal___fieldOwners = 'relationships___site_setting_entity__main_page___internal___fieldOwners',
  relationships___site_setting_entity__main_page___internal___ignoreType = 'relationships___site_setting_entity__main_page___internal___ignoreType',
  relationships___site_setting_entity__main_page___internal___mediaType = 'relationships___site_setting_entity__main_page___internal___mediaType',
  relationships___site_setting_entity__main_page___internal___owner = 'relationships___site_setting_entity__main_page___internal___owner',
  relationships___site_setting_entity__main_page___internal___type = 'relationships___site_setting_entity__main_page___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  field_comprehensive_solution_ite = 'field_comprehensive_solution_ite',
  field_comprehensive_solution_ite___target_revision_id = 'field_comprehensive_solution_ite___target_revision_id',
  field_comprehensive_solution_ite___drupal_internal__target_id = 'field_comprehensive_solution_ite___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Comprehensive_SolutionFilterInput = {
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  relationships?: Maybe<Paragraph__Comprehensive_SolutionRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_comprehensive_solution_ite?: Maybe<
    Paragraph__Comprehensive_SolutionField_Comprehensive_Solution_IteFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Comprehensive_SolutionFilterListInput = {
  elemMatch?: Maybe<Paragraph__Comprehensive_SolutionFilterInput>;
};

export type Paragraph__Comprehensive_SolutionGroupConnection = {
  __typename?: 'paragraph__comprehensive_solutionGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Comprehensive_SolutionEdge>;
  nodes: Array<Paragraph__Comprehensive_Solution>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Comprehensive_SolutionRelationships = {
  __typename?: 'paragraph__comprehensive_solutionRelationships';
  field_comprehensive_solution_ite?: Maybe<Array<Maybe<Paragraph__Comprehensive_Solution_Item>>>;
  site_setting_entity__main_page?: Maybe<Array<Maybe<Site_Setting_Entity__Main_Page>>>;
};

export type Paragraph__Comprehensive_SolutionRelationshipsFilterInput = {
  field_comprehensive_solution_ite?: Maybe<Paragraph__Comprehensive_Solution_ItemFilterListInput>;
  site_setting_entity__main_page?: Maybe<Site_Setting_Entity__Main_PageFilterListInput>;
};

export type Paragraph__Comprehensive_SolutionSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Comprehensive_SolutionFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Discounts_Container = Node & {
  __typename?: 'paragraph__discounts_container';
  internal: Internal;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  field_text?: Maybe<Field_Text>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  relationships?: Maybe<Paragraph__Discounts_ContainerRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_discounts_item?: Maybe<Array<Maybe<Paragraph__Discounts_ContainerField_Discounts_Item>>>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Discounts_ContainerCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Discounts_ContainerConnection = {
  __typename?: 'paragraph__discounts_containerConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Discounts_ContainerEdge>;
  nodes: Array<Paragraph__Discounts_Container>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Discounts_ContainerGroupConnection>;
};

export type Paragraph__Discounts_ContainerConnectionDistinctArgs = {
  field: Paragraph__Discounts_ContainerFieldsEnum;
};

export type Paragraph__Discounts_ContainerConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Discounts_ContainerFieldsEnum;
};

export type Paragraph__Discounts_ContainerEdge = {
  __typename?: 'paragraph__discounts_containerEdge';
  next?: Maybe<Paragraph__Discounts_Container>;
  node: Paragraph__Discounts_Container;
  previous?: Maybe<Paragraph__Discounts_Container>;
};

export type Paragraph__Discounts_ContainerField_Discounts_Item = {
  __typename?: 'paragraph__discounts_containerField_discounts_item';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Discounts_ContainerField_Discounts_ItemFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Discounts_ContainerField_Discounts_ItemFilterListInput = {
  elemMatch?: Maybe<Paragraph__Discounts_ContainerField_Discounts_ItemFilterInput>;
};

export enum Paragraph__Discounts_ContainerFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  drupal_id = 'drupal_id',
  field_text___value = 'field_text___value',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  relationships___field_discounts_item = 'relationships___field_discounts_item',
  relationships___field_discounts_item___field_branches_text___value = 'relationships___field_discounts_item___field_branches_text___value',
  relationships___field_discounts_item___id = 'relationships___field_discounts_item___id',
  relationships___field_discounts_item___field_branches_button_text = 'relationships___field_discounts_item___field_branches_button_text',
  relationships___field_discounts_item___field_branches_image___alt = 'relationships___field_discounts_item___field_branches_image___alt',
  relationships___field_discounts_item___relationships___paragraph__discounts_container = 'relationships___field_discounts_item___relationships___paragraph__discounts_container',
  relationships___field_discounts_item___drupal_id = 'relationships___field_discounts_item___drupal_id',
  relationships___field_discounts_item___drupal_internal__id = 'relationships___field_discounts_item___drupal_internal__id',
  relationships___field_discounts_item___status = 'relationships___field_discounts_item___status',
  relationships___field_discounts_item___created = 'relationships___field_discounts_item___created',
  relationships___field_discounts_item___parent_id = 'relationships___field_discounts_item___parent_id',
  relationships___field_discounts_item___parent_type = 'relationships___field_discounts_item___parent_type',
  relationships___field_discounts_item___parent_field_name = 'relationships___field_discounts_item___parent_field_name',
  relationships___field_discounts_item___content_translation_source = 'relationships___field_discounts_item___content_translation_source',
  relationships___field_discounts_item___content_translation_outdated = 'relationships___field_discounts_item___content_translation_outdated',
  relationships___field_discounts_item___content_translation_changed = 'relationships___field_discounts_item___content_translation_changed',
  relationships___field_discounts_item___langcode = 'relationships___field_discounts_item___langcode',
  relationships___field_discounts_item___uuid = 'relationships___field_discounts_item___uuid',
  relationships___field_discounts_item___parent___id = 'relationships___field_discounts_item___parent___id',
  relationships___field_discounts_item___parent___children = 'relationships___field_discounts_item___parent___children',
  relationships___field_discounts_item___children = 'relationships___field_discounts_item___children',
  relationships___field_discounts_item___children___id = 'relationships___field_discounts_item___children___id',
  relationships___field_discounts_item___children___children = 'relationships___field_discounts_item___children___children',
  relationships___field_discounts_item___internal___content = 'relationships___field_discounts_item___internal___content',
  relationships___field_discounts_item___internal___contentDigest = 'relationships___field_discounts_item___internal___contentDigest',
  relationships___field_discounts_item___internal___description = 'relationships___field_discounts_item___internal___description',
  relationships___field_discounts_item___internal___fieldOwners = 'relationships___field_discounts_item___internal___fieldOwners',
  relationships___field_discounts_item___internal___ignoreType = 'relationships___field_discounts_item___internal___ignoreType',
  relationships___field_discounts_item___internal___mediaType = 'relationships___field_discounts_item___internal___mediaType',
  relationships___field_discounts_item___internal___owner = 'relationships___field_discounts_item___internal___owner',
  relationships___field_discounts_item___internal___type = 'relationships___field_discounts_item___internal___type',
  relationships___node__branches = 'relationships___node__branches',
  relationships___node__branches___langcode = 'relationships___node__branches___langcode',
  relationships___node__branches___id = 'relationships___node__branches___id',
  relationships___node__branches___drupal_id = 'relationships___node__branches___drupal_id',
  relationships___node__branches___title = 'relationships___node__branches___title',
  relationships___node__branches___field_pl_is_online = 'relationships___node__branches___field_pl_is_online',
  relationships___node__branches___field_branches_date = 'relationships___node__branches___field_branches_date',
  relationships___node__branches___field_weight = 'relationships___node__branches___field_weight',
  relationships___node__branches___path___alias = 'relationships___node__branches___path___alias',
  relationships___node__branches___field_meta_description = 'relationships___node__branches___field_meta_description',
  relationships___node__branches___field_meta_title = 'relationships___node__branches___field_meta_title',
  relationships___node__branches___relationships___node__accessories = 'relationships___node__branches___relationships___node__accessories',
  relationships___node__branches___relationships___node__cases = 'relationships___node__branches___relationships___node__cases',
  relationships___node__branches___relationships___node__devices = 'relationships___node__branches___relationships___node__devices',
  relationships___node__branches___relationships___node__tariffs = 'relationships___node__branches___relationships___node__tariffs',
  relationships___node__branches___drupal_internal__nid = 'relationships___node__branches___drupal_internal__nid',
  relationships___node__branches___status = 'relationships___node__branches___status',
  relationships___node__branches___content_translation_source = 'relationships___node__branches___content_translation_source',
  relationships___node__branches___content_translation_outdated = 'relationships___node__branches___content_translation_outdated',
  relationships___node__branches___uuid = 'relationships___node__branches___uuid',
  relationships___node__branches___field_branches_icon___alt = 'relationships___node__branches___field_branches_icon___alt',
  relationships___node__branches___field_branches_icon___title = 'relationships___node__branches___field_branches_icon___title',
  relationships___node__branches___field_branches_icon___width = 'relationships___node__branches___field_branches_icon___width',
  relationships___node__branches___field_branches_icon___height = 'relationships___node__branches___field_branches_icon___height',
  relationships___node__branches___field_branches_icon___drupal_internal__target_id = 'relationships___node__branches___field_branches_icon___drupal_internal__target_id',
  relationships___node__branches___field_branches_image___alt = 'relationships___node__branches___field_branches_image___alt',
  relationships___node__branches___field_branches_image___title = 'relationships___node__branches___field_branches_image___title',
  relationships___node__branches___field_branches_image___width = 'relationships___node__branches___field_branches_image___width',
  relationships___node__branches___field_branches_image___height = 'relationships___node__branches___field_branches_image___height',
  relationships___node__branches___field_branches_image___drupal_internal__target_id = 'relationships___node__branches___field_branches_image___drupal_internal__target_id',
  relationships___node__branches___field_components = 'relationships___node__branches___field_components',
  relationships___node__branches___field_components___target_revision_id = 'relationships___node__branches___field_components___target_revision_id',
  relationships___node__branches___field_components___drupal_internal__target_id = 'relationships___node__branches___field_components___drupal_internal__target_id',
  relationships___node__branches___parent___id = 'relationships___node__branches___parent___id',
  relationships___node__branches___parent___children = 'relationships___node__branches___parent___children',
  relationships___node__branches___children = 'relationships___node__branches___children',
  relationships___node__branches___children___id = 'relationships___node__branches___children___id',
  relationships___node__branches___children___children = 'relationships___node__branches___children___children',
  relationships___node__branches___internal___content = 'relationships___node__branches___internal___content',
  relationships___node__branches___internal___contentDigest = 'relationships___node__branches___internal___contentDigest',
  relationships___node__branches___internal___description = 'relationships___node__branches___internal___description',
  relationships___node__branches___internal___fieldOwners = 'relationships___node__branches___internal___fieldOwners',
  relationships___node__branches___internal___ignoreType = 'relationships___node__branches___internal___ignoreType',
  relationships___node__branches___internal___mediaType = 'relationships___node__branches___internal___mediaType',
  relationships___node__branches___internal___owner = 'relationships___node__branches___internal___owner',
  relationships___node__branches___internal___type = 'relationships___node__branches___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  langcode = 'langcode',
  uuid = 'uuid',
  field_discounts_item = 'field_discounts_item',
  field_discounts_item___target_revision_id = 'field_discounts_item___target_revision_id',
  field_discounts_item___drupal_internal__target_id = 'field_discounts_item___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Discounts_ContainerFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  relationships?: Maybe<Paragraph__Discounts_ContainerRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_discounts_item?: Maybe<Paragraph__Discounts_ContainerField_Discounts_ItemFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Discounts_ContainerFilterListInput = {
  elemMatch?: Maybe<Paragraph__Discounts_ContainerFilterInput>;
};

export type Paragraph__Discounts_ContainerGroupConnection = {
  __typename?: 'paragraph__discounts_containerGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Discounts_ContainerEdge>;
  nodes: Array<Paragraph__Discounts_Container>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Discounts_ContainerRelationships = {
  __typename?: 'paragraph__discounts_containerRelationships';
  field_discounts_item?: Maybe<Array<Maybe<Paragraph__Branches_Discounts>>>;
  node__branches?: Maybe<Array<Maybe<Node__Branches>>>;
};

export type Paragraph__Discounts_ContainerRelationshipsFilterInput = {
  field_discounts_item?: Maybe<Paragraph__Branches_DiscountsFilterListInput>;
  node__branches?: Maybe<Node__BranchesFilterListInput>;
};

export type Paragraph__Discounts_ContainerSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Discounts_ContainerFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Footer_Section = Node & {
  __typename?: 'paragraph__footer_section';
  id: Scalars['ID'];
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  field_section_header?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Footer_SectionRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_section_components?: Maybe<Array<Maybe<Paragraph__Footer_SectionField_Section_Components>>>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Footer_SectionCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Footer_SectionContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Footer_SectionConnection = {
  __typename?: 'paragraph__footer_sectionConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Footer_SectionEdge>;
  nodes: Array<Paragraph__Footer_Section>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Footer_SectionGroupConnection>;
};

export type Paragraph__Footer_SectionConnectionDistinctArgs = {
  field: Paragraph__Footer_SectionFieldsEnum;
};

export type Paragraph__Footer_SectionConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Footer_SectionFieldsEnum;
};

export type Paragraph__Footer_SectionEdge = {
  __typename?: 'paragraph__footer_sectionEdge';
  next?: Maybe<Paragraph__Footer_Section>;
  node: Paragraph__Footer_Section;
  previous?: Maybe<Paragraph__Footer_Section>;
};

export type Paragraph__Footer_SectionField_Section_Components = {
  __typename?: 'paragraph__footer_sectionField_section_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Footer_SectionField_Section_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Footer_SectionField_Section_ComponentsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Footer_SectionField_Section_ComponentsFilterInput>;
};

export enum Paragraph__Footer_SectionFieldsEnum {
  id = 'id',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  field_section_header = 'field_section_header',
  relationships___site_setting_entity__footer = 'relationships___site_setting_entity__footer',
  relationships___site_setting_entity__footer___langcode = 'relationships___site_setting_entity__footer___langcode',
  relationships___site_setting_entity__footer___relationships___field_footer_sections = 'relationships___site_setting_entity__footer___relationships___field_footer_sections',
  relationships___site_setting_entity__footer___relationships___field_footer_social_links = 'relationships___site_setting_entity__footer___relationships___field_footer_social_links',
  relationships___site_setting_entity__footer___drupal_id = 'relationships___site_setting_entity__footer___drupal_id',
  relationships___site_setting_entity__footer___drupal_internal__id = 'relationships___site_setting_entity__footer___drupal_internal__id',
  relationships___site_setting_entity__footer___name = 'relationships___site_setting_entity__footer___name',
  relationships___site_setting_entity__footer___fieldset = 'relationships___site_setting_entity__footer___fieldset',
  relationships___site_setting_entity__footer___status = 'relationships___site_setting_entity__footer___status',
  relationships___site_setting_entity__footer___created = 'relationships___site_setting_entity__footer___created',
  relationships___site_setting_entity__footer___changed = 'relationships___site_setting_entity__footer___changed',
  relationships___site_setting_entity__footer___default_langcode = 'relationships___site_setting_entity__footer___default_langcode',
  relationships___site_setting_entity__footer___content_translation_source = 'relationships___site_setting_entity__footer___content_translation_source',
  relationships___site_setting_entity__footer___content_translation_outdated = 'relationships___site_setting_entity__footer___content_translation_outdated',
  relationships___site_setting_entity__footer___content_translation_status = 'relationships___site_setting_entity__footer___content_translation_status',
  relationships___site_setting_entity__footer___content_translation_created = 'relationships___site_setting_entity__footer___content_translation_created',
  relationships___site_setting_entity__footer___content_translation_changed = 'relationships___site_setting_entity__footer___content_translation_changed',
  relationships___site_setting_entity__footer___uuid = 'relationships___site_setting_entity__footer___uuid',
  relationships___site_setting_entity__footer___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__footer___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__footer___field_footer_sections = 'relationships___site_setting_entity__footer___field_footer_sections',
  relationships___site_setting_entity__footer___field_footer_sections___target_revision_id = 'relationships___site_setting_entity__footer___field_footer_sections___target_revision_id',
  relationships___site_setting_entity__footer___field_footer_sections___drupal_internal__target_id = 'relationships___site_setting_entity__footer___field_footer_sections___drupal_internal__target_id',
  relationships___site_setting_entity__footer___field_footer_social_links = 'relationships___site_setting_entity__footer___field_footer_social_links',
  relationships___site_setting_entity__footer___field_footer_social_links___target_revision_id = 'relationships___site_setting_entity__footer___field_footer_social_links___target_revision_id',
  relationships___site_setting_entity__footer___field_footer_social_links___drupal_internal__target_id = 'relationships___site_setting_entity__footer___field_footer_social_links___drupal_internal__target_id',
  relationships___site_setting_entity__footer___id = 'relationships___site_setting_entity__footer___id',
  relationships___site_setting_entity__footer___parent___id = 'relationships___site_setting_entity__footer___parent___id',
  relationships___site_setting_entity__footer___parent___children = 'relationships___site_setting_entity__footer___parent___children',
  relationships___site_setting_entity__footer___children = 'relationships___site_setting_entity__footer___children',
  relationships___site_setting_entity__footer___children___id = 'relationships___site_setting_entity__footer___children___id',
  relationships___site_setting_entity__footer___children___children = 'relationships___site_setting_entity__footer___children___children',
  relationships___site_setting_entity__footer___internal___content = 'relationships___site_setting_entity__footer___internal___content',
  relationships___site_setting_entity__footer___internal___contentDigest = 'relationships___site_setting_entity__footer___internal___contentDigest',
  relationships___site_setting_entity__footer___internal___description = 'relationships___site_setting_entity__footer___internal___description',
  relationships___site_setting_entity__footer___internal___fieldOwners = 'relationships___site_setting_entity__footer___internal___fieldOwners',
  relationships___site_setting_entity__footer___internal___ignoreType = 'relationships___site_setting_entity__footer___internal___ignoreType',
  relationships___site_setting_entity__footer___internal___mediaType = 'relationships___site_setting_entity__footer___internal___mediaType',
  relationships___site_setting_entity__footer___internal___owner = 'relationships___site_setting_entity__footer___internal___owner',
  relationships___site_setting_entity__footer___internal___type = 'relationships___site_setting_entity__footer___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_section_components = 'field_section_components',
  field_section_components___target_revision_id = 'field_section_components___target_revision_id',
  field_section_components___drupal_internal__target_id = 'field_section_components___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Footer_SectionFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_section_header?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Footer_SectionRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_section_components?: Maybe<
    Paragraph__Footer_SectionField_Section_ComponentsFilterListInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Footer_SectionFilterListInput = {
  elemMatch?: Maybe<Paragraph__Footer_SectionFilterInput>;
};

export type Paragraph__Footer_SectionGroupConnection = {
  __typename?: 'paragraph__footer_sectionGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Footer_SectionEdge>;
  nodes: Array<Paragraph__Footer_Section>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Footer_SectionRelationships = {
  __typename?: 'paragraph__footer_sectionRelationships';
  field_section_components?: Maybe<Array<Maybe<SectionComponents>>>;
  site_setting_entity__footer?: Maybe<Array<Maybe<Site_Setting_Entity__Footer>>>;
};

export type Paragraph__Footer_SectionRelationshipsFilterInput = {
  site_setting_entity__footer?: Maybe<Site_Setting_Entity__FooterFilterListInput>;
};

export type Paragraph__Footer_SectionSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Footer_SectionFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Heading_And_Agreements = Node & {
  __typename?: 'paragraph__heading_and_agreements';
  field_heading_and_agrmnt_heading?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Heading_And_AgreementsRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['Date']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_agreements?: Maybe<Array<Maybe<Paragraph__Heading_And_AgreementsField_Agreements>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Heading_And_AgreementsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_AgreementsParent_IdArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_AgreementsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_AgreementsConnection = {
  __typename?: 'paragraph__heading_and_agreementsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Heading_And_AgreementsEdge>;
  nodes: Array<Paragraph__Heading_And_Agreements>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Heading_And_AgreementsGroupConnection>;
};

export type Paragraph__Heading_And_AgreementsConnectionDistinctArgs = {
  field: Paragraph__Heading_And_AgreementsFieldsEnum;
};

export type Paragraph__Heading_And_AgreementsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Heading_And_AgreementsFieldsEnum;
};

export type Paragraph__Heading_And_AgreementsEdge = {
  __typename?: 'paragraph__heading_and_agreementsEdge';
  next?: Maybe<Paragraph__Heading_And_Agreements>;
  node: Paragraph__Heading_And_Agreements;
  previous?: Maybe<Paragraph__Heading_And_Agreements>;
};

export type Paragraph__Heading_And_AgreementsField_Agreements = {
  __typename?: 'paragraph__heading_and_agreementsField_agreements';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Heading_And_AgreementsField_AgreementsFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Heading_And_AgreementsField_AgreementsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Heading_And_AgreementsField_AgreementsFilterInput>;
};

export enum Paragraph__Heading_And_AgreementsFieldsEnum {
  field_heading_and_agrmnt_heading = 'field_heading_and_agrmnt_heading',
  relationships___field_agreements = 'relationships___field_agreements',
  relationships___field_agreements___langcode = 'relationships___field_agreements___langcode',
  relationships___field_agreements___drupal_id = 'relationships___field_agreements___drupal_id',
  relationships___field_agreements___agreement_content___value = 'relationships___field_agreements___agreement_content___value',
  relationships___field_agreements___agreement_explication___value = 'relationships___field_agreements___agreement_explication___value',
  relationships___field_agreements___agreement_type = 'relationships___field_agreements___agreement_type',
  relationships___field_agreements___agreement_pdf_title = 'relationships___field_agreements___agreement_pdf_title',
  relationships___field_agreements___relationships___agreement_payment_types = 'relationships___field_agreements___relationships___agreement_payment_types',
  relationships___field_agreements___relationships___paragraph__heading_and_agreements = 'relationships___field_agreements___relationships___paragraph__heading_and_agreements',
  relationships___field_agreements___drupal_internal__nid = 'relationships___field_agreements___drupal_internal__nid',
  relationships___field_agreements___drupal_internal__vid = 'relationships___field_agreements___drupal_internal__vid',
  relationships___field_agreements___status = 'relationships___field_agreements___status',
  relationships___field_agreements___title = 'relationships___field_agreements___title',
  relationships___field_agreements___created = 'relationships___field_agreements___created',
  relationships___field_agreements___changed = 'relationships___field_agreements___changed',
  relationships___field_agreements___uuid = 'relationships___field_agreements___uuid',
  relationships___field_agreements___agreement_payment_types = 'relationships___field_agreements___agreement_payment_types',
  relationships___field_agreements___agreement_payment_types___drupal_internal__target_id = 'relationships___field_agreements___agreement_payment_types___drupal_internal__target_id',
  relationships___field_agreements___agreement_pdf___drupal_internal__target_id = 'relationships___field_agreements___agreement_pdf___drupal_internal__target_id',
  relationships___field_agreements___id = 'relationships___field_agreements___id',
  relationships___field_agreements___parent___id = 'relationships___field_agreements___parent___id',
  relationships___field_agreements___parent___children = 'relationships___field_agreements___parent___children',
  relationships___field_agreements___children = 'relationships___field_agreements___children',
  relationships___field_agreements___children___id = 'relationships___field_agreements___children___id',
  relationships___field_agreements___children___children = 'relationships___field_agreements___children___children',
  relationships___field_agreements___internal___content = 'relationships___field_agreements___internal___content',
  relationships___field_agreements___internal___contentDigest = 'relationships___field_agreements___internal___contentDigest',
  relationships___field_agreements___internal___description = 'relationships___field_agreements___internal___description',
  relationships___field_agreements___internal___fieldOwners = 'relationships___field_agreements___internal___fieldOwners',
  relationships___field_agreements___internal___ignoreType = 'relationships___field_agreements___internal___ignoreType',
  relationships___field_agreements___internal___mediaType = 'relationships___field_agreements___internal___mediaType',
  relationships___field_agreements___internal___owner = 'relationships___field_agreements___internal___owner',
  relationships___field_agreements___internal___type = 'relationships___field_agreements___internal___type',
  relationships___paragraph__footer_section = 'relationships___paragraph__footer_section',
  relationships___paragraph__footer_section___id = 'relationships___paragraph__footer_section___id',
  relationships___paragraph__footer_section___internal___content = 'relationships___paragraph__footer_section___internal___content',
  relationships___paragraph__footer_section___internal___contentDigest = 'relationships___paragraph__footer_section___internal___contentDigest',
  relationships___paragraph__footer_section___internal___description = 'relationships___paragraph__footer_section___internal___description',
  relationships___paragraph__footer_section___internal___fieldOwners = 'relationships___paragraph__footer_section___internal___fieldOwners',
  relationships___paragraph__footer_section___internal___ignoreType = 'relationships___paragraph__footer_section___internal___ignoreType',
  relationships___paragraph__footer_section___internal___mediaType = 'relationships___paragraph__footer_section___internal___mediaType',
  relationships___paragraph__footer_section___internal___owner = 'relationships___paragraph__footer_section___internal___owner',
  relationships___paragraph__footer_section___internal___type = 'relationships___paragraph__footer_section___internal___type',
  relationships___paragraph__footer_section___drupal_id = 'relationships___paragraph__footer_section___drupal_id',
  relationships___paragraph__footer_section___field_section_header = 'relationships___paragraph__footer_section___field_section_header',
  relationships___paragraph__footer_section___relationships___site_setting_entity__footer = 'relationships___paragraph__footer_section___relationships___site_setting_entity__footer',
  relationships___paragraph__footer_section___drupal_internal__id = 'relationships___paragraph__footer_section___drupal_internal__id',
  relationships___paragraph__footer_section___drupal_internal__revision_id = 'relationships___paragraph__footer_section___drupal_internal__revision_id',
  relationships___paragraph__footer_section___langcode = 'relationships___paragraph__footer_section___langcode',
  relationships___paragraph__footer_section___status = 'relationships___paragraph__footer_section___status',
  relationships___paragraph__footer_section___created = 'relationships___paragraph__footer_section___created',
  relationships___paragraph__footer_section___parent_id = 'relationships___paragraph__footer_section___parent_id',
  relationships___paragraph__footer_section___parent_type = 'relationships___paragraph__footer_section___parent_type',
  relationships___paragraph__footer_section___parent_field_name = 'relationships___paragraph__footer_section___parent_field_name',
  relationships___paragraph__footer_section___default_langcode = 'relationships___paragraph__footer_section___default_langcode',
  relationships___paragraph__footer_section___revision_translation_affected = 'relationships___paragraph__footer_section___revision_translation_affected',
  relationships___paragraph__footer_section___content_translation_source = 'relationships___paragraph__footer_section___content_translation_source',
  relationships___paragraph__footer_section___content_translation_outdated = 'relationships___paragraph__footer_section___content_translation_outdated',
  relationships___paragraph__footer_section___content_translation_changed = 'relationships___paragraph__footer_section___content_translation_changed',
  relationships___paragraph__footer_section___uuid = 'relationships___paragraph__footer_section___uuid',
  relationships___paragraph__footer_section___field_section_components = 'relationships___paragraph__footer_section___field_section_components',
  relationships___paragraph__footer_section___field_section_components___target_revision_id = 'relationships___paragraph__footer_section___field_section_components___target_revision_id',
  relationships___paragraph__footer_section___field_section_components___drupal_internal__target_id = 'relationships___paragraph__footer_section___field_section_components___drupal_internal__target_id',
  relationships___paragraph__footer_section___parent___id = 'relationships___paragraph__footer_section___parent___id',
  relationships___paragraph__footer_section___parent___children = 'relationships___paragraph__footer_section___parent___children',
  relationships___paragraph__footer_section___children = 'relationships___paragraph__footer_section___children',
  relationships___paragraph__footer_section___children___id = 'relationships___paragraph__footer_section___children___id',
  relationships___paragraph__footer_section___children___children = 'relationships___paragraph__footer_section___children___children',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_agreements = 'field_agreements',
  field_agreements___drupal_internal__target_id = 'field_agreements___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Heading_And_AgreementsFilterInput = {
  field_heading_and_agrmnt_heading?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Heading_And_AgreementsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_agreements?: Maybe<Paragraph__Heading_And_AgreementsField_AgreementsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Heading_And_AgreementsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Heading_And_AgreementsFilterInput>;
};

export type Paragraph__Heading_And_AgreementsGroupConnection = {
  __typename?: 'paragraph__heading_and_agreementsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Heading_And_AgreementsEdge>;
  nodes: Array<Paragraph__Heading_And_Agreements>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_AgreementsRelationships = {
  __typename?: 'paragraph__heading_and_agreementsRelationships';
  field_agreements?: Maybe<Array<Maybe<Node__Agreement>>>;
  paragraph__footer_section?: Maybe<Array<Maybe<Paragraph__Footer_Section>>>;
};

export type Paragraph__Heading_And_AgreementsRelationshipsFilterInput = {
  field_agreements?: Maybe<Node__AgreementFilterListInput>;
  paragraph__footer_section?: Maybe<Paragraph__Footer_SectionFilterListInput>;
};

export type Paragraph__Heading_And_AgreementsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Heading_And_AgreementsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Heading_And_Links = Node & {
  __typename?: 'paragraph__heading_and_links';
  field_heading_and_links_heading?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Heading_And_LinksRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['Date']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_heading_and_links_links?: Maybe<
    Array<Maybe<Paragraph__Heading_And_LinksField_Heading_And_Links_Links>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Heading_And_LinksCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_LinksParent_IdArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_LinksContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_LinksConnection = {
  __typename?: 'paragraph__heading_and_linksConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Heading_And_LinksEdge>;
  nodes: Array<Paragraph__Heading_And_Links>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Heading_And_LinksGroupConnection>;
};

export type Paragraph__Heading_And_LinksConnectionDistinctArgs = {
  field: Paragraph__Heading_And_LinksFieldsEnum;
};

export type Paragraph__Heading_And_LinksConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Heading_And_LinksFieldsEnum;
};

export type Paragraph__Heading_And_LinksEdge = {
  __typename?: 'paragraph__heading_and_linksEdge';
  next?: Maybe<Paragraph__Heading_And_Links>;
  node: Paragraph__Heading_And_Links;
  previous?: Maybe<Paragraph__Heading_And_Links>;
};

export type Paragraph__Heading_And_LinksField_Heading_And_Links_Links = {
  __typename?: 'paragraph__heading_and_linksField_heading_and_links_links';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Heading_And_LinksField_Heading_And_Links_LinksFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Heading_And_LinksField_Heading_And_Links_LinksFilterListInput = {
  elemMatch?: Maybe<Paragraph__Heading_And_LinksField_Heading_And_Links_LinksFilterInput>;
};

export enum Paragraph__Heading_And_LinksFieldsEnum {
  field_heading_and_links_heading = 'field_heading_and_links_heading',
  relationships___field_heading_and_links_links = 'relationships___field_heading_and_links_links',
  relationships___field_heading_and_links_links___field_link_with_text_text = 'relationships___field_heading_and_links_links___field_link_with_text_text',
  relationships___field_heading_and_links_links___field_link_with_text_link = 'relationships___field_heading_and_links_links___field_link_with_text_link',
  relationships___field_heading_and_links_links___drupal_id = 'relationships___field_heading_and_links_links___drupal_id',
  relationships___field_heading_and_links_links___drupal_internal__id = 'relationships___field_heading_and_links_links___drupal_internal__id',
  relationships___field_heading_and_links_links___drupal_internal__revision_id = 'relationships___field_heading_and_links_links___drupal_internal__revision_id',
  relationships___field_heading_and_links_links___langcode = 'relationships___field_heading_and_links_links___langcode',
  relationships___field_heading_and_links_links___status = 'relationships___field_heading_and_links_links___status',
  relationships___field_heading_and_links_links___created = 'relationships___field_heading_and_links_links___created',
  relationships___field_heading_and_links_links___parent_id = 'relationships___field_heading_and_links_links___parent_id',
  relationships___field_heading_and_links_links___parent_type = 'relationships___field_heading_and_links_links___parent_type',
  relationships___field_heading_and_links_links___parent_field_name = 'relationships___field_heading_and_links_links___parent_field_name',
  relationships___field_heading_and_links_links___default_langcode = 'relationships___field_heading_and_links_links___default_langcode',
  relationships___field_heading_and_links_links___revision_translation_affected = 'relationships___field_heading_and_links_links___revision_translation_affected',
  relationships___field_heading_and_links_links___content_translation_source = 'relationships___field_heading_and_links_links___content_translation_source',
  relationships___field_heading_and_links_links___content_translation_outdated = 'relationships___field_heading_and_links_links___content_translation_outdated',
  relationships___field_heading_and_links_links___content_translation_changed = 'relationships___field_heading_and_links_links___content_translation_changed',
  relationships___field_heading_and_links_links___uuid = 'relationships___field_heading_and_links_links___uuid',
  relationships___field_heading_and_links_links___relationships___paragraph__heading_and_links = 'relationships___field_heading_and_links_links___relationships___paragraph__heading_and_links',
  relationships___field_heading_and_links_links___relationships___paragraph__heading_and_text = 'relationships___field_heading_and_links_links___relationships___paragraph__heading_and_text',
  relationships___field_heading_and_links_links___relationships___paragraph__make_an_appointment = 'relationships___field_heading_and_links_links___relationships___paragraph__make_an_appointment',
  relationships___field_heading_and_links_links___id = 'relationships___field_heading_and_links_links___id',
  relationships___field_heading_and_links_links___parent___id = 'relationships___field_heading_and_links_links___parent___id',
  relationships___field_heading_and_links_links___parent___children = 'relationships___field_heading_and_links_links___parent___children',
  relationships___field_heading_and_links_links___children = 'relationships___field_heading_and_links_links___children',
  relationships___field_heading_and_links_links___children___id = 'relationships___field_heading_and_links_links___children___id',
  relationships___field_heading_and_links_links___children___children = 'relationships___field_heading_and_links_links___children___children',
  relationships___field_heading_and_links_links___internal___content = 'relationships___field_heading_and_links_links___internal___content',
  relationships___field_heading_and_links_links___internal___contentDigest = 'relationships___field_heading_and_links_links___internal___contentDigest',
  relationships___field_heading_and_links_links___internal___description = 'relationships___field_heading_and_links_links___internal___description',
  relationships___field_heading_and_links_links___internal___fieldOwners = 'relationships___field_heading_and_links_links___internal___fieldOwners',
  relationships___field_heading_and_links_links___internal___ignoreType = 'relationships___field_heading_and_links_links___internal___ignoreType',
  relationships___field_heading_and_links_links___internal___mediaType = 'relationships___field_heading_and_links_links___internal___mediaType',
  relationships___field_heading_and_links_links___internal___owner = 'relationships___field_heading_and_links_links___internal___owner',
  relationships___field_heading_and_links_links___internal___type = 'relationships___field_heading_and_links_links___internal___type',
  relationships___paragraph__footer_section = 'relationships___paragraph__footer_section',
  relationships___paragraph__footer_section___id = 'relationships___paragraph__footer_section___id',
  relationships___paragraph__footer_section___internal___content = 'relationships___paragraph__footer_section___internal___content',
  relationships___paragraph__footer_section___internal___contentDigest = 'relationships___paragraph__footer_section___internal___contentDigest',
  relationships___paragraph__footer_section___internal___description = 'relationships___paragraph__footer_section___internal___description',
  relationships___paragraph__footer_section___internal___fieldOwners = 'relationships___paragraph__footer_section___internal___fieldOwners',
  relationships___paragraph__footer_section___internal___ignoreType = 'relationships___paragraph__footer_section___internal___ignoreType',
  relationships___paragraph__footer_section___internal___mediaType = 'relationships___paragraph__footer_section___internal___mediaType',
  relationships___paragraph__footer_section___internal___owner = 'relationships___paragraph__footer_section___internal___owner',
  relationships___paragraph__footer_section___internal___type = 'relationships___paragraph__footer_section___internal___type',
  relationships___paragraph__footer_section___drupal_id = 'relationships___paragraph__footer_section___drupal_id',
  relationships___paragraph__footer_section___field_section_header = 'relationships___paragraph__footer_section___field_section_header',
  relationships___paragraph__footer_section___relationships___site_setting_entity__footer = 'relationships___paragraph__footer_section___relationships___site_setting_entity__footer',
  relationships___paragraph__footer_section___drupal_internal__id = 'relationships___paragraph__footer_section___drupal_internal__id',
  relationships___paragraph__footer_section___drupal_internal__revision_id = 'relationships___paragraph__footer_section___drupal_internal__revision_id',
  relationships___paragraph__footer_section___langcode = 'relationships___paragraph__footer_section___langcode',
  relationships___paragraph__footer_section___status = 'relationships___paragraph__footer_section___status',
  relationships___paragraph__footer_section___created = 'relationships___paragraph__footer_section___created',
  relationships___paragraph__footer_section___parent_id = 'relationships___paragraph__footer_section___parent_id',
  relationships___paragraph__footer_section___parent_type = 'relationships___paragraph__footer_section___parent_type',
  relationships___paragraph__footer_section___parent_field_name = 'relationships___paragraph__footer_section___parent_field_name',
  relationships___paragraph__footer_section___default_langcode = 'relationships___paragraph__footer_section___default_langcode',
  relationships___paragraph__footer_section___revision_translation_affected = 'relationships___paragraph__footer_section___revision_translation_affected',
  relationships___paragraph__footer_section___content_translation_source = 'relationships___paragraph__footer_section___content_translation_source',
  relationships___paragraph__footer_section___content_translation_outdated = 'relationships___paragraph__footer_section___content_translation_outdated',
  relationships___paragraph__footer_section___content_translation_changed = 'relationships___paragraph__footer_section___content_translation_changed',
  relationships___paragraph__footer_section___uuid = 'relationships___paragraph__footer_section___uuid',
  relationships___paragraph__footer_section___field_section_components = 'relationships___paragraph__footer_section___field_section_components',
  relationships___paragraph__footer_section___field_section_components___target_revision_id = 'relationships___paragraph__footer_section___field_section_components___target_revision_id',
  relationships___paragraph__footer_section___field_section_components___drupal_internal__target_id = 'relationships___paragraph__footer_section___field_section_components___drupal_internal__target_id',
  relationships___paragraph__footer_section___parent___id = 'relationships___paragraph__footer_section___parent___id',
  relationships___paragraph__footer_section___parent___children = 'relationships___paragraph__footer_section___parent___children',
  relationships___paragraph__footer_section___children = 'relationships___paragraph__footer_section___children',
  relationships___paragraph__footer_section___children___id = 'relationships___paragraph__footer_section___children___id',
  relationships___paragraph__footer_section___children___children = 'relationships___paragraph__footer_section___children___children',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_heading_and_links_links = 'field_heading_and_links_links',
  field_heading_and_links_links___target_revision_id = 'field_heading_and_links_links___target_revision_id',
  field_heading_and_links_links___drupal_internal__target_id = 'field_heading_and_links_links___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Heading_And_LinksFilterInput = {
  field_heading_and_links_heading?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Heading_And_LinksRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_heading_and_links_links?: Maybe<
    Paragraph__Heading_And_LinksField_Heading_And_Links_LinksFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Heading_And_LinksFilterListInput = {
  elemMatch?: Maybe<Paragraph__Heading_And_LinksFilterInput>;
};

export type Paragraph__Heading_And_LinksGroupConnection = {
  __typename?: 'paragraph__heading_and_linksGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Heading_And_LinksEdge>;
  nodes: Array<Paragraph__Heading_And_Links>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_LinksRelationships = {
  __typename?: 'paragraph__heading_and_linksRelationships';
  field_heading_and_links_links?: Maybe<Array<Maybe<Paragraph__Link_With_Text>>>;
  paragraph__footer_section?: Maybe<Array<Maybe<Paragraph__Footer_Section>>>;
};

export type Paragraph__Heading_And_LinksRelationshipsFilterInput = {
  field_heading_and_links_links?: Maybe<Paragraph__Link_With_TextFilterListInput>;
  paragraph__footer_section?: Maybe<Paragraph__Footer_SectionFilterListInput>;
};

export type Paragraph__Heading_And_LinksSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Heading_And_LinksFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Heading_And_Text = Node & {
  __typename?: 'paragraph__heading_and_text';
  field_heading_and_text_heading?: Maybe<Scalars['String']>;
  field_heading_and_text_text?: Maybe<Field_Heading_And_Text_Text>;
  relationships?: Maybe<Paragraph__Heading_And_TextRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['Date']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_heading_and_text_links?: Maybe<
    Array<Maybe<Paragraph__Heading_And_TextField_Heading_And_Text_Links>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Heading_And_TextCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_TextParent_IdArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_TextContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_TextConnection = {
  __typename?: 'paragraph__heading_and_textConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Heading_And_TextEdge>;
  nodes: Array<Paragraph__Heading_And_Text>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Heading_And_TextGroupConnection>;
};

export type Paragraph__Heading_And_TextConnectionDistinctArgs = {
  field: Paragraph__Heading_And_TextFieldsEnum;
};

export type Paragraph__Heading_And_TextConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Heading_And_TextFieldsEnum;
};

export type Paragraph__Heading_And_TextEdge = {
  __typename?: 'paragraph__heading_and_textEdge';
  next?: Maybe<Paragraph__Heading_And_Text>;
  node: Paragraph__Heading_And_Text;
  previous?: Maybe<Paragraph__Heading_And_Text>;
};

export type Paragraph__Heading_And_TextField_Heading_And_Text_Links = {
  __typename?: 'paragraph__heading_and_textField_heading_and_text_links';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Heading_And_TextField_Heading_And_Text_LinksFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Heading_And_TextField_Heading_And_Text_LinksFilterListInput = {
  elemMatch?: Maybe<Paragraph__Heading_And_TextField_Heading_And_Text_LinksFilterInput>;
};

export enum Paragraph__Heading_And_TextFieldsEnum {
  field_heading_and_text_heading = 'field_heading_and_text_heading',
  field_heading_and_text_text___value = 'field_heading_and_text_text___value',
  relationships___field_heading_and_text_links = 'relationships___field_heading_and_text_links',
  relationships___field_heading_and_text_links___field_link_with_text_text = 'relationships___field_heading_and_text_links___field_link_with_text_text',
  relationships___field_heading_and_text_links___field_link_with_text_link = 'relationships___field_heading_and_text_links___field_link_with_text_link',
  relationships___field_heading_and_text_links___drupal_id = 'relationships___field_heading_and_text_links___drupal_id',
  relationships___field_heading_and_text_links___drupal_internal__id = 'relationships___field_heading_and_text_links___drupal_internal__id',
  relationships___field_heading_and_text_links___drupal_internal__revision_id = 'relationships___field_heading_and_text_links___drupal_internal__revision_id',
  relationships___field_heading_and_text_links___langcode = 'relationships___field_heading_and_text_links___langcode',
  relationships___field_heading_and_text_links___status = 'relationships___field_heading_and_text_links___status',
  relationships___field_heading_and_text_links___created = 'relationships___field_heading_and_text_links___created',
  relationships___field_heading_and_text_links___parent_id = 'relationships___field_heading_and_text_links___parent_id',
  relationships___field_heading_and_text_links___parent_type = 'relationships___field_heading_and_text_links___parent_type',
  relationships___field_heading_and_text_links___parent_field_name = 'relationships___field_heading_and_text_links___parent_field_name',
  relationships___field_heading_and_text_links___default_langcode = 'relationships___field_heading_and_text_links___default_langcode',
  relationships___field_heading_and_text_links___revision_translation_affected = 'relationships___field_heading_and_text_links___revision_translation_affected',
  relationships___field_heading_and_text_links___content_translation_source = 'relationships___field_heading_and_text_links___content_translation_source',
  relationships___field_heading_and_text_links___content_translation_outdated = 'relationships___field_heading_and_text_links___content_translation_outdated',
  relationships___field_heading_and_text_links___content_translation_changed = 'relationships___field_heading_and_text_links___content_translation_changed',
  relationships___field_heading_and_text_links___uuid = 'relationships___field_heading_and_text_links___uuid',
  relationships___field_heading_and_text_links___relationships___paragraph__heading_and_links = 'relationships___field_heading_and_text_links___relationships___paragraph__heading_and_links',
  relationships___field_heading_and_text_links___relationships___paragraph__heading_and_text = 'relationships___field_heading_and_text_links___relationships___paragraph__heading_and_text',
  relationships___field_heading_and_text_links___relationships___paragraph__make_an_appointment = 'relationships___field_heading_and_text_links___relationships___paragraph__make_an_appointment',
  relationships___field_heading_and_text_links___id = 'relationships___field_heading_and_text_links___id',
  relationships___field_heading_and_text_links___parent___id = 'relationships___field_heading_and_text_links___parent___id',
  relationships___field_heading_and_text_links___parent___children = 'relationships___field_heading_and_text_links___parent___children',
  relationships___field_heading_and_text_links___children = 'relationships___field_heading_and_text_links___children',
  relationships___field_heading_and_text_links___children___id = 'relationships___field_heading_and_text_links___children___id',
  relationships___field_heading_and_text_links___children___children = 'relationships___field_heading_and_text_links___children___children',
  relationships___field_heading_and_text_links___internal___content = 'relationships___field_heading_and_text_links___internal___content',
  relationships___field_heading_and_text_links___internal___contentDigest = 'relationships___field_heading_and_text_links___internal___contentDigest',
  relationships___field_heading_and_text_links___internal___description = 'relationships___field_heading_and_text_links___internal___description',
  relationships___field_heading_and_text_links___internal___fieldOwners = 'relationships___field_heading_and_text_links___internal___fieldOwners',
  relationships___field_heading_and_text_links___internal___ignoreType = 'relationships___field_heading_and_text_links___internal___ignoreType',
  relationships___field_heading_and_text_links___internal___mediaType = 'relationships___field_heading_and_text_links___internal___mediaType',
  relationships___field_heading_and_text_links___internal___owner = 'relationships___field_heading_and_text_links___internal___owner',
  relationships___field_heading_and_text_links___internal___type = 'relationships___field_heading_and_text_links___internal___type',
  relationships___paragraph__footer_section = 'relationships___paragraph__footer_section',
  relationships___paragraph__footer_section___id = 'relationships___paragraph__footer_section___id',
  relationships___paragraph__footer_section___internal___content = 'relationships___paragraph__footer_section___internal___content',
  relationships___paragraph__footer_section___internal___contentDigest = 'relationships___paragraph__footer_section___internal___contentDigest',
  relationships___paragraph__footer_section___internal___description = 'relationships___paragraph__footer_section___internal___description',
  relationships___paragraph__footer_section___internal___fieldOwners = 'relationships___paragraph__footer_section___internal___fieldOwners',
  relationships___paragraph__footer_section___internal___ignoreType = 'relationships___paragraph__footer_section___internal___ignoreType',
  relationships___paragraph__footer_section___internal___mediaType = 'relationships___paragraph__footer_section___internal___mediaType',
  relationships___paragraph__footer_section___internal___owner = 'relationships___paragraph__footer_section___internal___owner',
  relationships___paragraph__footer_section___internal___type = 'relationships___paragraph__footer_section___internal___type',
  relationships___paragraph__footer_section___drupal_id = 'relationships___paragraph__footer_section___drupal_id',
  relationships___paragraph__footer_section___field_section_header = 'relationships___paragraph__footer_section___field_section_header',
  relationships___paragraph__footer_section___relationships___site_setting_entity__footer = 'relationships___paragraph__footer_section___relationships___site_setting_entity__footer',
  relationships___paragraph__footer_section___drupal_internal__id = 'relationships___paragraph__footer_section___drupal_internal__id',
  relationships___paragraph__footer_section___drupal_internal__revision_id = 'relationships___paragraph__footer_section___drupal_internal__revision_id',
  relationships___paragraph__footer_section___langcode = 'relationships___paragraph__footer_section___langcode',
  relationships___paragraph__footer_section___status = 'relationships___paragraph__footer_section___status',
  relationships___paragraph__footer_section___created = 'relationships___paragraph__footer_section___created',
  relationships___paragraph__footer_section___parent_id = 'relationships___paragraph__footer_section___parent_id',
  relationships___paragraph__footer_section___parent_type = 'relationships___paragraph__footer_section___parent_type',
  relationships___paragraph__footer_section___parent_field_name = 'relationships___paragraph__footer_section___parent_field_name',
  relationships___paragraph__footer_section___default_langcode = 'relationships___paragraph__footer_section___default_langcode',
  relationships___paragraph__footer_section___revision_translation_affected = 'relationships___paragraph__footer_section___revision_translation_affected',
  relationships___paragraph__footer_section___content_translation_source = 'relationships___paragraph__footer_section___content_translation_source',
  relationships___paragraph__footer_section___content_translation_outdated = 'relationships___paragraph__footer_section___content_translation_outdated',
  relationships___paragraph__footer_section___content_translation_changed = 'relationships___paragraph__footer_section___content_translation_changed',
  relationships___paragraph__footer_section___uuid = 'relationships___paragraph__footer_section___uuid',
  relationships___paragraph__footer_section___field_section_components = 'relationships___paragraph__footer_section___field_section_components',
  relationships___paragraph__footer_section___field_section_components___target_revision_id = 'relationships___paragraph__footer_section___field_section_components___target_revision_id',
  relationships___paragraph__footer_section___field_section_components___drupal_internal__target_id = 'relationships___paragraph__footer_section___field_section_components___drupal_internal__target_id',
  relationships___paragraph__footer_section___parent___id = 'relationships___paragraph__footer_section___parent___id',
  relationships___paragraph__footer_section___parent___children = 'relationships___paragraph__footer_section___parent___children',
  relationships___paragraph__footer_section___children = 'relationships___paragraph__footer_section___children',
  relationships___paragraph__footer_section___children___id = 'relationships___paragraph__footer_section___children___id',
  relationships___paragraph__footer_section___children___children = 'relationships___paragraph__footer_section___children___children',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_heading_and_text_links = 'field_heading_and_text_links',
  field_heading_and_text_links___target_revision_id = 'field_heading_and_text_links___target_revision_id',
  field_heading_and_text_links___drupal_internal__target_id = 'field_heading_and_text_links___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Heading_And_TextFilterInput = {
  field_heading_and_text_heading?: Maybe<StringQueryOperatorInput>;
  field_heading_and_text_text?: Maybe<Field_Heading_And_Text_TextFilterInput>;
  relationships?: Maybe<Paragraph__Heading_And_TextRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_heading_and_text_links?: Maybe<
    Paragraph__Heading_And_TextField_Heading_And_Text_LinksFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Heading_And_TextFilterListInput = {
  elemMatch?: Maybe<Paragraph__Heading_And_TextFilterInput>;
};

export type Paragraph__Heading_And_TextGroupConnection = {
  __typename?: 'paragraph__heading_and_textGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Heading_And_TextEdge>;
  nodes: Array<Paragraph__Heading_And_Text>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Heading_And_TextRelationships = {
  __typename?: 'paragraph__heading_and_textRelationships';
  field_heading_and_text_links?: Maybe<Array<Maybe<Paragraph__Link_With_Text>>>;
  paragraph__footer_section?: Maybe<Array<Maybe<Paragraph__Footer_Section>>>;
};

export type Paragraph__Heading_And_TextRelationshipsFilterInput = {
  field_heading_and_text_links?: Maybe<Paragraph__Link_With_TextFilterListInput>;
  paragraph__footer_section?: Maybe<Paragraph__Footer_SectionFilterListInput>;
};

export type Paragraph__Heading_And_TextSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Heading_And_TextFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Hero_1 = Node & {
  __typename?: 'paragraph__hero_1';
  field_hero_1_button?: Maybe<Scalars['String']>;
  field_hero_1_video?: Maybe<Scalars['String']>;
  field_hero_1_image?: Maybe<Field_Hero_1_Image>;
  field_hero_1_image_mobile?: Maybe<Field_Hero_1_Image_Mobile>;
  field_hero_1_subtitle?: Maybe<Field_Hero_1_Subtitle>;
  field_hero_1_text_1?: Maybe<Field_Hero_1_Text_1>;
  field_hero_1_text_2?: Maybe<Field_Hero_1_Text_2>;
  field_hero_1_title?: Maybe<Field_Hero_1_Title>;
  field_video_thumbnail?: Maybe<Field_Video_Thumbnail>;
  relationships?: Maybe<Paragraph__Hero_1Relationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Hero_1CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Hero_1Connection = {
  __typename?: 'paragraph__hero_1Connection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Hero_1Edge>;
  nodes: Array<Paragraph__Hero_1>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Hero_1GroupConnection>;
};

export type Paragraph__Hero_1ConnectionDistinctArgs = {
  field: Paragraph__Hero_1FieldsEnum;
};

export type Paragraph__Hero_1ConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Hero_1FieldsEnum;
};

export type Paragraph__Hero_1Edge = {
  __typename?: 'paragraph__hero_1Edge';
  next?: Maybe<Paragraph__Hero_1>;
  node: Paragraph__Hero_1;
  previous?: Maybe<Paragraph__Hero_1>;
};

export enum Paragraph__Hero_1FieldsEnum {
  field_hero_1_button = 'field_hero_1_button',
  field_hero_1_video = 'field_hero_1_video',
  field_hero_1_image___alt = 'field_hero_1_image___alt',
  field_hero_1_image_mobile___alt = 'field_hero_1_image_mobile___alt',
  field_hero_1_subtitle___value = 'field_hero_1_subtitle___value',
  field_hero_1_text_1___value = 'field_hero_1_text_1___value',
  field_hero_1_text_2___value = 'field_hero_1_text_2___value',
  field_hero_1_title___value = 'field_hero_1_title___value',
  field_video_thumbnail___alt = 'field_video_thumbnail___alt',
  relationships___field_hero_1_image___localFile___birthtime = 'relationships___field_hero_1_image___localFile___birthtime',
  relationships___field_hero_1_image___localFile___birthtimeMs = 'relationships___field_hero_1_image___localFile___birthtimeMs',
  relationships___field_hero_1_image___localFile___publicURL = 'relationships___field_hero_1_image___localFile___publicURL',
  relationships___field_hero_1_image___localFile___sourceInstanceName = 'relationships___field_hero_1_image___localFile___sourceInstanceName',
  relationships___field_hero_1_image___localFile___absolutePath = 'relationships___field_hero_1_image___localFile___absolutePath',
  relationships___field_hero_1_image___localFile___relativePath = 'relationships___field_hero_1_image___localFile___relativePath',
  relationships___field_hero_1_image___localFile___extension = 'relationships___field_hero_1_image___localFile___extension',
  relationships___field_hero_1_image___localFile___size = 'relationships___field_hero_1_image___localFile___size',
  relationships___field_hero_1_image___localFile___prettySize = 'relationships___field_hero_1_image___localFile___prettySize',
  relationships___field_hero_1_image___localFile___modifiedTime = 'relationships___field_hero_1_image___localFile___modifiedTime',
  relationships___field_hero_1_image___localFile___accessTime = 'relationships___field_hero_1_image___localFile___accessTime',
  relationships___field_hero_1_image___localFile___changeTime = 'relationships___field_hero_1_image___localFile___changeTime',
  relationships___field_hero_1_image___localFile___birthTime = 'relationships___field_hero_1_image___localFile___birthTime',
  relationships___field_hero_1_image___localFile___root = 'relationships___field_hero_1_image___localFile___root',
  relationships___field_hero_1_image___localFile___dir = 'relationships___field_hero_1_image___localFile___dir',
  relationships___field_hero_1_image___localFile___base = 'relationships___field_hero_1_image___localFile___base',
  relationships___field_hero_1_image___localFile___ext = 'relationships___field_hero_1_image___localFile___ext',
  relationships___field_hero_1_image___localFile___name = 'relationships___field_hero_1_image___localFile___name',
  relationships___field_hero_1_image___localFile___relativeDirectory = 'relationships___field_hero_1_image___localFile___relativeDirectory',
  relationships___field_hero_1_image___localFile___dev = 'relationships___field_hero_1_image___localFile___dev',
  relationships___field_hero_1_image___localFile___mode = 'relationships___field_hero_1_image___localFile___mode',
  relationships___field_hero_1_image___localFile___nlink = 'relationships___field_hero_1_image___localFile___nlink',
  relationships___field_hero_1_image___localFile___uid = 'relationships___field_hero_1_image___localFile___uid',
  relationships___field_hero_1_image___localFile___gid = 'relationships___field_hero_1_image___localFile___gid',
  relationships___field_hero_1_image___localFile___rdev = 'relationships___field_hero_1_image___localFile___rdev',
  relationships___field_hero_1_image___localFile___blksize = 'relationships___field_hero_1_image___localFile___blksize',
  relationships___field_hero_1_image___localFile___ino = 'relationships___field_hero_1_image___localFile___ino',
  relationships___field_hero_1_image___localFile___blocks = 'relationships___field_hero_1_image___localFile___blocks',
  relationships___field_hero_1_image___localFile___atimeMs = 'relationships___field_hero_1_image___localFile___atimeMs',
  relationships___field_hero_1_image___localFile___mtimeMs = 'relationships___field_hero_1_image___localFile___mtimeMs',
  relationships___field_hero_1_image___localFile___ctimeMs = 'relationships___field_hero_1_image___localFile___ctimeMs',
  relationships___field_hero_1_image___localFile___atime = 'relationships___field_hero_1_image___localFile___atime',
  relationships___field_hero_1_image___localFile___mtime = 'relationships___field_hero_1_image___localFile___mtime',
  relationships___field_hero_1_image___localFile___ctime = 'relationships___field_hero_1_image___localFile___ctime',
  relationships___field_hero_1_image___localFile___url = 'relationships___field_hero_1_image___localFile___url',
  relationships___field_hero_1_image___localFile___id = 'relationships___field_hero_1_image___localFile___id',
  relationships___field_hero_1_image___localFile___children = 'relationships___field_hero_1_image___localFile___children',
  relationships___field_hero_1_image___uri___url = 'relationships___field_hero_1_image___uri___url',
  relationships___field_hero_1_image___drupal_id = 'relationships___field_hero_1_image___drupal_id',
  relationships___field_hero_1_image___drupal_internal__fid = 'relationships___field_hero_1_image___drupal_internal__fid',
  relationships___field_hero_1_image___langcode = 'relationships___field_hero_1_image___langcode',
  relationships___field_hero_1_image___filename = 'relationships___field_hero_1_image___filename',
  relationships___field_hero_1_image___filemime = 'relationships___field_hero_1_image___filemime',
  relationships___field_hero_1_image___filesize = 'relationships___field_hero_1_image___filesize',
  relationships___field_hero_1_image___status = 'relationships___field_hero_1_image___status',
  relationships___field_hero_1_image___created = 'relationships___field_hero_1_image___created',
  relationships___field_hero_1_image___changed = 'relationships___field_hero_1_image___changed',
  relationships___field_hero_1_image___uuid = 'relationships___field_hero_1_image___uuid',
  relationships___field_hero_1_image___relationships___node__blog_post = 'relationships___field_hero_1_image___relationships___node__blog_post',
  relationships___field_hero_1_image___relationships___node__devices = 'relationships___field_hero_1_image___relationships___node__devices',
  relationships___field_hero_1_image___relationships___node__accessories = 'relationships___field_hero_1_image___relationships___node__accessories',
  relationships___field_hero_1_image___relationships___paragraph__news_image_title_text = 'relationships___field_hero_1_image___relationships___paragraph__news_image_title_text',
  relationships___field_hero_1_image___relationships___node__cases = 'relationships___field_hero_1_image___relationships___node__cases',
  relationships___field_hero_1_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_1_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_1_image___relationships___paragraph__branches_features = 'relationships___field_hero_1_image___relationships___paragraph__branches_features',
  relationships___field_hero_1_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_1_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_1_image___relationships___paragraph__branches_testimonials = 'relationships___field_hero_1_image___relationships___paragraph__branches_testimonials',
  relationships___field_hero_1_image___relationships___paragraph__branches_discounts = 'relationships___field_hero_1_image___relationships___paragraph__branches_discounts',
  relationships___field_hero_1_image___relationships___node__news = 'relationships___field_hero_1_image___relationships___node__news',
  relationships___field_hero_1_image___relationships___node__branches = 'relationships___field_hero_1_image___relationships___node__branches',
  relationships___field_hero_1_image___relationships___paragraph__branches_hero = 'relationships___field_hero_1_image___relationships___paragraph__branches_hero',
  relationships___field_hero_1_image___relationships___paragraph__bullets = 'relationships___field_hero_1_image___relationships___paragraph__bullets',
  relationships___field_hero_1_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_1_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_1_image___relationships___paragraph__branches_device = 'relationships___field_hero_1_image___relationships___paragraph__branches_device',
  relationships___field_hero_1_image___relationships___paragraph__branches_recommendation = 'relationships___field_hero_1_image___relationships___paragraph__branches_recommendation',
  relationships___field_hero_1_image___relationships___site_setting_entity__accessories = 'relationships___field_hero_1_image___relationships___site_setting_entity__accessories',
  relationships___field_hero_1_image___relationships___site_setting_entity__branches = 'relationships___field_hero_1_image___relationships___site_setting_entity__branches',
  relationships___field_hero_1_image___relationships___site_setting_entity__about_us = 'relationships___field_hero_1_image___relationships___site_setting_entity__about_us',
  relationships___field_hero_1_image___relationships___paragraph__logos = 'relationships___field_hero_1_image___relationships___paragraph__logos',
  relationships___field_hero_1_image___relationships___paragraph__how_it_works = 'relationships___field_hero_1_image___relationships___paragraph__how_it_works',
  relationships___field_hero_1_image___relationships___paragraph__ico_and_text = 'relationships___field_hero_1_image___relationships___paragraph__ico_and_text',
  relationships___field_hero_1_image___relationships___paragraph__hero_1 = 'relationships___field_hero_1_image___relationships___paragraph__hero_1',
  relationships___field_hero_1_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_1_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_1_image___relationships___site_setting_entity__product = 'relationships___field_hero_1_image___relationships___site_setting_entity__product',
  relationships___field_hero_1_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_1_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_1_image___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_1_image___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_1_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_1_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_1_image___relationships___site_setting_entity__main_page = 'relationships___field_hero_1_image___relationships___site_setting_entity__main_page',
  relationships___field_hero_1_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_1_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_1_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_1_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_1_image___relationships___site_setting_entity__intercars = 'relationships___field_hero_1_image___relationships___site_setting_entity__intercars',
  relationships___field_hero_1_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_1_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_1_image___relationships___node__agreement = 'relationships___field_hero_1_image___relationships___node__agreement',
  relationships___field_hero_1_image___relationships___node__fiscalization = 'relationships___field_hero_1_image___relationships___node__fiscalization',
  relationships___field_hero_1_image___relationships___site_setting_entity__configurator = 'relationships___field_hero_1_image___relationships___site_setting_entity__configurator',
  relationships___field_hero_1_image___relationships___paragraph__social_link = 'relationships___field_hero_1_image___relationships___paragraph__social_link',
  relationships___field_hero_1_image___relationships___paragraph__make_an_appointment = 'relationships___field_hero_1_image___relationships___paragraph__make_an_appointment',
  relationships___field_hero_1_image___relationships___paragraph__linked_icons = 'relationships___field_hero_1_image___relationships___paragraph__linked_icons',
  relationships___field_hero_1_image___relationships___site_setting_entity__financing = 'relationships___field_hero_1_image___relationships___site_setting_entity__financing',
  relationships___field_hero_1_image___relationships___site_setting_entity__franchise = 'relationships___field_hero_1_image___relationships___site_setting_entity__franchise',
  relationships___field_hero_1_image___id = 'relationships___field_hero_1_image___id',
  relationships___field_hero_1_image___parent___id = 'relationships___field_hero_1_image___parent___id',
  relationships___field_hero_1_image___parent___children = 'relationships___field_hero_1_image___parent___children',
  relationships___field_hero_1_image___children = 'relationships___field_hero_1_image___children',
  relationships___field_hero_1_image___children___id = 'relationships___field_hero_1_image___children___id',
  relationships___field_hero_1_image___children___children = 'relationships___field_hero_1_image___children___children',
  relationships___field_hero_1_image___internal___content = 'relationships___field_hero_1_image___internal___content',
  relationships___field_hero_1_image___internal___contentDigest = 'relationships___field_hero_1_image___internal___contentDigest',
  relationships___field_hero_1_image___internal___description = 'relationships___field_hero_1_image___internal___description',
  relationships___field_hero_1_image___internal___fieldOwners = 'relationships___field_hero_1_image___internal___fieldOwners',
  relationships___field_hero_1_image___internal___ignoreType = 'relationships___field_hero_1_image___internal___ignoreType',
  relationships___field_hero_1_image___internal___mediaType = 'relationships___field_hero_1_image___internal___mediaType',
  relationships___field_hero_1_image___internal___owner = 'relationships___field_hero_1_image___internal___owner',
  relationships___field_hero_1_image___internal___type = 'relationships___field_hero_1_image___internal___type',
  relationships___field_hero_1_image_mobile___localFile___birthtime = 'relationships___field_hero_1_image_mobile___localFile___birthtime',
  relationships___field_hero_1_image_mobile___localFile___birthtimeMs = 'relationships___field_hero_1_image_mobile___localFile___birthtimeMs',
  relationships___field_hero_1_image_mobile___localFile___publicURL = 'relationships___field_hero_1_image_mobile___localFile___publicURL',
  relationships___field_hero_1_image_mobile___localFile___sourceInstanceName = 'relationships___field_hero_1_image_mobile___localFile___sourceInstanceName',
  relationships___field_hero_1_image_mobile___localFile___absolutePath = 'relationships___field_hero_1_image_mobile___localFile___absolutePath',
  relationships___field_hero_1_image_mobile___localFile___relativePath = 'relationships___field_hero_1_image_mobile___localFile___relativePath',
  relationships___field_hero_1_image_mobile___localFile___extension = 'relationships___field_hero_1_image_mobile___localFile___extension',
  relationships___field_hero_1_image_mobile___localFile___size = 'relationships___field_hero_1_image_mobile___localFile___size',
  relationships___field_hero_1_image_mobile___localFile___prettySize = 'relationships___field_hero_1_image_mobile___localFile___prettySize',
  relationships___field_hero_1_image_mobile___localFile___modifiedTime = 'relationships___field_hero_1_image_mobile___localFile___modifiedTime',
  relationships___field_hero_1_image_mobile___localFile___accessTime = 'relationships___field_hero_1_image_mobile___localFile___accessTime',
  relationships___field_hero_1_image_mobile___localFile___changeTime = 'relationships___field_hero_1_image_mobile___localFile___changeTime',
  relationships___field_hero_1_image_mobile___localFile___birthTime = 'relationships___field_hero_1_image_mobile___localFile___birthTime',
  relationships___field_hero_1_image_mobile___localFile___root = 'relationships___field_hero_1_image_mobile___localFile___root',
  relationships___field_hero_1_image_mobile___localFile___dir = 'relationships___field_hero_1_image_mobile___localFile___dir',
  relationships___field_hero_1_image_mobile___localFile___base = 'relationships___field_hero_1_image_mobile___localFile___base',
  relationships___field_hero_1_image_mobile___localFile___ext = 'relationships___field_hero_1_image_mobile___localFile___ext',
  relationships___field_hero_1_image_mobile___localFile___name = 'relationships___field_hero_1_image_mobile___localFile___name',
  relationships___field_hero_1_image_mobile___localFile___relativeDirectory = 'relationships___field_hero_1_image_mobile___localFile___relativeDirectory',
  relationships___field_hero_1_image_mobile___localFile___dev = 'relationships___field_hero_1_image_mobile___localFile___dev',
  relationships___field_hero_1_image_mobile___localFile___mode = 'relationships___field_hero_1_image_mobile___localFile___mode',
  relationships___field_hero_1_image_mobile___localFile___nlink = 'relationships___field_hero_1_image_mobile___localFile___nlink',
  relationships___field_hero_1_image_mobile___localFile___uid = 'relationships___field_hero_1_image_mobile___localFile___uid',
  relationships___field_hero_1_image_mobile___localFile___gid = 'relationships___field_hero_1_image_mobile___localFile___gid',
  relationships___field_hero_1_image_mobile___localFile___rdev = 'relationships___field_hero_1_image_mobile___localFile___rdev',
  relationships___field_hero_1_image_mobile___localFile___blksize = 'relationships___field_hero_1_image_mobile___localFile___blksize',
  relationships___field_hero_1_image_mobile___localFile___ino = 'relationships___field_hero_1_image_mobile___localFile___ino',
  relationships___field_hero_1_image_mobile___localFile___blocks = 'relationships___field_hero_1_image_mobile___localFile___blocks',
  relationships___field_hero_1_image_mobile___localFile___atimeMs = 'relationships___field_hero_1_image_mobile___localFile___atimeMs',
  relationships___field_hero_1_image_mobile___localFile___mtimeMs = 'relationships___field_hero_1_image_mobile___localFile___mtimeMs',
  relationships___field_hero_1_image_mobile___localFile___ctimeMs = 'relationships___field_hero_1_image_mobile___localFile___ctimeMs',
  relationships___field_hero_1_image_mobile___localFile___atime = 'relationships___field_hero_1_image_mobile___localFile___atime',
  relationships___field_hero_1_image_mobile___localFile___mtime = 'relationships___field_hero_1_image_mobile___localFile___mtime',
  relationships___field_hero_1_image_mobile___localFile___ctime = 'relationships___field_hero_1_image_mobile___localFile___ctime',
  relationships___field_hero_1_image_mobile___localFile___url = 'relationships___field_hero_1_image_mobile___localFile___url',
  relationships___field_hero_1_image_mobile___localFile___id = 'relationships___field_hero_1_image_mobile___localFile___id',
  relationships___field_hero_1_image_mobile___localFile___children = 'relationships___field_hero_1_image_mobile___localFile___children',
  relationships___field_hero_1_image_mobile___uri___url = 'relationships___field_hero_1_image_mobile___uri___url',
  relationships___field_hero_1_image_mobile___drupal_id = 'relationships___field_hero_1_image_mobile___drupal_id',
  relationships___field_hero_1_image_mobile___drupal_internal__fid = 'relationships___field_hero_1_image_mobile___drupal_internal__fid',
  relationships___field_hero_1_image_mobile___langcode = 'relationships___field_hero_1_image_mobile___langcode',
  relationships___field_hero_1_image_mobile___filename = 'relationships___field_hero_1_image_mobile___filename',
  relationships___field_hero_1_image_mobile___filemime = 'relationships___field_hero_1_image_mobile___filemime',
  relationships___field_hero_1_image_mobile___filesize = 'relationships___field_hero_1_image_mobile___filesize',
  relationships___field_hero_1_image_mobile___status = 'relationships___field_hero_1_image_mobile___status',
  relationships___field_hero_1_image_mobile___created = 'relationships___field_hero_1_image_mobile___created',
  relationships___field_hero_1_image_mobile___changed = 'relationships___field_hero_1_image_mobile___changed',
  relationships___field_hero_1_image_mobile___uuid = 'relationships___field_hero_1_image_mobile___uuid',
  relationships___field_hero_1_image_mobile___relationships___node__blog_post = 'relationships___field_hero_1_image_mobile___relationships___node__blog_post',
  relationships___field_hero_1_image_mobile___relationships___node__devices = 'relationships___field_hero_1_image_mobile___relationships___node__devices',
  relationships___field_hero_1_image_mobile___relationships___node__accessories = 'relationships___field_hero_1_image_mobile___relationships___node__accessories',
  relationships___field_hero_1_image_mobile___relationships___paragraph__news_image_title_text = 'relationships___field_hero_1_image_mobile___relationships___paragraph__news_image_title_text',
  relationships___field_hero_1_image_mobile___relationships___node__cases = 'relationships___field_hero_1_image_mobile___relationships___node__cases',
  relationships___field_hero_1_image_mobile___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_1_image_mobile___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_1_image_mobile___relationships___paragraph__branches_features = 'relationships___field_hero_1_image_mobile___relationships___paragraph__branches_features',
  relationships___field_hero_1_image_mobile___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_1_image_mobile___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_1_image_mobile___relationships___paragraph__branches_testimonials = 'relationships___field_hero_1_image_mobile___relationships___paragraph__branches_testimonials',
  relationships___field_hero_1_image_mobile___relationships___paragraph__branches_discounts = 'relationships___field_hero_1_image_mobile___relationships___paragraph__branches_discounts',
  relationships___field_hero_1_image_mobile___relationships___node__news = 'relationships___field_hero_1_image_mobile___relationships___node__news',
  relationships___field_hero_1_image_mobile___relationships___node__branches = 'relationships___field_hero_1_image_mobile___relationships___node__branches',
  relationships___field_hero_1_image_mobile___relationships___paragraph__branches_hero = 'relationships___field_hero_1_image_mobile___relationships___paragraph__branches_hero',
  relationships___field_hero_1_image_mobile___relationships___paragraph__bullets = 'relationships___field_hero_1_image_mobile___relationships___paragraph__bullets',
  relationships___field_hero_1_image_mobile___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_1_image_mobile___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_1_image_mobile___relationships___paragraph__branches_device = 'relationships___field_hero_1_image_mobile___relationships___paragraph__branches_device',
  relationships___field_hero_1_image_mobile___relationships___paragraph__branches_recommendation = 'relationships___field_hero_1_image_mobile___relationships___paragraph__branches_recommendation',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__accessories = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__accessories',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__branches = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__branches',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__about_us = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__about_us',
  relationships___field_hero_1_image_mobile___relationships___paragraph__logos = 'relationships___field_hero_1_image_mobile___relationships___paragraph__logos',
  relationships___field_hero_1_image_mobile___relationships___paragraph__how_it_works = 'relationships___field_hero_1_image_mobile___relationships___paragraph__how_it_works',
  relationships___field_hero_1_image_mobile___relationships___paragraph__ico_and_text = 'relationships___field_hero_1_image_mobile___relationships___paragraph__ico_and_text',
  relationships___field_hero_1_image_mobile___relationships___paragraph__hero_1 = 'relationships___field_hero_1_image_mobile___relationships___paragraph__hero_1',
  relationships___field_hero_1_image_mobile___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_1_image_mobile___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__product = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__product',
  relationships___field_hero_1_image_mobile___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_1_image_mobile___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_1_image_mobile___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_1_image_mobile___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_1_image_mobile___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_1_image_mobile___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__main_page = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__main_page',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_1_image_mobile___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_1_image_mobile___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__intercars = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__intercars',
  relationships___field_hero_1_image_mobile___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_1_image_mobile___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_1_image_mobile___relationships___node__agreement = 'relationships___field_hero_1_image_mobile___relationships___node__agreement',
  relationships___field_hero_1_image_mobile___relationships___node__fiscalization = 'relationships___field_hero_1_image_mobile___relationships___node__fiscalization',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__configurator = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__configurator',
  relationships___field_hero_1_image_mobile___relationships___paragraph__social_link = 'relationships___field_hero_1_image_mobile___relationships___paragraph__social_link',
  relationships___field_hero_1_image_mobile___relationships___paragraph__make_an_appointment = 'relationships___field_hero_1_image_mobile___relationships___paragraph__make_an_appointment',
  relationships___field_hero_1_image_mobile___relationships___paragraph__linked_icons = 'relationships___field_hero_1_image_mobile___relationships___paragraph__linked_icons',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__financing = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__financing',
  relationships___field_hero_1_image_mobile___relationships___site_setting_entity__franchise = 'relationships___field_hero_1_image_mobile___relationships___site_setting_entity__franchise',
  relationships___field_hero_1_image_mobile___id = 'relationships___field_hero_1_image_mobile___id',
  relationships___field_hero_1_image_mobile___parent___id = 'relationships___field_hero_1_image_mobile___parent___id',
  relationships___field_hero_1_image_mobile___parent___children = 'relationships___field_hero_1_image_mobile___parent___children',
  relationships___field_hero_1_image_mobile___children = 'relationships___field_hero_1_image_mobile___children',
  relationships___field_hero_1_image_mobile___children___id = 'relationships___field_hero_1_image_mobile___children___id',
  relationships___field_hero_1_image_mobile___children___children = 'relationships___field_hero_1_image_mobile___children___children',
  relationships___field_hero_1_image_mobile___internal___content = 'relationships___field_hero_1_image_mobile___internal___content',
  relationships___field_hero_1_image_mobile___internal___contentDigest = 'relationships___field_hero_1_image_mobile___internal___contentDigest',
  relationships___field_hero_1_image_mobile___internal___description = 'relationships___field_hero_1_image_mobile___internal___description',
  relationships___field_hero_1_image_mobile___internal___fieldOwners = 'relationships___field_hero_1_image_mobile___internal___fieldOwners',
  relationships___field_hero_1_image_mobile___internal___ignoreType = 'relationships___field_hero_1_image_mobile___internal___ignoreType',
  relationships___field_hero_1_image_mobile___internal___mediaType = 'relationships___field_hero_1_image_mobile___internal___mediaType',
  relationships___field_hero_1_image_mobile___internal___owner = 'relationships___field_hero_1_image_mobile___internal___owner',
  relationships___field_hero_1_image_mobile___internal___type = 'relationships___field_hero_1_image_mobile___internal___type',
  relationships___field_video_thumbnail___localFile___birthtime = 'relationships___field_video_thumbnail___localFile___birthtime',
  relationships___field_video_thumbnail___localFile___birthtimeMs = 'relationships___field_video_thumbnail___localFile___birthtimeMs',
  relationships___field_video_thumbnail___localFile___publicURL = 'relationships___field_video_thumbnail___localFile___publicURL',
  relationships___field_video_thumbnail___localFile___sourceInstanceName = 'relationships___field_video_thumbnail___localFile___sourceInstanceName',
  relationships___field_video_thumbnail___localFile___absolutePath = 'relationships___field_video_thumbnail___localFile___absolutePath',
  relationships___field_video_thumbnail___localFile___relativePath = 'relationships___field_video_thumbnail___localFile___relativePath',
  relationships___field_video_thumbnail___localFile___extension = 'relationships___field_video_thumbnail___localFile___extension',
  relationships___field_video_thumbnail___localFile___size = 'relationships___field_video_thumbnail___localFile___size',
  relationships___field_video_thumbnail___localFile___prettySize = 'relationships___field_video_thumbnail___localFile___prettySize',
  relationships___field_video_thumbnail___localFile___modifiedTime = 'relationships___field_video_thumbnail___localFile___modifiedTime',
  relationships___field_video_thumbnail___localFile___accessTime = 'relationships___field_video_thumbnail___localFile___accessTime',
  relationships___field_video_thumbnail___localFile___changeTime = 'relationships___field_video_thumbnail___localFile___changeTime',
  relationships___field_video_thumbnail___localFile___birthTime = 'relationships___field_video_thumbnail___localFile___birthTime',
  relationships___field_video_thumbnail___localFile___root = 'relationships___field_video_thumbnail___localFile___root',
  relationships___field_video_thumbnail___localFile___dir = 'relationships___field_video_thumbnail___localFile___dir',
  relationships___field_video_thumbnail___localFile___base = 'relationships___field_video_thumbnail___localFile___base',
  relationships___field_video_thumbnail___localFile___ext = 'relationships___field_video_thumbnail___localFile___ext',
  relationships___field_video_thumbnail___localFile___name = 'relationships___field_video_thumbnail___localFile___name',
  relationships___field_video_thumbnail___localFile___relativeDirectory = 'relationships___field_video_thumbnail___localFile___relativeDirectory',
  relationships___field_video_thumbnail___localFile___dev = 'relationships___field_video_thumbnail___localFile___dev',
  relationships___field_video_thumbnail___localFile___mode = 'relationships___field_video_thumbnail___localFile___mode',
  relationships___field_video_thumbnail___localFile___nlink = 'relationships___field_video_thumbnail___localFile___nlink',
  relationships___field_video_thumbnail___localFile___uid = 'relationships___field_video_thumbnail___localFile___uid',
  relationships___field_video_thumbnail___localFile___gid = 'relationships___field_video_thumbnail___localFile___gid',
  relationships___field_video_thumbnail___localFile___rdev = 'relationships___field_video_thumbnail___localFile___rdev',
  relationships___field_video_thumbnail___localFile___blksize = 'relationships___field_video_thumbnail___localFile___blksize',
  relationships___field_video_thumbnail___localFile___ino = 'relationships___field_video_thumbnail___localFile___ino',
  relationships___field_video_thumbnail___localFile___blocks = 'relationships___field_video_thumbnail___localFile___blocks',
  relationships___field_video_thumbnail___localFile___atimeMs = 'relationships___field_video_thumbnail___localFile___atimeMs',
  relationships___field_video_thumbnail___localFile___mtimeMs = 'relationships___field_video_thumbnail___localFile___mtimeMs',
  relationships___field_video_thumbnail___localFile___ctimeMs = 'relationships___field_video_thumbnail___localFile___ctimeMs',
  relationships___field_video_thumbnail___localFile___atime = 'relationships___field_video_thumbnail___localFile___atime',
  relationships___field_video_thumbnail___localFile___mtime = 'relationships___field_video_thumbnail___localFile___mtime',
  relationships___field_video_thumbnail___localFile___ctime = 'relationships___field_video_thumbnail___localFile___ctime',
  relationships___field_video_thumbnail___localFile___url = 'relationships___field_video_thumbnail___localFile___url',
  relationships___field_video_thumbnail___localFile___id = 'relationships___field_video_thumbnail___localFile___id',
  relationships___field_video_thumbnail___localFile___children = 'relationships___field_video_thumbnail___localFile___children',
  relationships___field_video_thumbnail___uri___url = 'relationships___field_video_thumbnail___uri___url',
  relationships___field_video_thumbnail___drupal_id = 'relationships___field_video_thumbnail___drupal_id',
  relationships___field_video_thumbnail___drupal_internal__fid = 'relationships___field_video_thumbnail___drupal_internal__fid',
  relationships___field_video_thumbnail___langcode = 'relationships___field_video_thumbnail___langcode',
  relationships___field_video_thumbnail___filename = 'relationships___field_video_thumbnail___filename',
  relationships___field_video_thumbnail___filemime = 'relationships___field_video_thumbnail___filemime',
  relationships___field_video_thumbnail___filesize = 'relationships___field_video_thumbnail___filesize',
  relationships___field_video_thumbnail___status = 'relationships___field_video_thumbnail___status',
  relationships___field_video_thumbnail___created = 'relationships___field_video_thumbnail___created',
  relationships___field_video_thumbnail___changed = 'relationships___field_video_thumbnail___changed',
  relationships___field_video_thumbnail___uuid = 'relationships___field_video_thumbnail___uuid',
  relationships___field_video_thumbnail___relationships___node__blog_post = 'relationships___field_video_thumbnail___relationships___node__blog_post',
  relationships___field_video_thumbnail___relationships___node__devices = 'relationships___field_video_thumbnail___relationships___node__devices',
  relationships___field_video_thumbnail___relationships___node__accessories = 'relationships___field_video_thumbnail___relationships___node__accessories',
  relationships___field_video_thumbnail___relationships___paragraph__news_image_title_text = 'relationships___field_video_thumbnail___relationships___paragraph__news_image_title_text',
  relationships___field_video_thumbnail___relationships___node__cases = 'relationships___field_video_thumbnail___relationships___node__cases',
  relationships___field_video_thumbnail___relationships___paragraph__branches_what_is_ipos = 'relationships___field_video_thumbnail___relationships___paragraph__branches_what_is_ipos',
  relationships___field_video_thumbnail___relationships___paragraph__branches_features = 'relationships___field_video_thumbnail___relationships___paragraph__branches_features',
  relationships___field_video_thumbnail___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_video_thumbnail___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_video_thumbnail___relationships___paragraph__branches_testimonials = 'relationships___field_video_thumbnail___relationships___paragraph__branches_testimonials',
  relationships___field_video_thumbnail___relationships___paragraph__branches_discounts = 'relationships___field_video_thumbnail___relationships___paragraph__branches_discounts',
  relationships___field_video_thumbnail___relationships___node__news = 'relationships___field_video_thumbnail___relationships___node__news',
  relationships___field_video_thumbnail___relationships___node__branches = 'relationships___field_video_thumbnail___relationships___node__branches',
  relationships___field_video_thumbnail___relationships___paragraph__branches_hero = 'relationships___field_video_thumbnail___relationships___paragraph__branches_hero',
  relationships___field_video_thumbnail___relationships___paragraph__bullets = 'relationships___field_video_thumbnail___relationships___paragraph__bullets',
  relationships___field_video_thumbnail___relationships___taxonomy_term__pricelist_group = 'relationships___field_video_thumbnail___relationships___taxonomy_term__pricelist_group',
  relationships___field_video_thumbnail___relationships___paragraph__branches_device = 'relationships___field_video_thumbnail___relationships___paragraph__branches_device',
  relationships___field_video_thumbnail___relationships___paragraph__branches_recommendation = 'relationships___field_video_thumbnail___relationships___paragraph__branches_recommendation',
  relationships___field_video_thumbnail___relationships___site_setting_entity__accessories = 'relationships___field_video_thumbnail___relationships___site_setting_entity__accessories',
  relationships___field_video_thumbnail___relationships___site_setting_entity__branches = 'relationships___field_video_thumbnail___relationships___site_setting_entity__branches',
  relationships___field_video_thumbnail___relationships___site_setting_entity__about_us = 'relationships___field_video_thumbnail___relationships___site_setting_entity__about_us',
  relationships___field_video_thumbnail___relationships___paragraph__logos = 'relationships___field_video_thumbnail___relationships___paragraph__logos',
  relationships___field_video_thumbnail___relationships___paragraph__how_it_works = 'relationships___field_video_thumbnail___relationships___paragraph__how_it_works',
  relationships___field_video_thumbnail___relationships___paragraph__ico_and_text = 'relationships___field_video_thumbnail___relationships___paragraph__ico_and_text',
  relationships___field_video_thumbnail___relationships___paragraph__hero_1 = 'relationships___field_video_thumbnail___relationships___paragraph__hero_1',
  relationships___field_video_thumbnail___relationships___paragraph__comprehensive_solution_item = 'relationships___field_video_thumbnail___relationships___paragraph__comprehensive_solution_item',
  relationships___field_video_thumbnail___relationships___site_setting_entity__product = 'relationships___field_video_thumbnail___relationships___site_setting_entity__product',
  relationships___field_video_thumbnail___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_video_thumbnail___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_video_thumbnail___relationships___paragraph__porownanie_terminali = 'relationships___field_video_thumbnail___relationships___paragraph__porownanie_terminali',
  relationships___field_video_thumbnail___relationships___paragraph__zdjecie_opis_link = 'relationships___field_video_thumbnail___relationships___paragraph__zdjecie_opis_link',
  relationships___field_video_thumbnail___relationships___site_setting_entity__main_page = 'relationships___field_video_thumbnail___relationships___site_setting_entity__main_page',
  relationships___field_video_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_video_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_video_thumbnail___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_video_thumbnail___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_video_thumbnail___relationships___site_setting_entity__intercars = 'relationships___field_video_thumbnail___relationships___site_setting_entity__intercars',
  relationships___field_video_thumbnail___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_video_thumbnail___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_video_thumbnail___relationships___node__agreement = 'relationships___field_video_thumbnail___relationships___node__agreement',
  relationships___field_video_thumbnail___relationships___node__fiscalization = 'relationships___field_video_thumbnail___relationships___node__fiscalization',
  relationships___field_video_thumbnail___relationships___site_setting_entity__configurator = 'relationships___field_video_thumbnail___relationships___site_setting_entity__configurator',
  relationships___field_video_thumbnail___relationships___paragraph__social_link = 'relationships___field_video_thumbnail___relationships___paragraph__social_link',
  relationships___field_video_thumbnail___relationships___paragraph__make_an_appointment = 'relationships___field_video_thumbnail___relationships___paragraph__make_an_appointment',
  relationships___field_video_thumbnail___relationships___paragraph__linked_icons = 'relationships___field_video_thumbnail___relationships___paragraph__linked_icons',
  relationships___field_video_thumbnail___relationships___site_setting_entity__financing = 'relationships___field_video_thumbnail___relationships___site_setting_entity__financing',
  relationships___field_video_thumbnail___relationships___site_setting_entity__franchise = 'relationships___field_video_thumbnail___relationships___site_setting_entity__franchise',
  relationships___field_video_thumbnail___id = 'relationships___field_video_thumbnail___id',
  relationships___field_video_thumbnail___parent___id = 'relationships___field_video_thumbnail___parent___id',
  relationships___field_video_thumbnail___parent___children = 'relationships___field_video_thumbnail___parent___children',
  relationships___field_video_thumbnail___children = 'relationships___field_video_thumbnail___children',
  relationships___field_video_thumbnail___children___id = 'relationships___field_video_thumbnail___children___id',
  relationships___field_video_thumbnail___children___children = 'relationships___field_video_thumbnail___children___children',
  relationships___field_video_thumbnail___internal___content = 'relationships___field_video_thumbnail___internal___content',
  relationships___field_video_thumbnail___internal___contentDigest = 'relationships___field_video_thumbnail___internal___contentDigest',
  relationships___field_video_thumbnail___internal___description = 'relationships___field_video_thumbnail___internal___description',
  relationships___field_video_thumbnail___internal___fieldOwners = 'relationships___field_video_thumbnail___internal___fieldOwners',
  relationships___field_video_thumbnail___internal___ignoreType = 'relationships___field_video_thumbnail___internal___ignoreType',
  relationships___field_video_thumbnail___internal___mediaType = 'relationships___field_video_thumbnail___internal___mediaType',
  relationships___field_video_thumbnail___internal___owner = 'relationships___field_video_thumbnail___internal___owner',
  relationships___field_video_thumbnail___internal___type = 'relationships___field_video_thumbnail___internal___type',
  relationships___site_setting_entity__main_page = 'relationships___site_setting_entity__main_page',
  relationships___site_setting_entity__main_page___langcode = 'relationships___site_setting_entity__main_page___langcode',
  relationships___site_setting_entity__main_page___field_mp_text_footer___value = 'relationships___site_setting_entity__main_page___field_mp_text_footer___value',
  relationships___site_setting_entity__main_page___field_mp_text___value = 'relationships___site_setting_entity__main_page___field_mp_text___value',
  relationships___site_setting_entity__main_page___field_mp_button_text = 'relationships___site_setting_entity__main_page___field_mp_button_text',
  relationships___site_setting_entity__main_page___field_meta_description = 'relationships___site_setting_entity__main_page___field_meta_description',
  relationships___site_setting_entity__main_page___field_meta_title = 'relationships___site_setting_entity__main_page___field_meta_title',
  relationships___site_setting_entity__main_page___field_btn_url = 'relationships___site_setting_entity__main_page___field_btn_url',
  relationships___site_setting_entity__main_page___field_settings_button_text = 'relationships___site_setting_entity__main_page___field_settings_button_text',
  relationships___site_setting_entity__main_page___field_mp_image_footer___alt = 'relationships___site_setting_entity__main_page___field_mp_image_footer___alt',
  relationships___site_setting_entity__main_page___field_mp_image___alt = 'relationships___site_setting_entity__main_page___field_mp_image___alt',
  relationships___site_setting_entity__main_page___drupal_id = 'relationships___site_setting_entity__main_page___drupal_id',
  relationships___site_setting_entity__main_page___drupal_internal__id = 'relationships___site_setting_entity__main_page___drupal_internal__id',
  relationships___site_setting_entity__main_page___name = 'relationships___site_setting_entity__main_page___name',
  relationships___site_setting_entity__main_page___fieldset = 'relationships___site_setting_entity__main_page___fieldset',
  relationships___site_setting_entity__main_page___status = 'relationships___site_setting_entity__main_page___status',
  relationships___site_setting_entity__main_page___created = 'relationships___site_setting_entity__main_page___created',
  relationships___site_setting_entity__main_page___changed = 'relationships___site_setting_entity__main_page___changed',
  relationships___site_setting_entity__main_page___default_langcode = 'relationships___site_setting_entity__main_page___default_langcode',
  relationships___site_setting_entity__main_page___content_translation_source = 'relationships___site_setting_entity__main_page___content_translation_source',
  relationships___site_setting_entity__main_page___content_translation_outdated = 'relationships___site_setting_entity__main_page___content_translation_outdated',
  relationships___site_setting_entity__main_page___content_translation_status = 'relationships___site_setting_entity__main_page___content_translation_status',
  relationships___site_setting_entity__main_page___content_translation_created = 'relationships___site_setting_entity__main_page___content_translation_created',
  relationships___site_setting_entity__main_page___content_translation_changed = 'relationships___site_setting_entity__main_page___content_translation_changed',
  relationships___site_setting_entity__main_page___uuid = 'relationships___site_setting_entity__main_page___uuid',
  relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___field_components = 'relationships___site_setting_entity__main_page___field_components',
  relationships___site_setting_entity__main_page___field_components___target_revision_id = 'relationships___site_setting_entity__main_page___field_components___target_revision_id',
  relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___id = 'relationships___site_setting_entity__main_page___id',
  relationships___site_setting_entity__main_page___parent___id = 'relationships___site_setting_entity__main_page___parent___id',
  relationships___site_setting_entity__main_page___parent___children = 'relationships___site_setting_entity__main_page___parent___children',
  relationships___site_setting_entity__main_page___children = 'relationships___site_setting_entity__main_page___children',
  relationships___site_setting_entity__main_page___children___id = 'relationships___site_setting_entity__main_page___children___id',
  relationships___site_setting_entity__main_page___children___children = 'relationships___site_setting_entity__main_page___children___children',
  relationships___site_setting_entity__main_page___internal___content = 'relationships___site_setting_entity__main_page___internal___content',
  relationships___site_setting_entity__main_page___internal___contentDigest = 'relationships___site_setting_entity__main_page___internal___contentDigest',
  relationships___site_setting_entity__main_page___internal___description = 'relationships___site_setting_entity__main_page___internal___description',
  relationships___site_setting_entity__main_page___internal___fieldOwners = 'relationships___site_setting_entity__main_page___internal___fieldOwners',
  relationships___site_setting_entity__main_page___internal___ignoreType = 'relationships___site_setting_entity__main_page___internal___ignoreType',
  relationships___site_setting_entity__main_page___internal___mediaType = 'relationships___site_setting_entity__main_page___internal___mediaType',
  relationships___site_setting_entity__main_page___internal___owner = 'relationships___site_setting_entity__main_page___internal___owner',
  relationships___site_setting_entity__main_page___internal___type = 'relationships___site_setting_entity__main_page___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  langcode = 'langcode',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Hero_1FilterInput = {
  field_hero_1_button?: Maybe<StringQueryOperatorInput>;
  field_hero_1_video?: Maybe<StringQueryOperatorInput>;
  field_hero_1_image?: Maybe<Field_Hero_1_ImageFilterInput>;
  field_hero_1_image_mobile?: Maybe<Field_Hero_1_Image_MobileFilterInput>;
  field_hero_1_subtitle?: Maybe<Field_Hero_1_SubtitleFilterInput>;
  field_hero_1_text_1?: Maybe<Field_Hero_1_Text_1FilterInput>;
  field_hero_1_text_2?: Maybe<Field_Hero_1_Text_2FilterInput>;
  field_hero_1_title?: Maybe<Field_Hero_1_TitleFilterInput>;
  field_video_thumbnail?: Maybe<Field_Video_ThumbnailFilterInput>;
  relationships?: Maybe<Paragraph__Hero_1RelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Hero_1FilterListInput = {
  elemMatch?: Maybe<Paragraph__Hero_1FilterInput>;
};

export type Paragraph__Hero_1GroupConnection = {
  __typename?: 'paragraph__hero_1GroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Hero_1Edge>;
  nodes: Array<Paragraph__Hero_1>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Hero_1Relationships = {
  __typename?: 'paragraph__hero_1Relationships';
  field_hero_1_image?: Maybe<File__File>;
  field_hero_1_image_mobile?: Maybe<File__File>;
  field_video_thumbnail?: Maybe<File__File>;
  site_setting_entity__main_page?: Maybe<Array<Maybe<Site_Setting_Entity__Main_Page>>>;
};

export type Paragraph__Hero_1RelationshipsFilterInput = {
  field_hero_1_image?: Maybe<File__FileFilterInput>;
  field_hero_1_image_mobile?: Maybe<File__FileFilterInput>;
  field_video_thumbnail?: Maybe<File__FileFilterInput>;
  site_setting_entity__main_page?: Maybe<Site_Setting_Entity__Main_PageFilterListInput>;
};

export type Paragraph__Hero_1SortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Hero_1FieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__How_It_Works = Node & {
  __typename?: 'paragraph__how_it_works';
  field_description?: Maybe<Field_Description>;
  field_ico_1?: Maybe<Field_Ico_1>;
  field_image?: Maybe<Field_Image>;
  field_image_1?: Maybe<Field_Image_1>;
  field_image_2?: Maybe<Field_Image_2>;
  field_image_3?: Maybe<Field_Image_3>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_short_text_1?: Maybe<Field_Short_Text_1>;
  field_short_text_2?: Maybe<Field_Short_Text_2>;
  field_short_text_3?: Maybe<Field_Short_Text_3>;
  field_text?: Maybe<Field_Text>;
  relationships?: Maybe<Paragraph__How_It_WorksRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__How_It_WorksCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__How_It_WorksConnection = {
  __typename?: 'paragraph__how_it_worksConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__How_It_WorksEdge>;
  nodes: Array<Paragraph__How_It_Works>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__How_It_WorksGroupConnection>;
};

export type Paragraph__How_It_WorksConnectionDistinctArgs = {
  field: Paragraph__How_It_WorksFieldsEnum;
};

export type Paragraph__How_It_WorksConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__How_It_WorksFieldsEnum;
};

export type Paragraph__How_It_WorksEdge = {
  __typename?: 'paragraph__how_it_worksEdge';
  next?: Maybe<Paragraph__How_It_Works>;
  node: Paragraph__How_It_Works;
  previous?: Maybe<Paragraph__How_It_Works>;
};

export enum Paragraph__How_It_WorksFieldsEnum {
  field_description___value = 'field_description___value',
  field_ico_1___alt = 'field_ico_1___alt',
  field_image___alt = 'field_image___alt',
  field_image_1___alt = 'field_image_1___alt',
  field_image_2___alt = 'field_image_2___alt',
  field_image_3___alt = 'field_image_3___alt',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_short_text_1___value = 'field_short_text_1___value',
  field_short_text_2___value = 'field_short_text_2___value',
  field_short_text_3___value = 'field_short_text_3___value',
  field_text___value = 'field_text___value',
  relationships___field_ico_1___localFile___birthtime = 'relationships___field_ico_1___localFile___birthtime',
  relationships___field_ico_1___localFile___birthtimeMs = 'relationships___field_ico_1___localFile___birthtimeMs',
  relationships___field_ico_1___localFile___publicURL = 'relationships___field_ico_1___localFile___publicURL',
  relationships___field_ico_1___localFile___sourceInstanceName = 'relationships___field_ico_1___localFile___sourceInstanceName',
  relationships___field_ico_1___localFile___absolutePath = 'relationships___field_ico_1___localFile___absolutePath',
  relationships___field_ico_1___localFile___relativePath = 'relationships___field_ico_1___localFile___relativePath',
  relationships___field_ico_1___localFile___extension = 'relationships___field_ico_1___localFile___extension',
  relationships___field_ico_1___localFile___size = 'relationships___field_ico_1___localFile___size',
  relationships___field_ico_1___localFile___prettySize = 'relationships___field_ico_1___localFile___prettySize',
  relationships___field_ico_1___localFile___modifiedTime = 'relationships___field_ico_1___localFile___modifiedTime',
  relationships___field_ico_1___localFile___accessTime = 'relationships___field_ico_1___localFile___accessTime',
  relationships___field_ico_1___localFile___changeTime = 'relationships___field_ico_1___localFile___changeTime',
  relationships___field_ico_1___localFile___birthTime = 'relationships___field_ico_1___localFile___birthTime',
  relationships___field_ico_1___localFile___root = 'relationships___field_ico_1___localFile___root',
  relationships___field_ico_1___localFile___dir = 'relationships___field_ico_1___localFile___dir',
  relationships___field_ico_1___localFile___base = 'relationships___field_ico_1___localFile___base',
  relationships___field_ico_1___localFile___ext = 'relationships___field_ico_1___localFile___ext',
  relationships___field_ico_1___localFile___name = 'relationships___field_ico_1___localFile___name',
  relationships___field_ico_1___localFile___relativeDirectory = 'relationships___field_ico_1___localFile___relativeDirectory',
  relationships___field_ico_1___localFile___dev = 'relationships___field_ico_1___localFile___dev',
  relationships___field_ico_1___localFile___mode = 'relationships___field_ico_1___localFile___mode',
  relationships___field_ico_1___localFile___nlink = 'relationships___field_ico_1___localFile___nlink',
  relationships___field_ico_1___localFile___uid = 'relationships___field_ico_1___localFile___uid',
  relationships___field_ico_1___localFile___gid = 'relationships___field_ico_1___localFile___gid',
  relationships___field_ico_1___localFile___rdev = 'relationships___field_ico_1___localFile___rdev',
  relationships___field_ico_1___localFile___blksize = 'relationships___field_ico_1___localFile___blksize',
  relationships___field_ico_1___localFile___ino = 'relationships___field_ico_1___localFile___ino',
  relationships___field_ico_1___localFile___blocks = 'relationships___field_ico_1___localFile___blocks',
  relationships___field_ico_1___localFile___atimeMs = 'relationships___field_ico_1___localFile___atimeMs',
  relationships___field_ico_1___localFile___mtimeMs = 'relationships___field_ico_1___localFile___mtimeMs',
  relationships___field_ico_1___localFile___ctimeMs = 'relationships___field_ico_1___localFile___ctimeMs',
  relationships___field_ico_1___localFile___atime = 'relationships___field_ico_1___localFile___atime',
  relationships___field_ico_1___localFile___mtime = 'relationships___field_ico_1___localFile___mtime',
  relationships___field_ico_1___localFile___ctime = 'relationships___field_ico_1___localFile___ctime',
  relationships___field_ico_1___localFile___url = 'relationships___field_ico_1___localFile___url',
  relationships___field_ico_1___localFile___id = 'relationships___field_ico_1___localFile___id',
  relationships___field_ico_1___localFile___children = 'relationships___field_ico_1___localFile___children',
  relationships___field_ico_1___uri___url = 'relationships___field_ico_1___uri___url',
  relationships___field_ico_1___drupal_id = 'relationships___field_ico_1___drupal_id',
  relationships___field_ico_1___drupal_internal__fid = 'relationships___field_ico_1___drupal_internal__fid',
  relationships___field_ico_1___langcode = 'relationships___field_ico_1___langcode',
  relationships___field_ico_1___filename = 'relationships___field_ico_1___filename',
  relationships___field_ico_1___filemime = 'relationships___field_ico_1___filemime',
  relationships___field_ico_1___filesize = 'relationships___field_ico_1___filesize',
  relationships___field_ico_1___status = 'relationships___field_ico_1___status',
  relationships___field_ico_1___created = 'relationships___field_ico_1___created',
  relationships___field_ico_1___changed = 'relationships___field_ico_1___changed',
  relationships___field_ico_1___uuid = 'relationships___field_ico_1___uuid',
  relationships___field_ico_1___relationships___node__blog_post = 'relationships___field_ico_1___relationships___node__blog_post',
  relationships___field_ico_1___relationships___node__devices = 'relationships___field_ico_1___relationships___node__devices',
  relationships___field_ico_1___relationships___node__accessories = 'relationships___field_ico_1___relationships___node__accessories',
  relationships___field_ico_1___relationships___paragraph__news_image_title_text = 'relationships___field_ico_1___relationships___paragraph__news_image_title_text',
  relationships___field_ico_1___relationships___node__cases = 'relationships___field_ico_1___relationships___node__cases',
  relationships___field_ico_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico_1___relationships___paragraph__branches_features = 'relationships___field_ico_1___relationships___paragraph__branches_features',
  relationships___field_ico_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico_1___relationships___paragraph__branches_testimonials = 'relationships___field_ico_1___relationships___paragraph__branches_testimonials',
  relationships___field_ico_1___relationships___paragraph__branches_discounts = 'relationships___field_ico_1___relationships___paragraph__branches_discounts',
  relationships___field_ico_1___relationships___node__news = 'relationships___field_ico_1___relationships___node__news',
  relationships___field_ico_1___relationships___node__branches = 'relationships___field_ico_1___relationships___node__branches',
  relationships___field_ico_1___relationships___paragraph__branches_hero = 'relationships___field_ico_1___relationships___paragraph__branches_hero',
  relationships___field_ico_1___relationships___paragraph__bullets = 'relationships___field_ico_1___relationships___paragraph__bullets',
  relationships___field_ico_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico_1___relationships___paragraph__branches_device = 'relationships___field_ico_1___relationships___paragraph__branches_device',
  relationships___field_ico_1___relationships___paragraph__branches_recommendation = 'relationships___field_ico_1___relationships___paragraph__branches_recommendation',
  relationships___field_ico_1___relationships___site_setting_entity__accessories = 'relationships___field_ico_1___relationships___site_setting_entity__accessories',
  relationships___field_ico_1___relationships___site_setting_entity__branches = 'relationships___field_ico_1___relationships___site_setting_entity__branches',
  relationships___field_ico_1___relationships___site_setting_entity__about_us = 'relationships___field_ico_1___relationships___site_setting_entity__about_us',
  relationships___field_ico_1___relationships___paragraph__logos = 'relationships___field_ico_1___relationships___paragraph__logos',
  relationships___field_ico_1___relationships___paragraph__how_it_works = 'relationships___field_ico_1___relationships___paragraph__how_it_works',
  relationships___field_ico_1___relationships___paragraph__ico_and_text = 'relationships___field_ico_1___relationships___paragraph__ico_and_text',
  relationships___field_ico_1___relationships___paragraph__hero_1 = 'relationships___field_ico_1___relationships___paragraph__hero_1',
  relationships___field_ico_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico_1___relationships___site_setting_entity__product = 'relationships___field_ico_1___relationships___site_setting_entity__product',
  relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico_1___relationships___paragraph__porownanie_terminali = 'relationships___field_ico_1___relationships___paragraph__porownanie_terminali',
  relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico_1___relationships___site_setting_entity__main_page = 'relationships___field_ico_1___relationships___site_setting_entity__main_page',
  relationships___field_ico_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico_1___relationships___site_setting_entity__intercars = 'relationships___field_ico_1___relationships___site_setting_entity__intercars',
  relationships___field_ico_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico_1___relationships___node__agreement = 'relationships___field_ico_1___relationships___node__agreement',
  relationships___field_ico_1___relationships___node__fiscalization = 'relationships___field_ico_1___relationships___node__fiscalization',
  relationships___field_ico_1___relationships___site_setting_entity__configurator = 'relationships___field_ico_1___relationships___site_setting_entity__configurator',
  relationships___field_ico_1___relationships___paragraph__social_link = 'relationships___field_ico_1___relationships___paragraph__social_link',
  relationships___field_ico_1___relationships___paragraph__make_an_appointment = 'relationships___field_ico_1___relationships___paragraph__make_an_appointment',
  relationships___field_ico_1___relationships___paragraph__linked_icons = 'relationships___field_ico_1___relationships___paragraph__linked_icons',
  relationships___field_ico_1___relationships___site_setting_entity__financing = 'relationships___field_ico_1___relationships___site_setting_entity__financing',
  relationships___field_ico_1___relationships___site_setting_entity__franchise = 'relationships___field_ico_1___relationships___site_setting_entity__franchise',
  relationships___field_ico_1___id = 'relationships___field_ico_1___id',
  relationships___field_ico_1___parent___id = 'relationships___field_ico_1___parent___id',
  relationships___field_ico_1___parent___children = 'relationships___field_ico_1___parent___children',
  relationships___field_ico_1___children = 'relationships___field_ico_1___children',
  relationships___field_ico_1___children___id = 'relationships___field_ico_1___children___id',
  relationships___field_ico_1___children___children = 'relationships___field_ico_1___children___children',
  relationships___field_ico_1___internal___content = 'relationships___field_ico_1___internal___content',
  relationships___field_ico_1___internal___contentDigest = 'relationships___field_ico_1___internal___contentDigest',
  relationships___field_ico_1___internal___description = 'relationships___field_ico_1___internal___description',
  relationships___field_ico_1___internal___fieldOwners = 'relationships___field_ico_1___internal___fieldOwners',
  relationships___field_ico_1___internal___ignoreType = 'relationships___field_ico_1___internal___ignoreType',
  relationships___field_ico_1___internal___mediaType = 'relationships___field_ico_1___internal___mediaType',
  relationships___field_ico_1___internal___owner = 'relationships___field_ico_1___internal___owner',
  relationships___field_ico_1___internal___type = 'relationships___field_ico_1___internal___type',
  relationships___field_image___localFile___birthtime = 'relationships___field_image___localFile___birthtime',
  relationships___field_image___localFile___birthtimeMs = 'relationships___field_image___localFile___birthtimeMs',
  relationships___field_image___localFile___publicURL = 'relationships___field_image___localFile___publicURL',
  relationships___field_image___localFile___sourceInstanceName = 'relationships___field_image___localFile___sourceInstanceName',
  relationships___field_image___localFile___absolutePath = 'relationships___field_image___localFile___absolutePath',
  relationships___field_image___localFile___relativePath = 'relationships___field_image___localFile___relativePath',
  relationships___field_image___localFile___extension = 'relationships___field_image___localFile___extension',
  relationships___field_image___localFile___size = 'relationships___field_image___localFile___size',
  relationships___field_image___localFile___prettySize = 'relationships___field_image___localFile___prettySize',
  relationships___field_image___localFile___modifiedTime = 'relationships___field_image___localFile___modifiedTime',
  relationships___field_image___localFile___accessTime = 'relationships___field_image___localFile___accessTime',
  relationships___field_image___localFile___changeTime = 'relationships___field_image___localFile___changeTime',
  relationships___field_image___localFile___birthTime = 'relationships___field_image___localFile___birthTime',
  relationships___field_image___localFile___root = 'relationships___field_image___localFile___root',
  relationships___field_image___localFile___dir = 'relationships___field_image___localFile___dir',
  relationships___field_image___localFile___base = 'relationships___field_image___localFile___base',
  relationships___field_image___localFile___ext = 'relationships___field_image___localFile___ext',
  relationships___field_image___localFile___name = 'relationships___field_image___localFile___name',
  relationships___field_image___localFile___relativeDirectory = 'relationships___field_image___localFile___relativeDirectory',
  relationships___field_image___localFile___dev = 'relationships___field_image___localFile___dev',
  relationships___field_image___localFile___mode = 'relationships___field_image___localFile___mode',
  relationships___field_image___localFile___nlink = 'relationships___field_image___localFile___nlink',
  relationships___field_image___localFile___uid = 'relationships___field_image___localFile___uid',
  relationships___field_image___localFile___gid = 'relationships___field_image___localFile___gid',
  relationships___field_image___localFile___rdev = 'relationships___field_image___localFile___rdev',
  relationships___field_image___localFile___blksize = 'relationships___field_image___localFile___blksize',
  relationships___field_image___localFile___ino = 'relationships___field_image___localFile___ino',
  relationships___field_image___localFile___blocks = 'relationships___field_image___localFile___blocks',
  relationships___field_image___localFile___atimeMs = 'relationships___field_image___localFile___atimeMs',
  relationships___field_image___localFile___mtimeMs = 'relationships___field_image___localFile___mtimeMs',
  relationships___field_image___localFile___ctimeMs = 'relationships___field_image___localFile___ctimeMs',
  relationships___field_image___localFile___atime = 'relationships___field_image___localFile___atime',
  relationships___field_image___localFile___mtime = 'relationships___field_image___localFile___mtime',
  relationships___field_image___localFile___ctime = 'relationships___field_image___localFile___ctime',
  relationships___field_image___localFile___url = 'relationships___field_image___localFile___url',
  relationships___field_image___localFile___id = 'relationships___field_image___localFile___id',
  relationships___field_image___localFile___children = 'relationships___field_image___localFile___children',
  relationships___field_image___uri___url = 'relationships___field_image___uri___url',
  relationships___field_image___drupal_id = 'relationships___field_image___drupal_id',
  relationships___field_image___drupal_internal__fid = 'relationships___field_image___drupal_internal__fid',
  relationships___field_image___langcode = 'relationships___field_image___langcode',
  relationships___field_image___filename = 'relationships___field_image___filename',
  relationships___field_image___filemime = 'relationships___field_image___filemime',
  relationships___field_image___filesize = 'relationships___field_image___filesize',
  relationships___field_image___status = 'relationships___field_image___status',
  relationships___field_image___created = 'relationships___field_image___created',
  relationships___field_image___changed = 'relationships___field_image___changed',
  relationships___field_image___uuid = 'relationships___field_image___uuid',
  relationships___field_image___relationships___node__blog_post = 'relationships___field_image___relationships___node__blog_post',
  relationships___field_image___relationships___node__devices = 'relationships___field_image___relationships___node__devices',
  relationships___field_image___relationships___node__accessories = 'relationships___field_image___relationships___node__accessories',
  relationships___field_image___relationships___paragraph__news_image_title_text = 'relationships___field_image___relationships___paragraph__news_image_title_text',
  relationships___field_image___relationships___node__cases = 'relationships___field_image___relationships___node__cases',
  relationships___field_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image___relationships___paragraph__branches_features = 'relationships___field_image___relationships___paragraph__branches_features',
  relationships___field_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image___relationships___paragraph__branches_testimonials = 'relationships___field_image___relationships___paragraph__branches_testimonials',
  relationships___field_image___relationships___paragraph__branches_discounts = 'relationships___field_image___relationships___paragraph__branches_discounts',
  relationships___field_image___relationships___node__news = 'relationships___field_image___relationships___node__news',
  relationships___field_image___relationships___node__branches = 'relationships___field_image___relationships___node__branches',
  relationships___field_image___relationships___paragraph__branches_hero = 'relationships___field_image___relationships___paragraph__branches_hero',
  relationships___field_image___relationships___paragraph__bullets = 'relationships___field_image___relationships___paragraph__bullets',
  relationships___field_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_image___relationships___paragraph__branches_device = 'relationships___field_image___relationships___paragraph__branches_device',
  relationships___field_image___relationships___paragraph__branches_recommendation = 'relationships___field_image___relationships___paragraph__branches_recommendation',
  relationships___field_image___relationships___site_setting_entity__accessories = 'relationships___field_image___relationships___site_setting_entity__accessories',
  relationships___field_image___relationships___site_setting_entity__branches = 'relationships___field_image___relationships___site_setting_entity__branches',
  relationships___field_image___relationships___site_setting_entity__about_us = 'relationships___field_image___relationships___site_setting_entity__about_us',
  relationships___field_image___relationships___paragraph__logos = 'relationships___field_image___relationships___paragraph__logos',
  relationships___field_image___relationships___paragraph__how_it_works = 'relationships___field_image___relationships___paragraph__how_it_works',
  relationships___field_image___relationships___paragraph__ico_and_text = 'relationships___field_image___relationships___paragraph__ico_and_text',
  relationships___field_image___relationships___paragraph__hero_1 = 'relationships___field_image___relationships___paragraph__hero_1',
  relationships___field_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image___relationships___site_setting_entity__product = 'relationships___field_image___relationships___site_setting_entity__product',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image___relationships___paragraph__porownanie_terminali = 'relationships___field_image___relationships___paragraph__porownanie_terminali',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image___relationships___site_setting_entity__main_page = 'relationships___field_image___relationships___site_setting_entity__main_page',
  relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image___relationships___site_setting_entity__intercars = 'relationships___field_image___relationships___site_setting_entity__intercars',
  relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image___relationships___node__agreement = 'relationships___field_image___relationships___node__agreement',
  relationships___field_image___relationships___node__fiscalization = 'relationships___field_image___relationships___node__fiscalization',
  relationships___field_image___relationships___site_setting_entity__configurator = 'relationships___field_image___relationships___site_setting_entity__configurator',
  relationships___field_image___relationships___paragraph__social_link = 'relationships___field_image___relationships___paragraph__social_link',
  relationships___field_image___relationships___paragraph__make_an_appointment = 'relationships___field_image___relationships___paragraph__make_an_appointment',
  relationships___field_image___relationships___paragraph__linked_icons = 'relationships___field_image___relationships___paragraph__linked_icons',
  relationships___field_image___relationships___site_setting_entity__financing = 'relationships___field_image___relationships___site_setting_entity__financing',
  relationships___field_image___relationships___site_setting_entity__franchise = 'relationships___field_image___relationships___site_setting_entity__franchise',
  relationships___field_image___id = 'relationships___field_image___id',
  relationships___field_image___parent___id = 'relationships___field_image___parent___id',
  relationships___field_image___parent___children = 'relationships___field_image___parent___children',
  relationships___field_image___children = 'relationships___field_image___children',
  relationships___field_image___children___id = 'relationships___field_image___children___id',
  relationships___field_image___children___children = 'relationships___field_image___children___children',
  relationships___field_image___internal___content = 'relationships___field_image___internal___content',
  relationships___field_image___internal___contentDigest = 'relationships___field_image___internal___contentDigest',
  relationships___field_image___internal___description = 'relationships___field_image___internal___description',
  relationships___field_image___internal___fieldOwners = 'relationships___field_image___internal___fieldOwners',
  relationships___field_image___internal___ignoreType = 'relationships___field_image___internal___ignoreType',
  relationships___field_image___internal___mediaType = 'relationships___field_image___internal___mediaType',
  relationships___field_image___internal___owner = 'relationships___field_image___internal___owner',
  relationships___field_image___internal___type = 'relationships___field_image___internal___type',
  relationships___field_image_1___localFile___birthtime = 'relationships___field_image_1___localFile___birthtime',
  relationships___field_image_1___localFile___birthtimeMs = 'relationships___field_image_1___localFile___birthtimeMs',
  relationships___field_image_1___localFile___publicURL = 'relationships___field_image_1___localFile___publicURL',
  relationships___field_image_1___localFile___sourceInstanceName = 'relationships___field_image_1___localFile___sourceInstanceName',
  relationships___field_image_1___localFile___absolutePath = 'relationships___field_image_1___localFile___absolutePath',
  relationships___field_image_1___localFile___relativePath = 'relationships___field_image_1___localFile___relativePath',
  relationships___field_image_1___localFile___extension = 'relationships___field_image_1___localFile___extension',
  relationships___field_image_1___localFile___size = 'relationships___field_image_1___localFile___size',
  relationships___field_image_1___localFile___prettySize = 'relationships___field_image_1___localFile___prettySize',
  relationships___field_image_1___localFile___modifiedTime = 'relationships___field_image_1___localFile___modifiedTime',
  relationships___field_image_1___localFile___accessTime = 'relationships___field_image_1___localFile___accessTime',
  relationships___field_image_1___localFile___changeTime = 'relationships___field_image_1___localFile___changeTime',
  relationships___field_image_1___localFile___birthTime = 'relationships___field_image_1___localFile___birthTime',
  relationships___field_image_1___localFile___root = 'relationships___field_image_1___localFile___root',
  relationships___field_image_1___localFile___dir = 'relationships___field_image_1___localFile___dir',
  relationships___field_image_1___localFile___base = 'relationships___field_image_1___localFile___base',
  relationships___field_image_1___localFile___ext = 'relationships___field_image_1___localFile___ext',
  relationships___field_image_1___localFile___name = 'relationships___field_image_1___localFile___name',
  relationships___field_image_1___localFile___relativeDirectory = 'relationships___field_image_1___localFile___relativeDirectory',
  relationships___field_image_1___localFile___dev = 'relationships___field_image_1___localFile___dev',
  relationships___field_image_1___localFile___mode = 'relationships___field_image_1___localFile___mode',
  relationships___field_image_1___localFile___nlink = 'relationships___field_image_1___localFile___nlink',
  relationships___field_image_1___localFile___uid = 'relationships___field_image_1___localFile___uid',
  relationships___field_image_1___localFile___gid = 'relationships___field_image_1___localFile___gid',
  relationships___field_image_1___localFile___rdev = 'relationships___field_image_1___localFile___rdev',
  relationships___field_image_1___localFile___blksize = 'relationships___field_image_1___localFile___blksize',
  relationships___field_image_1___localFile___ino = 'relationships___field_image_1___localFile___ino',
  relationships___field_image_1___localFile___blocks = 'relationships___field_image_1___localFile___blocks',
  relationships___field_image_1___localFile___atimeMs = 'relationships___field_image_1___localFile___atimeMs',
  relationships___field_image_1___localFile___mtimeMs = 'relationships___field_image_1___localFile___mtimeMs',
  relationships___field_image_1___localFile___ctimeMs = 'relationships___field_image_1___localFile___ctimeMs',
  relationships___field_image_1___localFile___atime = 'relationships___field_image_1___localFile___atime',
  relationships___field_image_1___localFile___mtime = 'relationships___field_image_1___localFile___mtime',
  relationships___field_image_1___localFile___ctime = 'relationships___field_image_1___localFile___ctime',
  relationships___field_image_1___localFile___url = 'relationships___field_image_1___localFile___url',
  relationships___field_image_1___localFile___id = 'relationships___field_image_1___localFile___id',
  relationships___field_image_1___localFile___children = 'relationships___field_image_1___localFile___children',
  relationships___field_image_1___uri___url = 'relationships___field_image_1___uri___url',
  relationships___field_image_1___drupal_id = 'relationships___field_image_1___drupal_id',
  relationships___field_image_1___drupal_internal__fid = 'relationships___field_image_1___drupal_internal__fid',
  relationships___field_image_1___langcode = 'relationships___field_image_1___langcode',
  relationships___field_image_1___filename = 'relationships___field_image_1___filename',
  relationships___field_image_1___filemime = 'relationships___field_image_1___filemime',
  relationships___field_image_1___filesize = 'relationships___field_image_1___filesize',
  relationships___field_image_1___status = 'relationships___field_image_1___status',
  relationships___field_image_1___created = 'relationships___field_image_1___created',
  relationships___field_image_1___changed = 'relationships___field_image_1___changed',
  relationships___field_image_1___uuid = 'relationships___field_image_1___uuid',
  relationships___field_image_1___relationships___node__blog_post = 'relationships___field_image_1___relationships___node__blog_post',
  relationships___field_image_1___relationships___node__devices = 'relationships___field_image_1___relationships___node__devices',
  relationships___field_image_1___relationships___node__accessories = 'relationships___field_image_1___relationships___node__accessories',
  relationships___field_image_1___relationships___paragraph__news_image_title_text = 'relationships___field_image_1___relationships___paragraph__news_image_title_text',
  relationships___field_image_1___relationships___node__cases = 'relationships___field_image_1___relationships___node__cases',
  relationships___field_image_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_1___relationships___paragraph__branches_features = 'relationships___field_image_1___relationships___paragraph__branches_features',
  relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_1___relationships___paragraph__branches_testimonials = 'relationships___field_image_1___relationships___paragraph__branches_testimonials',
  relationships___field_image_1___relationships___paragraph__branches_discounts = 'relationships___field_image_1___relationships___paragraph__branches_discounts',
  relationships___field_image_1___relationships___node__news = 'relationships___field_image_1___relationships___node__news',
  relationships___field_image_1___relationships___node__branches = 'relationships___field_image_1___relationships___node__branches',
  relationships___field_image_1___relationships___paragraph__branches_hero = 'relationships___field_image_1___relationships___paragraph__branches_hero',
  relationships___field_image_1___relationships___paragraph__bullets = 'relationships___field_image_1___relationships___paragraph__bullets',
  relationships___field_image_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_1___relationships___paragraph__branches_device = 'relationships___field_image_1___relationships___paragraph__branches_device',
  relationships___field_image_1___relationships___paragraph__branches_recommendation = 'relationships___field_image_1___relationships___paragraph__branches_recommendation',
  relationships___field_image_1___relationships___site_setting_entity__accessories = 'relationships___field_image_1___relationships___site_setting_entity__accessories',
  relationships___field_image_1___relationships___site_setting_entity__branches = 'relationships___field_image_1___relationships___site_setting_entity__branches',
  relationships___field_image_1___relationships___site_setting_entity__about_us = 'relationships___field_image_1___relationships___site_setting_entity__about_us',
  relationships___field_image_1___relationships___paragraph__logos = 'relationships___field_image_1___relationships___paragraph__logos',
  relationships___field_image_1___relationships___paragraph__how_it_works = 'relationships___field_image_1___relationships___paragraph__how_it_works',
  relationships___field_image_1___relationships___paragraph__ico_and_text = 'relationships___field_image_1___relationships___paragraph__ico_and_text',
  relationships___field_image_1___relationships___paragraph__hero_1 = 'relationships___field_image_1___relationships___paragraph__hero_1',
  relationships___field_image_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_1___relationships___site_setting_entity__product = 'relationships___field_image_1___relationships___site_setting_entity__product',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_1___relationships___paragraph__porownanie_terminali = 'relationships___field_image_1___relationships___paragraph__porownanie_terminali',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_1___relationships___site_setting_entity__main_page = 'relationships___field_image_1___relationships___site_setting_entity__main_page',
  relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_1___relationships___site_setting_entity__intercars = 'relationships___field_image_1___relationships___site_setting_entity__intercars',
  relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_1___relationships___node__agreement = 'relationships___field_image_1___relationships___node__agreement',
  relationships___field_image_1___relationships___node__fiscalization = 'relationships___field_image_1___relationships___node__fiscalization',
  relationships___field_image_1___relationships___site_setting_entity__configurator = 'relationships___field_image_1___relationships___site_setting_entity__configurator',
  relationships___field_image_1___relationships___paragraph__social_link = 'relationships___field_image_1___relationships___paragraph__social_link',
  relationships___field_image_1___relationships___paragraph__make_an_appointment = 'relationships___field_image_1___relationships___paragraph__make_an_appointment',
  relationships___field_image_1___relationships___paragraph__linked_icons = 'relationships___field_image_1___relationships___paragraph__linked_icons',
  relationships___field_image_1___relationships___site_setting_entity__financing = 'relationships___field_image_1___relationships___site_setting_entity__financing',
  relationships___field_image_1___relationships___site_setting_entity__franchise = 'relationships___field_image_1___relationships___site_setting_entity__franchise',
  relationships___field_image_1___id = 'relationships___field_image_1___id',
  relationships___field_image_1___parent___id = 'relationships___field_image_1___parent___id',
  relationships___field_image_1___parent___children = 'relationships___field_image_1___parent___children',
  relationships___field_image_1___children = 'relationships___field_image_1___children',
  relationships___field_image_1___children___id = 'relationships___field_image_1___children___id',
  relationships___field_image_1___children___children = 'relationships___field_image_1___children___children',
  relationships___field_image_1___internal___content = 'relationships___field_image_1___internal___content',
  relationships___field_image_1___internal___contentDigest = 'relationships___field_image_1___internal___contentDigest',
  relationships___field_image_1___internal___description = 'relationships___field_image_1___internal___description',
  relationships___field_image_1___internal___fieldOwners = 'relationships___field_image_1___internal___fieldOwners',
  relationships___field_image_1___internal___ignoreType = 'relationships___field_image_1___internal___ignoreType',
  relationships___field_image_1___internal___mediaType = 'relationships___field_image_1___internal___mediaType',
  relationships___field_image_1___internal___owner = 'relationships___field_image_1___internal___owner',
  relationships___field_image_1___internal___type = 'relationships___field_image_1___internal___type',
  relationships___field_image_2___localFile___birthtime = 'relationships___field_image_2___localFile___birthtime',
  relationships___field_image_2___localFile___birthtimeMs = 'relationships___field_image_2___localFile___birthtimeMs',
  relationships___field_image_2___localFile___publicURL = 'relationships___field_image_2___localFile___publicURL',
  relationships___field_image_2___localFile___sourceInstanceName = 'relationships___field_image_2___localFile___sourceInstanceName',
  relationships___field_image_2___localFile___absolutePath = 'relationships___field_image_2___localFile___absolutePath',
  relationships___field_image_2___localFile___relativePath = 'relationships___field_image_2___localFile___relativePath',
  relationships___field_image_2___localFile___extension = 'relationships___field_image_2___localFile___extension',
  relationships___field_image_2___localFile___size = 'relationships___field_image_2___localFile___size',
  relationships___field_image_2___localFile___prettySize = 'relationships___field_image_2___localFile___prettySize',
  relationships___field_image_2___localFile___modifiedTime = 'relationships___field_image_2___localFile___modifiedTime',
  relationships___field_image_2___localFile___accessTime = 'relationships___field_image_2___localFile___accessTime',
  relationships___field_image_2___localFile___changeTime = 'relationships___field_image_2___localFile___changeTime',
  relationships___field_image_2___localFile___birthTime = 'relationships___field_image_2___localFile___birthTime',
  relationships___field_image_2___localFile___root = 'relationships___field_image_2___localFile___root',
  relationships___field_image_2___localFile___dir = 'relationships___field_image_2___localFile___dir',
  relationships___field_image_2___localFile___base = 'relationships___field_image_2___localFile___base',
  relationships___field_image_2___localFile___ext = 'relationships___field_image_2___localFile___ext',
  relationships___field_image_2___localFile___name = 'relationships___field_image_2___localFile___name',
  relationships___field_image_2___localFile___relativeDirectory = 'relationships___field_image_2___localFile___relativeDirectory',
  relationships___field_image_2___localFile___dev = 'relationships___field_image_2___localFile___dev',
  relationships___field_image_2___localFile___mode = 'relationships___field_image_2___localFile___mode',
  relationships___field_image_2___localFile___nlink = 'relationships___field_image_2___localFile___nlink',
  relationships___field_image_2___localFile___uid = 'relationships___field_image_2___localFile___uid',
  relationships___field_image_2___localFile___gid = 'relationships___field_image_2___localFile___gid',
  relationships___field_image_2___localFile___rdev = 'relationships___field_image_2___localFile___rdev',
  relationships___field_image_2___localFile___blksize = 'relationships___field_image_2___localFile___blksize',
  relationships___field_image_2___localFile___ino = 'relationships___field_image_2___localFile___ino',
  relationships___field_image_2___localFile___blocks = 'relationships___field_image_2___localFile___blocks',
  relationships___field_image_2___localFile___atimeMs = 'relationships___field_image_2___localFile___atimeMs',
  relationships___field_image_2___localFile___mtimeMs = 'relationships___field_image_2___localFile___mtimeMs',
  relationships___field_image_2___localFile___ctimeMs = 'relationships___field_image_2___localFile___ctimeMs',
  relationships___field_image_2___localFile___atime = 'relationships___field_image_2___localFile___atime',
  relationships___field_image_2___localFile___mtime = 'relationships___field_image_2___localFile___mtime',
  relationships___field_image_2___localFile___ctime = 'relationships___field_image_2___localFile___ctime',
  relationships___field_image_2___localFile___url = 'relationships___field_image_2___localFile___url',
  relationships___field_image_2___localFile___id = 'relationships___field_image_2___localFile___id',
  relationships___field_image_2___localFile___children = 'relationships___field_image_2___localFile___children',
  relationships___field_image_2___uri___url = 'relationships___field_image_2___uri___url',
  relationships___field_image_2___drupal_id = 'relationships___field_image_2___drupal_id',
  relationships___field_image_2___drupal_internal__fid = 'relationships___field_image_2___drupal_internal__fid',
  relationships___field_image_2___langcode = 'relationships___field_image_2___langcode',
  relationships___field_image_2___filename = 'relationships___field_image_2___filename',
  relationships___field_image_2___filemime = 'relationships___field_image_2___filemime',
  relationships___field_image_2___filesize = 'relationships___field_image_2___filesize',
  relationships___field_image_2___status = 'relationships___field_image_2___status',
  relationships___field_image_2___created = 'relationships___field_image_2___created',
  relationships___field_image_2___changed = 'relationships___field_image_2___changed',
  relationships___field_image_2___uuid = 'relationships___field_image_2___uuid',
  relationships___field_image_2___relationships___node__blog_post = 'relationships___field_image_2___relationships___node__blog_post',
  relationships___field_image_2___relationships___node__devices = 'relationships___field_image_2___relationships___node__devices',
  relationships___field_image_2___relationships___node__accessories = 'relationships___field_image_2___relationships___node__accessories',
  relationships___field_image_2___relationships___paragraph__news_image_title_text = 'relationships___field_image_2___relationships___paragraph__news_image_title_text',
  relationships___field_image_2___relationships___node__cases = 'relationships___field_image_2___relationships___node__cases',
  relationships___field_image_2___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_2___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_2___relationships___paragraph__branches_features = 'relationships___field_image_2___relationships___paragraph__branches_features',
  relationships___field_image_2___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_2___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_2___relationships___paragraph__branches_testimonials = 'relationships___field_image_2___relationships___paragraph__branches_testimonials',
  relationships___field_image_2___relationships___paragraph__branches_discounts = 'relationships___field_image_2___relationships___paragraph__branches_discounts',
  relationships___field_image_2___relationships___node__news = 'relationships___field_image_2___relationships___node__news',
  relationships___field_image_2___relationships___node__branches = 'relationships___field_image_2___relationships___node__branches',
  relationships___field_image_2___relationships___paragraph__branches_hero = 'relationships___field_image_2___relationships___paragraph__branches_hero',
  relationships___field_image_2___relationships___paragraph__bullets = 'relationships___field_image_2___relationships___paragraph__bullets',
  relationships___field_image_2___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_2___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_2___relationships___paragraph__branches_device = 'relationships___field_image_2___relationships___paragraph__branches_device',
  relationships___field_image_2___relationships___paragraph__branches_recommendation = 'relationships___field_image_2___relationships___paragraph__branches_recommendation',
  relationships___field_image_2___relationships___site_setting_entity__accessories = 'relationships___field_image_2___relationships___site_setting_entity__accessories',
  relationships___field_image_2___relationships___site_setting_entity__branches = 'relationships___field_image_2___relationships___site_setting_entity__branches',
  relationships___field_image_2___relationships___site_setting_entity__about_us = 'relationships___field_image_2___relationships___site_setting_entity__about_us',
  relationships___field_image_2___relationships___paragraph__logos = 'relationships___field_image_2___relationships___paragraph__logos',
  relationships___field_image_2___relationships___paragraph__how_it_works = 'relationships___field_image_2___relationships___paragraph__how_it_works',
  relationships___field_image_2___relationships___paragraph__ico_and_text = 'relationships___field_image_2___relationships___paragraph__ico_and_text',
  relationships___field_image_2___relationships___paragraph__hero_1 = 'relationships___field_image_2___relationships___paragraph__hero_1',
  relationships___field_image_2___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_2___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_2___relationships___site_setting_entity__product = 'relationships___field_image_2___relationships___site_setting_entity__product',
  relationships___field_image_2___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_2___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_2___relationships___paragraph__porownanie_terminali = 'relationships___field_image_2___relationships___paragraph__porownanie_terminali',
  relationships___field_image_2___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_2___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_2___relationships___site_setting_entity__main_page = 'relationships___field_image_2___relationships___site_setting_entity__main_page',
  relationships___field_image_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_2___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_2___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_2___relationships___site_setting_entity__intercars = 'relationships___field_image_2___relationships___site_setting_entity__intercars',
  relationships___field_image_2___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_2___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_2___relationships___node__agreement = 'relationships___field_image_2___relationships___node__agreement',
  relationships___field_image_2___relationships___node__fiscalization = 'relationships___field_image_2___relationships___node__fiscalization',
  relationships___field_image_2___relationships___site_setting_entity__configurator = 'relationships___field_image_2___relationships___site_setting_entity__configurator',
  relationships___field_image_2___relationships___paragraph__social_link = 'relationships___field_image_2___relationships___paragraph__social_link',
  relationships___field_image_2___relationships___paragraph__make_an_appointment = 'relationships___field_image_2___relationships___paragraph__make_an_appointment',
  relationships___field_image_2___relationships___paragraph__linked_icons = 'relationships___field_image_2___relationships___paragraph__linked_icons',
  relationships___field_image_2___relationships___site_setting_entity__financing = 'relationships___field_image_2___relationships___site_setting_entity__financing',
  relationships___field_image_2___relationships___site_setting_entity__franchise = 'relationships___field_image_2___relationships___site_setting_entity__franchise',
  relationships___field_image_2___id = 'relationships___field_image_2___id',
  relationships___field_image_2___parent___id = 'relationships___field_image_2___parent___id',
  relationships___field_image_2___parent___children = 'relationships___field_image_2___parent___children',
  relationships___field_image_2___children = 'relationships___field_image_2___children',
  relationships___field_image_2___children___id = 'relationships___field_image_2___children___id',
  relationships___field_image_2___children___children = 'relationships___field_image_2___children___children',
  relationships___field_image_2___internal___content = 'relationships___field_image_2___internal___content',
  relationships___field_image_2___internal___contentDigest = 'relationships___field_image_2___internal___contentDigest',
  relationships___field_image_2___internal___description = 'relationships___field_image_2___internal___description',
  relationships___field_image_2___internal___fieldOwners = 'relationships___field_image_2___internal___fieldOwners',
  relationships___field_image_2___internal___ignoreType = 'relationships___field_image_2___internal___ignoreType',
  relationships___field_image_2___internal___mediaType = 'relationships___field_image_2___internal___mediaType',
  relationships___field_image_2___internal___owner = 'relationships___field_image_2___internal___owner',
  relationships___field_image_2___internal___type = 'relationships___field_image_2___internal___type',
  relationships___field_image_3___localFile___birthtime = 'relationships___field_image_3___localFile___birthtime',
  relationships___field_image_3___localFile___birthtimeMs = 'relationships___field_image_3___localFile___birthtimeMs',
  relationships___field_image_3___localFile___publicURL = 'relationships___field_image_3___localFile___publicURL',
  relationships___field_image_3___localFile___sourceInstanceName = 'relationships___field_image_3___localFile___sourceInstanceName',
  relationships___field_image_3___localFile___absolutePath = 'relationships___field_image_3___localFile___absolutePath',
  relationships___field_image_3___localFile___relativePath = 'relationships___field_image_3___localFile___relativePath',
  relationships___field_image_3___localFile___extension = 'relationships___field_image_3___localFile___extension',
  relationships___field_image_3___localFile___size = 'relationships___field_image_3___localFile___size',
  relationships___field_image_3___localFile___prettySize = 'relationships___field_image_3___localFile___prettySize',
  relationships___field_image_3___localFile___modifiedTime = 'relationships___field_image_3___localFile___modifiedTime',
  relationships___field_image_3___localFile___accessTime = 'relationships___field_image_3___localFile___accessTime',
  relationships___field_image_3___localFile___changeTime = 'relationships___field_image_3___localFile___changeTime',
  relationships___field_image_3___localFile___birthTime = 'relationships___field_image_3___localFile___birthTime',
  relationships___field_image_3___localFile___root = 'relationships___field_image_3___localFile___root',
  relationships___field_image_3___localFile___dir = 'relationships___field_image_3___localFile___dir',
  relationships___field_image_3___localFile___base = 'relationships___field_image_3___localFile___base',
  relationships___field_image_3___localFile___ext = 'relationships___field_image_3___localFile___ext',
  relationships___field_image_3___localFile___name = 'relationships___field_image_3___localFile___name',
  relationships___field_image_3___localFile___relativeDirectory = 'relationships___field_image_3___localFile___relativeDirectory',
  relationships___field_image_3___localFile___dev = 'relationships___field_image_3___localFile___dev',
  relationships___field_image_3___localFile___mode = 'relationships___field_image_3___localFile___mode',
  relationships___field_image_3___localFile___nlink = 'relationships___field_image_3___localFile___nlink',
  relationships___field_image_3___localFile___uid = 'relationships___field_image_3___localFile___uid',
  relationships___field_image_3___localFile___gid = 'relationships___field_image_3___localFile___gid',
  relationships___field_image_3___localFile___rdev = 'relationships___field_image_3___localFile___rdev',
  relationships___field_image_3___localFile___blksize = 'relationships___field_image_3___localFile___blksize',
  relationships___field_image_3___localFile___ino = 'relationships___field_image_3___localFile___ino',
  relationships___field_image_3___localFile___blocks = 'relationships___field_image_3___localFile___blocks',
  relationships___field_image_3___localFile___atimeMs = 'relationships___field_image_3___localFile___atimeMs',
  relationships___field_image_3___localFile___mtimeMs = 'relationships___field_image_3___localFile___mtimeMs',
  relationships___field_image_3___localFile___ctimeMs = 'relationships___field_image_3___localFile___ctimeMs',
  relationships___field_image_3___localFile___atime = 'relationships___field_image_3___localFile___atime',
  relationships___field_image_3___localFile___mtime = 'relationships___field_image_3___localFile___mtime',
  relationships___field_image_3___localFile___ctime = 'relationships___field_image_3___localFile___ctime',
  relationships___field_image_3___localFile___url = 'relationships___field_image_3___localFile___url',
  relationships___field_image_3___localFile___id = 'relationships___field_image_3___localFile___id',
  relationships___field_image_3___localFile___children = 'relationships___field_image_3___localFile___children',
  relationships___field_image_3___uri___url = 'relationships___field_image_3___uri___url',
  relationships___field_image_3___drupal_id = 'relationships___field_image_3___drupal_id',
  relationships___field_image_3___drupal_internal__fid = 'relationships___field_image_3___drupal_internal__fid',
  relationships___field_image_3___langcode = 'relationships___field_image_3___langcode',
  relationships___field_image_3___filename = 'relationships___field_image_3___filename',
  relationships___field_image_3___filemime = 'relationships___field_image_3___filemime',
  relationships___field_image_3___filesize = 'relationships___field_image_3___filesize',
  relationships___field_image_3___status = 'relationships___field_image_3___status',
  relationships___field_image_3___created = 'relationships___field_image_3___created',
  relationships___field_image_3___changed = 'relationships___field_image_3___changed',
  relationships___field_image_3___uuid = 'relationships___field_image_3___uuid',
  relationships___field_image_3___relationships___node__blog_post = 'relationships___field_image_3___relationships___node__blog_post',
  relationships___field_image_3___relationships___node__devices = 'relationships___field_image_3___relationships___node__devices',
  relationships___field_image_3___relationships___node__accessories = 'relationships___field_image_3___relationships___node__accessories',
  relationships___field_image_3___relationships___paragraph__news_image_title_text = 'relationships___field_image_3___relationships___paragraph__news_image_title_text',
  relationships___field_image_3___relationships___node__cases = 'relationships___field_image_3___relationships___node__cases',
  relationships___field_image_3___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_3___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_3___relationships___paragraph__branches_features = 'relationships___field_image_3___relationships___paragraph__branches_features',
  relationships___field_image_3___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_3___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_3___relationships___paragraph__branches_testimonials = 'relationships___field_image_3___relationships___paragraph__branches_testimonials',
  relationships___field_image_3___relationships___paragraph__branches_discounts = 'relationships___field_image_3___relationships___paragraph__branches_discounts',
  relationships___field_image_3___relationships___node__news = 'relationships___field_image_3___relationships___node__news',
  relationships___field_image_3___relationships___node__branches = 'relationships___field_image_3___relationships___node__branches',
  relationships___field_image_3___relationships___paragraph__branches_hero = 'relationships___field_image_3___relationships___paragraph__branches_hero',
  relationships___field_image_3___relationships___paragraph__bullets = 'relationships___field_image_3___relationships___paragraph__bullets',
  relationships___field_image_3___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_3___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_3___relationships___paragraph__branches_device = 'relationships___field_image_3___relationships___paragraph__branches_device',
  relationships___field_image_3___relationships___paragraph__branches_recommendation = 'relationships___field_image_3___relationships___paragraph__branches_recommendation',
  relationships___field_image_3___relationships___site_setting_entity__accessories = 'relationships___field_image_3___relationships___site_setting_entity__accessories',
  relationships___field_image_3___relationships___site_setting_entity__branches = 'relationships___field_image_3___relationships___site_setting_entity__branches',
  relationships___field_image_3___relationships___site_setting_entity__about_us = 'relationships___field_image_3___relationships___site_setting_entity__about_us',
  relationships___field_image_3___relationships___paragraph__logos = 'relationships___field_image_3___relationships___paragraph__logos',
  relationships___field_image_3___relationships___paragraph__how_it_works = 'relationships___field_image_3___relationships___paragraph__how_it_works',
  relationships___field_image_3___relationships___paragraph__ico_and_text = 'relationships___field_image_3___relationships___paragraph__ico_and_text',
  relationships___field_image_3___relationships___paragraph__hero_1 = 'relationships___field_image_3___relationships___paragraph__hero_1',
  relationships___field_image_3___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_3___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_3___relationships___site_setting_entity__product = 'relationships___field_image_3___relationships___site_setting_entity__product',
  relationships___field_image_3___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_3___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_3___relationships___paragraph__porownanie_terminali = 'relationships___field_image_3___relationships___paragraph__porownanie_terminali',
  relationships___field_image_3___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_3___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_3___relationships___site_setting_entity__main_page = 'relationships___field_image_3___relationships___site_setting_entity__main_page',
  relationships___field_image_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_3___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_3___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_3___relationships___site_setting_entity__intercars = 'relationships___field_image_3___relationships___site_setting_entity__intercars',
  relationships___field_image_3___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_3___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_3___relationships___node__agreement = 'relationships___field_image_3___relationships___node__agreement',
  relationships___field_image_3___relationships___node__fiscalization = 'relationships___field_image_3___relationships___node__fiscalization',
  relationships___field_image_3___relationships___site_setting_entity__configurator = 'relationships___field_image_3___relationships___site_setting_entity__configurator',
  relationships___field_image_3___relationships___paragraph__social_link = 'relationships___field_image_3___relationships___paragraph__social_link',
  relationships___field_image_3___relationships___paragraph__make_an_appointment = 'relationships___field_image_3___relationships___paragraph__make_an_appointment',
  relationships___field_image_3___relationships___paragraph__linked_icons = 'relationships___field_image_3___relationships___paragraph__linked_icons',
  relationships___field_image_3___relationships___site_setting_entity__financing = 'relationships___field_image_3___relationships___site_setting_entity__financing',
  relationships___field_image_3___relationships___site_setting_entity__franchise = 'relationships___field_image_3___relationships___site_setting_entity__franchise',
  relationships___field_image_3___id = 'relationships___field_image_3___id',
  relationships___field_image_3___parent___id = 'relationships___field_image_3___parent___id',
  relationships___field_image_3___parent___children = 'relationships___field_image_3___parent___children',
  relationships___field_image_3___children = 'relationships___field_image_3___children',
  relationships___field_image_3___children___id = 'relationships___field_image_3___children___id',
  relationships___field_image_3___children___children = 'relationships___field_image_3___children___children',
  relationships___field_image_3___internal___content = 'relationships___field_image_3___internal___content',
  relationships___field_image_3___internal___contentDigest = 'relationships___field_image_3___internal___contentDigest',
  relationships___field_image_3___internal___description = 'relationships___field_image_3___internal___description',
  relationships___field_image_3___internal___fieldOwners = 'relationships___field_image_3___internal___fieldOwners',
  relationships___field_image_3___internal___ignoreType = 'relationships___field_image_3___internal___ignoreType',
  relationships___field_image_3___internal___mediaType = 'relationships___field_image_3___internal___mediaType',
  relationships___field_image_3___internal___owner = 'relationships___field_image_3___internal___owner',
  relationships___field_image_3___internal___type = 'relationships___field_image_3___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__How_It_WorksFilterInput = {
  field_description?: Maybe<Field_DescriptionFilterInput>;
  field_ico_1?: Maybe<Field_Ico_1FilterInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_image_2?: Maybe<Field_Image_2FilterInput>;
  field_image_3?: Maybe<Field_Image_3FilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  field_short_text_2?: Maybe<Field_Short_Text_2FilterInput>;
  field_short_text_3?: Maybe<Field_Short_Text_3FilterInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  relationships?: Maybe<Paragraph__How_It_WorksRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__How_It_WorksFilterListInput = {
  elemMatch?: Maybe<Paragraph__How_It_WorksFilterInput>;
};

export type Paragraph__How_It_WorksGroupConnection = {
  __typename?: 'paragraph__how_it_worksGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__How_It_WorksEdge>;
  nodes: Array<Paragraph__How_It_Works>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__How_It_WorksRelationships = {
  __typename?: 'paragraph__how_it_worksRelationships';
  field_ico_1?: Maybe<File__File>;
  field_image?: Maybe<File__File>;
  field_image_1?: Maybe<File__File>;
  field_image_2?: Maybe<File__File>;
  field_image_3?: Maybe<File__File>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
};

export type Paragraph__How_It_WorksRelationshipsFilterInput = {
  field_ico_1?: Maybe<File__FileFilterInput>;
  field_image?: Maybe<File__FileFilterInput>;
  field_image_1?: Maybe<File__FileFilterInput>;
  field_image_2?: Maybe<File__FileFilterInput>;
  field_image_3?: Maybe<File__FileFilterInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
};

export type Paragraph__How_It_WorksSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__How_It_WorksFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Ico_And_Text = Node & {
  __typename?: 'paragraph__ico_and_text';
  field_text?: Maybe<Field_Text>;
  field_ico?: Maybe<Field_Ico>;
  relationships?: Maybe<Paragraph__Ico_And_TextRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Ico_And_TextCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Ico_And_TextConnection = {
  __typename?: 'paragraph__ico_and_textConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Ico_And_TextEdge>;
  nodes: Array<Paragraph__Ico_And_Text>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Ico_And_TextGroupConnection>;
};

export type Paragraph__Ico_And_TextConnectionDistinctArgs = {
  field: Paragraph__Ico_And_TextFieldsEnum;
};

export type Paragraph__Ico_And_TextConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Ico_And_TextFieldsEnum;
};

export type Paragraph__Ico_And_TextEdge = {
  __typename?: 'paragraph__ico_and_textEdge';
  next?: Maybe<Paragraph__Ico_And_Text>;
  node: Paragraph__Ico_And_Text;
  previous?: Maybe<Paragraph__Ico_And_Text>;
};

export enum Paragraph__Ico_And_TextFieldsEnum {
  field_text___value = 'field_text___value',
  field_ico___alt = 'field_ico___alt',
  relationships___field_ico___localFile___birthtime = 'relationships___field_ico___localFile___birthtime',
  relationships___field_ico___localFile___birthtimeMs = 'relationships___field_ico___localFile___birthtimeMs',
  relationships___field_ico___localFile___publicURL = 'relationships___field_ico___localFile___publicURL',
  relationships___field_ico___localFile___sourceInstanceName = 'relationships___field_ico___localFile___sourceInstanceName',
  relationships___field_ico___localFile___absolutePath = 'relationships___field_ico___localFile___absolutePath',
  relationships___field_ico___localFile___relativePath = 'relationships___field_ico___localFile___relativePath',
  relationships___field_ico___localFile___extension = 'relationships___field_ico___localFile___extension',
  relationships___field_ico___localFile___size = 'relationships___field_ico___localFile___size',
  relationships___field_ico___localFile___prettySize = 'relationships___field_ico___localFile___prettySize',
  relationships___field_ico___localFile___modifiedTime = 'relationships___field_ico___localFile___modifiedTime',
  relationships___field_ico___localFile___accessTime = 'relationships___field_ico___localFile___accessTime',
  relationships___field_ico___localFile___changeTime = 'relationships___field_ico___localFile___changeTime',
  relationships___field_ico___localFile___birthTime = 'relationships___field_ico___localFile___birthTime',
  relationships___field_ico___localFile___root = 'relationships___field_ico___localFile___root',
  relationships___field_ico___localFile___dir = 'relationships___field_ico___localFile___dir',
  relationships___field_ico___localFile___base = 'relationships___field_ico___localFile___base',
  relationships___field_ico___localFile___ext = 'relationships___field_ico___localFile___ext',
  relationships___field_ico___localFile___name = 'relationships___field_ico___localFile___name',
  relationships___field_ico___localFile___relativeDirectory = 'relationships___field_ico___localFile___relativeDirectory',
  relationships___field_ico___localFile___dev = 'relationships___field_ico___localFile___dev',
  relationships___field_ico___localFile___mode = 'relationships___field_ico___localFile___mode',
  relationships___field_ico___localFile___nlink = 'relationships___field_ico___localFile___nlink',
  relationships___field_ico___localFile___uid = 'relationships___field_ico___localFile___uid',
  relationships___field_ico___localFile___gid = 'relationships___field_ico___localFile___gid',
  relationships___field_ico___localFile___rdev = 'relationships___field_ico___localFile___rdev',
  relationships___field_ico___localFile___blksize = 'relationships___field_ico___localFile___blksize',
  relationships___field_ico___localFile___ino = 'relationships___field_ico___localFile___ino',
  relationships___field_ico___localFile___blocks = 'relationships___field_ico___localFile___blocks',
  relationships___field_ico___localFile___atimeMs = 'relationships___field_ico___localFile___atimeMs',
  relationships___field_ico___localFile___mtimeMs = 'relationships___field_ico___localFile___mtimeMs',
  relationships___field_ico___localFile___ctimeMs = 'relationships___field_ico___localFile___ctimeMs',
  relationships___field_ico___localFile___atime = 'relationships___field_ico___localFile___atime',
  relationships___field_ico___localFile___mtime = 'relationships___field_ico___localFile___mtime',
  relationships___field_ico___localFile___ctime = 'relationships___field_ico___localFile___ctime',
  relationships___field_ico___localFile___url = 'relationships___field_ico___localFile___url',
  relationships___field_ico___localFile___id = 'relationships___field_ico___localFile___id',
  relationships___field_ico___localFile___children = 'relationships___field_ico___localFile___children',
  relationships___field_ico___uri___url = 'relationships___field_ico___uri___url',
  relationships___field_ico___drupal_id = 'relationships___field_ico___drupal_id',
  relationships___field_ico___drupal_internal__fid = 'relationships___field_ico___drupal_internal__fid',
  relationships___field_ico___langcode = 'relationships___field_ico___langcode',
  relationships___field_ico___filename = 'relationships___field_ico___filename',
  relationships___field_ico___filemime = 'relationships___field_ico___filemime',
  relationships___field_ico___filesize = 'relationships___field_ico___filesize',
  relationships___field_ico___status = 'relationships___field_ico___status',
  relationships___field_ico___created = 'relationships___field_ico___created',
  relationships___field_ico___changed = 'relationships___field_ico___changed',
  relationships___field_ico___uuid = 'relationships___field_ico___uuid',
  relationships___field_ico___relationships___node__blog_post = 'relationships___field_ico___relationships___node__blog_post',
  relationships___field_ico___relationships___node__devices = 'relationships___field_ico___relationships___node__devices',
  relationships___field_ico___relationships___node__accessories = 'relationships___field_ico___relationships___node__accessories',
  relationships___field_ico___relationships___paragraph__news_image_title_text = 'relationships___field_ico___relationships___paragraph__news_image_title_text',
  relationships___field_ico___relationships___node__cases = 'relationships___field_ico___relationships___node__cases',
  relationships___field_ico___relationships___paragraph__branches_what_is_ipos = 'relationships___field_ico___relationships___paragraph__branches_what_is_ipos',
  relationships___field_ico___relationships___paragraph__branches_features = 'relationships___field_ico___relationships___paragraph__branches_features',
  relationships___field_ico___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_ico___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_ico___relationships___paragraph__branches_testimonials = 'relationships___field_ico___relationships___paragraph__branches_testimonials',
  relationships___field_ico___relationships___paragraph__branches_discounts = 'relationships___field_ico___relationships___paragraph__branches_discounts',
  relationships___field_ico___relationships___node__news = 'relationships___field_ico___relationships___node__news',
  relationships___field_ico___relationships___node__branches = 'relationships___field_ico___relationships___node__branches',
  relationships___field_ico___relationships___paragraph__branches_hero = 'relationships___field_ico___relationships___paragraph__branches_hero',
  relationships___field_ico___relationships___paragraph__bullets = 'relationships___field_ico___relationships___paragraph__bullets',
  relationships___field_ico___relationships___taxonomy_term__pricelist_group = 'relationships___field_ico___relationships___taxonomy_term__pricelist_group',
  relationships___field_ico___relationships___paragraph__branches_device = 'relationships___field_ico___relationships___paragraph__branches_device',
  relationships___field_ico___relationships___paragraph__branches_recommendation = 'relationships___field_ico___relationships___paragraph__branches_recommendation',
  relationships___field_ico___relationships___site_setting_entity__accessories = 'relationships___field_ico___relationships___site_setting_entity__accessories',
  relationships___field_ico___relationships___site_setting_entity__branches = 'relationships___field_ico___relationships___site_setting_entity__branches',
  relationships___field_ico___relationships___site_setting_entity__about_us = 'relationships___field_ico___relationships___site_setting_entity__about_us',
  relationships___field_ico___relationships___paragraph__logos = 'relationships___field_ico___relationships___paragraph__logos',
  relationships___field_ico___relationships___paragraph__how_it_works = 'relationships___field_ico___relationships___paragraph__how_it_works',
  relationships___field_ico___relationships___paragraph__ico_and_text = 'relationships___field_ico___relationships___paragraph__ico_and_text',
  relationships___field_ico___relationships___paragraph__hero_1 = 'relationships___field_ico___relationships___paragraph__hero_1',
  relationships___field_ico___relationships___paragraph__comprehensive_solution_item = 'relationships___field_ico___relationships___paragraph__comprehensive_solution_item',
  relationships___field_ico___relationships___site_setting_entity__product = 'relationships___field_ico___relationships___site_setting_entity__product',
  relationships___field_ico___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_ico___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_ico___relationships___paragraph__porownanie_terminali = 'relationships___field_ico___relationships___paragraph__porownanie_terminali',
  relationships___field_ico___relationships___paragraph__zdjecie_opis_link = 'relationships___field_ico___relationships___paragraph__zdjecie_opis_link',
  relationships___field_ico___relationships___site_setting_entity__main_page = 'relationships___field_ico___relationships___site_setting_entity__main_page',
  relationships___field_ico___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_ico___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_ico___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_ico___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_ico___relationships___site_setting_entity__intercars = 'relationships___field_ico___relationships___site_setting_entity__intercars',
  relationships___field_ico___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_ico___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_ico___relationships___node__agreement = 'relationships___field_ico___relationships___node__agreement',
  relationships___field_ico___relationships___node__fiscalization = 'relationships___field_ico___relationships___node__fiscalization',
  relationships___field_ico___relationships___site_setting_entity__configurator = 'relationships___field_ico___relationships___site_setting_entity__configurator',
  relationships___field_ico___relationships___paragraph__social_link = 'relationships___field_ico___relationships___paragraph__social_link',
  relationships___field_ico___relationships___paragraph__make_an_appointment = 'relationships___field_ico___relationships___paragraph__make_an_appointment',
  relationships___field_ico___relationships___paragraph__linked_icons = 'relationships___field_ico___relationships___paragraph__linked_icons',
  relationships___field_ico___relationships___site_setting_entity__financing = 'relationships___field_ico___relationships___site_setting_entity__financing',
  relationships___field_ico___relationships___site_setting_entity__franchise = 'relationships___field_ico___relationships___site_setting_entity__franchise',
  relationships___field_ico___id = 'relationships___field_ico___id',
  relationships___field_ico___parent___id = 'relationships___field_ico___parent___id',
  relationships___field_ico___parent___children = 'relationships___field_ico___parent___children',
  relationships___field_ico___children = 'relationships___field_ico___children',
  relationships___field_ico___children___id = 'relationships___field_ico___children___id',
  relationships___field_ico___children___children = 'relationships___field_ico___children___children',
  relationships___field_ico___internal___content = 'relationships___field_ico___internal___content',
  relationships___field_ico___internal___contentDigest = 'relationships___field_ico___internal___contentDigest',
  relationships___field_ico___internal___description = 'relationships___field_ico___internal___description',
  relationships___field_ico___internal___fieldOwners = 'relationships___field_ico___internal___fieldOwners',
  relationships___field_ico___internal___ignoreType = 'relationships___field_ico___internal___ignoreType',
  relationships___field_ico___internal___mediaType = 'relationships___field_ico___internal___mediaType',
  relationships___field_ico___internal___owner = 'relationships___field_ico___internal___owner',
  relationships___field_ico___internal___type = 'relationships___field_ico___internal___type',
  relationships___paragraph__title_icons = 'relationships___paragraph__title_icons',
  relationships___paragraph__title_icons___field_paragraph_headline___value = 'relationships___paragraph__title_icons___field_paragraph_headline___value',
  relationships___paragraph__title_icons___relationships___field_ico_and_text = 'relationships___paragraph__title_icons___relationships___field_ico_and_text',
  relationships___paragraph__title_icons___relationships___site_setting_entity__franchise = 'relationships___paragraph__title_icons___relationships___site_setting_entity__franchise',
  relationships___paragraph__title_icons___drupal_id = 'relationships___paragraph__title_icons___drupal_id',
  relationships___paragraph__title_icons___drupal_internal__id = 'relationships___paragraph__title_icons___drupal_internal__id',
  relationships___paragraph__title_icons___drupal_internal__revision_id = 'relationships___paragraph__title_icons___drupal_internal__revision_id',
  relationships___paragraph__title_icons___langcode = 'relationships___paragraph__title_icons___langcode',
  relationships___paragraph__title_icons___status = 'relationships___paragraph__title_icons___status',
  relationships___paragraph__title_icons___created = 'relationships___paragraph__title_icons___created',
  relationships___paragraph__title_icons___parent_id = 'relationships___paragraph__title_icons___parent_id',
  relationships___paragraph__title_icons___parent_type = 'relationships___paragraph__title_icons___parent_type',
  relationships___paragraph__title_icons___parent_field_name = 'relationships___paragraph__title_icons___parent_field_name',
  relationships___paragraph__title_icons___default_langcode = 'relationships___paragraph__title_icons___default_langcode',
  relationships___paragraph__title_icons___revision_translation_affected = 'relationships___paragraph__title_icons___revision_translation_affected',
  relationships___paragraph__title_icons___content_translation_source = 'relationships___paragraph__title_icons___content_translation_source',
  relationships___paragraph__title_icons___content_translation_outdated = 'relationships___paragraph__title_icons___content_translation_outdated',
  relationships___paragraph__title_icons___uuid = 'relationships___paragraph__title_icons___uuid',
  relationships___paragraph__title_icons___field_ico_and_text = 'relationships___paragraph__title_icons___field_ico_and_text',
  relationships___paragraph__title_icons___field_ico_and_text___target_revision_id = 'relationships___paragraph__title_icons___field_ico_and_text___target_revision_id',
  relationships___paragraph__title_icons___field_ico_and_text___drupal_internal__target_id = 'relationships___paragraph__title_icons___field_ico_and_text___drupal_internal__target_id',
  relationships___paragraph__title_icons___id = 'relationships___paragraph__title_icons___id',
  relationships___paragraph__title_icons___parent___id = 'relationships___paragraph__title_icons___parent___id',
  relationships___paragraph__title_icons___parent___children = 'relationships___paragraph__title_icons___parent___children',
  relationships___paragraph__title_icons___children = 'relationships___paragraph__title_icons___children',
  relationships___paragraph__title_icons___children___id = 'relationships___paragraph__title_icons___children___id',
  relationships___paragraph__title_icons___children___children = 'relationships___paragraph__title_icons___children___children',
  relationships___paragraph__title_icons___internal___content = 'relationships___paragraph__title_icons___internal___content',
  relationships___paragraph__title_icons___internal___contentDigest = 'relationships___paragraph__title_icons___internal___contentDigest',
  relationships___paragraph__title_icons___internal___description = 'relationships___paragraph__title_icons___internal___description',
  relationships___paragraph__title_icons___internal___fieldOwners = 'relationships___paragraph__title_icons___internal___fieldOwners',
  relationships___paragraph__title_icons___internal___ignoreType = 'relationships___paragraph__title_icons___internal___ignoreType',
  relationships___paragraph__title_icons___internal___mediaType = 'relationships___paragraph__title_icons___internal___mediaType',
  relationships___paragraph__title_icons___internal___owner = 'relationships___paragraph__title_icons___internal___owner',
  relationships___paragraph__title_icons___internal___type = 'relationships___paragraph__title_icons___internal___type',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Ico_And_TextFilterInput = {
  field_text?: Maybe<Field_TextFilterInput>;
  field_ico?: Maybe<Field_IcoFilterInput>;
  relationships?: Maybe<Paragraph__Ico_And_TextRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Ico_And_TextFilterListInput = {
  elemMatch?: Maybe<Paragraph__Ico_And_TextFilterInput>;
};

export type Paragraph__Ico_And_TextGroupConnection = {
  __typename?: 'paragraph__ico_and_textGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Ico_And_TextEdge>;
  nodes: Array<Paragraph__Ico_And_Text>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Ico_And_TextRelationships = {
  __typename?: 'paragraph__ico_and_textRelationships';
  field_ico?: Maybe<File__File>;
  paragraph__title_icons?: Maybe<Array<Maybe<Paragraph__Title_Icons>>>;
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
};

export type Paragraph__Ico_And_TextRelationshipsFilterInput = {
  field_ico?: Maybe<File__FileFilterInput>;
  paragraph__title_icons?: Maybe<Paragraph__Title_IconsFilterListInput>;
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
};

export type Paragraph__Ico_And_TextSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Ico_And_TextFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Icons_Belt = Node & {
  __typename?: 'paragraph__icons_belt';
  id: Scalars['ID'];
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  field_icons_belt_enabled?: Maybe<Scalars['Boolean']>;
  field_icons_belt_heading?: Maybe<Scalars['String']>;
  field_icons_belt_show_urls: Array<Maybe<Scalars['String']>>;
  relationships?: Maybe<Paragraph__Icons_BeltRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_icons_belt_icons?: Maybe<Array<Maybe<Paragraph__Icons_BeltField_Icons_Belt_Icons>>>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Icons_BeltCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Icons_BeltContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Icons_BeltConnection = {
  __typename?: 'paragraph__icons_beltConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Icons_BeltEdge>;
  nodes: Array<Paragraph__Icons_Belt>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Icons_BeltGroupConnection>;
};

export type Paragraph__Icons_BeltConnectionDistinctArgs = {
  field: Paragraph__Icons_BeltFieldsEnum;
};

export type Paragraph__Icons_BeltConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Icons_BeltFieldsEnum;
};

export type Paragraph__Icons_BeltEdge = {
  __typename?: 'paragraph__icons_beltEdge';
  next?: Maybe<Paragraph__Icons_Belt>;
  node: Paragraph__Icons_Belt;
  previous?: Maybe<Paragraph__Icons_Belt>;
};

export type Paragraph__Icons_BeltField_Icons_Belt_Icons = {
  __typename?: 'paragraph__icons_beltField_icons_belt_icons';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Icons_BeltField_Icons_Belt_IconsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Icons_BeltField_Icons_Belt_IconsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Icons_BeltField_Icons_Belt_IconsFilterInput>;
};

export enum Paragraph__Icons_BeltFieldsEnum {
  id = 'id',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  field_icons_belt_enabled = 'field_icons_belt_enabled',
  field_icons_belt_heading = 'field_icons_belt_heading',
  field_icons_belt_show_urls = 'field_icons_belt_show_urls',
  relationships___field_icons_belt_icons = 'relationships___field_icons_belt_icons',
  relationships___field_icons_belt_icons___field_linked_icon_text = 'relationships___field_icons_belt_icons___field_linked_icon_text',
  relationships___field_icons_belt_icons___field_linked_icon_url = 'relationships___field_icons_belt_icons___field_linked_icon_url',
  relationships___field_icons_belt_icons___relationships___paragraph__icons_belt = 'relationships___field_icons_belt_icons___relationships___paragraph__icons_belt',
  relationships___field_icons_belt_icons___drupal_id = 'relationships___field_icons_belt_icons___drupal_id',
  relationships___field_icons_belt_icons___drupal_internal__id = 'relationships___field_icons_belt_icons___drupal_internal__id',
  relationships___field_icons_belt_icons___drupal_internal__revision_id = 'relationships___field_icons_belt_icons___drupal_internal__revision_id',
  relationships___field_icons_belt_icons___langcode = 'relationships___field_icons_belt_icons___langcode',
  relationships___field_icons_belt_icons___status = 'relationships___field_icons_belt_icons___status',
  relationships___field_icons_belt_icons___created = 'relationships___field_icons_belt_icons___created',
  relationships___field_icons_belt_icons___parent_id = 'relationships___field_icons_belt_icons___parent_id',
  relationships___field_icons_belt_icons___parent_type = 'relationships___field_icons_belt_icons___parent_type',
  relationships___field_icons_belt_icons___parent_field_name = 'relationships___field_icons_belt_icons___parent_field_name',
  relationships___field_icons_belt_icons___default_langcode = 'relationships___field_icons_belt_icons___default_langcode',
  relationships___field_icons_belt_icons___revision_translation_affected = 'relationships___field_icons_belt_icons___revision_translation_affected',
  relationships___field_icons_belt_icons___content_translation_source = 'relationships___field_icons_belt_icons___content_translation_source',
  relationships___field_icons_belt_icons___content_translation_outdated = 'relationships___field_icons_belt_icons___content_translation_outdated',
  relationships___field_icons_belt_icons___content_translation_changed = 'relationships___field_icons_belt_icons___content_translation_changed',
  relationships___field_icons_belt_icons___uuid = 'relationships___field_icons_belt_icons___uuid',
  relationships___field_icons_belt_icons___field_linked_icon_icon___alt = 'relationships___field_icons_belt_icons___field_linked_icon_icon___alt',
  relationships___field_icons_belt_icons___field_linked_icon_icon___title = 'relationships___field_icons_belt_icons___field_linked_icon_icon___title',
  relationships___field_icons_belt_icons___field_linked_icon_icon___width = 'relationships___field_icons_belt_icons___field_linked_icon_icon___width',
  relationships___field_icons_belt_icons___field_linked_icon_icon___height = 'relationships___field_icons_belt_icons___field_linked_icon_icon___height',
  relationships___field_icons_belt_icons___field_linked_icon_icon___drupal_internal__target_id = 'relationships___field_icons_belt_icons___field_linked_icon_icon___drupal_internal__target_id',
  relationships___field_icons_belt_icons___id = 'relationships___field_icons_belt_icons___id',
  relationships___field_icons_belt_icons___parent___id = 'relationships___field_icons_belt_icons___parent___id',
  relationships___field_icons_belt_icons___parent___children = 'relationships___field_icons_belt_icons___parent___children',
  relationships___field_icons_belt_icons___children = 'relationships___field_icons_belt_icons___children',
  relationships___field_icons_belt_icons___children___id = 'relationships___field_icons_belt_icons___children___id',
  relationships___field_icons_belt_icons___children___children = 'relationships___field_icons_belt_icons___children___children',
  relationships___field_icons_belt_icons___internal___content = 'relationships___field_icons_belt_icons___internal___content',
  relationships___field_icons_belt_icons___internal___contentDigest = 'relationships___field_icons_belt_icons___internal___contentDigest',
  relationships___field_icons_belt_icons___internal___description = 'relationships___field_icons_belt_icons___internal___description',
  relationships___field_icons_belt_icons___internal___fieldOwners = 'relationships___field_icons_belt_icons___internal___fieldOwners',
  relationships___field_icons_belt_icons___internal___ignoreType = 'relationships___field_icons_belt_icons___internal___ignoreType',
  relationships___field_icons_belt_icons___internal___mediaType = 'relationships___field_icons_belt_icons___internal___mediaType',
  relationships___field_icons_belt_icons___internal___owner = 'relationships___field_icons_belt_icons___internal___owner',
  relationships___field_icons_belt_icons___internal___type = 'relationships___field_icons_belt_icons___internal___type',
  relationships___site_setting_entity__menu = 'relationships___site_setting_entity__menu',
  relationships___site_setting_entity__menu___field_settings_text_1 = 'relationships___site_setting_entity__menu___field_settings_text_1',
  relationships___site_setting_entity__menu___field_settings_text_2 = 'relationships___site_setting_entity__menu___field_settings_text_2',
  relationships___site_setting_entity__menu___field_settings_text_3 = 'relationships___site_setting_entity__menu___field_settings_text_3',
  relationships___site_setting_entity__menu___field_settings_text_4 = 'relationships___site_setting_entity__menu___field_settings_text_4',
  relationships___site_setting_entity__menu___field_settings_text_5 = 'relationships___site_setting_entity__menu___field_settings_text_5',
  relationships___site_setting_entity__menu___field_settings_text_6 = 'relationships___site_setting_entity__menu___field_settings_text_6',
  relationships___site_setting_entity__menu___field_settings_text_7 = 'relationships___site_setting_entity__menu___field_settings_text_7',
  relationships___site_setting_entity__menu___field_settings_tresc___value = 'relationships___site_setting_entity__menu___field_settings_tresc___value',
  relationships___site_setting_entity__menu___langcode = 'relationships___site_setting_entity__menu___langcode',
  relationships___site_setting_entity__menu___field_optional_label = 'relationships___site_setting_entity__menu___field_optional_label',
  relationships___site_setting_entity__menu___field_optional_url = 'relationships___site_setting_entity__menu___field_optional_url',
  relationships___site_setting_entity__menu___drupal_id = 'relationships___site_setting_entity__menu___drupal_id',
  relationships___site_setting_entity__menu___drupal_internal__id = 'relationships___site_setting_entity__menu___drupal_internal__id',
  relationships___site_setting_entity__menu___name = 'relationships___site_setting_entity__menu___name',
  relationships___site_setting_entity__menu___fieldset = 'relationships___site_setting_entity__menu___fieldset',
  relationships___site_setting_entity__menu___status = 'relationships___site_setting_entity__menu___status',
  relationships___site_setting_entity__menu___created = 'relationships___site_setting_entity__menu___created',
  relationships___site_setting_entity__menu___changed = 'relationships___site_setting_entity__menu___changed',
  relationships___site_setting_entity__menu___default_langcode = 'relationships___site_setting_entity__menu___default_langcode',
  relationships___site_setting_entity__menu___content_translation_source = 'relationships___site_setting_entity__menu___content_translation_source',
  relationships___site_setting_entity__menu___content_translation_outdated = 'relationships___site_setting_entity__menu___content_translation_outdated',
  relationships___site_setting_entity__menu___content_translation_status = 'relationships___site_setting_entity__menu___content_translation_status',
  relationships___site_setting_entity__menu___content_translation_created = 'relationships___site_setting_entity__menu___content_translation_created',
  relationships___site_setting_entity__menu___content_translation_changed = 'relationships___site_setting_entity__menu___content_translation_changed',
  relationships___site_setting_entity__menu___uuid = 'relationships___site_setting_entity__menu___uuid',
  relationships___site_setting_entity__menu___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__menu___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__menu___field_menu_icons_belt___target_revision_id = 'relationships___site_setting_entity__menu___field_menu_icons_belt___target_revision_id',
  relationships___site_setting_entity__menu___field_menu_icons_belt___drupal_internal__target_id = 'relationships___site_setting_entity__menu___field_menu_icons_belt___drupal_internal__target_id',
  relationships___site_setting_entity__menu___id = 'relationships___site_setting_entity__menu___id',
  relationships___site_setting_entity__menu___parent___id = 'relationships___site_setting_entity__menu___parent___id',
  relationships___site_setting_entity__menu___parent___children = 'relationships___site_setting_entity__menu___parent___children',
  relationships___site_setting_entity__menu___children = 'relationships___site_setting_entity__menu___children',
  relationships___site_setting_entity__menu___children___id = 'relationships___site_setting_entity__menu___children___id',
  relationships___site_setting_entity__menu___children___children = 'relationships___site_setting_entity__menu___children___children',
  relationships___site_setting_entity__menu___internal___content = 'relationships___site_setting_entity__menu___internal___content',
  relationships___site_setting_entity__menu___internal___contentDigest = 'relationships___site_setting_entity__menu___internal___contentDigest',
  relationships___site_setting_entity__menu___internal___description = 'relationships___site_setting_entity__menu___internal___description',
  relationships___site_setting_entity__menu___internal___fieldOwners = 'relationships___site_setting_entity__menu___internal___fieldOwners',
  relationships___site_setting_entity__menu___internal___ignoreType = 'relationships___site_setting_entity__menu___internal___ignoreType',
  relationships___site_setting_entity__menu___internal___mediaType = 'relationships___site_setting_entity__menu___internal___mediaType',
  relationships___site_setting_entity__menu___internal___owner = 'relationships___site_setting_entity__menu___internal___owner',
  relationships___site_setting_entity__menu___internal___type = 'relationships___site_setting_entity__menu___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_icons_belt_icons = 'field_icons_belt_icons',
  field_icons_belt_icons___target_revision_id = 'field_icons_belt_icons___target_revision_id',
  field_icons_belt_icons___drupal_internal__target_id = 'field_icons_belt_icons___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Icons_BeltFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_icons_belt_enabled?: Maybe<BooleanQueryOperatorInput>;
  field_icons_belt_heading?: Maybe<StringQueryOperatorInput>;
  field_icons_belt_show_urls?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Icons_BeltRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_icons_belt_icons?: Maybe<Paragraph__Icons_BeltField_Icons_Belt_IconsFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Icons_BeltFilterListInput = {
  elemMatch?: Maybe<Paragraph__Icons_BeltFilterInput>;
};

export type Paragraph__Icons_BeltGroupConnection = {
  __typename?: 'paragraph__icons_beltGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Icons_BeltEdge>;
  nodes: Array<Paragraph__Icons_Belt>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Icons_BeltRelationships = {
  __typename?: 'paragraph__icons_beltRelationships';
  field_icons_belt_icons: Array<Maybe<Paragraph__Linked_Icons>>;
  site_setting_entity__menu?: Maybe<Array<Maybe<Site_Setting_Entity__Menu>>>;
};

export type Paragraph__Icons_BeltRelationshipsFilterInput = {
  field_icons_belt_icons?: Maybe<Paragraph__Linked_IconsFilterListInput>;
  site_setting_entity__menu?: Maybe<Site_Setting_Entity__MenuFilterListInput>;
};

export type Paragraph__Icons_BeltSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Icons_BeltFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Link_With_Text = Node & {
  __typename?: 'paragraph__link_with_text';
  field_link_with_text_text?: Maybe<Scalars['String']>;
  field_link_with_text_link?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['Date']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Link_With_TextRelationships>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Link_With_TextCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Link_With_TextParent_IdArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Link_With_TextContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Link_With_TextConnection = {
  __typename?: 'paragraph__link_with_textConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Link_With_TextEdge>;
  nodes: Array<Paragraph__Link_With_Text>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Link_With_TextGroupConnection>;
};

export type Paragraph__Link_With_TextConnectionDistinctArgs = {
  field: Paragraph__Link_With_TextFieldsEnum;
};

export type Paragraph__Link_With_TextConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Link_With_TextFieldsEnum;
};

export type Paragraph__Link_With_TextEdge = {
  __typename?: 'paragraph__link_with_textEdge';
  next?: Maybe<Paragraph__Link_With_Text>;
  node: Paragraph__Link_With_Text;
  previous?: Maybe<Paragraph__Link_With_Text>;
};

export enum Paragraph__Link_With_TextFieldsEnum {
  field_link_with_text_text = 'field_link_with_text_text',
  field_link_with_text_link = 'field_link_with_text_link',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  relationships___paragraph__heading_and_links = 'relationships___paragraph__heading_and_links',
  relationships___paragraph__heading_and_links___field_heading_and_links_heading = 'relationships___paragraph__heading_and_links___field_heading_and_links_heading',
  relationships___paragraph__heading_and_links___relationships___field_heading_and_links_links = 'relationships___paragraph__heading_and_links___relationships___field_heading_and_links_links',
  relationships___paragraph__heading_and_links___relationships___paragraph__footer_section = 'relationships___paragraph__heading_and_links___relationships___paragraph__footer_section',
  relationships___paragraph__heading_and_links___drupal_id = 'relationships___paragraph__heading_and_links___drupal_id',
  relationships___paragraph__heading_and_links___drupal_internal__id = 'relationships___paragraph__heading_and_links___drupal_internal__id',
  relationships___paragraph__heading_and_links___drupal_internal__revision_id = 'relationships___paragraph__heading_and_links___drupal_internal__revision_id',
  relationships___paragraph__heading_and_links___langcode = 'relationships___paragraph__heading_and_links___langcode',
  relationships___paragraph__heading_and_links___status = 'relationships___paragraph__heading_and_links___status',
  relationships___paragraph__heading_and_links___created = 'relationships___paragraph__heading_and_links___created',
  relationships___paragraph__heading_and_links___parent_id = 'relationships___paragraph__heading_and_links___parent_id',
  relationships___paragraph__heading_and_links___parent_type = 'relationships___paragraph__heading_and_links___parent_type',
  relationships___paragraph__heading_and_links___parent_field_name = 'relationships___paragraph__heading_and_links___parent_field_name',
  relationships___paragraph__heading_and_links___default_langcode = 'relationships___paragraph__heading_and_links___default_langcode',
  relationships___paragraph__heading_and_links___revision_translation_affected = 'relationships___paragraph__heading_and_links___revision_translation_affected',
  relationships___paragraph__heading_and_links___content_translation_source = 'relationships___paragraph__heading_and_links___content_translation_source',
  relationships___paragraph__heading_and_links___content_translation_outdated = 'relationships___paragraph__heading_and_links___content_translation_outdated',
  relationships___paragraph__heading_and_links___content_translation_changed = 'relationships___paragraph__heading_and_links___content_translation_changed',
  relationships___paragraph__heading_and_links___uuid = 'relationships___paragraph__heading_and_links___uuid',
  relationships___paragraph__heading_and_links___field_heading_and_links_links = 'relationships___paragraph__heading_and_links___field_heading_and_links_links',
  relationships___paragraph__heading_and_links___field_heading_and_links_links___target_revision_id = 'relationships___paragraph__heading_and_links___field_heading_and_links_links___target_revision_id',
  relationships___paragraph__heading_and_links___field_heading_and_links_links___drupal_internal__target_id = 'relationships___paragraph__heading_and_links___field_heading_and_links_links___drupal_internal__target_id',
  relationships___paragraph__heading_and_links___id = 'relationships___paragraph__heading_and_links___id',
  relationships___paragraph__heading_and_links___parent___id = 'relationships___paragraph__heading_and_links___parent___id',
  relationships___paragraph__heading_and_links___parent___children = 'relationships___paragraph__heading_and_links___parent___children',
  relationships___paragraph__heading_and_links___children = 'relationships___paragraph__heading_and_links___children',
  relationships___paragraph__heading_and_links___children___id = 'relationships___paragraph__heading_and_links___children___id',
  relationships___paragraph__heading_and_links___children___children = 'relationships___paragraph__heading_and_links___children___children',
  relationships___paragraph__heading_and_links___internal___content = 'relationships___paragraph__heading_and_links___internal___content',
  relationships___paragraph__heading_and_links___internal___contentDigest = 'relationships___paragraph__heading_and_links___internal___contentDigest',
  relationships___paragraph__heading_and_links___internal___description = 'relationships___paragraph__heading_and_links___internal___description',
  relationships___paragraph__heading_and_links___internal___fieldOwners = 'relationships___paragraph__heading_and_links___internal___fieldOwners',
  relationships___paragraph__heading_and_links___internal___ignoreType = 'relationships___paragraph__heading_and_links___internal___ignoreType',
  relationships___paragraph__heading_and_links___internal___mediaType = 'relationships___paragraph__heading_and_links___internal___mediaType',
  relationships___paragraph__heading_and_links___internal___owner = 'relationships___paragraph__heading_and_links___internal___owner',
  relationships___paragraph__heading_and_links___internal___type = 'relationships___paragraph__heading_and_links___internal___type',
  relationships___paragraph__heading_and_text = 'relationships___paragraph__heading_and_text',
  relationships___paragraph__heading_and_text___field_heading_and_text_heading = 'relationships___paragraph__heading_and_text___field_heading_and_text_heading',
  relationships___paragraph__heading_and_text___field_heading_and_text_text___value = 'relationships___paragraph__heading_and_text___field_heading_and_text_text___value',
  relationships___paragraph__heading_and_text___relationships___field_heading_and_text_links = 'relationships___paragraph__heading_and_text___relationships___field_heading_and_text_links',
  relationships___paragraph__heading_and_text___relationships___paragraph__footer_section = 'relationships___paragraph__heading_and_text___relationships___paragraph__footer_section',
  relationships___paragraph__heading_and_text___drupal_id = 'relationships___paragraph__heading_and_text___drupal_id',
  relationships___paragraph__heading_and_text___drupal_internal__id = 'relationships___paragraph__heading_and_text___drupal_internal__id',
  relationships___paragraph__heading_and_text___drupal_internal__revision_id = 'relationships___paragraph__heading_and_text___drupal_internal__revision_id',
  relationships___paragraph__heading_and_text___langcode = 'relationships___paragraph__heading_and_text___langcode',
  relationships___paragraph__heading_and_text___status = 'relationships___paragraph__heading_and_text___status',
  relationships___paragraph__heading_and_text___created = 'relationships___paragraph__heading_and_text___created',
  relationships___paragraph__heading_and_text___parent_id = 'relationships___paragraph__heading_and_text___parent_id',
  relationships___paragraph__heading_and_text___parent_type = 'relationships___paragraph__heading_and_text___parent_type',
  relationships___paragraph__heading_and_text___parent_field_name = 'relationships___paragraph__heading_and_text___parent_field_name',
  relationships___paragraph__heading_and_text___default_langcode = 'relationships___paragraph__heading_and_text___default_langcode',
  relationships___paragraph__heading_and_text___revision_translation_affected = 'relationships___paragraph__heading_and_text___revision_translation_affected',
  relationships___paragraph__heading_and_text___content_translation_source = 'relationships___paragraph__heading_and_text___content_translation_source',
  relationships___paragraph__heading_and_text___content_translation_outdated = 'relationships___paragraph__heading_and_text___content_translation_outdated',
  relationships___paragraph__heading_and_text___content_translation_changed = 'relationships___paragraph__heading_and_text___content_translation_changed',
  relationships___paragraph__heading_and_text___uuid = 'relationships___paragraph__heading_and_text___uuid',
  relationships___paragraph__heading_and_text___field_heading_and_text_links = 'relationships___paragraph__heading_and_text___field_heading_and_text_links',
  relationships___paragraph__heading_and_text___field_heading_and_text_links___target_revision_id = 'relationships___paragraph__heading_and_text___field_heading_and_text_links___target_revision_id',
  relationships___paragraph__heading_and_text___field_heading_and_text_links___drupal_internal__target_id = 'relationships___paragraph__heading_and_text___field_heading_and_text_links___drupal_internal__target_id',
  relationships___paragraph__heading_and_text___id = 'relationships___paragraph__heading_and_text___id',
  relationships___paragraph__heading_and_text___parent___id = 'relationships___paragraph__heading_and_text___parent___id',
  relationships___paragraph__heading_and_text___parent___children = 'relationships___paragraph__heading_and_text___parent___children',
  relationships___paragraph__heading_and_text___children = 'relationships___paragraph__heading_and_text___children',
  relationships___paragraph__heading_and_text___children___id = 'relationships___paragraph__heading_and_text___children___id',
  relationships___paragraph__heading_and_text___children___children = 'relationships___paragraph__heading_and_text___children___children',
  relationships___paragraph__heading_and_text___internal___content = 'relationships___paragraph__heading_and_text___internal___content',
  relationships___paragraph__heading_and_text___internal___contentDigest = 'relationships___paragraph__heading_and_text___internal___contentDigest',
  relationships___paragraph__heading_and_text___internal___description = 'relationships___paragraph__heading_and_text___internal___description',
  relationships___paragraph__heading_and_text___internal___fieldOwners = 'relationships___paragraph__heading_and_text___internal___fieldOwners',
  relationships___paragraph__heading_and_text___internal___ignoreType = 'relationships___paragraph__heading_and_text___internal___ignoreType',
  relationships___paragraph__heading_and_text___internal___mediaType = 'relationships___paragraph__heading_and_text___internal___mediaType',
  relationships___paragraph__heading_and_text___internal___owner = 'relationships___paragraph__heading_and_text___internal___owner',
  relationships___paragraph__heading_and_text___internal___type = 'relationships___paragraph__heading_and_text___internal___type',
  relationships___paragraph__make_an_appointment = 'relationships___paragraph__make_an_appointment',
  relationships___paragraph__make_an_appointment___id = 'relationships___paragraph__make_an_appointment___id',
  relationships___paragraph__make_an_appointment___internal___content = 'relationships___paragraph__make_an_appointment___internal___content',
  relationships___paragraph__make_an_appointment___internal___contentDigest = 'relationships___paragraph__make_an_appointment___internal___contentDigest',
  relationships___paragraph__make_an_appointment___internal___description = 'relationships___paragraph__make_an_appointment___internal___description',
  relationships___paragraph__make_an_appointment___internal___fieldOwners = 'relationships___paragraph__make_an_appointment___internal___fieldOwners',
  relationships___paragraph__make_an_appointment___internal___ignoreType = 'relationships___paragraph__make_an_appointment___internal___ignoreType',
  relationships___paragraph__make_an_appointment___internal___mediaType = 'relationships___paragraph__make_an_appointment___internal___mediaType',
  relationships___paragraph__make_an_appointment___internal___owner = 'relationships___paragraph__make_an_appointment___internal___owner',
  relationships___paragraph__make_an_appointment___internal___type = 'relationships___paragraph__make_an_appointment___internal___type',
  relationships___paragraph__make_an_appointment___drupal_id = 'relationships___paragraph__make_an_appointment___drupal_id',
  relationships___paragraph__make_an_appointment___field_appointment_header = 'relationships___paragraph__make_an_appointment___field_appointment_header',
  relationships___paragraph__make_an_appointment___field_appointment_text___value = 'relationships___paragraph__make_an_appointment___field_appointment_text___value',
  relationships___paragraph__make_an_appointment___relationships___site_setting_entity__franchise = 'relationships___paragraph__make_an_appointment___relationships___site_setting_entity__franchise',
  relationships___paragraph__make_an_appointment___drupal_internal__id = 'relationships___paragraph__make_an_appointment___drupal_internal__id',
  relationships___paragraph__make_an_appointment___drupal_internal__revision_id = 'relationships___paragraph__make_an_appointment___drupal_internal__revision_id',
  relationships___paragraph__make_an_appointment___langcode = 'relationships___paragraph__make_an_appointment___langcode',
  relationships___paragraph__make_an_appointment___status = 'relationships___paragraph__make_an_appointment___status',
  relationships___paragraph__make_an_appointment___created = 'relationships___paragraph__make_an_appointment___created',
  relationships___paragraph__make_an_appointment___parent_id = 'relationships___paragraph__make_an_appointment___parent_id',
  relationships___paragraph__make_an_appointment___parent_type = 'relationships___paragraph__make_an_appointment___parent_type',
  relationships___paragraph__make_an_appointment___parent_field_name = 'relationships___paragraph__make_an_appointment___parent_field_name',
  relationships___paragraph__make_an_appointment___default_langcode = 'relationships___paragraph__make_an_appointment___default_langcode',
  relationships___paragraph__make_an_appointment___revision_translation_affected = 'relationships___paragraph__make_an_appointment___revision_translation_affected',
  relationships___paragraph__make_an_appointment___content_translation_source = 'relationships___paragraph__make_an_appointment___content_translation_source',
  relationships___paragraph__make_an_appointment___content_translation_outdated = 'relationships___paragraph__make_an_appointment___content_translation_outdated',
  relationships___paragraph__make_an_appointment___content_translation_changed = 'relationships___paragraph__make_an_appointment___content_translation_changed',
  relationships___paragraph__make_an_appointment___uuid = 'relationships___paragraph__make_an_appointment___uuid',
  relationships___paragraph__make_an_appointment___field_appointment_button___target_revision_id = 'relationships___paragraph__make_an_appointment___field_appointment_button___target_revision_id',
  relationships___paragraph__make_an_appointment___field_appointment_button___drupal_internal__target_id = 'relationships___paragraph__make_an_appointment___field_appointment_button___drupal_internal__target_id',
  relationships___paragraph__make_an_appointment___field_appointment_image___alt = 'relationships___paragraph__make_an_appointment___field_appointment_image___alt',
  relationships___paragraph__make_an_appointment___field_appointment_image___title = 'relationships___paragraph__make_an_appointment___field_appointment_image___title',
  relationships___paragraph__make_an_appointment___field_appointment_image___width = 'relationships___paragraph__make_an_appointment___field_appointment_image___width',
  relationships___paragraph__make_an_appointment___field_appointment_image___height = 'relationships___paragraph__make_an_appointment___field_appointment_image___height',
  relationships___paragraph__make_an_appointment___field_appointment_image___drupal_internal__target_id = 'relationships___paragraph__make_an_appointment___field_appointment_image___drupal_internal__target_id',
  relationships___paragraph__make_an_appointment___parent___id = 'relationships___paragraph__make_an_appointment___parent___id',
  relationships___paragraph__make_an_appointment___parent___children = 'relationships___paragraph__make_an_appointment___parent___children',
  relationships___paragraph__make_an_appointment___children = 'relationships___paragraph__make_an_appointment___children',
  relationships___paragraph__make_an_appointment___children___id = 'relationships___paragraph__make_an_appointment___children___id',
  relationships___paragraph__make_an_appointment___children___children = 'relationships___paragraph__make_an_appointment___children___children',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Link_With_TextFilterInput = {
  field_link_with_text_text?: Maybe<StringQueryOperatorInput>;
  field_link_with_text_link?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Link_With_TextRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Link_With_TextFilterListInput = {
  elemMatch?: Maybe<Paragraph__Link_With_TextFilterInput>;
};

export type Paragraph__Link_With_TextGroupConnection = {
  __typename?: 'paragraph__link_with_textGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Link_With_TextEdge>;
  nodes: Array<Paragraph__Link_With_Text>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Link_With_TextRelationships = {
  __typename?: 'paragraph__link_with_textRelationships';
  paragraph__heading_and_links?: Maybe<Array<Maybe<Paragraph__Heading_And_Links>>>;
  paragraph__heading_and_text?: Maybe<Array<Maybe<Paragraph__Heading_And_Text>>>;
  paragraph__make_an_appointment?: Maybe<Array<Maybe<Paragraph__Make_An_Appointment>>>;
};

export type Paragraph__Link_With_TextRelationshipsFilterInput = {
  paragraph__heading_and_links?: Maybe<Paragraph__Heading_And_LinksFilterListInput>;
  paragraph__heading_and_text?: Maybe<Paragraph__Heading_And_TextFilterListInput>;
  paragraph__make_an_appointment?: Maybe<Paragraph__Make_An_AppointmentFilterListInput>;
};

export type Paragraph__Link_With_TextSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Link_With_TextFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Linked_Icons = Node & {
  __typename?: 'paragraph__linked_icons';
  field_linked_icon_text?: Maybe<Scalars['String']>;
  field_linked_icon_url?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Linked_IconsRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['Date']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_linked_icon_icon?: Maybe<Paragraph__Linked_IconsField_Linked_Icon_Icon>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Linked_IconsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Linked_IconsParent_IdArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Linked_IconsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Linked_IconsConnection = {
  __typename?: 'paragraph__linked_iconsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Linked_IconsEdge>;
  nodes: Array<Paragraph__Linked_Icons>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Linked_IconsGroupConnection>;
};

export type Paragraph__Linked_IconsConnectionDistinctArgs = {
  field: Paragraph__Linked_IconsFieldsEnum;
};

export type Paragraph__Linked_IconsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Linked_IconsFieldsEnum;
};

export type Paragraph__Linked_IconsEdge = {
  __typename?: 'paragraph__linked_iconsEdge';
  next?: Maybe<Paragraph__Linked_Icons>;
  node: Paragraph__Linked_Icons;
  previous?: Maybe<Paragraph__Linked_Icons>;
};

export type Paragraph__Linked_IconsField_Linked_Icon_Icon = {
  __typename?: 'paragraph__linked_iconsField_linked_icon_icon';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Linked_IconsField_Linked_Icon_IconFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Paragraph__Linked_IconsFieldsEnum {
  field_linked_icon_text = 'field_linked_icon_text',
  field_linked_icon_url = 'field_linked_icon_url',
  relationships___field_linked_icon_icon___localFile___birthtime = 'relationships___field_linked_icon_icon___localFile___birthtime',
  relationships___field_linked_icon_icon___localFile___birthtimeMs = 'relationships___field_linked_icon_icon___localFile___birthtimeMs',
  relationships___field_linked_icon_icon___localFile___publicURL = 'relationships___field_linked_icon_icon___localFile___publicURL',
  relationships___field_linked_icon_icon___localFile___sourceInstanceName = 'relationships___field_linked_icon_icon___localFile___sourceInstanceName',
  relationships___field_linked_icon_icon___localFile___absolutePath = 'relationships___field_linked_icon_icon___localFile___absolutePath',
  relationships___field_linked_icon_icon___localFile___relativePath = 'relationships___field_linked_icon_icon___localFile___relativePath',
  relationships___field_linked_icon_icon___localFile___extension = 'relationships___field_linked_icon_icon___localFile___extension',
  relationships___field_linked_icon_icon___localFile___size = 'relationships___field_linked_icon_icon___localFile___size',
  relationships___field_linked_icon_icon___localFile___prettySize = 'relationships___field_linked_icon_icon___localFile___prettySize',
  relationships___field_linked_icon_icon___localFile___modifiedTime = 'relationships___field_linked_icon_icon___localFile___modifiedTime',
  relationships___field_linked_icon_icon___localFile___accessTime = 'relationships___field_linked_icon_icon___localFile___accessTime',
  relationships___field_linked_icon_icon___localFile___changeTime = 'relationships___field_linked_icon_icon___localFile___changeTime',
  relationships___field_linked_icon_icon___localFile___birthTime = 'relationships___field_linked_icon_icon___localFile___birthTime',
  relationships___field_linked_icon_icon___localFile___root = 'relationships___field_linked_icon_icon___localFile___root',
  relationships___field_linked_icon_icon___localFile___dir = 'relationships___field_linked_icon_icon___localFile___dir',
  relationships___field_linked_icon_icon___localFile___base = 'relationships___field_linked_icon_icon___localFile___base',
  relationships___field_linked_icon_icon___localFile___ext = 'relationships___field_linked_icon_icon___localFile___ext',
  relationships___field_linked_icon_icon___localFile___name = 'relationships___field_linked_icon_icon___localFile___name',
  relationships___field_linked_icon_icon___localFile___relativeDirectory = 'relationships___field_linked_icon_icon___localFile___relativeDirectory',
  relationships___field_linked_icon_icon___localFile___dev = 'relationships___field_linked_icon_icon___localFile___dev',
  relationships___field_linked_icon_icon___localFile___mode = 'relationships___field_linked_icon_icon___localFile___mode',
  relationships___field_linked_icon_icon___localFile___nlink = 'relationships___field_linked_icon_icon___localFile___nlink',
  relationships___field_linked_icon_icon___localFile___uid = 'relationships___field_linked_icon_icon___localFile___uid',
  relationships___field_linked_icon_icon___localFile___gid = 'relationships___field_linked_icon_icon___localFile___gid',
  relationships___field_linked_icon_icon___localFile___rdev = 'relationships___field_linked_icon_icon___localFile___rdev',
  relationships___field_linked_icon_icon___localFile___blksize = 'relationships___field_linked_icon_icon___localFile___blksize',
  relationships___field_linked_icon_icon___localFile___ino = 'relationships___field_linked_icon_icon___localFile___ino',
  relationships___field_linked_icon_icon___localFile___blocks = 'relationships___field_linked_icon_icon___localFile___blocks',
  relationships___field_linked_icon_icon___localFile___atimeMs = 'relationships___field_linked_icon_icon___localFile___atimeMs',
  relationships___field_linked_icon_icon___localFile___mtimeMs = 'relationships___field_linked_icon_icon___localFile___mtimeMs',
  relationships___field_linked_icon_icon___localFile___ctimeMs = 'relationships___field_linked_icon_icon___localFile___ctimeMs',
  relationships___field_linked_icon_icon___localFile___atime = 'relationships___field_linked_icon_icon___localFile___atime',
  relationships___field_linked_icon_icon___localFile___mtime = 'relationships___field_linked_icon_icon___localFile___mtime',
  relationships___field_linked_icon_icon___localFile___ctime = 'relationships___field_linked_icon_icon___localFile___ctime',
  relationships___field_linked_icon_icon___localFile___url = 'relationships___field_linked_icon_icon___localFile___url',
  relationships___field_linked_icon_icon___localFile___id = 'relationships___field_linked_icon_icon___localFile___id',
  relationships___field_linked_icon_icon___localFile___children = 'relationships___field_linked_icon_icon___localFile___children',
  relationships___field_linked_icon_icon___uri___url = 'relationships___field_linked_icon_icon___uri___url',
  relationships___field_linked_icon_icon___drupal_id = 'relationships___field_linked_icon_icon___drupal_id',
  relationships___field_linked_icon_icon___drupal_internal__fid = 'relationships___field_linked_icon_icon___drupal_internal__fid',
  relationships___field_linked_icon_icon___langcode = 'relationships___field_linked_icon_icon___langcode',
  relationships___field_linked_icon_icon___filename = 'relationships___field_linked_icon_icon___filename',
  relationships___field_linked_icon_icon___filemime = 'relationships___field_linked_icon_icon___filemime',
  relationships___field_linked_icon_icon___filesize = 'relationships___field_linked_icon_icon___filesize',
  relationships___field_linked_icon_icon___status = 'relationships___field_linked_icon_icon___status',
  relationships___field_linked_icon_icon___created = 'relationships___field_linked_icon_icon___created',
  relationships___field_linked_icon_icon___changed = 'relationships___field_linked_icon_icon___changed',
  relationships___field_linked_icon_icon___uuid = 'relationships___field_linked_icon_icon___uuid',
  relationships___field_linked_icon_icon___relationships___node__blog_post = 'relationships___field_linked_icon_icon___relationships___node__blog_post',
  relationships___field_linked_icon_icon___relationships___node__devices = 'relationships___field_linked_icon_icon___relationships___node__devices',
  relationships___field_linked_icon_icon___relationships___node__accessories = 'relationships___field_linked_icon_icon___relationships___node__accessories',
  relationships___field_linked_icon_icon___relationships___paragraph__news_image_title_text = 'relationships___field_linked_icon_icon___relationships___paragraph__news_image_title_text',
  relationships___field_linked_icon_icon___relationships___node__cases = 'relationships___field_linked_icon_icon___relationships___node__cases',
  relationships___field_linked_icon_icon___relationships___paragraph__branches_what_is_ipos = 'relationships___field_linked_icon_icon___relationships___paragraph__branches_what_is_ipos',
  relationships___field_linked_icon_icon___relationships___paragraph__branches_features = 'relationships___field_linked_icon_icon___relationships___paragraph__branches_features',
  relationships___field_linked_icon_icon___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_linked_icon_icon___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_linked_icon_icon___relationships___paragraph__branches_testimonials = 'relationships___field_linked_icon_icon___relationships___paragraph__branches_testimonials',
  relationships___field_linked_icon_icon___relationships___paragraph__branches_discounts = 'relationships___field_linked_icon_icon___relationships___paragraph__branches_discounts',
  relationships___field_linked_icon_icon___relationships___node__news = 'relationships___field_linked_icon_icon___relationships___node__news',
  relationships___field_linked_icon_icon___relationships___node__branches = 'relationships___field_linked_icon_icon___relationships___node__branches',
  relationships___field_linked_icon_icon___relationships___paragraph__branches_hero = 'relationships___field_linked_icon_icon___relationships___paragraph__branches_hero',
  relationships___field_linked_icon_icon___relationships___paragraph__bullets = 'relationships___field_linked_icon_icon___relationships___paragraph__bullets',
  relationships___field_linked_icon_icon___relationships___taxonomy_term__pricelist_group = 'relationships___field_linked_icon_icon___relationships___taxonomy_term__pricelist_group',
  relationships___field_linked_icon_icon___relationships___paragraph__branches_device = 'relationships___field_linked_icon_icon___relationships___paragraph__branches_device',
  relationships___field_linked_icon_icon___relationships___paragraph__branches_recommendation = 'relationships___field_linked_icon_icon___relationships___paragraph__branches_recommendation',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__accessories = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__accessories',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__branches = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__branches',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__about_us = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__about_us',
  relationships___field_linked_icon_icon___relationships___paragraph__logos = 'relationships___field_linked_icon_icon___relationships___paragraph__logos',
  relationships___field_linked_icon_icon___relationships___paragraph__how_it_works = 'relationships___field_linked_icon_icon___relationships___paragraph__how_it_works',
  relationships___field_linked_icon_icon___relationships___paragraph__ico_and_text = 'relationships___field_linked_icon_icon___relationships___paragraph__ico_and_text',
  relationships___field_linked_icon_icon___relationships___paragraph__hero_1 = 'relationships___field_linked_icon_icon___relationships___paragraph__hero_1',
  relationships___field_linked_icon_icon___relationships___paragraph__comprehensive_solution_item = 'relationships___field_linked_icon_icon___relationships___paragraph__comprehensive_solution_item',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__product = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__product',
  relationships___field_linked_icon_icon___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_linked_icon_icon___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_linked_icon_icon___relationships___paragraph__porownanie_terminali = 'relationships___field_linked_icon_icon___relationships___paragraph__porownanie_terminali',
  relationships___field_linked_icon_icon___relationships___paragraph__zdjecie_opis_link = 'relationships___field_linked_icon_icon___relationships___paragraph__zdjecie_opis_link',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__main_page = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__main_page',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_linked_icon_icon___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_linked_icon_icon___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__intercars = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__intercars',
  relationships___field_linked_icon_icon___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_linked_icon_icon___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_linked_icon_icon___relationships___node__agreement = 'relationships___field_linked_icon_icon___relationships___node__agreement',
  relationships___field_linked_icon_icon___relationships___node__fiscalization = 'relationships___field_linked_icon_icon___relationships___node__fiscalization',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__configurator = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__configurator',
  relationships___field_linked_icon_icon___relationships___paragraph__social_link = 'relationships___field_linked_icon_icon___relationships___paragraph__social_link',
  relationships___field_linked_icon_icon___relationships___paragraph__make_an_appointment = 'relationships___field_linked_icon_icon___relationships___paragraph__make_an_appointment',
  relationships___field_linked_icon_icon___relationships___paragraph__linked_icons = 'relationships___field_linked_icon_icon___relationships___paragraph__linked_icons',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__financing = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__financing',
  relationships___field_linked_icon_icon___relationships___site_setting_entity__franchise = 'relationships___field_linked_icon_icon___relationships___site_setting_entity__franchise',
  relationships___field_linked_icon_icon___id = 'relationships___field_linked_icon_icon___id',
  relationships___field_linked_icon_icon___parent___id = 'relationships___field_linked_icon_icon___parent___id',
  relationships___field_linked_icon_icon___parent___children = 'relationships___field_linked_icon_icon___parent___children',
  relationships___field_linked_icon_icon___children = 'relationships___field_linked_icon_icon___children',
  relationships___field_linked_icon_icon___children___id = 'relationships___field_linked_icon_icon___children___id',
  relationships___field_linked_icon_icon___children___children = 'relationships___field_linked_icon_icon___children___children',
  relationships___field_linked_icon_icon___internal___content = 'relationships___field_linked_icon_icon___internal___content',
  relationships___field_linked_icon_icon___internal___contentDigest = 'relationships___field_linked_icon_icon___internal___contentDigest',
  relationships___field_linked_icon_icon___internal___description = 'relationships___field_linked_icon_icon___internal___description',
  relationships___field_linked_icon_icon___internal___fieldOwners = 'relationships___field_linked_icon_icon___internal___fieldOwners',
  relationships___field_linked_icon_icon___internal___ignoreType = 'relationships___field_linked_icon_icon___internal___ignoreType',
  relationships___field_linked_icon_icon___internal___mediaType = 'relationships___field_linked_icon_icon___internal___mediaType',
  relationships___field_linked_icon_icon___internal___owner = 'relationships___field_linked_icon_icon___internal___owner',
  relationships___field_linked_icon_icon___internal___type = 'relationships___field_linked_icon_icon___internal___type',
  relationships___paragraph__icons_belt = 'relationships___paragraph__icons_belt',
  relationships___paragraph__icons_belt___id = 'relationships___paragraph__icons_belt___id',
  relationships___paragraph__icons_belt___internal___content = 'relationships___paragraph__icons_belt___internal___content',
  relationships___paragraph__icons_belt___internal___contentDigest = 'relationships___paragraph__icons_belt___internal___contentDigest',
  relationships___paragraph__icons_belt___internal___description = 'relationships___paragraph__icons_belt___internal___description',
  relationships___paragraph__icons_belt___internal___fieldOwners = 'relationships___paragraph__icons_belt___internal___fieldOwners',
  relationships___paragraph__icons_belt___internal___ignoreType = 'relationships___paragraph__icons_belt___internal___ignoreType',
  relationships___paragraph__icons_belt___internal___mediaType = 'relationships___paragraph__icons_belt___internal___mediaType',
  relationships___paragraph__icons_belt___internal___owner = 'relationships___paragraph__icons_belt___internal___owner',
  relationships___paragraph__icons_belt___internal___type = 'relationships___paragraph__icons_belt___internal___type',
  relationships___paragraph__icons_belt___drupal_id = 'relationships___paragraph__icons_belt___drupal_id',
  relationships___paragraph__icons_belt___field_icons_belt_enabled = 'relationships___paragraph__icons_belt___field_icons_belt_enabled',
  relationships___paragraph__icons_belt___field_icons_belt_heading = 'relationships___paragraph__icons_belt___field_icons_belt_heading',
  relationships___paragraph__icons_belt___field_icons_belt_show_urls = 'relationships___paragraph__icons_belt___field_icons_belt_show_urls',
  relationships___paragraph__icons_belt___relationships___field_icons_belt_icons = 'relationships___paragraph__icons_belt___relationships___field_icons_belt_icons',
  relationships___paragraph__icons_belt___relationships___site_setting_entity__menu = 'relationships___paragraph__icons_belt___relationships___site_setting_entity__menu',
  relationships___paragraph__icons_belt___drupal_internal__id = 'relationships___paragraph__icons_belt___drupal_internal__id',
  relationships___paragraph__icons_belt___drupal_internal__revision_id = 'relationships___paragraph__icons_belt___drupal_internal__revision_id',
  relationships___paragraph__icons_belt___langcode = 'relationships___paragraph__icons_belt___langcode',
  relationships___paragraph__icons_belt___status = 'relationships___paragraph__icons_belt___status',
  relationships___paragraph__icons_belt___created = 'relationships___paragraph__icons_belt___created',
  relationships___paragraph__icons_belt___parent_id = 'relationships___paragraph__icons_belt___parent_id',
  relationships___paragraph__icons_belt___parent_type = 'relationships___paragraph__icons_belt___parent_type',
  relationships___paragraph__icons_belt___parent_field_name = 'relationships___paragraph__icons_belt___parent_field_name',
  relationships___paragraph__icons_belt___default_langcode = 'relationships___paragraph__icons_belt___default_langcode',
  relationships___paragraph__icons_belt___revision_translation_affected = 'relationships___paragraph__icons_belt___revision_translation_affected',
  relationships___paragraph__icons_belt___content_translation_source = 'relationships___paragraph__icons_belt___content_translation_source',
  relationships___paragraph__icons_belt___content_translation_outdated = 'relationships___paragraph__icons_belt___content_translation_outdated',
  relationships___paragraph__icons_belt___content_translation_changed = 'relationships___paragraph__icons_belt___content_translation_changed',
  relationships___paragraph__icons_belt___uuid = 'relationships___paragraph__icons_belt___uuid',
  relationships___paragraph__icons_belt___field_icons_belt_icons = 'relationships___paragraph__icons_belt___field_icons_belt_icons',
  relationships___paragraph__icons_belt___field_icons_belt_icons___target_revision_id = 'relationships___paragraph__icons_belt___field_icons_belt_icons___target_revision_id',
  relationships___paragraph__icons_belt___field_icons_belt_icons___drupal_internal__target_id = 'relationships___paragraph__icons_belt___field_icons_belt_icons___drupal_internal__target_id',
  relationships___paragraph__icons_belt___parent___id = 'relationships___paragraph__icons_belt___parent___id',
  relationships___paragraph__icons_belt___parent___children = 'relationships___paragraph__icons_belt___parent___children',
  relationships___paragraph__icons_belt___children = 'relationships___paragraph__icons_belt___children',
  relationships___paragraph__icons_belt___children___id = 'relationships___paragraph__icons_belt___children___id',
  relationships___paragraph__icons_belt___children___children = 'relationships___paragraph__icons_belt___children___children',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_linked_icon_icon___alt = 'field_linked_icon_icon___alt',
  field_linked_icon_icon___title = 'field_linked_icon_icon___title',
  field_linked_icon_icon___width = 'field_linked_icon_icon___width',
  field_linked_icon_icon___height = 'field_linked_icon_icon___height',
  field_linked_icon_icon___drupal_internal__target_id = 'field_linked_icon_icon___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Linked_IconsFilterInput = {
  field_linked_icon_text?: Maybe<StringQueryOperatorInput>;
  field_linked_icon_url?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Linked_IconsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_linked_icon_icon?: Maybe<Paragraph__Linked_IconsField_Linked_Icon_IconFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Linked_IconsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Linked_IconsFilterInput>;
};

export type Paragraph__Linked_IconsGroupConnection = {
  __typename?: 'paragraph__linked_iconsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Linked_IconsEdge>;
  nodes: Array<Paragraph__Linked_Icons>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Linked_IconsRelationships = {
  __typename?: 'paragraph__linked_iconsRelationships';
  field_linked_icon_icon?: Maybe<File__File>;
  paragraph__icons_belt?: Maybe<Array<Maybe<Paragraph__Icons_Belt>>>;
};

export type Paragraph__Linked_IconsRelationshipsFilterInput = {
  field_linked_icon_icon?: Maybe<File__FileFilterInput>;
  paragraph__icons_belt?: Maybe<Paragraph__Icons_BeltFilterListInput>;
};

export type Paragraph__Linked_IconsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Linked_IconsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Logos = Node & {
  __typename?: 'paragraph__logos';
  field_logos_logos?: Maybe<Array<Maybe<Paragraph__LogosField_Logos_Logos>>>;
  field_logos_text?: Maybe<Field_Logos_Text>;
  relationships?: Maybe<Paragraph__LogosRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__LogosConnection = {
  __typename?: 'paragraph__logosConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__LogosEdge>;
  nodes: Array<Paragraph__Logos>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__LogosGroupConnection>;
};

export type Paragraph__LogosConnectionDistinctArgs = {
  field: Paragraph__LogosFieldsEnum;
};

export type Paragraph__LogosConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__LogosFieldsEnum;
};

export type Paragraph__LogosEdge = {
  __typename?: 'paragraph__logosEdge';
  next?: Maybe<Paragraph__Logos>;
  node: Paragraph__Logos;
  previous?: Maybe<Paragraph__Logos>;
};

export type Paragraph__LogosField_Logos_Logos = {
  __typename?: 'paragraph__logosField_logos_logos';
  alt?: Maybe<Scalars['String']>;
};

export type Paragraph__LogosField_Logos_LogosFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
};

export type Paragraph__LogosField_Logos_LogosFilterListInput = {
  elemMatch?: Maybe<Paragraph__LogosField_Logos_LogosFilterInput>;
};

export enum Paragraph__LogosFieldsEnum {
  field_logos_logos = 'field_logos_logos',
  field_logos_logos___alt = 'field_logos_logos___alt',
  field_logos_text___value = 'field_logos_text___value',
  relationships___field_logos_logos = 'relationships___field_logos_logos',
  relationships___field_logos_logos___localFile___birthtime = 'relationships___field_logos_logos___localFile___birthtime',
  relationships___field_logos_logos___localFile___birthtimeMs = 'relationships___field_logos_logos___localFile___birthtimeMs',
  relationships___field_logos_logos___localFile___publicURL = 'relationships___field_logos_logos___localFile___publicURL',
  relationships___field_logos_logos___localFile___sourceInstanceName = 'relationships___field_logos_logos___localFile___sourceInstanceName',
  relationships___field_logos_logos___localFile___absolutePath = 'relationships___field_logos_logos___localFile___absolutePath',
  relationships___field_logos_logos___localFile___relativePath = 'relationships___field_logos_logos___localFile___relativePath',
  relationships___field_logos_logos___localFile___extension = 'relationships___field_logos_logos___localFile___extension',
  relationships___field_logos_logos___localFile___size = 'relationships___field_logos_logos___localFile___size',
  relationships___field_logos_logos___localFile___prettySize = 'relationships___field_logos_logos___localFile___prettySize',
  relationships___field_logos_logos___localFile___modifiedTime = 'relationships___field_logos_logos___localFile___modifiedTime',
  relationships___field_logos_logos___localFile___accessTime = 'relationships___field_logos_logos___localFile___accessTime',
  relationships___field_logos_logos___localFile___changeTime = 'relationships___field_logos_logos___localFile___changeTime',
  relationships___field_logos_logos___localFile___birthTime = 'relationships___field_logos_logos___localFile___birthTime',
  relationships___field_logos_logos___localFile___root = 'relationships___field_logos_logos___localFile___root',
  relationships___field_logos_logos___localFile___dir = 'relationships___field_logos_logos___localFile___dir',
  relationships___field_logos_logos___localFile___base = 'relationships___field_logos_logos___localFile___base',
  relationships___field_logos_logos___localFile___ext = 'relationships___field_logos_logos___localFile___ext',
  relationships___field_logos_logos___localFile___name = 'relationships___field_logos_logos___localFile___name',
  relationships___field_logos_logos___localFile___relativeDirectory = 'relationships___field_logos_logos___localFile___relativeDirectory',
  relationships___field_logos_logos___localFile___dev = 'relationships___field_logos_logos___localFile___dev',
  relationships___field_logos_logos___localFile___mode = 'relationships___field_logos_logos___localFile___mode',
  relationships___field_logos_logos___localFile___nlink = 'relationships___field_logos_logos___localFile___nlink',
  relationships___field_logos_logos___localFile___uid = 'relationships___field_logos_logos___localFile___uid',
  relationships___field_logos_logos___localFile___gid = 'relationships___field_logos_logos___localFile___gid',
  relationships___field_logos_logos___localFile___rdev = 'relationships___field_logos_logos___localFile___rdev',
  relationships___field_logos_logos___localFile___blksize = 'relationships___field_logos_logos___localFile___blksize',
  relationships___field_logos_logos___localFile___ino = 'relationships___field_logos_logos___localFile___ino',
  relationships___field_logos_logos___localFile___blocks = 'relationships___field_logos_logos___localFile___blocks',
  relationships___field_logos_logos___localFile___atimeMs = 'relationships___field_logos_logos___localFile___atimeMs',
  relationships___field_logos_logos___localFile___mtimeMs = 'relationships___field_logos_logos___localFile___mtimeMs',
  relationships___field_logos_logos___localFile___ctimeMs = 'relationships___field_logos_logos___localFile___ctimeMs',
  relationships___field_logos_logos___localFile___atime = 'relationships___field_logos_logos___localFile___atime',
  relationships___field_logos_logos___localFile___mtime = 'relationships___field_logos_logos___localFile___mtime',
  relationships___field_logos_logos___localFile___ctime = 'relationships___field_logos_logos___localFile___ctime',
  relationships___field_logos_logos___localFile___url = 'relationships___field_logos_logos___localFile___url',
  relationships___field_logos_logos___localFile___id = 'relationships___field_logos_logos___localFile___id',
  relationships___field_logos_logos___localFile___children = 'relationships___field_logos_logos___localFile___children',
  relationships___field_logos_logos___uri___url = 'relationships___field_logos_logos___uri___url',
  relationships___field_logos_logos___drupal_id = 'relationships___field_logos_logos___drupal_id',
  relationships___field_logos_logos___drupal_internal__fid = 'relationships___field_logos_logos___drupal_internal__fid',
  relationships___field_logos_logos___langcode = 'relationships___field_logos_logos___langcode',
  relationships___field_logos_logos___filename = 'relationships___field_logos_logos___filename',
  relationships___field_logos_logos___filemime = 'relationships___field_logos_logos___filemime',
  relationships___field_logos_logos___filesize = 'relationships___field_logos_logos___filesize',
  relationships___field_logos_logos___status = 'relationships___field_logos_logos___status',
  relationships___field_logos_logos___created = 'relationships___field_logos_logos___created',
  relationships___field_logos_logos___changed = 'relationships___field_logos_logos___changed',
  relationships___field_logos_logos___uuid = 'relationships___field_logos_logos___uuid',
  relationships___field_logos_logos___relationships___node__blog_post = 'relationships___field_logos_logos___relationships___node__blog_post',
  relationships___field_logos_logos___relationships___node__devices = 'relationships___field_logos_logos___relationships___node__devices',
  relationships___field_logos_logos___relationships___node__accessories = 'relationships___field_logos_logos___relationships___node__accessories',
  relationships___field_logos_logos___relationships___paragraph__news_image_title_text = 'relationships___field_logos_logos___relationships___paragraph__news_image_title_text',
  relationships___field_logos_logos___relationships___node__cases = 'relationships___field_logos_logos___relationships___node__cases',
  relationships___field_logos_logos___relationships___paragraph__branches_what_is_ipos = 'relationships___field_logos_logos___relationships___paragraph__branches_what_is_ipos',
  relationships___field_logos_logos___relationships___paragraph__branches_features = 'relationships___field_logos_logos___relationships___paragraph__branches_features',
  relationships___field_logos_logos___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_logos_logos___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_logos_logos___relationships___paragraph__branches_testimonials = 'relationships___field_logos_logos___relationships___paragraph__branches_testimonials',
  relationships___field_logos_logos___relationships___paragraph__branches_discounts = 'relationships___field_logos_logos___relationships___paragraph__branches_discounts',
  relationships___field_logos_logos___relationships___node__news = 'relationships___field_logos_logos___relationships___node__news',
  relationships___field_logos_logos___relationships___node__branches = 'relationships___field_logos_logos___relationships___node__branches',
  relationships___field_logos_logos___relationships___paragraph__branches_hero = 'relationships___field_logos_logos___relationships___paragraph__branches_hero',
  relationships___field_logos_logos___relationships___paragraph__bullets = 'relationships___field_logos_logos___relationships___paragraph__bullets',
  relationships___field_logos_logos___relationships___taxonomy_term__pricelist_group = 'relationships___field_logos_logos___relationships___taxonomy_term__pricelist_group',
  relationships___field_logos_logos___relationships___paragraph__branches_device = 'relationships___field_logos_logos___relationships___paragraph__branches_device',
  relationships___field_logos_logos___relationships___paragraph__branches_recommendation = 'relationships___field_logos_logos___relationships___paragraph__branches_recommendation',
  relationships___field_logos_logos___relationships___site_setting_entity__accessories = 'relationships___field_logos_logos___relationships___site_setting_entity__accessories',
  relationships___field_logos_logos___relationships___site_setting_entity__branches = 'relationships___field_logos_logos___relationships___site_setting_entity__branches',
  relationships___field_logos_logos___relationships___site_setting_entity__about_us = 'relationships___field_logos_logos___relationships___site_setting_entity__about_us',
  relationships___field_logos_logos___relationships___paragraph__logos = 'relationships___field_logos_logos___relationships___paragraph__logos',
  relationships___field_logos_logos___relationships___paragraph__how_it_works = 'relationships___field_logos_logos___relationships___paragraph__how_it_works',
  relationships___field_logos_logos___relationships___paragraph__ico_and_text = 'relationships___field_logos_logos___relationships___paragraph__ico_and_text',
  relationships___field_logos_logos___relationships___paragraph__hero_1 = 'relationships___field_logos_logos___relationships___paragraph__hero_1',
  relationships___field_logos_logos___relationships___paragraph__comprehensive_solution_item = 'relationships___field_logos_logos___relationships___paragraph__comprehensive_solution_item',
  relationships___field_logos_logos___relationships___site_setting_entity__product = 'relationships___field_logos_logos___relationships___site_setting_entity__product',
  relationships___field_logos_logos___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_logos_logos___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_logos_logos___relationships___paragraph__porownanie_terminali = 'relationships___field_logos_logos___relationships___paragraph__porownanie_terminali',
  relationships___field_logos_logos___relationships___paragraph__zdjecie_opis_link = 'relationships___field_logos_logos___relationships___paragraph__zdjecie_opis_link',
  relationships___field_logos_logos___relationships___site_setting_entity__main_page = 'relationships___field_logos_logos___relationships___site_setting_entity__main_page',
  relationships___field_logos_logos___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_logos_logos___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_logos_logos___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_logos_logos___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_logos_logos___relationships___site_setting_entity__intercars = 'relationships___field_logos_logos___relationships___site_setting_entity__intercars',
  relationships___field_logos_logos___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_logos_logos___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_logos_logos___relationships___node__agreement = 'relationships___field_logos_logos___relationships___node__agreement',
  relationships___field_logos_logos___relationships___node__fiscalization = 'relationships___field_logos_logos___relationships___node__fiscalization',
  relationships___field_logos_logos___relationships___site_setting_entity__configurator = 'relationships___field_logos_logos___relationships___site_setting_entity__configurator',
  relationships___field_logos_logos___relationships___paragraph__social_link = 'relationships___field_logos_logos___relationships___paragraph__social_link',
  relationships___field_logos_logos___relationships___paragraph__make_an_appointment = 'relationships___field_logos_logos___relationships___paragraph__make_an_appointment',
  relationships___field_logos_logos___relationships___paragraph__linked_icons = 'relationships___field_logos_logos___relationships___paragraph__linked_icons',
  relationships___field_logos_logos___relationships___site_setting_entity__financing = 'relationships___field_logos_logos___relationships___site_setting_entity__financing',
  relationships___field_logos_logos___relationships___site_setting_entity__franchise = 'relationships___field_logos_logos___relationships___site_setting_entity__franchise',
  relationships___field_logos_logos___id = 'relationships___field_logos_logos___id',
  relationships___field_logos_logos___parent___id = 'relationships___field_logos_logos___parent___id',
  relationships___field_logos_logos___parent___children = 'relationships___field_logos_logos___parent___children',
  relationships___field_logos_logos___children = 'relationships___field_logos_logos___children',
  relationships___field_logos_logos___children___id = 'relationships___field_logos_logos___children___id',
  relationships___field_logos_logos___children___children = 'relationships___field_logos_logos___children___children',
  relationships___field_logos_logos___internal___content = 'relationships___field_logos_logos___internal___content',
  relationships___field_logos_logos___internal___contentDigest = 'relationships___field_logos_logos___internal___contentDigest',
  relationships___field_logos_logos___internal___description = 'relationships___field_logos_logos___internal___description',
  relationships___field_logos_logos___internal___fieldOwners = 'relationships___field_logos_logos___internal___fieldOwners',
  relationships___field_logos_logos___internal___ignoreType = 'relationships___field_logos_logos___internal___ignoreType',
  relationships___field_logos_logos___internal___mediaType = 'relationships___field_logos_logos___internal___mediaType',
  relationships___field_logos_logos___internal___owner = 'relationships___field_logos_logos___internal___owner',
  relationships___field_logos_logos___internal___type = 'relationships___field_logos_logos___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  langcode = 'langcode',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__LogosFilterInput = {
  field_logos_logos?: Maybe<Paragraph__LogosField_Logos_LogosFilterListInput>;
  field_logos_text?: Maybe<Field_Logos_TextFilterInput>;
  relationships?: Maybe<Paragraph__LogosRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__LogosFilterListInput = {
  elemMatch?: Maybe<Paragraph__LogosFilterInput>;
};

export type Paragraph__LogosGroupConnection = {
  __typename?: 'paragraph__logosGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__LogosEdge>;
  nodes: Array<Paragraph__Logos>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__LogosRelationships = {
  __typename?: 'paragraph__logosRelationships';
  field_logos_logos?: Maybe<Array<Maybe<File__File>>>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
};

export type Paragraph__LogosRelationshipsFilterInput = {
  field_logos_logos?: Maybe<File__FileFilterListInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
};

export type Paragraph__LogosSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__LogosFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Make_An_Appointment = Node & {
  __typename?: 'paragraph__make_an_appointment';
  id: Scalars['ID'];
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  field_appointment_header?: Maybe<Scalars['String']>;
  field_appointment_text?: Maybe<Field_Appointment_Text>;
  relationships?: Maybe<Paragraph__Make_An_AppointmentRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_appointment_button?: Maybe<Paragraph__Make_An_AppointmentField_Appointment_Button>;
  field_appointment_image?: Maybe<Paragraph__Make_An_AppointmentField_Appointment_Image>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Make_An_AppointmentCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Make_An_AppointmentContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Make_An_AppointmentConnection = {
  __typename?: 'paragraph__make_an_appointmentConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Make_An_AppointmentEdge>;
  nodes: Array<Paragraph__Make_An_Appointment>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Make_An_AppointmentGroupConnection>;
};

export type Paragraph__Make_An_AppointmentConnectionDistinctArgs = {
  field: Paragraph__Make_An_AppointmentFieldsEnum;
};

export type Paragraph__Make_An_AppointmentConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Make_An_AppointmentFieldsEnum;
};

export type Paragraph__Make_An_AppointmentEdge = {
  __typename?: 'paragraph__make_an_appointmentEdge';
  next?: Maybe<Paragraph__Make_An_Appointment>;
  node: Paragraph__Make_An_Appointment;
  previous?: Maybe<Paragraph__Make_An_Appointment>;
};

export type Paragraph__Make_An_AppointmentField_Appointment_Button = {
  __typename?: 'paragraph__make_an_appointmentField_appointment_button';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Make_An_AppointmentField_Appointment_ButtonFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Make_An_AppointmentField_Appointment_Image = {
  __typename?: 'paragraph__make_an_appointmentField_appointment_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Make_An_AppointmentField_Appointment_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Paragraph__Make_An_AppointmentFieldsEnum {
  id = 'id',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  field_appointment_header = 'field_appointment_header',
  field_appointment_text___value = 'field_appointment_text___value',
  relationships___field_appointment_icon___localFile___birthtime = 'relationships___field_appointment_icon___localFile___birthtime',
  relationships___field_appointment_icon___localFile___birthtimeMs = 'relationships___field_appointment_icon___localFile___birthtimeMs',
  relationships___field_appointment_icon___localFile___publicURL = 'relationships___field_appointment_icon___localFile___publicURL',
  relationships___field_appointment_icon___localFile___sourceInstanceName = 'relationships___field_appointment_icon___localFile___sourceInstanceName',
  relationships___field_appointment_icon___localFile___absolutePath = 'relationships___field_appointment_icon___localFile___absolutePath',
  relationships___field_appointment_icon___localFile___relativePath = 'relationships___field_appointment_icon___localFile___relativePath',
  relationships___field_appointment_icon___localFile___extension = 'relationships___field_appointment_icon___localFile___extension',
  relationships___field_appointment_icon___localFile___size = 'relationships___field_appointment_icon___localFile___size',
  relationships___field_appointment_icon___localFile___prettySize = 'relationships___field_appointment_icon___localFile___prettySize',
  relationships___field_appointment_icon___localFile___modifiedTime = 'relationships___field_appointment_icon___localFile___modifiedTime',
  relationships___field_appointment_icon___localFile___accessTime = 'relationships___field_appointment_icon___localFile___accessTime',
  relationships___field_appointment_icon___localFile___changeTime = 'relationships___field_appointment_icon___localFile___changeTime',
  relationships___field_appointment_icon___localFile___birthTime = 'relationships___field_appointment_icon___localFile___birthTime',
  relationships___field_appointment_icon___localFile___root = 'relationships___field_appointment_icon___localFile___root',
  relationships___field_appointment_icon___localFile___dir = 'relationships___field_appointment_icon___localFile___dir',
  relationships___field_appointment_icon___localFile___base = 'relationships___field_appointment_icon___localFile___base',
  relationships___field_appointment_icon___localFile___ext = 'relationships___field_appointment_icon___localFile___ext',
  relationships___field_appointment_icon___localFile___name = 'relationships___field_appointment_icon___localFile___name',
  relationships___field_appointment_icon___localFile___relativeDirectory = 'relationships___field_appointment_icon___localFile___relativeDirectory',
  relationships___field_appointment_icon___localFile___dev = 'relationships___field_appointment_icon___localFile___dev',
  relationships___field_appointment_icon___localFile___mode = 'relationships___field_appointment_icon___localFile___mode',
  relationships___field_appointment_icon___localFile___nlink = 'relationships___field_appointment_icon___localFile___nlink',
  relationships___field_appointment_icon___localFile___uid = 'relationships___field_appointment_icon___localFile___uid',
  relationships___field_appointment_icon___localFile___gid = 'relationships___field_appointment_icon___localFile___gid',
  relationships___field_appointment_icon___localFile___rdev = 'relationships___field_appointment_icon___localFile___rdev',
  relationships___field_appointment_icon___localFile___blksize = 'relationships___field_appointment_icon___localFile___blksize',
  relationships___field_appointment_icon___localFile___ino = 'relationships___field_appointment_icon___localFile___ino',
  relationships___field_appointment_icon___localFile___blocks = 'relationships___field_appointment_icon___localFile___blocks',
  relationships___field_appointment_icon___localFile___atimeMs = 'relationships___field_appointment_icon___localFile___atimeMs',
  relationships___field_appointment_icon___localFile___mtimeMs = 'relationships___field_appointment_icon___localFile___mtimeMs',
  relationships___field_appointment_icon___localFile___ctimeMs = 'relationships___field_appointment_icon___localFile___ctimeMs',
  relationships___field_appointment_icon___localFile___atime = 'relationships___field_appointment_icon___localFile___atime',
  relationships___field_appointment_icon___localFile___mtime = 'relationships___field_appointment_icon___localFile___mtime',
  relationships___field_appointment_icon___localFile___ctime = 'relationships___field_appointment_icon___localFile___ctime',
  relationships___field_appointment_icon___localFile___url = 'relationships___field_appointment_icon___localFile___url',
  relationships___field_appointment_icon___localFile___id = 'relationships___field_appointment_icon___localFile___id',
  relationships___field_appointment_icon___localFile___children = 'relationships___field_appointment_icon___localFile___children',
  relationships___field_appointment_icon___uri___url = 'relationships___field_appointment_icon___uri___url',
  relationships___field_appointment_icon___drupal_id = 'relationships___field_appointment_icon___drupal_id',
  relationships___field_appointment_icon___drupal_internal__fid = 'relationships___field_appointment_icon___drupal_internal__fid',
  relationships___field_appointment_icon___langcode = 'relationships___field_appointment_icon___langcode',
  relationships___field_appointment_icon___filename = 'relationships___field_appointment_icon___filename',
  relationships___field_appointment_icon___filemime = 'relationships___field_appointment_icon___filemime',
  relationships___field_appointment_icon___filesize = 'relationships___field_appointment_icon___filesize',
  relationships___field_appointment_icon___status = 'relationships___field_appointment_icon___status',
  relationships___field_appointment_icon___created = 'relationships___field_appointment_icon___created',
  relationships___field_appointment_icon___changed = 'relationships___field_appointment_icon___changed',
  relationships___field_appointment_icon___uuid = 'relationships___field_appointment_icon___uuid',
  relationships___field_appointment_icon___relationships___node__blog_post = 'relationships___field_appointment_icon___relationships___node__blog_post',
  relationships___field_appointment_icon___relationships___node__devices = 'relationships___field_appointment_icon___relationships___node__devices',
  relationships___field_appointment_icon___relationships___node__accessories = 'relationships___field_appointment_icon___relationships___node__accessories',
  relationships___field_appointment_icon___relationships___paragraph__news_image_title_text = 'relationships___field_appointment_icon___relationships___paragraph__news_image_title_text',
  relationships___field_appointment_icon___relationships___node__cases = 'relationships___field_appointment_icon___relationships___node__cases',
  relationships___field_appointment_icon___relationships___paragraph__branches_what_is_ipos = 'relationships___field_appointment_icon___relationships___paragraph__branches_what_is_ipos',
  relationships___field_appointment_icon___relationships___paragraph__branches_features = 'relationships___field_appointment_icon___relationships___paragraph__branches_features',
  relationships___field_appointment_icon___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_appointment_icon___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_appointment_icon___relationships___paragraph__branches_testimonials = 'relationships___field_appointment_icon___relationships___paragraph__branches_testimonials',
  relationships___field_appointment_icon___relationships___paragraph__branches_discounts = 'relationships___field_appointment_icon___relationships___paragraph__branches_discounts',
  relationships___field_appointment_icon___relationships___node__news = 'relationships___field_appointment_icon___relationships___node__news',
  relationships___field_appointment_icon___relationships___node__branches = 'relationships___field_appointment_icon___relationships___node__branches',
  relationships___field_appointment_icon___relationships___paragraph__branches_hero = 'relationships___field_appointment_icon___relationships___paragraph__branches_hero',
  relationships___field_appointment_icon___relationships___paragraph__bullets = 'relationships___field_appointment_icon___relationships___paragraph__bullets',
  relationships___field_appointment_icon___relationships___taxonomy_term__pricelist_group = 'relationships___field_appointment_icon___relationships___taxonomy_term__pricelist_group',
  relationships___field_appointment_icon___relationships___paragraph__branches_device = 'relationships___field_appointment_icon___relationships___paragraph__branches_device',
  relationships___field_appointment_icon___relationships___paragraph__branches_recommendation = 'relationships___field_appointment_icon___relationships___paragraph__branches_recommendation',
  relationships___field_appointment_icon___relationships___site_setting_entity__accessories = 'relationships___field_appointment_icon___relationships___site_setting_entity__accessories',
  relationships___field_appointment_icon___relationships___site_setting_entity__branches = 'relationships___field_appointment_icon___relationships___site_setting_entity__branches',
  relationships___field_appointment_icon___relationships___site_setting_entity__about_us = 'relationships___field_appointment_icon___relationships___site_setting_entity__about_us',
  relationships___field_appointment_icon___relationships___paragraph__logos = 'relationships___field_appointment_icon___relationships___paragraph__logos',
  relationships___field_appointment_icon___relationships___paragraph__how_it_works = 'relationships___field_appointment_icon___relationships___paragraph__how_it_works',
  relationships___field_appointment_icon___relationships___paragraph__ico_and_text = 'relationships___field_appointment_icon___relationships___paragraph__ico_and_text',
  relationships___field_appointment_icon___relationships___paragraph__hero_1 = 'relationships___field_appointment_icon___relationships___paragraph__hero_1',
  relationships___field_appointment_icon___relationships___paragraph__comprehensive_solution_item = 'relationships___field_appointment_icon___relationships___paragraph__comprehensive_solution_item',
  relationships___field_appointment_icon___relationships___site_setting_entity__product = 'relationships___field_appointment_icon___relationships___site_setting_entity__product',
  relationships___field_appointment_icon___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_appointment_icon___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_appointment_icon___relationships___paragraph__porownanie_terminali = 'relationships___field_appointment_icon___relationships___paragraph__porownanie_terminali',
  relationships___field_appointment_icon___relationships___paragraph__zdjecie_opis_link = 'relationships___field_appointment_icon___relationships___paragraph__zdjecie_opis_link',
  relationships___field_appointment_icon___relationships___site_setting_entity__main_page = 'relationships___field_appointment_icon___relationships___site_setting_entity__main_page',
  relationships___field_appointment_icon___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_appointment_icon___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_appointment_icon___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_appointment_icon___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_appointment_icon___relationships___site_setting_entity__intercars = 'relationships___field_appointment_icon___relationships___site_setting_entity__intercars',
  relationships___field_appointment_icon___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_appointment_icon___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_appointment_icon___relationships___node__agreement = 'relationships___field_appointment_icon___relationships___node__agreement',
  relationships___field_appointment_icon___relationships___node__fiscalization = 'relationships___field_appointment_icon___relationships___node__fiscalization',
  relationships___field_appointment_icon___relationships___site_setting_entity__configurator = 'relationships___field_appointment_icon___relationships___site_setting_entity__configurator',
  relationships___field_appointment_icon___relationships___paragraph__social_link = 'relationships___field_appointment_icon___relationships___paragraph__social_link',
  relationships___field_appointment_icon___relationships___paragraph__make_an_appointment = 'relationships___field_appointment_icon___relationships___paragraph__make_an_appointment',
  relationships___field_appointment_icon___relationships___paragraph__linked_icons = 'relationships___field_appointment_icon___relationships___paragraph__linked_icons',
  relationships___field_appointment_icon___relationships___site_setting_entity__financing = 'relationships___field_appointment_icon___relationships___site_setting_entity__financing',
  relationships___field_appointment_icon___relationships___site_setting_entity__franchise = 'relationships___field_appointment_icon___relationships___site_setting_entity__franchise',
  relationships___field_appointment_icon___id = 'relationships___field_appointment_icon___id',
  relationships___field_appointment_icon___parent___id = 'relationships___field_appointment_icon___parent___id',
  relationships___field_appointment_icon___parent___children = 'relationships___field_appointment_icon___parent___children',
  relationships___field_appointment_icon___children = 'relationships___field_appointment_icon___children',
  relationships___field_appointment_icon___children___id = 'relationships___field_appointment_icon___children___id',
  relationships___field_appointment_icon___children___children = 'relationships___field_appointment_icon___children___children',
  relationships___field_appointment_icon___internal___content = 'relationships___field_appointment_icon___internal___content',
  relationships___field_appointment_icon___internal___contentDigest = 'relationships___field_appointment_icon___internal___contentDigest',
  relationships___field_appointment_icon___internal___description = 'relationships___field_appointment_icon___internal___description',
  relationships___field_appointment_icon___internal___fieldOwners = 'relationships___field_appointment_icon___internal___fieldOwners',
  relationships___field_appointment_icon___internal___ignoreType = 'relationships___field_appointment_icon___internal___ignoreType',
  relationships___field_appointment_icon___internal___mediaType = 'relationships___field_appointment_icon___internal___mediaType',
  relationships___field_appointment_icon___internal___owner = 'relationships___field_appointment_icon___internal___owner',
  relationships___field_appointment_icon___internal___type = 'relationships___field_appointment_icon___internal___type',
  relationships___field_appointment_image___localFile___birthtime = 'relationships___field_appointment_image___localFile___birthtime',
  relationships___field_appointment_image___localFile___birthtimeMs = 'relationships___field_appointment_image___localFile___birthtimeMs',
  relationships___field_appointment_image___localFile___publicURL = 'relationships___field_appointment_image___localFile___publicURL',
  relationships___field_appointment_image___localFile___sourceInstanceName = 'relationships___field_appointment_image___localFile___sourceInstanceName',
  relationships___field_appointment_image___localFile___absolutePath = 'relationships___field_appointment_image___localFile___absolutePath',
  relationships___field_appointment_image___localFile___relativePath = 'relationships___field_appointment_image___localFile___relativePath',
  relationships___field_appointment_image___localFile___extension = 'relationships___field_appointment_image___localFile___extension',
  relationships___field_appointment_image___localFile___size = 'relationships___field_appointment_image___localFile___size',
  relationships___field_appointment_image___localFile___prettySize = 'relationships___field_appointment_image___localFile___prettySize',
  relationships___field_appointment_image___localFile___modifiedTime = 'relationships___field_appointment_image___localFile___modifiedTime',
  relationships___field_appointment_image___localFile___accessTime = 'relationships___field_appointment_image___localFile___accessTime',
  relationships___field_appointment_image___localFile___changeTime = 'relationships___field_appointment_image___localFile___changeTime',
  relationships___field_appointment_image___localFile___birthTime = 'relationships___field_appointment_image___localFile___birthTime',
  relationships___field_appointment_image___localFile___root = 'relationships___field_appointment_image___localFile___root',
  relationships___field_appointment_image___localFile___dir = 'relationships___field_appointment_image___localFile___dir',
  relationships___field_appointment_image___localFile___base = 'relationships___field_appointment_image___localFile___base',
  relationships___field_appointment_image___localFile___ext = 'relationships___field_appointment_image___localFile___ext',
  relationships___field_appointment_image___localFile___name = 'relationships___field_appointment_image___localFile___name',
  relationships___field_appointment_image___localFile___relativeDirectory = 'relationships___field_appointment_image___localFile___relativeDirectory',
  relationships___field_appointment_image___localFile___dev = 'relationships___field_appointment_image___localFile___dev',
  relationships___field_appointment_image___localFile___mode = 'relationships___field_appointment_image___localFile___mode',
  relationships___field_appointment_image___localFile___nlink = 'relationships___field_appointment_image___localFile___nlink',
  relationships___field_appointment_image___localFile___uid = 'relationships___field_appointment_image___localFile___uid',
  relationships___field_appointment_image___localFile___gid = 'relationships___field_appointment_image___localFile___gid',
  relationships___field_appointment_image___localFile___rdev = 'relationships___field_appointment_image___localFile___rdev',
  relationships___field_appointment_image___localFile___blksize = 'relationships___field_appointment_image___localFile___blksize',
  relationships___field_appointment_image___localFile___ino = 'relationships___field_appointment_image___localFile___ino',
  relationships___field_appointment_image___localFile___blocks = 'relationships___field_appointment_image___localFile___blocks',
  relationships___field_appointment_image___localFile___atimeMs = 'relationships___field_appointment_image___localFile___atimeMs',
  relationships___field_appointment_image___localFile___mtimeMs = 'relationships___field_appointment_image___localFile___mtimeMs',
  relationships___field_appointment_image___localFile___ctimeMs = 'relationships___field_appointment_image___localFile___ctimeMs',
  relationships___field_appointment_image___localFile___atime = 'relationships___field_appointment_image___localFile___atime',
  relationships___field_appointment_image___localFile___mtime = 'relationships___field_appointment_image___localFile___mtime',
  relationships___field_appointment_image___localFile___ctime = 'relationships___field_appointment_image___localFile___ctime',
  relationships___field_appointment_image___localFile___url = 'relationships___field_appointment_image___localFile___url',
  relationships___field_appointment_image___localFile___id = 'relationships___field_appointment_image___localFile___id',
  relationships___field_appointment_image___localFile___children = 'relationships___field_appointment_image___localFile___children',
  relationships___field_appointment_image___uri___url = 'relationships___field_appointment_image___uri___url',
  relationships___field_appointment_image___drupal_id = 'relationships___field_appointment_image___drupal_id',
  relationships___field_appointment_image___drupal_internal__fid = 'relationships___field_appointment_image___drupal_internal__fid',
  relationships___field_appointment_image___langcode = 'relationships___field_appointment_image___langcode',
  relationships___field_appointment_image___filename = 'relationships___field_appointment_image___filename',
  relationships___field_appointment_image___filemime = 'relationships___field_appointment_image___filemime',
  relationships___field_appointment_image___filesize = 'relationships___field_appointment_image___filesize',
  relationships___field_appointment_image___status = 'relationships___field_appointment_image___status',
  relationships___field_appointment_image___created = 'relationships___field_appointment_image___created',
  relationships___field_appointment_image___changed = 'relationships___field_appointment_image___changed',
  relationships___field_appointment_image___uuid = 'relationships___field_appointment_image___uuid',
  relationships___field_appointment_image___relationships___node__blog_post = 'relationships___field_appointment_image___relationships___node__blog_post',
  relationships___field_appointment_image___relationships___node__devices = 'relationships___field_appointment_image___relationships___node__devices',
  relationships___field_appointment_image___relationships___node__accessories = 'relationships___field_appointment_image___relationships___node__accessories',
  relationships___field_appointment_image___relationships___paragraph__news_image_title_text = 'relationships___field_appointment_image___relationships___paragraph__news_image_title_text',
  relationships___field_appointment_image___relationships___node__cases = 'relationships___field_appointment_image___relationships___node__cases',
  relationships___field_appointment_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_appointment_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_appointment_image___relationships___paragraph__branches_features = 'relationships___field_appointment_image___relationships___paragraph__branches_features',
  relationships___field_appointment_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_appointment_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_appointment_image___relationships___paragraph__branches_testimonials = 'relationships___field_appointment_image___relationships___paragraph__branches_testimonials',
  relationships___field_appointment_image___relationships___paragraph__branches_discounts = 'relationships___field_appointment_image___relationships___paragraph__branches_discounts',
  relationships___field_appointment_image___relationships___node__news = 'relationships___field_appointment_image___relationships___node__news',
  relationships___field_appointment_image___relationships___node__branches = 'relationships___field_appointment_image___relationships___node__branches',
  relationships___field_appointment_image___relationships___paragraph__branches_hero = 'relationships___field_appointment_image___relationships___paragraph__branches_hero',
  relationships___field_appointment_image___relationships___paragraph__bullets = 'relationships___field_appointment_image___relationships___paragraph__bullets',
  relationships___field_appointment_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_appointment_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_appointment_image___relationships___paragraph__branches_device = 'relationships___field_appointment_image___relationships___paragraph__branches_device',
  relationships___field_appointment_image___relationships___paragraph__branches_recommendation = 'relationships___field_appointment_image___relationships___paragraph__branches_recommendation',
  relationships___field_appointment_image___relationships___site_setting_entity__accessories = 'relationships___field_appointment_image___relationships___site_setting_entity__accessories',
  relationships___field_appointment_image___relationships___site_setting_entity__branches = 'relationships___field_appointment_image___relationships___site_setting_entity__branches',
  relationships___field_appointment_image___relationships___site_setting_entity__about_us = 'relationships___field_appointment_image___relationships___site_setting_entity__about_us',
  relationships___field_appointment_image___relationships___paragraph__logos = 'relationships___field_appointment_image___relationships___paragraph__logos',
  relationships___field_appointment_image___relationships___paragraph__how_it_works = 'relationships___field_appointment_image___relationships___paragraph__how_it_works',
  relationships___field_appointment_image___relationships___paragraph__ico_and_text = 'relationships___field_appointment_image___relationships___paragraph__ico_and_text',
  relationships___field_appointment_image___relationships___paragraph__hero_1 = 'relationships___field_appointment_image___relationships___paragraph__hero_1',
  relationships___field_appointment_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_appointment_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_appointment_image___relationships___site_setting_entity__product = 'relationships___field_appointment_image___relationships___site_setting_entity__product',
  relationships___field_appointment_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_appointment_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_appointment_image___relationships___paragraph__porownanie_terminali = 'relationships___field_appointment_image___relationships___paragraph__porownanie_terminali',
  relationships___field_appointment_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_appointment_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_appointment_image___relationships___site_setting_entity__main_page = 'relationships___field_appointment_image___relationships___site_setting_entity__main_page',
  relationships___field_appointment_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_appointment_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_appointment_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_appointment_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_appointment_image___relationships___site_setting_entity__intercars = 'relationships___field_appointment_image___relationships___site_setting_entity__intercars',
  relationships___field_appointment_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_appointment_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_appointment_image___relationships___node__agreement = 'relationships___field_appointment_image___relationships___node__agreement',
  relationships___field_appointment_image___relationships___node__fiscalization = 'relationships___field_appointment_image___relationships___node__fiscalization',
  relationships___field_appointment_image___relationships___site_setting_entity__configurator = 'relationships___field_appointment_image___relationships___site_setting_entity__configurator',
  relationships___field_appointment_image___relationships___paragraph__social_link = 'relationships___field_appointment_image___relationships___paragraph__social_link',
  relationships___field_appointment_image___relationships___paragraph__make_an_appointment = 'relationships___field_appointment_image___relationships___paragraph__make_an_appointment',
  relationships___field_appointment_image___relationships___paragraph__linked_icons = 'relationships___field_appointment_image___relationships___paragraph__linked_icons',
  relationships___field_appointment_image___relationships___site_setting_entity__financing = 'relationships___field_appointment_image___relationships___site_setting_entity__financing',
  relationships___field_appointment_image___relationships___site_setting_entity__franchise = 'relationships___field_appointment_image___relationships___site_setting_entity__franchise',
  relationships___field_appointment_image___id = 'relationships___field_appointment_image___id',
  relationships___field_appointment_image___parent___id = 'relationships___field_appointment_image___parent___id',
  relationships___field_appointment_image___parent___children = 'relationships___field_appointment_image___parent___children',
  relationships___field_appointment_image___children = 'relationships___field_appointment_image___children',
  relationships___field_appointment_image___children___id = 'relationships___field_appointment_image___children___id',
  relationships___field_appointment_image___children___children = 'relationships___field_appointment_image___children___children',
  relationships___field_appointment_image___internal___content = 'relationships___field_appointment_image___internal___content',
  relationships___field_appointment_image___internal___contentDigest = 'relationships___field_appointment_image___internal___contentDigest',
  relationships___field_appointment_image___internal___description = 'relationships___field_appointment_image___internal___description',
  relationships___field_appointment_image___internal___fieldOwners = 'relationships___field_appointment_image___internal___fieldOwners',
  relationships___field_appointment_image___internal___ignoreType = 'relationships___field_appointment_image___internal___ignoreType',
  relationships___field_appointment_image___internal___mediaType = 'relationships___field_appointment_image___internal___mediaType',
  relationships___field_appointment_image___internal___owner = 'relationships___field_appointment_image___internal___owner',
  relationships___field_appointment_image___internal___type = 'relationships___field_appointment_image___internal___type',
  relationships___field_appointment_button___field_link_with_text_text = 'relationships___field_appointment_button___field_link_with_text_text',
  relationships___field_appointment_button___field_link_with_text_link = 'relationships___field_appointment_button___field_link_with_text_link',
  relationships___field_appointment_button___drupal_id = 'relationships___field_appointment_button___drupal_id',
  relationships___field_appointment_button___drupal_internal__id = 'relationships___field_appointment_button___drupal_internal__id',
  relationships___field_appointment_button___drupal_internal__revision_id = 'relationships___field_appointment_button___drupal_internal__revision_id',
  relationships___field_appointment_button___langcode = 'relationships___field_appointment_button___langcode',
  relationships___field_appointment_button___status = 'relationships___field_appointment_button___status',
  relationships___field_appointment_button___created = 'relationships___field_appointment_button___created',
  relationships___field_appointment_button___parent_id = 'relationships___field_appointment_button___parent_id',
  relationships___field_appointment_button___parent_type = 'relationships___field_appointment_button___parent_type',
  relationships___field_appointment_button___parent_field_name = 'relationships___field_appointment_button___parent_field_name',
  relationships___field_appointment_button___default_langcode = 'relationships___field_appointment_button___default_langcode',
  relationships___field_appointment_button___revision_translation_affected = 'relationships___field_appointment_button___revision_translation_affected',
  relationships___field_appointment_button___content_translation_source = 'relationships___field_appointment_button___content_translation_source',
  relationships___field_appointment_button___content_translation_outdated = 'relationships___field_appointment_button___content_translation_outdated',
  relationships___field_appointment_button___content_translation_changed = 'relationships___field_appointment_button___content_translation_changed',
  relationships___field_appointment_button___uuid = 'relationships___field_appointment_button___uuid',
  relationships___field_appointment_button___relationships___paragraph__heading_and_links = 'relationships___field_appointment_button___relationships___paragraph__heading_and_links',
  relationships___field_appointment_button___relationships___paragraph__heading_and_text = 'relationships___field_appointment_button___relationships___paragraph__heading_and_text',
  relationships___field_appointment_button___relationships___paragraph__make_an_appointment = 'relationships___field_appointment_button___relationships___paragraph__make_an_appointment',
  relationships___field_appointment_button___id = 'relationships___field_appointment_button___id',
  relationships___field_appointment_button___parent___id = 'relationships___field_appointment_button___parent___id',
  relationships___field_appointment_button___parent___children = 'relationships___field_appointment_button___parent___children',
  relationships___field_appointment_button___children = 'relationships___field_appointment_button___children',
  relationships___field_appointment_button___children___id = 'relationships___field_appointment_button___children___id',
  relationships___field_appointment_button___children___children = 'relationships___field_appointment_button___children___children',
  relationships___field_appointment_button___internal___content = 'relationships___field_appointment_button___internal___content',
  relationships___field_appointment_button___internal___contentDigest = 'relationships___field_appointment_button___internal___contentDigest',
  relationships___field_appointment_button___internal___description = 'relationships___field_appointment_button___internal___description',
  relationships___field_appointment_button___internal___fieldOwners = 'relationships___field_appointment_button___internal___fieldOwners',
  relationships___field_appointment_button___internal___ignoreType = 'relationships___field_appointment_button___internal___ignoreType',
  relationships___field_appointment_button___internal___mediaType = 'relationships___field_appointment_button___internal___mediaType',
  relationships___field_appointment_button___internal___owner = 'relationships___field_appointment_button___internal___owner',
  relationships___field_appointment_button___internal___type = 'relationships___field_appointment_button___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_appointment_button___target_revision_id = 'field_appointment_button___target_revision_id',
  field_appointment_button___drupal_internal__target_id = 'field_appointment_button___drupal_internal__target_id',
  field_appointment_image___alt = 'field_appointment_image___alt',
  field_appointment_image___title = 'field_appointment_image___title',
  field_appointment_image___width = 'field_appointment_image___width',
  field_appointment_image___height = 'field_appointment_image___height',
  field_appointment_image___drupal_internal__target_id = 'field_appointment_image___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Make_An_AppointmentFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_appointment_header?: Maybe<StringQueryOperatorInput>;
  field_appointment_text?: Maybe<Field_Appointment_TextFilterInput>;
  relationships?: Maybe<Paragraph__Make_An_AppointmentRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_appointment_button?: Maybe<
    Paragraph__Make_An_AppointmentField_Appointment_ButtonFilterInput
  >;
  field_appointment_image?: Maybe<Paragraph__Make_An_AppointmentField_Appointment_ImageFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Make_An_AppointmentFilterListInput = {
  elemMatch?: Maybe<Paragraph__Make_An_AppointmentFilterInput>;
};

export type Paragraph__Make_An_AppointmentGroupConnection = {
  __typename?: 'paragraph__make_an_appointmentGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Make_An_AppointmentEdge>;
  nodes: Array<Paragraph__Make_An_Appointment>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Make_An_AppointmentRelationships = {
  __typename?: 'paragraph__make_an_appointmentRelationships';
  field_appointment_icon?: Maybe<File__File>;
  field_appointment_image?: Maybe<File__File>;
  field_appointment_button?: Maybe<Paragraph__Link_With_Text>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
};

export type Paragraph__Make_An_AppointmentRelationshipsFilterInput = {
  field_appointment_icon?: Maybe<File__FileFilterInput>;
  field_appointment_image?: Maybe<File__FileFilterInput>;
  field_appointment_button?: Maybe<Paragraph__Link_With_TextFilterInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
};

export type Paragraph__Make_An_AppointmentSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Make_An_AppointmentFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Member_Of_The_Board_Personal_Dat = Node & {
  __typename?: 'paragraph__member_of_the_board_personal_dat';
  field_description_member_board?: Maybe<Field_Description_Member_Board>;
  field_photo_member_of_the_board?: Maybe<Field_Photo_Member_Of_The_Board>;
  relationships?: Maybe<Paragraph__Member_Of_The_Board_Personal_DatRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Member_Of_The_Board_Personal_DatCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Member_Of_The_Board_Personal_DatContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Member_Of_The_Board_Personal_DatConnection = {
  __typename?: 'paragraph__member_of_the_board_personal_datConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Member_Of_The_Board_Personal_DatEdge>;
  nodes: Array<Paragraph__Member_Of_The_Board_Personal_Dat>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Member_Of_The_Board_Personal_DatGroupConnection>;
};

export type Paragraph__Member_Of_The_Board_Personal_DatConnectionDistinctArgs = {
  field: Paragraph__Member_Of_The_Board_Personal_DatFieldsEnum;
};

export type Paragraph__Member_Of_The_Board_Personal_DatConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Member_Of_The_Board_Personal_DatFieldsEnum;
};

export type Paragraph__Member_Of_The_Board_Personal_DatEdge = {
  __typename?: 'paragraph__member_of_the_board_personal_datEdge';
  next?: Maybe<Paragraph__Member_Of_The_Board_Personal_Dat>;
  node: Paragraph__Member_Of_The_Board_Personal_Dat;
  previous?: Maybe<Paragraph__Member_Of_The_Board_Personal_Dat>;
};

export enum Paragraph__Member_Of_The_Board_Personal_DatFieldsEnum {
  field_description_member_board___value = 'field_description_member_board___value',
  field_photo_member_of_the_board___alt = 'field_photo_member_of_the_board___alt',
  relationships___field_photo_member_of_the_board___localFile___birthtime = 'relationships___field_photo_member_of_the_board___localFile___birthtime',
  relationships___field_photo_member_of_the_board___localFile___birthtimeMs = 'relationships___field_photo_member_of_the_board___localFile___birthtimeMs',
  relationships___field_photo_member_of_the_board___localFile___publicURL = 'relationships___field_photo_member_of_the_board___localFile___publicURL',
  relationships___field_photo_member_of_the_board___localFile___sourceInstanceName = 'relationships___field_photo_member_of_the_board___localFile___sourceInstanceName',
  relationships___field_photo_member_of_the_board___localFile___absolutePath = 'relationships___field_photo_member_of_the_board___localFile___absolutePath',
  relationships___field_photo_member_of_the_board___localFile___relativePath = 'relationships___field_photo_member_of_the_board___localFile___relativePath',
  relationships___field_photo_member_of_the_board___localFile___extension = 'relationships___field_photo_member_of_the_board___localFile___extension',
  relationships___field_photo_member_of_the_board___localFile___size = 'relationships___field_photo_member_of_the_board___localFile___size',
  relationships___field_photo_member_of_the_board___localFile___prettySize = 'relationships___field_photo_member_of_the_board___localFile___prettySize',
  relationships___field_photo_member_of_the_board___localFile___modifiedTime = 'relationships___field_photo_member_of_the_board___localFile___modifiedTime',
  relationships___field_photo_member_of_the_board___localFile___accessTime = 'relationships___field_photo_member_of_the_board___localFile___accessTime',
  relationships___field_photo_member_of_the_board___localFile___changeTime = 'relationships___field_photo_member_of_the_board___localFile___changeTime',
  relationships___field_photo_member_of_the_board___localFile___birthTime = 'relationships___field_photo_member_of_the_board___localFile___birthTime',
  relationships___field_photo_member_of_the_board___localFile___root = 'relationships___field_photo_member_of_the_board___localFile___root',
  relationships___field_photo_member_of_the_board___localFile___dir = 'relationships___field_photo_member_of_the_board___localFile___dir',
  relationships___field_photo_member_of_the_board___localFile___base = 'relationships___field_photo_member_of_the_board___localFile___base',
  relationships___field_photo_member_of_the_board___localFile___ext = 'relationships___field_photo_member_of_the_board___localFile___ext',
  relationships___field_photo_member_of_the_board___localFile___name = 'relationships___field_photo_member_of_the_board___localFile___name',
  relationships___field_photo_member_of_the_board___localFile___relativeDirectory = 'relationships___field_photo_member_of_the_board___localFile___relativeDirectory',
  relationships___field_photo_member_of_the_board___localFile___dev = 'relationships___field_photo_member_of_the_board___localFile___dev',
  relationships___field_photo_member_of_the_board___localFile___mode = 'relationships___field_photo_member_of_the_board___localFile___mode',
  relationships___field_photo_member_of_the_board___localFile___nlink = 'relationships___field_photo_member_of_the_board___localFile___nlink',
  relationships___field_photo_member_of_the_board___localFile___uid = 'relationships___field_photo_member_of_the_board___localFile___uid',
  relationships___field_photo_member_of_the_board___localFile___gid = 'relationships___field_photo_member_of_the_board___localFile___gid',
  relationships___field_photo_member_of_the_board___localFile___rdev = 'relationships___field_photo_member_of_the_board___localFile___rdev',
  relationships___field_photo_member_of_the_board___localFile___blksize = 'relationships___field_photo_member_of_the_board___localFile___blksize',
  relationships___field_photo_member_of_the_board___localFile___ino = 'relationships___field_photo_member_of_the_board___localFile___ino',
  relationships___field_photo_member_of_the_board___localFile___blocks = 'relationships___field_photo_member_of_the_board___localFile___blocks',
  relationships___field_photo_member_of_the_board___localFile___atimeMs = 'relationships___field_photo_member_of_the_board___localFile___atimeMs',
  relationships___field_photo_member_of_the_board___localFile___mtimeMs = 'relationships___field_photo_member_of_the_board___localFile___mtimeMs',
  relationships___field_photo_member_of_the_board___localFile___ctimeMs = 'relationships___field_photo_member_of_the_board___localFile___ctimeMs',
  relationships___field_photo_member_of_the_board___localFile___atime = 'relationships___field_photo_member_of_the_board___localFile___atime',
  relationships___field_photo_member_of_the_board___localFile___mtime = 'relationships___field_photo_member_of_the_board___localFile___mtime',
  relationships___field_photo_member_of_the_board___localFile___ctime = 'relationships___field_photo_member_of_the_board___localFile___ctime',
  relationships___field_photo_member_of_the_board___localFile___url = 'relationships___field_photo_member_of_the_board___localFile___url',
  relationships___field_photo_member_of_the_board___localFile___id = 'relationships___field_photo_member_of_the_board___localFile___id',
  relationships___field_photo_member_of_the_board___localFile___children = 'relationships___field_photo_member_of_the_board___localFile___children',
  relationships___field_photo_member_of_the_board___uri___url = 'relationships___field_photo_member_of_the_board___uri___url',
  relationships___field_photo_member_of_the_board___drupal_id = 'relationships___field_photo_member_of_the_board___drupal_id',
  relationships___field_photo_member_of_the_board___drupal_internal__fid = 'relationships___field_photo_member_of_the_board___drupal_internal__fid',
  relationships___field_photo_member_of_the_board___langcode = 'relationships___field_photo_member_of_the_board___langcode',
  relationships___field_photo_member_of_the_board___filename = 'relationships___field_photo_member_of_the_board___filename',
  relationships___field_photo_member_of_the_board___filemime = 'relationships___field_photo_member_of_the_board___filemime',
  relationships___field_photo_member_of_the_board___filesize = 'relationships___field_photo_member_of_the_board___filesize',
  relationships___field_photo_member_of_the_board___status = 'relationships___field_photo_member_of_the_board___status',
  relationships___field_photo_member_of_the_board___created = 'relationships___field_photo_member_of_the_board___created',
  relationships___field_photo_member_of_the_board___changed = 'relationships___field_photo_member_of_the_board___changed',
  relationships___field_photo_member_of_the_board___uuid = 'relationships___field_photo_member_of_the_board___uuid',
  relationships___field_photo_member_of_the_board___relationships___node__blog_post = 'relationships___field_photo_member_of_the_board___relationships___node__blog_post',
  relationships___field_photo_member_of_the_board___relationships___node__devices = 'relationships___field_photo_member_of_the_board___relationships___node__devices',
  relationships___field_photo_member_of_the_board___relationships___node__accessories = 'relationships___field_photo_member_of_the_board___relationships___node__accessories',
  relationships___field_photo_member_of_the_board___relationships___paragraph__news_image_title_text = 'relationships___field_photo_member_of_the_board___relationships___paragraph__news_image_title_text',
  relationships___field_photo_member_of_the_board___relationships___node__cases = 'relationships___field_photo_member_of_the_board___relationships___node__cases',
  relationships___field_photo_member_of_the_board___relationships___paragraph__branches_what_is_ipos = 'relationships___field_photo_member_of_the_board___relationships___paragraph__branches_what_is_ipos',
  relationships___field_photo_member_of_the_board___relationships___paragraph__branches_features = 'relationships___field_photo_member_of_the_board___relationships___paragraph__branches_features',
  relationships___field_photo_member_of_the_board___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_photo_member_of_the_board___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_photo_member_of_the_board___relationships___paragraph__branches_testimonials = 'relationships___field_photo_member_of_the_board___relationships___paragraph__branches_testimonials',
  relationships___field_photo_member_of_the_board___relationships___paragraph__branches_discounts = 'relationships___field_photo_member_of_the_board___relationships___paragraph__branches_discounts',
  relationships___field_photo_member_of_the_board___relationships___node__news = 'relationships___field_photo_member_of_the_board___relationships___node__news',
  relationships___field_photo_member_of_the_board___relationships___node__branches = 'relationships___field_photo_member_of_the_board___relationships___node__branches',
  relationships___field_photo_member_of_the_board___relationships___paragraph__branches_hero = 'relationships___field_photo_member_of_the_board___relationships___paragraph__branches_hero',
  relationships___field_photo_member_of_the_board___relationships___paragraph__bullets = 'relationships___field_photo_member_of_the_board___relationships___paragraph__bullets',
  relationships___field_photo_member_of_the_board___relationships___taxonomy_term__pricelist_group = 'relationships___field_photo_member_of_the_board___relationships___taxonomy_term__pricelist_group',
  relationships___field_photo_member_of_the_board___relationships___paragraph__branches_device = 'relationships___field_photo_member_of_the_board___relationships___paragraph__branches_device',
  relationships___field_photo_member_of_the_board___relationships___paragraph__branches_recommendation = 'relationships___field_photo_member_of_the_board___relationships___paragraph__branches_recommendation',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__accessories = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__accessories',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__branches = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__branches',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__about_us = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__about_us',
  relationships___field_photo_member_of_the_board___relationships___paragraph__logos = 'relationships___field_photo_member_of_the_board___relationships___paragraph__logos',
  relationships___field_photo_member_of_the_board___relationships___paragraph__how_it_works = 'relationships___field_photo_member_of_the_board___relationships___paragraph__how_it_works',
  relationships___field_photo_member_of_the_board___relationships___paragraph__ico_and_text = 'relationships___field_photo_member_of_the_board___relationships___paragraph__ico_and_text',
  relationships___field_photo_member_of_the_board___relationships___paragraph__hero_1 = 'relationships___field_photo_member_of_the_board___relationships___paragraph__hero_1',
  relationships___field_photo_member_of_the_board___relationships___paragraph__comprehensive_solution_item = 'relationships___field_photo_member_of_the_board___relationships___paragraph__comprehensive_solution_item',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__product = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__product',
  relationships___field_photo_member_of_the_board___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_photo_member_of_the_board___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_photo_member_of_the_board___relationships___paragraph__porownanie_terminali = 'relationships___field_photo_member_of_the_board___relationships___paragraph__porownanie_terminali',
  relationships___field_photo_member_of_the_board___relationships___paragraph__zdjecie_opis_link = 'relationships___field_photo_member_of_the_board___relationships___paragraph__zdjecie_opis_link',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__main_page = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__main_page',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_photo_member_of_the_board___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_photo_member_of_the_board___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__intercars = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__intercars',
  relationships___field_photo_member_of_the_board___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_photo_member_of_the_board___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_photo_member_of_the_board___relationships___node__agreement = 'relationships___field_photo_member_of_the_board___relationships___node__agreement',
  relationships___field_photo_member_of_the_board___relationships___node__fiscalization = 'relationships___field_photo_member_of_the_board___relationships___node__fiscalization',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__configurator = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__configurator',
  relationships___field_photo_member_of_the_board___relationships___paragraph__social_link = 'relationships___field_photo_member_of_the_board___relationships___paragraph__social_link',
  relationships___field_photo_member_of_the_board___relationships___paragraph__make_an_appointment = 'relationships___field_photo_member_of_the_board___relationships___paragraph__make_an_appointment',
  relationships___field_photo_member_of_the_board___relationships___paragraph__linked_icons = 'relationships___field_photo_member_of_the_board___relationships___paragraph__linked_icons',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__financing = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__financing',
  relationships___field_photo_member_of_the_board___relationships___site_setting_entity__franchise = 'relationships___field_photo_member_of_the_board___relationships___site_setting_entity__franchise',
  relationships___field_photo_member_of_the_board___id = 'relationships___field_photo_member_of_the_board___id',
  relationships___field_photo_member_of_the_board___parent___id = 'relationships___field_photo_member_of_the_board___parent___id',
  relationships___field_photo_member_of_the_board___parent___children = 'relationships___field_photo_member_of_the_board___parent___children',
  relationships___field_photo_member_of_the_board___children = 'relationships___field_photo_member_of_the_board___children',
  relationships___field_photo_member_of_the_board___children___id = 'relationships___field_photo_member_of_the_board___children___id',
  relationships___field_photo_member_of_the_board___children___children = 'relationships___field_photo_member_of_the_board___children___children',
  relationships___field_photo_member_of_the_board___internal___content = 'relationships___field_photo_member_of_the_board___internal___content',
  relationships___field_photo_member_of_the_board___internal___contentDigest = 'relationships___field_photo_member_of_the_board___internal___contentDigest',
  relationships___field_photo_member_of_the_board___internal___description = 'relationships___field_photo_member_of_the_board___internal___description',
  relationships___field_photo_member_of_the_board___internal___fieldOwners = 'relationships___field_photo_member_of_the_board___internal___fieldOwners',
  relationships___field_photo_member_of_the_board___internal___ignoreType = 'relationships___field_photo_member_of_the_board___internal___ignoreType',
  relationships___field_photo_member_of_the_board___internal___mediaType = 'relationships___field_photo_member_of_the_board___internal___mediaType',
  relationships___field_photo_member_of_the_board___internal___owner = 'relationships___field_photo_member_of_the_board___internal___owner',
  relationships___field_photo_member_of_the_board___internal___type = 'relationships___field_photo_member_of_the_board___internal___type',
  relationships___site_setting_entity__about_us = 'relationships___site_setting_entity__about_us',
  relationships___site_setting_entity__about_us___langcode = 'relationships___site_setting_entity__about_us___langcode',
  relationships___site_setting_entity__about_us___field_meta_description = 'relationships___site_setting_entity__about_us___field_meta_description',
  relationships___site_setting_entity__about_us___field_meta_title = 'relationships___site_setting_entity__about_us___field_meta_title',
  relationships___site_setting_entity__about_us___field_btn_au_text_1 = 'relationships___site_setting_entity__about_us___field_btn_au_text_1',
  relationships___site_setting_entity__about_us___field_btn_au_url_1 = 'relationships___site_setting_entity__about_us___field_btn_au_url_1',
  relationships___site_setting_entity__about_us___field_info_text___value = 'relationships___site_setting_entity__about_us___field_info_text___value',
  relationships___site_setting_entity__about_us___field_hero_image___alt = 'relationships___site_setting_entity__about_us___field_hero_image___alt',
  relationships___site_setting_entity__about_us___field_hero_image_mobile___alt = 'relationships___site_setting_entity__about_us___field_hero_image_mobile___alt',
  relationships___site_setting_entity__about_us___field_hero_text___value = 'relationships___site_setting_entity__about_us___field_hero_text___value',
  relationships___site_setting_entity__about_us___field_hero_headline___value = 'relationships___site_setting_entity__about_us___field_hero_headline___value',
  relationships___site_setting_entity__about_us___field_pl_header_1___value = 'relationships___site_setting_entity__about_us___field_pl_header_1___value',
  relationships___site_setting_entity__about_us___relationships___field_components = 'relationships___site_setting_entity__about_us___relationships___field_components',
  relationships___site_setting_entity__about_us___relationships___field_member_of_the_board_paragr = 'relationships___site_setting_entity__about_us___relationships___field_member_of_the_board_paragr',
  relationships___site_setting_entity__about_us___drupal_id = 'relationships___site_setting_entity__about_us___drupal_id',
  relationships___site_setting_entity__about_us___drupal_internal__id = 'relationships___site_setting_entity__about_us___drupal_internal__id',
  relationships___site_setting_entity__about_us___name = 'relationships___site_setting_entity__about_us___name',
  relationships___site_setting_entity__about_us___fieldset = 'relationships___site_setting_entity__about_us___fieldset',
  relationships___site_setting_entity__about_us___status = 'relationships___site_setting_entity__about_us___status',
  relationships___site_setting_entity__about_us___created = 'relationships___site_setting_entity__about_us___created',
  relationships___site_setting_entity__about_us___changed = 'relationships___site_setting_entity__about_us___changed',
  relationships___site_setting_entity__about_us___default_langcode = 'relationships___site_setting_entity__about_us___default_langcode',
  relationships___site_setting_entity__about_us___content_translation_source = 'relationships___site_setting_entity__about_us___content_translation_source',
  relationships___site_setting_entity__about_us___content_translation_outdated = 'relationships___site_setting_entity__about_us___content_translation_outdated',
  relationships___site_setting_entity__about_us___content_translation_status = 'relationships___site_setting_entity__about_us___content_translation_status',
  relationships___site_setting_entity__about_us___content_translation_created = 'relationships___site_setting_entity__about_us___content_translation_created',
  relationships___site_setting_entity__about_us___content_translation_changed = 'relationships___site_setting_entity__about_us___content_translation_changed',
  relationships___site_setting_entity__about_us___uuid = 'relationships___site_setting_entity__about_us___uuid',
  relationships___site_setting_entity__about_us___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___field_components = 'relationships___site_setting_entity__about_us___field_components',
  relationships___site_setting_entity__about_us___field_components___target_revision_id = 'relationships___site_setting_entity__about_us___field_components___target_revision_id',
  relationships___site_setting_entity__about_us___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___target_revision_id = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___target_revision_id',
  relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___drupal_internal__target_id = 'relationships___site_setting_entity__about_us___field_member_of_the_board_paragr___drupal_internal__target_id',
  relationships___site_setting_entity__about_us___id = 'relationships___site_setting_entity__about_us___id',
  relationships___site_setting_entity__about_us___parent___id = 'relationships___site_setting_entity__about_us___parent___id',
  relationships___site_setting_entity__about_us___parent___children = 'relationships___site_setting_entity__about_us___parent___children',
  relationships___site_setting_entity__about_us___children = 'relationships___site_setting_entity__about_us___children',
  relationships___site_setting_entity__about_us___children___id = 'relationships___site_setting_entity__about_us___children___id',
  relationships___site_setting_entity__about_us___children___children = 'relationships___site_setting_entity__about_us___children___children',
  relationships___site_setting_entity__about_us___internal___content = 'relationships___site_setting_entity__about_us___internal___content',
  relationships___site_setting_entity__about_us___internal___contentDigest = 'relationships___site_setting_entity__about_us___internal___contentDigest',
  relationships___site_setting_entity__about_us___internal___description = 'relationships___site_setting_entity__about_us___internal___description',
  relationships___site_setting_entity__about_us___internal___fieldOwners = 'relationships___site_setting_entity__about_us___internal___fieldOwners',
  relationships___site_setting_entity__about_us___internal___ignoreType = 'relationships___site_setting_entity__about_us___internal___ignoreType',
  relationships___site_setting_entity__about_us___internal___mediaType = 'relationships___site_setting_entity__about_us___internal___mediaType',
  relationships___site_setting_entity__about_us___internal___owner = 'relationships___site_setting_entity__about_us___internal___owner',
  relationships___site_setting_entity__about_us___internal___type = 'relationships___site_setting_entity__about_us___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Member_Of_The_Board_Personal_DatFilterInput = {
  field_description_member_board?: Maybe<Field_Description_Member_BoardFilterInput>;
  field_photo_member_of_the_board?: Maybe<Field_Photo_Member_Of_The_BoardFilterInput>;
  relationships?: Maybe<Paragraph__Member_Of_The_Board_Personal_DatRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Member_Of_The_Board_Personal_DatFilterListInput = {
  elemMatch?: Maybe<Paragraph__Member_Of_The_Board_Personal_DatFilterInput>;
};

export type Paragraph__Member_Of_The_Board_Personal_DatGroupConnection = {
  __typename?: 'paragraph__member_of_the_board_personal_datGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Member_Of_The_Board_Personal_DatEdge>;
  nodes: Array<Paragraph__Member_Of_The_Board_Personal_Dat>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Member_Of_The_Board_Personal_DatRelationships = {
  __typename?: 'paragraph__member_of_the_board_personal_datRelationships';
  field_photo_member_of_the_board?: Maybe<File__File>;
  site_setting_entity__about_us?: Maybe<Array<Maybe<Site_Setting_Entity__About_Us>>>;
};

export type Paragraph__Member_Of_The_Board_Personal_DatRelationshipsFilterInput = {
  field_photo_member_of_the_board?: Maybe<File__FileFilterInput>;
  site_setting_entity__about_us?: Maybe<Site_Setting_Entity__About_UsFilterListInput>;
};

export type Paragraph__Member_Of_The_Board_Personal_DatSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Member_Of_The_Board_Personal_DatFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Sidebar_Image_Item = Node & {
  __typename?: 'paragraph__sidebar_image_item';
  field_label_1?: Maybe<Scalars['String']>;
  field_text_1?: Maybe<Field_Text_1>;
  field_image_side?: Maybe<Scalars['String']>;
  field_image_1?: Maybe<Field_Image_1>;
  relationships?: Maybe<Paragraph__SidebarImageItemRelationships>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Sidebar_Image = Node & {
  __typename?: 'paragraph__sidebar_image';
  field_title?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Sidebar_ImageRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  field_sidebar_image_items?: Maybe<
    Array<Maybe<Paragraph__Sidebar_ImageField_Sidebar_Image_Items>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Sidebar_ImageRelationships = {
  __typename?: 'paragraph__sidebar_imageRelationships';
  field_sidebar_image_items?: Maybe<Array<Maybe<Paragraph__Sidebar_Image_Item>>>;
  site_setting_entity__main_page?: Maybe<Array<Maybe<Site_Setting_Entity__Main_Page>>>;
};

export type Paragraph__Sidebar_ImageField_Sidebar_Image_Items = {
  __typename?: 'paragraph__sidebar_imageField_sidebar_image_items';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__SidebarImageItemRelationships = {
  __typename?: 'paragraph__sidebar_image_itemRelationships';
  field_image_1?: Maybe<File__File>;
  field_image_2?: Maybe<File__File>;
  field_image_3?: Maybe<File__File>;
};

export type Paragraph__News_Cta = Node & {
  __typename?: 'paragraph__news_cta';
  id: Scalars['ID'];
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_nc_text?: Maybe<Scalars['String']>;
  field_nc_button_url?: Maybe<Scalars['String']>;
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__News_CtaRelationships>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__News_CtaContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__News_CtaConnection = {
  __typename?: 'paragraph__news_ctaConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__News_CtaEdge>;
  nodes: Array<Paragraph__News_Cta>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__News_CtaGroupConnection>;
};

export type Paragraph__News_CtaConnectionDistinctArgs = {
  field: Paragraph__News_CtaFieldsEnum;
};

export type Paragraph__News_CtaConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__News_CtaFieldsEnum;
};

export type Paragraph__News_CtaEdge = {
  __typename?: 'paragraph__news_ctaEdge';
  next?: Maybe<Paragraph__News_Cta>;
  node: Paragraph__News_Cta;
  previous?: Maybe<Paragraph__News_Cta>;
};

export enum Paragraph__News_CtaFieldsEnum {
  id = 'id',
  field_nc_button_text = 'field_nc_button_text',
  field_nc_text = 'field_nc_text',
  field_nc_button_url = 'field_nc_button_url',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  relationships___node__news = 'relationships___node__news',
  relationships___node__news___langcode = 'relationships___node__news___langcode',
  relationships___node__news___id = 'relationships___node__news___id',
  relationships___node__news___drupal_internal__nid = 'relationships___node__news___drupal_internal__nid',
  relationships___node__news___title = 'relationships___node__news___title',
  relationships___node__news___field_news_reading_time = 'relationships___node__news___field_news_reading_time',
  relationships___node__news___field_news_body = 'relationships___node__news___field_news_body',
  relationships___node__news___created = 'relationships___node__news___created',
  relationships___node__news___path___alias = 'relationships___node__news___path___alias',
  relationships___node__news___status = 'relationships___node__news___status',
  relationships___node__news___field_meta_description = 'relationships___node__news___field_meta_description',
  relationships___node__news___field_meta_title = 'relationships___node__news___field_meta_title',
  relationships___node__news___relationships___field_news_tags = 'relationships___node__news___relationships___field_news_tags',
  relationships___node__news___relationships___node__news = 'relationships___node__news___relationships___node__news',
  relationships___node__news___relationships___paragraph__recommended_blog_posts = 'relationships___node__news___relationships___paragraph__recommended_blog_posts',
  relationships___node__news___drupal_id = 'relationships___node__news___drupal_id',
  relationships___node__news___rh_redirect_fallback_action = 'relationships___node__news___rh_redirect_fallback_action',
  relationships___node__news___content_translation_source = 'relationships___node__news___content_translation_source',
  relationships___node__news___content_translation_outdated = 'relationships___node__news___content_translation_outdated',
  relationships___node__news___field_meta_keywords = 'relationships___node__news___field_meta_keywords',
  relationships___node__news___uuid = 'relationships___node__news___uuid',
  relationships___node__news___field_news_categories___drupal_internal__target_id = 'relationships___node__news___field_news_categories___drupal_internal__target_id',
  relationships___node__news___field_news_components = 'relationships___node__news___field_news_components',
  relationships___node__news___field_news_components___target_revision_id = 'relationships___node__news___field_news_components___target_revision_id',
  relationships___node__news___field_news_components___drupal_internal__target_id = 'relationships___node__news___field_news_components___drupal_internal__target_id',
  relationships___node__news___field_news_image___alt = 'relationships___node__news___field_news_image___alt',
  relationships___node__news___field_news_image___title = 'relationships___node__news___field_news_image___title',
  relationships___node__news___field_news_image___width = 'relationships___node__news___field_news_image___width',
  relationships___node__news___field_news_image___height = 'relationships___node__news___field_news_image___height',
  relationships___node__news___field_news_image___drupal_internal__target_id = 'relationships___node__news___field_news_image___drupal_internal__target_id',
  relationships___node__news___field_news_tags = 'relationships___node__news___field_news_tags',
  relationships___node__news___field_news_tags___drupal_internal__target_id = 'relationships___node__news___field_news_tags___drupal_internal__target_id',
  relationships___node__news___field_related_news_1___drupal_internal__target_id = 'relationships___node__news___field_related_news_1___drupal_internal__target_id',
  relationships___node__news___field_related_news_2___drupal_internal__target_id = 'relationships___node__news___field_related_news_2___drupal_internal__target_id',
  relationships___node__news___field_related_news_3___drupal_internal__target_id = 'relationships___node__news___field_related_news_3___drupal_internal__target_id',
  relationships___node__news___parent___id = 'relationships___node__news___parent___id',
  relationships___node__news___parent___children = 'relationships___node__news___parent___children',
  relationships___node__news___children = 'relationships___node__news___children',
  relationships___node__news___children___id = 'relationships___node__news___children___id',
  relationships___node__news___children___children = 'relationships___node__news___children___children',
  relationships___node__news___internal___content = 'relationships___node__news___internal___content',
  relationships___node__news___internal___contentDigest = 'relationships___node__news___internal___contentDigest',
  relationships___node__news___internal___description = 'relationships___node__news___internal___description',
  relationships___node__news___internal___fieldOwners = 'relationships___node__news___internal___fieldOwners',
  relationships___node__news___internal___ignoreType = 'relationships___node__news___internal___ignoreType',
  relationships___node__news___internal___mediaType = 'relationships___node__news___internal___mediaType',
  relationships___node__news___internal___owner = 'relationships___node__news___internal___owner',
  relationships___node__news___internal___type = 'relationships___node__news___internal___type',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__News_CtaFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_nc_text?: Maybe<StringQueryOperatorInput>;
  field_nc_button_url?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__News_CtaRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__News_CtaGroupConnection = {
  __typename?: 'paragraph__news_ctaGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__News_CtaEdge>;
  nodes: Array<Paragraph__News_Cta>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__News_CtaRelationships = {
  __typename?: 'paragraph__news_ctaRelationships';
  node__news?: Maybe<Array<Maybe<Node__News>>>;
};

export type Paragraph__News_CtaRelationshipsFilterInput = {
  node__news?: Maybe<Node__NewsFilterListInput>;
};

export type Paragraph__News_CtaSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__News_CtaFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__News_Image_Title_Text = Node & {
  __typename?: 'paragraph__news_image_title_text';
  field_nitt_text?: Maybe<Scalars['String']>;
  field_nitt_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internal: Internal;
  relationships?: Maybe<Paragraph__News_Image_Title_TextRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_nitt_image?: Maybe<Paragraph__News_Image_Title_TextField_Nitt_Image>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__News_Image_Title_TextContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__News_Image_Title_TextConnection = {
  __typename?: 'paragraph__news_image_title_textConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__News_Image_Title_TextEdge>;
  nodes: Array<Paragraph__News_Image_Title_Text>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__News_Image_Title_TextGroupConnection>;
};

export type Paragraph__News_Image_Title_TextConnectionDistinctArgs = {
  field: Paragraph__News_Image_Title_TextFieldsEnum;
};

export type Paragraph__News_Image_Title_TextConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__News_Image_Title_TextFieldsEnum;
};

export type Paragraph__News_Image_Title_TextEdge = {
  __typename?: 'paragraph__news_image_title_textEdge';
  next?: Maybe<Paragraph__News_Image_Title_Text>;
  node: Paragraph__News_Image_Title_Text;
  previous?: Maybe<Paragraph__News_Image_Title_Text>;
};

export type Paragraph__News_Image_Title_TextField_Nitt_Image = {
  __typename?: 'paragraph__news_image_title_textField_nitt_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__News_Image_Title_TextField_Nitt_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Paragraph__News_Image_Title_TextFieldsEnum {
  field_nitt_text = 'field_nitt_text',
  field_nitt_title = 'field_nitt_title',
  id = 'id',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  relationships___field_nitt_image___localFile___birthtime = 'relationships___field_nitt_image___localFile___birthtime',
  relationships___field_nitt_image___localFile___birthtimeMs = 'relationships___field_nitt_image___localFile___birthtimeMs',
  relationships___field_nitt_image___localFile___publicURL = 'relationships___field_nitt_image___localFile___publicURL',
  relationships___field_nitt_image___localFile___sourceInstanceName = 'relationships___field_nitt_image___localFile___sourceInstanceName',
  relationships___field_nitt_image___localFile___absolutePath = 'relationships___field_nitt_image___localFile___absolutePath',
  relationships___field_nitt_image___localFile___relativePath = 'relationships___field_nitt_image___localFile___relativePath',
  relationships___field_nitt_image___localFile___extension = 'relationships___field_nitt_image___localFile___extension',
  relationships___field_nitt_image___localFile___size = 'relationships___field_nitt_image___localFile___size',
  relationships___field_nitt_image___localFile___prettySize = 'relationships___field_nitt_image___localFile___prettySize',
  relationships___field_nitt_image___localFile___modifiedTime = 'relationships___field_nitt_image___localFile___modifiedTime',
  relationships___field_nitt_image___localFile___accessTime = 'relationships___field_nitt_image___localFile___accessTime',
  relationships___field_nitt_image___localFile___changeTime = 'relationships___field_nitt_image___localFile___changeTime',
  relationships___field_nitt_image___localFile___birthTime = 'relationships___field_nitt_image___localFile___birthTime',
  relationships___field_nitt_image___localFile___root = 'relationships___field_nitt_image___localFile___root',
  relationships___field_nitt_image___localFile___dir = 'relationships___field_nitt_image___localFile___dir',
  relationships___field_nitt_image___localFile___base = 'relationships___field_nitt_image___localFile___base',
  relationships___field_nitt_image___localFile___ext = 'relationships___field_nitt_image___localFile___ext',
  relationships___field_nitt_image___localFile___name = 'relationships___field_nitt_image___localFile___name',
  relationships___field_nitt_image___localFile___relativeDirectory = 'relationships___field_nitt_image___localFile___relativeDirectory',
  relationships___field_nitt_image___localFile___dev = 'relationships___field_nitt_image___localFile___dev',
  relationships___field_nitt_image___localFile___mode = 'relationships___field_nitt_image___localFile___mode',
  relationships___field_nitt_image___localFile___nlink = 'relationships___field_nitt_image___localFile___nlink',
  relationships___field_nitt_image___localFile___uid = 'relationships___field_nitt_image___localFile___uid',
  relationships___field_nitt_image___localFile___gid = 'relationships___field_nitt_image___localFile___gid',
  relationships___field_nitt_image___localFile___rdev = 'relationships___field_nitt_image___localFile___rdev',
  relationships___field_nitt_image___localFile___blksize = 'relationships___field_nitt_image___localFile___blksize',
  relationships___field_nitt_image___localFile___ino = 'relationships___field_nitt_image___localFile___ino',
  relationships___field_nitt_image___localFile___blocks = 'relationships___field_nitt_image___localFile___blocks',
  relationships___field_nitt_image___localFile___atimeMs = 'relationships___field_nitt_image___localFile___atimeMs',
  relationships___field_nitt_image___localFile___mtimeMs = 'relationships___field_nitt_image___localFile___mtimeMs',
  relationships___field_nitt_image___localFile___ctimeMs = 'relationships___field_nitt_image___localFile___ctimeMs',
  relationships___field_nitt_image___localFile___atime = 'relationships___field_nitt_image___localFile___atime',
  relationships___field_nitt_image___localFile___mtime = 'relationships___field_nitt_image___localFile___mtime',
  relationships___field_nitt_image___localFile___ctime = 'relationships___field_nitt_image___localFile___ctime',
  relationships___field_nitt_image___localFile___url = 'relationships___field_nitt_image___localFile___url',
  relationships___field_nitt_image___localFile___id = 'relationships___field_nitt_image___localFile___id',
  relationships___field_nitt_image___localFile___children = 'relationships___field_nitt_image___localFile___children',
  relationships___field_nitt_image___uri___url = 'relationships___field_nitt_image___uri___url',
  relationships___field_nitt_image___drupal_id = 'relationships___field_nitt_image___drupal_id',
  relationships___field_nitt_image___drupal_internal__fid = 'relationships___field_nitt_image___drupal_internal__fid',
  relationships___field_nitt_image___langcode = 'relationships___field_nitt_image___langcode',
  relationships___field_nitt_image___filename = 'relationships___field_nitt_image___filename',
  relationships___field_nitt_image___filemime = 'relationships___field_nitt_image___filemime',
  relationships___field_nitt_image___filesize = 'relationships___field_nitt_image___filesize',
  relationships___field_nitt_image___status = 'relationships___field_nitt_image___status',
  relationships___field_nitt_image___created = 'relationships___field_nitt_image___created',
  relationships___field_nitt_image___changed = 'relationships___field_nitt_image___changed',
  relationships___field_nitt_image___uuid = 'relationships___field_nitt_image___uuid',
  relationships___field_nitt_image___relationships___node__blog_post = 'relationships___field_nitt_image___relationships___node__blog_post',
  relationships___field_nitt_image___relationships___node__devices = 'relationships___field_nitt_image___relationships___node__devices',
  relationships___field_nitt_image___relationships___node__accessories = 'relationships___field_nitt_image___relationships___node__accessories',
  relationships___field_nitt_image___relationships___paragraph__news_image_title_text = 'relationships___field_nitt_image___relationships___paragraph__news_image_title_text',
  relationships___field_nitt_image___relationships___node__cases = 'relationships___field_nitt_image___relationships___node__cases',
  relationships___field_nitt_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_nitt_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_nitt_image___relationships___paragraph__branches_features = 'relationships___field_nitt_image___relationships___paragraph__branches_features',
  relationships___field_nitt_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_nitt_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_nitt_image___relationships___paragraph__branches_testimonials = 'relationships___field_nitt_image___relationships___paragraph__branches_testimonials',
  relationships___field_nitt_image___relationships___paragraph__branches_discounts = 'relationships___field_nitt_image___relationships___paragraph__branches_discounts',
  relationships___field_nitt_image___relationships___node__news = 'relationships___field_nitt_image___relationships___node__news',
  relationships___field_nitt_image___relationships___node__branches = 'relationships___field_nitt_image___relationships___node__branches',
  relationships___field_nitt_image___relationships___paragraph__branches_hero = 'relationships___field_nitt_image___relationships___paragraph__branches_hero',
  relationships___field_nitt_image___relationships___paragraph__bullets = 'relationships___field_nitt_image___relationships___paragraph__bullets',
  relationships___field_nitt_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_nitt_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_nitt_image___relationships___paragraph__branches_device = 'relationships___field_nitt_image___relationships___paragraph__branches_device',
  relationships___field_nitt_image___relationships___paragraph__branches_recommendation = 'relationships___field_nitt_image___relationships___paragraph__branches_recommendation',
  relationships___field_nitt_image___relationships___site_setting_entity__accessories = 'relationships___field_nitt_image___relationships___site_setting_entity__accessories',
  relationships___field_nitt_image___relationships___site_setting_entity__branches = 'relationships___field_nitt_image___relationships___site_setting_entity__branches',
  relationships___field_nitt_image___relationships___site_setting_entity__about_us = 'relationships___field_nitt_image___relationships___site_setting_entity__about_us',
  relationships___field_nitt_image___relationships___paragraph__logos = 'relationships___field_nitt_image___relationships___paragraph__logos',
  relationships___field_nitt_image___relationships___paragraph__how_it_works = 'relationships___field_nitt_image___relationships___paragraph__how_it_works',
  relationships___field_nitt_image___relationships___paragraph__ico_and_text = 'relationships___field_nitt_image___relationships___paragraph__ico_and_text',
  relationships___field_nitt_image___relationships___paragraph__hero_1 = 'relationships___field_nitt_image___relationships___paragraph__hero_1',
  relationships___field_nitt_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_nitt_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_nitt_image___relationships___site_setting_entity__product = 'relationships___field_nitt_image___relationships___site_setting_entity__product',
  relationships___field_nitt_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_nitt_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_nitt_image___relationships___paragraph__porownanie_terminali = 'relationships___field_nitt_image___relationships___paragraph__porownanie_terminali',
  relationships___field_nitt_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_nitt_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_nitt_image___relationships___site_setting_entity__main_page = 'relationships___field_nitt_image___relationships___site_setting_entity__main_page',
  relationships___field_nitt_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_nitt_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_nitt_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_nitt_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_nitt_image___relationships___site_setting_entity__intercars = 'relationships___field_nitt_image___relationships___site_setting_entity__intercars',
  relationships___field_nitt_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_nitt_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_nitt_image___relationships___node__agreement = 'relationships___field_nitt_image___relationships___node__agreement',
  relationships___field_nitt_image___relationships___node__fiscalization = 'relationships___field_nitt_image___relationships___node__fiscalization',
  relationships___field_nitt_image___relationships___site_setting_entity__configurator = 'relationships___field_nitt_image___relationships___site_setting_entity__configurator',
  relationships___field_nitt_image___relationships___paragraph__social_link = 'relationships___field_nitt_image___relationships___paragraph__social_link',
  relationships___field_nitt_image___relationships___paragraph__make_an_appointment = 'relationships___field_nitt_image___relationships___paragraph__make_an_appointment',
  relationships___field_nitt_image___relationships___paragraph__linked_icons = 'relationships___field_nitt_image___relationships___paragraph__linked_icons',
  relationships___field_nitt_image___relationships___site_setting_entity__financing = 'relationships___field_nitt_image___relationships___site_setting_entity__financing',
  relationships___field_nitt_image___relationships___site_setting_entity__franchise = 'relationships___field_nitt_image___relationships___site_setting_entity__franchise',
  relationships___field_nitt_image___id = 'relationships___field_nitt_image___id',
  relationships___field_nitt_image___parent___id = 'relationships___field_nitt_image___parent___id',
  relationships___field_nitt_image___parent___children = 'relationships___field_nitt_image___parent___children',
  relationships___field_nitt_image___children = 'relationships___field_nitt_image___children',
  relationships___field_nitt_image___children___id = 'relationships___field_nitt_image___children___id',
  relationships___field_nitt_image___children___children = 'relationships___field_nitt_image___children___children',
  relationships___field_nitt_image___internal___content = 'relationships___field_nitt_image___internal___content',
  relationships___field_nitt_image___internal___contentDigest = 'relationships___field_nitt_image___internal___contentDigest',
  relationships___field_nitt_image___internal___description = 'relationships___field_nitt_image___internal___description',
  relationships___field_nitt_image___internal___fieldOwners = 'relationships___field_nitt_image___internal___fieldOwners',
  relationships___field_nitt_image___internal___ignoreType = 'relationships___field_nitt_image___internal___ignoreType',
  relationships___field_nitt_image___internal___mediaType = 'relationships___field_nitt_image___internal___mediaType',
  relationships___field_nitt_image___internal___owner = 'relationships___field_nitt_image___internal___owner',
  relationships___field_nitt_image___internal___type = 'relationships___field_nitt_image___internal___type',
  relationships___node__news = 'relationships___node__news',
  relationships___node__news___langcode = 'relationships___node__news___langcode',
  relationships___node__news___id = 'relationships___node__news___id',
  relationships___node__news___drupal_internal__nid = 'relationships___node__news___drupal_internal__nid',
  relationships___node__news___title = 'relationships___node__news___title',
  relationships___node__news___field_news_reading_time = 'relationships___node__news___field_news_reading_time',
  relationships___node__news___field_news_body = 'relationships___node__news___field_news_body',
  relationships___node__news___created = 'relationships___node__news___created',
  relationships___node__news___path___alias = 'relationships___node__news___path___alias',
  relationships___node__news___status = 'relationships___node__news___status',
  relationships___node__news___field_meta_description = 'relationships___node__news___field_meta_description',
  relationships___node__news___field_meta_title = 'relationships___node__news___field_meta_title',
  relationships___node__news___relationships___field_news_tags = 'relationships___node__news___relationships___field_news_tags',
  relationships___node__news___relationships___node__news = 'relationships___node__news___relationships___node__news',
  relationships___node__news___relationships___paragraph__recommended_blog_posts = 'relationships___node__news___relationships___paragraph__recommended_blog_posts',
  relationships___node__news___drupal_id = 'relationships___node__news___drupal_id',
  relationships___node__news___rh_redirect_fallback_action = 'relationships___node__news___rh_redirect_fallback_action',
  relationships___node__news___content_translation_source = 'relationships___node__news___content_translation_source',
  relationships___node__news___content_translation_outdated = 'relationships___node__news___content_translation_outdated',
  relationships___node__news___field_meta_keywords = 'relationships___node__news___field_meta_keywords',
  relationships___node__news___uuid = 'relationships___node__news___uuid',
  relationships___node__news___field_news_categories___drupal_internal__target_id = 'relationships___node__news___field_news_categories___drupal_internal__target_id',
  relationships___node__news___field_news_components = 'relationships___node__news___field_news_components',
  relationships___node__news___field_news_components___target_revision_id = 'relationships___node__news___field_news_components___target_revision_id',
  relationships___node__news___field_news_components___drupal_internal__target_id = 'relationships___node__news___field_news_components___drupal_internal__target_id',
  relationships___node__news___field_news_image___alt = 'relationships___node__news___field_news_image___alt',
  relationships___node__news___field_news_image___title = 'relationships___node__news___field_news_image___title',
  relationships___node__news___field_news_image___width = 'relationships___node__news___field_news_image___width',
  relationships___node__news___field_news_image___height = 'relationships___node__news___field_news_image___height',
  relationships___node__news___field_news_image___drupal_internal__target_id = 'relationships___node__news___field_news_image___drupal_internal__target_id',
  relationships___node__news___field_news_tags = 'relationships___node__news___field_news_tags',
  relationships___node__news___field_news_tags___drupal_internal__target_id = 'relationships___node__news___field_news_tags___drupal_internal__target_id',
  relationships___node__news___field_related_news_1___drupal_internal__target_id = 'relationships___node__news___field_related_news_1___drupal_internal__target_id',
  relationships___node__news___field_related_news_2___drupal_internal__target_id = 'relationships___node__news___field_related_news_2___drupal_internal__target_id',
  relationships___node__news___field_related_news_3___drupal_internal__target_id = 'relationships___node__news___field_related_news_3___drupal_internal__target_id',
  relationships___node__news___parent___id = 'relationships___node__news___parent___id',
  relationships___node__news___parent___children = 'relationships___node__news___parent___children',
  relationships___node__news___children = 'relationships___node__news___children',
  relationships___node__news___children___id = 'relationships___node__news___children___id',
  relationships___node__news___children___children = 'relationships___node__news___children___children',
  relationships___node__news___internal___content = 'relationships___node__news___internal___content',
  relationships___node__news___internal___contentDigest = 'relationships___node__news___internal___contentDigest',
  relationships___node__news___internal___description = 'relationships___node__news___internal___description',
  relationships___node__news___internal___fieldOwners = 'relationships___node__news___internal___fieldOwners',
  relationships___node__news___internal___ignoreType = 'relationships___node__news___internal___ignoreType',
  relationships___node__news___internal___mediaType = 'relationships___node__news___internal___mediaType',
  relationships___node__news___internal___owner = 'relationships___node__news___internal___owner',
  relationships___node__news___internal___type = 'relationships___node__news___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_nitt_image___alt = 'field_nitt_image___alt',
  field_nitt_image___title = 'field_nitt_image___title',
  field_nitt_image___width = 'field_nitt_image___width',
  field_nitt_image___height = 'field_nitt_image___height',
  field_nitt_image___drupal_internal__target_id = 'field_nitt_image___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__News_Image_Title_TextFilterInput = {
  field_nitt_text?: Maybe<StringQueryOperatorInput>;
  field_nitt_title?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  relationships?: Maybe<Paragraph__News_Image_Title_TextRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_nitt_image?: Maybe<Paragraph__News_Image_Title_TextField_Nitt_ImageFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__News_Image_Title_TextFilterListInput = {
  elemMatch?: Maybe<Paragraph__News_Image_Title_TextFilterInput>;
};

export type Paragraph__News_Image_Title_TextGroupConnection = {
  __typename?: 'paragraph__news_image_title_textGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__News_Image_Title_TextEdge>;
  nodes: Array<Paragraph__News_Image_Title_Text>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__News_Image_Title_TextRelationships = {
  __typename?: 'paragraph__news_image_title_textRelationships';
  field_nitt_image?: Maybe<File__File>;
  node__news?: Maybe<Array<Maybe<Node__News>>>;
};

export type Paragraph__News_Image_Title_TextRelationshipsFilterInput = {
  field_nitt_image?: Maybe<File__FileFilterInput>;
  node__news?: Maybe<Node__NewsFilterListInput>;
};

export type Paragraph__News_Image_Title_TextSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__News_Image_Title_TextFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__News_Quotation = Node & {
  __typename?: 'paragraph__news_quotation';
  id: Scalars['ID'];
  field_nq_quotation?: Maybe<Scalars['String']>;
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__News_QuotationRelationships>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__News_QuotationContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__News_QuotationConnection = {
  __typename?: 'paragraph__news_quotationConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__News_QuotationEdge>;
  nodes: Array<Paragraph__News_Quotation>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__News_QuotationGroupConnection>;
};

export type Paragraph__News_QuotationConnectionDistinctArgs = {
  field: Paragraph__News_QuotationFieldsEnum;
};

export type Paragraph__News_QuotationConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__News_QuotationFieldsEnum;
};

export type Paragraph__News_QuotationEdge = {
  __typename?: 'paragraph__news_quotationEdge';
  next?: Maybe<Paragraph__News_Quotation>;
  node: Paragraph__News_Quotation;
  previous?: Maybe<Paragraph__News_Quotation>;
};

export enum Paragraph__News_QuotationFieldsEnum {
  id = 'id',
  field_nq_quotation = 'field_nq_quotation',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  relationships___node__news = 'relationships___node__news',
  relationships___node__news___langcode = 'relationships___node__news___langcode',
  relationships___node__news___id = 'relationships___node__news___id',
  relationships___node__news___drupal_internal__nid = 'relationships___node__news___drupal_internal__nid',
  relationships___node__news___title = 'relationships___node__news___title',
  relationships___node__news___field_news_reading_time = 'relationships___node__news___field_news_reading_time',
  relationships___node__news___field_news_body = 'relationships___node__news___field_news_body',
  relationships___node__news___created = 'relationships___node__news___created',
  relationships___node__news___path___alias = 'relationships___node__news___path___alias',
  relationships___node__news___status = 'relationships___node__news___status',
  relationships___node__news___field_meta_description = 'relationships___node__news___field_meta_description',
  relationships___node__news___field_meta_title = 'relationships___node__news___field_meta_title',
  relationships___node__news___relationships___field_news_tags = 'relationships___node__news___relationships___field_news_tags',
  relationships___node__news___relationships___node__news = 'relationships___node__news___relationships___node__news',
  relationships___node__news___relationships___paragraph__recommended_blog_posts = 'relationships___node__news___relationships___paragraph__recommended_blog_posts',
  relationships___node__news___drupal_id = 'relationships___node__news___drupal_id',
  relationships___node__news___rh_redirect_fallback_action = 'relationships___node__news___rh_redirect_fallback_action',
  relationships___node__news___content_translation_source = 'relationships___node__news___content_translation_source',
  relationships___node__news___content_translation_outdated = 'relationships___node__news___content_translation_outdated',
  relationships___node__news___field_meta_keywords = 'relationships___node__news___field_meta_keywords',
  relationships___node__news___uuid = 'relationships___node__news___uuid',
  relationships___node__news___field_news_categories___drupal_internal__target_id = 'relationships___node__news___field_news_categories___drupal_internal__target_id',
  relationships___node__news___field_news_components = 'relationships___node__news___field_news_components',
  relationships___node__news___field_news_components___target_revision_id = 'relationships___node__news___field_news_components___target_revision_id',
  relationships___node__news___field_news_components___drupal_internal__target_id = 'relationships___node__news___field_news_components___drupal_internal__target_id',
  relationships___node__news___field_news_image___alt = 'relationships___node__news___field_news_image___alt',
  relationships___node__news___field_news_image___title = 'relationships___node__news___field_news_image___title',
  relationships___node__news___field_news_image___width = 'relationships___node__news___field_news_image___width',
  relationships___node__news___field_news_image___height = 'relationships___node__news___field_news_image___height',
  relationships___node__news___field_news_image___drupal_internal__target_id = 'relationships___node__news___field_news_image___drupal_internal__target_id',
  relationships___node__news___field_news_tags = 'relationships___node__news___field_news_tags',
  relationships___node__news___field_news_tags___drupal_internal__target_id = 'relationships___node__news___field_news_tags___drupal_internal__target_id',
  relationships___node__news___field_related_news_1___drupal_internal__target_id = 'relationships___node__news___field_related_news_1___drupal_internal__target_id',
  relationships___node__news___field_related_news_2___drupal_internal__target_id = 'relationships___node__news___field_related_news_2___drupal_internal__target_id',
  relationships___node__news___field_related_news_3___drupal_internal__target_id = 'relationships___node__news___field_related_news_3___drupal_internal__target_id',
  relationships___node__news___parent___id = 'relationships___node__news___parent___id',
  relationships___node__news___parent___children = 'relationships___node__news___parent___children',
  relationships___node__news___children = 'relationships___node__news___children',
  relationships___node__news___children___id = 'relationships___node__news___children___id',
  relationships___node__news___children___children = 'relationships___node__news___children___children',
  relationships___node__news___internal___content = 'relationships___node__news___internal___content',
  relationships___node__news___internal___contentDigest = 'relationships___node__news___internal___contentDigest',
  relationships___node__news___internal___description = 'relationships___node__news___internal___description',
  relationships___node__news___internal___fieldOwners = 'relationships___node__news___internal___fieldOwners',
  relationships___node__news___internal___ignoreType = 'relationships___node__news___internal___ignoreType',
  relationships___node__news___internal___mediaType = 'relationships___node__news___internal___mediaType',
  relationships___node__news___internal___owner = 'relationships___node__news___internal___owner',
  relationships___node__news___internal___type = 'relationships___node__news___internal___type',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__News_QuotationFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  field_nq_quotation?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__News_QuotationRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__News_QuotationGroupConnection = {
  __typename?: 'paragraph__news_quotationGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__News_QuotationEdge>;
  nodes: Array<Paragraph__News_Quotation>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__News_QuotationRelationships = {
  __typename?: 'paragraph__news_quotationRelationships';
  node__news?: Maybe<Array<Maybe<Node__News>>>;
};

export type Paragraph__News_QuotationRelationshipsFilterInput = {
  node__news?: Maybe<Node__NewsFilterListInput>;
};

export type Paragraph__News_QuotationSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__News_QuotationFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__News_Text = Node & {
  __typename?: 'paragraph__news_text';
  id: Scalars['ID'];
  field_nt_text?: Maybe<Scalars['String']>;
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__News_TextRelationships>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__News_TextContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__News_TextConnection = {
  __typename?: 'paragraph__news_textConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__News_TextEdge>;
  nodes: Array<Paragraph__News_Text>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__News_TextGroupConnection>;
};

export type Paragraph__News_TextConnectionDistinctArgs = {
  field: Paragraph__News_TextFieldsEnum;
};

export type Paragraph__News_TextConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__News_TextFieldsEnum;
};

export type Paragraph__News_TextEdge = {
  __typename?: 'paragraph__news_textEdge';
  next?: Maybe<Paragraph__News_Text>;
  node: Paragraph__News_Text;
  previous?: Maybe<Paragraph__News_Text>;
};

export enum Paragraph__News_TextFieldsEnum {
  id = 'id',
  field_nt_text = 'field_nt_text',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  relationships___node__news = 'relationships___node__news',
  relationships___node__news___langcode = 'relationships___node__news___langcode',
  relationships___node__news___id = 'relationships___node__news___id',
  relationships___node__news___drupal_internal__nid = 'relationships___node__news___drupal_internal__nid',
  relationships___node__news___title = 'relationships___node__news___title',
  relationships___node__news___field_news_reading_time = 'relationships___node__news___field_news_reading_time',
  relationships___node__news___field_news_body = 'relationships___node__news___field_news_body',
  relationships___node__news___created = 'relationships___node__news___created',
  relationships___node__news___path___alias = 'relationships___node__news___path___alias',
  relationships___node__news___status = 'relationships___node__news___status',
  relationships___node__news___field_meta_description = 'relationships___node__news___field_meta_description',
  relationships___node__news___field_meta_title = 'relationships___node__news___field_meta_title',
  relationships___node__news___relationships___field_news_tags = 'relationships___node__news___relationships___field_news_tags',
  relationships___node__news___relationships___node__news = 'relationships___node__news___relationships___node__news',
  relationships___node__news___relationships___paragraph__recommended_blog_posts = 'relationships___node__news___relationships___paragraph__recommended_blog_posts',
  relationships___node__news___drupal_id = 'relationships___node__news___drupal_id',
  relationships___node__news___rh_redirect_fallback_action = 'relationships___node__news___rh_redirect_fallback_action',
  relationships___node__news___content_translation_source = 'relationships___node__news___content_translation_source',
  relationships___node__news___content_translation_outdated = 'relationships___node__news___content_translation_outdated',
  relationships___node__news___field_meta_keywords = 'relationships___node__news___field_meta_keywords',
  relationships___node__news___uuid = 'relationships___node__news___uuid',
  relationships___node__news___field_news_categories___drupal_internal__target_id = 'relationships___node__news___field_news_categories___drupal_internal__target_id',
  relationships___node__news___field_news_components = 'relationships___node__news___field_news_components',
  relationships___node__news___field_news_components___target_revision_id = 'relationships___node__news___field_news_components___target_revision_id',
  relationships___node__news___field_news_components___drupal_internal__target_id = 'relationships___node__news___field_news_components___drupal_internal__target_id',
  relationships___node__news___field_news_image___alt = 'relationships___node__news___field_news_image___alt',
  relationships___node__news___field_news_image___title = 'relationships___node__news___field_news_image___title',
  relationships___node__news___field_news_image___width = 'relationships___node__news___field_news_image___width',
  relationships___node__news___field_news_image___height = 'relationships___node__news___field_news_image___height',
  relationships___node__news___field_news_image___drupal_internal__target_id = 'relationships___node__news___field_news_image___drupal_internal__target_id',
  relationships___node__news___field_news_tags = 'relationships___node__news___field_news_tags',
  relationships___node__news___field_news_tags___drupal_internal__target_id = 'relationships___node__news___field_news_tags___drupal_internal__target_id',
  relationships___node__news___field_related_news_1___drupal_internal__target_id = 'relationships___node__news___field_related_news_1___drupal_internal__target_id',
  relationships___node__news___field_related_news_2___drupal_internal__target_id = 'relationships___node__news___field_related_news_2___drupal_internal__target_id',
  relationships___node__news___field_related_news_3___drupal_internal__target_id = 'relationships___node__news___field_related_news_3___drupal_internal__target_id',
  relationships___node__news___parent___id = 'relationships___node__news___parent___id',
  relationships___node__news___parent___children = 'relationships___node__news___parent___children',
  relationships___node__news___children = 'relationships___node__news___children',
  relationships___node__news___children___id = 'relationships___node__news___children___id',
  relationships___node__news___children___children = 'relationships___node__news___children___children',
  relationships___node__news___internal___content = 'relationships___node__news___internal___content',
  relationships___node__news___internal___contentDigest = 'relationships___node__news___internal___contentDigest',
  relationships___node__news___internal___description = 'relationships___node__news___internal___description',
  relationships___node__news___internal___fieldOwners = 'relationships___node__news___internal___fieldOwners',
  relationships___node__news___internal___ignoreType = 'relationships___node__news___internal___ignoreType',
  relationships___node__news___internal___mediaType = 'relationships___node__news___internal___mediaType',
  relationships___node__news___internal___owner = 'relationships___node__news___internal___owner',
  relationships___node__news___internal___type = 'relationships___node__news___internal___type',
  relationships___site_setting_entity__financing = 'relationships___site_setting_entity__financing',
  relationships___site_setting_entity__financing___langcode = 'relationships___site_setting_entity__financing___langcode',
  relationships___site_setting_entity__financing___field_meta_description = 'relationships___site_setting_entity__financing___field_meta_description',
  relationships___site_setting_entity__financing___field_meta_title = 'relationships___site_setting_entity__financing___field_meta_title',
  relationships___site_setting_entity__financing___drupal_id = 'relationships___site_setting_entity__financing___drupal_id',
  relationships___site_setting_entity__financing___drupal_internal__id = 'relationships___site_setting_entity__financing___drupal_internal__id',
  relationships___site_setting_entity__financing___name = 'relationships___site_setting_entity__financing___name',
  relationships___site_setting_entity__financing___fieldset = 'relationships___site_setting_entity__financing___fieldset',
  relationships___site_setting_entity__financing___status = 'relationships___site_setting_entity__financing___status',
  relationships___site_setting_entity__financing___created = 'relationships___site_setting_entity__financing___created',
  relationships___site_setting_entity__financing___changed = 'relationships___site_setting_entity__financing___changed',
  relationships___site_setting_entity__financing___default_langcode = 'relationships___site_setting_entity__financing___default_langcode',
  relationships___site_setting_entity__financing___content_translation_source = 'relationships___site_setting_entity__financing___content_translation_source',
  relationships___site_setting_entity__financing___content_translation_outdated = 'relationships___site_setting_entity__financing___content_translation_outdated',
  relationships___site_setting_entity__financing___content_translation_status = 'relationships___site_setting_entity__financing___content_translation_status',
  relationships___site_setting_entity__financing___content_translation_created = 'relationships___site_setting_entity__financing___content_translation_created',
  relationships___site_setting_entity__financing___content_translation_changed = 'relationships___site_setting_entity__financing___content_translation_changed',
  relationships___site_setting_entity__financing___uuid = 'relationships___site_setting_entity__financing___uuid',
  relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__financing___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_components = 'relationships___site_setting_entity__financing___field_components',
  relationships___site_setting_entity__financing___field_components___target_revision_id = 'relationships___site_setting_entity__financing___field_components___target_revision_id',
  relationships___site_setting_entity__financing___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__financing___field_main_image___alt = 'relationships___site_setting_entity__financing___field_main_image___alt',
  relationships___site_setting_entity__financing___field_main_image___title = 'relationships___site_setting_entity__financing___field_main_image___title',
  relationships___site_setting_entity__financing___field_main_image___width = 'relationships___site_setting_entity__financing___field_main_image___width',
  relationships___site_setting_entity__financing___field_main_image___height = 'relationships___site_setting_entity__financing___field_main_image___height',
  relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__financing___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__financing___id = 'relationships___site_setting_entity__financing___id',
  relationships___site_setting_entity__financing___parent___id = 'relationships___site_setting_entity__financing___parent___id',
  relationships___site_setting_entity__financing___parent___children = 'relationships___site_setting_entity__financing___parent___children',
  relationships___site_setting_entity__financing___children = 'relationships___site_setting_entity__financing___children',
  relationships___site_setting_entity__financing___children___id = 'relationships___site_setting_entity__financing___children___id',
  relationships___site_setting_entity__financing___children___children = 'relationships___site_setting_entity__financing___children___children',
  relationships___site_setting_entity__financing___internal___content = 'relationships___site_setting_entity__financing___internal___content',
  relationships___site_setting_entity__financing___internal___contentDigest = 'relationships___site_setting_entity__financing___internal___contentDigest',
  relationships___site_setting_entity__financing___internal___description = 'relationships___site_setting_entity__financing___internal___description',
  relationships___site_setting_entity__financing___internal___fieldOwners = 'relationships___site_setting_entity__financing___internal___fieldOwners',
  relationships___site_setting_entity__financing___internal___ignoreType = 'relationships___site_setting_entity__financing___internal___ignoreType',
  relationships___site_setting_entity__financing___internal___mediaType = 'relationships___site_setting_entity__financing___internal___mediaType',
  relationships___site_setting_entity__financing___internal___owner = 'relationships___site_setting_entity__financing___internal___owner',
  relationships___site_setting_entity__financing___internal___type = 'relationships___site_setting_entity__financing___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__News_TextFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  field_nt_text?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__News_TextRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__News_TextGroupConnection = {
  __typename?: 'paragraph__news_textGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__News_TextEdge>;
  nodes: Array<Paragraph__News_Text>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__News_TextRelationships = {
  __typename?: 'paragraph__news_textRelationships';
  node__news?: Maybe<Array<Maybe<Node__News>>>;
  site_setting_entity__financing?: Maybe<Array<Maybe<Site_Setting_Entity__Financing>>>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
};

export type Paragraph__News_TextRelationshipsFilterInput = {
  node__news?: Maybe<Node__NewsFilterListInput>;
  site_setting_entity__financing?: Maybe<Site_Setting_Entity__FinancingFilterListInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
};

export type Paragraph__News_TextSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__News_TextFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Porownanie_Terminali = Node & {
  __typename?: 'paragraph__porownanie_terminali';
  field_description?: Maybe<Field_Description>;
  field_image?: Maybe<Field_Image>;
  field_image_1?: Maybe<Field_Image_1>;
  field_kup_teraz?: Maybe<Scalars['String']>;
  field_label_1?: Maybe<Scalars['String']>;
  field_label_2?: Maybe<Scalars['String']>;
  field_label_3?: Maybe<Scalars['String']>;
  field_label_4?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_text_1?: Maybe<Field_Text_1>;
  field_text_2?: Maybe<Field_Text_2>;
  field_text_3?: Maybe<Scalars['String']>;
  field_text_4?: Maybe<Field_Text_4>;
  field_zobacz_szczegoly?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Porownanie_TerminaliRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Porownanie_TerminaliCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Porownanie_TerminaliConnection = {
  __typename?: 'paragraph__porownanie_terminaliConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Porownanie_TerminaliEdge>;
  nodes: Array<Paragraph__Porownanie_Terminali>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Porownanie_TerminaliGroupConnection>;
};

export type Paragraph__Porownanie_TerminaliConnectionDistinctArgs = {
  field: Paragraph__Porownanie_TerminaliFieldsEnum;
};

export type Paragraph__Porownanie_TerminaliConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Porownanie_TerminaliFieldsEnum;
};

export type Paragraph__Porownanie_TerminaliEdge = {
  __typename?: 'paragraph__porownanie_terminaliEdge';
  next?: Maybe<Paragraph__Porownanie_Terminali>;
  node: Paragraph__Porownanie_Terminali;
  previous?: Maybe<Paragraph__Porownanie_Terminali>;
};

export enum Paragraph__Porownanie_TerminaliFieldsEnum {
  field_description___value = 'field_description___value',
  field_image___alt = 'field_image___alt',
  field_image_1___alt = 'field_image_1___alt',
  field_kup_teraz = 'field_kup_teraz',
  field_label_1 = 'field_label_1',
  field_label_2 = 'field_label_2',
  field_label_3 = 'field_label_3',
  field_label_4 = 'field_label_4',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_text_1___value = 'field_text_1___value',
  field_text_2___value = 'field_text_2___value',
  field_text_3 = 'field_text_3',
  field_text_4___value = 'field_text_4___value',
  field_zobacz_szczegoly = 'field_zobacz_szczegoly',
  relationships___field_image___localFile___birthtime = 'relationships___field_image___localFile___birthtime',
  relationships___field_image___localFile___birthtimeMs = 'relationships___field_image___localFile___birthtimeMs',
  relationships___field_image___localFile___publicURL = 'relationships___field_image___localFile___publicURL',
  relationships___field_image___localFile___sourceInstanceName = 'relationships___field_image___localFile___sourceInstanceName',
  relationships___field_image___localFile___absolutePath = 'relationships___field_image___localFile___absolutePath',
  relationships___field_image___localFile___relativePath = 'relationships___field_image___localFile___relativePath',
  relationships___field_image___localFile___extension = 'relationships___field_image___localFile___extension',
  relationships___field_image___localFile___size = 'relationships___field_image___localFile___size',
  relationships___field_image___localFile___prettySize = 'relationships___field_image___localFile___prettySize',
  relationships___field_image___localFile___modifiedTime = 'relationships___field_image___localFile___modifiedTime',
  relationships___field_image___localFile___accessTime = 'relationships___field_image___localFile___accessTime',
  relationships___field_image___localFile___changeTime = 'relationships___field_image___localFile___changeTime',
  relationships___field_image___localFile___birthTime = 'relationships___field_image___localFile___birthTime',
  relationships___field_image___localFile___root = 'relationships___field_image___localFile___root',
  relationships___field_image___localFile___dir = 'relationships___field_image___localFile___dir',
  relationships___field_image___localFile___base = 'relationships___field_image___localFile___base',
  relationships___field_image___localFile___ext = 'relationships___field_image___localFile___ext',
  relationships___field_image___localFile___name = 'relationships___field_image___localFile___name',
  relationships___field_image___localFile___relativeDirectory = 'relationships___field_image___localFile___relativeDirectory',
  relationships___field_image___localFile___dev = 'relationships___field_image___localFile___dev',
  relationships___field_image___localFile___mode = 'relationships___field_image___localFile___mode',
  relationships___field_image___localFile___nlink = 'relationships___field_image___localFile___nlink',
  relationships___field_image___localFile___uid = 'relationships___field_image___localFile___uid',
  relationships___field_image___localFile___gid = 'relationships___field_image___localFile___gid',
  relationships___field_image___localFile___rdev = 'relationships___field_image___localFile___rdev',
  relationships___field_image___localFile___blksize = 'relationships___field_image___localFile___blksize',
  relationships___field_image___localFile___ino = 'relationships___field_image___localFile___ino',
  relationships___field_image___localFile___blocks = 'relationships___field_image___localFile___blocks',
  relationships___field_image___localFile___atimeMs = 'relationships___field_image___localFile___atimeMs',
  relationships___field_image___localFile___mtimeMs = 'relationships___field_image___localFile___mtimeMs',
  relationships___field_image___localFile___ctimeMs = 'relationships___field_image___localFile___ctimeMs',
  relationships___field_image___localFile___atime = 'relationships___field_image___localFile___atime',
  relationships___field_image___localFile___mtime = 'relationships___field_image___localFile___mtime',
  relationships___field_image___localFile___ctime = 'relationships___field_image___localFile___ctime',
  relationships___field_image___localFile___url = 'relationships___field_image___localFile___url',
  relationships___field_image___localFile___id = 'relationships___field_image___localFile___id',
  relationships___field_image___localFile___children = 'relationships___field_image___localFile___children',
  relationships___field_image___uri___url = 'relationships___field_image___uri___url',
  relationships___field_image___drupal_id = 'relationships___field_image___drupal_id',
  relationships___field_image___drupal_internal__fid = 'relationships___field_image___drupal_internal__fid',
  relationships___field_image___langcode = 'relationships___field_image___langcode',
  relationships___field_image___filename = 'relationships___field_image___filename',
  relationships___field_image___filemime = 'relationships___field_image___filemime',
  relationships___field_image___filesize = 'relationships___field_image___filesize',
  relationships___field_image___status = 'relationships___field_image___status',
  relationships___field_image___created = 'relationships___field_image___created',
  relationships___field_image___changed = 'relationships___field_image___changed',
  relationships___field_image___uuid = 'relationships___field_image___uuid',
  relationships___field_image___relationships___node__blog_post = 'relationships___field_image___relationships___node__blog_post',
  relationships___field_image___relationships___node__devices = 'relationships___field_image___relationships___node__devices',
  relationships___field_image___relationships___node__accessories = 'relationships___field_image___relationships___node__accessories',
  relationships___field_image___relationships___paragraph__news_image_title_text = 'relationships___field_image___relationships___paragraph__news_image_title_text',
  relationships___field_image___relationships___node__cases = 'relationships___field_image___relationships___node__cases',
  relationships___field_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image___relationships___paragraph__branches_features = 'relationships___field_image___relationships___paragraph__branches_features',
  relationships___field_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image___relationships___paragraph__branches_testimonials = 'relationships___field_image___relationships___paragraph__branches_testimonials',
  relationships___field_image___relationships___paragraph__branches_discounts = 'relationships___field_image___relationships___paragraph__branches_discounts',
  relationships___field_image___relationships___node__news = 'relationships___field_image___relationships___node__news',
  relationships___field_image___relationships___node__branches = 'relationships___field_image___relationships___node__branches',
  relationships___field_image___relationships___paragraph__branches_hero = 'relationships___field_image___relationships___paragraph__branches_hero',
  relationships___field_image___relationships___paragraph__bullets = 'relationships___field_image___relationships___paragraph__bullets',
  relationships___field_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_image___relationships___paragraph__branches_device = 'relationships___field_image___relationships___paragraph__branches_device',
  relationships___field_image___relationships___paragraph__branches_recommendation = 'relationships___field_image___relationships___paragraph__branches_recommendation',
  relationships___field_image___relationships___site_setting_entity__accessories = 'relationships___field_image___relationships___site_setting_entity__accessories',
  relationships___field_image___relationships___site_setting_entity__branches = 'relationships___field_image___relationships___site_setting_entity__branches',
  relationships___field_image___relationships___site_setting_entity__about_us = 'relationships___field_image___relationships___site_setting_entity__about_us',
  relationships___field_image___relationships___paragraph__logos = 'relationships___field_image___relationships___paragraph__logos',
  relationships___field_image___relationships___paragraph__how_it_works = 'relationships___field_image___relationships___paragraph__how_it_works',
  relationships___field_image___relationships___paragraph__ico_and_text = 'relationships___field_image___relationships___paragraph__ico_and_text',
  relationships___field_image___relationships___paragraph__hero_1 = 'relationships___field_image___relationships___paragraph__hero_1',
  relationships___field_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image___relationships___site_setting_entity__product = 'relationships___field_image___relationships___site_setting_entity__product',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image___relationships___paragraph__porownanie_terminali = 'relationships___field_image___relationships___paragraph__porownanie_terminali',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image___relationships___site_setting_entity__main_page = 'relationships___field_image___relationships___site_setting_entity__main_page',
  relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image___relationships___site_setting_entity__intercars = 'relationships___field_image___relationships___site_setting_entity__intercars',
  relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image___relationships___node__agreement = 'relationships___field_image___relationships___node__agreement',
  relationships___field_image___relationships___node__fiscalization = 'relationships___field_image___relationships___node__fiscalization',
  relationships___field_image___relationships___site_setting_entity__configurator = 'relationships___field_image___relationships___site_setting_entity__configurator',
  relationships___field_image___relationships___paragraph__social_link = 'relationships___field_image___relationships___paragraph__social_link',
  relationships___field_image___relationships___paragraph__make_an_appointment = 'relationships___field_image___relationships___paragraph__make_an_appointment',
  relationships___field_image___relationships___paragraph__linked_icons = 'relationships___field_image___relationships___paragraph__linked_icons',
  relationships___field_image___relationships___site_setting_entity__financing = 'relationships___field_image___relationships___site_setting_entity__financing',
  relationships___field_image___relationships___site_setting_entity__franchise = 'relationships___field_image___relationships___site_setting_entity__franchise',
  relationships___field_image___id = 'relationships___field_image___id',
  relationships___field_image___parent___id = 'relationships___field_image___parent___id',
  relationships___field_image___parent___children = 'relationships___field_image___parent___children',
  relationships___field_image___children = 'relationships___field_image___children',
  relationships___field_image___children___id = 'relationships___field_image___children___id',
  relationships___field_image___children___children = 'relationships___field_image___children___children',
  relationships___field_image___internal___content = 'relationships___field_image___internal___content',
  relationships___field_image___internal___contentDigest = 'relationships___field_image___internal___contentDigest',
  relationships___field_image___internal___description = 'relationships___field_image___internal___description',
  relationships___field_image___internal___fieldOwners = 'relationships___field_image___internal___fieldOwners',
  relationships___field_image___internal___ignoreType = 'relationships___field_image___internal___ignoreType',
  relationships___field_image___internal___mediaType = 'relationships___field_image___internal___mediaType',
  relationships___field_image___internal___owner = 'relationships___field_image___internal___owner',
  relationships___field_image___internal___type = 'relationships___field_image___internal___type',
  relationships___field_image_1___localFile___birthtime = 'relationships___field_image_1___localFile___birthtime',
  relationships___field_image_1___localFile___birthtimeMs = 'relationships___field_image_1___localFile___birthtimeMs',
  relationships___field_image_1___localFile___publicURL = 'relationships___field_image_1___localFile___publicURL',
  relationships___field_image_1___localFile___sourceInstanceName = 'relationships___field_image_1___localFile___sourceInstanceName',
  relationships___field_image_1___localFile___absolutePath = 'relationships___field_image_1___localFile___absolutePath',
  relationships___field_image_1___localFile___relativePath = 'relationships___field_image_1___localFile___relativePath',
  relationships___field_image_1___localFile___extension = 'relationships___field_image_1___localFile___extension',
  relationships___field_image_1___localFile___size = 'relationships___field_image_1___localFile___size',
  relationships___field_image_1___localFile___prettySize = 'relationships___field_image_1___localFile___prettySize',
  relationships___field_image_1___localFile___modifiedTime = 'relationships___field_image_1___localFile___modifiedTime',
  relationships___field_image_1___localFile___accessTime = 'relationships___field_image_1___localFile___accessTime',
  relationships___field_image_1___localFile___changeTime = 'relationships___field_image_1___localFile___changeTime',
  relationships___field_image_1___localFile___birthTime = 'relationships___field_image_1___localFile___birthTime',
  relationships___field_image_1___localFile___root = 'relationships___field_image_1___localFile___root',
  relationships___field_image_1___localFile___dir = 'relationships___field_image_1___localFile___dir',
  relationships___field_image_1___localFile___base = 'relationships___field_image_1___localFile___base',
  relationships___field_image_1___localFile___ext = 'relationships___field_image_1___localFile___ext',
  relationships___field_image_1___localFile___name = 'relationships___field_image_1___localFile___name',
  relationships___field_image_1___localFile___relativeDirectory = 'relationships___field_image_1___localFile___relativeDirectory',
  relationships___field_image_1___localFile___dev = 'relationships___field_image_1___localFile___dev',
  relationships___field_image_1___localFile___mode = 'relationships___field_image_1___localFile___mode',
  relationships___field_image_1___localFile___nlink = 'relationships___field_image_1___localFile___nlink',
  relationships___field_image_1___localFile___uid = 'relationships___field_image_1___localFile___uid',
  relationships___field_image_1___localFile___gid = 'relationships___field_image_1___localFile___gid',
  relationships___field_image_1___localFile___rdev = 'relationships___field_image_1___localFile___rdev',
  relationships___field_image_1___localFile___blksize = 'relationships___field_image_1___localFile___blksize',
  relationships___field_image_1___localFile___ino = 'relationships___field_image_1___localFile___ino',
  relationships___field_image_1___localFile___blocks = 'relationships___field_image_1___localFile___blocks',
  relationships___field_image_1___localFile___atimeMs = 'relationships___field_image_1___localFile___atimeMs',
  relationships___field_image_1___localFile___mtimeMs = 'relationships___field_image_1___localFile___mtimeMs',
  relationships___field_image_1___localFile___ctimeMs = 'relationships___field_image_1___localFile___ctimeMs',
  relationships___field_image_1___localFile___atime = 'relationships___field_image_1___localFile___atime',
  relationships___field_image_1___localFile___mtime = 'relationships___field_image_1___localFile___mtime',
  relationships___field_image_1___localFile___ctime = 'relationships___field_image_1___localFile___ctime',
  relationships___field_image_1___localFile___url = 'relationships___field_image_1___localFile___url',
  relationships___field_image_1___localFile___id = 'relationships___field_image_1___localFile___id',
  relationships___field_image_1___localFile___children = 'relationships___field_image_1___localFile___children',
  relationships___field_image_1___uri___url = 'relationships___field_image_1___uri___url',
  relationships___field_image_1___drupal_id = 'relationships___field_image_1___drupal_id',
  relationships___field_image_1___drupal_internal__fid = 'relationships___field_image_1___drupal_internal__fid',
  relationships___field_image_1___langcode = 'relationships___field_image_1___langcode',
  relationships___field_image_1___filename = 'relationships___field_image_1___filename',
  relationships___field_image_1___filemime = 'relationships___field_image_1___filemime',
  relationships___field_image_1___filesize = 'relationships___field_image_1___filesize',
  relationships___field_image_1___status = 'relationships___field_image_1___status',
  relationships___field_image_1___created = 'relationships___field_image_1___created',
  relationships___field_image_1___changed = 'relationships___field_image_1___changed',
  relationships___field_image_1___uuid = 'relationships___field_image_1___uuid',
  relationships___field_image_1___relationships___node__blog_post = 'relationships___field_image_1___relationships___node__blog_post',
  relationships___field_image_1___relationships___node__devices = 'relationships___field_image_1___relationships___node__devices',
  relationships___field_image_1___relationships___node__accessories = 'relationships___field_image_1___relationships___node__accessories',
  relationships___field_image_1___relationships___paragraph__news_image_title_text = 'relationships___field_image_1___relationships___paragraph__news_image_title_text',
  relationships___field_image_1___relationships___node__cases = 'relationships___field_image_1___relationships___node__cases',
  relationships___field_image_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_1___relationships___paragraph__branches_features = 'relationships___field_image_1___relationships___paragraph__branches_features',
  relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_1___relationships___paragraph__branches_testimonials = 'relationships___field_image_1___relationships___paragraph__branches_testimonials',
  relationships___field_image_1___relationships___paragraph__branches_discounts = 'relationships___field_image_1___relationships___paragraph__branches_discounts',
  relationships___field_image_1___relationships___node__news = 'relationships___field_image_1___relationships___node__news',
  relationships___field_image_1___relationships___node__branches = 'relationships___field_image_1___relationships___node__branches',
  relationships___field_image_1___relationships___paragraph__branches_hero = 'relationships___field_image_1___relationships___paragraph__branches_hero',
  relationships___field_image_1___relationships___paragraph__bullets = 'relationships___field_image_1___relationships___paragraph__bullets',
  relationships___field_image_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_1___relationships___paragraph__branches_device = 'relationships___field_image_1___relationships___paragraph__branches_device',
  relationships___field_image_1___relationships___paragraph__branches_recommendation = 'relationships___field_image_1___relationships___paragraph__branches_recommendation',
  relationships___field_image_1___relationships___site_setting_entity__accessories = 'relationships___field_image_1___relationships___site_setting_entity__accessories',
  relationships___field_image_1___relationships___site_setting_entity__branches = 'relationships___field_image_1___relationships___site_setting_entity__branches',
  relationships___field_image_1___relationships___site_setting_entity__about_us = 'relationships___field_image_1___relationships___site_setting_entity__about_us',
  relationships___field_image_1___relationships___paragraph__logos = 'relationships___field_image_1___relationships___paragraph__logos',
  relationships___field_image_1___relationships___paragraph__how_it_works = 'relationships___field_image_1___relationships___paragraph__how_it_works',
  relationships___field_image_1___relationships___paragraph__ico_and_text = 'relationships___field_image_1___relationships___paragraph__ico_and_text',
  relationships___field_image_1___relationships___paragraph__hero_1 = 'relationships___field_image_1___relationships___paragraph__hero_1',
  relationships___field_image_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_1___relationships___site_setting_entity__product = 'relationships___field_image_1___relationships___site_setting_entity__product',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_1___relationships___paragraph__porownanie_terminali = 'relationships___field_image_1___relationships___paragraph__porownanie_terminali',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_1___relationships___site_setting_entity__main_page = 'relationships___field_image_1___relationships___site_setting_entity__main_page',
  relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_1___relationships___site_setting_entity__intercars = 'relationships___field_image_1___relationships___site_setting_entity__intercars',
  relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_1___relationships___node__agreement = 'relationships___field_image_1___relationships___node__agreement',
  relationships___field_image_1___relationships___node__fiscalization = 'relationships___field_image_1___relationships___node__fiscalization',
  relationships___field_image_1___relationships___site_setting_entity__configurator = 'relationships___field_image_1___relationships___site_setting_entity__configurator',
  relationships___field_image_1___relationships___paragraph__social_link = 'relationships___field_image_1___relationships___paragraph__social_link',
  relationships___field_image_1___relationships___paragraph__make_an_appointment = 'relationships___field_image_1___relationships___paragraph__make_an_appointment',
  relationships___field_image_1___relationships___paragraph__linked_icons = 'relationships___field_image_1___relationships___paragraph__linked_icons',
  relationships___field_image_1___relationships___site_setting_entity__financing = 'relationships___field_image_1___relationships___site_setting_entity__financing',
  relationships___field_image_1___relationships___site_setting_entity__franchise = 'relationships___field_image_1___relationships___site_setting_entity__franchise',
  relationships___field_image_1___id = 'relationships___field_image_1___id',
  relationships___field_image_1___parent___id = 'relationships___field_image_1___parent___id',
  relationships___field_image_1___parent___children = 'relationships___field_image_1___parent___children',
  relationships___field_image_1___children = 'relationships___field_image_1___children',
  relationships___field_image_1___children___id = 'relationships___field_image_1___children___id',
  relationships___field_image_1___children___children = 'relationships___field_image_1___children___children',
  relationships___field_image_1___internal___content = 'relationships___field_image_1___internal___content',
  relationships___field_image_1___internal___contentDigest = 'relationships___field_image_1___internal___contentDigest',
  relationships___field_image_1___internal___description = 'relationships___field_image_1___internal___description',
  relationships___field_image_1___internal___fieldOwners = 'relationships___field_image_1___internal___fieldOwners',
  relationships___field_image_1___internal___ignoreType = 'relationships___field_image_1___internal___ignoreType',
  relationships___field_image_1___internal___mediaType = 'relationships___field_image_1___internal___mediaType',
  relationships___field_image_1___internal___owner = 'relationships___field_image_1___internal___owner',
  relationships___field_image_1___internal___type = 'relationships___field_image_1___internal___type',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Porownanie_TerminaliFilterInput = {
  field_description?: Maybe<Field_DescriptionFilterInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_kup_teraz?: Maybe<StringQueryOperatorInput>;
  field_label_1?: Maybe<StringQueryOperatorInput>;
  field_label_2?: Maybe<StringQueryOperatorInput>;
  field_label_3?: Maybe<StringQueryOperatorInput>;
  field_label_4?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_text_3?: Maybe<StringQueryOperatorInput>;
  field_text_4?: Maybe<Field_Text_4FilterInput>;
  field_zobacz_szczegoly?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Porownanie_TerminaliRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Porownanie_TerminaliFilterListInput = {
  elemMatch?: Maybe<Paragraph__Porownanie_TerminaliFilterInput>;
};

export type Paragraph__Porownanie_TerminaliGroupConnection = {
  __typename?: 'paragraph__porownanie_terminaliGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Porownanie_TerminaliEdge>;
  nodes: Array<Paragraph__Porownanie_Terminali>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Porownanie_TerminaliRelationships = {
  __typename?: 'paragraph__porownanie_terminaliRelationships';
  field_image?: Maybe<File__File>;
  field_image_1?: Maybe<File__File>;
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
};

export type Paragraph__Porownanie_TerminaliRelationshipsFilterInput = {
  field_image?: Maybe<File__FileFilterInput>;
  field_image_1?: Maybe<File__FileFilterInput>;
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
};

export type Paragraph__Porownanie_TerminaliSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Porownanie_TerminaliFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Profit_Reference_To_Price_List = Node & {
  __typename?: 'paragraph__profit_reference_to_price_list';
  field_text?: Maybe<Field_Text>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Profit_Reference_To_Price_ListRelationships>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_price_group_reference?: Maybe<
    Array<Maybe<Paragraph__Profit_Reference_To_Price_ListField_Price_Group_Reference>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Profit_Reference_To_Price_ListCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Profit_Reference_To_Price_ListContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Profit_Reference_To_Price_ListConnection = {
  __typename?: 'paragraph__profit_reference_to_price_listConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Profit_Reference_To_Price_ListEdge>;
  nodes: Array<Paragraph__Profit_Reference_To_Price_List>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Profit_Reference_To_Price_ListGroupConnection>;
};

export type Paragraph__Profit_Reference_To_Price_ListConnectionDistinctArgs = {
  field: Paragraph__Profit_Reference_To_Price_ListFieldsEnum;
};

export type Paragraph__Profit_Reference_To_Price_ListConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Profit_Reference_To_Price_ListFieldsEnum;
};

export type Paragraph__Profit_Reference_To_Price_ListEdge = {
  __typename?: 'paragraph__profit_reference_to_price_listEdge';
  next?: Maybe<Paragraph__Profit_Reference_To_Price_List>;
  node: Paragraph__Profit_Reference_To_Price_List;
  previous?: Maybe<Paragraph__Profit_Reference_To_Price_List>;
};

export type Paragraph__Profit_Reference_To_Price_ListField_Price_Group_Reference = {
  __typename?: 'paragraph__profit_reference_to_price_listField_price_group_reference';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Profit_Reference_To_Price_ListField_Price_Group_ReferenceFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Profit_Reference_To_Price_ListField_Price_Group_ReferenceFilterListInput = {
  elemMatch?: Maybe<
    Paragraph__Profit_Reference_To_Price_ListField_Price_Group_ReferenceFilterInput
  >;
};

export enum Paragraph__Profit_Reference_To_Price_ListFieldsEnum {
  field_text___value = 'field_text___value',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  langcode = 'langcode',
  relationships___field_price_group_reference = 'relationships___field_price_group_reference',
  relationships___field_price_group_reference___langcode = 'relationships___field_price_group_reference___langcode',
  relationships___field_price_group_reference___drupal_id = 'relationships___field_price_group_reference___drupal_id',
  relationships___field_price_group_reference___name = 'relationships___field_price_group_reference___name',
  relationships___field_price_group_reference___description___value = 'relationships___field_price_group_reference___description___value',
  relationships___field_price_group_reference___field_discount = 'relationships___field_price_group_reference___field_discount',
  relationships___field_price_group_reference___field_name_in_api = 'relationships___field_price_group_reference___field_name_in_api',
  relationships___field_price_group_reference___field_name_in_cms = 'relationships___field_price_group_reference___field_name_in_cms',
  relationships___field_price_group_reference___field_isperproduct = 'relationships___field_price_group_reference___field_isperproduct',
  relationships___field_price_group_reference___field_maxfundedproducts = 'relationships___field_price_group_reference___field_maxfundedproducts',
  relationships___field_price_group_reference___field_publish = 'relationships___field_price_group_reference___field_publish',
  relationships___field_price_group_reference___field_text_when_unpublished___value = 'relationships___field_price_group_reference___field_text_when_unpublished___value',
  relationships___field_price_group_reference___relationships___node__price_list = 'relationships___field_price_group_reference___relationships___node__price_list',
  relationships___field_price_group_reference___relationships___paragraph__profit_reference_to_price_list = 'relationships___field_price_group_reference___relationships___paragraph__profit_reference_to_price_list',
  relationships___field_price_group_reference___field_is_discount = 'relationships___field_price_group_reference___field_is_discount',
  relationships___field_price_group_reference___field_side_panel_info = 'relationships___field_price_group_reference___field_side_panel_info',
  relationships___field_price_group_reference___field_is_default = 'relationships___field_price_group_reference___field_is_default',
  relationships___field_price_group_reference___drupal_internal__tid = 'relationships___field_price_group_reference___drupal_internal__tid',
  relationships___field_price_group_reference___drupal_internal__revision_id = 'relationships___field_price_group_reference___drupal_internal__revision_id',
  relationships___field_price_group_reference___revision_created = 'relationships___field_price_group_reference___revision_created',
  relationships___field_price_group_reference___status = 'relationships___field_price_group_reference___status',
  relationships___field_price_group_reference___weight = 'relationships___field_price_group_reference___weight',
  relationships___field_price_group_reference___changed = 'relationships___field_price_group_reference___changed',
  relationships___field_price_group_reference___default_langcode = 'relationships___field_price_group_reference___default_langcode',
  relationships___field_price_group_reference___revision_translation_affected = 'relationships___field_price_group_reference___revision_translation_affected',
  relationships___field_price_group_reference___content_translation_source = 'relationships___field_price_group_reference___content_translation_source',
  relationships___field_price_group_reference___content_translation_outdated = 'relationships___field_price_group_reference___content_translation_outdated',
  relationships___field_price_group_reference___content_translation_created = 'relationships___field_price_group_reference___content_translation_created',
  relationships___field_price_group_reference___field_coupon_in_api = 'relationships___field_price_group_reference___field_coupon_in_api',
  relationships___field_price_group_reference___uuid = 'relationships___field_price_group_reference___uuid',
  relationships___field_price_group_reference___vid___drupal_internal__target_id = 'relationships___field_price_group_reference___vid___drupal_internal__target_id',
  relationships___field_price_group_reference___field_pg_image___alt = 'relationships___field_price_group_reference___field_pg_image___alt',
  relationships___field_price_group_reference___field_pg_image___title = 'relationships___field_price_group_reference___field_pg_image___title',
  relationships___field_price_group_reference___field_pg_image___width = 'relationships___field_price_group_reference___field_pg_image___width',
  relationships___field_price_group_reference___field_pg_image___height = 'relationships___field_price_group_reference___field_pg_image___height',
  relationships___field_price_group_reference___field_pg_image___drupal_internal__target_id = 'relationships___field_price_group_reference___field_pg_image___drupal_internal__target_id',
  relationships___field_price_group_reference___id = 'relationships___field_price_group_reference___id',
  relationships___field_price_group_reference___parent___id = 'relationships___field_price_group_reference___parent___id',
  relationships___field_price_group_reference___parent___children = 'relationships___field_price_group_reference___parent___children',
  relationships___field_price_group_reference___children = 'relationships___field_price_group_reference___children',
  relationships___field_price_group_reference___children___id = 'relationships___field_price_group_reference___children___id',
  relationships___field_price_group_reference___children___children = 'relationships___field_price_group_reference___children___children',
  relationships___field_price_group_reference___internal___content = 'relationships___field_price_group_reference___internal___content',
  relationships___field_price_group_reference___internal___contentDigest = 'relationships___field_price_group_reference___internal___contentDigest',
  relationships___field_price_group_reference___internal___description = 'relationships___field_price_group_reference___internal___description',
  relationships___field_price_group_reference___internal___fieldOwners = 'relationships___field_price_group_reference___internal___fieldOwners',
  relationships___field_price_group_reference___internal___ignoreType = 'relationships___field_price_group_reference___internal___ignoreType',
  relationships___field_price_group_reference___internal___mediaType = 'relationships___field_price_group_reference___internal___mediaType',
  relationships___field_price_group_reference___internal___owner = 'relationships___field_price_group_reference___internal___owner',
  relationships___field_price_group_reference___internal___type = 'relationships___field_price_group_reference___internal___type',
  relationships___paragraph__profits = 'relationships___paragraph__profits',
  relationships___paragraph__profits___field_profits_active = 'relationships___paragraph__profits___field_profits_active',
  relationships___paragraph__profits___field_profits_name___value = 'relationships___paragraph__profits___field_profits_name___value',
  relationships___paragraph__profits___field_ref_desc = 'relationships___paragraph__profits___field_ref_desc',
  relationships___paragraph__profits___field_ref_desc___target_revision_id = 'relationships___paragraph__profits___field_ref_desc___target_revision_id',
  relationships___paragraph__profits___field_ref_desc___drupal_internal__target_id = 'relationships___paragraph__profits___field_ref_desc___drupal_internal__target_id',
  relationships___paragraph__profits___drupal_id = 'relationships___paragraph__profits___drupal_id',
  relationships___paragraph__profits___drupal_internal__id = 'relationships___paragraph__profits___drupal_internal__id',
  relationships___paragraph__profits___status = 'relationships___paragraph__profits___status',
  relationships___paragraph__profits___created = 'relationships___paragraph__profits___created',
  relationships___paragraph__profits___parent_id = 'relationships___paragraph__profits___parent_id',
  relationships___paragraph__profits___parent_type = 'relationships___paragraph__profits___parent_type',
  relationships___paragraph__profits___parent_field_name = 'relationships___paragraph__profits___parent_field_name',
  relationships___paragraph__profits___content_translation_source = 'relationships___paragraph__profits___content_translation_source',
  relationships___paragraph__profits___content_translation_outdated = 'relationships___paragraph__profits___content_translation_outdated',
  relationships___paragraph__profits___content_translation_changed = 'relationships___paragraph__profits___content_translation_changed',
  relationships___paragraph__profits___langcode = 'relationships___paragraph__profits___langcode',
  relationships___paragraph__profits___uuid = 'relationships___paragraph__profits___uuid',
  relationships___paragraph__profits___relationships___node__tariffs = 'relationships___paragraph__profits___relationships___node__tariffs',
  relationships___paragraph__profits___relationships___field_ref_desc = 'relationships___paragraph__profits___relationships___field_ref_desc',
  relationships___paragraph__profits___relationships___paragraph__branches_device = 'relationships___paragraph__profits___relationships___paragraph__branches_device',
  relationships___paragraph__profits___relationships___paragraph__branches_tariff = 'relationships___paragraph__profits___relationships___paragraph__branches_tariff',
  relationships___paragraph__profits___id = 'relationships___paragraph__profits___id',
  relationships___paragraph__profits___parent___id = 'relationships___paragraph__profits___parent___id',
  relationships___paragraph__profits___parent___children = 'relationships___paragraph__profits___parent___children',
  relationships___paragraph__profits___children = 'relationships___paragraph__profits___children',
  relationships___paragraph__profits___children___id = 'relationships___paragraph__profits___children___id',
  relationships___paragraph__profits___children___children = 'relationships___paragraph__profits___children___children',
  relationships___paragraph__profits___internal___content = 'relationships___paragraph__profits___internal___content',
  relationships___paragraph__profits___internal___contentDigest = 'relationships___paragraph__profits___internal___contentDigest',
  relationships___paragraph__profits___internal___description = 'relationships___paragraph__profits___internal___description',
  relationships___paragraph__profits___internal___fieldOwners = 'relationships___paragraph__profits___internal___fieldOwners',
  relationships___paragraph__profits___internal___ignoreType = 'relationships___paragraph__profits___internal___ignoreType',
  relationships___paragraph__profits___internal___mediaType = 'relationships___paragraph__profits___internal___mediaType',
  relationships___paragraph__profits___internal___owner = 'relationships___paragraph__profits___internal___owner',
  relationships___paragraph__profits___internal___type = 'relationships___paragraph__profits___internal___type',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_price_group_reference = 'field_price_group_reference',
  field_price_group_reference___drupal_internal__target_id = 'field_price_group_reference___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Profit_Reference_To_Price_ListFilterInput = {
  field_text?: Maybe<Field_TextFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Profit_Reference_To_Price_ListRelationshipsFilterInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_price_group_reference?: Maybe<
    Paragraph__Profit_Reference_To_Price_ListField_Price_Group_ReferenceFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Profit_Reference_To_Price_ListFilterListInput = {
  elemMatch?: Maybe<Paragraph__Profit_Reference_To_Price_ListFilterInput>;
};

export type Paragraph__Profit_Reference_To_Price_ListGroupConnection = {
  __typename?: 'paragraph__profit_reference_to_price_listGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Profit_Reference_To_Price_ListEdge>;
  nodes: Array<Paragraph__Profit_Reference_To_Price_List>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Profit_Reference_To_Price_ListRelationships = {
  __typename?: 'paragraph__profit_reference_to_price_listRelationships';
  field_price_group_reference?: Maybe<Array<Maybe<Taxonomy_Term__Pricelist_Group>>>;
  paragraph__profits?: Maybe<Array<Maybe<Paragraph__Profits>>>;
};

export type Paragraph__Profit_Reference_To_Price_ListRelationshipsFilterInput = {
  field_price_group_reference?: Maybe<Taxonomy_Term__Pricelist_GroupFilterListInput>;
  paragraph__profits?: Maybe<Paragraph__ProfitsFilterListInput>;
};

export type Paragraph__Profit_Reference_To_Price_ListSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Profit_Reference_To_Price_ListFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Profits = Node & {
  __typename?: 'paragraph__profits';
  field_profits_active?: Maybe<Scalars['Boolean']>;
  field_profits_name?: Maybe<Field_Profits_Name>;
  field_ref_desc?: Maybe<Array<Maybe<Paragraph__ProfitsField_Ref_Desc>>>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__ProfitsRelationships>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__ProfitsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__ProfitsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__ProfitsConnection = {
  __typename?: 'paragraph__profitsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__ProfitsEdge>;
  nodes: Array<Paragraph__Profits>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__ProfitsGroupConnection>;
};

export type Paragraph__ProfitsConnectionDistinctArgs = {
  field: Paragraph__ProfitsFieldsEnum;
};

export type Paragraph__ProfitsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__ProfitsFieldsEnum;
};

export type Paragraph__ProfitsEdge = {
  __typename?: 'paragraph__profitsEdge';
  next?: Maybe<Paragraph__Profits>;
  node: Paragraph__Profits;
  previous?: Maybe<Paragraph__Profits>;
};

export type Paragraph__ProfitsField_Ref_Desc = {
  __typename?: 'paragraph__profitsField_ref_desc';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__ProfitsField_Ref_DescFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__ProfitsField_Ref_DescFilterListInput = {
  elemMatch?: Maybe<Paragraph__ProfitsField_Ref_DescFilterInput>;
};

export enum Paragraph__ProfitsFieldsEnum {
  field_profits_active = 'field_profits_active',
  field_profits_name___value = 'field_profits_name___value',
  field_ref_desc = 'field_ref_desc',
  field_ref_desc___target_revision_id = 'field_ref_desc___target_revision_id',
  field_ref_desc___drupal_internal__target_id = 'field_ref_desc___drupal_internal__target_id',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  relationships___node__tariffs = 'relationships___node__tariffs',
  relationships___node__tariffs___langcode = 'relationships___node__tariffs___langcode',
  relationships___node__tariffs___drupal_id = 'relationships___node__tariffs___drupal_id',
  relationships___node__tariffs___title = 'relationships___node__tariffs___title',
  relationships___node__tariffs___field_pl_description_in_cms___value = 'relationships___node__tariffs___field_pl_description_in_cms___value',
  relationships___node__tariffs___field_pl_is_popular = 'relationships___node__tariffs___field_pl_is_popular',
  relationships___node__tariffs___field_weight = 'relationships___node__tariffs___field_weight',
  relationships___node__tariffs___relationships___field_profits = 'relationships___node__tariffs___relationships___field_profits',
  relationships___node__tariffs___relationships___field_recommended_for_branches = 'relationships___node__tariffs___relationships___field_recommended_for_branches',
  relationships___node__tariffs___price_list_type = 'relationships___node__tariffs___price_list_type',
  relationships___node__tariffs___drupal_internal__nid = 'relationships___node__tariffs___drupal_internal__nid',
  relationships___node__tariffs___status = 'relationships___node__tariffs___status',
  relationships___node__tariffs___content_translation_source = 'relationships___node__tariffs___content_translation_source',
  relationships___node__tariffs___content_translation_outdated = 'relationships___node__tariffs___content_translation_outdated',
  relationships___node__tariffs___uuid = 'relationships___node__tariffs___uuid',
  relationships___node__tariffs___field_profits = 'relationships___node__tariffs___field_profits',
  relationships___node__tariffs___field_profits___target_revision_id = 'relationships___node__tariffs___field_profits___target_revision_id',
  relationships___node__tariffs___field_profits___drupal_internal__target_id = 'relationships___node__tariffs___field_profits___drupal_internal__target_id',
  relationships___node__tariffs___field_recommended_for_branches = 'relationships___node__tariffs___field_recommended_for_branches',
  relationships___node__tariffs___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__tariffs___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__tariffs___id = 'relationships___node__tariffs___id',
  relationships___node__tariffs___parent___id = 'relationships___node__tariffs___parent___id',
  relationships___node__tariffs___parent___children = 'relationships___node__tariffs___parent___children',
  relationships___node__tariffs___children = 'relationships___node__tariffs___children',
  relationships___node__tariffs___children___id = 'relationships___node__tariffs___children___id',
  relationships___node__tariffs___children___children = 'relationships___node__tariffs___children___children',
  relationships___node__tariffs___internal___content = 'relationships___node__tariffs___internal___content',
  relationships___node__tariffs___internal___contentDigest = 'relationships___node__tariffs___internal___contentDigest',
  relationships___node__tariffs___internal___description = 'relationships___node__tariffs___internal___description',
  relationships___node__tariffs___internal___fieldOwners = 'relationships___node__tariffs___internal___fieldOwners',
  relationships___node__tariffs___internal___ignoreType = 'relationships___node__tariffs___internal___ignoreType',
  relationships___node__tariffs___internal___mediaType = 'relationships___node__tariffs___internal___mediaType',
  relationships___node__tariffs___internal___owner = 'relationships___node__tariffs___internal___owner',
  relationships___node__tariffs___internal___type = 'relationships___node__tariffs___internal___type',
  relationships___field_ref_desc = 'relationships___field_ref_desc',
  relationships___field_ref_desc___field_text___value = 'relationships___field_ref_desc___field_text___value',
  relationships___field_ref_desc___drupal_id = 'relationships___field_ref_desc___drupal_id',
  relationships___field_ref_desc___drupal_internal__id = 'relationships___field_ref_desc___drupal_internal__id',
  relationships___field_ref_desc___langcode = 'relationships___field_ref_desc___langcode',
  relationships___field_ref_desc___relationships___field_price_group_reference = 'relationships___field_ref_desc___relationships___field_price_group_reference',
  relationships___field_ref_desc___relationships___paragraph__profits = 'relationships___field_ref_desc___relationships___paragraph__profits',
  relationships___field_ref_desc___drupal_internal__revision_id = 'relationships___field_ref_desc___drupal_internal__revision_id',
  relationships___field_ref_desc___status = 'relationships___field_ref_desc___status',
  relationships___field_ref_desc___created = 'relationships___field_ref_desc___created',
  relationships___field_ref_desc___parent_id = 'relationships___field_ref_desc___parent_id',
  relationships___field_ref_desc___parent_type = 'relationships___field_ref_desc___parent_type',
  relationships___field_ref_desc___parent_field_name = 'relationships___field_ref_desc___parent_field_name',
  relationships___field_ref_desc___default_langcode = 'relationships___field_ref_desc___default_langcode',
  relationships___field_ref_desc___revision_translation_affected = 'relationships___field_ref_desc___revision_translation_affected',
  relationships___field_ref_desc___content_translation_source = 'relationships___field_ref_desc___content_translation_source',
  relationships___field_ref_desc___content_translation_outdated = 'relationships___field_ref_desc___content_translation_outdated',
  relationships___field_ref_desc___content_translation_changed = 'relationships___field_ref_desc___content_translation_changed',
  relationships___field_ref_desc___uuid = 'relationships___field_ref_desc___uuid',
  relationships___field_ref_desc___field_price_group_reference = 'relationships___field_ref_desc___field_price_group_reference',
  relationships___field_ref_desc___field_price_group_reference___drupal_internal__target_id = 'relationships___field_ref_desc___field_price_group_reference___drupal_internal__target_id',
  relationships___field_ref_desc___id = 'relationships___field_ref_desc___id',
  relationships___field_ref_desc___parent___id = 'relationships___field_ref_desc___parent___id',
  relationships___field_ref_desc___parent___children = 'relationships___field_ref_desc___parent___children',
  relationships___field_ref_desc___children = 'relationships___field_ref_desc___children',
  relationships___field_ref_desc___children___id = 'relationships___field_ref_desc___children___id',
  relationships___field_ref_desc___children___children = 'relationships___field_ref_desc___children___children',
  relationships___field_ref_desc___internal___content = 'relationships___field_ref_desc___internal___content',
  relationships___field_ref_desc___internal___contentDigest = 'relationships___field_ref_desc___internal___contentDigest',
  relationships___field_ref_desc___internal___description = 'relationships___field_ref_desc___internal___description',
  relationships___field_ref_desc___internal___fieldOwners = 'relationships___field_ref_desc___internal___fieldOwners',
  relationships___field_ref_desc___internal___ignoreType = 'relationships___field_ref_desc___internal___ignoreType',
  relationships___field_ref_desc___internal___mediaType = 'relationships___field_ref_desc___internal___mediaType',
  relationships___field_ref_desc___internal___owner = 'relationships___field_ref_desc___internal___owner',
  relationships___field_ref_desc___internal___type = 'relationships___field_ref_desc___internal___type',
  relationships___paragraph__branches_device = 'relationships___paragraph__branches_device',
  relationships___paragraph__branches_device___field_branches_name = 'relationships___paragraph__branches_device___field_branches_name',
  relationships___paragraph__branches_device___field_recommended = 'relationships___paragraph__branches_device___field_recommended',
  relationships___paragraph__branches_device___field_button_text_recommended = 'relationships___paragraph__branches_device___field_button_text_recommended',
  relationships___paragraph__branches_device___relationships___field_branches_profits = 'relationships___paragraph__branches_device___relationships___field_branches_profits',
  relationships___paragraph__branches_device___relationships___paragraph__branches_recommended_devices = 'relationships___paragraph__branches_device___relationships___paragraph__branches_recommended_devices',
  relationships___paragraph__branches_device___drupal_id = 'relationships___paragraph__branches_device___drupal_id',
  relationships___paragraph__branches_device___drupal_internal__id = 'relationships___paragraph__branches_device___drupal_internal__id',
  relationships___paragraph__branches_device___status = 'relationships___paragraph__branches_device___status',
  relationships___paragraph__branches_device___created = 'relationships___paragraph__branches_device___created',
  relationships___paragraph__branches_device___parent_id = 'relationships___paragraph__branches_device___parent_id',
  relationships___paragraph__branches_device___parent_type = 'relationships___paragraph__branches_device___parent_type',
  relationships___paragraph__branches_device___parent_field_name = 'relationships___paragraph__branches_device___parent_field_name',
  relationships___paragraph__branches_device___content_translation_source = 'relationships___paragraph__branches_device___content_translation_source',
  relationships___paragraph__branches_device___content_translation_outdated = 'relationships___paragraph__branches_device___content_translation_outdated',
  relationships___paragraph__branches_device___field_nc_button_text = 'relationships___paragraph__branches_device___field_nc_button_text',
  relationships___paragraph__branches_device___langcode = 'relationships___paragraph__branches_device___langcode',
  relationships___paragraph__branches_device___uuid = 'relationships___paragraph__branches_device___uuid',
  relationships___paragraph__branches_device___field_branches_image___alt = 'relationships___paragraph__branches_device___field_branches_image___alt',
  relationships___paragraph__branches_device___field_branches_image___title = 'relationships___paragraph__branches_device___field_branches_image___title',
  relationships___paragraph__branches_device___field_branches_image___width = 'relationships___paragraph__branches_device___field_branches_image___width',
  relationships___paragraph__branches_device___field_branches_image___height = 'relationships___paragraph__branches_device___field_branches_image___height',
  relationships___paragraph__branches_device___field_branches_image___drupal_internal__target_id = 'relationships___paragraph__branches_device___field_branches_image___drupal_internal__target_id',
  relationships___paragraph__branches_device___field_branches_profits = 'relationships___paragraph__branches_device___field_branches_profits',
  relationships___paragraph__branches_device___field_branches_profits___target_revision_id = 'relationships___paragraph__branches_device___field_branches_profits___target_revision_id',
  relationships___paragraph__branches_device___field_branches_profits___drupal_internal__target_id = 'relationships___paragraph__branches_device___field_branches_profits___drupal_internal__target_id',
  relationships___paragraph__branches_device___id = 'relationships___paragraph__branches_device___id',
  relationships___paragraph__branches_device___parent___id = 'relationships___paragraph__branches_device___parent___id',
  relationships___paragraph__branches_device___parent___children = 'relationships___paragraph__branches_device___parent___children',
  relationships___paragraph__branches_device___children = 'relationships___paragraph__branches_device___children',
  relationships___paragraph__branches_device___children___id = 'relationships___paragraph__branches_device___children___id',
  relationships___paragraph__branches_device___children___children = 'relationships___paragraph__branches_device___children___children',
  relationships___paragraph__branches_device___internal___content = 'relationships___paragraph__branches_device___internal___content',
  relationships___paragraph__branches_device___internal___contentDigest = 'relationships___paragraph__branches_device___internal___contentDigest',
  relationships___paragraph__branches_device___internal___description = 'relationships___paragraph__branches_device___internal___description',
  relationships___paragraph__branches_device___internal___fieldOwners = 'relationships___paragraph__branches_device___internal___fieldOwners',
  relationships___paragraph__branches_device___internal___ignoreType = 'relationships___paragraph__branches_device___internal___ignoreType',
  relationships___paragraph__branches_device___internal___mediaType = 'relationships___paragraph__branches_device___internal___mediaType',
  relationships___paragraph__branches_device___internal___owner = 'relationships___paragraph__branches_device___internal___owner',
  relationships___paragraph__branches_device___internal___type = 'relationships___paragraph__branches_device___internal___type',
  relationships___paragraph__branches_tariff = 'relationships___paragraph__branches_tariff',
  relationships___paragraph__branches_tariff___field_branches_name = 'relationships___paragraph__branches_tariff___field_branches_name',
  relationships___paragraph__branches_tariff___field_recommended = 'relationships___paragraph__branches_tariff___field_recommended',
  relationships___paragraph__branches_tariff___relationships___field_branches_profits = 'relationships___paragraph__branches_tariff___relationships___field_branches_profits',
  relationships___paragraph__branches_tariff___relationships___paragraph__branches_recommended_tariffs = 'relationships___paragraph__branches_tariff___relationships___paragraph__branches_recommended_tariffs',
  relationships___paragraph__branches_tariff___relationships___site_setting_entity__product = 'relationships___paragraph__branches_tariff___relationships___site_setting_entity__product',
  relationships___paragraph__branches_tariff___drupal_id = 'relationships___paragraph__branches_tariff___drupal_id',
  relationships___paragraph__branches_tariff___drupal_internal__id = 'relationships___paragraph__branches_tariff___drupal_internal__id',
  relationships___paragraph__branches_tariff___status = 'relationships___paragraph__branches_tariff___status',
  relationships___paragraph__branches_tariff___created = 'relationships___paragraph__branches_tariff___created',
  relationships___paragraph__branches_tariff___parent_id = 'relationships___paragraph__branches_tariff___parent_id',
  relationships___paragraph__branches_tariff___parent_type = 'relationships___paragraph__branches_tariff___parent_type',
  relationships___paragraph__branches_tariff___parent_field_name = 'relationships___paragraph__branches_tariff___parent_field_name',
  relationships___paragraph__branches_tariff___content_translation_source = 'relationships___paragraph__branches_tariff___content_translation_source',
  relationships___paragraph__branches_tariff___content_translation_outdated = 'relationships___paragraph__branches_tariff___content_translation_outdated',
  relationships___paragraph__branches_tariff___content_translation_changed = 'relationships___paragraph__branches_tariff___content_translation_changed',
  relationships___paragraph__branches_tariff___langcode = 'relationships___paragraph__branches_tariff___langcode',
  relationships___paragraph__branches_tariff___uuid = 'relationships___paragraph__branches_tariff___uuid',
  relationships___paragraph__branches_tariff___field_branches_profits = 'relationships___paragraph__branches_tariff___field_branches_profits',
  relationships___paragraph__branches_tariff___field_branches_profits___target_revision_id = 'relationships___paragraph__branches_tariff___field_branches_profits___target_revision_id',
  relationships___paragraph__branches_tariff___field_branches_profits___drupal_internal__target_id = 'relationships___paragraph__branches_tariff___field_branches_profits___drupal_internal__target_id',
  relationships___paragraph__branches_tariff___field_date_from_api___drupal_internal__target_id = 'relationships___paragraph__branches_tariff___field_date_from_api___drupal_internal__target_id',
  relationships___paragraph__branches_tariff___id = 'relationships___paragraph__branches_tariff___id',
  relationships___paragraph__branches_tariff___parent___id = 'relationships___paragraph__branches_tariff___parent___id',
  relationships___paragraph__branches_tariff___parent___children = 'relationships___paragraph__branches_tariff___parent___children',
  relationships___paragraph__branches_tariff___children = 'relationships___paragraph__branches_tariff___children',
  relationships___paragraph__branches_tariff___children___id = 'relationships___paragraph__branches_tariff___children___id',
  relationships___paragraph__branches_tariff___children___children = 'relationships___paragraph__branches_tariff___children___children',
  relationships___paragraph__branches_tariff___internal___content = 'relationships___paragraph__branches_tariff___internal___content',
  relationships___paragraph__branches_tariff___internal___contentDigest = 'relationships___paragraph__branches_tariff___internal___contentDigest',
  relationships___paragraph__branches_tariff___internal___description = 'relationships___paragraph__branches_tariff___internal___description',
  relationships___paragraph__branches_tariff___internal___fieldOwners = 'relationships___paragraph__branches_tariff___internal___fieldOwners',
  relationships___paragraph__branches_tariff___internal___ignoreType = 'relationships___paragraph__branches_tariff___internal___ignoreType',
  relationships___paragraph__branches_tariff___internal___mediaType = 'relationships___paragraph__branches_tariff___internal___mediaType',
  relationships___paragraph__branches_tariff___internal___owner = 'relationships___paragraph__branches_tariff___internal___owner',
  relationships___paragraph__branches_tariff___internal___type = 'relationships___paragraph__branches_tariff___internal___type',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__ProfitsFilterInput = {
  field_profits_active?: Maybe<BooleanQueryOperatorInput>;
  field_profits_name?: Maybe<Field_Profits_NameFilterInput>;
  field_ref_desc?: Maybe<Paragraph__ProfitsField_Ref_DescFilterListInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__ProfitsRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__ProfitsFilterListInput = {
  elemMatch?: Maybe<Paragraph__ProfitsFilterInput>;
};

export type Paragraph__ProfitsGroupConnection = {
  __typename?: 'paragraph__profitsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__ProfitsEdge>;
  nodes: Array<Paragraph__Profits>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__ProfitsRelationships = {
  __typename?: 'paragraph__profitsRelationships';
  node__tariffs?: Maybe<Array<Maybe<Node__Tariffs>>>;
  field_ref_desc?: Maybe<Array<Maybe<Paragraph__Profit_Reference_To_Price_List>>>;
  paragraph__branches_device?: Maybe<Array<Maybe<Paragraph__Branches_Device>>>;
  paragraph__branches_tariff?: Maybe<Array<Maybe<Paragraph__Branches_Tariff>>>;
};

export type Paragraph__ProfitsRelationshipsFilterInput = {
  node__tariffs?: Maybe<Node__TariffsFilterListInput>;
  field_ref_desc?: Maybe<Paragraph__Profit_Reference_To_Price_ListFilterListInput>;
  paragraph__branches_device?: Maybe<Paragraph__Branches_DeviceFilterListInput>;
  paragraph__branches_tariff?: Maybe<Paragraph__Branches_TariffFilterListInput>;
};

export type Paragraph__ProfitsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__ProfitsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Recommended_Blog_Posts = Node & {
  __typename?: 'paragraph__recommended_blog_posts';
  id: Scalars['ID'];
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  field_headline?: Maybe<Scalars['String']>;
  field_read_more_button_text?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Recommended_Blog_PostsRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  langcode?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  field_blog_posts?: Maybe<Array<Maybe<Paragraph__Recommended_Blog_PostsField_Blog_Posts>>>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Recommended_Blog_PostsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Recommended_Blog_PostsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Recommended_Blog_PostsConnection = {
  __typename?: 'paragraph__recommended_blog_postsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Recommended_Blog_PostsEdge>;
  nodes: Array<Paragraph__Recommended_Blog_Posts>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Recommended_Blog_PostsGroupConnection>;
};

export type Paragraph__Recommended_Blog_PostsConnectionDistinctArgs = {
  field: Paragraph__Recommended_Blog_PostsFieldsEnum;
};

export type Paragraph__Recommended_Blog_PostsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Recommended_Blog_PostsFieldsEnum;
};

export type Paragraph__Recommended_Blog_PostsEdge = {
  __typename?: 'paragraph__recommended_blog_postsEdge';
  next?: Maybe<Paragraph__Recommended_Blog_Posts>;
  node: Paragraph__Recommended_Blog_Posts;
  previous?: Maybe<Paragraph__Recommended_Blog_Posts>;
};

export type Paragraph__Recommended_Blog_PostsField_Blog_Posts = {
  __typename?: 'paragraph__recommended_blog_postsField_blog_posts';
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Recommended_Blog_PostsField_Blog_PostsFilterInput = {
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Recommended_Blog_PostsField_Blog_PostsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Recommended_Blog_PostsField_Blog_PostsFilterInput>;
};

export enum Paragraph__Recommended_Blog_PostsFieldsEnum {
  id = 'id',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  field_headline = 'field_headline',
  field_read_more_button_text = 'field_read_more_button_text',
  relationships___field_blog_posts = 'relationships___field_blog_posts',
  relationships___field_blog_posts___langcode = 'relationships___field_blog_posts___langcode',
  relationships___field_blog_posts___id = 'relationships___field_blog_posts___id',
  relationships___field_blog_posts___drupal_internal__nid = 'relationships___field_blog_posts___drupal_internal__nid',
  relationships___field_blog_posts___title = 'relationships___field_blog_posts___title',
  relationships___field_blog_posts___field_news_reading_time = 'relationships___field_blog_posts___field_news_reading_time',
  relationships___field_blog_posts___field_news_body = 'relationships___field_blog_posts___field_news_body',
  relationships___field_blog_posts___created = 'relationships___field_blog_posts___created',
  relationships___field_blog_posts___path___alias = 'relationships___field_blog_posts___path___alias',
  relationships___field_blog_posts___status = 'relationships___field_blog_posts___status',
  relationships___field_blog_posts___field_meta_description = 'relationships___field_blog_posts___field_meta_description',
  relationships___field_blog_posts___field_meta_title = 'relationships___field_blog_posts___field_meta_title',
  relationships___field_blog_posts___relationships___field_news_tags = 'relationships___field_blog_posts___relationships___field_news_tags',
  relationships___field_blog_posts___relationships___node__news = 'relationships___field_blog_posts___relationships___node__news',
  relationships___field_blog_posts___relationships___paragraph__recommended_blog_posts = 'relationships___field_blog_posts___relationships___paragraph__recommended_blog_posts',
  relationships___field_blog_posts___drupal_id = 'relationships___field_blog_posts___drupal_id',
  relationships___field_blog_posts___rh_redirect_fallback_action = 'relationships___field_blog_posts___rh_redirect_fallback_action',
  relationships___field_blog_posts___content_translation_source = 'relationships___field_blog_posts___content_translation_source',
  relationships___field_blog_posts___content_translation_outdated = 'relationships___field_blog_posts___content_translation_outdated',
  relationships___field_blog_posts___field_meta_keywords = 'relationships___field_blog_posts___field_meta_keywords',
  relationships___field_blog_posts___uuid = 'relationships___field_blog_posts___uuid',
  relationships___field_blog_posts___field_news_categories___drupal_internal__target_id = 'relationships___field_blog_posts___field_news_categories___drupal_internal__target_id',
  relationships___field_blog_posts___field_news_components = 'relationships___field_blog_posts___field_news_components',
  relationships___field_blog_posts___field_news_components___target_revision_id = 'relationships___field_blog_posts___field_news_components___target_revision_id',
  relationships___field_blog_posts___field_news_components___drupal_internal__target_id = 'relationships___field_blog_posts___field_news_components___drupal_internal__target_id',
  relationships___field_blog_posts___field_news_image___alt = 'relationships___field_blog_posts___field_news_image___alt',
  relationships___field_blog_posts___field_news_image___title = 'relationships___field_blog_posts___field_news_image___title',
  relationships___field_blog_posts___field_news_image___width = 'relationships___field_blog_posts___field_news_image___width',
  relationships___field_blog_posts___field_news_image___height = 'relationships___field_blog_posts___field_news_image___height',
  relationships___field_blog_posts___field_news_image___drupal_internal__target_id = 'relationships___field_blog_posts___field_news_image___drupal_internal__target_id',
  relationships___field_blog_posts___field_news_tags = 'relationships___field_blog_posts___field_news_tags',
  relationships___field_blog_posts___field_news_tags___drupal_internal__target_id = 'relationships___field_blog_posts___field_news_tags___drupal_internal__target_id',
  relationships___field_blog_posts___field_related_news_1___drupal_internal__target_id = 'relationships___field_blog_posts___field_related_news_1___drupal_internal__target_id',
  relationships___field_blog_posts___field_related_news_2___drupal_internal__target_id = 'relationships___field_blog_posts___field_related_news_2___drupal_internal__target_id',
  relationships___field_blog_posts___field_related_news_3___drupal_internal__target_id = 'relationships___field_blog_posts___field_related_news_3___drupal_internal__target_id',
  relationships___field_blog_posts___parent___id = 'relationships___field_blog_posts___parent___id',
  relationships___field_blog_posts___parent___children = 'relationships___field_blog_posts___parent___children',
  relationships___field_blog_posts___children = 'relationships___field_blog_posts___children',
  relationships___field_blog_posts___children___id = 'relationships___field_blog_posts___children___id',
  relationships___field_blog_posts___children___children = 'relationships___field_blog_posts___children___children',
  relationships___field_blog_posts___internal___content = 'relationships___field_blog_posts___internal___content',
  relationships___field_blog_posts___internal___contentDigest = 'relationships___field_blog_posts___internal___contentDigest',
  relationships___field_blog_posts___internal___description = 'relationships___field_blog_posts___internal___description',
  relationships___field_blog_posts___internal___fieldOwners = 'relationships___field_blog_posts___internal___fieldOwners',
  relationships___field_blog_posts___internal___ignoreType = 'relationships___field_blog_posts___internal___ignoreType',
  relationships___field_blog_posts___internal___mediaType = 'relationships___field_blog_posts___internal___mediaType',
  relationships___field_blog_posts___internal___owner = 'relationships___field_blog_posts___internal___owner',
  relationships___field_blog_posts___internal___type = 'relationships___field_blog_posts___internal___type',
  relationships___site_setting_entity__main_page = 'relationships___site_setting_entity__main_page',
  relationships___site_setting_entity__main_page___langcode = 'relationships___site_setting_entity__main_page___langcode',
  relationships___site_setting_entity__main_page___field_mp_text_footer___value = 'relationships___site_setting_entity__main_page___field_mp_text_footer___value',
  relationships___site_setting_entity__main_page___field_mp_text___value = 'relationships___site_setting_entity__main_page___field_mp_text___value',
  relationships___site_setting_entity__main_page___field_mp_button_text = 'relationships___site_setting_entity__main_page___field_mp_button_text',
  relationships___site_setting_entity__main_page___field_meta_description = 'relationships___site_setting_entity__main_page___field_meta_description',
  relationships___site_setting_entity__main_page___field_meta_title = 'relationships___site_setting_entity__main_page___field_meta_title',
  relationships___site_setting_entity__main_page___field_btn_url = 'relationships___site_setting_entity__main_page___field_btn_url',
  relationships___site_setting_entity__main_page___field_settings_button_text = 'relationships___site_setting_entity__main_page___field_settings_button_text',
  relationships___site_setting_entity__main_page___field_mp_image_footer___alt = 'relationships___site_setting_entity__main_page___field_mp_image_footer___alt',
  relationships___site_setting_entity__main_page___field_mp_image___alt = 'relationships___site_setting_entity__main_page___field_mp_image___alt',
  relationships___site_setting_entity__main_page___drupal_id = 'relationships___site_setting_entity__main_page___drupal_id',
  relationships___site_setting_entity__main_page___drupal_internal__id = 'relationships___site_setting_entity__main_page___drupal_internal__id',
  relationships___site_setting_entity__main_page___name = 'relationships___site_setting_entity__main_page___name',
  relationships___site_setting_entity__main_page___fieldset = 'relationships___site_setting_entity__main_page___fieldset',
  relationships___site_setting_entity__main_page___status = 'relationships___site_setting_entity__main_page___status',
  relationships___site_setting_entity__main_page___created = 'relationships___site_setting_entity__main_page___created',
  relationships___site_setting_entity__main_page___changed = 'relationships___site_setting_entity__main_page___changed',
  relationships___site_setting_entity__main_page___default_langcode = 'relationships___site_setting_entity__main_page___default_langcode',
  relationships___site_setting_entity__main_page___content_translation_source = 'relationships___site_setting_entity__main_page___content_translation_source',
  relationships___site_setting_entity__main_page___content_translation_outdated = 'relationships___site_setting_entity__main_page___content_translation_outdated',
  relationships___site_setting_entity__main_page___content_translation_status = 'relationships___site_setting_entity__main_page___content_translation_status',
  relationships___site_setting_entity__main_page___content_translation_created = 'relationships___site_setting_entity__main_page___content_translation_created',
  relationships___site_setting_entity__main_page___content_translation_changed = 'relationships___site_setting_entity__main_page___content_translation_changed',
  relationships___site_setting_entity__main_page___uuid = 'relationships___site_setting_entity__main_page___uuid',
  relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___field_components = 'relationships___site_setting_entity__main_page___field_components',
  relationships___site_setting_entity__main_page___field_components___target_revision_id = 'relationships___site_setting_entity__main_page___field_components___target_revision_id',
  relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__main_page___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__main_page___id = 'relationships___site_setting_entity__main_page___id',
  relationships___site_setting_entity__main_page___parent___id = 'relationships___site_setting_entity__main_page___parent___id',
  relationships___site_setting_entity__main_page___parent___children = 'relationships___site_setting_entity__main_page___parent___children',
  relationships___site_setting_entity__main_page___children = 'relationships___site_setting_entity__main_page___children',
  relationships___site_setting_entity__main_page___children___id = 'relationships___site_setting_entity__main_page___children___id',
  relationships___site_setting_entity__main_page___children___children = 'relationships___site_setting_entity__main_page___children___children',
  relationships___site_setting_entity__main_page___internal___content = 'relationships___site_setting_entity__main_page___internal___content',
  relationships___site_setting_entity__main_page___internal___contentDigest = 'relationships___site_setting_entity__main_page___internal___contentDigest',
  relationships___site_setting_entity__main_page___internal___description = 'relationships___site_setting_entity__main_page___internal___description',
  relationships___site_setting_entity__main_page___internal___fieldOwners = 'relationships___site_setting_entity__main_page___internal___fieldOwners',
  relationships___site_setting_entity__main_page___internal___ignoreType = 'relationships___site_setting_entity__main_page___internal___ignoreType',
  relationships___site_setting_entity__main_page___internal___mediaType = 'relationships___site_setting_entity__main_page___internal___mediaType',
  relationships___site_setting_entity__main_page___internal___owner = 'relationships___site_setting_entity__main_page___internal___owner',
  relationships___site_setting_entity__main_page___internal___type = 'relationships___site_setting_entity__main_page___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  langcode = 'langcode',
  uuid = 'uuid',
  field_blog_posts = 'field_blog_posts',
  field_blog_posts___drupal_internal__target_id = 'field_blog_posts___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Recommended_Blog_PostsFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_headline?: Maybe<StringQueryOperatorInput>;
  field_read_more_button_text?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Recommended_Blog_PostsRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_blog_posts?: Maybe<Paragraph__Recommended_Blog_PostsField_Blog_PostsFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Recommended_Blog_PostsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Recommended_Blog_PostsFilterInput>;
};

export type Paragraph__Recommended_Blog_PostsGroupConnection = {
  __typename?: 'paragraph__recommended_blog_postsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Recommended_Blog_PostsEdge>;
  nodes: Array<Paragraph__Recommended_Blog_Posts>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Recommended_Blog_PostsRelationships = {
  __typename?: 'paragraph__recommended_blog_postsRelationships';
  field_blog_posts?: Maybe<Array<Maybe<Node__News>>>;
  site_setting_entity__main_page?: Maybe<Array<Maybe<Site_Setting_Entity__Main_Page>>>;
};

export type Paragraph__Recommended_Blog_PostsRelationshipsFilterInput = {
  field_blog_posts?: Maybe<Node__NewsFilterListInput>;
  site_setting_entity__main_page?: Maybe<Site_Setting_Entity__Main_PageFilterListInput>;
};

export type Paragraph__Recommended_Blog_PostsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Recommended_Blog_PostsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Simple_Form = Node & {
  __typename?: 'paragraph__simple_form';
  id: Scalars['ID'];
  langcode?: Maybe<Scalars['String']>;
  field_title?: Maybe<Scalars['String']>;
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Simple_FormRelationships>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Simple_FormCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Simple_FormContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Simple_FormConnection = {
  __typename?: 'paragraph__simple_formConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Simple_FormEdge>;
  nodes: Array<Paragraph__Simple_Form>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Simple_FormGroupConnection>;
};

export type Paragraph__Simple_FormConnectionDistinctArgs = {
  field: Paragraph__Simple_FormFieldsEnum;
};

export type Paragraph__Simple_FormConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Simple_FormFieldsEnum;
};

export type Paragraph__Simple_FormEdge = {
  __typename?: 'paragraph__simple_formEdge';
  next?: Maybe<Paragraph__Simple_Form>;
  node: Paragraph__Simple_Form;
  previous?: Maybe<Paragraph__Simple_Form>;
};

export enum Paragraph__Simple_FormFieldsEnum {
  id = 'id',
  langcode = 'langcode',
  field_title = 'field_title',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  relationships___node__pages = 'relationships___node__pages',
  relationships___node__pages___langcode = 'relationships___node__pages___langcode',
  relationships___node__pages___id = 'relationships___node__pages___id',
  relationships___node__pages___title = 'relationships___node__pages___title',
  relationships___node__pages___field_pages_text___value = 'relationships___node__pages___field_pages_text___value',
  relationships___node__pages___path___alias = 'relationships___node__pages___path___alias',
  relationships___node__pages___field_meta_title = 'relationships___node__pages___field_meta_title',
  relationships___node__pages___drupal_id = 'relationships___node__pages___drupal_id',
  relationships___node__pages___drupal_internal__nid = 'relationships___node__pages___drupal_internal__nid',
  relationships___node__pages___drupal_internal__vid = 'relationships___node__pages___drupal_internal__vid',
  relationships___node__pages___rh_action = 'relationships___node__pages___rh_action',
  relationships___node__pages___rh_redirect_response = 'relationships___node__pages___rh_redirect_response',
  relationships___node__pages___rh_redirect_fallback_action = 'relationships___node__pages___rh_redirect_fallback_action',
  relationships___node__pages___content_translation_source = 'relationships___node__pages___content_translation_source',
  relationships___node__pages___content_translation_outdated = 'relationships___node__pages___content_translation_outdated',
  relationships___node__pages___field_meta_description = 'relationships___node__pages___field_meta_description',
  relationships___node__pages___field_meta_keywords = 'relationships___node__pages___field_meta_keywords',
  relationships___node__pages___uuid = 'relationships___node__pages___uuid',
  relationships___node__pages___field_components = 'relationships___node__pages___field_components',
  relationships___node__pages___field_components___target_revision_id = 'relationships___node__pages___field_components___target_revision_id',
  relationships___node__pages___field_components___drupal_internal__target_id = 'relationships___node__pages___field_components___drupal_internal__target_id',
  relationships___node__pages___parent___id = 'relationships___node__pages___parent___id',
  relationships___node__pages___parent___children = 'relationships___node__pages___parent___children',
  relationships___node__pages___children = 'relationships___node__pages___children',
  relationships___node__pages___children___id = 'relationships___node__pages___children___id',
  relationships___node__pages___children___children = 'relationships___node__pages___children___children',
  relationships___node__pages___internal___content = 'relationships___node__pages___internal___content',
  relationships___node__pages___internal___contentDigest = 'relationships___node__pages___internal___contentDigest',
  relationships___node__pages___internal___description = 'relationships___node__pages___internal___description',
  relationships___node__pages___internal___fieldOwners = 'relationships___node__pages___internal___fieldOwners',
  relationships___node__pages___internal___ignoreType = 'relationships___node__pages___internal___ignoreType',
  relationships___node__pages___internal___mediaType = 'relationships___node__pages___internal___mediaType',
  relationships___node__pages___internal___owner = 'relationships___node__pages___internal___owner',
  relationships___node__pages___internal___type = 'relationships___node__pages___internal___type',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Simple_FormFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  field_title?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Simple_FormRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Simple_FormGroupConnection = {
  __typename?: 'paragraph__simple_formGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Simple_FormEdge>;
  nodes: Array<Paragraph__Simple_Form>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Simple_FormRelationships = {
  __typename?: 'paragraph__simple_formRelationships';
  node__pages?: Maybe<Array<Maybe<Node__Pages>>>;
};

export type Paragraph__Simple_FormRelationshipsFilterInput = {
  node__pages?: Maybe<Node__PagesFilterListInput>;
};

export type Paragraph__Simple_FormSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Simple_FormFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Social_Link = Node & {
  __typename?: 'paragraph__social_link';
  field_social_link?: Maybe<Field_Social_Link>;
  relationships?: Maybe<Paragraph__Social_LinkRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  field_social_icon?: Maybe<Paragraph__Social_LinkField_Social_Icon>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Social_LinkCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Social_LinkContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Social_LinkConnection = {
  __typename?: 'paragraph__social_linkConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Social_LinkEdge>;
  nodes: Array<Paragraph__Social_Link>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Social_LinkGroupConnection>;
};

export type Paragraph__Social_LinkConnectionDistinctArgs = {
  field: Paragraph__Social_LinkFieldsEnum;
};

export type Paragraph__Social_LinkConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Social_LinkFieldsEnum;
};

export type Paragraph__Social_LinkEdge = {
  __typename?: 'paragraph__social_linkEdge';
  next?: Maybe<Paragraph__Social_Link>;
  node: Paragraph__Social_Link;
  previous?: Maybe<Paragraph__Social_Link>;
};

export type Paragraph__Social_LinkField_Social_Icon = {
  __typename?: 'paragraph__social_linkField_social_icon';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Social_LinkField_Social_IconFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Paragraph__Social_LinkFieldsEnum {
  field_social_link___uri = 'field_social_link___uri',
  field_social_link___title = 'field_social_link___title',
  relationships___field_social_icon___localFile___birthtime = 'relationships___field_social_icon___localFile___birthtime',
  relationships___field_social_icon___localFile___birthtimeMs = 'relationships___field_social_icon___localFile___birthtimeMs',
  relationships___field_social_icon___localFile___publicURL = 'relationships___field_social_icon___localFile___publicURL',
  relationships___field_social_icon___localFile___sourceInstanceName = 'relationships___field_social_icon___localFile___sourceInstanceName',
  relationships___field_social_icon___localFile___absolutePath = 'relationships___field_social_icon___localFile___absolutePath',
  relationships___field_social_icon___localFile___relativePath = 'relationships___field_social_icon___localFile___relativePath',
  relationships___field_social_icon___localFile___extension = 'relationships___field_social_icon___localFile___extension',
  relationships___field_social_icon___localFile___size = 'relationships___field_social_icon___localFile___size',
  relationships___field_social_icon___localFile___prettySize = 'relationships___field_social_icon___localFile___prettySize',
  relationships___field_social_icon___localFile___modifiedTime = 'relationships___field_social_icon___localFile___modifiedTime',
  relationships___field_social_icon___localFile___accessTime = 'relationships___field_social_icon___localFile___accessTime',
  relationships___field_social_icon___localFile___changeTime = 'relationships___field_social_icon___localFile___changeTime',
  relationships___field_social_icon___localFile___birthTime = 'relationships___field_social_icon___localFile___birthTime',
  relationships___field_social_icon___localFile___root = 'relationships___field_social_icon___localFile___root',
  relationships___field_social_icon___localFile___dir = 'relationships___field_social_icon___localFile___dir',
  relationships___field_social_icon___localFile___base = 'relationships___field_social_icon___localFile___base',
  relationships___field_social_icon___localFile___ext = 'relationships___field_social_icon___localFile___ext',
  relationships___field_social_icon___localFile___name = 'relationships___field_social_icon___localFile___name',
  relationships___field_social_icon___localFile___relativeDirectory = 'relationships___field_social_icon___localFile___relativeDirectory',
  relationships___field_social_icon___localFile___dev = 'relationships___field_social_icon___localFile___dev',
  relationships___field_social_icon___localFile___mode = 'relationships___field_social_icon___localFile___mode',
  relationships___field_social_icon___localFile___nlink = 'relationships___field_social_icon___localFile___nlink',
  relationships___field_social_icon___localFile___uid = 'relationships___field_social_icon___localFile___uid',
  relationships___field_social_icon___localFile___gid = 'relationships___field_social_icon___localFile___gid',
  relationships___field_social_icon___localFile___rdev = 'relationships___field_social_icon___localFile___rdev',
  relationships___field_social_icon___localFile___blksize = 'relationships___field_social_icon___localFile___blksize',
  relationships___field_social_icon___localFile___ino = 'relationships___field_social_icon___localFile___ino',
  relationships___field_social_icon___localFile___blocks = 'relationships___field_social_icon___localFile___blocks',
  relationships___field_social_icon___localFile___atimeMs = 'relationships___field_social_icon___localFile___atimeMs',
  relationships___field_social_icon___localFile___mtimeMs = 'relationships___field_social_icon___localFile___mtimeMs',
  relationships___field_social_icon___localFile___ctimeMs = 'relationships___field_social_icon___localFile___ctimeMs',
  relationships___field_social_icon___localFile___atime = 'relationships___field_social_icon___localFile___atime',
  relationships___field_social_icon___localFile___mtime = 'relationships___field_social_icon___localFile___mtime',
  relationships___field_social_icon___localFile___ctime = 'relationships___field_social_icon___localFile___ctime',
  relationships___field_social_icon___localFile___url = 'relationships___field_social_icon___localFile___url',
  relationships___field_social_icon___localFile___id = 'relationships___field_social_icon___localFile___id',
  relationships___field_social_icon___localFile___children = 'relationships___field_social_icon___localFile___children',
  relationships___field_social_icon___uri___url = 'relationships___field_social_icon___uri___url',
  relationships___field_social_icon___drupal_id = 'relationships___field_social_icon___drupal_id',
  relationships___field_social_icon___drupal_internal__fid = 'relationships___field_social_icon___drupal_internal__fid',
  relationships___field_social_icon___langcode = 'relationships___field_social_icon___langcode',
  relationships___field_social_icon___filename = 'relationships___field_social_icon___filename',
  relationships___field_social_icon___filemime = 'relationships___field_social_icon___filemime',
  relationships___field_social_icon___filesize = 'relationships___field_social_icon___filesize',
  relationships___field_social_icon___status = 'relationships___field_social_icon___status',
  relationships___field_social_icon___created = 'relationships___field_social_icon___created',
  relationships___field_social_icon___changed = 'relationships___field_social_icon___changed',
  relationships___field_social_icon___uuid = 'relationships___field_social_icon___uuid',
  relationships___field_social_icon___relationships___node__blog_post = 'relationships___field_social_icon___relationships___node__blog_post',
  relationships___field_social_icon___relationships___node__devices = 'relationships___field_social_icon___relationships___node__devices',
  relationships___field_social_icon___relationships___node__accessories = 'relationships___field_social_icon___relationships___node__accessories',
  relationships___field_social_icon___relationships___paragraph__news_image_title_text = 'relationships___field_social_icon___relationships___paragraph__news_image_title_text',
  relationships___field_social_icon___relationships___node__cases = 'relationships___field_social_icon___relationships___node__cases',
  relationships___field_social_icon___relationships___paragraph__branches_what_is_ipos = 'relationships___field_social_icon___relationships___paragraph__branches_what_is_ipos',
  relationships___field_social_icon___relationships___paragraph__branches_features = 'relationships___field_social_icon___relationships___paragraph__branches_features',
  relationships___field_social_icon___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_social_icon___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_social_icon___relationships___paragraph__branches_testimonials = 'relationships___field_social_icon___relationships___paragraph__branches_testimonials',
  relationships___field_social_icon___relationships___paragraph__branches_discounts = 'relationships___field_social_icon___relationships___paragraph__branches_discounts',
  relationships___field_social_icon___relationships___node__news = 'relationships___field_social_icon___relationships___node__news',
  relationships___field_social_icon___relationships___node__branches = 'relationships___field_social_icon___relationships___node__branches',
  relationships___field_social_icon___relationships___paragraph__branches_hero = 'relationships___field_social_icon___relationships___paragraph__branches_hero',
  relationships___field_social_icon___relationships___paragraph__bullets = 'relationships___field_social_icon___relationships___paragraph__bullets',
  relationships___field_social_icon___relationships___taxonomy_term__pricelist_group = 'relationships___field_social_icon___relationships___taxonomy_term__pricelist_group',
  relationships___field_social_icon___relationships___paragraph__branches_device = 'relationships___field_social_icon___relationships___paragraph__branches_device',
  relationships___field_social_icon___relationships___paragraph__branches_recommendation = 'relationships___field_social_icon___relationships___paragraph__branches_recommendation',
  relationships___field_social_icon___relationships___site_setting_entity__accessories = 'relationships___field_social_icon___relationships___site_setting_entity__accessories',
  relationships___field_social_icon___relationships___site_setting_entity__branches = 'relationships___field_social_icon___relationships___site_setting_entity__branches',
  relationships___field_social_icon___relationships___site_setting_entity__about_us = 'relationships___field_social_icon___relationships___site_setting_entity__about_us',
  relationships___field_social_icon___relationships___paragraph__logos = 'relationships___field_social_icon___relationships___paragraph__logos',
  relationships___field_social_icon___relationships___paragraph__how_it_works = 'relationships___field_social_icon___relationships___paragraph__how_it_works',
  relationships___field_social_icon___relationships___paragraph__ico_and_text = 'relationships___field_social_icon___relationships___paragraph__ico_and_text',
  relationships___field_social_icon___relationships___paragraph__hero_1 = 'relationships___field_social_icon___relationships___paragraph__hero_1',
  relationships___field_social_icon___relationships___paragraph__comprehensive_solution_item = 'relationships___field_social_icon___relationships___paragraph__comprehensive_solution_item',
  relationships___field_social_icon___relationships___site_setting_entity__product = 'relationships___field_social_icon___relationships___site_setting_entity__product',
  relationships___field_social_icon___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_social_icon___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_social_icon___relationships___paragraph__porownanie_terminali = 'relationships___field_social_icon___relationships___paragraph__porownanie_terminali',
  relationships___field_social_icon___relationships___paragraph__zdjecie_opis_link = 'relationships___field_social_icon___relationships___paragraph__zdjecie_opis_link',
  relationships___field_social_icon___relationships___site_setting_entity__main_page = 'relationships___field_social_icon___relationships___site_setting_entity__main_page',
  relationships___field_social_icon___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_social_icon___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_social_icon___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_social_icon___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_social_icon___relationships___site_setting_entity__intercars = 'relationships___field_social_icon___relationships___site_setting_entity__intercars',
  relationships___field_social_icon___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_social_icon___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_social_icon___relationships___node__agreement = 'relationships___field_social_icon___relationships___node__agreement',
  relationships___field_social_icon___relationships___node__fiscalization = 'relationships___field_social_icon___relationships___node__fiscalization',
  relationships___field_social_icon___relationships___site_setting_entity__configurator = 'relationships___field_social_icon___relationships___site_setting_entity__configurator',
  relationships___field_social_icon___relationships___paragraph__social_link = 'relationships___field_social_icon___relationships___paragraph__social_link',
  relationships___field_social_icon___relationships___paragraph__make_an_appointment = 'relationships___field_social_icon___relationships___paragraph__make_an_appointment',
  relationships___field_social_icon___relationships___paragraph__linked_icons = 'relationships___field_social_icon___relationships___paragraph__linked_icons',
  relationships___field_social_icon___relationships___site_setting_entity__financing = 'relationships___field_social_icon___relationships___site_setting_entity__financing',
  relationships___field_social_icon___relationships___site_setting_entity__franchise = 'relationships___field_social_icon___relationships___site_setting_entity__franchise',
  relationships___field_social_icon___id = 'relationships___field_social_icon___id',
  relationships___field_social_icon___parent___id = 'relationships___field_social_icon___parent___id',
  relationships___field_social_icon___parent___children = 'relationships___field_social_icon___parent___children',
  relationships___field_social_icon___children = 'relationships___field_social_icon___children',
  relationships___field_social_icon___children___id = 'relationships___field_social_icon___children___id',
  relationships___field_social_icon___children___children = 'relationships___field_social_icon___children___children',
  relationships___field_social_icon___internal___content = 'relationships___field_social_icon___internal___content',
  relationships___field_social_icon___internal___contentDigest = 'relationships___field_social_icon___internal___contentDigest',
  relationships___field_social_icon___internal___description = 'relationships___field_social_icon___internal___description',
  relationships___field_social_icon___internal___fieldOwners = 'relationships___field_social_icon___internal___fieldOwners',
  relationships___field_social_icon___internal___ignoreType = 'relationships___field_social_icon___internal___ignoreType',
  relationships___field_social_icon___internal___mediaType = 'relationships___field_social_icon___internal___mediaType',
  relationships___field_social_icon___internal___owner = 'relationships___field_social_icon___internal___owner',
  relationships___field_social_icon___internal___type = 'relationships___field_social_icon___internal___type',
  relationships___site_setting_entity__footer = 'relationships___site_setting_entity__footer',
  relationships___site_setting_entity__footer___langcode = 'relationships___site_setting_entity__footer___langcode',
  relationships___site_setting_entity__footer___relationships___field_footer_sections = 'relationships___site_setting_entity__footer___relationships___field_footer_sections',
  relationships___site_setting_entity__footer___relationships___field_footer_social_links = 'relationships___site_setting_entity__footer___relationships___field_footer_social_links',
  relationships___site_setting_entity__footer___drupal_id = 'relationships___site_setting_entity__footer___drupal_id',
  relationships___site_setting_entity__footer___drupal_internal__id = 'relationships___site_setting_entity__footer___drupal_internal__id',
  relationships___site_setting_entity__footer___name = 'relationships___site_setting_entity__footer___name',
  relationships___site_setting_entity__footer___fieldset = 'relationships___site_setting_entity__footer___fieldset',
  relationships___site_setting_entity__footer___status = 'relationships___site_setting_entity__footer___status',
  relationships___site_setting_entity__footer___created = 'relationships___site_setting_entity__footer___created',
  relationships___site_setting_entity__footer___changed = 'relationships___site_setting_entity__footer___changed',
  relationships___site_setting_entity__footer___default_langcode = 'relationships___site_setting_entity__footer___default_langcode',
  relationships___site_setting_entity__footer___content_translation_source = 'relationships___site_setting_entity__footer___content_translation_source',
  relationships___site_setting_entity__footer___content_translation_outdated = 'relationships___site_setting_entity__footer___content_translation_outdated',
  relationships___site_setting_entity__footer___content_translation_status = 'relationships___site_setting_entity__footer___content_translation_status',
  relationships___site_setting_entity__footer___content_translation_created = 'relationships___site_setting_entity__footer___content_translation_created',
  relationships___site_setting_entity__footer___content_translation_changed = 'relationships___site_setting_entity__footer___content_translation_changed',
  relationships___site_setting_entity__footer___uuid = 'relationships___site_setting_entity__footer___uuid',
  relationships___site_setting_entity__footer___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__footer___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__footer___field_footer_sections = 'relationships___site_setting_entity__footer___field_footer_sections',
  relationships___site_setting_entity__footer___field_footer_sections___target_revision_id = 'relationships___site_setting_entity__footer___field_footer_sections___target_revision_id',
  relationships___site_setting_entity__footer___field_footer_sections___drupal_internal__target_id = 'relationships___site_setting_entity__footer___field_footer_sections___drupal_internal__target_id',
  relationships___site_setting_entity__footer___field_footer_social_links = 'relationships___site_setting_entity__footer___field_footer_social_links',
  relationships___site_setting_entity__footer___field_footer_social_links___target_revision_id = 'relationships___site_setting_entity__footer___field_footer_social_links___target_revision_id',
  relationships___site_setting_entity__footer___field_footer_social_links___drupal_internal__target_id = 'relationships___site_setting_entity__footer___field_footer_social_links___drupal_internal__target_id',
  relationships___site_setting_entity__footer___id = 'relationships___site_setting_entity__footer___id',
  relationships___site_setting_entity__footer___parent___id = 'relationships___site_setting_entity__footer___parent___id',
  relationships___site_setting_entity__footer___parent___children = 'relationships___site_setting_entity__footer___parent___children',
  relationships___site_setting_entity__footer___children = 'relationships___site_setting_entity__footer___children',
  relationships___site_setting_entity__footer___children___id = 'relationships___site_setting_entity__footer___children___id',
  relationships___site_setting_entity__footer___children___children = 'relationships___site_setting_entity__footer___children___children',
  relationships___site_setting_entity__footer___internal___content = 'relationships___site_setting_entity__footer___internal___content',
  relationships___site_setting_entity__footer___internal___contentDigest = 'relationships___site_setting_entity__footer___internal___contentDigest',
  relationships___site_setting_entity__footer___internal___description = 'relationships___site_setting_entity__footer___internal___description',
  relationships___site_setting_entity__footer___internal___fieldOwners = 'relationships___site_setting_entity__footer___internal___fieldOwners',
  relationships___site_setting_entity__footer___internal___ignoreType = 'relationships___site_setting_entity__footer___internal___ignoreType',
  relationships___site_setting_entity__footer___internal___mediaType = 'relationships___site_setting_entity__footer___internal___mediaType',
  relationships___site_setting_entity__footer___internal___owner = 'relationships___site_setting_entity__footer___internal___owner',
  relationships___site_setting_entity__footer___internal___type = 'relationships___site_setting_entity__footer___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  field_social_icon___alt = 'field_social_icon___alt',
  field_social_icon___title = 'field_social_icon___title',
  field_social_icon___width = 'field_social_icon___width',
  field_social_icon___height = 'field_social_icon___height',
  field_social_icon___drupal_internal__target_id = 'field_social_icon___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Social_LinkFilterInput = {
  field_social_link?: Maybe<Field_Social_LinkFilterInput>;
  relationships?: Maybe<Paragraph__Social_LinkRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_social_icon?: Maybe<Paragraph__Social_LinkField_Social_IconFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Social_LinkFilterListInput = {
  elemMatch?: Maybe<Paragraph__Social_LinkFilterInput>;
};

export type Paragraph__Social_LinkGroupConnection = {
  __typename?: 'paragraph__social_linkGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Social_LinkEdge>;
  nodes: Array<Paragraph__Social_Link>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Social_LinkRelationships = {
  __typename?: 'paragraph__social_linkRelationships';
  field_social_icon?: Maybe<File__File>;
  site_setting_entity__footer?: Maybe<Array<Maybe<Site_Setting_Entity__Footer>>>;
};

export type Paragraph__Social_LinkRelationshipsFilterInput = {
  field_social_icon?: Maybe<File__FileFilterInput>;
  site_setting_entity__footer?: Maybe<Site_Setting_Entity__FooterFilterListInput>;
};

export type Paragraph__Social_LinkSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Social_LinkFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Terminal_Models = Node & {
  __typename?: 'paragraph__terminal_models';
  field_terminals_title?: Maybe<Field_Terminals_Title>;
  field_terminals_description?: Maybe<Field_Terminals_Description>;
  relationships?: Maybe<Paragraph__Terminal_ModelsRelationships>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Terminal_Models_Item = Node & {
  __typename?: 'paragraph__terminal_models_item';
  internal: Internal;
  field_terminal_band_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  field_button_text?: Maybe<Scalars['String']>;
  field_terminal_description?: Maybe<Field_Terminal_Description>;
  field_terminal_image?: Maybe<Field_Terminal_Image>;
  relationships?: Maybe<Paragraph__Terminal_Models_ItemRelationships>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Terminal_Models_ItemConnection = {
  __typename?: 'paragraph__terminal_models_itemConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Terminal_Models_ItemEdge>;
  nodes: Array<Paragraph__Terminal_Models_Item>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Terminal_Models_ItemGroupConnection>;
};

export type Paragraph__Terminal_Models_ItemConnectionDistinctArgs = {
  field: Paragraph__Terminal_Models_ItemFieldsEnum;
};

export type Paragraph__Terminal_Models_ItemConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Terminal_Models_ItemFieldsEnum;
};

export type Paragraph__Terminal_Models_ItemEdge = {
  __typename?: 'paragraph__terminal_models_itemEdge';
  next?: Maybe<Paragraph__Terminal_Models_Item>;
  node: Paragraph__Terminal_Models_Item;
  previous?: Maybe<Paragraph__Terminal_Models_Item>;
};

export enum Paragraph__Terminal_Models_ItemFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  field_terminal_band_text = 'field_terminal_band_text',
  field_button_text = 'field_button_text',
  field_terminal_description___value = 'field_terminal_description___value',
  field_terminal_image___alt = 'field_terminal_image___alt',
  relationships___field_terminal_image___localFile___birthtime = 'relationships___field_terminal_image___localFile___birthtime',
  relationships___field_terminal_image___localFile___birthtimeMs = 'relationships___field_terminal_image___localFile___birthtimeMs',
  relationships___field_terminal_image___localFile___publicURL = 'relationships___field_terminal_image___localFile___publicURL',
  relationships___field_terminal_image___localFile___sourceInstanceName = 'relationships___field_terminal_image___localFile___sourceInstanceName',
  relationships___field_terminal_image___localFile___absolutePath = 'relationships___field_terminal_image___localFile___absolutePath',
  relationships___field_terminal_image___localFile___relativePath = 'relationships___field_terminal_image___localFile___relativePath',
  relationships___field_terminal_image___localFile___extension = 'relationships___field_terminal_image___localFile___extension',
  relationships___field_terminal_image___localFile___size = 'relationships___field_terminal_image___localFile___size',
  relationships___field_terminal_image___localFile___prettySize = 'relationships___field_terminal_image___localFile___prettySize',
  relationships___field_terminal_image___localFile___modifiedTime = 'relationships___field_terminal_image___localFile___modifiedTime',
  relationships___field_terminal_image___localFile___accessTime = 'relationships___field_terminal_image___localFile___accessTime',
  relationships___field_terminal_image___localFile___changeTime = 'relationships___field_terminal_image___localFile___changeTime',
  relationships___field_terminal_image___localFile___birthTime = 'relationships___field_terminal_image___localFile___birthTime',
  relationships___field_terminal_image___localFile___root = 'relationships___field_terminal_image___localFile___root',
  relationships___field_terminal_image___localFile___dir = 'relationships___field_terminal_image___localFile___dir',
  relationships___field_terminal_image___localFile___base = 'relationships___field_terminal_image___localFile___base',
  relationships___field_terminal_image___localFile___ext = 'relationships___field_terminal_image___localFile___ext',
  relationships___field_terminal_image___localFile___name = 'relationships___field_terminal_image___localFile___name',
  relationships___field_terminal_image___localFile___relativeDirectory = 'relationships___field_terminal_image___localFile___relativeDirectory',
  relationships___field_terminal_image___localFile___dev = 'relationships___field_terminal_image___localFile___dev',
  relationships___field_terminal_image___localFile___mode = 'relationships___field_terminal_image___localFile___mode',
  relationships___field_terminal_image___localFile___nlink = 'relationships___field_terminal_image___localFile___nlink',
  relationships___field_terminal_image___localFile___uid = 'relationships___field_terminal_image___localFile___uid',
  relationships___field_terminal_image___localFile___gid = 'relationships___field_terminal_image___localFile___gid',
  relationships___field_terminal_image___localFile___rdev = 'relationships___field_terminal_image___localFile___rdev',
  relationships___field_terminal_image___localFile___blksize = 'relationships___field_terminal_image___localFile___blksize',
  relationships___field_terminal_image___localFile___ino = 'relationships___field_terminal_image___localFile___ino',
  relationships___field_terminal_image___localFile___blocks = 'relationships___field_terminal_image___localFile___blocks',
  relationships___field_terminal_image___localFile___atimeMs = 'relationships___field_terminal_image___localFile___atimeMs',
  relationships___field_terminal_image___localFile___mtimeMs = 'relationships___field_terminal_image___localFile___mtimeMs',
  relationships___field_terminal_image___localFile___ctimeMs = 'relationships___field_terminal_image___localFile___ctimeMs',
  relationships___field_terminal_image___localFile___atime = 'relationships___field_terminal_image___localFile___atime',
  relationships___field_terminal_image___localFile___mtime = 'relationships___field_terminal_image___localFile___mtime',
  relationships___field_terminal_image___localFile___ctime = 'relationships___field_terminal_image___localFile___ctime',
  relationships___field_terminal_image___localFile___url = 'relationships___field_terminal_image___localFile___url',
  relationships___field_terminal_image___localFile___id = 'relationships___field_terminal_image___localFile___id',
  relationships___field_terminal_image___localFile___children = 'relationships___field_terminal_image___localFile___children',
  relationships___field_terminal_image___uri___url = 'relationships___field_terminal_image___uri___url',
  relationships___field_terminal_image___drupal_id = 'relationships___field_terminal_image___drupal_id',
  relationships___field_terminal_image___drupal_internal__fid = 'relationships___field_terminal_image___drupal_internal__fid',
  relationships___field_terminal_image___langcode = 'relationships___field_terminal_image___langcode',
  relationships___field_terminal_image___filename = 'relationships___field_terminal_image___filename',
  relationships___field_terminal_image___filemime = 'relationships___field_terminal_image___filemime',
  relationships___field_terminal_image___filesize = 'relationships___field_terminal_image___filesize',
  relationships___field_terminal_image___status = 'relationships___field_terminal_image___status',
  relationships___field_terminal_image___created = 'relationships___field_terminal_image___created',
  relationships___field_terminal_image___changed = 'relationships___field_terminal_image___changed',
  relationships___field_terminal_image___uuid = 'relationships___field_terminal_image___uuid',
  relationships___field_terminal_image___relationships___node__blog_post = 'relationships___field_terminal_image___relationships___node__blog_post',
  relationships___field_terminal_image___relationships___node__devices = 'relationships___field_terminal_image___relationships___node__devices',
  relationships___field_terminal_image___relationships___node__accessories = 'relationships___field_terminal_image___relationships___node__accessories',
  relationships___field_terminal_image___relationships___paragraph__news_image_title_text = 'relationships___field_terminal_image___relationships___paragraph__news_image_title_text',
  relationships___field_terminal_image___relationships___node__cases = 'relationships___field_terminal_image___relationships___node__cases',
  relationships___field_terminal_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_terminal_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_terminal_image___relationships___paragraph__branches_features = 'relationships___field_terminal_image___relationships___paragraph__branches_features',
  relationships___field_terminal_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_terminal_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_terminal_image___relationships___paragraph__branches_testimonials = 'relationships___field_terminal_image___relationships___paragraph__branches_testimonials',
  relationships___field_terminal_image___relationships___paragraph__branches_discounts = 'relationships___field_terminal_image___relationships___paragraph__branches_discounts',
  relationships___field_terminal_image___relationships___node__news = 'relationships___field_terminal_image___relationships___node__news',
  relationships___field_terminal_image___relationships___node__branches = 'relationships___field_terminal_image___relationships___node__branches',
  relationships___field_terminal_image___relationships___paragraph__branches_hero = 'relationships___field_terminal_image___relationships___paragraph__branches_hero',
  relationships___field_terminal_image___relationships___paragraph__bullets = 'relationships___field_terminal_image___relationships___paragraph__bullets',
  relationships___field_terminal_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_terminal_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_terminal_image___relationships___paragraph__branches_device = 'relationships___field_terminal_image___relationships___paragraph__branches_device',
  relationships___field_terminal_image___relationships___paragraph__branches_recommendation = 'relationships___field_terminal_image___relationships___paragraph__branches_recommendation',
  relationships___field_terminal_image___relationships___site_setting_entity__accessories = 'relationships___field_terminal_image___relationships___site_setting_entity__accessories',
  relationships___field_terminal_image___relationships___site_setting_entity__branches = 'relationships___field_terminal_image___relationships___site_setting_entity__branches',
  relationships___field_terminal_image___relationships___site_setting_entity__about_us = 'relationships___field_terminal_image___relationships___site_setting_entity__about_us',
  relationships___field_terminal_image___relationships___paragraph__logos = 'relationships___field_terminal_image___relationships___paragraph__logos',
  relationships___field_terminal_image___relationships___paragraph__how_it_works = 'relationships___field_terminal_image___relationships___paragraph__how_it_works',
  relationships___field_terminal_image___relationships___paragraph__ico_and_text = 'relationships___field_terminal_image___relationships___paragraph__ico_and_text',
  relationships___field_terminal_image___relationships___paragraph__hero_1 = 'relationships___field_terminal_image___relationships___paragraph__hero_1',
  relationships___field_terminal_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_terminal_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_terminal_image___relationships___site_setting_entity__product = 'relationships___field_terminal_image___relationships___site_setting_entity__product',
  relationships___field_terminal_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_terminal_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_terminal_image___relationships___paragraph__porownanie_terminali = 'relationships___field_terminal_image___relationships___paragraph__porownanie_terminali',
  relationships___field_terminal_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_terminal_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_terminal_image___relationships___site_setting_entity__main_page = 'relationships___field_terminal_image___relationships___site_setting_entity__main_page',
  relationships___field_terminal_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_terminal_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_terminal_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_terminal_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_terminal_image___relationships___site_setting_entity__intercars = 'relationships___field_terminal_image___relationships___site_setting_entity__intercars',
  relationships___field_terminal_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_terminal_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_terminal_image___relationships___node__agreement = 'relationships___field_terminal_image___relationships___node__agreement',
  relationships___field_terminal_image___relationships___node__fiscalization = 'relationships___field_terminal_image___relationships___node__fiscalization',
  relationships___field_terminal_image___relationships___site_setting_entity__configurator = 'relationships___field_terminal_image___relationships___site_setting_entity__configurator',
  relationships___field_terminal_image___relationships___paragraph__social_link = 'relationships___field_terminal_image___relationships___paragraph__social_link',
  relationships___field_terminal_image___relationships___paragraph__make_an_appointment = 'relationships___field_terminal_image___relationships___paragraph__make_an_appointment',
  relationships___field_terminal_image___relationships___paragraph__linked_icons = 'relationships___field_terminal_image___relationships___paragraph__linked_icons',
  relationships___field_terminal_image___relationships___site_setting_entity__financing = 'relationships___field_terminal_image___relationships___site_setting_entity__financing',
  relationships___field_terminal_image___relationships___site_setting_entity__franchise = 'relationships___field_terminal_image___relationships___site_setting_entity__franchise',
  relationships___field_terminal_image___id = 'relationships___field_terminal_image___id',
  relationships___field_terminal_image___parent___id = 'relationships___field_terminal_image___parent___id',
  relationships___field_terminal_image___parent___children = 'relationships___field_terminal_image___parent___children',
  relationships___field_terminal_image___children = 'relationships___field_terminal_image___children',
  relationships___field_terminal_image___children___id = 'relationships___field_terminal_image___children___id',
  relationships___field_terminal_image___children___children = 'relationships___field_terminal_image___children___children',
  relationships___field_terminal_image___internal___content = 'relationships___field_terminal_image___internal___content',
  relationships___field_terminal_image___internal___contentDigest = 'relationships___field_terminal_image___internal___contentDigest',
  relationships___field_terminal_image___internal___description = 'relationships___field_terminal_image___internal___description',
  relationships___field_terminal_image___internal___fieldOwners = 'relationships___field_terminal_image___internal___fieldOwners',
  relationships___field_terminal_image___internal___ignoreType = 'relationships___field_terminal_image___internal___ignoreType',
  relationships___field_terminal_image___internal___mediaType = 'relationships___field_terminal_image___internal___mediaType',
  relationships___field_terminal_image___internal___owner = 'relationships___field_terminal_image___internal___owner',
  relationships___field_terminal_image___internal___type = 'relationships___field_terminal_image___internal___type',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Terminal_Models_ItemFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  field_terminal_band_text?: Maybe<StringQueryOperatorInput>;
  field_button_text?: Maybe<StringQueryOperatorInput>;
  field_terminal_description?: Maybe<Field_Terminal_DescriptionFilterInput>;
  field_terminal_image?: Maybe<Field_Terminal_ImageFilterInput>;
  relationships?: Maybe<Paragraph__Terminal_Models_ItemRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Terminal_Models_ItemFilterListInput = {
  elemMatch?: Maybe<Paragraph__Terminal_Models_ItemFilterInput>;
};

export type Paragraph__Terminal_Models_ItemGroupConnection = {
  __typename?: 'paragraph__terminal_models_itemGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Terminal_Models_ItemEdge>;
  nodes: Array<Paragraph__Terminal_Models_Item>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Terminal_Models_ItemRelationships = {
  __typename?: 'paragraph__terminal_models_itemRelationships';
  field_terminal_image?: Maybe<File__File>;
};

export type Paragraph__Terminal_Models_ItemRelationshipsFilterInput = {
  field_terminal_image?: Maybe<File__FileFilterInput>;
};

export type Paragraph__Terminal_Models_ItemSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Terminal_Models_ItemFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Terminal_ModelsConnection = {
  __typename?: 'paragraph__terminal_modelsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Terminal_ModelsEdge>;
  nodes: Array<Paragraph__Terminal_Models>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Terminal_ModelsGroupConnection>;
};

export type Paragraph__Terminal_ModelsConnectionDistinctArgs = {
  field: Paragraph__Terminal_ModelsFieldsEnum;
};

export type Paragraph__Terminal_ModelsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Terminal_ModelsFieldsEnum;
};

export type Paragraph__Terminal_ModelsEdge = {
  __typename?: 'paragraph__terminal_modelsEdge';
  next?: Maybe<Paragraph__Terminal_Models>;
  node: Paragraph__Terminal_Models;
  previous?: Maybe<Paragraph__Terminal_Models>;
};

export enum Paragraph__Terminal_ModelsFieldsEnum {
  field_terminals_title___value = 'field_terminals_title___value',
  field_terminals_description___value = 'field_terminals_description___value',
  relationships___field_terminals_items = 'relationships___field_terminals_items',
  relationships___field_terminals_items___internal___content = 'relationships___field_terminals_items___internal___content',
  relationships___field_terminals_items___internal___contentDigest = 'relationships___field_terminals_items___internal___contentDigest',
  relationships___field_terminals_items___internal___description = 'relationships___field_terminals_items___internal___description',
  relationships___field_terminals_items___internal___fieldOwners = 'relationships___field_terminals_items___internal___fieldOwners',
  relationships___field_terminals_items___internal___ignoreType = 'relationships___field_terminals_items___internal___ignoreType',
  relationships___field_terminals_items___internal___mediaType = 'relationships___field_terminals_items___internal___mediaType',
  relationships___field_terminals_items___internal___owner = 'relationships___field_terminals_items___internal___owner',
  relationships___field_terminals_items___internal___type = 'relationships___field_terminals_items___internal___type',
  relationships___field_terminals_items___field_terminal_band_text = 'relationships___field_terminals_items___field_terminal_band_text',
  relationships___field_terminals_items___field_button_text = 'relationships___field_terminals_items___field_button_text',
  relationships___field_terminals_items___field_terminal_description___value = 'relationships___field_terminals_items___field_terminal_description___value',
  relationships___field_terminals_items___field_terminal_image___alt = 'relationships___field_terminals_items___field_terminal_image___alt',
  relationships___field_terminals_items___id = 'relationships___field_terminals_items___id',
  relationships___field_terminals_items___parent___id = 'relationships___field_terminals_items___parent___id',
  relationships___field_terminals_items___parent___children = 'relationships___field_terminals_items___parent___children',
  relationships___field_terminals_items___children = 'relationships___field_terminals_items___children',
  relationships___field_terminals_items___children___id = 'relationships___field_terminals_items___children___id',
  relationships___field_terminals_items___children___children = 'relationships___field_terminals_items___children___children',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Terminal_ModelsFilterInput = {
  field_terminals_title?: Maybe<Field_Terminals_TitleFilterInput>;
  field_terminals_description?: Maybe<Field_Terminals_DescriptionFilterInput>;
  relationships?: Maybe<Paragraph__Terminal_ModelsRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Terminal_ModelsGroupConnection = {
  __typename?: 'paragraph__terminal_modelsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Terminal_ModelsEdge>;
  nodes: Array<Paragraph__Terminal_Models>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Terminal_ModelsRelationships = {
  __typename?: 'paragraph__terminal_modelsRelationships';
  field_terminals_items?: Maybe<Array<Maybe<Paragraph__Terminal_Models_Item>>>;
};

export type Paragraph__Terminal_ModelsRelationshipsFilterInput = {
  field_terminals_items?: Maybe<Paragraph__Terminal_Models_ItemFilterListInput>;
};

export type Paragraph__Terminal_ModelsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Terminal_ModelsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Text_Button = Node & {
  __typename?: 'paragraph__text_button';
  internal: Internal;
  id: Scalars['ID'];
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_text?: Maybe<Field_Text>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Text_ButtonRelationships>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__Text_ButtonCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Text_ButtonConnection = {
  __typename?: 'paragraph__text_buttonConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Text_ButtonEdge>;
  nodes: Array<Paragraph__Text_Button>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Text_ButtonGroupConnection>;
};

export type Paragraph__Text_ButtonConnectionDistinctArgs = {
  field: Paragraph__Text_ButtonFieldsEnum;
};

export type Paragraph__Text_ButtonConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Text_ButtonFieldsEnum;
};

export type Paragraph__Text_ButtonEdge = {
  __typename?: 'paragraph__text_buttonEdge';
  next?: Maybe<Paragraph__Text_Button>;
  node: Paragraph__Text_Button;
  previous?: Maybe<Paragraph__Text_Button>;
};

export enum Paragraph__Text_ButtonFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  field_nc_button_text = 'field_nc_button_text',
  field_text___value = 'field_text___value',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  relationships___site_setting_entity__accessories = 'relationships___site_setting_entity__accessories',
  relationships___site_setting_entity__accessories___langcode = 'relationships___site_setting_entity__accessories___langcode',
  relationships___site_setting_entity__accessories___field_meta_description = 'relationships___site_setting_entity__accessories___field_meta_description',
  relationships___site_setting_entity__accessories___field_meta_title = 'relationships___site_setting_entity__accessories___field_meta_title',
  relationships___site_setting_entity__accessories___drupal_id = 'relationships___site_setting_entity__accessories___drupal_id',
  relationships___site_setting_entity__accessories___field_hero_button_text = 'relationships___site_setting_entity__accessories___field_hero_button_text',
  relationships___site_setting_entity__accessories___field_hero_headline___value = 'relationships___site_setting_entity__accessories___field_hero_headline___value',
  relationships___site_setting_entity__accessories___field_hero_text___value = 'relationships___site_setting_entity__accessories___field_hero_text___value',
  relationships___site_setting_entity__accessories___drupal_internal__id = 'relationships___site_setting_entity__accessories___drupal_internal__id',
  relationships___site_setting_entity__accessories___name = 'relationships___site_setting_entity__accessories___name',
  relationships___site_setting_entity__accessories___fieldset = 'relationships___site_setting_entity__accessories___fieldset',
  relationships___site_setting_entity__accessories___status = 'relationships___site_setting_entity__accessories___status',
  relationships___site_setting_entity__accessories___created = 'relationships___site_setting_entity__accessories___created',
  relationships___site_setting_entity__accessories___changed = 'relationships___site_setting_entity__accessories___changed',
  relationships___site_setting_entity__accessories___default_langcode = 'relationships___site_setting_entity__accessories___default_langcode',
  relationships___site_setting_entity__accessories___content_translation_source = 'relationships___site_setting_entity__accessories___content_translation_source',
  relationships___site_setting_entity__accessories___content_translation_outdated = 'relationships___site_setting_entity__accessories___content_translation_outdated',
  relationships___site_setting_entity__accessories___content_translation_status = 'relationships___site_setting_entity__accessories___content_translation_status',
  relationships___site_setting_entity__accessories___content_translation_created = 'relationships___site_setting_entity__accessories___content_translation_created',
  relationships___site_setting_entity__accessories___content_translation_changed = 'relationships___site_setting_entity__accessories___content_translation_changed',
  relationships___site_setting_entity__accessories___uuid = 'relationships___site_setting_entity__accessories___uuid',
  relationships___site_setting_entity__accessories___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_components = 'relationships___site_setting_entity__accessories___field_components',
  relationships___site_setting_entity__accessories___field_components___target_revision_id = 'relationships___site_setting_entity__accessories___field_components___target_revision_id',
  relationships___site_setting_entity__accessories___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_hero_image___alt = 'relationships___site_setting_entity__accessories___field_hero_image___alt',
  relationships___site_setting_entity__accessories___field_hero_image___title = 'relationships___site_setting_entity__accessories___field_hero_image___title',
  relationships___site_setting_entity__accessories___field_hero_image___width = 'relationships___site_setting_entity__accessories___field_hero_image___width',
  relationships___site_setting_entity__accessories___field_hero_image___height = 'relationships___site_setting_entity__accessories___field_hero_image___height',
  relationships___site_setting_entity__accessories___field_hero_image___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_hero_image___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___alt = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___alt',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___title = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___title',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___width = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___width',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___height = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___height',
  relationships___site_setting_entity__accessories___field_hero_image_mobile___drupal_internal__target_id = 'relationships___site_setting_entity__accessories___field_hero_image_mobile___drupal_internal__target_id',
  relationships___site_setting_entity__accessories___id = 'relationships___site_setting_entity__accessories___id',
  relationships___site_setting_entity__accessories___parent___id = 'relationships___site_setting_entity__accessories___parent___id',
  relationships___site_setting_entity__accessories___parent___children = 'relationships___site_setting_entity__accessories___parent___children',
  relationships___site_setting_entity__accessories___children = 'relationships___site_setting_entity__accessories___children',
  relationships___site_setting_entity__accessories___children___id = 'relationships___site_setting_entity__accessories___children___id',
  relationships___site_setting_entity__accessories___children___children = 'relationships___site_setting_entity__accessories___children___children',
  relationships___site_setting_entity__accessories___internal___content = 'relationships___site_setting_entity__accessories___internal___content',
  relationships___site_setting_entity__accessories___internal___contentDigest = 'relationships___site_setting_entity__accessories___internal___contentDigest',
  relationships___site_setting_entity__accessories___internal___description = 'relationships___site_setting_entity__accessories___internal___description',
  relationships___site_setting_entity__accessories___internal___fieldOwners = 'relationships___site_setting_entity__accessories___internal___fieldOwners',
  relationships___site_setting_entity__accessories___internal___ignoreType = 'relationships___site_setting_entity__accessories___internal___ignoreType',
  relationships___site_setting_entity__accessories___internal___mediaType = 'relationships___site_setting_entity__accessories___internal___mediaType',
  relationships___site_setting_entity__accessories___internal___owner = 'relationships___site_setting_entity__accessories___internal___owner',
  relationships___site_setting_entity__accessories___internal___type = 'relationships___site_setting_entity__accessories___internal___type',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__Text_ButtonFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Text_ButtonRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__Text_ButtonGroupConnection = {
  __typename?: 'paragraph__text_buttonGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Text_ButtonEdge>;
  nodes: Array<Paragraph__Text_Button>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Text_ButtonRelationships = {
  __typename?: 'paragraph__text_buttonRelationships';
  site_setting_entity__accessories?: Maybe<Array<Maybe<Site_Setting_Entity__Accessories>>>;
};

export type Paragraph__Text_ButtonRelationshipsFilterInput = {
  site_setting_entity__accessories?: Maybe<Site_Setting_Entity__AccessoriesFilterListInput>;
};

export type Paragraph__Text_ButtonSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Text_ButtonFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Text_Link = Node & {
  __typename?: 'paragraph__text_link';
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__Text_LinkRelationships>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Text_LinkCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Text_LinkConnection = {
  __typename?: 'paragraph__text_linkConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Text_LinkEdge>;
  nodes: Array<Paragraph__Text_Link>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Text_LinkGroupConnection>;
};

export type Paragraph__Text_LinkConnectionDistinctArgs = {
  field: Paragraph__Text_LinkFieldsEnum;
};

export type Paragraph__Text_LinkConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Text_LinkFieldsEnum;
};

export type Paragraph__Text_LinkEdge = {
  __typename?: 'paragraph__text_linkEdge';
  next?: Maybe<Paragraph__Text_Link>;
  node: Paragraph__Text_Link;
  previous?: Maybe<Paragraph__Text_Link>;
};

export enum Paragraph__Text_LinkFieldsEnum {
  field_nc_button_text = 'field_nc_button_text',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Text_LinkFilterInput = {
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Text_LinkRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Text_LinkGroupConnection = {
  __typename?: 'paragraph__text_linkGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Text_LinkEdge>;
  nodes: Array<Paragraph__Text_Link>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Text_LinkRelationships = {
  __typename?: 'paragraph__text_linkRelationships';
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
};

export type Paragraph__Text_LinkRelationshipsFilterInput = {
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
};

export type Paragraph__Text_LinkSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Text_LinkFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Title_Icons = Node & {
  __typename?: 'paragraph__title_icons';
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  relationships?: Maybe<Paragraph__Title_IconsRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  field_ico_and_text?: Maybe<Array<Maybe<Paragraph__Title_IconsField_Ico_And_Text>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Title_IconsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Title_IconsConnection = {
  __typename?: 'paragraph__title_iconsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Title_IconsEdge>;
  nodes: Array<Paragraph__Title_Icons>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Title_IconsGroupConnection>;
};

export type Paragraph__Title_IconsConnectionDistinctArgs = {
  field: Paragraph__Title_IconsFieldsEnum;
};

export type Paragraph__Title_IconsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Title_IconsFieldsEnum;
};

export type Paragraph__Title_IconsEdge = {
  __typename?: 'paragraph__title_iconsEdge';
  next?: Maybe<Paragraph__Title_Icons>;
  node: Paragraph__Title_Icons;
  previous?: Maybe<Paragraph__Title_Icons>;
};

export type Paragraph__Title_IconsField_Ico_And_Text = {
  __typename?: 'paragraph__title_iconsField_ico_and_text';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Paragraph__Title_IconsField_Ico_And_TextFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Paragraph__Title_IconsField_Ico_And_TextFilterListInput = {
  elemMatch?: Maybe<Paragraph__Title_IconsField_Ico_And_TextFilterInput>;
};

export enum Paragraph__Title_IconsFieldsEnum {
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  relationships___field_ico_and_text = 'relationships___field_ico_and_text',
  relationships___field_ico_and_text___field_text___value = 'relationships___field_ico_and_text___field_text___value',
  relationships___field_ico_and_text___field_ico___alt = 'relationships___field_ico_and_text___field_ico___alt',
  relationships___field_ico_and_text___relationships___paragraph__title_icons = 'relationships___field_ico_and_text___relationships___paragraph__title_icons',
  relationships___field_ico_and_text___relationships___site_setting_entity__product = 'relationships___field_ico_and_text___relationships___site_setting_entity__product',
  relationships___field_ico_and_text___drupal_id = 'relationships___field_ico_and_text___drupal_id',
  relationships___field_ico_and_text___drupal_internal__id = 'relationships___field_ico_and_text___drupal_internal__id',
  relationships___field_ico_and_text___drupal_internal__revision_id = 'relationships___field_ico_and_text___drupal_internal__revision_id',
  relationships___field_ico_and_text___langcode = 'relationships___field_ico_and_text___langcode',
  relationships___field_ico_and_text___status = 'relationships___field_ico_and_text___status',
  relationships___field_ico_and_text___created = 'relationships___field_ico_and_text___created',
  relationships___field_ico_and_text___parent_id = 'relationships___field_ico_and_text___parent_id',
  relationships___field_ico_and_text___parent_type = 'relationships___field_ico_and_text___parent_type',
  relationships___field_ico_and_text___parent_field_name = 'relationships___field_ico_and_text___parent_field_name',
  relationships___field_ico_and_text___default_langcode = 'relationships___field_ico_and_text___default_langcode',
  relationships___field_ico_and_text___revision_translation_affected = 'relationships___field_ico_and_text___revision_translation_affected',
  relationships___field_ico_and_text___content_translation_source = 'relationships___field_ico_and_text___content_translation_source',
  relationships___field_ico_and_text___content_translation_outdated = 'relationships___field_ico_and_text___content_translation_outdated',
  relationships___field_ico_and_text___uuid = 'relationships___field_ico_and_text___uuid',
  relationships___field_ico_and_text___id = 'relationships___field_ico_and_text___id',
  relationships___field_ico_and_text___parent___id = 'relationships___field_ico_and_text___parent___id',
  relationships___field_ico_and_text___parent___children = 'relationships___field_ico_and_text___parent___children',
  relationships___field_ico_and_text___children = 'relationships___field_ico_and_text___children',
  relationships___field_ico_and_text___children___id = 'relationships___field_ico_and_text___children___id',
  relationships___field_ico_and_text___children___children = 'relationships___field_ico_and_text___children___children',
  relationships___field_ico_and_text___internal___content = 'relationships___field_ico_and_text___internal___content',
  relationships___field_ico_and_text___internal___contentDigest = 'relationships___field_ico_and_text___internal___contentDigest',
  relationships___field_ico_and_text___internal___description = 'relationships___field_ico_and_text___internal___description',
  relationships___field_ico_and_text___internal___fieldOwners = 'relationships___field_ico_and_text___internal___fieldOwners',
  relationships___field_ico_and_text___internal___ignoreType = 'relationships___field_ico_and_text___internal___ignoreType',
  relationships___field_ico_and_text___internal___mediaType = 'relationships___field_ico_and_text___internal___mediaType',
  relationships___field_ico_and_text___internal___owner = 'relationships___field_ico_and_text___internal___owner',
  relationships___field_ico_and_text___internal___type = 'relationships___field_ico_and_text___internal___type',
  relationships___site_setting_entity__franchise = 'relationships___site_setting_entity__franchise',
  relationships___site_setting_entity__franchise___langcode = 'relationships___site_setting_entity__franchise___langcode',
  relationships___site_setting_entity__franchise___field_fr_form_branch = 'relationships___site_setting_entity__franchise___field_fr_form_branch',
  relationships___site_setting_entity__franchise___field_fr_form_email = 'relationships___site_setting_entity__franchise___field_fr_form_email',
  relationships___site_setting_entity__franchise___field_fr_form_header = 'relationships___site_setting_entity__franchise___field_fr_form_header',
  relationships___site_setting_entity__franchise___field_fr_form_name = 'relationships___site_setting_entity__franchise___field_fr_form_name',
  relationships___site_setting_entity__franchise___field_fr_form_network_name = 'relationships___site_setting_entity__franchise___field_fr_form_network_name',
  relationships___site_setting_entity__franchise___field_fr_form_phone = 'relationships___site_setting_entity__franchise___field_fr_form_phone',
  relationships___site_setting_entity__franchise___field_fr_form_shops_number = 'relationships___site_setting_entity__franchise___field_fr_form_shops_number',
  relationships___site_setting_entity__franchise___field_settings_button_text = 'relationships___site_setting_entity__franchise___field_settings_button_text',
  relationships___site_setting_entity__franchise___field_settings_text_1 = 'relationships___site_setting_entity__franchise___field_settings_text_1',
  relationships___site_setting_entity__franchise___field_settings_text_2 = 'relationships___site_setting_entity__franchise___field_settings_text_2',
  relationships___site_setting_entity__franchise___field_settings_text_3 = 'relationships___site_setting_entity__franchise___field_settings_text_3',
  relationships___site_setting_entity__franchise___field_settings_text_4 = 'relationships___site_setting_entity__franchise___field_settings_text_4',
  relationships___site_setting_entity__franchise___field_settings_text_5 = 'relationships___site_setting_entity__franchise___field_settings_text_5',
  relationships___site_setting_entity__franchise___field_settings_text_6 = 'relationships___site_setting_entity__franchise___field_settings_text_6',
  relationships___site_setting_entity__franchise___field_settings_text_7 = 'relationships___site_setting_entity__franchise___field_settings_text_7',
  relationships___site_setting_entity__franchise___field_settings_text_8 = 'relationships___site_setting_entity__franchise___field_settings_text_8',
  relationships___site_setting_entity__franchise___field_settings_text_9 = 'relationships___site_setting_entity__franchise___field_settings_text_9',
  relationships___site_setting_entity__franchise___field_settings_text_10 = 'relationships___site_setting_entity__franchise___field_settings_text_10',
  relationships___site_setting_entity__franchise___field_text_1___value = 'relationships___site_setting_entity__franchise___field_text_1___value',
  relationships___site_setting_entity__franchise___field_meta_description = 'relationships___site_setting_entity__franchise___field_meta_description',
  relationships___site_setting_entity__franchise___field_meta_title = 'relationships___site_setting_entity__franchise___field_meta_title',
  relationships___site_setting_entity__franchise___field_modal_1___value = 'relationships___site_setting_entity__franchise___field_modal_1___value',
  relationships___site_setting_entity__franchise___field_modal_2___value = 'relationships___site_setting_entity__franchise___field_modal_2___value',
  relationships___site_setting_entity__franchise___drupal_id = 'relationships___site_setting_entity__franchise___drupal_id',
  relationships___site_setting_entity__franchise___drupal_internal__id = 'relationships___site_setting_entity__franchise___drupal_internal__id',
  relationships___site_setting_entity__franchise___name = 'relationships___site_setting_entity__franchise___name',
  relationships___site_setting_entity__franchise___fieldset = 'relationships___site_setting_entity__franchise___fieldset',
  relationships___site_setting_entity__franchise___status = 'relationships___site_setting_entity__franchise___status',
  relationships___site_setting_entity__franchise___created = 'relationships___site_setting_entity__franchise___created',
  relationships___site_setting_entity__franchise___changed = 'relationships___site_setting_entity__franchise___changed',
  relationships___site_setting_entity__franchise___default_langcode = 'relationships___site_setting_entity__franchise___default_langcode',
  relationships___site_setting_entity__franchise___content_translation_source = 'relationships___site_setting_entity__franchise___content_translation_source',
  relationships___site_setting_entity__franchise___content_translation_outdated = 'relationships___site_setting_entity__franchise___content_translation_outdated',
  relationships___site_setting_entity__franchise___content_translation_status = 'relationships___site_setting_entity__franchise___content_translation_status',
  relationships___site_setting_entity__franchise___content_translation_created = 'relationships___site_setting_entity__franchise___content_translation_created',
  relationships___site_setting_entity__franchise___content_translation_changed = 'relationships___site_setting_entity__franchise___content_translation_changed',
  relationships___site_setting_entity__franchise___uuid = 'relationships___site_setting_entity__franchise___uuid',
  relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_components = 'relationships___site_setting_entity__franchise___field_components',
  relationships___site_setting_entity__franchise___field_components___target_revision_id = 'relationships___site_setting_entity__franchise___field_components___target_revision_id',
  relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___field_main_image___alt = 'relationships___site_setting_entity__franchise___field_main_image___alt',
  relationships___site_setting_entity__franchise___field_main_image___title = 'relationships___site_setting_entity__franchise___field_main_image___title',
  relationships___site_setting_entity__franchise___field_main_image___width = 'relationships___site_setting_entity__franchise___field_main_image___width',
  relationships___site_setting_entity__franchise___field_main_image___height = 'relationships___site_setting_entity__franchise___field_main_image___height',
  relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id = 'relationships___site_setting_entity__franchise___field_main_image___drupal_internal__target_id',
  relationships___site_setting_entity__franchise___id = 'relationships___site_setting_entity__franchise___id',
  relationships___site_setting_entity__franchise___parent___id = 'relationships___site_setting_entity__franchise___parent___id',
  relationships___site_setting_entity__franchise___parent___children = 'relationships___site_setting_entity__franchise___parent___children',
  relationships___site_setting_entity__franchise___children = 'relationships___site_setting_entity__franchise___children',
  relationships___site_setting_entity__franchise___children___id = 'relationships___site_setting_entity__franchise___children___id',
  relationships___site_setting_entity__franchise___children___children = 'relationships___site_setting_entity__franchise___children___children',
  relationships___site_setting_entity__franchise___internal___content = 'relationships___site_setting_entity__franchise___internal___content',
  relationships___site_setting_entity__franchise___internal___contentDigest = 'relationships___site_setting_entity__franchise___internal___contentDigest',
  relationships___site_setting_entity__franchise___internal___description = 'relationships___site_setting_entity__franchise___internal___description',
  relationships___site_setting_entity__franchise___internal___fieldOwners = 'relationships___site_setting_entity__franchise___internal___fieldOwners',
  relationships___site_setting_entity__franchise___internal___ignoreType = 'relationships___site_setting_entity__franchise___internal___ignoreType',
  relationships___site_setting_entity__franchise___internal___mediaType = 'relationships___site_setting_entity__franchise___internal___mediaType',
  relationships___site_setting_entity__franchise___internal___owner = 'relationships___site_setting_entity__franchise___internal___owner',
  relationships___site_setting_entity__franchise___internal___type = 'relationships___site_setting_entity__franchise___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  field_ico_and_text = 'field_ico_and_text',
  field_ico_and_text___target_revision_id = 'field_ico_and_text___target_revision_id',
  field_ico_and_text___drupal_internal__target_id = 'field_ico_and_text___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Title_IconsFilterInput = {
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  relationships?: Maybe<Paragraph__Title_IconsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_ico_and_text?: Maybe<Paragraph__Title_IconsField_Ico_And_TextFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Title_IconsFilterListInput = {
  elemMatch?: Maybe<Paragraph__Title_IconsFilterInput>;
};

export type Paragraph__Title_IconsGroupConnection = {
  __typename?: 'paragraph__title_iconsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Title_IconsEdge>;
  nodes: Array<Paragraph__Title_Icons>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Title_IconsRelationships = {
  __typename?: 'paragraph__title_iconsRelationships';
  field_ico_and_text?: Maybe<Array<Maybe<Paragraph__Ico_And_Text>>>;
  site_setting_entity__franchise?: Maybe<Array<Maybe<Site_Setting_Entity__Franchise>>>;
};

export type Paragraph__Title_IconsRelationshipsFilterInput = {
  field_ico_and_text?: Maybe<Paragraph__Ico_And_TextFilterListInput>;
  site_setting_entity__franchise?: Maybe<Site_Setting_Entity__FranchiseFilterListInput>;
};

export type Paragraph__Title_IconsSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Title_IconsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Video = Node & {
  __typename?: 'paragraph__video';
  internal: Internal;
  id: Scalars['ID'];
  drupal_id?: Maybe<Scalars['String']>;
  field_header?: Maybe<Scalars['String']>;
  field_video_text?: Maybe<Field_Video_Text>;
  field_thumbnail?: Maybe<Field_Image>;
  field_video_video?: Maybe<Scalars['String']>;
  relationships?: Maybe<Paragraph__VideoRelationships>;
  parent?: Maybe<Node>;
  children: Array<Node>;
};

export type Paragraph__VideoConnection = {
  __typename?: 'paragraph__videoConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__VideoEdge>;
  nodes: Array<Paragraph__Video>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__VideoGroupConnection>;
};

export type Paragraph__VideoConnectionDistinctArgs = {
  field: Paragraph__VideoFieldsEnum;
};

export type Paragraph__VideoConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__VideoFieldsEnum;
};

export type Paragraph__VideoEdge = {
  __typename?: 'paragraph__videoEdge';
  next?: Maybe<Paragraph__Video>;
  node: Paragraph__Video;
  previous?: Maybe<Paragraph__Video>;
};

export enum Paragraph__VideoFieldsEnum {
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  id = 'id',
  drupal_id = 'drupal_id',
  field_header = 'field_header',
  field_video_text___value = 'field_video_text___value',
  field_thumbnail___alt = 'field_thumbnail___alt',
  field_video_video = 'field_video_video',
  relationships___field_thumbnail___localFile___birthtime = 'relationships___field_thumbnail___localFile___birthtime',
  relationships___field_thumbnail___localFile___birthtimeMs = 'relationships___field_thumbnail___localFile___birthtimeMs',
  relationships___field_thumbnail___localFile___publicURL = 'relationships___field_thumbnail___localFile___publicURL',
  relationships___field_thumbnail___localFile___sourceInstanceName = 'relationships___field_thumbnail___localFile___sourceInstanceName',
  relationships___field_thumbnail___localFile___absolutePath = 'relationships___field_thumbnail___localFile___absolutePath',
  relationships___field_thumbnail___localFile___relativePath = 'relationships___field_thumbnail___localFile___relativePath',
  relationships___field_thumbnail___localFile___extension = 'relationships___field_thumbnail___localFile___extension',
  relationships___field_thumbnail___localFile___size = 'relationships___field_thumbnail___localFile___size',
  relationships___field_thumbnail___localFile___prettySize = 'relationships___field_thumbnail___localFile___prettySize',
  relationships___field_thumbnail___localFile___modifiedTime = 'relationships___field_thumbnail___localFile___modifiedTime',
  relationships___field_thumbnail___localFile___accessTime = 'relationships___field_thumbnail___localFile___accessTime',
  relationships___field_thumbnail___localFile___changeTime = 'relationships___field_thumbnail___localFile___changeTime',
  relationships___field_thumbnail___localFile___birthTime = 'relationships___field_thumbnail___localFile___birthTime',
  relationships___field_thumbnail___localFile___root = 'relationships___field_thumbnail___localFile___root',
  relationships___field_thumbnail___localFile___dir = 'relationships___field_thumbnail___localFile___dir',
  relationships___field_thumbnail___localFile___base = 'relationships___field_thumbnail___localFile___base',
  relationships___field_thumbnail___localFile___ext = 'relationships___field_thumbnail___localFile___ext',
  relationships___field_thumbnail___localFile___name = 'relationships___field_thumbnail___localFile___name',
  relationships___field_thumbnail___localFile___relativeDirectory = 'relationships___field_thumbnail___localFile___relativeDirectory',
  relationships___field_thumbnail___localFile___dev = 'relationships___field_thumbnail___localFile___dev',
  relationships___field_thumbnail___localFile___mode = 'relationships___field_thumbnail___localFile___mode',
  relationships___field_thumbnail___localFile___nlink = 'relationships___field_thumbnail___localFile___nlink',
  relationships___field_thumbnail___localFile___uid = 'relationships___field_thumbnail___localFile___uid',
  relationships___field_thumbnail___localFile___gid = 'relationships___field_thumbnail___localFile___gid',
  relationships___field_thumbnail___localFile___rdev = 'relationships___field_thumbnail___localFile___rdev',
  relationships___field_thumbnail___localFile___blksize = 'relationships___field_thumbnail___localFile___blksize',
  relationships___field_thumbnail___localFile___ino = 'relationships___field_thumbnail___localFile___ino',
  relationships___field_thumbnail___localFile___blocks = 'relationships___field_thumbnail___localFile___blocks',
  relationships___field_thumbnail___localFile___atimeMs = 'relationships___field_thumbnail___localFile___atimeMs',
  relationships___field_thumbnail___localFile___mtimeMs = 'relationships___field_thumbnail___localFile___mtimeMs',
  relationships___field_thumbnail___localFile___ctimeMs = 'relationships___field_thumbnail___localFile___ctimeMs',
  relationships___field_thumbnail___localFile___atime = 'relationships___field_thumbnail___localFile___atime',
  relationships___field_thumbnail___localFile___mtime = 'relationships___field_thumbnail___localFile___mtime',
  relationships___field_thumbnail___localFile___ctime = 'relationships___field_thumbnail___localFile___ctime',
  relationships___field_thumbnail___localFile___url = 'relationships___field_thumbnail___localFile___url',
  relationships___field_thumbnail___localFile___id = 'relationships___field_thumbnail___localFile___id',
  relationships___field_thumbnail___localFile___children = 'relationships___field_thumbnail___localFile___children',
  relationships___field_thumbnail___uri___url = 'relationships___field_thumbnail___uri___url',
  relationships___field_thumbnail___drupal_id = 'relationships___field_thumbnail___drupal_id',
  relationships___field_thumbnail___drupal_internal__fid = 'relationships___field_thumbnail___drupal_internal__fid',
  relationships___field_thumbnail___langcode = 'relationships___field_thumbnail___langcode',
  relationships___field_thumbnail___filename = 'relationships___field_thumbnail___filename',
  relationships___field_thumbnail___filemime = 'relationships___field_thumbnail___filemime',
  relationships___field_thumbnail___filesize = 'relationships___field_thumbnail___filesize',
  relationships___field_thumbnail___status = 'relationships___field_thumbnail___status',
  relationships___field_thumbnail___created = 'relationships___field_thumbnail___created',
  relationships___field_thumbnail___changed = 'relationships___field_thumbnail___changed',
  relationships___field_thumbnail___uuid = 'relationships___field_thumbnail___uuid',
  relationships___field_thumbnail___relationships___node__blog_post = 'relationships___field_thumbnail___relationships___node__blog_post',
  relationships___field_thumbnail___relationships___node__devices = 'relationships___field_thumbnail___relationships___node__devices',
  relationships___field_thumbnail___relationships___node__accessories = 'relationships___field_thumbnail___relationships___node__accessories',
  relationships___field_thumbnail___relationships___paragraph__news_image_title_text = 'relationships___field_thumbnail___relationships___paragraph__news_image_title_text',
  relationships___field_thumbnail___relationships___node__cases = 'relationships___field_thumbnail___relationships___node__cases',
  relationships___field_thumbnail___relationships___paragraph__branches_what_is_ipos = 'relationships___field_thumbnail___relationships___paragraph__branches_what_is_ipos',
  relationships___field_thumbnail___relationships___paragraph__branches_features = 'relationships___field_thumbnail___relationships___paragraph__branches_features',
  relationships___field_thumbnail___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_thumbnail___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_thumbnail___relationships___paragraph__branches_testimonials = 'relationships___field_thumbnail___relationships___paragraph__branches_testimonials',
  relationships___field_thumbnail___relationships___paragraph__branches_discounts = 'relationships___field_thumbnail___relationships___paragraph__branches_discounts',
  relationships___field_thumbnail___relationships___node__news = 'relationships___field_thumbnail___relationships___node__news',
  relationships___field_thumbnail___relationships___node__branches = 'relationships___field_thumbnail___relationships___node__branches',
  relationships___field_thumbnail___relationships___paragraph__branches_hero = 'relationships___field_thumbnail___relationships___paragraph__branches_hero',
  relationships___field_thumbnail___relationships___paragraph__bullets = 'relationships___field_thumbnail___relationships___paragraph__bullets',
  relationships___field_thumbnail___relationships___taxonomy_term__pricelist_group = 'relationships___field_thumbnail___relationships___taxonomy_term__pricelist_group',
  relationships___field_thumbnail___relationships___paragraph__branches_device = 'relationships___field_thumbnail___relationships___paragraph__branches_device',
  relationships___field_thumbnail___relationships___paragraph__branches_recommendation = 'relationships___field_thumbnail___relationships___paragraph__branches_recommendation',
  relationships___field_thumbnail___relationships___site_setting_entity__accessories = 'relationships___field_thumbnail___relationships___site_setting_entity__accessories',
  relationships___field_thumbnail___relationships___site_setting_entity__branches = 'relationships___field_thumbnail___relationships___site_setting_entity__branches',
  relationships___field_thumbnail___relationships___site_setting_entity__about_us = 'relationships___field_thumbnail___relationships___site_setting_entity__about_us',
  relationships___field_thumbnail___relationships___paragraph__logos = 'relationships___field_thumbnail___relationships___paragraph__logos',
  relationships___field_thumbnail___relationships___paragraph__how_it_works = 'relationships___field_thumbnail___relationships___paragraph__how_it_works',
  relationships___field_thumbnail___relationships___paragraph__ico_and_text = 'relationships___field_thumbnail___relationships___paragraph__ico_and_text',
  relationships___field_thumbnail___relationships___paragraph__hero_1 = 'relationships___field_thumbnail___relationships___paragraph__hero_1',
  relationships___field_thumbnail___relationships___paragraph__comprehensive_solution_item = 'relationships___field_thumbnail___relationships___paragraph__comprehensive_solution_item',
  relationships___field_thumbnail___relationships___site_setting_entity__product = 'relationships___field_thumbnail___relationships___site_setting_entity__product',
  relationships___field_thumbnail___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_thumbnail___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_thumbnail___relationships___paragraph__porownanie_terminali = 'relationships___field_thumbnail___relationships___paragraph__porownanie_terminali',
  relationships___field_thumbnail___relationships___paragraph__zdjecie_opis_link = 'relationships___field_thumbnail___relationships___paragraph__zdjecie_opis_link',
  relationships___field_thumbnail___relationships___site_setting_entity__main_page = 'relationships___field_thumbnail___relationships___site_setting_entity__main_page',
  relationships___field_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_thumbnail___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_thumbnail___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_thumbnail___relationships___site_setting_entity__intercars = 'relationships___field_thumbnail___relationships___site_setting_entity__intercars',
  relationships___field_thumbnail___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_thumbnail___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_thumbnail___relationships___node__agreement = 'relationships___field_thumbnail___relationships___node__agreement',
  relationships___field_thumbnail___relationships___node__fiscalization = 'relationships___field_thumbnail___relationships___node__fiscalization',
  relationships___field_thumbnail___relationships___site_setting_entity__configurator = 'relationships___field_thumbnail___relationships___site_setting_entity__configurator',
  relationships___field_thumbnail___relationships___paragraph__social_link = 'relationships___field_thumbnail___relationships___paragraph__social_link',
  relationships___field_thumbnail___relationships___paragraph__make_an_appointment = 'relationships___field_thumbnail___relationships___paragraph__make_an_appointment',
  relationships___field_thumbnail___relationships___paragraph__linked_icons = 'relationships___field_thumbnail___relationships___paragraph__linked_icons',
  relationships___field_thumbnail___relationships___site_setting_entity__financing = 'relationships___field_thumbnail___relationships___site_setting_entity__financing',
  relationships___field_thumbnail___relationships___site_setting_entity__franchise = 'relationships___field_thumbnail___relationships___site_setting_entity__franchise',
  relationships___field_thumbnail___id = 'relationships___field_thumbnail___id',
  relationships___field_thumbnail___parent___id = 'relationships___field_thumbnail___parent___id',
  relationships___field_thumbnail___parent___children = 'relationships___field_thumbnail___parent___children',
  relationships___field_thumbnail___children = 'relationships___field_thumbnail___children',
  relationships___field_thumbnail___children___id = 'relationships___field_thumbnail___children___id',
  relationships___field_thumbnail___children___children = 'relationships___field_thumbnail___children___children',
  relationships___field_thumbnail___internal___content = 'relationships___field_thumbnail___internal___content',
  relationships___field_thumbnail___internal___contentDigest = 'relationships___field_thumbnail___internal___contentDigest',
  relationships___field_thumbnail___internal___description = 'relationships___field_thumbnail___internal___description',
  relationships___field_thumbnail___internal___fieldOwners = 'relationships___field_thumbnail___internal___fieldOwners',
  relationships___field_thumbnail___internal___ignoreType = 'relationships___field_thumbnail___internal___ignoreType',
  relationships___field_thumbnail___internal___mediaType = 'relationships___field_thumbnail___internal___mediaType',
  relationships___field_thumbnail___internal___owner = 'relationships___field_thumbnail___internal___owner',
  relationships___field_thumbnail___internal___type = 'relationships___field_thumbnail___internal___type',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
}

export type Paragraph__VideoFilterInput = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_header?: Maybe<StringQueryOperatorInput>;
  field_video_text?: Maybe<Field_Video_TextFilterInput>;
  field_thumbnail?: Maybe<Field_ImageFilterInput>;
  field_video_video?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__VideoRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type Paragraph__VideoGroupConnection = {
  __typename?: 'paragraph__videoGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__VideoEdge>;
  nodes: Array<Paragraph__Video>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__VideoRelationships = {
  __typename?: 'paragraph__videoRelationships';
  field_thumbnail?: Maybe<File__File>;
};

export type Paragraph__VideoRelationshipsFilterInput = {
  field_thumbnail?: Maybe<File__FileFilterInput>;
};

export type Paragraph__VideoSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__VideoFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Zdjecie_Opis_Link = Node & {
  __typename?: 'paragraph__zdjecie_opis_link';
  field_image?: Maybe<Field_Image>;
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_text?: Maybe<Field_Text>;
  field_hidden_or_disable?: Maybe<Scalars['Boolean']>;
  relationships?: Maybe<Paragraph__Zdjecie_Opis_LinkRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Zdjecie_Opis_LinkCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Zdjecie_Opis_Link_Ico = Node & {
  __typename?: 'paragraph__zdjecie_opis_link_ico';
  field_image?: Maybe<Field_Image>;
  field_image_1?: Maybe<Field_Image_1>;
  field_nc_button_text?: Maybe<Scalars['String']>;
  field_paragraph_headline?: Maybe<Field_Paragraph_Headline>;
  field_text?: Maybe<Field_Text>;
  relationships?: Maybe<Paragraph__Zdjecie_Opis_Link_IcoRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  parent_id?: Maybe<Scalars['String']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_field_name?: Maybe<Scalars['String']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Paragraph__Zdjecie_Opis_Link_IcoCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Paragraph__Zdjecie_Opis_Link_IcoConnection = {
  __typename?: 'paragraph__zdjecie_opis_link_icoConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Zdjecie_Opis_Link_IcoEdge>;
  nodes: Array<Paragraph__Zdjecie_Opis_Link_Ico>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Zdjecie_Opis_Link_IcoGroupConnection>;
};

export type Paragraph__Zdjecie_Opis_Link_IcoConnectionDistinctArgs = {
  field: Paragraph__Zdjecie_Opis_Link_IcoFieldsEnum;
};

export type Paragraph__Zdjecie_Opis_Link_IcoConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Zdjecie_Opis_Link_IcoFieldsEnum;
};

export type Paragraph__Zdjecie_Opis_Link_IcoEdge = {
  __typename?: 'paragraph__zdjecie_opis_link_icoEdge';
  next?: Maybe<Paragraph__Zdjecie_Opis_Link_Ico>;
  node: Paragraph__Zdjecie_Opis_Link_Ico;
  previous?: Maybe<Paragraph__Zdjecie_Opis_Link_Ico>;
};

export enum Paragraph__Zdjecie_Opis_Link_IcoFieldsEnum {
  field_image___alt = 'field_image___alt',
  field_image_1___alt = 'field_image_1___alt',
  field_nc_button_text = 'field_nc_button_text',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_text___value = 'field_text___value',
  relationships___field_image___localFile___birthtime = 'relationships___field_image___localFile___birthtime',
  relationships___field_image___localFile___birthtimeMs = 'relationships___field_image___localFile___birthtimeMs',
  relationships___field_image___localFile___publicURL = 'relationships___field_image___localFile___publicURL',
  relationships___field_image___localFile___sourceInstanceName = 'relationships___field_image___localFile___sourceInstanceName',
  relationships___field_image___localFile___absolutePath = 'relationships___field_image___localFile___absolutePath',
  relationships___field_image___localFile___relativePath = 'relationships___field_image___localFile___relativePath',
  relationships___field_image___localFile___extension = 'relationships___field_image___localFile___extension',
  relationships___field_image___localFile___size = 'relationships___field_image___localFile___size',
  relationships___field_image___localFile___prettySize = 'relationships___field_image___localFile___prettySize',
  relationships___field_image___localFile___modifiedTime = 'relationships___field_image___localFile___modifiedTime',
  relationships___field_image___localFile___accessTime = 'relationships___field_image___localFile___accessTime',
  relationships___field_image___localFile___changeTime = 'relationships___field_image___localFile___changeTime',
  relationships___field_image___localFile___birthTime = 'relationships___field_image___localFile___birthTime',
  relationships___field_image___localFile___root = 'relationships___field_image___localFile___root',
  relationships___field_image___localFile___dir = 'relationships___field_image___localFile___dir',
  relationships___field_image___localFile___base = 'relationships___field_image___localFile___base',
  relationships___field_image___localFile___ext = 'relationships___field_image___localFile___ext',
  relationships___field_image___localFile___name = 'relationships___field_image___localFile___name',
  relationships___field_image___localFile___relativeDirectory = 'relationships___field_image___localFile___relativeDirectory',
  relationships___field_image___localFile___dev = 'relationships___field_image___localFile___dev',
  relationships___field_image___localFile___mode = 'relationships___field_image___localFile___mode',
  relationships___field_image___localFile___nlink = 'relationships___field_image___localFile___nlink',
  relationships___field_image___localFile___uid = 'relationships___field_image___localFile___uid',
  relationships___field_image___localFile___gid = 'relationships___field_image___localFile___gid',
  relationships___field_image___localFile___rdev = 'relationships___field_image___localFile___rdev',
  relationships___field_image___localFile___blksize = 'relationships___field_image___localFile___blksize',
  relationships___field_image___localFile___ino = 'relationships___field_image___localFile___ino',
  relationships___field_image___localFile___blocks = 'relationships___field_image___localFile___blocks',
  relationships___field_image___localFile___atimeMs = 'relationships___field_image___localFile___atimeMs',
  relationships___field_image___localFile___mtimeMs = 'relationships___field_image___localFile___mtimeMs',
  relationships___field_image___localFile___ctimeMs = 'relationships___field_image___localFile___ctimeMs',
  relationships___field_image___localFile___atime = 'relationships___field_image___localFile___atime',
  relationships___field_image___localFile___mtime = 'relationships___field_image___localFile___mtime',
  relationships___field_image___localFile___ctime = 'relationships___field_image___localFile___ctime',
  relationships___field_image___localFile___url = 'relationships___field_image___localFile___url',
  relationships___field_image___localFile___id = 'relationships___field_image___localFile___id',
  relationships___field_image___localFile___children = 'relationships___field_image___localFile___children',
  relationships___field_image___uri___url = 'relationships___field_image___uri___url',
  relationships___field_image___drupal_id = 'relationships___field_image___drupal_id',
  relationships___field_image___drupal_internal__fid = 'relationships___field_image___drupal_internal__fid',
  relationships___field_image___langcode = 'relationships___field_image___langcode',
  relationships___field_image___filename = 'relationships___field_image___filename',
  relationships___field_image___filemime = 'relationships___field_image___filemime',
  relationships___field_image___filesize = 'relationships___field_image___filesize',
  relationships___field_image___status = 'relationships___field_image___status',
  relationships___field_image___created = 'relationships___field_image___created',
  relationships___field_image___changed = 'relationships___field_image___changed',
  relationships___field_image___uuid = 'relationships___field_image___uuid',
  relationships___field_image___relationships___node__blog_post = 'relationships___field_image___relationships___node__blog_post',
  relationships___field_image___relationships___node__devices = 'relationships___field_image___relationships___node__devices',
  relationships___field_image___relationships___node__accessories = 'relationships___field_image___relationships___node__accessories',
  relationships___field_image___relationships___paragraph__news_image_title_text = 'relationships___field_image___relationships___paragraph__news_image_title_text',
  relationships___field_image___relationships___node__cases = 'relationships___field_image___relationships___node__cases',
  relationships___field_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image___relationships___paragraph__branches_features = 'relationships___field_image___relationships___paragraph__branches_features',
  relationships___field_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image___relationships___paragraph__branches_testimonials = 'relationships___field_image___relationships___paragraph__branches_testimonials',
  relationships___field_image___relationships___paragraph__branches_discounts = 'relationships___field_image___relationships___paragraph__branches_discounts',
  relationships___field_image___relationships___node__news = 'relationships___field_image___relationships___node__news',
  relationships___field_image___relationships___node__branches = 'relationships___field_image___relationships___node__branches',
  relationships___field_image___relationships___paragraph__branches_hero = 'relationships___field_image___relationships___paragraph__branches_hero',
  relationships___field_image___relationships___paragraph__bullets = 'relationships___field_image___relationships___paragraph__bullets',
  relationships___field_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_image___relationships___paragraph__branches_device = 'relationships___field_image___relationships___paragraph__branches_device',
  relationships___field_image___relationships___paragraph__branches_recommendation = 'relationships___field_image___relationships___paragraph__branches_recommendation',
  relationships___field_image___relationships___site_setting_entity__accessories = 'relationships___field_image___relationships___site_setting_entity__accessories',
  relationships___field_image___relationships___site_setting_entity__branches = 'relationships___field_image___relationships___site_setting_entity__branches',
  relationships___field_image___relationships___site_setting_entity__about_us = 'relationships___field_image___relationships___site_setting_entity__about_us',
  relationships___field_image___relationships___paragraph__logos = 'relationships___field_image___relationships___paragraph__logos',
  relationships___field_image___relationships___paragraph__how_it_works = 'relationships___field_image___relationships___paragraph__how_it_works',
  relationships___field_image___relationships___paragraph__ico_and_text = 'relationships___field_image___relationships___paragraph__ico_and_text',
  relationships___field_image___relationships___paragraph__hero_1 = 'relationships___field_image___relationships___paragraph__hero_1',
  relationships___field_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image___relationships___site_setting_entity__product = 'relationships___field_image___relationships___site_setting_entity__product',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image___relationships___paragraph__porownanie_terminali = 'relationships___field_image___relationships___paragraph__porownanie_terminali',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image___relationships___site_setting_entity__main_page = 'relationships___field_image___relationships___site_setting_entity__main_page',
  relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image___relationships___site_setting_entity__intercars = 'relationships___field_image___relationships___site_setting_entity__intercars',
  relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image___relationships___node__agreement = 'relationships___field_image___relationships___node__agreement',
  relationships___field_image___relationships___node__fiscalization = 'relationships___field_image___relationships___node__fiscalization',
  relationships___field_image___relationships___site_setting_entity__configurator = 'relationships___field_image___relationships___site_setting_entity__configurator',
  relationships___field_image___relationships___paragraph__social_link = 'relationships___field_image___relationships___paragraph__social_link',
  relationships___field_image___relationships___paragraph__make_an_appointment = 'relationships___field_image___relationships___paragraph__make_an_appointment',
  relationships___field_image___relationships___paragraph__linked_icons = 'relationships___field_image___relationships___paragraph__linked_icons',
  relationships___field_image___relationships___site_setting_entity__financing = 'relationships___field_image___relationships___site_setting_entity__financing',
  relationships___field_image___relationships___site_setting_entity__franchise = 'relationships___field_image___relationships___site_setting_entity__franchise',
  relationships___field_image___id = 'relationships___field_image___id',
  relationships___field_image___parent___id = 'relationships___field_image___parent___id',
  relationships___field_image___parent___children = 'relationships___field_image___parent___children',
  relationships___field_image___children = 'relationships___field_image___children',
  relationships___field_image___children___id = 'relationships___field_image___children___id',
  relationships___field_image___children___children = 'relationships___field_image___children___children',
  relationships___field_image___internal___content = 'relationships___field_image___internal___content',
  relationships___field_image___internal___contentDigest = 'relationships___field_image___internal___contentDigest',
  relationships___field_image___internal___description = 'relationships___field_image___internal___description',
  relationships___field_image___internal___fieldOwners = 'relationships___field_image___internal___fieldOwners',
  relationships___field_image___internal___ignoreType = 'relationships___field_image___internal___ignoreType',
  relationships___field_image___internal___mediaType = 'relationships___field_image___internal___mediaType',
  relationships___field_image___internal___owner = 'relationships___field_image___internal___owner',
  relationships___field_image___internal___type = 'relationships___field_image___internal___type',
  relationships___field_image_1___localFile___birthtime = 'relationships___field_image_1___localFile___birthtime',
  relationships___field_image_1___localFile___birthtimeMs = 'relationships___field_image_1___localFile___birthtimeMs',
  relationships___field_image_1___localFile___publicURL = 'relationships___field_image_1___localFile___publicURL',
  relationships___field_image_1___localFile___sourceInstanceName = 'relationships___field_image_1___localFile___sourceInstanceName',
  relationships___field_image_1___localFile___absolutePath = 'relationships___field_image_1___localFile___absolutePath',
  relationships___field_image_1___localFile___relativePath = 'relationships___field_image_1___localFile___relativePath',
  relationships___field_image_1___localFile___extension = 'relationships___field_image_1___localFile___extension',
  relationships___field_image_1___localFile___size = 'relationships___field_image_1___localFile___size',
  relationships___field_image_1___localFile___prettySize = 'relationships___field_image_1___localFile___prettySize',
  relationships___field_image_1___localFile___modifiedTime = 'relationships___field_image_1___localFile___modifiedTime',
  relationships___field_image_1___localFile___accessTime = 'relationships___field_image_1___localFile___accessTime',
  relationships___field_image_1___localFile___changeTime = 'relationships___field_image_1___localFile___changeTime',
  relationships___field_image_1___localFile___birthTime = 'relationships___field_image_1___localFile___birthTime',
  relationships___field_image_1___localFile___root = 'relationships___field_image_1___localFile___root',
  relationships___field_image_1___localFile___dir = 'relationships___field_image_1___localFile___dir',
  relationships___field_image_1___localFile___base = 'relationships___field_image_1___localFile___base',
  relationships___field_image_1___localFile___ext = 'relationships___field_image_1___localFile___ext',
  relationships___field_image_1___localFile___name = 'relationships___field_image_1___localFile___name',
  relationships___field_image_1___localFile___relativeDirectory = 'relationships___field_image_1___localFile___relativeDirectory',
  relationships___field_image_1___localFile___dev = 'relationships___field_image_1___localFile___dev',
  relationships___field_image_1___localFile___mode = 'relationships___field_image_1___localFile___mode',
  relationships___field_image_1___localFile___nlink = 'relationships___field_image_1___localFile___nlink',
  relationships___field_image_1___localFile___uid = 'relationships___field_image_1___localFile___uid',
  relationships___field_image_1___localFile___gid = 'relationships___field_image_1___localFile___gid',
  relationships___field_image_1___localFile___rdev = 'relationships___field_image_1___localFile___rdev',
  relationships___field_image_1___localFile___blksize = 'relationships___field_image_1___localFile___blksize',
  relationships___field_image_1___localFile___ino = 'relationships___field_image_1___localFile___ino',
  relationships___field_image_1___localFile___blocks = 'relationships___field_image_1___localFile___blocks',
  relationships___field_image_1___localFile___atimeMs = 'relationships___field_image_1___localFile___atimeMs',
  relationships___field_image_1___localFile___mtimeMs = 'relationships___field_image_1___localFile___mtimeMs',
  relationships___field_image_1___localFile___ctimeMs = 'relationships___field_image_1___localFile___ctimeMs',
  relationships___field_image_1___localFile___atime = 'relationships___field_image_1___localFile___atime',
  relationships___field_image_1___localFile___mtime = 'relationships___field_image_1___localFile___mtime',
  relationships___field_image_1___localFile___ctime = 'relationships___field_image_1___localFile___ctime',
  relationships___field_image_1___localFile___url = 'relationships___field_image_1___localFile___url',
  relationships___field_image_1___localFile___id = 'relationships___field_image_1___localFile___id',
  relationships___field_image_1___localFile___children = 'relationships___field_image_1___localFile___children',
  relationships___field_image_1___uri___url = 'relationships___field_image_1___uri___url',
  relationships___field_image_1___drupal_id = 'relationships___field_image_1___drupal_id',
  relationships___field_image_1___drupal_internal__fid = 'relationships___field_image_1___drupal_internal__fid',
  relationships___field_image_1___langcode = 'relationships___field_image_1___langcode',
  relationships___field_image_1___filename = 'relationships___field_image_1___filename',
  relationships___field_image_1___filemime = 'relationships___field_image_1___filemime',
  relationships___field_image_1___filesize = 'relationships___field_image_1___filesize',
  relationships___field_image_1___status = 'relationships___field_image_1___status',
  relationships___field_image_1___created = 'relationships___field_image_1___created',
  relationships___field_image_1___changed = 'relationships___field_image_1___changed',
  relationships___field_image_1___uuid = 'relationships___field_image_1___uuid',
  relationships___field_image_1___relationships___node__blog_post = 'relationships___field_image_1___relationships___node__blog_post',
  relationships___field_image_1___relationships___node__devices = 'relationships___field_image_1___relationships___node__devices',
  relationships___field_image_1___relationships___node__accessories = 'relationships___field_image_1___relationships___node__accessories',
  relationships___field_image_1___relationships___paragraph__news_image_title_text = 'relationships___field_image_1___relationships___paragraph__news_image_title_text',
  relationships___field_image_1___relationships___node__cases = 'relationships___field_image_1___relationships___node__cases',
  relationships___field_image_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_1___relationships___paragraph__branches_features = 'relationships___field_image_1___relationships___paragraph__branches_features',
  relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_1___relationships___paragraph__branches_testimonials = 'relationships___field_image_1___relationships___paragraph__branches_testimonials',
  relationships___field_image_1___relationships___paragraph__branches_discounts = 'relationships___field_image_1___relationships___paragraph__branches_discounts',
  relationships___field_image_1___relationships___node__news = 'relationships___field_image_1___relationships___node__news',
  relationships___field_image_1___relationships___node__branches = 'relationships___field_image_1___relationships___node__branches',
  relationships___field_image_1___relationships___paragraph__branches_hero = 'relationships___field_image_1___relationships___paragraph__branches_hero',
  relationships___field_image_1___relationships___paragraph__bullets = 'relationships___field_image_1___relationships___paragraph__bullets',
  relationships___field_image_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_1___relationships___paragraph__branches_device = 'relationships___field_image_1___relationships___paragraph__branches_device',
  relationships___field_image_1___relationships___paragraph__branches_recommendation = 'relationships___field_image_1___relationships___paragraph__branches_recommendation',
  relationships___field_image_1___relationships___site_setting_entity__accessories = 'relationships___field_image_1___relationships___site_setting_entity__accessories',
  relationships___field_image_1___relationships___site_setting_entity__branches = 'relationships___field_image_1___relationships___site_setting_entity__branches',
  relationships___field_image_1___relationships___site_setting_entity__about_us = 'relationships___field_image_1___relationships___site_setting_entity__about_us',
  relationships___field_image_1___relationships___paragraph__logos = 'relationships___field_image_1___relationships___paragraph__logos',
  relationships___field_image_1___relationships___paragraph__how_it_works = 'relationships___field_image_1___relationships___paragraph__how_it_works',
  relationships___field_image_1___relationships___paragraph__ico_and_text = 'relationships___field_image_1___relationships___paragraph__ico_and_text',
  relationships___field_image_1___relationships___paragraph__hero_1 = 'relationships___field_image_1___relationships___paragraph__hero_1',
  relationships___field_image_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_1___relationships___site_setting_entity__product = 'relationships___field_image_1___relationships___site_setting_entity__product',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_1___relationships___paragraph__porownanie_terminali = 'relationships___field_image_1___relationships___paragraph__porownanie_terminali',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_1___relationships___site_setting_entity__main_page = 'relationships___field_image_1___relationships___site_setting_entity__main_page',
  relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_1___relationships___site_setting_entity__intercars = 'relationships___field_image_1___relationships___site_setting_entity__intercars',
  relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_1___relationships___node__agreement = 'relationships___field_image_1___relationships___node__agreement',
  relationships___field_image_1___relationships___node__fiscalization = 'relationships___field_image_1___relationships___node__fiscalization',
  relationships___field_image_1___relationships___site_setting_entity__configurator = 'relationships___field_image_1___relationships___site_setting_entity__configurator',
  relationships___field_image_1___relationships___paragraph__social_link = 'relationships___field_image_1___relationships___paragraph__social_link',
  relationships___field_image_1___relationships___paragraph__make_an_appointment = 'relationships___field_image_1___relationships___paragraph__make_an_appointment',
  relationships___field_image_1___relationships___paragraph__linked_icons = 'relationships___field_image_1___relationships___paragraph__linked_icons',
  relationships___field_image_1___relationships___site_setting_entity__financing = 'relationships___field_image_1___relationships___site_setting_entity__financing',
  relationships___field_image_1___relationships___site_setting_entity__franchise = 'relationships___field_image_1___relationships___site_setting_entity__franchise',
  relationships___field_image_1___id = 'relationships___field_image_1___id',
  relationships___field_image_1___parent___id = 'relationships___field_image_1___parent___id',
  relationships___field_image_1___parent___children = 'relationships___field_image_1___parent___children',
  relationships___field_image_1___children = 'relationships___field_image_1___children',
  relationships___field_image_1___children___id = 'relationships___field_image_1___children___id',
  relationships___field_image_1___children___children = 'relationships___field_image_1___children___children',
  relationships___field_image_1___internal___content = 'relationships___field_image_1___internal___content',
  relationships___field_image_1___internal___contentDigest = 'relationships___field_image_1___internal___contentDigest',
  relationships___field_image_1___internal___description = 'relationships___field_image_1___internal___description',
  relationships___field_image_1___internal___fieldOwners = 'relationships___field_image_1___internal___fieldOwners',
  relationships___field_image_1___internal___ignoreType = 'relationships___field_image_1___internal___ignoreType',
  relationships___field_image_1___internal___mediaType = 'relationships___field_image_1___internal___mediaType',
  relationships___field_image_1___internal___owner = 'relationships___field_image_1___internal___owner',
  relationships___field_image_1___internal___type = 'relationships___field_image_1___internal___type',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Zdjecie_Opis_Link_IcoFilterInput = {
  field_image?: Maybe<Field_ImageFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  relationships?: Maybe<Paragraph__Zdjecie_Opis_Link_IcoRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Zdjecie_Opis_Link_IcoFilterListInput = {
  elemMatch?: Maybe<Paragraph__Zdjecie_Opis_Link_IcoFilterInput>;
};

export type Paragraph__Zdjecie_Opis_Link_IcoGroupConnection = {
  __typename?: 'paragraph__zdjecie_opis_link_icoGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Zdjecie_Opis_Link_IcoEdge>;
  nodes: Array<Paragraph__Zdjecie_Opis_Link_Ico>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Zdjecie_Opis_Link_IcoRelationships = {
  __typename?: 'paragraph__zdjecie_opis_link_icoRelationships';
  field_image?: Maybe<File__File>;
  field_image_1?: Maybe<File__File>;
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
};

export type Paragraph__Zdjecie_Opis_Link_IcoRelationshipsFilterInput = {
  field_image?: Maybe<File__FileFilterInput>;
  field_image_1?: Maybe<File__FileFilterInput>;
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
};

export type Paragraph__Zdjecie_Opis_Link_IcoSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Zdjecie_Opis_Link_IcoFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Paragraph__Zdjecie_Opis_LinkConnection = {
  __typename?: 'paragraph__zdjecie_opis_linkConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Zdjecie_Opis_LinkEdge>;
  nodes: Array<Paragraph__Zdjecie_Opis_Link>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Paragraph__Zdjecie_Opis_LinkGroupConnection>;
};

export type Paragraph__Zdjecie_Opis_LinkConnectionDistinctArgs = {
  field: Paragraph__Zdjecie_Opis_LinkFieldsEnum;
};

export type Paragraph__Zdjecie_Opis_LinkConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Paragraph__Zdjecie_Opis_LinkFieldsEnum;
};

export type Paragraph__Zdjecie_Opis_LinkEdge = {
  __typename?: 'paragraph__zdjecie_opis_linkEdge';
  next?: Maybe<Paragraph__Zdjecie_Opis_Link>;
  node: Paragraph__Zdjecie_Opis_Link;
  previous?: Maybe<Paragraph__Zdjecie_Opis_Link>;
};

export enum Paragraph__Zdjecie_Opis_LinkFieldsEnum {
  field_image___alt = 'field_image___alt',
  field_nc_button_text = 'field_nc_button_text',
  field_paragraph_headline___value = 'field_paragraph_headline___value',
  field_text___value = 'field_text___value',
  field_hidden_or_disable = 'field_hidden_or_disable',
  relationships___field_image___localFile___birthtime = 'relationships___field_image___localFile___birthtime',
  relationships___field_image___localFile___birthtimeMs = 'relationships___field_image___localFile___birthtimeMs',
  relationships___field_image___localFile___publicURL = 'relationships___field_image___localFile___publicURL',
  relationships___field_image___localFile___sourceInstanceName = 'relationships___field_image___localFile___sourceInstanceName',
  relationships___field_image___localFile___absolutePath = 'relationships___field_image___localFile___absolutePath',
  relationships___field_image___localFile___relativePath = 'relationships___field_image___localFile___relativePath',
  relationships___field_image___localFile___extension = 'relationships___field_image___localFile___extension',
  relationships___field_image___localFile___size = 'relationships___field_image___localFile___size',
  relationships___field_image___localFile___prettySize = 'relationships___field_image___localFile___prettySize',
  relationships___field_image___localFile___modifiedTime = 'relationships___field_image___localFile___modifiedTime',
  relationships___field_image___localFile___accessTime = 'relationships___field_image___localFile___accessTime',
  relationships___field_image___localFile___changeTime = 'relationships___field_image___localFile___changeTime',
  relationships___field_image___localFile___birthTime = 'relationships___field_image___localFile___birthTime',
  relationships___field_image___localFile___root = 'relationships___field_image___localFile___root',
  relationships___field_image___localFile___dir = 'relationships___field_image___localFile___dir',
  relationships___field_image___localFile___base = 'relationships___field_image___localFile___base',
  relationships___field_image___localFile___ext = 'relationships___field_image___localFile___ext',
  relationships___field_image___localFile___name = 'relationships___field_image___localFile___name',
  relationships___field_image___localFile___relativeDirectory = 'relationships___field_image___localFile___relativeDirectory',
  relationships___field_image___localFile___dev = 'relationships___field_image___localFile___dev',
  relationships___field_image___localFile___mode = 'relationships___field_image___localFile___mode',
  relationships___field_image___localFile___nlink = 'relationships___field_image___localFile___nlink',
  relationships___field_image___localFile___uid = 'relationships___field_image___localFile___uid',
  relationships___field_image___localFile___gid = 'relationships___field_image___localFile___gid',
  relationships___field_image___localFile___rdev = 'relationships___field_image___localFile___rdev',
  relationships___field_image___localFile___blksize = 'relationships___field_image___localFile___blksize',
  relationships___field_image___localFile___ino = 'relationships___field_image___localFile___ino',
  relationships___field_image___localFile___blocks = 'relationships___field_image___localFile___blocks',
  relationships___field_image___localFile___atimeMs = 'relationships___field_image___localFile___atimeMs',
  relationships___field_image___localFile___mtimeMs = 'relationships___field_image___localFile___mtimeMs',
  relationships___field_image___localFile___ctimeMs = 'relationships___field_image___localFile___ctimeMs',
  relationships___field_image___localFile___atime = 'relationships___field_image___localFile___atime',
  relationships___field_image___localFile___mtime = 'relationships___field_image___localFile___mtime',
  relationships___field_image___localFile___ctime = 'relationships___field_image___localFile___ctime',
  relationships___field_image___localFile___url = 'relationships___field_image___localFile___url',
  relationships___field_image___localFile___id = 'relationships___field_image___localFile___id',
  relationships___field_image___localFile___children = 'relationships___field_image___localFile___children',
  relationships___field_image___uri___url = 'relationships___field_image___uri___url',
  relationships___field_image___drupal_id = 'relationships___field_image___drupal_id',
  relationships___field_image___drupal_internal__fid = 'relationships___field_image___drupal_internal__fid',
  relationships___field_image___langcode = 'relationships___field_image___langcode',
  relationships___field_image___filename = 'relationships___field_image___filename',
  relationships___field_image___filemime = 'relationships___field_image___filemime',
  relationships___field_image___filesize = 'relationships___field_image___filesize',
  relationships___field_image___status = 'relationships___field_image___status',
  relationships___field_image___created = 'relationships___field_image___created',
  relationships___field_image___changed = 'relationships___field_image___changed',
  relationships___field_image___uuid = 'relationships___field_image___uuid',
  relationships___field_image___relationships___node__blog_post = 'relationships___field_image___relationships___node__blog_post',
  relationships___field_image___relationships___node__devices = 'relationships___field_image___relationships___node__devices',
  relationships___field_image___relationships___node__accessories = 'relationships___field_image___relationships___node__accessories',
  relationships___field_image___relationships___paragraph__news_image_title_text = 'relationships___field_image___relationships___paragraph__news_image_title_text',
  relationships___field_image___relationships___node__cases = 'relationships___field_image___relationships___node__cases',
  relationships___field_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image___relationships___paragraph__branches_features = 'relationships___field_image___relationships___paragraph__branches_features',
  relationships___field_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image___relationships___paragraph__branches_testimonials = 'relationships___field_image___relationships___paragraph__branches_testimonials',
  relationships___field_image___relationships___paragraph__branches_discounts = 'relationships___field_image___relationships___paragraph__branches_discounts',
  relationships___field_image___relationships___node__news = 'relationships___field_image___relationships___node__news',
  relationships___field_image___relationships___node__branches = 'relationships___field_image___relationships___node__branches',
  relationships___field_image___relationships___paragraph__branches_hero = 'relationships___field_image___relationships___paragraph__branches_hero',
  relationships___field_image___relationships___paragraph__bullets = 'relationships___field_image___relationships___paragraph__bullets',
  relationships___field_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_image___relationships___paragraph__branches_device = 'relationships___field_image___relationships___paragraph__branches_device',
  relationships___field_image___relationships___paragraph__branches_recommendation = 'relationships___field_image___relationships___paragraph__branches_recommendation',
  relationships___field_image___relationships___site_setting_entity__accessories = 'relationships___field_image___relationships___site_setting_entity__accessories',
  relationships___field_image___relationships___site_setting_entity__branches = 'relationships___field_image___relationships___site_setting_entity__branches',
  relationships___field_image___relationships___site_setting_entity__about_us = 'relationships___field_image___relationships___site_setting_entity__about_us',
  relationships___field_image___relationships___paragraph__logos = 'relationships___field_image___relationships___paragraph__logos',
  relationships___field_image___relationships___paragraph__how_it_works = 'relationships___field_image___relationships___paragraph__how_it_works',
  relationships___field_image___relationships___paragraph__ico_and_text = 'relationships___field_image___relationships___paragraph__ico_and_text',
  relationships___field_image___relationships___paragraph__hero_1 = 'relationships___field_image___relationships___paragraph__hero_1',
  relationships___field_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image___relationships___site_setting_entity__product = 'relationships___field_image___relationships___site_setting_entity__product',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image___relationships___paragraph__porownanie_terminali = 'relationships___field_image___relationships___paragraph__porownanie_terminali',
  relationships___field_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image___relationships___site_setting_entity__main_page = 'relationships___field_image___relationships___site_setting_entity__main_page',
  relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image___relationships___site_setting_entity__intercars = 'relationships___field_image___relationships___site_setting_entity__intercars',
  relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image___relationships___node__agreement = 'relationships___field_image___relationships___node__agreement',
  relationships___field_image___relationships___node__fiscalization = 'relationships___field_image___relationships___node__fiscalization',
  relationships___field_image___relationships___site_setting_entity__configurator = 'relationships___field_image___relationships___site_setting_entity__configurator',
  relationships___field_image___relationships___paragraph__social_link = 'relationships___field_image___relationships___paragraph__social_link',
  relationships___field_image___relationships___paragraph__make_an_appointment = 'relationships___field_image___relationships___paragraph__make_an_appointment',
  relationships___field_image___relationships___paragraph__linked_icons = 'relationships___field_image___relationships___paragraph__linked_icons',
  relationships___field_image___relationships___site_setting_entity__financing = 'relationships___field_image___relationships___site_setting_entity__financing',
  relationships___field_image___relationships___site_setting_entity__franchise = 'relationships___field_image___relationships___site_setting_entity__franchise',
  relationships___field_image___id = 'relationships___field_image___id',
  relationships___field_image___parent___id = 'relationships___field_image___parent___id',
  relationships___field_image___parent___children = 'relationships___field_image___parent___children',
  relationships___field_image___children = 'relationships___field_image___children',
  relationships___field_image___children___id = 'relationships___field_image___children___id',
  relationships___field_image___children___children = 'relationships___field_image___children___children',
  relationships___field_image___internal___content = 'relationships___field_image___internal___content',
  relationships___field_image___internal___contentDigest = 'relationships___field_image___internal___contentDigest',
  relationships___field_image___internal___description = 'relationships___field_image___internal___description',
  relationships___field_image___internal___fieldOwners = 'relationships___field_image___internal___fieldOwners',
  relationships___field_image___internal___ignoreType = 'relationships___field_image___internal___ignoreType',
  relationships___field_image___internal___mediaType = 'relationships___field_image___internal___mediaType',
  relationships___field_image___internal___owner = 'relationships___field_image___internal___owner',
  relationships___field_image___internal___type = 'relationships___field_image___internal___type',
  relationships___site_setting_entity__product = 'relationships___site_setting_entity__product',
  relationships___site_setting_entity__product___langcode = 'relationships___site_setting_entity__product___langcode',
  relationships___site_setting_entity__product___field_hero_button_text = 'relationships___site_setting_entity__product___field_hero_button_text',
  relationships___site_setting_entity__product___field_hero_video = 'relationships___site_setting_entity__product___field_hero_video',
  relationships___site_setting_entity__product___field_hero_headline___value = 'relationships___site_setting_entity__product___field_hero_headline___value',
  relationships___site_setting_entity__product___field_hero_image___alt = 'relationships___site_setting_entity__product___field_hero_image___alt',
  relationships___site_setting_entity__product___field_hero_image_mobile___alt = 'relationships___site_setting_entity__product___field_hero_image_mobile___alt',
  relationships___site_setting_entity__product___field_hero_text___value = 'relationships___site_setting_entity__product___field_hero_text___value',
  relationships___site_setting_entity__product___field_image_1___alt = 'relationships___site_setting_entity__product___field_image_1___alt',
  relationships___site_setting_entity__product___field_image_2___alt = 'relationships___site_setting_entity__product___field_image_2___alt',
  relationships___site_setting_entity__product___field_image_3___alt = 'relationships___site_setting_entity__product___field_image_3___alt',
  relationships___site_setting_entity__product___field_hero_video_thumbnail___alt = 'relationships___site_setting_entity__product___field_hero_video_thumbnail___alt',
  relationships___site_setting_entity__product___field_kasa_online = 'relationships___site_setting_entity__product___field_kasa_online',
  relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku = 'relationships___site_setting_entity__product___field_porownaj_taryfy_w_cenniku',
  relationships___site_setting_entity__product___field_settings_text_2 = 'relationships___site_setting_entity__product___field_settings_text_2',
  relationships___site_setting_entity__product___field_settings_text_6 = 'relationships___site_setting_entity__product___field_settings_text_6',
  relationships___site_setting_entity__product___field_settings_text_7 = 'relationships___site_setting_entity__product___field_settings_text_7',
  relationships___site_setting_entity__product___field_text_1___value = 'relationships___site_setting_entity__product___field_text_1___value',
  relationships___site_setting_entity__product___field_text_2___value = 'relationships___site_setting_entity__product___field_text_2___value',
  relationships___site_setting_entity__product___field_meta_title = 'relationships___site_setting_entity__product___field_meta_title',
  relationships___site_setting_entity__product___field_meta_description = 'relationships___site_setting_entity__product___field_meta_description',
  relationships___site_setting_entity__product___drupal_id = 'relationships___site_setting_entity__product___drupal_id',
  relationships___site_setting_entity__product___drupal_internal__id = 'relationships___site_setting_entity__product___drupal_internal__id',
  relationships___site_setting_entity__product___name = 'relationships___site_setting_entity__product___name',
  relationships___site_setting_entity__product___fieldset = 'relationships___site_setting_entity__product___fieldset',
  relationships___site_setting_entity__product___status = 'relationships___site_setting_entity__product___status',
  relationships___site_setting_entity__product___created = 'relationships___site_setting_entity__product___created',
  relationships___site_setting_entity__product___changed = 'relationships___site_setting_entity__product___changed',
  relationships___site_setting_entity__product___default_langcode = 'relationships___site_setting_entity__product___default_langcode',
  relationships___site_setting_entity__product___content_translation_source = 'relationships___site_setting_entity__product___content_translation_source',
  relationships___site_setting_entity__product___content_translation_outdated = 'relationships___site_setting_entity__product___content_translation_outdated',
  relationships___site_setting_entity__product___content_translation_status = 'relationships___site_setting_entity__product___content_translation_status',
  relationships___site_setting_entity__product___content_translation_created = 'relationships___site_setting_entity__product___content_translation_created',
  relationships___site_setting_entity__product___content_translation_changed = 'relationships___site_setting_entity__product___content_translation_changed',
  relationships___site_setting_entity__product___uuid = 'relationships___site_setting_entity__product___uuid',
  relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id = 'relationships___site_setting_entity__product___site_setting_entity_type___drupal_internal__target_id',
  relationships___site_setting_entity__product___field_components = 'relationships___site_setting_entity__product___field_components',
  relationships___site_setting_entity__product___field_components___target_revision_id = 'relationships___site_setting_entity__product___field_components___target_revision_id',
  relationships___site_setting_entity__product___field_components___drupal_internal__target_id = 'relationships___site_setting_entity__product___field_components___drupal_internal__target_id',
  relationships___site_setting_entity__product___id = 'relationships___site_setting_entity__product___id',
  relationships___site_setting_entity__product___parent___id = 'relationships___site_setting_entity__product___parent___id',
  relationships___site_setting_entity__product___parent___children = 'relationships___site_setting_entity__product___parent___children',
  relationships___site_setting_entity__product___children = 'relationships___site_setting_entity__product___children',
  relationships___site_setting_entity__product___children___id = 'relationships___site_setting_entity__product___children___id',
  relationships___site_setting_entity__product___children___children = 'relationships___site_setting_entity__product___children___children',
  relationships___site_setting_entity__product___internal___content = 'relationships___site_setting_entity__product___internal___content',
  relationships___site_setting_entity__product___internal___contentDigest = 'relationships___site_setting_entity__product___internal___contentDigest',
  relationships___site_setting_entity__product___internal___description = 'relationships___site_setting_entity__product___internal___description',
  relationships___site_setting_entity__product___internal___fieldOwners = 'relationships___site_setting_entity__product___internal___fieldOwners',
  relationships___site_setting_entity__product___internal___ignoreType = 'relationships___site_setting_entity__product___internal___ignoreType',
  relationships___site_setting_entity__product___internal___mediaType = 'relationships___site_setting_entity__product___internal___mediaType',
  relationships___site_setting_entity__product___internal___owner = 'relationships___site_setting_entity__product___internal___owner',
  relationships___site_setting_entity__product___internal___type = 'relationships___site_setting_entity__product___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  status = 'status',
  created = 'created',
  parent_id = 'parent_id',
  parent_type = 'parent_type',
  parent_field_name = 'parent_field_name',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Paragraph__Zdjecie_Opis_LinkFilterInput = {
  field_image?: Maybe<Field_ImageFilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  field_hidden_or_disable?: Maybe<BooleanQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Zdjecie_Opis_LinkRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Paragraph__Zdjecie_Opis_LinkFilterListInput = {
  elemMatch?: Maybe<Paragraph__Zdjecie_Opis_LinkFilterInput>;
};

export type Paragraph__Zdjecie_Opis_LinkGroupConnection = {
  __typename?: 'paragraph__zdjecie_opis_linkGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Paragraph__Zdjecie_Opis_LinkEdge>;
  nodes: Array<Paragraph__Zdjecie_Opis_Link>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Paragraph__Zdjecie_Opis_LinkRelationships = {
  __typename?: 'paragraph__zdjecie_opis_linkRelationships';
  field_image?: Maybe<File__File>;
  site_setting_entity__product?: Maybe<Array<Maybe<Site_Setting_Entity__Product>>>;
};

export type Paragraph__Zdjecie_Opis_LinkRelationshipsFilterInput = {
  field_image?: Maybe<File__FileFilterInput>;
  site_setting_entity__product?: Maybe<Site_Setting_Entity__ProductFilterListInput>;
};

export type Paragraph__Zdjecie_Opis_LinkSortInput = {
  fields?: Maybe<Array<Maybe<Paragraph__Zdjecie_Opis_LinkFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Path = {
  __typename?: 'path';
  alias?: Maybe<Scalars['String']>;
};

export type PathFilterInput = {
  alias?: Maybe<StringQueryOperatorInput>;
};

export type Potrace = {
  turnPolicy?: Maybe<PotraceTurnPolicy>;
  turdSize?: Maybe<Scalars['Float']>;
  alphaMax?: Maybe<Scalars['Float']>;
  optCurve?: Maybe<Scalars['Boolean']>;
  optTolerance?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Int']>;
  blackOnWhite?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  background?: Maybe<Scalars['String']>;
};

export enum PotraceTurnPolicy {
  TURNPOLICY_BLACK = 'TURNPOLICY_BLACK',
  TURNPOLICY_WHITE = 'TURNPOLICY_WHITE',
  TURNPOLICY_LEFT = 'TURNPOLICY_LEFT',
  TURNPOLICY_RIGHT = 'TURNPOLICY_RIGHT',
  TURNPOLICY_MINORITY = 'TURNPOLICY_MINORITY',
  TURNPOLICY_MAJORITY = 'TURNPOLICY_MAJORITY',
}

export type ProductRelatedComponents =
  | Paragraph__Bullets_Container
  | Paragraph__Branches_Saving_Up_Block
  | Paragraph__Branches_Tariff
  | Paragraph__Ico_And_Text
  | Paragraph__Porownanie_Terminali
  | Paragraph__Text_Link
  | Paragraph__Zdjecie_Opis_Link
  | Paragraph__Zdjecie_Opis_Link_Ico
  | Paragraph__Terminal_Models;

export type ProductsJson = Node & {
  __typename?: 'ProductsJson';
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<File>;
  imageForAccessories?: Maybe<File>;
  hasOnline?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  fiscalization?: Maybe<Scalars['Int']>;
  availableColors?: Maybe<Array<Maybe<ProductsJsonAvailableColors>>>;
};

export type ProductsJsonAvailableColors = {
  __typename?: 'ProductsJsonAvailableColors';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<File>;
  hex?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type ProductsJsonAvailableColorsFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  image?: Maybe<FileFilterInput>;
  hex?: Maybe<StringQueryOperatorInput>;
  price?: Maybe<IntQueryOperatorInput>;
};

export type ProductsJsonAvailableColorsFilterListInput = {
  elemMatch?: Maybe<ProductsJsonAvailableColorsFilterInput>;
};

export type ProductsJsonConnection = {
  __typename?: 'ProductsJsonConnection';
  totalCount: Scalars['Int'];
  edges: Array<ProductsJsonEdge>;
  nodes: Array<ProductsJson>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<ProductsJsonGroupConnection>;
};

export type ProductsJsonConnectionDistinctArgs = {
  field: ProductsJsonFieldsEnum;
};

export type ProductsJsonConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: ProductsJsonFieldsEnum;
};

export type ProductsJsonEdge = {
  __typename?: 'ProductsJsonEdge';
  next?: Maybe<ProductsJson>;
  node: ProductsJson;
  previous?: Maybe<ProductsJson>;
};

export enum ProductsJsonFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  name = 'name',
  image___birthtime = 'image___birthtime',
  image___birthtimeMs = 'image___birthtimeMs',
  image___childImageSharp___id = 'image___childImageSharp___id',
  image___childImageSharp___fixed___base64 = 'image___childImageSharp___fixed___base64',
  image___childImageSharp___fixed___tracedSVG = 'image___childImageSharp___fixed___tracedSVG',
  image___childImageSharp___fixed___aspectRatio = 'image___childImageSharp___fixed___aspectRatio',
  image___childImageSharp___fixed___width = 'image___childImageSharp___fixed___width',
  image___childImageSharp___fixed___height = 'image___childImageSharp___fixed___height',
  image___childImageSharp___fixed___src = 'image___childImageSharp___fixed___src',
  image___childImageSharp___fixed___srcSet = 'image___childImageSharp___fixed___srcSet',
  image___childImageSharp___fixed___srcWebp = 'image___childImageSharp___fixed___srcWebp',
  image___childImageSharp___fixed___srcSetWebp = 'image___childImageSharp___fixed___srcSetWebp',
  image___childImageSharp___fixed___originalName = 'image___childImageSharp___fixed___originalName',
  image___childImageSharp___resolutions___base64 = 'image___childImageSharp___resolutions___base64',
  image___childImageSharp___resolutions___tracedSVG = 'image___childImageSharp___resolutions___tracedSVG',
  image___childImageSharp___resolutions___aspectRatio = 'image___childImageSharp___resolutions___aspectRatio',
  image___childImageSharp___resolutions___width = 'image___childImageSharp___resolutions___width',
  image___childImageSharp___resolutions___height = 'image___childImageSharp___resolutions___height',
  image___childImageSharp___resolutions___src = 'image___childImageSharp___resolutions___src',
  image___childImageSharp___resolutions___srcSet = 'image___childImageSharp___resolutions___srcSet',
  image___childImageSharp___resolutions___srcWebp = 'image___childImageSharp___resolutions___srcWebp',
  image___childImageSharp___resolutions___srcSetWebp = 'image___childImageSharp___resolutions___srcSetWebp',
  image___childImageSharp___resolutions___originalName = 'image___childImageSharp___resolutions___originalName',
  image___childImageSharp___fluid___base64 = 'image___childImageSharp___fluid___base64',
  image___childImageSharp___fluid___tracedSVG = 'image___childImageSharp___fluid___tracedSVG',
  image___childImageSharp___fluid___aspectRatio = 'image___childImageSharp___fluid___aspectRatio',
  image___childImageSharp___fluid___src = 'image___childImageSharp___fluid___src',
  image___childImageSharp___fluid___srcSet = 'image___childImageSharp___fluid___srcSet',
  image___childImageSharp___fluid___srcWebp = 'image___childImageSharp___fluid___srcWebp',
  image___childImageSharp___fluid___srcSetWebp = 'image___childImageSharp___fluid___srcSetWebp',
  image___childImageSharp___fluid___sizes = 'image___childImageSharp___fluid___sizes',
  image___childImageSharp___fluid___originalImg = 'image___childImageSharp___fluid___originalImg',
  image___childImageSharp___fluid___originalName = 'image___childImageSharp___fluid___originalName',
  image___childImageSharp___fluid___presentationWidth = 'image___childImageSharp___fluid___presentationWidth',
  image___childImageSharp___fluid___presentationHeight = 'image___childImageSharp___fluid___presentationHeight',
  image___childImageSharp___sizes___base64 = 'image___childImageSharp___sizes___base64',
  image___childImageSharp___sizes___tracedSVG = 'image___childImageSharp___sizes___tracedSVG',
  image___childImageSharp___sizes___aspectRatio = 'image___childImageSharp___sizes___aspectRatio',
  image___childImageSharp___sizes___src = 'image___childImageSharp___sizes___src',
  image___childImageSharp___sizes___srcSet = 'image___childImageSharp___sizes___srcSet',
  image___childImageSharp___sizes___srcWebp = 'image___childImageSharp___sizes___srcWebp',
  image___childImageSharp___sizes___srcSetWebp = 'image___childImageSharp___sizes___srcSetWebp',
  image___childImageSharp___sizes___sizes = 'image___childImageSharp___sizes___sizes',
  image___childImageSharp___sizes___originalImg = 'image___childImageSharp___sizes___originalImg',
  image___childImageSharp___sizes___originalName = 'image___childImageSharp___sizes___originalName',
  image___childImageSharp___sizes___presentationWidth = 'image___childImageSharp___sizes___presentationWidth',
  image___childImageSharp___sizes___presentationHeight = 'image___childImageSharp___sizes___presentationHeight',
  image___childImageSharp___original___width = 'image___childImageSharp___original___width',
  image___childImageSharp___original___height = 'image___childImageSharp___original___height',
  image___childImageSharp___original___src = 'image___childImageSharp___original___src',
  image___childImageSharp___resize___src = 'image___childImageSharp___resize___src',
  image___childImageSharp___resize___tracedSVG = 'image___childImageSharp___resize___tracedSVG',
  image___childImageSharp___resize___width = 'image___childImageSharp___resize___width',
  image___childImageSharp___resize___height = 'image___childImageSharp___resize___height',
  image___childImageSharp___resize___aspectRatio = 'image___childImageSharp___resize___aspectRatio',
  image___childImageSharp___resize___originalName = 'image___childImageSharp___resize___originalName',
  image___childImageSharp___parent___id = 'image___childImageSharp___parent___id',
  image___childImageSharp___parent___children = 'image___childImageSharp___parent___children',
  image___childImageSharp___children = 'image___childImageSharp___children',
  image___childImageSharp___children___id = 'image___childImageSharp___children___id',
  image___childImageSharp___children___children = 'image___childImageSharp___children___children',
  image___childImageSharp___internal___content = 'image___childImageSharp___internal___content',
  image___childImageSharp___internal___contentDigest = 'image___childImageSharp___internal___contentDigest',
  image___childImageSharp___internal___description = 'image___childImageSharp___internal___description',
  image___childImageSharp___internal___fieldOwners = 'image___childImageSharp___internal___fieldOwners',
  image___childImageSharp___internal___ignoreType = 'image___childImageSharp___internal___ignoreType',
  image___childImageSharp___internal___mediaType = 'image___childImageSharp___internal___mediaType',
  image___childImageSharp___internal___owner = 'image___childImageSharp___internal___owner',
  image___childImageSharp___internal___type = 'image___childImageSharp___internal___type',
  image___publicURL = 'image___publicURL',
  image___sourceInstanceName = 'image___sourceInstanceName',
  image___absolutePath = 'image___absolutePath',
  image___relativePath = 'image___relativePath',
  image___extension = 'image___extension',
  image___size = 'image___size',
  image___prettySize = 'image___prettySize',
  image___modifiedTime = 'image___modifiedTime',
  image___accessTime = 'image___accessTime',
  image___changeTime = 'image___changeTime',
  image___birthTime = 'image___birthTime',
  image___root = 'image___root',
  image___dir = 'image___dir',
  image___base = 'image___base',
  image___ext = 'image___ext',
  image___name = 'image___name',
  image___relativeDirectory = 'image___relativeDirectory',
  image___dev = 'image___dev',
  image___mode = 'image___mode',
  image___nlink = 'image___nlink',
  image___uid = 'image___uid',
  image___gid = 'image___gid',
  image___rdev = 'image___rdev',
  image___blksize = 'image___blksize',
  image___ino = 'image___ino',
  image___blocks = 'image___blocks',
  image___atimeMs = 'image___atimeMs',
  image___mtimeMs = 'image___mtimeMs',
  image___ctimeMs = 'image___ctimeMs',
  image___atime = 'image___atime',
  image___mtime = 'image___mtime',
  image___ctime = 'image___ctime',
  image___url = 'image___url',
  image___id = 'image___id',
  image___parent___id = 'image___parent___id',
  image___parent___parent___id = 'image___parent___parent___id',
  image___parent___parent___children = 'image___parent___parent___children',
  image___parent___children = 'image___parent___children',
  image___parent___children___id = 'image___parent___children___id',
  image___parent___children___children = 'image___parent___children___children',
  image___parent___internal___content = 'image___parent___internal___content',
  image___parent___internal___contentDigest = 'image___parent___internal___contentDigest',
  image___parent___internal___description = 'image___parent___internal___description',
  image___parent___internal___fieldOwners = 'image___parent___internal___fieldOwners',
  image___parent___internal___ignoreType = 'image___parent___internal___ignoreType',
  image___parent___internal___mediaType = 'image___parent___internal___mediaType',
  image___parent___internal___owner = 'image___parent___internal___owner',
  image___parent___internal___type = 'image___parent___internal___type',
  image___children = 'image___children',
  image___children___id = 'image___children___id',
  image___children___parent___id = 'image___children___parent___id',
  image___children___parent___children = 'image___children___parent___children',
  image___children___children = 'image___children___children',
  image___children___children___id = 'image___children___children___id',
  image___children___children___children = 'image___children___children___children',
  image___children___internal___content = 'image___children___internal___content',
  image___children___internal___contentDigest = 'image___children___internal___contentDigest',
  image___children___internal___description = 'image___children___internal___description',
  image___children___internal___fieldOwners = 'image___children___internal___fieldOwners',
  image___children___internal___ignoreType = 'image___children___internal___ignoreType',
  image___children___internal___mediaType = 'image___children___internal___mediaType',
  image___children___internal___owner = 'image___children___internal___owner',
  image___children___internal___type = 'image___children___internal___type',
  image___internal___content = 'image___internal___content',
  image___internal___contentDigest = 'image___internal___contentDigest',
  image___internal___description = 'image___internal___description',
  image___internal___fieldOwners = 'image___internal___fieldOwners',
  image___internal___ignoreType = 'image___internal___ignoreType',
  image___internal___mediaType = 'image___internal___mediaType',
  image___internal___owner = 'image___internal___owner',
  image___internal___type = 'image___internal___type',
  imageForAccessories___birthtime = 'imageForAccessories___birthtime',
  imageForAccessories___birthtimeMs = 'imageForAccessories___birthtimeMs',
  imageForAccessories___childImageSharp___id = 'imageForAccessories___childImageSharp___id',
  imageForAccessories___childImageSharp___fixed___base64 = 'imageForAccessories___childImageSharp___fixed___base64',
  imageForAccessories___childImageSharp___fixed___tracedSVG = 'imageForAccessories___childImageSharp___fixed___tracedSVG',
  imageForAccessories___childImageSharp___fixed___aspectRatio = 'imageForAccessories___childImageSharp___fixed___aspectRatio',
  imageForAccessories___childImageSharp___fixed___width = 'imageForAccessories___childImageSharp___fixed___width',
  imageForAccessories___childImageSharp___fixed___height = 'imageForAccessories___childImageSharp___fixed___height',
  imageForAccessories___childImageSharp___fixed___src = 'imageForAccessories___childImageSharp___fixed___src',
  imageForAccessories___childImageSharp___fixed___srcSet = 'imageForAccessories___childImageSharp___fixed___srcSet',
  imageForAccessories___childImageSharp___fixed___srcWebp = 'imageForAccessories___childImageSharp___fixed___srcWebp',
  imageForAccessories___childImageSharp___fixed___srcSetWebp = 'imageForAccessories___childImageSharp___fixed___srcSetWebp',
  imageForAccessories___childImageSharp___fixed___originalName = 'imageForAccessories___childImageSharp___fixed___originalName',
  imageForAccessories___childImageSharp___resolutions___base64 = 'imageForAccessories___childImageSharp___resolutions___base64',
  imageForAccessories___childImageSharp___resolutions___tracedSVG = 'imageForAccessories___childImageSharp___resolutions___tracedSVG',
  imageForAccessories___childImageSharp___resolutions___aspectRatio = 'imageForAccessories___childImageSharp___resolutions___aspectRatio',
  imageForAccessories___childImageSharp___resolutions___width = 'imageForAccessories___childImageSharp___resolutions___width',
  imageForAccessories___childImageSharp___resolutions___height = 'imageForAccessories___childImageSharp___resolutions___height',
  imageForAccessories___childImageSharp___resolutions___src = 'imageForAccessories___childImageSharp___resolutions___src',
  imageForAccessories___childImageSharp___resolutions___srcSet = 'imageForAccessories___childImageSharp___resolutions___srcSet',
  imageForAccessories___childImageSharp___resolutions___srcWebp = 'imageForAccessories___childImageSharp___resolutions___srcWebp',
  imageForAccessories___childImageSharp___resolutions___srcSetWebp = 'imageForAccessories___childImageSharp___resolutions___srcSetWebp',
  imageForAccessories___childImageSharp___resolutions___originalName = 'imageForAccessories___childImageSharp___resolutions___originalName',
  imageForAccessories___childImageSharp___fluid___base64 = 'imageForAccessories___childImageSharp___fluid___base64',
  imageForAccessories___childImageSharp___fluid___tracedSVG = 'imageForAccessories___childImageSharp___fluid___tracedSVG',
  imageForAccessories___childImageSharp___fluid___aspectRatio = 'imageForAccessories___childImageSharp___fluid___aspectRatio',
  imageForAccessories___childImageSharp___fluid___src = 'imageForAccessories___childImageSharp___fluid___src',
  imageForAccessories___childImageSharp___fluid___srcSet = 'imageForAccessories___childImageSharp___fluid___srcSet',
  imageForAccessories___childImageSharp___fluid___srcWebp = 'imageForAccessories___childImageSharp___fluid___srcWebp',
  imageForAccessories___childImageSharp___fluid___srcSetWebp = 'imageForAccessories___childImageSharp___fluid___srcSetWebp',
  imageForAccessories___childImageSharp___fluid___sizes = 'imageForAccessories___childImageSharp___fluid___sizes',
  imageForAccessories___childImageSharp___fluid___originalImg = 'imageForAccessories___childImageSharp___fluid___originalImg',
  imageForAccessories___childImageSharp___fluid___originalName = 'imageForAccessories___childImageSharp___fluid___originalName',
  imageForAccessories___childImageSharp___fluid___presentationWidth = 'imageForAccessories___childImageSharp___fluid___presentationWidth',
  imageForAccessories___childImageSharp___fluid___presentationHeight = 'imageForAccessories___childImageSharp___fluid___presentationHeight',
  imageForAccessories___childImageSharp___sizes___base64 = 'imageForAccessories___childImageSharp___sizes___base64',
  imageForAccessories___childImageSharp___sizes___tracedSVG = 'imageForAccessories___childImageSharp___sizes___tracedSVG',
  imageForAccessories___childImageSharp___sizes___aspectRatio = 'imageForAccessories___childImageSharp___sizes___aspectRatio',
  imageForAccessories___childImageSharp___sizes___src = 'imageForAccessories___childImageSharp___sizes___src',
  imageForAccessories___childImageSharp___sizes___srcSet = 'imageForAccessories___childImageSharp___sizes___srcSet',
  imageForAccessories___childImageSharp___sizes___srcWebp = 'imageForAccessories___childImageSharp___sizes___srcWebp',
  imageForAccessories___childImageSharp___sizes___srcSetWebp = 'imageForAccessories___childImageSharp___sizes___srcSetWebp',
  imageForAccessories___childImageSharp___sizes___sizes = 'imageForAccessories___childImageSharp___sizes___sizes',
  imageForAccessories___childImageSharp___sizes___originalImg = 'imageForAccessories___childImageSharp___sizes___originalImg',
  imageForAccessories___childImageSharp___sizes___originalName = 'imageForAccessories___childImageSharp___sizes___originalName',
  imageForAccessories___childImageSharp___sizes___presentationWidth = 'imageForAccessories___childImageSharp___sizes___presentationWidth',
  imageForAccessories___childImageSharp___sizes___presentationHeight = 'imageForAccessories___childImageSharp___sizes___presentationHeight',
  imageForAccessories___childImageSharp___original___width = 'imageForAccessories___childImageSharp___original___width',
  imageForAccessories___childImageSharp___original___height = 'imageForAccessories___childImageSharp___original___height',
  imageForAccessories___childImageSharp___original___src = 'imageForAccessories___childImageSharp___original___src',
  imageForAccessories___childImageSharp___resize___src = 'imageForAccessories___childImageSharp___resize___src',
  imageForAccessories___childImageSharp___resize___tracedSVG = 'imageForAccessories___childImageSharp___resize___tracedSVG',
  imageForAccessories___childImageSharp___resize___width = 'imageForAccessories___childImageSharp___resize___width',
  imageForAccessories___childImageSharp___resize___height = 'imageForAccessories___childImageSharp___resize___height',
  imageForAccessories___childImageSharp___resize___aspectRatio = 'imageForAccessories___childImageSharp___resize___aspectRatio',
  imageForAccessories___childImageSharp___resize___originalName = 'imageForAccessories___childImageSharp___resize___originalName',
  imageForAccessories___childImageSharp___parent___id = 'imageForAccessories___childImageSharp___parent___id',
  imageForAccessories___childImageSharp___parent___children = 'imageForAccessories___childImageSharp___parent___children',
  imageForAccessories___childImageSharp___children = 'imageForAccessories___childImageSharp___children',
  imageForAccessories___childImageSharp___children___id = 'imageForAccessories___childImageSharp___children___id',
  imageForAccessories___childImageSharp___children___children = 'imageForAccessories___childImageSharp___children___children',
  imageForAccessories___childImageSharp___internal___content = 'imageForAccessories___childImageSharp___internal___content',
  imageForAccessories___childImageSharp___internal___contentDigest = 'imageForAccessories___childImageSharp___internal___contentDigest',
  imageForAccessories___childImageSharp___internal___description = 'imageForAccessories___childImageSharp___internal___description',
  imageForAccessories___childImageSharp___internal___fieldOwners = 'imageForAccessories___childImageSharp___internal___fieldOwners',
  imageForAccessories___childImageSharp___internal___ignoreType = 'imageForAccessories___childImageSharp___internal___ignoreType',
  imageForAccessories___childImageSharp___internal___mediaType = 'imageForAccessories___childImageSharp___internal___mediaType',
  imageForAccessories___childImageSharp___internal___owner = 'imageForAccessories___childImageSharp___internal___owner',
  imageForAccessories___childImageSharp___internal___type = 'imageForAccessories___childImageSharp___internal___type',
  imageForAccessories___publicURL = 'imageForAccessories___publicURL',
  imageForAccessories___sourceInstanceName = 'imageForAccessories___sourceInstanceName',
  imageForAccessories___absolutePath = 'imageForAccessories___absolutePath',
  imageForAccessories___relativePath = 'imageForAccessories___relativePath',
  imageForAccessories___extension = 'imageForAccessories___extension',
  imageForAccessories___size = 'imageForAccessories___size',
  imageForAccessories___prettySize = 'imageForAccessories___prettySize',
  imageForAccessories___modifiedTime = 'imageForAccessories___modifiedTime',
  imageForAccessories___accessTime = 'imageForAccessories___accessTime',
  imageForAccessories___changeTime = 'imageForAccessories___changeTime',
  imageForAccessories___birthTime = 'imageForAccessories___birthTime',
  imageForAccessories___root = 'imageForAccessories___root',
  imageForAccessories___dir = 'imageForAccessories___dir',
  imageForAccessories___base = 'imageForAccessories___base',
  imageForAccessories___ext = 'imageForAccessories___ext',
  imageForAccessories___name = 'imageForAccessories___name',
  imageForAccessories___relativeDirectory = 'imageForAccessories___relativeDirectory',
  imageForAccessories___dev = 'imageForAccessories___dev',
  imageForAccessories___mode = 'imageForAccessories___mode',
  imageForAccessories___nlink = 'imageForAccessories___nlink',
  imageForAccessories___uid = 'imageForAccessories___uid',
  imageForAccessories___gid = 'imageForAccessories___gid',
  imageForAccessories___rdev = 'imageForAccessories___rdev',
  imageForAccessories___blksize = 'imageForAccessories___blksize',
  imageForAccessories___ino = 'imageForAccessories___ino',
  imageForAccessories___blocks = 'imageForAccessories___blocks',
  imageForAccessories___atimeMs = 'imageForAccessories___atimeMs',
  imageForAccessories___mtimeMs = 'imageForAccessories___mtimeMs',
  imageForAccessories___ctimeMs = 'imageForAccessories___ctimeMs',
  imageForAccessories___atime = 'imageForAccessories___atime',
  imageForAccessories___mtime = 'imageForAccessories___mtime',
  imageForAccessories___ctime = 'imageForAccessories___ctime',
  imageForAccessories___url = 'imageForAccessories___url',
  imageForAccessories___id = 'imageForAccessories___id',
  imageForAccessories___parent___id = 'imageForAccessories___parent___id',
  imageForAccessories___parent___parent___id = 'imageForAccessories___parent___parent___id',
  imageForAccessories___parent___parent___children = 'imageForAccessories___parent___parent___children',
  imageForAccessories___parent___children = 'imageForAccessories___parent___children',
  imageForAccessories___parent___children___id = 'imageForAccessories___parent___children___id',
  imageForAccessories___parent___children___children = 'imageForAccessories___parent___children___children',
  imageForAccessories___parent___internal___content = 'imageForAccessories___parent___internal___content',
  imageForAccessories___parent___internal___contentDigest = 'imageForAccessories___parent___internal___contentDigest',
  imageForAccessories___parent___internal___description = 'imageForAccessories___parent___internal___description',
  imageForAccessories___parent___internal___fieldOwners = 'imageForAccessories___parent___internal___fieldOwners',
  imageForAccessories___parent___internal___ignoreType = 'imageForAccessories___parent___internal___ignoreType',
  imageForAccessories___parent___internal___mediaType = 'imageForAccessories___parent___internal___mediaType',
  imageForAccessories___parent___internal___owner = 'imageForAccessories___parent___internal___owner',
  imageForAccessories___parent___internal___type = 'imageForAccessories___parent___internal___type',
  imageForAccessories___children = 'imageForAccessories___children',
  imageForAccessories___children___id = 'imageForAccessories___children___id',
  imageForAccessories___children___parent___id = 'imageForAccessories___children___parent___id',
  imageForAccessories___children___parent___children = 'imageForAccessories___children___parent___children',
  imageForAccessories___children___children = 'imageForAccessories___children___children',
  imageForAccessories___children___children___id = 'imageForAccessories___children___children___id',
  imageForAccessories___children___children___children = 'imageForAccessories___children___children___children',
  imageForAccessories___children___internal___content = 'imageForAccessories___children___internal___content',
  imageForAccessories___children___internal___contentDigest = 'imageForAccessories___children___internal___contentDigest',
  imageForAccessories___children___internal___description = 'imageForAccessories___children___internal___description',
  imageForAccessories___children___internal___fieldOwners = 'imageForAccessories___children___internal___fieldOwners',
  imageForAccessories___children___internal___ignoreType = 'imageForAccessories___children___internal___ignoreType',
  imageForAccessories___children___internal___mediaType = 'imageForAccessories___children___internal___mediaType',
  imageForAccessories___children___internal___owner = 'imageForAccessories___children___internal___owner',
  imageForAccessories___children___internal___type = 'imageForAccessories___children___internal___type',
  imageForAccessories___internal___content = 'imageForAccessories___internal___content',
  imageForAccessories___internal___contentDigest = 'imageForAccessories___internal___contentDigest',
  imageForAccessories___internal___description = 'imageForAccessories___internal___description',
  imageForAccessories___internal___fieldOwners = 'imageForAccessories___internal___fieldOwners',
  imageForAccessories___internal___ignoreType = 'imageForAccessories___internal___ignoreType',
  imageForAccessories___internal___mediaType = 'imageForAccessories___internal___mediaType',
  imageForAccessories___internal___owner = 'imageForAccessories___internal___owner',
  imageForAccessories___internal___type = 'imageForAccessories___internal___type',
  hasOnline = 'hasOnline',
  description = 'description',
  price = 'price',
  fiscalization = 'fiscalization',
  availableColors = 'availableColors',
  availableColors___id = 'availableColors___id',
  availableColors___name = 'availableColors___name',
  availableColors___image___birthtime = 'availableColors___image___birthtime',
  availableColors___image___birthtimeMs = 'availableColors___image___birthtimeMs',
  availableColors___image___childImageSharp___id = 'availableColors___image___childImageSharp___id',
  availableColors___image___childImageSharp___children = 'availableColors___image___childImageSharp___children',
  availableColors___image___publicURL = 'availableColors___image___publicURL',
  availableColors___image___sourceInstanceName = 'availableColors___image___sourceInstanceName',
  availableColors___image___absolutePath = 'availableColors___image___absolutePath',
  availableColors___image___relativePath = 'availableColors___image___relativePath',
  availableColors___image___extension = 'availableColors___image___extension',
  availableColors___image___size = 'availableColors___image___size',
  availableColors___image___prettySize = 'availableColors___image___prettySize',
  availableColors___image___modifiedTime = 'availableColors___image___modifiedTime',
  availableColors___image___accessTime = 'availableColors___image___accessTime',
  availableColors___image___changeTime = 'availableColors___image___changeTime',
  availableColors___image___birthTime = 'availableColors___image___birthTime',
  availableColors___image___root = 'availableColors___image___root',
  availableColors___image___dir = 'availableColors___image___dir',
  availableColors___image___base = 'availableColors___image___base',
  availableColors___image___ext = 'availableColors___image___ext',
  availableColors___image___name = 'availableColors___image___name',
  availableColors___image___relativeDirectory = 'availableColors___image___relativeDirectory',
  availableColors___image___dev = 'availableColors___image___dev',
  availableColors___image___mode = 'availableColors___image___mode',
  availableColors___image___nlink = 'availableColors___image___nlink',
  availableColors___image___uid = 'availableColors___image___uid',
  availableColors___image___gid = 'availableColors___image___gid',
  availableColors___image___rdev = 'availableColors___image___rdev',
  availableColors___image___blksize = 'availableColors___image___blksize',
  availableColors___image___ino = 'availableColors___image___ino',
  availableColors___image___blocks = 'availableColors___image___blocks',
  availableColors___image___atimeMs = 'availableColors___image___atimeMs',
  availableColors___image___mtimeMs = 'availableColors___image___mtimeMs',
  availableColors___image___ctimeMs = 'availableColors___image___ctimeMs',
  availableColors___image___atime = 'availableColors___image___atime',
  availableColors___image___mtime = 'availableColors___image___mtime',
  availableColors___image___ctime = 'availableColors___image___ctime',
  availableColors___image___url = 'availableColors___image___url',
  availableColors___image___id = 'availableColors___image___id',
  availableColors___image___parent___id = 'availableColors___image___parent___id',
  availableColors___image___parent___children = 'availableColors___image___parent___children',
  availableColors___image___children = 'availableColors___image___children',
  availableColors___image___children___id = 'availableColors___image___children___id',
  availableColors___image___children___children = 'availableColors___image___children___children',
  availableColors___image___internal___content = 'availableColors___image___internal___content',
  availableColors___image___internal___contentDigest = 'availableColors___image___internal___contentDigest',
  availableColors___image___internal___description = 'availableColors___image___internal___description',
  availableColors___image___internal___fieldOwners = 'availableColors___image___internal___fieldOwners',
  availableColors___image___internal___ignoreType = 'availableColors___image___internal___ignoreType',
  availableColors___image___internal___mediaType = 'availableColors___image___internal___mediaType',
  availableColors___image___internal___owner = 'availableColors___image___internal___owner',
  availableColors___image___internal___type = 'availableColors___image___internal___type',
  availableColors___hex = 'availableColors___hex',
  availableColors___price = 'availableColors___price',
}

export type ProductsJsonFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  name?: Maybe<StringQueryOperatorInput>;
  image?: Maybe<FileFilterInput>;
  imageForAccessories?: Maybe<FileFilterInput>;
  hasOnline?: Maybe<BooleanQueryOperatorInput>;
  description?: Maybe<StringQueryOperatorInput>;
  price?: Maybe<IntQueryOperatorInput>;
  fiscalization?: Maybe<IntQueryOperatorInput>;
  availableColors?: Maybe<ProductsJsonAvailableColorsFilterListInput>;
};

export type ProductsJsonGroupConnection = {
  __typename?: 'ProductsJsonGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<ProductsJsonEdge>;
  nodes: Array<ProductsJson>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type ProductsJsonSortInput = {
  fields?: Maybe<Array<Maybe<ProductsJsonFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Query = {
  __typename?: 'Query';
  imageSharp?: Maybe<ImageSharp>;
  allImageSharp?: Maybe<ImageSharpConnection>;
  file?: Maybe<File>;
  allFile?: Maybe<FileConnection>;
  fileFile?: Maybe<File__File>;
  allFileFile?: Maybe<File__FileConnection>;
  childImageSharpFixed?: Maybe<ChildImageSharpFixed>;
  allChildImageSharpFixed?: Maybe<ChildImageSharpFixedConnection>;
  fileFileUri?: Maybe<File__FileUri>;
  allFileFileUri?: Maybe<File__FileUriConnection>;
  paragraphHero1?: Maybe<Paragraph__Hero_1>;
  allParagraphHero1?: Maybe<Paragraph__Hero_1Connection>;
  paragraphBranchesRecommendation?: Maybe<Paragraph__Branches_Recommendation>;
  allParagraphBranchesRecommendation?: Maybe<Paragraph__Branches_RecommendationConnection>;
  paragraphComprehensiveSolution?: Maybe<Paragraph__Comprehensive_Solution>;
  allParagraphComprehensiveSolution?: Maybe<Paragraph__Comprehensive_SolutionConnection>;
  paragraphComprehensiveSolutionItem?: Maybe<Paragraph__Comprehensive_Solution_Item>;
  allParagraphComprehensiveSolutionItem?: Maybe<Paragraph__Comprehensive_Solution_ItemConnection>;
  paragraphBulletsContainer?: Maybe<Paragraph__Bullets_Container>;
  allParagraphBulletsContainer?: Maybe<Paragraph__Bullets_ContainerConnection>;
  paragraphBullets?: Maybe<Paragraph__Bullets>;
  allParagraphBullets?: Maybe<Paragraph__BulletsConnection>;
  paragraphBranchesSavingUpBlock?: Maybe<Paragraph__Branches_Saving_Up_Block>;
  allParagraphBranchesSavingUpBlock?: Maybe<Paragraph__Branches_Saving_Up_BlockConnection>;
  paragraph2ZdjeciaOpisyLink?: Maybe<Paragraph__2_Zdjecia_Opisy_Link>;
  allParagraph2ZdjeciaOpisyLink?: Maybe<Paragraph__2_Zdjecia_Opisy_LinkConnection>;
  paragraphIcoAndText?: Maybe<Paragraph__Ico_And_Text>;
  allParagraphIcoAndText?: Maybe<Paragraph__Ico_And_TextConnection>;
  paragraphTextLink?: Maybe<Paragraph__Text_Link>;
  allParagraphTextLink?: Maybe<Paragraph__Text_LinkConnection>;
  paragraphBranchesTariff?: Maybe<Paragraph__Branches_Tariff>;
  allParagraphBranchesTariff?: Maybe<Paragraph__Branches_TariffConnection>;
  paragraphProfits?: Maybe<Paragraph__Profits>;
  allParagraphProfits?: Maybe<Paragraph__ProfitsConnection>;
  paragraphPorownanieTerminali?: Maybe<Paragraph__Porownanie_Terminali>;
  allParagraphPorownanieTerminali?: Maybe<Paragraph__Porownanie_TerminaliConnection>;
  paragraphZdjecieOpisLink?: Maybe<Paragraph__Zdjecie_Opis_Link>;
  allParagraphZdjecieOpisLink?: Maybe<Paragraph__Zdjecie_Opis_LinkConnection>;
  paragraphZdjecieOpisLinkIco?: Maybe<Paragraph__Zdjecie_Opis_Link_Ico>;
  allParagraphZdjecieOpisLinkIco?: Maybe<Paragraph__Zdjecie_Opis_Link_IcoConnection>;
  paragraphBranchesHero?: Maybe<Paragraph__Branches_Hero>;
  allParagraphBranchesHero?: Maybe<Paragraph__Branches_HeroConnection>;
  paragraphBranchesWhatIsIpos?: Maybe<Paragraph__Branches_What_Is_Ipos>;
  allParagraphBranchesWhatIsIpos?: Maybe<Paragraph__Branches_What_Is_IposConnection>;
  paragraphBranchesFeatures?: Maybe<Paragraph__Branches_Features>;
  allParagraphBranchesFeatures?: Maybe<Paragraph__Branches_FeaturesConnection>;
  paragraphBranchesRecommendedDevices?: Maybe<Paragraph__Branches_Recommended_Devices>;
  allParagraphBranchesRecommendedDevices?: Maybe<Paragraph__Branches_Recommended_DevicesConnection>;
  paragraphBranchesDevice?: Maybe<Paragraph__Branches_Device>;
  allParagraphBranchesDevice?: Maybe<Paragraph__Branches_DeviceConnection>;
  paragraphBranchesRecommendedTariffs?: Maybe<Paragraph__Branches_Recommended_Tariffs>;
  allParagraphBranchesRecommendedTariffs?: Maybe<Paragraph__Branches_Recommended_TariffsConnection>;
  nodePriceList?: Maybe<Node__Price_List>;
  allNodePriceList?: Maybe<Node__Price_ListConnection>;
  paragraphBranchesCheckWhatSGoingOn?: Maybe<Paragraph__Branches_Check_What_S_Going_On>;
  allParagraphBranchesCheckWhatSGoingOn?: Maybe<
    Paragraph__Branches_Check_What_S_Going_OnConnection
  >;
  paragraphBranchesTestimonials?: Maybe<Paragraph__Branches_Testimonials>;
  allParagraphBranchesTestimonials?: Maybe<Paragraph__Branches_TestimonialsConnection>;
  paragraphDiscountsContainer?: Maybe<Paragraph__Discounts_Container>;
  allParagraphDiscountsContainer?: Maybe<Paragraph__Discounts_ContainerConnection>;
  paragraphBranchesDiscounts?: Maybe<Paragraph__Branches_Discounts>;
  allParagraphBranchesDiscounts?: Maybe<Paragraph__Branches_DiscountsConnection>;
  paragraphNewsCta?: Maybe<Paragraph__News_Cta>;
  allParagraphNewsCta?: Maybe<Paragraph__News_CtaConnection>;
  paragraphNewsImageTitleText?: Maybe<Paragraph__News_Image_Title_Text>;
  allParagraphNewsImageTitleText?: Maybe<Paragraph__News_Image_Title_TextConnection>;
  paragraphNewsQuotation?: Maybe<Paragraph__News_Quotation>;
  allParagraphNewsQuotation?: Maybe<Paragraph__News_QuotationConnection>;
  paragraphNewsText?: Maybe<Paragraph__News_Text>;
  allParagraphNewsText?: Maybe<Paragraph__News_TextConnection>;
  paragraphTextButton?: Maybe<Paragraph__Text_Button>;
  allParagraphTextButton?: Maybe<Paragraph__Text_ButtonConnection>;
  paragraphLogos?: Maybe<Paragraph__Logos>;
  allParagraphLogos?: Maybe<Paragraph__LogosConnection>;
  paragraphHowItWorks?: Maybe<Paragraph__How_It_Works>;
  allParagraphHowItWorks?: Maybe<Paragraph__How_It_WorksConnection>;
  paragraphTitleIcons?: Maybe<Paragraph__Title_Icons>;
  allParagraphTitleIcons?: Maybe<Paragraph__Title_IconsConnection>;
  paragraphSimpleForm?: Maybe<Paragraph__Simple_Form>;
  allParagraphSimpleForm?: Maybe<Paragraph__Simple_FormConnection>;
  paragraphRecommendedBlogPosts?: Maybe<Paragraph__Recommended_Blog_Posts>;
  allParagraphRecommendedBlogPosts?: Maybe<Paragraph__Recommended_Blog_PostsConnection>;
  paragraphIconsBelt?: Maybe<Paragraph__Icons_Belt>;
  allParagraphIconsBelt?: Maybe<Paragraph__Icons_BeltConnection>;
  paragraphLinkedIcons?: Maybe<Paragraph__Linked_Icons>;
  allParagraphLinkedIcons?: Maybe<Paragraph__Linked_IconsConnection>;
  paragraphFooterSection?: Maybe<Paragraph__Footer_Section>;
  allParagraphFooterSection?: Maybe<Paragraph__Footer_SectionConnection>;
  paragraphHeadingAndLinks?: Maybe<Paragraph__Heading_And_Links>;
  allParagraphHeadingAndLinks?: Maybe<Paragraph__Heading_And_LinksConnection>;
  paragraphHeadingAndText?: Maybe<Paragraph__Heading_And_Text>;
  allParagraphHeadingAndText?: Maybe<Paragraph__Heading_And_TextConnection>;
  paragraphHeadingAndAgreements?: Maybe<Paragraph__Heading_And_Agreements>;
  allParagraphHeadingAndAgreements?: Maybe<Paragraph__Heading_And_AgreementsConnection>;
  paragraphSocialLink?: Maybe<Paragraph__Social_Link>;
  allParagraphSocialLink?: Maybe<Paragraph__Social_LinkConnection>;
  paragraphMakeAnAppointment?: Maybe<Paragraph__Make_An_Appointment>;
  allParagraphMakeAnAppointment?: Maybe<Paragraph__Make_An_AppointmentConnection>;
  paragraphLinkWithText?: Maybe<Paragraph__Link_With_Text>;
  allParagraphLinkWithText?: Maybe<Paragraph__Link_With_TextConnection>;
  taxonomyTermPaymentTypes?: Maybe<Taxonomy_Term__Payment_Types>;
  allTaxonomyTermPaymentTypes?: Maybe<Taxonomy_Term__Payment_TypesConnection>;
  paragraphVideo?: Maybe<Paragraph__Video>;
  allParagraphVideo?: Maybe<Paragraph__VideoConnection>;
  paragraphTerminalModels?: Maybe<Paragraph__Terminal_Models>;
  allParagraphTerminalModels?: Maybe<Paragraph__Terminal_ModelsConnection>;
  paragraphTerminalModelsItem?: Maybe<Paragraph__Terminal_Models_Item>;
  allParagraphTerminalModelsItem?: Maybe<Paragraph__Terminal_Models_ItemConnection>;
  paragraphSidebarImageItem?: Maybe<Paragraph__Sidebar_Image_Item>
  paragraphSidebarImage?: Maybe<Paragraph__Sidebar_Image>
  nodeAgreement?: Maybe<Node__Agreement>;
  allNodeAgreement?: Maybe<Node__AgreementConnection>;
  siteSettingEntityUrls?: Maybe<Site_Setting_Entity__Urls>;
  allSiteSettingEntityUrls?: Maybe<Site_Setting_Entity__UrlsConnection>;
  siteSettingEntityMenu?: Maybe<Site_Setting_Entity__Menu>;
  allSiteSettingEntityMenu?: Maybe<Site_Setting_Entity__MenuConnection>;
  siteSettingEntityFooter?: Maybe<Site_Setting_Entity__Footer>;
  allSiteSettingEntityFooter?: Maybe<Site_Setting_Entity__FooterConnection>;
  siteSettingEntityPanelCookies?: Maybe<Site_Setting_Entity__Panel_Cookies>;
  allSiteSettingEntityPanelCookies?: Maybe<Site_Setting_Entity__Panel_CookiesConnection>;
  siteSettingEntityAdministration?: Maybe<Site_Setting_Entity__Administration>;
  allSiteSettingEntityAdministration?: Maybe<Site_Setting_Entity__AdministrationConnection>;
  siteSettingEntityMainPage?: Maybe<Site_Setting_Entity__Main_Page>;
  allSiteSettingEntityMainPage?: Maybe<Site_Setting_Entity__Main_PageConnection>;
  siteSettingEntityProduct?: Maybe<Site_Setting_Entity__Product>;
  allSiteSettingEntityProduct?: Maybe<Site_Setting_Entity__ProductConnection>;
  siteSettingEntityKasaFiskalnaVsKasoterminal?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal
  >;
  allSiteSettingEntityKasaFiskalnaVsKasoterminal?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalConnection
  >;
  siteSettingEntityBranches?: Maybe<Site_Setting_Entity__Branches>;
  allSiteSettingEntityBranches?: Maybe<Site_Setting_Entity__BranchesConnection>;
  nodeBranches?: Maybe<Node__Branches>;
  allNodeBranches?: Maybe<Node__BranchesConnection>;
  taxonomyTermAccesoryType?: Maybe<Taxonomy_Term__Accesory_Type>;
  allTaxonomyTermAccesoryType?: Maybe<Taxonomy_Term__Accesory_TypeConnection>;
  nodeDevices?: Maybe<Node__Devices>;
  allNodeDevices?: Maybe<Node__DevicesConnection>;
  nodeCases?: Maybe<Node__Cases>;
  allNodeCases?: Maybe<Node__CasesConnection>;
  nodeAccessories?: Maybe<Node__Accessories>;
  allNodeAccessories?: Maybe<Node__AccessoriesConnection>;
  nodeTariffs?: Maybe<Node__Tariffs>;
  allNodeTariffs?: Maybe<Node__TariffsConnection>;
  siteSettingEntityConfigurator?: Maybe<Site_Setting_Entity__Configurator>;
  allSiteSettingEntityConfigurator?: Maybe<Site_Setting_Entity__ConfiguratorConnection>;
  paragraphProfitReferenceToPriceList?: Maybe<Paragraph__Profit_Reference_To_Price_List>;
  allParagraphProfitReferenceToPriceList?: Maybe<
    Paragraph__Profit_Reference_To_Price_ListConnection
  >;
  taxonomyTermPricelistGroup?: Maybe<Taxonomy_Term__Pricelist_Group>;
  allTaxonomyTermPricelistGroup?: Maybe<Taxonomy_Term__Pricelist_GroupConnection>;
  allTaxonomyTermProductCategory?: Maybe<Taxonomy_Term__Product_CategoriesConnection>;
  nodeFiscalization?: Maybe<Node__Fiscalization>;
  allNodeFiscalization?: Maybe<Node__FiscalizationConnection>;
  siteSettingEntityPriceList?: Maybe<Site_Setting_Entity__Price_List>;
  allSiteSettingEntityPriceList?: Maybe<Site_Setting_Entity__Price_ListConnection>;
  siteSettingEntityDemo?: Maybe<Site_Setting_Entity__Demo>;
  allSiteSettingEntityDemo?: Maybe<Site_Setting_Entity__DemoConnection>;
  siteSettingEntityThankYouPages?: Maybe<Site_Setting_Entity__Thank_You_Pages>;
  allSiteSettingEntityThankYouPages?: Maybe<Site_Setting_Entity__Thank_You_PagesConnection>;
  siteSettingEntityBrakStrony404?: Maybe<Site_Setting_Entity__Brak_Strony_404_>;
  allSiteSettingEntityBrakStrony404?: Maybe<Site_Setting_Entity__Brak_Strony_404_Connection>;
  siteSettingEntityAboutUs?: Maybe<Site_Setting_Entity__About_Us>;
  allSiteSettingEntityAboutUs?: Maybe<Site_Setting_Entity__About_UsConnection>;
  paragraphMemberOfTheBoardPersonalDat?: Maybe<Paragraph__Member_Of_The_Board_Personal_Dat>;
  allParagraphMemberOfTheBoardPersonalDat?: Maybe<
    Paragraph__Member_Of_The_Board_Personal_DatConnection
  >;
  siteSettingEntityAktualnosci?: Maybe<Site_Setting_Entity__Aktualnosci>;
  allSiteSettingEntityAktualnosci?: Maybe<Site_Setting_Entity__AktualnosciConnection>;
  taxonomyTermNewsCategories?: Maybe<Taxonomy_Term__News_Categories>;
  allTaxonomyTermNewsCategories?: Maybe<Taxonomy_Term__News_CategoriesConnection>;
  taxonomyTermNewsTags?: Maybe<Taxonomy_Term__News_Tags>;
  allTaxonomyTermNewsTags?: Maybe<Taxonomy_Term__News_TagsConnection>;
  nodeNews?: Maybe<Node__News>;
  allNodeNews?: Maybe<Node__NewsConnection>;
  siteSettingEntityAccessories?: Maybe<Site_Setting_Entity__Accessories>;
  allSiteSettingEntityAccessories?: Maybe<Site_Setting_Entity__AccessoriesConnection>;
  siteSettingEntityFinancing?: Maybe<Site_Setting_Entity__Financing>;
  allSiteSettingEntityFinancing?: Maybe<Site_Setting_Entity__FinancingConnection>;
  siteSettingEntityFranchise?: Maybe<Site_Setting_Entity__Franchise>;
  allSiteSettingEntityFranchise?: Maybe<Site_Setting_Entity__FranchiseConnection>;
  siteSettingEntityIntercars?: Maybe<Site_Setting_Entity__Intercars>;
  allSiteSettingEntityIntercars?: Maybe<Site_Setting_Entity__IntercarsConnection>;
  nodePages?: Maybe<Node__Pages>;
  allNodePages?: Maybe<Node__PagesConnection>;
  siteSettingEntitySimpleForm?: Maybe<Site_Setting_Entity__Simple_Form>;
  allSiteSettingEntitySimpleForm?: Maybe<Site_Setting_Entity__Simple_FormConnection>;
  sitePage?: Maybe<SitePage>;
  allSitePage?: Maybe<SitePageConnection>;
  sitePlugin?: Maybe<SitePlugin>;
  allSitePlugin?: Maybe<SitePluginConnection>;
  site?: Maybe<Site>;
  allSite?: Maybe<SiteConnection>;
  directory?: Maybe<Directory>;
  allDirectory?: Maybe<DirectoryConnection>;
  nodeBlogPost?: Maybe<Node__Blog_Post>;
  allNodeBlogPost?: Maybe<Node__Blog_PostConnection>;
  taxonomyTermTypeInCms?: Maybe<Taxonomy_Term__Type_In_Cms>;
  allTaxonomyTermTypeInCms?: Maybe<Taxonomy_Term__Type_In_CmsConnection>;
  jsonJson?: Maybe<JsonJson>;
  allJsonJson?: Maybe<JsonJsonConnection>;
  productsJson?: Maybe<ProductsJson>;
  allProductsJson?: Maybe<ProductsJsonConnection>;
};

export type QueryImageSharpArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  fixed?: Maybe<ImageSharpFixedFilterInput>;
  resolutions?: Maybe<ImageSharpResolutionsFilterInput>;
  fluid?: Maybe<ImageSharpFluidFilterInput>;
  sizes?: Maybe<ImageSharpSizesFilterInput>;
  original?: Maybe<ImageSharpOriginalFilterInput>;
  resize?: Maybe<ImageSharpResizeFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllImageSharpArgs = {
  filter?: Maybe<ImageSharpFilterInput>;
  sort?: Maybe<ImageSharpSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryFileArgs = {
  birthtime?: Maybe<DateQueryOperatorInput>;
  birthtimeMs?: Maybe<FloatQueryOperatorInput>;
  childImageSharp?: Maybe<ImageSharpFilterInput>;
  publicURL?: Maybe<StringQueryOperatorInput>;
  sourceInstanceName?: Maybe<StringQueryOperatorInput>;
  absolutePath?: Maybe<StringQueryOperatorInput>;
  relativePath?: Maybe<StringQueryOperatorInput>;
  extension?: Maybe<StringQueryOperatorInput>;
  size?: Maybe<IntQueryOperatorInput>;
  prettySize?: Maybe<StringQueryOperatorInput>;
  modifiedTime?: Maybe<DateQueryOperatorInput>;
  accessTime?: Maybe<DateQueryOperatorInput>;
  changeTime?: Maybe<DateQueryOperatorInput>;
  birthTime?: Maybe<DateQueryOperatorInput>;
  root?: Maybe<StringQueryOperatorInput>;
  dir?: Maybe<StringQueryOperatorInput>;
  base?: Maybe<StringQueryOperatorInput>;
  ext?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  relativeDirectory?: Maybe<StringQueryOperatorInput>;
  dev?: Maybe<IntQueryOperatorInput>;
  mode?: Maybe<IntQueryOperatorInput>;
  nlink?: Maybe<IntQueryOperatorInput>;
  uid?: Maybe<IntQueryOperatorInput>;
  gid?: Maybe<IntQueryOperatorInput>;
  rdev?: Maybe<IntQueryOperatorInput>;
  blksize?: Maybe<IntQueryOperatorInput>;
  ino?: Maybe<IntQueryOperatorInput>;
  blocks?: Maybe<IntQueryOperatorInput>;
  atimeMs?: Maybe<FloatQueryOperatorInput>;
  mtimeMs?: Maybe<FloatQueryOperatorInput>;
  ctimeMs?: Maybe<FloatQueryOperatorInput>;
  atime?: Maybe<DateQueryOperatorInput>;
  mtime?: Maybe<DateQueryOperatorInput>;
  ctime?: Maybe<DateQueryOperatorInput>;
  url?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllFileArgs = {
  filter?: Maybe<FileFilterInput>;
  sort?: Maybe<FileSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryFileFileArgs = {
  localFile?: Maybe<FileFilterInput>;
  uri?: Maybe<UriFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__fid?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  filename?: Maybe<StringQueryOperatorInput>;
  filemime?: Maybe<StringQueryOperatorInput>;
  filesize?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<File__FileRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllFileFileArgs = {
  filter?: Maybe<File__FileFilterInput>;
  sort?: Maybe<File__FileSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryChildImageSharpFixedArgs = {
  base64?: Maybe<StringQueryOperatorInput>;
  tracedSVG?: Maybe<StringQueryOperatorInput>;
  aspectRatio?: Maybe<FloatQueryOperatorInput>;
  width?: Maybe<FloatQueryOperatorInput>;
  height?: Maybe<FloatQueryOperatorInput>;
  src?: Maybe<StringQueryOperatorInput>;
  srcSet?: Maybe<StringQueryOperatorInput>;
  srcWebp?: Maybe<StringQueryOperatorInput>;
  srcSetWebp?: Maybe<StringQueryOperatorInput>;
  originalName?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllChildImageSharpFixedArgs = {
  filter?: Maybe<ChildImageSharpFixedFilterInput>;
  sort?: Maybe<ChildImageSharpFixedSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryFileFileUriArgs = {
  value?: Maybe<StringQueryOperatorInput>;
  url?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllFileFileUriArgs = {
  filter?: Maybe<File__FileUriFilterInput>;
  sort?: Maybe<File__FileUriSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphHero1Args = {
  field_hero_1_button?: Maybe<StringQueryOperatorInput>;
  field_hero_1_video?: Maybe<StringQueryOperatorInput>;
  field_hero_1_image?: Maybe<Field_Hero_1_ImageFilterInput>;
  field_hero_1_image_mobile?: Maybe<Field_Hero_1_Image_MobileFilterInput>;
  field_hero_1_subtitle?: Maybe<Field_Hero_1_SubtitleFilterInput>;
  field_hero_1_text_1?: Maybe<Field_Hero_1_Text_1FilterInput>;
  field_hero_1_text_2?: Maybe<Field_Hero_1_Text_2FilterInput>;
  field_hero_1_title?: Maybe<Field_Hero_1_TitleFilterInput>;
  field_video_thumbnail?: Maybe<Field_Video_ThumbnailFilterInput>;
  relationships?: Maybe<Paragraph__Hero_1RelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphHero1Args = {
  filter?: Maybe<Paragraph__Hero_1FilterInput>;
  sort?: Maybe<Paragraph__Hero_1SortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesRecommendationArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Field_Branches_ImageFilterInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_is_online?: Maybe<BooleanQueryOperatorInput>;
  field_is_online_text?: Maybe<StringQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_url?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_RecommendationRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesRecommendationArgs = {
  filter?: Maybe<Paragraph__Branches_RecommendationFilterInput>;
  sort?: Maybe<Paragraph__Branches_RecommendationSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphComprehensiveSolutionArgs = {
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  relationships?: Maybe<Paragraph__Comprehensive_SolutionRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_comprehensive_solution_ite?: Maybe<
    Paragraph__Comprehensive_SolutionField_Comprehensive_Solution_IteFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphComprehensiveSolutionArgs = {
  filter?: Maybe<Paragraph__Comprehensive_SolutionFilterInput>;
  sort?: Maybe<Paragraph__Comprehensive_SolutionSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphComprehensiveSolutionItemArgs = {
  field_is_online?: Maybe<BooleanQueryOperatorInput>;
  field_is_online_text?: Maybe<StringQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  relationships?: Maybe<Paragraph__Comprehensive_Solution_ItemRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphComprehensiveSolutionItemArgs = {
  filter?: Maybe<Paragraph__Comprehensive_Solution_ItemFilterInput>;
  sort?: Maybe<Paragraph__Comprehensive_Solution_ItemSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBulletsContainerArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Bullets_ContainerRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_bullets_item?: Maybe<Paragraph__Bullets_ContainerField_Bullets_ItemFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBulletsContainerArgs = {
  filter?: Maybe<Paragraph__Bullets_ContainerFilterInput>;
  sort?: Maybe<Paragraph__Bullets_ContainerSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBulletsArgs = {
  internal?: Maybe<InternalFilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_btn_url?: Maybe<StringQueryOperatorInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  field_video?: Maybe<StringQueryOperatorInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  field_bullets_icons?: Maybe<Field_Bullets_IconsFilterListInput>;
  relationships?: Maybe<Paragraph__BulletsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBulletsArgs = {
  filter?: Maybe<Paragraph__BulletsFilterInput>;
  sort?: Maybe<Paragraph__BulletsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesSavingUpBlockArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_branches_button_text?: Maybe<StringQueryOperatorInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_Saving_Up_BlockRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesSavingUpBlockArgs = {
  filter?: Maybe<Paragraph__Branches_Saving_Up_BlockFilterInput>;
  sort?: Maybe<Paragraph__Branches_Saving_Up_BlockSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraph2ZdjeciaOpisyLinkArgs = {
  field_label_1?: Maybe<StringQueryOperatorInput>;
  field_label_2?: Maybe<StringQueryOperatorInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_btn_url?: Maybe<StringQueryOperatorInput>;
  field_only_text?: Maybe<BooleanQueryOperatorInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  relationships?: Maybe<Paragraph__2_Zdjecia_Opisy_LinkRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraph2ZdjeciaOpisyLinkArgs = {
  filter?: Maybe<Paragraph__2_Zdjecia_Opisy_LinkFilterInput>;
  sort?: Maybe<Paragraph__2_Zdjecia_Opisy_LinkSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphIcoAndTextArgs = {
  field_text?: Maybe<Field_TextFilterInput>;
  field_ico?: Maybe<Field_IcoFilterInput>;
  relationships?: Maybe<Paragraph__Ico_And_TextRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphIcoAndTextArgs = {
  filter?: Maybe<Paragraph__Ico_And_TextFilterInput>;
  sort?: Maybe<Paragraph__Ico_And_TextSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphTextLinkArgs = {
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Text_LinkRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphTextLinkArgs = {
  filter?: Maybe<Paragraph__Text_LinkFilterInput>;
  sort?: Maybe<Paragraph__Text_LinkSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesTariffArgs = {
  field_branches_name?: Maybe<StringQueryOperatorInput>;
  field_recommended?: Maybe<BooleanQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_TariffRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_profits?: Maybe<Paragraph__Branches_TariffField_Branches_ProfitsFilterListInput>;
  field_date_from_api?: Maybe<Paragraph__Branches_TariffField_Date_From_ApiFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphBranchesTariffArgs = {
  filter?: Maybe<Paragraph__Branches_TariffFilterInput>;
  sort?: Maybe<Paragraph__Branches_TariffSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphProfitsArgs = {
  field_profits_active?: Maybe<BooleanQueryOperatorInput>;
  field_profits_name?: Maybe<Field_Profits_NameFilterInput>;
  field_ref_desc?: Maybe<Paragraph__ProfitsField_Ref_DescFilterListInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__ProfitsRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphProfitsArgs = {
  filter?: Maybe<Paragraph__ProfitsFilterInput>;
  sort?: Maybe<Paragraph__ProfitsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphPorownanieTerminaliArgs = {
  field_description?: Maybe<Field_DescriptionFilterInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_kup_teraz?: Maybe<StringQueryOperatorInput>;
  field_label_1?: Maybe<StringQueryOperatorInput>;
  field_label_2?: Maybe<StringQueryOperatorInput>;
  field_label_3?: Maybe<StringQueryOperatorInput>;
  field_label_4?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_text_3?: Maybe<StringQueryOperatorInput>;
  field_text_4?: Maybe<Field_Text_4FilterInput>;
  field_zobacz_szczegoly?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Porownanie_TerminaliRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphPorownanieTerminaliArgs = {
  filter?: Maybe<Paragraph__Porownanie_TerminaliFilterInput>;
  sort?: Maybe<Paragraph__Porownanie_TerminaliSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphZdjecieOpisLinkArgs = {
  field_image?: Maybe<Field_ImageFilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  field_hidden_or_disable?: Maybe<BooleanQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Zdjecie_Opis_LinkRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphZdjecieOpisLinkArgs = {
  filter?: Maybe<Paragraph__Zdjecie_Opis_LinkFilterInput>;
  sort?: Maybe<Paragraph__Zdjecie_Opis_LinkSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphZdjecieOpisLinkIcoArgs = {
  field_image?: Maybe<Field_ImageFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  relationships?: Maybe<Paragraph__Zdjecie_Opis_Link_IcoRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphZdjecieOpisLinkIcoArgs = {
  filter?: Maybe<Paragraph__Zdjecie_Opis_Link_IcoFilterInput>;
  sort?: Maybe<Paragraph__Zdjecie_Opis_Link_IcoSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesHeroArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  field_hero_1_video?: Maybe<StringQueryOperatorInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_branches_button_text?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Field_Branches_ImageFilterInput>;
  field_branches_image_mobile?: Maybe<Field_Branches_Image_MobileFilterInput>;
  field_video_thumbnail?: Maybe<Field_Video_ThumbnailFilterInput>;
  relationships?: Maybe<Paragraph__Branches_HeroRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_device_image?: Maybe<
    Paragraph__Branches_HeroField_Branches_Device_ImageFilterInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesHeroArgs = {
  filter?: Maybe<Paragraph__Branches_HeroFilterInput>;
  sort?: Maybe<Paragraph__Branches_HeroSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesWhatIsIposArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  field_short_text_2?: Maybe<Field_Short_Text_2FilterInput>;
  field_short_text_3?: Maybe<Field_Short_Text_3FilterInput>;
  relationships?: Maybe<Paragraph__Branches_What_Is_IposRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Paragraph__Branches_What_Is_IposField_Branches_ImageFilterInput>;
  field_ico_1?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_1FilterInput>;
  field_ico_2?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_2FilterInput>;
  field_ico_3?: Maybe<Paragraph__Branches_What_Is_IposField_Ico_3FilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesWhatIsIposArgs = {
  filter?: Maybe<Paragraph__Branches_What_Is_IposFilterInput>;
  sort?: Maybe<Paragraph__Branches_What_Is_IposSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesFeaturesArgs = {
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  field_short_text_2?: Maybe<Field_Short_Text_2FilterInput>;
  field_short_text_3?: Maybe<Field_Short_Text_3FilterInput>;
  field_short_text_4?: Maybe<Field_Short_Text_4FilterInput>;
  relationships?: Maybe<Paragraph__Branches_FeaturesRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Paragraph__Branches_FeaturesField_Branches_ImageFilterInput>;
  field_ico_1?: Maybe<Paragraph__Branches_FeaturesField_Ico_1FilterInput>;
  field_ico_2?: Maybe<Paragraph__Branches_FeaturesField_Ico_2FilterInput>;
  field_ico_3?: Maybe<Paragraph__Branches_FeaturesField_Ico_3FilterInput>;
  field_ico_4?: Maybe<Paragraph__Branches_FeaturesField_Ico_4FilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesFeaturesArgs = {
  filter?: Maybe<Paragraph__Branches_FeaturesFilterInput>;
  sort?: Maybe<Paragraph__Branches_FeaturesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesRecommendedDevicesArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  relationships?: Maybe<Paragraph__Branches_Recommended_DevicesRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_devices?: Maybe<
    Paragraph__Branches_Recommended_DevicesField_Branches_DevicesFilterListInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesRecommendedDevicesArgs = {
  filter?: Maybe<Paragraph__Branches_Recommended_DevicesFilterInput>;
  sort?: Maybe<Paragraph__Branches_Recommended_DevicesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesDeviceArgs = {
  field_branches_name?: Maybe<StringQueryOperatorInput>;
  field_recommended?: Maybe<BooleanQueryOperatorInput>;
  field_button_text_recommended?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_DeviceRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Paragraph__Branches_DeviceField_Branches_ImageFilterInput>;
  field_branches_profits?: Maybe<Paragraph__Branches_DeviceField_Branches_ProfitsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphBranchesDeviceArgs = {
  filter?: Maybe<Paragraph__Branches_DeviceFilterInput>;
  sort?: Maybe<Paragraph__Branches_DeviceSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesRecommendedTariffsArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Branches_Recommended_TariffsRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_tarrifs?: Maybe<
    Paragraph__Branches_Recommended_TariffsField_Branches_TarrifsFilterListInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesRecommendedTariffsArgs = {
  filter?: Maybe<Paragraph__Branches_Recommended_TariffsFilterInput>;
  sort?: Maybe<Paragraph__Branches_Recommended_TariffsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodePriceListArgs = {
  drupal_id?: Maybe<StringQueryOperatorInput>;
  fixedprice?: Maybe<StringQueryOperatorInput>;
  iposfee?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  field_pl_locationfee?: Maybe<StringQueryOperatorInput>;
  field_pl_name?: Maybe<StringQueryOperatorInput>;
  field_pl_optionname?: Maybe<StringQueryOperatorInput>;
  field_pl_lease?: Maybe<BooleanQueryOperatorInput>;
  field_pl_lease_name?: Maybe<StringQueryOperatorInput>;
  field_pl_payment_type?: Maybe<StringQueryOperatorInput>;
  field_pl_activation_price?: Maybe<StringQueryOperatorInput>;
  field_pl_contract_duration?: Maybe<IntQueryOperatorInput>;
  field_pl_group_name_in_api?: Maybe<StringQueryOperatorInput>;
  field_pl_reduced_fee_duration?: Maybe<IntQueryOperatorInput>;
  field_pl_reduced_fee?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  field_pl_first_ipos_fee?: Maybe<StringQueryOperatorInput>;
  vatrate?: Maybe<IntQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__Price_ListRelationshipsFilterInput>;
  pricelistgroup?: Maybe<Node__Price_ListPricelistgroupFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodePriceListArgs = {
  filter?: Maybe<Node__Price_ListFilterInput>;
  sort?: Maybe<Node__Price_ListSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesCheckWhatSGoingOnArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  field_short_text_2?: Maybe<Field_Short_Text_2FilterInput>;
  field_short_text_3?: Maybe<Field_Short_Text_3FilterInput>;
  field_short_text_4?: Maybe<Field_Short_Text_4FilterInput>;
  field_branches_button_text?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Field_Branches_ImageFilterInput>;
  relationships?: Maybe<Paragraph__Branches_Check_What_S_Going_OnRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_ico_1?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_1FilterInput>;
  field_ico_2?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_2FilterInput>;
  field_ico_3?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_3FilterInput>;
  field_ico_4?: Maybe<Paragraph__Branches_Check_What_S_Going_OnField_Ico_4FilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesCheckWhatSGoingOnArgs = {
  filter?: Maybe<Paragraph__Branches_Check_What_S_Going_OnFilterInput>;
  sort?: Maybe<Paragraph__Branches_Check_What_S_Going_OnSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesTestimonialsArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_nq_quotation?: Maybe<Field_Nq_QuotationFilterInput>;
  relationships?: Maybe<Paragraph__Branches_TestimonialsRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  field_branches_name?: Maybe<StringQueryOperatorInput>;
  field_paragraph_headline?: Maybe<
    Paragraph__Branches_TestimonialsField_Paragraph_HeadlineFilterInput
  >;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Paragraph__Branches_TestimonialsField_Branches_ImageFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphBranchesTestimonialsArgs = {
  filter?: Maybe<Paragraph__Branches_TestimonialsFilterInput>;
  sort?: Maybe<Paragraph__Branches_TestimonialsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphDiscountsContainerArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  relationships?: Maybe<Paragraph__Discounts_ContainerRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_discounts_item?: Maybe<Paragraph__Discounts_ContainerField_Discounts_ItemFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphDiscountsContainerArgs = {
  filter?: Maybe<Paragraph__Discounts_ContainerFilterInput>;
  sort?: Maybe<Paragraph__Discounts_ContainerSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphBranchesDiscountsArgs = {
  field_branches_text?: Maybe<Field_Branches_TextFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  field_branches_button_text?: Maybe<StringQueryOperatorInput>;
  field_branches_image?: Maybe<Field_Branches_ImageFilterInput>;
  relationships?: Maybe<Paragraph__Branches_DiscountsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphBranchesDiscountsArgs = {
  filter?: Maybe<Paragraph__Branches_DiscountsFilterInput>;
  sort?: Maybe<Paragraph__Branches_DiscountsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphNewsCtaArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_nc_text?: Maybe<StringQueryOperatorInput>;
  field_nc_button_url?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__News_CtaRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphNewsCtaArgs = {
  filter?: Maybe<Paragraph__News_CtaFilterInput>;
  sort?: Maybe<Paragraph__News_CtaSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphNewsImageTitleTextArgs = {
  field_nitt_text?: Maybe<StringQueryOperatorInput>;
  field_nitt_title?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  relationships?: Maybe<Paragraph__News_Image_Title_TextRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_nitt_image?: Maybe<Paragraph__News_Image_Title_TextField_Nitt_ImageFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphNewsImageTitleTextArgs = {
  filter?: Maybe<Paragraph__News_Image_Title_TextFilterInput>;
  sort?: Maybe<Paragraph__News_Image_Title_TextSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphNewsQuotationArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  field_nq_quotation?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__News_QuotationRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphNewsQuotationArgs = {
  filter?: Maybe<Paragraph__News_QuotationFilterInput>;
  sort?: Maybe<Paragraph__News_QuotationSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphNewsTextArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  field_nt_text?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__News_TextRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphNewsTextArgs = {
  filter?: Maybe<Paragraph__News_TextFilterInput>;
  sort?: Maybe<Paragraph__News_TextSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphTextButtonArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  field_nc_button_text?: Maybe<StringQueryOperatorInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Text_ButtonRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphTextButtonArgs = {
  filter?: Maybe<Paragraph__Text_ButtonFilterInput>;
  sort?: Maybe<Paragraph__Text_ButtonSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphLogosArgs = {
  field_logos_logos?: Maybe<Paragraph__LogosField_Logos_LogosFilterListInput>;
  field_logos_text?: Maybe<Field_Logos_TextFilterInput>;
  relationships?: Maybe<Paragraph__LogosRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphLogosArgs = {
  filter?: Maybe<Paragraph__LogosFilterInput>;
  sort?: Maybe<Paragraph__LogosSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphHowItWorksArgs = {
  field_description?: Maybe<Field_DescriptionFilterInput>;
  field_ico_1?: Maybe<Field_Ico_1FilterInput>;
  field_image?: Maybe<Field_ImageFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_image_2?: Maybe<Field_Image_2FilterInput>;
  field_image_3?: Maybe<Field_Image_3FilterInput>;
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  field_short_text_1?: Maybe<Field_Short_Text_1FilterInput>;
  field_short_text_2?: Maybe<Field_Short_Text_2FilterInput>;
  field_short_text_3?: Maybe<Field_Short_Text_3FilterInput>;
  field_text?: Maybe<Field_TextFilterInput>;
  relationships?: Maybe<Paragraph__How_It_WorksRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphHowItWorksArgs = {
  filter?: Maybe<Paragraph__How_It_WorksFilterInput>;
  sort?: Maybe<Paragraph__How_It_WorksSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphTitleIconsArgs = {
  field_paragraph_headline?: Maybe<Field_Paragraph_HeadlineFilterInput>;
  relationships?: Maybe<Paragraph__Title_IconsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_ico_and_text?: Maybe<Paragraph__Title_IconsField_Ico_And_TextFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphTitleIconsArgs = {
  filter?: Maybe<Paragraph__Title_IconsFilterInput>;
  sort?: Maybe<Paragraph__Title_IconsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphSimpleFormArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  field_title?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Simple_FormRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphSimpleFormArgs = {
  filter?: Maybe<Paragraph__Simple_FormFilterInput>;
  sort?: Maybe<Paragraph__Simple_FormSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphRecommendedBlogPostsArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_headline?: Maybe<StringQueryOperatorInput>;
  field_read_more_button_text?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Recommended_Blog_PostsRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_blog_posts?: Maybe<Paragraph__Recommended_Blog_PostsField_Blog_PostsFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphRecommendedBlogPostsArgs = {
  filter?: Maybe<Paragraph__Recommended_Blog_PostsFilterInput>;
  sort?: Maybe<Paragraph__Recommended_Blog_PostsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphIconsBeltArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_icons_belt_enabled?: Maybe<BooleanQueryOperatorInput>;
  field_icons_belt_heading?: Maybe<StringQueryOperatorInput>;
  field_icons_belt_show_urls?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Icons_BeltRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_icons_belt_icons?: Maybe<Paragraph__Icons_BeltField_Icons_Belt_IconsFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphIconsBeltArgs = {
  filter?: Maybe<Paragraph__Icons_BeltFilterInput>;
  sort?: Maybe<Paragraph__Icons_BeltSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphLinkedIconsArgs = {
  field_linked_icon_text?: Maybe<StringQueryOperatorInput>;
  field_linked_icon_url?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Linked_IconsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_linked_icon_icon?: Maybe<Paragraph__Linked_IconsField_Linked_Icon_IconFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphLinkedIconsArgs = {
  filter?: Maybe<Paragraph__Linked_IconsFilterInput>;
  sort?: Maybe<Paragraph__Linked_IconsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphFooterSectionArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_section_header?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Footer_SectionRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_section_components?: Maybe<
    Paragraph__Footer_SectionField_Section_ComponentsFilterListInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphFooterSectionArgs = {
  filter?: Maybe<Paragraph__Footer_SectionFilterInput>;
  sort?: Maybe<Paragraph__Footer_SectionSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphHeadingAndLinksArgs = {
  field_heading_and_links_heading?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Heading_And_LinksRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_heading_and_links_links?: Maybe<
    Paragraph__Heading_And_LinksField_Heading_And_Links_LinksFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphHeadingAndLinksArgs = {
  filter?: Maybe<Paragraph__Heading_And_LinksFilterInput>;
  sort?: Maybe<Paragraph__Heading_And_LinksSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphHeadingAndTextArgs = {
  field_heading_and_text_heading?: Maybe<StringQueryOperatorInput>;
  field_heading_and_text_text?: Maybe<Field_Heading_And_Text_TextFilterInput>;
  relationships?: Maybe<Paragraph__Heading_And_TextRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_heading_and_text_links?: Maybe<
    Paragraph__Heading_And_TextField_Heading_And_Text_LinksFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphHeadingAndTextArgs = {
  filter?: Maybe<Paragraph__Heading_And_TextFilterInput>;
  sort?: Maybe<Paragraph__Heading_And_TextSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphHeadingAndAgreementsArgs = {
  field_heading_and_agrmnt_heading?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Heading_And_AgreementsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_agreements?: Maybe<Paragraph__Heading_And_AgreementsField_AgreementsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphHeadingAndAgreementsArgs = {
  filter?: Maybe<Paragraph__Heading_And_AgreementsFilterInput>;
  sort?: Maybe<Paragraph__Heading_And_AgreementsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphSocialLinkArgs = {
  field_social_link?: Maybe<Field_Social_LinkFilterInput>;
  relationships?: Maybe<Paragraph__Social_LinkRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_social_icon?: Maybe<Paragraph__Social_LinkField_Social_IconFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphSocialLinkArgs = {
  filter?: Maybe<Paragraph__Social_LinkFilterInput>;
  sort?: Maybe<Paragraph__Social_LinkSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphMakeAnAppointmentArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_appointment_header?: Maybe<StringQueryOperatorInput>;
  field_appointment_text?: Maybe<Field_Appointment_TextFilterInput>;
  relationships?: Maybe<Paragraph__Make_An_AppointmentRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_appointment_button?: Maybe<
    Paragraph__Make_An_AppointmentField_Appointment_ButtonFilterInput
  >;
  field_appointment_image?: Maybe<Paragraph__Make_An_AppointmentField_Appointment_ImageFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphMakeAnAppointmentArgs = {
  filter?: Maybe<Paragraph__Make_An_AppointmentFilterInput>;
  sort?: Maybe<Paragraph__Make_An_AppointmentSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphLinkWithTextArgs = {
  field_link_with_text_text?: Maybe<StringQueryOperatorInput>;
  field_link_with_text_link?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<DateQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Link_With_TextRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphLinkWithTextArgs = {
  filter?: Maybe<Paragraph__Link_With_TextFilterInput>;
  sort?: Maybe<Paragraph__Link_With_TextSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryTaxonomyTermPaymentTypesArgs = {
  machine_api_name?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Taxonomy_Term__Payment_TypesRelationshipsFilterInput>;
  vid?: Maybe<Taxonomy_Term__Payment_TypesVidFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllTaxonomyTermPaymentTypesArgs = {
  filter?: Maybe<Taxonomy_Term__Payment_TypesFilterInput>;
  sort?: Maybe<Taxonomy_Term__Payment_TypesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphVideoArgs = {
  internal?: Maybe<InternalFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_header?: Maybe<StringQueryOperatorInput>;
  field_video_text?: Maybe<Field_Video_TextFilterInput>;
  field_thumbnail?: Maybe<Field_ImageFilterInput>;
  field_video_video?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__VideoRelationshipsFilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphVideoArgs = {
  filter?: Maybe<Paragraph__VideoFilterInput>;
  sort?: Maybe<Paragraph__VideoSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphTerminalModelsArgs = {
  field_terminals_title?: Maybe<Field_Terminals_TitleFilterInput>;
  field_terminals_description?: Maybe<Field_Terminals_DescriptionFilterInput>;
  relationships?: Maybe<Paragraph__Terminal_ModelsRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphTerminalModelsArgs = {
  filter?: Maybe<Paragraph__Terminal_ModelsFilterInput>;
  sort?: Maybe<Paragraph__Terminal_ModelsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphTerminalModelsItemArgs = {
  internal?: Maybe<InternalFilterInput>;
  field_terminal_band_text?: Maybe<StringQueryOperatorInput>;
  field_button_text?: Maybe<StringQueryOperatorInput>;
  field_terminal_description?: Maybe<Field_Terminal_DescriptionFilterInput>;
  field_terminal_image?: Maybe<Field_Terminal_ImageFilterInput>;
  relationships?: Maybe<Paragraph__Terminal_Models_ItemRelationshipsFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
};

export type QueryAllParagraphTerminalModelsItemArgs = {
  filter?: Maybe<Paragraph__Terminal_Models_ItemFilterInput>;
  sort?: Maybe<Paragraph__Terminal_Models_ItemSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeAgreementArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  agreement_content?: Maybe<Agreement_ContentFilterInput>;
  agreement_explication?: Maybe<Agreement_ExplicationFilterInput>;
  agreement_type?: Maybe<StringQueryOperatorInput>;
  agreement_pdf_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__AgreementRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__vid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  agreement_payment_types?: Maybe<Node__AgreementAgreement_Payment_TypesFilterListInput>;
  agreement_pdf?: Maybe<Node__AgreementAgreement_PdfFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodeAgreementArgs = {
  filter?: Maybe<Node__AgreementFilterInput>;
  sort?: Maybe<Node__AgreementSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityUrlsArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_urls_array?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__UrlsSite_Setting_Entity_TypeFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityUrlsArgs = {
  filter?: Maybe<Site_Setting_Entity__UrlsFilterInput>;
  sort?: Maybe<Site_Setting_Entity__UrlsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityMenuArgs = {
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_settings_text_7?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  field_optional_label?: Maybe<StringQueryOperatorInput>;
  field_optional_url?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__MenuRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__MenuSite_Setting_Entity_TypeFilterInput>;
  field_menu_icons_belt?: Maybe<Site_Setting_Entity__MenuField_Menu_Icons_BeltFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityMenuArgs = {
  filter?: Maybe<Site_Setting_Entity__MenuFilterInput>;
  sort?: Maybe<Site_Setting_Entity__MenuSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityFooterArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__FooterRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__FooterSite_Setting_Entity_TypeFilterInput>;
  field_footer_sections?: Maybe<Site_Setting_Entity__FooterField_Footer_SectionsFilterListInput>;
  field_footer_social_links?: Maybe<
    Site_Setting_Entity__FooterField_Footer_Social_LinksFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityFooterArgs = {
  filter?: Maybe<Site_Setting_Entity__FooterFilterInput>;
  sort?: Maybe<Site_Setting_Entity__FooterSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityPanelCookiesArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Panel_CookiesSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityPanelCookiesArgs = {
  filter?: Maybe<Site_Setting_Entity__Panel_CookiesFilterInput>;
  sort?: Maybe<Site_Setting_Entity__Panel_CookiesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityAdministrationArgs = {
  field_translation_boolean?: Maybe<BooleanQueryOperatorInput>;
  field_minprice?: Maybe<StringQueryOperatorInput>;
  field_maxprice?: Maybe<StringQueryOperatorInput>;
  field_recaptcha_key?: Maybe<StringQueryOperatorInput>;
  field_selfdepositpercent?: Maybe<StringQueryOperatorInput>;
  field_max_number_of_terminals?: Maybe<IntQueryOperatorInput>;
  field_installmentquantity?: Maybe<StringQueryOperatorInput>;
  field_days_to_visit?: Maybe<IntQueryOperatorInput>;
  field_sms_code?: Maybe<StringQueryOperatorInput>;
  field_yearpercent?: Maybe<StringQueryOperatorInput>;
  field_email_contact_form_1?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_default_payment_type?: Maybe<StringQueryOperatorInput>;
  field_current_version?: Maybe<IntQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  max_tariff_localization?: Maybe<IntQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__AdministrationSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityAdministrationArgs = {
  filter?: Maybe<Site_Setting_Entity__AdministrationFilterInput>;
  sort?: Maybe<Site_Setting_Entity__AdministrationSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityMainPageArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_mp_text_footer?: Maybe<Field_Mp_Text_FooterFilterInput>;
  field_mp_text?: Maybe<Field_Mp_TextFilterInput>;
  field_mp_button_text?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_btn_url?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_mp_image_footer?: Maybe<Field_Mp_Image_FooterFilterInput>;
  field_mp_image?: Maybe<Field_Mp_ImageFilterInput>;
  relationships?: Maybe<Site_Setting_Entity__Main_PageRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Main_PageSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__Main_PageField_ComponentsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityMainPageArgs = {
  filter?: Maybe<Site_Setting_Entity__Main_PageFilterInput>;
  sort?: Maybe<Site_Setting_Entity__Main_PageSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityProductArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_hero_button_text?: Maybe<StringQueryOperatorInput>;
  field_hero_video?: Maybe<StringQueryOperatorInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_hero_image?: Maybe<Field_Hero_ImageFilterInput>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_MobileFilterInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_image_2?: Maybe<Field_Image_2FilterInput>;
  field_image_3?: Maybe<Field_Image_3FilterInput>;
  field_hero_video_thumbnail?: Maybe<Field_Hero_Video_ThumbnailFilterInput>;
  field_kasa_online?: Maybe<BooleanQueryOperatorInput>;
  field_porownaj_taryfy_w_cenniku?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_settings_text_7?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__ProductRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__ProductSite_Setting_Entity_TypeFilterInput>;
  field_components?: Maybe<Site_Setting_Entity__ProductField_ComponentsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityProductArgs = {
  filter?: Maybe<Site_Setting_Entity__ProductFilterInput>;
  sort?: Maybe<Site_Setting_Entity__ProductSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityKasaFiskalnaVsKasoterminalArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_hero_video?: Maybe<StringQueryOperatorInput>;
  field_hero_image?: Maybe<Field_Hero_ImageFilterInput>;
  field_hero_video_thumbnail?: Maybe<Field_Hero_Video_ThumbnailFilterInput>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_MobileFilterInput>;
  field_hero_button_text?: Maybe<StringQueryOperatorInput>;
  field_opisy_wykresow?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_text_3?: Maybe<Field_Text_3FilterInput>;
  field_text_4?: Maybe<Field_Text_4FilterInput>;
  relationships?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalField_ComponentsFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityKasaFiskalnaVsKasoterminalArgs = {
  filter?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFilterInput>;
  sort?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityBranchesArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_hero_image?: Maybe<Field_Hero_ImageFilterInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_hero_button_text?: Maybe<StringQueryOperatorInput>;
  field_button_text_1?: Maybe<StringQueryOperatorInput>;
  field_button_text_2?: Maybe<StringQueryOperatorInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_text_3?: Maybe<Field_Text_3FilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__BranchesRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__BranchesSite_Setting_Entity_TypeFilterInput
  >;
  field_hero_image_mobile?: Maybe<Site_Setting_Entity__BranchesField_Hero_Image_MobileFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityBranchesArgs = {
  filter?: Maybe<Site_Setting_Entity__BranchesFilterInput>;
  sort?: Maybe<Site_Setting_Entity__BranchesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeBranchesArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pl_is_online?: Maybe<BooleanQueryOperatorInput>;
  field_branches_date?: Maybe<StringQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  path?: Maybe<PathFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__BranchesRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_branches_icon?: Maybe<Node__BranchesField_Branches_IconFilterInput>;
  field_branches_image?: Maybe<Node__BranchesField_Branches_ImageFilterInput>;
  field_components?: Maybe<Node__BranchesField_ComponentsFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodeBranchesArgs = {
  filter?: Maybe<Node__BranchesFilterInput>;
  sort?: Maybe<Node__BranchesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryTaxonomyTermAccesoryTypeArgs = {
  name?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Taxonomy_Term__Accesory_TypeRelationshipsFilterInput>;
  vid?: Maybe<Taxonomy_Term__Accesory_TypeVidFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllTaxonomyTermAccesoryTypeArgs = {
  filter?: Maybe<Taxonomy_Term__Accesory_TypeFilterInput>;
  sort?: Maybe<Taxonomy_Term__Accesory_TypeSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeDevicesArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_subtitle?: Maybe<StringQueryOperatorInput>;
  field_pl_description_in_cms?: Maybe<Field_Pl_Description_In_CmsFilterInput>;
  field_pl_is_online?: Maybe<BooleanQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  field_pl_strikethrough_price?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__DevicesRelationshipsFilterInput>;
  field_tooltip_text_for_branches?: Maybe<StringQueryOperatorInput>;
  field_pl_is_virtual?: Maybe<BooleanQueryOperatorInput>;
  field_is_available?: Maybe<BooleanQueryOperatorInput>;
  field_lease_strike_through_price?: Maybe<StringQueryOperatorInput>;
  field_lease_allowed?: Maybe<BooleanQueryOperatorInput>;
  field_pl_price_list_name?: Maybe<StringQueryOperatorInput>;
  field_additional_price_lists?: Maybe<StringQueryOperatorInput>;
  field_popup_body?: Maybe<Field_Popup_BodyFilterInput>;
  field_popup_button_back?: Maybe<StringQueryOperatorInput>;
  field_popup_button_ok?: Maybe<StringQueryOperatorInput>;
  field_popup_enabled?: Maybe<BooleanQueryOperatorInput>;
  field_popup_title?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_payment_methods?: Maybe<Node__DevicesField_Payment_MethodsFilterListInput>;
  field_pl_cases?: Maybe<Node__DevicesField_Pl_CasesFilterListInput>;
  field_pl_image?: Maybe<Node__DevicesField_Pl_ImageFilterInput>;
  field_pl_image_for_accessories?: Maybe<Node__DevicesField_Pl_Image_For_AccessoriesFilterInput>;
  field_recommended_for_branches?: Maybe<
    Node__DevicesField_Recommended_For_BranchesFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodeDevicesArgs = {
  filter?: Maybe<Node__DevicesFilterInput>;
  sort?: Maybe<Node__DevicesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeCasesArgs = {
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pl_color?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__CasesRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_pl_image?: Maybe<Node__CasesField_Pl_ImageFilterInput>;
  field_recommended_for_branches?: Maybe<Node__CasesField_Recommended_For_BranchesFilterListInput>;
  field_date_from_api?: Maybe<Node__CasesField_Date_From_ApiFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodeCasesArgs = {
  filter?: Maybe<Node__CasesFilterInput>;
  sort?: Maybe<Node__CasesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeAccessoriesArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pl_groupid?: Maybe<IntQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  relationships?: Maybe<Node__AccessoriesRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_date_from_api?: Maybe<Node__AccessoriesField_Date_From_ApiFilterInput>;
  field_pl_accessory_type?: Maybe<Node__AccessoriesField_Pl_Accessory_TypeFilterInput>;
  field_pl_image?: Maybe<Node__AccessoriesField_Pl_ImageFilterInput>;
  field_pl_image_accessories_outl?: Maybe<
    Node__AccessoriesField_Pl_Image_Accessories_OutlFilterInput
  >;
  field_pl_image_accessories_table?: Maybe<
    Node__AccessoriesField_Pl_Image_Accessories_TableFilterInput
  >;
  field_recommended_for_branches?: Maybe<
    Node__AccessoriesField_Recommended_For_BranchesFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodeAccessoriesArgs = {
  filter?: Maybe<Node__AccessoriesFilterInput>;
  sort?: Maybe<Node__AccessoriesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeTariffsArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pl_description_in_cms?: Maybe<Field_Pl_Description_In_CmsFilterInput>;
  field_pl_is_popular?: Maybe<BooleanQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  relationships?: Maybe<Node__TariffsRelationshipsFilterInput>;
  price_list_type?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_profits?: Maybe<Node__TariffsField_ProfitsFilterListInput>;
  field_recommended_for_branches?: Maybe<
    Node__TariffsField_Recommended_For_BranchesFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodeTariffsArgs = {
  filter?: Maybe<Node__TariffsFilterInput>;
  sort?: Maybe<Node__TariffsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityConfiguratorArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_calendar_available?: Maybe<BooleanQueryOperatorInput>;
  field_info_text?: Maybe<Field_Info_TextFilterInput>;
  field_enable_info_text?: Maybe<BooleanQueryOperatorInput>;
  field_proste_pola?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_pola_wysywig?: Maybe<Site_Setting_Entity__ConfiguratorField_Pola_WysywigFilterListInput>;
  relationships?: Maybe<Site_Setting_Entity__ConfiguratorRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__ConfiguratorSite_Setting_Entity_TypeFilterInput
  >;
  field_images?: Maybe<Site_Setting_Entity__ConfiguratorField_ImagesFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityConfiguratorArgs = {
  filter?: Maybe<Site_Setting_Entity__ConfiguratorFilterInput>;
  sort?: Maybe<Site_Setting_Entity__ConfiguratorSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphProfitReferenceToPriceListArgs = {
  field_text?: Maybe<Field_TextFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Paragraph__Profit_Reference_To_Price_ListRelationshipsFilterInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_price_group_reference?: Maybe<
    Paragraph__Profit_Reference_To_Price_ListField_Price_Group_ReferenceFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphProfitReferenceToPriceListArgs = {
  filter?: Maybe<Paragraph__Profit_Reference_To_Price_ListFilterInput>;
  sort?: Maybe<Paragraph__Profit_Reference_To_Price_ListSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryTaxonomyTermPricelistGroupArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<DescriptionFilterInput>;
  field_discount?: Maybe<IntQueryOperatorInput>;
  field_name_in_api?: Maybe<StringQueryOperatorInput>;
  field_name_in_cms?: Maybe<StringQueryOperatorInput>;
  field_isperproduct?: Maybe<BooleanQueryOperatorInput>;
  field_maxfundedproducts?: Maybe<IntQueryOperatorInput>;
  field_publish?: Maybe<BooleanQueryOperatorInput>;
  field_text_when_unpublished?: Maybe<Field_Text_When_UnpublishedFilterInput>;
  relationships?: Maybe<Taxonomy_Term__Pricelist_GroupRelationshipsFilterInput>;
  field_is_discount?: Maybe<BooleanQueryOperatorInput>;
  field_side_panel_info?: Maybe<StringQueryOperatorInput>;
  field_is_default?: Maybe<BooleanQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  field_coupon_in_api?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  vid?: Maybe<Taxonomy_Term__Pricelist_GroupVidFilterInput>;
  field_pg_image?: Maybe<Taxonomy_Term__Pricelist_GroupField_Pg_ImageFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllTaxonomyTermPricelistGroupArgs = {
  filter?: Maybe<Taxonomy_Term__Pricelist_GroupFilterInput>;
  sort?: Maybe<Taxonomy_Term__Pricelist_GroupSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeFiscalizationArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<Fiscalization_DescriptionFilterInput>;
  appointment_available?: Maybe<BooleanQueryOperatorInput>;
  is_free?: Maybe<BooleanQueryOperatorInput>;
  is_popular?: Maybe<BooleanQueryOperatorInput>;
  price_list_name?: Maybe<StringQueryOperatorInput>;
  field_weight?: Maybe<IntQueryOperatorInput>;
  calendar_text?: Maybe<Fiscalization_Calendar_TextFilterInput>;
  disabled_fiscalization_messsage?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__FiscalizationRelationshipsFilterInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__vid?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  available_for_devices?: Maybe<Node__FiscalizationAvailable_For_DevicesFilterListInput>;
  image?: Maybe<Node__FiscalizationImageFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodeFiscalizationArgs = {
  filter?: Maybe<Node__FiscalizationFilterInput>;
  sort?: Maybe<Node__FiscalizationSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityPriceListArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_pl_header_1?: Maybe<Field_Pl_Header_1FilterInput>;
  field_pl_header_2?: Maybe<Field_Pl_Header_2FilterInput>;
  field_pl_header_3?: Maybe<Field_Pl_Header_3FilterInput>;
  field_pl_header_4?: Maybe<Field_Pl_Header_4FilterInput>;
  field_footer_left?: Maybe<Field_Footer_LeftFilterInput>;
  field_footer_right?: Maybe<Field_Footer_RightFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_texts?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__Price_ListRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Price_ListSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__Price_ListField_ComponentsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityPriceListArgs = {
  filter?: Maybe<Site_Setting_Entity__Price_ListFilterInput>;
  sort?: Maybe<Site_Setting_Entity__Price_ListSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityDemoArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_button_text_1?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_text_3?: Maybe<Field_Text_3FilterInput>;
  field_tooltip?: Maybe<StringQueryOperatorInput>;
  field_agreement_1?: Maybe<Field_Agreement_1FilterInput>;
  field_agreement_2?: Maybe<StringQueryOperatorInput>;
  field_agreement_3?: Maybe<Field_Agreement_3FilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__DemoSite_Setting_Entity_TypeFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityDemoArgs = {
  filter?: Maybe<Site_Setting_Entity__DemoFilterInput>;
  sort?: Maybe<Site_Setting_Entity__DemoSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityThankYouPagesArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_meta_title_1?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_description_1?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Thank_You_PagesSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityThankYouPagesArgs = {
  filter?: Maybe<Site_Setting_Entity__Thank_You_PagesFilterInput>;
  sort?: Maybe<Site_Setting_Entity__Thank_You_PagesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityBrakStrony404Args = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Brak_Strony_404_Site_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityBrakStrony404Args = {
  filter?: Maybe<Site_Setting_Entity__Brak_Strony_404_FilterInput>;
  sort?: Maybe<Site_Setting_Entity__Brak_Strony_404_SortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityAboutUsArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_btn_au_text_1?: Maybe<StringQueryOperatorInput>;
  field_btn_au_url_1?: Maybe<StringQueryOperatorInput>;
  field_info_text?: Maybe<Field_Info_TextFilterInput>;
  field_hero_image?: Maybe<Field_Hero_ImageFilterInput>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_MobileFilterInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_pl_header_1?: Maybe<Field_Pl_Header_1FilterInput>;
  relationships?: Maybe<Site_Setting_Entity__About_UsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__About_UsSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__About_UsField_ComponentsFilterListInput>;
  field_member_of_the_board_paragr?: Maybe<
    Site_Setting_Entity__About_UsField_Member_Of_The_Board_ParagrFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityAboutUsArgs = {
  filter?: Maybe<Site_Setting_Entity__About_UsFilterInput>;
  sort?: Maybe<Site_Setting_Entity__About_UsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryParagraphMemberOfTheBoardPersonalDatArgs = {
  field_description_member_board?: Maybe<Field_Description_Member_BoardFilterInput>;
  field_photo_member_of_the_board?: Maybe<Field_Photo_Member_Of_The_BoardFilterInput>;
  relationships?: Maybe<Paragraph__Member_Of_The_Board_Personal_DatRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  parent_id?: Maybe<StringQueryOperatorInput>;
  parent_type?: Maybe<StringQueryOperatorInput>;
  parent_field_name?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllParagraphMemberOfTheBoardPersonalDatArgs = {
  filter?: Maybe<Paragraph__Member_Of_The_Board_Personal_DatFilterInput>;
  sort?: Maybe<Paragraph__Member_Of_The_Board_Personal_DatSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityAktualnosciArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_settings_text_7?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__AktualnosciRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__AktualnosciSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityAktualnosciArgs = {
  filter?: Maybe<Site_Setting_Entity__AktualnosciFilterInput>;
  sort?: Maybe<Site_Setting_Entity__AktualnosciSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryTaxonomyTermNewsCategoriesArgs = {
  name?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Taxonomy_Term__News_CategoriesRelationshipsFilterInput>;
  vid?: Maybe<Taxonomy_Term__News_CategoriesVidFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllTaxonomyTermNewsCategoriesArgs = {
  filter?: Maybe<Taxonomy_Term__News_CategoriesFilterInput>;
  sort?: Maybe<Taxonomy_Term__News_CategoriesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryTaxonomyTermNewsTagsArgs = {
  name?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  rh_redirect_fallback_action?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Taxonomy_Term__News_TagsRelationshipsFilterInput>;
  vid?: Maybe<Taxonomy_Term__News_TagsVidFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllTaxonomyTermNewsTagsArgs = {
  filter?: Maybe<Taxonomy_Term__News_TagsFilterInput>;
  sort?: Maybe<Taxonomy_Term__News_TagsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeNewsArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_news_reading_time?: Maybe<IntQueryOperatorInput>;
  field_news_body?: Maybe<StringQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  path?: Maybe<PathFilterInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__NewsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  rh_redirect_fallback_action?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  field_meta_keywords?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_news_categories?: Maybe<Node__NewsField_News_CategoriesFilterInput>;
  field_news_components?: Maybe<Node__NewsField_News_ComponentsFilterListInput>;
  field_news_image?: Maybe<Node__NewsField_News_ImageFilterInput>;
  field_news_tags?: Maybe<Node__NewsField_News_TagsFilterListInput>;
  field_related_news_1?: Maybe<Node__NewsField_Related_News_1FilterInput>;
  field_related_news_2?: Maybe<Node__NewsField_Related_News_2FilterInput>;
  field_related_news_3?: Maybe<Node__NewsField_Related_News_3FilterInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodeNewsArgs = {
  filter?: Maybe<Node__NewsFilterInput>;
  sort?: Maybe<Node__NewsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityAccessoriesArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_hero_button_text?: Maybe<StringQueryOperatorInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  relationships?: Maybe<Site_Setting_Entity__AccessoriesRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__AccessoriesSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__AccessoriesField_ComponentsFilterListInput>;
  field_hero_image?: Maybe<Site_Setting_Entity__AccessoriesField_Hero_ImageFilterInput>;
  field_hero_image_mobile?: Maybe<
    Site_Setting_Entity__AccessoriesField_Hero_Image_MobileFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityAccessoriesArgs = {
  filter?: Maybe<Site_Setting_Entity__AccessoriesFilterInput>;
  sort?: Maybe<Site_Setting_Entity__AccessoriesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityFinancingArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__FinancingRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__FinancingSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__FinancingField_ComponentsFilterListInput>;
  field_main_image?: Maybe<Site_Setting_Entity__FinancingField_Main_ImageFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityFinancingArgs = {
  filter?: Maybe<Site_Setting_Entity__FinancingFilterInput>;
  sort?: Maybe<Site_Setting_Entity__FinancingSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityFranchiseArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_fr_form_branch?: Maybe<StringQueryOperatorInput>;
  field_fr_form_email?: Maybe<StringQueryOperatorInput>;
  field_fr_form_header?: Maybe<StringQueryOperatorInput>;
  field_fr_form_name?: Maybe<StringQueryOperatorInput>;
  field_fr_form_network_name?: Maybe<StringQueryOperatorInput>;
  field_fr_form_phone?: Maybe<StringQueryOperatorInput>;
  field_fr_form_shops_number?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_settings_text_7?: Maybe<StringQueryOperatorInput>;
  field_settings_text_8?: Maybe<StringQueryOperatorInput>;
  field_settings_text_9?: Maybe<StringQueryOperatorInput>;
  field_settings_text_10?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_modal_1?: Maybe<Field_Modal_1FilterInput>;
  field_modal_2?: Maybe<Field_Modal_2FilterInput>;
  relationships?: Maybe<Site_Setting_Entity__FranchiseRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__FranchiseSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__FranchiseField_ComponentsFilterListInput>;
  field_main_image?: Maybe<Site_Setting_Entity__FranchiseField_Main_ImageFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityFranchiseArgs = {
  filter?: Maybe<Site_Setting_Entity__FranchiseFilterInput>;
  sort?: Maybe<Site_Setting_Entity__FranchiseSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntityIntercarsArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_agreement_1?: Maybe<Field_Agreement_1FilterInput>;
  field_agreement_2?: Maybe<StringQueryOperatorInput>;
  field_agreement_3?: Maybe<Field_Agreement_3FilterInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_info_text?: Maybe<Field_Info_TextFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_teksty_formularza?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  relationships?: Maybe<Site_Setting_Entity__IntercarsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__IntercarsSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntityIntercarsArgs = {
  filter?: Maybe<Site_Setting_Entity__IntercarsFilterInput>;
  sort?: Maybe<Site_Setting_Entity__IntercarsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodePagesArgs = {
  langcode?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  field_pages_text?: Maybe<Field_Pages_TextFilterInput>;
  path?: Maybe<PathFilterInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__PagesRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__vid?: Maybe<IntQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  rh_redirect_fallback_action?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_keywords?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  field_components?: Maybe<Node__PagesField_ComponentsFilterListInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllNodePagesArgs = {
  filter?: Maybe<Node__PagesFilterInput>;
  sort?: Maybe<Node__PagesSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteSettingEntitySimpleFormArgs = {
  field_fr_form_email?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  field_agreement_1?: Maybe<Site_Setting_Entity__Simple_FormField_Agreement_1FilterInput>;
  field_fr_form_name?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_tooltip?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  field_modal_1?: Maybe<Site_Setting_Entity__Simple_FormField_Modal_1FilterInput>;
  field_modal_2?: Maybe<Site_Setting_Entity__Simple_FormField_Modal_2FilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Simple_FormSite_Setting_Entity_TypeFilterInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type QueryAllSiteSettingEntitySimpleFormArgs = {
  filter?: Maybe<Site_Setting_Entity__Simple_FormFilterInput>;
  sort?: Maybe<Site_Setting_Entity__Simple_FormSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySitePageArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  path?: Maybe<StringQueryOperatorInput>;
  internalComponentName?: Maybe<StringQueryOperatorInput>;
  component?: Maybe<StringQueryOperatorInput>;
  componentChunkName?: Maybe<StringQueryOperatorInput>;
  isCreatedByStatefulCreatePages?: Maybe<BooleanQueryOperatorInput>;
  context?: Maybe<SitePageContextFilterInput>;
  pluginCreator?: Maybe<SitePluginFilterInput>;
  pluginCreatorId?: Maybe<StringQueryOperatorInput>;
  componentPath?: Maybe<StringQueryOperatorInput>;
};

export type QueryAllSitePageArgs = {
  filter?: Maybe<SitePageFilterInput>;
  sort?: Maybe<SitePageSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySitePluginArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  resolve?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
  pluginOptions?: Maybe<SitePluginPluginOptionsFilterInput>;
  nodeAPIs?: Maybe<StringQueryOperatorInput>;
  browserAPIs?: Maybe<StringQueryOperatorInput>;
  ssrAPIs?: Maybe<StringQueryOperatorInput>;
  pluginFilepath?: Maybe<StringQueryOperatorInput>;
  packageJson?: Maybe<SitePluginPackageJsonFilterInput>;
};

export type QueryAllSitePluginArgs = {
  filter?: Maybe<SitePluginFilterInput>;
  sort?: Maybe<SitePluginSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QuerySiteArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  siteMetadata?: Maybe<SiteSiteMetadataFilterInput>;
  port?: Maybe<IntQueryOperatorInput>;
  host?: Maybe<StringQueryOperatorInput>;
  pathPrefix?: Maybe<StringQueryOperatorInput>;
  polyfill?: Maybe<BooleanQueryOperatorInput>;
  buildTime?: Maybe<DateQueryOperatorInput>;
};

export type QueryAllSiteArgs = {
  filter?: Maybe<SiteFilterInput>;
  sort?: Maybe<SiteSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryDirectoryArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  sourceInstanceName?: Maybe<StringQueryOperatorInput>;
  absolutePath?: Maybe<StringQueryOperatorInput>;
  relativePath?: Maybe<StringQueryOperatorInput>;
  extension?: Maybe<StringQueryOperatorInput>;
  size?: Maybe<IntQueryOperatorInput>;
  prettySize?: Maybe<StringQueryOperatorInput>;
  modifiedTime?: Maybe<DateQueryOperatorInput>;
  accessTime?: Maybe<DateQueryOperatorInput>;
  changeTime?: Maybe<DateQueryOperatorInput>;
  birthTime?: Maybe<DateQueryOperatorInput>;
  root?: Maybe<StringQueryOperatorInput>;
  dir?: Maybe<StringQueryOperatorInput>;
  base?: Maybe<StringQueryOperatorInput>;
  ext?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  relativeDirectory?: Maybe<StringQueryOperatorInput>;
  dev?: Maybe<IntQueryOperatorInput>;
  mode?: Maybe<IntQueryOperatorInput>;
  nlink?: Maybe<IntQueryOperatorInput>;
  uid?: Maybe<IntQueryOperatorInput>;
  gid?: Maybe<IntQueryOperatorInput>;
  rdev?: Maybe<IntQueryOperatorInput>;
  blksize?: Maybe<IntQueryOperatorInput>;
  ino?: Maybe<IntQueryOperatorInput>;
  blocks?: Maybe<IntQueryOperatorInput>;
  atimeMs?: Maybe<FloatQueryOperatorInput>;
  mtimeMs?: Maybe<FloatQueryOperatorInput>;
  ctimeMs?: Maybe<FloatQueryOperatorInput>;
  birthtimeMs?: Maybe<FloatQueryOperatorInput>;
  atime?: Maybe<DateQueryOperatorInput>;
  mtime?: Maybe<DateQueryOperatorInput>;
  ctime?: Maybe<DateQueryOperatorInput>;
  birthtime?: Maybe<DateQueryOperatorInput>;
};

export type QueryAllDirectoryArgs = {
  filter?: Maybe<DirectoryFilterInput>;
  sort?: Maybe<DirectorySortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryNodeBlogPostArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__nid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__vid?: Maybe<IntQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  path?: Maybe<Node__Blog_PostPathFilterInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  body?: Maybe<Node__Blog_PostBodyFilterInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Node__Blog_PostRelationshipsFilterInput>;
  field_blog_post_image?: Maybe<Node__Blog_PostField_Blog_Post_ImageFilterInput>;
};

export type QueryAllNodeBlogPostArgs = {
  filter?: Maybe<Node__Blog_PostFilterInput>;
  sort?: Maybe<Node__Blog_PostSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryTaxonomyTermTypeInCmsArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  vid?: Maybe<Taxonomy_Term__Type_In_CmsVidFilterInput>;
};

export type QueryAllTaxonomyTermTypeInCmsArgs = {
  filter?: Maybe<Taxonomy_Term__Type_In_CmsFilterInput>;
  sort?: Maybe<Taxonomy_Term__Type_In_CmsSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryJsonJsonArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  default?: Maybe<StringQueryOperatorInput>;
  codes?: Maybe<StringQueryOperatorInput>;
};

export type QueryAllJsonJsonArgs = {
  filter?: Maybe<JsonJsonFilterInput>;
  sort?: Maybe<JsonJsonSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryProductsJsonArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  name?: Maybe<StringQueryOperatorInput>;
  image?: Maybe<FileFilterInput>;
  imageForAccessories?: Maybe<FileFilterInput>;
  hasOnline?: Maybe<BooleanQueryOperatorInput>;
  description?: Maybe<StringQueryOperatorInput>;
  price?: Maybe<IntQueryOperatorInput>;
  fiscalization?: Maybe<IntQueryOperatorInput>;
  availableColors?: Maybe<ProductsJsonAvailableColorsFilterListInput>;
};

export type QueryAllProductsJsonArgs = {
  filter?: Maybe<ProductsJsonFilterInput>;
  sort?: Maybe<ProductsJsonSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type SectionComponents =
  | Paragraph__Heading_And_Links
  | Paragraph__Heading_And_Text
  | Paragraph__Heading_And_Agreements;

export type SimplePageRelatedComponents =
  | Paragraph__Branches_Hero
  | Paragraph__Branches_What_Is_Ipos
  | Paragraph__Branches_Recommendation
  | Paragraph__Branches_Features
  | Paragraph__Bullets_Container
  | Paragraph__Branches_Recommended_Devices
  | Paragraph__Branches_Recommended_Tariffs
  | Paragraph__Branches_Check_What_S_Going_On
  | Paragraph__Branches_Testimonials
  | Paragraph__Discounts_Container
  | Paragraph__Simple_Form;

export type Site = Node & {
  __typename?: 'Site';
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  siteMetadata?: Maybe<SiteSiteMetadata>;
  port?: Maybe<Scalars['Int']>;
  host?: Maybe<Scalars['String']>;
  pathPrefix?: Maybe<Scalars['String']>;
  polyfill?: Maybe<Scalars['Boolean']>;
  buildTime?: Maybe<Scalars['Date']>;
};

export type SiteBuildTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__About_Us = Node & {
  __typename?: 'site_setting_entity__about_us';
  langcode?: Maybe<Scalars['String']>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_btn_au_text_1?: Maybe<Scalars['String']>;
  field_btn_au_url_1?: Maybe<Scalars['String']>;
  field_info_text?: Maybe<Field_Info_Text>;
  field_hero_image?: Maybe<Field_Hero_Image>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_Mobile>;
  field_hero_text?: Maybe<Field_Hero_Text>;
  field_hero_headline?: Maybe<Field_Hero_Headline>;
  field_pl_header_1?: Maybe<Field_Pl_Header_1>;
  relationships?: Maybe<Site_Setting_Entity__About_UsRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__About_UsSite_Setting_Entity_Type>;
  field_components?: Maybe<Array<Maybe<Site_Setting_Entity__About_UsField_Components>>>;
  field_member_of_the_board_paragr?: Maybe<
    Array<Maybe<Site_Setting_Entity__About_UsField_Member_Of_The_Board_Paragr>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__About_UsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__About_UsChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__About_UsContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__About_UsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__About_UsConnection = {
  __typename?: 'site_setting_entity__about_usConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__About_UsEdge>;
  nodes: Array<Site_Setting_Entity__About_Us>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__About_UsGroupConnection>;
};

export type Site_Setting_Entity__About_UsConnectionDistinctArgs = {
  field: Site_Setting_Entity__About_UsFieldsEnum;
};

export type Site_Setting_Entity__About_UsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__About_UsFieldsEnum;
};

export type Site_Setting_Entity__About_UsEdge = {
  __typename?: 'site_setting_entity__about_usEdge';
  next?: Maybe<Site_Setting_Entity__About_Us>;
  node: Site_Setting_Entity__About_Us;
  previous?: Maybe<Site_Setting_Entity__About_Us>;
};

export type Site_Setting_Entity__About_UsField_Components = {
  __typename?: 'site_setting_entity__about_usField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__About_UsField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__About_UsField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__About_UsField_ComponentsFilterInput>;
};

export type Site_Setting_Entity__About_UsField_Member_Of_The_Board_Paragr = {
  __typename?: 'site_setting_entity__about_usField_member_of_the_board_paragr';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__About_UsField_Member_Of_The_Board_ParagrFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__About_UsField_Member_Of_The_Board_ParagrFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__About_UsField_Member_Of_The_Board_ParagrFilterInput>;
};

export enum Site_Setting_Entity__About_UsFieldsEnum {
  langcode = 'langcode',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  field_btn_au_text_1 = 'field_btn_au_text_1',
  field_btn_au_url_1 = 'field_btn_au_url_1',
  field_info_text___value = 'field_info_text___value',
  field_hero_image___alt = 'field_hero_image___alt',
  field_hero_image_mobile___alt = 'field_hero_image_mobile___alt',
  field_hero_text___value = 'field_hero_text___value',
  field_hero_headline___value = 'field_hero_headline___value',
  field_pl_header_1___value = 'field_pl_header_1___value',
  relationships___field_components = 'relationships___field_components',
  relationships___field_components___internal___content = 'relationships___field_components___internal___content',
  relationships___field_components___internal___contentDigest = 'relationships___field_components___internal___contentDigest',
  relationships___field_components___internal___description = 'relationships___field_components___internal___description',
  relationships___field_components___internal___fieldOwners = 'relationships___field_components___internal___fieldOwners',
  relationships___field_components___internal___ignoreType = 'relationships___field_components___internal___ignoreType',
  relationships___field_components___internal___mediaType = 'relationships___field_components___internal___mediaType',
  relationships___field_components___internal___owner = 'relationships___field_components___internal___owner',
  relationships___field_components___internal___type = 'relationships___field_components___internal___type',
  relationships___field_components___id = 'relationships___field_components___id',
  relationships___field_components___drupal_id = 'relationships___field_components___drupal_id',
  relationships___field_components___relationships___field_bullets_item = 'relationships___field_components___relationships___field_bullets_item',
  relationships___field_components___relationships___node__branches = 'relationships___field_components___relationships___node__branches',
  relationships___field_components___relationships___site_setting_entity__about_us = 'relationships___field_components___relationships___site_setting_entity__about_us',
  relationships___field_components___relationships___site_setting_entity__financing = 'relationships___field_components___relationships___site_setting_entity__financing',
  relationships___field_components___relationships___site_setting_entity__franchise = 'relationships___field_components___relationships___site_setting_entity__franchise',
  relationships___field_components___relationships___site_setting_entity__main_page = 'relationships___field_components___relationships___site_setting_entity__main_page',
  relationships___field_components___relationships___site_setting_entity__product = 'relationships___field_components___relationships___site_setting_entity__product',
  relationships___field_components___relationships___node__pages = 'relationships___field_components___relationships___node__pages',
  relationships___field_components___drupal_internal__id = 'relationships___field_components___drupal_internal__id',
  relationships___field_components___status = 'relationships___field_components___status',
  relationships___field_components___created = 'relationships___field_components___created',
  relationships___field_components___parent_id = 'relationships___field_components___parent_id',
  relationships___field_components___parent_type = 'relationships___field_components___parent_type',
  relationships___field_components___parent_field_name = 'relationships___field_components___parent_field_name',
  relationships___field_components___content_translation_source = 'relationships___field_components___content_translation_source',
  relationships___field_components___content_translation_outdated = 'relationships___field_components___content_translation_outdated',
  relationships___field_components___content_translation_changed = 'relationships___field_components___content_translation_changed',
  relationships___field_components___langcode = 'relationships___field_components___langcode',
  relationships___field_components___uuid = 'relationships___field_components___uuid',
  relationships___field_components___field_bullets_item = 'relationships___field_components___field_bullets_item',
  relationships___field_components___field_bullets_item___target_revision_id = 'relationships___field_components___field_bullets_item___target_revision_id',
  relationships___field_components___field_bullets_item___drupal_internal__target_id = 'relationships___field_components___field_bullets_item___drupal_internal__target_id',
  relationships___field_components___parent___id = 'relationships___field_components___parent___id',
  relationships___field_components___parent___children = 'relationships___field_components___parent___children',
  relationships___field_components___children = 'relationships___field_components___children',
  relationships___field_components___children___id = 'relationships___field_components___children___id',
  relationships___field_components___children___children = 'relationships___field_components___children___children',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  relationships___field_hero_image___localFile___birthtime = 'relationships___field_hero_image___localFile___birthtime',
  relationships___field_hero_image___localFile___birthtimeMs = 'relationships___field_hero_image___localFile___birthtimeMs',
  relationships___field_hero_image___localFile___publicURL = 'relationships___field_hero_image___localFile___publicURL',
  relationships___field_hero_image___localFile___sourceInstanceName = 'relationships___field_hero_image___localFile___sourceInstanceName',
  relationships___field_hero_image___localFile___absolutePath = 'relationships___field_hero_image___localFile___absolutePath',
  relationships___field_hero_image___localFile___relativePath = 'relationships___field_hero_image___localFile___relativePath',
  relationships___field_hero_image___localFile___extension = 'relationships___field_hero_image___localFile___extension',
  relationships___field_hero_image___localFile___size = 'relationships___field_hero_image___localFile___size',
  relationships___field_hero_image___localFile___prettySize = 'relationships___field_hero_image___localFile___prettySize',
  relationships___field_hero_image___localFile___modifiedTime = 'relationships___field_hero_image___localFile___modifiedTime',
  relationships___field_hero_image___localFile___accessTime = 'relationships___field_hero_image___localFile___accessTime',
  relationships___field_hero_image___localFile___changeTime = 'relationships___field_hero_image___localFile___changeTime',
  relationships___field_hero_image___localFile___birthTime = 'relationships___field_hero_image___localFile___birthTime',
  relationships___field_hero_image___localFile___root = 'relationships___field_hero_image___localFile___root',
  relationships___field_hero_image___localFile___dir = 'relationships___field_hero_image___localFile___dir',
  relationships___field_hero_image___localFile___base = 'relationships___field_hero_image___localFile___base',
  relationships___field_hero_image___localFile___ext = 'relationships___field_hero_image___localFile___ext',
  relationships___field_hero_image___localFile___name = 'relationships___field_hero_image___localFile___name',
  relationships___field_hero_image___localFile___relativeDirectory = 'relationships___field_hero_image___localFile___relativeDirectory',
  relationships___field_hero_image___localFile___dev = 'relationships___field_hero_image___localFile___dev',
  relationships___field_hero_image___localFile___mode = 'relationships___field_hero_image___localFile___mode',
  relationships___field_hero_image___localFile___nlink = 'relationships___field_hero_image___localFile___nlink',
  relationships___field_hero_image___localFile___uid = 'relationships___field_hero_image___localFile___uid',
  relationships___field_hero_image___localFile___gid = 'relationships___field_hero_image___localFile___gid',
  relationships___field_hero_image___localFile___rdev = 'relationships___field_hero_image___localFile___rdev',
  relationships___field_hero_image___localFile___blksize = 'relationships___field_hero_image___localFile___blksize',
  relationships___field_hero_image___localFile___ino = 'relationships___field_hero_image___localFile___ino',
  relationships___field_hero_image___localFile___blocks = 'relationships___field_hero_image___localFile___blocks',
  relationships___field_hero_image___localFile___atimeMs = 'relationships___field_hero_image___localFile___atimeMs',
  relationships___field_hero_image___localFile___mtimeMs = 'relationships___field_hero_image___localFile___mtimeMs',
  relationships___field_hero_image___localFile___ctimeMs = 'relationships___field_hero_image___localFile___ctimeMs',
  relationships___field_hero_image___localFile___atime = 'relationships___field_hero_image___localFile___atime',
  relationships___field_hero_image___localFile___mtime = 'relationships___field_hero_image___localFile___mtime',
  relationships___field_hero_image___localFile___ctime = 'relationships___field_hero_image___localFile___ctime',
  relationships___field_hero_image___localFile___url = 'relationships___field_hero_image___localFile___url',
  relationships___field_hero_image___localFile___id = 'relationships___field_hero_image___localFile___id',
  relationships___field_hero_image___localFile___children = 'relationships___field_hero_image___localFile___children',
  relationships___field_hero_image___uri___url = 'relationships___field_hero_image___uri___url',
  relationships___field_hero_image___drupal_id = 'relationships___field_hero_image___drupal_id',
  relationships___field_hero_image___drupal_internal__fid = 'relationships___field_hero_image___drupal_internal__fid',
  relationships___field_hero_image___langcode = 'relationships___field_hero_image___langcode',
  relationships___field_hero_image___filename = 'relationships___field_hero_image___filename',
  relationships___field_hero_image___filemime = 'relationships___field_hero_image___filemime',
  relationships___field_hero_image___filesize = 'relationships___field_hero_image___filesize',
  relationships___field_hero_image___status = 'relationships___field_hero_image___status',
  relationships___field_hero_image___created = 'relationships___field_hero_image___created',
  relationships___field_hero_image___changed = 'relationships___field_hero_image___changed',
  relationships___field_hero_image___uuid = 'relationships___field_hero_image___uuid',
  relationships___field_hero_image___relationships___node__blog_post = 'relationships___field_hero_image___relationships___node__blog_post',
  relationships___field_hero_image___relationships___node__devices = 'relationships___field_hero_image___relationships___node__devices',
  relationships___field_hero_image___relationships___node__accessories = 'relationships___field_hero_image___relationships___node__accessories',
  relationships___field_hero_image___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image___relationships___node__cases = 'relationships___field_hero_image___relationships___node__cases',
  relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image___relationships___paragraph__branches_features = 'relationships___field_hero_image___relationships___paragraph__branches_features',
  relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image___relationships___paragraph__branches_discounts = 'relationships___field_hero_image___relationships___paragraph__branches_discounts',
  relationships___field_hero_image___relationships___node__news = 'relationships___field_hero_image___relationships___node__news',
  relationships___field_hero_image___relationships___node__branches = 'relationships___field_hero_image___relationships___node__branches',
  relationships___field_hero_image___relationships___paragraph__branches_hero = 'relationships___field_hero_image___relationships___paragraph__branches_hero',
  relationships___field_hero_image___relationships___paragraph__bullets = 'relationships___field_hero_image___relationships___paragraph__bullets',
  relationships___field_hero_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image___relationships___paragraph__branches_device = 'relationships___field_hero_image___relationships___paragraph__branches_device',
  relationships___field_hero_image___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image___relationships___site_setting_entity__accessories = 'relationships___field_hero_image___relationships___site_setting_entity__accessories',
  relationships___field_hero_image___relationships___site_setting_entity__branches = 'relationships___field_hero_image___relationships___site_setting_entity__branches',
  relationships___field_hero_image___relationships___site_setting_entity__about_us = 'relationships___field_hero_image___relationships___site_setting_entity__about_us',
  relationships___field_hero_image___relationships___paragraph__logos = 'relationships___field_hero_image___relationships___paragraph__logos',
  relationships___field_hero_image___relationships___paragraph__how_it_works = 'relationships___field_hero_image___relationships___paragraph__how_it_works',
  relationships___field_hero_image___relationships___paragraph__ico_and_text = 'relationships___field_hero_image___relationships___paragraph__ico_and_text',
  relationships___field_hero_image___relationships___paragraph__hero_1 = 'relationships___field_hero_image___relationships___paragraph__hero_1',
  relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image___relationships___site_setting_entity__product = 'relationships___field_hero_image___relationships___site_setting_entity__product',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image___relationships___site_setting_entity__main_page = 'relationships___field_hero_image___relationships___site_setting_entity__main_page',
  relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image___relationships___site_setting_entity__intercars = 'relationships___field_hero_image___relationships___site_setting_entity__intercars',
  relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image___relationships___node__agreement = 'relationships___field_hero_image___relationships___node__agreement',
  relationships___field_hero_image___relationships___node__fiscalization = 'relationships___field_hero_image___relationships___node__fiscalization',
  relationships___field_hero_image___relationships___site_setting_entity__configurator = 'relationships___field_hero_image___relationships___site_setting_entity__configurator',
  relationships___field_hero_image___relationships___paragraph__social_link = 'relationships___field_hero_image___relationships___paragraph__social_link',
  relationships___field_hero_image___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image___relationships___paragraph__linked_icons = 'relationships___field_hero_image___relationships___paragraph__linked_icons',
  relationships___field_hero_image___relationships___site_setting_entity__financing = 'relationships___field_hero_image___relationships___site_setting_entity__financing',
  relationships___field_hero_image___relationships___site_setting_entity__franchise = 'relationships___field_hero_image___relationships___site_setting_entity__franchise',
  relationships___field_hero_image___id = 'relationships___field_hero_image___id',
  relationships___field_hero_image___parent___id = 'relationships___field_hero_image___parent___id',
  relationships___field_hero_image___parent___children = 'relationships___field_hero_image___parent___children',
  relationships___field_hero_image___children = 'relationships___field_hero_image___children',
  relationships___field_hero_image___children___id = 'relationships___field_hero_image___children___id',
  relationships___field_hero_image___children___children = 'relationships___field_hero_image___children___children',
  relationships___field_hero_image___internal___content = 'relationships___field_hero_image___internal___content',
  relationships___field_hero_image___internal___contentDigest = 'relationships___field_hero_image___internal___contentDigest',
  relationships___field_hero_image___internal___description = 'relationships___field_hero_image___internal___description',
  relationships___field_hero_image___internal___fieldOwners = 'relationships___field_hero_image___internal___fieldOwners',
  relationships___field_hero_image___internal___ignoreType = 'relationships___field_hero_image___internal___ignoreType',
  relationships___field_hero_image___internal___mediaType = 'relationships___field_hero_image___internal___mediaType',
  relationships___field_hero_image___internal___owner = 'relationships___field_hero_image___internal___owner',
  relationships___field_hero_image___internal___type = 'relationships___field_hero_image___internal___type',
  relationships___field_hero_image_mobile___localFile___birthtime = 'relationships___field_hero_image_mobile___localFile___birthtime',
  relationships___field_hero_image_mobile___localFile___birthtimeMs = 'relationships___field_hero_image_mobile___localFile___birthtimeMs',
  relationships___field_hero_image_mobile___localFile___publicURL = 'relationships___field_hero_image_mobile___localFile___publicURL',
  relationships___field_hero_image_mobile___localFile___sourceInstanceName = 'relationships___field_hero_image_mobile___localFile___sourceInstanceName',
  relationships___field_hero_image_mobile___localFile___absolutePath = 'relationships___field_hero_image_mobile___localFile___absolutePath',
  relationships___field_hero_image_mobile___localFile___relativePath = 'relationships___field_hero_image_mobile___localFile___relativePath',
  relationships___field_hero_image_mobile___localFile___extension = 'relationships___field_hero_image_mobile___localFile___extension',
  relationships___field_hero_image_mobile___localFile___size = 'relationships___field_hero_image_mobile___localFile___size',
  relationships___field_hero_image_mobile___localFile___prettySize = 'relationships___field_hero_image_mobile___localFile___prettySize',
  relationships___field_hero_image_mobile___localFile___modifiedTime = 'relationships___field_hero_image_mobile___localFile___modifiedTime',
  relationships___field_hero_image_mobile___localFile___accessTime = 'relationships___field_hero_image_mobile___localFile___accessTime',
  relationships___field_hero_image_mobile___localFile___changeTime = 'relationships___field_hero_image_mobile___localFile___changeTime',
  relationships___field_hero_image_mobile___localFile___birthTime = 'relationships___field_hero_image_mobile___localFile___birthTime',
  relationships___field_hero_image_mobile___localFile___root = 'relationships___field_hero_image_mobile___localFile___root',
  relationships___field_hero_image_mobile___localFile___dir = 'relationships___field_hero_image_mobile___localFile___dir',
  relationships___field_hero_image_mobile___localFile___base = 'relationships___field_hero_image_mobile___localFile___base',
  relationships___field_hero_image_mobile___localFile___ext = 'relationships___field_hero_image_mobile___localFile___ext',
  relationships___field_hero_image_mobile___localFile___name = 'relationships___field_hero_image_mobile___localFile___name',
  relationships___field_hero_image_mobile___localFile___relativeDirectory = 'relationships___field_hero_image_mobile___localFile___relativeDirectory',
  relationships___field_hero_image_mobile___localFile___dev = 'relationships___field_hero_image_mobile___localFile___dev',
  relationships___field_hero_image_mobile___localFile___mode = 'relationships___field_hero_image_mobile___localFile___mode',
  relationships___field_hero_image_mobile___localFile___nlink = 'relationships___field_hero_image_mobile___localFile___nlink',
  relationships___field_hero_image_mobile___localFile___uid = 'relationships___field_hero_image_mobile___localFile___uid',
  relationships___field_hero_image_mobile___localFile___gid = 'relationships___field_hero_image_mobile___localFile___gid',
  relationships___field_hero_image_mobile___localFile___rdev = 'relationships___field_hero_image_mobile___localFile___rdev',
  relationships___field_hero_image_mobile___localFile___blksize = 'relationships___field_hero_image_mobile___localFile___blksize',
  relationships___field_hero_image_mobile___localFile___ino = 'relationships___field_hero_image_mobile___localFile___ino',
  relationships___field_hero_image_mobile___localFile___blocks = 'relationships___field_hero_image_mobile___localFile___blocks',
  relationships___field_hero_image_mobile___localFile___atimeMs = 'relationships___field_hero_image_mobile___localFile___atimeMs',
  relationships___field_hero_image_mobile___localFile___mtimeMs = 'relationships___field_hero_image_mobile___localFile___mtimeMs',
  relationships___field_hero_image_mobile___localFile___ctimeMs = 'relationships___field_hero_image_mobile___localFile___ctimeMs',
  relationships___field_hero_image_mobile___localFile___atime = 'relationships___field_hero_image_mobile___localFile___atime',
  relationships___field_hero_image_mobile___localFile___mtime = 'relationships___field_hero_image_mobile___localFile___mtime',
  relationships___field_hero_image_mobile___localFile___ctime = 'relationships___field_hero_image_mobile___localFile___ctime',
  relationships___field_hero_image_mobile___localFile___url = 'relationships___field_hero_image_mobile___localFile___url',
  relationships___field_hero_image_mobile___localFile___id = 'relationships___field_hero_image_mobile___localFile___id',
  relationships___field_hero_image_mobile___localFile___children = 'relationships___field_hero_image_mobile___localFile___children',
  relationships___field_hero_image_mobile___uri___url = 'relationships___field_hero_image_mobile___uri___url',
  relationships___field_hero_image_mobile___drupal_id = 'relationships___field_hero_image_mobile___drupal_id',
  relationships___field_hero_image_mobile___drupal_internal__fid = 'relationships___field_hero_image_mobile___drupal_internal__fid',
  relationships___field_hero_image_mobile___langcode = 'relationships___field_hero_image_mobile___langcode',
  relationships___field_hero_image_mobile___filename = 'relationships___field_hero_image_mobile___filename',
  relationships___field_hero_image_mobile___filemime = 'relationships___field_hero_image_mobile___filemime',
  relationships___field_hero_image_mobile___filesize = 'relationships___field_hero_image_mobile___filesize',
  relationships___field_hero_image_mobile___status = 'relationships___field_hero_image_mobile___status',
  relationships___field_hero_image_mobile___created = 'relationships___field_hero_image_mobile___created',
  relationships___field_hero_image_mobile___changed = 'relationships___field_hero_image_mobile___changed',
  relationships___field_hero_image_mobile___uuid = 'relationships___field_hero_image_mobile___uuid',
  relationships___field_hero_image_mobile___relationships___node__blog_post = 'relationships___field_hero_image_mobile___relationships___node__blog_post',
  relationships___field_hero_image_mobile___relationships___node__devices = 'relationships___field_hero_image_mobile___relationships___node__devices',
  relationships___field_hero_image_mobile___relationships___node__accessories = 'relationships___field_hero_image_mobile___relationships___node__accessories',
  relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image_mobile___relationships___node__cases = 'relationships___field_hero_image_mobile___relationships___node__cases',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_features = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_features',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts',
  relationships___field_hero_image_mobile___relationships___node__news = 'relationships___field_hero_image_mobile___relationships___node__news',
  relationships___field_hero_image_mobile___relationships___node__branches = 'relationships___field_hero_image_mobile___relationships___node__branches',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_hero = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_hero',
  relationships___field_hero_image_mobile___relationships___paragraph__bullets = 'relationships___field_hero_image_mobile___relationships___paragraph__bullets',
  relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_device = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_device',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__branches = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__branches',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us',
  relationships___field_hero_image_mobile___relationships___paragraph__logos = 'relationships___field_hero_image_mobile___relationships___paragraph__logos',
  relationships___field_hero_image_mobile___relationships___paragraph__how_it_works = 'relationships___field_hero_image_mobile___relationships___paragraph__how_it_works',
  relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text = 'relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text',
  relationships___field_hero_image_mobile___relationships___paragraph__hero_1 = 'relationships___field_hero_image_mobile___relationships___paragraph__hero_1',
  relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__product = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__product',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars',
  relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image_mobile___relationships___node__agreement = 'relationships___field_hero_image_mobile___relationships___node__agreement',
  relationships___field_hero_image_mobile___relationships___node__fiscalization = 'relationships___field_hero_image_mobile___relationships___node__fiscalization',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator',
  relationships___field_hero_image_mobile___relationships___paragraph__social_link = 'relationships___field_hero_image_mobile___relationships___paragraph__social_link',
  relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image_mobile___relationships___paragraph__linked_icons = 'relationships___field_hero_image_mobile___relationships___paragraph__linked_icons',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__financing = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__financing',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise',
  relationships___field_hero_image_mobile___id = 'relationships___field_hero_image_mobile___id',
  relationships___field_hero_image_mobile___parent___id = 'relationships___field_hero_image_mobile___parent___id',
  relationships___field_hero_image_mobile___parent___children = 'relationships___field_hero_image_mobile___parent___children',
  relationships___field_hero_image_mobile___children = 'relationships___field_hero_image_mobile___children',
  relationships___field_hero_image_mobile___children___id = 'relationships___field_hero_image_mobile___children___id',
  relationships___field_hero_image_mobile___children___children = 'relationships___field_hero_image_mobile___children___children',
  relationships___field_hero_image_mobile___internal___content = 'relationships___field_hero_image_mobile___internal___content',
  relationships___field_hero_image_mobile___internal___contentDigest = 'relationships___field_hero_image_mobile___internal___contentDigest',
  relationships___field_hero_image_mobile___internal___description = 'relationships___field_hero_image_mobile___internal___description',
  relationships___field_hero_image_mobile___internal___fieldOwners = 'relationships___field_hero_image_mobile___internal___fieldOwners',
  relationships___field_hero_image_mobile___internal___ignoreType = 'relationships___field_hero_image_mobile___internal___ignoreType',
  relationships___field_hero_image_mobile___internal___mediaType = 'relationships___field_hero_image_mobile___internal___mediaType',
  relationships___field_hero_image_mobile___internal___owner = 'relationships___field_hero_image_mobile___internal___owner',
  relationships___field_hero_image_mobile___internal___type = 'relationships___field_hero_image_mobile___internal___type',
  relationships___field_member_of_the_board_paragr = 'relationships___field_member_of_the_board_paragr',
  relationships___field_member_of_the_board_paragr___field_description_member_board___value = 'relationships___field_member_of_the_board_paragr___field_description_member_board___value',
  relationships___field_member_of_the_board_paragr___field_photo_member_of_the_board___alt = 'relationships___field_member_of_the_board_paragr___field_photo_member_of_the_board___alt',
  relationships___field_member_of_the_board_paragr___relationships___site_setting_entity__about_us = 'relationships___field_member_of_the_board_paragr___relationships___site_setting_entity__about_us',
  relationships___field_member_of_the_board_paragr___drupal_id = 'relationships___field_member_of_the_board_paragr___drupal_id',
  relationships___field_member_of_the_board_paragr___drupal_internal__id = 'relationships___field_member_of_the_board_paragr___drupal_internal__id',
  relationships___field_member_of_the_board_paragr___drupal_internal__revision_id = 'relationships___field_member_of_the_board_paragr___drupal_internal__revision_id',
  relationships___field_member_of_the_board_paragr___langcode = 'relationships___field_member_of_the_board_paragr___langcode',
  relationships___field_member_of_the_board_paragr___status = 'relationships___field_member_of_the_board_paragr___status',
  relationships___field_member_of_the_board_paragr___created = 'relationships___field_member_of_the_board_paragr___created',
  relationships___field_member_of_the_board_paragr___parent_id = 'relationships___field_member_of_the_board_paragr___parent_id',
  relationships___field_member_of_the_board_paragr___parent_type = 'relationships___field_member_of_the_board_paragr___parent_type',
  relationships___field_member_of_the_board_paragr___parent_field_name = 'relationships___field_member_of_the_board_paragr___parent_field_name',
  relationships___field_member_of_the_board_paragr___default_langcode = 'relationships___field_member_of_the_board_paragr___default_langcode',
  relationships___field_member_of_the_board_paragr___revision_translation_affected = 'relationships___field_member_of_the_board_paragr___revision_translation_affected',
  relationships___field_member_of_the_board_paragr___content_translation_source = 'relationships___field_member_of_the_board_paragr___content_translation_source',
  relationships___field_member_of_the_board_paragr___content_translation_outdated = 'relationships___field_member_of_the_board_paragr___content_translation_outdated',
  relationships___field_member_of_the_board_paragr___content_translation_changed = 'relationships___field_member_of_the_board_paragr___content_translation_changed',
  relationships___field_member_of_the_board_paragr___uuid = 'relationships___field_member_of_the_board_paragr___uuid',
  relationships___field_member_of_the_board_paragr___id = 'relationships___field_member_of_the_board_paragr___id',
  relationships___field_member_of_the_board_paragr___parent___id = 'relationships___field_member_of_the_board_paragr___parent___id',
  relationships___field_member_of_the_board_paragr___parent___children = 'relationships___field_member_of_the_board_paragr___parent___children',
  relationships___field_member_of_the_board_paragr___children = 'relationships___field_member_of_the_board_paragr___children',
  relationships___field_member_of_the_board_paragr___children___id = 'relationships___field_member_of_the_board_paragr___children___id',
  relationships___field_member_of_the_board_paragr___children___children = 'relationships___field_member_of_the_board_paragr___children___children',
  relationships___field_member_of_the_board_paragr___internal___content = 'relationships___field_member_of_the_board_paragr___internal___content',
  relationships___field_member_of_the_board_paragr___internal___contentDigest = 'relationships___field_member_of_the_board_paragr___internal___contentDigest',
  relationships___field_member_of_the_board_paragr___internal___description = 'relationships___field_member_of_the_board_paragr___internal___description',
  relationships___field_member_of_the_board_paragr___internal___fieldOwners = 'relationships___field_member_of_the_board_paragr___internal___fieldOwners',
  relationships___field_member_of_the_board_paragr___internal___ignoreType = 'relationships___field_member_of_the_board_paragr___internal___ignoreType',
  relationships___field_member_of_the_board_paragr___internal___mediaType = 'relationships___field_member_of_the_board_paragr___internal___mediaType',
  relationships___field_member_of_the_board_paragr___internal___owner = 'relationships___field_member_of_the_board_paragr___internal___owner',
  relationships___field_member_of_the_board_paragr___internal___type = 'relationships___field_member_of_the_board_paragr___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  field_member_of_the_board_paragr = 'field_member_of_the_board_paragr',
  field_member_of_the_board_paragr___target_revision_id = 'field_member_of_the_board_paragr___target_revision_id',
  field_member_of_the_board_paragr___drupal_internal__target_id = 'field_member_of_the_board_paragr___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__About_UsFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_btn_au_text_1?: Maybe<StringQueryOperatorInput>;
  field_btn_au_url_1?: Maybe<StringQueryOperatorInput>;
  field_info_text?: Maybe<Field_Info_TextFilterInput>;
  field_hero_image?: Maybe<Field_Hero_ImageFilterInput>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_MobileFilterInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_pl_header_1?: Maybe<Field_Pl_Header_1FilterInput>;
  relationships?: Maybe<Site_Setting_Entity__About_UsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__About_UsSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__About_UsField_ComponentsFilterListInput>;
  field_member_of_the_board_paragr?: Maybe<
    Site_Setting_Entity__About_UsField_Member_Of_The_Board_ParagrFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__About_UsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__About_UsFilterInput>;
};

export type Site_Setting_Entity__About_UsGroupConnection = {
  __typename?: 'site_setting_entity__about_usGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__About_UsEdge>;
  nodes: Array<Site_Setting_Entity__About_Us>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__About_UsRelationships = {
  __typename?: 'site_setting_entity__about_usRelationships';
  field_components?: Maybe<Array<Maybe<Paragraph__Bullets_Container>>>;
  field_main_image?: Maybe<File__File>;
  field_hero_image?: Maybe<File__File>;
  field_hero_image_mobile?: Maybe<File__File>;
  field_member_of_the_board_paragr?: Maybe<
    Array<Maybe<Paragraph__Member_Of_The_Board_Personal_Dat>>
  >;
};

export type Site_Setting_Entity__About_UsRelationshipsFilterInput = {
  field_components?: Maybe<Paragraph__Bullets_ContainerFilterListInput>;
  field_main_image?: Maybe<File__FileFilterInput>;
  field_hero_image?: Maybe<File__FileFilterInput>;
  field_hero_image_mobile?: Maybe<File__FileFilterInput>;
  field_member_of_the_board_paragr?: Maybe<
    Paragraph__Member_Of_The_Board_Personal_DatFilterListInput
  >;
};

export type Site_Setting_Entity__About_UsSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__about_usSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__About_UsSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__About_UsSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__About_UsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Accessories = Node & {
  __typename?: 'site_setting_entity__accessories';
  langcode?: Maybe<Scalars['String']>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  field_hero_button_text?: Maybe<Scalars['String']>;
  field_hero_headline?: Maybe<Field_Hero_Headline>;
  field_hero_text?: Maybe<Field_Hero_Text>;
  relationships?: Maybe<Site_Setting_Entity__AccessoriesRelationships>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__AccessoriesSite_Setting_Entity_Type>;
  field_components?: Maybe<Array<Maybe<Site_Setting_Entity__AccessoriesField_Components>>>;
  field_hero_image?: Maybe<Site_Setting_Entity__AccessoriesField_Hero_Image>;
  field_hero_image_mobile?: Maybe<Site_Setting_Entity__AccessoriesField_Hero_Image_Mobile>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__AccessoriesCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AccessoriesChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AccessoriesContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AccessoriesContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AccessoriesConnection = {
  __typename?: 'site_setting_entity__accessoriesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__AccessoriesEdge>;
  nodes: Array<Site_Setting_Entity__Accessories>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__AccessoriesGroupConnection>;
};

export type Site_Setting_Entity__AccessoriesConnectionDistinctArgs = {
  field: Site_Setting_Entity__AccessoriesFieldsEnum;
};

export type Site_Setting_Entity__AccessoriesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__AccessoriesFieldsEnum;
};

export type Site_Setting_Entity__AccessoriesEdge = {
  __typename?: 'site_setting_entity__accessoriesEdge';
  next?: Maybe<Site_Setting_Entity__Accessories>;
  node: Site_Setting_Entity__Accessories;
  previous?: Maybe<Site_Setting_Entity__Accessories>;
};

export type Site_Setting_Entity__AccessoriesField_Components = {
  __typename?: 'site_setting_entity__accessoriesField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__AccessoriesField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__AccessoriesField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__AccessoriesField_ComponentsFilterInput>;
};

export type Site_Setting_Entity__AccessoriesField_Hero_Image = {
  __typename?: 'site_setting_entity__accessoriesField_hero_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__AccessoriesField_Hero_Image_Mobile = {
  __typename?: 'site_setting_entity__accessoriesField_hero_image_mobile';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__AccessoriesField_Hero_Image_MobileFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__AccessoriesField_Hero_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Site_Setting_Entity__AccessoriesFieldsEnum {
  langcode = 'langcode',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  drupal_id = 'drupal_id',
  field_hero_button_text = 'field_hero_button_text',
  field_hero_headline___value = 'field_hero_headline___value',
  field_hero_text___value = 'field_hero_text___value',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  relationships___field_hero_image___localFile___birthtime = 'relationships___field_hero_image___localFile___birthtime',
  relationships___field_hero_image___localFile___birthtimeMs = 'relationships___field_hero_image___localFile___birthtimeMs',
  relationships___field_hero_image___localFile___publicURL = 'relationships___field_hero_image___localFile___publicURL',
  relationships___field_hero_image___localFile___sourceInstanceName = 'relationships___field_hero_image___localFile___sourceInstanceName',
  relationships___field_hero_image___localFile___absolutePath = 'relationships___field_hero_image___localFile___absolutePath',
  relationships___field_hero_image___localFile___relativePath = 'relationships___field_hero_image___localFile___relativePath',
  relationships___field_hero_image___localFile___extension = 'relationships___field_hero_image___localFile___extension',
  relationships___field_hero_image___localFile___size = 'relationships___field_hero_image___localFile___size',
  relationships___field_hero_image___localFile___prettySize = 'relationships___field_hero_image___localFile___prettySize',
  relationships___field_hero_image___localFile___modifiedTime = 'relationships___field_hero_image___localFile___modifiedTime',
  relationships___field_hero_image___localFile___accessTime = 'relationships___field_hero_image___localFile___accessTime',
  relationships___field_hero_image___localFile___changeTime = 'relationships___field_hero_image___localFile___changeTime',
  relationships___field_hero_image___localFile___birthTime = 'relationships___field_hero_image___localFile___birthTime',
  relationships___field_hero_image___localFile___root = 'relationships___field_hero_image___localFile___root',
  relationships___field_hero_image___localFile___dir = 'relationships___field_hero_image___localFile___dir',
  relationships___field_hero_image___localFile___base = 'relationships___field_hero_image___localFile___base',
  relationships___field_hero_image___localFile___ext = 'relationships___field_hero_image___localFile___ext',
  relationships___field_hero_image___localFile___name = 'relationships___field_hero_image___localFile___name',
  relationships___field_hero_image___localFile___relativeDirectory = 'relationships___field_hero_image___localFile___relativeDirectory',
  relationships___field_hero_image___localFile___dev = 'relationships___field_hero_image___localFile___dev',
  relationships___field_hero_image___localFile___mode = 'relationships___field_hero_image___localFile___mode',
  relationships___field_hero_image___localFile___nlink = 'relationships___field_hero_image___localFile___nlink',
  relationships___field_hero_image___localFile___uid = 'relationships___field_hero_image___localFile___uid',
  relationships___field_hero_image___localFile___gid = 'relationships___field_hero_image___localFile___gid',
  relationships___field_hero_image___localFile___rdev = 'relationships___field_hero_image___localFile___rdev',
  relationships___field_hero_image___localFile___blksize = 'relationships___field_hero_image___localFile___blksize',
  relationships___field_hero_image___localFile___ino = 'relationships___field_hero_image___localFile___ino',
  relationships___field_hero_image___localFile___blocks = 'relationships___field_hero_image___localFile___blocks',
  relationships___field_hero_image___localFile___atimeMs = 'relationships___field_hero_image___localFile___atimeMs',
  relationships___field_hero_image___localFile___mtimeMs = 'relationships___field_hero_image___localFile___mtimeMs',
  relationships___field_hero_image___localFile___ctimeMs = 'relationships___field_hero_image___localFile___ctimeMs',
  relationships___field_hero_image___localFile___atime = 'relationships___field_hero_image___localFile___atime',
  relationships___field_hero_image___localFile___mtime = 'relationships___field_hero_image___localFile___mtime',
  relationships___field_hero_image___localFile___ctime = 'relationships___field_hero_image___localFile___ctime',
  relationships___field_hero_image___localFile___url = 'relationships___field_hero_image___localFile___url',
  relationships___field_hero_image___localFile___id = 'relationships___field_hero_image___localFile___id',
  relationships___field_hero_image___localFile___children = 'relationships___field_hero_image___localFile___children',
  relationships___field_hero_image___uri___url = 'relationships___field_hero_image___uri___url',
  relationships___field_hero_image___drupal_id = 'relationships___field_hero_image___drupal_id',
  relationships___field_hero_image___drupal_internal__fid = 'relationships___field_hero_image___drupal_internal__fid',
  relationships___field_hero_image___langcode = 'relationships___field_hero_image___langcode',
  relationships___field_hero_image___filename = 'relationships___field_hero_image___filename',
  relationships___field_hero_image___filemime = 'relationships___field_hero_image___filemime',
  relationships___field_hero_image___filesize = 'relationships___field_hero_image___filesize',
  relationships___field_hero_image___status = 'relationships___field_hero_image___status',
  relationships___field_hero_image___created = 'relationships___field_hero_image___created',
  relationships___field_hero_image___changed = 'relationships___field_hero_image___changed',
  relationships___field_hero_image___uuid = 'relationships___field_hero_image___uuid',
  relationships___field_hero_image___relationships___node__blog_post = 'relationships___field_hero_image___relationships___node__blog_post',
  relationships___field_hero_image___relationships___node__devices = 'relationships___field_hero_image___relationships___node__devices',
  relationships___field_hero_image___relationships___node__accessories = 'relationships___field_hero_image___relationships___node__accessories',
  relationships___field_hero_image___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image___relationships___node__cases = 'relationships___field_hero_image___relationships___node__cases',
  relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image___relationships___paragraph__branches_features = 'relationships___field_hero_image___relationships___paragraph__branches_features',
  relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image___relationships___paragraph__branches_discounts = 'relationships___field_hero_image___relationships___paragraph__branches_discounts',
  relationships___field_hero_image___relationships___node__news = 'relationships___field_hero_image___relationships___node__news',
  relationships___field_hero_image___relationships___node__branches = 'relationships___field_hero_image___relationships___node__branches',
  relationships___field_hero_image___relationships___paragraph__branches_hero = 'relationships___field_hero_image___relationships___paragraph__branches_hero',
  relationships___field_hero_image___relationships___paragraph__bullets = 'relationships___field_hero_image___relationships___paragraph__bullets',
  relationships___field_hero_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image___relationships___paragraph__branches_device = 'relationships___field_hero_image___relationships___paragraph__branches_device',
  relationships___field_hero_image___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image___relationships___site_setting_entity__accessories = 'relationships___field_hero_image___relationships___site_setting_entity__accessories',
  relationships___field_hero_image___relationships___site_setting_entity__branches = 'relationships___field_hero_image___relationships___site_setting_entity__branches',
  relationships___field_hero_image___relationships___site_setting_entity__about_us = 'relationships___field_hero_image___relationships___site_setting_entity__about_us',
  relationships___field_hero_image___relationships___paragraph__logos = 'relationships___field_hero_image___relationships___paragraph__logos',
  relationships___field_hero_image___relationships___paragraph__how_it_works = 'relationships___field_hero_image___relationships___paragraph__how_it_works',
  relationships___field_hero_image___relationships___paragraph__ico_and_text = 'relationships___field_hero_image___relationships___paragraph__ico_and_text',
  relationships___field_hero_image___relationships___paragraph__hero_1 = 'relationships___field_hero_image___relationships___paragraph__hero_1',
  relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image___relationships___site_setting_entity__product = 'relationships___field_hero_image___relationships___site_setting_entity__product',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image___relationships___site_setting_entity__main_page = 'relationships___field_hero_image___relationships___site_setting_entity__main_page',
  relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image___relationships___site_setting_entity__intercars = 'relationships___field_hero_image___relationships___site_setting_entity__intercars',
  relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image___relationships___node__agreement = 'relationships___field_hero_image___relationships___node__agreement',
  relationships___field_hero_image___relationships___node__fiscalization = 'relationships___field_hero_image___relationships___node__fiscalization',
  relationships___field_hero_image___relationships___site_setting_entity__configurator = 'relationships___field_hero_image___relationships___site_setting_entity__configurator',
  relationships___field_hero_image___relationships___paragraph__social_link = 'relationships___field_hero_image___relationships___paragraph__social_link',
  relationships___field_hero_image___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image___relationships___paragraph__linked_icons = 'relationships___field_hero_image___relationships___paragraph__linked_icons',
  relationships___field_hero_image___relationships___site_setting_entity__financing = 'relationships___field_hero_image___relationships___site_setting_entity__financing',
  relationships___field_hero_image___relationships___site_setting_entity__franchise = 'relationships___field_hero_image___relationships___site_setting_entity__franchise',
  relationships___field_hero_image___id = 'relationships___field_hero_image___id',
  relationships___field_hero_image___parent___id = 'relationships___field_hero_image___parent___id',
  relationships___field_hero_image___parent___children = 'relationships___field_hero_image___parent___children',
  relationships___field_hero_image___children = 'relationships___field_hero_image___children',
  relationships___field_hero_image___children___id = 'relationships___field_hero_image___children___id',
  relationships___field_hero_image___children___children = 'relationships___field_hero_image___children___children',
  relationships___field_hero_image___internal___content = 'relationships___field_hero_image___internal___content',
  relationships___field_hero_image___internal___contentDigest = 'relationships___field_hero_image___internal___contentDigest',
  relationships___field_hero_image___internal___description = 'relationships___field_hero_image___internal___description',
  relationships___field_hero_image___internal___fieldOwners = 'relationships___field_hero_image___internal___fieldOwners',
  relationships___field_hero_image___internal___ignoreType = 'relationships___field_hero_image___internal___ignoreType',
  relationships___field_hero_image___internal___mediaType = 'relationships___field_hero_image___internal___mediaType',
  relationships___field_hero_image___internal___owner = 'relationships___field_hero_image___internal___owner',
  relationships___field_hero_image___internal___type = 'relationships___field_hero_image___internal___type',
  relationships___field_hero_image_mobile___localFile___birthtime = 'relationships___field_hero_image_mobile___localFile___birthtime',
  relationships___field_hero_image_mobile___localFile___birthtimeMs = 'relationships___field_hero_image_mobile___localFile___birthtimeMs',
  relationships___field_hero_image_mobile___localFile___publicURL = 'relationships___field_hero_image_mobile___localFile___publicURL',
  relationships___field_hero_image_mobile___localFile___sourceInstanceName = 'relationships___field_hero_image_mobile___localFile___sourceInstanceName',
  relationships___field_hero_image_mobile___localFile___absolutePath = 'relationships___field_hero_image_mobile___localFile___absolutePath',
  relationships___field_hero_image_mobile___localFile___relativePath = 'relationships___field_hero_image_mobile___localFile___relativePath',
  relationships___field_hero_image_mobile___localFile___extension = 'relationships___field_hero_image_mobile___localFile___extension',
  relationships___field_hero_image_mobile___localFile___size = 'relationships___field_hero_image_mobile___localFile___size',
  relationships___field_hero_image_mobile___localFile___prettySize = 'relationships___field_hero_image_mobile___localFile___prettySize',
  relationships___field_hero_image_mobile___localFile___modifiedTime = 'relationships___field_hero_image_mobile___localFile___modifiedTime',
  relationships___field_hero_image_mobile___localFile___accessTime = 'relationships___field_hero_image_mobile___localFile___accessTime',
  relationships___field_hero_image_mobile___localFile___changeTime = 'relationships___field_hero_image_mobile___localFile___changeTime',
  relationships___field_hero_image_mobile___localFile___birthTime = 'relationships___field_hero_image_mobile___localFile___birthTime',
  relationships___field_hero_image_mobile___localFile___root = 'relationships___field_hero_image_mobile___localFile___root',
  relationships___field_hero_image_mobile___localFile___dir = 'relationships___field_hero_image_mobile___localFile___dir',
  relationships___field_hero_image_mobile___localFile___base = 'relationships___field_hero_image_mobile___localFile___base',
  relationships___field_hero_image_mobile___localFile___ext = 'relationships___field_hero_image_mobile___localFile___ext',
  relationships___field_hero_image_mobile___localFile___name = 'relationships___field_hero_image_mobile___localFile___name',
  relationships___field_hero_image_mobile___localFile___relativeDirectory = 'relationships___field_hero_image_mobile___localFile___relativeDirectory',
  relationships___field_hero_image_mobile___localFile___dev = 'relationships___field_hero_image_mobile___localFile___dev',
  relationships___field_hero_image_mobile___localFile___mode = 'relationships___field_hero_image_mobile___localFile___mode',
  relationships___field_hero_image_mobile___localFile___nlink = 'relationships___field_hero_image_mobile___localFile___nlink',
  relationships___field_hero_image_mobile___localFile___uid = 'relationships___field_hero_image_mobile___localFile___uid',
  relationships___field_hero_image_mobile___localFile___gid = 'relationships___field_hero_image_mobile___localFile___gid',
  relationships___field_hero_image_mobile___localFile___rdev = 'relationships___field_hero_image_mobile___localFile___rdev',
  relationships___field_hero_image_mobile___localFile___blksize = 'relationships___field_hero_image_mobile___localFile___blksize',
  relationships___field_hero_image_mobile___localFile___ino = 'relationships___field_hero_image_mobile___localFile___ino',
  relationships___field_hero_image_mobile___localFile___blocks = 'relationships___field_hero_image_mobile___localFile___blocks',
  relationships___field_hero_image_mobile___localFile___atimeMs = 'relationships___field_hero_image_mobile___localFile___atimeMs',
  relationships___field_hero_image_mobile___localFile___mtimeMs = 'relationships___field_hero_image_mobile___localFile___mtimeMs',
  relationships___field_hero_image_mobile___localFile___ctimeMs = 'relationships___field_hero_image_mobile___localFile___ctimeMs',
  relationships___field_hero_image_mobile___localFile___atime = 'relationships___field_hero_image_mobile___localFile___atime',
  relationships___field_hero_image_mobile___localFile___mtime = 'relationships___field_hero_image_mobile___localFile___mtime',
  relationships___field_hero_image_mobile___localFile___ctime = 'relationships___field_hero_image_mobile___localFile___ctime',
  relationships___field_hero_image_mobile___localFile___url = 'relationships___field_hero_image_mobile___localFile___url',
  relationships___field_hero_image_mobile___localFile___id = 'relationships___field_hero_image_mobile___localFile___id',
  relationships___field_hero_image_mobile___localFile___children = 'relationships___field_hero_image_mobile___localFile___children',
  relationships___field_hero_image_mobile___uri___url = 'relationships___field_hero_image_mobile___uri___url',
  relationships___field_hero_image_mobile___drupal_id = 'relationships___field_hero_image_mobile___drupal_id',
  relationships___field_hero_image_mobile___drupal_internal__fid = 'relationships___field_hero_image_mobile___drupal_internal__fid',
  relationships___field_hero_image_mobile___langcode = 'relationships___field_hero_image_mobile___langcode',
  relationships___field_hero_image_mobile___filename = 'relationships___field_hero_image_mobile___filename',
  relationships___field_hero_image_mobile___filemime = 'relationships___field_hero_image_mobile___filemime',
  relationships___field_hero_image_mobile___filesize = 'relationships___field_hero_image_mobile___filesize',
  relationships___field_hero_image_mobile___status = 'relationships___field_hero_image_mobile___status',
  relationships___field_hero_image_mobile___created = 'relationships___field_hero_image_mobile___created',
  relationships___field_hero_image_mobile___changed = 'relationships___field_hero_image_mobile___changed',
  relationships___field_hero_image_mobile___uuid = 'relationships___field_hero_image_mobile___uuid',
  relationships___field_hero_image_mobile___relationships___node__blog_post = 'relationships___field_hero_image_mobile___relationships___node__blog_post',
  relationships___field_hero_image_mobile___relationships___node__devices = 'relationships___field_hero_image_mobile___relationships___node__devices',
  relationships___field_hero_image_mobile___relationships___node__accessories = 'relationships___field_hero_image_mobile___relationships___node__accessories',
  relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image_mobile___relationships___node__cases = 'relationships___field_hero_image_mobile___relationships___node__cases',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_features = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_features',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts',
  relationships___field_hero_image_mobile___relationships___node__news = 'relationships___field_hero_image_mobile___relationships___node__news',
  relationships___field_hero_image_mobile___relationships___node__branches = 'relationships___field_hero_image_mobile___relationships___node__branches',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_hero = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_hero',
  relationships___field_hero_image_mobile___relationships___paragraph__bullets = 'relationships___field_hero_image_mobile___relationships___paragraph__bullets',
  relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_device = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_device',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__branches = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__branches',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us',
  relationships___field_hero_image_mobile___relationships___paragraph__logos = 'relationships___field_hero_image_mobile___relationships___paragraph__logos',
  relationships___field_hero_image_mobile___relationships___paragraph__how_it_works = 'relationships___field_hero_image_mobile___relationships___paragraph__how_it_works',
  relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text = 'relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text',
  relationships___field_hero_image_mobile___relationships___paragraph__hero_1 = 'relationships___field_hero_image_mobile___relationships___paragraph__hero_1',
  relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__product = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__product',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars',
  relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image_mobile___relationships___node__agreement = 'relationships___field_hero_image_mobile___relationships___node__agreement',
  relationships___field_hero_image_mobile___relationships___node__fiscalization = 'relationships___field_hero_image_mobile___relationships___node__fiscalization',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator',
  relationships___field_hero_image_mobile___relationships___paragraph__social_link = 'relationships___field_hero_image_mobile___relationships___paragraph__social_link',
  relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image_mobile___relationships___paragraph__linked_icons = 'relationships___field_hero_image_mobile___relationships___paragraph__linked_icons',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__financing = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__financing',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise',
  relationships___field_hero_image_mobile___id = 'relationships___field_hero_image_mobile___id',
  relationships___field_hero_image_mobile___parent___id = 'relationships___field_hero_image_mobile___parent___id',
  relationships___field_hero_image_mobile___parent___children = 'relationships___field_hero_image_mobile___parent___children',
  relationships___field_hero_image_mobile___children = 'relationships___field_hero_image_mobile___children',
  relationships___field_hero_image_mobile___children___id = 'relationships___field_hero_image_mobile___children___id',
  relationships___field_hero_image_mobile___children___children = 'relationships___field_hero_image_mobile___children___children',
  relationships___field_hero_image_mobile___internal___content = 'relationships___field_hero_image_mobile___internal___content',
  relationships___field_hero_image_mobile___internal___contentDigest = 'relationships___field_hero_image_mobile___internal___contentDigest',
  relationships___field_hero_image_mobile___internal___description = 'relationships___field_hero_image_mobile___internal___description',
  relationships___field_hero_image_mobile___internal___fieldOwners = 'relationships___field_hero_image_mobile___internal___fieldOwners',
  relationships___field_hero_image_mobile___internal___ignoreType = 'relationships___field_hero_image_mobile___internal___ignoreType',
  relationships___field_hero_image_mobile___internal___mediaType = 'relationships___field_hero_image_mobile___internal___mediaType',
  relationships___field_hero_image_mobile___internal___owner = 'relationships___field_hero_image_mobile___internal___owner',
  relationships___field_hero_image_mobile___internal___type = 'relationships___field_hero_image_mobile___internal___type',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  field_hero_image___alt = 'field_hero_image___alt',
  field_hero_image___title = 'field_hero_image___title',
  field_hero_image___width = 'field_hero_image___width',
  field_hero_image___height = 'field_hero_image___height',
  field_hero_image___drupal_internal__target_id = 'field_hero_image___drupal_internal__target_id',
  field_hero_image_mobile___alt = 'field_hero_image_mobile___alt',
  field_hero_image_mobile___title = 'field_hero_image_mobile___title',
  field_hero_image_mobile___width = 'field_hero_image_mobile___width',
  field_hero_image_mobile___height = 'field_hero_image_mobile___height',
  field_hero_image_mobile___drupal_internal__target_id = 'field_hero_image_mobile___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__AccessoriesFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_hero_button_text?: Maybe<StringQueryOperatorInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  relationships?: Maybe<Site_Setting_Entity__AccessoriesRelationshipsFilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__AccessoriesSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__AccessoriesField_ComponentsFilterListInput>;
  field_hero_image?: Maybe<Site_Setting_Entity__AccessoriesField_Hero_ImageFilterInput>;
  field_hero_image_mobile?: Maybe<
    Site_Setting_Entity__AccessoriesField_Hero_Image_MobileFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__AccessoriesFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__AccessoriesFilterInput>;
};

export type Site_Setting_Entity__AccessoriesGroupConnection = {
  __typename?: 'site_setting_entity__accessoriesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__AccessoriesEdge>;
  nodes: Array<Site_Setting_Entity__Accessories>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AccessoriesRelationships = {
  __typename?: 'site_setting_entity__accessoriesRelationships';
  field_main_image?: Maybe<File__File>;
  field_hero_image?: Maybe<File__File>;
  field_components?: Maybe<Array<Maybe<AccessoriesRelatedComponents>>>;
  field_hero_image_mobile?: Maybe<File__File>;
};

export type Site_Setting_Entity__AccessoriesRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
  field_hero_image?: Maybe<File__FileFilterInput>;
  field_hero_image_mobile?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__AccessoriesSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__accessoriesSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AccessoriesSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__AccessoriesSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__AccessoriesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Administration = Node & {
  __typename?: 'site_setting_entity__administration';
  field_translation_boolean?: Maybe<Scalars['Boolean']>;
  field_minprice?: Maybe<Scalars['String']>;
  field_maxprice?: Maybe<Scalars['String']>;
  field_recaptcha_key?: Maybe<Scalars['String']>;
  field_selfdepositpercent?: Maybe<Scalars['String']>;
  field_max_number_of_terminals?: Maybe<Scalars['Int']>;
  field_installmentquantity?: Maybe<Scalars['String']>;
  field_days_to_visit?: Maybe<Scalars['Int']>;
  field_sms_code?: Maybe<Scalars['String']>;
  field_yearpercent?: Maybe<Scalars['String']>;
  field_email_contact_form_1?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  langcode?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  field_default_payment_type?: Maybe<Scalars['String']>;
  field_current_version?: Maybe<Scalars['Int']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  max_tariff_localization?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__AdministrationSite_Setting_Entity_Type>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__AdministrationCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AdministrationChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AdministrationConnection = {
  __typename?: 'site_setting_entity__administrationConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__AdministrationEdge>;
  nodes: Array<Site_Setting_Entity__Administration>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__AdministrationGroupConnection>;
};

export type Site_Setting_Entity__AdministrationConnectionDistinctArgs = {
  field: Site_Setting_Entity__AdministrationFieldsEnum;
};

export type Site_Setting_Entity__AdministrationConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__AdministrationFieldsEnum;
};

export type Site_Setting_Entity__AdministrationEdge = {
  __typename?: 'site_setting_entity__administrationEdge';
  next?: Maybe<Site_Setting_Entity__Administration>;
  node: Site_Setting_Entity__Administration;
  previous?: Maybe<Site_Setting_Entity__Administration>;
};

export enum Site_Setting_Entity__AdministrationFieldsEnum {
  field_translation_boolean = 'field_translation_boolean',
  field_minprice = 'field_minprice',
  field_maxprice = 'field_maxprice',
  field_recaptcha_key = 'field_recaptcha_key',
  field_selfdepositpercent = 'field_selfdepositpercent',
  field_max_number_of_terminals = 'field_max_number_of_terminals',
  field_installmentquantity = 'field_installmentquantity',
  field_days_to_visit = 'field_days_to_visit',
  field_sms_code = 'field_sms_code',
  field_yearpercent = 'field_yearpercent',
  field_email_contact_form_1 = 'field_email_contact_form_1',
  name = 'name',
  langcode = 'langcode',
  drupal_id = 'drupal_id',
  field_default_payment_type = 'field_default_payment_type',
  field_current_version = 'field_current_version',
  drupal_internal__id = 'drupal_internal__id',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  max_tariff_localization = 'max_tariff_localization',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__AdministrationFilterInput = {
  field_translation_boolean?: Maybe<BooleanQueryOperatorInput>;
  field_minprice?: Maybe<StringQueryOperatorInput>;
  field_maxprice?: Maybe<StringQueryOperatorInput>;
  field_recaptcha_key?: Maybe<StringQueryOperatorInput>;
  field_selfdepositpercent?: Maybe<StringQueryOperatorInput>;
  field_max_number_of_terminals?: Maybe<IntQueryOperatorInput>;
  field_installmentquantity?: Maybe<StringQueryOperatorInput>;
  field_days_to_visit?: Maybe<IntQueryOperatorInput>;
  field_sms_code?: Maybe<StringQueryOperatorInput>;
  field_yearpercent?: Maybe<StringQueryOperatorInput>;
  field_email_contact_form_1?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  field_default_payment_type?: Maybe<StringQueryOperatorInput>;
  field_current_version?: Maybe<IntQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  max_tariff_localization?: Maybe<IntQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__AdministrationSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__AdministrationGroupConnection = {
  __typename?: 'site_setting_entity__administrationGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__AdministrationEdge>;
  nodes: Array<Site_Setting_Entity__Administration>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AdministrationSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__administrationSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AdministrationSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__AdministrationSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__AdministrationFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Aktualnosci = Node & {
  __typename?: 'site_setting_entity__aktualnosci';
  langcode?: Maybe<Scalars['String']>;
  field_settings_text_1?: Maybe<Scalars['String']>;
  field_settings_text_2?: Maybe<Scalars['String']>;
  field_settings_text_3?: Maybe<Scalars['String']>;
  field_settings_text_4?: Maybe<Scalars['String']>;
  field_settings_text_5?: Maybe<Scalars['String']>;
  field_settings_text_6?: Maybe<Scalars['String']>;
  field_settings_text_7?: Maybe<Scalars['String']>;
  field_settings_button_text?: Maybe<Scalars['String']>;
  field_settings_button_text_2?: Maybe<Scalars['String']>;
  field_settings_tresc?: Maybe<Field_Settings_Tresc>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  relationships?: Maybe<Site_Setting_Entity__AktualnosciRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__AktualnosciSite_Setting_Entity_Type>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__AktualnosciCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AktualnosciChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AktualnosciContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AktualnosciContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AktualnosciConnection = {
  __typename?: 'site_setting_entity__aktualnosciConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__AktualnosciEdge>;
  nodes: Array<Site_Setting_Entity__Aktualnosci>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__AktualnosciGroupConnection>;
};

export type Site_Setting_Entity__AktualnosciConnectionDistinctArgs = {
  field: Site_Setting_Entity__AktualnosciFieldsEnum;
};

export type Site_Setting_Entity__AktualnosciConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__AktualnosciFieldsEnum;
};

export type Site_Setting_Entity__AktualnosciEdge = {
  __typename?: 'site_setting_entity__aktualnosciEdge';
  next?: Maybe<Site_Setting_Entity__Aktualnosci>;
  node: Site_Setting_Entity__Aktualnosci;
  previous?: Maybe<Site_Setting_Entity__Aktualnosci>;
};

export enum Site_Setting_Entity__AktualnosciFieldsEnum {
  langcode = 'langcode',
  field_settings_text_1 = 'field_settings_text_1',
  field_settings_text_2 = 'field_settings_text_2',
  field_settings_text_3 = 'field_settings_text_3',
  field_settings_text_4 = 'field_settings_text_4',
  field_settings_text_5 = 'field_settings_text_5',
  field_settings_text_6 = 'field_settings_text_6',
  field_settings_text_7 = 'field_settings_text_7',
  field_settings_button_text = 'field_settings_button_text',
  field_settings_button_text_2 = 'field_settings_button_text_2',
  field_settings_tresc___value = 'field_settings_tresc___value',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__AktualnosciFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_settings_text_7?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__AktualnosciRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__AktualnosciSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__AktualnosciGroupConnection = {
  __typename?: 'site_setting_entity__aktualnosciGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__AktualnosciEdge>;
  nodes: Array<Site_Setting_Entity__Aktualnosci>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AktualnosciRelationships = {
  __typename?: 'site_setting_entity__aktualnosciRelationships';
  field_main_image?: Maybe<File__File>;
};

export type Site_Setting_Entity__AktualnosciRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__AktualnosciSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__aktualnosciSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__AktualnosciSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__AktualnosciSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__AktualnosciFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Brak_Strony_404_ = Node & {
  __typename?: 'site_setting_entity__brak_strony_404_';
  langcode?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_settings_button_text?: Maybe<Scalars['String']>;
  field_settings_tresc?: Maybe<Field_Settings_Tresc>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__Brak_Strony_404_Site_Setting_Entity_Type>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__Brak_Strony_404_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Brak_Strony_404_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Brak_Strony_404_Content_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Brak_Strony_404_Content_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Brak_Strony_404_Connection = {
  __typename?: 'site_setting_entity__brak_strony_404_Connection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Brak_Strony_404_Edge>;
  nodes: Array<Site_Setting_Entity__Brak_Strony_404_>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__Brak_Strony_404_GroupConnection>;
};

export type Site_Setting_Entity__Brak_Strony_404_ConnectionDistinctArgs = {
  field: Site_Setting_Entity__Brak_Strony_404_FieldsEnum;
};

export type Site_Setting_Entity__Brak_Strony_404_ConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__Brak_Strony_404_FieldsEnum;
};

export type Site_Setting_Entity__Brak_Strony_404_Edge = {
  __typename?: 'site_setting_entity__brak_strony_404_Edge';
  next?: Maybe<Site_Setting_Entity__Brak_Strony_404_>;
  node: Site_Setting_Entity__Brak_Strony_404_;
  previous?: Maybe<Site_Setting_Entity__Brak_Strony_404_>;
};

export enum Site_Setting_Entity__Brak_Strony_404_FieldsEnum {
  langcode = 'langcode',
  field_meta_title = 'field_meta_title',
  field_settings_button_text = 'field_settings_button_text',
  field_settings_tresc___value = 'field_settings_tresc___value',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__Brak_Strony_404_FilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Brak_Strony_404_Site_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__Brak_Strony_404_GroupConnection = {
  __typename?: 'site_setting_entity__brak_strony_404_GroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Brak_Strony_404_Edge>;
  nodes: Array<Site_Setting_Entity__Brak_Strony_404_>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Brak_Strony_404_Site_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__brak_strony_404_Site_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Brak_Strony_404_Site_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Brak_Strony_404_SortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__Brak_Strony_404_FieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Branches = Node & {
  __typename?: 'site_setting_entity__branches';
  langcode?: Maybe<Scalars['String']>;
  field_hero_image?: Maybe<Field_Hero_Image>;
  field_hero_headline?: Maybe<Field_Hero_Headline>;
  field_hero_button_text?: Maybe<Scalars['String']>;
  field_button_text_1?: Maybe<Scalars['String']>;
  field_button_text_2?: Maybe<Scalars['String']>;
  field_hero_text?: Maybe<Field_Hero_Text>;
  field_text_1?: Maybe<Field_Text_1>;
  field_text_2?: Maybe<Field_Text_2>;
  field_text_3?: Maybe<Field_Text_3>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  relationships?: Maybe<Site_Setting_Entity__BranchesRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__BranchesSite_Setting_Entity_Type>;
  field_hero_image_mobile?: Maybe<Site_Setting_Entity__BranchesField_Hero_Image_Mobile>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__BranchesCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__BranchesChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__BranchesContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__BranchesContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__BranchesConnection = {
  __typename?: 'site_setting_entity__branchesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__BranchesEdge>;
  nodes: Array<Site_Setting_Entity__Branches>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__BranchesGroupConnection>;
};

export type Site_Setting_Entity__BranchesConnectionDistinctArgs = {
  field: Site_Setting_Entity__BranchesFieldsEnum;
};

export type Site_Setting_Entity__BranchesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__BranchesFieldsEnum;
};

export type Site_Setting_Entity__BranchesEdge = {
  __typename?: 'site_setting_entity__branchesEdge';
  next?: Maybe<Site_Setting_Entity__Branches>;
  node: Site_Setting_Entity__Branches;
  previous?: Maybe<Site_Setting_Entity__Branches>;
};

export type Site_Setting_Entity__BranchesField_Hero_Image_Mobile = {
  __typename?: 'site_setting_entity__branchesField_hero_image_mobile';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__BranchesField_Hero_Image_MobileFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Site_Setting_Entity__BranchesFieldsEnum {
  langcode = 'langcode',
  field_hero_image___alt = 'field_hero_image___alt',
  field_hero_headline___value = 'field_hero_headline___value',
  field_hero_button_text = 'field_hero_button_text',
  field_button_text_1 = 'field_button_text_1',
  field_button_text_2 = 'field_button_text_2',
  field_hero_text___value = 'field_hero_text___value',
  field_text_1___value = 'field_text_1___value',
  field_text_2___value = 'field_text_2___value',
  field_text_3___value = 'field_text_3___value',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  relationships___field_hero_image___localFile___birthtime = 'relationships___field_hero_image___localFile___birthtime',
  relationships___field_hero_image___localFile___birthtimeMs = 'relationships___field_hero_image___localFile___birthtimeMs',
  relationships___field_hero_image___localFile___publicURL = 'relationships___field_hero_image___localFile___publicURL',
  relationships___field_hero_image___localFile___sourceInstanceName = 'relationships___field_hero_image___localFile___sourceInstanceName',
  relationships___field_hero_image___localFile___absolutePath = 'relationships___field_hero_image___localFile___absolutePath',
  relationships___field_hero_image___localFile___relativePath = 'relationships___field_hero_image___localFile___relativePath',
  relationships___field_hero_image___localFile___extension = 'relationships___field_hero_image___localFile___extension',
  relationships___field_hero_image___localFile___size = 'relationships___field_hero_image___localFile___size',
  relationships___field_hero_image___localFile___prettySize = 'relationships___field_hero_image___localFile___prettySize',
  relationships___field_hero_image___localFile___modifiedTime = 'relationships___field_hero_image___localFile___modifiedTime',
  relationships___field_hero_image___localFile___accessTime = 'relationships___field_hero_image___localFile___accessTime',
  relationships___field_hero_image___localFile___changeTime = 'relationships___field_hero_image___localFile___changeTime',
  relationships___field_hero_image___localFile___birthTime = 'relationships___field_hero_image___localFile___birthTime',
  relationships___field_hero_image___localFile___root = 'relationships___field_hero_image___localFile___root',
  relationships___field_hero_image___localFile___dir = 'relationships___field_hero_image___localFile___dir',
  relationships___field_hero_image___localFile___base = 'relationships___field_hero_image___localFile___base',
  relationships___field_hero_image___localFile___ext = 'relationships___field_hero_image___localFile___ext',
  relationships___field_hero_image___localFile___name = 'relationships___field_hero_image___localFile___name',
  relationships___field_hero_image___localFile___relativeDirectory = 'relationships___field_hero_image___localFile___relativeDirectory',
  relationships___field_hero_image___localFile___dev = 'relationships___field_hero_image___localFile___dev',
  relationships___field_hero_image___localFile___mode = 'relationships___field_hero_image___localFile___mode',
  relationships___field_hero_image___localFile___nlink = 'relationships___field_hero_image___localFile___nlink',
  relationships___field_hero_image___localFile___uid = 'relationships___field_hero_image___localFile___uid',
  relationships___field_hero_image___localFile___gid = 'relationships___field_hero_image___localFile___gid',
  relationships___field_hero_image___localFile___rdev = 'relationships___field_hero_image___localFile___rdev',
  relationships___field_hero_image___localFile___blksize = 'relationships___field_hero_image___localFile___blksize',
  relationships___field_hero_image___localFile___ino = 'relationships___field_hero_image___localFile___ino',
  relationships___field_hero_image___localFile___blocks = 'relationships___field_hero_image___localFile___blocks',
  relationships___field_hero_image___localFile___atimeMs = 'relationships___field_hero_image___localFile___atimeMs',
  relationships___field_hero_image___localFile___mtimeMs = 'relationships___field_hero_image___localFile___mtimeMs',
  relationships___field_hero_image___localFile___ctimeMs = 'relationships___field_hero_image___localFile___ctimeMs',
  relationships___field_hero_image___localFile___atime = 'relationships___field_hero_image___localFile___atime',
  relationships___field_hero_image___localFile___mtime = 'relationships___field_hero_image___localFile___mtime',
  relationships___field_hero_image___localFile___ctime = 'relationships___field_hero_image___localFile___ctime',
  relationships___field_hero_image___localFile___url = 'relationships___field_hero_image___localFile___url',
  relationships___field_hero_image___localFile___id = 'relationships___field_hero_image___localFile___id',
  relationships___field_hero_image___localFile___children = 'relationships___field_hero_image___localFile___children',
  relationships___field_hero_image___uri___url = 'relationships___field_hero_image___uri___url',
  relationships___field_hero_image___drupal_id = 'relationships___field_hero_image___drupal_id',
  relationships___field_hero_image___drupal_internal__fid = 'relationships___field_hero_image___drupal_internal__fid',
  relationships___field_hero_image___langcode = 'relationships___field_hero_image___langcode',
  relationships___field_hero_image___filename = 'relationships___field_hero_image___filename',
  relationships___field_hero_image___filemime = 'relationships___field_hero_image___filemime',
  relationships___field_hero_image___filesize = 'relationships___field_hero_image___filesize',
  relationships___field_hero_image___status = 'relationships___field_hero_image___status',
  relationships___field_hero_image___created = 'relationships___field_hero_image___created',
  relationships___field_hero_image___changed = 'relationships___field_hero_image___changed',
  relationships___field_hero_image___uuid = 'relationships___field_hero_image___uuid',
  relationships___field_hero_image___relationships___node__blog_post = 'relationships___field_hero_image___relationships___node__blog_post',
  relationships___field_hero_image___relationships___node__devices = 'relationships___field_hero_image___relationships___node__devices',
  relationships___field_hero_image___relationships___node__accessories = 'relationships___field_hero_image___relationships___node__accessories',
  relationships___field_hero_image___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image___relationships___node__cases = 'relationships___field_hero_image___relationships___node__cases',
  relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image___relationships___paragraph__branches_features = 'relationships___field_hero_image___relationships___paragraph__branches_features',
  relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image___relationships___paragraph__branches_discounts = 'relationships___field_hero_image___relationships___paragraph__branches_discounts',
  relationships___field_hero_image___relationships___node__news = 'relationships___field_hero_image___relationships___node__news',
  relationships___field_hero_image___relationships___node__branches = 'relationships___field_hero_image___relationships___node__branches',
  relationships___field_hero_image___relationships___paragraph__branches_hero = 'relationships___field_hero_image___relationships___paragraph__branches_hero',
  relationships___field_hero_image___relationships___paragraph__bullets = 'relationships___field_hero_image___relationships___paragraph__bullets',
  relationships___field_hero_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image___relationships___paragraph__branches_device = 'relationships___field_hero_image___relationships___paragraph__branches_device',
  relationships___field_hero_image___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image___relationships___site_setting_entity__accessories = 'relationships___field_hero_image___relationships___site_setting_entity__accessories',
  relationships___field_hero_image___relationships___site_setting_entity__branches = 'relationships___field_hero_image___relationships___site_setting_entity__branches',
  relationships___field_hero_image___relationships___site_setting_entity__about_us = 'relationships___field_hero_image___relationships___site_setting_entity__about_us',
  relationships___field_hero_image___relationships___paragraph__logos = 'relationships___field_hero_image___relationships___paragraph__logos',
  relationships___field_hero_image___relationships___paragraph__how_it_works = 'relationships___field_hero_image___relationships___paragraph__how_it_works',
  relationships___field_hero_image___relationships___paragraph__ico_and_text = 'relationships___field_hero_image___relationships___paragraph__ico_and_text',
  relationships___field_hero_image___relationships___paragraph__hero_1 = 'relationships___field_hero_image___relationships___paragraph__hero_1',
  relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image___relationships___site_setting_entity__product = 'relationships___field_hero_image___relationships___site_setting_entity__product',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image___relationships___site_setting_entity__main_page = 'relationships___field_hero_image___relationships___site_setting_entity__main_page',
  relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image___relationships___site_setting_entity__intercars = 'relationships___field_hero_image___relationships___site_setting_entity__intercars',
  relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image___relationships___node__agreement = 'relationships___field_hero_image___relationships___node__agreement',
  relationships___field_hero_image___relationships___node__fiscalization = 'relationships___field_hero_image___relationships___node__fiscalization',
  relationships___field_hero_image___relationships___site_setting_entity__configurator = 'relationships___field_hero_image___relationships___site_setting_entity__configurator',
  relationships___field_hero_image___relationships___paragraph__social_link = 'relationships___field_hero_image___relationships___paragraph__social_link',
  relationships___field_hero_image___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image___relationships___paragraph__linked_icons = 'relationships___field_hero_image___relationships___paragraph__linked_icons',
  relationships___field_hero_image___relationships___site_setting_entity__financing = 'relationships___field_hero_image___relationships___site_setting_entity__financing',
  relationships___field_hero_image___relationships___site_setting_entity__franchise = 'relationships___field_hero_image___relationships___site_setting_entity__franchise',
  relationships___field_hero_image___id = 'relationships___field_hero_image___id',
  relationships___field_hero_image___parent___id = 'relationships___field_hero_image___parent___id',
  relationships___field_hero_image___parent___children = 'relationships___field_hero_image___parent___children',
  relationships___field_hero_image___children = 'relationships___field_hero_image___children',
  relationships___field_hero_image___children___id = 'relationships___field_hero_image___children___id',
  relationships___field_hero_image___children___children = 'relationships___field_hero_image___children___children',
  relationships___field_hero_image___internal___content = 'relationships___field_hero_image___internal___content',
  relationships___field_hero_image___internal___contentDigest = 'relationships___field_hero_image___internal___contentDigest',
  relationships___field_hero_image___internal___description = 'relationships___field_hero_image___internal___description',
  relationships___field_hero_image___internal___fieldOwners = 'relationships___field_hero_image___internal___fieldOwners',
  relationships___field_hero_image___internal___ignoreType = 'relationships___field_hero_image___internal___ignoreType',
  relationships___field_hero_image___internal___mediaType = 'relationships___field_hero_image___internal___mediaType',
  relationships___field_hero_image___internal___owner = 'relationships___field_hero_image___internal___owner',
  relationships___field_hero_image___internal___type = 'relationships___field_hero_image___internal___type',
  relationships___field_hero_image_mobile___localFile___birthtime = 'relationships___field_hero_image_mobile___localFile___birthtime',
  relationships___field_hero_image_mobile___localFile___birthtimeMs = 'relationships___field_hero_image_mobile___localFile___birthtimeMs',
  relationships___field_hero_image_mobile___localFile___publicURL = 'relationships___field_hero_image_mobile___localFile___publicURL',
  relationships___field_hero_image_mobile___localFile___sourceInstanceName = 'relationships___field_hero_image_mobile___localFile___sourceInstanceName',
  relationships___field_hero_image_mobile___localFile___absolutePath = 'relationships___field_hero_image_mobile___localFile___absolutePath',
  relationships___field_hero_image_mobile___localFile___relativePath = 'relationships___field_hero_image_mobile___localFile___relativePath',
  relationships___field_hero_image_mobile___localFile___extension = 'relationships___field_hero_image_mobile___localFile___extension',
  relationships___field_hero_image_mobile___localFile___size = 'relationships___field_hero_image_mobile___localFile___size',
  relationships___field_hero_image_mobile___localFile___prettySize = 'relationships___field_hero_image_mobile___localFile___prettySize',
  relationships___field_hero_image_mobile___localFile___modifiedTime = 'relationships___field_hero_image_mobile___localFile___modifiedTime',
  relationships___field_hero_image_mobile___localFile___accessTime = 'relationships___field_hero_image_mobile___localFile___accessTime',
  relationships___field_hero_image_mobile___localFile___changeTime = 'relationships___field_hero_image_mobile___localFile___changeTime',
  relationships___field_hero_image_mobile___localFile___birthTime = 'relationships___field_hero_image_mobile___localFile___birthTime',
  relationships___field_hero_image_mobile___localFile___root = 'relationships___field_hero_image_mobile___localFile___root',
  relationships___field_hero_image_mobile___localFile___dir = 'relationships___field_hero_image_mobile___localFile___dir',
  relationships___field_hero_image_mobile___localFile___base = 'relationships___field_hero_image_mobile___localFile___base',
  relationships___field_hero_image_mobile___localFile___ext = 'relationships___field_hero_image_mobile___localFile___ext',
  relationships___field_hero_image_mobile___localFile___name = 'relationships___field_hero_image_mobile___localFile___name',
  relationships___field_hero_image_mobile___localFile___relativeDirectory = 'relationships___field_hero_image_mobile___localFile___relativeDirectory',
  relationships___field_hero_image_mobile___localFile___dev = 'relationships___field_hero_image_mobile___localFile___dev',
  relationships___field_hero_image_mobile___localFile___mode = 'relationships___field_hero_image_mobile___localFile___mode',
  relationships___field_hero_image_mobile___localFile___nlink = 'relationships___field_hero_image_mobile___localFile___nlink',
  relationships___field_hero_image_mobile___localFile___uid = 'relationships___field_hero_image_mobile___localFile___uid',
  relationships___field_hero_image_mobile___localFile___gid = 'relationships___field_hero_image_mobile___localFile___gid',
  relationships___field_hero_image_mobile___localFile___rdev = 'relationships___field_hero_image_mobile___localFile___rdev',
  relationships___field_hero_image_mobile___localFile___blksize = 'relationships___field_hero_image_mobile___localFile___blksize',
  relationships___field_hero_image_mobile___localFile___ino = 'relationships___field_hero_image_mobile___localFile___ino',
  relationships___field_hero_image_mobile___localFile___blocks = 'relationships___field_hero_image_mobile___localFile___blocks',
  relationships___field_hero_image_mobile___localFile___atimeMs = 'relationships___field_hero_image_mobile___localFile___atimeMs',
  relationships___field_hero_image_mobile___localFile___mtimeMs = 'relationships___field_hero_image_mobile___localFile___mtimeMs',
  relationships___field_hero_image_mobile___localFile___ctimeMs = 'relationships___field_hero_image_mobile___localFile___ctimeMs',
  relationships___field_hero_image_mobile___localFile___atime = 'relationships___field_hero_image_mobile___localFile___atime',
  relationships___field_hero_image_mobile___localFile___mtime = 'relationships___field_hero_image_mobile___localFile___mtime',
  relationships___field_hero_image_mobile___localFile___ctime = 'relationships___field_hero_image_mobile___localFile___ctime',
  relationships___field_hero_image_mobile___localFile___url = 'relationships___field_hero_image_mobile___localFile___url',
  relationships___field_hero_image_mobile___localFile___id = 'relationships___field_hero_image_mobile___localFile___id',
  relationships___field_hero_image_mobile___localFile___children = 'relationships___field_hero_image_mobile___localFile___children',
  relationships___field_hero_image_mobile___uri___url = 'relationships___field_hero_image_mobile___uri___url',
  relationships___field_hero_image_mobile___drupal_id = 'relationships___field_hero_image_mobile___drupal_id',
  relationships___field_hero_image_mobile___drupal_internal__fid = 'relationships___field_hero_image_mobile___drupal_internal__fid',
  relationships___field_hero_image_mobile___langcode = 'relationships___field_hero_image_mobile___langcode',
  relationships___field_hero_image_mobile___filename = 'relationships___field_hero_image_mobile___filename',
  relationships___field_hero_image_mobile___filemime = 'relationships___field_hero_image_mobile___filemime',
  relationships___field_hero_image_mobile___filesize = 'relationships___field_hero_image_mobile___filesize',
  relationships___field_hero_image_mobile___status = 'relationships___field_hero_image_mobile___status',
  relationships___field_hero_image_mobile___created = 'relationships___field_hero_image_mobile___created',
  relationships___field_hero_image_mobile___changed = 'relationships___field_hero_image_mobile___changed',
  relationships___field_hero_image_mobile___uuid = 'relationships___field_hero_image_mobile___uuid',
  relationships___field_hero_image_mobile___relationships___node__blog_post = 'relationships___field_hero_image_mobile___relationships___node__blog_post',
  relationships___field_hero_image_mobile___relationships___node__devices = 'relationships___field_hero_image_mobile___relationships___node__devices',
  relationships___field_hero_image_mobile___relationships___node__accessories = 'relationships___field_hero_image_mobile___relationships___node__accessories',
  relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image_mobile___relationships___node__cases = 'relationships___field_hero_image_mobile___relationships___node__cases',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_features = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_features',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts',
  relationships___field_hero_image_mobile___relationships___node__news = 'relationships___field_hero_image_mobile___relationships___node__news',
  relationships___field_hero_image_mobile___relationships___node__branches = 'relationships___field_hero_image_mobile___relationships___node__branches',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_hero = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_hero',
  relationships___field_hero_image_mobile___relationships___paragraph__bullets = 'relationships___field_hero_image_mobile___relationships___paragraph__bullets',
  relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_device = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_device',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__branches = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__branches',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us',
  relationships___field_hero_image_mobile___relationships___paragraph__logos = 'relationships___field_hero_image_mobile___relationships___paragraph__logos',
  relationships___field_hero_image_mobile___relationships___paragraph__how_it_works = 'relationships___field_hero_image_mobile___relationships___paragraph__how_it_works',
  relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text = 'relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text',
  relationships___field_hero_image_mobile___relationships___paragraph__hero_1 = 'relationships___field_hero_image_mobile___relationships___paragraph__hero_1',
  relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__product = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__product',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars',
  relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image_mobile___relationships___node__agreement = 'relationships___field_hero_image_mobile___relationships___node__agreement',
  relationships___field_hero_image_mobile___relationships___node__fiscalization = 'relationships___field_hero_image_mobile___relationships___node__fiscalization',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator',
  relationships___field_hero_image_mobile___relationships___paragraph__social_link = 'relationships___field_hero_image_mobile___relationships___paragraph__social_link',
  relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image_mobile___relationships___paragraph__linked_icons = 'relationships___field_hero_image_mobile___relationships___paragraph__linked_icons',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__financing = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__financing',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise',
  relationships___field_hero_image_mobile___id = 'relationships___field_hero_image_mobile___id',
  relationships___field_hero_image_mobile___parent___id = 'relationships___field_hero_image_mobile___parent___id',
  relationships___field_hero_image_mobile___parent___children = 'relationships___field_hero_image_mobile___parent___children',
  relationships___field_hero_image_mobile___children = 'relationships___field_hero_image_mobile___children',
  relationships___field_hero_image_mobile___children___id = 'relationships___field_hero_image_mobile___children___id',
  relationships___field_hero_image_mobile___children___children = 'relationships___field_hero_image_mobile___children___children',
  relationships___field_hero_image_mobile___internal___content = 'relationships___field_hero_image_mobile___internal___content',
  relationships___field_hero_image_mobile___internal___contentDigest = 'relationships___field_hero_image_mobile___internal___contentDigest',
  relationships___field_hero_image_mobile___internal___description = 'relationships___field_hero_image_mobile___internal___description',
  relationships___field_hero_image_mobile___internal___fieldOwners = 'relationships___field_hero_image_mobile___internal___fieldOwners',
  relationships___field_hero_image_mobile___internal___ignoreType = 'relationships___field_hero_image_mobile___internal___ignoreType',
  relationships___field_hero_image_mobile___internal___mediaType = 'relationships___field_hero_image_mobile___internal___mediaType',
  relationships___field_hero_image_mobile___internal___owner = 'relationships___field_hero_image_mobile___internal___owner',
  relationships___field_hero_image_mobile___internal___type = 'relationships___field_hero_image_mobile___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_hero_image_mobile___alt = 'field_hero_image_mobile___alt',
  field_hero_image_mobile___title = 'field_hero_image_mobile___title',
  field_hero_image_mobile___width = 'field_hero_image_mobile___width',
  field_hero_image_mobile___height = 'field_hero_image_mobile___height',
  field_hero_image_mobile___drupal_internal__target_id = 'field_hero_image_mobile___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__BranchesFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_hero_image?: Maybe<Field_Hero_ImageFilterInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_hero_button_text?: Maybe<StringQueryOperatorInput>;
  field_button_text_1?: Maybe<StringQueryOperatorInput>;
  field_button_text_2?: Maybe<StringQueryOperatorInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_text_3?: Maybe<Field_Text_3FilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__BranchesRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__BranchesSite_Setting_Entity_TypeFilterInput
  >;
  field_hero_image_mobile?: Maybe<Site_Setting_Entity__BranchesField_Hero_Image_MobileFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__BranchesFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__BranchesFilterInput>;
};

export type Site_Setting_Entity__BranchesGroupConnection = {
  __typename?: 'site_setting_entity__branchesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__BranchesEdge>;
  nodes: Array<Site_Setting_Entity__Branches>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__BranchesRelationships = {
  __typename?: 'site_setting_entity__branchesRelationships';
  field_main_image?: Maybe<File__File>;
  field_hero_image?: Maybe<File__File>;
  field_hero_image_mobile?: Maybe<File__File>;
};

export type Site_Setting_Entity__BranchesRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
  field_hero_image?: Maybe<File__FileFilterInput>;
  field_hero_image_mobile?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__BranchesSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__branchesSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__BranchesSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__BranchesSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__BranchesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Configurator = Node & {
  __typename?: 'site_setting_entity__configurator';
  langcode?: Maybe<Scalars['String']>;
  field_calendar_available?: Maybe<Scalars['Boolean']>;
  field_info_text?: Maybe<Field_Info_Text>;
  field_enable_info_text?: Maybe<Scalars['Boolean']>;
  field_proste_pola?: Maybe<Array<Scalars['String']>>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_pola_wysywig?: Maybe<Array<Maybe<Site_Setting_Entity__ConfiguratorField_Pola_Wysywig>>>;
  relationships?: Maybe<Site_Setting_Entity__ConfiguratorRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__ConfiguratorSite_Setting_Entity_Type>;
  field_images?: Maybe<Array<Maybe<Site_Setting_Entity__ConfiguratorField_Images>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__ConfiguratorCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ConfiguratorChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ConfiguratorContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ConfiguratorContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ConfiguratorConnection = {
  __typename?: 'site_setting_entity__configuratorConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__ConfiguratorEdge>;
  nodes: Array<Site_Setting_Entity__Configurator>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__ConfiguratorGroupConnection>;
};

export type Site_Setting_Entity__ConfiguratorConnectionDistinctArgs = {
  field: Site_Setting_Entity__ConfiguratorFieldsEnum;
};

export type Site_Setting_Entity__ConfiguratorConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__ConfiguratorFieldsEnum;
};

export type Site_Setting_Entity__ConfiguratorEdge = {
  __typename?: 'site_setting_entity__configuratorEdge';
  next?: Maybe<Site_Setting_Entity__Configurator>;
  node: Site_Setting_Entity__Configurator;
  previous?: Maybe<Site_Setting_Entity__Configurator>;
};

export type Site_Setting_Entity__ConfiguratorField_Images = {
  __typename?: 'site_setting_entity__configuratorField_images';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__ConfiguratorField_ImagesFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__ConfiguratorField_ImagesFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__ConfiguratorField_ImagesFilterInput>;
};

export type Site_Setting_Entity__ConfiguratorField_Pola_Wysywig = {
  __typename?: 'site_setting_entity__configuratorField_pola_wysywig';
  value?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ConfiguratorField_Pola_WysywigFilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__ConfiguratorField_Pola_WysywigFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__ConfiguratorField_Pola_WysywigFilterInput>;
};

export enum Site_Setting_Entity__ConfiguratorFieldsEnum {
  langcode = 'langcode',
  field_calendar_available = 'field_calendar_available',
  field_info_text___value = 'field_info_text___value',
  field_enable_info_text = 'field_enable_info_text',
  field_proste_pola = 'field_proste_pola',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  field_pola_wysywig = 'field_pola_wysywig',
  field_pola_wysywig___value = 'field_pola_wysywig___value',
  relationships___field_images = 'relationships___field_images',
  relationships___field_images___localFile___birthtime = 'relationships___field_images___localFile___birthtime',
  relationships___field_images___localFile___birthtimeMs = 'relationships___field_images___localFile___birthtimeMs',
  relationships___field_images___localFile___publicURL = 'relationships___field_images___localFile___publicURL',
  relationships___field_images___localFile___sourceInstanceName = 'relationships___field_images___localFile___sourceInstanceName',
  relationships___field_images___localFile___absolutePath = 'relationships___field_images___localFile___absolutePath',
  relationships___field_images___localFile___relativePath = 'relationships___field_images___localFile___relativePath',
  relationships___field_images___localFile___extension = 'relationships___field_images___localFile___extension',
  relationships___field_images___localFile___size = 'relationships___field_images___localFile___size',
  relationships___field_images___localFile___prettySize = 'relationships___field_images___localFile___prettySize',
  relationships___field_images___localFile___modifiedTime = 'relationships___field_images___localFile___modifiedTime',
  relationships___field_images___localFile___accessTime = 'relationships___field_images___localFile___accessTime',
  relationships___field_images___localFile___changeTime = 'relationships___field_images___localFile___changeTime',
  relationships___field_images___localFile___birthTime = 'relationships___field_images___localFile___birthTime',
  relationships___field_images___localFile___root = 'relationships___field_images___localFile___root',
  relationships___field_images___localFile___dir = 'relationships___field_images___localFile___dir',
  relationships___field_images___localFile___base = 'relationships___field_images___localFile___base',
  relationships___field_images___localFile___ext = 'relationships___field_images___localFile___ext',
  relationships___field_images___localFile___name = 'relationships___field_images___localFile___name',
  relationships___field_images___localFile___relativeDirectory = 'relationships___field_images___localFile___relativeDirectory',
  relationships___field_images___localFile___dev = 'relationships___field_images___localFile___dev',
  relationships___field_images___localFile___mode = 'relationships___field_images___localFile___mode',
  relationships___field_images___localFile___nlink = 'relationships___field_images___localFile___nlink',
  relationships___field_images___localFile___uid = 'relationships___field_images___localFile___uid',
  relationships___field_images___localFile___gid = 'relationships___field_images___localFile___gid',
  relationships___field_images___localFile___rdev = 'relationships___field_images___localFile___rdev',
  relationships___field_images___localFile___blksize = 'relationships___field_images___localFile___blksize',
  relationships___field_images___localFile___ino = 'relationships___field_images___localFile___ino',
  relationships___field_images___localFile___blocks = 'relationships___field_images___localFile___blocks',
  relationships___field_images___localFile___atimeMs = 'relationships___field_images___localFile___atimeMs',
  relationships___field_images___localFile___mtimeMs = 'relationships___field_images___localFile___mtimeMs',
  relationships___field_images___localFile___ctimeMs = 'relationships___field_images___localFile___ctimeMs',
  relationships___field_images___localFile___atime = 'relationships___field_images___localFile___atime',
  relationships___field_images___localFile___mtime = 'relationships___field_images___localFile___mtime',
  relationships___field_images___localFile___ctime = 'relationships___field_images___localFile___ctime',
  relationships___field_images___localFile___url = 'relationships___field_images___localFile___url',
  relationships___field_images___localFile___id = 'relationships___field_images___localFile___id',
  relationships___field_images___localFile___children = 'relationships___field_images___localFile___children',
  relationships___field_images___uri___url = 'relationships___field_images___uri___url',
  relationships___field_images___drupal_id = 'relationships___field_images___drupal_id',
  relationships___field_images___drupal_internal__fid = 'relationships___field_images___drupal_internal__fid',
  relationships___field_images___langcode = 'relationships___field_images___langcode',
  relationships___field_images___filename = 'relationships___field_images___filename',
  relationships___field_images___filemime = 'relationships___field_images___filemime',
  relationships___field_images___filesize = 'relationships___field_images___filesize',
  relationships___field_images___status = 'relationships___field_images___status',
  relationships___field_images___created = 'relationships___field_images___created',
  relationships___field_images___changed = 'relationships___field_images___changed',
  relationships___field_images___uuid = 'relationships___field_images___uuid',
  relationships___field_images___relationships___node__blog_post = 'relationships___field_images___relationships___node__blog_post',
  relationships___field_images___relationships___node__devices = 'relationships___field_images___relationships___node__devices',
  relationships___field_images___relationships___node__accessories = 'relationships___field_images___relationships___node__accessories',
  relationships___field_images___relationships___paragraph__news_image_title_text = 'relationships___field_images___relationships___paragraph__news_image_title_text',
  relationships___field_images___relationships___node__cases = 'relationships___field_images___relationships___node__cases',
  relationships___field_images___relationships___paragraph__branches_what_is_ipos = 'relationships___field_images___relationships___paragraph__branches_what_is_ipos',
  relationships___field_images___relationships___paragraph__branches_features = 'relationships___field_images___relationships___paragraph__branches_features',
  relationships___field_images___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_images___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_images___relationships___paragraph__branches_testimonials = 'relationships___field_images___relationships___paragraph__branches_testimonials',
  relationships___field_images___relationships___paragraph__branches_discounts = 'relationships___field_images___relationships___paragraph__branches_discounts',
  relationships___field_images___relationships___node__news = 'relationships___field_images___relationships___node__news',
  relationships___field_images___relationships___node__branches = 'relationships___field_images___relationships___node__branches',
  relationships___field_images___relationships___paragraph__branches_hero = 'relationships___field_images___relationships___paragraph__branches_hero',
  relationships___field_images___relationships___paragraph__bullets = 'relationships___field_images___relationships___paragraph__bullets',
  relationships___field_images___relationships___taxonomy_term__pricelist_group = 'relationships___field_images___relationships___taxonomy_term__pricelist_group',
  relationships___field_images___relationships___paragraph__branches_device = 'relationships___field_images___relationships___paragraph__branches_device',
  relationships___field_images___relationships___paragraph__branches_recommendation = 'relationships___field_images___relationships___paragraph__branches_recommendation',
  relationships___field_images___relationships___site_setting_entity__accessories = 'relationships___field_images___relationships___site_setting_entity__accessories',
  relationships___field_images___relationships___site_setting_entity__branches = 'relationships___field_images___relationships___site_setting_entity__branches',
  relationships___field_images___relationships___site_setting_entity__about_us = 'relationships___field_images___relationships___site_setting_entity__about_us',
  relationships___field_images___relationships___paragraph__logos = 'relationships___field_images___relationships___paragraph__logos',
  relationships___field_images___relationships___paragraph__how_it_works = 'relationships___field_images___relationships___paragraph__how_it_works',
  relationships___field_images___relationships___paragraph__ico_and_text = 'relationships___field_images___relationships___paragraph__ico_and_text',
  relationships___field_images___relationships___paragraph__hero_1 = 'relationships___field_images___relationships___paragraph__hero_1',
  relationships___field_images___relationships___paragraph__comprehensive_solution_item = 'relationships___field_images___relationships___paragraph__comprehensive_solution_item',
  relationships___field_images___relationships___site_setting_entity__product = 'relationships___field_images___relationships___site_setting_entity__product',
  relationships___field_images___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_images___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_images___relationships___paragraph__porownanie_terminali = 'relationships___field_images___relationships___paragraph__porownanie_terminali',
  relationships___field_images___relationships___paragraph__zdjecie_opis_link = 'relationships___field_images___relationships___paragraph__zdjecie_opis_link',
  relationships___field_images___relationships___site_setting_entity__main_page = 'relationships___field_images___relationships___site_setting_entity__main_page',
  relationships___field_images___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_images___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_images___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_images___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_images___relationships___site_setting_entity__intercars = 'relationships___field_images___relationships___site_setting_entity__intercars',
  relationships___field_images___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_images___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_images___relationships___node__agreement = 'relationships___field_images___relationships___node__agreement',
  relationships___field_images___relationships___node__fiscalization = 'relationships___field_images___relationships___node__fiscalization',
  relationships___field_images___relationships___site_setting_entity__configurator = 'relationships___field_images___relationships___site_setting_entity__configurator',
  relationships___field_images___relationships___paragraph__social_link = 'relationships___field_images___relationships___paragraph__social_link',
  relationships___field_images___relationships___paragraph__make_an_appointment = 'relationships___field_images___relationships___paragraph__make_an_appointment',
  relationships___field_images___relationships___paragraph__linked_icons = 'relationships___field_images___relationships___paragraph__linked_icons',
  relationships___field_images___relationships___site_setting_entity__financing = 'relationships___field_images___relationships___site_setting_entity__financing',
  relationships___field_images___relationships___site_setting_entity__franchise = 'relationships___field_images___relationships___site_setting_entity__franchise',
  relationships___field_images___id = 'relationships___field_images___id',
  relationships___field_images___parent___id = 'relationships___field_images___parent___id',
  relationships___field_images___parent___children = 'relationships___field_images___parent___children',
  relationships___field_images___children = 'relationships___field_images___children',
  relationships___field_images___children___id = 'relationships___field_images___children___id',
  relationships___field_images___children___children = 'relationships___field_images___children___children',
  relationships___field_images___internal___content = 'relationships___field_images___internal___content',
  relationships___field_images___internal___contentDigest = 'relationships___field_images___internal___contentDigest',
  relationships___field_images___internal___description = 'relationships___field_images___internal___description',
  relationships___field_images___internal___fieldOwners = 'relationships___field_images___internal___fieldOwners',
  relationships___field_images___internal___ignoreType = 'relationships___field_images___internal___ignoreType',
  relationships___field_images___internal___mediaType = 'relationships___field_images___internal___mediaType',
  relationships___field_images___internal___owner = 'relationships___field_images___internal___owner',
  relationships___field_images___internal___type = 'relationships___field_images___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_images = 'field_images',
  field_images___alt = 'field_images___alt',
  field_images___title = 'field_images___title',
  field_images___width = 'field_images___width',
  field_images___height = 'field_images___height',
  field_images___drupal_internal__target_id = 'field_images___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__ConfiguratorFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_calendar_available?: Maybe<BooleanQueryOperatorInput>;
  field_info_text?: Maybe<Field_Info_TextFilterInput>;
  field_enable_info_text?: Maybe<BooleanQueryOperatorInput>;
  field_proste_pola?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_pola_wysywig?: Maybe<Site_Setting_Entity__ConfiguratorField_Pola_WysywigFilterListInput>;
  relationships?: Maybe<Site_Setting_Entity__ConfiguratorRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__ConfiguratorSite_Setting_Entity_TypeFilterInput
  >;
  field_images?: Maybe<Site_Setting_Entity__ConfiguratorField_ImagesFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__ConfiguratorFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__ConfiguratorFilterInput>;
};

export type Site_Setting_Entity__ConfiguratorGroupConnection = {
  __typename?: 'site_setting_entity__configuratorGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__ConfiguratorEdge>;
  nodes: Array<Site_Setting_Entity__Configurator>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ConfiguratorRelationships = {
  __typename?: 'site_setting_entity__configuratorRelationships';
  field_images?: Maybe<Array<Maybe<File__File>>>;
};

export type Site_Setting_Entity__ConfiguratorRelationshipsFilterInput = {
  field_images?: Maybe<File__FileFilterListInput>;
};

export type Site_Setting_Entity__ConfiguratorSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__configuratorSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ConfiguratorSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__ConfiguratorSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__ConfiguratorFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Demo = Node & {
  __typename?: 'site_setting_entity__demo';
  langcode?: Maybe<Scalars['String']>;
  field_button_text_1?: Maybe<Scalars['String']>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_settings_text_1?: Maybe<Scalars['String']>;
  field_settings_text_2?: Maybe<Scalars['String']>;
  field_settings_text_3?: Maybe<Scalars['String']>;
  field_settings_text_4?: Maybe<Scalars['String']>;
  field_settings_text_5?: Maybe<Scalars['String']>;
  field_settings_text_6?: Maybe<Scalars['String']>;
  field_text_1?: Maybe<Field_Text_1>;
  field_text_2?: Maybe<Field_Text_2>;
  field_text_3?: Maybe<Field_Text_3>;
  field_tooltip?: Maybe<Scalars['String']>;
  field_agreement_1?: Maybe<Field_Agreement_1>;
  field_agreement_2?: Maybe<Scalars['String']>;
  field_agreement_3?: Maybe<Field_Agreement_3>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__DemoSite_Setting_Entity_Type>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__DemoCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__DemoChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__DemoContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__DemoContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__DemoConnection = {
  __typename?: 'site_setting_entity__demoConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__DemoEdge>;
  nodes: Array<Site_Setting_Entity__Demo>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__DemoGroupConnection>;
};

export type Site_Setting_Entity__DemoConnectionDistinctArgs = {
  field: Site_Setting_Entity__DemoFieldsEnum;
};

export type Site_Setting_Entity__DemoConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__DemoFieldsEnum;
};

export type Site_Setting_Entity__DemoEdge = {
  __typename?: 'site_setting_entity__demoEdge';
  next?: Maybe<Site_Setting_Entity__Demo>;
  node: Site_Setting_Entity__Demo;
  previous?: Maybe<Site_Setting_Entity__Demo>;
};

export enum Site_Setting_Entity__DemoFieldsEnum {
  langcode = 'langcode',
  field_button_text_1 = 'field_button_text_1',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  field_settings_text_1 = 'field_settings_text_1',
  field_settings_text_2 = 'field_settings_text_2',
  field_settings_text_3 = 'field_settings_text_3',
  field_settings_text_4 = 'field_settings_text_4',
  field_settings_text_5 = 'field_settings_text_5',
  field_settings_text_6 = 'field_settings_text_6',
  field_text_1___value = 'field_text_1___value',
  field_text_2___value = 'field_text_2___value',
  field_text_3___value = 'field_text_3___value',
  field_tooltip = 'field_tooltip',
  field_agreement_1___value = 'field_agreement_1___value',
  field_agreement_2 = 'field_agreement_2',
  field_agreement_3___value = 'field_agreement_3___value',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__DemoFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_button_text_1?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_text_3?: Maybe<Field_Text_3FilterInput>;
  field_tooltip?: Maybe<StringQueryOperatorInput>;
  field_agreement_1?: Maybe<Field_Agreement_1FilterInput>;
  field_agreement_2?: Maybe<StringQueryOperatorInput>;
  field_agreement_3?: Maybe<Field_Agreement_3FilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__DemoSite_Setting_Entity_TypeFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__DemoGroupConnection = {
  __typename?: 'site_setting_entity__demoGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__DemoEdge>;
  nodes: Array<Site_Setting_Entity__Demo>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__DemoSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__demoSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__DemoSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__DemoSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__DemoFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Financing = Node & {
  __typename?: 'site_setting_entity__financing';
  langcode?: Maybe<Scalars['String']>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  relationships?: Maybe<Site_Setting_Entity__FinancingRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__FinancingSite_Setting_Entity_Type>;
  field_components?: Maybe<Array<Maybe<Site_Setting_Entity__FinancingField_Components>>>;
  field_main_image?: Maybe<Site_Setting_Entity__FinancingField_Main_Image>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__FinancingCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FinancingChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FinancingContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FinancingContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FinancingConnection = {
  __typename?: 'site_setting_entity__financingConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__FinancingEdge>;
  nodes: Array<Site_Setting_Entity__Financing>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__FinancingGroupConnection>;
};

export type Site_Setting_Entity__FinancingConnectionDistinctArgs = {
  field: Site_Setting_Entity__FinancingFieldsEnum;
};

export type Site_Setting_Entity__FinancingConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__FinancingFieldsEnum;
};

export type Site_Setting_Entity__FinancingEdge = {
  __typename?: 'site_setting_entity__financingEdge';
  next?: Maybe<Site_Setting_Entity__Financing>;
  node: Site_Setting_Entity__Financing;
  previous?: Maybe<Site_Setting_Entity__Financing>;
};

export type Site_Setting_Entity__FinancingField_Components = {
  __typename?: 'site_setting_entity__financingField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__FinancingField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__FinancingField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__FinancingField_ComponentsFilterInput>;
};

export type Site_Setting_Entity__FinancingField_Main_Image = {
  __typename?: 'site_setting_entity__financingField_main_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__FinancingField_Main_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Site_Setting_Entity__FinancingFieldsEnum {
  langcode = 'langcode',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  field_main_image___alt = 'field_main_image___alt',
  field_main_image___title = 'field_main_image___title',
  field_main_image___width = 'field_main_image___width',
  field_main_image___height = 'field_main_image___height',
  field_main_image___drupal_internal__target_id = 'field_main_image___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__FinancingFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__FinancingRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__FinancingSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__FinancingField_ComponentsFilterListInput>;
  field_main_image?: Maybe<Site_Setting_Entity__FinancingField_Main_ImageFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__FinancingFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__FinancingFilterInput>;
};

export type Site_Setting_Entity__FinancingGroupConnection = {
  __typename?: 'site_setting_entity__financingGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__FinancingEdge>;
  nodes: Array<Site_Setting_Entity__Financing>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FinancingRelationships = {
  __typename?: 'site_setting_entity__financingRelationships';
  field_main_image?: Maybe<File__File>;
  field_components?: Maybe<Array<Maybe<FundingsRelatedComponents>>>;
};

export type Site_Setting_Entity__FinancingRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__FinancingSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__financingSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FinancingSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__FinancingSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__FinancingFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Footer = Node & {
  __typename?: 'site_setting_entity__footer';
  langcode?: Maybe<Scalars['String']>;
  relationships?: Maybe<Site_Setting_Entity__FooterRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__FooterSite_Setting_Entity_Type>;
  field_footer_sections?: Maybe<Array<Maybe<Site_Setting_Entity__FooterField_Footer_Sections>>>;
  field_footer_social_links?: Maybe<
    Array<Maybe<Site_Setting_Entity__FooterField_Footer_Social_Links>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__FooterCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FooterChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FooterContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FooterContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FooterConnection = {
  __typename?: 'site_setting_entity__footerConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__FooterEdge>;
  nodes: Array<Site_Setting_Entity__Footer>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__FooterGroupConnection>;
};

export type Site_Setting_Entity__FooterConnectionDistinctArgs = {
  field: Site_Setting_Entity__FooterFieldsEnum;
};

export type Site_Setting_Entity__FooterConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__FooterFieldsEnum;
};

export type Site_Setting_Entity__FooterEdge = {
  __typename?: 'site_setting_entity__footerEdge';
  next?: Maybe<Site_Setting_Entity__Footer>;
  node: Site_Setting_Entity__Footer;
  previous?: Maybe<Site_Setting_Entity__Footer>;
};

export type Site_Setting_Entity__FooterField_Footer_Sections = {
  __typename?: 'site_setting_entity__footerField_footer_sections';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__FooterField_Footer_SectionsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__FooterField_Footer_SectionsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__FooterField_Footer_SectionsFilterInput>;
};

export type Site_Setting_Entity__FooterField_Footer_Social_Links = {
  __typename?: 'site_setting_entity__footerField_footer_social_links';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__FooterField_Footer_Social_LinksFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__FooterField_Footer_Social_LinksFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__FooterField_Footer_Social_LinksFilterInput>;
};

export enum Site_Setting_Entity__FooterFieldsEnum {
  langcode = 'langcode',
  relationships___field_footer_sections = 'relationships___field_footer_sections',
  relationships___field_footer_sections___id = 'relationships___field_footer_sections___id',
  relationships___field_footer_sections___internal___content = 'relationships___field_footer_sections___internal___content',
  relationships___field_footer_sections___internal___contentDigest = 'relationships___field_footer_sections___internal___contentDigest',
  relationships___field_footer_sections___internal___description = 'relationships___field_footer_sections___internal___description',
  relationships___field_footer_sections___internal___fieldOwners = 'relationships___field_footer_sections___internal___fieldOwners',
  relationships___field_footer_sections___internal___ignoreType = 'relationships___field_footer_sections___internal___ignoreType',
  relationships___field_footer_sections___internal___mediaType = 'relationships___field_footer_sections___internal___mediaType',
  relationships___field_footer_sections___internal___owner = 'relationships___field_footer_sections___internal___owner',
  relationships___field_footer_sections___internal___type = 'relationships___field_footer_sections___internal___type',
  relationships___field_footer_sections___drupal_id = 'relationships___field_footer_sections___drupal_id',
  relationships___field_footer_sections___field_section_header = 'relationships___field_footer_sections___field_section_header',
  relationships___field_footer_sections___relationships___site_setting_entity__footer = 'relationships___field_footer_sections___relationships___site_setting_entity__footer',
  relationships___field_footer_sections___drupal_internal__id = 'relationships___field_footer_sections___drupal_internal__id',
  relationships___field_footer_sections___drupal_internal__revision_id = 'relationships___field_footer_sections___drupal_internal__revision_id',
  relationships___field_footer_sections___langcode = 'relationships___field_footer_sections___langcode',
  relationships___field_footer_sections___status = 'relationships___field_footer_sections___status',
  relationships___field_footer_sections___created = 'relationships___field_footer_sections___created',
  relationships___field_footer_sections___parent_id = 'relationships___field_footer_sections___parent_id',
  relationships___field_footer_sections___parent_type = 'relationships___field_footer_sections___parent_type',
  relationships___field_footer_sections___parent_field_name = 'relationships___field_footer_sections___parent_field_name',
  relationships___field_footer_sections___default_langcode = 'relationships___field_footer_sections___default_langcode',
  relationships___field_footer_sections___revision_translation_affected = 'relationships___field_footer_sections___revision_translation_affected',
  relationships___field_footer_sections___content_translation_source = 'relationships___field_footer_sections___content_translation_source',
  relationships___field_footer_sections___content_translation_outdated = 'relationships___field_footer_sections___content_translation_outdated',
  relationships___field_footer_sections___content_translation_changed = 'relationships___field_footer_sections___content_translation_changed',
  relationships___field_footer_sections___uuid = 'relationships___field_footer_sections___uuid',
  relationships___field_footer_sections___field_section_components = 'relationships___field_footer_sections___field_section_components',
  relationships___field_footer_sections___field_section_components___target_revision_id = 'relationships___field_footer_sections___field_section_components___target_revision_id',
  relationships___field_footer_sections___field_section_components___drupal_internal__target_id = 'relationships___field_footer_sections___field_section_components___drupal_internal__target_id',
  relationships___field_footer_sections___parent___id = 'relationships___field_footer_sections___parent___id',
  relationships___field_footer_sections___parent___children = 'relationships___field_footer_sections___parent___children',
  relationships___field_footer_sections___children = 'relationships___field_footer_sections___children',
  relationships___field_footer_sections___children___id = 'relationships___field_footer_sections___children___id',
  relationships___field_footer_sections___children___children = 'relationships___field_footer_sections___children___children',
  relationships___field_footer_social_links = 'relationships___field_footer_social_links',
  relationships___field_footer_social_links___field_social_link___uri = 'relationships___field_footer_social_links___field_social_link___uri',
  relationships___field_footer_social_links___field_social_link___title = 'relationships___field_footer_social_links___field_social_link___title',
  relationships___field_footer_social_links___relationships___site_setting_entity__footer = 'relationships___field_footer_social_links___relationships___site_setting_entity__footer',
  relationships___field_footer_social_links___drupal_id = 'relationships___field_footer_social_links___drupal_id',
  relationships___field_footer_social_links___drupal_internal__id = 'relationships___field_footer_social_links___drupal_internal__id',
  relationships___field_footer_social_links___drupal_internal__revision_id = 'relationships___field_footer_social_links___drupal_internal__revision_id',
  relationships___field_footer_social_links___langcode = 'relationships___field_footer_social_links___langcode',
  relationships___field_footer_social_links___status = 'relationships___field_footer_social_links___status',
  relationships___field_footer_social_links___created = 'relationships___field_footer_social_links___created',
  relationships___field_footer_social_links___parent_id = 'relationships___field_footer_social_links___parent_id',
  relationships___field_footer_social_links___parent_type = 'relationships___field_footer_social_links___parent_type',
  relationships___field_footer_social_links___parent_field_name = 'relationships___field_footer_social_links___parent_field_name',
  relationships___field_footer_social_links___default_langcode = 'relationships___field_footer_social_links___default_langcode',
  relationships___field_footer_social_links___revision_translation_affected = 'relationships___field_footer_social_links___revision_translation_affected',
  relationships___field_footer_social_links___content_translation_source = 'relationships___field_footer_social_links___content_translation_source',
  relationships___field_footer_social_links___content_translation_outdated = 'relationships___field_footer_social_links___content_translation_outdated',
  relationships___field_footer_social_links___content_translation_changed = 'relationships___field_footer_social_links___content_translation_changed',
  relationships___field_footer_social_links___uuid = 'relationships___field_footer_social_links___uuid',
  relationships___field_footer_social_links___field_social_icon___alt = 'relationships___field_footer_social_links___field_social_icon___alt',
  relationships___field_footer_social_links___field_social_icon___title = 'relationships___field_footer_social_links___field_social_icon___title',
  relationships___field_footer_social_links___field_social_icon___width = 'relationships___field_footer_social_links___field_social_icon___width',
  relationships___field_footer_social_links___field_social_icon___height = 'relationships___field_footer_social_links___field_social_icon___height',
  relationships___field_footer_social_links___field_social_icon___drupal_internal__target_id = 'relationships___field_footer_social_links___field_social_icon___drupal_internal__target_id',
  relationships___field_footer_social_links___id = 'relationships___field_footer_social_links___id',
  relationships___field_footer_social_links___parent___id = 'relationships___field_footer_social_links___parent___id',
  relationships___field_footer_social_links___parent___children = 'relationships___field_footer_social_links___parent___children',
  relationships___field_footer_social_links___children = 'relationships___field_footer_social_links___children',
  relationships___field_footer_social_links___children___id = 'relationships___field_footer_social_links___children___id',
  relationships___field_footer_social_links___children___children = 'relationships___field_footer_social_links___children___children',
  relationships___field_footer_social_links___internal___content = 'relationships___field_footer_social_links___internal___content',
  relationships___field_footer_social_links___internal___contentDigest = 'relationships___field_footer_social_links___internal___contentDigest',
  relationships___field_footer_social_links___internal___description = 'relationships___field_footer_social_links___internal___description',
  relationships___field_footer_social_links___internal___fieldOwners = 'relationships___field_footer_social_links___internal___fieldOwners',
  relationships___field_footer_social_links___internal___ignoreType = 'relationships___field_footer_social_links___internal___ignoreType',
  relationships___field_footer_social_links___internal___mediaType = 'relationships___field_footer_social_links___internal___mediaType',
  relationships___field_footer_social_links___internal___owner = 'relationships___field_footer_social_links___internal___owner',
  relationships___field_footer_social_links___internal___type = 'relationships___field_footer_social_links___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_footer_sections = 'field_footer_sections',
  field_footer_sections___target_revision_id = 'field_footer_sections___target_revision_id',
  field_footer_sections___drupal_internal__target_id = 'field_footer_sections___drupal_internal__target_id',
  field_footer_social_links = 'field_footer_social_links',
  field_footer_social_links___target_revision_id = 'field_footer_social_links___target_revision_id',
  field_footer_social_links___drupal_internal__target_id = 'field_footer_social_links___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__FooterFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__FooterRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__FooterSite_Setting_Entity_TypeFilterInput>;
  field_footer_sections?: Maybe<Site_Setting_Entity__FooterField_Footer_SectionsFilterListInput>;
  field_footer_social_links?: Maybe<
    Site_Setting_Entity__FooterField_Footer_Social_LinksFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__FooterFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__FooterFilterInput>;
};

export type Site_Setting_Entity__FooterGroupConnection = {
  __typename?: 'site_setting_entity__footerGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__FooterEdge>;
  nodes: Array<Site_Setting_Entity__Footer>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FooterRelationships = {
  __typename?: 'site_setting_entity__footerRelationships';
  field_footer_sections: Array<Maybe<Paragraph__Footer_Section>>;
  field_footer_social_links: Array<Maybe<Paragraph__Social_Link>>;
};

export type Site_Setting_Entity__FooterRelationshipsFilterInput = {
  field_footer_sections?: Maybe<Paragraph__Footer_SectionFilterListInput>;
  field_footer_social_links?: Maybe<Paragraph__Social_LinkFilterListInput>;
};

export type Site_Setting_Entity__FooterSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__footerSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FooterSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__FooterSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__FooterFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Franchise = Node & {
  __typename?: 'site_setting_entity__franchise';
  langcode?: Maybe<Scalars['String']>;
  field_fr_form_branch?: Maybe<Scalars['String']>;
  field_fr_form_email?: Maybe<Scalars['String']>;
  field_fr_form_header?: Maybe<Scalars['String']>;
  field_fr_form_name?: Maybe<Scalars['String']>;
  field_fr_form_network_name?: Maybe<Scalars['String']>;
  field_fr_form_phone?: Maybe<Scalars['String']>;
  field_fr_form_shops_number?: Maybe<Scalars['String']>;
  field_settings_button_text?: Maybe<Scalars['String']>;
  field_settings_text_1?: Maybe<Scalars['String']>;
  field_settings_text_2?: Maybe<Scalars['String']>;
  field_settings_text_3?: Maybe<Scalars['String']>;
  field_settings_text_4?: Maybe<Scalars['String']>;
  field_settings_text_5?: Maybe<Scalars['String']>;
  field_settings_text_6?: Maybe<Scalars['String']>;
  field_settings_text_7?: Maybe<Scalars['String']>;
  field_settings_text_8?: Maybe<Scalars['String']>;
  field_settings_text_9?: Maybe<Scalars['String']>;
  field_settings_text_10?: Maybe<Scalars['String']>;
  field_text_1?: Maybe<Field_Text_1>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_modal_1?: Maybe<Field_Modal_1>;
  field_modal_2?: Maybe<Field_Modal_2>;
  relationships?: Maybe<Site_Setting_Entity__FranchiseRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__FranchiseSite_Setting_Entity_Type>;
  field_components?: Maybe<Array<Maybe<Site_Setting_Entity__FranchiseField_Components>>>;
  field_main_image?: Maybe<Site_Setting_Entity__FranchiseField_Main_Image>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__FranchiseCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FranchiseChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FranchiseContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FranchiseContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FranchiseConnection = {
  __typename?: 'site_setting_entity__franchiseConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__FranchiseEdge>;
  nodes: Array<Site_Setting_Entity__Franchise>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__FranchiseGroupConnection>;
};

export type Site_Setting_Entity__FranchiseConnectionDistinctArgs = {
  field: Site_Setting_Entity__FranchiseFieldsEnum;
};

export type Site_Setting_Entity__FranchiseConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__FranchiseFieldsEnum;
};

export type Site_Setting_Entity__FranchiseEdge = {
  __typename?: 'site_setting_entity__franchiseEdge';
  next?: Maybe<Site_Setting_Entity__Franchise>;
  node: Site_Setting_Entity__Franchise;
  previous?: Maybe<Site_Setting_Entity__Franchise>;
};

export type Site_Setting_Entity__FranchiseField_Components = {
  __typename?: 'site_setting_entity__franchiseField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__FranchiseField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__FranchiseField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__FranchiseField_ComponentsFilterInput>;
};

export type Site_Setting_Entity__FranchiseField_Main_Image = {
  __typename?: 'site_setting_entity__franchiseField_main_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__FranchiseField_Main_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Site_Setting_Entity__FranchiseFieldsEnum {
  langcode = 'langcode',
  field_fr_form_branch = 'field_fr_form_branch',
  field_fr_form_email = 'field_fr_form_email',
  field_fr_form_header = 'field_fr_form_header',
  field_fr_form_name = 'field_fr_form_name',
  field_fr_form_network_name = 'field_fr_form_network_name',
  field_fr_form_phone = 'field_fr_form_phone',
  field_fr_form_shops_number = 'field_fr_form_shops_number',
  field_settings_button_text = 'field_settings_button_text',
  field_settings_text_1 = 'field_settings_text_1',
  field_settings_text_2 = 'field_settings_text_2',
  field_settings_text_3 = 'field_settings_text_3',
  field_settings_text_4 = 'field_settings_text_4',
  field_settings_text_5 = 'field_settings_text_5',
  field_settings_text_6 = 'field_settings_text_6',
  field_settings_text_7 = 'field_settings_text_7',
  field_settings_text_8 = 'field_settings_text_8',
  field_settings_text_9 = 'field_settings_text_9',
  field_settings_text_10 = 'field_settings_text_10',
  field_text_1___value = 'field_text_1___value',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  field_modal_1___value = 'field_modal_1___value',
  field_modal_2___value = 'field_modal_2___value',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  field_main_image___alt = 'field_main_image___alt',
  field_main_image___title = 'field_main_image___title',
  field_main_image___width = 'field_main_image___width',
  field_main_image___height = 'field_main_image___height',
  field_main_image___drupal_internal__target_id = 'field_main_image___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__FranchiseFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_fr_form_branch?: Maybe<StringQueryOperatorInput>;
  field_fr_form_email?: Maybe<StringQueryOperatorInput>;
  field_fr_form_header?: Maybe<StringQueryOperatorInput>;
  field_fr_form_name?: Maybe<StringQueryOperatorInput>;
  field_fr_form_network_name?: Maybe<StringQueryOperatorInput>;
  field_fr_form_phone?: Maybe<StringQueryOperatorInput>;
  field_fr_form_shops_number?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_settings_text_7?: Maybe<StringQueryOperatorInput>;
  field_settings_text_8?: Maybe<StringQueryOperatorInput>;
  field_settings_text_9?: Maybe<StringQueryOperatorInput>;
  field_settings_text_10?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_modal_1?: Maybe<Field_Modal_1FilterInput>;
  field_modal_2?: Maybe<Field_Modal_2FilterInput>;
  relationships?: Maybe<Site_Setting_Entity__FranchiseRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__FranchiseSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__FranchiseField_ComponentsFilterListInput>;
  field_main_image?: Maybe<Site_Setting_Entity__FranchiseField_Main_ImageFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__FranchiseFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__FranchiseFilterInput>;
};

export type Site_Setting_Entity__FranchiseGroupConnection = {
  __typename?: 'site_setting_entity__franchiseGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__FranchiseEdge>;
  nodes: Array<Site_Setting_Entity__Franchise>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FranchiseRelationships = {
  __typename?: 'site_setting_entity__franchiseRelationships';
  field_main_image?: Maybe<File__File>;
  field_components?: Maybe<Array<Maybe<FranchiseRelatedComponents>>>;
};

export type Site_Setting_Entity__FranchiseRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__FranchiseSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__franchiseSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__FranchiseSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__FranchiseSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__FranchiseFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Intercars = Node & {
  __typename?: 'site_setting_entity__intercars';
  langcode?: Maybe<Scalars['String']>;
  field_agreement_1?: Maybe<Field_Agreement_1>;
  field_agreement_2?: Maybe<Scalars['String']>;
  field_agreement_3?: Maybe<Field_Agreement_3>;
  field_hero_headline?: Maybe<Field_Hero_Headline>;
  field_image_1?: Maybe<Field_Image_1>;
  field_info_text?: Maybe<Field_Info_Text>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_teksty_formularza?: Maybe<Array<Maybe<Scalars['String']>>>;
  field_text_1?: Maybe<Field_Text_1>;
  relationships?: Maybe<Site_Setting_Entity__IntercarsRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__IntercarsSite_Setting_Entity_Type>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__IntercarsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__IntercarsChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__IntercarsContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__IntercarsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__IntercarsConnection = {
  __typename?: 'site_setting_entity__intercarsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__IntercarsEdge>;
  nodes: Array<Site_Setting_Entity__Intercars>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__IntercarsGroupConnection>;
};

export type Site_Setting_Entity__IntercarsConnectionDistinctArgs = {
  field: Site_Setting_Entity__IntercarsFieldsEnum;
};

export type Site_Setting_Entity__IntercarsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__IntercarsFieldsEnum;
};

export type Site_Setting_Entity__IntercarsEdge = {
  __typename?: 'site_setting_entity__intercarsEdge';
  next?: Maybe<Site_Setting_Entity__Intercars>;
  node: Site_Setting_Entity__Intercars;
  previous?: Maybe<Site_Setting_Entity__Intercars>;
};

export enum Site_Setting_Entity__IntercarsFieldsEnum {
  langcode = 'langcode',
  field_agreement_1___value = 'field_agreement_1___value',
  field_agreement_2 = 'field_agreement_2',
  field_agreement_3___value = 'field_agreement_3___value',
  field_hero_headline___value = 'field_hero_headline___value',
  field_image_1___alt = 'field_image_1___alt',
  field_info_text___value = 'field_info_text___value',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  field_teksty_formularza = 'field_teksty_formularza',
  field_text_1___value = 'field_text_1___value',
  relationships___field_image_1___localFile___birthtime = 'relationships___field_image_1___localFile___birthtime',
  relationships___field_image_1___localFile___birthtimeMs = 'relationships___field_image_1___localFile___birthtimeMs',
  relationships___field_image_1___localFile___publicURL = 'relationships___field_image_1___localFile___publicURL',
  relationships___field_image_1___localFile___sourceInstanceName = 'relationships___field_image_1___localFile___sourceInstanceName',
  relationships___field_image_1___localFile___absolutePath = 'relationships___field_image_1___localFile___absolutePath',
  relationships___field_image_1___localFile___relativePath = 'relationships___field_image_1___localFile___relativePath',
  relationships___field_image_1___localFile___extension = 'relationships___field_image_1___localFile___extension',
  relationships___field_image_1___localFile___size = 'relationships___field_image_1___localFile___size',
  relationships___field_image_1___localFile___prettySize = 'relationships___field_image_1___localFile___prettySize',
  relationships___field_image_1___localFile___modifiedTime = 'relationships___field_image_1___localFile___modifiedTime',
  relationships___field_image_1___localFile___accessTime = 'relationships___field_image_1___localFile___accessTime',
  relationships___field_image_1___localFile___changeTime = 'relationships___field_image_1___localFile___changeTime',
  relationships___field_image_1___localFile___birthTime = 'relationships___field_image_1___localFile___birthTime',
  relationships___field_image_1___localFile___root = 'relationships___field_image_1___localFile___root',
  relationships___field_image_1___localFile___dir = 'relationships___field_image_1___localFile___dir',
  relationships___field_image_1___localFile___base = 'relationships___field_image_1___localFile___base',
  relationships___field_image_1___localFile___ext = 'relationships___field_image_1___localFile___ext',
  relationships___field_image_1___localFile___name = 'relationships___field_image_1___localFile___name',
  relationships___field_image_1___localFile___relativeDirectory = 'relationships___field_image_1___localFile___relativeDirectory',
  relationships___field_image_1___localFile___dev = 'relationships___field_image_1___localFile___dev',
  relationships___field_image_1___localFile___mode = 'relationships___field_image_1___localFile___mode',
  relationships___field_image_1___localFile___nlink = 'relationships___field_image_1___localFile___nlink',
  relationships___field_image_1___localFile___uid = 'relationships___field_image_1___localFile___uid',
  relationships___field_image_1___localFile___gid = 'relationships___field_image_1___localFile___gid',
  relationships___field_image_1___localFile___rdev = 'relationships___field_image_1___localFile___rdev',
  relationships___field_image_1___localFile___blksize = 'relationships___field_image_1___localFile___blksize',
  relationships___field_image_1___localFile___ino = 'relationships___field_image_1___localFile___ino',
  relationships___field_image_1___localFile___blocks = 'relationships___field_image_1___localFile___blocks',
  relationships___field_image_1___localFile___atimeMs = 'relationships___field_image_1___localFile___atimeMs',
  relationships___field_image_1___localFile___mtimeMs = 'relationships___field_image_1___localFile___mtimeMs',
  relationships___field_image_1___localFile___ctimeMs = 'relationships___field_image_1___localFile___ctimeMs',
  relationships___field_image_1___localFile___atime = 'relationships___field_image_1___localFile___atime',
  relationships___field_image_1___localFile___mtime = 'relationships___field_image_1___localFile___mtime',
  relationships___field_image_1___localFile___ctime = 'relationships___field_image_1___localFile___ctime',
  relationships___field_image_1___localFile___url = 'relationships___field_image_1___localFile___url',
  relationships___field_image_1___localFile___id = 'relationships___field_image_1___localFile___id',
  relationships___field_image_1___localFile___children = 'relationships___field_image_1___localFile___children',
  relationships___field_image_1___uri___url = 'relationships___field_image_1___uri___url',
  relationships___field_image_1___drupal_id = 'relationships___field_image_1___drupal_id',
  relationships___field_image_1___drupal_internal__fid = 'relationships___field_image_1___drupal_internal__fid',
  relationships___field_image_1___langcode = 'relationships___field_image_1___langcode',
  relationships___field_image_1___filename = 'relationships___field_image_1___filename',
  relationships___field_image_1___filemime = 'relationships___field_image_1___filemime',
  relationships___field_image_1___filesize = 'relationships___field_image_1___filesize',
  relationships___field_image_1___status = 'relationships___field_image_1___status',
  relationships___field_image_1___created = 'relationships___field_image_1___created',
  relationships___field_image_1___changed = 'relationships___field_image_1___changed',
  relationships___field_image_1___uuid = 'relationships___field_image_1___uuid',
  relationships___field_image_1___relationships___node__blog_post = 'relationships___field_image_1___relationships___node__blog_post',
  relationships___field_image_1___relationships___node__devices = 'relationships___field_image_1___relationships___node__devices',
  relationships___field_image_1___relationships___node__accessories = 'relationships___field_image_1___relationships___node__accessories',
  relationships___field_image_1___relationships___paragraph__news_image_title_text = 'relationships___field_image_1___relationships___paragraph__news_image_title_text',
  relationships___field_image_1___relationships___node__cases = 'relationships___field_image_1___relationships___node__cases',
  relationships___field_image_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_1___relationships___paragraph__branches_features = 'relationships___field_image_1___relationships___paragraph__branches_features',
  relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_1___relationships___paragraph__branches_testimonials = 'relationships___field_image_1___relationships___paragraph__branches_testimonials',
  relationships___field_image_1___relationships___paragraph__branches_discounts = 'relationships___field_image_1___relationships___paragraph__branches_discounts',
  relationships___field_image_1___relationships___node__news = 'relationships___field_image_1___relationships___node__news',
  relationships___field_image_1___relationships___node__branches = 'relationships___field_image_1___relationships___node__branches',
  relationships___field_image_1___relationships___paragraph__branches_hero = 'relationships___field_image_1___relationships___paragraph__branches_hero',
  relationships___field_image_1___relationships___paragraph__bullets = 'relationships___field_image_1___relationships___paragraph__bullets',
  relationships___field_image_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_1___relationships___paragraph__branches_device = 'relationships___field_image_1___relationships___paragraph__branches_device',
  relationships___field_image_1___relationships___paragraph__branches_recommendation = 'relationships___field_image_1___relationships___paragraph__branches_recommendation',
  relationships___field_image_1___relationships___site_setting_entity__accessories = 'relationships___field_image_1___relationships___site_setting_entity__accessories',
  relationships___field_image_1___relationships___site_setting_entity__branches = 'relationships___field_image_1___relationships___site_setting_entity__branches',
  relationships___field_image_1___relationships___site_setting_entity__about_us = 'relationships___field_image_1___relationships___site_setting_entity__about_us',
  relationships___field_image_1___relationships___paragraph__logos = 'relationships___field_image_1___relationships___paragraph__logos',
  relationships___field_image_1___relationships___paragraph__how_it_works = 'relationships___field_image_1___relationships___paragraph__how_it_works',
  relationships___field_image_1___relationships___paragraph__ico_and_text = 'relationships___field_image_1___relationships___paragraph__ico_and_text',
  relationships___field_image_1___relationships___paragraph__hero_1 = 'relationships___field_image_1___relationships___paragraph__hero_1',
  relationships___field_image_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_1___relationships___site_setting_entity__product = 'relationships___field_image_1___relationships___site_setting_entity__product',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_1___relationships___paragraph__porownanie_terminali = 'relationships___field_image_1___relationships___paragraph__porownanie_terminali',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_1___relationships___site_setting_entity__main_page = 'relationships___field_image_1___relationships___site_setting_entity__main_page',
  relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_1___relationships___site_setting_entity__intercars = 'relationships___field_image_1___relationships___site_setting_entity__intercars',
  relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_1___relationships___node__agreement = 'relationships___field_image_1___relationships___node__agreement',
  relationships___field_image_1___relationships___node__fiscalization = 'relationships___field_image_1___relationships___node__fiscalization',
  relationships___field_image_1___relationships___site_setting_entity__configurator = 'relationships___field_image_1___relationships___site_setting_entity__configurator',
  relationships___field_image_1___relationships___paragraph__social_link = 'relationships___field_image_1___relationships___paragraph__social_link',
  relationships___field_image_1___relationships___paragraph__make_an_appointment = 'relationships___field_image_1___relationships___paragraph__make_an_appointment',
  relationships___field_image_1___relationships___paragraph__linked_icons = 'relationships___field_image_1___relationships___paragraph__linked_icons',
  relationships___field_image_1___relationships___site_setting_entity__financing = 'relationships___field_image_1___relationships___site_setting_entity__financing',
  relationships___field_image_1___relationships___site_setting_entity__franchise = 'relationships___field_image_1___relationships___site_setting_entity__franchise',
  relationships___field_image_1___id = 'relationships___field_image_1___id',
  relationships___field_image_1___parent___id = 'relationships___field_image_1___parent___id',
  relationships___field_image_1___parent___children = 'relationships___field_image_1___parent___children',
  relationships___field_image_1___children = 'relationships___field_image_1___children',
  relationships___field_image_1___children___id = 'relationships___field_image_1___children___id',
  relationships___field_image_1___children___children = 'relationships___field_image_1___children___children',
  relationships___field_image_1___internal___content = 'relationships___field_image_1___internal___content',
  relationships___field_image_1___internal___contentDigest = 'relationships___field_image_1___internal___contentDigest',
  relationships___field_image_1___internal___description = 'relationships___field_image_1___internal___description',
  relationships___field_image_1___internal___fieldOwners = 'relationships___field_image_1___internal___fieldOwners',
  relationships___field_image_1___internal___ignoreType = 'relationships___field_image_1___internal___ignoreType',
  relationships___field_image_1___internal___mediaType = 'relationships___field_image_1___internal___mediaType',
  relationships___field_image_1___internal___owner = 'relationships___field_image_1___internal___owner',
  relationships___field_image_1___internal___type = 'relationships___field_image_1___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__IntercarsFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_agreement_1?: Maybe<Field_Agreement_1FilterInput>;
  field_agreement_2?: Maybe<StringQueryOperatorInput>;
  field_agreement_3?: Maybe<Field_Agreement_3FilterInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_info_text?: Maybe<Field_Info_TextFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_teksty_formularza?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  relationships?: Maybe<Site_Setting_Entity__IntercarsRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__IntercarsSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__IntercarsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__IntercarsFilterInput>;
};

export type Site_Setting_Entity__IntercarsGroupConnection = {
  __typename?: 'site_setting_entity__intercarsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__IntercarsEdge>;
  nodes: Array<Site_Setting_Entity__Intercars>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__IntercarsRelationships = {
  __typename?: 'site_setting_entity__intercarsRelationships';
  field_image_1?: Maybe<File__File>;
};

export type Site_Setting_Entity__IntercarsRelationshipsFilterInput = {
  field_image_1?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__IntercarsSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__intercarsSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__IntercarsSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__IntercarsSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__IntercarsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal = Node & {
  __typename?: 'site_setting_entity__kasa_fiskalna_vs_kasoterminal';
  langcode?: Maybe<Scalars['String']>;
  field_hero_headline?: Maybe<Field_Hero_Headline>;
  field_hero_text?: Maybe<Field_Hero_Text>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_hero_video?: Maybe<Scalars['String']>;
  field_hero_image?: Maybe<Field_Hero_Image>;
  field_hero_video_thumbnail?: Maybe<Field_Hero_Video_Thumbnail>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_Mobile>;
  field_hero_button_text?: Maybe<Scalars['String']>;
  field_opisy_wykresow?: Maybe<Array<Maybe<Scalars['String']>>>;
  field_settings_tresc?: Maybe<Field_Settings_Tresc>;
  field_text_1?: Maybe<Field_Text_1>;
  field_text_2?: Maybe<Field_Text_2>;
  field_text_3?: Maybe<Field_Text_3>;
  field_text_4?: Maybe<Field_Text_4>;
  relationships?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalSite_Setting_Entity_Type
  >;
  field_components?: Maybe<
    Array<Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalField_Components>>
  >;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalConnection = {
  __typename?: 'site_setting_entity__kasa_fiskalna_vs_kasoterminalConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalEdge>;
  nodes: Array<Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalGroupConnection>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalConnectionDistinctArgs = {
  field: Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFieldsEnum;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFieldsEnum;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalEdge = {
  __typename?: 'site_setting_entity__kasa_fiskalna_vs_kasoterminalEdge';
  next?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal>;
  node: Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal;
  previous?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalField_Components = {
  __typename?: 'site_setting_entity__kasa_fiskalna_vs_kasoterminalField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalField_ComponentsFilterInput>;
};

export enum Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFieldsEnum {
  langcode = 'langcode',
  field_hero_headline___value = 'field_hero_headline___value',
  field_hero_text___value = 'field_hero_text___value',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  field_hero_video = 'field_hero_video',
  field_hero_image___alt = 'field_hero_image___alt',
  field_hero_video_thumbnail___alt = 'field_hero_video_thumbnail___alt',
  field_hero_image_mobile___alt = 'field_hero_image_mobile___alt',
  field_hero_button_text = 'field_hero_button_text',
  field_opisy_wykresow = 'field_opisy_wykresow',
  field_settings_tresc___value = 'field_settings_tresc___value',
  field_text_1___value = 'field_text_1___value',
  field_text_2___value = 'field_text_2___value',
  field_text_3___value = 'field_text_3___value',
  field_text_4___value = 'field_text_4___value',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  relationships___field_hero_image___localFile___birthtime = 'relationships___field_hero_image___localFile___birthtime',
  relationships___field_hero_image___localFile___birthtimeMs = 'relationships___field_hero_image___localFile___birthtimeMs',
  relationships___field_hero_image___localFile___publicURL = 'relationships___field_hero_image___localFile___publicURL',
  relationships___field_hero_image___localFile___sourceInstanceName = 'relationships___field_hero_image___localFile___sourceInstanceName',
  relationships___field_hero_image___localFile___absolutePath = 'relationships___field_hero_image___localFile___absolutePath',
  relationships___field_hero_image___localFile___relativePath = 'relationships___field_hero_image___localFile___relativePath',
  relationships___field_hero_image___localFile___extension = 'relationships___field_hero_image___localFile___extension',
  relationships___field_hero_image___localFile___size = 'relationships___field_hero_image___localFile___size',
  relationships___field_hero_image___localFile___prettySize = 'relationships___field_hero_image___localFile___prettySize',
  relationships___field_hero_image___localFile___modifiedTime = 'relationships___field_hero_image___localFile___modifiedTime',
  relationships___field_hero_image___localFile___accessTime = 'relationships___field_hero_image___localFile___accessTime',
  relationships___field_hero_image___localFile___changeTime = 'relationships___field_hero_image___localFile___changeTime',
  relationships___field_hero_image___localFile___birthTime = 'relationships___field_hero_image___localFile___birthTime',
  relationships___field_hero_image___localFile___root = 'relationships___field_hero_image___localFile___root',
  relationships___field_hero_image___localFile___dir = 'relationships___field_hero_image___localFile___dir',
  relationships___field_hero_image___localFile___base = 'relationships___field_hero_image___localFile___base',
  relationships___field_hero_image___localFile___ext = 'relationships___field_hero_image___localFile___ext',
  relationships___field_hero_image___localFile___name = 'relationships___field_hero_image___localFile___name',
  relationships___field_hero_image___localFile___relativeDirectory = 'relationships___field_hero_image___localFile___relativeDirectory',
  relationships___field_hero_image___localFile___dev = 'relationships___field_hero_image___localFile___dev',
  relationships___field_hero_image___localFile___mode = 'relationships___field_hero_image___localFile___mode',
  relationships___field_hero_image___localFile___nlink = 'relationships___field_hero_image___localFile___nlink',
  relationships___field_hero_image___localFile___uid = 'relationships___field_hero_image___localFile___uid',
  relationships___field_hero_image___localFile___gid = 'relationships___field_hero_image___localFile___gid',
  relationships___field_hero_image___localFile___rdev = 'relationships___field_hero_image___localFile___rdev',
  relationships___field_hero_image___localFile___blksize = 'relationships___field_hero_image___localFile___blksize',
  relationships___field_hero_image___localFile___ino = 'relationships___field_hero_image___localFile___ino',
  relationships___field_hero_image___localFile___blocks = 'relationships___field_hero_image___localFile___blocks',
  relationships___field_hero_image___localFile___atimeMs = 'relationships___field_hero_image___localFile___atimeMs',
  relationships___field_hero_image___localFile___mtimeMs = 'relationships___field_hero_image___localFile___mtimeMs',
  relationships___field_hero_image___localFile___ctimeMs = 'relationships___field_hero_image___localFile___ctimeMs',
  relationships___field_hero_image___localFile___atime = 'relationships___field_hero_image___localFile___atime',
  relationships___field_hero_image___localFile___mtime = 'relationships___field_hero_image___localFile___mtime',
  relationships___field_hero_image___localFile___ctime = 'relationships___field_hero_image___localFile___ctime',
  relationships___field_hero_image___localFile___url = 'relationships___field_hero_image___localFile___url',
  relationships___field_hero_image___localFile___id = 'relationships___field_hero_image___localFile___id',
  relationships___field_hero_image___localFile___children = 'relationships___field_hero_image___localFile___children',
  relationships___field_hero_image___uri___url = 'relationships___field_hero_image___uri___url',
  relationships___field_hero_image___drupal_id = 'relationships___field_hero_image___drupal_id',
  relationships___field_hero_image___drupal_internal__fid = 'relationships___field_hero_image___drupal_internal__fid',
  relationships___field_hero_image___langcode = 'relationships___field_hero_image___langcode',
  relationships___field_hero_image___filename = 'relationships___field_hero_image___filename',
  relationships___field_hero_image___filemime = 'relationships___field_hero_image___filemime',
  relationships___field_hero_image___filesize = 'relationships___field_hero_image___filesize',
  relationships___field_hero_image___status = 'relationships___field_hero_image___status',
  relationships___field_hero_image___created = 'relationships___field_hero_image___created',
  relationships___field_hero_image___changed = 'relationships___field_hero_image___changed',
  relationships___field_hero_image___uuid = 'relationships___field_hero_image___uuid',
  relationships___field_hero_image___relationships___node__blog_post = 'relationships___field_hero_image___relationships___node__blog_post',
  relationships___field_hero_image___relationships___node__devices = 'relationships___field_hero_image___relationships___node__devices',
  relationships___field_hero_image___relationships___node__accessories = 'relationships___field_hero_image___relationships___node__accessories',
  relationships___field_hero_image___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image___relationships___node__cases = 'relationships___field_hero_image___relationships___node__cases',
  relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image___relationships___paragraph__branches_features = 'relationships___field_hero_image___relationships___paragraph__branches_features',
  relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image___relationships___paragraph__branches_discounts = 'relationships___field_hero_image___relationships___paragraph__branches_discounts',
  relationships___field_hero_image___relationships___node__news = 'relationships___field_hero_image___relationships___node__news',
  relationships___field_hero_image___relationships___node__branches = 'relationships___field_hero_image___relationships___node__branches',
  relationships___field_hero_image___relationships___paragraph__branches_hero = 'relationships___field_hero_image___relationships___paragraph__branches_hero',
  relationships___field_hero_image___relationships___paragraph__bullets = 'relationships___field_hero_image___relationships___paragraph__bullets',
  relationships___field_hero_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image___relationships___paragraph__branches_device = 'relationships___field_hero_image___relationships___paragraph__branches_device',
  relationships___field_hero_image___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image___relationships___site_setting_entity__accessories = 'relationships___field_hero_image___relationships___site_setting_entity__accessories',
  relationships___field_hero_image___relationships___site_setting_entity__branches = 'relationships___field_hero_image___relationships___site_setting_entity__branches',
  relationships___field_hero_image___relationships___site_setting_entity__about_us = 'relationships___field_hero_image___relationships___site_setting_entity__about_us',
  relationships___field_hero_image___relationships___paragraph__logos = 'relationships___field_hero_image___relationships___paragraph__logos',
  relationships___field_hero_image___relationships___paragraph__how_it_works = 'relationships___field_hero_image___relationships___paragraph__how_it_works',
  relationships___field_hero_image___relationships___paragraph__ico_and_text = 'relationships___field_hero_image___relationships___paragraph__ico_and_text',
  relationships___field_hero_image___relationships___paragraph__hero_1 = 'relationships___field_hero_image___relationships___paragraph__hero_1',
  relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image___relationships___site_setting_entity__product = 'relationships___field_hero_image___relationships___site_setting_entity__product',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image___relationships___site_setting_entity__main_page = 'relationships___field_hero_image___relationships___site_setting_entity__main_page',
  relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image___relationships___site_setting_entity__intercars = 'relationships___field_hero_image___relationships___site_setting_entity__intercars',
  relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image___relationships___node__agreement = 'relationships___field_hero_image___relationships___node__agreement',
  relationships___field_hero_image___relationships___node__fiscalization = 'relationships___field_hero_image___relationships___node__fiscalization',
  relationships___field_hero_image___relationships___site_setting_entity__configurator = 'relationships___field_hero_image___relationships___site_setting_entity__configurator',
  relationships___field_hero_image___relationships___paragraph__social_link = 'relationships___field_hero_image___relationships___paragraph__social_link',
  relationships___field_hero_image___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image___relationships___paragraph__linked_icons = 'relationships___field_hero_image___relationships___paragraph__linked_icons',
  relationships___field_hero_image___relationships___site_setting_entity__financing = 'relationships___field_hero_image___relationships___site_setting_entity__financing',
  relationships___field_hero_image___relationships___site_setting_entity__franchise = 'relationships___field_hero_image___relationships___site_setting_entity__franchise',
  relationships___field_hero_image___id = 'relationships___field_hero_image___id',
  relationships___field_hero_image___parent___id = 'relationships___field_hero_image___parent___id',
  relationships___field_hero_image___parent___children = 'relationships___field_hero_image___parent___children',
  relationships___field_hero_image___children = 'relationships___field_hero_image___children',
  relationships___field_hero_image___children___id = 'relationships___field_hero_image___children___id',
  relationships___field_hero_image___children___children = 'relationships___field_hero_image___children___children',
  relationships___field_hero_image___internal___content = 'relationships___field_hero_image___internal___content',
  relationships___field_hero_image___internal___contentDigest = 'relationships___field_hero_image___internal___contentDigest',
  relationships___field_hero_image___internal___description = 'relationships___field_hero_image___internal___description',
  relationships___field_hero_image___internal___fieldOwners = 'relationships___field_hero_image___internal___fieldOwners',
  relationships___field_hero_image___internal___ignoreType = 'relationships___field_hero_image___internal___ignoreType',
  relationships___field_hero_image___internal___mediaType = 'relationships___field_hero_image___internal___mediaType',
  relationships___field_hero_image___internal___owner = 'relationships___field_hero_image___internal___owner',
  relationships___field_hero_image___internal___type = 'relationships___field_hero_image___internal___type',
  relationships___field_hero_image_mobile___localFile___birthtime = 'relationships___field_hero_image_mobile___localFile___birthtime',
  relationships___field_hero_image_mobile___localFile___birthtimeMs = 'relationships___field_hero_image_mobile___localFile___birthtimeMs',
  relationships___field_hero_image_mobile___localFile___publicURL = 'relationships___field_hero_image_mobile___localFile___publicURL',
  relationships___field_hero_image_mobile___localFile___sourceInstanceName = 'relationships___field_hero_image_mobile___localFile___sourceInstanceName',
  relationships___field_hero_image_mobile___localFile___absolutePath = 'relationships___field_hero_image_mobile___localFile___absolutePath',
  relationships___field_hero_image_mobile___localFile___relativePath = 'relationships___field_hero_image_mobile___localFile___relativePath',
  relationships___field_hero_image_mobile___localFile___extension = 'relationships___field_hero_image_mobile___localFile___extension',
  relationships___field_hero_image_mobile___localFile___size = 'relationships___field_hero_image_mobile___localFile___size',
  relationships___field_hero_image_mobile___localFile___prettySize = 'relationships___field_hero_image_mobile___localFile___prettySize',
  relationships___field_hero_image_mobile___localFile___modifiedTime = 'relationships___field_hero_image_mobile___localFile___modifiedTime',
  relationships___field_hero_image_mobile___localFile___accessTime = 'relationships___field_hero_image_mobile___localFile___accessTime',
  relationships___field_hero_image_mobile___localFile___changeTime = 'relationships___field_hero_image_mobile___localFile___changeTime',
  relationships___field_hero_image_mobile___localFile___birthTime = 'relationships___field_hero_image_mobile___localFile___birthTime',
  relationships___field_hero_image_mobile___localFile___root = 'relationships___field_hero_image_mobile___localFile___root',
  relationships___field_hero_image_mobile___localFile___dir = 'relationships___field_hero_image_mobile___localFile___dir',
  relationships___field_hero_image_mobile___localFile___base = 'relationships___field_hero_image_mobile___localFile___base',
  relationships___field_hero_image_mobile___localFile___ext = 'relationships___field_hero_image_mobile___localFile___ext',
  relationships___field_hero_image_mobile___localFile___name = 'relationships___field_hero_image_mobile___localFile___name',
  relationships___field_hero_image_mobile___localFile___relativeDirectory = 'relationships___field_hero_image_mobile___localFile___relativeDirectory',
  relationships___field_hero_image_mobile___localFile___dev = 'relationships___field_hero_image_mobile___localFile___dev',
  relationships___field_hero_image_mobile___localFile___mode = 'relationships___field_hero_image_mobile___localFile___mode',
  relationships___field_hero_image_mobile___localFile___nlink = 'relationships___field_hero_image_mobile___localFile___nlink',
  relationships___field_hero_image_mobile___localFile___uid = 'relationships___field_hero_image_mobile___localFile___uid',
  relationships___field_hero_image_mobile___localFile___gid = 'relationships___field_hero_image_mobile___localFile___gid',
  relationships___field_hero_image_mobile___localFile___rdev = 'relationships___field_hero_image_mobile___localFile___rdev',
  relationships___field_hero_image_mobile___localFile___blksize = 'relationships___field_hero_image_mobile___localFile___blksize',
  relationships___field_hero_image_mobile___localFile___ino = 'relationships___field_hero_image_mobile___localFile___ino',
  relationships___field_hero_image_mobile___localFile___blocks = 'relationships___field_hero_image_mobile___localFile___blocks',
  relationships___field_hero_image_mobile___localFile___atimeMs = 'relationships___field_hero_image_mobile___localFile___atimeMs',
  relationships___field_hero_image_mobile___localFile___mtimeMs = 'relationships___field_hero_image_mobile___localFile___mtimeMs',
  relationships___field_hero_image_mobile___localFile___ctimeMs = 'relationships___field_hero_image_mobile___localFile___ctimeMs',
  relationships___field_hero_image_mobile___localFile___atime = 'relationships___field_hero_image_mobile___localFile___atime',
  relationships___field_hero_image_mobile___localFile___mtime = 'relationships___field_hero_image_mobile___localFile___mtime',
  relationships___field_hero_image_mobile___localFile___ctime = 'relationships___field_hero_image_mobile___localFile___ctime',
  relationships___field_hero_image_mobile___localFile___url = 'relationships___field_hero_image_mobile___localFile___url',
  relationships___field_hero_image_mobile___localFile___id = 'relationships___field_hero_image_mobile___localFile___id',
  relationships___field_hero_image_mobile___localFile___children = 'relationships___field_hero_image_mobile___localFile___children',
  relationships___field_hero_image_mobile___uri___url = 'relationships___field_hero_image_mobile___uri___url',
  relationships___field_hero_image_mobile___drupal_id = 'relationships___field_hero_image_mobile___drupal_id',
  relationships___field_hero_image_mobile___drupal_internal__fid = 'relationships___field_hero_image_mobile___drupal_internal__fid',
  relationships___field_hero_image_mobile___langcode = 'relationships___field_hero_image_mobile___langcode',
  relationships___field_hero_image_mobile___filename = 'relationships___field_hero_image_mobile___filename',
  relationships___field_hero_image_mobile___filemime = 'relationships___field_hero_image_mobile___filemime',
  relationships___field_hero_image_mobile___filesize = 'relationships___field_hero_image_mobile___filesize',
  relationships___field_hero_image_mobile___status = 'relationships___field_hero_image_mobile___status',
  relationships___field_hero_image_mobile___created = 'relationships___field_hero_image_mobile___created',
  relationships___field_hero_image_mobile___changed = 'relationships___field_hero_image_mobile___changed',
  relationships___field_hero_image_mobile___uuid = 'relationships___field_hero_image_mobile___uuid',
  relationships___field_hero_image_mobile___relationships___node__blog_post = 'relationships___field_hero_image_mobile___relationships___node__blog_post',
  relationships___field_hero_image_mobile___relationships___node__devices = 'relationships___field_hero_image_mobile___relationships___node__devices',
  relationships___field_hero_image_mobile___relationships___node__accessories = 'relationships___field_hero_image_mobile___relationships___node__accessories',
  relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image_mobile___relationships___node__cases = 'relationships___field_hero_image_mobile___relationships___node__cases',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_features = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_features',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts',
  relationships___field_hero_image_mobile___relationships___node__news = 'relationships___field_hero_image_mobile___relationships___node__news',
  relationships___field_hero_image_mobile___relationships___node__branches = 'relationships___field_hero_image_mobile___relationships___node__branches',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_hero = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_hero',
  relationships___field_hero_image_mobile___relationships___paragraph__bullets = 'relationships___field_hero_image_mobile___relationships___paragraph__bullets',
  relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_device = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_device',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__branches = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__branches',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us',
  relationships___field_hero_image_mobile___relationships___paragraph__logos = 'relationships___field_hero_image_mobile___relationships___paragraph__logos',
  relationships___field_hero_image_mobile___relationships___paragraph__how_it_works = 'relationships___field_hero_image_mobile___relationships___paragraph__how_it_works',
  relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text = 'relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text',
  relationships___field_hero_image_mobile___relationships___paragraph__hero_1 = 'relationships___field_hero_image_mobile___relationships___paragraph__hero_1',
  relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__product = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__product',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars',
  relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image_mobile___relationships___node__agreement = 'relationships___field_hero_image_mobile___relationships___node__agreement',
  relationships___field_hero_image_mobile___relationships___node__fiscalization = 'relationships___field_hero_image_mobile___relationships___node__fiscalization',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator',
  relationships___field_hero_image_mobile___relationships___paragraph__social_link = 'relationships___field_hero_image_mobile___relationships___paragraph__social_link',
  relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image_mobile___relationships___paragraph__linked_icons = 'relationships___field_hero_image_mobile___relationships___paragraph__linked_icons',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__financing = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__financing',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise',
  relationships___field_hero_image_mobile___id = 'relationships___field_hero_image_mobile___id',
  relationships___field_hero_image_mobile___parent___id = 'relationships___field_hero_image_mobile___parent___id',
  relationships___field_hero_image_mobile___parent___children = 'relationships___field_hero_image_mobile___parent___children',
  relationships___field_hero_image_mobile___children = 'relationships___field_hero_image_mobile___children',
  relationships___field_hero_image_mobile___children___id = 'relationships___field_hero_image_mobile___children___id',
  relationships___field_hero_image_mobile___children___children = 'relationships___field_hero_image_mobile___children___children',
  relationships___field_hero_image_mobile___internal___content = 'relationships___field_hero_image_mobile___internal___content',
  relationships___field_hero_image_mobile___internal___contentDigest = 'relationships___field_hero_image_mobile___internal___contentDigest',
  relationships___field_hero_image_mobile___internal___description = 'relationships___field_hero_image_mobile___internal___description',
  relationships___field_hero_image_mobile___internal___fieldOwners = 'relationships___field_hero_image_mobile___internal___fieldOwners',
  relationships___field_hero_image_mobile___internal___ignoreType = 'relationships___field_hero_image_mobile___internal___ignoreType',
  relationships___field_hero_image_mobile___internal___mediaType = 'relationships___field_hero_image_mobile___internal___mediaType',
  relationships___field_hero_image_mobile___internal___owner = 'relationships___field_hero_image_mobile___internal___owner',
  relationships___field_hero_image_mobile___internal___type = 'relationships___field_hero_image_mobile___internal___type',
  relationships___field_hero_video_thumbnail___localFile___birthtime = 'relationships___field_hero_video_thumbnail___localFile___birthtime',
  relationships___field_hero_video_thumbnail___localFile___birthtimeMs = 'relationships___field_hero_video_thumbnail___localFile___birthtimeMs',
  relationships___field_hero_video_thumbnail___localFile___publicURL = 'relationships___field_hero_video_thumbnail___localFile___publicURL',
  relationships___field_hero_video_thumbnail___localFile___sourceInstanceName = 'relationships___field_hero_video_thumbnail___localFile___sourceInstanceName',
  relationships___field_hero_video_thumbnail___localFile___absolutePath = 'relationships___field_hero_video_thumbnail___localFile___absolutePath',
  relationships___field_hero_video_thumbnail___localFile___relativePath = 'relationships___field_hero_video_thumbnail___localFile___relativePath',
  relationships___field_hero_video_thumbnail___localFile___extension = 'relationships___field_hero_video_thumbnail___localFile___extension',
  relationships___field_hero_video_thumbnail___localFile___size = 'relationships___field_hero_video_thumbnail___localFile___size',
  relationships___field_hero_video_thumbnail___localFile___prettySize = 'relationships___field_hero_video_thumbnail___localFile___prettySize',
  relationships___field_hero_video_thumbnail___localFile___modifiedTime = 'relationships___field_hero_video_thumbnail___localFile___modifiedTime',
  relationships___field_hero_video_thumbnail___localFile___accessTime = 'relationships___field_hero_video_thumbnail___localFile___accessTime',
  relationships___field_hero_video_thumbnail___localFile___changeTime = 'relationships___field_hero_video_thumbnail___localFile___changeTime',
  relationships___field_hero_video_thumbnail___localFile___birthTime = 'relationships___field_hero_video_thumbnail___localFile___birthTime',
  relationships___field_hero_video_thumbnail___localFile___root = 'relationships___field_hero_video_thumbnail___localFile___root',
  relationships___field_hero_video_thumbnail___localFile___dir = 'relationships___field_hero_video_thumbnail___localFile___dir',
  relationships___field_hero_video_thumbnail___localFile___base = 'relationships___field_hero_video_thumbnail___localFile___base',
  relationships___field_hero_video_thumbnail___localFile___ext = 'relationships___field_hero_video_thumbnail___localFile___ext',
  relationships___field_hero_video_thumbnail___localFile___name = 'relationships___field_hero_video_thumbnail___localFile___name',
  relationships___field_hero_video_thumbnail___localFile___relativeDirectory = 'relationships___field_hero_video_thumbnail___localFile___relativeDirectory',
  relationships___field_hero_video_thumbnail___localFile___dev = 'relationships___field_hero_video_thumbnail___localFile___dev',
  relationships___field_hero_video_thumbnail___localFile___mode = 'relationships___field_hero_video_thumbnail___localFile___mode',
  relationships___field_hero_video_thumbnail___localFile___nlink = 'relationships___field_hero_video_thumbnail___localFile___nlink',
  relationships___field_hero_video_thumbnail___localFile___uid = 'relationships___field_hero_video_thumbnail___localFile___uid',
  relationships___field_hero_video_thumbnail___localFile___gid = 'relationships___field_hero_video_thumbnail___localFile___gid',
  relationships___field_hero_video_thumbnail___localFile___rdev = 'relationships___field_hero_video_thumbnail___localFile___rdev',
  relationships___field_hero_video_thumbnail___localFile___blksize = 'relationships___field_hero_video_thumbnail___localFile___blksize',
  relationships___field_hero_video_thumbnail___localFile___ino = 'relationships___field_hero_video_thumbnail___localFile___ino',
  relationships___field_hero_video_thumbnail___localFile___blocks = 'relationships___field_hero_video_thumbnail___localFile___blocks',
  relationships___field_hero_video_thumbnail___localFile___atimeMs = 'relationships___field_hero_video_thumbnail___localFile___atimeMs',
  relationships___field_hero_video_thumbnail___localFile___mtimeMs = 'relationships___field_hero_video_thumbnail___localFile___mtimeMs',
  relationships___field_hero_video_thumbnail___localFile___ctimeMs = 'relationships___field_hero_video_thumbnail___localFile___ctimeMs',
  relationships___field_hero_video_thumbnail___localFile___atime = 'relationships___field_hero_video_thumbnail___localFile___atime',
  relationships___field_hero_video_thumbnail___localFile___mtime = 'relationships___field_hero_video_thumbnail___localFile___mtime',
  relationships___field_hero_video_thumbnail___localFile___ctime = 'relationships___field_hero_video_thumbnail___localFile___ctime',
  relationships___field_hero_video_thumbnail___localFile___url = 'relationships___field_hero_video_thumbnail___localFile___url',
  relationships___field_hero_video_thumbnail___localFile___id = 'relationships___field_hero_video_thumbnail___localFile___id',
  relationships___field_hero_video_thumbnail___localFile___children = 'relationships___field_hero_video_thumbnail___localFile___children',
  relationships___field_hero_video_thumbnail___uri___url = 'relationships___field_hero_video_thumbnail___uri___url',
  relationships___field_hero_video_thumbnail___drupal_id = 'relationships___field_hero_video_thumbnail___drupal_id',
  relationships___field_hero_video_thumbnail___drupal_internal__fid = 'relationships___field_hero_video_thumbnail___drupal_internal__fid',
  relationships___field_hero_video_thumbnail___langcode = 'relationships___field_hero_video_thumbnail___langcode',
  relationships___field_hero_video_thumbnail___filename = 'relationships___field_hero_video_thumbnail___filename',
  relationships___field_hero_video_thumbnail___filemime = 'relationships___field_hero_video_thumbnail___filemime',
  relationships___field_hero_video_thumbnail___filesize = 'relationships___field_hero_video_thumbnail___filesize',
  relationships___field_hero_video_thumbnail___status = 'relationships___field_hero_video_thumbnail___status',
  relationships___field_hero_video_thumbnail___created = 'relationships___field_hero_video_thumbnail___created',
  relationships___field_hero_video_thumbnail___changed = 'relationships___field_hero_video_thumbnail___changed',
  relationships___field_hero_video_thumbnail___uuid = 'relationships___field_hero_video_thumbnail___uuid',
  relationships___field_hero_video_thumbnail___relationships___node__blog_post = 'relationships___field_hero_video_thumbnail___relationships___node__blog_post',
  relationships___field_hero_video_thumbnail___relationships___node__devices = 'relationships___field_hero_video_thumbnail___relationships___node__devices',
  relationships___field_hero_video_thumbnail___relationships___node__accessories = 'relationships___field_hero_video_thumbnail___relationships___node__accessories',
  relationships___field_hero_video_thumbnail___relationships___paragraph__news_image_title_text = 'relationships___field_hero_video_thumbnail___relationships___paragraph__news_image_title_text',
  relationships___field_hero_video_thumbnail___relationships___node__cases = 'relationships___field_hero_video_thumbnail___relationships___node__cases',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_features = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_features',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_testimonials = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_testimonials',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_discounts = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_discounts',
  relationships___field_hero_video_thumbnail___relationships___node__news = 'relationships___field_hero_video_thumbnail___relationships___node__news',
  relationships___field_hero_video_thumbnail___relationships___node__branches = 'relationships___field_hero_video_thumbnail___relationships___node__branches',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_hero = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_hero',
  relationships___field_hero_video_thumbnail___relationships___paragraph__bullets = 'relationships___field_hero_video_thumbnail___relationships___paragraph__bullets',
  relationships___field_hero_video_thumbnail___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_video_thumbnail___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_device = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_device',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_recommendation = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_recommendation',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__accessories = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__accessories',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__branches = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__branches',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__about_us = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__about_us',
  relationships___field_hero_video_thumbnail___relationships___paragraph__logos = 'relationships___field_hero_video_thumbnail___relationships___paragraph__logos',
  relationships___field_hero_video_thumbnail___relationships___paragraph__how_it_works = 'relationships___field_hero_video_thumbnail___relationships___paragraph__how_it_works',
  relationships___field_hero_video_thumbnail___relationships___paragraph__ico_and_text = 'relationships___field_hero_video_thumbnail___relationships___paragraph__ico_and_text',
  relationships___field_hero_video_thumbnail___relationships___paragraph__hero_1 = 'relationships___field_hero_video_thumbnail___relationships___paragraph__hero_1',
  relationships___field_hero_video_thumbnail___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_video_thumbnail___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__product = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__product',
  relationships___field_hero_video_thumbnail___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_video_thumbnail___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_video_thumbnail___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_video_thumbnail___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_video_thumbnail___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_video_thumbnail___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__main_page = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__main_page',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_video_thumbnail___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_video_thumbnail___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__intercars = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__intercars',
  relationships___field_hero_video_thumbnail___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_video_thumbnail___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_video_thumbnail___relationships___node__agreement = 'relationships___field_hero_video_thumbnail___relationships___node__agreement',
  relationships___field_hero_video_thumbnail___relationships___node__fiscalization = 'relationships___field_hero_video_thumbnail___relationships___node__fiscalization',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__configurator = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__configurator',
  relationships___field_hero_video_thumbnail___relationships___paragraph__social_link = 'relationships___field_hero_video_thumbnail___relationships___paragraph__social_link',
  relationships___field_hero_video_thumbnail___relationships___paragraph__make_an_appointment = 'relationships___field_hero_video_thumbnail___relationships___paragraph__make_an_appointment',
  relationships___field_hero_video_thumbnail___relationships___paragraph__linked_icons = 'relationships___field_hero_video_thumbnail___relationships___paragraph__linked_icons',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__financing = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__financing',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__franchise = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__franchise',
  relationships___field_hero_video_thumbnail___id = 'relationships___field_hero_video_thumbnail___id',
  relationships___field_hero_video_thumbnail___parent___id = 'relationships___field_hero_video_thumbnail___parent___id',
  relationships___field_hero_video_thumbnail___parent___children = 'relationships___field_hero_video_thumbnail___parent___children',
  relationships___field_hero_video_thumbnail___children = 'relationships___field_hero_video_thumbnail___children',
  relationships___field_hero_video_thumbnail___children___id = 'relationships___field_hero_video_thumbnail___children___id',
  relationships___field_hero_video_thumbnail___children___children = 'relationships___field_hero_video_thumbnail___children___children',
  relationships___field_hero_video_thumbnail___internal___content = 'relationships___field_hero_video_thumbnail___internal___content',
  relationships___field_hero_video_thumbnail___internal___contentDigest = 'relationships___field_hero_video_thumbnail___internal___contentDigest',
  relationships___field_hero_video_thumbnail___internal___description = 'relationships___field_hero_video_thumbnail___internal___description',
  relationships___field_hero_video_thumbnail___internal___fieldOwners = 'relationships___field_hero_video_thumbnail___internal___fieldOwners',
  relationships___field_hero_video_thumbnail___internal___ignoreType = 'relationships___field_hero_video_thumbnail___internal___ignoreType',
  relationships___field_hero_video_thumbnail___internal___mediaType = 'relationships___field_hero_video_thumbnail___internal___mediaType',
  relationships___field_hero_video_thumbnail___internal___owner = 'relationships___field_hero_video_thumbnail___internal___owner',
  relationships___field_hero_video_thumbnail___internal___type = 'relationships___field_hero_video_thumbnail___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_hero_video?: Maybe<StringQueryOperatorInput>;
  field_hero_image?: Maybe<Field_Hero_ImageFilterInput>;
  field_hero_video_thumbnail?: Maybe<Field_Hero_Video_ThumbnailFilterInput>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_MobileFilterInput>;
  field_hero_button_text?: Maybe<StringQueryOperatorInput>;
  field_opisy_wykresow?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_text_3?: Maybe<Field_Text_3FilterInput>;
  field_text_4?: Maybe<Field_Text_4FilterInput>;
  relationships?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<
    Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalField_ComponentsFilterListInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFilterInput>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalGroupConnection = {
  __typename?: 'site_setting_entity__kasa_fiskalna_vs_kasoterminalGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalEdge>;
  nodes: Array<Site_Setting_Entity__Kasa_Fiskalna_Vs_Kasoterminal>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalRelationships = {
  __typename?: 'site_setting_entity__kasa_fiskalna_vs_kasoterminalRelationships';
  field_main_image?: Maybe<File__File>;
  field_components?: Maybe<Array<Maybe<VsTerminalRelatedComponents>>>;
  field_hero_image?: Maybe<File__File>;
  field_hero_image_mobile?: Maybe<File__File>;
  field_hero_video_thumbnail?: Maybe<File__File>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
  field_hero_image?: Maybe<File__FileFilterInput>;
  field_hero_image_mobile?: Maybe<File__FileFilterInput>;
  field_hero_video_thumbnail?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__kasa_fiskalna_vs_kasoterminalSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__Kasa_Fiskalna_Vs_KasoterminalFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Main_Page = Node & {
  __typename?: 'site_setting_entity__main_page';
  langcode?: Maybe<Scalars['String']>;
  field_mp_text_footer?: Maybe<Field_Mp_Text_Footer>;
  field_mp_text?: Maybe<Field_Mp_Text>;
  field_mp_button_text?: Maybe<Scalars['String']>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_btn_url?: Maybe<Scalars['String']>;
  field_settings_button_text?: Maybe<Scalars['String']>;
  field_mp_image_footer?: Maybe<Field_Mp_Image_Footer>;
  field_mp_image?: Maybe<Field_Mp_Image>;
  relationships?: Maybe<Site_Setting_Entity__Main_PageRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__Main_PageSite_Setting_Entity_Type>;
  field_components?: Maybe<Array<Maybe<Site_Setting_Entity__Main_PageField_Components>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__Main_PageCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Main_PageChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Main_PageContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Main_PageContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Main_PageConnection = {
  __typename?: 'site_setting_entity__main_pageConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Main_PageEdge>;
  nodes: Array<Site_Setting_Entity__Main_Page>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__Main_PageGroupConnection>;
};

export type Site_Setting_Entity__Main_PageConnectionDistinctArgs = {
  field: Site_Setting_Entity__Main_PageFieldsEnum;
};

export type Site_Setting_Entity__Main_PageConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__Main_PageFieldsEnum;
};

export type Site_Setting_Entity__Main_PageEdge = {
  __typename?: 'site_setting_entity__main_pageEdge';
  next?: Maybe<Site_Setting_Entity__Main_Page>;
  node: Site_Setting_Entity__Main_Page;
  previous?: Maybe<Site_Setting_Entity__Main_Page>;
};

export type Site_Setting_Entity__Main_PageField_Components = {
  __typename?: 'site_setting_entity__main_pageField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__Main_PageField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__Main_PageField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__Main_PageField_ComponentsFilterInput>;
};

export enum Site_Setting_Entity__Main_PageFieldsEnum {
  langcode = 'langcode',
  field_mp_text_footer___value = 'field_mp_text_footer___value',
  field_mp_text___value = 'field_mp_text___value',
  field_mp_button_text = 'field_mp_button_text',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  field_btn_url = 'field_btn_url',
  field_settings_button_text = 'field_settings_button_text',
  field_mp_image_footer___alt = 'field_mp_image_footer___alt',
  field_mp_image___alt = 'field_mp_image___alt',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  relationships___field_mp_image___localFile___birthtime = 'relationships___field_mp_image___localFile___birthtime',
  relationships___field_mp_image___localFile___birthtimeMs = 'relationships___field_mp_image___localFile___birthtimeMs',
  relationships___field_mp_image___localFile___publicURL = 'relationships___field_mp_image___localFile___publicURL',
  relationships___field_mp_image___localFile___sourceInstanceName = 'relationships___field_mp_image___localFile___sourceInstanceName',
  relationships___field_mp_image___localFile___absolutePath = 'relationships___field_mp_image___localFile___absolutePath',
  relationships___field_mp_image___localFile___relativePath = 'relationships___field_mp_image___localFile___relativePath',
  relationships___field_mp_image___localFile___extension = 'relationships___field_mp_image___localFile___extension',
  relationships___field_mp_image___localFile___size = 'relationships___field_mp_image___localFile___size',
  relationships___field_mp_image___localFile___prettySize = 'relationships___field_mp_image___localFile___prettySize',
  relationships___field_mp_image___localFile___modifiedTime = 'relationships___field_mp_image___localFile___modifiedTime',
  relationships___field_mp_image___localFile___accessTime = 'relationships___field_mp_image___localFile___accessTime',
  relationships___field_mp_image___localFile___changeTime = 'relationships___field_mp_image___localFile___changeTime',
  relationships___field_mp_image___localFile___birthTime = 'relationships___field_mp_image___localFile___birthTime',
  relationships___field_mp_image___localFile___root = 'relationships___field_mp_image___localFile___root',
  relationships___field_mp_image___localFile___dir = 'relationships___field_mp_image___localFile___dir',
  relationships___field_mp_image___localFile___base = 'relationships___field_mp_image___localFile___base',
  relationships___field_mp_image___localFile___ext = 'relationships___field_mp_image___localFile___ext',
  relationships___field_mp_image___localFile___name = 'relationships___field_mp_image___localFile___name',
  relationships___field_mp_image___localFile___relativeDirectory = 'relationships___field_mp_image___localFile___relativeDirectory',
  relationships___field_mp_image___localFile___dev = 'relationships___field_mp_image___localFile___dev',
  relationships___field_mp_image___localFile___mode = 'relationships___field_mp_image___localFile___mode',
  relationships___field_mp_image___localFile___nlink = 'relationships___field_mp_image___localFile___nlink',
  relationships___field_mp_image___localFile___uid = 'relationships___field_mp_image___localFile___uid',
  relationships___field_mp_image___localFile___gid = 'relationships___field_mp_image___localFile___gid',
  relationships___field_mp_image___localFile___rdev = 'relationships___field_mp_image___localFile___rdev',
  relationships___field_mp_image___localFile___blksize = 'relationships___field_mp_image___localFile___blksize',
  relationships___field_mp_image___localFile___ino = 'relationships___field_mp_image___localFile___ino',
  relationships___field_mp_image___localFile___blocks = 'relationships___field_mp_image___localFile___blocks',
  relationships___field_mp_image___localFile___atimeMs = 'relationships___field_mp_image___localFile___atimeMs',
  relationships___field_mp_image___localFile___mtimeMs = 'relationships___field_mp_image___localFile___mtimeMs',
  relationships___field_mp_image___localFile___ctimeMs = 'relationships___field_mp_image___localFile___ctimeMs',
  relationships___field_mp_image___localFile___atime = 'relationships___field_mp_image___localFile___atime',
  relationships___field_mp_image___localFile___mtime = 'relationships___field_mp_image___localFile___mtime',
  relationships___field_mp_image___localFile___ctime = 'relationships___field_mp_image___localFile___ctime',
  relationships___field_mp_image___localFile___url = 'relationships___field_mp_image___localFile___url',
  relationships___field_mp_image___localFile___id = 'relationships___field_mp_image___localFile___id',
  relationships___field_mp_image___localFile___children = 'relationships___field_mp_image___localFile___children',
  relationships___field_mp_image___uri___url = 'relationships___field_mp_image___uri___url',
  relationships___field_mp_image___drupal_id = 'relationships___field_mp_image___drupal_id',
  relationships___field_mp_image___drupal_internal__fid = 'relationships___field_mp_image___drupal_internal__fid',
  relationships___field_mp_image___langcode = 'relationships___field_mp_image___langcode',
  relationships___field_mp_image___filename = 'relationships___field_mp_image___filename',
  relationships___field_mp_image___filemime = 'relationships___field_mp_image___filemime',
  relationships___field_mp_image___filesize = 'relationships___field_mp_image___filesize',
  relationships___field_mp_image___status = 'relationships___field_mp_image___status',
  relationships___field_mp_image___created = 'relationships___field_mp_image___created',
  relationships___field_mp_image___changed = 'relationships___field_mp_image___changed',
  relationships___field_mp_image___uuid = 'relationships___field_mp_image___uuid',
  relationships___field_mp_image___relationships___node__blog_post = 'relationships___field_mp_image___relationships___node__blog_post',
  relationships___field_mp_image___relationships___node__devices = 'relationships___field_mp_image___relationships___node__devices',
  relationships___field_mp_image___relationships___node__accessories = 'relationships___field_mp_image___relationships___node__accessories',
  relationships___field_mp_image___relationships___paragraph__news_image_title_text = 'relationships___field_mp_image___relationships___paragraph__news_image_title_text',
  relationships___field_mp_image___relationships___node__cases = 'relationships___field_mp_image___relationships___node__cases',
  relationships___field_mp_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_mp_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_mp_image___relationships___paragraph__branches_features = 'relationships___field_mp_image___relationships___paragraph__branches_features',
  relationships___field_mp_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_mp_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_mp_image___relationships___paragraph__branches_testimonials = 'relationships___field_mp_image___relationships___paragraph__branches_testimonials',
  relationships___field_mp_image___relationships___paragraph__branches_discounts = 'relationships___field_mp_image___relationships___paragraph__branches_discounts',
  relationships___field_mp_image___relationships___node__news = 'relationships___field_mp_image___relationships___node__news',
  relationships___field_mp_image___relationships___node__branches = 'relationships___field_mp_image___relationships___node__branches',
  relationships___field_mp_image___relationships___paragraph__branches_hero = 'relationships___field_mp_image___relationships___paragraph__branches_hero',
  relationships___field_mp_image___relationships___paragraph__bullets = 'relationships___field_mp_image___relationships___paragraph__bullets',
  relationships___field_mp_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_mp_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_mp_image___relationships___paragraph__branches_device = 'relationships___field_mp_image___relationships___paragraph__branches_device',
  relationships___field_mp_image___relationships___paragraph__branches_recommendation = 'relationships___field_mp_image___relationships___paragraph__branches_recommendation',
  relationships___field_mp_image___relationships___site_setting_entity__accessories = 'relationships___field_mp_image___relationships___site_setting_entity__accessories',
  relationships___field_mp_image___relationships___site_setting_entity__branches = 'relationships___field_mp_image___relationships___site_setting_entity__branches',
  relationships___field_mp_image___relationships___site_setting_entity__about_us = 'relationships___field_mp_image___relationships___site_setting_entity__about_us',
  relationships___field_mp_image___relationships___paragraph__logos = 'relationships___field_mp_image___relationships___paragraph__logos',
  relationships___field_mp_image___relationships___paragraph__how_it_works = 'relationships___field_mp_image___relationships___paragraph__how_it_works',
  relationships___field_mp_image___relationships___paragraph__ico_and_text = 'relationships___field_mp_image___relationships___paragraph__ico_and_text',
  relationships___field_mp_image___relationships___paragraph__hero_1 = 'relationships___field_mp_image___relationships___paragraph__hero_1',
  relationships___field_mp_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_mp_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_mp_image___relationships___site_setting_entity__product = 'relationships___field_mp_image___relationships___site_setting_entity__product',
  relationships___field_mp_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_mp_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_mp_image___relationships___paragraph__porownanie_terminali = 'relationships___field_mp_image___relationships___paragraph__porownanie_terminali',
  relationships___field_mp_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_mp_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_mp_image___relationships___site_setting_entity__main_page = 'relationships___field_mp_image___relationships___site_setting_entity__main_page',
  relationships___field_mp_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_mp_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_mp_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_mp_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_mp_image___relationships___site_setting_entity__intercars = 'relationships___field_mp_image___relationships___site_setting_entity__intercars',
  relationships___field_mp_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_mp_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_mp_image___relationships___node__agreement = 'relationships___field_mp_image___relationships___node__agreement',
  relationships___field_mp_image___relationships___node__fiscalization = 'relationships___field_mp_image___relationships___node__fiscalization',
  relationships___field_mp_image___relationships___site_setting_entity__configurator = 'relationships___field_mp_image___relationships___site_setting_entity__configurator',
  relationships___field_mp_image___relationships___paragraph__social_link = 'relationships___field_mp_image___relationships___paragraph__social_link',
  relationships___field_mp_image___relationships___paragraph__make_an_appointment = 'relationships___field_mp_image___relationships___paragraph__make_an_appointment',
  relationships___field_mp_image___relationships___paragraph__linked_icons = 'relationships___field_mp_image___relationships___paragraph__linked_icons',
  relationships___field_mp_image___relationships___site_setting_entity__financing = 'relationships___field_mp_image___relationships___site_setting_entity__financing',
  relationships___field_mp_image___relationships___site_setting_entity__franchise = 'relationships___field_mp_image___relationships___site_setting_entity__franchise',
  relationships___field_mp_image___id = 'relationships___field_mp_image___id',
  relationships___field_mp_image___parent___id = 'relationships___field_mp_image___parent___id',
  relationships___field_mp_image___parent___children = 'relationships___field_mp_image___parent___children',
  relationships___field_mp_image___children = 'relationships___field_mp_image___children',
  relationships___field_mp_image___children___id = 'relationships___field_mp_image___children___id',
  relationships___field_mp_image___children___children = 'relationships___field_mp_image___children___children',
  relationships___field_mp_image___internal___content = 'relationships___field_mp_image___internal___content',
  relationships___field_mp_image___internal___contentDigest = 'relationships___field_mp_image___internal___contentDigest',
  relationships___field_mp_image___internal___description = 'relationships___field_mp_image___internal___description',
  relationships___field_mp_image___internal___fieldOwners = 'relationships___field_mp_image___internal___fieldOwners',
  relationships___field_mp_image___internal___ignoreType = 'relationships___field_mp_image___internal___ignoreType',
  relationships___field_mp_image___internal___mediaType = 'relationships___field_mp_image___internal___mediaType',
  relationships___field_mp_image___internal___owner = 'relationships___field_mp_image___internal___owner',
  relationships___field_mp_image___internal___type = 'relationships___field_mp_image___internal___type',
  relationships___field_mp_image_footer___localFile___birthtime = 'relationships___field_mp_image_footer___localFile___birthtime',
  relationships___field_mp_image_footer___localFile___birthtimeMs = 'relationships___field_mp_image_footer___localFile___birthtimeMs',
  relationships___field_mp_image_footer___localFile___publicURL = 'relationships___field_mp_image_footer___localFile___publicURL',
  relationships___field_mp_image_footer___localFile___sourceInstanceName = 'relationships___field_mp_image_footer___localFile___sourceInstanceName',
  relationships___field_mp_image_footer___localFile___absolutePath = 'relationships___field_mp_image_footer___localFile___absolutePath',
  relationships___field_mp_image_footer___localFile___relativePath = 'relationships___field_mp_image_footer___localFile___relativePath',
  relationships___field_mp_image_footer___localFile___extension = 'relationships___field_mp_image_footer___localFile___extension',
  relationships___field_mp_image_footer___localFile___size = 'relationships___field_mp_image_footer___localFile___size',
  relationships___field_mp_image_footer___localFile___prettySize = 'relationships___field_mp_image_footer___localFile___prettySize',
  relationships___field_mp_image_footer___localFile___modifiedTime = 'relationships___field_mp_image_footer___localFile___modifiedTime',
  relationships___field_mp_image_footer___localFile___accessTime = 'relationships___field_mp_image_footer___localFile___accessTime',
  relationships___field_mp_image_footer___localFile___changeTime = 'relationships___field_mp_image_footer___localFile___changeTime',
  relationships___field_mp_image_footer___localFile___birthTime = 'relationships___field_mp_image_footer___localFile___birthTime',
  relationships___field_mp_image_footer___localFile___root = 'relationships___field_mp_image_footer___localFile___root',
  relationships___field_mp_image_footer___localFile___dir = 'relationships___field_mp_image_footer___localFile___dir',
  relationships___field_mp_image_footer___localFile___base = 'relationships___field_mp_image_footer___localFile___base',
  relationships___field_mp_image_footer___localFile___ext = 'relationships___field_mp_image_footer___localFile___ext',
  relationships___field_mp_image_footer___localFile___name = 'relationships___field_mp_image_footer___localFile___name',
  relationships___field_mp_image_footer___localFile___relativeDirectory = 'relationships___field_mp_image_footer___localFile___relativeDirectory',
  relationships___field_mp_image_footer___localFile___dev = 'relationships___field_mp_image_footer___localFile___dev',
  relationships___field_mp_image_footer___localFile___mode = 'relationships___field_mp_image_footer___localFile___mode',
  relationships___field_mp_image_footer___localFile___nlink = 'relationships___field_mp_image_footer___localFile___nlink',
  relationships___field_mp_image_footer___localFile___uid = 'relationships___field_mp_image_footer___localFile___uid',
  relationships___field_mp_image_footer___localFile___gid = 'relationships___field_mp_image_footer___localFile___gid',
  relationships___field_mp_image_footer___localFile___rdev = 'relationships___field_mp_image_footer___localFile___rdev',
  relationships___field_mp_image_footer___localFile___blksize = 'relationships___field_mp_image_footer___localFile___blksize',
  relationships___field_mp_image_footer___localFile___ino = 'relationships___field_mp_image_footer___localFile___ino',
  relationships___field_mp_image_footer___localFile___blocks = 'relationships___field_mp_image_footer___localFile___blocks',
  relationships___field_mp_image_footer___localFile___atimeMs = 'relationships___field_mp_image_footer___localFile___atimeMs',
  relationships___field_mp_image_footer___localFile___mtimeMs = 'relationships___field_mp_image_footer___localFile___mtimeMs',
  relationships___field_mp_image_footer___localFile___ctimeMs = 'relationships___field_mp_image_footer___localFile___ctimeMs',
  relationships___field_mp_image_footer___localFile___atime = 'relationships___field_mp_image_footer___localFile___atime',
  relationships___field_mp_image_footer___localFile___mtime = 'relationships___field_mp_image_footer___localFile___mtime',
  relationships___field_mp_image_footer___localFile___ctime = 'relationships___field_mp_image_footer___localFile___ctime',
  relationships___field_mp_image_footer___localFile___url = 'relationships___field_mp_image_footer___localFile___url',
  relationships___field_mp_image_footer___localFile___id = 'relationships___field_mp_image_footer___localFile___id',
  relationships___field_mp_image_footer___localFile___children = 'relationships___field_mp_image_footer___localFile___children',
  relationships___field_mp_image_footer___uri___url = 'relationships___field_mp_image_footer___uri___url',
  relationships___field_mp_image_footer___drupal_id = 'relationships___field_mp_image_footer___drupal_id',
  relationships___field_mp_image_footer___drupal_internal__fid = 'relationships___field_mp_image_footer___drupal_internal__fid',
  relationships___field_mp_image_footer___langcode = 'relationships___field_mp_image_footer___langcode',
  relationships___field_mp_image_footer___filename = 'relationships___field_mp_image_footer___filename',
  relationships___field_mp_image_footer___filemime = 'relationships___field_mp_image_footer___filemime',
  relationships___field_mp_image_footer___filesize = 'relationships___field_mp_image_footer___filesize',
  relationships___field_mp_image_footer___status = 'relationships___field_mp_image_footer___status',
  relationships___field_mp_image_footer___created = 'relationships___field_mp_image_footer___created',
  relationships___field_mp_image_footer___changed = 'relationships___field_mp_image_footer___changed',
  relationships___field_mp_image_footer___uuid = 'relationships___field_mp_image_footer___uuid',
  relationships___field_mp_image_footer___relationships___node__blog_post = 'relationships___field_mp_image_footer___relationships___node__blog_post',
  relationships___field_mp_image_footer___relationships___node__devices = 'relationships___field_mp_image_footer___relationships___node__devices',
  relationships___field_mp_image_footer___relationships___node__accessories = 'relationships___field_mp_image_footer___relationships___node__accessories',
  relationships___field_mp_image_footer___relationships___paragraph__news_image_title_text = 'relationships___field_mp_image_footer___relationships___paragraph__news_image_title_text',
  relationships___field_mp_image_footer___relationships___node__cases = 'relationships___field_mp_image_footer___relationships___node__cases',
  relationships___field_mp_image_footer___relationships___paragraph__branches_what_is_ipos = 'relationships___field_mp_image_footer___relationships___paragraph__branches_what_is_ipos',
  relationships___field_mp_image_footer___relationships___paragraph__branches_features = 'relationships___field_mp_image_footer___relationships___paragraph__branches_features',
  relationships___field_mp_image_footer___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_mp_image_footer___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_mp_image_footer___relationships___paragraph__branches_testimonials = 'relationships___field_mp_image_footer___relationships___paragraph__branches_testimonials',
  relationships___field_mp_image_footer___relationships___paragraph__branches_discounts = 'relationships___field_mp_image_footer___relationships___paragraph__branches_discounts',
  relationships___field_mp_image_footer___relationships___node__news = 'relationships___field_mp_image_footer___relationships___node__news',
  relationships___field_mp_image_footer___relationships___node__branches = 'relationships___field_mp_image_footer___relationships___node__branches',
  relationships___field_mp_image_footer___relationships___paragraph__branches_hero = 'relationships___field_mp_image_footer___relationships___paragraph__branches_hero',
  relationships___field_mp_image_footer___relationships___paragraph__bullets = 'relationships___field_mp_image_footer___relationships___paragraph__bullets',
  relationships___field_mp_image_footer___relationships___taxonomy_term__pricelist_group = 'relationships___field_mp_image_footer___relationships___taxonomy_term__pricelist_group',
  relationships___field_mp_image_footer___relationships___paragraph__branches_device = 'relationships___field_mp_image_footer___relationships___paragraph__branches_device',
  relationships___field_mp_image_footer___relationships___paragraph__branches_recommendation = 'relationships___field_mp_image_footer___relationships___paragraph__branches_recommendation',
  relationships___field_mp_image_footer___relationships___site_setting_entity__accessories = 'relationships___field_mp_image_footer___relationships___site_setting_entity__accessories',
  relationships___field_mp_image_footer___relationships___site_setting_entity__branches = 'relationships___field_mp_image_footer___relationships___site_setting_entity__branches',
  relationships___field_mp_image_footer___relationships___site_setting_entity__about_us = 'relationships___field_mp_image_footer___relationships___site_setting_entity__about_us',
  relationships___field_mp_image_footer___relationships___paragraph__logos = 'relationships___field_mp_image_footer___relationships___paragraph__logos',
  relationships___field_mp_image_footer___relationships___paragraph__how_it_works = 'relationships___field_mp_image_footer___relationships___paragraph__how_it_works',
  relationships___field_mp_image_footer___relationships___paragraph__ico_and_text = 'relationships___field_mp_image_footer___relationships___paragraph__ico_and_text',
  relationships___field_mp_image_footer___relationships___paragraph__hero_1 = 'relationships___field_mp_image_footer___relationships___paragraph__hero_1',
  relationships___field_mp_image_footer___relationships___paragraph__comprehensive_solution_item = 'relationships___field_mp_image_footer___relationships___paragraph__comprehensive_solution_item',
  relationships___field_mp_image_footer___relationships___site_setting_entity__product = 'relationships___field_mp_image_footer___relationships___site_setting_entity__product',
  relationships___field_mp_image_footer___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_mp_image_footer___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_mp_image_footer___relationships___paragraph__porownanie_terminali = 'relationships___field_mp_image_footer___relationships___paragraph__porownanie_terminali',
  relationships___field_mp_image_footer___relationships___paragraph__zdjecie_opis_link = 'relationships___field_mp_image_footer___relationships___paragraph__zdjecie_opis_link',
  relationships___field_mp_image_footer___relationships___site_setting_entity__main_page = 'relationships___field_mp_image_footer___relationships___site_setting_entity__main_page',
  relationships___field_mp_image_footer___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_mp_image_footer___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_mp_image_footer___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_mp_image_footer___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_mp_image_footer___relationships___site_setting_entity__intercars = 'relationships___field_mp_image_footer___relationships___site_setting_entity__intercars',
  relationships___field_mp_image_footer___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_mp_image_footer___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_mp_image_footer___relationships___node__agreement = 'relationships___field_mp_image_footer___relationships___node__agreement',
  relationships___field_mp_image_footer___relationships___node__fiscalization = 'relationships___field_mp_image_footer___relationships___node__fiscalization',
  relationships___field_mp_image_footer___relationships___site_setting_entity__configurator = 'relationships___field_mp_image_footer___relationships___site_setting_entity__configurator',
  relationships___field_mp_image_footer___relationships___paragraph__social_link = 'relationships___field_mp_image_footer___relationships___paragraph__social_link',
  relationships___field_mp_image_footer___relationships___paragraph__make_an_appointment = 'relationships___field_mp_image_footer___relationships___paragraph__make_an_appointment',
  relationships___field_mp_image_footer___relationships___paragraph__linked_icons = 'relationships___field_mp_image_footer___relationships___paragraph__linked_icons',
  relationships___field_mp_image_footer___relationships___site_setting_entity__financing = 'relationships___field_mp_image_footer___relationships___site_setting_entity__financing',
  relationships___field_mp_image_footer___relationships___site_setting_entity__franchise = 'relationships___field_mp_image_footer___relationships___site_setting_entity__franchise',
  relationships___field_mp_image_footer___id = 'relationships___field_mp_image_footer___id',
  relationships___field_mp_image_footer___parent___id = 'relationships___field_mp_image_footer___parent___id',
  relationships___field_mp_image_footer___parent___children = 'relationships___field_mp_image_footer___parent___children',
  relationships___field_mp_image_footer___children = 'relationships___field_mp_image_footer___children',
  relationships___field_mp_image_footer___children___id = 'relationships___field_mp_image_footer___children___id',
  relationships___field_mp_image_footer___children___children = 'relationships___field_mp_image_footer___children___children',
  relationships___field_mp_image_footer___internal___content = 'relationships___field_mp_image_footer___internal___content',
  relationships___field_mp_image_footer___internal___contentDigest = 'relationships___field_mp_image_footer___internal___contentDigest',
  relationships___field_mp_image_footer___internal___description = 'relationships___field_mp_image_footer___internal___description',
  relationships___field_mp_image_footer___internal___fieldOwners = 'relationships___field_mp_image_footer___internal___fieldOwners',
  relationships___field_mp_image_footer___internal___ignoreType = 'relationships___field_mp_image_footer___internal___ignoreType',
  relationships___field_mp_image_footer___internal___mediaType = 'relationships___field_mp_image_footer___internal___mediaType',
  relationships___field_mp_image_footer___internal___owner = 'relationships___field_mp_image_footer___internal___owner',
  relationships___field_mp_image_footer___internal___type = 'relationships___field_mp_image_footer___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__Main_PageFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_mp_text_footer?: Maybe<Field_Mp_Text_FooterFilterInput>;
  field_mp_text?: Maybe<Field_Mp_TextFilterInput>;
  field_mp_button_text?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_btn_url?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_mp_image_footer?: Maybe<Field_Mp_Image_FooterFilterInput>;
  field_mp_image?: Maybe<Field_Mp_ImageFilterInput>;
  relationships?: Maybe<Site_Setting_Entity__Main_PageRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Main_PageSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__Main_PageField_ComponentsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__Main_PageFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__Main_PageFilterInput>;
};

export type Site_Setting_Entity__Main_PageGroupConnection = {
  __typename?: 'site_setting_entity__main_pageGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Main_PageEdge>;
  nodes: Array<Site_Setting_Entity__Main_Page>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Main_PageRelationships = {
  __typename?: 'site_setting_entity__main_pageRelationships';
  field_main_image?: Maybe<File__File>;
  field_components?: Maybe<Array<Maybe<HomepageRelatedComponents>>>;
  field_mp_image?: Maybe<File__File>;
  field_mp_image_footer?: Maybe<File__File>;
};

export type Site_Setting_Entity__Main_PageRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
  field_mp_image?: Maybe<File__FileFilterInput>;
  field_mp_image_footer?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__Main_PageSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__main_pageSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Main_PageSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Main_PageSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__Main_PageFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Menu = Node & {
  __typename?: 'site_setting_entity__menu';
  field_settings_text_1?: Maybe<Scalars['String']>;
  field_settings_text_2?: Maybe<Scalars['String']>;
  field_settings_text_3?: Maybe<Scalars['String']>;
  field_settings_text_4?: Maybe<Scalars['String']>;
  field_settings_text_5?: Maybe<Scalars['String']>;
  field_settings_text_6?: Maybe<Scalars['String']>;
  field_settings_text_7?: Maybe<Scalars['String']>;
  field_settings_tresc?: Maybe<Field_Settings_Tresc>;
  langcode?: Maybe<Scalars['String']>;
  field_optional_label?: Maybe<Scalars['String']>;
  field_optional_url?: Maybe<Scalars['String']>;
  relationships?: Maybe<Site_Setting_Entity__MenuRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__MenuSite_Setting_Entity_Type>;
  field_menu_icons_belt?: Maybe<Site_Setting_Entity__MenuField_Menu_Icons_Belt>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__MenuCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__MenuChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__MenuContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__MenuContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__MenuConnection = {
  __typename?: 'site_setting_entity__menuConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__MenuEdge>;
  nodes: Array<Site_Setting_Entity__Menu>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__MenuGroupConnection>;
};

export type Site_Setting_Entity__MenuConnectionDistinctArgs = {
  field: Site_Setting_Entity__MenuFieldsEnum;
};

export type Site_Setting_Entity__MenuConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__MenuFieldsEnum;
};

export type Site_Setting_Entity__MenuEdge = {
  __typename?: 'site_setting_entity__menuEdge';
  next?: Maybe<Site_Setting_Entity__Menu>;
  node: Site_Setting_Entity__Menu;
  previous?: Maybe<Site_Setting_Entity__Menu>;
};

export type Site_Setting_Entity__MenuField_Menu_Icons_Belt = {
  __typename?: 'site_setting_entity__menuField_menu_icons_belt';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__MenuField_Menu_Icons_BeltFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Site_Setting_Entity__MenuFieldsEnum {
  field_settings_text_1 = 'field_settings_text_1',
  field_settings_text_2 = 'field_settings_text_2',
  field_settings_text_3 = 'field_settings_text_3',
  field_settings_text_4 = 'field_settings_text_4',
  field_settings_text_5 = 'field_settings_text_5',
  field_settings_text_6 = 'field_settings_text_6',
  field_settings_text_7 = 'field_settings_text_7',
  field_settings_tresc___value = 'field_settings_tresc___value',
  langcode = 'langcode',
  field_optional_label = 'field_optional_label',
  field_optional_url = 'field_optional_url',
  relationships___field_menu_icons_belt___id = 'relationships___field_menu_icons_belt___id',
  relationships___field_menu_icons_belt___internal___content = 'relationships___field_menu_icons_belt___internal___content',
  relationships___field_menu_icons_belt___internal___contentDigest = 'relationships___field_menu_icons_belt___internal___contentDigest',
  relationships___field_menu_icons_belt___internal___description = 'relationships___field_menu_icons_belt___internal___description',
  relationships___field_menu_icons_belt___internal___fieldOwners = 'relationships___field_menu_icons_belt___internal___fieldOwners',
  relationships___field_menu_icons_belt___internal___ignoreType = 'relationships___field_menu_icons_belt___internal___ignoreType',
  relationships___field_menu_icons_belt___internal___mediaType = 'relationships___field_menu_icons_belt___internal___mediaType',
  relationships___field_menu_icons_belt___internal___owner = 'relationships___field_menu_icons_belt___internal___owner',
  relationships___field_menu_icons_belt___internal___type = 'relationships___field_menu_icons_belt___internal___type',
  relationships___field_menu_icons_belt___drupal_id = 'relationships___field_menu_icons_belt___drupal_id',
  relationships___field_menu_icons_belt___field_icons_belt_enabled = 'relationships___field_menu_icons_belt___field_icons_belt_enabled',
  relationships___field_menu_icons_belt___field_icons_belt_heading = 'relationships___field_menu_icons_belt___field_icons_belt_heading',
  relationships___field_menu_icons_belt___field_icons_belt_show_urls = 'relationships___field_menu_icons_belt___field_icons_belt_show_urls',
  relationships___field_menu_icons_belt___relationships___field_icons_belt_icons = 'relationships___field_menu_icons_belt___relationships___field_icons_belt_icons',
  relationships___field_menu_icons_belt___relationships___site_setting_entity__menu = 'relationships___field_menu_icons_belt___relationships___site_setting_entity__menu',
  relationships___field_menu_icons_belt___drupal_internal__id = 'relationships___field_menu_icons_belt___drupal_internal__id',
  relationships___field_menu_icons_belt___drupal_internal__revision_id = 'relationships___field_menu_icons_belt___drupal_internal__revision_id',
  relationships___field_menu_icons_belt___langcode = 'relationships___field_menu_icons_belt___langcode',
  relationships___field_menu_icons_belt___status = 'relationships___field_menu_icons_belt___status',
  relationships___field_menu_icons_belt___created = 'relationships___field_menu_icons_belt___created',
  relationships___field_menu_icons_belt___parent_id = 'relationships___field_menu_icons_belt___parent_id',
  relationships___field_menu_icons_belt___parent_type = 'relationships___field_menu_icons_belt___parent_type',
  relationships___field_menu_icons_belt___parent_field_name = 'relationships___field_menu_icons_belt___parent_field_name',
  relationships___field_menu_icons_belt___default_langcode = 'relationships___field_menu_icons_belt___default_langcode',
  relationships___field_menu_icons_belt___revision_translation_affected = 'relationships___field_menu_icons_belt___revision_translation_affected',
  relationships___field_menu_icons_belt___content_translation_source = 'relationships___field_menu_icons_belt___content_translation_source',
  relationships___field_menu_icons_belt___content_translation_outdated = 'relationships___field_menu_icons_belt___content_translation_outdated',
  relationships___field_menu_icons_belt___content_translation_changed = 'relationships___field_menu_icons_belt___content_translation_changed',
  relationships___field_menu_icons_belt___uuid = 'relationships___field_menu_icons_belt___uuid',
  relationships___field_menu_icons_belt___field_icons_belt_icons = 'relationships___field_menu_icons_belt___field_icons_belt_icons',
  relationships___field_menu_icons_belt___field_icons_belt_icons___target_revision_id = 'relationships___field_menu_icons_belt___field_icons_belt_icons___target_revision_id',
  relationships___field_menu_icons_belt___field_icons_belt_icons___drupal_internal__target_id = 'relationships___field_menu_icons_belt___field_icons_belt_icons___drupal_internal__target_id',
  relationships___field_menu_icons_belt___parent___id = 'relationships___field_menu_icons_belt___parent___id',
  relationships___field_menu_icons_belt___parent___children = 'relationships___field_menu_icons_belt___parent___children',
  relationships___field_menu_icons_belt___children = 'relationships___field_menu_icons_belt___children',
  relationships___field_menu_icons_belt___children___id = 'relationships___field_menu_icons_belt___children___id',
  relationships___field_menu_icons_belt___children___children = 'relationships___field_menu_icons_belt___children___children',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_menu_icons_belt___target_revision_id = 'field_menu_icons_belt___target_revision_id',
  field_menu_icons_belt___drupal_internal__target_id = 'field_menu_icons_belt___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__MenuFilterInput = {
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_settings_text_7?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  field_optional_label?: Maybe<StringQueryOperatorInput>;
  field_optional_url?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__MenuRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__MenuSite_Setting_Entity_TypeFilterInput>;
  field_menu_icons_belt?: Maybe<Site_Setting_Entity__MenuField_Menu_Icons_BeltFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__MenuFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__MenuFilterInput>;
};

export type Site_Setting_Entity__MenuGroupConnection = {
  __typename?: 'site_setting_entity__menuGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__MenuEdge>;
  nodes: Array<Site_Setting_Entity__Menu>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__MenuRelationships = {
  __typename?: 'site_setting_entity__menuRelationships';
  field_menu_icons_belt?: Maybe<Paragraph__Icons_Belt>;
};

export type Site_Setting_Entity__MenuRelationshipsFilterInput = {
  field_menu_icons_belt?: Maybe<Paragraph__Icons_BeltFilterInput>;
};

export type Site_Setting_Entity__MenuSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__menuSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__MenuSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__MenuSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__MenuFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Panel_Cookies = Node & {
  __typename?: 'site_setting_entity__panel_cookies';
  langcode?: Maybe<Scalars['String']>;
  field_settings_tresc?: Maybe<Field_Settings_Tresc>;
  field_settings_button_text?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__Panel_CookiesSite_Setting_Entity_Type>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__Panel_CookiesCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Panel_CookiesChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Panel_CookiesContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Panel_CookiesContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Panel_CookiesConnection = {
  __typename?: 'site_setting_entity__panel_cookiesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Panel_CookiesEdge>;
  nodes: Array<Site_Setting_Entity__Panel_Cookies>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__Panel_CookiesGroupConnection>;
};

export type Site_Setting_Entity__Panel_CookiesConnectionDistinctArgs = {
  field: Site_Setting_Entity__Panel_CookiesFieldsEnum;
};

export type Site_Setting_Entity__Panel_CookiesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__Panel_CookiesFieldsEnum;
};

export type Site_Setting_Entity__Panel_CookiesEdge = {
  __typename?: 'site_setting_entity__panel_cookiesEdge';
  next?: Maybe<Site_Setting_Entity__Panel_Cookies>;
  node: Site_Setting_Entity__Panel_Cookies;
  previous?: Maybe<Site_Setting_Entity__Panel_Cookies>;
};

export enum Site_Setting_Entity__Panel_CookiesFieldsEnum {
  langcode = 'langcode',
  field_settings_tresc___value = 'field_settings_tresc___value',
  field_settings_button_text = 'field_settings_button_text',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__Panel_CookiesFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Panel_CookiesSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__Panel_CookiesGroupConnection = {
  __typename?: 'site_setting_entity__panel_cookiesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Panel_CookiesEdge>;
  nodes: Array<Site_Setting_Entity__Panel_Cookies>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Panel_CookiesSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__panel_cookiesSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Panel_CookiesSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Panel_CookiesSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__Panel_CookiesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Price_List = Node & {
  __typename?: 'site_setting_entity__price_list';
  langcode?: Maybe<Scalars['String']>;
  field_pl_header_1?: Maybe<Field_Pl_Header_1>;
  field_pl_header_2?: Maybe<Field_Pl_Header_2>;
  field_pl_header_3?: Maybe<Field_Pl_Header_3>;
  field_pl_header_4?: Maybe<Field_Pl_Header_4>;
  field_footer_left?: Maybe<Field_Footer_Left>;
  field_footer_right?: Maybe<Field_Footer_Right>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_texts?: Maybe<Array<Maybe<Scalars['String']>>>;
  relationships?: Maybe<Site_Setting_Entity__Price_ListRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__Price_ListSite_Setting_Entity_Type>;
  field_components?: Maybe<Array<Maybe<Site_Setting_Entity__Price_ListField_Components>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__Price_ListCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Price_ListChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Price_ListContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Price_ListContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Price_ListConnection = {
  __typename?: 'site_setting_entity__price_listConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Price_ListEdge>;
  nodes: Array<Site_Setting_Entity__Price_List>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__Price_ListGroupConnection>;
};

export type Site_Setting_Entity__Price_ListConnectionDistinctArgs = {
  field: Site_Setting_Entity__Price_ListFieldsEnum;
};

export type Site_Setting_Entity__Price_ListConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__Price_ListFieldsEnum;
};

export type Site_Setting_Entity__Price_ListEdge = {
  __typename?: 'site_setting_entity__price_listEdge';
  next?: Maybe<Site_Setting_Entity__Price_List>;
  node: Site_Setting_Entity__Price_List;
  previous?: Maybe<Site_Setting_Entity__Price_List>;
};

export type Site_Setting_Entity__Price_ListField_Components = {
  __typename?: 'site_setting_entity__price_listField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__Price_ListField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__Price_ListField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__Price_ListField_ComponentsFilterInput>;
};

export enum Site_Setting_Entity__Price_ListFieldsEnum {
  langcode = 'langcode',
  field_pl_header_1___value = 'field_pl_header_1___value',
  field_pl_header_2___value = 'field_pl_header_2___value',
  field_pl_header_3___value = 'field_pl_header_3___value',
  field_pl_header_4___value = 'field_pl_header_4___value',
  field_footer_left___value = 'field_footer_left___value',
  field_footer_right___value = 'field_footer_right___value',
  field_meta_description = 'field_meta_description',
  field_meta_title = 'field_meta_title',
  field_texts = 'field_texts',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  relationships___field_components = 'relationships___field_components',
  relationships___field_components___id = 'relationships___field_components___id',
  relationships___field_components___internal___content = 'relationships___field_components___internal___content',
  relationships___field_components___internal___contentDigest = 'relationships___field_components___internal___contentDigest',
  relationships___field_components___internal___description = 'relationships___field_components___internal___description',
  relationships___field_components___internal___fieldOwners = 'relationships___field_components___internal___fieldOwners',
  relationships___field_components___internal___ignoreType = 'relationships___field_components___internal___ignoreType',
  relationships___field_components___internal___mediaType = 'relationships___field_components___internal___mediaType',
  relationships___field_components___internal___owner = 'relationships___field_components___internal___owner',
  relationships___field_components___internal___type = 'relationships___field_components___internal___type',
  relationships___field_components___drupal_id = 'relationships___field_components___drupal_id',
  relationships___field_components___parent_field_name = 'relationships___field_components___parent_field_name',
  relationships___field_components___field_hero_1_video = 'relationships___field_components___field_hero_1_video',
  relationships___field_components___field_branches_text___value = 'relationships___field_components___field_branches_text___value',
  relationships___field_components___field_paragraph_headline___value = 'relationships___field_components___field_paragraph_headline___value',
  relationships___field_components___field_branches_button_text = 'relationships___field_components___field_branches_button_text',
  relationships___field_components___field_branches_image___alt = 'relationships___field_components___field_branches_image___alt',
  relationships___field_components___field_branches_image_mobile___alt = 'relationships___field_components___field_branches_image_mobile___alt',
  relationships___field_components___field_video_thumbnail___alt = 'relationships___field_components___field_video_thumbnail___alt',
  relationships___field_components___relationships___node__branches = 'relationships___field_components___relationships___node__branches',
  relationships___field_components___relationships___site_setting_entity__price_list = 'relationships___field_components___relationships___site_setting_entity__price_list',
  relationships___field_components___relationships___site_setting_entity__financing = 'relationships___field_components___relationships___site_setting_entity__financing',
  relationships___field_components___relationships___site_setting_entity__franchise = 'relationships___field_components___relationships___site_setting_entity__franchise',
  relationships___field_components___relationships___node__pages = 'relationships___field_components___relationships___node__pages',
  relationships___field_components___drupal_internal__id = 'relationships___field_components___drupal_internal__id',
  relationships___field_components___status = 'relationships___field_components___status',
  relationships___field_components___created = 'relationships___field_components___created',
  relationships___field_components___parent_id = 'relationships___field_components___parent_id',
  relationships___field_components___parent_type = 'relationships___field_components___parent_type',
  relationships___field_components___content_translation_source = 'relationships___field_components___content_translation_source',
  relationships___field_components___content_translation_outdated = 'relationships___field_components___content_translation_outdated',
  relationships___field_components___content_translation_changed = 'relationships___field_components___content_translation_changed',
  relationships___field_components___langcode = 'relationships___field_components___langcode',
  relationships___field_components___uuid = 'relationships___field_components___uuid',
  relationships___field_components___field_branches_device_image___alt = 'relationships___field_components___field_branches_device_image___alt',
  relationships___field_components___field_branches_device_image___title = 'relationships___field_components___field_branches_device_image___title',
  relationships___field_components___field_branches_device_image___width = 'relationships___field_components___field_branches_device_image___width',
  relationships___field_components___field_branches_device_image___height = 'relationships___field_components___field_branches_device_image___height',
  relationships___field_components___field_branches_device_image___drupal_internal__target_id = 'relationships___field_components___field_branches_device_image___drupal_internal__target_id',
  relationships___field_components___parent___id = 'relationships___field_components___parent___id',
  relationships___field_components___parent___children = 'relationships___field_components___parent___children',
  relationships___field_components___children = 'relationships___field_components___children',
  relationships___field_components___children___id = 'relationships___field_components___children___id',
  relationships___field_components___children___children = 'relationships___field_components___children___children',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__Price_ListFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_pl_header_1?: Maybe<Field_Pl_Header_1FilterInput>;
  field_pl_header_2?: Maybe<Field_Pl_Header_2FilterInput>;
  field_pl_header_3?: Maybe<Field_Pl_Header_3FilterInput>;
  field_pl_header_4?: Maybe<Field_Pl_Header_4FilterInput>;
  field_footer_left?: Maybe<Field_Footer_LeftFilterInput>;
  field_footer_right?: Maybe<Field_Footer_RightFilterInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_texts?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__Price_ListRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Price_ListSite_Setting_Entity_TypeFilterInput
  >;
  field_components?: Maybe<Site_Setting_Entity__Price_ListField_ComponentsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__Price_ListFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__Price_ListFilterInput>;
};

export type Site_Setting_Entity__Price_ListGroupConnection = {
  __typename?: 'site_setting_entity__price_listGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Price_ListEdge>;
  nodes: Array<Site_Setting_Entity__Price_List>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Price_ListRelationships = {
  __typename?: 'site_setting_entity__price_listRelationships';
  field_main_image?: Maybe<File__File>;
  field_components?: Maybe<Array<Maybe<Paragraph__Branches_Hero>>>;
};

export type Site_Setting_Entity__Price_ListRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
  field_components?: Maybe<Paragraph__Branches_HeroFilterListInput>;
};

export type Site_Setting_Entity__Price_ListSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__price_listSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Price_ListSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Price_ListSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__Price_ListFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Product = Node & {
  __typename?: 'site_setting_entity__product';
  langcode?: Maybe<Scalars['String']>;
  field_hero_button_text?: Maybe<Scalars['String']>;
  field_hero_video?: Maybe<Scalars['String']>;
  field_hero_headline?: Maybe<Field_Hero_Headline>;
  field_hero_image?: Maybe<Field_Hero_Image>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_Mobile>;
  field_hero_text?: Maybe<Field_Hero_Text>;
  field_image_1?: Maybe<Field_Image_1>;
  field_image_2?: Maybe<Field_Image_2>;
  field_image_3?: Maybe<Field_Image_3>;
  field_hero_video_thumbnail?: Maybe<Field_Hero_Video_Thumbnail>;
  field_kasa_online?: Maybe<Scalars['Boolean']>;
  field_porownaj_taryfy_w_cenniku?: Maybe<Scalars['String']>;
  field_settings_text_2?: Maybe<Scalars['String']>;
  field_settings_text_6?: Maybe<Scalars['String']>;
  field_settings_text_7?: Maybe<Scalars['String']>;
  field_text_1?: Maybe<Field_Text_1>;
  field_text_2?: Maybe<Field_Text_2>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_meta_description?: Maybe<Scalars['String']>;
  relationships?: Maybe<Site_Setting_Entity__ProductRelationships>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__ProductSite_Setting_Entity_Type>;
  field_components?: Maybe<Array<Maybe<Site_Setting_Entity__ProductField_Components>>>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__ProductCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ProductChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ProductContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ProductContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ProductConnection = {
  __typename?: 'site_setting_entity__productConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__ProductEdge>;
  nodes: Array<Site_Setting_Entity__Product>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__ProductGroupConnection>;
};

export type Site_Setting_Entity__ProductConnectionDistinctArgs = {
  field: Site_Setting_Entity__ProductFieldsEnum;
};

export type Site_Setting_Entity__ProductConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__ProductFieldsEnum;
};

export type Site_Setting_Entity__ProductEdge = {
  __typename?: 'site_setting_entity__productEdge';
  next?: Maybe<Site_Setting_Entity__Product>;
  node: Site_Setting_Entity__Product;
  previous?: Maybe<Site_Setting_Entity__Product>;
};

export type Site_Setting_Entity__ProductField_Components = {
  __typename?: 'site_setting_entity__productField_components';
  target_revision_id?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Site_Setting_Entity__ProductField_ComponentsFilterInput = {
  target_revision_id?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export type Site_Setting_Entity__ProductField_ComponentsFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__ProductField_ComponentsFilterInput>;
};

export enum Site_Setting_Entity__ProductFieldsEnum {
  langcode = 'langcode',
  field_hero_button_text = 'field_hero_button_text',
  field_hero_video = 'field_hero_video',
  field_hero_headline___value = 'field_hero_headline___value',
  field_hero_image___alt = 'field_hero_image___alt',
  field_hero_image_mobile___alt = 'field_hero_image_mobile___alt',
  field_hero_text___value = 'field_hero_text___value',
  field_image_1___alt = 'field_image_1___alt',
  field_image_2___alt = 'field_image_2___alt',
  field_image_3___alt = 'field_image_3___alt',
  field_hero_video_thumbnail___alt = 'field_hero_video_thumbnail___alt',
  field_kasa_online = 'field_kasa_online',
  field_porownaj_taryfy_w_cenniku = 'field_porownaj_taryfy_w_cenniku',
  field_settings_text_2 = 'field_settings_text_2',
  field_settings_text_6 = 'field_settings_text_6',
  field_settings_text_7 = 'field_settings_text_7',
  field_text_1___value = 'field_text_1___value',
  field_text_2___value = 'field_text_2___value',
  field_meta_title = 'field_meta_title',
  field_meta_description = 'field_meta_description',
  relationships___field_main_image___localFile___birthtime = 'relationships___field_main_image___localFile___birthtime',
  relationships___field_main_image___localFile___birthtimeMs = 'relationships___field_main_image___localFile___birthtimeMs',
  relationships___field_main_image___localFile___publicURL = 'relationships___field_main_image___localFile___publicURL',
  relationships___field_main_image___localFile___sourceInstanceName = 'relationships___field_main_image___localFile___sourceInstanceName',
  relationships___field_main_image___localFile___absolutePath = 'relationships___field_main_image___localFile___absolutePath',
  relationships___field_main_image___localFile___relativePath = 'relationships___field_main_image___localFile___relativePath',
  relationships___field_main_image___localFile___extension = 'relationships___field_main_image___localFile___extension',
  relationships___field_main_image___localFile___size = 'relationships___field_main_image___localFile___size',
  relationships___field_main_image___localFile___prettySize = 'relationships___field_main_image___localFile___prettySize',
  relationships___field_main_image___localFile___modifiedTime = 'relationships___field_main_image___localFile___modifiedTime',
  relationships___field_main_image___localFile___accessTime = 'relationships___field_main_image___localFile___accessTime',
  relationships___field_main_image___localFile___changeTime = 'relationships___field_main_image___localFile___changeTime',
  relationships___field_main_image___localFile___birthTime = 'relationships___field_main_image___localFile___birthTime',
  relationships___field_main_image___localFile___root = 'relationships___field_main_image___localFile___root',
  relationships___field_main_image___localFile___dir = 'relationships___field_main_image___localFile___dir',
  relationships___field_main_image___localFile___base = 'relationships___field_main_image___localFile___base',
  relationships___field_main_image___localFile___ext = 'relationships___field_main_image___localFile___ext',
  relationships___field_main_image___localFile___name = 'relationships___field_main_image___localFile___name',
  relationships___field_main_image___localFile___relativeDirectory = 'relationships___field_main_image___localFile___relativeDirectory',
  relationships___field_main_image___localFile___dev = 'relationships___field_main_image___localFile___dev',
  relationships___field_main_image___localFile___mode = 'relationships___field_main_image___localFile___mode',
  relationships___field_main_image___localFile___nlink = 'relationships___field_main_image___localFile___nlink',
  relationships___field_main_image___localFile___uid = 'relationships___field_main_image___localFile___uid',
  relationships___field_main_image___localFile___gid = 'relationships___field_main_image___localFile___gid',
  relationships___field_main_image___localFile___rdev = 'relationships___field_main_image___localFile___rdev',
  relationships___field_main_image___localFile___blksize = 'relationships___field_main_image___localFile___blksize',
  relationships___field_main_image___localFile___ino = 'relationships___field_main_image___localFile___ino',
  relationships___field_main_image___localFile___blocks = 'relationships___field_main_image___localFile___blocks',
  relationships___field_main_image___localFile___atimeMs = 'relationships___field_main_image___localFile___atimeMs',
  relationships___field_main_image___localFile___mtimeMs = 'relationships___field_main_image___localFile___mtimeMs',
  relationships___field_main_image___localFile___ctimeMs = 'relationships___field_main_image___localFile___ctimeMs',
  relationships___field_main_image___localFile___atime = 'relationships___field_main_image___localFile___atime',
  relationships___field_main_image___localFile___mtime = 'relationships___field_main_image___localFile___mtime',
  relationships___field_main_image___localFile___ctime = 'relationships___field_main_image___localFile___ctime',
  relationships___field_main_image___localFile___url = 'relationships___field_main_image___localFile___url',
  relationships___field_main_image___localFile___id = 'relationships___field_main_image___localFile___id',
  relationships___field_main_image___localFile___children = 'relationships___field_main_image___localFile___children',
  relationships___field_main_image___uri___url = 'relationships___field_main_image___uri___url',
  relationships___field_main_image___drupal_id = 'relationships___field_main_image___drupal_id',
  relationships___field_main_image___drupal_internal__fid = 'relationships___field_main_image___drupal_internal__fid',
  relationships___field_main_image___langcode = 'relationships___field_main_image___langcode',
  relationships___field_main_image___filename = 'relationships___field_main_image___filename',
  relationships___field_main_image___filemime = 'relationships___field_main_image___filemime',
  relationships___field_main_image___filesize = 'relationships___field_main_image___filesize',
  relationships___field_main_image___status = 'relationships___field_main_image___status',
  relationships___field_main_image___created = 'relationships___field_main_image___created',
  relationships___field_main_image___changed = 'relationships___field_main_image___changed',
  relationships___field_main_image___uuid = 'relationships___field_main_image___uuid',
  relationships___field_main_image___relationships___node__blog_post = 'relationships___field_main_image___relationships___node__blog_post',
  relationships___field_main_image___relationships___node__devices = 'relationships___field_main_image___relationships___node__devices',
  relationships___field_main_image___relationships___node__accessories = 'relationships___field_main_image___relationships___node__accessories',
  relationships___field_main_image___relationships___paragraph__news_image_title_text = 'relationships___field_main_image___relationships___paragraph__news_image_title_text',
  relationships___field_main_image___relationships___node__cases = 'relationships___field_main_image___relationships___node__cases',
  relationships___field_main_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_main_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_main_image___relationships___paragraph__branches_features = 'relationships___field_main_image___relationships___paragraph__branches_features',
  relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_main_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_main_image___relationships___paragraph__branches_testimonials = 'relationships___field_main_image___relationships___paragraph__branches_testimonials',
  relationships___field_main_image___relationships___paragraph__branches_discounts = 'relationships___field_main_image___relationships___paragraph__branches_discounts',
  relationships___field_main_image___relationships___node__news = 'relationships___field_main_image___relationships___node__news',
  relationships___field_main_image___relationships___node__branches = 'relationships___field_main_image___relationships___node__branches',
  relationships___field_main_image___relationships___paragraph__branches_hero = 'relationships___field_main_image___relationships___paragraph__branches_hero',
  relationships___field_main_image___relationships___paragraph__bullets = 'relationships___field_main_image___relationships___paragraph__bullets',
  relationships___field_main_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_main_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_main_image___relationships___paragraph__branches_device = 'relationships___field_main_image___relationships___paragraph__branches_device',
  relationships___field_main_image___relationships___paragraph__branches_recommendation = 'relationships___field_main_image___relationships___paragraph__branches_recommendation',
  relationships___field_main_image___relationships___site_setting_entity__accessories = 'relationships___field_main_image___relationships___site_setting_entity__accessories',
  relationships___field_main_image___relationships___site_setting_entity__branches = 'relationships___field_main_image___relationships___site_setting_entity__branches',
  relationships___field_main_image___relationships___site_setting_entity__about_us = 'relationships___field_main_image___relationships___site_setting_entity__about_us',
  relationships___field_main_image___relationships___paragraph__logos = 'relationships___field_main_image___relationships___paragraph__logos',
  relationships___field_main_image___relationships___paragraph__how_it_works = 'relationships___field_main_image___relationships___paragraph__how_it_works',
  relationships___field_main_image___relationships___paragraph__ico_and_text = 'relationships___field_main_image___relationships___paragraph__ico_and_text',
  relationships___field_main_image___relationships___paragraph__hero_1 = 'relationships___field_main_image___relationships___paragraph__hero_1',
  relationships___field_main_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_main_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_main_image___relationships___site_setting_entity__product = 'relationships___field_main_image___relationships___site_setting_entity__product',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_main_image___relationships___paragraph__porownanie_terminali = 'relationships___field_main_image___relationships___paragraph__porownanie_terminali',
  relationships___field_main_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_main_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_main_image___relationships___site_setting_entity__main_page = 'relationships___field_main_image___relationships___site_setting_entity__main_page',
  relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_main_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_main_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_main_image___relationships___site_setting_entity__intercars = 'relationships___field_main_image___relationships___site_setting_entity__intercars',
  relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_main_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_main_image___relationships___node__agreement = 'relationships___field_main_image___relationships___node__agreement',
  relationships___field_main_image___relationships___node__fiscalization = 'relationships___field_main_image___relationships___node__fiscalization',
  relationships___field_main_image___relationships___site_setting_entity__configurator = 'relationships___field_main_image___relationships___site_setting_entity__configurator',
  relationships___field_main_image___relationships___paragraph__social_link = 'relationships___field_main_image___relationships___paragraph__social_link',
  relationships___field_main_image___relationships___paragraph__make_an_appointment = 'relationships___field_main_image___relationships___paragraph__make_an_appointment',
  relationships___field_main_image___relationships___paragraph__linked_icons = 'relationships___field_main_image___relationships___paragraph__linked_icons',
  relationships___field_main_image___relationships___site_setting_entity__financing = 'relationships___field_main_image___relationships___site_setting_entity__financing',
  relationships___field_main_image___relationships___site_setting_entity__franchise = 'relationships___field_main_image___relationships___site_setting_entity__franchise',
  relationships___field_main_image___id = 'relationships___field_main_image___id',
  relationships___field_main_image___parent___id = 'relationships___field_main_image___parent___id',
  relationships___field_main_image___parent___children = 'relationships___field_main_image___parent___children',
  relationships___field_main_image___children = 'relationships___field_main_image___children',
  relationships___field_main_image___children___id = 'relationships___field_main_image___children___id',
  relationships___field_main_image___children___children = 'relationships___field_main_image___children___children',
  relationships___field_main_image___internal___content = 'relationships___field_main_image___internal___content',
  relationships___field_main_image___internal___contentDigest = 'relationships___field_main_image___internal___contentDigest',
  relationships___field_main_image___internal___description = 'relationships___field_main_image___internal___description',
  relationships___field_main_image___internal___fieldOwners = 'relationships___field_main_image___internal___fieldOwners',
  relationships___field_main_image___internal___ignoreType = 'relationships___field_main_image___internal___ignoreType',
  relationships___field_main_image___internal___mediaType = 'relationships___field_main_image___internal___mediaType',
  relationships___field_main_image___internal___owner = 'relationships___field_main_image___internal___owner',
  relationships___field_main_image___internal___type = 'relationships___field_main_image___internal___type',
  relationships___field_hero_image___localFile___birthtime = 'relationships___field_hero_image___localFile___birthtime',
  relationships___field_hero_image___localFile___birthtimeMs = 'relationships___field_hero_image___localFile___birthtimeMs',
  relationships___field_hero_image___localFile___publicURL = 'relationships___field_hero_image___localFile___publicURL',
  relationships___field_hero_image___localFile___sourceInstanceName = 'relationships___field_hero_image___localFile___sourceInstanceName',
  relationships___field_hero_image___localFile___absolutePath = 'relationships___field_hero_image___localFile___absolutePath',
  relationships___field_hero_image___localFile___relativePath = 'relationships___field_hero_image___localFile___relativePath',
  relationships___field_hero_image___localFile___extension = 'relationships___field_hero_image___localFile___extension',
  relationships___field_hero_image___localFile___size = 'relationships___field_hero_image___localFile___size',
  relationships___field_hero_image___localFile___prettySize = 'relationships___field_hero_image___localFile___prettySize',
  relationships___field_hero_image___localFile___modifiedTime = 'relationships___field_hero_image___localFile___modifiedTime',
  relationships___field_hero_image___localFile___accessTime = 'relationships___field_hero_image___localFile___accessTime',
  relationships___field_hero_image___localFile___changeTime = 'relationships___field_hero_image___localFile___changeTime',
  relationships___field_hero_image___localFile___birthTime = 'relationships___field_hero_image___localFile___birthTime',
  relationships___field_hero_image___localFile___root = 'relationships___field_hero_image___localFile___root',
  relationships___field_hero_image___localFile___dir = 'relationships___field_hero_image___localFile___dir',
  relationships___field_hero_image___localFile___base = 'relationships___field_hero_image___localFile___base',
  relationships___field_hero_image___localFile___ext = 'relationships___field_hero_image___localFile___ext',
  relationships___field_hero_image___localFile___name = 'relationships___field_hero_image___localFile___name',
  relationships___field_hero_image___localFile___relativeDirectory = 'relationships___field_hero_image___localFile___relativeDirectory',
  relationships___field_hero_image___localFile___dev = 'relationships___field_hero_image___localFile___dev',
  relationships___field_hero_image___localFile___mode = 'relationships___field_hero_image___localFile___mode',
  relationships___field_hero_image___localFile___nlink = 'relationships___field_hero_image___localFile___nlink',
  relationships___field_hero_image___localFile___uid = 'relationships___field_hero_image___localFile___uid',
  relationships___field_hero_image___localFile___gid = 'relationships___field_hero_image___localFile___gid',
  relationships___field_hero_image___localFile___rdev = 'relationships___field_hero_image___localFile___rdev',
  relationships___field_hero_image___localFile___blksize = 'relationships___field_hero_image___localFile___blksize',
  relationships___field_hero_image___localFile___ino = 'relationships___field_hero_image___localFile___ino',
  relationships___field_hero_image___localFile___blocks = 'relationships___field_hero_image___localFile___blocks',
  relationships___field_hero_image___localFile___atimeMs = 'relationships___field_hero_image___localFile___atimeMs',
  relationships___field_hero_image___localFile___mtimeMs = 'relationships___field_hero_image___localFile___mtimeMs',
  relationships___field_hero_image___localFile___ctimeMs = 'relationships___field_hero_image___localFile___ctimeMs',
  relationships___field_hero_image___localFile___atime = 'relationships___field_hero_image___localFile___atime',
  relationships___field_hero_image___localFile___mtime = 'relationships___field_hero_image___localFile___mtime',
  relationships___field_hero_image___localFile___ctime = 'relationships___field_hero_image___localFile___ctime',
  relationships___field_hero_image___localFile___url = 'relationships___field_hero_image___localFile___url',
  relationships___field_hero_image___localFile___id = 'relationships___field_hero_image___localFile___id',
  relationships___field_hero_image___localFile___children = 'relationships___field_hero_image___localFile___children',
  relationships___field_hero_image___uri___url = 'relationships___field_hero_image___uri___url',
  relationships___field_hero_image___drupal_id = 'relationships___field_hero_image___drupal_id',
  relationships___field_hero_image___drupal_internal__fid = 'relationships___field_hero_image___drupal_internal__fid',
  relationships___field_hero_image___langcode = 'relationships___field_hero_image___langcode',
  relationships___field_hero_image___filename = 'relationships___field_hero_image___filename',
  relationships___field_hero_image___filemime = 'relationships___field_hero_image___filemime',
  relationships___field_hero_image___filesize = 'relationships___field_hero_image___filesize',
  relationships___field_hero_image___status = 'relationships___field_hero_image___status',
  relationships___field_hero_image___created = 'relationships___field_hero_image___created',
  relationships___field_hero_image___changed = 'relationships___field_hero_image___changed',
  relationships___field_hero_image___uuid = 'relationships___field_hero_image___uuid',
  relationships___field_hero_image___relationships___node__blog_post = 'relationships___field_hero_image___relationships___node__blog_post',
  relationships___field_hero_image___relationships___node__devices = 'relationships___field_hero_image___relationships___node__devices',
  relationships___field_hero_image___relationships___node__accessories = 'relationships___field_hero_image___relationships___node__accessories',
  relationships___field_hero_image___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image___relationships___node__cases = 'relationships___field_hero_image___relationships___node__cases',
  relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image___relationships___paragraph__branches_features = 'relationships___field_hero_image___relationships___paragraph__branches_features',
  relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image___relationships___paragraph__branches_discounts = 'relationships___field_hero_image___relationships___paragraph__branches_discounts',
  relationships___field_hero_image___relationships___node__news = 'relationships___field_hero_image___relationships___node__news',
  relationships___field_hero_image___relationships___node__branches = 'relationships___field_hero_image___relationships___node__branches',
  relationships___field_hero_image___relationships___paragraph__branches_hero = 'relationships___field_hero_image___relationships___paragraph__branches_hero',
  relationships___field_hero_image___relationships___paragraph__bullets = 'relationships___field_hero_image___relationships___paragraph__bullets',
  relationships___field_hero_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image___relationships___paragraph__branches_device = 'relationships___field_hero_image___relationships___paragraph__branches_device',
  relationships___field_hero_image___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image___relationships___site_setting_entity__accessories = 'relationships___field_hero_image___relationships___site_setting_entity__accessories',
  relationships___field_hero_image___relationships___site_setting_entity__branches = 'relationships___field_hero_image___relationships___site_setting_entity__branches',
  relationships___field_hero_image___relationships___site_setting_entity__about_us = 'relationships___field_hero_image___relationships___site_setting_entity__about_us',
  relationships___field_hero_image___relationships___paragraph__logos = 'relationships___field_hero_image___relationships___paragraph__logos',
  relationships___field_hero_image___relationships___paragraph__how_it_works = 'relationships___field_hero_image___relationships___paragraph__how_it_works',
  relationships___field_hero_image___relationships___paragraph__ico_and_text = 'relationships___field_hero_image___relationships___paragraph__ico_and_text',
  relationships___field_hero_image___relationships___paragraph__hero_1 = 'relationships___field_hero_image___relationships___paragraph__hero_1',
  relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image___relationships___site_setting_entity__product = 'relationships___field_hero_image___relationships___site_setting_entity__product',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image___relationships___site_setting_entity__main_page = 'relationships___field_hero_image___relationships___site_setting_entity__main_page',
  relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image___relationships___site_setting_entity__intercars = 'relationships___field_hero_image___relationships___site_setting_entity__intercars',
  relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image___relationships___node__agreement = 'relationships___field_hero_image___relationships___node__agreement',
  relationships___field_hero_image___relationships___node__fiscalization = 'relationships___field_hero_image___relationships___node__fiscalization',
  relationships___field_hero_image___relationships___site_setting_entity__configurator = 'relationships___field_hero_image___relationships___site_setting_entity__configurator',
  relationships___field_hero_image___relationships___paragraph__social_link = 'relationships___field_hero_image___relationships___paragraph__social_link',
  relationships___field_hero_image___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image___relationships___paragraph__linked_icons = 'relationships___field_hero_image___relationships___paragraph__linked_icons',
  relationships___field_hero_image___relationships___site_setting_entity__financing = 'relationships___field_hero_image___relationships___site_setting_entity__financing',
  relationships___field_hero_image___relationships___site_setting_entity__franchise = 'relationships___field_hero_image___relationships___site_setting_entity__franchise',
  relationships___field_hero_image___id = 'relationships___field_hero_image___id',
  relationships___field_hero_image___parent___id = 'relationships___field_hero_image___parent___id',
  relationships___field_hero_image___parent___children = 'relationships___field_hero_image___parent___children',
  relationships___field_hero_image___children = 'relationships___field_hero_image___children',
  relationships___field_hero_image___children___id = 'relationships___field_hero_image___children___id',
  relationships___field_hero_image___children___children = 'relationships___field_hero_image___children___children',
  relationships___field_hero_image___internal___content = 'relationships___field_hero_image___internal___content',
  relationships___field_hero_image___internal___contentDigest = 'relationships___field_hero_image___internal___contentDigest',
  relationships___field_hero_image___internal___description = 'relationships___field_hero_image___internal___description',
  relationships___field_hero_image___internal___fieldOwners = 'relationships___field_hero_image___internal___fieldOwners',
  relationships___field_hero_image___internal___ignoreType = 'relationships___field_hero_image___internal___ignoreType',
  relationships___field_hero_image___internal___mediaType = 'relationships___field_hero_image___internal___mediaType',
  relationships___field_hero_image___internal___owner = 'relationships___field_hero_image___internal___owner',
  relationships___field_hero_image___internal___type = 'relationships___field_hero_image___internal___type',
  relationships___field_hero_image_mobile___localFile___birthtime = 'relationships___field_hero_image_mobile___localFile___birthtime',
  relationships___field_hero_image_mobile___localFile___birthtimeMs = 'relationships___field_hero_image_mobile___localFile___birthtimeMs',
  relationships___field_hero_image_mobile___localFile___publicURL = 'relationships___field_hero_image_mobile___localFile___publicURL',
  relationships___field_hero_image_mobile___localFile___sourceInstanceName = 'relationships___field_hero_image_mobile___localFile___sourceInstanceName',
  relationships___field_hero_image_mobile___localFile___absolutePath = 'relationships___field_hero_image_mobile___localFile___absolutePath',
  relationships___field_hero_image_mobile___localFile___relativePath = 'relationships___field_hero_image_mobile___localFile___relativePath',
  relationships___field_hero_image_mobile___localFile___extension = 'relationships___field_hero_image_mobile___localFile___extension',
  relationships___field_hero_image_mobile___localFile___size = 'relationships___field_hero_image_mobile___localFile___size',
  relationships___field_hero_image_mobile___localFile___prettySize = 'relationships___field_hero_image_mobile___localFile___prettySize',
  relationships___field_hero_image_mobile___localFile___modifiedTime = 'relationships___field_hero_image_mobile___localFile___modifiedTime',
  relationships___field_hero_image_mobile___localFile___accessTime = 'relationships___field_hero_image_mobile___localFile___accessTime',
  relationships___field_hero_image_mobile___localFile___changeTime = 'relationships___field_hero_image_mobile___localFile___changeTime',
  relationships___field_hero_image_mobile___localFile___birthTime = 'relationships___field_hero_image_mobile___localFile___birthTime',
  relationships___field_hero_image_mobile___localFile___root = 'relationships___field_hero_image_mobile___localFile___root',
  relationships___field_hero_image_mobile___localFile___dir = 'relationships___field_hero_image_mobile___localFile___dir',
  relationships___field_hero_image_mobile___localFile___base = 'relationships___field_hero_image_mobile___localFile___base',
  relationships___field_hero_image_mobile___localFile___ext = 'relationships___field_hero_image_mobile___localFile___ext',
  relationships___field_hero_image_mobile___localFile___name = 'relationships___field_hero_image_mobile___localFile___name',
  relationships___field_hero_image_mobile___localFile___relativeDirectory = 'relationships___field_hero_image_mobile___localFile___relativeDirectory',
  relationships___field_hero_image_mobile___localFile___dev = 'relationships___field_hero_image_mobile___localFile___dev',
  relationships___field_hero_image_mobile___localFile___mode = 'relationships___field_hero_image_mobile___localFile___mode',
  relationships___field_hero_image_mobile___localFile___nlink = 'relationships___field_hero_image_mobile___localFile___nlink',
  relationships___field_hero_image_mobile___localFile___uid = 'relationships___field_hero_image_mobile___localFile___uid',
  relationships___field_hero_image_mobile___localFile___gid = 'relationships___field_hero_image_mobile___localFile___gid',
  relationships___field_hero_image_mobile___localFile___rdev = 'relationships___field_hero_image_mobile___localFile___rdev',
  relationships___field_hero_image_mobile___localFile___blksize = 'relationships___field_hero_image_mobile___localFile___blksize',
  relationships___field_hero_image_mobile___localFile___ino = 'relationships___field_hero_image_mobile___localFile___ino',
  relationships___field_hero_image_mobile___localFile___blocks = 'relationships___field_hero_image_mobile___localFile___blocks',
  relationships___field_hero_image_mobile___localFile___atimeMs = 'relationships___field_hero_image_mobile___localFile___atimeMs',
  relationships___field_hero_image_mobile___localFile___mtimeMs = 'relationships___field_hero_image_mobile___localFile___mtimeMs',
  relationships___field_hero_image_mobile___localFile___ctimeMs = 'relationships___field_hero_image_mobile___localFile___ctimeMs',
  relationships___field_hero_image_mobile___localFile___atime = 'relationships___field_hero_image_mobile___localFile___atime',
  relationships___field_hero_image_mobile___localFile___mtime = 'relationships___field_hero_image_mobile___localFile___mtime',
  relationships___field_hero_image_mobile___localFile___ctime = 'relationships___field_hero_image_mobile___localFile___ctime',
  relationships___field_hero_image_mobile___localFile___url = 'relationships___field_hero_image_mobile___localFile___url',
  relationships___field_hero_image_mobile___localFile___id = 'relationships___field_hero_image_mobile___localFile___id',
  relationships___field_hero_image_mobile___localFile___children = 'relationships___field_hero_image_mobile___localFile___children',
  relationships___field_hero_image_mobile___uri___url = 'relationships___field_hero_image_mobile___uri___url',
  relationships___field_hero_image_mobile___drupal_id = 'relationships___field_hero_image_mobile___drupal_id',
  relationships___field_hero_image_mobile___drupal_internal__fid = 'relationships___field_hero_image_mobile___drupal_internal__fid',
  relationships___field_hero_image_mobile___langcode = 'relationships___field_hero_image_mobile___langcode',
  relationships___field_hero_image_mobile___filename = 'relationships___field_hero_image_mobile___filename',
  relationships___field_hero_image_mobile___filemime = 'relationships___field_hero_image_mobile___filemime',
  relationships___field_hero_image_mobile___filesize = 'relationships___field_hero_image_mobile___filesize',
  relationships___field_hero_image_mobile___status = 'relationships___field_hero_image_mobile___status',
  relationships___field_hero_image_mobile___created = 'relationships___field_hero_image_mobile___created',
  relationships___field_hero_image_mobile___changed = 'relationships___field_hero_image_mobile___changed',
  relationships___field_hero_image_mobile___uuid = 'relationships___field_hero_image_mobile___uuid',
  relationships___field_hero_image_mobile___relationships___node__blog_post = 'relationships___field_hero_image_mobile___relationships___node__blog_post',
  relationships___field_hero_image_mobile___relationships___node__devices = 'relationships___field_hero_image_mobile___relationships___node__devices',
  relationships___field_hero_image_mobile___relationships___node__accessories = 'relationships___field_hero_image_mobile___relationships___node__accessories',
  relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text = 'relationships___field_hero_image_mobile___relationships___paragraph__news_image_title_text',
  relationships___field_hero_image_mobile___relationships___node__cases = 'relationships___field_hero_image_mobile___relationships___node__cases',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_features = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_features',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_testimonials',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_discounts',
  relationships___field_hero_image_mobile___relationships___node__news = 'relationships___field_hero_image_mobile___relationships___node__news',
  relationships___field_hero_image_mobile___relationships___node__branches = 'relationships___field_hero_image_mobile___relationships___node__branches',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_hero = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_hero',
  relationships___field_hero_image_mobile___relationships___paragraph__bullets = 'relationships___field_hero_image_mobile___relationships___paragraph__bullets',
  relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_image_mobile___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_device = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_device',
  relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation = 'relationships___field_hero_image_mobile___relationships___paragraph__branches_recommendation',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__accessories',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__branches = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__branches',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__about_us',
  relationships___field_hero_image_mobile___relationships___paragraph__logos = 'relationships___field_hero_image_mobile___relationships___paragraph__logos',
  relationships___field_hero_image_mobile___relationships___paragraph__how_it_works = 'relationships___field_hero_image_mobile___relationships___paragraph__how_it_works',
  relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text = 'relationships___field_hero_image_mobile___relationships___paragraph__ico_and_text',
  relationships___field_hero_image_mobile___relationships___paragraph__hero_1 = 'relationships___field_hero_image_mobile___relationships___paragraph__hero_1',
  relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_image_mobile___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__product = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__product',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_image_mobile___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_image_mobile___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__main_page',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_image_mobile___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__intercars',
  relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_image_mobile___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_image_mobile___relationships___node__agreement = 'relationships___field_hero_image_mobile___relationships___node__agreement',
  relationships___field_hero_image_mobile___relationships___node__fiscalization = 'relationships___field_hero_image_mobile___relationships___node__fiscalization',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__configurator',
  relationships___field_hero_image_mobile___relationships___paragraph__social_link = 'relationships___field_hero_image_mobile___relationships___paragraph__social_link',
  relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment = 'relationships___field_hero_image_mobile___relationships___paragraph__make_an_appointment',
  relationships___field_hero_image_mobile___relationships___paragraph__linked_icons = 'relationships___field_hero_image_mobile___relationships___paragraph__linked_icons',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__financing = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__financing',
  relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise = 'relationships___field_hero_image_mobile___relationships___site_setting_entity__franchise',
  relationships___field_hero_image_mobile___id = 'relationships___field_hero_image_mobile___id',
  relationships___field_hero_image_mobile___parent___id = 'relationships___field_hero_image_mobile___parent___id',
  relationships___field_hero_image_mobile___parent___children = 'relationships___field_hero_image_mobile___parent___children',
  relationships___field_hero_image_mobile___children = 'relationships___field_hero_image_mobile___children',
  relationships___field_hero_image_mobile___children___id = 'relationships___field_hero_image_mobile___children___id',
  relationships___field_hero_image_mobile___children___children = 'relationships___field_hero_image_mobile___children___children',
  relationships___field_hero_image_mobile___internal___content = 'relationships___field_hero_image_mobile___internal___content',
  relationships___field_hero_image_mobile___internal___contentDigest = 'relationships___field_hero_image_mobile___internal___contentDigest',
  relationships___field_hero_image_mobile___internal___description = 'relationships___field_hero_image_mobile___internal___description',
  relationships___field_hero_image_mobile___internal___fieldOwners = 'relationships___field_hero_image_mobile___internal___fieldOwners',
  relationships___field_hero_image_mobile___internal___ignoreType = 'relationships___field_hero_image_mobile___internal___ignoreType',
  relationships___field_hero_image_mobile___internal___mediaType = 'relationships___field_hero_image_mobile___internal___mediaType',
  relationships___field_hero_image_mobile___internal___owner = 'relationships___field_hero_image_mobile___internal___owner',
  relationships___field_hero_image_mobile___internal___type = 'relationships___field_hero_image_mobile___internal___type',
  relationships___field_image_1___localFile___birthtime = 'relationships___field_image_1___localFile___birthtime',
  relationships___field_image_1___localFile___birthtimeMs = 'relationships___field_image_1___localFile___birthtimeMs',
  relationships___field_image_1___localFile___publicURL = 'relationships___field_image_1___localFile___publicURL',
  relationships___field_image_1___localFile___sourceInstanceName = 'relationships___field_image_1___localFile___sourceInstanceName',
  relationships___field_image_1___localFile___absolutePath = 'relationships___field_image_1___localFile___absolutePath',
  relationships___field_image_1___localFile___relativePath = 'relationships___field_image_1___localFile___relativePath',
  relationships___field_image_1___localFile___extension = 'relationships___field_image_1___localFile___extension',
  relationships___field_image_1___localFile___size = 'relationships___field_image_1___localFile___size',
  relationships___field_image_1___localFile___prettySize = 'relationships___field_image_1___localFile___prettySize',
  relationships___field_image_1___localFile___modifiedTime = 'relationships___field_image_1___localFile___modifiedTime',
  relationships___field_image_1___localFile___accessTime = 'relationships___field_image_1___localFile___accessTime',
  relationships___field_image_1___localFile___changeTime = 'relationships___field_image_1___localFile___changeTime',
  relationships___field_image_1___localFile___birthTime = 'relationships___field_image_1___localFile___birthTime',
  relationships___field_image_1___localFile___root = 'relationships___field_image_1___localFile___root',
  relationships___field_image_1___localFile___dir = 'relationships___field_image_1___localFile___dir',
  relationships___field_image_1___localFile___base = 'relationships___field_image_1___localFile___base',
  relationships___field_image_1___localFile___ext = 'relationships___field_image_1___localFile___ext',
  relationships___field_image_1___localFile___name = 'relationships___field_image_1___localFile___name',
  relationships___field_image_1___localFile___relativeDirectory = 'relationships___field_image_1___localFile___relativeDirectory',
  relationships___field_image_1___localFile___dev = 'relationships___field_image_1___localFile___dev',
  relationships___field_image_1___localFile___mode = 'relationships___field_image_1___localFile___mode',
  relationships___field_image_1___localFile___nlink = 'relationships___field_image_1___localFile___nlink',
  relationships___field_image_1___localFile___uid = 'relationships___field_image_1___localFile___uid',
  relationships___field_image_1___localFile___gid = 'relationships___field_image_1___localFile___gid',
  relationships___field_image_1___localFile___rdev = 'relationships___field_image_1___localFile___rdev',
  relationships___field_image_1___localFile___blksize = 'relationships___field_image_1___localFile___blksize',
  relationships___field_image_1___localFile___ino = 'relationships___field_image_1___localFile___ino',
  relationships___field_image_1___localFile___blocks = 'relationships___field_image_1___localFile___blocks',
  relationships___field_image_1___localFile___atimeMs = 'relationships___field_image_1___localFile___atimeMs',
  relationships___field_image_1___localFile___mtimeMs = 'relationships___field_image_1___localFile___mtimeMs',
  relationships___field_image_1___localFile___ctimeMs = 'relationships___field_image_1___localFile___ctimeMs',
  relationships___field_image_1___localFile___atime = 'relationships___field_image_1___localFile___atime',
  relationships___field_image_1___localFile___mtime = 'relationships___field_image_1___localFile___mtime',
  relationships___field_image_1___localFile___ctime = 'relationships___field_image_1___localFile___ctime',
  relationships___field_image_1___localFile___url = 'relationships___field_image_1___localFile___url',
  relationships___field_image_1___localFile___id = 'relationships___field_image_1___localFile___id',
  relationships___field_image_1___localFile___children = 'relationships___field_image_1___localFile___children',
  relationships___field_image_1___uri___url = 'relationships___field_image_1___uri___url',
  relationships___field_image_1___drupal_id = 'relationships___field_image_1___drupal_id',
  relationships___field_image_1___drupal_internal__fid = 'relationships___field_image_1___drupal_internal__fid',
  relationships___field_image_1___langcode = 'relationships___field_image_1___langcode',
  relationships___field_image_1___filename = 'relationships___field_image_1___filename',
  relationships___field_image_1___filemime = 'relationships___field_image_1___filemime',
  relationships___field_image_1___filesize = 'relationships___field_image_1___filesize',
  relationships___field_image_1___status = 'relationships___field_image_1___status',
  relationships___field_image_1___created = 'relationships___field_image_1___created',
  relationships___field_image_1___changed = 'relationships___field_image_1___changed',
  relationships___field_image_1___uuid = 'relationships___field_image_1___uuid',
  relationships___field_image_1___relationships___node__blog_post = 'relationships___field_image_1___relationships___node__blog_post',
  relationships___field_image_1___relationships___node__devices = 'relationships___field_image_1___relationships___node__devices',
  relationships___field_image_1___relationships___node__accessories = 'relationships___field_image_1___relationships___node__accessories',
  relationships___field_image_1___relationships___paragraph__news_image_title_text = 'relationships___field_image_1___relationships___paragraph__news_image_title_text',
  relationships___field_image_1___relationships___node__cases = 'relationships___field_image_1___relationships___node__cases',
  relationships___field_image_1___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_1___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_1___relationships___paragraph__branches_features = 'relationships___field_image_1___relationships___paragraph__branches_features',
  relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_1___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_1___relationships___paragraph__branches_testimonials = 'relationships___field_image_1___relationships___paragraph__branches_testimonials',
  relationships___field_image_1___relationships___paragraph__branches_discounts = 'relationships___field_image_1___relationships___paragraph__branches_discounts',
  relationships___field_image_1___relationships___node__news = 'relationships___field_image_1___relationships___node__news',
  relationships___field_image_1___relationships___node__branches = 'relationships___field_image_1___relationships___node__branches',
  relationships___field_image_1___relationships___paragraph__branches_hero = 'relationships___field_image_1___relationships___paragraph__branches_hero',
  relationships___field_image_1___relationships___paragraph__bullets = 'relationships___field_image_1___relationships___paragraph__bullets',
  relationships___field_image_1___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_1___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_1___relationships___paragraph__branches_device = 'relationships___field_image_1___relationships___paragraph__branches_device',
  relationships___field_image_1___relationships___paragraph__branches_recommendation = 'relationships___field_image_1___relationships___paragraph__branches_recommendation',
  relationships___field_image_1___relationships___site_setting_entity__accessories = 'relationships___field_image_1___relationships___site_setting_entity__accessories',
  relationships___field_image_1___relationships___site_setting_entity__branches = 'relationships___field_image_1___relationships___site_setting_entity__branches',
  relationships___field_image_1___relationships___site_setting_entity__about_us = 'relationships___field_image_1___relationships___site_setting_entity__about_us',
  relationships___field_image_1___relationships___paragraph__logos = 'relationships___field_image_1___relationships___paragraph__logos',
  relationships___field_image_1___relationships___paragraph__how_it_works = 'relationships___field_image_1___relationships___paragraph__how_it_works',
  relationships___field_image_1___relationships___paragraph__ico_and_text = 'relationships___field_image_1___relationships___paragraph__ico_and_text',
  relationships___field_image_1___relationships___paragraph__hero_1 = 'relationships___field_image_1___relationships___paragraph__hero_1',
  relationships___field_image_1___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_1___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_1___relationships___site_setting_entity__product = 'relationships___field_image_1___relationships___site_setting_entity__product',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_1___relationships___paragraph__porownanie_terminali = 'relationships___field_image_1___relationships___paragraph__porownanie_terminali',
  relationships___field_image_1___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_1___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_1___relationships___site_setting_entity__main_page = 'relationships___field_image_1___relationships___site_setting_entity__main_page',
  relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_1___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_1___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_1___relationships___site_setting_entity__intercars = 'relationships___field_image_1___relationships___site_setting_entity__intercars',
  relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_1___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_1___relationships___node__agreement = 'relationships___field_image_1___relationships___node__agreement',
  relationships___field_image_1___relationships___node__fiscalization = 'relationships___field_image_1___relationships___node__fiscalization',
  relationships___field_image_1___relationships___site_setting_entity__configurator = 'relationships___field_image_1___relationships___site_setting_entity__configurator',
  relationships___field_image_1___relationships___paragraph__social_link = 'relationships___field_image_1___relationships___paragraph__social_link',
  relationships___field_image_1___relationships___paragraph__make_an_appointment = 'relationships___field_image_1___relationships___paragraph__make_an_appointment',
  relationships___field_image_1___relationships___paragraph__linked_icons = 'relationships___field_image_1___relationships___paragraph__linked_icons',
  relationships___field_image_1___relationships___site_setting_entity__financing = 'relationships___field_image_1___relationships___site_setting_entity__financing',
  relationships___field_image_1___relationships___site_setting_entity__franchise = 'relationships___field_image_1___relationships___site_setting_entity__franchise',
  relationships___field_image_1___id = 'relationships___field_image_1___id',
  relationships___field_image_1___parent___id = 'relationships___field_image_1___parent___id',
  relationships___field_image_1___parent___children = 'relationships___field_image_1___parent___children',
  relationships___field_image_1___children = 'relationships___field_image_1___children',
  relationships___field_image_1___children___id = 'relationships___field_image_1___children___id',
  relationships___field_image_1___children___children = 'relationships___field_image_1___children___children',
  relationships___field_image_1___internal___content = 'relationships___field_image_1___internal___content',
  relationships___field_image_1___internal___contentDigest = 'relationships___field_image_1___internal___contentDigest',
  relationships___field_image_1___internal___description = 'relationships___field_image_1___internal___description',
  relationships___field_image_1___internal___fieldOwners = 'relationships___field_image_1___internal___fieldOwners',
  relationships___field_image_1___internal___ignoreType = 'relationships___field_image_1___internal___ignoreType',
  relationships___field_image_1___internal___mediaType = 'relationships___field_image_1___internal___mediaType',
  relationships___field_image_1___internal___owner = 'relationships___field_image_1___internal___owner',
  relationships___field_image_1___internal___type = 'relationships___field_image_1___internal___type',
  relationships___field_image_2___localFile___birthtime = 'relationships___field_image_2___localFile___birthtime',
  relationships___field_image_2___localFile___birthtimeMs = 'relationships___field_image_2___localFile___birthtimeMs',
  relationships___field_image_2___localFile___publicURL = 'relationships___field_image_2___localFile___publicURL',
  relationships___field_image_2___localFile___sourceInstanceName = 'relationships___field_image_2___localFile___sourceInstanceName',
  relationships___field_image_2___localFile___absolutePath = 'relationships___field_image_2___localFile___absolutePath',
  relationships___field_image_2___localFile___relativePath = 'relationships___field_image_2___localFile___relativePath',
  relationships___field_image_2___localFile___extension = 'relationships___field_image_2___localFile___extension',
  relationships___field_image_2___localFile___size = 'relationships___field_image_2___localFile___size',
  relationships___field_image_2___localFile___prettySize = 'relationships___field_image_2___localFile___prettySize',
  relationships___field_image_2___localFile___modifiedTime = 'relationships___field_image_2___localFile___modifiedTime',
  relationships___field_image_2___localFile___accessTime = 'relationships___field_image_2___localFile___accessTime',
  relationships___field_image_2___localFile___changeTime = 'relationships___field_image_2___localFile___changeTime',
  relationships___field_image_2___localFile___birthTime = 'relationships___field_image_2___localFile___birthTime',
  relationships___field_image_2___localFile___root = 'relationships___field_image_2___localFile___root',
  relationships___field_image_2___localFile___dir = 'relationships___field_image_2___localFile___dir',
  relationships___field_image_2___localFile___base = 'relationships___field_image_2___localFile___base',
  relationships___field_image_2___localFile___ext = 'relationships___field_image_2___localFile___ext',
  relationships___field_image_2___localFile___name = 'relationships___field_image_2___localFile___name',
  relationships___field_image_2___localFile___relativeDirectory = 'relationships___field_image_2___localFile___relativeDirectory',
  relationships___field_image_2___localFile___dev = 'relationships___field_image_2___localFile___dev',
  relationships___field_image_2___localFile___mode = 'relationships___field_image_2___localFile___mode',
  relationships___field_image_2___localFile___nlink = 'relationships___field_image_2___localFile___nlink',
  relationships___field_image_2___localFile___uid = 'relationships___field_image_2___localFile___uid',
  relationships___field_image_2___localFile___gid = 'relationships___field_image_2___localFile___gid',
  relationships___field_image_2___localFile___rdev = 'relationships___field_image_2___localFile___rdev',
  relationships___field_image_2___localFile___blksize = 'relationships___field_image_2___localFile___blksize',
  relationships___field_image_2___localFile___ino = 'relationships___field_image_2___localFile___ino',
  relationships___field_image_2___localFile___blocks = 'relationships___field_image_2___localFile___blocks',
  relationships___field_image_2___localFile___atimeMs = 'relationships___field_image_2___localFile___atimeMs',
  relationships___field_image_2___localFile___mtimeMs = 'relationships___field_image_2___localFile___mtimeMs',
  relationships___field_image_2___localFile___ctimeMs = 'relationships___field_image_2___localFile___ctimeMs',
  relationships___field_image_2___localFile___atime = 'relationships___field_image_2___localFile___atime',
  relationships___field_image_2___localFile___mtime = 'relationships___field_image_2___localFile___mtime',
  relationships___field_image_2___localFile___ctime = 'relationships___field_image_2___localFile___ctime',
  relationships___field_image_2___localFile___url = 'relationships___field_image_2___localFile___url',
  relationships___field_image_2___localFile___id = 'relationships___field_image_2___localFile___id',
  relationships___field_image_2___localFile___children = 'relationships___field_image_2___localFile___children',
  relationships___field_image_2___uri___url = 'relationships___field_image_2___uri___url',
  relationships___field_image_2___drupal_id = 'relationships___field_image_2___drupal_id',
  relationships___field_image_2___drupal_internal__fid = 'relationships___field_image_2___drupal_internal__fid',
  relationships___field_image_2___langcode = 'relationships___field_image_2___langcode',
  relationships___field_image_2___filename = 'relationships___field_image_2___filename',
  relationships___field_image_2___filemime = 'relationships___field_image_2___filemime',
  relationships___field_image_2___filesize = 'relationships___field_image_2___filesize',
  relationships___field_image_2___status = 'relationships___field_image_2___status',
  relationships___field_image_2___created = 'relationships___field_image_2___created',
  relationships___field_image_2___changed = 'relationships___field_image_2___changed',
  relationships___field_image_2___uuid = 'relationships___field_image_2___uuid',
  relationships___field_image_2___relationships___node__blog_post = 'relationships___field_image_2___relationships___node__blog_post',
  relationships___field_image_2___relationships___node__devices = 'relationships___field_image_2___relationships___node__devices',
  relationships___field_image_2___relationships___node__accessories = 'relationships___field_image_2___relationships___node__accessories',
  relationships___field_image_2___relationships___paragraph__news_image_title_text = 'relationships___field_image_2___relationships___paragraph__news_image_title_text',
  relationships___field_image_2___relationships___node__cases = 'relationships___field_image_2___relationships___node__cases',
  relationships___field_image_2___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_2___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_2___relationships___paragraph__branches_features = 'relationships___field_image_2___relationships___paragraph__branches_features',
  relationships___field_image_2___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_2___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_2___relationships___paragraph__branches_testimonials = 'relationships___field_image_2___relationships___paragraph__branches_testimonials',
  relationships___field_image_2___relationships___paragraph__branches_discounts = 'relationships___field_image_2___relationships___paragraph__branches_discounts',
  relationships___field_image_2___relationships___node__news = 'relationships___field_image_2___relationships___node__news',
  relationships___field_image_2___relationships___node__branches = 'relationships___field_image_2___relationships___node__branches',
  relationships___field_image_2___relationships___paragraph__branches_hero = 'relationships___field_image_2___relationships___paragraph__branches_hero',
  relationships___field_image_2___relationships___paragraph__bullets = 'relationships___field_image_2___relationships___paragraph__bullets',
  relationships___field_image_2___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_2___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_2___relationships___paragraph__branches_device = 'relationships___field_image_2___relationships___paragraph__branches_device',
  relationships___field_image_2___relationships___paragraph__branches_recommendation = 'relationships___field_image_2___relationships___paragraph__branches_recommendation',
  relationships___field_image_2___relationships___site_setting_entity__accessories = 'relationships___field_image_2___relationships___site_setting_entity__accessories',
  relationships___field_image_2___relationships___site_setting_entity__branches = 'relationships___field_image_2___relationships___site_setting_entity__branches',
  relationships___field_image_2___relationships___site_setting_entity__about_us = 'relationships___field_image_2___relationships___site_setting_entity__about_us',
  relationships___field_image_2___relationships___paragraph__logos = 'relationships___field_image_2___relationships___paragraph__logos',
  relationships___field_image_2___relationships___paragraph__how_it_works = 'relationships___field_image_2___relationships___paragraph__how_it_works',
  relationships___field_image_2___relationships___paragraph__ico_and_text = 'relationships___field_image_2___relationships___paragraph__ico_and_text',
  relationships___field_image_2___relationships___paragraph__hero_1 = 'relationships___field_image_2___relationships___paragraph__hero_1',
  relationships___field_image_2___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_2___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_2___relationships___site_setting_entity__product = 'relationships___field_image_2___relationships___site_setting_entity__product',
  relationships___field_image_2___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_2___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_2___relationships___paragraph__porownanie_terminali = 'relationships___field_image_2___relationships___paragraph__porownanie_terminali',
  relationships___field_image_2___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_2___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_2___relationships___site_setting_entity__main_page = 'relationships___field_image_2___relationships___site_setting_entity__main_page',
  relationships___field_image_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_2___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_2___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_2___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_2___relationships___site_setting_entity__intercars = 'relationships___field_image_2___relationships___site_setting_entity__intercars',
  relationships___field_image_2___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_2___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_2___relationships___node__agreement = 'relationships___field_image_2___relationships___node__agreement',
  relationships___field_image_2___relationships___node__fiscalization = 'relationships___field_image_2___relationships___node__fiscalization',
  relationships___field_image_2___relationships___site_setting_entity__configurator = 'relationships___field_image_2___relationships___site_setting_entity__configurator',
  relationships___field_image_2___relationships___paragraph__social_link = 'relationships___field_image_2___relationships___paragraph__social_link',
  relationships___field_image_2___relationships___paragraph__make_an_appointment = 'relationships___field_image_2___relationships___paragraph__make_an_appointment',
  relationships___field_image_2___relationships___paragraph__linked_icons = 'relationships___field_image_2___relationships___paragraph__linked_icons',
  relationships___field_image_2___relationships___site_setting_entity__financing = 'relationships___field_image_2___relationships___site_setting_entity__financing',
  relationships___field_image_2___relationships___site_setting_entity__franchise = 'relationships___field_image_2___relationships___site_setting_entity__franchise',
  relationships___field_image_2___id = 'relationships___field_image_2___id',
  relationships___field_image_2___parent___id = 'relationships___field_image_2___parent___id',
  relationships___field_image_2___parent___children = 'relationships___field_image_2___parent___children',
  relationships___field_image_2___children = 'relationships___field_image_2___children',
  relationships___field_image_2___children___id = 'relationships___field_image_2___children___id',
  relationships___field_image_2___children___children = 'relationships___field_image_2___children___children',
  relationships___field_image_2___internal___content = 'relationships___field_image_2___internal___content',
  relationships___field_image_2___internal___contentDigest = 'relationships___field_image_2___internal___contentDigest',
  relationships___field_image_2___internal___description = 'relationships___field_image_2___internal___description',
  relationships___field_image_2___internal___fieldOwners = 'relationships___field_image_2___internal___fieldOwners',
  relationships___field_image_2___internal___ignoreType = 'relationships___field_image_2___internal___ignoreType',
  relationships___field_image_2___internal___mediaType = 'relationships___field_image_2___internal___mediaType',
  relationships___field_image_2___internal___owner = 'relationships___field_image_2___internal___owner',
  relationships___field_image_2___internal___type = 'relationships___field_image_2___internal___type',
  relationships___field_image_3___localFile___birthtime = 'relationships___field_image_3___localFile___birthtime',
  relationships___field_image_3___localFile___birthtimeMs = 'relationships___field_image_3___localFile___birthtimeMs',
  relationships___field_image_3___localFile___publicURL = 'relationships___field_image_3___localFile___publicURL',
  relationships___field_image_3___localFile___sourceInstanceName = 'relationships___field_image_3___localFile___sourceInstanceName',
  relationships___field_image_3___localFile___absolutePath = 'relationships___field_image_3___localFile___absolutePath',
  relationships___field_image_3___localFile___relativePath = 'relationships___field_image_3___localFile___relativePath',
  relationships___field_image_3___localFile___extension = 'relationships___field_image_3___localFile___extension',
  relationships___field_image_3___localFile___size = 'relationships___field_image_3___localFile___size',
  relationships___field_image_3___localFile___prettySize = 'relationships___field_image_3___localFile___prettySize',
  relationships___field_image_3___localFile___modifiedTime = 'relationships___field_image_3___localFile___modifiedTime',
  relationships___field_image_3___localFile___accessTime = 'relationships___field_image_3___localFile___accessTime',
  relationships___field_image_3___localFile___changeTime = 'relationships___field_image_3___localFile___changeTime',
  relationships___field_image_3___localFile___birthTime = 'relationships___field_image_3___localFile___birthTime',
  relationships___field_image_3___localFile___root = 'relationships___field_image_3___localFile___root',
  relationships___field_image_3___localFile___dir = 'relationships___field_image_3___localFile___dir',
  relationships___field_image_3___localFile___base = 'relationships___field_image_3___localFile___base',
  relationships___field_image_3___localFile___ext = 'relationships___field_image_3___localFile___ext',
  relationships___field_image_3___localFile___name = 'relationships___field_image_3___localFile___name',
  relationships___field_image_3___localFile___relativeDirectory = 'relationships___field_image_3___localFile___relativeDirectory',
  relationships___field_image_3___localFile___dev = 'relationships___field_image_3___localFile___dev',
  relationships___field_image_3___localFile___mode = 'relationships___field_image_3___localFile___mode',
  relationships___field_image_3___localFile___nlink = 'relationships___field_image_3___localFile___nlink',
  relationships___field_image_3___localFile___uid = 'relationships___field_image_3___localFile___uid',
  relationships___field_image_3___localFile___gid = 'relationships___field_image_3___localFile___gid',
  relationships___field_image_3___localFile___rdev = 'relationships___field_image_3___localFile___rdev',
  relationships___field_image_3___localFile___blksize = 'relationships___field_image_3___localFile___blksize',
  relationships___field_image_3___localFile___ino = 'relationships___field_image_3___localFile___ino',
  relationships___field_image_3___localFile___blocks = 'relationships___field_image_3___localFile___blocks',
  relationships___field_image_3___localFile___atimeMs = 'relationships___field_image_3___localFile___atimeMs',
  relationships___field_image_3___localFile___mtimeMs = 'relationships___field_image_3___localFile___mtimeMs',
  relationships___field_image_3___localFile___ctimeMs = 'relationships___field_image_3___localFile___ctimeMs',
  relationships___field_image_3___localFile___atime = 'relationships___field_image_3___localFile___atime',
  relationships___field_image_3___localFile___mtime = 'relationships___field_image_3___localFile___mtime',
  relationships___field_image_3___localFile___ctime = 'relationships___field_image_3___localFile___ctime',
  relationships___field_image_3___localFile___url = 'relationships___field_image_3___localFile___url',
  relationships___field_image_3___localFile___id = 'relationships___field_image_3___localFile___id',
  relationships___field_image_3___localFile___children = 'relationships___field_image_3___localFile___children',
  relationships___field_image_3___uri___url = 'relationships___field_image_3___uri___url',
  relationships___field_image_3___drupal_id = 'relationships___field_image_3___drupal_id',
  relationships___field_image_3___drupal_internal__fid = 'relationships___field_image_3___drupal_internal__fid',
  relationships___field_image_3___langcode = 'relationships___field_image_3___langcode',
  relationships___field_image_3___filename = 'relationships___field_image_3___filename',
  relationships___field_image_3___filemime = 'relationships___field_image_3___filemime',
  relationships___field_image_3___filesize = 'relationships___field_image_3___filesize',
  relationships___field_image_3___status = 'relationships___field_image_3___status',
  relationships___field_image_3___created = 'relationships___field_image_3___created',
  relationships___field_image_3___changed = 'relationships___field_image_3___changed',
  relationships___field_image_3___uuid = 'relationships___field_image_3___uuid',
  relationships___field_image_3___relationships___node__blog_post = 'relationships___field_image_3___relationships___node__blog_post',
  relationships___field_image_3___relationships___node__devices = 'relationships___field_image_3___relationships___node__devices',
  relationships___field_image_3___relationships___node__accessories = 'relationships___field_image_3___relationships___node__accessories',
  relationships___field_image_3___relationships___paragraph__news_image_title_text = 'relationships___field_image_3___relationships___paragraph__news_image_title_text',
  relationships___field_image_3___relationships___node__cases = 'relationships___field_image_3___relationships___node__cases',
  relationships___field_image_3___relationships___paragraph__branches_what_is_ipos = 'relationships___field_image_3___relationships___paragraph__branches_what_is_ipos',
  relationships___field_image_3___relationships___paragraph__branches_features = 'relationships___field_image_3___relationships___paragraph__branches_features',
  relationships___field_image_3___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_image_3___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_image_3___relationships___paragraph__branches_testimonials = 'relationships___field_image_3___relationships___paragraph__branches_testimonials',
  relationships___field_image_3___relationships___paragraph__branches_discounts = 'relationships___field_image_3___relationships___paragraph__branches_discounts',
  relationships___field_image_3___relationships___node__news = 'relationships___field_image_3___relationships___node__news',
  relationships___field_image_3___relationships___node__branches = 'relationships___field_image_3___relationships___node__branches',
  relationships___field_image_3___relationships___paragraph__branches_hero = 'relationships___field_image_3___relationships___paragraph__branches_hero',
  relationships___field_image_3___relationships___paragraph__bullets = 'relationships___field_image_3___relationships___paragraph__bullets',
  relationships___field_image_3___relationships___taxonomy_term__pricelist_group = 'relationships___field_image_3___relationships___taxonomy_term__pricelist_group',
  relationships___field_image_3___relationships___paragraph__branches_device = 'relationships___field_image_3___relationships___paragraph__branches_device',
  relationships___field_image_3___relationships___paragraph__branches_recommendation = 'relationships___field_image_3___relationships___paragraph__branches_recommendation',
  relationships___field_image_3___relationships___site_setting_entity__accessories = 'relationships___field_image_3___relationships___site_setting_entity__accessories',
  relationships___field_image_3___relationships___site_setting_entity__branches = 'relationships___field_image_3___relationships___site_setting_entity__branches',
  relationships___field_image_3___relationships___site_setting_entity__about_us = 'relationships___field_image_3___relationships___site_setting_entity__about_us',
  relationships___field_image_3___relationships___paragraph__logos = 'relationships___field_image_3___relationships___paragraph__logos',
  relationships___field_image_3___relationships___paragraph__how_it_works = 'relationships___field_image_3___relationships___paragraph__how_it_works',
  relationships___field_image_3___relationships___paragraph__ico_and_text = 'relationships___field_image_3___relationships___paragraph__ico_and_text',
  relationships___field_image_3___relationships___paragraph__hero_1 = 'relationships___field_image_3___relationships___paragraph__hero_1',
  relationships___field_image_3___relationships___paragraph__comprehensive_solution_item = 'relationships___field_image_3___relationships___paragraph__comprehensive_solution_item',
  relationships___field_image_3___relationships___site_setting_entity__product = 'relationships___field_image_3___relationships___site_setting_entity__product',
  relationships___field_image_3___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_image_3___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_image_3___relationships___paragraph__porownanie_terminali = 'relationships___field_image_3___relationships___paragraph__porownanie_terminali',
  relationships___field_image_3___relationships___paragraph__zdjecie_opis_link = 'relationships___field_image_3___relationships___paragraph__zdjecie_opis_link',
  relationships___field_image_3___relationships___site_setting_entity__main_page = 'relationships___field_image_3___relationships___site_setting_entity__main_page',
  relationships___field_image_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_image_3___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_image_3___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_image_3___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_image_3___relationships___site_setting_entity__intercars = 'relationships___field_image_3___relationships___site_setting_entity__intercars',
  relationships___field_image_3___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_image_3___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_image_3___relationships___node__agreement = 'relationships___field_image_3___relationships___node__agreement',
  relationships___field_image_3___relationships___node__fiscalization = 'relationships___field_image_3___relationships___node__fiscalization',
  relationships___field_image_3___relationships___site_setting_entity__configurator = 'relationships___field_image_3___relationships___site_setting_entity__configurator',
  relationships___field_image_3___relationships___paragraph__social_link = 'relationships___field_image_3___relationships___paragraph__social_link',
  relationships___field_image_3___relationships___paragraph__make_an_appointment = 'relationships___field_image_3___relationships___paragraph__make_an_appointment',
  relationships___field_image_3___relationships___paragraph__linked_icons = 'relationships___field_image_3___relationships___paragraph__linked_icons',
  relationships___field_image_3___relationships___site_setting_entity__financing = 'relationships___field_image_3___relationships___site_setting_entity__financing',
  relationships___field_image_3___relationships___site_setting_entity__franchise = 'relationships___field_image_3___relationships___site_setting_entity__franchise',
  relationships___field_image_3___id = 'relationships___field_image_3___id',
  relationships___field_image_3___parent___id = 'relationships___field_image_3___parent___id',
  relationships___field_image_3___parent___children = 'relationships___field_image_3___parent___children',
  relationships___field_image_3___children = 'relationships___field_image_3___children',
  relationships___field_image_3___children___id = 'relationships___field_image_3___children___id',
  relationships___field_image_3___children___children = 'relationships___field_image_3___children___children',
  relationships___field_image_3___internal___content = 'relationships___field_image_3___internal___content',
  relationships___field_image_3___internal___contentDigest = 'relationships___field_image_3___internal___contentDigest',
  relationships___field_image_3___internal___description = 'relationships___field_image_3___internal___description',
  relationships___field_image_3___internal___fieldOwners = 'relationships___field_image_3___internal___fieldOwners',
  relationships___field_image_3___internal___ignoreType = 'relationships___field_image_3___internal___ignoreType',
  relationships___field_image_3___internal___mediaType = 'relationships___field_image_3___internal___mediaType',
  relationships___field_image_3___internal___owner = 'relationships___field_image_3___internal___owner',
  relationships___field_image_3___internal___type = 'relationships___field_image_3___internal___type',
  relationships___field_hero_video_thumbnail___localFile___birthtime = 'relationships___field_hero_video_thumbnail___localFile___birthtime',
  relationships___field_hero_video_thumbnail___localFile___birthtimeMs = 'relationships___field_hero_video_thumbnail___localFile___birthtimeMs',
  relationships___field_hero_video_thumbnail___localFile___publicURL = 'relationships___field_hero_video_thumbnail___localFile___publicURL',
  relationships___field_hero_video_thumbnail___localFile___sourceInstanceName = 'relationships___field_hero_video_thumbnail___localFile___sourceInstanceName',
  relationships___field_hero_video_thumbnail___localFile___absolutePath = 'relationships___field_hero_video_thumbnail___localFile___absolutePath',
  relationships___field_hero_video_thumbnail___localFile___relativePath = 'relationships___field_hero_video_thumbnail___localFile___relativePath',
  relationships___field_hero_video_thumbnail___localFile___extension = 'relationships___field_hero_video_thumbnail___localFile___extension',
  relationships___field_hero_video_thumbnail___localFile___size = 'relationships___field_hero_video_thumbnail___localFile___size',
  relationships___field_hero_video_thumbnail___localFile___prettySize = 'relationships___field_hero_video_thumbnail___localFile___prettySize',
  relationships___field_hero_video_thumbnail___localFile___modifiedTime = 'relationships___field_hero_video_thumbnail___localFile___modifiedTime',
  relationships___field_hero_video_thumbnail___localFile___accessTime = 'relationships___field_hero_video_thumbnail___localFile___accessTime',
  relationships___field_hero_video_thumbnail___localFile___changeTime = 'relationships___field_hero_video_thumbnail___localFile___changeTime',
  relationships___field_hero_video_thumbnail___localFile___birthTime = 'relationships___field_hero_video_thumbnail___localFile___birthTime',
  relationships___field_hero_video_thumbnail___localFile___root = 'relationships___field_hero_video_thumbnail___localFile___root',
  relationships___field_hero_video_thumbnail___localFile___dir = 'relationships___field_hero_video_thumbnail___localFile___dir',
  relationships___field_hero_video_thumbnail___localFile___base = 'relationships___field_hero_video_thumbnail___localFile___base',
  relationships___field_hero_video_thumbnail___localFile___ext = 'relationships___field_hero_video_thumbnail___localFile___ext',
  relationships___field_hero_video_thumbnail___localFile___name = 'relationships___field_hero_video_thumbnail___localFile___name',
  relationships___field_hero_video_thumbnail___localFile___relativeDirectory = 'relationships___field_hero_video_thumbnail___localFile___relativeDirectory',
  relationships___field_hero_video_thumbnail___localFile___dev = 'relationships___field_hero_video_thumbnail___localFile___dev',
  relationships___field_hero_video_thumbnail___localFile___mode = 'relationships___field_hero_video_thumbnail___localFile___mode',
  relationships___field_hero_video_thumbnail___localFile___nlink = 'relationships___field_hero_video_thumbnail___localFile___nlink',
  relationships___field_hero_video_thumbnail___localFile___uid = 'relationships___field_hero_video_thumbnail___localFile___uid',
  relationships___field_hero_video_thumbnail___localFile___gid = 'relationships___field_hero_video_thumbnail___localFile___gid',
  relationships___field_hero_video_thumbnail___localFile___rdev = 'relationships___field_hero_video_thumbnail___localFile___rdev',
  relationships___field_hero_video_thumbnail___localFile___blksize = 'relationships___field_hero_video_thumbnail___localFile___blksize',
  relationships___field_hero_video_thumbnail___localFile___ino = 'relationships___field_hero_video_thumbnail___localFile___ino',
  relationships___field_hero_video_thumbnail___localFile___blocks = 'relationships___field_hero_video_thumbnail___localFile___blocks',
  relationships___field_hero_video_thumbnail___localFile___atimeMs = 'relationships___field_hero_video_thumbnail___localFile___atimeMs',
  relationships___field_hero_video_thumbnail___localFile___mtimeMs = 'relationships___field_hero_video_thumbnail___localFile___mtimeMs',
  relationships___field_hero_video_thumbnail___localFile___ctimeMs = 'relationships___field_hero_video_thumbnail___localFile___ctimeMs',
  relationships___field_hero_video_thumbnail___localFile___atime = 'relationships___field_hero_video_thumbnail___localFile___atime',
  relationships___field_hero_video_thumbnail___localFile___mtime = 'relationships___field_hero_video_thumbnail___localFile___mtime',
  relationships___field_hero_video_thumbnail___localFile___ctime = 'relationships___field_hero_video_thumbnail___localFile___ctime',
  relationships___field_hero_video_thumbnail___localFile___url = 'relationships___field_hero_video_thumbnail___localFile___url',
  relationships___field_hero_video_thumbnail___localFile___id = 'relationships___field_hero_video_thumbnail___localFile___id',
  relationships___field_hero_video_thumbnail___localFile___children = 'relationships___field_hero_video_thumbnail___localFile___children',
  relationships___field_hero_video_thumbnail___uri___url = 'relationships___field_hero_video_thumbnail___uri___url',
  relationships___field_hero_video_thumbnail___drupal_id = 'relationships___field_hero_video_thumbnail___drupal_id',
  relationships___field_hero_video_thumbnail___drupal_internal__fid = 'relationships___field_hero_video_thumbnail___drupal_internal__fid',
  relationships___field_hero_video_thumbnail___langcode = 'relationships___field_hero_video_thumbnail___langcode',
  relationships___field_hero_video_thumbnail___filename = 'relationships___field_hero_video_thumbnail___filename',
  relationships___field_hero_video_thumbnail___filemime = 'relationships___field_hero_video_thumbnail___filemime',
  relationships___field_hero_video_thumbnail___filesize = 'relationships___field_hero_video_thumbnail___filesize',
  relationships___field_hero_video_thumbnail___status = 'relationships___field_hero_video_thumbnail___status',
  relationships___field_hero_video_thumbnail___created = 'relationships___field_hero_video_thumbnail___created',
  relationships___field_hero_video_thumbnail___changed = 'relationships___field_hero_video_thumbnail___changed',
  relationships___field_hero_video_thumbnail___uuid = 'relationships___field_hero_video_thumbnail___uuid',
  relationships___field_hero_video_thumbnail___relationships___node__blog_post = 'relationships___field_hero_video_thumbnail___relationships___node__blog_post',
  relationships___field_hero_video_thumbnail___relationships___node__devices = 'relationships___field_hero_video_thumbnail___relationships___node__devices',
  relationships___field_hero_video_thumbnail___relationships___node__accessories = 'relationships___field_hero_video_thumbnail___relationships___node__accessories',
  relationships___field_hero_video_thumbnail___relationships___paragraph__news_image_title_text = 'relationships___field_hero_video_thumbnail___relationships___paragraph__news_image_title_text',
  relationships___field_hero_video_thumbnail___relationships___node__cases = 'relationships___field_hero_video_thumbnail___relationships___node__cases',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_what_is_ipos = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_what_is_ipos',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_features = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_features',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_testimonials = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_testimonials',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_discounts = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_discounts',
  relationships___field_hero_video_thumbnail___relationships___node__news = 'relationships___field_hero_video_thumbnail___relationships___node__news',
  relationships___field_hero_video_thumbnail___relationships___node__branches = 'relationships___field_hero_video_thumbnail___relationships___node__branches',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_hero = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_hero',
  relationships___field_hero_video_thumbnail___relationships___paragraph__bullets = 'relationships___field_hero_video_thumbnail___relationships___paragraph__bullets',
  relationships___field_hero_video_thumbnail___relationships___taxonomy_term__pricelist_group = 'relationships___field_hero_video_thumbnail___relationships___taxonomy_term__pricelist_group',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_device = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_device',
  relationships___field_hero_video_thumbnail___relationships___paragraph__branches_recommendation = 'relationships___field_hero_video_thumbnail___relationships___paragraph__branches_recommendation',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__accessories = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__accessories',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__branches = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__branches',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__about_us = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__about_us',
  relationships___field_hero_video_thumbnail___relationships___paragraph__logos = 'relationships___field_hero_video_thumbnail___relationships___paragraph__logos',
  relationships___field_hero_video_thumbnail___relationships___paragraph__how_it_works = 'relationships___field_hero_video_thumbnail___relationships___paragraph__how_it_works',
  relationships___field_hero_video_thumbnail___relationships___paragraph__ico_and_text = 'relationships___field_hero_video_thumbnail___relationships___paragraph__ico_and_text',
  relationships___field_hero_video_thumbnail___relationships___paragraph__hero_1 = 'relationships___field_hero_video_thumbnail___relationships___paragraph__hero_1',
  relationships___field_hero_video_thumbnail___relationships___paragraph__comprehensive_solution_item = 'relationships___field_hero_video_thumbnail___relationships___paragraph__comprehensive_solution_item',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__product = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__product',
  relationships___field_hero_video_thumbnail___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_hero_video_thumbnail___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_hero_video_thumbnail___relationships___paragraph__porownanie_terminali = 'relationships___field_hero_video_thumbnail___relationships___paragraph__porownanie_terminali',
  relationships___field_hero_video_thumbnail___relationships___paragraph__zdjecie_opis_link = 'relationships___field_hero_video_thumbnail___relationships___paragraph__zdjecie_opis_link',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__main_page = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__main_page',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_hero_video_thumbnail___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_hero_video_thumbnail___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__intercars = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__intercars',
  relationships___field_hero_video_thumbnail___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_hero_video_thumbnail___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_hero_video_thumbnail___relationships___node__agreement = 'relationships___field_hero_video_thumbnail___relationships___node__agreement',
  relationships___field_hero_video_thumbnail___relationships___node__fiscalization = 'relationships___field_hero_video_thumbnail___relationships___node__fiscalization',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__configurator = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__configurator',
  relationships___field_hero_video_thumbnail___relationships___paragraph__social_link = 'relationships___field_hero_video_thumbnail___relationships___paragraph__social_link',
  relationships___field_hero_video_thumbnail___relationships___paragraph__make_an_appointment = 'relationships___field_hero_video_thumbnail___relationships___paragraph__make_an_appointment',
  relationships___field_hero_video_thumbnail___relationships___paragraph__linked_icons = 'relationships___field_hero_video_thumbnail___relationships___paragraph__linked_icons',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__financing = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__financing',
  relationships___field_hero_video_thumbnail___relationships___site_setting_entity__franchise = 'relationships___field_hero_video_thumbnail___relationships___site_setting_entity__franchise',
  relationships___field_hero_video_thumbnail___id = 'relationships___field_hero_video_thumbnail___id',
  relationships___field_hero_video_thumbnail___parent___id = 'relationships___field_hero_video_thumbnail___parent___id',
  relationships___field_hero_video_thumbnail___parent___children = 'relationships___field_hero_video_thumbnail___parent___children',
  relationships___field_hero_video_thumbnail___children = 'relationships___field_hero_video_thumbnail___children',
  relationships___field_hero_video_thumbnail___children___id = 'relationships___field_hero_video_thumbnail___children___id',
  relationships___field_hero_video_thumbnail___children___children = 'relationships___field_hero_video_thumbnail___children___children',
  relationships___field_hero_video_thumbnail___internal___content = 'relationships___field_hero_video_thumbnail___internal___content',
  relationships___field_hero_video_thumbnail___internal___contentDigest = 'relationships___field_hero_video_thumbnail___internal___contentDigest',
  relationships___field_hero_video_thumbnail___internal___description = 'relationships___field_hero_video_thumbnail___internal___description',
  relationships___field_hero_video_thumbnail___internal___fieldOwners = 'relationships___field_hero_video_thumbnail___internal___fieldOwners',
  relationships___field_hero_video_thumbnail___internal___ignoreType = 'relationships___field_hero_video_thumbnail___internal___ignoreType',
  relationships___field_hero_video_thumbnail___internal___mediaType = 'relationships___field_hero_video_thumbnail___internal___mediaType',
  relationships___field_hero_video_thumbnail___internal___owner = 'relationships___field_hero_video_thumbnail___internal___owner',
  relationships___field_hero_video_thumbnail___internal___type = 'relationships___field_hero_video_thumbnail___internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  field_components = 'field_components',
  field_components___target_revision_id = 'field_components___target_revision_id',
  field_components___drupal_internal__target_id = 'field_components___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__ProductFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_hero_button_text?: Maybe<StringQueryOperatorInput>;
  field_hero_video?: Maybe<StringQueryOperatorInput>;
  field_hero_headline?: Maybe<Field_Hero_HeadlineFilterInput>;
  field_hero_image?: Maybe<Field_Hero_ImageFilterInput>;
  field_hero_image_mobile?: Maybe<Field_Hero_Image_MobileFilterInput>;
  field_hero_text?: Maybe<Field_Hero_TextFilterInput>;
  field_image_1?: Maybe<Field_Image_1FilterInput>;
  field_image_2?: Maybe<Field_Image_2FilterInput>;
  field_image_3?: Maybe<Field_Image_3FilterInput>;
  field_hero_video_thumbnail?: Maybe<Field_Hero_Video_ThumbnailFilterInput>;
  field_kasa_online?: Maybe<BooleanQueryOperatorInput>;
  field_porownaj_taryfy_w_cenniku?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_settings_text_7?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  field_text_2?: Maybe<Field_Text_2FilterInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Site_Setting_Entity__ProductRelationshipsFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__ProductSite_Setting_Entity_TypeFilterInput>;
  field_components?: Maybe<Site_Setting_Entity__ProductField_ComponentsFilterListInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__ProductFilterListInput = {
  elemMatch?: Maybe<Site_Setting_Entity__ProductFilterInput>;
};

export type Site_Setting_Entity__ProductGroupConnection = {
  __typename?: 'site_setting_entity__productGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__ProductEdge>;
  nodes: Array<Site_Setting_Entity__Product>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ProductRelationships = {
  __typename?: 'site_setting_entity__productRelationships';
  field_components?: Maybe<Array<Maybe<ProductRelatedComponents>>>;
  field_main_image?: Maybe<File__File>;
  field_hero_image?: Maybe<File__File>;
  field_hero_image_mobile?: Maybe<File__File>;
  field_image_1?: Maybe<File__File>;
  field_image_2?: Maybe<File__File>;
  field_image_3?: Maybe<File__File>;
  field_hero_video_thumbnail?: Maybe<File__File>;
};

export type Site_Setting_Entity__ProductRelationshipsFilterInput = {
  field_main_image?: Maybe<File__FileFilterInput>;
  field_hero_image?: Maybe<File__FileFilterInput>;
  field_hero_image_mobile?: Maybe<File__FileFilterInput>;
  field_image_1?: Maybe<File__FileFilterInput>;
  field_image_2?: Maybe<File__FileFilterInput>;
  field_image_3?: Maybe<File__FileFilterInput>;
  field_hero_video_thumbnail?: Maybe<File__FileFilterInput>;
};

export type Site_Setting_Entity__ProductSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__productSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__ProductSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__ProductSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__ProductFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Simple_Form = Node & {
  __typename?: 'site_setting_entity__simple_form';
  field_fr_form_email?: Maybe<Scalars['String']>;
  langcode?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  field_agreement_1?: Maybe<Site_Setting_Entity__Simple_FormField_Agreement_1>;
  field_fr_form_name?: Maybe<Scalars['String']>;
  field_settings_button_text?: Maybe<Scalars['String']>;
  field_settings_text_1?: Maybe<Scalars['String']>;
  field_settings_text_2?: Maybe<Scalars['String']>;
  field_settings_text_3?: Maybe<Scalars['String']>;
  field_settings_text_4?: Maybe<Scalars['String']>;
  field_settings_text_5?: Maybe<Scalars['String']>;
  field_settings_text_6?: Maybe<Scalars['String']>;
  field_tooltip?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  field_modal_1?: Maybe<Site_Setting_Entity__Simple_FormField_Modal_1>;
  field_modal_2?: Maybe<Site_Setting_Entity__Simple_FormField_Modal_2>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__Simple_FormSite_Setting_Entity_Type>;
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__Simple_FormCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormConnection = {
  __typename?: 'site_setting_entity__simple_formConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Simple_FormEdge>;
  nodes: Array<Site_Setting_Entity__Simple_Form>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__Simple_FormGroupConnection>;
};

export type Site_Setting_Entity__Simple_FormConnectionDistinctArgs = {
  field: Site_Setting_Entity__Simple_FormFieldsEnum;
};

export type Site_Setting_Entity__Simple_FormConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__Simple_FormFieldsEnum;
};

export type Site_Setting_Entity__Simple_FormEdge = {
  __typename?: 'site_setting_entity__simple_formEdge';
  next?: Maybe<Site_Setting_Entity__Simple_Form>;
  node: Site_Setting_Entity__Simple_Form;
  previous?: Maybe<Site_Setting_Entity__Simple_Form>;
};

export type Site_Setting_Entity__Simple_FormField_Agreement_1 = {
  __typename?: 'site_setting_entity__simple_formField_agreement_1';
  value?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormField_Agreement_1FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Simple_FormField_Modal_1 = {
  __typename?: 'site_setting_entity__simple_formField_modal_1';
  value?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormField_Modal_1FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Simple_FormField_Modal_2 = {
  __typename?: 'site_setting_entity__simple_formField_modal_2';
  value?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormField_Modal_2FilterInput = {
  value?: Maybe<StringQueryOperatorInput>;
};

export enum Site_Setting_Entity__Simple_FormFieldsEnum {
  field_fr_form_email = 'field_fr_form_email',
  langcode = 'langcode',
  drupal_id = 'drupal_id',
  id = 'id',
  field_agreement_1___value = 'field_agreement_1___value',
  field_fr_form_name = 'field_fr_form_name',
  field_settings_button_text = 'field_settings_button_text',
  field_settings_text_1 = 'field_settings_text_1',
  field_settings_text_2 = 'field_settings_text_2',
  field_settings_text_3 = 'field_settings_text_3',
  field_settings_text_4 = 'field_settings_text_4',
  field_settings_text_5 = 'field_settings_text_5',
  field_settings_text_6 = 'field_settings_text_6',
  field_tooltip = 'field_tooltip',
  fieldset = 'fieldset',
  name = 'name',
  field_modal_1___value = 'field_modal_1___value',
  field_modal_2___value = 'field_modal_2___value',
  drupal_internal__id = 'drupal_internal__id',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__Simple_FormFilterInput = {
  field_fr_form_email?: Maybe<StringQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  field_agreement_1?: Maybe<Site_Setting_Entity__Simple_FormField_Agreement_1FilterInput>;
  field_fr_form_name?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_text_1?: Maybe<StringQueryOperatorInput>;
  field_settings_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_text_3?: Maybe<StringQueryOperatorInput>;
  field_settings_text_4?: Maybe<StringQueryOperatorInput>;
  field_settings_text_5?: Maybe<StringQueryOperatorInput>;
  field_settings_text_6?: Maybe<StringQueryOperatorInput>;
  field_tooltip?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  field_modal_1?: Maybe<Site_Setting_Entity__Simple_FormField_Modal_1FilterInput>;
  field_modal_2?: Maybe<Site_Setting_Entity__Simple_FormField_Modal_2FilterInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Simple_FormSite_Setting_Entity_TypeFilterInput
  >;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__Simple_FormGroupConnection = {
  __typename?: 'site_setting_entity__simple_formGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Simple_FormEdge>;
  nodes: Array<Site_Setting_Entity__Simple_Form>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__simple_formSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Simple_FormSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Simple_FormSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__Simple_FormFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Thank_You_Pages = Node & {
  __typename?: 'site_setting_entity__thank_you_pages';
  langcode?: Maybe<Scalars['String']>;
  field_settings_button_text?: Maybe<Scalars['String']>;
  field_settings_button_text_2?: Maybe<Scalars['String']>;
  field_settings_tresc?: Maybe<Field_Settings_Tresc>;
  field_meta_title?: Maybe<Scalars['String']>;
  field_meta_title_1?: Maybe<Scalars['String']>;
  field_meta_description?: Maybe<Scalars['String']>;
  field_meta_description_1?: Maybe<Scalars['String']>;
  field_text_1?: Maybe<Field_Text_1>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__Thank_You_PagesSite_Setting_Entity_Type>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__Thank_You_PagesCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Thank_You_PagesChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Thank_You_PagesContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Thank_You_PagesContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Thank_You_PagesConnection = {
  __typename?: 'site_setting_entity__thank_you_pagesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Thank_You_PagesEdge>;
  nodes: Array<Site_Setting_Entity__Thank_You_Pages>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__Thank_You_PagesGroupConnection>;
};

export type Site_Setting_Entity__Thank_You_PagesConnectionDistinctArgs = {
  field: Site_Setting_Entity__Thank_You_PagesFieldsEnum;
};

export type Site_Setting_Entity__Thank_You_PagesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__Thank_You_PagesFieldsEnum;
};

export type Site_Setting_Entity__Thank_You_PagesEdge = {
  __typename?: 'site_setting_entity__thank_you_pagesEdge';
  next?: Maybe<Site_Setting_Entity__Thank_You_Pages>;
  node: Site_Setting_Entity__Thank_You_Pages;
  previous?: Maybe<Site_Setting_Entity__Thank_You_Pages>;
};

export enum Site_Setting_Entity__Thank_You_PagesFieldsEnum {
  langcode = 'langcode',
  field_settings_button_text = 'field_settings_button_text',
  field_settings_button_text_2 = 'field_settings_button_text_2',
  field_settings_tresc___value = 'field_settings_tresc___value',
  field_meta_title = 'field_meta_title',
  field_meta_title_1 = 'field_meta_title_1',
  field_meta_description = 'field_meta_description',
  field_meta_description_1 = 'field_meta_description_1',
  field_text_1___value = 'field_text_1___value',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__Thank_You_PagesFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text?: Maybe<StringQueryOperatorInput>;
  field_settings_button_text_2?: Maybe<StringQueryOperatorInput>;
  field_settings_tresc?: Maybe<Field_Settings_TrescFilterInput>;
  field_meta_title?: Maybe<StringQueryOperatorInput>;
  field_meta_title_1?: Maybe<StringQueryOperatorInput>;
  field_meta_description?: Maybe<StringQueryOperatorInput>;
  field_meta_description_1?: Maybe<StringQueryOperatorInput>;
  field_text_1?: Maybe<Field_Text_1FilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<
    Site_Setting_Entity__Thank_You_PagesSite_Setting_Entity_TypeFilterInput
  >;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__Thank_You_PagesGroupConnection = {
  __typename?: 'site_setting_entity__thank_you_pagesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__Thank_You_PagesEdge>;
  nodes: Array<Site_Setting_Entity__Thank_You_Pages>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Thank_You_PagesSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__thank_you_pagesSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__Thank_You_PagesSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__Thank_You_PagesSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__Thank_You_PagesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Site_Setting_Entity__Urls = Node & {
  __typename?: 'site_setting_entity__urls';
  langcode?: Maybe<Scalars['String']>;
  field_urls_array?: Maybe<Array<Maybe<Scalars['String']>>>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fieldset?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_status?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  content_translation_changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__UrlsSite_Setting_Entity_Type>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Site_Setting_Entity__UrlsCreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__UrlsChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__UrlsContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__UrlsContent_Translation_ChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__UrlsConnection = {
  __typename?: 'site_setting_entity__urlsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__UrlsEdge>;
  nodes: Array<Site_Setting_Entity__Urls>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Site_Setting_Entity__UrlsGroupConnection>;
};

export type Site_Setting_Entity__UrlsConnectionDistinctArgs = {
  field: Site_Setting_Entity__UrlsFieldsEnum;
};

export type Site_Setting_Entity__UrlsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Site_Setting_Entity__UrlsFieldsEnum;
};

export type Site_Setting_Entity__UrlsEdge = {
  __typename?: 'site_setting_entity__urlsEdge';
  next?: Maybe<Site_Setting_Entity__Urls>;
  node: Site_Setting_Entity__Urls;
  previous?: Maybe<Site_Setting_Entity__Urls>;
};

export enum Site_Setting_Entity__UrlsFieldsEnum {
  langcode = 'langcode',
  field_urls_array = 'field_urls_array',
  drupal_id = 'drupal_id',
  drupal_internal__id = 'drupal_internal__id',
  name = 'name',
  fieldset = 'fieldset',
  status = 'status',
  created = 'created',
  changed = 'changed',
  default_langcode = 'default_langcode',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_status = 'content_translation_status',
  content_translation_created = 'content_translation_created',
  content_translation_changed = 'content_translation_changed',
  uuid = 'uuid',
  site_setting_entity_type___drupal_internal__target_id = 'site_setting_entity_type___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Site_Setting_Entity__UrlsFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  field_urls_array?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__id?: Maybe<IntQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  fieldset?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  created?: Maybe<DateQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_status?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  content_translation_changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  site_setting_entity_type?: Maybe<Site_Setting_Entity__UrlsSite_Setting_Entity_TypeFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Site_Setting_Entity__UrlsGroupConnection = {
  __typename?: 'site_setting_entity__urlsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Site_Setting_Entity__UrlsEdge>;
  nodes: Array<Site_Setting_Entity__Urls>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__UrlsSite_Setting_Entity_Type = {
  __typename?: 'site_setting_entity__urlsSite_setting_entity_type';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Site_Setting_Entity__UrlsSite_Setting_Entity_TypeFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Site_Setting_Entity__UrlsSortInput = {
  fields?: Maybe<Array<Maybe<Site_Setting_Entity__UrlsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type SiteConnection = {
  __typename?: 'SiteConnection';
  totalCount: Scalars['Int'];
  edges: Array<SiteEdge>;
  nodes: Array<Site>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<SiteGroupConnection>;
};

export type SiteConnectionDistinctArgs = {
  field: SiteFieldsEnum;
};

export type SiteConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: SiteFieldsEnum;
};

export type SiteEdge = {
  __typename?: 'SiteEdge';
  next?: Maybe<Site>;
  node: Site;
  previous?: Maybe<Site>;
};

export enum SiteFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  siteMetadata___siteUrl = 'siteMetadata___siteUrl',
  siteMetadata___title = 'siteMetadata___title',
  siteMetadata___description = 'siteMetadata___description',
  port = 'port',
  host = 'host',
  pathPrefix = 'pathPrefix',
  polyfill = 'polyfill',
  buildTime = 'buildTime',
}

export type SiteFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  siteMetadata?: Maybe<SiteSiteMetadataFilterInput>;
  port?: Maybe<IntQueryOperatorInput>;
  host?: Maybe<StringQueryOperatorInput>;
  pathPrefix?: Maybe<StringQueryOperatorInput>;
  polyfill?: Maybe<BooleanQueryOperatorInput>;
  buildTime?: Maybe<DateQueryOperatorInput>;
};

export type SiteGroupConnection = {
  __typename?: 'SiteGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<SiteEdge>;
  nodes: Array<Site>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type SitePage = Node & {
  __typename?: 'SitePage';
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  path?: Maybe<Scalars['String']>;
  internalComponentName?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  componentChunkName?: Maybe<Scalars['String']>;
  isCreatedByStatefulCreatePages?: Maybe<Scalars['Boolean']>;
  context?: Maybe<SitePageContext>;
  pluginCreator?: Maybe<SitePlugin>;
  pluginCreatorId?: Maybe<Scalars['String']>;
  componentPath?: Maybe<Scalars['String']>;
};

export type SitePageConnection = {
  __typename?: 'SitePageConnection';
  totalCount: Scalars['Int'];
  edges: Array<SitePageEdge>;
  nodes: Array<SitePage>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<SitePageGroupConnection>;
};

export type SitePageConnectionDistinctArgs = {
  field: SitePageFieldsEnum;
};

export type SitePageConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: SitePageFieldsEnum;
};

export type SitePageContext = {
  __typename?: 'SitePageContext';
  langcode?: Maybe<Scalars['String']>;
  allUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  otherLangUrl?: Maybe<SitePageContextOtherLangUrl>;
  id?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['Boolean']>;
};

export type SitePageContextFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  allUrls?: Maybe<StringQueryOperatorInput>;
  otherLangUrl?: Maybe<SitePageContextOtherLangUrlFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  layout?: Maybe<StringQueryOperatorInput>;
  slug?: Maybe<StringQueryOperatorInput>;
  category?: Maybe<BooleanQueryOperatorInput>;
};

export type SitePageContextOtherLangUrl = {
  __typename?: 'SitePageContextOtherLangUrl';
  pl?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
};

export type SitePageContextOtherLangUrlFilterInput = {
  pl?: Maybe<StringQueryOperatorInput>;
  en?: Maybe<StringQueryOperatorInput>;
};

export type SitePageEdge = {
  __typename?: 'SitePageEdge';
  next?: Maybe<SitePage>;
  node: SitePage;
  previous?: Maybe<SitePage>;
};

export enum SitePageFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  path = 'path',
  internalComponentName = 'internalComponentName',
  component = 'component',
  componentChunkName = 'componentChunkName',
  isCreatedByStatefulCreatePages = 'isCreatedByStatefulCreatePages',
  context___langcode = 'context___langcode',
  context___allUrls = 'context___allUrls',
  context___otherLangUrl___pl = 'context___otherLangUrl___pl',
  context___otherLangUrl___en = 'context___otherLangUrl___en',
  context___id = 'context___id',
  context___layout = 'context___layout',
  context___slug = 'context___slug',
  context___category = 'context___category',
  pluginCreator___id = 'pluginCreator___id',
  pluginCreator___parent___id = 'pluginCreator___parent___id',
  pluginCreator___parent___parent___id = 'pluginCreator___parent___parent___id',
  pluginCreator___parent___parent___children = 'pluginCreator___parent___parent___children',
  pluginCreator___parent___children = 'pluginCreator___parent___children',
  pluginCreator___parent___children___id = 'pluginCreator___parent___children___id',
  pluginCreator___parent___children___children = 'pluginCreator___parent___children___children',
  pluginCreator___parent___internal___content = 'pluginCreator___parent___internal___content',
  pluginCreator___parent___internal___contentDigest = 'pluginCreator___parent___internal___contentDigest',
  pluginCreator___parent___internal___description = 'pluginCreator___parent___internal___description',
  pluginCreator___parent___internal___fieldOwners = 'pluginCreator___parent___internal___fieldOwners',
  pluginCreator___parent___internal___ignoreType = 'pluginCreator___parent___internal___ignoreType',
  pluginCreator___parent___internal___mediaType = 'pluginCreator___parent___internal___mediaType',
  pluginCreator___parent___internal___owner = 'pluginCreator___parent___internal___owner',
  pluginCreator___parent___internal___type = 'pluginCreator___parent___internal___type',
  pluginCreator___children = 'pluginCreator___children',
  pluginCreator___children___id = 'pluginCreator___children___id',
  pluginCreator___children___parent___id = 'pluginCreator___children___parent___id',
  pluginCreator___children___parent___children = 'pluginCreator___children___parent___children',
  pluginCreator___children___children = 'pluginCreator___children___children',
  pluginCreator___children___children___id = 'pluginCreator___children___children___id',
  pluginCreator___children___children___children = 'pluginCreator___children___children___children',
  pluginCreator___children___internal___content = 'pluginCreator___children___internal___content',
  pluginCreator___children___internal___contentDigest = 'pluginCreator___children___internal___contentDigest',
  pluginCreator___children___internal___description = 'pluginCreator___children___internal___description',
  pluginCreator___children___internal___fieldOwners = 'pluginCreator___children___internal___fieldOwners',
  pluginCreator___children___internal___ignoreType = 'pluginCreator___children___internal___ignoreType',
  pluginCreator___children___internal___mediaType = 'pluginCreator___children___internal___mediaType',
  pluginCreator___children___internal___owner = 'pluginCreator___children___internal___owner',
  pluginCreator___children___internal___type = 'pluginCreator___children___internal___type',
  pluginCreator___internal___content = 'pluginCreator___internal___content',
  pluginCreator___internal___contentDigest = 'pluginCreator___internal___contentDigest',
  pluginCreator___internal___description = 'pluginCreator___internal___description',
  pluginCreator___internal___fieldOwners = 'pluginCreator___internal___fieldOwners',
  pluginCreator___internal___ignoreType = 'pluginCreator___internal___ignoreType',
  pluginCreator___internal___mediaType = 'pluginCreator___internal___mediaType',
  pluginCreator___internal___owner = 'pluginCreator___internal___owner',
  pluginCreator___internal___type = 'pluginCreator___internal___type',
  pluginCreator___resolve = 'pluginCreator___resolve',
  pluginCreator___name = 'pluginCreator___name',
  pluginCreator___version = 'pluginCreator___version',
  pluginCreator___pluginOptions___features = 'pluginCreator___pluginOptions___features',
  pluginCreator___pluginOptions___name = 'pluginCreator___pluginOptions___name',
  pluginCreator___pluginOptions___path = 'pluginCreator___pluginOptions___path',
  pluginCreator___pluginOptions___baseUrl = 'pluginCreator___pluginOptions___baseUrl',
  pluginCreator___pluginOptions___apiBase = 'pluginCreator___pluginOptions___apiBase',
  pluginCreator___pluginOptions___concurrentFileRequests = 'pluginCreator___pluginOptions___concurrentFileRequests',
  pluginCreator___pluginOptions___i18n = 'pluginCreator___pluginOptions___i18n',
  pluginCreator___pluginOptions___i18n___langcode = 'pluginCreator___pluginOptions___i18n___langcode',
  pluginCreator___pluginOptions___layout = 'pluginCreator___pluginOptions___layout',
  pluginCreator___pluginOptions___typekit___id = 'pluginCreator___pluginOptions___typekit___id',
  pluginCreator___pluginOptions___short_name = 'pluginCreator___pluginOptions___short_name',
  pluginCreator___pluginOptions___start_url = 'pluginCreator___pluginOptions___start_url',
  pluginCreator___pluginOptions___background_color = 'pluginCreator___pluginOptions___background_color',
  pluginCreator___pluginOptions___theme_color = 'pluginCreator___pluginOptions___theme_color',
  pluginCreator___pluginOptions___display = 'pluginCreator___pluginOptions___display',
  pluginCreator___pluginOptions___icon = 'pluginCreator___pluginOptions___icon',
  pluginCreator___pluginOptions___icons = 'pluginCreator___pluginOptions___icons',
  pluginCreator___pluginOptions___icons___src = 'pluginCreator___pluginOptions___icons___src',
  pluginCreator___pluginOptions___icons___sizes = 'pluginCreator___pluginOptions___icons___sizes',
  pluginCreator___pluginOptions___icons___type = 'pluginCreator___pluginOptions___icons___type',
  pluginCreator___pluginOptions___pixelId = 'pluginCreator___pluginOptions___pixelId',
  pluginCreator___pluginOptions___exclude = 'pluginCreator___pluginOptions___exclude',
  pluginCreator___pluginOptions___redirect = 'pluginCreator___pluginOptions___redirect',
  pluginCreator___pluginOptions___custom = 'pluginCreator___pluginOptions___custom',
  pluginCreator___pluginOptions___key = 'pluginCreator___pluginOptions___key',
  pluginCreator___pluginOptions___id = 'pluginCreator___pluginOptions___id',
  pluginCreator___pluginOptions___pathCheck = 'pluginCreator___pluginOptions___pathCheck',
  pluginCreator___nodeAPIs = 'pluginCreator___nodeAPIs',
  pluginCreator___browserAPIs = 'pluginCreator___browserAPIs',
  pluginCreator___ssrAPIs = 'pluginCreator___ssrAPIs',
  pluginCreator___pluginFilepath = 'pluginCreator___pluginFilepath',
  pluginCreator___packageJson___name = 'pluginCreator___packageJson___name',
  pluginCreator___packageJson___description = 'pluginCreator___packageJson___description',
  pluginCreator___packageJson___version = 'pluginCreator___packageJson___version',
  pluginCreator___packageJson___main = 'pluginCreator___packageJson___main',
  pluginCreator___packageJson___author = 'pluginCreator___packageJson___author',
  pluginCreator___packageJson___license = 'pluginCreator___packageJson___license',
  pluginCreator___packageJson___dependencies = 'pluginCreator___packageJson___dependencies',
  pluginCreator___packageJson___dependencies___name = 'pluginCreator___packageJson___dependencies___name',
  pluginCreator___packageJson___dependencies___version = 'pluginCreator___packageJson___dependencies___version',
  pluginCreator___packageJson___devDependencies = 'pluginCreator___packageJson___devDependencies',
  pluginCreator___packageJson___devDependencies___name = 'pluginCreator___packageJson___devDependencies___name',
  pluginCreator___packageJson___devDependencies___version = 'pluginCreator___packageJson___devDependencies___version',
  pluginCreator___packageJson___peerDependencies = 'pluginCreator___packageJson___peerDependencies',
  pluginCreator___packageJson___peerDependencies___name = 'pluginCreator___packageJson___peerDependencies___name',
  pluginCreator___packageJson___peerDependencies___version = 'pluginCreator___packageJson___peerDependencies___version',
  pluginCreator___packageJson___keywords = 'pluginCreator___packageJson___keywords',
  pluginCreatorId = 'pluginCreatorId',
  componentPath = 'componentPath',
}

export type SitePageFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  path?: Maybe<StringQueryOperatorInput>;
  internalComponentName?: Maybe<StringQueryOperatorInput>;
  component?: Maybe<StringQueryOperatorInput>;
  componentChunkName?: Maybe<StringQueryOperatorInput>;
  isCreatedByStatefulCreatePages?: Maybe<BooleanQueryOperatorInput>;
  context?: Maybe<SitePageContextFilterInput>;
  pluginCreator?: Maybe<SitePluginFilterInput>;
  pluginCreatorId?: Maybe<StringQueryOperatorInput>;
  componentPath?: Maybe<StringQueryOperatorInput>;
};

export type SitePageGroupConnection = {
  __typename?: 'SitePageGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<SitePageEdge>;
  nodes: Array<SitePage>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type SitePageSortInput = {
  fields?: Maybe<Array<Maybe<SitePageFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type SitePlugin = Node & {
  __typename?: 'SitePlugin';
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  resolve?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  pluginOptions?: Maybe<SitePluginPluginOptions>;
  nodeAPIs?: Maybe<Array<Maybe<Scalars['String']>>>;
  browserAPIs?: Maybe<Array<Maybe<Scalars['String']>>>;
  ssrAPIs?: Maybe<Array<Maybe<Scalars['String']>>>;
  pluginFilepath?: Maybe<Scalars['String']>;
  packageJson?: Maybe<SitePluginPackageJson>;
};

export type SitePluginConnection = {
  __typename?: 'SitePluginConnection';
  totalCount: Scalars['Int'];
  edges: Array<SitePluginEdge>;
  nodes: Array<SitePlugin>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<SitePluginGroupConnection>;
};

export type SitePluginConnectionDistinctArgs = {
  field: SitePluginFieldsEnum;
};

export type SitePluginConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: SitePluginFieldsEnum;
};

export type SitePluginEdge = {
  __typename?: 'SitePluginEdge';
  next?: Maybe<SitePlugin>;
  node: SitePlugin;
  previous?: Maybe<SitePlugin>;
};

export enum SitePluginFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  resolve = 'resolve',
  name = 'name',
  version = 'version',
  pluginOptions___features = 'pluginOptions___features',
  pluginOptions___name = 'pluginOptions___name',
  pluginOptions___path = 'pluginOptions___path',
  pluginOptions___baseUrl = 'pluginOptions___baseUrl',
  pluginOptions___apiBase = 'pluginOptions___apiBase',
  pluginOptions___concurrentFileRequests = 'pluginOptions___concurrentFileRequests',
  pluginOptions___i18n = 'pluginOptions___i18n',
  pluginOptions___i18n___langcode = 'pluginOptions___i18n___langcode',
  pluginOptions___layout = 'pluginOptions___layout',
  pluginOptions___typekit___id = 'pluginOptions___typekit___id',
  pluginOptions___short_name = 'pluginOptions___short_name',
  pluginOptions___start_url = 'pluginOptions___start_url',
  pluginOptions___background_color = 'pluginOptions___background_color',
  pluginOptions___theme_color = 'pluginOptions___theme_color',
  pluginOptions___display = 'pluginOptions___display',
  pluginOptions___icon = 'pluginOptions___icon',
  pluginOptions___icons = 'pluginOptions___icons',
  pluginOptions___icons___src = 'pluginOptions___icons___src',
  pluginOptions___icons___sizes = 'pluginOptions___icons___sizes',
  pluginOptions___icons___type = 'pluginOptions___icons___type',
  pluginOptions___pixelId = 'pluginOptions___pixelId',
  pluginOptions___exclude = 'pluginOptions___exclude',
  pluginOptions___redirect = 'pluginOptions___redirect',
  pluginOptions___custom = 'pluginOptions___custom',
  pluginOptions___key = 'pluginOptions___key',
  pluginOptions___id = 'pluginOptions___id',
  pluginOptions___pathCheck = 'pluginOptions___pathCheck',
  nodeAPIs = 'nodeAPIs',
  browserAPIs = 'browserAPIs',
  ssrAPIs = 'ssrAPIs',
  pluginFilepath = 'pluginFilepath',
  packageJson___name = 'packageJson___name',
  packageJson___description = 'packageJson___description',
  packageJson___version = 'packageJson___version',
  packageJson___main = 'packageJson___main',
  packageJson___author = 'packageJson___author',
  packageJson___license = 'packageJson___license',
  packageJson___dependencies = 'packageJson___dependencies',
  packageJson___dependencies___name = 'packageJson___dependencies___name',
  packageJson___dependencies___version = 'packageJson___dependencies___version',
  packageJson___devDependencies = 'packageJson___devDependencies',
  packageJson___devDependencies___name = 'packageJson___devDependencies___name',
  packageJson___devDependencies___version = 'packageJson___devDependencies___version',
  packageJson___peerDependencies = 'packageJson___peerDependencies',
  packageJson___peerDependencies___name = 'packageJson___peerDependencies___name',
  packageJson___peerDependencies___version = 'packageJson___peerDependencies___version',
  packageJson___keywords = 'packageJson___keywords',
}

export type SitePluginFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  resolve?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
  pluginOptions?: Maybe<SitePluginPluginOptionsFilterInput>;
  nodeAPIs?: Maybe<StringQueryOperatorInput>;
  browserAPIs?: Maybe<StringQueryOperatorInput>;
  ssrAPIs?: Maybe<StringQueryOperatorInput>;
  pluginFilepath?: Maybe<StringQueryOperatorInput>;
  packageJson?: Maybe<SitePluginPackageJsonFilterInput>;
};

export type SitePluginGroupConnection = {
  __typename?: 'SitePluginGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<SitePluginEdge>;
  nodes: Array<SitePlugin>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type SitePluginPackageJson = {
  __typename?: 'SitePluginPackageJson';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  main?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  dependencies?: Maybe<Array<Maybe<SitePluginPackageJsonDependencies>>>;
  devDependencies?: Maybe<Array<Maybe<SitePluginPackageJsonDevDependencies>>>;
  peerDependencies?: Maybe<Array<Maybe<SitePluginPackageJsonPeerDependencies>>>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SitePluginPackageJsonDependencies = {
  __typename?: 'SitePluginPackageJsonDependencies';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type SitePluginPackageJsonDependenciesFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPackageJsonDependenciesFilterListInput = {
  elemMatch?: Maybe<SitePluginPackageJsonDependenciesFilterInput>;
};

export type SitePluginPackageJsonDevDependencies = {
  __typename?: 'SitePluginPackageJsonDevDependencies';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type SitePluginPackageJsonDevDependenciesFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPackageJsonDevDependenciesFilterListInput = {
  elemMatch?: Maybe<SitePluginPackageJsonDevDependenciesFilterInput>;
};

export type SitePluginPackageJsonFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
  main?: Maybe<StringQueryOperatorInput>;
  author?: Maybe<StringQueryOperatorInput>;
  license?: Maybe<StringQueryOperatorInput>;
  dependencies?: Maybe<SitePluginPackageJsonDependenciesFilterListInput>;
  devDependencies?: Maybe<SitePluginPackageJsonDevDependenciesFilterListInput>;
  peerDependencies?: Maybe<SitePluginPackageJsonPeerDependenciesFilterListInput>;
  keywords?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPackageJsonPeerDependencies = {
  __typename?: 'SitePluginPackageJsonPeerDependencies';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type SitePluginPackageJsonPeerDependenciesFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPackageJsonPeerDependenciesFilterListInput = {
  elemMatch?: Maybe<SitePluginPackageJsonPeerDependenciesFilterInput>;
};

export type SitePluginPluginOptions = {
  __typename?: 'SitePluginPluginOptions';
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  baseUrl?: Maybe<Scalars['String']>;
  apiBase?: Maybe<Scalars['String']>;
  concurrentFileRequests?: Maybe<Scalars['Int']>;
  i18n?: Maybe<Array<Maybe<SitePluginPluginOptionsI18n>>>;
  layout?: Maybe<Scalars['String']>;
  typekit?: Maybe<SitePluginPluginOptionsTypekit>;
  short_name?: Maybe<Scalars['String']>;
  start_url?: Maybe<Scalars['String']>;
  background_color?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icons?: Maybe<Array<Maybe<SitePluginPluginOptionsIcons>>>;
  pixelId?: Maybe<Scalars['String']>;
  exclude?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirect?: Maybe<Array<Maybe<Scalars['String']>>>;
  custom?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pathCheck?: Maybe<Scalars['Boolean']>;
};

export type SitePluginPluginOptionsFilterInput = {
  features?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  path?: Maybe<StringQueryOperatorInput>;
  baseUrl?: Maybe<StringQueryOperatorInput>;
  apiBase?: Maybe<StringQueryOperatorInput>;
  concurrentFileRequests?: Maybe<IntQueryOperatorInput>;
  i18n?: Maybe<SitePluginPluginOptionsI18nFilterListInput>;
  layout?: Maybe<StringQueryOperatorInput>;
  typekit?: Maybe<SitePluginPluginOptionsTypekitFilterInput>;
  short_name?: Maybe<StringQueryOperatorInput>;
  start_url?: Maybe<StringQueryOperatorInput>;
  background_color?: Maybe<StringQueryOperatorInput>;
  theme_color?: Maybe<StringQueryOperatorInput>;
  display?: Maybe<StringQueryOperatorInput>;
  icon?: Maybe<StringQueryOperatorInput>;
  icons?: Maybe<SitePluginPluginOptionsIconsFilterListInput>;
  pixelId?: Maybe<StringQueryOperatorInput>;
  exclude?: Maybe<StringQueryOperatorInput>;
  redirect?: Maybe<StringQueryOperatorInput>;
  custom?: Maybe<StringQueryOperatorInput>;
  key?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  pathCheck?: Maybe<BooleanQueryOperatorInput>;
};

export type SitePluginPluginOptionsI18n = {
  __typename?: 'SitePluginPluginOptionsI18n';
  langcode?: Maybe<Scalars['String']>;
};

export type SitePluginPluginOptionsI18nFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPluginOptionsI18nFilterListInput = {
  elemMatch?: Maybe<SitePluginPluginOptionsI18nFilterInput>;
};

export type SitePluginPluginOptionsIcons = {
  __typename?: 'SitePluginPluginOptionsIcons';
  src?: Maybe<Scalars['String']>;
  sizes?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SitePluginPluginOptionsIconsFilterInput = {
  src?: Maybe<StringQueryOperatorInput>;
  sizes?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPluginOptionsIconsFilterListInput = {
  elemMatch?: Maybe<SitePluginPluginOptionsIconsFilterInput>;
};

export type SitePluginPluginOptionsTypekit = {
  __typename?: 'SitePluginPluginOptionsTypekit';
  id?: Maybe<Scalars['String']>;
};

export type SitePluginPluginOptionsTypekitFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginSortInput = {
  fields?: Maybe<Array<Maybe<SitePluginFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type SiteSiteMetadata = {
  __typename?: 'SiteSiteMetadata';
  siteUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type SiteSiteMetadataFilterInput = {
  siteUrl?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<StringQueryOperatorInput>;
};

export type SiteSortInput = {
  fields?: Maybe<Array<Maybe<SiteFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type StringQueryOperatorInput = {
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['String']>;
  glob?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Accesory_Type = Node & {
  __typename?: 'taxonomy_term__accesory_type';
  name?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__tid?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  revision_created?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  rh_action?: Maybe<Scalars['String']>;
  rh_redirect_response?: Maybe<Scalars['Int']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Taxonomy_Term__Accesory_TypeRelationships>;
  vid?: Maybe<Taxonomy_Term__Accesory_TypeVid>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Taxonomy_Term__Accesory_TypeRevision_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Accesory_TypeChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Accesory_TypeContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Accesory_TypeConnection = {
  __typename?: 'taxonomy_term__accesory_typeConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Accesory_TypeEdge>;
  nodes: Array<Taxonomy_Term__Accesory_Type>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Taxonomy_Term__Accesory_TypeGroupConnection>;
};

export type Taxonomy_Term__Accesory_TypeConnectionDistinctArgs = {
  field: Taxonomy_Term__Accesory_TypeFieldsEnum;
};

export type Taxonomy_Term__Accesory_TypeConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Taxonomy_Term__Accesory_TypeFieldsEnum;
};

export type Taxonomy_Term__Accesory_TypeEdge = {
  __typename?: 'taxonomy_term__accesory_typeEdge';
  next?: Maybe<Taxonomy_Term__Accesory_Type>;
  node: Taxonomy_Term__Accesory_Type;
  previous?: Maybe<Taxonomy_Term__Accesory_Type>;
};

export enum Taxonomy_Term__Accesory_TypeFieldsEnum {
  name = 'name',
  drupal_id = 'drupal_id',
  drupal_internal__tid = 'drupal_internal__tid',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  revision_created = 'revision_created',
  status = 'status',
  weight = 'weight',
  changed = 'changed',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  rh_action = 'rh_action',
  rh_redirect_response = 'rh_redirect_response',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_created = 'content_translation_created',
  uuid = 'uuid',
  relationships___node__accessories = 'relationships___node__accessories',
  relationships___node__accessories___langcode = 'relationships___node__accessories___langcode',
  relationships___node__accessories___drupal_id = 'relationships___node__accessories___drupal_id',
  relationships___node__accessories___title = 'relationships___node__accessories___title',
  relationships___node__accessories___field_pl_groupid = 'relationships___node__accessories___field_pl_groupid',
  relationships___node__accessories___field_weight = 'relationships___node__accessories___field_weight',
  relationships___node__accessories___relationships___field_recommended_for_branches = 'relationships___node__accessories___relationships___field_recommended_for_branches',
  relationships___node__accessories___drupal_internal__nid = 'relationships___node__accessories___drupal_internal__nid',
  relationships___node__accessories___status = 'relationships___node__accessories___status',
  relationships___node__accessories___content_translation_source = 'relationships___node__accessories___content_translation_source',
  relationships___node__accessories___content_translation_outdated = 'relationships___node__accessories___content_translation_outdated',
  relationships___node__accessories___uuid = 'relationships___node__accessories___uuid',
  relationships___node__accessories___field_date_from_api___drupal_internal__target_id = 'relationships___node__accessories___field_date_from_api___drupal_internal__target_id',
  relationships___node__accessories___field_pl_accessory_type___drupal_internal__target_id = 'relationships___node__accessories___field_pl_accessory_type___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image___alt = 'relationships___node__accessories___field_pl_image___alt',
  relationships___node__accessories___field_pl_image___title = 'relationships___node__accessories___field_pl_image___title',
  relationships___node__accessories___field_pl_image___width = 'relationships___node__accessories___field_pl_image___width',
  relationships___node__accessories___field_pl_image___height = 'relationships___node__accessories___field_pl_image___height',
  relationships___node__accessories___field_pl_image___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image_accessories_outl___alt = 'relationships___node__accessories___field_pl_image_accessories_outl___alt',
  relationships___node__accessories___field_pl_image_accessories_outl___title = 'relationships___node__accessories___field_pl_image_accessories_outl___title',
  relationships___node__accessories___field_pl_image_accessories_outl___width = 'relationships___node__accessories___field_pl_image_accessories_outl___width',
  relationships___node__accessories___field_pl_image_accessories_outl___height = 'relationships___node__accessories___field_pl_image_accessories_outl___height',
  relationships___node__accessories___field_pl_image_accessories_outl___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image_accessories_outl___drupal_internal__target_id',
  relationships___node__accessories___field_pl_image_accessories_table___alt = 'relationships___node__accessories___field_pl_image_accessories_table___alt',
  relationships___node__accessories___field_pl_image_accessories_table___title = 'relationships___node__accessories___field_pl_image_accessories_table___title',
  relationships___node__accessories___field_pl_image_accessories_table___width = 'relationships___node__accessories___field_pl_image_accessories_table___width',
  relationships___node__accessories___field_pl_image_accessories_table___height = 'relationships___node__accessories___field_pl_image_accessories_table___height',
  relationships___node__accessories___field_pl_image_accessories_table___drupal_internal__target_id = 'relationships___node__accessories___field_pl_image_accessories_table___drupal_internal__target_id',
  relationships___node__accessories___field_recommended_for_branches = 'relationships___node__accessories___field_recommended_for_branches',
  relationships___node__accessories___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__accessories___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__accessories___id = 'relationships___node__accessories___id',
  relationships___node__accessories___parent___id = 'relationships___node__accessories___parent___id',
  relationships___node__accessories___parent___children = 'relationships___node__accessories___parent___children',
  relationships___node__accessories___children = 'relationships___node__accessories___children',
  relationships___node__accessories___children___id = 'relationships___node__accessories___children___id',
  relationships___node__accessories___children___children = 'relationships___node__accessories___children___children',
  relationships___node__accessories___internal___content = 'relationships___node__accessories___internal___content',
  relationships___node__accessories___internal___contentDigest = 'relationships___node__accessories___internal___contentDigest',
  relationships___node__accessories___internal___description = 'relationships___node__accessories___internal___description',
  relationships___node__accessories___internal___fieldOwners = 'relationships___node__accessories___internal___fieldOwners',
  relationships___node__accessories___internal___ignoreType = 'relationships___node__accessories___internal___ignoreType',
  relationships___node__accessories___internal___mediaType = 'relationships___node__accessories___internal___mediaType',
  relationships___node__accessories___internal___owner = 'relationships___node__accessories___internal___owner',
  relationships___node__accessories___internal___type = 'relationships___node__accessories___internal___type',
  vid___drupal_internal__target_id = 'vid___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Taxonomy_Term__Accesory_TypeFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Taxonomy_Term__Accesory_TypeRelationshipsFilterInput>;
  vid?: Maybe<Taxonomy_Term__Accesory_TypeVidFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Taxonomy_Term__Accesory_TypeGroupConnection = {
  __typename?: 'taxonomy_term__accesory_typeGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Accesory_TypeEdge>;
  nodes: Array<Taxonomy_Term__Accesory_Type>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Accesory_TypeRelationships = {
  __typename?: 'taxonomy_term__accesory_typeRelationships';
  node__accessories?: Maybe<Array<Maybe<Node__Accessories>>>;
};

export type Taxonomy_Term__Accesory_TypeRelationshipsFilterInput = {
  node__accessories?: Maybe<Node__AccessoriesFilterListInput>;
};

export type Taxonomy_Term__Accesory_TypeSortInput = {
  fields?: Maybe<Array<Maybe<Taxonomy_Term__Accesory_TypeFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Taxonomy_Term__Accesory_TypeVid = {
  __typename?: 'taxonomy_term__accesory_typeVid';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Accesory_TypeVidFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Taxonomy_Term__News_Categories = Node & {
  __typename?: 'taxonomy_term__news_categories';
  name?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__tid?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  revision_created?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  rh_action?: Maybe<Scalars['String']>;
  rh_redirect_response?: Maybe<Scalars['Int']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Taxonomy_Term__News_CategoriesRelationships>;
  vid?: Maybe<Taxonomy_Term__News_CategoriesVid>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Taxonomy_Term__News_CategoriesRevision_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_CategoriesChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_CategoriesConnection = {
  __typename?: 'taxonomy_term__news_categoriesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__News_CategoriesEdge>;
  nodes: Array<Taxonomy_Term__News_Categories>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Taxonomy_Term__News_CategoriesGroupConnection>;
};

export type Taxonomy_Term__News_CategoriesConnectionDistinctArgs = {
  field: Taxonomy_Term__News_CategoriesFieldsEnum;
};

export type Taxonomy_Term__News_CategoriesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Taxonomy_Term__News_CategoriesFieldsEnum;
};

export type Taxonomy_Term__News_CategoriesEdge = {
  __typename?: 'taxonomy_term__news_categoriesEdge';
  next?: Maybe<Taxonomy_Term__News_Categories>;
  node: Taxonomy_Term__News_Categories;
  previous?: Maybe<Taxonomy_Term__News_Categories>;
};

export enum Taxonomy_Term__News_CategoriesFieldsEnum {
  name = 'name',
  drupal_id = 'drupal_id',
  drupal_internal__tid = 'drupal_internal__tid',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  revision_created = 'revision_created',
  status = 'status',
  weight = 'weight',
  changed = 'changed',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  rh_action = 'rh_action',
  rh_redirect_response = 'rh_redirect_response',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  relationships___node__news = 'relationships___node__news',
  relationships___node__news___langcode = 'relationships___node__news___langcode',
  relationships___node__news___id = 'relationships___node__news___id',
  relationships___node__news___drupal_internal__nid = 'relationships___node__news___drupal_internal__nid',
  relationships___node__news___title = 'relationships___node__news___title',
  relationships___node__news___field_news_reading_time = 'relationships___node__news___field_news_reading_time',
  relationships___node__news___field_news_body = 'relationships___node__news___field_news_body',
  relationships___node__news___created = 'relationships___node__news___created',
  relationships___node__news___path___alias = 'relationships___node__news___path___alias',
  relationships___node__news___status = 'relationships___node__news___status',
  relationships___node__news___field_meta_description = 'relationships___node__news___field_meta_description',
  relationships___node__news___field_meta_title = 'relationships___node__news___field_meta_title',
  relationships___node__news___relationships___field_news_tags = 'relationships___node__news___relationships___field_news_tags',
  relationships___node__news___relationships___node__news = 'relationships___node__news___relationships___node__news',
  relationships___node__news___relationships___paragraph__recommended_blog_posts = 'relationships___node__news___relationships___paragraph__recommended_blog_posts',
  relationships___node__news___drupal_id = 'relationships___node__news___drupal_id',
  relationships___node__news___rh_redirect_fallback_action = 'relationships___node__news___rh_redirect_fallback_action',
  relationships___node__news___content_translation_source = 'relationships___node__news___content_translation_source',
  relationships___node__news___content_translation_outdated = 'relationships___node__news___content_translation_outdated',
  relationships___node__news___field_meta_keywords = 'relationships___node__news___field_meta_keywords',
  relationships___node__news___uuid = 'relationships___node__news___uuid',
  relationships___node__news___field_news_categories___drupal_internal__target_id = 'relationships___node__news___field_news_categories___drupal_internal__target_id',
  relationships___node__news___field_news_components = 'relationships___node__news___field_news_components',
  relationships___node__news___field_news_components___target_revision_id = 'relationships___node__news___field_news_components___target_revision_id',
  relationships___node__news___field_news_components___drupal_internal__target_id = 'relationships___node__news___field_news_components___drupal_internal__target_id',
  relationships___node__news___field_news_image___alt = 'relationships___node__news___field_news_image___alt',
  relationships___node__news___field_news_image___title = 'relationships___node__news___field_news_image___title',
  relationships___node__news___field_news_image___width = 'relationships___node__news___field_news_image___width',
  relationships___node__news___field_news_image___height = 'relationships___node__news___field_news_image___height',
  relationships___node__news___field_news_image___drupal_internal__target_id = 'relationships___node__news___field_news_image___drupal_internal__target_id',
  relationships___node__news___field_news_tags = 'relationships___node__news___field_news_tags',
  relationships___node__news___field_news_tags___drupal_internal__target_id = 'relationships___node__news___field_news_tags___drupal_internal__target_id',
  relationships___node__news___field_related_news_1___drupal_internal__target_id = 'relationships___node__news___field_related_news_1___drupal_internal__target_id',
  relationships___node__news___field_related_news_2___drupal_internal__target_id = 'relationships___node__news___field_related_news_2___drupal_internal__target_id',
  relationships___node__news___field_related_news_3___drupal_internal__target_id = 'relationships___node__news___field_related_news_3___drupal_internal__target_id',
  relationships___node__news___parent___id = 'relationships___node__news___parent___id',
  relationships___node__news___parent___children = 'relationships___node__news___parent___children',
  relationships___node__news___children = 'relationships___node__news___children',
  relationships___node__news___children___id = 'relationships___node__news___children___id',
  relationships___node__news___children___children = 'relationships___node__news___children___children',
  relationships___node__news___internal___content = 'relationships___node__news___internal___content',
  relationships___node__news___internal___contentDigest = 'relationships___node__news___internal___contentDigest',
  relationships___node__news___internal___description = 'relationships___node__news___internal___description',
  relationships___node__news___internal___fieldOwners = 'relationships___node__news___internal___fieldOwners',
  relationships___node__news___internal___ignoreType = 'relationships___node__news___internal___ignoreType',
  relationships___node__news___internal___mediaType = 'relationships___node__news___internal___mediaType',
  relationships___node__news___internal___owner = 'relationships___node__news___internal___owner',
  relationships___node__news___internal___type = 'relationships___node__news___internal___type',
  vid___drupal_internal__target_id = 'vid___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Taxonomy_Term__News_CategoriesFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Taxonomy_Term__News_CategoriesRelationshipsFilterInput>;
  vid?: Maybe<Taxonomy_Term__News_CategoriesVidFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Taxonomy_Term__News_CategoriesGroupConnection = {
  __typename?: 'taxonomy_term__news_categoriesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__News_CategoriesEdge>;
  nodes: Array<Taxonomy_Term__News_Categories>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_CategoriesRelationships = {
  __typename?: 'taxonomy_term__news_categoriesRelationships';
  node__news?: Maybe<Array<Maybe<Node__News>>>;
};

export type Taxonomy_Term__News_CategoriesRelationshipsFilterInput = {
  node__news?: Maybe<Node__NewsFilterListInput>;
};

export type Taxonomy_Term__News_CategoriesSortInput = {
  fields?: Maybe<Array<Maybe<Taxonomy_Term__News_CategoriesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Taxonomy_Term__News_CategoriesVid = {
  __typename?: 'taxonomy_term__news_categoriesVid';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_CategoriesVidFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Taxonomy_Term__News_Tags = Node & {
  __typename?: 'taxonomy_term__news_tags';
  name?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__tid?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  revision_created?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  rh_action?: Maybe<Scalars['String']>;
  rh_redirect_response?: Maybe<Scalars['Int']>;
  rh_redirect_fallback_action?: Maybe<Scalars['String']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Taxonomy_Term__News_TagsRelationships>;
  vid?: Maybe<Taxonomy_Term__News_TagsVid>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Taxonomy_Term__News_TagsRevision_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_TagsChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_TagsContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_TagsConnection = {
  __typename?: 'taxonomy_term__news_tagsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__News_TagsEdge>;
  nodes: Array<Taxonomy_Term__News_Tags>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Taxonomy_Term__News_TagsGroupConnection>;
};

export type Taxonomy_Term__News_TagsConnectionDistinctArgs = {
  field: Taxonomy_Term__News_TagsFieldsEnum;
};

export type Taxonomy_Term__News_TagsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Taxonomy_Term__News_TagsFieldsEnum;
};

export type Taxonomy_Term__News_TagsEdge = {
  __typename?: 'taxonomy_term__news_tagsEdge';
  next?: Maybe<Taxonomy_Term__News_Tags>;
  node: Taxonomy_Term__News_Tags;
  previous?: Maybe<Taxonomy_Term__News_Tags>;
};

export enum Taxonomy_Term__News_TagsFieldsEnum {
  name = 'name',
  drupal_id = 'drupal_id',
  drupal_internal__tid = 'drupal_internal__tid',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  revision_created = 'revision_created',
  status = 'status',
  weight = 'weight',
  changed = 'changed',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  rh_action = 'rh_action',
  rh_redirect_response = 'rh_redirect_response',
  rh_redirect_fallback_action = 'rh_redirect_fallback_action',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_created = 'content_translation_created',
  uuid = 'uuid',
  relationships___node__news = 'relationships___node__news',
  relationships___node__news___langcode = 'relationships___node__news___langcode',
  relationships___node__news___id = 'relationships___node__news___id',
  relationships___node__news___drupal_internal__nid = 'relationships___node__news___drupal_internal__nid',
  relationships___node__news___title = 'relationships___node__news___title',
  relationships___node__news___field_news_reading_time = 'relationships___node__news___field_news_reading_time',
  relationships___node__news___field_news_body = 'relationships___node__news___field_news_body',
  relationships___node__news___created = 'relationships___node__news___created',
  relationships___node__news___path___alias = 'relationships___node__news___path___alias',
  relationships___node__news___status = 'relationships___node__news___status',
  relationships___node__news___field_meta_description = 'relationships___node__news___field_meta_description',
  relationships___node__news___field_meta_title = 'relationships___node__news___field_meta_title',
  relationships___node__news___relationships___field_news_tags = 'relationships___node__news___relationships___field_news_tags',
  relationships___node__news___relationships___node__news = 'relationships___node__news___relationships___node__news',
  relationships___node__news___relationships___paragraph__recommended_blog_posts = 'relationships___node__news___relationships___paragraph__recommended_blog_posts',
  relationships___node__news___drupal_id = 'relationships___node__news___drupal_id',
  relationships___node__news___rh_redirect_fallback_action = 'relationships___node__news___rh_redirect_fallback_action',
  relationships___node__news___content_translation_source = 'relationships___node__news___content_translation_source',
  relationships___node__news___content_translation_outdated = 'relationships___node__news___content_translation_outdated',
  relationships___node__news___field_meta_keywords = 'relationships___node__news___field_meta_keywords',
  relationships___node__news___uuid = 'relationships___node__news___uuid',
  relationships___node__news___field_news_categories___drupal_internal__target_id = 'relationships___node__news___field_news_categories___drupal_internal__target_id',
  relationships___node__news___field_news_components = 'relationships___node__news___field_news_components',
  relationships___node__news___field_news_components___target_revision_id = 'relationships___node__news___field_news_components___target_revision_id',
  relationships___node__news___field_news_components___drupal_internal__target_id = 'relationships___node__news___field_news_components___drupal_internal__target_id',
  relationships___node__news___field_news_image___alt = 'relationships___node__news___field_news_image___alt',
  relationships___node__news___field_news_image___title = 'relationships___node__news___field_news_image___title',
  relationships___node__news___field_news_image___width = 'relationships___node__news___field_news_image___width',
  relationships___node__news___field_news_image___height = 'relationships___node__news___field_news_image___height',
  relationships___node__news___field_news_image___drupal_internal__target_id = 'relationships___node__news___field_news_image___drupal_internal__target_id',
  relationships___node__news___field_news_tags = 'relationships___node__news___field_news_tags',
  relationships___node__news___field_news_tags___drupal_internal__target_id = 'relationships___node__news___field_news_tags___drupal_internal__target_id',
  relationships___node__news___field_related_news_1___drupal_internal__target_id = 'relationships___node__news___field_related_news_1___drupal_internal__target_id',
  relationships___node__news___field_related_news_2___drupal_internal__target_id = 'relationships___node__news___field_related_news_2___drupal_internal__target_id',
  relationships___node__news___field_related_news_3___drupal_internal__target_id = 'relationships___node__news___field_related_news_3___drupal_internal__target_id',
  relationships___node__news___parent___id = 'relationships___node__news___parent___id',
  relationships___node__news___parent___children = 'relationships___node__news___parent___children',
  relationships___node__news___children = 'relationships___node__news___children',
  relationships___node__news___children___id = 'relationships___node__news___children___id',
  relationships___node__news___children___children = 'relationships___node__news___children___children',
  relationships___node__news___internal___content = 'relationships___node__news___internal___content',
  relationships___node__news___internal___contentDigest = 'relationships___node__news___internal___contentDigest',
  relationships___node__news___internal___description = 'relationships___node__news___internal___description',
  relationships___node__news___internal___fieldOwners = 'relationships___node__news___internal___fieldOwners',
  relationships___node__news___internal___ignoreType = 'relationships___node__news___internal___ignoreType',
  relationships___node__news___internal___mediaType = 'relationships___node__news___internal___mediaType',
  relationships___node__news___internal___owner = 'relationships___node__news___internal___owner',
  relationships___node__news___internal___type = 'relationships___node__news___internal___type',
  relationships___parent = 'relationships___parent',
  relationships___parent___name = 'relationships___parent___name',
  relationships___parent___drupal_id = 'relationships___parent___drupal_id',
  relationships___parent___drupal_internal__tid = 'relationships___parent___drupal_internal__tid',
  relationships___parent___drupal_internal__revision_id = 'relationships___parent___drupal_internal__revision_id',
  relationships___parent___langcode = 'relationships___parent___langcode',
  relationships___parent___revision_created = 'relationships___parent___revision_created',
  relationships___parent___status = 'relationships___parent___status',
  relationships___parent___weight = 'relationships___parent___weight',
  relationships___parent___changed = 'relationships___parent___changed',
  relationships___parent___default_langcode = 'relationships___parent___default_langcode',
  relationships___parent___revision_translation_affected = 'relationships___parent___revision_translation_affected',
  relationships___parent___rh_action = 'relationships___parent___rh_action',
  relationships___parent___rh_redirect_response = 'relationships___parent___rh_redirect_response',
  relationships___parent___rh_redirect_fallback_action = 'relationships___parent___rh_redirect_fallback_action',
  relationships___parent___content_translation_source = 'relationships___parent___content_translation_source',
  relationships___parent___content_translation_outdated = 'relationships___parent___content_translation_outdated',
  relationships___parent___content_translation_created = 'relationships___parent___content_translation_created',
  relationships___parent___uuid = 'relationships___parent___uuid',
  relationships___parent___relationships___node__news = 'relationships___parent___relationships___node__news',
  relationships___parent___relationships___parent = 'relationships___parent___relationships___parent',
  relationships___parent___relationships___taxonomy_term__news_tags = 'relationships___parent___relationships___taxonomy_term__news_tags',
  relationships___parent___vid___drupal_internal__target_id = 'relationships___parent___vid___drupal_internal__target_id',
  relationships___parent___id = 'relationships___parent___id',
  relationships___parent___parent___id = 'relationships___parent___parent___id',
  relationships___parent___parent___children = 'relationships___parent___parent___children',
  relationships___parent___children = 'relationships___parent___children',
  relationships___parent___children___id = 'relationships___parent___children___id',
  relationships___parent___children___children = 'relationships___parent___children___children',
  relationships___parent___internal___content = 'relationships___parent___internal___content',
  relationships___parent___internal___contentDigest = 'relationships___parent___internal___contentDigest',
  relationships___parent___internal___description = 'relationships___parent___internal___description',
  relationships___parent___internal___fieldOwners = 'relationships___parent___internal___fieldOwners',
  relationships___parent___internal___ignoreType = 'relationships___parent___internal___ignoreType',
  relationships___parent___internal___mediaType = 'relationships___parent___internal___mediaType',
  relationships___parent___internal___owner = 'relationships___parent___internal___owner',
  relationships___parent___internal___type = 'relationships___parent___internal___type',
  relationships___taxonomy_term__news_tags = 'relationships___taxonomy_term__news_tags',
  relationships___taxonomy_term__news_tags___name = 'relationships___taxonomy_term__news_tags___name',
  relationships___taxonomy_term__news_tags___drupal_id = 'relationships___taxonomy_term__news_tags___drupal_id',
  relationships___taxonomy_term__news_tags___drupal_internal__tid = 'relationships___taxonomy_term__news_tags___drupal_internal__tid',
  relationships___taxonomy_term__news_tags___drupal_internal__revision_id = 'relationships___taxonomy_term__news_tags___drupal_internal__revision_id',
  relationships___taxonomy_term__news_tags___langcode = 'relationships___taxonomy_term__news_tags___langcode',
  relationships___taxonomy_term__news_tags___revision_created = 'relationships___taxonomy_term__news_tags___revision_created',
  relationships___taxonomy_term__news_tags___status = 'relationships___taxonomy_term__news_tags___status',
  relationships___taxonomy_term__news_tags___weight = 'relationships___taxonomy_term__news_tags___weight',
  relationships___taxonomy_term__news_tags___changed = 'relationships___taxonomy_term__news_tags___changed',
  relationships___taxonomy_term__news_tags___default_langcode = 'relationships___taxonomy_term__news_tags___default_langcode',
  relationships___taxonomy_term__news_tags___revision_translation_affected = 'relationships___taxonomy_term__news_tags___revision_translation_affected',
  relationships___taxonomy_term__news_tags___rh_action = 'relationships___taxonomy_term__news_tags___rh_action',
  relationships___taxonomy_term__news_tags___rh_redirect_response = 'relationships___taxonomy_term__news_tags___rh_redirect_response',
  relationships___taxonomy_term__news_tags___rh_redirect_fallback_action = 'relationships___taxonomy_term__news_tags___rh_redirect_fallback_action',
  relationships___taxonomy_term__news_tags___content_translation_source = 'relationships___taxonomy_term__news_tags___content_translation_source',
  relationships___taxonomy_term__news_tags___content_translation_outdated = 'relationships___taxonomy_term__news_tags___content_translation_outdated',
  relationships___taxonomy_term__news_tags___content_translation_created = 'relationships___taxonomy_term__news_tags___content_translation_created',
  relationships___taxonomy_term__news_tags___uuid = 'relationships___taxonomy_term__news_tags___uuid',
  relationships___taxonomy_term__news_tags___relationships___node__news = 'relationships___taxonomy_term__news_tags___relationships___node__news',
  relationships___taxonomy_term__news_tags___relationships___parent = 'relationships___taxonomy_term__news_tags___relationships___parent',
  relationships___taxonomy_term__news_tags___relationships___taxonomy_term__news_tags = 'relationships___taxonomy_term__news_tags___relationships___taxonomy_term__news_tags',
  relationships___taxonomy_term__news_tags___vid___drupal_internal__target_id = 'relationships___taxonomy_term__news_tags___vid___drupal_internal__target_id',
  relationships___taxonomy_term__news_tags___id = 'relationships___taxonomy_term__news_tags___id',
  relationships___taxonomy_term__news_tags___parent___id = 'relationships___taxonomy_term__news_tags___parent___id',
  relationships___taxonomy_term__news_tags___parent___children = 'relationships___taxonomy_term__news_tags___parent___children',
  relationships___taxonomy_term__news_tags___children = 'relationships___taxonomy_term__news_tags___children',
  relationships___taxonomy_term__news_tags___children___id = 'relationships___taxonomy_term__news_tags___children___id',
  relationships___taxonomy_term__news_tags___children___children = 'relationships___taxonomy_term__news_tags___children___children',
  relationships___taxonomy_term__news_tags___internal___content = 'relationships___taxonomy_term__news_tags___internal___content',
  relationships___taxonomy_term__news_tags___internal___contentDigest = 'relationships___taxonomy_term__news_tags___internal___contentDigest',
  relationships___taxonomy_term__news_tags___internal___description = 'relationships___taxonomy_term__news_tags___internal___description',
  relationships___taxonomy_term__news_tags___internal___fieldOwners = 'relationships___taxonomy_term__news_tags___internal___fieldOwners',
  relationships___taxonomy_term__news_tags___internal___ignoreType = 'relationships___taxonomy_term__news_tags___internal___ignoreType',
  relationships___taxonomy_term__news_tags___internal___mediaType = 'relationships___taxonomy_term__news_tags___internal___mediaType',
  relationships___taxonomy_term__news_tags___internal___owner = 'relationships___taxonomy_term__news_tags___internal___owner',
  relationships___taxonomy_term__news_tags___internal___type = 'relationships___taxonomy_term__news_tags___internal___type',
  vid___drupal_internal__target_id = 'vid___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Taxonomy_Term__News_TagsFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  rh_redirect_fallback_action?: Maybe<StringQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Taxonomy_Term__News_TagsRelationshipsFilterInput>;
  vid?: Maybe<Taxonomy_Term__News_TagsVidFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Taxonomy_Term__News_TagsFilterListInput = {
  elemMatch?: Maybe<Taxonomy_Term__News_TagsFilterInput>;
};

export type Taxonomy_Term__News_TagsGroupConnection = {
  __typename?: 'taxonomy_term__news_tagsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__News_TagsEdge>;
  nodes: Array<Taxonomy_Term__News_Tags>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_TagsRelationships = {
  __typename?: 'taxonomy_term__news_tagsRelationships';
  node__news?: Maybe<Array<Maybe<Node__News>>>;
  parent?: Maybe<Array<Maybe<Taxonomy_Term__News_Tags>>>;
  taxonomy_term__news_tags?: Maybe<Array<Maybe<Taxonomy_Term__News_Tags>>>;
};

export type Taxonomy_Term__News_TagsRelationshipsFilterInput = {
  node__news?: Maybe<Node__NewsFilterListInput>;
  parent?: Maybe<Taxonomy_Term__News_TagsFilterListInput>;
  taxonomy_term__news_tags?: Maybe<Taxonomy_Term__News_TagsFilterListInput>;
};

export type Taxonomy_Term__News_TagsSortInput = {
  fields?: Maybe<Array<Maybe<Taxonomy_Term__News_TagsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Taxonomy_Term__News_TagsVid = {
  __typename?: 'taxonomy_term__news_tagsVid';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__News_TagsVidFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Taxonomy_Term__Payment_Types = Node & {
  __typename?: 'taxonomy_term__payment_types';
  machine_api_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__tid?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Taxonomy_Term__Payment_TypesRelationships>;
  vid?: Maybe<Taxonomy_Term__Payment_TypesVid>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Taxonomy_Term__Product_Category = Node & {
  __typename?: 'taxonomy_term__product_category';
  name?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__tid?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  changed?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
  relationships?: Maybe<Taxonomy_Term__Product_CategoryRelationships>;
  vid?: Maybe<Taxonomy_Term__Product_CategoryVid>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  field_tooltip?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Payment_TypesChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Payment_TypesConnection = {
  __typename?: 'taxonomy_term__payment_typesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Payment_TypesEdge>;
  nodes: Array<Taxonomy_Term__Payment_Types>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Taxonomy_Term__Payment_TypesGroupConnection>;
};

export type Taxonomy_Term__Payment_TypesConnectionDistinctArgs = {
  field: Taxonomy_Term__Payment_TypesFieldsEnum;
};

export type Taxonomy_Term__Payment_TypesConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Taxonomy_Term__Payment_TypesFieldsEnum;
};

export type Taxonomy_Term__Payment_TypesEdge = {
  __typename?: 'taxonomy_term__payment_typesEdge';
  next?: Maybe<Taxonomy_Term__Payment_Types>;
  node: Taxonomy_Term__Payment_Types;
  previous?: Maybe<Taxonomy_Term__Payment_Types>;
};

export enum Taxonomy_Term__Payment_TypesFieldsEnum {
  machine_api_name = 'machine_api_name',
  name = 'name',
  drupal_id = 'drupal_id',
  drupal_internal__tid = 'drupal_internal__tid',
  langcode = 'langcode',
  status = 'status',
  weight = 'weight',
  changed = 'changed',
  uuid = 'uuid',
  relationships___node__agreement = 'relationships___node__agreement',
  relationships___node__agreement___langcode = 'relationships___node__agreement___langcode',
  relationships___node__agreement___drupal_id = 'relationships___node__agreement___drupal_id',
  relationships___node__agreement___agreement_content___value = 'relationships___node__agreement___agreement_content___value',
  relationships___node__agreement___agreement_explication___value = 'relationships___node__agreement___agreement_explication___value',
  relationships___node__agreement___agreement_type = 'relationships___node__agreement___agreement_type',
  relationships___node__agreement___agreement_pdf_title = 'relationships___node__agreement___agreement_pdf_title',
  relationships___node__agreement___relationships___agreement_payment_types = 'relationships___node__agreement___relationships___agreement_payment_types',
  relationships___node__agreement___relationships___paragraph__heading_and_agreements = 'relationships___node__agreement___relationships___paragraph__heading_and_agreements',
  relationships___node__agreement___drupal_internal__nid = 'relationships___node__agreement___drupal_internal__nid',
  relationships___node__agreement___drupal_internal__vid = 'relationships___node__agreement___drupal_internal__vid',
  relationships___node__agreement___status = 'relationships___node__agreement___status',
  relationships___node__agreement___title = 'relationships___node__agreement___title',
  relationships___node__agreement___created = 'relationships___node__agreement___created',
  relationships___node__agreement___changed = 'relationships___node__agreement___changed',
  relationships___node__agreement___uuid = 'relationships___node__agreement___uuid',
  relationships___node__agreement___agreement_payment_types = 'relationships___node__agreement___agreement_payment_types',
  relationships___node__agreement___agreement_payment_types___drupal_internal__target_id = 'relationships___node__agreement___agreement_payment_types___drupal_internal__target_id',
  relationships___node__agreement___agreement_pdf___drupal_internal__target_id = 'relationships___node__agreement___agreement_pdf___drupal_internal__target_id',
  relationships___node__agreement___id = 'relationships___node__agreement___id',
  relationships___node__agreement___parent___id = 'relationships___node__agreement___parent___id',
  relationships___node__agreement___parent___children = 'relationships___node__agreement___parent___children',
  relationships___node__agreement___children = 'relationships___node__agreement___children',
  relationships___node__agreement___children___id = 'relationships___node__agreement___children___id',
  relationships___node__agreement___children___children = 'relationships___node__agreement___children___children',
  relationships___node__agreement___internal___content = 'relationships___node__agreement___internal___content',
  relationships___node__agreement___internal___contentDigest = 'relationships___node__agreement___internal___contentDigest',
  relationships___node__agreement___internal___description = 'relationships___node__agreement___internal___description',
  relationships___node__agreement___internal___fieldOwners = 'relationships___node__agreement___internal___fieldOwners',
  relationships___node__agreement___internal___ignoreType = 'relationships___node__agreement___internal___ignoreType',
  relationships___node__agreement___internal___mediaType = 'relationships___node__agreement___internal___mediaType',
  relationships___node__agreement___internal___owner = 'relationships___node__agreement___internal___owner',
  relationships___node__agreement___internal___type = 'relationships___node__agreement___internal___type',
  relationships___node__devices = 'relationships___node__devices',
  relationships___node__devices___langcode = 'relationships___node__devices___langcode',
  relationships___node__devices___drupal_id = 'relationships___node__devices___drupal_id',
  relationships___node__devices___title = 'relationships___node__devices___title',
  relationships___node__devices___field_subtitle = 'relationships___node__devices___field_subtitle',
  relationships___node__devices___field_pl_description_in_cms___value = 'relationships___node__devices___field_pl_description_in_cms___value',
  relationships___node__devices___field_pl_is_online = 'relationships___node__devices___field_pl_is_online',
  relationships___node__devices___field_weight = 'relationships___node__devices___field_weight',
  relationships___node__devices___field_pl_strikethrough_price = 'relationships___node__devices___field_pl_strikethrough_price',
  relationships___node__devices___relationships___field_recommended_for_branches = 'relationships___node__devices___relationships___field_recommended_for_branches',
  relationships___node__devices___relationships___field_accessories = 'relationships___node__devices___relationships___field_accessories',
  relationships___node__devices___relationships___field_pl_cases = 'relationships___node__devices___relationships___field_pl_cases',
  relationships___node__devices___relationships___field_payment_methods = 'relationships___node__devices___relationships___field_payment_methods',
  relationships___node__devices___relationships___node__fiscalization = 'relationships___node__devices___relationships___node__fiscalization',
  relationships___node__devices___field_tooltip_text_for_branches = 'relationships___node__devices___field_tooltip_text_for_branches',
  relationships___node__devices___field_pl_is_virtual = 'relationships___node__devices___field_pl_is_virtual',
  relationships___node__devices___field_is_available = 'relationships___node__devices___field_is_available',
  relationships___node__devices___field_lease_strike_through_price = 'relationships___node__devices___field_lease_strike_through_price',
  relationships___node__devices___field_lease_allowed = 'relationships___node__devices___field_lease_allowed',
  relationships___node__devices___field_pl_price_list_name = 'relationships___node__devices___field_pl_price_list_name',
  relationships___node__devices___field_additional_price_lists = 'relationships___node__devices___field_additional_price_lists',
  relationships___node__devices___field_popup_body___value = 'relationships___node__devices___field_popup_body___value',
  relationships___node__devices___field_popup_button_back = 'relationships___node__devices___field_popup_button_back',
  relationships___node__devices___field_popup_button_ok = 'relationships___node__devices___field_popup_button_ok',
  relationships___node__devices___field_popup_enabled = 'relationships___node__devices___field_popup_enabled',
  relationships___node__devices___field_popup_title = 'relationships___node__devices___field_popup_title',
  relationships___node__devices___drupal_internal__nid = 'relationships___node__devices___drupal_internal__nid',
  relationships___node__devices___status = 'relationships___node__devices___status',
  relationships___node__devices___content_translation_source = 'relationships___node__devices___content_translation_source',
  relationships___node__devices___content_translation_outdated = 'relationships___node__devices___content_translation_outdated',
  relationships___node__devices___uuid = 'relationships___node__devices___uuid',
  relationships___node__devices___field_payment_methods = 'relationships___node__devices___field_payment_methods',
  relationships___node__devices___field_payment_methods___drupal_internal__target_id = 'relationships___node__devices___field_payment_methods___drupal_internal__target_id',
  relationships___node__devices___field_pl_cases = 'relationships___node__devices___field_pl_cases',
  relationships___node__devices___field_pl_cases___drupal_internal__target_id = 'relationships___node__devices___field_pl_cases___drupal_internal__target_id',
  relationships___node__devices___field_pl_image___alt = 'relationships___node__devices___field_pl_image___alt',
  relationships___node__devices___field_pl_image___title = 'relationships___node__devices___field_pl_image___title',
  relationships___node__devices___field_pl_image___width = 'relationships___node__devices___field_pl_image___width',
  relationships___node__devices___field_pl_image___height = 'relationships___node__devices___field_pl_image___height',
  relationships___node__devices___field_pl_image___drupal_internal__target_id = 'relationships___node__devices___field_pl_image___drupal_internal__target_id',
  relationships___node__devices___field_pl_image_for_accessories___alt = 'relationships___node__devices___field_pl_image_for_accessories___alt',
  relationships___node__devices___field_pl_image_for_accessories___title = 'relationships___node__devices___field_pl_image_for_accessories___title',
  relationships___node__devices___field_pl_image_for_accessories___width = 'relationships___node__devices___field_pl_image_for_accessories___width',
  relationships___node__devices___field_pl_image_for_accessories___height = 'relationships___node__devices___field_pl_image_for_accessories___height',
  relationships___node__devices___field_pl_image_for_accessories___drupal_internal__target_id = 'relationships___node__devices___field_pl_image_for_accessories___drupal_internal__target_id',
  relationships___node__devices___field_recommended_for_branches = 'relationships___node__devices___field_recommended_for_branches',
  relationships___node__devices___field_recommended_for_branches___drupal_internal__target_id = 'relationships___node__devices___field_recommended_for_branches___drupal_internal__target_id',
  relationships___node__devices___id = 'relationships___node__devices___id',
  relationships___node__devices___parent___id = 'relationships___node__devices___parent___id',
  relationships___node__devices___parent___children = 'relationships___node__devices___parent___children',
  relationships___node__devices___children = 'relationships___node__devices___children',
  relationships___node__devices___children___id = 'relationships___node__devices___children___id',
  relationships___node__devices___children___children = 'relationships___node__devices___children___children',
  relationships___node__devices___internal___content = 'relationships___node__devices___internal___content',
  relationships___node__devices___internal___contentDigest = 'relationships___node__devices___internal___contentDigest',
  relationships___node__devices___internal___description = 'relationships___node__devices___internal___description',
  relationships___node__devices___internal___fieldOwners = 'relationships___node__devices___internal___fieldOwners',
  relationships___node__devices___internal___ignoreType = 'relationships___node__devices___internal___ignoreType',
  relationships___node__devices___internal___mediaType = 'relationships___node__devices___internal___mediaType',
  relationships___node__devices___internal___owner = 'relationships___node__devices___internal___owner',
  relationships___node__devices___internal___type = 'relationships___node__devices___internal___type',
  vid___drupal_internal__target_id = 'vid___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Taxonomy_Term__Payment_TypesFilterInput = {
  machine_api_name?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  relationships?: Maybe<Taxonomy_Term__Payment_TypesRelationshipsFilterInput>;
  vid?: Maybe<Taxonomy_Term__Payment_TypesVidFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Taxonomy_Term__Payment_TypesFilterListInput = {
  elemMatch?: Maybe<Taxonomy_Term__Payment_TypesFilterInput>;
};

export type Taxonomy_Term__Payment_TypesGroupConnection = {
  __typename?: 'taxonomy_term__payment_typesGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Payment_TypesEdge>;
  nodes: Array<Taxonomy_Term__Payment_Types>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Payment_TypesRelationships = {
  __typename?: 'taxonomy_term__payment_typesRelationships';
  node__agreement?: Maybe<Array<Maybe<Node__Agreement>>>;
  node__devices?: Maybe<Array<Maybe<Node__Devices>>>;
};

export type Taxonomy_Term__Product_CategoryRelationships = {
  __typename?: 'taxonomy_term__product_categoryRelationships';
  node__devices?: Maybe<Array<Maybe<Node__Devices>>>;
};

export type Taxonomy_Term__Payment_TypesRelationshipsFilterInput = {
  node__agreement?: Maybe<Node__AgreementFilterListInput>;
  node__devices?: Maybe<Node__DevicesFilterListInput>;
};

export type Taxonomy_Term__Payment_TypesSortInput = {
  fields?: Maybe<Array<Maybe<Taxonomy_Term__Payment_TypesFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Taxonomy_Term__Payment_TypesVid = {
  __typename?: 'taxonomy_term__payment_typesVid';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Product_CategoryVid = {
  __typename?: 'taxonomy_term__product_categoryVid';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Payment_TypesVidFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Taxonomy_Term__Pricelist_Group = Node & {
  __typename?: 'taxonomy_term__pricelist_group';
  langcode?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Description>;
  field_discount?: Maybe<Scalars['Int']>;
  field_name_in_api?: Maybe<Scalars['String']>;
  field_name_in_cms?: Maybe<Scalars['String']>;
  field_isperproduct?: Maybe<Scalars['Boolean']>;
  field_maxfundedproducts?: Maybe<Scalars['Int']>;
  field_publish?: Maybe<Scalars['Boolean']>;
  field_text_when_unpublished?: Maybe<Field_Text_When_Unpublished>;
  relationships?: Maybe<Taxonomy_Term__Pricelist_GroupRelationships>;
  field_is_discount?: Maybe<Scalars['Boolean']>;
  field_side_panel_info?: Maybe<Scalars['String']>;
  field_is_default?: Maybe<Scalars['Boolean']>;
  drupal_internal__tid?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  revision_created?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  content_translation_created?: Maybe<Scalars['Date']>;
  field_coupon_in_api?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  vid?: Maybe<Taxonomy_Term__Pricelist_GroupVid>;
  field_pg_image?: Maybe<Taxonomy_Term__Pricelist_GroupField_Pg_Image>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type Taxonomy_Term__Pricelist_GroupRevision_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Pricelist_GroupChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Pricelist_GroupContent_Translation_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Pricelist_GroupConnection = {
  __typename?: 'taxonomy_term__pricelist_groupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Pricelist_GroupEdge>;
  nodes: Array<Taxonomy_Term__Pricelist_Group>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Taxonomy_Term__Pricelist_GroupGroupConnection>;
};

export type Taxonomy_Term__Product_CategoriesConnection = {
  __typename?: 'taxonomy_Term__Product_CategoriesConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Product_Categories_GroupEdge>;
  nodes: Array<Taxonomy_Term__Product_Category>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Taxonomy_Term__Product_Categories_GroupGroupConnection>;
}

export type Taxonomy_Term__Pricelist_GroupConnectionDistinctArgs = {
  field: Taxonomy_Term__Pricelist_GroupFieldsEnum;
};

export type Taxonomy_Term__Pricelist_GroupConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Taxonomy_Term__Pricelist_GroupFieldsEnum;
};

export type Taxonomy_Term__Pricelist_GroupEdge = {
  __typename?: 'taxonomy_term__pricelist_groupEdge';
  next?: Maybe<Taxonomy_Term__Pricelist_Group>;
  node: Taxonomy_Term__Pricelist_Group;
  previous?: Maybe<Taxonomy_Term__Pricelist_Group>;
};

export type Taxonomy_Term__Product_Categories_GroupEdge = {
  __typename?: 'taxonomy_term__product_categories_groupEdge';
  next?: Maybe<Taxonomy_Term__Product_Category>;
  node: Taxonomy_Term__Product_Category;
  previous?: Maybe<Taxonomy_Term__Product_Category>;
};


export type Taxonomy_Term__Pricelist_GroupField_Pg_Image = {
  __typename?: 'taxonomy_term__pricelist_groupField_pg_image';
  alt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  drupal_internal__target_id?: Maybe<Scalars['Int']>;
};

export type Taxonomy_Term__Pricelist_GroupField_Pg_ImageFilterInput = {
  alt?: Maybe<StringQueryOperatorInput>;
  title?: Maybe<StringQueryOperatorInput>;
  width?: Maybe<IntQueryOperatorInput>;
  height?: Maybe<IntQueryOperatorInput>;
  drupal_internal__target_id?: Maybe<IntQueryOperatorInput>;
};

export enum Taxonomy_Term__Pricelist_GroupFieldsEnum {
  langcode = 'langcode',
  drupal_id = 'drupal_id',
  name = 'name',
  description___value = 'description___value',
  field_discount = 'field_discount',
  field_name_in_api = 'field_name_in_api',
  field_name_in_cms = 'field_name_in_cms',
  field_isperproduct = 'field_isperproduct',
  field_maxfundedproducts = 'field_maxfundedproducts',
  field_publish = 'field_publish',
  field_text_when_unpublished___value = 'field_text_when_unpublished___value',
  relationships___field_pg_image___localFile___birthtime = 'relationships___field_pg_image___localFile___birthtime',
  relationships___field_pg_image___localFile___birthtimeMs = 'relationships___field_pg_image___localFile___birthtimeMs',
  relationships___field_pg_image___localFile___publicURL = 'relationships___field_pg_image___localFile___publicURL',
  relationships___field_pg_image___localFile___sourceInstanceName = 'relationships___field_pg_image___localFile___sourceInstanceName',
  relationships___field_pg_image___localFile___absolutePath = 'relationships___field_pg_image___localFile___absolutePath',
  relationships___field_pg_image___localFile___relativePath = 'relationships___field_pg_image___localFile___relativePath',
  relationships___field_pg_image___localFile___extension = 'relationships___field_pg_image___localFile___extension',
  relationships___field_pg_image___localFile___size = 'relationships___field_pg_image___localFile___size',
  relationships___field_pg_image___localFile___prettySize = 'relationships___field_pg_image___localFile___prettySize',
  relationships___field_pg_image___localFile___modifiedTime = 'relationships___field_pg_image___localFile___modifiedTime',
  relationships___field_pg_image___localFile___accessTime = 'relationships___field_pg_image___localFile___accessTime',
  relationships___field_pg_image___localFile___changeTime = 'relationships___field_pg_image___localFile___changeTime',
  relationships___field_pg_image___localFile___birthTime = 'relationships___field_pg_image___localFile___birthTime',
  relationships___field_pg_image___localFile___root = 'relationships___field_pg_image___localFile___root',
  relationships___field_pg_image___localFile___dir = 'relationships___field_pg_image___localFile___dir',
  relationships___field_pg_image___localFile___base = 'relationships___field_pg_image___localFile___base',
  relationships___field_pg_image___localFile___ext = 'relationships___field_pg_image___localFile___ext',
  relationships___field_pg_image___localFile___name = 'relationships___field_pg_image___localFile___name',
  relationships___field_pg_image___localFile___relativeDirectory = 'relationships___field_pg_image___localFile___relativeDirectory',
  relationships___field_pg_image___localFile___dev = 'relationships___field_pg_image___localFile___dev',
  relationships___field_pg_image___localFile___mode = 'relationships___field_pg_image___localFile___mode',
  relationships___field_pg_image___localFile___nlink = 'relationships___field_pg_image___localFile___nlink',
  relationships___field_pg_image___localFile___uid = 'relationships___field_pg_image___localFile___uid',
  relationships___field_pg_image___localFile___gid = 'relationships___field_pg_image___localFile___gid',
  relationships___field_pg_image___localFile___rdev = 'relationships___field_pg_image___localFile___rdev',
  relationships___field_pg_image___localFile___blksize = 'relationships___field_pg_image___localFile___blksize',
  relationships___field_pg_image___localFile___ino = 'relationships___field_pg_image___localFile___ino',
  relationships___field_pg_image___localFile___blocks = 'relationships___field_pg_image___localFile___blocks',
  relationships___field_pg_image___localFile___atimeMs = 'relationships___field_pg_image___localFile___atimeMs',
  relationships___field_pg_image___localFile___mtimeMs = 'relationships___field_pg_image___localFile___mtimeMs',
  relationships___field_pg_image___localFile___ctimeMs = 'relationships___field_pg_image___localFile___ctimeMs',
  relationships___field_pg_image___localFile___atime = 'relationships___field_pg_image___localFile___atime',
  relationships___field_pg_image___localFile___mtime = 'relationships___field_pg_image___localFile___mtime',
  relationships___field_pg_image___localFile___ctime = 'relationships___field_pg_image___localFile___ctime',
  relationships___field_pg_image___localFile___url = 'relationships___field_pg_image___localFile___url',
  relationships___field_pg_image___localFile___id = 'relationships___field_pg_image___localFile___id',
  relationships___field_pg_image___localFile___children = 'relationships___field_pg_image___localFile___children',
  relationships___field_pg_image___uri___url = 'relationships___field_pg_image___uri___url',
  relationships___field_pg_image___drupal_id = 'relationships___field_pg_image___drupal_id',
  relationships___field_pg_image___drupal_internal__fid = 'relationships___field_pg_image___drupal_internal__fid',
  relationships___field_pg_image___langcode = 'relationships___field_pg_image___langcode',
  relationships___field_pg_image___filename = 'relationships___field_pg_image___filename',
  relationships___field_pg_image___filemime = 'relationships___field_pg_image___filemime',
  relationships___field_pg_image___filesize = 'relationships___field_pg_image___filesize',
  relationships___field_pg_image___status = 'relationships___field_pg_image___status',
  relationships___field_pg_image___created = 'relationships___field_pg_image___created',
  relationships___field_pg_image___changed = 'relationships___field_pg_image___changed',
  relationships___field_pg_image___uuid = 'relationships___field_pg_image___uuid',
  relationships___field_pg_image___relationships___node__blog_post = 'relationships___field_pg_image___relationships___node__blog_post',
  relationships___field_pg_image___relationships___node__devices = 'relationships___field_pg_image___relationships___node__devices',
  relationships___field_pg_image___relationships___node__accessories = 'relationships___field_pg_image___relationships___node__accessories',
  relationships___field_pg_image___relationships___paragraph__news_image_title_text = 'relationships___field_pg_image___relationships___paragraph__news_image_title_text',
  relationships___field_pg_image___relationships___node__cases = 'relationships___field_pg_image___relationships___node__cases',
  relationships___field_pg_image___relationships___paragraph__branches_what_is_ipos = 'relationships___field_pg_image___relationships___paragraph__branches_what_is_ipos',
  relationships___field_pg_image___relationships___paragraph__branches_features = 'relationships___field_pg_image___relationships___paragraph__branches_features',
  relationships___field_pg_image___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_pg_image___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_pg_image___relationships___paragraph__branches_testimonials = 'relationships___field_pg_image___relationships___paragraph__branches_testimonials',
  relationships___field_pg_image___relationships___paragraph__branches_discounts = 'relationships___field_pg_image___relationships___paragraph__branches_discounts',
  relationships___field_pg_image___relationships___node__news = 'relationships___field_pg_image___relationships___node__news',
  relationships___field_pg_image___relationships___node__branches = 'relationships___field_pg_image___relationships___node__branches',
  relationships___field_pg_image___relationships___paragraph__branches_hero = 'relationships___field_pg_image___relationships___paragraph__branches_hero',
  relationships___field_pg_image___relationships___paragraph__bullets = 'relationships___field_pg_image___relationships___paragraph__bullets',
  relationships___field_pg_image___relationships___taxonomy_term__pricelist_group = 'relationships___field_pg_image___relationships___taxonomy_term__pricelist_group',
  relationships___field_pg_image___relationships___paragraph__branches_device = 'relationships___field_pg_image___relationships___paragraph__branches_device',
  relationships___field_pg_image___relationships___paragraph__branches_recommendation = 'relationships___field_pg_image___relationships___paragraph__branches_recommendation',
  relationships___field_pg_image___relationships___site_setting_entity__accessories = 'relationships___field_pg_image___relationships___site_setting_entity__accessories',
  relationships___field_pg_image___relationships___site_setting_entity__branches = 'relationships___field_pg_image___relationships___site_setting_entity__branches',
  relationships___field_pg_image___relationships___site_setting_entity__about_us = 'relationships___field_pg_image___relationships___site_setting_entity__about_us',
  relationships___field_pg_image___relationships___paragraph__logos = 'relationships___field_pg_image___relationships___paragraph__logos',
  relationships___field_pg_image___relationships___paragraph__how_it_works = 'relationships___field_pg_image___relationships___paragraph__how_it_works',
  relationships___field_pg_image___relationships___paragraph__ico_and_text = 'relationships___field_pg_image___relationships___paragraph__ico_and_text',
  relationships___field_pg_image___relationships___paragraph__hero_1 = 'relationships___field_pg_image___relationships___paragraph__hero_1',
  relationships___field_pg_image___relationships___paragraph__comprehensive_solution_item = 'relationships___field_pg_image___relationships___paragraph__comprehensive_solution_item',
  relationships___field_pg_image___relationships___site_setting_entity__product = 'relationships___field_pg_image___relationships___site_setting_entity__product',
  relationships___field_pg_image___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_pg_image___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_pg_image___relationships___paragraph__porownanie_terminali = 'relationships___field_pg_image___relationships___paragraph__porownanie_terminali',
  relationships___field_pg_image___relationships___paragraph__zdjecie_opis_link = 'relationships___field_pg_image___relationships___paragraph__zdjecie_opis_link',
  relationships___field_pg_image___relationships___site_setting_entity__main_page = 'relationships___field_pg_image___relationships___site_setting_entity__main_page',
  relationships___field_pg_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_pg_image___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_pg_image___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_pg_image___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_pg_image___relationships___site_setting_entity__intercars = 'relationships___field_pg_image___relationships___site_setting_entity__intercars',
  relationships___field_pg_image___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_pg_image___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_pg_image___relationships___node__agreement = 'relationships___field_pg_image___relationships___node__agreement',
  relationships___field_pg_image___relationships___node__fiscalization = 'relationships___field_pg_image___relationships___node__fiscalization',
  relationships___field_pg_image___relationships___site_setting_entity__configurator = 'relationships___field_pg_image___relationships___site_setting_entity__configurator',
  relationships___field_pg_image___relationships___paragraph__social_link = 'relationships___field_pg_image___relationships___paragraph__social_link',
  relationships___field_pg_image___relationships___paragraph__make_an_appointment = 'relationships___field_pg_image___relationships___paragraph__make_an_appointment',
  relationships___field_pg_image___relationships___paragraph__linked_icons = 'relationships___field_pg_image___relationships___paragraph__linked_icons',
  relationships___field_pg_image___relationships___site_setting_entity__financing = 'relationships___field_pg_image___relationships___site_setting_entity__financing',
  relationships___field_pg_image___relationships___site_setting_entity__franchise = 'relationships___field_pg_image___relationships___site_setting_entity__franchise',
  relationships___field_pg_image___id = 'relationships___field_pg_image___id',
  relationships___field_pg_image___parent___id = 'relationships___field_pg_image___parent___id',
  relationships___field_pg_image___parent___children = 'relationships___field_pg_image___parent___children',
  relationships___field_pg_image___children = 'relationships___field_pg_image___children',
  relationships___field_pg_image___children___id = 'relationships___field_pg_image___children___id',
  relationships___field_pg_image___children___children = 'relationships___field_pg_image___children___children',
  relationships___field_pg_image___internal___content = 'relationships___field_pg_image___internal___content',
  relationships___field_pg_image___internal___contentDigest = 'relationships___field_pg_image___internal___contentDigest',
  relationships___field_pg_image___internal___description = 'relationships___field_pg_image___internal___description',
  relationships___field_pg_image___internal___fieldOwners = 'relationships___field_pg_image___internal___fieldOwners',
  relationships___field_pg_image___internal___ignoreType = 'relationships___field_pg_image___internal___ignoreType',
  relationships___field_pg_image___internal___mediaType = 'relationships___field_pg_image___internal___mediaType',
  relationships___field_pg_image___internal___owner = 'relationships___field_pg_image___internal___owner',
  relationships___field_pg_image___internal___type = 'relationships___field_pg_image___internal___type',
  relationships___field_pg_image_large___localFile___birthtime = 'relationships___field_pg_image_large___localFile___birthtime',
  relationships___field_pg_image_large___localFile___birthtimeMs = 'relationships___field_pg_image_large___localFile___birthtimeMs',
  relationships___field_pg_image_large___localFile___publicURL = 'relationships___field_pg_image_large___localFile___publicURL',
  relationships___field_pg_image_large___localFile___sourceInstanceName = 'relationships___field_pg_image_large___localFile___sourceInstanceName',
  relationships___field_pg_image_large___localFile___absolutePath = 'relationships___field_pg_image_large___localFile___absolutePath',
  relationships___field_pg_image_large___localFile___relativePath = 'relationships___field_pg_image_large___localFile___relativePath',
  relationships___field_pg_image_large___localFile___extension = 'relationships___field_pg_image_large___localFile___extension',
  relationships___field_pg_image_large___localFile___size = 'relationships___field_pg_image_large___localFile___size',
  relationships___field_pg_image_large___localFile___prettySize = 'relationships___field_pg_image_large___localFile___prettySize',
  relationships___field_pg_image_large___localFile___modifiedTime = 'relationships___field_pg_image_large___localFile___modifiedTime',
  relationships___field_pg_image_large___localFile___accessTime = 'relationships___field_pg_image_large___localFile___accessTime',
  relationships___field_pg_image_large___localFile___changeTime = 'relationships___field_pg_image_large___localFile___changeTime',
  relationships___field_pg_image_large___localFile___birthTime = 'relationships___field_pg_image_large___localFile___birthTime',
  relationships___field_pg_image_large___localFile___root = 'relationships___field_pg_image_large___localFile___root',
  relationships___field_pg_image_large___localFile___dir = 'relationships___field_pg_image_large___localFile___dir',
  relationships___field_pg_image_large___localFile___base = 'relationships___field_pg_image_large___localFile___base',
  relationships___field_pg_image_large___localFile___ext = 'relationships___field_pg_image_large___localFile___ext',
  relationships___field_pg_image_large___localFile___name = 'relationships___field_pg_image_large___localFile___name',
  relationships___field_pg_image_large___localFile___relativeDirectory = 'relationships___field_pg_image_large___localFile___relativeDirectory',
  relationships___field_pg_image_large___localFile___dev = 'relationships___field_pg_image_large___localFile___dev',
  relationships___field_pg_image_large___localFile___mode = 'relationships___field_pg_image_large___localFile___mode',
  relationships___field_pg_image_large___localFile___nlink = 'relationships___field_pg_image_large___localFile___nlink',
  relationships___field_pg_image_large___localFile___uid = 'relationships___field_pg_image_large___localFile___uid',
  relationships___field_pg_image_large___localFile___gid = 'relationships___field_pg_image_large___localFile___gid',
  relationships___field_pg_image_large___localFile___rdev = 'relationships___field_pg_image_large___localFile___rdev',
  relationships___field_pg_image_large___localFile___blksize = 'relationships___field_pg_image_large___localFile___blksize',
  relationships___field_pg_image_large___localFile___ino = 'relationships___field_pg_image_large___localFile___ino',
  relationships___field_pg_image_large___localFile___blocks = 'relationships___field_pg_image_large___localFile___blocks',
  relationships___field_pg_image_large___localFile___atimeMs = 'relationships___field_pg_image_large___localFile___atimeMs',
  relationships___field_pg_image_large___localFile___mtimeMs = 'relationships___field_pg_image_large___localFile___mtimeMs',
  relationships___field_pg_image_large___localFile___ctimeMs = 'relationships___field_pg_image_large___localFile___ctimeMs',
  relationships___field_pg_image_large___localFile___atime = 'relationships___field_pg_image_large___localFile___atime',
  relationships___field_pg_image_large___localFile___mtime = 'relationships___field_pg_image_large___localFile___mtime',
  relationships___field_pg_image_large___localFile___ctime = 'relationships___field_pg_image_large___localFile___ctime',
  relationships___field_pg_image_large___localFile___url = 'relationships___field_pg_image_large___localFile___url',
  relationships___field_pg_image_large___localFile___id = 'relationships___field_pg_image_large___localFile___id',
  relationships___field_pg_image_large___localFile___children = 'relationships___field_pg_image_large___localFile___children',
  relationships___field_pg_image_large___uri___url = 'relationships___field_pg_image_large___uri___url',
  relationships___field_pg_image_large___drupal_id = 'relationships___field_pg_image_large___drupal_id',
  relationships___field_pg_image_large___drupal_internal__fid = 'relationships___field_pg_image_large___drupal_internal__fid',
  relationships___field_pg_image_large___langcode = 'relationships___field_pg_image_large___langcode',
  relationships___field_pg_image_large___filename = 'relationships___field_pg_image_large___filename',
  relationships___field_pg_image_large___filemime = 'relationships___field_pg_image_large___filemime',
  relationships___field_pg_image_large___filesize = 'relationships___field_pg_image_large___filesize',
  relationships___field_pg_image_large___status = 'relationships___field_pg_image_large___status',
  relationships___field_pg_image_large___created = 'relationships___field_pg_image_large___created',
  relationships___field_pg_image_large___changed = 'relationships___field_pg_image_large___changed',
  relationships___field_pg_image_large___uuid = 'relationships___field_pg_image_large___uuid',
  relationships___field_pg_image_large___relationships___node__blog_post = 'relationships___field_pg_image_large___relationships___node__blog_post',
  relationships___field_pg_image_large___relationships___node__devices = 'relationships___field_pg_image_large___relationships___node__devices',
  relationships___field_pg_image_large___relationships___node__accessories = 'relationships___field_pg_image_large___relationships___node__accessories',
  relationships___field_pg_image_large___relationships___paragraph__news_image_title_text = 'relationships___field_pg_image_large___relationships___paragraph__news_image_title_text',
  relationships___field_pg_image_large___relationships___node__cases = 'relationships___field_pg_image_large___relationships___node__cases',
  relationships___field_pg_image_large___relationships___paragraph__branches_what_is_ipos = 'relationships___field_pg_image_large___relationships___paragraph__branches_what_is_ipos',
  relationships___field_pg_image_large___relationships___paragraph__branches_features = 'relationships___field_pg_image_large___relationships___paragraph__branches_features',
  relationships___field_pg_image_large___relationships___paragraph__branches_check_what_s_going_on = 'relationships___field_pg_image_large___relationships___paragraph__branches_check_what_s_going_on',
  relationships___field_pg_image_large___relationships___paragraph__branches_testimonials = 'relationships___field_pg_image_large___relationships___paragraph__branches_testimonials',
  relationships___field_pg_image_large___relationships___paragraph__branches_discounts = 'relationships___field_pg_image_large___relationships___paragraph__branches_discounts',
  relationships___field_pg_image_large___relationships___node__news = 'relationships___field_pg_image_large___relationships___node__news',
  relationships___field_pg_image_large___relationships___node__branches = 'relationships___field_pg_image_large___relationships___node__branches',
  relationships___field_pg_image_large___relationships___paragraph__branches_hero = 'relationships___field_pg_image_large___relationships___paragraph__branches_hero',
  relationships___field_pg_image_large___relationships___paragraph__bullets = 'relationships___field_pg_image_large___relationships___paragraph__bullets',
  relationships___field_pg_image_large___relationships___taxonomy_term__pricelist_group = 'relationships___field_pg_image_large___relationships___taxonomy_term__pricelist_group',
  relationships___field_pg_image_large___relationships___paragraph__branches_device = 'relationships___field_pg_image_large___relationships___paragraph__branches_device',
  relationships___field_pg_image_large___relationships___paragraph__branches_recommendation = 'relationships___field_pg_image_large___relationships___paragraph__branches_recommendation',
  relationships___field_pg_image_large___relationships___site_setting_entity__accessories = 'relationships___field_pg_image_large___relationships___site_setting_entity__accessories',
  relationships___field_pg_image_large___relationships___site_setting_entity__branches = 'relationships___field_pg_image_large___relationships___site_setting_entity__branches',
  relationships___field_pg_image_large___relationships___site_setting_entity__about_us = 'relationships___field_pg_image_large___relationships___site_setting_entity__about_us',
  relationships___field_pg_image_large___relationships___paragraph__logos = 'relationships___field_pg_image_large___relationships___paragraph__logos',
  relationships___field_pg_image_large___relationships___paragraph__how_it_works = 'relationships___field_pg_image_large___relationships___paragraph__how_it_works',
  relationships___field_pg_image_large___relationships___paragraph__ico_and_text = 'relationships___field_pg_image_large___relationships___paragraph__ico_and_text',
  relationships___field_pg_image_large___relationships___paragraph__hero_1 = 'relationships___field_pg_image_large___relationships___paragraph__hero_1',
  relationships___field_pg_image_large___relationships___paragraph__comprehensive_solution_item = 'relationships___field_pg_image_large___relationships___paragraph__comprehensive_solution_item',
  relationships___field_pg_image_large___relationships___site_setting_entity__product = 'relationships___field_pg_image_large___relationships___site_setting_entity__product',
  relationships___field_pg_image_large___relationships___paragraph__zdjecie_opis_link_ico = 'relationships___field_pg_image_large___relationships___paragraph__zdjecie_opis_link_ico',
  relationships___field_pg_image_large___relationships___paragraph__porownanie_terminali = 'relationships___field_pg_image_large___relationships___paragraph__porownanie_terminali',
  relationships___field_pg_image_large___relationships___paragraph__zdjecie_opis_link = 'relationships___field_pg_image_large___relationships___paragraph__zdjecie_opis_link',
  relationships___field_pg_image_large___relationships___site_setting_entity__main_page = 'relationships___field_pg_image_large___relationships___site_setting_entity__main_page',
  relationships___field_pg_image_large___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal = 'relationships___field_pg_image_large___relationships___site_setting_entity__kasa_fiskalna_vs_kasoterminal',
  relationships___field_pg_image_large___relationships___paragraph__2_zdjecia_opisy_link = 'relationships___field_pg_image_large___relationships___paragraph__2_zdjecia_opisy_link',
  relationships___field_pg_image_large___relationships___site_setting_entity__intercars = 'relationships___field_pg_image_large___relationships___site_setting_entity__intercars',
  relationships___field_pg_image_large___relationships___paragraph__member_of_the_board_personal_dat = 'relationships___field_pg_image_large___relationships___paragraph__member_of_the_board_personal_dat',
  relationships___field_pg_image_large___relationships___node__agreement = 'relationships___field_pg_image_large___relationships___node__agreement',
  relationships___field_pg_image_large___relationships___node__fiscalization = 'relationships___field_pg_image_large___relationships___node__fiscalization',
  relationships___field_pg_image_large___relationships___site_setting_entity__configurator = 'relationships___field_pg_image_large___relationships___site_setting_entity__configurator',
  relationships___field_pg_image_large___relationships___paragraph__social_link = 'relationships___field_pg_image_large___relationships___paragraph__social_link',
  relationships___field_pg_image_large___relationships___paragraph__make_an_appointment = 'relationships___field_pg_image_large___relationships___paragraph__make_an_appointment',
  relationships___field_pg_image_large___relationships___paragraph__linked_icons = 'relationships___field_pg_image_large___relationships___paragraph__linked_icons',
  relationships___field_pg_image_large___relationships___site_setting_entity__financing = 'relationships___field_pg_image_large___relationships___site_setting_entity__financing',
  relationships___field_pg_image_large___relationships___site_setting_entity__franchise = 'relationships___field_pg_image_large___relationships___site_setting_entity__franchise',
  relationships___field_pg_image_large___id = 'relationships___field_pg_image_large___id',
  relationships___field_pg_image_large___parent___id = 'relationships___field_pg_image_large___parent___id',
  relationships___field_pg_image_large___parent___children = 'relationships___field_pg_image_large___parent___children',
  relationships___field_pg_image_large___children = 'relationships___field_pg_image_large___children',
  relationships___field_pg_image_large___children___id = 'relationships___field_pg_image_large___children___id',
  relationships___field_pg_image_large___children___children = 'relationships___field_pg_image_large___children___children',
  relationships___field_pg_image_large___internal___content = 'relationships___field_pg_image_large___internal___content',
  relationships___field_pg_image_large___internal___contentDigest = 'relationships___field_pg_image_large___internal___contentDigest',
  relationships___field_pg_image_large___internal___description = 'relationships___field_pg_image_large___internal___description',
  relationships___field_pg_image_large___internal___fieldOwners = 'relationships___field_pg_image_large___internal___fieldOwners',
  relationships___field_pg_image_large___internal___ignoreType = 'relationships___field_pg_image_large___internal___ignoreType',
  relationships___field_pg_image_large___internal___mediaType = 'relationships___field_pg_image_large___internal___mediaType',
  relationships___field_pg_image_large___internal___owner = 'relationships___field_pg_image_large___internal___owner',
  relationships___field_pg_image_large___internal___type = 'relationships___field_pg_image_large___internal___type',
  relationships___node__price_list = 'relationships___node__price_list',
  relationships___node__price_list___drupal_id = 'relationships___node__price_list___drupal_id',
  relationships___node__price_list___fixedprice = 'relationships___node__price_list___fixedprice',
  relationships___node__price_list___iposfee = 'relationships___node__price_list___iposfee',
  relationships___node__price_list___langcode = 'relationships___node__price_list___langcode',
  relationships___node__price_list___status = 'relationships___node__price_list___status',
  relationships___node__price_list___field_pl_locationfee = 'relationships___node__price_list___field_pl_locationfee',
  relationships___node__price_list___field_pl_name = 'relationships___node__price_list___field_pl_name',
  relationships___node__price_list___field_pl_optionname = 'relationships___node__price_list___field_pl_optionname',
  relationships___node__price_list___field_pl_lease = 'relationships___node__price_list___field_pl_lease',
  relationships___node__price_list___field_pl_lease_name = 'relationships___node__price_list___field_pl_lease_name',
  relationships___node__price_list___field_pl_payment_type = 'relationships___node__price_list___field_pl_payment_type',
  relationships___node__price_list___field_pl_activation_price = 'relationships___node__price_list___field_pl_activation_price',
  relationships___node__price_list___field_pl_contract_duration = 'relationships___node__price_list___field_pl_contract_duration',
  relationships___node__price_list___field_pl_group_name_in_api = 'relationships___node__price_list___field_pl_group_name_in_api',
  relationships___node__price_list___field_pl_reduced_fee_duration = 'relationships___node__price_list___field_pl_reduced_fee_duration',
  relationships___node__price_list___field_pl_reduced_fee = 'relationships___node__price_list___field_pl_reduced_fee',
  relationships___node__price_list___drupal_internal__nid = 'relationships___node__price_list___drupal_internal__nid',
  relationships___node__price_list___content_translation_source = 'relationships___node__price_list___content_translation_source',
  relationships___node__price_list___content_translation_outdated = 'relationships___node__price_list___content_translation_outdated',
  relationships___node__price_list___field_pl_first_ipos_fee = 'relationships___node__price_list___field_pl_first_ipos_fee',
  relationships___node__price_list___vatrate = 'relationships___node__price_list___vatrate',
  relationships___node__price_list___uuid = 'relationships___node__price_list___uuid',
  relationships___node__price_list___relationships___node__accessories = 'relationships___node__price_list___relationships___node__accessories',
  relationships___node__price_list___relationships___node__cases = 'relationships___node__price_list___relationships___node__cases',
  relationships___node__price_list___relationships___paragraph__branches_tariff = 'relationships___node__price_list___relationships___paragraph__branches_tariff',
  relationships___node__price_list___pricelistgroup___drupal_internal__target_id = 'relationships___node__price_list___pricelistgroup___drupal_internal__target_id',
  relationships___node__price_list___id = 'relationships___node__price_list___id',
  relationships___node__price_list___parent___id = 'relationships___node__price_list___parent___id',
  relationships___node__price_list___parent___children = 'relationships___node__price_list___parent___children',
  relationships___node__price_list___children = 'relationships___node__price_list___children',
  relationships___node__price_list___children___id = 'relationships___node__price_list___children___id',
  relationships___node__price_list___children___children = 'relationships___node__price_list___children___children',
  relationships___node__price_list___internal___content = 'relationships___node__price_list___internal___content',
  relationships___node__price_list___internal___contentDigest = 'relationships___node__price_list___internal___contentDigest',
  relationships___node__price_list___internal___description = 'relationships___node__price_list___internal___description',
  relationships___node__price_list___internal___fieldOwners = 'relationships___node__price_list___internal___fieldOwners',
  relationships___node__price_list___internal___ignoreType = 'relationships___node__price_list___internal___ignoreType',
  relationships___node__price_list___internal___mediaType = 'relationships___node__price_list___internal___mediaType',
  relationships___node__price_list___internal___owner = 'relationships___node__price_list___internal___owner',
  relationships___node__price_list___internal___type = 'relationships___node__price_list___internal___type',
  relationships___paragraph__profit_reference_to_price_list = 'relationships___paragraph__profit_reference_to_price_list',
  relationships___paragraph__profit_reference_to_price_list___field_text___value = 'relationships___paragraph__profit_reference_to_price_list___field_text___value',
  relationships___paragraph__profit_reference_to_price_list___drupal_id = 'relationships___paragraph__profit_reference_to_price_list___drupal_id',
  relationships___paragraph__profit_reference_to_price_list___drupal_internal__id = 'relationships___paragraph__profit_reference_to_price_list___drupal_internal__id',
  relationships___paragraph__profit_reference_to_price_list___langcode = 'relationships___paragraph__profit_reference_to_price_list___langcode',
  relationships___paragraph__profit_reference_to_price_list___relationships___field_price_group_reference = 'relationships___paragraph__profit_reference_to_price_list___relationships___field_price_group_reference',
  relationships___paragraph__profit_reference_to_price_list___relationships___paragraph__profits = 'relationships___paragraph__profit_reference_to_price_list___relationships___paragraph__profits',
  relationships___paragraph__profit_reference_to_price_list___drupal_internal__revision_id = 'relationships___paragraph__profit_reference_to_price_list___drupal_internal__revision_id',
  relationships___paragraph__profit_reference_to_price_list___status = 'relationships___paragraph__profit_reference_to_price_list___status',
  relationships___paragraph__profit_reference_to_price_list___created = 'relationships___paragraph__profit_reference_to_price_list___created',
  relationships___paragraph__profit_reference_to_price_list___parent_id = 'relationships___paragraph__profit_reference_to_price_list___parent_id',
  relationships___paragraph__profit_reference_to_price_list___parent_type = 'relationships___paragraph__profit_reference_to_price_list___parent_type',
  relationships___paragraph__profit_reference_to_price_list___parent_field_name = 'relationships___paragraph__profit_reference_to_price_list___parent_field_name',
  relationships___paragraph__profit_reference_to_price_list___default_langcode = 'relationships___paragraph__profit_reference_to_price_list___default_langcode',
  relationships___paragraph__profit_reference_to_price_list___revision_translation_affected = 'relationships___paragraph__profit_reference_to_price_list___revision_translation_affected',
  relationships___paragraph__profit_reference_to_price_list___content_translation_source = 'relationships___paragraph__profit_reference_to_price_list___content_translation_source',
  relationships___paragraph__profit_reference_to_price_list___content_translation_outdated = 'relationships___paragraph__profit_reference_to_price_list___content_translation_outdated',
  relationships___paragraph__profit_reference_to_price_list___content_translation_changed = 'relationships___paragraph__profit_reference_to_price_list___content_translation_changed',
  relationships___paragraph__profit_reference_to_price_list___uuid = 'relationships___paragraph__profit_reference_to_price_list___uuid',
  relationships___paragraph__profit_reference_to_price_list___field_price_group_reference = 'relationships___paragraph__profit_reference_to_price_list___field_price_group_reference',
  relationships___paragraph__profit_reference_to_price_list___field_price_group_reference___drupal_internal__target_id = 'relationships___paragraph__profit_reference_to_price_list___field_price_group_reference___drupal_internal__target_id',
  relationships___paragraph__profit_reference_to_price_list___id = 'relationships___paragraph__profit_reference_to_price_list___id',
  relationships___paragraph__profit_reference_to_price_list___parent___id = 'relationships___paragraph__profit_reference_to_price_list___parent___id',
  relationships___paragraph__profit_reference_to_price_list___parent___children = 'relationships___paragraph__profit_reference_to_price_list___parent___children',
  relationships___paragraph__profit_reference_to_price_list___children = 'relationships___paragraph__profit_reference_to_price_list___children',
  relationships___paragraph__profit_reference_to_price_list___children___id = 'relationships___paragraph__profit_reference_to_price_list___children___id',
  relationships___paragraph__profit_reference_to_price_list___children___children = 'relationships___paragraph__profit_reference_to_price_list___children___children',
  relationships___paragraph__profit_reference_to_price_list___internal___content = 'relationships___paragraph__profit_reference_to_price_list___internal___content',
  relationships___paragraph__profit_reference_to_price_list___internal___contentDigest = 'relationships___paragraph__profit_reference_to_price_list___internal___contentDigest',
  relationships___paragraph__profit_reference_to_price_list___internal___description = 'relationships___paragraph__profit_reference_to_price_list___internal___description',
  relationships___paragraph__profit_reference_to_price_list___internal___fieldOwners = 'relationships___paragraph__profit_reference_to_price_list___internal___fieldOwners',
  relationships___paragraph__profit_reference_to_price_list___internal___ignoreType = 'relationships___paragraph__profit_reference_to_price_list___internal___ignoreType',
  relationships___paragraph__profit_reference_to_price_list___internal___mediaType = 'relationships___paragraph__profit_reference_to_price_list___internal___mediaType',
  relationships___paragraph__profit_reference_to_price_list___internal___owner = 'relationships___paragraph__profit_reference_to_price_list___internal___owner',
  relationships___paragraph__profit_reference_to_price_list___internal___type = 'relationships___paragraph__profit_reference_to_price_list___internal___type',
  field_is_discount = 'field_is_discount',
  field_side_panel_info = 'field_side_panel_info',
  field_is_default = 'field_is_default',
  drupal_internal__tid = 'drupal_internal__tid',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  revision_created = 'revision_created',
  status = 'status',
  weight = 'weight',
  changed = 'changed',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  content_translation_created = 'content_translation_created',
  field_coupon_in_api = 'field_coupon_in_api',
  uuid = 'uuid',
  vid___drupal_internal__target_id = 'vid___drupal_internal__target_id',
  field_pg_image___alt = 'field_pg_image___alt',
  field_pg_image___title = 'field_pg_image___title',
  field_pg_image___width = 'field_pg_image___width',
  field_pg_image___height = 'field_pg_image___height',
  field_pg_image___drupal_internal__target_id = 'field_pg_image___drupal_internal__target_id',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
}

export type Taxonomy_Term__Pricelist_GroupFilterInput = {
  langcode?: Maybe<StringQueryOperatorInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<DescriptionFilterInput>;
  field_discount?: Maybe<IntQueryOperatorInput>;
  field_name_in_api?: Maybe<StringQueryOperatorInput>;
  field_name_in_cms?: Maybe<StringQueryOperatorInput>;
  field_isperproduct?: Maybe<BooleanQueryOperatorInput>;
  field_maxfundedproducts?: Maybe<IntQueryOperatorInput>;
  field_publish?: Maybe<BooleanQueryOperatorInput>;
  field_text_when_unpublished?: Maybe<Field_Text_When_UnpublishedFilterInput>;
  relationships?: Maybe<Taxonomy_Term__Pricelist_GroupRelationshipsFilterInput>;
  field_is_discount?: Maybe<BooleanQueryOperatorInput>;
  field_side_panel_info?: Maybe<StringQueryOperatorInput>;
  field_is_default?: Maybe<BooleanQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  content_translation_created?: Maybe<DateQueryOperatorInput>;
  field_coupon_in_api?: Maybe<StringQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  vid?: Maybe<Taxonomy_Term__Pricelist_GroupVidFilterInput>;
  field_pg_image?: Maybe<Taxonomy_Term__Pricelist_GroupField_Pg_ImageFilterInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type Taxonomy_Term__Pricelist_GroupFilterListInput = {
  elemMatch?: Maybe<Taxonomy_Term__Pricelist_GroupFilterInput>;
};

export type Taxonomy_Term__Pricelist_GroupGroupConnection = {
  __typename?: 'taxonomy_term__pricelist_groupGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Pricelist_GroupEdge>;
  nodes: Array<Taxonomy_Term__Pricelist_Group>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Product_Categories_GroupGroupConnection = {
  __typename?: 'taxonomy_term__product_categories_groupGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Product_Categories_GroupEdge>;
  nodes: Array<Taxonomy_Term__Product_Category>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Pricelist_GroupRelationships = {
  __typename?: 'taxonomy_term__pricelist_groupRelationships';
  field_pg_image?: Maybe<File__File>;
  field_pg_image_large?: Maybe<File__File>;
  node__price_list?: Maybe<Array<Maybe<Node__Price_List>>>;
  paragraph__profit_reference_to_price_list?: Maybe<
    Array<Maybe<Paragraph__Profit_Reference_To_Price_List>>
  >;
};

export type Taxonomy_Term__Pricelist_GroupRelationshipsFilterInput = {
  field_pg_image?: Maybe<File__FileFilterInput>;
  field_pg_image_large?: Maybe<File__FileFilterInput>;
  node__price_list?: Maybe<Node__Price_ListFilterListInput>;
  paragraph__profit_reference_to_price_list?: Maybe<
    Paragraph__Profit_Reference_To_Price_ListFilterListInput
  >;
};

export type Taxonomy_Term__Pricelist_GroupSortInput = {
  fields?: Maybe<Array<Maybe<Taxonomy_Term__Pricelist_GroupFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Taxonomy_Term__Pricelist_GroupVid = {
  __typename?: 'taxonomy_term__pricelist_groupVid';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Pricelist_GroupVidFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Taxonomy_Term__Type_In_Cms = Node & {
  __typename?: 'taxonomy_term__type_in_cms';
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  drupal_id?: Maybe<Scalars['String']>;
  drupal_internal__tid?: Maybe<Scalars['Int']>;
  drupal_internal__revision_id?: Maybe<Scalars['Int']>;
  langcode?: Maybe<Scalars['String']>;
  revision_created?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  changed?: Maybe<Scalars['Date']>;
  default_langcode?: Maybe<Scalars['Boolean']>;
  revision_translation_affected?: Maybe<Scalars['Boolean']>;
  rh_action?: Maybe<Scalars['String']>;
  rh_redirect_response?: Maybe<Scalars['Int']>;
  content_translation_source?: Maybe<Scalars['String']>;
  content_translation_outdated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  vid?: Maybe<Taxonomy_Term__Type_In_CmsVid>;
};

export type Taxonomy_Term__Type_In_CmsRevision_CreatedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Type_In_CmsChangedArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Type_In_CmsConnection = {
  __typename?: 'taxonomy_term__type_in_cmsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Type_In_CmsEdge>;
  nodes: Array<Taxonomy_Term__Type_In_Cms>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<Taxonomy_Term__Type_In_CmsGroupConnection>;
};

export type Taxonomy_Term__Type_In_CmsConnectionDistinctArgs = {
  field: Taxonomy_Term__Type_In_CmsFieldsEnum;
};

export type Taxonomy_Term__Type_In_CmsConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: Taxonomy_Term__Type_In_CmsFieldsEnum;
};

export type Taxonomy_Term__Type_In_CmsEdge = {
  __typename?: 'taxonomy_term__type_in_cmsEdge';
  next?: Maybe<Taxonomy_Term__Type_In_Cms>;
  node: Taxonomy_Term__Type_In_Cms;
  previous?: Maybe<Taxonomy_Term__Type_In_Cms>;
};

export enum Taxonomy_Term__Type_In_CmsFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  drupal_id = 'drupal_id',
  drupal_internal__tid = 'drupal_internal__tid',
  drupal_internal__revision_id = 'drupal_internal__revision_id',
  langcode = 'langcode',
  revision_created = 'revision_created',
  status = 'status',
  name = 'name',
  weight = 'weight',
  changed = 'changed',
  default_langcode = 'default_langcode',
  revision_translation_affected = 'revision_translation_affected',
  rh_action = 'rh_action',
  rh_redirect_response = 'rh_redirect_response',
  content_translation_source = 'content_translation_source',
  content_translation_outdated = 'content_translation_outdated',
  uuid = 'uuid',
  vid___drupal_internal__target_id = 'vid___drupal_internal__target_id',
}

export type Taxonomy_Term__Type_In_CmsFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  drupal_id?: Maybe<StringQueryOperatorInput>;
  drupal_internal__tid?: Maybe<IntQueryOperatorInput>;
  drupal_internal__revision_id?: Maybe<IntQueryOperatorInput>;
  langcode?: Maybe<StringQueryOperatorInput>;
  revision_created?: Maybe<DateQueryOperatorInput>;
  status?: Maybe<BooleanQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  weight?: Maybe<IntQueryOperatorInput>;
  changed?: Maybe<DateQueryOperatorInput>;
  default_langcode?: Maybe<BooleanQueryOperatorInput>;
  revision_translation_affected?: Maybe<BooleanQueryOperatorInput>;
  rh_action?: Maybe<StringQueryOperatorInput>;
  rh_redirect_response?: Maybe<IntQueryOperatorInput>;
  content_translation_source?: Maybe<StringQueryOperatorInput>;
  content_translation_outdated?: Maybe<BooleanQueryOperatorInput>;
  uuid?: Maybe<StringQueryOperatorInput>;
  vid?: Maybe<Taxonomy_Term__Type_In_CmsVidFilterInput>;
};

export type Taxonomy_Term__Type_In_CmsGroupConnection = {
  __typename?: 'taxonomy_term__type_in_cmsGroupConnection';
  totalCount: Scalars['Int'];
  edges: Array<Taxonomy_Term__Type_In_CmsEdge>;
  nodes: Array<Taxonomy_Term__Type_In_Cms>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Type_In_CmsSortInput = {
  fields?: Maybe<Array<Maybe<Taxonomy_Term__Type_In_CmsFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Taxonomy_Term__Type_In_CmsVid = {
  __typename?: 'taxonomy_term__type_in_cmsVid';
  drupal_internal__target_id?: Maybe<Scalars['String']>;
};

export type Taxonomy_Term__Type_In_CmsVidFilterInput = {
  drupal_internal__target_id?: Maybe<StringQueryOperatorInput>;
};

export type Uri = {
  __typename?: 'uri';
  url?: Maybe<Scalars['String']>;
};

export type UriFilterInput = {
  url?: Maybe<StringQueryOperatorInput>;
};

export type VsTerminalRelatedComponents = Paragraph__2_Zdjecia_Opisy_Link;

export enum PriceListPaymentTypes {
  casch = 'PRZELEW',
  lease = 'NAJEM',
}