import * as React from 'react';
import styled from '@emotion/styled';

import IconArrowRight from '../../assets/svg-inline/arrow-right.svg';
import IconIposWeb from '../../assets/svg-inline/ipos-web.svg';
import { Stylable } from '@content/types/general';

const StyledIcon = styled.span`
  display: inline-block;

  &.inherit-color {
    g {
      stroke: currentColor; /* let the icon have font color */
    }
  }

  svg {
    &.rotate-270 {
      transform: rotate(270deg);
    }
    &.rotate-90 {
      transform: rotate(90deg);
    }
    &.rotate-180 {
      transform: rotate(180deg);
    }
    &.rotate-90,
    &.rotate-270 {
      height: 8px;
      margin-left: -20px;
    }
  }
`;

type IconType = 'arrow-right' | 'arrow-up' | 'arrow-down' | 'arrow-left' | 'ipos-web';

interface ButtonLinkProps extends Stylable {
  type: IconType;
  inheritColor?: boolean;
}

const iconTypeToComponent = (type: IconType) => {
  switch (type) {
    case 'arrow-right':
      return <IconArrowRight />;
    case 'arrow-up':
      return <IconArrowRight className="rotate-270" />;
    case 'arrow-down':
      return <IconArrowRight className="rotate-90" />;
    case 'arrow-left':
      return <IconArrowRight className="rotate-180" />;
    case 'ipos-web':
      return <IconIposWeb />;
    default:
      return '';
  }
};

const Icon: React.FC<ButtonLinkProps> = ({ type, className, inheritColor, style }) => (
  <StyledIcon
    className={`
      ${className || ''}
      ${inheritColor ? 'inherit-color' : ''}
    `}
    style={style || {}}
  >
    {iconTypeToComponent(type)}
  </StyledIcon>
);

export default Icon;
